//React + Typescript
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
//Intro Lib

//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoaderContainer from '../../../Components/LoaderContainer';
import CustomTooltip from '../../../Components/CustomTooltip';

import { useHistory } from 'react-router';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import { Steps } from "intro.js-react";
import BrandedCard from '../../../Components/BrandedCard';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import APILibraryCARIcon from '../../../CustomIcons/APILibraryCARIcon';
import APILibraryCSRIcon from '../../../CustomIcons/APILibraryCSRIcon';
import APILibraryPRIcon from '../../../CustomIcons/APILibraryPRIcon';
import APILibraryWRIcon from '../../../CustomIcons/APILibraryWRIcon';
import APILibraryQHRIcon from '../../../CustomIcons/APILibraryQHRIcon';
import APILibrarySRIcon from '../../../CustomIcons/APILibrarySRIcon';
import APILibraryAtRiskIcon from '../../../CustomIcons/APILibraryAtRiskIcon';

const APILibrary = (props: any): JSX.Element => {

  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, superAdminSelectedBasicOrgData } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);



  const [expandAPIList, setExpandAPIList] = useState<boolean>(true);
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".APIList",
        title: allResourcesToTranslations.apiinformation,
        intro: allResourcesToTranslations.apiinformationguideme,
      }
    ],
  });

  useEffect(() => {
    let updateSteps = [...intro.steps];

    setIntro({ ...intro, steps: [...updateSteps] });
  }, []);

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.apilibrary)
    setTitleEnglish(pageTitles.apilibrary)
    updateBackButtonRoute("");
    setMenuSelection("API Library");
    setLoading(false);
    return () => { };
  }, []);

  const handleExpandCourseListClick = () => {
    setExpandAPIList(!expandAPIList);
  }
  const handleViewProperties = (reportname: string) => {
    history.push(`/apilibrarydetail/${reportname}`);
  }

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <BrandedCard>
        <div className={styles.reportWrraper}>

          <Accordion expanded={expandAPIList}
            style={{ margin: "15px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="managerSettings-content"
              id="course-header"
              onClick={(e) => handleExpandCourseListClick()}>
              <Typography className="typographyFonts">
                <div style={{ marginTop: '5px', float: 'left' }}> <b>Report APIs</b> </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
              <Typography className="typographyFonts" style={{ display: "inline" }}>
                <div className={`${styles.tableSection} APIList`} style={{ width: '100%' }}>
                  {/* CAR*/}
                  <CustomTooltip displayText={[allResourcesToTranslations.selectreportdetail]}>
                    <div className={`${responsivestyles.rowrule} ${styles.highlightrowhover}`}
                      onClick={() => handleViewProperties('CurrentAdminReport')}
                      style={{ width: '100%', border: 'solid 1px #d3d6df', borderRadius: '5px' }} >
                      <div style={{ padding: '10px' }}>
                        <div className={`${responsivestyles.columnrule_1} ${responsivestyles.columnrule_s_12}`} >
                          <APILibraryCARIcon></APILibraryCARIcon>
                        </div>
                        <div className={`${responsivestyles.columnrule_11} ${responsivestyles.columnrule_s_12}`} >
                          <div className={styles.tableContent} style={{ padding: '5px' }}>
                            <div className={styles.tableContentTitle}><b>{allResourcesToTranslations.currentadminreport}</b></div>
                            <div className={styles.tableContentDescription}>
                              <div className={styles.tableContentDescriptionText}>
                                {allResourcesToTranslations.apicarnote1}
                                <br></br>
                                {allResourcesToTranslations.apicarnote2}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomTooltip>
                  {/* CSR                    */}
                  <CustomTooltip displayText={[allResourcesToTranslations.selectreportdetail]}>
                    <div className={`${responsivestyles.rowrule} ${styles.highlightrowhover}`}
                      onClick={() => handleViewProperties('CourseStatusReport')}
                      style={{ width: '100%', border: 'solid 1px #d3d6df', borderRadius: '5px', marginTop: '10px' }} >

                      <div style={{ padding: '10px' }}>
                        <div className={`${responsivestyles.columnrule_1} ${responsivestyles.columnrule_s_12}`} >
                          <APILibraryCSRIcon></APILibraryCSRIcon>
                        </div>
                        <div className={`${responsivestyles.columnrule_11} ${responsivestyles.columnrule_s_12}`} >
                          <div className={styles.tableContent} style={{ padding: '5px' }}>
                            <div className={styles.tableContentTitle}><b>{allResourcesToTranslations.coursestatusreport}</b></div>
                            <div className={styles.tableContentDescription}>
                              <div className={styles.tableContentDescriptionText}>
                                {allResourcesToTranslations.apicsrnote1}
                                <br></br>
                                {allResourcesToTranslations.apicsrnote2}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </CustomTooltip>
                  {/* PR                    */}
                  <CustomTooltip displayText={[allResourcesToTranslations.selectreportdetail]}>
                    <div className={`${responsivestyles.rowrule} ${styles.highlightrowhover}`}
                      onClick={() => handleViewProperties('ProgressReport')}
                      style={{ width: '100%', border: 'solid 1px #d3d6df', borderRadius: '5px', marginTop: '10px' }} >
                      <div style={{ padding: '10px' }}>
                        <div className={`${responsivestyles.columnrule_1} ${responsivestyles.columnrule_s_12}`} >
                          <APILibraryPRIcon></APILibraryPRIcon>
                        </div>
                        <div className={`${responsivestyles.columnrule_11} ${responsivestyles.columnrule_s_12}`} >
                          <div className={styles.tableContent} style={{ padding: '5px' }}>
                            <div className={styles.tableContentTitle}><b>{allResourcesToTranslations.progressreport}</b></div>
                            <div className={styles.tableContentDescription}>
                              <div className={styles.tableContentDescriptionText}>
                                {allResourcesToTranslations.apiprnote1}
                                <br></br>
                                {allResourcesToTranslations.apiprnote1}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomTooltip>
                  {/* SR                    */}
                  <CustomTooltip displayText={[allResourcesToTranslations.selectreportdetail]}>
                    <div className={`${responsivestyles.rowrule} ${styles.highlightrowhover}`}
                      onClick={() => handleViewProperties('SummaryReport')}
                      style={{ width: '100%', border: 'solid 1px #d3d6df', borderRadius: '5px', marginTop: '10px' }} >
                      <div style={{ padding: '10px' }}>
                        <div className={`${responsivestyles.columnrule_1} ${responsivestyles.columnrule_s_12}`} >
                          <APILibrarySRIcon></APILibrarySRIcon>
                        </div>
                        <div className={`${responsivestyles.columnrule_11} ${responsivestyles.columnrule_s_12}`} >
                          <div className={styles.tableContent} style={{ padding: '5px' }}>
                            <div className={styles.tableContentTitle}><b>{allResourcesToTranslations.summaryreport}</b></div>
                            <div className={styles.tableContentDescription}>
                              <div className={styles.tableContentDescriptionText}>
                                {allResourcesToTranslations.apisrnote1}
                                <br></br>
                                {allResourcesToTranslations.apisrnote2}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomTooltip>
                  {/* QHR                    */}
                  <CustomTooltip displayText={[allResourcesToTranslations.selectreportdetail]}>
                    <div className={`${responsivestyles.rowrule} ${styles.highlightrowhover}`}
                      onClick={() => handleViewProperties('QuizHistoryReport')}
                      style={{ width: '100%', border: 'solid 1px #d3d6df', borderRadius: '5px', marginTop: '10px' }} >
                      <div style={{ padding: '10px' }}>
                        <div className={`${responsivestyles.columnrule_1} ${responsivestyles.columnrule_s_12}`} >
                          <APILibraryQHRIcon></APILibraryQHRIcon>
                        </div>
                        <div className={`${responsivestyles.columnrule_11} ${responsivestyles.columnrule_s_12}`} >
                          <div className={styles.tableContent} style={{ padding: '5px' }}>
                            <div className={styles.tableContentTitle}><b>{allResourcesToTranslations.quizhistoryreport}</b></div>
                            <div className={styles.tableContentDescription}>
                              <div className={styles.tableContentDescriptionText}>
                                {allResourcesToTranslations.apiqhrnote1}
                                <br></br>
                                {allResourcesToTranslations.apiqhrnote2}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomTooltip>
                  {/* WR                    */}
                  <CustomTooltip displayText={[allResourcesToTranslations.selectreportdetail]}>
                    <div className={`${responsivestyles.rowrule} ${styles.highlightrowhover}`}
                      onClick={() => handleViewProperties('WarningReport')}
                      style={{ width: '100%', border: 'solid 1px #d3d6df', borderRadius: '5px', marginTop: '10px' }} >
                     <div style={{ padding: '10px' }}>
                      <div className={`${responsivestyles.columnrule_1} ${responsivestyles.columnrule_s_12}`} >
                        <APILibraryWRIcon></APILibraryWRIcon>
                      </div>
                      <div className={`${responsivestyles.columnrule_11} ${responsivestyles.columnrule_s_12}`} >
                        <div className={styles.tableContent} style={{ padding: '5px' }}>
                          <div className={styles.tableContentTitle}><b>{allResourcesToTranslations.warningreport}</b></div>
                          <div className={styles.tableContentDescription}>
                            <div className={styles.tableContentDescriptionText}>
                              {allResourcesToTranslations.apiwrnote1}
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </CustomTooltip>
                  {/* ARR                    */}
                <CustomTooltip displayText={[allResourcesToTranslations.selectreportdetail]}>
                    <div className={`${responsivestyles.rowrule} ${styles.highlightrowhover}`}
                      onClick={() => handleViewProperties('AtRiskReport')}
                      style={{ width: '100%', border: 'solid 1px #d3d6df', borderRadius: '5px', marginTop: '10px' }} >
                     <div style={{ padding: '10px' }}>
                      <div className={`${responsivestyles.columnrule_1} ${responsivestyles.columnrule_s_12}`} >
                        <APILibraryAtRiskIcon></APILibraryAtRiskIcon>
                      </div>
                      <div className={`${responsivestyles.columnrule_11} ${responsivestyles.columnrule_s_12}`} >
                        <div className={styles.tableContent} style={{ padding: '5px' }}>
                          <div className={styles.tableContentTitle}><b>{allResourcesToTranslations.atriskreport}</b></div>
                          <div className={styles.tableContentDescription}>
                            <div className={styles.tableContentDescriptionText}>
                              {allResourcesToTranslations.apiatriskrnote1}
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  </CustomTooltip>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </BrandedCard>
    </>
  );
};
export default APILibrary;
