import React, { useState, useEffect, useContext, useCallback } from "react";
import { UnitTreeItem } from "../../../globalTypes";

//libs
import { Steps } from "intro.js-react";

//MUI
import { Button, Checkbox, CircularProgress, Tab, Tabs, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

//API Functions
import { getRelevantUnitTree } from "../../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";

//Components
import Modal from "../../../Components/Modal";
import BrandedCard from "../../../Components/BrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";

//Styles
import styles from "../../../styles/accountManagement.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { useHistory, useLocation } from "react-router-dom";
import ResetIcon from "../../../CustomIcons/ResetIcon";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UserListSection from "./UserListSection";
import Units from "../../ReportCommonComponents/Units";
import searchUsersList from "../../../API/Administration/Users/SearchUsersList";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import ViewPolicyAssignedUsers from "../../../API/Administration/PolicyBuilder/ViewPolicyAssignedUsers";
import GetProfileUsers from "../../../API/Administration/CPDProfiles/GetProfileUsers";

export const AccountManagement = (props: any) => {
  const {isCalledFrom="AccountManagement", needLatestUsers=false, policyId=0, givenProfileID=0, givenProfilePeriodID=0, 
          setUpdatedSelectedUsers={}, setSearchStarted={}, setRecordsSearched={}, setInitialUsersData={}, assignedUserIds = [] } = props;
  const location = useLocation<any>();
  const { setMessage } = useContext(LoaderContext);
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, updateBackButtonRoute, EnableActivityLogs, setAccountMgmntInputProps, accountMgmntInputProps,
          setTitleEnglish } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const requestingUserId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [activeKey, setActiveKey] = React.useState("");

  const [units, setUnits] = useState<Array<UnitTreeItem>>(!!location.state && !!location.state.units ? location.state.units : []);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [includeInactive, setincludeInactive] = useState<boolean>(((!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile") ? false : 
                                                    (!!location.state ? location.state.includeInactive : (isCalledFrom === "AccountManagement" ? true : false))));

  const [filterUnitName, setFilterUnitName]  = useState(!!location.state && !!location.state.filterUnitName ? location.state.filterUnitName.toString() : "");
  const [filterFirstName, setFilterFirstName] = useState(!!location.state && !!location.state.filterFirstName ? location.state.filterFirstName.toString() : "");
  const [filterLastName,setFilterLastName] = useState(!!location.state && !!location.state.filterLastName ? location.state.filterLastName.toString() : "");
  const [filterUserName,setFilterUserName] = useState(!!location.state && !!location.state.filterUserName ? location.state.filterUserName.toString() : "");
  const [filterEmail, setFilterEmail] = useState(!!location.state && !!location.state.filterEmail ? location.state.filterEmail.toString() : "");

  const [usersList, setUsersList] = React.useState<Array<any>>([]);
 // const [initaialUsersList, setInitaialUsersList] = React.useState<Array<any>>([]);
  const [userListOpen, setUserListOpen] = useState<boolean>(!!location.state ? location.state.userListOpen : false);
  const [userID, setuserID] = useState(!!location.state && !!location.state.userID ? location.state.userID : "");
  const [isPopupOpen, setIsPopupOpen] = React.useState<boolean>(false);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitFilterButtons",
        title: "Units",
        intro: allResourcesToTranslations.emrepgmunitsm,
      },
    ],
  });

  useEffect(() => {
    let updateSteps = [...intro.steps];
    updateSteps.push(
      {
        element: ".unitFilterSearch",
        title: allResourcesToTranslations.unitfiltersearchtitle,
        intro: allResourcesToTranslations.unitfiltersearch,
      },
      {
        element: ".firstName",
        title: allResourcesToTranslations.filter,
        intro: allResourcesToTranslations.firstnamemsg,
      },
      {
        element: ".lastName",
        title: allResourcesToTranslations.filter,
        intro: allResourcesToTranslations.lastnamemsg,
      },
      {
        element: ".userName",
        title: allResourcesToTranslations.filter,
        intro: allResourcesToTranslations.usernamemsg,
      },
      {
        element: ".email",
        title: allResourcesToTranslations.filter,
        intro: allResourcesToTranslations.emailmsg,
      },
      {
        element: ".userID",
        title: allResourcesToTranslations.filter,
        intro: allResourcesToTranslations.useridmsg,
      });
    if (isCalledFrom === "AccountManagement")
    {
      updateSteps.push(
        {
          element: ".includeInactive",
          title: allResourcesToTranslations.filter,
          intro: allResourcesToTranslations.includeinactiveguideme,
        });
    }
    updateSteps.push(
      {
        element: ".searchUsers",
        title: allResourcesToTranslations.searchusers,
        intro: allResourcesToTranslations.searchusersmsg,
      },
      {
        element: ".resetAll",
        title: allResourcesToTranslations.resetall,
        intro: allResourcesToTranslations.resetallmsg,
      }
    );
    setIntro({ ...intro, steps: [...updateSteps] });
  }, []);

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    if (isCalledFrom === "AccountManagement") {
      setTitle(allResourcesToTranslations.accountmanagement);
      setTitleEnglish(pageTitles.accountmanagement);
      updateBackButtonRoute("");
    }
    if (!!location && !!location.state) {
      if (location.state.userListOpen) { 
        handleSearch(); 
      }
      setLoading(false);
    }
    else {
      setLoading(true);
      getRelevantUnitTree(
        oidcUser.access_token,
        requestingUserId,
        orgDomain,
        true,
        parseInt(orgId) 
      ).then((unitTreeRes) => {
        if (!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile") {        
          setRecordsSearched(true);
        }
        if (unitTreeRes.isSuccess) {
          setUnits(unitTreeRes.unitTreeItems);
          let curUnits = unitTreeRes.unitTreeItems;
          setAccountMgmntInputProps({
            filterEmail, filterFirstName, filterLastName, filterUserName, userID, filterUnitName, includeInactive, units: curUnits, userListOpen
          });   
          setLoading(false);          
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!!accountMgmntInputProps !== true || 
        accountMgmntInputProps.filterEmail !== filterEmail ||
        accountMgmntInputProps.filterFirstName !== filterFirstName ||
        accountMgmntInputProps.filterLastName !== filterLastName ||
        accountMgmntInputProps.filterUserName !== filterUserName ||
        accountMgmntInputProps.userID !== userID ||
        accountMgmntInputProps.filterUnitName !== filterUnitName ||
        accountMgmntInputProps.includeInactive !== includeInactive ||
        accountMgmntInputProps.units !== units) {
      setAccountMgmntInputProps({
        filterEmail, filterFirstName, filterLastName, filterUserName, userID, filterUnitName, includeInactive, units, userListOpen
      });   
    } 
  }, [filterEmail, filterFirstName, filterLastName, filterUserName, userID, filterUnitName, includeInactive, units, userListOpen]);

  const deselectAllUnits = () => {
    let updatedUnits = units
    const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
            if (!unitArray[i].isHidden) {
                const unit = unitArray[i];
                if (!unit.isDisabled) {
                    unit.isSelected = false;
                }
                setAllDeselected(unit.childUnitTreeItems);
            }
        }
    }
    setAllDeselected(updatedUnits)
    setUnits(updatedUnits)
    setAccountMgmntInputProps({
      filterEmail, filterFirstName, filterLastName, filterUserName, userID, filterUnitName, includeInactive, units: updatedUnits, userListOpen
    });   
}

  const handleResetData = () => {
    setFilterEmail('');
    setFilterFirstName('');
    setFilterLastName('');
    setFilterUserName('');
    setuserID('');
    setincludeInactive(true);
    setAccountMgmntInputProps({
        filterEmail: '', filterFirstName: '', filterLastName: '', filterUserName: '', userID: '', filterUnitName: '', includeInactive: true, units
    });
    deselectAllUnits();
    setUsersList([]);
    setUserListOpen(false);
  }

  const validateNumber = (userId: string) => {
    let reg = /^[1-9]\d*$/;
    if (reg.test(userId) === false) {
         return false;
    }
    else {
      return true;
    }
  }

  const handleKeyPress = useCallback((event) => {
    //console.log(`Key pressed: ${event.key}`);
    if (!!event && !!event.key && event.key.toString() == "Enter" && !isSearchClicked) {
      //handleSearch();
      setActiveKey(event.key.toString());
      setTimeout(() => {
        setActiveKey("");
      }, 600);
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (!!activeKey && activeKey == "Enter") {
      handleSearch();
      setActiveKey("");
    }
  }, [activeKey]);

  const fillSelectedUnitIdsFromGiven = (unitArray: Array<UnitTreeItem> | null, resultUnitIds: Array<number>) => {
    if (unitArray == null || !!unitArray !== true) return (!!resultUnitIds !== true ? [] : [...resultUnitIds]);
    if (unitArray.length === 0) return (!!resultUnitIds !== true ? [] : [...resultUnitIds]);
    for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (!!unit && unit.isSelected) {
          resultUnitIds.push(unit.unitID)
        } 
        resultUnitIds = fillSelectedUnitIdsFromGiven(unit.childUnitTreeItems, [...resultUnitIds])
    }
    return (!!resultUnitIds !== true ? [] : [...resultUnitIds]);
  }

  const fillAllUnitIdsFromGiven = (unitArray: Array<UnitTreeItem> | null, resultUnitIds: Array<number>) => {
    if (unitArray == null || !!unitArray !== true) return (!!resultUnitIds !== true ? [] : [...resultUnitIds]);
    if (unitArray.length === 0) return (!!resultUnitIds !== true ? [] : [...resultUnitIds]);
    for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (!!unit) {
          resultUnitIds.push(unit.unitID)
        }
        resultUnitIds = fillAllUnitIdsFromGiven(unit.childUnitTreeItems, [...resultUnitIds])
    }
    return (!!resultUnitIds !== true ? [] : [...resultUnitIds]);
  }

  const handleSearch = () => {
    setIsSearchClicked(true);
    if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(true); }
    let errorString = '';
    setUsersList([]);
    setUserListOpen(false);
    setAccountMgmntInputProps({
      filterEmail, filterFirstName, filterLastName, filterUserName, userID, filterUnitName, includeInactive: true, units, userListOpen: false
    });   

    if ((userID !== '' && !validateNumber(userID))) {
      errorString = errorString + allResourcesToTranslations.useriderr;
    }
    if (errorString !== ''){
      setActiveKey("");
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      setIsSearchClicked(false);
      if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
      return;
    }

    let unitIds: Array<number> = [];
    let allUnitIds: Array<number> = [];
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
            if (filterUnitName !== "") {
              if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                unitIds.push(unit.unitID)
              }
            } else {
              unitIds.push(unit.unitID)
            }
          }
          allUnitIds.push(unit.unitID);
          getSelectedUnitIds(unit.childUnitTreeItems)
      }
    }
    getSelectedUnitIds(units);

    if (isCalledFrom === "AccountManagement" || isCalledFrom === "MoveAccountsToUnit" || isCalledFrom === "ExternalCertificate" || isCalledFrom === "Newsfeed" || isCalledFrom === "NewsfeedList"){
      searchUsersList(
        oidcUser.access_token,
        requestingUserId,
        orgDomain,
        unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
        filterFirstName,
        filterLastName,
        filterUserName,
        filterEmail,
        includeInactive,
        userID == "" ? 0 : parseInt(userID),
        parseInt(orgId)
      ).then((response) => {
        if (
          response !== null &&
          response !== undefined &&
          response.isSuccess &&
          response.listOfUsers !== null &&
          response.listOfUsers !== undefined
        ) {
        
          setActiveKey("");
          if (response.listOfUsers.length > 0) {
            let updatedUsersList = response.listOfUsers.map((item: any) => {
              if ((isCalledFrom === "ExternalCertificate" || isCalledFrom === "Newsfeed") && assignedUserIds.includes(item.userID)){
                item.granted = true;
              }
              return {
                ...item,
                isChecked: false,
              };
            });
            if (isCalledFrom === "ExternalCertificate" || isCalledFrom === "Newsfeed") {
            let initialUsersList = response.listOfUsers.map((item: any) => {
              return {
                ...item,
                isInitial: true,
              };
            });
            setInitialUsersData(initialUsersList);
            }
            setUsersList(updatedUsersList);
           
            setUserListOpen(true);
            setAccountMgmntInputProps({
              filterEmail, filterFirstName, filterLastName, filterUserName, userID, filterUnitName, includeInactive: true, units, userListOpen: true
            });   
            setIsSearchClicked(false);
            if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
    
            let element = document.getElementById("userList");
            if (!!element)
              element.scrollIntoView();

            if (isCalledFrom === "MoveAccountsToUnit" || isCalledFrom === "ExternalCertificate" || isCalledFrom === "Newsfeed" || isCalledFrom === "NewsfeedList"){
              setRecordsSearched(true);
            }
    
          } else {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.norecords);
            setHasErrored(false);
            setSaveSuccess(false);
            setIsSearchClicked(false);
            if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
            return;
          }          
        
      } else {
        setShowWarningModal(true);
        setModalPopupMessage(allResourcesToTranslations.fetchfailed);
        setHasErrored(true);
        setIsSearchClicked(false);
        if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
        return;
      }
    });
  }
  else if (isCalledFrom === "PolicyBuilder"){
    ViewPolicyAssignedUsers(
      oidcUser.access_token,
      requestingUserId,
      orgDomain,
      policyId,
      parseInt(orgId),
      unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
      filterFirstName,
      filterLastName,
      filterUserName,
      filterEmail,
      "",
      "search"
    ).then((response) => {
      setRecordsSearched(true);
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.policyAssignedUserItem !== null &&
        response.policyAssignedUserItem !== undefined
      ) {
        if(response.policyAssignedUserItem.length > 0) {
          let updatedUsersList = response.policyAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          let initialUsersList = response.policyAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isInitial: true,
            };
          });
          setUsersList(updatedUsersList);
          //setInitaialUsersList(initialUsersList);
          setInitialUsersData(initialUsersList);
          setUserListOpen(true);
          setIsSearchClicked(false);
          if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
  
          let element = document.getElementById("userList");
          if (!!element)
            element.scrollIntoView();
  
        } else {
          setShowWarningModal(true);
          setModalPopupMessage(allResourcesToTranslations.fetchfailed);
          setHasErrored(true);
          setIsSearchClicked(false);
          if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
          return;
        }
      };
    });
    }
    // Called from CPD Profile
  else if (!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile") {
      GetProfileUsers(
        oidcUser.access_token,
        requestingUserId,
        orgDomain,
        parseInt(orgId),
        givenProfileID,
        givenProfilePeriodID,
        unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
        filterFirstName,
        filterLastName,
        filterUserName,
        filterEmail,
        "",
        "search"
      ).then((response) => {
        setRecordsSearched(true);
        if (
          response !== null &&
          response !== undefined &&
          response.isSuccess &&
          response.profileUsers !== null &&
          response.profileUsers !== undefined
        ) {
          if (response.profileUsers.length > 0) {
            let updatedUsersList = response.profileUsers.map((item: any) => {
              return {
                ...item,
                isChecked: false,
              };
            });
            setUsersList(updatedUsersList);
            setUserListOpen(true);
            setIsSearchClicked(false);
            if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
    
            let element = !!document ? document.getElementById("userList") : null;
            if (!!element)
              element.scrollIntoView();
    
          } else {
            setShowWarningModal(true);
            setModalPopupMessage(allResourcesToTranslations.norecords);
            setHasErrored(false);
            setIsSearchClicked(false);
            if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
            setUserListOpen(false);
          }
        } else {
          setShowWarningModal(true);
          setModalPopupMessage(allResourcesToTranslations.fetchfailed);
          setHasErrored(true);
          setIsSearchClicked(false);
          if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(false); }
          setUserListOpen(false);
        }
      });
    }
  };

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  
  const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {        
    setUsersList(usersData);
    setUpdatedSelectedUsers(usersData, "userlistsection");
  }

  useEffect(() => {   
    if (needLatestUsers) {
      setUsersList(usersList);
      if (!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile") {
        setUpdatedSelectedUsers(usersList, "accountmanagement");
      }
    }
  }, [needLatestUsers]);

  if (loading) return <LoaderContainer />;

  return (
    <>
    {(isCalledFrom === "AccountManagement") && (   
      <>      
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={["Guide Me"]}>
        <span
          onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}
        >
          <GuideIcon />
        </span>
      </CustomTooltip>
      </>
      )} 
      <Modal
        modalOpen={isPopupOpen}
        setModalOpen={setIsPopupOpen}
        type="warning"
      >
        <Tabs
          value={"Active"}
          indicatorColor="primary"
          textColor="primary"
          onChange={() => {}}
          aria-label="disabled tabs example"
        >
          <Tab label="Active" />
          <Tab label="Disabled" />
          <Tab label="Active" />
        </Tabs>
      </Modal>

      <BrandedCard>
        <div className="accountSearch">
        <div className={styles.reportWrraper}>
          <div className={styles.unittreesection}>
            <div>
              <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} 
              untitreeTitle={(isCalledFrom === "AccountManagement") ? "Units" : (isCalledFrom === "MoveAccountsToUnit") ? "Move Accounts" : allResourcesToTranslations.assignaccounts}
              unitTreeTitleInfo={(isCalledFrom === "MoveAccountsToUnit") ? allResourcesToTranslations.searchforaccounttomove : allResourcesToTranslations.leaveblankforunit} />
            </div>
          </div>
          <div className={styles.accountRightSection}>
            <p
              style={{
                fontWeight: 700,
                color: "#D3D6DF",
                fontSize: "14px",
                fontStyle: "italic",
                marginTop: "5px",
              }}
            >
              Enter the account details below
            </p>
            <div className={styles.firstNameInput}>
              <div className="firstName">
                <TextField
                  className={styles.input}
                  label={"First Name"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterFirstName}
                  onChange={(e) => setFilterFirstName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="lastName">
                <TextField
                  className={styles.input}
                  label={"Last Name"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterLastName}
                  onChange={(e) => setFilterLastName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="userName">
                <TextField
                  className={styles.input}
                  label={"Username"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterUserName}
                  onChange={(e) => setFilterUserName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="email">
                <TextField
                  className={styles.input}
                  label={"Email"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterEmail}
                  onChange={(e) => setFilterEmail(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className="userID">
                <TextField
                  className={styles.input}
                  label={"Account ID"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={userID}
                  onChange={(e) => setuserID(e.target.value)}
                />
              </div>
            </div>
            {(isCalledFrom === "AccountManagement" || isCalledFrom === "MoveAccountsToUnit" ) && (   
            <div
              style={{ marginTop: "10px", marginBottom: "20px" }}
              className="includeInactive"
              hidden={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? true : false}
            >
              <Checkbox
                size="small"
                color="primary"
                checked={includeInactive}
                className={styles.checkBox}
                onClick={() => {
                  setincludeInactive(!includeInactive);                  
                  setAccountMgmntInputProps({
                    filterEmail, filterFirstName, filterLastName, filterUserName, userID, filterUnitName, includeInactive: !includeInactive, units, userListOpen
                  });   
                }}
              />{" "}
              {"Include Inactive Accounts"}
            </div>
            )}
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <div className="resetAll" style={{ marginBottom: "10px", marginRight: "-5px" }}>
                <CustomTooltip displayText={["Reset"]}>
                  <span className={styles.emailResetIcon} onClick={handleResetData}>
                    <ResetIcon />
                  </span>
                </CustomTooltip>
              </div>
              <div className="searchUsers">
                <CustomTooltip displayText={["Search"]} hidden={isSearchClicked}>
                  <Button
                    onClick={() => {if (!!setSearchStarted && typeof setSearchStarted === "function") { setSearchStarted(true); } handleSearch();}}
                    disabled={isSearchClicked}
                    className={styles.moduleListButton}
                    variant="contained"
                    color="primary"
                  >
                    <SearchIcon />
                  </Button>
                </CustomTooltip>
              </div>
              <div className={styles.loaderContainer}>
                <CircularProgress
                  style={{ width: isSearchClicked ? "20px" : "0px", padding: "15px 10px" }}
                  color="primary"
                  size="10"
                />
              </div>
            </div>
          </div>

          <div></div>
        </div>
        </div>
      </BrandedCard>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
  
      {userListOpen && usersList != null && usersList.length > 0 && (
        <div id="userList" className="userList">
          <UserListSection usersData={usersList} isCalledFrom={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? 'profile-account-search' : isCalledFrom} 
                            setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} needLatestUsers={needLatestUsers}
                            profileID={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? givenProfileID : -1} 
                            profilePeriodID={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? givenProfilePeriodID : -1}
                            selectedUnitIDs={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? fillSelectedUnitIdsFromGiven(units, []) : []}
                            allUnitIDs={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? fillAllUnitIdsFromGiven(units, []) : []}
                            filterFirstName={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? filterFirstName : ""}
                            filterLastName={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? filterLastName : ""}
                            filterUserName={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? filterUserName : ""}
                            filterEmail={!!isCalledFrom && isCalledFrom.trim().toLowerCase() == "profile" ? filterEmail : ""} />
        </div>
      )}
    </>
  );
};

export default AccountManagement;
