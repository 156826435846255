import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const APILibraryPRIcon = () => (
<svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M73.3513 54.9529C71.1514 60.156 67.71 64.7407 63.3287 68.3066C58.9473 71.8725 53.7591 74.3106 48.2178 75.4083C42.6762 76.506 36.9506 76.2293 31.5406 74.6025C26.1309 72.976 21.2019 70.0489 17.1847 66.0774C13.1674 62.1058 10.1843 57.2105 8.49599 51.8197C6.8077 46.4288 6.46571 40.7063 7.49985 35.1529C8.53403 29.5992 10.9129 24.3835 14.4285 19.9617C17.944 15.5399 22.4893 12.0465 27.6668 9.78717" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M76.0833 41.5C76.0833 36.9585 75.1887 32.4614 73.4508 28.2656C71.7127 24.0697 69.1656 20.2573 65.9542 17.0459C62.7428 13.8346 58.9303 11.2872 54.7344 9.54917C50.5387 7.81122 46.0415 6.91669 41.5 6.91669" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default APILibraryPRIcon
