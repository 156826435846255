// React
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReactOidc } from "@axa-fr/react-oidc-context";

// Types
import { CurrentAdminReportResultItem, SentEmailReportResultItem, UnitTreeItem } from "../../../globalTypes";

//libs
import { Steps } from "intro.js-react";

//MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Contexts
import { UIContext } from "../../../contexts/UIContext";
import { LoaderContext } from "../../../contexts/LoaderContext";

// Icons
import GuideIcon from "../../../CustomIcons/GuideIcon";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import AddEmailAccountIcon from "../../../CustomIcons/Administration/AddEmailAccountIcon";
import RestartIcon from "../../../CustomIcons/RestartIcon";
import RandomIcon from "../../../CustomIcons/Administration/RandomIcon";
import PasswordValidIcon from "../../../CustomIcons/Administration/PasswordValidIcon";
import PasswordInValidIcon from "../../../CustomIcons/Administration/PasswordInvalidIcon";
import SendEmailReportIcon from "../../../CustomIcons/SendEmailReportIcon";

//Styles
import styles from "../../../styles/AccountDetails.module.css";

// Contexts
import { TranslationsContext } from "../../../contexts/TranslationsContext";

// APIs
import { getRelevantUnitTree } from "../../../API/GetRelevantUnitTree";
import getOneUserDetails from "../../../API/Administration/Users/GetOneUserDetails";
import addOrUpdateUser from "../../../API/Administration/Users/AddOrUpdateUser";
import getTimezoneList from "../../../API/Administration/Users/GetTimezoneList";
import GetWelcomeEmailTemplate from "../../../API/Administration/Users/GetWelcomeEmailTemplate";
import sendWelcomeEmail from "../../../API/Administration/Users/SendWelcomeEmail";
import getClassificationType from "../../../API/GetClassificationType";
import getClassificationListByType from "../../../API/GetClassificationListByType";
import { getDefaultPassword } from "../../../API/Administration/Users/GetDefaultPassword";
import getOrgDomainConfig from "../../../API/GetOrgDomainConfig";
import getFullCurrentAdminReport from "../../../API/GetFullCurrentAdminReport";

//Components 
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";
import MUICustomSelect from "../../../Components/MUICustomSelect";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import Modal from "../../../Components/Modal";
import TinyMCEEditor from "../../../Components/TinyMCEEditorComponent";
import RadioUnits from "../AddAccount/RadioUnits";
import PasswordChecklist, { RuleNames } from "react-password-checklist";
import UnitTree from "../../CurrentAdminReport/UnitTree";
import validateEmail from "../../../functions/valudateEmail";
import CurrentAdminReportSection from "./CurrentAdminReportSection";

const AccountDetails = (props: any) => {
  const { userIdToEdit } = props;  
  const location = useLocation<any>();
  const { setMessage } = useContext(LoaderContext);
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs, IsEbookEnabled, timeZoneId, passwordLockout, superAdminSelectedBasicOrgData } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website, family_name, given_name  },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [unitsPrivileges, setUnitsPrivileges] = useState<Array<UnitTreeItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [enteredPassword, setPassword] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [confirmEnteredPassword, setConfirmPassword] = useState<string>( "");
  const [userIdToUpdate, setuserIdToUpdate] = useState(0);
  const [unitID, setUnitID] = useState(0);
  const [unitIDInitial, setUnitIDInitial] = useState(0);
  const [userTypeID, setUserTypeID] = useState(0);
  const [accountLocked, setAccountLocked] = useState<boolean>(false);
  const [isAccountMarkedUnlocked, setIsAccountMarkedUnlocked] = useState<boolean>(false);
  const [accountActive, setAccountActive] = useState<boolean>(false);
  const [accountActiveInitial, setAccountActiveInitial] = useState<boolean>(false);
  const [notifyManager, setNotifyManager] = useState<boolean>(false);
  const [sendEbook, setSendEbook] = useState<boolean>(false);
  const [alertUnitAdmin, setAlertUnitAdmin] = useState<boolean>( false);
  const [dateCreated, setDateCreated] = useState<string>("");
  const [dateUpdated, setDateUpdated] = useState<string>("");
  const [dateLastLogin, setDateLastLogin] = useState<string>("");
  const [createdByName, setCreatedByName] = useState<string>("");
  const [updatedByName, setUpdatedByName] = useState<string>("");
  const [lastLoginDateTime, setLastLoginDateTime] = useState<string>("");
  const [showLastUpdatedRow, setShowLastUpdatedRow] = useState<boolean>(true);

  const [showModal,setModalOpen] = useState<boolean>(false);

  const [alertOrgAdmin, setAlertOrgAdmin] = useState<boolean>(false);
  const [selectedTimezone, setSelectedTimezone] = useState<any>({});
  const [timezoneList, setTimezoneList] = useState<any>([]);
  const [isTimezoneOptionFocused, setIsTimezoneOptionFocused] = useState<boolean>(false);
  const [filterFirstName, setFilterFirstName] = useState("");
  const [timeZone, setTimeZone] = useState(0);
  const [filterLastName, setFilterLastName] = useState( "");
  const [filterUserName, setFilterUserName] = useState( "");
  const [filterEmail, setFilterEmail] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [filterExternalId, setFilterExternalId] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState<null | string>(null);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState<null | string>(null);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<null | string>(null);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<null | string>(null);

  const [enablePasswordRule, setEnablePasswordRule] = useState<boolean>(false);
  const [IsPasswordRuleCapital, setIsPasswordRuleCapital] = useState<boolean>(false)
  const [IsPasswordRuleNumber, setIsPasswordRuleNumbere] = useState<boolean>(false)
  const [IsPasswordRuleSpecialChar, setIsPasswordRuleSpecialChar] = useState<boolean>(false) 
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  // booleans for password validations
  const [containsUL, setContainsUL] = useState(false); // uppercase letter
  const [containsN, setContainsN] = useState(false); // number
  const [containsSC, setContainsSC] = useState(false); // special character
  const [contains8C, setContains8C] = useState(false); // min 8 characters
  const [contains16C, setContains16C] = useState(false); // max 16 characters
  const [passwordMatch, setPasswordMatch] = useState(false); // passwords match
  const [expandManagerSetting, setExpandManagerSetting] = useState(false);

  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [emailBodyTemplate, setEmailBodyTemplate] = useState<string>("");
  const [isHandlerBusy, setHandlerBusy] = useState<boolean>(false);
  const [emailReportBodyResult, setEmailReportBodyResult] = useState<Array<SentEmailReportResultItem>>([]);
  const [isPasswordChangeStarted, setIsPasswordChangeStarted] = useState<boolean>(false);
  const [classificationList, setClassificationList] = useState([]);
  const [groupingOption, setGroupingOption] = useState<boolean>(false);
  const [groupingOptionName, setGroupingOptionName] = useState<string>("Grouping Option");
  const [selectedClassification, setSelectedClassification] = useState<any>({value: 0});
  const [selectedUnitCallback, setSelectedUnitCallback] = useState<number>(0);
  const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree

  const [currentAdminReportOpen, setCurrentAdminReportOpen] = useState<boolean>(false);
  const [expandCurrentAdminReportList, setExpandCurrentAdminReportList] = useState<boolean>(false);
  const [currentAdminReportResultList, setCurrentAdminReportResultList] = useState<Array<any>>([]);
  const [loadedUser, setLoadedUser] = useState<any>({});

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitFilterButtons",
        title: "Units",
        intro: "Select, unselect, expand and collapse all units.",
      },
    ],
  });  

  const formatDate2 = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (givenDate === null || givenDate === undefined)
      {
          return "";
      }

      const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);

      return (year.toString() + "-" + 
      (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
      (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
  }

  const nextDate = new Date()
  nextDate.setDate(nextDate.getDate()+1);
  const nextDateString = formatDate2(nextDate); //`${nextDate.getUTCFullYear()}/${("0" + (nextDate.getUTCMonth() + 1)).slice(-2)}/${('0' + (nextDate.getUTCDate())).slice(-2)}` 
    
  const setGuideMe = (groupingOption: boolean, givenLoadedUser: any | null = null) => {
    let updateSteps = [...intro.steps];
      updateSteps.push(
        {
          element: ".unitFilterSearch",
          title: "Filter Units",
          intro: "Search unit/subunits by typing unit name.",
        },
        {
          element: ".checkInactive",
          title: "Filter",
          intro: "Select to activate Account.",
        },
        {
          element: ".firstName",
          title: "First Name",
          intro: "Enter Account's First Name.",
        },
        {
          element: ".lastName",
          title: "Last Name",
          intro: "Enter Account's Last Name.",
        },
        {
          element: ".userName",
          title: "Username",
          intro: "Enter Account's Username.",
        },
        {
          element: ".email",
          title: "Email Address",
          intro: "Enter Account's Email Address.",
        },
        {
          element: ".externalID",
          title: "External ID",
          intro: "Enter Account's External ID.",
        });
        if (groupingOption)
        {
            updateSteps.push({
                element: ".groupby",
                title: "Classification", intro: "Select classification for this Account."
            });
        }

        updateSteps.push(
        {
          element: ".password",
          title: "Password",
          intro: `Enter a new password for the Account.
                      `,
        },
        {
          element: ".confirmPassword",
          title: "Confirm Password",
          intro: ` confirm a new password for the Account.
                      `,
        });
  
        if(enablePasswordRule && arePasswordRulesToBeDisplayed()) {
          updateSteps.push({
          element: ".passwordRequirements",
          title: "Password Requirements",
          intro:
            "When specifying a new password, if set up, password complexity requirements will check off when the entered password complies to set rules.",
        });
      }
      updateSteps.push(
        {
          element: ".notifyManager",
          title: "Notify Manager",
          intro:
            "Check this to enable the notification of a manager of overdue courses for the account by email, enter the email address of the Manager to notify. NOTE: This must be enabled on a course-by-course basis under Organisation Mail Setup in Salt Admin.",
        },
  
        {
          element: ".emailAlert",
          title: "Send Email Alerts to Unit Admin",
          intro:
            " When an account is overdue for any given coursemodule, check this to also send an overdue notification to any relevant Unit Administrators based on where the account resides in Salt.",
        },
        {
          element: ".alertOrgAdmin",
          title: "Send Email Alerts to Org Admin",
          intro:
            "When an account is overdue for any given coursemodule, check this to also send an overdue notification to all Organisation Administrators. NOTE: This will cause a high volume of email traffic to Organisation Administrators.",
        },
        );
  
        if(IsEbookEnabled) {
          updateSteps.push(
            {
              element: ".sendEbook",
              title: "Send Email about new version of Ebook",
              intro:
                "Check this to send Email about new version of Ebook.",
            });
        }
        updateSteps.push(
        {
          element: ".timezone",
          title: "Timezone",
          intro:
            "Set the time zone the account resides in; this will ensure all date\time are in the account's local time.",
        },
        {
          element: ".lastLoginDetails",
          title: "Last Login Details",
          intro: "This shows when and who the account was created by, when and who last modified the account and when the account last logged in, if applicable."
        },
        {
          element: ".saveData",
          title: "Save",
          intro: "Click here to save all changes",
        },
        {
          element: ".sendWelcomeEmail",
          title: "Send Welcome Email",
          intro: "Click here to send account a welcome email",
        });
        if ((!!givenLoadedUser && givenLoadedUser.active) || (!!loadedUser && loadedUser.active)) {
          updateSteps.push(
            {
              element: "#carForSpecificAccount",
              title: allResourcesToTranslations.caraccountspecificguidemetitle,
              intro: allResourcesToTranslations.caraccountspecificguidememsg,
            });
        }
     
    setIntro({ ...intro, steps: [...updateSteps] });        
};

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return ((parseInt(day) < 10 ? "0" + day.toString() : day.toString()) + "/" + (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "/" + year.toString());
      }
      else {
          return "";
      }
  }

  const getRulesArray = () => 
  {
    var arr : RuleNames[] = ["minLength","maxLength","match", "capital", "number" ,"specialChar"]
    if (!IsPasswordRuleCapital) {
      const index = arr.indexOf("capital");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    if (!IsPasswordRuleNumber) {
      const index = arr.indexOf("number");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    if (!IsPasswordRuleSpecialChar) {
      const index = arr.indexOf("specialChar");
      if (index > -1) {
        arr.splice(index, 1);
      }
    }
    return arr;
  };

  const validatePassword = () => {
   
    if (!enablePasswordRule) {
      return;
    }
    if (enteredPassword === "" && confirmEnteredPassword === "") {
      setIsPasswordValid(true);
      return;
    }

    setIsPasswordValid(false);

    var allValid = true;
    // has uppercase letter
    if (enteredPassword.toLowerCase() != enteredPassword) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
      allValid = false;
    }

    // has number
    if (/[0-9]/g.test(enteredPassword)) {
      setContainsN(true);
    } else {
      setContainsN(false);
      allValid = false;
    }

    // has special character
    if (/[^0-9A-Za-z]/g.test(enteredPassword)) {
      setContainsSC(true);
    } else {
      setContainsSC(false);
      allValid = false;
    }

    // has 8 characters
    if (enteredPassword.length >= 8) {
      setContains8C(true);
    } else {
      setContains8C(false);
      allValid = false;
    }

    // has <16   characters
    if ((enteredPassword.length >= 8 && enteredPassword.length <= 16, EnableActivityLogs)) {
      setContains16C(true);
    } else {
      setContains16C(false);
      allValid = false;
    }

    // passwords match
    if (enteredPassword !== "" && enteredPassword === confirmEnteredPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
      allValid = false;
    }

    // all validations passed
    setIsPasswordValid(allValid && passwordMatch);

  };

  const resetErrorMessages = () => {
    setPasswordError(false);
    setPasswordErrorMessage(null);
    setEmailError(false);
    setEmailErrorMessage(null);
    setFirstNameError(false);
    setFirstNameErrorMessage(null);
    setLastNameError(false);
    setLastNameErrorMessage(null);
  };

  const handleResetPassword = () => {
    setPassword("");
    setConfirmPassword("");
    resetErrorMessages();
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleModal = (value:boolean) => {
    setModalOpen(value);
  }

  const handleSendWelcomeEmail = () =>
  {    
    if (!validateEmail(filterEmail)) {
      setShowWarningModal(true);
      setModalPopupMessage('Please enter valid email');
      setHasErrored(true);
      return;
    } else {
      handleModal(true);
    }
  }

  const handleEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if ((event.target.value != '')) {
      setEmailIsValid(false)
    }
    setEmailSubject(event.target.value);
  };

  const handleTextEditorChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event !== undefined) {
      setEmailBodyTemplate(event.target.value);
  }
  };

  const handleSendEmail = () => {
    if (!validateEmail(filterEmail)) {
      setShowWarningModal(true);
      setModalPopupMessage('Please enter valid email');
      setHasErrored(true);
      return;
    }
    if (emailSubject == '') {
      setEmailIsValid(true);
    } else {
      setHandlerBusy(true);
      setEmailIsValid(false);
      
      var emailBodyTemplateContent = (document.getElementById('editorText') as HTMLInputElement).value;
      var emailBodyTemplateInitialContent = (document.getElementById('editorChangedText') as HTMLInputElement).value;
      setEmailBodyTemplate((!!emailBodyTemplateContent === false || emailBodyTemplateContent.trim() === "") ? emailBodyTemplateInitialContent : emailBodyTemplateContent);

      sendWelcomeEmail(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(userIdToEdit), filterFirstName, filterLastName,
      filterEmail, emailSubject, (!!emailBodyTemplateContent === false || emailBodyTemplateContent.trim() === "") ? emailBodyTemplateInitialContent : emailBodyTemplateContent).then((emailresult) => {
        if (emailresult !== null) {
          if (emailresult.isSuccess && emailresult.error == "") {
            handleModal(false);
            setHandlerBusy(false);
            setShowWarningModal(true);
            setModalPopupMessage('Welcome email sent successfully!');
            setSaveSuccess(true);
            setHasErrored(false);
            setIsSaving(false);
          } else {
            handleModal(false);
            setShowWarningModal(true);
            setHandlerBusy(false);
            setModalPopupMessage(emailresult.error);
            setHasErrored(true);
            setIsSaving(false);
            return;
          }
          } else {
            handleModal(false);
            setHandlerBusy(false);
            setShowWarningModal(true);
            setModalPopupMessage('Saving record has failed, please try again.');
            setHasErrored(true);
            setIsSaving(false);
            return;
          }
      });
    }
  }

  const handleGeneratePassword = () => {
    getDefaultPassword( oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId)).then((result) => {
      if(result.isSuccess) {
        result.getDefaultPasswordItems.map((item:any) => {
          setPassword(item.password);
          setConfirmPassword(item.password);
        })
      }
    })
  }

  const handleSubmit = () => {
    let isReactivated = false;
    if (accountActiveInitial == false && accountActive == true) {
      isReactivated = true;
    }
    let isUnitChanged = false;
    if (unitIDInitial > 0 && selectedUnitCallback > 0 && selectedUnitCallback != unitIDInitial) {
      isUnitChanged = true;
    }

    let errorString = '';
    if (!validateEmail(filterEmail)) {
      errorString = errorString + 'Please enter valid email;'
    }

    if (selectedUnitCallback === 0) {
      errorString = errorString + 'Please select the unit;'
    }

    if ((managerEmail !== '' && !validateEmail(managerEmail)) || (notifyManager && !validateEmail(managerEmail))) {
      setExpandManagerSetting(true);
      errorString = errorString + 'Please enter valid manager email;'
    }

    if(isAccountMarkedUnlocked && enteredPassword.trim() == ""){
      errorString = errorString + 'Please change the password for unloacked account;'
    }
 
    if (errorString !== ''){
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      return;
    }

    setIsSaving(true);
    setSaveClicked(true);
    addOrUpdateUser(
      "update",
      oidcUser.access_token,
      userId,
      parseInt(orgId),
      orgDomain,
      selectedUnitCallback,
      filterFirstName,
      filterLastName,
      filterUserName,
      filterEmail,
      accountActive,
      userTypeID,
      filterExternalId,
      selectedTimezone.value,
      managerEmail,
      alertUnitAdmin,
      alertOrgAdmin,
      notifyManager,
      sendEbook,
      enteredPassword,
      userIdToUpdate,
      parseInt(selectedClassification.value),
      isReactivated,
      isUnitChanged
    ).then((result) => {
      if (result !== null) {
      if (result.isSuccess && result.error == "") {
        setIsPasswordChangeStarted(false);
        setShowWarningModal(true);
        setModalPopupMessage('Account updated successfully!');
        setSaveSuccess(true);
        setHasErrored(false);
        setIsSaving(false);
        loadUserData();
      } else {
        setShowWarningModal(true);
        setModalPopupMessage(result.error);
        setHasErrored(true);
        setIsSaving(false);
        return;
      }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Saving record has failed, please try again.');
        setHasErrored(true);
        setIsSaving(false);
        return;
      }
    });
  };

  const setSelectedUnit = (unitsArray: any, selectedUnitId: number) => {
    let unitHierarchy = "";
    let parentUnits: any = [];
    const setSelectOfUnit = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === selectedUnitId) {
          unit.isSelected = true;
          if (unit.unitHierarchy.indexOf(">") > 0) {
             unitHierarchy = unit.unitHierarchy;       
             parentUnits = unit.unitHierarchy.split(" > ");     
          }
        }
        setSelectOfUnit(unit.childUnitTreeItems);
      }

      if (unitHierarchy !== "" && parentUnits.length > 0) {      
        const setExpandOfUnit = (unitArray: Array<UnitTreeItem> | null) => {
          if (unitArray === null) return; //Break if no children
          if (unitArray.length === 0) return;
          for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (parentUnits.includes(unit.name)) {
              unit.isExpanded = true;
            }
            setExpandOfUnit(unit.childUnitTreeItems);
          }
        };
        setExpandOfUnit(unitsArray);
      }   
    };
    setSelectOfUnit(unitsArray);
  
    setUnits([...unitsArray]);
  }

  const loadUserData = () => {    
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) { 
        let unitsArray = [...unitTreeRes.unitTreeItems];
        setUnits([...unitsArray]);
        getOneUserDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(userIdToEdit)).then((result) => {
          if (result.isSuccess && result.error == null) {
            setLoadedUser(result.userDetailsItems[0]);  
            setGuideMe(groupingOption, result.userDetailsItems[0]);          
            setFilterFirstName(result.userDetailsItems[0].firstName);
            setFilterLastName(result.userDetailsItems[0].lastName);
            setFilterEmail(result.userDetailsItems[0].email);
            setFilterUserName(result.userDetailsItems[0].userName);
            setPassword("");
            setConfirmPassword("");
            setAccountActive(result.userDetailsItems[0].active);
            setAccountActiveInitial(result.userDetailsItems[0].active);
            setNotifyManager(result.userDetailsItems[0].notifyMgr);
            setManagerEmail(result.userDetailsItems[0].delinquencyManagerEmail);
            setAlertOrgAdmin(result.userDetailsItems[0].notifyOrgAdmin);
            setAlertUnitAdmin(result.userDetailsItems[0].notifyUnitAdmin);
            setSendEbook(result.userDetailsItems[0].ebookNotification);
            setUnitID(result.userDetailsItems[0].unitID);
            setUnitIDInitial(result.userDetailsItems[0].unitID);
            setFilterExternalId(result.userDetailsItems[0].externalID);
            setUserTypeID(parseInt(result.userDetailsItems[0].userTypeID));
            setTimeZone(result.userDetailsItems[0].timeZoneID ?? timeZoneId);
            setSelectedClassification({value : result.userDetailsItems[0].classificationID});
            setAccountLocked(result.userDetailsItems[0].loginFailCount >= 3 && passwordLockout);
          
            let createdDate = formatDate(new Date(result.userDetailsItems[0].dateCreated));
            setDateCreated(createdDate);

            let updatedDate = formatDate(new Date(result.userDetailsItems[0].dateUpdated));
            setDateUpdated(updatedDate);

            let lastLoginDate = result.userDetailsItems[0].lastLoginDate == null ? 'Never' : formatDate(new Date(result.userDetailsItems[0].lastLoginDate));
            setDateLastLogin(lastLoginDate);

            setCreatedByName(result.userDetailsItems[0].createdByUserName);
            setUpdatedByName(result.userDetailsItems[0].updatedByUserName);
            setLastLoginDateTime(result.userDetailsItems[0].strLastLogin);
            setShowLastUpdatedRow(result.userDetailsItems[0].updatedByName !== '');

            setSelectedUnit(unitsArray, result.userDetailsItems[0].unitID);


            if (parseInt(result.userDetailsItems[0].userTypeID) == 3) {
              getRelevantUnitTree(
                oidcUser.access_token,
                parseInt(userIdToEdit),
                orgDomain,
                true,
                parseInt(orgId)
              ).then((unitprivilegeTreeRes) => {
                if (unitprivilegeTreeRes.isSuccess) {        
                    let unitsPrivilegeArray = [...unitprivilegeTreeRes.unitTreeItems];


                    const setSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
                      if (unitArray === null) return //Break if no children
                      if (unitArray.length === 0) return
                      for (let i = 0; i < unitArray.length; i++) {
                        const unit = unitArray[i];
                        unit.isDisabled = true; 
                        unit.isExpanded = true;
                        unit.isSelected = true;
                        setSelectedUnitIds(unit.childUnitTreeItems);
                      }
                    }
                    setSelectedUnitIds(unitsPrivilegeArray);
                    setUnitsPrivileges([...unitsPrivilegeArray]);
                }
              });
            }
            setLoading(false);      
          }
        })
      }
    });
  }

  const handleCARExpandClick = () => {		
    setExpandCurrentAdminReportList(!expandCurrentAdminReportList);
    if (!expandCurrentAdminReportList) {
      if (!!loadedUser) {
        getFullCurrentAdminReport(
          oidcUser.access_token, userId, orgDomain, parseInt(orgId),
          "", "", 
          loadedUser.firstName,        
          loadedUser.lastName,
          loadedUser.userName,
          loadedUser.email,
          nextDateString,
          "Unit_User", "LAST_NAME", 
          loadedUser.active,
          loadedUser.classificationID,
          loadedUser.firstName,       
          loadedUser.lastName,
          loadedUser.userName,
          loadedUser.email,
          false
        ).then((response) => {
          if (
            response !== null &&
            response !== undefined &&
            response.isSuccess &&
            response.currentAdminReportItems !== null &&
            response.currentAdminReportItems !== undefined
          ) {
            //console.log("CAR rows: " + response.currentAdminReportItems.length);
            if (response.currentAdminReportItems.length > 0) {
              setCurrentAdminReportResultList(response.currentAdminReportItems.filter((r: CurrentAdminReportResultItem) => !!r && r.userID === loadedUser.userID));
              setCurrentAdminReportOpen(true);              
              //let element = document.getElementById("currentAdminReportList");
              // if (!!element)
              //   element.scrollIntoView();      
            } 
          } else {                          
            return;
          }
        });
      }
    }
  }

  const isMounted = useRef(false)
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if (parseInt(userIdToEdit) > 0) {
        setuserIdToUpdate(parseInt(userIdToEdit));
        setTitle(allResourcesToTranslations.accountmanagement);    
        setTitleEnglish(pageTitles.accountdetails); 
        
        let unitDetailsId = location.state?.inputunitID ?? "";
        //let userunitDetailsId = location.state?.inputuserID ?? ""; 
        if (unitDetailsId !== undefined && unitDetailsId != ""){
          //updateBackButtonRoute("/unitDetails/"+ parseInt(unitDetailsId)+"/"+ parseInt(userunitDetailsId)) //uncomment if need to redirect specific tab
          updateBackButtonRoute("/unitDetails/"+ parseInt(unitDetailsId))
        }
        else{
          updateBackButtonRoute("/AccountManagement");   
        }
        setLoading(true);
        loadUserData();
      }
    }
    return () => {
        isMounted.current = false;
      };  

  },[])

  useEffect(() => {
    if (timezoneList.length !== 0 && timeZone !== 0) {
      var selectedTimezoneItem = timezoneList.filter((tz: any) => tz.value == timeZone);
      if(selectedTimezoneItem != null && selectedTimezoneItem.length > 0) {
        setSelectedTimezone({
          value: selectedTimezoneItem[0].value,
          text: selectedTimezoneItem[0].text
        });
      }
    }
  }, [timezoneList, timeZone]);
  
  useEffect(() => {
    if (units.length > 0) {
      setUnits(units);
    }    
  }, [units, unitID]);

  useEffect(() => {

    GetWelcomeEmailTemplate(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(userIdToEdit)).then((emailresult) => {
      if (emailresult.isSuccess && emailresult.error == null) {
        if (emailresult.emailReportBodyItem !== null && emailresult.emailReportBodyItem.length > 0) {
          setEmailBodyTemplate(emailresult.emailReportBodyItem[0].body);
          let updatedDataNew = emailresult.emailReportBodyItem.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          setEmailReportBodyResult(updatedDataNew);
        }
      }
    });

    getTimezoneList(oidcUser.access_token, userId, orgDomain).then((result) => {
      if (result != null && result.isSuccess) {
        let updatedData = result.timeZoneItems.map((item: any) => {
          return {
            value: item.timeZoneID,
            text: item.flB_Name,
          };
        });
        setTimezoneList([...updatedData]);
          if (timeZone != 0) {
            updatedData.map((item:any) => {           
              if (item.value == timeZone) {
                setSelectedTimezone({
                  value: item.value,
                  text: item.text,
                });
              }
            })
          } else {
            setSelectedTimezone({
              value: updatedData[0].value,
              text: updatedData[0].text,
            });
          }   
        setTimezoneList([...updatedData]);
      }
    });

    getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {
                   
      if(classType !== null && classType.classificationTypeIDs !== null && 
         classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
          setGroupingOptionName(classType.classificationTypeName[0]);
          getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {
              
              if (data !== null && data.classificationlistItem !== null) {
                  setClassificationList(data.classificationlistItem)
                  setGroupingOption(true);
                  setGuideMe(true);
              }
              else {
                  setGroupingOption(false);
                  setGuideMe(false);
              }
          })
      }
      else {
          setGroupingOption(false);
      }
      setGuideMe(groupingOption);    
   });

   console.log("Account Details getOrgDomainConfig " + ((superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : ""))
   getOrgDomainConfig(oidcUser.access_token, userId, orgDomain, 
      (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
      (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName.toLowerCase().replace("altlogin-", "") : "",
      (superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "").then((configResult) => {
      if (configResult.isSuccess){       
        setEnablePasswordRule(configResult.config.enablePasswordRule);
        setIsPasswordRuleCapital(configResult.config.isPasswordRuleCapital)     
        setIsPasswordRuleNumbere(configResult.config.isPasswordRuleNumber)  
        setIsPasswordRuleSpecialChar(configResult.config.isPasswordRuleSpecialChar)    
      }      
    });

  }, []);

  useEffect(() => {
    if(showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      },5000)
    }
  },[showWarningModal, accountActive])

  const updateSelectedUnit = (id: number) => {
    let updatedUnits = unitsPrivileges;
    const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (unit.unitID === id) {
                unit.isSelected = !unit.isSelected
            } 
            updateSelected(unit.childUnitTreeItems);
        }
    }
    updateSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnitsPrivileges(updatedUnits);
  }

  const updateExpandedUnit = (id: number) => {
    let updatedUnits = unitsPrivileges;
    const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (unit.unitID === id) {
                unit.isExpanded = !unit.isExpanded
            } 
            checkForExpanded(unit.childUnitTreeItems)
        }
    }
    checkForExpanded(updatedUnits)
    setInteractionCount(interactionCount + 1)
    setUnitsPrivileges(updatedUnits);
  }

  const selectAllChildUnits = (id: number) => {
    let updatedUnits = unitsPrivileges;
    let parentList = [id];
    const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                parentList.push(unit.unitID)
                unit.isSelected = true;
            }
            updateAllChildUnits(unit.childUnitTreeItems)
        }
    }
    updateAllChildUnits(updatedUnits)
    setInteractionCount(interactionCount + 1)
    setUnitsPrivileges(updatedUnits);
  }

  const deselectAllChildUnits = (id: number) => {
    let updatedUnits = unitsPrivileges;
    let parentList = [id]
    const updateAllChildUnitsToUnselected = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                parentList.push(unit.unitID)
                unit.isSelected = false;
            }
            updateAllChildUnitsToUnselected(unit.childUnitTreeItems)
        }
    }
    updateAllChildUnitsToUnselected(updatedUnits)
    setInteractionCount(interactionCount + 1)
    setUnitsPrivileges(updatedUnits);
  }

  const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
    let updatedUnits = givenUnits
    const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return false //Break if no children
        if (unitArray.length === 0) return false;
        for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (unit.isSelected) {
                return true;
            }
            if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
                return true;
            }
        }
        return false;
    }
    return checkIfAnyUnitSelected(updatedUnits);
  }

  const unlockAccount = () => {
    
    if (accountLocked && passwordLockout) {
      setIsAccountMarkedUnlocked(true);
    }
    setAccountLocked(!accountLocked);
  };

  const arePasswordRulesToBeDisplayed = () => {
    // If pwd is empty and if it is already added new user and if it is pwd change not started after save, then do not display
    if (
      enteredPassword === "" && confirmEnteredPassword === "" &&
      !!userIdToEdit && parseInt(userIdToEdit) > 0 &&
      !isPasswordChangeStarted 
    ) {
      return false;
    }
    else {
      return true;
    }
  }

  useEffect(() => {      
  }, [currentAdminReportOpen, currentAdminReportResultList]);

  if (loading) return <LoaderContainer />;

  return (
    <>

      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={["Guide Me"]}>
        <span
          onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}
        >
          <GuideIcon />
        </span>
      </CustomTooltip>
      <Modal modalOpen={showModal} setModalOpen={handleModal} type="warning">        
          <div className={styles.modalContentWrapper}>
          <h2 style={{ color:"primary" , width: "500px", marginTop: "4px" }}>Send Welcome Email</h2>
          <div className="emailSubject" style={{ paddingTop: "10px" }}>
              <TextField
                className={styles.input}
                label={"Email Subject"}
                style={{ width: "99%", marginBottom: "20px" }}
                variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                value={emailSubject}
                onChange={handleEmailSubject}
                error={emailIsValid}
                placeholder="*This Field is required"
              />
            </div>
            <div className="editor" style={{ paddingTop: "10px", width: '99%', paddingBottom: '10px' }} >
              {emailReportBodyResult.map((result: any, i) => {
                if (i == 0) {
                  return (
                    <TinyMCEEditor index={i} key={"tinyMCEEditor" + i} editorData={result.body} isTemplate={false} is200Tall={false} handleTextEditorChange={handleTextEditorChange} />
                  );
                }
              })} 
            </div>
            <div className={styles.sendEmail}>
              <div className={styles.loaderContainer}>
                <CircularProgress 
                  style={{ width: isHandlerBusy ? "20px" : "0px", marginTop:'13px'}}
                  color="primary" 
                  size="10"
                  />
              </div>
              <div className="sendEmail">
                <CustomTooltip displayText={["Send Email"] /*TODO: Add translations */} >
                  <span>
                    <Button
                      onClick={handleSendEmail}
                      variant="contained"
                      color="primary"
                      id="btnNewSaltSendEmailReport">
                      <SendEmailReportIcon />
                    </Button>
                  </span>
                </CustomTooltip>
              </div>
            </div>
          </div>
      </Modal>
      <div>
        <div className={styles.unittreesection}>
          <div>
            <RadioUnits isResetClicked={isResetClicked} givenUnits={units} selectedUnitId={unitID} setSelectedUnitCallback={setSelectedUnitCallback}/>
          </div>
      { (userTypeID == 3) && (
      <div>
      <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border:'1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="unitadmin-content"
          id="unitadmin-header"
          style={{minHeight: "20px"}}
        >
          <Typography><b>Unit Administrator Privileges</b></Typography>
        </AccordionSummary>
        <AccordionDetails style={{paddingTop: "0px"}}>
          <Typography>       
            <UnitTree units={unitsPrivileges} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} ></UnitTree> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>)}

      { (userTypeID == 2) && (
                  <div
                  style={{
                    border: "1px solid #D3D6DF",
                    borderRadius: "5px",
                    padding: "10px",
                    marginTop:"20px"
                  }}
                >
                  <b>Organisation Administrator</b></div>
        )}
        </div>
        <div className={styles.accountRightSection}>
      
          
          <div style={{display:'flex', justifyContent:'center', alignItems:'center', border:'1px solid #D3D6DF', height:'42px', borderRadius:'5px'}}>
            <div>
              <p>Account ID: {userIdToUpdate}</p>
            </div>
            <div className="checkInactive">
                <Checkbox
                  size="small"
                  color="primary"
                  checked={accountActive}
                  className={styles.checkBox}
                  onClick={() => setAccountActive(!accountActive)}
                />{" "}
                <span className={accountActive ? styles.accountActiveText : styles.accountInactiveText} >{accountActive ? "Account Active" : "Account Inactive"}</span>
              </div>
              {passwordLockout && <div className="checkLocked">
                <Checkbox
                  size="small"
                  color="primary"
                  checked={accountLocked}
                  className={styles.checkBox}
                  onClick={() => unlockAccount()}
                />{" "}
                <span className={accountLocked ? styles.accountInactiveText : styles.accountActiveText} >{accountLocked ? "Account Locked" : "Account Unlocked"}</span>
              </div>
          }
          </div>
          <p
            style={{
              fontWeight: 700,
              color: "#D3D6DF",
              fontSize: "14px",
              fontStyle: "italic",
              marginTop: "5px",
            }}
          >
            Enter the account details below
          </p>
          <div className={styles.firstNameInput}>
            <div className="firstName">
              <TextField
                className={styles.input}
                label={"First Name"}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={filterFirstName}
                onChange={(e) => setFilterFirstName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className="lastName">
              <TextField
                className={styles.input}
                label={"Last Name"}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={filterLastName}
                onChange={(e) => setFilterLastName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className="userName">
              <TextField
                className={styles.input}
                label={"Username"}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={filterUserName}
                onChange={(e) => setFilterUserName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className="email">
              <TextField
                className={styles.input}
                label={"Email"}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={filterEmail}
                onChange={(e) => setFilterEmail(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.inputContainer}>
            <div className="externalID">
              <TextField
                className={styles.input}
                label={"External ID"}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={filterExternalId}
                onChange={(e) => setFilterExternalId(e.target.value)}
              />
            </div>
          </div>
          {groupingOption && 
            <div className="groupby">
              <div className={styles.groupingOption} >
                  <FormControl variant="outlined" className={styles.input}>                      
                      <InputLabel id="grouping">{groupingOptionName}</InputLabel>
                          <Select
                          value={selectedClassification.value}
                          native
                          onChange={(e) => setSelectedClassification({value: e.target.value})}
                          className={styles.input}
                          variant="outlined" 
                          label={groupingOptionName}
                          id="grouping"
                          >
                          {classificationList.map((item:any) => {
                              return <option key={'select'+ item.value} value={item.value}>{item.text}</option>
                          })}
                      </Select>
                  </FormControl>
              </div>
            </div>
          }  
          <div style={{ width: "100%", clear: "both"}}>
            <div style={{ minWidth: "300px", float:"left", paddingRight: "20px" }}>
            <div className={styles.inputContainer}>
                <TextField
                  name="password"
                  className={`${styles.input} password`}
                  type="password"
                  label={allResourcesToTranslations.newpassword}
                  variant="outlined"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword();
                    setIsPasswordChangeStarted(!!e.target.value && e.target.value !== "" && !!confirmEnteredPassword && confirmEnteredPassword !== "");
                  }}
                  value={enteredPassword}
                  error={passwordError}
                  autoComplete="off"
                  InputProps={{
                    readOnly: false,
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={styles.inputContainer}>
                <TextField
                  name="confirmPassword"
                  className={`${styles.input} confirmPassword`}
                  type="password"
                  label={allResourcesToTranslations.confirmnewpassword}
                  variant="outlined"
                  error={passwordError}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    validatePassword();
                    setIsPasswordChangeStarted(!!e.target.value && e.target.value !== "" && !!enteredPassword && enteredPassword !== "");
                  }}
                  value={confirmEnteredPassword}
                  helperText={passwordErrorMessage}
                  autoComplete="off"
                  InputProps={{
                    readOnly: false,
                    style: {
                      backgroundColor: "white",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <CustomTooltip displayText={["reset"]}>
                  <span>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={styles.restartButton}
                      onClick={handleResetPassword}
                      style={{padding: "7px 15px 3px 15px"}}
                    >
                      <RestartIcon />
                    </Button>
                  </span>
                </CustomTooltip>
                <CustomTooltip displayText={["Generate Random Password"]}>
              <span>
                <Button
                  size="small"
                  onClick={handleGeneratePassword}
                  variant="contained"
                  color="primary"
                >
                  <RandomIcon />
                </Button>
              </span>
            </CustomTooltip>
              </div>
            </div>
            {enablePasswordRule && arePasswordRulesToBeDisplayed() && <div
              style={{ minWidth: "250px", float:"left" }}
              className="passwordRequirements"
            >
              <div className={styles.inputContainer}>
                <span className={styles.passwordFinePrint}>
                  Password Requirements
                </span>
              </div>
              <div className={styles.passwordChecklist}>
                <PasswordChecklist
                  rules={getRulesArray()}
                  minLength={8}
                  maxLength={16}
                  value={enteredPassword}
                  iconComponents={{
                    ValidIcon: <PasswordValidIcon />,
                    InvalidIcon: <PasswordInValidIcon />,
                  }}
                  valueAgain={confirmEnteredPassword}
                  onChange={(isValid: boolean) => {
                    setIsPasswordValid(arePasswordRulesToBeDisplayed() ? isValid : true);
                  }}
                  messages={{
                    minLength: `${allResourcesToTranslations.passwordminlength}`,
                    maxLength: `${allResourcesToTranslations.passwordmaxlength}`,
                    capital: `${allResourcesToTranslations.passworduppercase}`,
                    number: `${allResourcesToTranslations.passwordnumber}`,
                    specialChar: `${allResourcesToTranslations.passwordspecial}`,
                    match: `${allResourcesToTranslations.passwordmatch}`,
                  }}
                />
              </div>
            </div>}
          </div>
          <div style={{ clear: "both" }}></div>
        <div>
        <Accordion style={{ margin: "5px 0px", borderRadius: "6px 6px 6px 6px", border:'1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }} expanded={expandManagerSetting}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="managerSettings-content"
          id="managerSettings-header"
          onClick={(e) =>  setExpandManagerSetting(!expandManagerSetting)}
        >
          <Typography className="typographyFonts"><b>Manager Settings</b></Typography>
        </AccordionSummary>
        <AccordionDetails style={{paddingTop: "0px", display: "block"}}>
          <Typography className="typographyFonts" style={{display: "inline"}}>
          <div className="notifyManager">
              <Checkbox
                size="small"
                color="primary"
                checked={notifyManager}
                className={styles.checkBox}
                onClick={() => setNotifyManager(!notifyManager)}
              />{" "}
              {"Notify Manager of overdue items by email"}
            </div>
            <div className={styles.inputContainer}>
              <div>
                <TextField
                  className={styles.input}
                  label={"Manager Email"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={managerEmail}
                  onChange={(e) => setManagerEmail(e.target.value)}
                />
              </div>
            </div>

            <div  className="emailAlert">
              <Checkbox
                size="small"
                color="primary"
                checked={alertUnitAdmin}
                className={styles.checkBox}
                onClick={() => setAlertUnitAdmin(!alertUnitAdmin)}
              />{" "}
              {"Send email alerts to Unit Admin"}
            </div>
            <div  className="alertOrgAdmin">
              <Checkbox
                size="small"
                color="primary"
                checked={alertOrgAdmin}
                className={styles.checkBox}
                onClick={() => setAlertOrgAdmin(!alertOrgAdmin)}
              />{" "}
              {"Send email alerts to Org Admin"}
            </div>
            {IsEbookEnabled && <div className="sendEbook">
              <Checkbox
                size="small"
                color="primary"
                checked={sendEbook}
                className={styles.checkBox}
                onClick={() => setSendEbook(!sendEbook)}
              />{" "}
              {"Send email about new version of Ebook"}
            </div>}
          </Typography>
        </AccordionDetails>
      </Accordion>
      </div>
          <div style={{ marginTop: "20px" }} className="timezone">
            <MUICustomSelect
              idName={"timezoneOption"}
              labelName={"Timezone"}
              currentValue={selectedTimezone.value}
              setCurrentValue={(e: any) => {
                if (!!e.target) {
                  setSelectedTimezone({
                    value: e.target.value,
                    text: (e.target as HTMLSelectElement).options[
                      (e.target as HTMLSelectElement).selectedIndex
                    ].text,
                  });
                } else {
                  let filtered = !!timezoneList
                    ? timezoneList.filter(
                        (cur: any) =>
                          !!cur && parseInt(cur.value) === parseInt(e)
                      )
                    : null;
                  if (!!filtered && filtered.length > 0) {
                    setSelectedTimezone({
                      value: filtered[0].value,
                      text: filtered[0].text,
                    });
                  }
                }
              }}
              isFocussed={isTimezoneOptionFocused}
              setIsFocussed={setIsTimezoneOptionFocused}
              inputLabelStyle={{}}
              listOfItems={timezoneList}
            />
          </div>
          <div style={{marginTop:'14px'}} className="lastLoginDetails">
              <table>
                <tbody>
                  <tr style={{height:'30px'}}>
                      <td style={{fontSize:'14px', fontWeight:700, color:'#767676'}}>{allResourcesToTranslations.createdby}:</td>
                      <td style={{paddingLeft:'10px'}}>{createdByName} on {dateCreated} </td>
                  </tr>                    
                  <tr style={{height: showLastUpdatedRow ? '30px' : '0px'}}>
                      <td style={{fontSize:'14px', fontWeight:700, color:'#767676'}}>{showLastUpdatedRow ? allResourcesToTranslations.lastmodifiedby :''} </td>
                      <td style={{paddingLeft:'10px'}}>{showLastUpdatedRow ? `${updatedByName} on ${dateUpdated}` :'' } </td>
                  </tr> 
                  <tr style={{height:'30px'}}>
                      <td style={{fontSize:'14px', fontWeight:700, color:'#767676', paddingRight:'10px'}}>Last Logon:</td>
                      <td style={lastLoginDateTime == 'Never' ? {color: '#F9A23B', paddingLeft:'10px'}: {paddingLeft:'10px'}}>{lastLoginDateTime}</td>
                  </tr>
                  </tbody>
              </table>
          </div>
          <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px"  }}>
            <CustomTooltip displayText={["Send Welcome Email"] /*TODO: Add translations */} >
              <span className="sendWelcomeEmail">
                <Button
                disabled={!accountActive}
                  onClick={handleSendWelcomeEmail}
                  variant="contained"
                  color="primary"
                  id="btnNewSaltSendEmailReport">
                  <AddEmailAccountIcon />
                </Button>
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={["Save"]}>
              <span  className="saveData">
                <Button
                  disabled={
                    isSaving ||
                    ((enteredPassword !== "" || confirmEnteredPassword !== "") &&
                      !isPasswordValid) || (enteredPassword !== confirmEnteredPassword)
                      || filterEmail == "" || filterFirstName == "" || filterLastName == "" || filterUserName == "" || selectedTimezone.value == 0 
                  }
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <SaveIcon />
                </Button>
              </span>
            </CustomTooltip>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                color="primary"
                size="10"
              />
            </div>
            <div style={{ paddingTop: "20px"}}>
          </div>
          </div>
          </div>         
          {
            !!loadedUser && loadedUser.active &&
            <div id="carForSpecificAccount">
              <Accordion style={{ margin: "5px 0px", borderRadius: "6px 6px 6px 6px", position: 'inherit', boxShadow: "none" }} expanded={expandCurrentAdminReportList}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="managerSettings-content"
                  id="managerSettings-header"
                  onClick={(e) => handleCARExpandClick()}
                >
                  <Typography className="typographyFonts"><b>{allResourcesToTranslations.currentadminreport}</b></Typography>
                </AccordionSummary>
                <AccordionDetails style={{padding: "0px"}}>              
                    {currentAdminReportOpen && currentAdminReportResultList != null && currentAdminReportResultList.length > 0 && !!loadedUser && loadedUser.active && (
                      <div id="currentAdminREportList" style={{width: "100%"}}>
                        <CurrentAdminReportSection  currentAdminReportData={currentAdminReportResultList}
                                                      givenFirstName={loadedUser.firstName} 
                                                      givenLastName={loadedUser.lastName} 
                                                      givenUserName={loadedUser.userName} 
                                                      givenEmail={loadedUser.email} 
                                                      givenResultError={""} 
                                                      givenIncludeInactive={loadedUser.active} 
                                                      givenDateTo={currentAdminReportResultList[0].effectiveDateOrg} 
                                                      givenIsInputHistoricData={false} 
                                                      givenReportTitle={"Current Admin Report"} 
                                                      givenExportReportTitle={"CurrentAdminReport"} />
                      </div>
                    )}         
                </AccordionDetails>             
              </Accordion>
            </div>
          }
      </div>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};

export default AccountDetails;
