import React, { useState, useEffect, useContext, useRef } from "react";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";

import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import { useHistory, useParams } from "react-router";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import BrandedCard from "../../../Components/BrandedCard";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import { CourseTreeItem } from "../../../globalTypes";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Courses from "../../ReportCommonComponents/Courses";
import getCourseListByOrganisation from "../../../API/GetCourseListByOrganisation";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, TextField, Typography } from "@material-ui/core";
import SaveOrgEmailSetup from "../../../API/Administration/Organisation/SaveOrgEmailSetup";

import EmailReminderTimeline from "../EmailSettings/EmailReminderTimeline";
import CourseProgressBar from "./CourseProgressBar";
import getOrgMailSetup from "../../../API/Administration/Organisation/GetOrgMailSetup";
import GetCourseDetails from "../../../API/Administration/CourseSettings/GetCourseDetails";
import ToggleRightIcon from "../../../CustomIcons/ToggleRightIcon";
import { ToggleLeftIcon } from "../../../CustomIcons/ToggleLeftIcon";


const CourseReminderEmailRulesAdd = (props: any) => {
  const { courseIdToEdit } = useParams<{ courseIdToEdit: string }>();
  const { remescIdToUpdate } = useParams<{ remescIdToUpdate: string }>();
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);

  const isMounted = useRef<boolean>(false);
  const { setTitle, setTitleEnglish, setMenuSelection, updateBackButtonRoute, setEmailSettingsRuleInputProps,superAdminSelectedBasicOrgData  } =
    useContext(UIContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedReminderType, setSelectedReminderType] = useState<string>('c');

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [courseName, setCourseName] = useState<string>("");


  const [initialEnrolmentEnable, setInitialEnrolmentEnable] = useState<boolean>(false);
  const [initialenrolmentnumber, setInitialenrolmentnumber] = useState<number>(0);
  const [initialenrolmentnumberText, setinitialenrolmentnumberText] = useState("0");

  const [preExpiryEnable, setPreExpiryEnable] = useState<boolean>(false);
  const [preExpirynumber, setPreExpirynumber] = useState<number>(0);
  const [preExpirynumberText, setPreExpirynumberText] = useState("0");
  const [preExpiryInitialEnrolment, setPreExpiryInitialEnrolment] = useState<boolean>(false);
  const [preExpiryResitPeriod, setPreExpiryResitPeriod] = useState<boolean>(false);

  const [postExpiryEnable, setPostExpiryEnable] = useState<boolean>(false);
  const [postExpirynumber, setPostExpirynumber] = useState<number>(1);
  const [postExpirynumberText, setPostExpirynumberText] = useState("1");
  const [postExpirynumberdays, setPostExpirynumberdays] = useState<number>(1);
  const [postExpirynumberdaysText, setPostExpirynumberdaysText] = useState("1");
  const [postExpiryInitialEnrolment, setPostExpiryInitialEnrolment] = useState<boolean>(false);
  const [postExpiryResitPeriod, setPostExpiryResitPeriod] = useState<boolean>(false);

  const [managerEnable, setManagerEnable] = useState<boolean>(false);
  const [managerExpirynumber, setManagerExpirynumber] = useState<number>(1);
  const [managerExpirynumberText, setManagerExpirynumberText] = useState("1");
  const [managerCumulativeEnable, setManagerCumulativeEnable] = useState<boolean>(false);

  const [expandExample, setExpandExample] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  //Course / Policy / Certificate List
  const [courseIds, setCourseIds] = useState<Array<number>>([]);
  const [filterCourseName, setFilterCourseName] = useState("");
  const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([]);  
  
  const [isCourseModuleAccess, setIsCourseModuleAccess] = useState<boolean>(false);
  const [isRemEmails,setIsRemEmails ] = useState<boolean>(false);	
  const [isUnitRule, setIsUnitRule ] = useState<boolean>(false);
  const [reminderID, setreminderID] = useState<number>(0);
  const [includeOptional, setIncludeOptional] = React.useState<boolean>(false);
 
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".inactiveoptional",
        title: "Enable  Reminder Emails ",
        intro: "Check to Enable  Reminder Emails.",
      },

    ]
  })


  const setGuideMe = (includeOptional: boolean = false) => {
    let updateSteps = []
  
    if(includeOptional)
    {
   // if ((courseIdToEdit == null || courseIdToEdit == undefined) && (remescIdToUpdate == null || remescIdToUpdate == undefined) && (selectedReminderType !== null && selectedReminderType !== undefined && selectedReminderType.trim() === 'c')) {
    if ((remescIdToUpdate == null || remescIdToUpdate == undefined)) {
        updateSteps.push(        
          {
          element: ".courseFilterButtons",
          title: allResourcesToTranslations.emrepgmcst, intro: allResourcesToTranslations.emrepgmcsm
        },
        {
          element: ".courseFilterSearch",
          title: allResourcesToTranslations.emrepgmfcst, intro: allResourcesToTranslations.emrepgmfcsm
        },
        {
          element: ".courseTree",
          title: allResourcesToTranslations.courselist, intro: allResourcesToTranslations.courselistinfo,
        });
    }

     
    if(selectedReminderType !== null && selectedReminderType !== undefined && selectedReminderType.trim() === 'c') {
      updateSteps.push(
        {
          element: ".guidemeinitialEnrolment",
          title: allResourcesToTranslations.chkinitialenrolment, 
          intro: allResourcesToTranslations.guidemeinitialenrolment
        });        
      updateSteps.push(          
      {
        element: ".guidemepreexpiry",
        title: allResourcesToTranslations.preexpiryreminder, intro: allResourcesToTranslations.guidemepreexpiry
      },
      {
        element: ".guidemePreexpiryinitial",
        title: allResourcesToTranslations.preexpiryreminder, intro: allResourcesToTranslations.guidemepreexpiryinitial
      },
      {
        element: ".guidemePreexpiryResitPeriod",
        title: allResourcesToTranslations.preexpiryreminder, intro: allResourcesToTranslations.guidemepreexpiryresitperiod
      },
      {
        element: ".guidemePostExpiry",
        title: allResourcesToTranslations.postexpiryreminder, intro: allResourcesToTranslations.guidemepostexpiry
      },
      {
        element: ".guidemePostExpiryinitial",
        title: allResourcesToTranslations.postexpiryreminder, intro: allResourcesToTranslations.guidemepostexpiryinitial
      },
      {
        element: ".guidemePostexpiryResitPeriod",
        title: allResourcesToTranslations.postexpiryreminder, intro: allResourcesToTranslations.guidemepostexpiryresitperiod
      },
      {
        element: ".guidemeManage",
        title: allResourcesToTranslations.managernotifications, intro: allResourcesToTranslations.guidememanage
      },
      {
        element: ".guidemeManagecumulative",
        title: allResourcesToTranslations.managernotifications, intro: allResourcesToTranslations.guidememanagecumulative
      }
    );
    }  
    updateSteps.push(      
      {
        element: ".saveData",
        title: "Save Details",
        intro: allResourcesToTranslations.coursesave
      },
      {
          element: ".nextData",
          title: "Move to Next",
          intro: allResourcesToTranslations.coursenext
        });
      }
      else
      {
        updateSteps.push( 
          {
            element: ".inactiveoptional",
            title: "Enable  Reminder Emails ",
            intro: "Check to Enable  Reminder Emails.",
          });
       }
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  useEffect(() => {
    isMounted.current = true;
    setTitle(allResourcesToTranslations.publishcourse +  " - " +allResourcesToTranslations.step4);
    setTitleEnglish(pageTitles.publishcourse);
    updateBackButtonRoute("");  
    setMenuSelection(allResourcesToTranslations.publishcourse);  
    setEmailSettingsRuleInputProps({
      selectedReminderType: selectedReminderType.trim()
    });   
    LoadCourseDetail();
    setLoading(false);
    // if ((courseIdToEdit == null || courseIdToEdit == undefined) && (remescIdToUpdate == null || remescIdToUpdate == undefined)) {
      if ((remescIdToUpdate == null || remescIdToUpdate == undefined)) {
        if (selectedReminderType !== null && selectedReminderType !== undefined) {
          if (selectedReminderType.trim() === 'c') {
            getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
              if (courseListRes.isSuccess) {
                let currCourseList = courseListRes.courselistItem.filter((item: any) => item.courseID === parseInt(courseIdToEdit));
       
                setCourseList(currCourseList)
                setGuideMe(false);
              }
            })
          }   
        }
      }
      else {
        getOrgMailSetup(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedReminderType.trim() === 'c' ? parseInt(courseIdToEdit) : null, null, null, selectedReminderType.trim()).then((result) => {
          if (result.isSuccess) {
            setIncludeOptional(true);
            setCourseName(result.orgEmailSetupDetail.courseName)
            setCourseIds(result.orgEmailSetupDetail.courseId);      
            setInitialEnrolmentEnable(result.orgEmailSetupDetail.remindUsers);
            setInitialenrolmentnumber(result.orgEmailSetupDetail.daysToCompleteCourse);
            //setInitialenrolmentnumber(result.orgEmailSetupDetail.daysToCompleteCourse == 0 ? "" : result.orgEmailSetupDetail.daysToCompleteCourse)            
  
            setPreExpiryEnable(result.orgEmailSetupDetail.quizExpiryWarn)
            setPreExpirynumber(result.orgEmailSetupDetail.daysQuizExpiry)
            //setPreExpirynumber(result.orgEmailSetupDetail.daysQuizExpiry == 0 ? "" : result.orgEmailSetupDetail.daysQuizExpiry)            
            setPreExpiryInitialEnrolment(result.orgEmailSetupDetail.preExpInitEnrolment)
            setPreExpiryResitPeriod(result.orgEmailSetupDetail.preExpResitPeriod)
  
  
            setPostExpiryEnable(result.orgEmailSetupDetail.postExpReminder)
            setPostExpirynumber(result.orgEmailSetupDetail.numOfRemNotfy)
            //setPostExpirynumber(result.orgEmailSetupDetail.numOfRemNotfy == 0 ? "" : result.orgEmailSetupDetail.numOfRemNotfy)
            setPostExpirynumberdays(result.orgEmailSetupDetail.repeatRem)
            //setPostExpirynumberdays(result.orgEmailSetupDetail.repeatRem == 0 ? "" : result.orgEmailSetupDetail.repeatRem)
            setPostExpiryInitialEnrolment(result.orgEmailSetupDetail.postExpInitEnrolment)
            setPostExpiryResitPeriod(result.orgEmailSetupDetail.postExpResitPeriod)
  
            setManagerEnable(result.orgEmailSetupDetail.notifyMgr)
            setManagerExpirynumber(result.orgEmailSetupDetail.notifyMgrDays)
            setManagerCumulativeEnable(result.orgEmailSetupDetail.isCumulative)
  
            if (selectedReminderType.trim() !== 'c')
            {           
              if (result.orgEmailSetupDetail.quizExpiryWarn) {
                setPreExpiryInitialEnrolment(true);
              }
              if (result.orgEmailSetupDetail.postExpReminder) {
                setPostExpiryInitialEnrolment(true);
              }            
            }
          }
        })
        setGuideMe(false);
      }   
  }, []); 

  useEffect(() => {
    if (courseList !== null && courseList !== undefined && courseList.length > 0) {
      var newCourses = courseList.map(function (e) {
        if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === "") {
          e.isHidden = false;
        }
        else {
          e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase()));
        }
        return e;
      });
      setCourseList(newCourses);
    }
  }, [filterCourseName])

  const handleinitialenrolmentnumber = (e: any) => {

    if (isNaN(Number(e.target.value))) {
      return false;
    }

    if(e.target.value === '') {
      setinitialenrolmentnumberText('');
      setInitialenrolmentnumber(0);
    }
    else {
      setinitialenrolmentnumberText(e.target.value);
      setInitialenrolmentnumber(Number(e.target.value));
      if (Number(e.target.value) > 365) {
        setInitialenrolmentnumber(365);
      }
      if (Number(e.target.value) < 1) {
        setInitialenrolmentnumber(1);
      }
    }
  }
  const handlepreExpirynumber = (e: any) => {
    if (isNaN(Number(e.target.value))) {
      return false;
    }
    
    if(e.target.value === '') {
      setPreExpirynumberText('');
      setPreExpirynumber(0);
    }
    else {
      setPreExpirynumberText(e.target.value);
      setPreExpirynumber(Number(e.target.value));
      if (Number(e.target.value) > 365) {
        setPreExpirynumber(365);
      }
      if (Number(e.target.value) < 1) {
        setPreExpirynumber(1);
      }
    }
  }
  const handlepostExpirynumber = (e: any) => {
    if (isNaN(Number(e.target.value))) {
      return false;
    }
    if(e.target.value === '') {
      setPostExpirynumberText('');
      setPostExpirynumber(0);
    }
    else {
      setPostExpirynumberText(e.target.value);
      setPostExpirynumber(Number(e.target.value));
      if (Number(e.target.value) > 100) {
        setPostExpirynumber(100);
      }
      if (Number(e.target.value) < 1) {
        setPostExpirynumber(1);
      }
    }
  }
  const handlepostExpirynumberdays = (e: any) => {
    if (isNaN(Number(e.target.value))) {
      return false;
    }

    if(e.target.value === '') {
      setPostExpirynumberdaysText('');
      setPostExpirynumberdays(0);
    }
    else {
      setPostExpirynumberdaysText(e.target.value);
      setPostExpirynumberdays(Number(e.target.value));
      if (Number(e.target.value) > 365) {
        setPostExpirynumberdays(365);
      }
      if (Number(e.target.value) < 1) {
        setPostExpirynumberdays(1);
      }
    }
  }
  const handlemanagerExpirynumber = (e: any) => {
    if (isNaN(Number(e.target.value))) {
      return false;
    }

    if(e.target.value === '') {
      setManagerExpirynumberText('');
      setManagerExpirynumber(0);
    }
    else {
      setManagerExpirynumberText(e.target.value);
      setManagerExpirynumber(Number(e.target.value));
      if (Number(e.target.value) > 365) {
        setManagerExpirynumber(365);
      }
      if (Number(e.target.value) < 1) {
        setManagerExpirynumber(1);
      }
    }
  }

 

  const setUpdatedPastCourseList = () => {
    let courseIds: Array<number> = []
    for (let i = 0; i < courseList.length; i++) {
      if (courseList[i].isSelected) {
        courseIds.push(courseList[i].courseID)
      }
    }
    setCourseIds(courseIds)
  }


  const LoadCourseDetail = () => {	  
		GetCourseDetails(oidcUser.access_token,
			userId,
			orgDomain,
			parseInt(orgId),
			parseInt(courseIdToEdit),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
				if (result != null && result.isSuccess && result.error == null) {				
					if (result != null && result.isSuccess && result.error == null) {						
						if (result != null && result.isSuccess && result.error == null) {  
                            if (result.getCourseDetailsItems[0].unitIds.length > 0) {
                              setIsCourseModuleAccess(true);
                            }
                            if (result.getCourseDetailsItems[0].remEscId    > 0)
                            {
                                setreminderID(result.getCourseDetailsItems[0].remEscId                                );
                                setIsRemEmails(true);                               
                            }    
                            if (result.getCourseDetailsItems[0].unitRuleId > 0)
                            {
                                setIsUnitRule(true);
                                
                            }
                        }
					}
				}
			});
	}


  const handleSave = () => {

    let errorString = '';

    if (initialEnrolmentEnable && initialenrolmentnumberText === "" && initialenrolmentnumber < 1) {
      errorString = errorString + 'Please enter valid Initital Enrolment period;'
    }

    if (preExpiryEnable && preExpirynumberText === "" && preExpirynumber < 1) {
      errorString = errorString + 'Please enter valid pre expiry Date;'
    }

    if (postExpiryEnable && postExpirynumberText === "" && postExpirynumber < 1) {
      errorString = errorString + 'Please enter valid post expiry Date;'
    }

    if (postExpiryEnable && postExpirynumberdaysText === "" && postExpirynumberdays < 1) {
      errorString = errorString + 'Please enter valid post expiry Days;'
    }

    if (managerEnable && managerExpirynumberText === "" && managerExpirynumber < 1) {
      errorString = errorString + 'Please enter valid manager notification day;'
    }

    if (errorString !== '') {
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      setSaveSuccess(false);
      return;
    }

    setIsSaving(false);
    let curIdToUpdate = "";
    let curremescId = 0;

    

    //Add New Rule
    if (selectedReminderType.trim() === 'c' && courseIds.length <= 0) {
      setShowWarningModal(true);
      setHasErrored(true);
      setSaveSuccess(false);
      setModalPopupMessage(allResourcesToTranslations.selectcourse);
    }   
    else {
      // Add Rule
     if ((remescIdToUpdate == null || remescIdToUpdate == undefined)) {
        curIdToUpdate =  courseIds.toString();
        curremescId = 0;
      }
      else {
        //Update Rule
        curIdToUpdate = courseIdToEdit
        curremescId = parseInt(remescIdToUpdate)
      }
      SaveOrgEmailSetup(oidcUser.access_token, userId, orgDomain, parseInt(orgId), curremescId, selectedReminderType.trim() === 'c' ? curIdToUpdate : '', '', '', selectedReminderType.trim(),
        initialenrolmentnumber, initialEnrolmentEnable, preExpiryEnable,
        preExpirynumber, selectedReminderType.trim() !== 'c' ? true : preExpiryInitialEnrolment, preExpiryResitPeriod, postExpiryEnable, postExpirynumber,
        postExpirynumberdays, selectedReminderType.trim() !== 'c' ? true : postExpiryInitialEnrolment, postExpiryResitPeriod, managerEnable,
        managerExpirynumber, managerCumulativeEnable).then((result) => {
          if (result.isSuccess) {
            setShowWarningModal(true);
            setHasErrored(false);
            setSaveSuccess(true);
            setModalPopupMessage(allResourcesToTranslations.successrulemessage);                 
          }
        })

    }
  }
  const handleNext = () => {      
    if (!isUnitRule)
      {
          history.push({
              pathname: `/coursecompliancerules/` + courseIdToEdit
          });
      }
  else
  {
      history.push({
          pathname: `/coursesummary/` + courseIdToEdit
      });
  }
  }
  useEffect(() => {
    if (initialEnrolmentEnable && initialenrolmentnumber < 1 && initialenrolmentnumberText !== '') {
      setInitialenrolmentnumber(1);
    }

      if (preExpiryEnable) {
        if (preExpirynumber < 1 && preExpirynumberText !== '') {
          setPreExpirynumber(1);
        }
        if (!preExpiryInitialEnrolment && (selectedReminderType.trim() === 'p' || selectedReminderType.trim() === 'e')) {
          setPreExpiryInitialEnrolment(true);
        }
      }
      if (postExpiryEnable) 
      {
          if (!postExpiryInitialEnrolment && (selectedReminderType.trim() === 'p' || selectedReminderType.trim() === 'e')) {
            setPostExpiryInitialEnrolment(true);
          }
      }
  }, [initialEnrolmentEnable, preExpiryEnable, preExpiryInitialEnrolment, postExpiryEnable, postExpiryInitialEnrolment, managerEnable, initialenrolmentnumber, preExpirynumber, postExpirynumber, postExpirynumberdays, managerExpirynumber]);
 
  const BackButtonRoute = () => {

    if(!isCourseModuleAccess)
    {
        history.push({
            pathname: `/assigncourseaccess/` + courseIdToEdit
        });
    }
    else
    { 
      history.push({  
          pathname: `/courserecourse/` + courseIdToEdit
      });
    }

  }     

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecificaddemailrule}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <div style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}>     
             <CourseProgressBar courseIdToEdit={parseInt(courseIdToEdit)} step='4' />
      </div>
      <BrandedCard>
      <div style={{ paddingLeft: '20px', paddingTop: '15px', fontWeight: 'bold' }} >
            <div onClick={(e) => { setIncludeOptional(!includeOptional); setGuideMe(!includeOptional); }} style={{ float: 'left' }} className='inactiveoptional'>
              <span style={{ marginTop: "-3px", paddingRight: "5px", float: "left" }} >{includeOptional ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
            </div>
            <div style={{ float: 'left' }}>
              <span id="allowLearnerswitch" className={styles.labelText}
                onClick={(e) => { setIncludeOptional(!includeOptional); }}
              >
                &nbsp;&nbsp;<b>{allResourcesToTranslations.step4} (Optional)</b></span>
            </div>
          </div>
          {includeOptional &&
            <div className={styles.Wrapper} style={{ paddingTop: '35px' }}>

      {/* CourseList Start  */}
      <UnbrandedCard>
        <div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
          <div className={styles.Wrapper}>

            { (parseInt(courseIdToEdit) > 0  && parseInt(remescIdToUpdate) > 0 )?
              <>
                <div className={styles.lableContainer}>
                {selectedReminderType.trim() === 'c' && <span className={styles.labelText}>{"1. " + allResourcesToTranslations.selectedcourse}</span>}
                </div><div style={{ justifyContent: "space-between", paddingTop: "20px" }}>
                  <span className={styles.labelText}>{courseName}</span>
                </div>
              </>
              :
              <>
                <div className={styles.lableContainer}>
                {selectedReminderType.trim() === 'c' && <span className={styles.labelText}>{"1. " + allResourcesToTranslations.addpublishcourse}</span>}
               
                </div><div className={styles.inputContainer}>
                {selectedReminderType.trim() === 'c' && <span className={styles.labelTextitalic}>{allResourcesToTranslations.applytoselectedcourse}</span>}
                
                </div><div style={{ paddingTop: "20px" }}>
                  {selectedReminderType.trim() === 'c' &&
                    <BrandedCard>
                      <div style={{ justifyContent: "space-between", padding: "20px" }}>
                        <Courses isResetClicked={true} givenCourses={courseList} hasSmallUnits={false} setUpdatedPastCourses={setUpdatedPastCourseList} CourseTitle={allResourcesToTranslations.courseenrolments} />
                      </div>
                    </BrandedCard>
                  }                 
                </div>
              </>
            }
          </div>
        </div>
      </UnbrandedCard>


      {/* Initial Enrollment Start  */}
      <div style={{ marginTop: "5px" }}>&nbsp;</div>
      <UnbrandedCard>
        <div className={`${styles.mainWrapper} guidemeinitialEnrolment`} style={{ marginTop: "0px" }}>
          <div className={styles.Wrapper}>
            <div className={styles.lableContainer} >
              <span className={styles.labelText}>{"2. " + allResourcesToTranslations.initialenrolmentreminder}</span>
            </div>
            <div className={styles.inputContainer}>
              <span className={styles.labelTextitalic}>{(allResourcesToTranslations.initialenrolmentremindernotes)}</span>
            </div>
            <div className={responsivestyles.rowrule} style={{ width: '100%' }} >
              <div className={`${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_12}`} >
                <Checkbox
                  size="small"
                  color="primary"
                  checked={initialEnrolmentEnable}
                  className={styles.checkBox}
                  onClick={() => {
                    setInitialEnrolmentEnable(!initialEnrolmentEnable);
                    if (!initialEnrolmentEnable) {
                      setPreExpiryInitialEnrolment(false);
                      setPostExpiryInitialEnrolment(false);
                    }
                  }
                  }
                />
                <span className={styles.labelText}>{allResourcesToTranslations.enableemailrule}</span>
              </div>
              <div className={`${responsivestyles.columnrule_9} ${responsivestyles.columnrule_s_12}`} >
                <div className={styles.inputContainer}>
                  <TextField
                    disabled={!initialEnrolmentEnable}
                    className={styles.input}
                    style={{ width: "100%", marginBottom: "15px" }}
                    label={allResourcesToTranslations.initialenrolmentnumber}
                    name="initialenrolmentnumber"
                    inputProps={{ maxLength: 3 }}
                    variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                    value={initialEnrolmentEnable ? (initialenrolmentnumberText === '' ? '' : initialenrolmentnumber) : 0}
                    type="text"
                    onChange={(e) => handleinitialenrolmentnumber(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UnbrandedCard>


      {/* Pre ExpiryNotification Start  */}
      <div style={{ marginTop: "5px" }}>&nbsp;</div>
      <UnbrandedCard>
        <div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
          <div className={styles.Wrapper}>
            <div className={styles.lableContainer}>
              <span className={styles.labelText}>{"3. " + allResourcesToTranslations.preexpiryreminderemails}</span>
            </div>
            <div className={styles.inputContainer}>
              <span className={styles.labelTextitalic}>{allResourcesToTranslations.preexpiryreminderemailsnotes }</span>
            </div>
            <div className={`${responsivestyles.rowrule} guidemepreexpiry`} style={{ width: '100%' }} >
              <div className={`${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_12} `} >
                <Checkbox
                  size="small"
                  color="primary"
                  checked={preExpiryEnable}
                  className={styles.checkBox}
                  onClick={() => {
                    setPreExpiryEnable(!preExpiryEnable);
                    if (!preExpiryEnable) {
                      setPreExpiryInitialEnrolment(false);
                      setPreExpiryResitPeriod(false);
                    }
                  }
                  }
                />
                <span className={styles.labelText}>{allResourcesToTranslations.enableemailrule}</span>
              </div>
              <div className={`${responsivestyles.columnrule_9} ${responsivestyles.columnrule_s_12} `} >
                <div className={styles.inputContainer}>
                  <TextField
                    disabled={!preExpiryEnable}
                    className={styles.input}
                    style={{ width: "100%", marginBottom: "15px" }}
                    label={allResourcesToTranslations.preexpirynumber }
                    name="preExpirynumber"
                    inputProps={{ maxLength: 3 }}
                    variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                    value={preExpiryEnable ? (preExpirynumberText === '' ? '' : preExpirynumber) : 0}
                    type="text"
                    onChange={(e) => handlepreExpirynumber(e)}
                  />

                </div>
              </div>
            </div>
            {selectedReminderType.trim() === 'c' &&
            <>
            <div className={styles.lableContainer} style={{ width: '100%', paddingTop: '5px' }}>
              <span>{allResourcesToTranslations.applyto}</span>
            </div>
            <div className={styles.inputContainer}>
              <span className={styles.labelTextitalic}>{allResourcesToTranslations.preexpiryduedate}</span>
            </div>
            <div style={{ width: '100%', paddingTop: '5px' }} >
              <Checkbox
                size="small"
                color="primary"
                disabled={!(preExpiryEnable && initialEnrolmentEnable)}
                checked={(preExpiryInitialEnrolment && preExpiryEnable && initialEnrolmentEnable)|| (selectedReminderType.trim() !== 'c')}
                className={`${styles.checkBox} guidemePreexpiryinitial`}
                onClick={() => setPreExpiryInitialEnrolment(!preExpiryInitialEnrolment)}
              />
              <span>{allResourcesToTranslations.chkinitialenrolment}</span>
             
                <span>
                &nbsp;&nbsp;
                  <Checkbox
                    size="small"
                    color="primary"
                    disabled={!preExpiryEnable}
                    checked={preExpiryResitPeriod && preExpiryEnable}
                    className={`${styles.checkBox} guidemePreexpiryResitPeriod`}
                    onClick={() => setPreExpiryResitPeriod(!preExpiryResitPeriod)}
                  />
                  <span>{allResourcesToTranslations.chkresitperiod}</span>
                </span>
              
            </div>
            </>
            }
          </div>
        </div>
      </UnbrandedCard>

      {/* Post ExpiryNotification Start  */}
      <div style={{ marginTop: "5px" }}>&nbsp;</div>
      <UnbrandedCard>
        <div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
          <div className={styles.Wrapper}>
            <div className={styles.lableContainer}>
              <span className={styles.labelText}>{"4. " + allResourcesToTranslations.postexpiryreminderemails}</span>
            </div>
            <div className={styles.inputContainer}>
              <span className={styles.labelTextitalic}>{allResourcesToTranslations.postexpiryreminderemailsnotes }</span>
            </div>

            <div className={`${responsivestyles.rowrule} guidemePostExpiry`} style={{ width: '100%' }} >
              <div className={`${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_12}`} >
                <Checkbox
                  size="small"
                  color="primary"
                  checked={postExpiryEnable}
                  className={styles.checkBox}
                  onClick={() => {
                    setPostExpiryEnable(!postExpiryEnable);
                    if (!postExpiryEnable) {
                      setPostExpiryInitialEnrolment(false);
                      setPostExpiryResitPeriod(false);
                    }
                  }
                  }
                />
                <span className={styles.labelText}>{allResourcesToTranslations.enableemailrule}</span>
              </div>
              <div className={`${responsivestyles.columnrule_9} ${responsivestyles.columnrule_s_12}`} >
                <div>  <TextField
                  disabled={!postExpiryEnable}
                  className={styles.input}
                  style={{ width: "100%", marginBottom: "15px" }}
                  label={allResourcesToTranslations.postexpirynumber}
                  name="initialenrolmentnumber"
                  inputProps={{ maxLength: 3 }}
                  variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={postExpiryEnable ? (postExpirynumberText === '' ? '' : postExpirynumber) : 1}
                  type="text"
                  onChange={(e) => handlepostExpirynumber(e)}
                /></div>
                <div style={{ paddingTop: "15px" }}>
                  <TextField
                    disabled={!postExpiryEnable}
                    className={styles.input}
                    style={{ width: "100%", marginBottom: "15px" }}
                    label={allResourcesToTranslations.postexpirynumbersendevery + " days"}
                    name="initialenrolmentnumber"
                    inputProps={{ maxLength: 3 }}
                    variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                    value={postExpiryEnable ? (postExpirynumberdaysText === '' ? '' : postExpirynumberdays) : 1}
                    type="text"
                    onChange={(e) => handlepostExpirynumberdays(e)}
                  /></div>
              </div>
            </div>
            {selectedReminderType.trim() === 'c' &&
            <>
            <div className={styles.lableContainer} style={{ width: '100%', paddingTop: '5px' }} >
              <span>{allResourcesToTranslations.applyto}</span>
            </div>
            <div style={{ width: '100%', paddingTop: '5px' }}>
              <Checkbox
                size="small"
                color="primary"
                disabled={!(postExpiryEnable && initialEnrolmentEnable)}
                checked={(postExpiryInitialEnrolment && postExpiryEnable && initialEnrolmentEnable) || (selectedReminderType.trim() !== 'c')}
                className={`${styles.checkBox} guidemePostExpiryinitial`}
                onClick={() => setPostExpiryInitialEnrolment(!postExpiryInitialEnrolment)}
              />
              <span>{allResourcesToTranslations.chkinitialenrolment}</span>
             
                <span>
                  &nbsp;&nbsp;
                  <Checkbox
                    size="small"
                    color="primary"
                    disabled={!postExpiryEnable}
                    checked={postExpiryResitPeriod && postExpiryEnable}
                    className={`${styles.checkBox} guidemePostexpiryResitPeriod`}
                    onClick={() => setPostExpiryResitPeriod(!postExpiryResitPeriod)}
                  />
                  <span>{allResourcesToTranslations.chkresitperiod}</span>
                </span>            
            </div>
            </>
              }
          </div>
        </div>
      </UnbrandedCard>

      {/* Example  */}
      <div style={{ marginTop: "5px" }}>&nbsp;</div>
      <div className="showexample">
        <UnbrandedCard>
          <Accordion style={{ borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }} expanded={expandExample} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="unitadmin-content"
              id="unitadmin-header"
              onClick={(e) => setExpandExample(!expandExample)}
            >
              <Typography>
                <div className={styles.lableContainer} >
                  <span className={styles.labelText}><b>Show/Hide Timeline</b></span>
                </div>
                <div className={styles.inputContainer}>
                  <span className={styles.labelTextitalic}>{allResourcesToTranslations.emailexpiryexample}</span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: "0px", width: '98%' }}>
              <Typography style={{ width: '100%' }}>
                <div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
                  <div className={styles.Wrapper}>
                    <EmailReminderTimeline showChart={initialEnrolmentEnable} showPreExpiry={preExpiryEnable && preExpiryInitialEnrolment} showPostExpiry={postExpiryEnable && postExpiryInitialEnrolment} showManagerNotification={managerEnable} totalCourseCompletionDays={initialenrolmentnumber} preexpirydays={preExpirynumber} noOfPostexpiryNotification={postExpirynumber} postExpriryEveryDay={postExpirynumberdays} managerNotificationEveryDaysNo={managerExpirynumber} reminderType={selectedReminderType.trim()}></EmailReminderTimeline>
                    {/* <img src="https://content.saltcompliance.com/contentfiles/Salt/email-timeline.png" /> */}
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </UnbrandedCard>
      </div>

      {/* Manager notification Rule  */}
      <div style={{ marginTop: "5px" }}>&nbsp;</div>
      <UnbrandedCard>
        <div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
          <div className={styles.Wrapper}>
            <div className={styles.lableContainer}>
              <span className={styles.labelText}>{"5. " + allResourcesToTranslations.managernotifications}</span>
            </div>
            <div className={styles.lableContainer}>
              <span className={styles.labelTextitalic}>{allResourcesToTranslations.managernotificationsnotes}</span>
            </div>

            <div className={`${responsivestyles.rowrule} guidemeManage`} style={{ width: '100%', paddingTop: "10px" }} >
              <div className={`${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_12}`} >
                <Checkbox
                  size="small"
                  color="primary"
                  checked={managerEnable}
                  className={styles.checkBox}
                  onClick={() => {
                    setManagerEnable(!managerEnable);
                    if (managerEnable) {
                      setManagerCumulativeEnable(false);
                    }
                    else {
                      setManagerCumulativeEnable(true);
                    }
                  }
                  }
                />
                <span className={styles.labelText}>{allResourcesToTranslations.enableemailrule}</span>
              </div>
              <div className={`${responsivestyles.columnrule_9} ${responsivestyles.columnrule_s_12}`} >
                <TextField
                  disabled={!managerEnable}
                  className={styles.input}
                  style={{ width: "100%", marginBottom: "15px" }}
                  label={allResourcesToTranslations.escalationoptions + " days"}
                  name="initialenrolmentnumber"
                  inputProps={{ maxLength: 3 }}
                  variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={managerEnable ? (managerExpirynumberText === '' ? '' : managerExpirynumber) : 1}
                  type="text"
                  onChange={(e) => handlemanagerExpirynumber(e)}
                />
              </div>
            </div>

            <div style={{ width: '100%', paddingTop: '5px' }} className="chkCumulativemanagernotification" >
              <Checkbox
                size="small"
                color="primary"
                disabled={!managerEnable}
                checked={managerCumulativeEnable}
                className={`${styles.checkBox} guidemeManagecumulative`}
                onClick={() => setManagerCumulativeEnable(!managerCumulativeEnable)}
              />
              <span>{allResourcesToTranslations.cumulativelist}</span>
            </div>
          </div>
        </div>
      </UnbrandedCard>
      <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>
          <span>
            <CircularProgress
              style={{ width: (isSaving) ? "20px" : "0px", marginTop: "5px" }}
              color="primary"
              size="10"
            />
          </span>        
          <CustomTooltip displayText={["Save"]}>
            <span>
              <Button
                disabled={(isSaving && courseIds.length <= 0)}
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="saveData">
                Save
              </Button>
            </span>
          </CustomTooltip>           
          </div>
       </div>   
       }
      </BrandedCard>
      <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%", paddingTop: includeOptional ? '10px' : '0px', clear: "both" }}>
            <CustomTooltip displayText={["Next"]}>
              <span style={{ marginTop: "10px" }}>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                  className="nextData"
                >
                  Next
                </Button>
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={["Back"]}>
            <span style={{ marginTop: "10px" }}>
              <Button
                onClick={BackButtonRoute}
                variant="contained"
                color="primary"
                className="save"
              >
                Back
              </Button>
            </span>
          </CustomTooltip>
        </div>


      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};

export default CourseReminderEmailRulesAdd;
