import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepOneInactive = () => (    
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#21394F"/>
<path d="M22.7617 27H19.7441V18.7383C19.7441 18.5104 19.7474 18.2305 19.7539 17.8984C19.7604 17.5599 19.7702 17.2148 19.7832 16.8633C19.7962 16.5052 19.8092 16.1829 19.8223 15.8965C19.7507 15.9811 19.6042 16.1276 19.3828 16.3359C19.168 16.5378 18.9661 16.7201 18.7773 16.8828L17.1367 18.2012L15.6816 16.3848L20.2812 12.7227H22.7617V27Z" fill="#21394F"/>
</svg>

    

);

export default StepOneInactive