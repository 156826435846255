import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SaltCertificatesReportIconMenu = () => (    
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9091 2.33337H6.18182C5.60316 2.33337 5.04821 2.54409 4.63904 2.91916C4.22987 3.29423 4 3.80294 4 4.33337V20.3334C4 20.8638 4.22987 21.3725 4.63904 21.7476C5.04821 22.1227 5.60316 22.3334 6.18182 22.3334H19.2727C19.8514 22.3334 20.4063 22.1227 20.8155 21.7476C21.2247 21.3725 21.4545 20.8638 21.4545 20.3334V8.33337L14.9091 2.33337Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14 2.27124V8.81669H20.5455" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.7285 11.2131L13.9649 13.7179L16.7298 14.122L14.7292 16.0706L15.2013 18.8235L12.7285 17.523L10.2558 18.8235L10.7279 16.0706L8.72729 14.122L11.4922 13.7179L12.7285 11.2131Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
    );

export default SaltCertificatesReportIconMenu
