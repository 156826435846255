//React + Typescript
import React, { useContext, useEffect, useState } from 'react';

//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";
import LoaderContainer from '../../../Components/LoaderContainer';
import BrandedCard from '../../../Components/BrandedCard';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@material-ui/core';
import getColor from '../../../functions/getColor';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';


interface APIParametersProps {


  templates?: string;
  type?: string;

}
const APIParameters: React.FC<APIParametersProps> = ({ templates, type }) => {

  const { color } = useContext(CorperateThemeContext).theme;
  const [loading, setLoading] = useState<boolean>(true);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
    return () => { };
  }, []);



  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }


  if (loading) return <LoaderContainer />;
  return (
    <>
      {type == "Parameters" &&
        <div>
          {templates == "CurrentAdminReport" &&
            <div>
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div>
                                {"API Parameters" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="Url"
                            style={{ color: getColor(color.substring(2)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div >
                                {"Description" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ReportRequestingUserID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> Organisation admin/unit admin user id </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >OrganisationID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Organisation ID</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UnitIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Unit ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Course ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userFirstName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by First name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userLastName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Last name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userEmail</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Email</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ClassificationID</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Grouping Option</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >IsHistoricReport</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Current Admin Report: false</span><br />
                            <span>Historic Admin Report: true</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>        
            </div>
          }

          {templates == "CourseStatusReport" &&
            <div>
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div>
                                {"API Parameters" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="Url"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div >
                                {"Description" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ReportRequestingUserID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> Organisation admin/unit admin user id </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >OrganisationID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Organisation ID</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UnitIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Unit ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Course ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseModuleStatus</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Incomplete=   0</span><br />
                            <span>Complete =     1 </span><br />
                            <span>Fail=                 2 </span><br />
                            <span>Not Started=   3 </span><br />
                            <span>Expired(Time Elapsed)=     4 </span><br />
                            <span>Expired(New Content) =      5 </span><br />
                            <span>Incomplete(With Details)=  6 </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >DateFromOrg</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by From Date </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >DateToOrg</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by To Date</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >IncludeInactive</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>to Include Inactive Accounts:true</span><br />
                            <span>Only Active Accounts: false n</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userFirstName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by First name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userLastName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Last name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userEmail</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Email</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Username</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ClassificationID</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Grouping Option</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>          
            </div>
          }

          {templates == "ProgressReport" &&
            <div>
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div>
                                {"API Parameters" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="Url"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div >
                                {"Description" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ReportRequestingUserID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> Organisation admin/unit admin user id </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >OrganisationID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Organisation ID</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UnitIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Unit ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Course ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >IncludeInactive</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>to Include Inactive Accounts:true</span><br />
                            <span>Only Active Accounts: false n</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userFirstName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by First name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userLastName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Last name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userEmail</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Email</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Username</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ClassificationID</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Grouping Option</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>            
            </div>
          }

          {templates == "SummaryReport" &&
            <div>
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div>
                                {"API Parameters" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="Url"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div >
                                {"Description" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ReportRequestingUserID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> Organisation admin/unit admin user id </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >OrganisationID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Organisation ID</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UnitIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Unit ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Course ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >EffectiveFromDateOrg </span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by From Date </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >EffectiveToDateOrg </span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by To Date </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ClassificationID</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Grouping Option</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>          
            </div>
          }

          {templates == "QuizHistoryReport" &&
            <div>
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div>
                                {"API Parameters" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="Url"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div >
                                {"Description" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ReportRequestingUserID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> Organisation admin/unit admin user id </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >OrganisationID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Organisation ID</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UnitIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Unit ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Course ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userFirstName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by First name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userLastName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Last name</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >userEmail</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Email</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UserName</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Username</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >IncludeInactive </span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>to Include Inactive Accounts:true </span><br></br>
                            <span>Only Active Accounts: false </span>
                          </TableCell>
                        </TableRow>

                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>             
            </div>
          }

          {templates == "WarningReport" &&
            <div>
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div>
                                {"API Parameters" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="Url"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div >
                                {"Description" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ReportRequestingUserID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> Organisation admin/unit admin user id </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >OrganisationID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Organisation ID</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UnitIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Unit ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Course ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >WarningPeriod </span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Expired Value </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >WarningUnit  </span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> mm / dd  (Month / days) </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >IncludeInactive </span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>to Include Inactive Accounts:true </span><br></br>
                            <span>Only Active Accounts: false </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>            
            </div>
          }

          {templates == "AtRiskReport" &&
            <div>
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div>
                                {"API Parameters" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="Url"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div >
                                {"Description" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ReportRequestingUserID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span> Organisation admin/unit admin user id </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >OrganisationID</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Organisation ID</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >UnitIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Unit ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >CourseIDs</span><span style={{ color: "red" }}>*</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Course ID's (1,2,3) leave blank to include all units</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >IncludeInactive</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>to Include Inactive Accounts:true</span><br />
                            <span>Only Active Accounts: false n</span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"25%"}>
                            <span >FailCounter
                            </span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by FailCounter
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow style={rowBackgroundColor(1)}>
                          <TableCell align="left" width={"25%"}>
                            <span >ClassificationID</span>
                          </TableCell>
                          <TableCell align="left" width={"75%"}>
                            <span>Filter Results by Grouping Option</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>             
            </div>
          }
        </div>
      }
      {type == "Response" &&

        <div>
          {templates == "CurrentAdminReport" &&
            <div>          
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div
                              >
                                {"API Response" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"100%"}>
                            <pre>
                              {JSON.stringify({
                                isSuccess: true,
                                error: null,
                                currentAdminReportItems: [
                                  {
                                    user: "FirstName LastName",
                                    userFirstName: "First Name",
                                    userLastName: "Last Name",
                                    unit: "Unit Name",
                                    course: "Course Name",
                                    module: "Module Name",
                                    quizStatus: "Not Started",
                                    quizScore: 0,
                                    quizStatusDate: "Date",
                                    quizStatusDateWithTime: "Date",
                                    lessonStatus: "",
                                    email: "Email",
                                    unitID: 0,
                                    userID: 0,
                                    userName: "User Name",
                                    externalID: "External ID",
                                    courseID: 0,
                                    moduleID: 0,
                                    active: false,
                                    classificationId: 0,
                                    classificationName: "",
                                    classificationValue: "",
                                    lastCourseCompletionDate: null,
                                    lastCourseCompletionDateTxt: "",
                                    dueDateTxt: "Date",
                                    dueDate: "Date"
                                  }
                                ]
                              }, null, 2)}
                            </pre>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>
            </div>
          }

          {templates == "CourseStatusReport" &&
            <div>             
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div
                              >
                                {"API Response" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"100%"}>
                            <pre>
                              {JSON.stringify({
                                isSuccess: true,
                                error: null,
                                coursestatusreportItem: [
                                  {
                                    userID: 0,
                                    unitID: 0,
                                    firstName: "First Name",
                                    lastName: "Last Name",
                                    userStatus: "Active",
                                    unitPathway: "Unit Pathway",
                                    courseStatusDate: "Date",
                                    actualLastCourseCompletionDate: "",
                                    lastCourseCompletionReason: "",
                                    userName: "User Name",
                                    email: "Email",
                                    externalID: "66944",
                                    courseName: "Course Name",
                                    courseID: -1,
                                    dateCreated: "Date",
                                    dateArchived: "",
                                    delinquencyManagerEmail: "Manager Email",
                                    classificationId: 0,
                                    classificationName: "",
                                    classificationValue: "",
                                    courseStatus: "Incomplete",
                                    dateEffectiveFrom: "Date",
                                    dateEffectiveTo: "Date",
                                    isEnabledateArchived: false,
                                    moduleName: null,
                                    quizStatus: null,
                                    quizScore: 0,
                                    quizStatusDate: null,
                                    quizStatusDateWithTime: null,
                                    courseStatusDateWithTime: null,
                                    userCreatedDateWithTime: null,
                                    dueDateTxt: "",
                                    dueDate: null
                                  }
                                ]
                              }, null, 2)}
                            </pre>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>
            </div>
          }

          {templates == "ProgressReport" &&
            <div>       
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div
                              >
                                {"API Response" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"100%"}>
                            <pre>
                              {JSON.stringify({
                                isSuccess: true,
                                error: null,
                                givenOrganisationID: 45,
                                givenClassificationID: 0,
                                orgClassificationTypeID: 27,
                                orgClassificationTypeName: "Location",
                                progreseportItems: [
                                  {
                                    userID: 0,
                                    unitPathway: "Unit Pathway",
                                    lastName: "Last Name",
                                    firstName: "First Name",
                                    userStatus: "Active",
                                    courseName: "Course Name",
                                    externalID: "66944",
                                    email: "Email",
                                    userName: "User Name",
                                    currentStatus: "InComplete",
                                    previousStatus: "",
                                    lastCourseCompletionStatusChangeDate: "",
                                    actualLastCourseCompletionDate: "",
                                    lastCourseCompletionReason: "",
                                    delinquencyManagerEmail: "Manager Email",
                                    classificationName: "",
                                    classificationValue: ""
                                  }
                                ],
                                progreseportgrandtotalItem: []
                              }, null, 2)}
                            </pre>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>
            </div>
          }

          {templates == "SummaryReport" &&
            <div>          
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div
                              >
                                {"API Response" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"100%"}>
                            <pre>
                              {JSON.stringify({
                                isSuccess: true,
                                error: null,
                                selectedCourseID: -1,
                                selectedCourseName: "Course Name",
                                summaryreportItem: [
                                  {
                                    unitID: 3115,
                                    courseName: "Course Name",
                                    unitPathway: "UnitPathway",
                                    complete: 0,
                                    incomplete: 5,
                                    count: 1,
                                    totalUsers: 1,
                                    incompletePercentage: 100,
                                    completePercentage: 0,
                                    classificationValue: "",
                                    dateEffectiveFrom: "Date",
                                    dateEffectiveTo: "Date",
                                    courseIds: "0",
                                    unitIDList: "0",
                                    effectiveDate: "Date",
                                    classificationId: 0
                                  }
                                ],
                                summaryReportUniqueCourseListItems: [
                                  {
                                    courseName: "Course Name",
                                    complete: 0,
                                    incomplete: 5,
                                    incompletePercentage: 100,
                                    completePercentage: 0
                                  }
                                ]
                              }, null, 2)}
                            </pre>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>
            </div>
          }

          {templates == "QuizHistoryReport" &&
            <div>         
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div
                              >
                                {"API Response" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"100%"}>
                            <pre>
                              {JSON.stringify({
                                isSuccess: true,
                                error: null,
                                quizHistoryReportItems: [
                                  {
                                    userID: 0,
                                    firstName: "First Name",
                                    lastName: "Last Name",
                                    email: "Email",
                                    userName: "User Name",
                                    unitID: 0,
                                    unitPathway: "Unit Pathway",
                                    unitName: "Unit Name",
                                    courseName: "Course Name",
                                    moduleName: "Module Name ",
                                    active: "Active",
                                    quizStatus: "Not Started",
                                    quizScore: 0,
                                    quizStatusDate: "Date",
                                    quizStatusDateWithTime: "Date"
                                  }
                                ]
                              }, null, 2)}
                            </pre>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>
            </div>
          }

          {templates == "WarningReport" &&
            <div>              
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div
                              >
                                {"API Response" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"100%"}>
                            <pre>
                              {JSON.stringify({
                                isSuccess: true,
                                error: null,
                                warningreportItem: [
                                  {
                                    courseName: "Course Name",
                                    unitPathway: "Unit Pathway",
                                    firstName: "First Name",
                                    lastName: "Last Name",
                                    flag: "",
                                    externalID: "External ID",
                                    email: "Email",
                                    userName: "USer Name",
                                    userId: 0,
                                    userStatus: 1,
                                    completionDate: "Date",
                                    expiryDate: "Date",
                                    classificationName: ""
                                  }
                                ],
                                warningReportGrandTotalItems: [
                                  {
                                    grandTotalusers: 1
                                  }
                                ]
                              }, null, 2)}
                            </pre>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>
            </div>
          }

          {templates == "AtRiskReport" &&
            <div>       
              <BrandedCard>
                <div className={styles.tableContentDescription}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="Caption"
                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                            align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div
                              >
                                {"API Response" /* TODO translate */}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow style={rowBackgroundColor(0)}>
                          <TableCell align="left" width={"100%"}>
                            <pre>
                              {JSON.stringify({
                                isSuccess: true,
                                error: null,
                                atRiskreportItem: [
                                  {
                                    courseName: "Course Name",
                                    unitPathway: "Unit Pathway",
                                    moduleName: "Module Name",
                                    firstName: "First Name",
                                    lastName: "Last Name",
                                    externalID: "ExternalID",
                                    email: "Email",
                                    userName: "User Name",
                                    userId: 0,
                                    moduleId: 0,
                                    failNumber: 0,
                                    currentStatus: "Not Started",
                                    classificationName: ""
                                  }
                                ],
                                atRiskReportGrandTotalItems: [
                                  {
                                    grandTotalusers: 1
                                  }
                                ]
                              }, null, 2)}
                            </pre>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </BrandedCard>
            </div>
          }
        </div>
      }
    </>
  );
};
export default APIParameters;
