//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

import { useHistory, useLocation, useParams } from "react-router";
// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
import { MyTrainingListContext } from '../../../contexts/MyTrainingListContext'
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/extcertdetails.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";

//General Functions
import getColor from '../../../functions/getColor';
//Components~~
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';
import GetOrgAdmins from '../../../API/Administration/Organisation/GetOrgAdmins';
import { Checkbox, CircularProgress, TableContainer, TablePagination, TextField,
          FormControl, InputLabel, Select, Radio, RadioGroup, FormControlLabel
       } from '@material-ui/core';
import { OrgAdmins, SentEmailReportResultItem, UnitTreeItem, CPDProfileWithItemPoint } from '../../../globalTypes';
import DeleteIcon from '../../../CustomIcons/DeleteIcon';

import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon";
import UnbrandedCard from '../../../Components/UnbrandedCard';
import Units from '../../ReportCommonComponents/Units';
import getRelevantUnitTree from '../../../API/GetRelevantUnitTree';
import ToggleLeftIcon from '../../../CustomIcons/ToggleLeftIcon';
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import SaveIcon from '../../../CustomIcons/SaveIcon';
import SaveExternalCertificate from '../../../API/Administration/ExternalCertificate/SaveExternalCertificate';
import CheckIfOrgHasCPDEnabled from '../../../API/Administration/CPDProfiles/CheckIfOrgHasCPDEnabled';
import GetOneExternalCertificate from '../../../API/Administration/ExternalCertificate/GetOneExternalCertificate';
import AddEmailAccountIcon from '../../../CustomIcons/Administration/AddEmailAccountIcon';
import AssignButtonPanel from '../PolicyBuilderSettings/AssignButtonPanel';
import AssignUnits from '../PolicyBuilderSettings/AssignUnits';
import UserListSection from '../AccountManagement/UserListSection';
import AccountManagement from '../AccountManagement/AccountManagement';
//import { set } from 'date-fns';
import ViewExternalCertificateAssignedUsers from '../../../API/Administration/ExternalCertificate/ViewExternalCertificateAssignedUsers';
import ExternalCertificateIcon from '../../../CustomIcons/NavigationMenu/ExternalCertificateIcon';
import AssignExternalCertificateUnits from '../../../API/Administration/ExternalCertificate/AssignExternalCertificateUnits';
import AssignExternalCertificateUsers from '../../../API/Administration/ExternalCertificate/AssignExternalCertificateUsers';
import GetCPDProfilesListWithGivenItemPoints from "../../../API/Administration/CPDProfiles/GetCPDProfilesListWithGivenItemPoints";
import validateEmail from '../../../functions/valudateEmail';
import TinyMCEEditor from '../../../Components/TinyMCEEditorComponent';
import SendEmailReportIcon from '../../../CustomIcons/SendEmailReportIcon';
import GetExternalCertyInitEmailTemplate from '../../../API/Administration/ExternalCertificate/GetExternalCertyInitEmailTemplate';
import GetExternalCertificateAssignedUserIds from '../../../API/Administration/ExternalCertificate/GetExternalCertificateAssignedUserIds';
import addEmailingReport from '../../../API/AddEmailingReport';

import loadingGif from '../../../CustomIcons/GIFs/loading_dots_blue.gif';

const ExternalCertificateDetails = () => {
  const { externalCertificateId } = useParams<{ externalCertificateId: string }>();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const {  setDashboardUpdateRequired, setTrainingListUpdateRequired} = useContext(MyTrainingListContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const location = useLocation<any>();
  const { setMessage } = useContext(LoaderContext);
  const { showCPD, setShowCPD, setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { oidcUser } = useReactOidc();
  const [orgAdmins, setorgAdmins] = useState<Array<OrgAdmins>>([]);
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);
  const history = useHistory();
  const [certificateName, setCertificateName] = useState<string>("");
  const [allowLearnerToEdit, setAllowLearnerToEdit] = useState<boolean>(true);
  const [enableCpdPoints, setEnableCpdPoints] = useState<boolean>(false);
  const [hasNoValidDates, setHasNoValidDates] = useState<boolean>(false);
  const [enableCertificateValidityPeriod, setEnableCertificateValidityPeriod] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [allowLearnerInDays, setAllowLearnerInDays] = useState("1");
  const [selectedUnitIds, setSelectedUnitIds] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isCPDSelectionChanging, setIsCPDSelectionChanging] = useState<boolean>(false);
  const [selectedCPDWhileChanging, setSelectedCPDWhileChanging] = useState<string>("");
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [units, setUnits] = useState<Array<UnitTreeItem>>(!!location.state && !!location.state.units ? location.state.units : []);
  const [filterUnitName, setFilterUnitName] = useState(!!location.state && !!location.state.filterUnitName ? location.state.filterUnitName.toString() : "");
  const [showAssignUnits, setshowAssignUnits] = useState<boolean>(false);
  const [showAssignAccounts, setshowAssignAccounts] = useState<boolean>(false);
  const [showViewAccounts, setshowViewAccounts] = useState<boolean>(false);
  const [showResetAccounts, setshowResetAccounts] = useState<boolean>(false);
  const [initaialUsersList, setInitaialUsersList] = React.useState<Array<any>>([]);
  const [updatedinitaialUsersList, setUpdatedInitaialUsersList] = React.useState<Array<any>>([]);
  const [finallySelectedUsersList, setFinallySelectedUsersList] = React.useState<Array<any>>([]);
  const [finallySelectedUnitList, setFinallySelectedUnitList] = React.useState<Array<any>>([]);
  const [certificateAssignedUsersList, setCertificateAssignedUsersList] = React.useState<Array<any>>([]);
  const [initialExternalCertificateAcceptedUsersList, setInititalExternalCertificateAcceptedUsersList] = React.useState<Array<any>>([]);
  const [needLatestUnits, setNeedLatestUnits] = useState<boolean>(false);
  const [needLatestUsers, setNeedLatestUsers] = useState<boolean>(false);
  const [assignedUserIds, setAssignUserIds] = useState<Array<number>>([]);

  const [hasSelectedUsersChanged, setHasSelectedUsersChanged] = useState<boolean>(false);
  const [assignUsers, setAssignUsers] = useState<boolean>(false);
  const [isSavingAssignment, setIsSavingAssignment] = useState<boolean>(false);
  const [resetAcceptances, setResetAcceptances] = useState<boolean>(false);
  const [hasAccountsSearched, setHasAccountsSearched] = useState<boolean>(false);
  const [hasUnitsLoaded, setHasUnitsLoaded] = useState<boolean>(false);
  const [selectedUsersCSVInitial, setSelectedUsersCSVInitial] = useState<string>("");
  const [viewExternalCertificateLoading, setViewExternalCertificateLoading] = useState<boolean>(false);
  const [isResettingAcceptances, setIsResettingAcceptances] = useState<boolean>(false);
  const [viewCertificateLoading, setViewCertificateLoading] = useState<boolean>(false);
  const [selectedUnits, setselectedUnits] = useState<string>("");
  const [updatedSelectedUnits, setupdatedSelectedUnits] = useState<Array<string>>([]);
  const [sameUnitModelMessage, setSameUnitModelMessage] = useState<string>("");
  const [showSameUnitModalForConfirmation, setShowSameUnitModalForConfirmation] = useState<boolean>(false);
  const [showModal, setModalOpen] = useState<boolean>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [emailBodyTemplate, setEmailBodyTemplate] = useState<string>("");
  const [emailReportBodyResult, setEmailReportBodyResult] = useState<Array<SentEmailReportResultItem>>([]);
  const [isHandlerBusy, setHandlerBusy] = useState<boolean>(false);   

  const [selectedCPDProfileID, setSelectedCPDProfileID] = useState<number>(-1);   
  const [selectedCPDProfilePeriodID, setSelectedCPDProfilePeriodID] = useState<number>(-1);    
  //const [selectedCPDProfileItemAssignedPointsInitial, setSelectedCPDProfileItemAssignedPointsInitial] = useState<number>(0.0);  
  const [isOrgCPDsLoading, setIsOrgCPDsLoading] = useState<boolean>(false);  
  const [orgCPDProfileResult, setOrgCPDProfileResult] = useState<any>(null);   
  const [isAnyCPDPointsChanged, setIsAnyCPDPointsChanged] = useState<boolean>(false);   
  const [selectedCPDProfileItemAssignedPointsModified, setSelectedCPDProfileItemAssignedPointsModified] = useState<number>(0.0);
  const [selectedCPDProfileItemAssignedPointsModifiedTxt, setSelectedCPDProfileItemAssignedPointsModifiedTxt] = useState<string>("");
  const [selectedCPDProfile, setSelectedCPDProfile] = useState<any>({profileID : -1, profileName: "", profilePeriodId: -1, profileStatus: "",
                                                                      dateStart: null, dateEnd: null, futureDateStart: null, futureDateEnd: null, dateStartUTC: null, dateEndUTC: null, 
                                                                      futureDateStartUTC: null, futureDateEndUTC: null, currentPeriod: "", futurePeriod: "", points: 0.0, futurePoints: 0.0,
                                                                      endOfPeriodAction: "1", futureMonthIncrement: null, applyToQuiz: null, applyToLesson: null});  
  const [assignToTypeExtCert, setAssignToTypeExtCert] = useState<any>("allaccountsexcludingcompletedforextcert"); 
  const [exclusionOfCertProvidedUsers, setExclusionOfCertProvidedUsers] = useState<boolean>(true);
  //const [csvOfAllProfilesToPeriodIDsToClearPoints, setCSVOfAllProfilesToPeriodIDsToClearPoints] = useState<string>("");

  const [showWindowMidWarningModalForConfirmation, setShowWindowMidWarningModalForConfirmation] = useState<boolean>(false);
  const [windowMidModelMessage, setWindowMidModelMessage] = useState<string>("");    
  const [csvOfAllDeniedUnits, setCSVOfAllDeniedUnits] = useState<string>("");

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [showCPDLocal, setShowCPDLocal] = useState<boolean>(false);   

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".certificateName",
        title: allResourcesToTranslations.certificatenametitle, intro: allResourcesToTranslations.certificatenameguidemetext
      },

    ]
  })

  const setGuideMe = () => {
    
    //let curShowCPD = CheckIfCPDEnabledForOrg();
    // let updateSteps = [...intro.steps];
    // if (updateSteps.length>1) {
    //   updateSteps.splice(1, updateSteps.length - 1);     
    // }
    let updateSteps = [];
    updateSteps.push(
      {
        element: ".certificateName",
        title: allResourcesToTranslations.certificatenametitle, intro: allResourcesToTranslations.certificatenameguidemetext
      });
    updateSteps.push(
      {
        element: ".allowLearnerToEdit",
        title: allResourcesToTranslations.allowlearnertoedittitle, intro: allResourcesToTranslations.allowlearnertoeditguideme
      },
      {
        element: ".hasNoValidDates",
        title: allResourcesToTranslations.hasnovaliddatestitle, intro: allResourcesToTranslations.hasnovaliddatesguideme
      },
      {
        element: ".activeInactive",
        title: allResourcesToTranslations.activeinactivetitle, intro: allResourcesToTranslations.activeinactiveguideme
      });
    let elem = document.getElementById("isCPDEnabledToggleParentID");
    if (showCPD || !!elem) {
      updateSteps.push(
        {
          element: ".isCPDEnabledToggle",
          title: allResourcesToTranslations.iscpdenabledtoggletitle, intro: allResourcesToTranslations.iscpdenabledtoggleguideme
        });
      let elem2 = document.getElementById("excludePreviousCompletionsInAssignPointsExtCert");
      if (enableCpdPoints || !!elem2) {
        updateSteps.push(
          {
            element: "#cpdProfileForExtCertDiv",
            title: allResourcesToTranslations.extcertprofgmt, intro: allResourcesToTranslations.extcertprofgmm
          },
          {
            element: "#cpdProfilePeriodForExtCertDiv",
            title: allResourcesToTranslations.extcertprofprdgmt, intro: allResourcesToTranslations.extcertprofprdgmm
          },
          {
            element: "#cpdProfilePeriodPointInputCtrl",
            title: allResourcesToTranslations.extcertprofprdptgmt, intro: allResourcesToTranslations.extcertprofprdptgmm
          },
          {
            element: "#includePreviousCompletionsInAssignPointsExtCert",
            title: allResourcesToTranslations.extcertprofprdptprincgmt, intro: allResourcesToTranslations.extcertprofprdptprincgmm
          },
          {
            element: "#excludePreviousCompletionsInAssignPointsExtCert",
            title: allResourcesToTranslations.extcertprofprdptprexcgmt, intro: allResourcesToTranslations.extcertprofprdptprexcgmm
          }
        );
      }
    }
    updateSteps.push(
      {
        element: ".saveData",
        title: allResourcesToTranslations.savedatatitle, intro: allResourcesToTranslations.savedataguideme

      },
      {
        element: ".sendWelcomeEmail",
        title: allResourcesToTranslations.sendwelcomeemailtitle, intro: allResourcesToTranslations.sendwelcomeemailguideme
      });
      if ((parseInt(externalCertificateId) > 0)) {
        updateSteps.push(
            {
                element: ".AssignUnits",
                title: allResourcesToTranslations.assignunitstitle, intro: allResourcesToTranslations.assignunitsguideme
            },
            {
                element: ".AssignAccounts",
                title: allResourcesToTranslations.assignaccountstitle, intro: allResourcesToTranslations.assignaccountsguideme
            },
            {
                element: ".ViewAccounts",
                title: allResourcesToTranslations.viewaccountstitle, intro: allResourcesToTranslations.viewaccountsguideme
            });
    }

    if ((parseInt(externalCertificateId) > 0) && showAssignUnits)
    {
      updateSteps.push(
        {
            element: ".unitFilterButtons",
            title: allResourcesToTranslations.unitfilterbuttonstitle, intro: allResourcesToTranslations.unitfilterbuttonsguideme
        },
        {
            element: ".unitFilterSearch",
            title: allResourcesToTranslations.unitfiltersearchtitletext, intro: allResourcesToTranslations.unitfiltersearchguideme
        },
        {
            element: ".unittree",
            title: allResourcesToTranslations.unittreetitle, intro: allResourcesToTranslations.unittreeguideme
        },
        {
            element: ".saveAssignedData",
            title: allResourcesToTranslations.saveassigneddatatitle, intro: allResourcesToTranslations.saveassigneddataguideme           
        });
    }

    if ((parseInt(externalCertificateId) > 0) && showAssignAccounts)
    {
        updateSteps.push(
        {
            element: ".unitFilterButtons",
            title: allResourcesToTranslations.unitfilterbuttonstitle, intro: allResourcesToTranslations.unitfilterbuttonsguideme
        },
        {
            element: ".unitFilterSearch",
            title: allResourcesToTranslations.unitfiltersearchtitletext, intro: allResourcesToTranslations.unitfiltersearchguideme
        },
        {
            element: ".unittree",
            title: allResourcesToTranslations.unittreetitle, intro: allResourcesToTranslations.unittreeguideme
        },
        {
            element: ".firstName",
            title: allResourcesToTranslations.filter,
            intro: allResourcesToTranslations.firstnamemsg,
        },
        {
            element: ".lastName",
            title: allResourcesToTranslations.filter,
            intro: allResourcesToTranslations.lastnamemsg,
        },
        {
            element: ".userName",
            title: allResourcesToTranslations.filter,
            intro: allResourcesToTranslations.usernamemsg,
        },
        {
            element: ".email",
            title: allResourcesToTranslations.filter,
            intro: allResourcesToTranslations.emailmsg,           
        },
        {
            element: ".userID",
            title: allResourcesToTranslations.filter,
            intro: allResourcesToTranslations.useridmsg,
        },
        {
            element: ".searchUsers",
            title: allResourcesToTranslations.searchusers,
            intro: allResourcesToTranslations.searchusersmsg,
        },
        {
            element: ".resetAll",
            title: allResourcesToTranslations.resetall,
            intro: allResourcesToTranslations.resetallmsg,
        });
    }
    if (parseInt(externalCertificateId) > 0 && showAssignAccounts && hasAccountsSearched)
    {
        updateSteps.push(
            {
                element: ".assignCheckbox",
                title: allResourcesToTranslations.assigncheckboxtitle, intro: allResourcesToTranslations.assigncheckboxguideme             
            },
            {
                element: ".unitpathwayCol",
                title: allResourcesToTranslations.unitpathwaycoltitle, intro: allResourcesToTranslations.unitpathwaycolguideme           
            },
            {
                element: ".lastnameCol",
                title: allResourcesToTranslations.lastnamecoltitle, intro: allResourcesToTranslations.lastnamecolguideme           
            },
            {
                element: ".firstnameCol",
                title: allResourcesToTranslations.firstnamecoltitle, intro: allResourcesToTranslations.firstnamecolguideme        
            },
            {
                element: ".saveAssignedData",
                title: allResourcesToTranslations.saveassigneddatatitle,
                intro: allResourcesToTranslations.saveassigneddataguidemegrid,                
            }
        );
    } 

    setIntro({ ...intro, steps: [...updateSteps] });
    // CheckIfOrgHasCPDEnabled(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((cpdorgsettingRes) => {
    //   if (!!cpdorgsettingRes && cpdorgsettingRes.isSuccess) {
    //     if (cpdorgsettingRes.isCPDEnabled !== null && cpdorgsettingRes.isCPDEnabled !== undefined) {
    //       setShowCPD(cpdorgsettingRes.isCPDEnabled);

    //       setIntro({ ...intro, steps: [...updateSteps] });
    //     }
    //     else {
    //       setShowCPD(false);

    //       removeCPDGuideMe(updateSteps);
    //     }
    //   }
    //   else {   
    //     setShowCPD(false); 

    //     removeCPDGuideMe(updateSteps);
    //   }
    // }).catch((error) => {
    //   setShowCPD(false);

    //   removeCPDGuideMe(updateSteps);
    // });
    
  };

  // const removeCPDGuideMe = (updateSteps: any[]) => {
  //   if (!!updateSteps && updateSteps.length > 0) {
  //     let updateStepsMod = updateSteps.filter(e => !!e && !!e.element && 
  //       e.element !== ".isCPDEnabledToggle" && 
  //       e.element !== "#cpdProfileForExtCertDiv" && 
  //       e.element !== "#cpdProfilePeriodForExtCertDiv" &&
  //       e.element !== "#cpdProfilePeriodPointInputCtrl" && 
  //       e.element !== "#includePreviousCompletionsInAssignPointsExtCert" && 
  //       e.element !== "#excludePreviousCompletionsInAssignPointsExtCert");

  //     if (!!updateStepsMod && updateStepsMod.length > 0) {
  //       setIntro({ ...intro, steps: [...updateStepsMod] });
  //     }
  //   }
  // }

  useEffect(() => {
    setTitle(allResourcesToTranslations.extcertificatedetails);
    setTitleEnglish(pageTitles.extcertificatedetails);
    updateBackButtonRoute("/ExternalCertificates");
    setMenuSelection(allResourcesToTranslations.extcertificate);
    setLoading(true);
    LoadExternalCertificate(parseInt(externalCertificateId));
    CheckIfCPDEnabledForOrg();
    
    return () => { };
  }, []);

  // useEffect(() => {
  //   setGuideMe();
  // }, [showCPD]);

  useEffect(() => {     
    //if (enableCpdPoints && parseInt(externalCertificateId) > 0) {
    if (enableCpdPoints) {
      loadOrgActiveCurrentProfiles();
    }
    else {
      setIsOrgCPDsLoading(false);
    }
  }, [enableCpdPoints]);

  const loadOrgActiveCurrentProfiles = (givenProfIDToSelect: number = -1, givenProfPrdIDToSelect: number = -1, givenExtCertId: number = -1) => {
    setIsOrgCPDsLoading(true);
    GetCPDProfilesListWithGivenItemPoints(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      givenExtCertId <= 0 ? parseInt(externalCertificateId) : givenExtCertId,
      "e", "en-au").then((orgCPDProfRes:any) => {   
        
          if (!!orgCPDProfRes && orgCPDProfRes.isSuccess) {
              let cpdProfilesMod : CPDProfileWithItemPoint[] = [];
              if (!!orgCPDProfRes.cpdProfiles && orgCPDProfRes.cpdProfiles.length > 0) {
                  cpdProfilesMod.push({cpdProfile: { profileID : -1, profileName: "", profilePeriodID: -1, profileStatus: "Enabled",
                                                     dateStart: null, dateEnd: null, futureDateStart: null, futureDateEnd: null, dateStartUTC: null, dateEndUTC: null, 
                                                     futureDateStartUTC: null, futureDateEndUTC: null, currentPeriod: "", futurePeriod: "", points: 0.0, futurePoints: 0.0,
                                                     endOfPeriodAction: "1", futureMonthIncrement: null, applyToQuiz: null, applyToLesson: null, applyToNewsfeed: null},
                                                  itemAssignedPoints: 0.0});
                  cpdProfilesMod.push(...orgCPDProfRes.cpdProfiles);
                  orgCPDProfRes.cpdProfiles = cpdProfilesMod;
              }
              else 
              {
                  cpdProfilesMod.push({cpdProfile: { profileID : -1, profileName: "", profilePeriodID: -1, profileStatus: "Enabled",
                                                     dateStart: null, dateEnd: null, futureDateStart: null, futureDateEnd: null, dateStartUTC: null, dateEndUTC: null, 
                                                     futureDateStartUTC: null, futureDateEndUTC: null, currentPeriod: "", futurePeriod: "", points: 0.0, futurePoints: 0.0,
                                                     endOfPeriodAction: "1", futureMonthIncrement: null, applyToQuiz: null, applyToLesson: null, applyToNewsfeed: null},
                                                     itemAssignedPoints: 0.0});
                  cpdProfilesMod.push(...orgCPDProfRes.cpdProfiles);
                  orgCPDProfRes.cpdProfiles = cpdProfilesMod;
              }
              setOrgCPDProfileResult(orgCPDProfRes);
              if (givenProfIDToSelect <= 0 || givenProfPrdIDToSelect <= 0) {
                let defaultSelectionAsNone = orgCPDProfRes.cpdProfiles[0].cpdProfile;
                setSelectedCPDProfile(defaultSelectionAsNone);
                updateCurrentProfileSelection(defaultSelectionAsNone, orgCPDProfRes);
                //setSelectedCPDProfileItemAssignedPointsInitial(0.0);
                setSelectedCPDProfileItemAssignedPointsModified(0.0);
                setSelectedCPDProfileItemAssignedPointsModifiedTxt("0.0");
              }
              else {
                proceedChangingCPDProfileSelectionGiven(givenProfIDToSelect.toString(), orgCPDProfRes);
              }
          }
          else {
              console.log("Getting the CPD profiles of the organisation is failed");
          }
          setIsOrgCPDsLoading(false);
      });
  }

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (
        (parseInt(year) < 10 ? "000" + year.toString()
          : ((parseInt(year) < 100 ? "00"
            : (((parseInt(year) < 1000 ? "0" : year.toString())))))) + "-" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }
    else {
      return "";
    }
  }

  const handleAssignUnits = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewExternalCertificateLoading(false);

    setshowAssignUnits(!showAssignUnits);
    setshowAssignAccounts(false);
    setshowViewAccounts(false);
    setshowResetAccounts(false);
    setAssignUsers(false);

    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleAssignAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewExternalCertificateLoading(false);

    setshowAssignAccounts(!showAssignAccounts);
    setshowAssignUnits(false);
    setshowViewAccounts(false);
    setshowResetAccounts(false);
    setAssignUsers(true);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleViewAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewExternalCertificateLoading(false);

    setshowViewAccounts(!showViewAccounts);
    setshowAssignAccounts(false);
    setshowAssignUnits(false);
    setshowResetAccounts(false);
    setCertificateAssignedUsersList([]);
    setInititalExternalCertificateAcceptedUsersList([]);
    LoadExternalCertificateAssignedUsers(parseInt(externalCertificateId));
    setAssignUsers(true);

    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleResetAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewExternalCertificateLoading(false);

    setshowAssignAccounts(false);
    setshowAssignUnits(false);
    setshowViewAccounts(false);
    setAssignUsers(false);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const LoadExternalCertificateAssignedUsers = (externalCertificateId: number) => {
    setViewCertificateLoading(true);
    ViewExternalCertificateAssignedUsers(
      oidcUser.access_token,
      userId,
      orgDomain,
      externalCertificateId,
      parseInt(orgId)
    ).then((response) => {
      setViewCertificateLoading(false);
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.externalCertificateAssignedUserItem !== null &&
        response.externalCertificateAssignedUserItem !== undefined
      ) {
        if (response.externalCertificateAssignedUserItem.length > 0) {
          let updatedUsersList = response.externalCertificateAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          let initialUsersList = response.externalCertificateAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isInitial: true,
            };
          });
          setCertificateAssignedUsersList(updatedUsersList);
          setInititalExternalCertificateAcceptedUsersList(initialUsersList);
          let initialGrantedList = initialUsersList.filter((initialItem: any) => initialItem.granted === true);
          const selectedIds = initialGrantedList.map(({ userID }: any) => userID);
          setSelectedUsersCSVInitial(selectedIds.join(','));
          setAssignUserIds(selectedIds);
          return;

        } else {
          setHasErrored(false);
          setSaveSuccess(false);
          return;
        }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Fetching record has failed, please try again.');
        setHasErrored(true);
        return;
      }
    });
  }

  const CheckIfCPDEnabledForOrg = () => {    
     CheckIfOrgHasCPDEnabled(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((cpdorgsettingRes) => {
      if (!!cpdorgsettingRes && cpdorgsettingRes.isSuccess) {
        if (cpdorgsettingRes.isCPDEnabled !== null && cpdorgsettingRes.isCPDEnabled !== undefined) {
          setShowCPD(cpdorgsettingRes.isCPDEnabled);
          setShowCPDLocal(cpdorgsettingRes.isCPDEnabled);
        }
        else {
          setShowCPD(false);
          setShowCPDLocal(false);
        }
      }
      else {   
        setShowCPD(false); 
        setShowCPDLocal(false);
      }
    }).catch((error) => {
      setShowCPD(false);
      setShowCPDLocal(false);
    });
  }

  const LoadExternalCertificate = (certificateId: number) => {
    if (certificateId !== -1) {
      GetExternalCertyInitEmailTemplate(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((emailresult) => {
        if (emailresult.isSuccess && emailresult.error == null) {
          if (emailresult.externalCertyEmailReportBodyItem !== null && emailresult.externalCertyEmailReportBodyItem.length > 0) {
            setEmailBodyTemplate(emailresult.externalCertyEmailReportBodyItem[0].body);
            let updatedDataNew = emailresult.externalCertyEmailReportBodyItem.map((item: any) => {
              return {
                ...item,
                isChecked: false,
              };
            });
            setEmailReportBodyResult(updatedDataNew);
          }
        }
      });

      GetOneExternalCertificate(oidcUser.access_token,
        userId,
        orgDomain,
        parseInt(orgId), certificateId, "en-au").then((result: any) => {
          if (!!result) {
            setLoading(false);
            if (result.isSuccess && result.error == null) {
              if (!!result.externalCertificateItem) {
                let externalCertificate = result.externalCertificateItem;
                setCertificateName(externalCertificate.certificateName);
                setAllowLearnerToEdit(externalCertificate.isUserEditable);
                setAllowLearnerInDays(parseInt(externalCertificate.editableGracePeriod) > 0 ? externalCertificate.editableGracePeriod.toString() : "");
                setEnableCpdPoints(externalCertificate.isCpdEnabled);
                setSelectedUnitIds(externalCertificate.unitIds);

                setselectedUnits(externalCertificate.unitIds);
                setAssignUserIds(!!externalCertificate.userIds && externalCertificate.userIds !== null ? externalCertificate.userIds.split(',').map(function(id: any) { return parseInt(id); }) : "");
                setSelectedUsersCSVInitial(externalCertificate.userIds);
                setIsActive(externalCertificate.active);
                setHasNoValidDates(externalCertificate.hasNoValidDates);
                setEnableCertificateValidityPeriod(!externalCertificate.hasNoValidDates);
              }
            } else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage('Getting External Certificate has failed!, Please try again!');
            }
          } else {
            setShowWarningModal(true);
            setHasErrored(true);
            setSaveSuccess(false);
            setModalPopupMessage('Getting External Certificate has failed!, Please try again!');
          }
          setGuideMe();
        });
    }
    else {
      setLoading(false);
    }
  }

  const handleSendWelcomeEmail = () => {
    handleModal(true);
  }

  const handleEnableCertificateValidityPeriod = () => {
    if (parseInt(externalCertificateId)  > 0) 
    {
        setShowWarningModal(true);    
        setSaveSuccess(false);
        setHasErrored(false);
      if (!enableCertificateValidityPeriod)
      {        
        setModalPopupMessage("Enforcing a Certificate Validity Period will require all currently provided Learners to enter new Validity period for this Certificate.");
        //update below message when you add logic in overnight servoce
        //setModalPopupMessage("Enforcing a Certificate Validity Period will require all currently provided Learners to enter new Validity period for this Certificate.  An email will be sent overnight to all affected Learners advising them of this change in due course.");
      } else {
        setModalPopupMessage("Toggling certificate validity period off will move any closed certificates to provided.");    
      }
    }
    setEnableCertificateValidityPeriod(!enableCertificateValidityPeriod);
 
  }

  const handleModal = (value: boolean) => {
    setModalOpen(value);
    if (value === true && parseInt(externalCertificateId) > 0) {
      GetAssignedUserIds();
    }
  }

  const GetAssignedUserIds = () => {
    GetExternalCertificateAssignedUserIds(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(externalCertificateId))
      .then((res) => {
        if (!!res && res.isSuccess) {
          setAssignUserIds(res.externalCertificateAssignedUserIds);
        }
      })
  }

  const setInitialUserListFunction = (usersData: Array<any>[]) => {
    setInitaialUsersList(usersData);
    let initialGrantedList = !!usersData !== true ? null : usersData.filter((initialItem: any) => initialItem.granted === true);
    const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
    setSelectedUsersCSVInitial(selectedIds.join(','));
    setGuideMe();
  }

  const handleTextEditorChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event !== undefined) {
      setEmailBodyTemplate(event.target.value);
    }
  };

  const setupdatedSelectedUnitsFunction = (unitIds: string[]) => {
    if (parseInt(externalCertificateId)) {
      setupdatedSelectedUnits(unitIds);
      setFinallySelectedUnitList(unitIds);
      SaveExternalCertificateAssignClick(unitIds, []);
    }
  }

  const setRecordsSearchStartedFunction = (hasSearchStarted: boolean) => {
  }

  const setRecordsSearchedFunction = (hasSearched: boolean) => {
    setHasAccountsSearched(hasSearched);
  }

  const setUserSelectionChangedFunction = (hasSelectedUsersChanged: boolean) => {
    setHasSelectedUsersChanged(hasSelectedUsersChanged);
  }

  const setUnitsLoadedFunction = (hasUnitsLoaded: boolean) => {
    setHasUnitsLoaded(hasUnitsLoaded);
    setGuideMe();
  }

  const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {
    setFinallySelectedUsersList(usersData);
  }

  const handleSameUnitModalForConfirmation = (value: boolean) => {
    setShowSameUnitModalForConfirmation(value);
  }

  const handleCancelSameUnitModalForConfirmation = () => {
    setShowSameUnitModalForConfirmation(false);
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
  }

  const handleSaveConfirmProceedSameUnitModalForConfirmation = () => {
    AssignUnitsToExternalCertificateFunction(selectedUnits.split(','));
  }

  const getValidIntNumberValue = (givenTxt: string) => {
    let numval = -1;
    if (!!givenTxt === false || givenTxt.trim() == "") {
      return -1;
    }
    try {
      numval = parseInt(givenTxt);
      if (!!numval == false || isNaN(numval) || Number.isNaN(numval)) {
        numval = -1;
      }
    }
    catch { numval = -1; }
    return numval;
  }

  const getNewlyModifiedUserIDs = (updatedSelectedUsers: any[]) => {
    let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
    let newlyGrantedUserIDsCSV = "";
    let newlyDeniedUserIDsCSV = "";

    let initialSelectedUserIDs: string[] = [];
    initialSelectedUserIDs = selectedUsersCSVInitial.split(',');
    // Get the newly granted and newly denied users
    if (!!updatedSelectedUsers && updatedSelectedUsers.length > 0 &&
      updatedSelectedUsers.some(id => !!id && !!id.userID && id.userID.toString().trim() != "")) {
      // Collect only newly granted in first loop of updated user-list checked against initial granted user-list
      for (let i = 0; i < updatedSelectedUsers.length; i++) {
        let curVal = updatedSelectedUsers[i];
        let curValId = "";
        if (!!curVal === false || !!curVal.userID === false || (curValId = curVal.userID.toString().trim()) == "" || curVal.granted !== true) {
          continue;
        }
        let numVal = getValidIntNumberValue(curValId);
        let numvalTxt = numVal.toString();
        // Extra not overlapping with initial list - collect only the denied to granted
        if (!initialSelectedUserIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
          if (curVal.granted === true) {
            newlyGrantedUserIDsCSV = ((newlyGrantedUserIDsCSV == "") ? numvalTxt : (newlyGrantedUserIDsCSV + "," + numvalTxt));
          }
        }
      }
      // Collect only newly denied in second loop of initial granted user-list checked against updated user-list
      for (let i = 0; i < initialSelectedUserIDs.length; i++) {
        let curVal = initialSelectedUserIDs[i];
        if (!!curVal === false || (curVal = curVal.toString().trim()) == "") {
          continue;
        }
        let numVal = getValidIntNumberValue(curVal);
        let numvalTxt = numVal.toString();
        // Overlapping with initial list - collect only granted to denied
        if (updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt) &&
          updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === false)) {
          newlyDeniedUserIDsCSV = ((newlyDeniedUserIDsCSV == "") ? numvalTxt : (newlyDeniedUserIDsCSV + "," + numvalTxt));
        }
      }
    }
    retVal.newlyDeniedIDsCSV = newlyDeniedUserIDsCSV;
    retVal.newlyGrantedIDsCSV = newlyGrantedUserIDsCSV;
    return retVal;
  }
  
  const handleSaveAssignment = () => {
    if (showAssignUnits) {
      setNeedLatestUnits(true);
    }
    if (showViewAccounts || showAssignAccounts) {
      setNeedLatestUsers(true);
      SaveExternalCertificateAssignClick([], finallySelectedUsersList);
    }
  }

  const handleEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if ((event.target.value != '')) {
      setEmailIsValid(false)
    }
    setEmailSubject(event.target.value);
  };

  const AssignUnitsToExternalCertificateFunction = (unitIds: string[]) => {
    handleSameUnitModalForConfirmation(false);
    setIsSavingAssignment(true);
    AssignExternalCertificateUnits(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(externalCertificateId), unitIds.join(","))
      .then((res) => {
        setIsSavingAssignment(false);
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setselectedUnits(unitIds.join(","));
        if (!!res && res.isSuccess) {
          setTrainingListUpdateRequired(true);
          setDashboardUpdateRequired(true);
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Assign units to this External Certificate have been saved successful. ");
          GetAssignedUserIds();
        }
      })
  }

  const SaveExternalCertificateAssignClick = (unitIds: string[], usersData: any[]) => {
    setNeedLatestUnits(false);
    if (showAssignUnits) {
      let anyChangeFound = false;
      if (unitIds.length === selectedUnits.split(",").length) {
        for (let curIdx = 0; curIdx < unitIds.length; curIdx++) {
          let currentValue = unitIds[curIdx];
          let unit = (selectedUnits.split(",").find(v => !!v && !!currentValue && v.toString().toLowerCase().trim() === currentValue.toString().toLowerCase().trim()));
          if (anyChangeFound === false && (unit === null || unit === undefined)) {
            anyChangeFound = true;
            break;
          }
        }
      }
      else {
        anyChangeFound = true;
      }
      if (anyChangeFound) {
        AssignUnitsToExternalCertificateFunction(unitIds);
      }
      else {
        handleSameUnitModalForConfirmation(true);
        setSameUnitModelMessage("There are no changes to Unit Assignments. Do you want to re-apply the existing Unit Assignments which will override any Account specific assignments?");
      }
    }
    if (showViewAccounts) {
      let anyUserChangeFound: boolean = false;
      let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
      let initialUserGrantedData = !!initialExternalCertificateAcceptedUsersList !== true ? null : initialExternalCertificateAcceptedUsersList.filter((initialItem) => initialItem.granted === true);
      if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length) {
        for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
          let currentItem = usersData[curIdx];
          let userdetail = (initialExternalCertificateAcceptedUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
          if (anyUserChangeFound === false && !!userdetail) {
            anyUserChangeFound = true;
            break;
          }
        }
      }
      else {
        anyUserChangeFound = true;
      }
      if (anyUserChangeFound) {
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = getNewlyModifiedUserIDs(usersData);
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

        setIsSavingAssignment(true);
        AssignExternalCertificateUsers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(externalCertificateId), newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
          .then((res) => {
            setIsSavingAssignment(false);
            setNeedLatestUnits(false);
            setNeedLatestUsers(false);
            if (!!res && res.isSuccess) {
              let initialUsersList = usersData.map((item: any) => {
                return {
                  ...item,
                  isInitialChanged: true,
                };
              });
                setInitaialUsersList(initialUsersList);
                let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
                const selectedIds = !!initialGrantedList ? initialGrantedList.map( ({userID}: any) => userID) : [];
                setSelectedUsersCSVInitial(selectedIds.join(','));

              setTrainingListUpdateRequired(true);
              setDashboardUpdateRequired(true);
              setShowWarningModal(true);
              setHasErrored(false);
              setSaveSuccess(true);
              setModalPopupMessage("Assign/Unasssign users to the external certificate have been saved successful. ");
              GetAssignedUserIds();
              if (showViewAccounts) {
                setCertificateAssignedUsersList([]);
                setNeedLatestUnits(false);
                setNeedLatestUsers(false);
                LoadExternalCertificateAssignedUsers(parseInt(externalCertificateId));
              }
            }
            else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage("Assign/Unasssign users to the external certificate have been failed.");
            }
          });
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(false);
        setModalPopupMessage("Please select the users to assign/unassign to the external certificate");
      }
    }
    if (showAssignAccounts) {
      let anyUserChangeFound: boolean = false;
      let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
      //console.log(currentUserGrantedData);
      //console.log(initaialUsersList);
      let initialUserGrantedData = !!initaialUsersList !== true ? null : initaialUsersList.filter((initialItem) => initialItem.granted === true);
      if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length) {
        // Setting external variable using the lambda of map function is avoided
        for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
          let currentItem = usersData[curIdx];
          let userdetail = (initaialUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
          if (anyUserChangeFound === false && !!userdetail) {
            anyUserChangeFound = true;
          }
        }
      }
      else {
        anyUserChangeFound = true;
      }
      if (anyUserChangeFound) {
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = getNewlyModifiedUserIDs(usersData);
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

        setIsSavingAssignment(true);
        AssignExternalCertificateUsers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(externalCertificateId), newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
          .then((res) => {           
            setIsSavingAssignment(false);
            setNeedLatestUnits(false);
            setNeedLatestUsers(false);
            
            if (!!res && res.isSuccess) {
              let initialUsersList = usersData.map((item: any) => {
                return {
                  ...item,
                  isInitial: true,
                };
              });
              setInitaialUsersList(initialUsersList);
              let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
              const selectedIds = !!initialGrantedList ? initialGrantedList.map( ({userID}: any) => userID) : [];
              setSelectedUsersCSVInitial(selectedIds.join(','));

              setTrainingListUpdateRequired(true);
              setDashboardUpdateRequired(true);
              setShowWarningModal(true);
              setHasErrored(false);
              setSaveSuccess(true);
              setModalPopupMessage("Assign/Unasssign users to the external certificate have been saved successful. ");
              GetAssignedUserIds();
            }
            else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage("Assign/Unasssign users to the external certificate have been failed.");
            }
          });
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(false);
        setModalPopupMessage("Please select the user to assign/unassign to the external certificate");
      }
    }
  }

  const handleSendEmail = () => {
    if (assignedUserIds.length > 0) {
      const nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 1);
      let assignedUsers = assignedUserIds.join(",");
      let popupMessage = '';
      addEmailingReport(oidcUser.access_token,
        orgDomain, userId, parseInt(orgId), '', '', assignedUsers,
        assignedUserIds.length, emailSubject, emailBodyTemplate,
        0, "1990-01-01", formatDate(nextDate), 2, 0,
        assignedUserIds.length, '', 4, '', 0, !!externalCertificateId ? externalCertificateId.toString() : '', 1, 0, 0, true, true, '', false).then(response => {
          handleModal(false);
          if (!!response && response.isSuccess) {

            popupMessage = popupMessage + " Welcome emails are queued and will start sending soon."
            setShowWarningModal(true);
            setModalPopupMessage(popupMessage);
            setSaveSuccess(true);
            setHasErrored(false);
          } else {
            const errorVal = popupMessage + "Sending welcome emails have failed! Please try again sending them from Email report or it will be picked up by overnight job.";
            setModalPopupMessage(errorVal);
            setHasErrored(true);
            setShowWarningModal(true);
          }
        }).catch((error) => {
          const errorVal = popupMessage + "Sending welcome emails have failed! Please try again sending them from Email report or it will be picked up by overnight job.";
          setModalPopupMessage(errorVal);
          setHasErrored(true);
          setShowWarningModal(true);
        });
    }
    else {
      setShowWarningModal(true);
      setHasErrored(false);
      setSaveSuccess(false);
      setModalPopupMessage("Can't find assigned accounts to this certificate; Please assign the users to certificate before sending an email.");
    }

  }

  const handleSubmit = () => {
    setIsSaving(true);
    setSaveSuccess(false);
    let unitIds: Array<number> = [];
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
          if (filterUnitName !== "") {
            if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
              unitIds.push(unit.unitID)
            }
          } else {
            unitIds.push(unit.unitID)
          }
        }
        getSelectedUnitIds(unit.childUnitTreeItems)
      }
    }
    getSelectedUnitIds(units);
    setSelectedUnitIds(unitIds.join(','));
    SaveExternalCertificate(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(!!externalCertificateId ? externalCertificateId : "-1"), isActive, certificateName,
      allowLearnerToEdit, parseInt(!!allowLearnerInDays ? allowLearnerInDays : "0"), hasNoValidDates, enableCpdPoints, isAnyCPDPointsChanged, selectedCPDProfileItemAssignedPointsModified,
      selectedCPDProfileID, selectedCPDProfilePeriodID, exclusionOfCertProvidedUsers) //, enableCpdPoints ? "" : csvOfAllProfilesToPeriodIDsToClearPoints)
      .then((res) => {
        setIsSaving(false);
        if (!!res && res.isSuccess) {
          if (!!res.error && res.error !== '') {
            setShowWarningModal(true);
            setHasErrored(true);
            setSaveSuccess(true);
            setModalPopupMessage(res.error);
          }
          else {
            setTrainingListUpdateRequired(true);
            setDashboardUpdateRequired(true);
            setShowWarningModal(true);
            setHasErrored(false);
            setSaveSuccess(true);
            setModalPopupMessage("External Certificate details have been saved successfully! ");
            loadOrgActiveCurrentProfiles(selectedCPDProfileID, selectedCPDProfilePeriodID, res.externalCertificateId);
            // // Instead of reload, just update the available local object with new value
            // if (!!orgCPDProfileResult.cpdProfiles && orgCPDProfileResult.cpdProfiles.length > 0 && 
            //     !!selectedCPDProfileID && !!selectedCPDProfilePeriodID) {
            //     let orgCPDProfRes:any = {
            //       isSuccess: Boolean = orgCPDProfileResult.isSuccess, error: String = orgCPDProfileResult.error,
            //       itemID: Number = orgCPDProfileResult.itemID, itemType: String = orgCPDProfileResult.itemType,
            //       cpdProfiles : orgCPDProfileResult.cpdProfiles
            //     };
            //     let cpdProfilesMod : CPDProfileWithItemPoint[] = [];
            //     cpdProfilesMod.push(...orgCPDProfileResult.cpdProfiles);
            //     orgCPDProfRes.cpdProfiles = cpdProfilesMod;
            //     let profilesMod = orgCPDProfRes.cpdProfiles as CPDProfileWithItemPoint[];
            //     for (let idxp =0; !!profilesMod && idxp<profilesMod.length; idxp++) {
            //       let cur = profilesMod[idxp];
            //       if (!!cur && !!cur.cpdProfile && cur.cpdProfile.profileID === selectedCPDProfileID && cur.cpdProfile.profilePeriodID === selectedCPDProfilePeriodID) {
            //         cur.cpdProfile.points = selectedCPDProfileItemAssignedPointsModified;
            //         break;
            //       }
            //     }
            //     setOrgCPDProfileResult(orgCPDProfRes);
            // }
            setIsAnyCPDPointsChanged(false);
            history.push({
              pathname: `../externalcertificatedetails/` + res.externalCertificateId.toString()
            });
          }
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          if (!!res && !!res.error && res.error !== '') {
            setModalPopupMessage("External Certificate details saving have been failed!;" + res.error !== '' ? res.error : "Please try again.");
          }
          setModalPopupMessage("External Certificate details saving have been failed!; Please try again.");
        }
      })
  };

  useEffect(() => {
    if (showWarningModal && saveSuccess) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 3000)
    }
  }, [showWarningModal, saveSuccess])

  useEffect(() => {
    setHasNoValidDates(!enableCertificateValidityPeriod);    
  }, [enableCertificateValidityPeriod])

  useEffect(() => {
    if (certificateAssignedUsersList.length > 0 && showViewAccounts) {
     setGuideMe();
    }
  }, [certificateAssignedUsersList])

  useEffect(() => {
     setGuideMe();
  }, [showAssignAccounts, hasAccountsSearched, enableCpdPoints])

  const updateCurrentProfileSelection = (givenSelectedCPDProfile: any, orgCPDProfRes: any = null) => {
    orgCPDProfRes = !!orgCPDProfRes ? orgCPDProfRes : orgCPDProfileResult;
    let matchedCPDProfiles : CPDProfileWithItemPoint[] | null = null;
    if (!!givenSelectedCPDProfile && !!givenSelectedCPDProfile.profileID && !!givenSelectedCPDProfile.profileName && givenSelectedCPDProfile.profileID > 0) {
      if (!!orgCPDProfRes && !!orgCPDProfRes.cpdProfiles && 
          (matchedCPDProfiles = (orgCPDProfRes.cpdProfiles as CPDProfileWithItemPoint[]).
            filter(c => !!c && !!c.cpdProfile.profileID && !!c.cpdProfile.profileName && c.cpdProfile.profileID == givenSelectedCPDProfile.profileID)) != null &&
            matchedCPDProfiles.length > 0 && matchedCPDProfiles[0] != null) {
              let matchedCPD : CPDProfileWithItemPoint = matchedCPDProfiles[0];
              setSelectedCPDProfileID(matchedCPD.cpdProfile.profileID);
              setSelectedCPDProfilePeriodID(matchedCPD.cpdProfile.profilePeriodID);
              //setSelectedCPDProfileItemAssignedPointsInitial(matchedCPD.itemAssignedPoints);
              setSelectedCPDProfileItemAssignedPointsModified(matchedCPD.itemAssignedPoints);
              setSelectedCPDProfileItemAssignedPointsModifiedTxt(matchedCPD.itemAssignedPoints.toString());
      }
      else {
      }
    }
  }

  const setAllDeniedUnitsAsCSVFunction = (csvGiven: string) => {
      setCSVOfAllDeniedUnits(csvGiven);
  }

  const getNumberFloatValueOrDefault = (givenTxt: string, defaultVal: number) => {
      if (!!givenTxt === false) {
          return defaultVal;
      }
      try {
          let givenVal = parseFloat(givenTxt);
          if (givenVal < 0 || Number.isNaN(givenVal)) {
              return defaultVal;
          }
          return givenVal;
      }
      catch {
          return defaultVal;
      }
  }

  const setSelectCPDProfilePoints = (givenValTxt: string) => {
    if (!!givenValTxt && givenValTxt.trim() != "" && givenValTxt.endsWith(".")) {
      setSelectedCPDProfileItemAssignedPointsModifiedTxt(givenValTxt.trim());
      return;
    }
    let val = getNumberFloatValueOrDefault(givenValTxt, 0.0);
    if (val != selectedCPDProfileItemAssignedPointsModified) {
      setIsAnyCPDPointsChanged(true);
    }
    setSelectedCPDProfileItemAssignedPointsModified(val);
    setSelectedCPDProfileItemAssignedPointsModifiedTxt(givenValTxt);
  }

  const handleAssignToTypeChangeExtCert = (event:any) => {
    const val = (event.target as HTMLInputElement).value;
    setAssignToTypeExtCert(val);
    if (!!val && val.trim().toLowerCase().includes("excluding")) {
      setExclusionOfCertProvidedUsers(true);
    }
    if (!!val && val.trim().toLowerCase().includes("including")) {
      setExclusionOfCertProvidedUsers(false);
    }
  }

  const canCPDProfileSelectionChangeProceed = (e: any) => {
    if (isAnyCPDPointsChanged) {
      let profID : string = !!e && !!e.target.value ? e.target.value.toString() : "";
      setSelectedCPDWhileChanging(profID);
      setWindowMidModelMessage(allResourcesToTranslations.cpdpointchangelooseconfirmforextcert);
      setShowWindowMidWarningModalForConfirmation(true);
    }
    else {
      setWindowMidModelMessage("");
      setSelectedCPDWhileChanging("");
      proceedChangingCPDProfileSelection(e);
    }
  }

  const proceedChangingCPDProfileSelection = (e: any) => {
    let profID : string = !!e && !!e.target.value ? e.target.value.toString() : "";
    proceedChangingCPDProfileSelectionGiven(profID);
  }

  const proceedChangingCPDProfileSelectionGiven = (profID: string, orgCPDProfRes: any = null) => {
    orgCPDProfRes = !!orgCPDProfRes ? orgCPDProfRes : orgCPDProfileResult;
    let matched = !!profID && !!orgCPDProfRes ? 
    orgCPDProfRes.cpdProfiles.filter((cur : any) => !!cur && !!cur.cpdProfile && 
                        parseInt(cur.cpdProfile.profileID) === parseInt(profID)) : null;
    if (!!matched)
    {
        let curSel = {  
                        value: profID, 
                        profileID: profID, 
                        profileName: (!!matched && !!matched[0] && !!matched[0].cpdProfile) ? matched[0].cpdProfile.profileName : "", 
                        currentPeriod: (!!matched && !!matched[0] && !!matched[0].cpdProfile && !!matched[0].cpdProfile.currentPeriod) ? matched[0].cpdProfile.currentPeriod : ""
                      };
        setSelectedCPDProfile(curSel);
        updateCurrentProfileSelection(curSel, orgCPDProfRes);
    }
    setIsAnyCPDPointsChanged(false);
  }

  const handleWindowMidModalForConfirmation = (value: boolean) => {
      setShowWindowMidWarningModalForConfirmation(value);
  }

  const handleCancelFromWindowMidModalForConfirmation = () => {
      setShowWindowMidWarningModalForConfirmation(false);      
      setShowWarningModal(false);
      setIsSaving(false);
      setIsCPDSelectionChanging(false);
      setWindowMidModelMessage("");
  }

  const handleCPDSelectionChangeConfirmProceedFromWindowMidModalForConfirmation = () => {
      proceedChangingCPDProfileSelectionGiven(selectedCPDWhileChanging);
      setIsCPDSelectionChanging(false);
      setShowWindowMidWarningModalForConfirmation(false);
      setWindowMidModelMessage("");
  }

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Modal modalOpen={showWindowMidWarningModalForConfirmation} setModalOpen={handleWindowMidModalForConfirmation} type="warning" hideCloseBtn={true}>
          <div className={styles.modalContentWrapper}>
              <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
              <div className={styles.inputContainer}>

                  <p>{windowMidModelMessage}</p>
              </div>
              <div
                  style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                      top: "10px",
                  }}
              >
                  <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                      <span className={styles.returnIcon} onClick={handleCancelFromWindowMidModalForConfirmation}>
                          <CloseWhiteIcon />
                      </span>
                  </CustomTooltip>
                  <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                      <span className="saveData">
                          <Button
                              onClick={handleCPDSelectionChangeConfirmProceedFromWindowMidModalForConfirmation}
                              variant="contained"
                              color="primary"
                              className="save"
                          >
                              <ProceedIcon />
                          </Button>
                      </span>
                  </CustomTooltip>
              </div>
          </div>
      </Modal>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => {
          setGuideMe();
          setIntro({ ...intro, stepsEnabled: true }) ;
        }}
          className={styles.guideMeButtonSpecific} style={{ right: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <Modal modalOpen={showSameUnitModalForConfirmation} setModalOpen={handleSameUnitModalForConfirmation} type="warning" hideCloseBtn={true}>
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
          <div className={styles.inputContainer}>

            <p>{sameUnitModelMessage}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelSameUnitModalForConfirmation}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleSaveConfirmProceedSameUnitModalForConfirmation}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <Modal modalOpen={showModal} setModalOpen={handleModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "primary", width: "500px", marginTop: "4px" }}>Send Welcome Email</h2>
          <div className="emailSubject" style={{ paddingTop: "10px" }}>
            <TextField
              className={styles.input}
              label={"Email Subject"}
              style={{ width: "99%", marginBottom: "20px" }}
              variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
              value={emailSubject}
              onChange={handleEmailSubject}
              error={emailIsValid}
              placeholder="*This Field is required"
            />
          </div>
          <div className="editor" style={{ paddingTop: "10px", width: '99%', paddingBottom: '10px' }} >
            {emailReportBodyResult.map((result: any, i) => {
              if (i == 0) {
                return (
                  <TinyMCEEditor index={i} key={"tinyMCEEditor" + i} editorData={result.body} isTemplate={false} is200Tall={false} handleTextEditorChange={handleTextEditorChange} />
                );
              }
            })}
          </div>
          <div className={styles.sendEmail}>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isHandlerBusy ? "20px" : "0px", marginTop: '13px' }}
                color="primary"
                size="10"
              />
            </div>
            <div className="sendEmail">
              <CustomTooltip displayText={["Send Email"]} >
                <span>
                  <Button
                    onClick={handleSendEmail}
                    disabled={emailSubject === ""}
                    variant="contained"
                    color="primary"
                    id="btnNewSaltSendEmailReport">
                    <SendEmailReportIcon />
                  </Button>
                </span>
              </CustomTooltip>
            </div>
          </div>
        </div>
      </Modal>
      <div>
        <div className="certificateName" style={{ backgroundColor: "white", height: "45px", paddingBottom: "10px" }}>
          <TextField
            className={styles.input}
            label={allResourcesToTranslations.certificatename}
            name="CertificateName"
            variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value)}
            placeholder={allResourcesToTranslations.requiredfield}
          />
        </div>
      </div>
      <UnbrandedCard>
        <div className={styles.mainWrapper}>
          <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
              <div className={styles.Wrapper}>
                <div className='allowLearnerToEdit' style={{ paddingTop: "15px" }}>
                  <div className='allowLearner'>
                    <div onClick={() => setAllowLearnerToEdit(!allowLearnerToEdit)}>
                      {allowLearnerToEdit &&
                        <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                      }
                      {!allowLearnerToEdit &&
                        <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                      }
                    </div>
                    <div className={styles.inputContainer}>
                      <span id="allowLearnerswitch" className={styles.labelText2} onClick={() => setAllowLearnerToEdit(!allowLearnerToEdit)}>
                        {allResourcesToTranslations.allowlearnertoedit}</span>
                    </div>
                  </div>
                  <div className={styles.inputContainer}>
                    <div className="allowLearnerDays"><TextField className={styles.input} variant="outlined" autoComplete="off"
                      InputLabelProps={{ shrink: true, }} disabled={!allowLearnerToEdit} placeholder={allResourcesToTranslations.entereditdurationdays}
                      type="text" value={allowLearnerInDays} onChange={(e) => setAllowLearnerInDays(e.target.value)} /></div>
                  </div>
                </div>
                <div className='hasNoValidDates' style={{ paddingTop: "15px", marginLeft: "-10px" }}>
                  <span>
                    <Checkbox disabled={false} id="chkhasNoValidDates"
                      size="small"
                      color="primary"
                      checked={enableCertificateValidityPeriod}
                      className={styles.checkBox}
                    // onClick={(e) => { setEnableCertificateValidityPeriod(!enableCertificateValidityPeriod); }}
                    onClick={handleEnableCertificateValidityPeriod}
                    />
                    <span id="hasNoValidDatesLabel"
                      onClick={handleEnableCertificateValidityPeriod}
                      //onClick={(e) => { setEnableCertificateValidityPeriod(!enableCertificateValidityPeriod); }}
                      style={{ fontSize: '16px', fontWeight: 'bold', color: '#21394F' }}>{allResourcesToTranslations.hasnovaliddates}</span>
                  </span>
                </div>
                <div className='activeInactive' style={{ paddingTop: "15px", marginLeft: "-10px" }}>

                  <span>
                    <Checkbox disabled={false} id="isCertyActive"
                      size="small"
                      color="primary"
                      checked={isActive}
                      className={styles.checkBox}
                      onClick={(e) => { setIsActive(!isActive); }}
                    />
                    <span id="isCertificateActiveLabel"
                      onClick={(e) => { setIsActive(!isActive); }}
                      style={{ fontSize: '16px', fontWeight: 'bold', color: '#21394F' }}>{allResourcesToTranslations.activeinactive}</span>
                  </span>
                </div>
              </div>
            </div>

          {
            showCPD &&
            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                <div className={styles.Wrapper}>
                  <div className='isCPDEnabledToggleParent' id={"isCPDEnabledToggleParentID"} style={{ paddingTop: "15px" }}>
                    <div className='isCPDEnabledToggle'>
                      <div onClick={() => setEnableCpdPoints(!enableCpdPoints)}>
                        {enableCpdPoints &&
                          <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                        }
                        {!enableCpdPoints &&
                          <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                        }
                      </div>
                      <div className={styles.inputContainer}>
                        <span id="isCPDEnabledToggleLabel" className={styles.labelText2} onClick={() => setEnableCpdPoints(!enableCpdPoints)}>
                          {allResourcesToTranslations.enablecpdpointsforextcertlabel}</span>
                      </div>
                    </div>
                  </div>
                  {     
                      enableCpdPoints && 
                      <>     
                          {
                              isOrgCPDsLoading &&
                              <>
                                  <div className={styles.loaderContainer}>
                                      {/* <CircularProgress
                                          style={{ width: isOrgCPDsLoading ? "20px" : "0px", padding: "15px 10px" }}
                                          color="primary"
                                          size="10"
                                      /> */}
                                      <nav  style={{width:"40px", height: "40px", marginLeft: "-27px"}}><img src={loadingGif} id={"cpdForExtCertLoadingCommon" } style={{width:"40px", height: "40px", marginLeft: "20px"}}  /></nav>
                                  </div>
                              </>
                          }   
                          {
                              !isOrgCPDsLoading &&
                              <>                          
                                  <div id="cpdProfileAndPeriodForExtCertDiv">
                                      <div className={styles.groupingOption} id="cpdProfileForExtCertDiv">                                                
                                          <FormControl variant="outlined" className={`${styles.formControl} cpdProfileForExtCertFormCtrl`}>                      
                                              <InputLabel id="cpdProfileIDForExtCert">{allResourcesToTranslations.profileforextcertlabel + ":"}</InputLabel>
                                              <Select
                                              value={
                                                  !!selectedCPDProfile ? selectedCPDProfile.value : "-1"
                                              }
                                              native
                                              onChange={(e : any) => { 
                                                  canCPDProfileSelectionChangeProceed(e);
                                              }}
                                              defaultValue={{value: "-1", profileID: "-1", profileName: ""}}
                                              className={styles.inputSelect}
                                              variant="outlined" 
                                              label={allResourcesToTranslations.profileforextcertlabel + ":"}
                                              id="cpdProfileIDForExtCertSelect"
                                              >
                                              {
                                                  !!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfiles && 
                                                  orgCPDProfileResult.cpdProfiles.filter((item: CPDProfileWithItemPoint) => !!item && item.cpdProfile.profileStatus === "Enabled").map((item:any) => {
                                                      return <option key={item.cpdProfile.profileID} value={item.cpdProfile.profileID}>{item.cpdProfile.profileID <= 0 ? 
                                                        //allResourcesToTranslations.profilecloningnoselection : item.cpdProfile.profileName + (!!item.cpdProfile.currentPeriod ? (" (" + item.cpdProfile.currentPeriod + ")") : "")}</option>
                                                        allResourcesToTranslations.profilecloningnoselection : item.cpdProfile.profileName}</option>
                                                  })
                                              }
                                          </Select>
                                          </FormControl>
                                      </div>
                                      <div className={styles.groupingOption2} id="cpdProfilePeriodForExtCertDiv" >                                                
                                          <FormControl variant="outlined" className={`${styles.formControl} cpdProfilePeriodForExtCertFormCtrl`}>                      
                                              <InputLabel id="cpdProfilePeriodIDForExtCert">{allResourcesToTranslations.profileperiodforextcertlabel + ":"}</InputLabel>
                                              <Select
                                              value={
                                                  !!selectedCPDProfile ? selectedCPDProfile.currentPeriod : ""
                                              }
                                              native
                                              onChange={(e : any) => { 
                                                  
                                              }}
                                              defaultValue={{value: "-1", profileID: "-1", profileName: ""}}
                                              className={styles.inputSelect}
                                              variant="outlined" 
                                              label={allResourcesToTranslations.profileperiodforextcertlabel + ":"}
                                              id="cpdProfilePeriodIDForExtCertSelect"
                                              >
                                              {
                                                  !!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfiles && 
                                                  orgCPDProfileResult.cpdProfiles.filter((item: CPDProfileWithItemPoint) => !!item && item.cpdProfile.profileStatus === "Enabled" && !!selectedCPDProfile && item.cpdProfile.profileID == selectedCPDProfile.profileID).map((item:any) => {
                                                      return <option key={item.cpdProfile.profilePeriodID} value={item.cpdProfile.profilePeriodID}>{item.cpdProfile.profilePeriodID <= 0 ? 
                                                        //allResourcesToTranslations.profilecloningnoselection : item.cpdProfile.profileName + (!!item.cpdProfile.currentPeriod ? (" (" + item.cpdProfile.currentPeriod + ")") : "")}</option>
                                                        allResourcesToTranslations.profileperiodnoselectionforextcert : item.cpdProfile.currentPeriod}</option>
                                                  })
                                              }
                                          </Select>
                                          </FormControl>
                                      </div>
                                  </div>
                                  <div className={styles.inputContainer2}>
                                    <span id="pointInputLabel" className={styles.labelText}>
                                      {allResourcesToTranslations.cpdpointinputlabelforextcert}</span>
                                  </div>
                                  <div className={styles.inputContainer} id="cpdProfilePeriodPointInputCtrl">
                                    <div className="pointInputTextCtrl"><TextField className={styles.input} variant="outlined" autoComplete="off"
                                      InputLabelProps={{ shrink: true, }} disabled={!allowLearnerToEdit} placeholder={allResourcesToTranslations.cpdpointinputlabelforextcert}
                                      type="text" value={selectedCPDProfileItemAssignedPointsModifiedTxt} 
                                      onChange={(e) => 
                                       {
                                          setSelectCPDProfilePoints(e.target.value); 
                                       }} /></div>
                                  </div>
                              </>
                          }
                      </>
                    } 
                    {  
                      enableCpdPoints &&
                      <>                   
                        <div style={{marginTop: "20px"}}>
                          <UnbrandedCard>
                            <div style={{ justifyContent: "space-between", padding: "15px 0px 20px 20px"}} key={"mainDiv1OfAssignPointsToExtCert"}>
                              <div style={{display: "grid"}} key={"mainDiv2OfAssignPointsToExtCert"}>
                                <span key="mainSpan1OfAssignPointsToExtCert" style={{fontWeight: "bold", fontSize: "15px", marginTop: "10px"}}>{enableCpdPoints ? allResourcesToTranslations.assignpointstoextcert : allResourcesToTranslations.removepointsfromextcert} </span>
                              </div>
                              <FormControl component="fieldset" style={{ width: '100%' }} className="recipient">
                                {/* <FormLabel component="legend">{allResourcesToTranslations.assignpointsto}</FormLabel> */}
                                <div className={styles.childContainer}>
                                    <RadioGroup
                                        name="assignToTypeExtCert"
                                        value={assignToTypeExtCert}
                                        onChange={handleAssignToTypeChangeExtCert}
                                        style={{ display: 'flex'}}
                                    >
                                        <FormControlLabel
                                            id="includePreviousCompletionsInAssignPointsExtCert"
                                            value={"allaccountsincludingcompletedforextcert"}
                                            control={<Radio color="primary" />}
                                            label={enableCpdPoints ? allResourcesToTranslations.allaccountsincludingcompletedforextcertprof : allResourcesToTranslations.allaccountsincludingcompletedforextcert}
                                        />
                                        <FormControlLabel
                                            id="excludePreviousCompletionsInAssignPointsExtCert"
                                            value={"allaccountsexcludingcompletedforextcert"}
                                            control={<Radio color="primary" />}
                                            label={enableCpdPoints ? allResourcesToTranslations.allaccountsexcludingcompletedforextcertprof : allResourcesToTranslations.allaccountsexcludingcompletedforextcert}
                                        />
                                    </RadioGroup>
                                </div>
                              </FormControl>
                            </div>
                          </UnbrandedCard>
                        </div>
                        <br />
                        <br />
                      </>
                    }
                    {/* <div className='cpdPoints' style={{ paddingTop: "15px" }}>
                      <div className='enablecpdPoints'>
                        <div onClick={() => setEnableCpdPoints(!enableCpdPoints)}>
                          {enableCpdPoints &&
                            <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                          }
                          {!enableCpdPoints &&
                            <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                          }
                        </div>
                        <div className={styles.inputContainer}>
                          <span id="enableCpdPointswitch" className={styles.labelText} onClick={() => setEnableCpdPoints(!enableCpdPoints)}>
                            {allResourcesToTranslations.enablecpdpoints}</span>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className='hasNoValidDates' style={{ paddingTop: "15px" }}>

                    <span>
                      <Checkbox disabled={false} id="chkhasNoValidDates"
                        size="small"
                        color="primary"
                        checked={enableCertificateValidityPeriod}
                        className={styles.checkBox}
                      // onClick={(e) => { setEnableCertificateValidityPeriod(!enableCertificateValidityPeriod); }}
                      onClick={handleEnableCertificateValidityPeriod}
                      />
                      <span id="hasNoValidDatesLabel"
                        onClick={handleEnableCertificateValidityPeriod}
                        //onClick={(e) => { setEnableCertificateValidityPeriod(!enableCertificateValidityPeriod); }}
                        style={{ fontSize: '16px', fontWeight: 'bold', color: '#21394F' }}>{allResourcesToTranslations.hasnovaliddates}</span>
                    </span>
                    </div> */}
                
                </div>
              </div> 
          }
          </div>
          <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", padding: "10px", clear: "both" }}>

            <CustomTooltip displayText={["Save External Certificate"]}>
              <span className="saveData" id="clickToTriggerSave" style={{ marginRight: "20px" }}>
                <Button disabled={(certificateName === "") || isSaving || isCPDSelectionChanging || isOrgCPDsLoading}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  className="save">
                  <SaveIcon />
                </Button>
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={["Send Welcome Email"] /*TODO: Add translations */} >
              <span className="sendWelcomeEmail">
                <Button
                  disabled={!isActive || (parseInt(externalCertificateId) === -1) || assignedUserIds.length === 0 || isSaving || isCPDSelectionChanging || isOrgCPDsLoading}
                  onClick={handleSendWelcomeEmail}
                  variant="contained"
                  color="primary"
                  id="btnNewSaltSendEmailReport">
                  <AddEmailAccountIcon />
                </Button>
              </span>
            </CustomTooltip>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                color="primary"
                size="10"
              />
            </div>
          </div>
        </div>
      </UnbrandedCard>

      {(parseInt(externalCertificateId) > 0) && (
        <div className="buttonspanel">
          <AssignButtonPanel isCalledFrom="ExternalCertificate" handleAssignUnitsClick={handleAssignUnits} handleAssignAccountsClick={handleAssignAccounts} handleViewAccountsClick={handleViewAccounts} handleResetAccountsClick={handleResetAccounts} isResetInProgress={isResettingAcceptances}></AssignButtonPanel>
        </div>
      )}
      <div id="assign">
        {(parseInt(externalCertificateId) > 0) && showAssignUnits && (
          <AssignUnits isCalledFrom="ExternalCertificate" needLatestUnits={needLatestUnits} selectedUnitIds={selectedUnits} setUpdatedSelectedUnits={setupdatedSelectedUnitsFunction} setUnitsLoaded={setUnitsLoadedFunction} setAllDeniedUnitsAsCSV={setAllDeniedUnitsAsCSVFunction}></AssignUnits>
        )}

        {(parseInt(externalCertificateId) > 0) && showAssignAccounts && (
          <div className={styles.mainWrapper}>
            <AccountManagement isCalledFrom="ExternalCertificate" needLatestUsers={needLatestUsers} setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} assignedUserIds={assignedUserIds}
              setInitialUsersData={setInitialUserListFunction} setSearchStarted={setRecordsSearchStartedFunction} setRecordsSearched={setRecordsSearchedFunction}
              policyId={parseInt(externalCertificateId)} sethasSelectionChanged={setUserSelectionChangedFunction}></AccountManagement>
          </div>
        )}

        {(parseInt(externalCertificateId) > 0) && showViewAccounts && !viewCertificateLoading && certificateAssignedUsersList.length > 0 && (
          <UserListSection usersData={certificateAssignedUsersList} isCalledFrom="ExternalCertificate" setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} needLatestUsers={needLatestUsers}
            profileID={-1} profilePeriodID={-1} selectedUnitIDs={[]} allUnitIDs={[]} filterFirstName={""} filterLastName={""} filterUserName={""} filterEmail={""} />
        )}

        {(parseInt(externalCertificateId) > 0) && showViewAccounts && viewCertificateLoading && (
          <LoaderContainer />
        )}

        {(parseInt(externalCertificateId) > 0) && showViewAccounts && !viewCertificateLoading && certificateAssignedUsersList.length <= 0 && (
          <BrandedCard>
            <div className={styles.noResultsCard} style={{ marginTop: "20px" }}>
              <div>No Results to Display</div>
            </div>
          </BrandedCard>
        )}
      </div>
      {(parseInt(externalCertificateId) > 0 &&
        ((showAssignUnits && hasUnitsLoaded) ||
          (showAssignAccounts && hasAccountsSearched) ||
          (showViewAccounts && !viewCertificateLoading && certificateAssignedUsersList.length > 0))) && (
          <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
            <CustomTooltip displayText={showAssignUnits ? ["Assign Units"] : (showAssignAccounts ? ["Assign Accounts"] : (showViewAccounts ? ["Unassign Accounts"] : ["Save"]))}>
              <span className="saveAssignedData" style={{ marginRight: "-5px" }}>
                <Button
                  onClick={handleSaveAssignment}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <SaveIcon />
                </Button>
              </span>
            </CustomTooltip>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isSavingAssignment ? "20px" : "0px", padding: "15px 10px" }}
                color="primary"
                size="10"
              />
            </div>
          </div>
        )}
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};
export default ExternalCertificateDetails;
