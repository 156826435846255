//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

import { useHistory, useLocation, useParams } from "react-router";
// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/organisationmanagement.module.css";


//General Functions
import getColor from '../../../functions/getColor';
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';
import GetOrgAdmins from '../../../API/Administration/Organisation/GetOrgAdmins';
import { TableContainer, TablePagination } from '@material-ui/core';
import { OrgAdmins } from '../../../globalTypes';
import DeleteIcon from '../../../CustomIcons/DeleteIcon';

import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon"; 
import UnbrandedCard from '../../../Components/UnbrandedCard';

const OrganisationAdministrators = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [orgAdmins, setorgAdmins] = useState<Array<OrgAdmins>>([]);
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);
  const history = useHistory();
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [firstNameDescending, setFirstNameDescending] = React.useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] = React.useState<boolean>(false);
  const [emailDescending, setEmailDescending] = React.useState<boolean>(false);
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("");
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [orgAmindID, setorgAmindID] = useState<number>(0);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".FirstName",
        title: "First Name",
        intro: "Click to sort listing of Org Admins by First Name."
      },

    ]
  })

  useEffect(() => {
    let updateSteps = [...intro.steps]
    updateSteps.push(
      {
        element: ".LastName",
        title: "Last Name",
        intro: "Click to sort listing of Org Admins by Last Name."
      },
      {
        element: ".Email",
        title: "Email Address",
        intro: "Click to sort listing of Org Admins by Email Address."

      },
      {
        element: ".Remove",
        title: "Remove Administrator",
        intro: "Click the trash icon in this column to remove the relevant Org Admin.",
      }
      
    )
    setIntro({ ...intro, steps: [...updateSteps] })

  }, [])

  const loadOrgAdmins = () => {
    GetOrgAdmins(oidcUser.access_token, userId, 0, orgDomain, parseInt(orgId), "").then((result: any) => {
      if (!!result && result.isSuccess) {
        if (result.orgAdmins !== null) {
          setorgAdmins([...result.orgAdmins]);
          setLoading(false);
        }
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load Organisation links!");
      }
      setLoading(false);
    });
  };

  const sortResultsByAsync = async (
    sortBy: "FirstName" | "LastName" | "Email" | "LastLogin",
    sortGivenItemInDesc: boolean
  ) => {
    if (orgAdmins == null || orgAdmins.length <= 0) {
      return orgAdmins;
    }
    var result = orgAdmins.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setorgAdmins(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setorgAdmins(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setorgAdmins(result);
        break;


      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy: "FirstName" | "LastName" | "Email" | "LastLogin",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setorgAdmins(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };
  useEffect(() => {
    setTitle(allResourcesToTranslations.organisationadministrators)
    setTitleEnglish(pageTitles.orgadmin);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.organisationadministrators)    
    loadOrgAdmins();
    return () => { };
  }, []);
  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }

  const handleDelete = (index: number) => {
    handleConfirmModalDelete(true);
    setorgAmindID(index);
  }
  const handleConfirmModalDelete = (value: boolean) => {
    setshowConfirmModalDelete(value);
  }
  const handleCancelConfirmPopupDelete = () => {
    handleConfirmModalDelete(false);
    setIsSaving(false);
  }

  const handleProceedDelete = () => {
    handleConfirmModalDelete(false);
    if (userId > 0) {
      GetOrgAdmins(oidcUser.access_token, userId, orgAmindID, orgDomain, parseInt(orgId), "delete").then((result) => {
        if (result.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          loadOrgAdmins();
          setModalPopupMessage("Organisation admin has been removed successfully!");

        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Organisation admin  removed failed!");
        }
      })
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddNewOrgAdmin = () => {
    history.push({
      pathname: `organisationadmindetail` 
  });
  }
  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>

      <Modal modalOpen={showConfirmModalDelete} setModalOpen={handleConfirmModalDelete} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              You are about to remove the Administration rights.<br></br> <br></br> Are you sure you wish to proceed?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelConfirmPopupDelete}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceedDelete}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <UnbrandedCard>
      <div className={styles.mainWrapper}>
      <div className={styles.Wrapper}>  
            <div style={{ width: '100%' }} >
              <div style={{float:"left"}}>
                <CustomTooltip displayText={[allResourcesToTranslations.addneworgadmin]}>               
                    <Button                    
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={`saveData addNewOrgAdmin`}
                        onClick={handleAddNewOrgAdmin}                     
                      >
                        <span style={{marginBottom: "-5px"}}>
                          <EditIcon/>
                        </span><span style={{marginLeft: "5px"}}> {allResourcesToTranslations.addneworgadmin}</span>
                      </Button>                     
                </CustomTooltip>
              </div>
            </div>  
            <div style={{ width: '100%', clear: "both" }} >
              {orgAdmins != null && orgAdmins.length > 0 && (
                <div>
                  <div className={styles.paginationControlsContainer}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={orgAdmins.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                  <div className={`${styles.tableSection}`}>
                    <div className={styles.tableContainer}>
                      <BrandedCard>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color, borderRadius: "8px 0 0 0"
                                  }} className='FirstName'
                                  align="left"
                                >
                                  <div
                                    className={styles.tableHeadCellContainer}
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      onClick={() => {
                                        setFirstNameDescending(!firstNameDescending);
                                        sortResultsBy(
                                          "FirstName",
                                          !firstNameDescending
                                        );
                                      }}
                                    >
                                      {"First Name" /* TODO translate */}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "FirstName" &&
                                              firstNameDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickFirstNameAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "FirstName" &&
                                              firstNameDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickFirstNameDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                  }} className='LastName'
                                  align="left"
                                >
                                  <div
                                    className={styles.tableHeadCellContainer}
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      onClick={() => {
                                        setLastNameDescending(!lastNameDescending);
                                        sortResultsBy("LastName", !lastNameDescending);
                                      }}
                                    >
                                      {"Last Name" /* TODO translate */}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "LastName" &&
                                              lastNameDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickLastNameAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "LastName" &&
                                              lastNameDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickLastNameDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                  }} className='Email'
                                  align="left"
                                >
                                  <div
                                    className={styles.tableHeadCellContainer}
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      onClick={() => {
                                        setEmailDescending(!emailDescending);
                                        sortResultsBy("Email", !emailDescending);
                                      }}
                                    >
                                      {"Email" /* TODO translate */}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "Email" &&
                                              emailDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickEmailAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "Email" &&
                                              emailDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickEmailDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                    borderRadius: "0 8px 0 0",
                                  }} className='Remove'
                                  align="left"
                                >
                                  <span> Remove Administrator</span>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {orgAdmins
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((result: any, i) => {
                                  return (
                                    <TableRow
                                      key={`reportScheduler-${i}`}
                                      style={rowBackgroundColor(i)}
                                    >
                                      <TableCell align="left">{result.firstName}</TableCell>
                                      <TableCell align="left">{result.lastName}</TableCell>
                                      <TableCell align="left">{result.email}</TableCell>
                                      <TableCell align="left">
                                        <CustomTooltip displayText={["Delete"]}>
                                          <Button className="ruledelete" onClick={() => handleDelete(result.userID)} variant="contained" style={{ backgroundColor: "red" }}>
                                            <DeleteIcon />
                                          </Button>
                                        </CustomTooltip>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </BrandedCard>
                    </div>
                  </div>
                  <div className={styles.paginationAddedUsersControl}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={orgAdmins.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
      </div>
      </div>
      </UnbrandedCard>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
   
    </>
  );
};
export default OrganisationAdministrators;
