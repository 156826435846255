//React + Typescript
import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
//Contexts
import { SessionContext } from '../../contexts/SessionContext';
//Components
import LectoraQuizPlayer from '../../Players/Lectora/LectoraQuizPlayer';
//Styles
import styles from '../../styles/quizWrapper.module.css';

export const Lesson = (props: any): JSX.Element => {
    const { setCurSelectedModuleTechType, multiSectionCurrentSelection } = useContext(SessionContext)

    useEffect(() => {
        setCurSelectedModuleTechType(learningModule.quizType)      
    });

    const state = props.location.state || (window as any).MOBILE_LOCATION_STATE
    if (!state) return <Redirect to={`/mytraining`} push/>  //If page is refreshed it will go back to the module so the state can be passed down again

    const {learningModule} = state

    return(
        <div className={styles.fillScreen}>
            <LectoraQuizPlayer key={"lprefix1" + (!!multiSectionCurrentSelection ? multiSectionCurrentSelection : "")} learningModuleData={learningModule} isLesson={true}/>
        </div>
    ) 
}

export default Lesson;
