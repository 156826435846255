import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepThreeActive = () => (    
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="#21394F"/>
<rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#21394F"/>
<path d="M24.7246 15.916C24.7246 16.5736 24.5879 17.1432 24.3145 17.625C24.041 18.1068 23.6699 18.5007 23.2012 18.8066C22.7389 19.1126 22.2181 19.3372 21.6387 19.4805V19.5391C22.7845 19.6823 23.6536 20.0339 24.2461 20.5938C24.8451 21.1536 25.1445 21.9023 25.1445 22.8398C25.1445 23.6732 24.9395 24.4186 24.5293 25.0762C24.1257 25.7337 23.5007 26.2513 22.6543 26.6289C21.8079 27.0065 20.7174 27.1953 19.3828 27.1953C18.5951 27.1953 17.8594 27.1302 17.1758 27C16.4987 26.8763 15.8607 26.6842 15.2617 26.4238V23.8555C15.8737 24.168 16.515 24.4056 17.1855 24.5684C17.8561 24.7246 18.4811 24.8027 19.0605 24.8027C20.1413 24.8027 20.8965 24.6172 21.3262 24.2461C21.7624 23.8685 21.9805 23.3411 21.9805 22.6641C21.9805 22.2669 21.8796 21.9316 21.6777 21.6582C21.4759 21.3848 21.1243 21.1764 20.623 21.0332C20.1283 20.89 19.4349 20.8184 18.543 20.8184H17.459V18.5039H18.5625C19.4414 18.5039 20.1087 18.4225 20.5645 18.2598C21.0267 18.0905 21.3392 17.8626 21.502 17.5762C21.6712 17.2832 21.7559 16.9512 21.7559 16.5801C21.7559 16.0723 21.5996 15.6751 21.2871 15.3887C20.9746 15.1022 20.4538 14.959 19.7246 14.959C19.2689 14.959 18.8522 15.0176 18.4746 15.1348C18.1035 15.2454 17.7682 15.3822 17.4688 15.5449C17.1693 15.7012 16.9056 15.8542 16.6777 16.0039L15.2812 13.9238C15.8411 13.5202 16.4954 13.1849 17.2441 12.918C17.9993 12.651 18.8978 12.5176 19.9395 12.5176C21.4108 12.5176 22.5762 12.8138 23.4355 13.4062C24.2949 13.9987 24.7246 14.8353 24.7246 15.916Z" fill="white"/>
</svg>


);

export default StepThreeActive