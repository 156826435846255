import React, { useState, useEffect, useContext } from "react";

//Styles
import styles from "../../styles/organisationmanagement.module.css";

import { useHistory} from "react-router";
import { TranslationsContext } from "../../contexts/TranslationsContext";
import { UIContext } from "../../contexts/UIContext";
import LoaderContainer from "../../Components/LoaderContainer";
import { useReactOidc } from "@axa-fr/react-oidc-context";

import { Button,  Table, TableBody, TableCell, TableContainer, TableHead,  TableRow } from "@material-ui/core";
import UnbrandedCard from "../../Components/UnbrandedCard";

import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import GetCourseAdditionalResourcesList from "../../API/Administration/CourseSettings/GetCourseAdditionalResourcesList";
import { CourseAdditionalResources } from "../../globalTypes";

import PageviewIcon from '@material-ui/icons/Pageview';
interface Props {
	courseIdToEdit: number;
}


const CourseAdditionalRecourseList = ({courseIdToEdit }: Props) => {
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute } =
    useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const {
      profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
      ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
      : "";
  const [loading, setLoading] = useState<boolean>(true);
  const { theme: { color } } = useContext(CorperateThemeContext);
  const [CourseAdditionalResources, setCourseAdditionalResources] = useState<Array<CourseAdditionalResources>>([]);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
    
  useEffect(() => {
    updateBackButtonRoute("/mytraining");
    LoadCourseRecourse();
  }, []);


  const LoadCourseRecourse = () => {
    GetCourseAdditionalResourcesList(oidcUser.access_token,
        userId,
        orgDomain,
        parseInt(orgId), courseIdToEdit).then((result: any) => {
            if (!!result && result.isSuccess && result.error == null) {
                setCourseAdditionalResources([...result.courseAdditionalResourcesListItems]);
                setLoading(false);
            } 
        });
}

  
  if (loading) return <LoaderContainer />;  
  return ( 
   <>    
      <UnbrandedCard>      
            {CourseAdditionalResources != null && CourseAdditionalResources.length > 0 && (
              <div>                  
                <div  className={`${styles.tableSection}`}>
                  <div className={styles.tableContainer}>
                   
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>   
                              <TableCell                                  
                                  align="left">
                                  <div className={styles.tableHeadCellContainer}>
                                      <div>Name
                                      </div>
                                  </div>
                              </TableCell>
                              <TableCell                                 
                                  align="left">
                                  <div className={styles.tableHeadCellContainer}>
                                      <div>Type
                                      </div>
                                  </div>
                              </TableCell>
                              <TableCell                               
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                    <div>
                                        Action
                                    </div>
                                </div>
                            </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {CourseAdditionalResources
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((result: any, i) => {
                                return (
                                  <TableRow
                                    key={`policybuilder-${i}`}
                                  >
                                    <TableCell align="left" width={"70%"}>
                                     {result.name} 
                                    </TableCell> 
                                    <TableCell align="left" width={"20%"}>
                                      {result.fileExtension}
                                    </TableCell> 
                                    <TableCell align="left" width={"10%"}>                                     
                                      <a href={`${result.strResourcesLink}`} target="_blank">
                                        <Button variant="contained" color="primary">
                                        <PageviewIcon />
                                        </Button>
                                    </a>


                                    </TableCell>                   
                                    {/* <TableCell align="center" className="dateEnabledToggele">
                                    <CustomTooltip displayText={[allResourcesToTranslations.showwelcomemessage]}>
                                        <Button variant="contained" color="primary">
                                          <PageviewIcon />
                                        </Button>
                                    </CustomTooltip>
                                    </TableCell> */}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                 
                  </div>
                </div>
                              
              </div>
            )}         
      </UnbrandedCard>
     </>
  );
};

export default CourseAdditionalRecourseList;
