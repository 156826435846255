//React + Typescript
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { LoaderContext } from '../../contexts/LoaderContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
//API Functions
import { addError } from '../../API/AddError';
import { generateCertificate } from '../../API/GenerateCertificate';
import { deleteGeneratedCertificate } from '../../API/DeleteGivenGeneratedCertificate';
//Components
import PDFPlayer from '../../Players/PDFPlayer/PDFPlayer';
import LoaderContainer from '../../Components/LoaderContainer';
//MUI Components
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//Styles
import styles from "../../styles/pdfplayer.module.css";

export const CertificateWrapper = () => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { EnableActivityLogs, latestUserCPDs, selectedCPDIndex } = useContext(UIContext);
    const { color } = useContext(CorperateThemeContext).theme; 
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [certUrl, setCertUrl] = useState<string>("");
    const [certFullpath, setCertFullPath] = useState<string>("");
    const { setMessage } = useContext(LoaderContext)
    const { courseId } = useParams<{ courseId: string }>();
    const { oidcUser } = useReactOidc();
    const { profile: { profile, user_id, website }} = oidcUser || {};
    const orgId = profile ? parseInt(profile) : -1;
    const userId = user_id ? parseInt(user_id) : 0;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  
    const handleExit = () => {
      deleteGeneratedCertificate(
        oidcUser.access_token, 
        userId,
        orgDomain,
        certFullpath,
        !orgId? 0 : orgId 
      )
      addError(oidcUser.access_token, userId, orgDomain, "Closed certificate viewer", null, "CertificateWrapper.tsx", 6, EnableActivityLogs)
      history.goBack()
    };

    const isMounted = useRef<boolean>(false)
    useEffect(() => {
      isMounted.current = true;
      const isProductionServer = process.env.REACT_APP_ENV === "prodserver";
      setMessage(allResourcesToTranslations.generatingcertificate);
      const targetFolder = isProductionServer ? `${process.env.REACT_APP_BASE_DIR}\\Files\\GeneratedCertificates` : `${process.env.REACT_APP_BASE_DIR}\\files\\General\\Scorm\\Content`;
      const certUrl = isProductionServer? `/GeneratedCertificates` : `/General/Scorm/Content`;
      generateCertificate(
        oidcUser.access_token,
        userId,
        orgDomain,
        parseInt(courseId),
        "en-AU",
        targetFolder,
        color,
        !!latestUserCPDs && latestUserCPDs.length > selectedCPDIndex && selectedCPDIndex >= 0 ? latestUserCPDs[selectedCPDIndex].id : -1,
        !!latestUserCPDs && latestUserCPDs.length > selectedCPDIndex && selectedCPDIndex >= 0 ? latestUserCPDs[selectedCPDIndex].periodID : -1
      ).then((data) => {
        if (isMounted.current && !!data && !!data.fullFilePath) {
          const fileName = data.fullFilePath.split("\\").slice(-1).pop();
          setCertFullPath(data.fullFilePath);
          setCertUrl(`${certUrl}/${fileName}`);
          setLoading(false);
        }
      });
      return () => {
        isMounted.current = false;
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) {
        return <LoaderContainer/>
    } else {
      return(
        <>
        <PDFPlayer url={certUrl}/> 
        <div className={styles.controls}>
          <Button
            id="exitButton"
            onClick={() => handleExit()}
            variant="contained"
            color="primary"
          >
              <ExitToAppIcon/>
            {allResourcesToTranslations.exit}
          </Button>
        </div>
        </>
    ) 
    }
}

export default CertificateWrapper