//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps } from "intro.js-react";
//API Functions 
import styles from "../../../styles/addAccount.module.css";

//General Functions
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button';
import SaveIcon from '../../../CustomIcons/SupportDetailSaveIcon';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import SaveCourse from '../../../API/Administration/CourseSettings/SaveCourse';
import { CircularProgress, Table, TableBody, TableCell,  TableHead, TableRow, TextField } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import ToggleLeftIcon from '../../../CustomIcons/ToggleLeftIcon';
import GetCourseDetails from '../../../API/Administration/CourseSettings/GetCourseDetails';
import CourseFileDropBox from './CourseFileDropBox';
import getColor from '../../../functions/getColor';
import PublishCourseContentGivenFileData from '../../../API/Administration/CourseSettings/PublishCourseContentGivenFileData';
import { CourseSCORMcontentList, CourseAdditionalResources } from "../../../globalTypes";
import CancelIcon from '../../../CustomIcons/SupportDetailCancelIcon';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CourseReupload from './CourseReupload';
import CourseProgressBar from './CourseProgressBar';

const AddCourse = (props: any) => {
  const { courseIdToEdit } = useParams<{ courseIdToEdit: string }>();
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, superAdminSelectedBasicOrgData } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);
  const [courseID, setCourseID] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [note, setNote] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [updatedBy, setUpdatedBy] = useState<number>(0);
  const [editedmoduleName, seteditedmoduleName] = useState<string>("");
  const [editedmoduleID, seteditedModuleID] = useState<number>(0);
  const [selectedFileData, setSelectedFileData] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [publishingCourseType, setPublishingCourseType] = useState<string>("");
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const [courseSCORMcontentList, setCourseSCORMcontentList] = useState<Array<CourseSCORMcontentList>>([]);
  const [duration, setDuration] = useState<number>(0.00);
  const [durationTxt, setDurationTxt] = useState<string>("");

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".Name",
        title: "Name",
        intro: allResourcesToTranslations.coursenameguideme
      },

    ]
  })
  const setGuideMe = (courseSCORMcontentList: boolean = false) => {

    let updateSteps = [];

    updateSteps.push(
      {
        element: ".Name",
        title: "Name",
        intro: allResourcesToTranslations.addcoursenameguideme
      },
      {
        element: ".Description",
        title: "Description",
        intro: allResourcesToTranslations.addcoursedescriptionguideme
      },
      {
        element: ".Duration",
        title: "Duration",
        intro: allResourcesToTranslations.addcoursedurationguideme
      },
      {
        element: ".Active",
        title: "Active",
        intro: allResourcesToTranslations.addcourseactivestatusguideme
      },
      {
        element: ".Filedropbox",
        title: "Upload course content",
        intro: allResourcesToTranslations.addcoursedragfileguideme
      });
      if(courseSCORMcontentList)
      {
            updateSteps.push(
          {
            element: ".orderguideme",
            title: "Order",
            intro: allResourcesToTranslations.orderguideme
          },
          {
              element: ".moduleguideme",
              title: "Module Name",
              intro: allResourcesToTranslations.moduleguideme
          },
          {
              element: ".modifyguideme",
              title: "Modify",
              intro: allResourcesToTranslations.modifyguideme

          },
          {
            element: ".reuploadguideme",
            title: "Reupload",

            intro: allResourcesToTranslations.reuploadguideme
          },
          {
              element: ".activeguideme",
              title: "Active",
              intro: allResourcesToTranslations.activeguideme
          },
          {
              element: ".reorderguideme",
              title: "Reorder",
              intro: allResourcesToTranslations.reorderguideme            
          });        
      }    
      
      updateSteps.push(      
      {
        element: ".saveData",
        title: "Save Course Details",
        intro: allResourcesToTranslations.addcoursesaveguideme
      });
    
    setIntro({ ...intro, steps: [...updateSteps] });
  };
 
  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };


  useEffect(() => {
    setLoading(false);
    setGuideMe(false);
    setTitle(allResourcesToTranslations.publishcourse)
    setTitleEnglish(pageTitles.publishcourse);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.publishcourse);
    if (parseInt(courseIdToEdit) > 0) {
      LoadCourseDetail();
    }
    return () => { };
  }, [superAdminSelectedBasicOrgData]);

  const LoadCourseDetail = () => {
    GetCourseDetails(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      parseInt(courseIdToEdit),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
        if (result != null && result.isSuccess && result.error == null) {
          setCourseID(result.getCourseDetailsItems[0].courseID);
          setName(result.getCourseDetailsItems[0].name);
          setDuration(result.getCourseDetailsItems[0].durationInMins);
          setDurationTxt(result.getCourseDetailsItems[0].durationInMins.toString());
          setNote(result.getCourseDetailsItems[0].notes);
          setActive(result.getCourseDetailsItems[0].active);
          setCourseSCORMcontentList([...result.getCourseModuleDetailsItems]);
          seteditedModuleID(result.getCourseModuleDetailsItems[0].moduleID);
          setLoading(false);
          setGuideMe(true);
        }
      });

  } 

  const BackButtonRoute = () => {
    history.push({
      pathname: `/coursedetails`
    });
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])


  const redirectCourseAddotional = (addedCourseID: number) => {
    history.push({
      pathname: `/courserecourse/` + addedCourseID
    });
  }

  const handleSave = () => {
    setIsSaving(true);
    SaveCourse(oidcUser.access_token, userId, orgDomain,
      parseInt(orgId),
      courseID > 0 ? courseID : 0,
      name,
      duration,
      note,
      active,
      userId,
      updatedBy,
      courseID > 0 ? "update" : "add",
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : ""),
      courseSCORMcontentList
    ).then((result: any) => {
      if (result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setModalPopupMessage("Course Details has been saved successfully.");
        //setIsSaving(false);  
        let addedCourseID = courseID > 0 ? courseID : result.addedCourseID;
        redirectCourseAddotional(addedCourseID);

      }
      else {
        setCourseSCORMcontentList([]);
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
         //setModalPopupMessage(result.error);
        setModalPopupMessage("File type not supported. Please check the file format and try again or contact your Salt Administrator for assistance.");
      }
    });
  };

  const handleFileUpload = (byteArray: string, fileExtension: string, filename: string) => {
    setSelectedFileData(byteArray);
    setSelectedFile(filename);
    setShowWarningModal(false);
    setModalPopupMessage("");
    setSelectedFile(filename);
    setIsFileUploading(true);
    PublishCourseContentGivenFileData(oidcUser.access_token, userId, orgDomain, parseInt(orgId), courseID > 0 ? courseID : 0, byteArray, fileExtension, filename).then((res) => {
      if (!!res && res.isSuccess) {
        let currentSCORMcontentList: Array<CourseSCORMcontentList> = []
        currentSCORMcontentList = [...courseSCORMcontentList];
        currentSCORMcontentList.push(...res.getSCORMcontentItems);
        let i = 1;
        let Curupdatelist = currentSCORMcontentList.map((item: CourseSCORMcontentList) => {
          return {
            ...item,
            sequence: i++
          }
        });

        setCourseSCORMcontentList(Curupdatelist);

        // setSelectedFile(res.fileName)
        // setSelectedFileData(res.fileData)
        // setPublishingCourseType(res.publishingCourseType);
        // setScormSchemaVersion(res.scormSchemaVersion);
        // setPublishingFolder(res.publishingFolder);


        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setIsSaving(false);
        setModalPopupMessage("Course content published successfully.");
        setSelectedFile(res.fileName)
        //resolve({ success: true })
        setIsFileUploading(false);

      }
      else {
        setSelectedFile("")
        //setImportUsersSummaryId(0);
        //resolve({ success: false });
        setIsFileUploading(false);
        setHasErrored(true);
        setShowWarningModal(true);
        setSaveSuccess(false);
        //setModalPopupMessage(res.error + " Reading file has failed!");
        setModalPopupMessage("File type not supported. Please check the file format and try again or contact your Salt Administrator for assistance.");
        // handleResetFileUpload();
      }
    })
  }

  const handleFileReUpload = (byteArray: string, fileExtension: string, filename: string) => {
    setSelectedFileData(byteArray);
    setSelectedFile(filename);
    setShowWarningModal(false);
    setModalPopupMessage("");
    setSelectedFile(filename);
    setIsFileUploading(true);
    PublishCourseContentGivenFileData(oidcUser.access_token, userId, orgDomain, parseInt(orgId), courseID > 0 ? courseID : 0, byteArray, fileExtension, filename).then((res) => {
      if (!!res && res.isSuccess) {

        const filteredCourseList = courseSCORMcontentList.map((item: CourseSCORMcontentList) => {
          if (!!item && !!item.moduleName && item.moduleID === editedmoduleID) {
            return {
              ...item,
              moduleName: res.getSCORMcontentItems[0].moduleName,
              publishingCourseType: res.getSCORMcontentItems[0].publishingCourseType,
              publishingFolder: res.getSCORMcontentItems[0].publishingFolder,
              fileData: res.getSCORMcontentItems[0].fileData,
              fileName: res.getSCORMcontentItems[0].fileName
            }
            // return {
            //   ...item,
            //   lessonLaunchPoint:res.lessonLaunchPoint,
            //   qfs: res.qfs,
            //   quizLaunchPoint: res.quizLaunchPoint,
            //   courseName: res.courseName,
            //   moduleName: res.moduleName,
            //   active:res.active,
            //   moduleID: res.moduleID,
            //   sequence: res.sequence,
            //   publishingCourseType: res.publishingCourseType,
            //   publishingFolder:  res.publishingFolder,  
            //   fileData: res.fileData,
            //   fileName: res.fileName
            // }           
          }
          return item;
        });
        setCourseSCORMcontentList([...filteredCourseList]);
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setIsSaving(false);
        setModalPopupMessage("Course content published successfully.");
        setSelectedFile(res.fileName)
        //resolve({ success: true })
        setIsFileUploading(false);

      }
      else {
        setSelectedFile("")
        //setImportUsersSummaryId(0);
        //resolve({ success: false });
        setIsFileUploading(false);
        setHasErrored(true);
        setShowWarningModal(true);
        setSaveSuccess(false);
        //setModalPopupMessage("Reading file has failed!");
        setModalPopupMessage("File type not supported. Please check the file format and try again or contact your Salt Administrator for assistance.");
        // handleResetFileUpload();
      }
    })
  }

  const handleSetSelectedFileName = (filename: string) => {
    setSelectedFile(filename);
  }

  const handleModuleActive = (modulename: string, action: boolean, sequence: number, moduleID: number) => {
    seteditedModuleID(moduleID);
    const filteredCourseList = courseSCORMcontentList.map((item: CourseSCORMcontentList) => {
      if (!!item && !!item.moduleName && item.moduleName === String(modulename)) {
        if (!!item.moduleName && (!!filteredCourseList !== true ||
          (!!filteredCourseList && item.moduleName.trim().toLowerCase().includes(modulename.trim().toLowerCase()))) && item.sequence === sequence) {
          return {
            ...item,
            active: action
          }
        }
      }
      return item;
    });
    setCourseSCORMcontentList([...filteredCourseList]);

  }

  const handleSaveModule = (moduleName: any, editedmoduleName: string, sequence: number) => {
    const filteredCourseList = courseSCORMcontentList.map((item: CourseSCORMcontentList) => {
      if (!!item && !!item.moduleName && item.moduleName === moduleName) {
        if (!!item.moduleName && item.moduleName.trim().toLowerCase().includes(moduleName.trim().toLowerCase()) && item.sequence === sequence) {
          return {
            ...item,
            moduleName: editedmoduleName,
            isBeingEdited: false
          }
        }
      }
      return item;
    });
    setCourseSCORMcontentList([...filteredCourseList]);

  };

  const handleEdit = (modulename: any, sequence: number, moduleID: number) => {
    seteditedModuleID(moduleID);
    let updatedSelectedSCORMcontentList = courseSCORMcontentList.map((item: CourseSCORMcontentList) => {
      if (!!item) {
        handleCancel(item.moduleName);
      }
      if (item.moduleName === modulename && item.sequence == sequence) {
        item.isBeingEdited = true;
      }
      return item;
    });
    setCourseSCORMcontentList([...updatedSelectedSCORMcontentList]);
  };

  const handleCancel = (name: any) => {
    const updatedSelectedEmailTemplates = courseSCORMcontentList.map((item: CourseSCORMcontentList) => {
      if (!!item && item.moduleName === name) {
        item.isBeingEdited = false;
      }
      return item;
    });
    setCourseSCORMcontentList([...updatedSelectedEmailTemplates]);
  };

  const handleDragEnd = (e: any) => {
    if (!e.destination) return;
    const rows = courseSCORMcontentList; // Define rows based on courseSCORMcontentList
    let tempData = Array.from(rows);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    tempData = tempData.map((item: CourseSCORMcontentList, index: number) => {
      return {
        ...item,
        sequence: index + 1
      }
    });
    setCourseSCORMcontentList(tempData);
  };


  const getNumberFloatValueOrDefault = (givenTxt: string, defaultVal: number) => {
    if (!!givenTxt === false) {
        return defaultVal;
    }
    try {
        let givenVal = parseFloat(givenTxt);
        if (givenVal < 0 || Number.isNaN(givenVal)) {
            return defaultVal;
        }
        return givenVal;
    }
    catch {
        return defaultVal;
    }
}

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <div style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}>
        <CourseProgressBar courseIdToEdit={parseInt(courseIdToEdit)} step='1' />
      </div>
      <BrandedCard>
        <div className={styles.reportWrraper}>
          <div className={styles.accountDetails} >
            {"Add Course "/* TODO translate */}
          </div>
          <div className={styles.inputContainer}>
            <div className="Name">
              <TextField
                className={styles.input}
                label={"Course Name"}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="*This Field is required"
              />
            </div>
          </div>
          <div className={styles.inputContainermultiline} style={{ height: "100px" }}>
            <div className="Description">
              <TextField
                className={styles.input}
                label={"Description"}
                variant="outlined"
                autoComplete="off"
                multiline={true}
                rows={4}
                InputLabelProps={{ shrink: true }}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="*This Field is required"
              />
            </div>
          </div>         
          <div className={styles.inputContainer}>
            <div className="Duration"> 
                <TextField className={styles.input} 
                label={"Duration (minutes)"} variant="outlined"
                autoComplete="off" InputLabelProps={{ shrink: true, }}
                value={(!!durationTxt && durationTxt.trim() != "") ? durationTxt : ""} 
                onChange={(e) => {setDurationTxt(e.target.value); setDuration(getNumberFloatValueOrDefault(e.target.value, 0.00)) }} />
            </div>
          </div>
          <div  className={`${styles.inputContainer} ${'Active'}`}>
            <div  onClick={() => setActive(!active)}>
              <span style={{ paddingRight: "5px", float: "left" }} >{active ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
            </div>
            <div style={{ paddingTop: "3px" }}>
              <span>Active Course</span>
            </div>
          </div>
          <div className={`${styles.tableSection}`} style={{ paddingTop: "20px" }}> 
            <div className={styles.tableContainer}>
              <BrandedCard>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "8px 0 0 0",
                          }} className="orderguideme"
                          align="left">
                          <div className={styles.tableHeadCellContainer}>
                            <div>
                              Order
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }} className="moduleguideme"
                          align="left">
                          <div className={styles.tableHeadCellContainer}>
                            <div>Module Name
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="modifyguideme"
                          style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                          align="left">
                          <div className={styles.tableHeadCellContainer}>
                            <div>
                              {"Modify"}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }} className="reuploadguideme"
                          align="left">
                          <div className={styles.tableHeadCellContainer}>
                            <div>Reupload
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }} className="activeguideme"
                          align="left">
                          <div className={styles.tableHeadCellContainer}>
                            <div>
                              Active
                            </div>
                          </div>
                        </TableCell>

                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "0 8px 0 0",
                          }} className="reorderguideme"
                          align="left">
                          <div className={styles.tableHeadCellContainer}>
                            <div>
                              Reorder
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <Droppable droppableId="droppable-1">
                      {(provider) => (
                        <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                          <TableRow>
                            <TableCell colSpan={6}>
                              <div style={{ width: '100%' }}>
                                <CustomTooltip displayText={[allResourcesToTranslations.draganddropcustomtooltip]}>
                                  <div className="Filedropbox">
                                    <CourseFileDropBox selectedFile={selectedFile} uploadFile={handleFileUpload} setSelectedFile={handleSetSelectedFileName} />
                                  </div>
                                </CustomTooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                          {courseSCORMcontentList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: any, index: any) => (
                            <Draggable key={result.moduleName} draggableId={result.moduleName} index={index}>
                              {(provider) => (
                                <TableRow
                                  key={result.moduleName}
                                  className={styles.tableRow}
                                  {...provider.draggableProps}
                                  ref={provider.innerRef}
                                >
                                  <TableCell align="center" width={"5%"} >
                                    {result.sequence}
                                  </TableCell>
                                  <TableCell align="left" width={"70%"}>
                                    {result.isBeingEdited ?
                                      <TextField style={{ width: '100%' }} variant="outlined"
                                        autoComplete="off" InputLabelProps={{ shrink: true, }}
                                        defaultValue={result.moduleName} onChange={(e) => seteditedmoduleName(e.target.value)} />
                                      :
                                      <span className={result.moduleName}>{result.moduleName}</span>
                                    }
                                  </TableCell>
                                  <TableCell align="left" width={"15%"}>
                                    {result.isBeingEdited ?
                                      <>
                                        <CustomTooltip displayText={["Save"]}>
                                          <Button disabled={editedmoduleName.trim() === ""} onClick={() => handleSaveModule(result.moduleName, editedmoduleName, result.sequence)} className={styles.moduleListButton} variant="contained" color="primary" >
                                            <SaveIcon />
                                          </Button>
                                        </CustomTooltip> &nbsp;&nbsp;
                                        <CustomTooltip displayText={["Cancel"]}>
                                          <Button onClick={() => handleCancel(result.moduleName)} className={styles.moduleListButton} variant="contained" color="primary">
                                            <CancelIcon />
                                          </Button>
                                        </CustomTooltip>
                                      </>
                                      :
                                      <CustomTooltip displayText={["Edit"]}>
                                        <Button className="ruleedit" onClick={() => handleEdit(result.moduleName, result.sequence, result.moduleID)} variant="contained" color="primary">
                                          <EditIcon />
                                        </Button>
                                      </CustomTooltip>
                                    }

                                  </TableCell>
                                  <TableCell align="center" width={"5%"} >
                                    <CustomTooltip displayText={["Reupload"]}>
                                      <CourseReupload selectedFile={selectedFile} uploadFile={handleFileReUpload} setSelectedFile={handleSetSelectedFileName} />
                                    </CustomTooltip>
                                  </TableCell>
                                  <TableCell align="center" width={"5%"}>
                                    <span style={{ paddingTop:'5px'}} onClick={() => handleModuleActive(result.moduleName, !result.active, result.sequence, result.moduleID)}>{result.active ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
                                  </TableCell>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    {...provider.dragHandleProps}
                                  >
                                    <DragIndicatorIcon />{publishingCourseType}
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                          {provider.placeholder}
                        </TableBody>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              </BrandedCard>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>
            <span>
              <CircularProgress
                style={{ width: (isFileUploading) ? "20px" : "0px", marginTop: "5px" }}
                color="primary"
                size="10"
              />
            </span>
            <CustomTooltip displayText={["Save & Next"]}>
              <span>
                <Button
                  disabled={(isSaving || name == "" || note == "")}
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  className="saveData">
                  Save & Next
                </Button>
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={["Back"]}>
              <span>
                <Button
                  onClick={BackButtonRoute}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  Back
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </BrandedCard>
      <div className={styles.mainWrapper}>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
      </div>
    </>
  );
};
export default AddCourse;
