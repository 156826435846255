//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";

import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import LoaderContainer from '../../../Components/LoaderContainer';
import { Button, CircularProgress, TextField, Chip, TablePagination, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, } from '@material-ui/core';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import CustomTooltip from '../../../Components/CustomTooltip';
import { AddCircleOutlineRounded } from '@material-ui/icons';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '../../../CustomIcons/SaveIcon';
import { useHistory } from 'react-router';
import oidcConfig from '../../../configuration';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';
import getColor from '../../../functions/getColor';
import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';
import ActiveIcon from "../../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../../CustomIcons/Status_InActiveIcon";
import DeleteIcon from '../../../CustomIcons/DeleteIcon';
import AssignButtonPanel from '../PolicyBuilderSettings/AssignButtonPanel';
import AssignUnits from '../PolicyBuilderSettings/AssignUnits';
import AccountManagement from '../AccountManagement/AccountManagement';

import getNewsfeedList from '../../../API/SuperAdmin/GetNewsfeedList';
import AssignNewsfeedUnits from '../../../API/Administration/Newsfeed/AssignNewsfeedUnits';
import AssignNewsfeedUsers from '../../../API/Administration/Newsfeed/AssignNewsfeedUsers';
import GetNewsfeedInitEmailTemplate from '../../../API/Administration/Newsfeed/GetNewsfeedInitEmailTemplate';
import SaveNewsfeedEmailReport from '../../../API/Administration/Newsfeed/SaveNewsfeedEmailReport';
import DeleteNewsfeed from '../../../API/Administration/Newsfeed/DeleteNewsfeed';

import { SentEmailReportResultItem } from '../../../globalTypes';
import TinyMCEEditor from '../../../Components/TinyMCEEditorComponent';
import SendEmailReportIcon from '../../../CustomIcons/SendEmailReportIcon';
import { Modal } from '../../../Components/Modal';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import CloseIcon from '../../../CustomIcons/CloseIcon';
import EmailPreviewIcon from '../../../CustomIcons/EmailPreviewIcon';
import ProceedIcon from '../../../CustomIcons/ProceedIcon';

const Newsfeed = (props: any): JSX.Element => {
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, superAdminSelectedBasicOrgData } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [superAdminDomain, setSuperAdminDomain] = useState<string>(oidcConfig.currentdomainSA);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [headingDescending, setheadingDescending] = React.useState<boolean>(false);
  const [subHeadingDescending, setsubHeadingDescending] = React.useState<boolean>(false);
  const [createBySuperAdminDescending, setCreateBySuperAdminDescending] = React.useState<boolean>(false);
  const [statusDescending, setStatusDescending] = React.useState<boolean>(false);
  const [dateCreatedDescending, setdateCreatedDescending] = React.useState<boolean>(false);
  const [datePublishedDescending, setDatePublishedDescending] = React.useState<boolean>(false);
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("");
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false);
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false);
  const [assignAll, setAssignAll] = React.useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [newsfeedList, setNewsfeedList] = React.useState<Array<any>>([]);
  const [needLatestUnits, setNeedLatestUnits] = useState<boolean>(false);
  const [needLatestUsers, setNeedLatestUsers] = useState<boolean>(false);
  const [assignedUserIds, setAssignUserIds] = useState<Array<number>>([]);
  const [hasSelectedUsersChanged, setHasSelectedUsersChanged] = useState<boolean>(false);
  const [assignUsers, setAssignUsers] = useState<boolean>(false);
  const [isSavingAssignment, setIsSavingAssignment] = useState<boolean>(false);
  const [hasAccountsSearched, setHasAccountsSearched] = useState<boolean>(false);
  const [hasUnitsLoaded, setHasUnitsLoaded] = useState<boolean>(false);
  const [showAssignUnits, setshowAssignUnits] = useState<boolean>(false);
  const [showAssignAccounts, setshowAssignAccounts] = useState<boolean>(false);
  const [selectedUnits, setselectedUnits] = useState<string>("");
  const [finallySelectedUsersList, setFinallySelectedUsersList] = React.useState<Array<any>>([]);
  const [finallySelectedUnitList, setFinallySelectedUnitList] = React.useState<Array<any>>([]);
  const [sameUnitModelMessage, setSameUnitModelMessage] = useState<string>("");
  const [initaialUsersList, setInitaialUsersList] = React.useState<Array<any>>([]);
  const [updatedinitaialUsersList, setUpdatedInitaialUsersList] = React.useState<Array<any>>([]);
  const [selectedUsersCSVInitial, setSelectedUsersCSVInitial] = useState<string>("");
  const [selectedNewsfeedCSV, setSelectedNewsfeedCSV] = useState<string>("");
  const [updatedSelectedUnits, setupdatedSelectedUnits] = useState<Array<string>>([]);
  const [emailBodyTemplate, setEmailBodyTemplate] = useState<string>("");
  const [emailReportBodyResult, setEmailReportBodyResult] = useState<Array<SentEmailReportResultItem>>([]);
  const [showModal, setModalOpen] = useState<boolean>(false);
  const [showPreviewModal, setPreviewModalOpen] = useState<boolean>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [isHandlerBusy, setHandlerBusy] = useState<boolean>(false);
  const [previewArticleText, setPreviewarticleText] = useState<string>("");
  const [showConfirmModalDeleteNewsfeed, setshowConfirmModalDeleteNewsfeed] = useState<boolean>(false);
  const [newsfeedIdToDelete, setNewsfeedIdToDelete] = useState<number>(0);
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".nfnewarticletitle",
        title: allResourcesToTranslations.nfnewarticletitle, intro: allResourcesToTranslations.nfnewarticle
      }
    ]
  });

  const setGuideMe = () => {
    let updateSteps = [...intro.steps];
    if (updateSteps.length > 1) {
      updateSteps.splice(1, updateSteps.length - 1);
    }
    updateSteps.push(
      {
        element: ".Assign",
        title: allResourcesToTranslations.assign, intro: allResourcesToTranslations.colassign
      },
      {
        element: ".headingCol",
        title: allResourcesToTranslations.nfarticletitle, intro: allResourcesToTranslations.colheading
      },
      {
        element: ".subHeadingCol",
        title: allResourcesToTranslations.nfsubheadingtitle, intro: allResourcesToTranslations.colsubheading
      },
      {
        element: ".dateCreatedCol",
        title: allResourcesToTranslations.emrepgmdtcreate, intro: allResourcesToTranslations.coldatecreated
      },
      {
        element: ".datePublishedCol",
        title: allResourcesToTranslations.nfdatepublished, intro: allResourcesToTranslations.coldatepublished
      },
      {
        element: ".statusCol",
        title: allResourcesToTranslations.status, intro: allResourcesToTranslations.colstatus
      },
      {
        element: ".EditCol",
        title: allResourcesToTranslations.editcol, intro: allResourcesToTranslations.coledit
      },
      {
        element: ".PreviewCol",
        title: allResourcesToTranslations.editcol, intro: allResourcesToTranslations.coledit
      },
      {
        element: ".DeleteCol",
        title: allResourcesToTranslations.deletecol, intro: allResourcesToTranslations.coldelete
      });

    updateSteps.push(
      {
        element: ".AssignUnits",
        title: "Assign Units", intro: allResourcesToTranslations.nflistassignunits
      },
      {
        element: ".AssignAccounts",
        title: "Assign Accounts", intro: allResourcesToTranslations.nflistassignaccounts
      },
      {
        element: ".SendWelcomeEmail",
        title: "Send Welcome Email", intro: allResourcesToTranslations.nflistsendemail
      });

    if (showAssignUnits) {
      updateSteps.push(
        {
          element: ".unittree",
          title: "Assign Units", intro: allResourcesToTranslations.nflistsearchunit
        },
        {
          element: ".saveAssignedData",
          title: "Save Assignment",
          intro: "Click to save unit assignments. ",
        });
    }

    if (showAssignAccounts) {
      updateSteps.push(
        {
          element: ".accountSearch",
          title: "Search Accounts", intro: allResourcesToTranslations.nflistsearchaccount
        });
    }
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const loadNewsfeeds = () => {
    getNewsfeedList(oidcUser.access_token, userId, orgDomain, parseInt(orgId),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
        if (result.isSuccess && result.error == '') {
          if (result.newsfeedList !== null) {
            let updatedNewsfeedList = result.newsfeedList.map((item: any) => {
              return {
                ...item,
                isChecked: false,
              };
            });
            setNewsfeedList([...updatedNewsfeedList]);
          }
          else {
            setNewsfeedList([]);
          }
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Can not load newsfeed!");
        }
        setLoading(false);
        setGuideMe();
      });
  };

  const getSendEmailTemplate = () => {

    GetNewsfeedInitEmailTemplate(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((emailresult) => {
      if (!!emailresult && !!emailresult.isSuccess) {
        if (emailresult.isSuccess && emailresult.error == null) {
          if (emailresult.newsfeedEmailReportBodyItem !== null && emailresult.newsfeedEmailReportBodyItem.length > 0) {
            setEmailBodyTemplate(emailresult.newsfeedEmailReportBodyItem[0].body);
            let updatedDataNew = emailresult.newsfeedEmailReportBodyItem.map((item: any) => {
              return {
                ...item,
                isChecked: false,
              };
            });
            setEmailReportBodyResult(updatedDataNew);
          }
        }
      }
    });

  };
  useEffect(() => {
    setTitle(allResourcesToTranslations.newsfeeds)
    setTitleEnglish(pageTitles.newsfeeds)
    updateBackButtonRoute("");
    setMenuSelection("Newsfeeds")
    setLoading(true);
    loadNewsfeeds();
    getSendEmailTemplate();

    return () => { };
  }, []);

  useEffect(() => {
    setGuideMe();
  }, [showAssignAccounts, selectedNewsfeedCSV, showAssignUnits]);

  const handleAddNewNewsfeed = () => {
    history.push({
      pathname: `newsfeeddetails/-1`
    });
  }

  const handleEdit = (idToEdit: number) => {
    history.push({
      pathname: `newsfeeddetails/` + idToEdit
    });
  };

  const handlePreview = (previewContent: string) => {
    setPreviewarticleText(previewContent);
    handlePreviewModal(true);
  };

  const closePreview = () => {
    handlePreviewModal(false);
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };


  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (
        (parseInt(year) < 10 ? "000" + year.toString()
          : ((parseInt(year) < 100 ? "00"
            : (((parseInt(year) < 1000 ? "0" : year.toString())))))) + "-" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }
    else {
      return "";
    }
  }

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortResultsByAsync = async (
    sortBy: "Heading" | "SubHeading" | "DateCreated" | "DatePublished" | "Status" | "CreateBySuperAdmin",
    sortGivenItemInDesc: boolean
  ) => {
    if (newsfeedList == null || newsfeedList.length <= 0) {
      return newsfeedList;
    }
    var result = newsfeedList.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "Heading":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.heading === null) {
              return -1;
            }
            if (a === null || a.heading === null) {
              return 1;
            }
            if (a.heading.toLowerCase() > b.heading.toLowerCase()) {
              return -1;
            }
            if (a.heading.toLowerCase() < b.heading.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setheadingDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.heading === null) {
              return 1;
            }
            if (a === null || a.heading === null) {
              return -1;
            }
            if (a.heading.toLowerCase() < b.heading.toLowerCase()) {
              return -1;
            }
            if (a.heading.toLowerCase() > b.heading.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setheadingDescending(true);
          }
        }
        setNewsfeedList(result);
        break;
      case "SubHeading":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.subHeading === null) {
              return -1;
            }
            if (a === null || a.subHeading === null) {
              return 1;
            }
            if (a.subHeading.toLowerCase() > b.subHeading.toLowerCase()) {
              return -1;
            }
            if (a.subHeading.toLowerCase() < b.subHeading.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setheadingDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.subHeading === null) {
              return 1;
            }
            if (a === null || a.subHeading === null) {
              return -1;
            }
            if (a.subHeading.toLowerCase() < b.subHeading.toLowerCase()) {
              return -1;
            }
            if (a.subHeading.toLowerCase() > b.subHeading.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setheadingDescending(true);
          }
        }
        setNewsfeedList(result);
        break;
      case "Status":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.status === null) {
              return -1;
            }
            if (a === null || a.status === null) {
              return 1;
            }
            if (a.status.toString().toLowerCase() > b.status.toString().toLowerCase()) {
              return -1;
            }
            if (a.status.toString().toLowerCase() < b.status.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.status === null) {
              return 1;
            }
            if (a === null || a.status === null) {
              return -1;
            }
            if (a.status.toString().toLowerCase() < b.status.toString().toLowerCase()) {
              return -1;
            }
            if (a.status.toString().toLowerCase() > b.status.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(true);
          }
        }
        setNewsfeedList(result);
        break;
      case "CreateBySuperAdmin":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.createBySuperAdmin === null) {
              return -1;
            }
            if (a === null || a.createBySuperAdmin === null) {
              return 1;
            }
            if (a.createBySuperAdmin.toString().toLowerCase() > b.createBySuperAdmin.toString().toLowerCase()) {
              return -1;
            }
            if (a.createBySuperAdmin.toString().toLowerCase() < b.createBySuperAdmin.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCreateBySuperAdminDescending(false);
          }
        }
        else {
          result.sort((a, b) => {
            if (b === null || b.createBySuperAdmin === null) {
              return 1;
            }
            if (a === null || a.createBySuperAdmin === null) {
              return -1;
            }
            if (a.createBySuperAdmin.toString().toLowerCase() < b.createBySuperAdmin.toString().toLowerCase()) {
              return -1;
            }
            if (a.createBySuperAdmin.toString().toLowerCase() > b.createBySuperAdmin.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCreateBySuperAdminDescending(true);
          }
        }
        setNewsfeedList(result);
        break;
      case "DateCreated":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return -1; }
            if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return 1; }
            var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setdateCreatedDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return 1; }
            if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return -1; }
            var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setdateCreatedDescending(true)
          }
        }
        setNewsfeedList(result);
        break;
      case "DatePublished":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.datePublished === null || b.datePublished.trim() === "") { return -1; }
            if (a === null || a.datePublished === null || a.datePublished.trim() === "") { return 1; }
            var dta = new Date(a.datePublished.split("/").reverse().join("-"))
            var dtb = new Date(b.datePublished.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setdateCreatedDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.datePublished === null || b.datePublished.trim() === "") { return 1; }
            if (a === null || a.datePublished === null || a.datePublished.trim() === "") { return -1; }
            var dta = new Date(a.datePublished.split("/").reverse().join("-"))
            var dtb = new Date(b.datePublished.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setDatePublishedDescending(true)
          }
        }
        setNewsfeedList(result);
        break;
      default:
        break;
    }
    return result.slice(0);
  };

  const handleSelectedNewsfeed = (e: any, newsfeedId: any) => {
    e.stopPropagation();

    let newsfeedData = [...newsfeedList];
    let updatedNewsfeedData = newsfeedData.map((newsfeed: any) => {
      if (newsfeed.newsfeedId === newsfeedId) {
        return {
          ...newsfeed,
          isChecked: !newsfeed.isChecked
        }
      }
      else {
        return {
          ...newsfeed
        }
      }
    });
    setNewsfeedList([...updatedNewsfeedData]);

    let selectedNewsfeeds = updatedNewsfeedData.filter((nf: any) => nf.isChecked == true);
    let selectedNewsfeedIds = "";

    let updatedDataLocal = selectedNewsfeeds.map((value: any) => value.newsfeedId)
    selectedNewsfeedIds = updatedDataLocal.join(",");
    setSelectedNewsfeedCSV(selectedNewsfeedIds);
    setGuideMe();
  };

  const sortResultsBy = async (
    sortBy: "Heading" | "SubHeading" | "DateCreated" | "DatePublished" | "Status" | "CreateBySuperAdmin",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setNewsfeedList(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickheadingAsc = () => {
    setReverseNextSort(false);
    setheadingDescending(false);
    sortResultsBy("Heading", false);
  };

  const clickheadingDesc = () => {
    setReverseNextSort(false);
    setheadingDescending(true);
    sortResultsBy("Heading", true);
  };

  const clicksubHeadingAsc = () => {
    setReverseNextSort(false);
    setsubHeadingDescending(false);
    sortResultsBy("SubHeading", false);
  };

  const clicksubHeadingDesc = () => {
    setReverseNextSort(false);
    setsubHeadingDescending(true);
    sortResultsBy("SubHeading", true);
  };

  const clickdateCreatedAsc = () => {
    setReverseNextSort(false);
    setdateCreatedDescending(false);
    sortResultsBy("DateCreated", false);
  };

  const clickdateCreatedDesc = () => {
    setReverseNextSort(false);
    setdateCreatedDescending(true);
    sortResultsBy("DateCreated", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickCreatedBySuperAdminAsc = () => {
    setReverseNextSort(false);
    setCreateBySuperAdminDescending(false);
    sortResultsBy("CreateBySuperAdmin", false);
  };

  const clickCreatedBySuperAdminDesc = () => {
    setReverseNextSort(false);
    setCreateBySuperAdminDescending(false);
    sortResultsBy("CreateBySuperAdmin", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickdatePublishedAsc = () => {
    setReverseNextSort(false);
    setDatePublishedDescending(false);
    sortResultsBy("DatePublished", false);
  };

  const clickdatePublishedDesc = () => {
    setReverseNextSort(false);
    setDatePublishedDescending(true);
    sortResultsBy("DatePublished", true);
  };


  const handleAssignUnits = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);

    setshowAssignUnits(!showAssignUnits);
    setshowAssignAccounts(false);
    setAssignUsers(false);

    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleAssignAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);

    setshowAssignAccounts(!showAssignAccounts);
    setshowAssignUnits(false);
    setAssignUsers(true);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const setupdatedSelectedUnitsFunction = (unitIds: string[]) => {
    if (selectedNewsfeedCSV !== "") {
      setupdatedSelectedUnits(unitIds);
      setFinallySelectedUnitList(unitIds);
      SaveNewsfeedAssignClick(unitIds, []);
    }
  }

  const setUnitsLoadedFunction = (hasUnitsLoaded: boolean) => {
    setHasUnitsLoaded(hasUnitsLoaded);
    setGuideMe();
  }

  const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {
    setFinallySelectedUsersList(usersData);
  }

  const setRecordsSearchedFunction = (hasSearched: boolean) => {
    setHasAccountsSearched(hasSearched);
  }
  const setUserSelectionChangedFunction = (hasSelectedUsersChanged: boolean) => {
    setHasSelectedUsersChanged(hasSelectedUsersChanged);
  }
  const handleSaveAssignment = () => {
    if (showAssignUnits) {
      setNeedLatestUnits(true);
    }
    if (showAssignAccounts) {
      setNeedLatestUsers(true);
      SaveNewsfeedAssignClick([], finallySelectedUsersList);
    }
  }
  const AssignUnitsToNewsfeedFunction = (unitIds: string[]) => {
    setIsSavingAssignment(true);
    AssignNewsfeedUnits(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedNewsfeedCSV, unitIds.join(","))
      .then((res) => {
        setIsSavingAssignment(false);
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setselectedUnits(unitIds.join(","));
        if (!!res && res.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Assign units to this Newsfeed have been saved successful. ");
        }
      })
  }

  const getValidIntNumberValue = (givenTxt: string) => {
    let numval = -1;
    if (!!givenTxt === false || givenTxt.trim() == "") {
      return -1;
    }
    try {
      numval = parseInt(givenTxt);
      if (!!numval == false || isNaN(numval) || Number.isNaN(numval)) {
        numval = -1;
      }
    }
    catch { numval = -1; }
    return numval;
  }

  const getNewlyModifiedUserIDs = (updatedSelectedUsers: any[]) => {
    let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
    let newlyGrantedUserIDsCSV = "";
    let newlyDeniedUserIDsCSV = "";

    let initialSelectedUserIDs: string[] = [];
    initialSelectedUserIDs = selectedUsersCSVInitial.split(',');
    // Get the newly granted and newly denied users
    if (!!updatedSelectedUsers && updatedSelectedUsers.length > 0 &&
      updatedSelectedUsers.some(id => !!id && !!id.userID && id.userID.toString().trim() != "")) {
      // Collect only newly granted in first loop of updated user-list checked against initial granted user-list
      for (let i = 0; i < updatedSelectedUsers.length; i++) {
        let curVal = updatedSelectedUsers[i];
        let curValId = "";
        if (!!curVal === false || !!curVal.userID === false || (curValId = curVal.userID.toString().trim()) == "" || curVal.granted !== true) {
          continue;
        }
        let numVal = getValidIntNumberValue(curValId);
        let numvalTxt = numVal.toString();
        // Extra not overlapping with initial list - collect only the denied to granted
        if (!initialSelectedUserIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
          if (curVal.granted === true) {
            newlyGrantedUserIDsCSV = ((newlyGrantedUserIDsCSV == "") ? numvalTxt : (newlyGrantedUserIDsCSV + "," + numvalTxt));
          }
        }
      }
      // Collect only newly denied in second loop of initial granted user-list checked against updated user-list
      for (let i = 0; i < initialSelectedUserIDs.length; i++) {
        let curVal = initialSelectedUserIDs[i];
        if (!!curVal === false || (curVal = curVal.toString().trim()) == "") {
          continue;
        }
        let numVal = getValidIntNumberValue(curVal);
        let numvalTxt = numVal.toString();
        // Overlapping with initial list - collect only granted to denied
        if (updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt) &&
          updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === false)) {
          newlyDeniedUserIDsCSV = ((newlyDeniedUserIDsCSV == "") ? numvalTxt : (newlyDeniedUserIDsCSV + "," + numvalTxt));
        }
      }
    }
    retVal.newlyDeniedIDsCSV = newlyDeniedUserIDsCSV;
    retVal.newlyGrantedIDsCSV = newlyGrantedUserIDsCSV;
    return retVal;
  }

  const handleSendNewsfeedsEmail = () => {
    if (selectedNewsfeedCSV.length > 0) {
      var emailBodyTemplateContent = (document.getElementById('editorText') as HTMLInputElement).value;
      var emailBodyTemplateInitialContent = (document.getElementById('editorChangedText') as HTMLInputElement).value;
      const nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 1);
      let popupMessage = '';

      setEmailBodyTemplate((!!emailBodyTemplateContent === false || emailBodyTemplateContent.trim() === "") ? emailBodyTemplateInitialContent : emailBodyTemplateContent);
      SaveNewsfeedEmailReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedNewsfeedCSV, emailSubject, (!!emailBodyTemplateContent === false || emailBodyTemplateContent.trim() === "") ? emailBodyTemplateInitialContent : emailBodyTemplateContent, 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")
    ).then(response => {
        handleModal(false);
        if (!!response && response.isSuccess) {
          popupMessage = popupMessage + " Newsfeed notifications are queued and will start sending soon."
          setShowWarningModal(true);
          setModalPopupMessage(popupMessage);
          setSaveSuccess(true);
          setHasErrored(false);
        } else {
          const errorVal = popupMessage + "Sending Newsfeed notifications have failed! Please try again sending them or it will be picked up by overnight job.";
          setModalPopupMessage(errorVal);
          setHasErrored(true);
          setShowWarningModal(true);

        }
      }).catch((error) => {
        const errorVal = popupMessage + "Sending Newsfeed notifications have failed! Please try again sending them from Email report or it will be picked up by overnight job.";
        setModalPopupMessage(errorVal);
        setHasErrored(true);
        setShowWarningModal(true);
      });
    } else {
      setShowWarningModal(true);
      setHasErrored(false);
      setSaveSuccess(false);
      setModalPopupMessage("Please select Newsfeeds to send email");
    }
  }

  const SaveNewsfeedAssignClick = (unitIds: string[], usersData: any[]) => {
    setNeedLatestUnits(false);
    if (showAssignUnits) {
      let anyChangeFound = false;
      if (unitIds.length === selectedUnits.split(",").length) {
        for (let curIdx = 0; curIdx < unitIds.length; curIdx++) {
          let currentValue = unitIds[curIdx];
          let unit = (selectedUnits.split(",").find(v => !!v && !!currentValue && v.toString().toLowerCase().trim() === currentValue.toString().toLowerCase().trim()));
          if (anyChangeFound === false && (unit === null || unit === undefined)) {
            anyChangeFound = true;
            break;
          }
        }
      }
      else {
        anyChangeFound = true;
      }
      if (anyChangeFound) {
        AssignUnitsToNewsfeedFunction(unitIds);
      }
      else {
        setSameUnitModelMessage("There are no changes to Unit Assignments. Do you want to re-apply the existing Unit Assignments which will override any Account specific assignments?");
      }
    }

    if (showAssignAccounts) {
      let anyUserChangeFound: boolean = false;
      let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
      //console.log(currentUserGrantedData);
      //console.log(initaialUsersList);
      let initialUserGrantedData = !!initaialUsersList !== true ? null : initaialUsersList.filter((initialItem) => initialItem.granted === true);
      if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length) {
        // Setting external variable using the lambda of map function is avoided
        for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
          let currentItem = usersData[curIdx];
          let userdetail = (initaialUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
          if (anyUserChangeFound === false && !!userdetail) {
            anyUserChangeFound = true;
          }
        }
      }
      else {
        anyUserChangeFound = true;
      }
      if (anyUserChangeFound) {
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = getNewlyModifiedUserIDs(usersData);
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

        setIsSavingAssignment(true);
        AssignNewsfeedUsers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedNewsfeedCSV, newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
          .then((res) => {
            setIsSavingAssignment(false);
            setNeedLatestUnits(false);
            setNeedLatestUsers(false);

            if (!!res && res.isSuccess) {
              let initialUsersList = usersData.map((item: any) => {
                return {
                  ...item,
                  isInitial: true,
                };
              });
              setInitaialUsersList(initialUsersList);
              let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
              const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
              setSelectedUsersCSVInitial(selectedIds.join(','));

              setShowWarningModal(true);
              setHasErrored(false);
              setSaveSuccess(true);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been saved successful. ");
            }
            else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been failed.");
            }
          });
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(false);
        setModalPopupMessage("Please select the user to assign/unassign to the newsfeed");
      }
    }
  }

  const handleSendEmail = () => {
    if (selectedNewsfeedCSV !== "") {
      handleModal(true);
    }
    else {
      setShowWarningModal(true);
      setHasErrored(false);
      setSaveSuccess(false);
      setModalPopupMessage("Please select Newsfeeds to send email for");
    }
  }

  const handleModal = (value: boolean) => {
    setModalOpen(value);
  }

  const handlePreviewModal = (value: boolean) => {
    setPreviewModalOpen(value);
  }

  const handleTextEditorChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event !== undefined) {
      setEmailBodyTemplate(event.target.value);
    }
  };


  const handleDelete = (idToDelete: number) => {
    setNewsfeedIdToDelete(idToDelete);
    setshowConfirmModalDeleteNewsfeed(true);
  };

  const handleProceedDeleteNewsfeed = () => {
    handleConfirmModalDeleteNewsfeed(false);
    if (newsfeedIdToDelete > 0) {
      DeleteNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedIdToDelete,
        (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
        (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
        (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((res) => {
          if (!!res && res.isSuccess) {
            loadNewsfeeds();
            setShowWarningModal(true);
            setHasErrored(false);
            setSaveSuccess(true);
            setModalPopupMessage("Newsfeed has been deleted successfully!");
          }
          else {
            setShowWarningModal(true);
            setHasErrored(true);
            setSaveSuccess(false);
            setModalPopupMessage("Newsfeed deleted failed!");
          }
        });
    }
  }

  const handleConfirmModalDeleteNewsfeed = (value: boolean) => {
    setshowConfirmModalDeleteNewsfeed(value);
  }


  const handleCancelFromConfirmPopupDeleteNewsfeed = () => {
    handleConfirmModalDeleteNewsfeed(false);
  }

  const handleEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if ((event.target.value != '')) {
      setEmailIsValid(false)
    }
    setEmailSubject(event.target.value);
  };

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 3000)
    }
  }, [showWarningModal])

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>

      <Modal modalOpen={showConfirmModalDeleteNewsfeed} setModalOpen={handleConfirmModalDeleteNewsfeed} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              Are you sure you want to delete this Newsfeed?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopupDeleteNewsfeed}>
                <CloseIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceedDeleteNewsfeed}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>

      <Modal modalOpen={showPreviewModal} setModalOpen={handlePreviewModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "primary", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.previewarticle}</h2>
          <div className="editor" style={{ paddingTop: "10px", width: '99%', paddingBottom: '10px' }} >
            {
              <div style={{ minWidth: '500px' }}>
                {/* <span style={{ fontSize: '14px', fontWeight: 700, color: '#767676' }}> Article Text: <br></br></span> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: previewArticleText //bodyFormatted
                  }}></div>
              </div>
            }
          </div>
          <div className={styles.sendEmail}>
            <div className="closePreview">
              <CustomTooltip displayText={["Close"]} >
                <span>
                  <Button
                    onClick={closePreview}
                    variant="contained"
                    color="primary"
                    id="btnNewSaltClosePreview">
                    <CloseIcon />
                  </Button>
                </span>
              </CustomTooltip>
            </div>
          </div>
        </div>
      </Modal>

      <Modal modalOpen={showModal} setModalOpen={handleModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "primary", width: "500px", marginTop: "4px" }}>Send Welcome Email</h2>
          <div className="emailSubject" style={{ paddingTop: "10px" }}>
            <TextField
              className={styles.input}
              label={"Email Subject"}
              style={{ width: "99%", marginBottom: "20px" }}
              variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
              value={emailSubject}
              onChange={handleEmailSubject}
              error={emailIsValid}
              placeholder="*This Field is required"
            />
          </div>
          <div className="editor" style={{ paddingTop: "10px", width: '99%', paddingBottom: '10px' }} >
            {
              emailReportBodyResult.map((result: any, i) => {
                if (i == 0) {
                  return (
                    <TinyMCEEditor index={i} key={"tinyMCEEditor" + i} editorData={result.body} isTemplate={false} is200Tall={false} handleTextEditorChange={handleTextEditorChange} />
                  );
                }
              })}
          </div>
          <div className={styles.sendEmail}>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isHandlerBusy ? "20px" : "0px", marginTop: '13px' }}
                color="primary"
                size="10"
              />
            </div>
            <div className="sendEmail">
              <CustomTooltip displayText={["Send Email"]} >
                <span>
                  <Button
                    onClick={handleSendNewsfeedsEmail}
                    disabled={emailSubject === ""}
                    variant="contained"
                    color="primary"
                    id="btnNewSaltSendEmailReport">
                    <SendEmailReportIcon />
                  </Button>
                </span>
              </CustomTooltip>
            </div>
          </div>
        </div>
      </Modal>
      {(newsfeedList === null || newsfeedList.length < 1) &&
        (<BrandedCard>
          <div className={styles.noResultsCard}>
            <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
          </div>
        </BrandedCard>)
      }
      <UnbrandedCard>
        <div className={styles.mainWrapper}>
          <div className={styles.Wrapper}>
            <div style={{ display: "flex", marginRight: "-5px", width: "100%", clear: "both" }}>
              <div className="nfnewarticletitle" style={{ width: "50%" }}>
                <CustomTooltip displayText={[allResourcesToTranslations.addnewarticle]}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={`saveData addNewNewsfeed`}
                    onClick={handleAddNewNewsfeed}
                  >
                    <div style={{ marginBottom: "-5px" }}>
                      <AddCircleOutlineRounded />
                    </div><span style={{ marginLeft: "5px" }}> {allResourcesToTranslations.addnewarticle}</span>
                  </Button>
                </CustomTooltip>
              </div>
            </div>
            {newsfeedList.length > 0 &&
              <div className={styles.paginationControlsContainer}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "95%",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={newsfeedList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            }
            {newsfeedList.length > 0 &&
              <div>
                <div className={styles.tableSection} id="newsfeedList">
                  <div className={styles.tableContainer}>
                    <BrandedCard>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="Assign"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", paddingRight: "60px" }}
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    {"Assign" /* TODO translate */}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="headingCol"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0" }}
                                align="left"
                              >
                                <div
                                  className={styles.tableHeadCellContainer}
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    onClick={() => {
                                      setheadingDescending(!headingDescending);
                                      sortResultsBy("Heading", !headingDescending);
                                    }}
                                  >
                                    {"Article - Heading" /* TODO translate */}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "heading" &&
                                            headingDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickheadingAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "heading" &&
                                            headingDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickheadingDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="subHeadingCol"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0" }}
                                align="left"
                              >
                                <div
                                  className={styles.tableHeadCellContainer}
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    onClick={() => {
                                      setsubHeadingDescending(!subHeadingDescending);
                                      sortResultsBy("SubHeading", !subHeadingDescending);
                                    }}
                                  >
                                    {"Sub Heading" /* TODO translate */}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "SubHeading" &&
                                            subHeadingDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clicksubHeadingAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "SubHeading" &&
                                            subHeadingDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clicksubHeadingDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="dateCreatedCol"
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                }}
                                align="left"
                              >
                                <div
                                  className={styles.tableHeadCellContainer}
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    onClick={() => {
                                      setdateCreatedDescending(!dateCreatedDescending);
                                      sortResultsBy("DateCreated", !dateCreatedDescending);
                                    }}
                                  >
                                    {"Date Created" /* TODO translate */}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "DateCreated" &&
                                            dateCreatedDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickdateCreatedAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "DateCreated" &&
                                            dateCreatedDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickdateCreatedDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="datePublishedCol"
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                }}
                                align="left"
                              >
                                <div
                                  className={styles.tableHeadCellContainer}
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    onClick={() => {
                                      setDatePublishedDescending(!datePublishedDescending);
                                      sortResultsBy("DatePublished", !datePublishedDescending);
                                    }}
                                  >
                                    {"Date Published" /* TODO translate */}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "DatePublished" &&
                                            datePublishedDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickdatePublishedAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "DatePublished" &&
                                            datePublishedDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickdatePublishedDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="statusCol"
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                }}
                                align="left"
                              >
                                <div
                                  className={styles.tableHeadCellContainer}
                                  style={{ display: "flex" }}
                                >
                                  <div
                                    onClick={() => {
                                      setStatusDescending(!statusDescending);
                                      sortResultsBy("Status", !statusDescending);
                                    }}
                                  >
                                    {"Status" /* TODO translate */}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "Status" &&
                                            statusDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickStatusAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "Status" &&
                                            statusDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickStatusDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              {!((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) &&
                                <TableCell className="hasCreatedByCol"
                                  style={{
                                    color: getColor(color.substring(1)),
                                    backgroundColor: color,
                                  }}
                                  align="left"
                                >
                                  <div
                                    className={styles.tableHeadCellContainer}
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      onClick={() => {
                                        setStatusDescending(!createBySuperAdminDescending);
                                        sortResultsBy("CreateBySuperAdmin", !createBySuperAdminDescending);
                                      }}
                                    >
                                      {"Created By Salt Admin" /* TODO translate */}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "CreateBySuperAdmin" &&
                                              createBySuperAdminDescending === false
                                              ? "100%"
                                              : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCreatedBySuperAdminAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity:
                                            currentSortBy === "CreateBySuperAdmin" &&
                                              createBySuperAdminDescending === true
                                              ? "100%"
                                              : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCreatedBySuperAdminDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                              }
                              <TableCell className="EditCol"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0", paddingRight: "60px" }}
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    {"Edit" /* TODO translate */}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="PreviewCol"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0", paddingRight: "60px" }}
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    {"Preview" /* TODO translate */}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="DeleteCol"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0", paddingRight: "60px" }}
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    {"Delete" /* TODO translate */}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {newsfeedList
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((result: any, i) => {
                                return (
                                  <TableRow
                                    key={`row-${i}`}
                                    style={rowBackgroundColor(i)}
                                  >
                                    <TableCell align="left">
                                      <Checkbox
                                        size="small"
                                        color="primary"
                                        className={styles.checkBox}
                                        name="selectedNewsfeed"
                                        key={result.newsfeedId}
                                        checked={result.isChecked}
                                        onChange={(e) =>
                                          handleSelectedNewsfeed(e, result.newsfeedId)
                                        }
                                      ></Checkbox>
                                    </TableCell>
                                    <TableCell align="left" className={"headingcol"}>
                                      {result.heading}
                                    </TableCell>
                                    <TableCell align="left" className={"subHeadingcol"}>
                                      {result.subHeading}
                                    </TableCell>
                                    <TableCell align="left" className={"dateCreatedCol"}>
                                      {result.dateCreated === null ? "" : formatDate(new Date(result.dateCreated))}
                                    </TableCell>
                                    <TableCell align="left" className={"datePublishedCol"}>
                                      {result.datePublished === null ? "" : formatDate(new Date(result.datePublished))}
                                    </TableCell>
                                    <TableCell align="left">
                                      {result.status ?
                                        <CustomTooltip displayText={["Active"]}>
                                          <span style={{ paddingRight: "5px" }}><ActiveIcon></ActiveIcon></span>
                                        </CustomTooltip>
                                        :
                                        <CustomTooltip displayText={["Inactive"]}>
                                          <span style={{ paddingRight: "5px" }}><InActiveIcon></InActiveIcon></span>
                                        </CustomTooltip>
                                      }
                                      {result.status ? "Active" : "Inactive"}</TableCell>
                                    {!((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) &&
                                      <TableCell align="left" className={"hasCreatedBycol"}>
                                        {result.hasCreatedBySuperAdmin == true ? "Yes" : "No"}
                                      </TableCell>
                                    }
                                    <TableCell align="left" width={"5%"}>
                                      <CustomTooltip displayText={["Edit"]}>
                                        <Button className="ruleedit" onClick={() => handleEdit(result.newsfeedId)} variant="contained" color="primary"
                                         disabled={!((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) && result.hasCreatedBySuperAdmin == true} >
                                          <EditIcon />
                                        </Button>
                                      </CustomTooltip>
                                    </TableCell>
                                    <TableCell align="left" width={"5%"}>
                                      <CustomTooltip displayText={["Preview"]}>
                                        <Button className="rulepreview" onClick={() => handlePreview(result.articleText)} variant="contained" style={{ backgroundColor: "#63C2FF" }}>
                                          <EmailPreviewIcon />
                                        </Button>
                                      </CustomTooltip>
                                    </TableCell>
                                    <TableCell align="left" width={"5%"}>
                                      <CustomTooltip displayText={["Delete"]}>
                                        <Button className="ruledelete" onClick={() => handleDelete(result.newsfeedId)} variant="contained" style={{ backgroundColor: "red" }}>
                                          <DeleteIcon />
                                        </Button>
                                      </CustomTooltip>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </BrandedCard>

                    <div className={styles.paginationAddedUsersControl}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "95%",
                        }}
                      >
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 20, 100]}
                          component="div"
                          count={newsfeedList.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </UnbrandedCard>
      {newsfeedList.length > 0 && 
        <>
          <div className="buttonspanel">
            <AssignButtonPanel isCalledFrom={!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain ? "newsfeedslistuperadmin" : "NewsfeedList"} handleAssignUnitsClick={handleAssignUnits} handleAssignAccountsClick={handleAssignAccounts} handleViewAccountsClick={() => { }} handleResetAccountsClick={() => { }} handleSendEmailClick={handleSendEmail} isResetInProgress={false}></AssignButtonPanel>
          </div>
          {!((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) &&
            <div id="assign">
              {showAssignUnits && (
                <AssignUnits isCalledFrom="NewsfeedList" needLatestUnits={needLatestUnits} selectedUnitIds={selectedUnits} setUpdatedSelectedUnits={setupdatedSelectedUnitsFunction} setUnitsLoaded={setUnitsLoadedFunction} setAllDeniedUnitsAsCSV={() => { }}></AssignUnits>
              )}

              {showAssignAccounts && (
                <div className={styles.mainWrapper} >
                  <AccountManagement className="assignAccount" isCalledFrom="NewsfeedList" needLatestUsers={needLatestUsers} setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} assignedUserIds={assignedUserIds}
                    setInitialUsersData={{}} setSearchStarted={() => { }} setRecordsSearched={setRecordsSearchedFunction}
                    policyId={0} sethasSelectionChanged={setUserSelectionChangedFunction}></AccountManagement>
                </div>
              )}
            </div>
          }
        </>
      }
      {(
        ((showAssignUnits && hasUnitsLoaded) ||
          (showAssignAccounts && hasAccountsSearched))) && (
          <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
            <CustomTooltip displayText={showAssignUnits ? ["Assign Units"] : (showAssignAccounts ? ["Assign Accounts"] : ["Save"])}>
              <span className="saveAssignedData" style={{ marginRight: "-5px" }}>
                <Button
                  onClick={handleSaveAssignment}
                  variant="contained"
                  color="primary"
                  className="save"
                  disabled={(selectedUnits.length === 0 || finallySelectedUsersList.length === 0) && selectedNewsfeedCSV.length === 0}
                >
                  <SaveIcon />
                </Button>
              </span>
            </CustomTooltip>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isSavingAssignment ? "20px" : "0px", padding: "15px 10px" }}
                color="primary"
                size="10"
              />
            </div>
          </div>
        )}

      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

    </>
  );
};
export default Newsfeed;
