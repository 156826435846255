//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from '../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip'
//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
import { get2DigitDayOrMonth } from '../../functions/get2DigitDayOrMonth'
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'
import { addError } from '../../API/AddError'
//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon';
import CSVFileIcon from '../../CustomIcons/CSVFileIcon';
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import BrushIcon from '@material-ui/icons/Brush';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ViewListIcon from '@material-ui/icons/ViewList';

// Style
import styles from '../../styles/currentAdminReport.module.css'

const AtRiskReportResult = (props: any) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const history = useHistory();
    const { profile: { user_id, family_name, given_name, website } } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const { theme: { color } } = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    const [AtRiskReportResult, setAtRiskReportResult] = useState<Array<any>>(props?.location?.state?.resultItems ?? [])
    const [inputFilterDateFrom, setInputFilterDateFrom] = useState<string>(props?.location?.state?.inputFilterDateFrom ?? "")
    const [inputFilterDateTo, setInputFilterDateTo] = useState<string>(props?.location?.state?.inputFilterDateTo ?? "")
    const [inputFilterFailureCount, setInputFilterFailureCount] = useState<string>(props?.location?.state?.inputFilterFailureCount ?? "")
    const [inputSelectedClassification, setInputSelectedClassification] = useState<string>(props?.location?.state?.inputSelectedClassification ?? "")

    const [exporting, setExporting] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [firstNameDescending, setFirstNameDescending] = useState<boolean>(true)
    const [lastNameDescending, setLastNameDescending] = useState<boolean>(true)
    const [courseDescending, setCourseDescending] = useState<boolean>(true)
    const [unitDescending, setUnitDescending] = useState<boolean>(true)
    const [moduleDescending, setModuleDescending] = useState<boolean>(true)
    const [emailDescending, setEmailDescending] = useState<boolean>(true)
    const [externalIDDescending, setExternalIDDescending] = useState<boolean>(true)
    const [failNumberDescending, setFailNumberDescending] = useState<boolean>(true)
    const [currentStatusDescending, setCurrentStatusDescending] = useState<boolean>(true)
    const [userNameDescending, setUserNameDescending] = useState<boolean>(true)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const [exportError, setExportError] = useState<string>("")
    const [resultError, setResultError] = useState<string>(props?.location?.state?.resultError ?? "")

    const [isLengthyCoursePresent, setIsLengthyCoursePresent] = useState<boolean>(false)

    // const toggleBanding = () => setEnableRowBanding(!enableRowBanding)
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: ".AtRiskReportList",
                title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta
            },
        ]
    })

    useEffect(() => {
      if (!!AtRiskReportResult && AtRiskReportResult.length > 0) {
          setIsLengthyCoursePresent(AtRiskReportResult.some((item) => !!item.courseName && item.courseName.length > 100))
      }
    }, [AtRiskReportResult]);

    useEffect(() => {
        let updateSteps = [...intro.steps]
        updateSteps.push(
            {
                element: ".xlxs",
                title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
            },
            {
                element: ".csv",
                title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
            },
            {
                element: ".pdf",
                title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
            },
            {
                element: ".rowShading",
                title: "Row Shading", intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
            }
            // {
            //     element: ".rowShading",
            //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
            // }
        )

        setIntro({ ...intro, steps: [...updateSteps] })
    }, [])

    const onExit = () => {
        setIntro({ ...intro, stepsEnabled: false });
    };

    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
        } else {
            return {};
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    
    const GetDate_yymmdd = () => {
        var d =  new Date(Date.now());
        let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
        return day;   
    }

    const buildRequestStringArray = () => {
        let resultArray: Array<string> = []
        const r = AtRiskReportResult[0];

        for (let i = 0; i < AtRiskReportResult.length; i++) {
            const r = AtRiskReportResult[i];
            if (r.grandTotalRow) {
                resultArray.push(`${"Grand Total"}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${r.courseName}`)
            }
            else {
                resultArray.push(`${r.showUnit ? r.unitPathway : ""}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.userName}<#>${r.courseName}<#>${r.moduleName}<#>${r.failNumber}<#>${r.currentStatus}<#>${r.totalAccount}`);
            }
        }
        return resultArray;
    }

    const handleXLSXExport = () => {
        setMessage("Exporting XLSX");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started AtRisk Report XLSX-EXPORT in NEWSALT ClientApp", null, "AtRiskReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = AtRiskReportResult[0]
        let ClassificationValue = r.classificationValue;
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 11, "AtRiskReport", enableRowBanding, ClassificationValue, "", "", "", false, false, "", "", "", "", "").then((xlxsRes) => {
            if (xlxsRes.isSuccess) {
                const fileData = xlxsRes.xlsxDataOutput.fileData
                var a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "AtRiskReport - " + GetDate_yymmdd() + ".xlsx";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
                setExportError(xlxsRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed AtRisk Report XLSX-EXPORT in NEWSALT ClientApp", null, "AtRiskReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExport = () => {
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started At Risk Report CSV-EXPORT in NEWSALT ClientApp", null, "AtRiskReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = AtRiskReportResult[0]
        let ClassificationValue = r.classificationValue;
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 11, "AtRiskReport", ClassificationValue, "", "", "", false, false, inputFilterFailureCount.toString(), false, "", "", "", "").then((csvRes) => {
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData;
                var a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "AtRiskReport - " + GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed AtRisk Report CSV-EXPORT in NEWSALT ClientApp", null, "AtRiskReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handlePDFXExport = () => {
        setMessage("Exporting PDF");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started AtRisk Report PDF-EXPORT in NEWSALT ClientApp", null, "AtRiskReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = AtRiskReportResult[0]
        let ClassificationValue = r.classificationValue;
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 11, "AtRiskReport", enableRowBanding, ClassificationValue, "", "", "", false, false, inputFilterFailureCount.toString(), "", "", "", "", "").then((pdfRes) => {
            if (pdfRes.isSuccess) {
                const fileData = pdfRes.pdfDataOutput.fileData
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "AtRiskReport - " + GetDate_yymmdd() + ".PDF";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)
            }
            else if (pdfRes.error !== undefined && pdfRes.error !== null) {
                setExportError(pdfRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed AtRisk Report PDF-EXPORT in NEWSALT ClientApp", null, "AtRiskReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const sortResultsByAsync = async (sortBy: "Course" | "FirstName" | "LastName" | "Email" | "ExternalID" | "Unit" | "Module" | "UserName" | "FailNumber" | "CurrentStatus", sortGivenItemInDesc: boolean) => {
        if (AtRiskReportResult == null || AtRiskReportResult.length <= 0) {
            return AtRiskReportResult;
        }
        var result = AtRiskReportResult.slice(0)
        setCurrentSortBy(sortBy)
        switch (sortBy) {
            case "Course":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.courseName === null) { return -1; }
                        if (a === null || a.courseName === null) { return 1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return -1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.courseName === null) { return 1; }
                        if (a === null || a.courseName === null) { return -1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return 1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(true)
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "Module":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.moduleName === null) { return -1; }
                        if (a === null || a.moduleName === null) { return 1; }
                        if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) { return -1; }
                        if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.moduleName === null) { return 1; }
                        if (a === null || a.moduleName === null) { return -1; }
                        if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) { return 1; }
                        if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(true)
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "Unit":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.unitPathway === null) { return -1; }
                        if (a === null || a.unitPathway === null) { return 1; }
                        if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return -1; }
                        if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return 1; }
                        return 0;
                        //return a.unitID-b.unitID
                    })
                    if (reverseNextSort) {
                        setUnitDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.unitPathway === null) { return 1; }
                        if (a === null || a.unitPathway === null) { return -1; }
                        if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return 1; }
                        if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return -1; }
                        return 0;
                        //return a.unitID+b.unitID
                    })
                    if (reverseNextSort) {
                        setUnitDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "Email":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.email === null) {
                            return -1;
                        }
                        if (a === null || a.email === null) {
                            return 1;
                        }
                        if (a.email.toLowerCase() > b.email.toLowerCase()) {
                            return -1;
                        }
                        if (a.email.toLowerCase() < b.email.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setEmailDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.email === null) {
                            return 1;
                        }
                        if (a === null || a.email === null) {
                            return -1;
                        }
                        if (a.email.toLowerCase() < b.email.toLowerCase()) {
                            return -1;
                        }
                        if (a.email.toLowerCase() > b.email.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setEmailDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "FirstName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.firstName === null) {
                            return -1;
                        }
                        if (a === null || a.firstName === null) {
                            return 1;
                        }
                        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                            return -1;
                        }
                        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setFirstNameDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.firstName === null) {
                            return 1;
                        }
                        if (a === null || a.firstName === null) {
                            return -1;
                        }
                        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                            return -1;
                        }
                        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setFirstNameDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "LastName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.lastName === null) {
                            return -1;
                        }
                        if (a === null || a.lastName === null) {
                            return 1;
                        }
                        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                            return -1;
                        }
                        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setLastNameDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.lastName === null) {
                            return 1;
                        }
                        if (a === null || a.lastName === null) {
                            return -1;
                        }
                        if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                            return -1;
                        }
                        if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setLastNameDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "UserName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.userName === null) {
                            return -1;
                        }
                        if (a === null || a.userName === null) {
                            return 1;
                        }
                        if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
                            return -1;
                        }
                        if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setUserNameDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.userName === null) {
                            return 1;
                        }
                        if (a === null || a.userName === null) {
                            return -1;
                        }
                        if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
                            return -1;
                        }
                        if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setUserNameDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "ExternalID":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.externalID === null) {
                            return -1;
                        }
                        if (a === null || a.externalID === null) {
                            return 1;
                        }
                        if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) {
                            return -1;
                        }
                        if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setExternalIDDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.externalID === null) {
                            return 1;
                        }
                        if (a === null || a.externalID === null) {
                            return -1;
                        }
                        if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) {
                            return -1;
                        }
                        if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setExternalIDDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "FailNumber":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.failNumber === null) {
                            return -1;
                        }
                        if (a === null || a.failNumber === null) {
                            return 1;
                        }
                        if (a.failNumber.toString().toLowerCase() > b.failNumber.toString().toLowerCase()) {
                            return -1;
                        }
                        if (a.failNumber.toString().toLowerCase() < b.failNumber.toString().toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setFailNumberDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.failNumber === null) {
                            return 1;
                        }
                        if (a === null || a.failNumber === null) {
                            return -1;
                        }
                        if (a.failNumber.toString().toLowerCase() < b.failNumber.toString().toLowerCase()) {
                            return -1;
                        }
                        if (a.failNumber.toString().toLowerCase() > b.failNumber.toString().toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setFailNumberDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;
            case "CurrentStatus":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.currentStatus === null) {
                            return -1;
                        }
                        if (a === null || a.currentStatus === null) {
                            return 1;
                        }
                        if (a.currentStatus.toLowerCase() > b.currentStatus.toLowerCase()) {
                            return -1;
                        }
                        if (a.currentStatus.toLowerCase() < b.currentStatus.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setCurrentStatusDescending(false);
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (b === null || b.currentStatus === null) {
                            return 1;
                        }
                        if (a === null || a.currentStatus === null) {
                            return -1;
                        }
                        if (a.currentStatus.toLowerCase() < b.currentStatus.toLowerCase()) {
                            return -1;
                        }
                        if (a.currentStatus.toLowerCase() > b.currentStatus.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
                    if (reverseNextSort) {
                        setCurrentStatusDescending(true);
                    }
                }
                setAtRiskReportResult(result);
                break;

            default:
        }

        return result.slice(0);
    }

    const sortResultsBy = async (sortBy: "Course" | "Unit" | "Module" | "FirstName" | "LastName" | "Email" | "ExternalID" | "UserName" | "FailNumber" | "CurrentStatus", sortGivenItemInDesc: boolean) => {

        setSortInProgress(true);
        setExportError("");

        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
            setCurrentSortBy(sortBy)
            setAtRiskReportResult(result)
            setPage(0)
            setSortInProgress(false);
        });
    }

    const clickFailNumberAsc = () => {
        setReverseNextSort(false);
        setFailNumberDescending(false);
        sortResultsBy("FailNumber", false);
    };

    const clickFailNumberDesc = () => {
        setReverseNextSort(false);
        setFailNumberDescending(true);
        sortResultsBy("FailNumber", true);
    };

    const clickFirstNameAsc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(false);
        sortResultsBy("FirstName", false);
    };

    const clickFirstNameDesc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(true);
        sortResultsBy("FirstName", true);
    };

    const clickLastNameAsc = () => {
        setReverseNextSort(false);
        setLastNameDescending(false);
        sortResultsBy("LastName", false);
    };

    const clickLastNameDesc = () => {
        setReverseNextSort(false);
        setLastNameDescending(true);
        sortResultsBy("LastName", true);
    };

    const clickCurrentStatusAsc = () => {
        setReverseNextSort(false);
        setCurrentStatusDescending(false);
        sortResultsBy("CurrentStatus", false);
    };

    const clickCurrentStatusDesc = () => {
        setReverseNextSort(false);
        setCurrentStatusDescending(true);
        sortResultsBy("CurrentStatus", true);
    };
    const clickUserNameAsc = () => {
        setReverseNextSort(false);
        setUserNameDescending(false);
        sortResultsBy("UserName", false);
    };

    const clickUserNameDesc = () => {
        setReverseNextSort(false);
        setUserNameDescending(true);
        sortResultsBy("UserName", true);
    };
    const clickEmailAsc = () => {
        setReverseNextSort(false);
        setEmailDescending(false);
        sortResultsBy("Email", false);
    };

    const clickEmailDesc = () => {
        setReverseNextSort(false);
        setEmailDescending(true);
        sortResultsBy("Email", true);
    };

    const clickExternalIDAsc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(false);
        sortResultsBy("ExternalID", false);
    };

    const clickExternalIDDesc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(true);
        sortResultsBy("ExternalID", true);
    };

    const clickUnitAsc = () => {
        setReverseNextSort(false);
        setUnitDescending(false)
        sortResultsBy("Unit", false)
    }

    const clickUnitDesc = () => {
        setReverseNextSort(false);
        setUnitDescending(true)
        sortResultsBy("Unit", true)
    }

    const clickCourseAsc = () => {
        setReverseNextSort(false);
        setCourseDescending(false);
        sortResultsBy("Course", false);
    }

    const clickCourseDesc = () => {
        setReverseNextSort(false);
        setCourseDescending(true)
        sortResultsBy("Course", true)
    }

    const clickModuleAsc = () => {
        setReverseNextSort(false);
        setModuleDescending(false);
        sortResultsBy("Module", false);
    }

    const clickModuleDesc = () => {
        setReverseNextSort(false);
        setModuleDescending(true)
        sortResultsBy("Module", true)
    }

    const initialSort = () => {
        setCurrentSortBy("Unit");
        setUnitDescending(false);
        getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
            if (res.isSuccess) {
              let curdt = res.currentDateTimeText;
              setReportRunTimeTaken(curdt);
            } else {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
            }
           }).catch ((error) => {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
           });
        setPage(0)
    }

    useEffect(() => {
        initialSort()
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Completed AtRisk Report in NEWSALT ClientApp", null, "AtRiskReportResult.tsx", 6, EnableActivityLogs);
    }, [])

    const GetClassificationname = () => {
        if (inputSelectedClassification !== "") {
            return <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                    {"Grouping Option" /* TODO Tranlation */}
                </span>
                <span className={styles.reportDetail}>{inputSelectedClassification}</span>
            </div>
        } else {
            return "";
        }
    }
    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (!isNaN(givenDate.getTime())) {
            const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
            const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
            const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

            return (year.toString() + "-" +
                (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
                (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
        }
        else {
            return "";
        }
    }

    const GetSelectedFilteredFailureCount = () => {
        if (inputFilterFailureCount !== "") {
            return <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                    {"Failure Count" /* TODO Tranlation */}
                </span>
                <span className={styles.reportDetail}>{inputFilterFailureCount}</span>
            </div>
        } else {
            return "";
        }
    }

    const NavigateToQuizHistory = (moduleID: number, userId: number) => {
        addError(oidcUser.access_token, userId, orgDomain, 'Opened ' + moduleID.toString() +' quizhistory', null, "currentadminreport.tsx", 6, EnableActivityLogs)
    
        history.push({
            pathname: `../currentAdminReport/${moduleID}/${userId}/quizhistory`,               
            state: {
                page: "AtRisk",
                inputFilterFailureCount: inputFilterFailureCount,
                inputSelectedClassification: inputSelectedClassification,
                inputFilterDateFrom: inputFilterDateFrom,    
                inputFilterDateTo: inputFilterDateTo,               
                atRiskReportResultItems: AtRiskReportResult,
                resultError: resultError
            },
        });
    }
    const GetDatePeriod = () => {
        let DateEffectiveFrom: string = inputFilterDateFrom;
        let DateEffectiveTo: string = inputFilterDateTo;

        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + 1);

        if (DateEffectiveFrom == "1990-01-01" && DateEffectiveTo == formatDate(nextDate)) {
            return <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{"Date Range" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}> {"ALL DATA"}</span></div>
        } else {

            if (DateEffectiveFrom !== "" && DateEffectiveFrom !== undefined && DateEffectiveFrom !== null) {
                const tokens = DateEffectiveFrom.split("-");
                if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                    DateEffectiveFrom = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
                }
            }

            if (DateEffectiveTo !== "" && DateEffectiveTo !== undefined && DateEffectiveTo !== null) {
                const tokens = DateEffectiveTo.split("-");
                if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                    DateEffectiveTo = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
                }
            }

            return DateEffectiveFrom != "" ? <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{"Date Range" /* TODO Tranlation */}</span>
                <span className={styles.reportDetail}> {" "}{`${DateEffectiveFrom} - ${DateEffectiveTo}`}</span></div> : ""
        }
    }

    if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0) {
        setTitle(allResourcesToTranslations.atriskreport)
        setTitleEnglish(pageTitles.atriskreport)
        updateBackButtonRoute("/AtRiskReport");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div style={{ color: "red" }}>Error: {resultError}</div>
                <br />
                <div>Select the Back Button to return to Previous Page</div>
            </div>
        </BrandedCard>)
    }
    else if (AtRiskReportResult === null || AtRiskReportResult.length < 1) {
        setTitle(allResourcesToTranslations.atriskreport)
        setTitleEnglish(pageTitles.atriskreport)
        updateBackButtonRoute("/AtRiskReport");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div>No Results to Display</div>
                <div>Select the Back Button to return to Previous Page</div>
            </div>
        </BrandedCard>)
    } else {
        setTitle(allResourcesToTranslations.atriskreport)
        setTitleEnglish(pageTitles.atriskreport)
        updateBackButtonRoute("/AtRiskReport");
    }

    if (exporting) return <LoaderContainer />

    return (
        <>
            <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep}
                onExit={onExit}
                options={{
                    nextLabel: 'Next',
                    prevLabel: 'Back',
                    tooltipClass: 'sample-class',
                    showBullets: true,
                }} />
            <CustomTooltip displayText={["Guide Me"]}>
                <span style={{ right: "105px" }} onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
                    <GuideIcon />
                </span>
            </CustomTooltip>
            <BrandedCard>
                <div className={styles.reportPageTitleSection}>
                    <div className={styles.exportBtnContainer}>
                        <div className={styles.reportContainer}>
                            <div className={styles.reportDetailContainer}>
                                <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                                <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                            </div>
                            <div className={styles.reportDetailContainer}>
                                <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                                <span className={styles.reportDetail}>
                                    {reportRunTimeTaken}
                                </span>
                            </div>
                            {GetClassificationname()}
                            {GetSelectedFilteredFailureCount()}
                            {GetDatePeriod()}
                        </div>

                        <div className={styles.reportExportContainer}>
                            <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>
                                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCSRReportExportXLSX">
                                    <ExcelFileIcon />
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>
                                <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCSRReportExportCSV">
                                    <CSVFileIcon />
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>
                                <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCSRReportExportPDF">
                                    <PDFIcon />
                                </Button>
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
            </BrandedCard>
            <hr />
            {<div style={{ color: "red" }}>{(exportError === undefined || exportError === null || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}
            <hr />
            <div className={styles.paginationControlsContainer}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={AtRiskReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
            <div className={styles.tableSection}>
                <div className={styles.tableContainer}>
                    <BrandedCard>
                        <TableContainer>
                            <Table>
                                <TableHead className="AtRiskReportList">
                                    <TableRow>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", width: "100px" }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div
                                                    onClick={() => {
                                                        setUnitDescending(!unitDescending);
                                                        sortResultsBy("Unit", !unitDescending);
                                                    }}>
                                                    {"Unit Pathway"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: (currentSortBy === "Unit" && unitDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickUnitAsc}><UpArrowIcon /> </span>
                                                    <span style={{ opacity: (currentSortBy === "Unit" && unitDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickUnitDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div onClick={() => { setLastNameDescending(!lastNameDescending); sortResultsBy("LastName", !lastNameDescending); }}>
                                                    {"Last Name" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: currentSortBy === "LastName" && lastNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickLastNameAsc}>
                                                        <UpArrowIcon />{" "}
                                                    </span>
                                                    <span style={{ opacity: currentSortBy === "LastName" && lastNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickLastNameDesc}>
                                                        <DownArrowIcon />{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div onClick={() => { setFirstNameDescending(!firstNameDescending); sortResultsBy("FirstName", !firstNameDescending); }}>
                                                    {"First Name" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: currentSortBy === "FirstName" && firstNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickFirstNameAsc}>
                                                        <UpArrowIcon />{" "}
                                                    </span>
                                                    <span style={{ opacity: currentSortBy === "FirstName" && firstNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickFirstNameDesc}>
                                                        <DownArrowIcon />{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div onClick={() => { setEmailDescending(!emailDescending); sortResultsBy("Email", !emailDescending); }}>
                                                    {"Email" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: currentSortBy === "Email" && emailDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickEmailAsc}>
                                                        <UpArrowIcon />{" "}
                                                    </span>
                                                    <span style={{ opacity: currentSortBy === "Email" && emailDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickEmailDesc}>
                                                        <DownArrowIcon />{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, }} align="left">
                                            <div className={isLengthyCoursePresent ? styles.tableHeadCellContainerShort : styles.tableHeadCellContainer}>
                                                <div onClick={() => { setExternalIDDescending(!externalIDDescending); sortResultsBy("ExternalID", !externalIDDescending); }}>
                                                    {"External ID" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: currentSortBy === "ExternalID" && externalIDDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickExternalIDAsc}>
                                                        <UpArrowIcon />{" "}
                                                    </span>
                                                    <span style={{ opacity: currentSortBy === "ExternalID" && externalIDDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickExternalIDDesc}>
                                                        <DownArrowIcon />{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div onClick={() => { setUserNameDescending(!userNameDescending); sortResultsBy("UserName", !userNameDescending); }} >
                                                    {"Username" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: currentSortBy === "UserName" && userNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickUserNameAsc} >
                                                        <UpArrowIcon />{" "}
                                                    </span>
                                                    <span style={{ opacity: currentSortBy === "UserName" && userNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickUserNameDesc} >
                                                        <DownArrowIcon />{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={isLengthyCoursePresent ? styles.tableHeadCellContainerLong : styles.tableHeadCellContainer}>
                                                <div onClick={() => {
                                                    setCourseDescending(!courseDescending);
                                                    sortResultsBy("Course", !courseDescending);
                                                }}>
                                                    {"Course Name"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: (currentSortBy === "Course" && courseDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickCourseAsc}><UpArrowIcon /> </span>
                                                    <span style={{ opacity: (currentSortBy === "Course" && courseDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickCourseDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div onClick={() => {
                                                    setCourseDescending(!courseDescending);
                                                    sortResultsBy("Module", !courseDescending);
                                                }}>
                                                    {"Module Name"}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: (currentSortBy === "Module" && courseDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickCourseAsc}><UpArrowIcon /> </span>
                                                    <span style={{ opacity: (currentSortBy === "Module" && courseDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickCourseDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div onClick={() => { setFailNumberDescending(!failNumberDescending); sortResultsBy("FailNumber", !failNumberDescending); }}>
                                                    {"Fail Number" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: currentSortBy === "FailNumber" && failNumberDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickFailNumberAsc}>
                                                        <UpArrowIcon />{" "}
                                                    </span>
                                                    <span style={{ opacity: currentSortBy === "FailNumber" && failNumberDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickFailNumberDesc}>
                                                        <DownArrowIcon />{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div onClick={() => { setCurrentStatusDescending(!currentStatusDescending); sortResultsBy("CurrentStatus", !currentStatusDescending); }}>
                                                    {"Current Status" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span style={{ opacity: currentSortBy === "CurrentStatus" && currentStatusDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickCurrentStatusAsc}>
                                                        <UpArrowIcon />{" "}
                                                    </span>
                                                    <span style={{ opacity: currentSortBy === "CurrentStatus" && currentStatusDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickCurrentStatusDesc}>
                                                        <DownArrowIcon />{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div>
                                                    {"Unit Total"}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="hideForPrint" style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0",}} align="center">
                                            {allResourcesToTranslations.history}
                                        </TableCell>  
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        AtRiskReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                                            (result: any, i) => {
                                                if (result.grandTotalRow) {
                                                    return (
                                                        <TableRow key={`AtRiskReportresult-${i}`} >
                                                            <TableCell colSpan={12} style={{ padding: "0px" }}>
                                                                <table style={{ width: "100%", padding: "10px", borderColor: color, borderStyle: "solid", borderWidth: "2px 2px 0px 2px", borderRadius: "5px" }}>
                                                                    <tr>
                                                                        <td style={{ width: "15%", padding: "10px" }} >
                                                                            <b> {result.unitPathway}</b>
                                                                        </td>
                                                                        <td align="left" style={{ width: "70%" }}>
                                                                        </td>
                                                                        <td style={{ width: "10%", padding: "0px 0px 0px 50px" }} >
                                                                            <b> {result.courseName} </b>
                                                                        </td>
                                                                        <td align="left" style={{ width: "5%" }}>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                } else {
                                                    return (
                                                        <TableRow key={`AtRiskReportresult-${i}`} style={rowBackgroundColor(i)}>
                                                            <TableCell align="left" style={{ width: "10%" }}>
                                                                {result.showUnit && result.unitPathway}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "8%" }}>
                                                                {result.lastName}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "8%" }}>
                                                                {result.firstName}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "12%" }}>
                                                                {result.email}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "5%" }}>
                                                                {result.externalId}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "9%" }}>
                                                                {result.userName}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "12%" }}>
                                                                {result.courseName}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "12%" }}>
                                                                {result.moduleName}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "5%" }}>
                                                                {result.failNumber}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "9%" }}>
                                                                {result.currentStatus}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ width: "10%" }}>
                                                                {result.totalAccount}
                                                            </TableCell>
                                                            <TableCell className="hideForPrint history" align="center">
                                                            <CustomTooltip displayText={[allResourcesToTranslations.viewhistory]}>
                                                                <Button variant="contained" color="primary" onClick={() => { NavigateToQuizHistory(result.moduleId,result.userId); }}>
                                                                <ViewListIcon/>
                                                                </Button>
                                                            </CustomTooltip>
                                                    </TableCell>   
                                                        </TableRow>
                                                    )
                                                }
                                            })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>
                </div>
            </div>
            <div className={styles.paginationControlsContainer}>
                <TablePagination
                    component="div"
                    count={AtRiskReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
}

export default AtRiskReportResult
