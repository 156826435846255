import React, { useEffect, useContext, useState, useRef, createContext } from "react";

//Contexts
import { UIContext } from "../../contexts/UIContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';

//General Functions
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
import { SelectedSettings, SentEmailReportResultItem } from "../../globalTypes";
import CustomTooltip from "../../Components/CustomTooltip";
import { get2DigitDayOrMonth } from '../../functions/get2DigitDayOrMonth'
//Components
import BrandedCard from "../../Components/BrandedCard";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
import SendEmailReportIcon from "../../CustomIcons/SendEmailReportIcon";

//MUI Components
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import BrushIcon from "@material-ui/icons/Brush";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SelectAllIcon from "../../CustomIcons/SelectAllIcon";
import UnSelectAllIcon from "../../CustomIcons/UnSelectAllIcon";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';

// // Tiny MCE
import TinyMCEEditor from "../../Components/TinyMCEEditorComponent";

//General Functions
import getColor from "../../functions/getColor";

//APIs
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import addEmailingReport from "../../API/AddEmailingReport";

//Styles
import styles from "../../styles/emailReport.module.css";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import getActualOutgoingEmailsCount from "../../API/GetActualOutgoingEmailsCount";
import GuideIcon from "../../CustomIcons/GuideIcon";
import { Steps } from "intro.js-react";
import InfoOrErrorPopUp from "../../Components/InfoOrErrorPopUp";
import { createGuid } from "../../functions/generateGuid";
import { truncate } from "fs";

interface Props {
  selectedEmailReportType: number,
  selectedUnitIds: string;
  selectedUnitName: string;
  selectedCourseIds: string;
  selectedCourseName: string;
  selectedcourseQuizStatus: string;
  courseQuizStatusId: number;
  selectedRecipientType: string;
  selectedGrouping: string;
  selectedClassificationId: number;
  inputFilterFromDate: string;
  inputFilterToDate: string;
  inputFilterFirstName: string,
  inputFilterLastName: string,
  inputFilterUserName: string,
  inputFilterEmail: string,
  resultItems: any;
  resultBodyItems: any;
  resultError: any;
  handleSendPreviewEmailReport: any;
  handleBackButtonclick: any;
  setPageReset: boolean;

  selectedPolicyIDs: string;  
  selectedPolicyStatus: number; 
  selectedCPDProfileStatus: number;  
  selectedCPDProfileID: number; 
  selectedCPDProfileName: string;  
  newStarterOnlyFlag: boolean; 
  selectedExternalCertificateIDs: string;  
  selectedExternalCertificateStatus: number; 
}

export const EmailSendPreview = ({
  selectedEmailReportType,
  selectedUnitIds,
  selectedUnitName,
  selectedCourseIds,
  selectedCourseName,
  selectedcourseQuizStatus,
  courseQuizStatusId,
  selectedRecipientType,
  selectedGrouping,
  selectedClassificationId,
  inputFilterFromDate,
  inputFilterToDate,
  inputFilterFirstName,
  inputFilterLastName,
  inputFilterUserName,
  inputFilterEmail,
  resultItems,
  resultBodyItems,
  resultError,
  handleSendPreviewEmailReport,
  handleBackButtonclick,
  setPageReset,

  selectedPolicyIDs, 
  selectedPolicyStatus,
  selectedCPDProfileStatus, 
  selectedCPDProfileID, 
  selectedCPDProfileName,
  newStarterOnlyFlag,
  selectedExternalCertificateIDs, 
  selectedExternalCertificateStatus
}: Props): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { setSubComponentName } = useContext(UIContext);
  const [emailReportBodyResult, setEmailReportBodyResult] = useState<Array<SentEmailReportResultItem>>([]);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const [openCriteria, setOpenCriteria] = useState(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { theme: { color }, } = useContext(CorperateThemeContext);
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const [emailReportResult, setEmailReportResult] = useState<
    Array<any>
  >([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [retrievedActualOutgoingMailsCount, setActualOutgoingMailsCount] = useState<number>(0);
  const [retrievedTotalUserCount, setRetrievedTotalUserCount] = useState<number>(0);
  const [retrievedFormattedUserToIDsList, setFormattedUserToIDsList] = useState<string>("");
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [isHandlerBusy, setHandlerBusy] = useState<boolean>(false);
  const [firstNameDescending, setFirstNameDescending] = useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] = useState<boolean>(false);
  const [userEmailDescending, setUserEmailDescending] = useState<boolean>(false);
  const [hierarchyNameDescending, setHierarchyNameDescending] = useState<boolean>(false);
  const [dateCreatedDescending, setDateCreatedDescending] = useState<boolean>(false);
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  //const [emailSubject, setEmailSubject] = useState<string>(!!selectedEmailReportType ? (selectedEmailReportType == 0 ? "Salt Courses" : (selectedEmailReportType == 1 ? "Salt Policies" : (selectedEmailReportType == 2 ? "Continuous Professional Development Incomplete" : "Salt Login Details"))) : "");
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [emailBodyTemplate, setEmailBodyTemplate] = useState<string>("");
  const [isUserSelected, setIsUserSelected] = useState<boolean>(true);
  const [hasNoData, setHasNoData] = useState<boolean>(false);
  // States for PopUp
  const [isInputErrored, setIsInputErrored] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [popUpHeading, setPopUpHeading] = useState<string>("Error");
  const [popUpMessages, setPopUpMessages] = useState<Array<string>>(["Info"]);
  const firstUpdate = useRef(true);
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".emailReportUserListSelect",
        title: allResourcesToTranslations.emrepprvgmtselusrs, 
        intro: allResourcesToTranslations.emrepprvgmmselusrs,
      },
      {
        element: ".emailReportUserList",
        title: allResourcesToTranslations.emrepprvgmtrepdta, 
        intro: allResourcesToTranslations.emrepprvgmmrepdta,
      },
      {
        element: ".selectUser",
        title: allResourcesToTranslations.emrepprvgmtselusr, 
        intro: allResourcesToTranslations.emrepprvgmmselusr,
      },
      {
        element: ".emailSubject",
        title: allResourcesToTranslations.emrepprvgmtsub, 
        intro: allResourcesToTranslations.emrepprvgmmsub,
      },
      {
        element: ".editor",
        title: allResourcesToTranslations.emrepprvgmtembod, 
        intro: allResourcesToTranslations.emrepprvgmmembod,
      },
      {
        element: ".sendEmail",
        title: allResourcesToTranslations.emrepprvgmtsndem, 
        intro: allResourcesToTranslations.emrepprvgmmsndem,
      },
    ]
  })

  // useEffect(() => {
  //   let updateSteps = [...intro.steps];
  //   if (openCriteria) {
  //     updateSteps.push(
  //       {
  //         element: ".rowShading",
  //         intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
  //       }
  //     )
  //   }
  //   setIntro({ ...intro, steps: [...updateSteps] })
  // }, [openCriteria, emailReportBodyResult])

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    ResetAll();
    if (resultItems?.length > 0) {
      setHasNoData(false);
      let updatedData = resultItems.map((item: any) => {
        return {
          ...item,
          isChecked: true,
        };
      });
      setEmailReportResult(updatedData);
    } else {
      setHasNoData(true);
    }

    if (resultBodyItems?.length > 0) {
      setEmailBodyTemplate(resultBodyItems[0].body);
      let updatedDataNew = resultBodyItems.map((item: any) => {
        return {
          ...item,
          isChecked: false,
        };
      });
      setEmailReportBodyResult(updatedDataNew);
    }
    if (firstUpdate.current || emailSubject == '') {
      firstUpdate.current = false;
      // setEmailSubject((selectedEmailReportType == 0 ? "Salt Courses" : 
      // (selectedEmailReportType == 1 ? "Salt Policies" : 
      // (selectedEmailReportType == 2 ? "Continuous Professional Development Incomplete" : "Salt Login Details"))));
      setEmailIsValid(false);
    }
    //setSubComponentName("EmailReportPreview");
  }, [resultItems, resultBodyItems]);

  const sortResultsByAsync = async (
    sortBy:
      | "FirstName"
      | "LastName"
      | "HierarchyName"
      | "DateCreated"
      | "UserEmail",
    sortGivenItemInDesc: boolean
  ) => {
    if (emailReportResult == null || emailReportResult.length <= 0) {
      return emailReportResult;
    }
    var result = emailReportResult.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setEmailReportResult(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setEmailReportResult(result);
        break;
      case "UserEmail":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.userEmail === null) {
                return -1;
              }
              if (a === null || a.userEmail === null) {
                return 1;
              }
              if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) {
                return -1;
              }
              if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUserEmailDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.userEmail === null) {
                return 1;
              }
              if (a === null || a.userEmail === null) {
                return -1;
              }
              if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) {
                return -1;
              }
              if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUserEmailDescending(true);
            }
          }
          setEmailReportResult(result);
          break;

      case "HierarchyName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.hierarchyName === null) {
              return -1;
            }
            if (a === null || a.hierarchyName === null) {
              return 1;
            }
            if (a.hierarchyName.toLowerCase() > b.hierarchyName.toLowerCase()) {
              return -1;
            }
            if (a.hierarchyName.toLowerCase() < b.hierarchyName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setHierarchyNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.hierarchyName === null) {
              return 1;
            }
            if (a === null || a.hierarchyName === null) {
              return -1;
            }
            if (a.hierarchyName.toLowerCase() < b.hierarchyName.toLowerCase()) {
              return -1;
            }
            if (a.hierarchyName.toLowerCase() > b.hierarchyName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setHierarchyNameDescending(true);
          }
        }
        setEmailReportResult(result);
        break;
      case "DateCreated":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return -1; }
            if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return 1; }
            var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
            if(dta > dtb) { return -1; }
            if(dta < dtb) { return 1; }
            return 0;
        })
        if (reverseNextSort) {
            setDateCreatedDescending(false)
        }
        } else {
          result.sort((a, b) => {
            if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return 1; }
            if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return -1; }
            var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
            var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
            if(dta > dtb) { return 1; }
            if(dta < dtb) { return -1; }
            return 0;
        })
        if (reverseNextSort) {
            setDateCreatedDescending(true)
        }
        }
        setEmailReportResult(result);
        break;

      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "FirstName"
      | "LastName"
      | "HierarchyName"
      | "DateCreated"
      | "UserEmail",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setEmailReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickHierarchyNameAsc = () => {
    setReverseNextSort(false);
    setHierarchyNameDescending(false);
    sortResultsBy("HierarchyName", false);
  };

  const clickHierarchyNameDesc = () => {
    setReverseNextSort(false);
    setHierarchyNameDescending(true);
    sortResultsBy("HierarchyName", true);
  };

  const clickDateCreatedAsc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(false);
    sortResultsBy("DateCreated", false);
  };

  const clickDateCreatedDesc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(true);
    sortResultsBy("DateCreated", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickUserEmailAsc = () => {
    setReverseNextSort(false);
    setUserEmailDescending(false);
    sortResultsBy("UserEmail", false);
  };

  const clickUserEmailDesc = () => {
    setReverseNextSort(false);
    setUserEmailDescending(true);
    sortResultsBy("UserEmail", true);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleSelectedEmails = (e: any, data: any) => {
    e.stopPropagation();

    let updatedDataLocal = emailReportResult.map((item: any) => {
      if (data.userID == item.userID) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }
      return item;
    });

    let selectedItem = updatedDataLocal.find((item) => item.isChecked == true);
    if (selectedItem !== undefined) {
      setIsUserSelected(true);
    }
    else
    {
      setIsUserSelected(false); 
    }
    setEmailReportResult(updatedDataLocal);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = () => {
    let selectedData = emailReportResult.map((item: any) => {
      return {
        ...item,
        isChecked: true,
      };
    });
   
    setIsUserSelected(true);    
    setEmailReportResult(selectedData);
  };

  const handleUnSelectAll = () => {
    let unSelectedData = emailReportResult.map((item: any) => {
      return {
        ...item,
        isChecked: false,
      };
    });

    setIsUserSelected(false); 
    setEmailReportResult(unSelectedData);
  };

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

          return (year.toString() + "-" +
              (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
              (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
          return "";
      }
  }

  const GetDatePeriod = () => {
    if (selectedEmailReportType > 1) {
      return "";
    }
    let DateEffectiveFrom: string = inputFilterFromDate;
    let DateEffectiveTo: string = inputFilterToDate;

    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);

    if (DateEffectiveFrom == "1990-01-01" && DateEffectiveTo == formatDate(nextDate))
    {
      return <div className={styles.reportDetailContainer}>
      <span className={styles.reportDetailTitle}>{
          selectedEmailReportType === 0 ? allResourcesToTranslations.emrepgmqzdtrng :
            (selectedEmailReportType === 1 ? allResourcesToTranslations.emrepgmpoldtrng : "")
            }</span>
      <span className={styles.reportDetail}> {allResourcesToTranslations.emrepgmalldata}</span></div> 
    } else {

      if (inputFilterFromDate !== "" && inputFilterFromDate !== undefined && inputFilterFromDate !== null) {
        const tokens = DateEffectiveFrom.split("-");
        if (tokens !== null && tokens !== undefined && tokens.length == 3) {
          DateEffectiveFrom = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
        }
      }
    
      if (DateEffectiveTo !== "" && DateEffectiveTo !== undefined && DateEffectiveTo !== null) {
        const tokens = DateEffectiveTo.split("-");
        if (tokens !== null && tokens !== undefined && tokens.length == 3) {
          DateEffectiveTo = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
        }
      }

      return DateEffectiveFrom != "" ? <div className={styles.reportDetailContainer}>
        <span className={styles.reportDetailTitle}>{
          selectedEmailReportType === 0 ? allResourcesToTranslations.emrepgmqzdtrng :
            (selectedEmailReportType === 1 ? allResourcesToTranslations.emrepgmpoldtrng : "") 
            }</span>
        <span className={styles.reportDetail}> {" "}{`${DateEffectiveFrom} - ${DateEffectiveTo}`}</span></div> : ""
    }
  }

  // const GetSelectedUnitNameSummary = () => {

  //   let arr = selectedUnitName.split(',');
  //   return (selectedUnitName != "" && arr.length > 5) ? <div className={styles.reportDetailContainer}>
  //     <span className={styles.reportDetailTitle}>{"Unit(s)" /* TODO Tranlation */}</span>
  //     {selectedUnitName.split(',', 5).map((step: any) => <span className={styles.reportDetail}>{step}</span>)}
  //     <span className={styles.reportDetail}>{arr.length > 6 ? "+" + (arr.length - 6) + " more..." : ""}</span>
  //   </div> :

  //     <div className={styles.reportDetailContainer}>
  //       <span className={styles.reportDetailTitle}>{"Unit(s)" /* TODO Tranlation */}</span>
  //       {selectedUnitName.split(',').map((step: any) => <span className={styles.reportDetail}>{step}</span>)}
  //     </div>
  // }

  const GetSelectedCourseNameSummary = () => {
    if (!!selectedCourseName) {
      let arr = selectedCourseName.split(',');
      return (selectedCourseName != "" && arr.length > 5) ?
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>{allResourcesToTranslations.emrepgmcrseappl}</span>
          {selectedCourseName.split(',', 5).map((step: any) => <span key={step} className={styles.reportDetail}>{step}</span>)}
          <CustomTooltip displayText={selectedCourseName.split(',').splice(5, arr.length)} placement="bottom">
            <span className={styles.reportDetail}>{arr.length > 5 ? "+" + (arr.length - 5) + " " + allResourcesToTranslations.emrepgmmore + "..." : ""}</span>
          </CustomTooltip>
        </div>
        :
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>{allResourcesToTranslations.emrepgmcrseappl}</span>
          {selectedCourseName.split(',').map((step: any) => <span key={step} className={styles.reportDetail}>{step}</span>)}
        </div>
    } else {
      return "";
    }
  }

  const GetSelectedFilteredFirstName = () => {
    if (inputFilterFirstName !== '') {
      return <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                  {allResourcesToTranslations.firstname}
                </span>
                <span className={styles.reportDetail}>{inputFilterFirstName}</span>
            </div>
    } else {
      return "";
    }
  }
  const GetSelectedFilteredLastName = () => {
    if (inputFilterLastName !== '') {
    return <div className={styles.reportDetailContainer}>
              <span className={styles.reportDetailTitle}>
                {allResourcesToTranslations.lastname}
              </span>
              <span className={styles.reportDetail}>{inputFilterLastName}</span>
          </div>
    } else {
    return "";
    }
  }
  const GetSelectedFilteredUserName = () => {
    if (inputFilterUserName !== '') {
      return <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                  {allResourcesToTranslations.username}
                </span>
                <span className={styles.reportDetail}>{inputFilterUserName}</span>
            </div>
      } else {
      return "";
      }
  }
  const GetSelectedFilteredEmail = () => {
    if (inputFilterEmail !== '') {
      return <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>
                  {allResourcesToTranslations.emrepgmum}
                </span>
                <span className={styles.reportDetail}>{inputFilterEmail}</span>
            </div>
      } else {
      return "";
      }
  }

  const ResetAll = () => {
    setEmailSubject('');
    // setEmailSubject((selectedEmailReportType == 0 ? "Salt Courses" :
    //     (selectedEmailReportType == 1 ? "Salt Policies" :
    //         (selectedEmailReportType == 2 ? "Continuous Professional Development Incomplete" : "Salt Login Details"))));
    setEmailIsValid(false);
    setEmailReportResult([]);
    setEmailReportBodyResult([]);
    setPage(0);
    setRowsPerPage(10);
  };

  const handleTextEditorChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event !== undefined) {
      setEmailBodyTemplate(event.target.value);
  }
  };


  useEffect(() => {
    getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      if (res.isSuccess) {
        let curdt = res.currentDateTimeText;
        setReportRunTimeTaken(curdt);
      } else {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
      }
     }).catch ((error) => {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
     });
    //setSubComponentName("EmailReportPreview");
  }, []);

  useEffect(() => 
  {
    if (setPageReset) {
      firstUpdate.current = true;
      ResetAll();
    }
    //setSubComponentName("EmailReportPreview");
  },[setPageReset]);

  // useEffect(() => {

  // }, [selectedUnitIds,selectedUnitName, selectedCourseIds, selectedCourseName, selectedcourseQuizStatus, courseQuizStatusId, selectedRecipientType, selectedGrouping, 
  //   selectedClassificationId, inputFilterFromDate,inputFilterToDate, resultItems,resultBodyItems,resultError]);

  useEffect(() => {
    //setSubComponentName("EmailReportPreview");
    return () => {
      //setSubComponentName("");
    } 
  }, [showPopUp])

  //const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const handleEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if ((event.target.value != '')) {
      setEmailIsValid(false)
    }
    setEmailSubject(event.target.value);
  };

  const handleShowPopUp = (value: boolean) => {
    setShowPopUp(value);
  }

  const handlePopUpClose = () => {
    setShowPopUp(false);
  }
  const handlePopUpProceed = () => {
    setShowPopUp(false);
    SendEmail();
  }

  const handleSendEmail = () => {
    if (emailSubject == '') {
      setEmailIsValid(true);
    } else {
      setHandlerBusy(true);
      setEmailIsValid(false);
      let userIds: Array<number> = [];
      const getSelectedUsers = () => {
        for (let i = 0; i < emailReportResult.length; i++) {
          if (emailReportResult[i].isChecked && !userIds.includes(emailReportResult[i].userID)) {
            userIds.push(emailReportResult[i].userID);
          }
        }
      }
      getSelectedUsers();
      getActualOutgoingEmailsCount(oidcUser.access_token,
        orgDomain, userId, parseInt(orgId), selectedUnitIds, selectedCourseIds, userIds.toString(),
        selectedClassificationId, courseQuizStatusId, inputFilterFromDate, inputFilterToDate,
        0, selectedRecipientType.toLowerCase() === "admins" ? 1 : 2, selectedEmailReportType, 
        selectedPolicyIDs, parseInt(selectedPolicyStatus.toString()), 
        selectedExternalCertificateIDs, parseInt(selectedExternalCertificateStatus.toString()), 
        parseInt(selectedCPDProfileStatus.toString()), parseInt(selectedCPDProfileID.toString()), newStarterOnlyFlag).then(response => {
          //debugger
          if (!!response && response.isSuccess && !!response.actualOutgoingMailsCount && 
            !!response.totalUserCount && !!response.formattedUserToIDsList) {
            setActualOutgoingMailsCount(response.actualOutgoingMailsCount);
            setFormattedUserToIDsList(response.formattedUserToIDsList);
            setRetrievedTotalUserCount(response.totalUserCount);
            if (!!response.willProcessingSpillOverWeekend && response.willProcessingSpillOverWeekend) {
              setPopUpMessages(["The selected email report processing overlaps with service restart time, please change your input or process it later"]);
              setIsInputErrored(true);
              setShowPopUp(true);
              setPopUpHeading("Error");
              setHandlerBusy(false);
            }
            else 
            {
              // Show Confirm Pop up
              if (response.actualOutgoingMailsCount == response.totalUserCount) {
                setPopUpMessages(["You are about to send emails to " + response.totalUserCount.toString() + " " + (selectedRecipientType.toLowerCase() === "admins" ? "administrators" : "users") + 
                ". Do you wish to proceed?"]);
              } else {
              setPopUpMessages(["You are about to send a total of " + response.actualOutgoingMailsCount.toString() + 
                  " emails to " + response.totalUserCount.toString() + " " + (selectedRecipientType.toLowerCase() === "admins" ? "administrators" : "users") + 
                  ". Do you wish to proceed?"]);
              }

              setIsInputErrored(false);
              setShowPopUp(true);
              setPopUpHeading("Confirmation");
              setHandlerBusy(false);
            }
          } else {
            const errorVal = (response !== undefined && response !== null && response.error !== undefined && response.error !== null) ? response.error : "Failed to get the total outgoing emails count";
            setPopUpMessages([errorVal]);
            setIsInputErrored(true);
            setShowPopUp(true);
            setPopUpHeading("Error");
            setHandlerBusy(false);
          }
        }).catch((error) => {
          const errorVal = (error !== undefined && error !== null) ? error : "Failed to get the total outgoing emails count due to exception";
          setPopUpMessages([errorVal]);
          setIsInputErrored(true);
          setShowPopUp(true);
          setPopUpHeading("Error");
          setHandlerBusy(false);
        });      
    }
  }

  const SendEmail = () => {
    let userIds: Array<number> = [];
    let selectedUserCount: number = 0;
    const getSelectedUsers = () => {
      for (let i = 0; i < emailReportResult.length; i++) {
        if (emailReportResult[i].isChecked && !userIds.includes(emailReportResult[i].userID)) {
          userIds.push(emailReportResult[i].userID);
          selectedUserCount++;
        }
      }
      setIsUserSelected(selectedUserCount > 0);
    }
    getSelectedUsers();
    if (selectedUserCount > 0) {
      var emailBodyTemplateContent = (document.getElementById('editorText') as HTMLInputElement).value;
      var emailBodyTemplateInitialContent = (document.getElementById('editorChangedText') as HTMLInputElement).value;
      setEmailBodyTemplate((!!emailBodyTemplateContent === false || emailBodyTemplateContent.trim() === "") ? emailBodyTemplateInitialContent : emailBodyTemplateContent);
      addEmailingReport(oidcUser.access_token,
        orgDomain, userId, parseInt(orgId), selectedUnitIds, selectedCourseIds, userIds.toString(),
        retrievedActualOutgoingMailsCount, emailSubject, (!!emailBodyTemplateContent === false || emailBodyTemplateContent.trim() === "") ? emailBodyTemplateInitialContent : emailBodyTemplateContent,
        courseQuizStatusId, inputFilterFromDate, inputFilterToDate, 
        selectedRecipientType.toLowerCase() === "admins" ? 1 : 2, selectedClassificationId,
        retrievedTotalUserCount, retrievedFormattedUserToIDsList, selectedEmailReportType, selectedPolicyIDs, selectedPolicyStatus, 
        selectedExternalCertificateIDs, selectedExternalCertificateStatus, selectedCPDProfileStatus, selectedCPDProfileID, newStarterOnlyFlag, false, '', false).then(response => {
          if (!!response && response.isSuccess) {
            handleSendPreviewEmailReport(response.addedEmailingReportID);
          } else {
            const errorVal = (response !== undefined && response !== null && 
              response.error !== undefined && response.error !== null) ? response.error : 
              "Saving emailing report failed";
            setPopUpMessages([errorVal]);
            setIsInputErrored(true);
            setShowPopUp(true);
            setPopUpHeading("Error");
            handleSendPreviewEmailReport(0);

          }
        }).catch((error) => {
          const errorVal = (error !== undefined && error !== null) ? error : "Saving emailing report failed with exception";
          setPopUpMessages([errorVal]);
          setIsInputErrored(true);
          setShowPopUp(true);
          setPopUpHeading("Error");
          handleSendPreviewEmailReport(0);
        });
    } else {
      handleSendPreviewEmailReport(0);
    }
  }

  return (
      <>
      <InfoOrErrorPopUp key="emailReportSendErrorPopUp"
         isError={isInputErrored}
         givenPopUpHeading={popUpHeading}
         givenShowPopUp={showPopUp}
         givenMessages={popUpMessages}
         givenSettingsList={[]}
         onHandleShowPopUp={handleShowPopUp}
         onPopUpClose={handlePopUpClose}
         onPopUpProceed={handlePopUpProceed}
         givenFlagToHideTopRightCloseBtn={false} 
         isInfoErrorHighlightRequired={false}
         givenSubBulletRequiredSettingTitles={[]} />
        <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep}
          onExit={onExit}
          options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }} />
        <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
          <span style={{ right: "105px" }} onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
          </span>
        </CustomTooltip>
        <div className={styles.backButtonSpecific}>
          <CustomTooltip displayText={[allResourcesToTranslations.back]}>
            <Button
              onClick={() => handleBackButtonclick(false)}
              variant="contained"
              color="primary"
              disabled={false}
              className="back"
            >
              <ArrowBackIcon />
            </Button>
          </CustomTooltip>
        </div>
        <Accordion
          className={styles.criteriaSection}
          style={{
            margin: "10px 0px",
            borderRadius: "6px 6px 6px 6px",
            border: "0px dashed transparent",
            position: "inherit",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setOpenCriteria(!openCriteria)}
          >
            {!openCriteria ? (
              <b>{allResourcesToTranslations.emrepgmvwrepcri}</b>
            ) : (
              <b>{allResourcesToTranslations.emrepgmclrepcri}</b>
            )}
          </AccordionSummary>
          <div className={styles.exportBtnContainer}>
            <div className={styles.reportContainer}>
              <div className={styles.reportDetailContainer}>
                <div style={{ marginLeft: "18px", marginBottom: "20px" }}>
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.reportyrunby}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {`${given_name} ${family_name}`}
                    </span>
                  </div>
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.reportruntime}
                    </span>
                    <span className={styles.reportDetail}>{reportRunTimeTaken}</span>
                  </div>
                  {<div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmembased}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedEmailReportType === 0 ? allResourcesToTranslations.emrepgmcs : 
                        (selectedEmailReportType === 1 ? allResourcesToTranslations.policyacceptance : 
                          (selectedEmailReportType === 2 ? allResourcesToTranslations.emrepgmcpd : 
                            (selectedEmailReportType === 3 ? allResourcesToTranslations.emrepgmwe : 
                              (selectedEmailReportType === 4 ? allResourcesToTranslations.extcertificate : ""))))}
                    </span>
                  </div>
                  }
                  {selectedEmailReportType === 0 && GetSelectedCourseNameSummary()}
                  {/* {selectedEmailReportType === 2 && 
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {"CPD Profile ID" }
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedCPDProfileID.toString()}
                    </span>
                  </div>
                  } */}
                  {selectedEmailReportType === 2 && 
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmcpdname}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedCPDProfileName.toString()}
                    </span>
                  </div>
                  }
                  {selectedEmailReportType === 0 &&
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmcrsqz}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedcourseQuizStatus}
                    </span>
                  </div>
                  }
                  {selectedEmailReportType === 1 &&
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmpol}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedPolicyStatus === 0 ? allResourcesToTranslations.accepted : allResourcesToTranslations.notaccepted}
                    </span>
                  </div>
                  }
                  {selectedEmailReportType === 4 &&
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmecsta}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedExternalCertificateStatus === 0 ? allResourcesToTranslations.selectpagereference : 
                      (selectedExternalCertificateStatus === 1 ? allResourcesToTranslations.notprovidedcertificates + " / " + allResourcesToTranslations.emrepgmoutst + " / " + allResourcesToTranslations.emrepgmnew : 
                      (selectedExternalCertificateStatus === 2 ? allResourcesToTranslations.providedcertificates + " / " + allResourcesToTranslations.emrepgmvld + " / " + allResourcesToTranslations.emrepgmcmpl : 
                      (selectedExternalCertificateStatus === 3 ? allResourcesToTranslations.emrepgmclsd + " / " + allResourcesToTranslations.overdue + " / " + allResourcesToTranslations.expiredcertificates : allResourcesToTranslations.selectpagereference)))}
                    </span>
                  </div>
                  }
                  {selectedEmailReportType === 3 &&
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmnwstronly}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {newStarterOnlyFlag ? allResourcesToTranslations.emrepgmyes : allResourcesToTranslations.emrepgmno}
                    </span>
                  </div>
                  }
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmgrping}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedGrouping}
                    </span>
                  </div>
                  {GetDatePeriod()}
                  {selectedEmailReportType !== 3 && selectedEmailReportType !== 2 &&
                  <div className={styles.reportDetailContainer}>
                    <span className={styles.reportDetailTitle}>
                      {allResourcesToTranslations.emrepgmrecpt}
                    </span>
                    <span className={styles.reportDetail}>
                      {" "}
                      {selectedRecipientType}
                    </span>
                  </div>
                  }
                  {GetSelectedFilteredFirstName()}
                  {GetSelectedFilteredLastName()}
                  {GetSelectedFilteredUserName()}
                  {GetSelectedFilteredEmail()}
                </div>
              </div>
            </div>
            <div className={styles.reportExportContainer}>
              {/* <CustomTooltip
                displayText={
                  ["Show/Hide Row Shading"] 
                
              >
                <Button
                  className="rowShading"
                  color="primary"
                  onClick={toggleBanding}
                >
                  <BrushIcon />
                  <CalendarViewDayIcon />
                </Button>
              </CustomTooltip> */}
            </div>
          </div>
        </Accordion>
        <div hidden={!hasNoData}>
          <span>{allResourcesToTranslations.emrepgmrefsrchmsg}</span>
        </div>
        <div hidden={hasNoData}>
          <div style={{ display: "flex" }}>
            <div className="emailReportUserListSelect">
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="Filter"
              >
                <Button className={styles.button} onClick={handleSelectAll}>
                  <SelectAllIcon />
                  <span style={{ marginLeft: "5px", fontSize: "12px" }}>{
                    ` ` + allResourcesToTranslations.selectall
                  }</span>
                </Button>
                <Button
                  className={styles.button}
                  onClick={handleUnSelectAll}
                  style={{ marginLeft: "-5px" }}
                >
                  <UnSelectAllIcon />
                  <span style={{ fontSize: "12px", marginLeft: "5px" }}>{
                    ` ` + allResourcesToTranslations.unselectall
                  }</span>
                </Button>
              </ButtonGroup>
            </div>
            <div style={{ flexGrow: 2, marginRight: "37%" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={emailReportResult.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div>
            <div className={styles.tableSection}>
              <div className={styles.tableContainer}>
                <BrandedCard>
                  <TableContainer>
                    <Table>
                      <TableHead className="emailReportUserList">
                        <TableRow>
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                              borderRadius: "8px 0 0 0",
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div className="selectUser">{"Select"}</div>
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div
                                onClick={() => {
                                  setLastNameDescending(!lastNameDescending);
                                  sortResultsBy("LastName", !lastNameDescending);
                                }}
                              >
                                {allResourcesToTranslations.lastname }
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "LastName" &&
                                        lastNameDescending === false
                                        ? "100%"
                                        : "50%",
                                    margin: "2px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickLastNameAsc}
                                >
                                  <UpArrowIcon />{" "}
                                </span>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "LastName" &&
                                        lastNameDescending === true
                                        ? "100%"
                                        : "50%",
                                    margin: "0px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickLastNameDesc}
                                >
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div
                                onClick={() => {
                                  setFirstNameDescending(!firstNameDescending);
                                  sortResultsBy(
                                    "FirstName",
                                    !firstNameDescending
                                  );
                                }}
                              >
                                {allResourcesToTranslations.firstname }
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "FirstName" &&
                                        firstNameDescending === false
                                        ? "100%"
                                        : "50%",
                                    margin: "2px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickFirstNameAsc}
                                >
                                  <UpArrowIcon />{" "}
                                </span>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "FirstName" &&
                                        firstNameDescending === true
                                        ? "100%"
                                        : "50%",
                                    margin: "0px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickFirstNameDesc}
                                >
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>  
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                            }}
                            align="left"
                          >
                          {selectedEmailReportType !== 0 &&
                            <div className={styles.tableHeadCellContainer}>
                              <div
                                onClick={() => {
                                  setUserEmailDescending(!userEmailDescending);
                                  sortResultsBy(
                                    "UserEmail",
                                    !userEmailDescending
                                  );
                                }}
                              >
                                {allResourcesToTranslations.emrepgmum }
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "UserEmail" &&
                                      userEmailDescending === false
                                        ? "100%"
                                        : "50%",
                                    margin: "2px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickUserEmailAsc}
                                >
                                  <UpArrowIcon />{" "}
                                </span>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "UserEmail" &&
                                      userEmailDescending === true
                                        ? "100%"
                                        : "50%",
                                    margin: "0px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickUserEmailDesc}
                                >
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          }
                          </TableCell>
                          <TableCell
                            style={{
                              color: getColor(color.substring(1)),
                              backgroundColor: color,
                            }}
                            align="left"
                          >
                            <div className={styles.tableHeadCellContainer}>
                              <div
                                onClick={() => {
                                  setHierarchyNameDescending(!hierarchyNameDescending);
                                  sortResultsBy(
                                    "HierarchyName",
                                    !hierarchyNameDescending
                                  );
                                }}
                              >
                                {allResourcesToTranslations.emrepgmunit}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "HierarchyName" &&
                                        hierarchyNameDescending === false
                                        ? "100%"
                                        : "50%",
                                    margin: "2px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickHierarchyNameAsc}
                                >
                                  <UpArrowIcon />{" "}
                                </span>
                                <span
                                  style={{
                                    opacity:
                                      currentSortBy === "HierarchyName" &&
                                        hierarchyNameDescending === true
                                        ? "100%"
                                        : "50%",
                                    margin: "0px",
                                    height: "6px",
                                    display: "inline-flex",
                                  }}
                                  onClick={clickHierarchyNameDesc}
                                >
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          {
                            (selectedEmailReportType === 0 || selectedEmailReportType === 3) &&
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "0 8px 0 0"
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setDateCreatedDescending(!dateCreatedDescending);
                                    sortResultsBy(
                                      "DateCreated",
                                      !dateCreatedDescending
                                    );
                                  }}
                                >
                                  {allResourcesToTranslations.emrepgmdtcreate}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "DateCreated" &&
                                          dateCreatedDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickDateCreatedAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "DateCreated" &&
                                          dateCreatedDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickDateCreatedDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                          }
                          {
                            (selectedEmailReportType === 4 && !!selectedRecipientType && selectedRecipientType.toLowerCase() === "admins") &&
                            <TableCell
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "0 8px 0 0"
                              }}
                              align="left"
                            >
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {allResourcesToTranslations.emrepgmadmtyp}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:"100%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                  >{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:"100%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                  >{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {emailReportResult
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((result: any, i) => {
                            return (
                              <TableRow
                                key={`currentemailReportResult-${i}`}
                                style={rowBackgroundColor(i)}
                              >
                                <TableCell align="left" className="select">
                                  <Checkbox
                                    size="small"
                                    color="primary"
                                    className={styles.checkBox}
                                    name="selectedUser"
                                    key={result.userID}
                                    checked={result.isChecked}
                                    onChange={(e) =>
                                      handleSelectedEmails(e, result)
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {result.lastName}
                                </TableCell>
                                <TableCell align="left">
                                  {result.firstName}
                                </TableCell>
                                <TableCell align="left">
                                  {result.userEmail}
                                </TableCell>
                                <TableCell align="left">
                                  {result.hierarchyName}
                                </TableCell>
                                {
                                  (selectedEmailReportType === 0 || selectedEmailReportType === 3) &&
                                    <TableCell align="left">
                                      {result.dateCreated}
                                    </TableCell>
                                }
                                {
                                  (selectedEmailReportType === 4 && !!selectedRecipientType && selectedRecipientType.toLowerCase() === "admins") &&
                                    <TableCell align="left">
                                      {result.adminType}
                                    </TableCell>
                                }
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </BrandedCard>
              </div>
            </div>
            <div hidden={isUserSelected} style={{ width: "98%", marginTop: "10px", color: "red" }}><label>{allResourcesToTranslations.emrepgmselac}</label></div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TablePagination
                component="div"
                count={emailReportResult.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
        <div hidden={hasNoData}>
          <BrandedCard>
            <div className="emailSubject" style={{ marginLeft: "20px", paddingTop: "30px" }}>
              <TextField
                className={styles.input}
                label={allResourcesToTranslations.emrepgmemsub}
                style={{ width: "98%", marginBottom: "10px" }}
                variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                value={emailSubject}
                onChange={handleEmailSubject}
                error={emailIsValid}
                placeholder={"*" + allResourcesToTranslations.emrepgmfldreqd}
              />
            </div>
            <div className="editor" style={{ marginLeft: "20px", paddingTop: "10px", width: '97%', paddingBottom: '10px' }} >
              {emailReportBodyResult.map((result: any, i) => {
                if (i == 0) {
                  return (
                    <TinyMCEEditor index={i} key={"tinyMCEEditor" + i} editorData={result.body} isTemplate={false} is200Tall={true} handleTextEditorChange={handleTextEditorChange} />
                  );
                }
              })} 
            </div>
            <div className={styles.sendEmail}>
              <div className={styles.loaderContainer}>
                <CircularProgress 
                  style={{ width: isHandlerBusy ? "20px" : "0px", marginTop:'13px'}}
                  color="primary" 
                  size="10"
                  />
              </div>
              <div className="sendEmail">
                <CustomTooltip displayText={[allResourcesToTranslations.emrepprvgmtsndem]} >
                  <span>
                    <Button
                      onClick={handleSendEmail}
                      variant="contained"
                      color="primary"
                      disabled={!isUserSelected}
                      id="btnNewSaltSendEmailReport">
                      <SendEmailReportIcon />
                    </Button>
                  </span>
                </CustomTooltip>
              </div>
            </div>
          </BrandedCard>
        </div>
      </>
  );
}

export default EmailSendPreview;
