//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ReportCourseModuleItem, ReportItemCPDDetails, CPDTotal, ReportPolicyItem, ReportExternalCertificateItem, ReportNewsfeedItem } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { jsPDF } from 'jspdf';
// import '../../CustomFonts/NotoSansMyanmar-Regular-normal.js';
// import '../../CustomFonts/ZCOOLXiaoWei-Regular-normal.js';
// import '../../CustomFonts/NotoSansCT-Regular-normal.js';
// import '../../CustomFonts/KosugiMaru-Regular-normal.js';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { CPDPanelContext } from "../../contexts/CPDPanelContext";
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import { addError } from '../../API/AddError'
import { getUserPersonalReport } from '../../API/GetUserPersonalReport';
import { getChineseSimplifiedTTFData } from '../../API/GetChineseSimplifiedTTFData'
import { getChineseTraditionalTTFData } from '../../API/GetChineseTraditionalTTFData'
import { getJapaneseTTFData } from '../../API/GetJapaneseTTFData'
import { getMyanmarTTFData } from '../../API/GetMyanmarTTFData'
import { getUserCPDs } from '../../API/UserCPDs';
//General Functions
import getColor from "../../functions/getColor";
//Components
import CustomTooltip from '../../Components/CustomTooltip';
import UnbrandedCard from '../../Components/UnbrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import GetAppIcon from '@material-ui/icons/GetApp';
import ViewListIcon from '@material-ui/icons/ViewList';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';


//Styles
import styles from '../../styles/report.module.css'
import moment from 'moment';

const Report = (): JSX.Element => {
  const { translations: { allResourcesToTranslations, currentPageLang }, pageTitles} = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { showCPD, setShowCPD, setTitle, setTitleEnglish, setMenuSelection, updateBackButtonRoute, checkScrollReminder, setShowScrollArrow, 
          showLastPassDate, showReportDueDate, setshowReportDueDate, displayName, EnableActivityLogs, latestUserCPDs, setLatestUserCPDs } = useContext(UIContext);
  const { cpdList, setCpdList, currentCpd, setCurrentCpd, isCPDLoaded, setCPDLoaded } = useContext(CPDPanelContext);
  const [reportItemsOfCourseModules, setReportItemsOfCourseModules] = useState<Array<ReportCourseModuleItem> | null>(null);
  const [reportItemsOfPolicies, setReportItemsOfPolicies] = useState<Array<ReportPolicyItem> | null>(null);
  const [reportItemsOfExternalCertificates, setReportItemsOfExternalCertificates] = useState<Array<ReportExternalCertificateItem> | null>(null); 
  const [reportItemsOfNewsfeed, setReportItemsOfNewsfeed] = useState<Array<ReportNewsfeedItem> | null>(null);   
  const [cpdTotals, setCpdTotals] = useState<Array<CPDTotal> | null>(null)
  const [loading, setLoading] = useState<boolean>(true);
  const [exporting, setExporting] = useState<boolean>(false);
  const { oidcUser } = useReactOidc();
  const [filterCourseName, setFilterCourseName]  = useState("");
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".report",
        title: "Personal Report of Course-Modules", intro: "Your Personal Report shows all your assigned Courses, Modules, their current Statuses, the Quiz Status Date, Score, Required Pass Mark and if assigned your CPD Profile summary positions."
      },
      
    ]
  })

  const refreshGuideme = () => {
    let updateSteps = []
    updateSteps.push( {
      element: "#reportID",
      title: "Personal Report of Course-Modules, Policies and External Certificates", intro: "Your Personal Report shows all your assigned Courses, Modules, their current Statuses, the Quiz Status Date, Score, Required Pass Mark and if assigned your CPD Profile summary positions. If policies and external certificates are assigned, scroll down or search for the headers/rows with 'Policies' or 'External Certificates' in the first column"
    });
    if (!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0) {
        updateSteps.push( {
        element: ".report",
        title: "Personal Report of Course-Modules", intro: "Your Personal Report shows all your assigned Courses, Modules, their current Statuses, the Quiz Status Date, Score, Required Pass Mark and if assigned your CPD Profile summary positions."
      });
    }
    if (!!reportItemsOfPolicies && reportItemsOfPolicies.length > 0) {
      updateSteps.push( {
        element: "#tableHeadStartPolicyRowID",
        title: "Personal Report of Policies", intro: "Your Personal Report also shows all your assigned Policies, their current Statuses, their Policy Status Dates and if assigned your CPD Profile summary positions."
      });
    }
    if (!!reportItemsOfExternalCertificates && reportItemsOfExternalCertificates.length > 0) {
      updateSteps.push( {
        element: "#tableHeadStartExtcertRowID",
        title: "Personal Report of External Certificates", intro: "Your Personal Report also shows all your assigned External Certificates, their current Statuses, their Status Dates and if assigned your CPD Profile summary positions."
      });
    }

    if (!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0) {
        updateSteps.push( {
        element: ".history",
        title: "Quiz History", intro: "Select this button to view all of your Quiz attempts, Quiz status dates and related scores for this Module."
      });
    }
    updateSteps.push({
      element: ".pdf",
      title: "Export", intro: "Select this button to export a copy of your Personal Report."
    })

    if(showLastPassDate) {
      updateSteps.splice(2,0,{
        element: ".dueDate",
        title: "Hide Next New Dates", intro: "Select button to show or hide when each Module is next due for completion."
      })
    }

    setIntro({...intro, steps: [...updateSteps]});
  }

  const loadData = () => {
      setLoading(true)
      let cpdId = -1
      let cpdPrdId = -1 
      getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
        if(isMounted.current){
          setLatestUserCPDs(cpdData);

          let cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
          let cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
          setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0);
          setCpdList(cpdData)

          cpdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].id : -1
          cpdPrdId = (!!cpdData && cpdData.length > 0) ? cpdData[currentCpd].periodID : -1
          
          getUserPersonalReport(oidcUser.access_token, userId, orgDomain).then(dataResult => {
            if(isMounted.current){
              setCpdTotals(dataResult.listOfCPDTotals2)
              setReportItemsOfCourseModules(dataResult.courseModuleItems)
              setReportItemsOfPolicies(dataResult.policyItems)
              setReportItemsOfExternalCertificates(dataResult.externalCertificateItems);
              setReportItemsOfNewsfeed(dataResult.newsfeedItems)
              setLoading(false)
              checkScrollReminder()
            }
          }).catch((e) => {
            console.error(e);
            setLoading(false);
          });
        }
      }).catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }

  useEffect(() => {

    refreshGuideme();
    //let updateSteps = [...intro.steps]
    
    // let updateSteps = []
    // if (!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0) {
    //     updateSteps.push( {
    //     element: ".report",
    //     title: "Personal Report of Course-Modules", intro: "Your Personal Report shows all your assigned Courses, Modules, their current Statuses, the Quiz Status Date, Score, Required Pass Mark and if assigned your CPD Profile summary positions."
    //   });
    // }
    // if (!!reportItemsOfPolicies && reportItemsOfPolicies.length > 0) {
    //   updateSteps.push( {
    //     element: "#tableHeadStartPolicyRowID",
    //     title: "Personal Report of Policies", intro: "Your Personal Report also shows all your assigned Policies, their current Statuses, their Policy Status Dates and if assigned your CPD Profile summary positions."
    //   });
    // }
    // if (!!reportItemsOfExternalCertificates && reportItemsOfExternalCertificates.length > 0) {
    //   updateSteps.push( {
    //     element: "#tableHeadStartExtcertRowID",
    //     title: "Personal Report of External Certificates", intro: "Your Personal Report also shows all your assigned External Certificates, their current Statuses, their Status Dates and if assigned your CPD Profile summary positions."
    //   });
    // }

    // if (!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0) {
    //     updateSteps.push( {
    //     element: ".history",
    //     title: "Quiz History", intro: "Select this button to view all of your Quiz attempts, Quiz status dates and related scores for this Module."
    //   });
    // }
    
    // updateSteps.push( {
    //   element: ".pdf",
    //   title: "Export", intro: "Select this button to export a copy of your Personal Report."
    // });

    // if(showLastPassDate) {
    //   updateSteps.splice(2,0,{
    //     element: ".dueDate",
    //     title: "Hide Next New Dates", intro: "Select button to show or hide when each Module is next due for completion."
    //   })
    // }

    // setIntro({...intro, steps: [...updateSteps]})
    
  },[])

  useEffect(() => {
      if (reportItemsOfCourseModules !== null && reportItemsOfCourseModules !== undefined && reportItemsOfCourseModules.length > 0) {
          var reportItemsModified = reportItemsOfCourseModules.map(function(e) { 
              if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === ""){
                  e.isHidden = false; 
              }
              else {
                  e.isHidden = !(e !== null && e !== undefined && e.courseName !== null && e.courseName !== undefined && e.courseName.toLowerCase().includes(filterCourseName.toLowerCase())); 
              }
              return e;
          });
          setReportItemsOfCourseModules(reportItemsModified);
      }
  },[filterCourseName]) 



  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };

  const showExportLoader = (exportingLoading: boolean): JSX.Element | null => exportingLoading? <LoaderContainer/> : null;
  
  const logNavigation = (message: string) => {
    addError(oidcUser.access_token, userId, orgDomain, message, null, "Report.tsx", 6, EnableActivityLogs)
  }
 
  const getStatusCSSClass = (status: string) => {
    switch(status) {
      case "Passed":
        return styles.passed
      case "Failed":
        return styles.failed
      case "Accepted":
        return styles.passed
      case "Not Accepted":
        return styles.failed
      case "Completed":
        return styles.passed
      case "Provided":
        return styles.passed
      case "Not Provided":
        return styles.failed
      case "One time completion":
        return styles.onetimecourse 
      case "Closed":
        return styles.failed
      case "Read":
        return styles.passed
      case "Not Read":
          return styles.failed
      default:
        return
    }
  }

  const getStatusTranslation = (status: string):string => {
    switch(status) {
      case "Passed":
        return allResourcesToTranslations.passed
      case "Failed":
        return allResourcesToTranslations.failed
      case "Completed":
          return allResourcesToTranslations.completed
      case "Not Started":
        return allResourcesToTranslations.notstarted
      case "Expired (Time Elapsed)":
        return allResourcesToTranslations.expiredtimeelapsed
      case "Expired (New Content)":
        return allResourcesToTranslations.expirednewcontent
      case "In Progress":
        return allResourcesToTranslations.inprogress
      case "Accepted":
        return allResourcesToTranslations.accepted
      case "Not Accepted":
        return allResourcesToTranslations.notaccepted
      case "Provided":
        return allResourcesToTranslations.providedcertificates
      case "Not Provided":
        return allResourcesToTranslations.notprovidedcertificates
      case "Closed":
        return allResourcesToTranslations.expiredcertificates
       case "One time completion":
        return allResourcesToTranslations.onetimeonlycourselistpage
      default:
        return ""
    }
  }

  const getFontName = (langCode: string) => {
    switch(langCode) {
      case "my":
        return "NotoSansMyanmar-Regular"
      case "zh-CHT":
        return "ZCOOLXiaoWei-Regular";
      case "zh-CHS":
        return "NotoSansCJKtc-Regular";
      case "ja-JP":
        return "KosugiMaru-Regular";
      default:
      return ""
    }
  }
  language.current = getFontName(currentPageLang)

  const isPDFLanguageSupported = (language: string) => {
    switch(language) {
      case "ja-JP":
      case "zh-CHS":
      case "zh-CHT":
      case "my":
        return false
      default:
        return true 
    }
  }

  const renderReportRowsOfCourseModules = () => {
    let lastCourseId = 0;
    let isColorGrey = false;
    if (reportItemsOfCourseModules != null) {
      return reportItemsOfCourseModules.map((rItem, i) => {
        let showCourseName = true;
        if (lastCourseId === rItem.courseID){
          showCourseName = false;
        } else {
          isColorGrey = !isColorGrey;
        }
        lastCourseId = rItem.courseID

          return(
            <TableRow key={`reportrow-${i}`} style={{backgroundColor: isColorGrey? "#f5f5f5" : "#fff" }} hidden={rItem.isHidden === true}>
              <TableCell align="left">
                <strong style={{ fontFamily: `${language.current}, sans-serif` }}>
                  {showCourseName? rItem.courseName : "" }
                </strong>
              </TableCell>
              <TableCell align="left" style={{ fontFamily: `${language.current}, sans-serif` }}>
                  {rItem.moduleName}
              </TableCell>
              <TableCell align="left" style={{ fontFamily: `${language.current}, sans-serif` }}>
                <span className={ getStatusCSSClass(rItem.lessonStatus) } style={{ fontFamily: `${language.current}, sans-serif` }}>
                  {(rItem.quizType === "SA" || rItem.quizType === "Rise360" || rItem.quizType === "Storyline360" || rItem.quizType === "LectoraLQ") ? "" : getStatusTranslation(rItem.lessonStatus)}
                </span>
              </TableCell>
              <TableCell align="left">
                <span style={{ fontFamily: `${language.current}, sans-serif` }}
                  className={ getStatusCSSClass(rItem.quizStatus) }>
                  {getStatusTranslation(rItem.quizStatus)}
                </span>
              </TableCell>
              <TableCell align="center">
                {rItem.quizStatusDate}
              </TableCell>
              <TableCell align="center">
                {rItem.score + "%"}
              </TableCell>
              <TableCell align="center">
                {rItem.passmark + "%"}
              </TableCell>
                {renderCPDRowItems(rItem.itemCPDDetails) }
                {renderDueDateRowItems(rItem)}  
              <TableCell align="left">
                {rItem.lastCourseCompletionDateTxt}
              </TableCell>
              <TableCell className="hideForPrint history" align="center" >
                <Link
                  onClick={() => { logNavigation(`Opened ${rItem.moduleName} history`) }}
                  to={{
                    pathname: `myreport/${rItem.moduleID}/history`
                  }}
                >
                  <CustomTooltip displayText={[allResourcesToTranslations.viewhistory]}>
                    <Button variant="contained" color="primary">
                      <ViewListIcon/>
                    </Button>
                  </CustomTooltip>
                </Link>
              </TableCell>
            </TableRow>
          )
        }
      );
    }
  }

  const renderReportRowsOfPolicies = () => {
    let lastCourseId = 0;
    let isColorGrey = true;
    if (reportItemsOfPolicies != null) {
      return reportItemsOfPolicies.map((rItem, i) => {
        let showPolicyName = true;
        if (lastCourseId === rItem.id){
          showPolicyName = false;
        } else {
          isColorGrey = !isColorGrey;
        }
        lastCourseId = rItem.id

          return(
            <TableRow key={`reportpolicyrow-${i}`} style={{backgroundColor: isColorGrey? "#f5f5f5" : "#fff" }} hidden={rItem.isHidden === true}>
              <TableCell align="left">
                <strong style={{ fontFamily: `${language.current}, sans-serif` }}>
                  {showPolicyName? rItem.name : "" }
                </strong>
              </TableCell>
              <TableCell align="center" style={{ fontFamily: `${language.current}, sans-serif` }}>
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="left" style={{ fontFamily: `${language.current}, sans-serif` }}>
                <span style={{ fontFamily: `${language.current}, sans-serif` }}
                  className={ getStatusCSSClass(rItem.status) }>
                  {getStatusTranslation(rItem.status)}
                </span>
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="left">
                {rItem.dateAcceptedTxt}
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              {renderCPDRowItems(rItem.itemCPDDetails) }
              {renderDueDateRowItemsPolicy(rItem)}  
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell className="hideForPrintPolicy history" align="center" >                
                  {/* <CustomTooltip displayText={[allResourcesToTranslations.viewhistory]}>
                    <Button variant="contained" color="primary">
                      <ViewListIcon/>
                    </Button>
                  </CustomTooltip> */}
                  {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
            </TableRow>
          )
        }
      );
    }
  }

  const renderReportRowsOfNewsfeeds = () => {
    let lastCourseId = 0;
    let isColorGrey = true;
    if (reportItemsOfNewsfeed != null) {
      return reportItemsOfNewsfeed.map((rItem, i) => {
        let showName = true;
        if (lastCourseId === rItem.id){
          showName = false;
        } else {
          isColorGrey = !isColorGrey;
        }
        lastCourseId = rItem.id

          return(
            <TableRow key={`reportnewsfeedrow-${i}`} style={{backgroundColor: isColorGrey? "#f5f5f5" : "#fff" }} hidden={rItem.isHidden === true}>
              <TableCell align="left">
                <strong style={{ fontFamily: `${language.current}, sans-serif` }}>
                  {showName? rItem.name : "" }
                </strong>
              </TableCell>
              <TableCell align="center" style={{ fontFamily: `${language.current}, sans-serif` }}>
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="left" style={{ fontFamily: `${language.current}, sans-serif` }}>
                <span style={{ fontFamily: `${language.current}, sans-serif` }}
                  className={ getStatusCSSClass(rItem.status) }>
                  {rItem.status}
                </span>
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="left">
                {rItem.dateReadTxt}
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              {renderCPDRowItems(rItem.itemCPDDetails) }
              {renderDueDateRowItemsNewsfeed(rItem)} 
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell className="hideForPrintNewsfeed history" align="center" >                
                  {/* <CustomTooltip displayText={[allResourcesToTranslations.viewhistory]}>
                    <Button variant="contained" color="primary">
                      <ViewListIcon/>
                    </Button>
                  </CustomTooltip> */}
                  {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
            </TableRow>
          )
        }
      );
    }
  }
  const renderReportRowsOfExternalCertificates = () => {
    let lastCourseId = 0;
    let isColorGrey = true;
    if (reportItemsOfExternalCertificates != null) {
      return reportItemsOfExternalCertificates.map((rItem, i) => {
        let showExtCertName = true;
        if (lastCourseId === rItem.id){
          showExtCertName = false;
        } else {
          isColorGrey = !isColorGrey;
        }
        lastCourseId = rItem.id

          return(
            <TableRow key={`reportextcertrow-${i}`} style={{backgroundColor: isColorGrey? "#f5f5f5" : "#fff" }} hidden={rItem.isHidden === true}>
              <TableCell align="left">
                <strong style={{ fontFamily: `${language.current}, sans-serif` }}>
                  {showExtCertName? rItem.name : "" }
                </strong>
              </TableCell>
              <TableCell align="center" style={{ fontFamily: `${language.current}, sans-serif` }}>
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="left" style={{ fontFamily: `${language.current}, sans-serif` }}>
                <span style={{ fontFamily: `${language.current}, sans-serif` }}
                  className={ getStatusCSSClass(rItem.status) }>
                  {getStatusTranslation(rItem.status)}
                </span>
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="left">
                {rItem.status == "Not Provided" ? rItem.dateAssignedTxt : (rItem.status == "Provided" ? rItem.dateProvidedTxt : (rItem.status == "Closed" ? rItem.dateClosedTxt : ""))}
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              {renderCPDRowItems(rItem.itemCPDDetails) }
              {renderDueDateRowItemsExternalCertificate(rItem)}  
              <TableCell align="center">
                {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
              <TableCell className="hideForPrintExtCert history" align="center" >                
                  {/* <CustomTooltip displayText={[allResourcesToTranslations.viewhistory]}>
                    <Button variant="contained" color="primary">
                      <ViewListIcon/>
                    </Button>
                  </CustomTooltip> */}
                  {<span style={{color: isColorGrey? "#E3E3E3" : "#fff"}}>-</span>}
              </TableCell>
            </TableRow>
          )
        }
      );
    }
  }

  const renderCPDRowItems = (cpdi: Array<ReportItemCPDDetails> ) => {
      return showCPD ? cpdi.map((cpd, i) => (
          <TableCell key={`cpdrow-${i}`} align="center" style={{ fontFamily: `${language.current}, sans-serif` }}>
             <CustomTooltip displayText={[allResourcesToTranslations.cpdperiod, cpd.period]}>
               <span className={`${styles.cdpScore} ${exporting? "blackText" : ""}`}>
                 {cpd.pointsEarned}
                </span>
             </CustomTooltip>
          </TableCell>
      )) : <></>
  }

  const renderDueDateRowItems = (item: ReportCourseModuleItem) => {
    if (showReportDueDate){
      return <TableCell align="center"><span className={ getStatusCSSClass(item.dueDate) } style={{ fontFamily: `${language.current}, sans-serif` }}> {item.dueDate == 'One time completion' ? getStatusTranslation(item.dueDate): item.dueDate}</span></TableCell>
    }
  }

  const renderDueDateRowItemsPolicy = (item: ReportPolicyItem) => {
    if (showReportDueDate){
      return <TableCell align="center">{<span style={{color: '#E3E3E3'}}>-</span>}</TableCell>
    }
  }

  const renderDueDateRowItemsExternalCertificate = (item: ReportExternalCertificateItem) => {
    if (showReportDueDate){
      return <TableCell align="center">{<span style={{color: '#E3E3E3'}}>-</span>}</TableCell>
    }
  }

  const renderDueDateRowItemsNewsfeed = (item: ReportNewsfeedItem) => {
    if (showReportDueDate){
      return <TableCell align="center">{<span style={{color: '#E3E3E3'}}>-</span>}</TableCell>
    }
  }

  const renderReportHeadOfCourseModules = () => {
      let courseCPDs = null;
      if (reportItemsOfCourseModules != null && reportItemsOfCourseModules.length > 0) {
        courseCPDs = reportItemsOfCourseModules[0].itemCPDDetails;
      }
      return(
      <TableRow>
         <TableCell className="tableHeadStart" style={{color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", fontFamily: `${language.current}, sans-serif` }} align="left">
            {allResourcesToTranslations.course}
          </TableCell>
          <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left', backgroundColor: color, fontFamily: `${language.current}, sans-serif` }} align="left">
            {allResourcesToTranslations.module}
          </TableCell>
          <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif` }} align="left">
            {allResourcesToTranslations.lessonstatus}
          </TableCell>
          <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif` }} align="left">
            {allResourcesToTranslations.quizstatus}
          </TableCell>
          <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif` }} align="left">
            {allResourcesToTranslations.quizstatusdate}
          </TableCell>         
          <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif` }} align="center">
            {allResourcesToTranslations.score}
          </TableCell>
          <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif`}} align="center">
            {allResourcesToTranslations.passmark}
          </TableCell>
          {renderCPDHeadItems(courseCPDs) }
          {renderDueDateHeadItems(showReportDueDate)}
           <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif`}} align="center">
           {allResourcesToTranslations.coursecompletiondate}
          </TableCell>
          <TableCell className="hideForPrint" style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, borderRadius: "0 8px 0 0",}} align="center">
            {allResourcesToTranslations.history}
          </TableCell>
      </TableRow>
    )
  }

  const renderReportHeadOfNewsfeeds = (highlightHeader: boolean = false) => {
    //console.log("renderReportHeadOfPolicies highlightHeader " + highlightHeader.toString());
      let newsfeedCPDs = null;
      if (reportItemsOfNewsfeed != null && reportItemsOfNewsfeed.length > 0) {
        newsfeedCPDs = reportItemsOfNewsfeed[0].itemCPDDetails;
      }
      return(
      <TableRow className="tableHeadStartNewsfeedRow" id="tableHeadStartNewsfeedRowID"
      style={highlightHeader ? {color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", fontFamily: `${language.current}, sans-serif` } : {}}
      >
         <TableCell className="tableHeadStartNewsfeed" style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black', backgroundColor: highlightHeader ? color : '#E3E3E3', borderRadius: highlightHeader ? "8px 0 0 0" : "0 0 0 0", fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.newsfeeds}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center', backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'left',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.status}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'left',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.readdate}
          </TableCell>         
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          {renderCPDHeadItemsPolicy(newsfeedCPDs, highlightHeader) }
          {renderDueDateHeadItemsNewsfeed(showReportDueDate, highlightHeader)} 
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
           {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell className="hideForPrintPolicy" style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3', fontWeight: 'bold', borderRadius: highlightHeader ? "0 8px 0 0" : "0 0 0 0"}} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
      </TableRow>
    )
  }

  const renderReportHeadOfPolicies = (highlightHeader: boolean = false) => {
      let policyCPDs = null;
      if (reportItemsOfPolicies != null && reportItemsOfPolicies.length > 0) {
        policyCPDs = reportItemsOfPolicies[0].itemCPDDetails;
      }
      return(
      <TableRow className="tableHeadStartPolicyRow" id="tableHeadStartPolicyRowID"
      style={highlightHeader ? {color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", fontFamily: `${language.current}, sans-serif` } : {}}
      >
         <TableCell className="tableHeadStartPolicy" style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black', backgroundColor: highlightHeader ? color : '#E3E3E3', borderRadius: highlightHeader ? "8px 0 0 0" : "0 0 0 0", fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.policy}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center', backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'left',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.status}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'left',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.policystatusdate}
          </TableCell>         
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          {renderCPDHeadItemsPolicy(policyCPDs, highlightHeader) }
          {renderDueDateHeadItemsPolicy(showReportDueDate, highlightHeader)}
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
           {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell className="hideForPrintPolicy" style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3', fontWeight: 'bold', borderRadius: highlightHeader ? "0 8px 0 0" : "0 0 0 0"}} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
      </TableRow>
      // <TableRow className="tableHeadStartPolicyRow" id="tableHeadStartPolicyRow">
      //   <TableCell className="tableHeadStartPolicy" style={{color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
      //     {allResourcesToTranslations.policy}
      //   </TableCell>
      //   <TableCell style={{color: getColor(color.substring(1)),textAlign: 'center', backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
      //     {"-"}
      //   </TableCell>
      //   <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
      //     {allResourcesToTranslations.status}
      //   </TableCell>
      //   <TableCell style={{color: getColor(color.substring(1)),textAlign: 'center',backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
      //     {"-"}
      //   </TableCell>
      //   <TableCell style={{color: getColor(color.substring(1)),textAlign: 'left',backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
      //     {allResourcesToTranslations.policystatusdate}
      //   </TableCell>         
      //   <TableCell style={{color: getColor(color.substring(1)),textAlign: 'center',backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="center">
      //     {"-"}
      //   </TableCell>
      //   <TableCell style={{color: getColor(color.substring(1)),textAlign: 'center',backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
      //     {"-"}
      //   </TableCell>
      //   {renderCPDHeadItems(policyCPDs) }
      //   {renderDueDateHeadItemsPolicy(showReportDueDate)}
      //   <TableCell style={{color: getColor(color.substring(1)),textAlign: 'center',backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
      //     {"-"}
      //   </TableCell>
      //   <TableCell className="hideForPrintPolicy" style={{color: getColor(color.substring(1)),textAlign: 'center',backgroundColor: color, borderRadius: "0 8px 0 0", fontWeight: 'bold'}} align="center">
      //     {"-"}
      //   </TableCell>
      // </TableRow>
    )
  }

  const renderReportHeadOfExternalCertificates = (highlightHeader: boolean = false) => {
      let extcertCPDs = null;
      if (reportItemsOfExternalCertificates != null && reportItemsOfExternalCertificates.length > 0) {
        extcertCPDs = reportItemsOfExternalCertificates[0].itemCPDDetails;
      }
      return(
      <TableRow className="tableHeadStartExtcertRow" id="tableHeadStartExtcertRowID"
        style={highlightHeader ? {color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", fontFamily: `${language.current}, sans-serif` } : {}}
      >
         <TableCell className="tableHeadStartExtcert" style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black', backgroundColor: highlightHeader ? color : '#E3E3E3', fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold', borderRadius: highlightHeader ? "8px 0 0 0" : "0 0 0 0" }} align="left">
            {allResourcesToTranslations.extcertificate}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center', backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'left',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.status}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'left',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="left">
            {allResourcesToTranslations.extcertstatusdate}
          </TableCell>         
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          {renderCPDHeadItemsExtcert(extcertCPDs, highlightHeader) }
          {renderDueDateHeadItemsExtcert(showReportDueDate, highlightHeader)}
          <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3',fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
           {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
          <TableCell className="hideForPrintExtcert" style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',textAlign: 'center',backgroundColor: highlightHeader ? color : '#E3E3E3', fontWeight: 'bold', borderRadius: highlightHeader ? "0 8px 0 0" : "0 0 0 0"}} align="center">
            {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
          </TableCell>
      </TableRow>
    )
  }

  const renderCPDHeadItems = (cCpd: Array<ReportItemCPDDetails> | null) => {
    if (showCPD && cCpd != null && cCpd.length > 0){
      return cCpd.map((cpdi, i) => (
        <TableCell
          key={`cpdhead-${i}`}
          style={{ color: getColor(color.substring(1)),backgroundColor: color, fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }}
          align="center"
        >
          {cpdi.name}
        </TableCell>
      ));
    }
    return <></>
  }

  const renderCPDHeadItemsPolicy = (cCpd: Array<ReportItemCPDDetails> | null, highlightHeader: boolean = false) => {
    if (showCPD && cCpd != null && cCpd.length > 0){
      return cCpd.map((cpdi, i) => (
        <TableCell
          key={`cpdheadpo-${i}`}
          style={{ color: highlightHeader ? getColor(color.substring(1)) : 'Black',backgroundColor: highlightHeader ? color : '#E3E3E3', fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }}
          align="center"
        >
          {cpdi.name}
        </TableCell>
      ));
    }
    return <></>
  }

  const renderCPDHeadItemsExtcert = (cCpd: Array<ReportItemCPDDetails> | null, highlightHeader: boolean = false) => {
    let elem1 = document.getElementById("cpdhead-1");
    let elem2 = document.getElementById("cpdheadpo-1");
    if ((showCPD || !!elem1 || !!elem2) && cCpd != null && cCpd.length > 0){
      return cCpd.map((cpdi, i) => (
        <TableCell
          key={`cpdheadec-${i}`}
          style={{ color: highlightHeader ? getColor(color.substring(1)) : 'Black', backgroundColor:  highlightHeader ? color : '#E3E3E3', fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold' }}
          align="center"
        >
          {cpdi.name}
        </TableCell>
      ));
    }
    return <></>
  }

  const renderDueDateHeadItems = (show: boolean) => {
    if(show) {
      return(
        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, fontFamily: `${language.current}, sans-serif`}} align="center">
          {allResourcesToTranslations.duedate}
        </TableCell>
      ) 
    } 
  }

  const renderDueDateHeadItemsPolicy = (show: boolean, highlightHeader: boolean = false) => {
    if(show) {
      return(
        <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',backgroundColor: highlightHeader ? color : '#E3E3E3', fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
          {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
        </TableCell>
      ) 
    } 
  }

  const renderDueDateHeadItemsNewsfeed = (show: boolean, highlightHeader: boolean = false) => {
    if(show) {
      return(
        <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',backgroundColor: highlightHeader ? color : '#E3E3E3', fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
          {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
        </TableCell>
      ) 
    } 
  }

  const renderDueDateHeadItemsExtcert = (show: boolean, highlightHeader: boolean = false) => {
    if(show) {
      return(
        <TableCell style={{color: highlightHeader ? getColor(color.substring(1)) : 'Black',backgroundColor: highlightHeader ? color : '#E3E3E3', fontFamily: `${language.current}, sans-serif`, fontWeight: 'bold'}} align="center">
          {<span style={{color: highlightHeader ? color : '#E3E3E3'}}>-</span>}
        </TableCell>
      ) 
    } 
  }

  const renderTotalRow = (cpdTlist: Array<CPDTotal> | null) => {
    if (showCPD && cpdTlist !== null) {
      return(
        <TableRow style={{ borderTop: `3px solid ${color}`}}>
         <TableCell align="left"/>
         <TableCell align="left"/>
         <TableCell align="left"/>
         <TableCell align="left"/>        
         <TableCell align="center"/>          
         <TableCell align="center"/>
         <TableCell align="center">
           <strong>
             {allResourcesToTranslations.total}
           </strong>
          </TableCell>
         { showCPD ? cpdTlist.map((cpdT, i) => {
            return(
                <TableCell key={`total-${i}`} align="center">
                  <CustomTooltip displayText={[`${cpdT.period}`, `${allResourcesToTranslations.pointsrequired} : ${cpdT.pointsRequired}`]}>
                    <span className={`${styles.cdpScore} ${exporting? "blackText" : ""}`} style={{ fontFamily: `${language.current}, sans-serif` }}>
                     {cpdT.pointsEarned}
                    </span>
                  </CustomTooltip>
                </TableCell>
            )
          }) : <></>
         }         
         {showReportDueDate?<TableCell className="hideForPrint" align="center"/>:null}        
         <TableCell align="center"/>        
         <TableCell className="hideForPrint" align="center"/>
        </TableRow>
      )
    }
  }

  const renderShortfallRow = (cpdTlist: Array<CPDTotal> | null) => {
    if (showCPD && cpdTlist !== null) {
      return(
        <TableRow>
         <TableCell align="center"/>
         <TableCell align="center"/>
         <TableCell align="center"/>
         <TableCell align="center"/>
         <TableCell align="center"/>
         <TableCell align="center"/>
         <TableCell align="center" style={{ fontFamily: `${language.current}, sans-serif` }}>
            <strong>
              {allResourcesToTranslations.shortfall}
            </strong>
         </TableCell>
         { showCPD ? cpdTlist.map((cpdT, i ) => {
            return (
              <TableCell key={`shortfall-${i}`} align="center">
                <CustomTooltip displayText={[`${cpdT.period}`, `${allResourcesToTranslations.pointsrequired} : ${cpdT.pointsRequired}`]}>
                  <span className={`${styles.cdpScore} ${exporting? "blackText" : ""}`} style={{ fontFamily: `${language.current}, sans-serif` }}>
                    {cpdT.pointsShortfall}
                  </span>
                </CustomTooltip>
              </TableCell>
            );
          }) : <></>
         }
          { showReportDueDate? <TableCell className="hideForPrint" align="center"/>: null}       
          <TableCell align="center"/>        
          <TableCell className="hideForPrint" align="center"/>
        </TableRow>
      )
    }
  }

  const showHideDueDate = () => {
    setshowReportDueDate(!showReportDueDate)
  }
      
  const fileToBase64 = (filename: string, filepath: string) => {
    return new Promise(resolve => {
      var file = new File([filename], filepath);
      var reader = new FileReader();

      reader.onload = function(e) {
        resolve(reader?.result?.toString());
      }
      
      // Convert data to base64 
      reader.readAsDataURL(file);
    });
  };
  
  const fromBinary = (encoded: string) => {
    let binary = atob(encoded)
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }

  const exportPdf = () => {
    setMessage(allResourcesToTranslations.exportingreport)
    setExporting(true)
    addError(oidcUser.access_token, userId, orgDomain, `Started export to PDF`, null, "Report.tsx", 6, EnableActivityLogs)
    const table = document.getElementById('tableToPrint');
    if (table !== null){
      const elsToHide = document.querySelectorAll('.hideForPrint'); //We hide the button column when exporting
      const tableHeadStart = document.querySelectorAll<HTMLElement>('.tableHeadStart'); //Round the corner for the last CPD entry when exporting
      const exportLoader = document.querySelectorAll<HTMLElement>('.exportLoaderCover'); // Using a new div to cover UI while screen is being captured

      const applyPrintStyle = () => {
        if (tableHeadStart !== null && !!tableHeadStart && tableHeadStart.length > 0 && !!tableHeadStart[0].style){
          tableHeadStart[0].style.borderRadius = "0px" // unsets rounded corners to 0px for screen grab
        }
        exportLoader[0].style.width = `${table.clientWidth + 10}px` //Set the size to cover the table 
        exportLoader[0].style.height = `${table.clientHeight + 10}px` 

        for (let i = 0; i < elsToHide.length; i++) {
          elsToHide[i].classList.add('hidden')    
        } 
        table.style.width = "1592px" 
      }

      const removePrintStyle = () => {
        if (tableHeadStart !== null && !!tableHeadStart && tableHeadStart.length > 0 && !!tableHeadStart[0].style){
          tableHeadStart[0].style.borderRadius = "8px 0 0 0"
        }
        exportLoader[0].style.width = "0px";
        exportLoader[0].style.height = "0px"; 

        for (let i = 0; i < elsToHide.length; i++) {
          elsToHide[i].classList.remove('hidden')
        } 

        table.removeAttribute('style');
        if (isMounted.current){
          setExporting(false)
        }
      }
      
      applyPrintStyle()     //Formats the HTML for screen capture
      const doc = new jsPDF('l', 'mm', [297, 210]); //A4 Size

      let filenamePrefix = displayName.replaceAll(".", "");
      // case "ja-JP":
      // case "zh-CHS":
      // case "zh-CHT":
      // case "my":
      // console.log(language.toString().toLowerCase())
      // let vv = language.toString().toLowerCase()
      // console.log(vv)
      //if (language.toString().toLowerCase() === "zh-chs") {
      if (getFontName(currentPageLang) === "NotoSansCJKtc-Regular") {
        getChineseSimplifiedTTFData(oidcUser.access_token, userId).then(dataResult2 => {
          if (dataResult2.isSuccess) {
            doc.addFileToVFS('NotoSansCJKtc-Regular-normal.ttf', dataResult2.ttf);
            doc.addFont('NotoSansCJKtc-Regular-normal.ttf', 'NotoSansCJKtc-Regular', 'normal');
              
            const currentTime = new Date();
            let momentVariable = moment(currentTime, 'YYMMDD');  
            let finalStringValue = momentVariable.format('YYMMDD');           
            doc.setFont(getFontName(currentPageLang))
            doc.text(`${displayName} - ${allResourcesToTranslations.myreport}\n${finalStringValue}`,10 , 13) //Adding name to the top of the document
            doc.html(table, {
              callback: (doc) => { 
                doc.save(`${filenamePrefix} -  ${allResourcesToTranslations.myreport}- ${finalStringValue}`); //Set file name here
                removePrintStyle() //Removes the HTML formatting for  screen capture
                addError(oidcUser.access_token, userId, orgDomain, "Export to PDF successful" , null, "Report.tsx", 6, EnableActivityLogs)
              },
              html2canvas: {
                scale: 0.174
              },
              x: 10,
              y: 20,
          });
          }

        })
      }
      else if (getFontName(currentPageLang) === "ZCOOLXiaoWei-Regular") {
        getChineseTraditionalTTFData(oidcUser.access_token, userId).then(dataResult3 => {
          if (dataResult3.isSuccess) {
            doc.addFileToVFS('ZCOOLXiaoWei-Regular-normal.ttf', dataResult3.ttf);
            doc.addFont('ZCOOLXiaoWei-Regular-normal.ttf', 'ZCOOLXiaoWei-Regular', 'normal');
              
            const currentTime = new Date();
            let momentVariable = moment(currentTime, 'YYMMDD');  
            let finalStringValue = momentVariable.format('YYMMDD');
            // console.log(doc.getFont());
            // console.log(getFontName(currentPageLang));
            doc.setFont(getFontName(currentPageLang))
            doc.text(`${displayName} - ${allResourcesToTranslations.myreport}\n${finalStringValue}`,10 , 13) //Adding name to the top of the document
            doc.html(table, {
              callback: (doc) => { 
                doc.save(`${filenamePrefix} -  ${allResourcesToTranslations.myreport}- ${finalStringValue}`); //Set file name here
                removePrintStyle() //Removes the HTML formatting for  screen capture
                addError(oidcUser.access_token, userId, orgDomain, "Export to PDF successful" , null, "Report.tsx", 6, EnableActivityLogs)
              },
              html2canvas: {
                scale: 0.174
              },
              x: 10,
              y: 20,
          });
          }
        })
      }
      else if (getFontName(currentPageLang) === "KosugiMaru-Regular") {
        getJapaneseTTFData(oidcUser.access_token, userId).then(dataResult4 => {
          if (dataResult4.isSuccess) {
            doc.addFileToVFS('KosugiMaru-Regular-normal.ttf', dataResult4.ttf);
            doc.addFont('KosugiMaru-Regular-normal.ttf', 'KosugiMaru-Regular', 'normal');

            const currentTime = new Date();
            let momentVariable = moment(currentTime, 'YYMMDD');  
            let finalStringValue = momentVariable.format('YYMMDD');
            // console.log(doc.getFont());
            // console.log(getFontName(currentPageLang));
            doc.setFont(getFontName(currentPageLang))
            doc.text(`${displayName} - ${allResourcesToTranslations.myreport}\n${finalStringValue}`,10 , 13) //Adding name to the top of the document
            doc.html(table, {
              callback: (doc) => { 
                doc.save(`${filenamePrefix} -  ${allResourcesToTranslations.myreport}- ${finalStringValue}`); //Set file name here
                removePrintStyle() //Removes the HTML formatting for  screen capture
                addError(oidcUser.access_token, userId, orgDomain, "Export to PDF successful" , null, "Report.tsx", 6, EnableActivityLogs)
              },
              html2canvas: {
                scale: 0.174
              },
              x: 10,
              y: 20,
           });
          }
        })
      }
      else if (getFontName(currentPageLang) === "NotoSansMyanmar-Regular") {
        getMyanmarTTFData(oidcUser.access_token, userId).then(dataResult5 => {
          if (dataResult5.isSuccess) {              
            doc.addFileToVFS('NotoSansMyanmar-Regular-normal.ttf', dataResult5.ttf);
            doc.addFont('NotoSansMyanmar-Regular-normal.ttf', 'NotoSansMyanmar-Regular', 'normal');
              
            const currentTime = new Date();
            let momentVariable = moment(currentTime, 'YYMMDD');  
            let finalStringValue = momentVariable.format('YYMMDD');
            // console.log(doc.getFont());
            // console.log(getFontName(currentPageLang));
            doc.setFont(getFontName(currentPageLang))
            doc.text(`${displayName} - ${allResourcesToTranslations.myreport}\n${finalStringValue}`,10 , 13) //Adding name to the top of the document
            doc.html(table, {
              callback: (doc) => { 
                doc.save(`${filenamePrefix} -  ${allResourcesToTranslations.myreport}- ${finalStringValue}`); //Set file name here
                removePrintStyle() //Removes the HTML formatting for  screen capture
                addError(oidcUser.access_token, userId, orgDomain, "Export to PDF successful" , null, "Report.tsx", 6, EnableActivityLogs)
              },
              html2canvas: {
                scale: 0.174
              },
              x: 10,
              y: 20,
          });
          }
        })
      }
      else {
        const currentTime = new Date();
        let momentVariable = moment(currentTime, 'YYMMDD');  
        let finalStringValue = momentVariable.format('YYMMDD');
        // console.log(doc.getFont());
        // console.log(getFontName(currentPageLang));
        doc.setFont(getFontName(currentPageLang))
        doc.text(`${displayName} - ${allResourcesToTranslations.myreport}\n${finalStringValue}`,10 , 13) //Adding name to the top of the document
        doc.html(table, {
          callback: (doc) => { 
            doc.save(`${filenamePrefix} -  ${allResourcesToTranslations.myreport}- ${finalStringValue}`); //Set file name here
            removePrintStyle() //Removes the HTML formatting for  screen capture
            addError(oidcUser.access_token, userId, orgDomain, "Export to PDF successful" , null, "Report.tsx", 6, EnableActivityLogs)
          },
          html2canvas: {
            scale: 0.174
          },
          x: 10,
          y: 20,
       });
      }
    } else {
      addError(oidcUser.access_token, userId, orgDomain, "Export to PDF failed", null, "Report.tsx", 1)
    }
  }

  const isMounted = useRef<boolean>(false)
  useEffect(() => {
    isMounted.current = true;
    updateBackButtonRoute("")
    setTitle(allResourcesToTranslations.personalreportfor.replace('{NAME}', displayName)) 
    setTitleEnglish(pageTitles.myreport)
    setMenuSelection("My Report")
    loadData();
    return () => {
      isMounted.current = false
      setShowScrollArrow(false)
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return <LoaderContainer/> 
  }

  return (
    <>
    <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          onExit={onExit}
          options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}
        />
      <div>

        <div className={styles.exportBtnContainer}>
        
        <CustomTooltip displayText={["Guide Me"]}>
                {/* <Button variant="outlined" color="primary" style={{backgroundColor:'white',position:'absolute',top:'80px',right:'10px'}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                    <GuideIcon />
                    </Button> */}
               
               <span onClick={() => { refreshGuideme(); setIntro({...intro, stepsEnabled: true}); }} className={styles.guideMeButtonSpecific}>
                      <GuideIcon />
                    </span>
            </CustomTooltip>
          <span className="dueDate">  
         <CustomTooltip  displayText={ showReportDueDate ? [allResourcesToTranslations.hideduedate] : [allResourcesToTranslations.showduedate]}>
         { showLastPassDate ? <Button   id="btnShowHide"
              onClick={() => { showHideDueDate() }}           
              className={`${styles.ShowHideBtn} `}
              variant="contained"
              color="primary">
              { showReportDueDate ? <VisibilityIcon /> : <VisibilityOffIcon /> }                        
            </Button> : <span></span>  }
            
          </CustomTooltip>
          </span>
          <span className="pdf">
          <CustomTooltip displayText={[allResourcesToTranslations.exporttopdf]}>
            <span>
              <Button
                onClick={() => { exportPdf() }}
                className={`${styles.exportBtn} pdf`}
                variant="contained"
                color="primary"
              >
                <GetAppIcon />
                <ListAltRoundedIcon />
              </Button>
            </span>
          </CustomTooltip>
          </span>
        </div>
        <UnbrandedCard>
          {/* <div className={styles.inputContainer}>
              <TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
              value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}/>
          </div> */}
        <div style={{display: exporting? "block" : "none"}} className={`${styles.exportLoader} exportLoaderCover`}>
          <div>
            { showExportLoader(exporting) }
          </div>
        </div>
          <Table id="tableToPrint">
            {/* <Table id="tableToPrint1"> */}
              <TableHead className="report" id="reportID">{
                (!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0) ?
                renderReportHeadOfCourseModules() : (
                  (!!reportItemsOfPolicies && reportItemsOfPolicies.length > 0) ? 
                  renderReportHeadOfPolicies(true) : (
                    (!!reportItemsOfExternalCertificates && reportItemsOfExternalCertificates.length > 0) ? 
                    renderReportHeadOfExternalCertificates(true) : <></>
                  ))
              }</TableHead>
              <TableBody>
                {
                  !!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0 ?
                  renderReportRowsOfCourseModules() : <></>
                }
                {
                  !!reportItemsOfPolicies && reportItemsOfPolicies.length > 0 &&
                  <>
                      {!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0 ? 
                      renderReportHeadOfPolicies() : <></>}
                      {renderReportRowsOfPolicies()}
                  </>
                }
                {
                  !!reportItemsOfExternalCertificates && reportItemsOfExternalCertificates.length > 0 &&
                  <>
                      {((!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0) ||
                        (!!reportItemsOfPolicies && reportItemsOfPolicies.length > 0)) ?
                        renderReportHeadOfExternalCertificates() : <></>}
                      {renderReportRowsOfExternalCertificates()}
                  </>
                }
                {
                  !!reportItemsOfNewsfeed && reportItemsOfNewsfeed.length > 0 &&
                  <>
                      {((!!reportItemsOfCourseModules && reportItemsOfCourseModules.length > 0) ||
                        (!!reportItemsOfPolicies && reportItemsOfPolicies.length > 0 ) || 
                        (!!reportItemsOfExternalCertificates && reportItemsOfExternalCertificates.length > 0)) ?
                        renderReportHeadOfNewsfeeds() : <></>}
                      {renderReportRowsOfNewsfeeds()}
                  </>
                }
                {
                  <>
                    {renderTotalRow(cpdTotals)}
                    {renderShortfallRow(cpdTotals)}
                  </>
                }
              </TableBody>
            {/* </Table>
            {
              !!reportItemsOfPolicies && reportItemsOfPolicies.length > 0 &&
              <Table id="tableToPrint2">
                <TableHead className="report">{renderReportHeadOfPolicies()}</TableHead>
                <TableBody>
                  {renderReportRowsOfPolicies()}
                  {renderTotalRow(cpdTotals)}
                  {renderShortfallRow(cpdTotals)}
                </TableBody>
              </Table>
            } */}
          </Table>
        </UnbrandedCard>
      </div>
    </>
  );
};

export default Report;
