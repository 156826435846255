import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MyNewsfeedIcon = () => (    
<svg width="38" height="38" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.41667 12.8333H18.3333V13.75H6.41667V12.8333ZM18.3333 10.0833H6.41667V11H18.3333V10.0833ZM18.3333 4.58333H13.75V5.5H18.3333V4.58333ZM18.3333 7.33333H13.75V8.25H18.3333V7.33333ZM2.75 0V15.7657C2.75 16.4725 1.83333 16.4725 1.83333 15.7657V1.83333H0V16.4817C0 17.5037 0.829583 18.3333 1.85167 18.3333H20.1465C21.1704 18.3333 22 17.5047 22 16.4817V0H2.75ZM20.1667 15.5833H4.58333V1.83333H20.1667V15.5833ZM11.9167 4.58333H6.41667V8.25H11.9167V4.58333Z" fill="#21394F"/>
</svg>

);

export default MyNewsfeedIcon
