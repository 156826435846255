import axios from 'axios';
import { addError } from '../AddError';
import { OrgBasicData } from '../../globalTypes';

export const SaveNfSubscribers = async (token: string, userId: number, orgDomain: string, orgId: number | null, nfSubscriberListId: number,    
  subscriberListName: string, isActiveStatus: boolean, OrgNfSubscribersData: OrgBasicData[] ) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/SuperAdministration/SaveNfSubscribers`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {        
        RequestingUserID: userId,
        RequestingOrgID: orgId,      
        RequestingDomain: orgDomain,      
        NfSubscriberListCommonId: nfSubscriberListId,        
        SubscriberListName: subscriberListName,
        IsActiveStatus: isActiveStatus,
        OrgNfSubscribersData: OrgNfSubscribersData
    }
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveNfSubscribers.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveNfSubscribers.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveNfSubscribers.ts", 1)
    }
    return null;
  }
};

export default SaveNfSubscribers;