import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const SaltCertificatesReportIcon = () => (    
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.51772 1.51772C5.4895 0.54594 6.80751 0 8.18182 0H22C22.4581 0 22.8974 0.18198 23.2214 0.505906L33.585 10.8695C33.9089 11.1935 34.0909 11.6328 34.0909 12.0909V32.8182C34.0909 34.1925 33.545 35.5105 32.5732 36.4823C31.6014 37.4541 30.2834 38 28.9091 38H8.18182C6.80751 38 5.4895 37.4541 4.51772 36.4823C3.54594 35.5105 3 34.1925 3 32.8182V5.18182C3 3.80751 3.54594 2.4895 4.51772 1.51772ZM8.18182 3.45455C7.72372 3.45455 7.28438 3.63653 6.96045 3.96045C6.63653 4.28438 6.45455 4.72372 6.45455 5.18182V32.8182C6.45455 33.2763 6.63653 33.7156 6.96045 34.0395C7.28438 34.3635 7.72372 34.5455 8.18182 34.5455H28.9091C29.3672 34.5455 29.8065 34.3635 30.1305 34.0395C30.4544 33.7156 30.6364 33.2763 30.6364 32.8182V12.8064L21.2845 3.45455H8.18182Z" fill="#21394F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 0C22.9539 0 23.7273 0.773326 23.7273 1.72727V10.3636H32.3636C33.3176 10.3636 34.0909 11.137 34.0909 12.0909C34.0909 13.0449 33.3176 13.8182 32.3636 13.8182H22C21.0461 13.8182 20.2727 13.0449 20.2727 12.0909V1.72727C20.2727 0.773326 21.0461 0 22 0Z" fill="#21394F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9794 15.5455C19.3081 15.5455 19.6084 15.7321 19.7539 16.0268L21.6887 19.9466L26.0156 20.579C26.3408 20.6265 26.6108 20.8546 26.7122 21.1672C26.8136 21.4798 26.7287 21.8229 26.4933 22.0522L23.3631 25.1011L24.1018 29.4083C24.1574 29.7323 24.0242 30.0598 23.7582 30.253C23.4922 30.4462 23.1396 30.4717 22.8486 30.3187L18.9794 28.2839L15.1103 30.3187C14.8193 30.4717 14.4667 30.4462 14.2007 30.253C13.9347 30.0598 13.8015 29.7323 13.8571 29.4083L14.5958 25.1011L11.4656 22.0522C11.2302 21.8229 11.1453 21.4798 11.2467 21.1672C11.348 20.8546 11.6181 20.6265 11.9433 20.579L16.2702 19.9466L18.205 16.0268C18.3505 15.7321 18.6507 15.5455 18.9794 15.5455ZM18.9794 18.3603L17.6183 21.1178C17.4926 21.3724 17.2497 21.549 16.9688 21.5901L13.9236 22.0352L16.1264 24.1807C16.3301 24.3791 16.4231 24.6651 16.375 24.9453L15.8553 27.9753L18.5775 26.5437C18.8291 26.4114 19.1298 26.4114 19.3814 26.5437L22.1035 27.9753L21.5839 24.9453C21.5358 24.6651 21.6288 24.3791 21.8325 24.1807L24.0352 22.0352L20.9901 21.5901C20.7091 21.549 20.4663 21.3724 20.3406 21.1178L18.9794 18.3603Z" fill="#21394F"/>
    </svg>

);

export default SaltCertificatesReportIcon
