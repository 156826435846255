//React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { UnitPathwayReportResultItem } from "../../globalTypes";

// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { addError } from "../../API/AddError";
//Components
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import BrushIcon from "@material-ui/icons/Brush";

// Style
import styles from "../../styles/currentAdminReport.module.css";
import GuideIcon from "../../CustomIcons/GuideIcon";

const UnitPathwayReportResult = (props: any) => {  
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  const [unitPathwayReport, setunitPathwayReportResult] = useState<
    Array<UnitPathwayReportResultItem>
  >(props?.location?.state?.resultItems ?? []);
  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [openCriteria, setOpenCriteria] = useState(false);
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);

  const [unitPathwayDescending, SetUnitPathwayDescending] = useState<boolean>(false);
  const [uniNameDescending, SetunitNameDescending] = useState<boolean>(false);
  const [unitIdDescending, SetunitIdDescending] = useState<boolean>(false);
  const [unitIdPathwayDescending, setunitIdPathwayDescending] = useState<boolean>(false);
  
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");
  const [resultError, setResultError] = useState<string>(
    props?.location?.state?.resultError ?? ""
  );
  const [includeInactiveUnits, setIncludeInactive] = useState<boolean>(
    props?.location?.state?.includeInactiveUnits ?? false
  );
  const [selectedUnitNames, setSelectedUnitNames] = useState<string>(
    props?.location?.state?.selectedUnits ?? []
  );
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".unitPathwayReportList",
        title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta        
      },      
    ]
  })

  useEffect(() => 
  {
      let updateSteps = [...intro.steps];
        updateSteps.push( 
        {
        element: ".xlxs",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
        },
        {
        element: ".csv",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
        },
        {
            element: ".pdf",
            title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
        }
        // {
        //     element: ".rowShading",
        //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
        // }           
    );
  setIntro({...intro, steps: [...updateSteps]});
  },[])
  
  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };

  //const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    
  const GetDate_yymmdd = () => {
      var d =  new Date(Date.now());
      let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
      return day;   
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < unitPathwayReport.length; i++) {
        const r = unitPathwayReport[i];        
        resultArray.push(`${r.unitPathway}<#>${r.unitName}<#>${r.unitID}<#>${r.unitIDPathway}`);     
    }
    return resultArray;
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Unit Pathway Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "UnitPathwayReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 4, "UnitPathwayReport", enableRowBanding, "","","","",false,false, "", "", "", "", "", "", "",false,includeInactiveUnits).then((xlxsRes) => {
        if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            );

            a.download = "UnitPathwayReport-"+ GetDate_yymmdd() + ".xlsx" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
        }
        setExporting(false);
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Unit Pathway Report XLSX-EXPORT in NEWSALT ClientApp", null, "UnitPathwayReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started UnitPathway Report CSV-EXPORT in NEWSALT ClientApp",
      null,
      "UnitPathwayReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 4, "UnitPathwayReport", "","","","",false,false, "",false, "", "", "", "", "", "",false,includeInactiveUnits).then((csvRes) => {
        if (csvRes.isSuccess) {
            const fileData = csvRes.csvDataOutput.fileData
            var a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "UnitPathwayReport-"+ GetDate_yymmdd() + ".csv" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (csvRes.error !== undefined && csvRes.error !== null) {
            setExportError(csvRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed UnitPathway Report CSV-EXPORT in NEWSALT ClientApp", null, "UnitPathwayReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFXExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started UnitPathway Report PDF-EXPORT in NEWSALT ClientApp",
      null,
      "UnitPathwayReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 4, "UnitPathwayReport", enableRowBanding,"","","","",false,false, "", "", "", "", "","","",false,includeInactiveUnits).then((pdfRes) => {
        if (pdfRes.isSuccess) {
            const fileData = pdfRes.pdfDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "UnitPathwayReport-"+ GetDate_yymmdd() + ".PDF" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
        }
        else if (pdfRes.error !== undefined && pdfRes.error !== null) {
            setExportError(pdfRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed UnitPathway Report PDF-EXPORT in NEWSALT ClientApp", null, "UnitPathwayReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
      | "UnitPathway"
      | "UnitName"
      | "UnitID"
      | "UnitIDPathway",
    sortGivenItemInDesc: boolean
  ) => {
    if (unitPathwayReport == null || unitPathwayReport.length <= 0) {
      return unitPathwayReport;
    }
    var result = unitPathwayReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitPathway === null) {
              return -1;
            }
            if (a === null || a.unitPathway === null) {
              return 1;
            }
            if (a.unitPathway > b.unitPathway) {
              return -1;
            }
            if (a.unitPathway < b.unitPathway) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            SetUnitPathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitPathway === null) {
              return 1;
            }
            if (a === null || a.unitPathway === null) {
              return -1;
            }
            if (a.unitPathway > b.unitPathway) {
              return 1;
            }
            if (a.unitPathway < b.unitPathway) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            SetUnitPathwayDescending(true);
          }
        }
        setunitPathwayReportResult(result);
        break;     
      case "UnitName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitName === null) {
              return -1;
            }
            if (a === null || a.unitName === null) {
              return 1;
            }
            if (a.unitName.toLowerCase() > b.unitName.toLowerCase()) {
              return -1;
            }
            if (a.unitName.toLowerCase() < b.unitName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            SetunitNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitName === null) {
              return 1;
            }
            if (a === null || a.unitName === null) {
              return -1;
            }
            if (a.unitName.toLowerCase() < b.unitName.toLowerCase()) {
              return -1;
            }
            if (a.unitName.toLowerCase() > b.unitName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            SetunitNameDescending(true);
          }
        }
        setunitPathwayReportResult(result);
        break;
      case "UnitID":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitID === null) {
              return -1;
            }
            if (a === null || a.unitID === null) {
              return 1;
            }
            if (a.unitID > b.unitID) {
              return -1;
            }
            if (a.unitID < b.unitID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            SetunitIdDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitID === null) {
              return 1;
            }
            if (a === null || a.unitID === null) {
              return -1;
            }
            if (a.unitID > b.unitID) {
              return 1;
            }
            if (a.unitID < b.unitID) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            SetunitIdDescending(true);
          }
        }
        setunitPathwayReportResult(result);
        break;
      case "UnitIDPathway":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.unitIDPathway === null) {
                  return -1;
                }
                if (a === null || a.unitIDPathway === null) {
                  return 1;
                }
                if (a.unitIDPathway.toLowerCase() > b.unitIDPathway.toLowerCase()) {
                  return -1;
                }
                if (a.unitIDPathway.toLowerCase() < b.unitIDPathway.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setunitIdPathwayDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.unitIDPathway === null) {
                  return 1;
                }
                if (a === null || a.unitIDPathway === null) {
                  return -1;
                }
                if (a.unitIDPathway.toLowerCase() < b.unitIDPathway.toLowerCase()) {
                  return -1;
                }
                if (a.unitIDPathway.toLowerCase() > b.unitIDPathway.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setunitIdPathwayDescending(true);
              }
            }
            setunitPathwayReportResult(result);
            break;
            
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
    | "UnitPathway"
    | "UnitName"
    | "UnitID"
    | "UnitIDPathway",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setunitPathwayReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    SetUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    SetUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickUnitNameAsc = () => {
    setReverseNextSort(false);
    SetunitNameDescending(false);
    sortResultsBy("UnitName", false);
  };

  const clickUnitNameDesc = () => {
    setReverseNextSort(false);
    SetunitNameDescending(true);
    sortResultsBy("UnitName", true);
  };

  const clickUnitIDAsc = () => {
    setReverseNextSort(false);
    SetunitIdDescending(false);
    sortResultsBy("UnitID", false);
  };

  const clickUnitIDDesc = () => {
    setReverseNextSort(false);
    SetunitIdDescending(true);
    sortResultsBy("UnitID", true);
  };

  const clickUnitIDPathwayAsc = () => {
    setReverseNextSort(false);
    setunitIdPathwayDescending(false);
    sortResultsBy("UnitIDPathway", false);
  };

  const clickUnitIDPathwayDesc = () => {
    setReverseNextSort(false);
    setunitIdPathwayDescending(true);
    sortResultsBy("UnitIDPathway", true);
  };

 

  const initialSort = () => {
    setCurrentSortBy("UnitPathway");
    SetunitIdDescending(false);
    SetUnitPathwayDescending(false);
    SetunitNameDescending(false);  
    getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      if (res.isSuccess) {
        let curdt = res.currentDateTimeText;
        setReportRunTimeTaken(curdt);
      } else {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
      }
     }).catch ((error) => {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
     });
    setPage(0);
  };

  useEffect(() => {
    initialSort();
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Completed UnitPathway Report in NEWSALT ClientApp",
      null,
      "UnitPathwayReportResult.tsx",
      6, EnableActivityLogs
    );
  }, []);

  if (
    resultError !== null &&
    resultError !== undefined &&
    resultError.trimLeft().trimRight().length > 0
  ) {
    setTitle(allResourcesToTranslations.unitpathwayreport);
    setTitleEnglish(pageTitles.unitpathwayreport)
    updateBackButtonRoute("/unitpathwayreport");
    //setExportError("");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div style={{ color: "red" }}>Error: {resultError}</div>
          <br />
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else if (unitPathwayReport === null || unitPathwayReport.length < 1) {
    setTitle(allResourcesToTranslations.unitpathwayreport);
    setTitleEnglish(pageTitles.unitpathwayreport)
    //setExportError("");
    updateBackButtonRoute("/unitpathwayreport");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div>No Results to Display</div>
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else {
    setTitle(allResourcesToTranslations.unitpathwayreport);
    setTitleEnglish(pageTitles.unitpathwayreport)
    updateBackButtonRoute("/unitpathwayreport");
  }

  const GetSelectedUnitsSummary = () => {
    if (!!selectedUnitNames) {
      let arr = selectedUnitNames.split(",");
      return selectedUnitNames != "" && arr.length > 5 ? (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",", 5).map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
          <CustomTooltip
            displayText={selectedUnitNames.split(",").splice(5, arr.length)}
            placement="bottom"
          >
            <span className={styles.reportDetail}>
              {arr.length > 5 ? "+" + (arr.length - 5) + " more..." : ""}
            </span>
          </CustomTooltip>
        </div>
      ) : (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",").map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  if (exporting) return <LoaderContainer />;

  return (
    <>
      {
        <div style={{ color: "red" }}>
          {exportError === undefined ||
          exportError === null ||
          exportError === ""
            ? ""
            : "Export Error: " + exportError}
        </div>
      }
      <hr />
      <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>                
                <div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}>
                                { reportRunTimeTaken }
                            </span>
                        </div>
                        {GetSelectedUnitsSummary()}
                        <div className={styles.reportDetailContainer}>
                          <span className={styles.reportDetailTitle}>
                            {"Include Inactive Units?" /* TODO Tranlation */}
                          </span>
                          <span className={styles.reportDetail}>
                            {" "}
                            {includeInactiveUnits ? "Yes" : "No"}
                          </span>
                        </div>
                    </div>   
                   
                    <div className={styles.reportExportContainer}>
                                <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                        <Button  className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                                            <ExcelFileIcon/>
                                        </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                    <Button   className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                                        <CSVFileIcon/>
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                    <Button  className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCARReportExportPDF">
                                        <PDFIcon/>
                                    </Button>
                                </CustomTooltip>
                              {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading"  color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/> 
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                    
                </div>
               
            </div>
            </BrandedCard>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={unitPathwayReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="unitPathwayReportList">
                  <TableRow>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                        borderRadius: "8px 0 0 0",
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            SetUnitPathwayDescending(!unitPathwayDescending);
                            sortResultsBy("UnitPathway", !unitPathwayDescending);
                          }}
                        >
                          {"Unit Pathway" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitPathway" &&
                                unitPathwayDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitPathwayAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitPathway" &&
                                unitPathwayDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitPathwayDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            SetunitNameDescending(!uniNameDescending);
                            sortResultsBy("UnitName", !uniNameDescending);
                          }}
                        >
                          {"Unit Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitName" &&
                                uniNameDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitNameAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitName" &&
                                uniNameDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitNameDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> 
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,
                      }}
                      align="center"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            SetunitIdDescending(!unitIdDescending);
                            sortResultsBy("UnitID", !unitIdDescending);
                          }}
                        >
                          {"Unit ID" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitID" &&
                                unitIdDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitIDAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitID" &&
                                unitIdDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitIDDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>                  
                    <TableCell
                      style={{
                        color: getColor(color.substring(1)),
                        backgroundColor: color,borderRadius: "0 8px 0 0"
                      }}
                      align="left"
                    >
                      <div className={styles.tableHeadCellContainer}>
                        <div
                          onClick={() => {
                            setunitIdPathwayDescending(!unitIdPathwayDescending);
                            sortResultsBy("UnitIDPathway", !unitIdPathwayDescending);
                          }}
                        >
                          {"Unit ID Pathway " /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitIDPathway" &&
                                unitIdPathwayDescending === false
                                  ? "100%"
                                  : "50%",
                              margin: "2px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitIDPathwayAsc}
                          >
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{
                              opacity:
                                currentSortBy === "UnitIDPathway" &&
                                unitIdPathwayDescending === true
                                  ? "100%"
                                  : "50%",
                              margin: "0px",
                              height: "6px",
                              display: "inline-flex",
                            }}
                            onClick={clickUnitIDPathwayDesc}
                          >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitPathwayReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: UnitPathwayReportResultItem, i) => {
                      return (
                        <TableRow
                          key={`UserDetailReportResult-${i}`}
                          style={rowBackgroundColor(i)}
                        >
                          <TableCell align="left">{result.unitPathway}</TableCell>
                          <TableCell align="left">{result.unitName}</TableCell>
                          <TableCell align="center">{result.unitID}</TableCell>
                          <TableCell align="left">{result.unitIDPathway}</TableCell>
                        
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          component="div"
          count={unitPathwayReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default UnitPathwayReportResult;
