import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/organisationmanagement.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TinyMCEEditor from "../../../Components/TinyMCEEditorComponent";
import { GroupingOptionsList, OrgFeatureItem, OrgNoteResultItem } from "../../../globalTypes";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CustomTooltip from "../../../Components/CustomTooltip";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import FileDropBox from "../ImportAccounts/FileDropBox";
import FileInfoIcon from "../../../CustomIcons/Administration/ImportAccounts_FileInfoIcon";
import CloseIcon from "../../../CustomIcons/Administration/closeIcon";
import { decode } from "html-entities";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import getColor from '../../../functions/getColor';
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import BrandedCard from "../../../Components/BrandedCard";
import ExcelFileIcon from "../../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../../CustomIcons/CSVFileIcon";
import addError from "../../../API/AddError";
import serialToArray from "../../../functions/serialToArray";
import getImportClassificationSampleCsv from "../../../API/Administration/Organisation/getImportClassificationSampleCsv";
import getImportClassificationSampleXlsx from "../../../API/Administration/Organisation/getImportClassificationSampleXlsx";
import GetOrgDetails from "../../../API/Administration/Organisation/GetOrgDetails";
import SaveOrgDetails from "../../../API/Administration/Organisation/SaveOrgDetails";
import ImportGroupByReadFileData from "../../../API/Administration/Organisation/ImportGroupByReadGivenFile";
import { AddIcon } from "../../../CustomIcons/Administration/AddIcon";
import { Steps } from "intro.js-react";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import getOrgFeatures from "../../../API/GetOrgFeatures";
import oidcConfig from '../../../configuration';
import HardDiskIcon from "../../../CustomIcons/HardDiskIcon";

const OrganisationManagement = () => {
	const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles } = useContext(TranslationsContext);
	const { setTitle, setTitleEnglish, showSuperAdminUI, updateBackButtonRoute, EnableActivityLogs, timeZoneId, superAdminSelectedBasicOrgData } = useContext(UIContext);
	const { color } = useContext(CorperateThemeContext).theme;
	const [ superAdminDomain, setSuperAdminDomain ] = useState<string>(oidcConfig.currentdomainSA);
	const { oidcUser } = useReactOidc();
	const {
		profile: { user_id, website,  },
	} = oidcUser || {};
	const userId = user_id ? parseInt(user_id) : 0;
	const orgId = oidcUser.profile.organisation_id ?? -1;
	const orgDomain = website
		? website
			.toLowerCase()
			.replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
		: "";
	const [loading, setLoading] = useState<boolean>(true);
	const [orgName, setOrgName] = useState<string>("");
	const [originalGroupBy, setOriginalGroupBy] = useState<string>("");
	const [groupBy, setGroupBy] = useState<string>("");
	const [orgDomainName, setOrgDomainName] = useState<string>("");
	const [allocatedDiskSpace, setAllocatedDiskSpace] = useState<string>("");
	const [totalAllotedStoragekbmg, setTotalAllotedStoragekbmg] = useState<string>("");


	const [welcomeMessageBodyTemplate, setWelcomeMessageBodyTemplate] = useState<string>("");
	const [welcomeMessageBodyResult, setWelcomeMessageBodyResult] = useState<Array<OrgNoteResultItem>>([]);
	const [selectedFile, setSelectedFile] = useState<string>("");
	const [selectedFileName, setSelectedFileName] = useState<string>("");
	const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
	const [enableFileUploading, setenableFileUploading] = useState<boolean>(false);
	const [groupingOptionsResult, setGroupingOptionsResult] = useState<Array<GroupingOptionsList>>([]);
	const [isFileReadDone, setIsFileReadDone] = useState<boolean>(true);
	const [expandGroupingoption, setExpandGroupingoption] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const [isCPDPointsAllowed, setIsCPDPointsAllowed] = useState<boolean>(false);
	const [isPolicyBuilderAllowed, setIsPolicyBuilderAllowed] = useState<boolean>(false);
	const [isCPDEventAllowed, setIsCPDEventAllowed] = useState<boolean>(false);
	const [isSSOAllowed, setIsSSOAllowed] = useState<boolean>(false);
	const [isExternalCertificateAllowed, setIsExternalCertificateAllowed] = useState<boolean>(false);
	const [isnewsfeedAllowed, setIsnewsfeedAllowed] = useState<boolean>(false);
	//const [enableTcCheck, setEnableTcCheck] = useState<boolean>(false);
	const [isAPILibraryAllowed, setIsAPILibraryAllowed] = useState<boolean>(false);
	
	const [showModal, setShowModal] = useState<boolean>(false);
	const [saveSuccess, setSaveSuccess] = React.useState(false);
	const [modalPopupMessage, setModalPopupMessage] = useState('');
	const [hasErrored, setHasErrored] = useState<boolean>(false);
	const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
	const [newClassification, setNewClassification] = useState<string>("");
	const [newClassificationActive, setNewClassificationActive] = useState<boolean>(true);
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const [intro, setIntro] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps: [
			{
				element: ".orgName",
				title: "Organisation Name",
				intro: "Specify an Organisation Name for your Salt Site.",
			},
			{
				element: ".editor",
				title: "Welcome Message",
				intro: "Edit the Welcome message displayed to your accounts upon login to Salt.",
			},
			{
				element: ".CSVSampleDownload",
				title: "Download Sample CSV File",
				intro: "Click to download a CSV sample import file.",
			}, {
				element: ".XLSXSampleDownload",
				title: "Download Sample XLSX File",
				intro: "Click to download a XLSX sample import file.",
			},
			{
				element: ".fileUpload",
				title: "Upload File", 
				intro: "Drag and drop your completed Group By import file (.CSV\.XLSX) here or browse for a completed import Group By import file."
			},
			{
				element: ".GroupBy",
				title: "Group By",
				intro: "Enter the Group By classification for your organisation's Salt Site.",
			}
		],
	});

	useEffect(() => {
		let updateSteps = [...intro.steps];
		if (expandGroupingoption) {

			updateSteps.push({
				element: "#NewGroupingOption",
				title: "New Grouping Option", intro: "Enter a new Group By value."
			},
			{
				element: ".AddNewGroupingOption",
				title: "Add New Grouping Option", intro: "Click to add a new Group By value"
			});

			if (groupingOptionsResult.length > 0) {
				updateSteps.push({
					element: ".GroupingOptionList",
					title: "Grouping Options", intro: "Edit, Deactivate and Reactivate existing Group By values"
				});
			}
		}
		else {
			updateSteps.push(
				{
					element: ".GroupingOptions",
					title: "Grouping Options",
					intro: "Expand this section to Add New, Update, Deactivate, Reactivate specific Group By values for your organisation's Salt Site",
				}
			);
		}
		updateSteps.push({
				element: ".orgDomainName",
				title: "Domain Name", intro: "This is the Domain Name for your organisation's Salt Site",
			},
			{
				element: ".basicsettingsdiv",
				title: allResourcesToTranslations.basicsettings, intro: allResourcesToTranslations.saltadminaccessonly,
			},
			{
				element: ".saveData",
				title: "Save",
				intro: "Click here to save all changes",
			}
		);
		setIntro({ ...intro, steps: [...updateSteps] });
	}, []);

	const onExit = () => {
		setIntro({ ...intro, stepsEnabled: false });
	};

	useEffect(() => {
		setTitle(allResourcesToTranslations.organisationmanagement);
		setTitleEnglish(pageTitles.organisationmanagement);
		updateBackButtonRoute("");
		setLoading(true);

		if ((superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData && superAdminSelectedBasicOrgData.orgID > 0) || (superAdminSelectedBasicOrgData === null && (superAdminSelectedBasicOrgData === null || superAdminSelectedBasicOrgData === undefined))) {
		getOrgFeatures(oidcUser.access_token, userId, orgDomain,  parseInt(orgId), 
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : -1),
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((featureResult) => {
			if (!!featureResult && featureResult.isSuccess){
			  var features = featureResult.orgFeatureList as Array<OrgFeatureItem>;
			  if (!!features && features.length > 0) {
				setIsCPDPointsAllowed(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "cpd profile" && !!el.isEnabled && el.isEnabled === true));
				setIsPolicyBuilderAllowed(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "policy" && !!el.isEnabled && el.isEnabled === true));
				setIsSSOAllowed(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "sso" && !!el.isEnabled && el.isEnabled === true));
				setIsExternalCertificateAllowed(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "external certificate" && !!el.isEnabled && el.isEnabled === true));
				setIsnewsfeedAllowed(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "newsfeed" && !!el.isEnabled && el.isEnabled === true));
				setIsCPDEventAllowed(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "cpd event" && !!el.isEnabled && el.isEnabled === true));
				setIsAPILibraryAllowed(features.some((el) => !!el.featureName && el.featureName.toLowerCase() === "api library" && !!el.isEnabled && el.isEnabled === true));
			  }
			}			
		  })
		  
		GetOrgDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), currentLanguage, 
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result) => {
			if (result.isSuccess && result.error == '') {
				if (result.orgDetail !== null) {
					setOrgDomainName(result.orgDetail.domainName);
					setAllocatedDiskSpace(result.orgDetail.allocatedDiskSpace);
					setTotalAllotedStoragekbmg(result.orgDetail.totalAllotedStoragekbmg);
					setOrgName(result.orgDetail.orgName);
					setGroupBy(result.orgDetail.groupBy);
					setOriginalGroupBy(result.orgDetail.groupBy);
					if(!!result.orgDetail.groupByOption)
					{
						setGroupingOptionsResult(result.orgDetail.groupByOption);
					}
					if (result.orgDetail.orgNotes != '') {
						setWelcomeMessageBodyTemplate(result.orgDetail.orgNotes);
						var html = decode(result.orgDetail.orgNotes);
						setWelcomeMessageBodyResult([{ body: html, orgId: parseInt(orgId) }])
					}

				//	setEnableTcCheck(result.orgDetail.enableTcCheck);
				}
			}
			else {
				setShowModal(true);
				setHasErrored(true);
				setSaveSuccess(false);
				setModalPopupMessage("Can not load Organisation details!");
			}
			setLoading(false);
		});
	}
	else {
		setLoading(false);
		setShowModal(true);
		setHasErrored(true);
		setSaveSuccess(false);
		setModalPopupMessage("Please select an organisation first!");
	}
		// at the end, return a cleanup methodhttp://localhost:3000/myreport
		return () => { };
	}, []);

	const handleTextEditorChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (event !== undefined) {
			setWelcomeMessageBodyTemplate(event.target.value);
		}
	};

	const handleNewClassificationAdd = (event: any) => {
		setNewClassification(event.target.value);
	}

	const handleGroupingOptionChange = (event: any, changeType: string, rowNo: number) => {
		let currentGroupingOptionsResult = [...groupingOptionsResult];
		currentGroupingOptionsResult.map((groupingOption: GroupingOptionsList) => {
			if (groupingOption.indexNo === rowNo) {
				if (changeType === 'GroupingOption') {
					groupingOption.value = event.target.value;
				}
				if (changeType === 'Active') {
					event.stopPropagation();
					groupingOption.active = !groupingOption.active;
				}
			}
		})
		setGroupingOptionsResult(currentGroupingOptionsResult);
	}

	const handleSave = () => {
		var orgNoteBodyTemplateContent = (document.getElementById('editorText') as HTMLInputElement).value;
		var orgNoteBodyTemplateInitialContent = (document.getElementById('editorChangedText') as HTMLInputElement).value;
		setWelcomeMessageBodyTemplate((!!orgNoteBodyTemplateContent === false || orgNoteBodyTemplateContent.trim() === "") ? orgNoteBodyTemplateInitialContent : orgNoteBodyTemplateContent);
		setIsSaving(true);
		SaveOrgDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId), currentLanguage, orgName, orgDomainName,
			(!!orgNoteBodyTemplateContent === false || orgNoteBodyTemplateContent.trim() === "") ? orgNoteBodyTemplateInitialContent : orgNoteBodyTemplateContent,
			groupBy, groupingOptionsResult, isCPDPointsAllowed, isPolicyBuilderAllowed, isCPDEventAllowed, isSSOAllowed, isnewsfeedAllowed, isExternalCertificateAllowed,isAPILibraryAllowed,	
			allocatedDiskSpace,	
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((res) => {
				setIsSaving(false);
				if (!!res && res.isSuccess) {
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(true);
					setModalPopupMessage("Organisation details have been saved successfully!");
				}
				else {
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Organisation details saving have been failed!");
				}
			})
	}

	const handleAddMoreClick = () => {
		let currentgroupByOption = [...groupingOptionsResult];

		let checkIfAlreadyExist = currentgroupByOption.find(x => x.value.toLowerCase().trim() === newClassification.toLowerCase().trim());
		if (!!checkIfAlreadyExist){
			setShowModal(true);
			setHasErrored(false);
			setSaveSuccess(false);
			setModalPopupMessage("Grouping Option does already exist!");
			setNewClassification('');
		}
		else 
		{
			let newGroupingOption: GroupingOptionsList = {
				indexNo: currentgroupByOption.length,
				classificationId: -1,
				classificationTypeId: 0,
				value: newClassification,
				active: newClassificationActive
			}
			currentgroupByOption.push(newGroupingOption);

			currentgroupByOption.sort((a: any, b: any) => {
				if (b === null || b.indexNo === null) {
					return -1;
				}
				if (a === null || a.indexNo === null) {
					return 1;
				}
				if (a.indexNo > b.indexNo) {
					return -1;
				}
				if (a.indexNo < b.indexNo) {
					return 1;
				}
				return 0;
			});
			
			setGroupingOptionsResult(currentgroupByOption);
			setNewClassification('');
			setPage(0);
		}
	}

	const handleFileUpload = (byteArray: string, fileExtension: string, filename: string) => {
		setSelectedFile(filename);
		setSelectedFileName(filename);
		setIsFileUploading(true);
		setenableFileUploading(true);
		setIsFileReadDone(false);

		ImportGroupByReadFileData(oidcUser.access_token, userId, orgDomain, parseInt(orgId), byteArray, fileExtension, filename, groupBy,
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
		(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((res) => {
			setIsFileReadDone(true);

			setenableFileUploading(false);
			if (!!res && res.isSuccess) {
				if (res.error != '') {
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(false);
					setModalPopupMessage(res.error);
				}
				if (!!res.groupByOption && res.groupByOption.length > 0) {
					setExpandGroupingoption(true);
					let addedgroupByOption = [...groupingOptionsResult];
					res.groupByOption.map((groupingOption: GroupingOptionsList) => {
						let filterexisting = addedgroupByOption.filter((option:GroupingOptionsList) => option.value.toLocaleLowerCase().trim() === groupingOption.value.toLocaleLowerCase().trim());
						if (filterexisting.length === 0) {
							addedgroupByOption.push(groupingOption);
						}
					})
					setGroupingOptionsResult(addedgroupByOption);
				}
			}
			else {
				setShowModal(true);
				setHasErrored(true);
				setSaveSuccess(false);
				setModalPopupMessage("Given CSV file content reading & formatting failed!" + res.error);
			}
		})
	}

	const handleSetSelectedFileName = (filename: string) => {
		setSelectedFileName(filename);
	}

	const onAccordianChange = (event: any, expanded: boolean) => {
		if (!!event && !!event.currentTarget && !!event.currentTarget.ariaExpanded) {
			event.currentTarget.ariaExpanded = !!expanded ? "true" : "false";
		}
	}

	const handleExpandClick = () => {		

		// Set Guide me
		if (!expandGroupingoption) {
			let updateSteps = [...intro.steps];
			updateSteps.splice(6, updateSteps.length - 6); 

			updateSteps.push({
				element: "#NewGroupingOption",
				title: "New Grouping Option", intro: "Enter a new Group By value."
			},
			{
				element: ".AddNewGroupingOption",
				title: "Add New Grouping Option", intro: "Click to add a new Group By value"
			});

			if (groupingOptionsResult.length > 0) {
				updateSteps.push({
					element: ".GroupingOptionList",
					title: "Grouping Options", intro: "Edit, Deactivate and Reactivate existing Group By values"
				});
			}

			updateSteps.push(
				{
					element: ".orgDomainName",
					title: "Domain Name", intro: "This will have Domain name for organisation"
				},
				{
					element: ".saveData",
					title: "Save",
					intro: "Click here to save all changes",
				}
			);
			setIntro({ ...intro, steps: [...updateSteps] });
		}
		else {
			let updateSteps = [...intro.steps];
			updateSteps.splice(6, updateSteps.length - 6); 
			updateSteps.push(
				{
					element: ".GroupingOptions",
					title: "Grouping Options",
					intro: "Expand this section to Add New, Update, Deactivate, Reactivate specific Group By values for your organisation's Salt Site",
				}
			);
			updateSteps.push({
				element: ".orgDomainName",
				title: "Domain Name", intro: "This will have Domain name for organisation"
			},
				{
					element: ".saveData",
					title: "Save",
					intro: "Click here to save all changes",
				}
			);
			setIntro({ ...intro, steps: [...updateSteps] });
		}
		setExpandGroupingoption(!expandGroupingoption);
	}

	const handleResetFileUpload = () => {
		let finalGroupingOption = [...groupingOptionsResult];
		finalGroupingOption = finalGroupingOption.filter((item) => item.classificationId > 0);
		setGroupingOptionsResult(finalGroupingOption);
		setSelectedFile('');
	}

	const handleClosePopup = () => {
		setShowModal(false);
		handleResetFileUpload();
	};

	const rowBackgroundColor = (index: number) => {
		if (enableRowBanding) {
			return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
		} else {
			return {};
		}
	};
	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}
	const handleXLSXSampleDownload = () => {
		// Activity log
		addError(
			oidcUser.access_token,
			userId,
			orgDomain,
			"Started Download Sample XLSX Export in NEWSALT ClientApp",
			null,
			"OrganisationManagement.tsx",
			6, EnableActivityLogs
		);
		getImportClassificationSampleXlsx(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((xlxsRes) => {
			if (xlxsRes.isSuccess) {
				const fileData = xlxsRes.xlsxDataOutput.fileData
				let a = window.document.createElement('a');
				a.href = window.URL.createObjectURL(
					new Blob([serialToArray(atob(fileData))], {
						type: ''
					})
				);

				a.download = xlxsRes.xlsxDataOutput.resultFileFullName;
				document.body.appendChild(a)
				a.click();
				document.body.removeChild(a)

			}
			else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
			}
			// Activity log
			addError(oidcUser.access_token, userId, orgDomain, "Completed Download Sample XLSX Export in NEWSALT ClientApp", null, "OrganisationManagement.tsx", 6, EnableActivityLogs);
		})
	};

	const handleCSVSampleDownload = () => {
		// Activity log
		addError(
			oidcUser.access_token,
			userId,
			orgDomain,
			"Started Download Sample CSV Export in NEWSALT ClientApp",
			null,
			"OrganisationManagement.tsx",
			6, EnableActivityLogs
		);
		getImportClassificationSampleCsv(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((csvRes) => {
			if (csvRes.isSuccess) {
				const fileData = csvRes.csvDataOutput.fileData
				let a = window.document.createElement('a');

				a.href = window.URL.createObjectURL(
					new Blob([serialToArray(atob(fileData))], {
						type: ''
					})
				)
				a.download = csvRes.csvDataOutput.resultFileFullName;
				document.body.appendChild(a)
				a.click();
				document.body.removeChild(a)

			}
			else if (csvRes.error !== undefined && csvRes.error !== null) {
			}
			// Activity log
			addError(oidcUser.access_token, userId, orgDomain, "Completed Download Sample CSV Export in NEWSALT ClientApp", null, "OrganisationManagement.tsx", 6, EnableActivityLogs);
		})
	};

	
	useEffect(() => {
		if (showModal && !hasErrored) {
		  setTimeout(() => {
			setShowModal(false);
			setSaveSuccess(false);
		  }, 3000)
		}
	  }, [showModal])

	if (loading) return <LoaderContainer />;

	return (
		<>
			<Steps
				enabled={intro.stepsEnabled}
				steps={intro.steps}
				initialStep={intro.initialStep}
				onExit={onExit}
				options={{
					nextLabel: "Next",
					prevLabel: "Back",
					tooltipClass: "sample-class",
					showBullets: true,
				}}
			/>
			<CustomTooltip displayText={["Guide Me"]}>
				<span
					onClick={() => setIntro({ ...intro, stepsEnabled: true })}
					className={styles.guideMeButtonSpecific}
				>
					<GuideIcon />
				</span>
			</CustomTooltip>

			<div className="orgName" style={{ backgroundColor: "white", height: "45px", paddingBottom: "10px" }}>
				<TextField
					className={styles.input}
					label={"Organisation Name"}
					name="OrganisationName"
					variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
					value={orgName}
					onChange={(e) => setOrgName(e.target.value)}
					placeholder={allResourcesToTranslations.requiredfield}
				/>
			</div>
			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>
						<div className={styles.inputContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.welcomemessage}</span>
						</div>
						<div className="editor" style={{ width: '100%' }} >
							<span></span>
							{welcomeMessageBodyResult.map((result1: any, j) => {
								if (j == 0) {
									return (
										<TinyMCEEditor  index={j} key="tinyMCEEditor" editorData={result1.body} isTemplate={false} is200Tall={true} handleTextEditorChange={handleTextEditorChange} />
									);
								}
							})}
						</div>
					</div>
				</div>
			</UnbrandedCard>

			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>
						<div className={styles.inputContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.groupingoption}</span>
						</div>
						<div>
							<div style={{ width: '50%', float: "left" }}>
								<div className={styles.inputContainer} >
									<span className={styles.labelTextNormal}>{allResourcesToTranslations.sampleimportfile}</span>
								</div>
								<div className={styles.inputContainer}>
									<span className={styles.labelTextitalic}>{allResourcesToTranslations.sampleimportfiledownload}</span>
								</div>
								<div className={styles.inputContainer}>
									{/*<FileDownloadIcon /> */}
									<CustomTooltip displayText={[allResourcesToTranslations.downloadsamplecsv]}>
										<Button onClick={() => { handleCSVSampleDownload(); }}
											className="CSVSampleDownload">
											<CSVFileIcon></CSVFileIcon>
										</Button>
									</CustomTooltip>&nbsp;&nbsp;&nbsp;
									<CustomTooltip displayText={[allResourcesToTranslations.downloadsamplexlsx]}>
										<Button onClick={() => { handleXLSXSampleDownload(); }}
											className="XLSXSampleDownload">
											<ExcelFileIcon></ExcelFileIcon>
										</Button>
									</CustomTooltip>
								</div>
								<div style={{ paddingRight: "20px" }}>
									<CustomTooltip displayText={[allResourcesToTranslations.draganddropcustomtooltip]}>
										<div className="fileUpload">
											<FileDropBox selectedFile={selectedFile} uploadFile={handleFileUpload} setSelectedFile={handleSetSelectedFileName} />
										</div>
									</CustomTooltip>
								</div>
								<div className="GroupBy" style={{ paddingRight: "20px" }}>
									<TextField
										className={styles.input}
										label={"Group By"}
										style={{ width: "100%", marginBottom: "15px" }}
										variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
										value={groupBy}
										onChange={(e) => setGroupBy(e.target.value)}
									/>
								</div>
							</div>
							<div style={{ width: '50%', float: "right" }}>
								{selectedFile != '' ?
									<div className={styles.importfileContainer}>
										<div style={{ width: "100%", display: "inline-flex" }}>
											<div style={{ float: "left", width: "95%", display: "flex" }}>
												<div> <FileInfoIcon></FileInfoIcon></div>
												<div>
													<span>{selectedFile}</span>
												</div>
											</div>
											<div style={{ float: "right", width: "5%" }}>
												{!isFileReadDone &&
													<div className={styles.loaderContainer}>
														<CircularProgress
															style={{ width: !isFileReadDone ? "20px" : "0px" }}
															color="primary"
															size="10"
														/>
													</div>
												}
												{isFileReadDone &&
													<span onClick={handleResetFileUpload} style={{ cursor: "pointer" }}>
														<CloseIcon />
													</span>
												}
											</div>
										</div>
									</div>
									: <span></span>
								}
								<Accordion className="GroupingOptions" expanded={expandGroupingoption} style={{ borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }} onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded); }}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="managerSettings-content"
										id="managerSettings-header"
										onClick={(e) => handleExpandClick()}
									>
										<Typography className="typographyFonts"><b>{allResourcesToTranslations.groupingoption}</b></Typography>
									</AccordionSummary>
									<AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
										<Typography className="typographyFonts" style={{ display: "inline" }}>
											<div style={{ display: "flex", marginRight: "-5px", width: "100%", paddingBottom: "10px" }}>
												<div id="NewGroupingOption" className={styles.wrraptxt} style={{ width: "inherit" }}>
													<TextField
														className={styles.input}
														label={"New Grouping Option"}
														style={{ width: "100%", marginBottom: "15px" }}
														variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
														value={newClassification}
														onChange={(e) => handleNewClassificationAdd(e)}
														placeholder="Add value"
													/>
												</div>
												<div className="AddNewGroupingOption">
													<CustomTooltip displayText={["Add new Grouping Option"]}>
														<Button className={styles.moduleListButton} onClick={(e) => handleAddMoreClick()} 
														disabled={newClassification.trim() === ''}
														variant="contained" color="primary">
															<AddIcon />
														</Button>
													</CustomTooltip>
												</div>
											</div>
											{groupingOptionsResult.length <= 0 ? (<span></span>) : (
												<div>
													{groupingOptionsResult.length <= 10 ? (<span></span>) : (
														<div className={styles.paginationControlsContainer}>

															<TablePagination
																rowsPerPageOptions={[5, 10, 20, 100]}
																component="div"
																style={{ position: 'relative' }}
																count={groupingOptionsResult.length}
																rowsPerPage={rowsPerPage}
																page={page}
																onChangePage={handleChangePage}
																onChangeRowsPerPage={handleChangeRowsPerPage}
															/>

														</div>
													)}
													<BrandedCard>
														<TableContainer>
															<Table className="GroupingOptionList">
																<TableHead>
																	<TableRow>
																		<TableCell className={styles.columnDesc} style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", fontFamily: `${currentLanguage}, sans-serif` }} align="left">
																			<span>Name</span>
																		</TableCell>
																		<TableCell className={styles.columnValue} style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0", fontFamily: `${currentLanguage}, sans-serif` }} align="center">
																			<span>Active</span>
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{
																		(
																			groupingOptionsResult
																				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
																				.map((result: GroupingOptionsList, i) => {
																					return (
																						<TableRow key={`GroupingOptionImportResult-${result.indexNo}`} style={rowBackgroundColor(i)} className={styles.groupingOptionRow}>
																							<TableCell align="left">
																								<div className={styles.wrraptxt}>
																									<TextField className={styles.inputmidsize} variant="outlined"
																										defaultValue={result.value} style={{ padding: "5px" }} onChange={(e) => { handleGroupingOptionChange(e, 'GroupingOption', result.indexNo) }}
																										autoComplete="off" InputLabelProps={{ shrink: true, }} />
																								</div>
																							</TableCell>
																							<TableCell className={styles.columnValue} align="center">
																								<Checkbox
																									size="small"
																									color="primary"
																									checked={result.active}
																									className={styles.checkBox}
																									onClick={(e) => { handleGroupingOptionChange(e, 'Active', result.indexNo) }}
																								/>
																							</TableCell>
																						</TableRow>
																					);
																				})
																		)}
																		<TableRow>
																				<TableCell className={styles.emptycell}></TableCell>
																				<TableCell className={styles.emptycell}></TableCell>
																		</TableRow>
																</TableBody>
															</Table>
														</TableContainer>
													</BrandedCard>
													{groupingOptionsResult.length <= 10 ? (<span></span>) : (
														<div className={styles.paginationControlsContainer}>

															<TablePagination
																rowsPerPageOptions={[5, 10, 20, 100]}
																component="div"
																style={{ position: 'relative' }}
																count={groupingOptionsResult.length}
																rowsPerPage={rowsPerPage}
																page={page}
																onChangePage={handleChangePage}
																onChangeRowsPerPage={handleChangeRowsPerPage}
															/>
														</div>
													)}
												</div>
											)}
										</Typography>
									</AccordionDetails>
								</Accordion>
							</div>
							<div style={{ clear: "both" }}></div>
						</div>
					</div>
				</div>
			</UnbrandedCard>

			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>
						<div className={styles.inputContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.orgdomainname}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.orgdomainnamenote}</span>
						</div>
						<div className="orgDomainName">
							<TextField
								className={styles.input}
								label={allResourcesToTranslations.orgDomainName}
								style={{ width: "100%", marginBottom: "15px" }}
								variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
								value={orgDomainName}
								disabled={!showSuperAdminUI}
								onChange={(e) => setOrgDomainName(e.target.value)}
							/>
						</div>
					</div>
				</div>
			</UnbrandedCard>
			
			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.basicsettings}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.selecttext}</span>
						</div>
						<div className={`${responsivestyles.rowrule} basicsettingsdiv`} style={{ width: '100%'}} >
							<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
								<div className="IsCPDPointsAllowed">
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isCPDPointsAllowed}
										className={styles.checkBox}
										disabled={!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)}
										onClick={() => setIsCPDPointsAllowed(!isCPDPointsAllowed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.cpdpoints}</span>
								</div>
								<div className="IsPolicyBuilderAllowed">
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isPolicyBuilderAllowed}
										className={styles.checkBox}
										disabled={!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)}
										onClick={() => setIsPolicyBuilderAllowed(!isPolicyBuilderAllowed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.policybuilder}</span>
								</div>
								<div className="IsnewsfeedAllowed" >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isnewsfeedAllowed}
										className={styles.checkBox}
										disabled={!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)}
										onClick={() => setIsnewsfeedAllowed(!isnewsfeedAllowed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.newsfeed}</span>
								</div>								
							</div>
							<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
								<div className="IsSSOAllowed" >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isSSOAllowed}
										className={styles.checkBox}
										disabled={!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)}
										onClick={() => setIsSSOAllowed(!isSSOAllowed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.singlesignon}</span>
								</div>
								<div className="IsExternalCertificateAllowed" >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isExternalCertificateAllowed}
										className={styles.checkBox}
										disabled={!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)}
										onClick={() => setIsExternalCertificateAllowed(!isExternalCertificateAllowed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.extcertificate}</span>
								</div>
								{(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<div className="isCPDEventAllowed" >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isCPDEventAllowed}
										className={styles.checkBox}
										disabled={!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)}
										onClick={() => setIsCPDEventAllowed(!isCPDEventAllowed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.cpdevent}</span>
								</div>
								}
							</div>
							<div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`} >
								<div className="IsAPILibraryAllowed" >
									<Checkbox
										style={{ paddingLeft: '0px' }}
										size="small"
										color="primary"
										checked={isAPILibraryAllowed}
										className={styles.checkBox}
										disabled={!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)}
										onClick={() => setIsAPILibraryAllowed(!isAPILibraryAllowed)}
									/>{" "}
									<span className={styles.labelText}>{allResourcesToTranslations.apilibrary}</span>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</UnbrandedCard>
			
 			{((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) && isPolicyBuilderAllowed ) &&
			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>						
						<div className={styles.inputContainer} style={{ width: '50%', float: 'left' }}>
							<div style={{float: 'left'}}> <HardDiskIcon/> </div>
							<div style={{ paddingTop: '7px', paddingLeft: '30px' }}><span className={styles.labelText}> {allResourcesToTranslations.policydiscsapace}</span></div>
						</div>
						<div className={styles.inputContainer} style={{ width: '45%', float: 'right', textAlign: 'right', paddingTop: '7px', paddingRight: '20px'}}>
							<span className={styles.labelText}> {totalAllotedStoragekbmg} Available</span>							
						</div>						
						<div className="OrgAllocatedDiskSpace">
							<TextField
								className={styles.input}
								type="number"
								label={allResourcesToTranslations.allocateddiskspace}
								style={{ width: "100%", marginBottom: "15px" }}
								variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
								value={allocatedDiskSpace}								
								onChange={(e) => setAllocatedDiskSpace(e.target.value)}
							/>
						</div>
					</div>
				</div>
			</UnbrandedCard>	
			}
			
			<div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px" }}>
				<CustomTooltip displayText={[allResourcesToTranslations.saveorgmanagement]}>
					<span className="saveData" style={{ marginRight: "-5px" }}>
						<Button
							disabled={orgName.trim() === "" || orgDomainName.trim() ==="" || (originalGroupBy.trim() !== "" && groupBy.trim() === "")}
							onClick={handleSave}
							variant="contained"
							color="primary"
							className="save"
						>
							<SaveIcon />
						</Button>
					</span>
				</CustomTooltip>
				<div className={styles.loaderContainer}>
					<CircularProgress
						style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
						color="primary"
						size="10"
					/>
				</div>
			</div>
			{showModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
		</>
	);
};

export default OrganisationManagement;
