//React + Typescript
import React, {  useEffect, useState } from 'react';
import {  useLocation } from 'react-router-dom';
// Libsimport

//Intro Lib
//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";
import LoaderContainer from '../../../Components/LoaderContainer';

import { UnitTreeItem, SelectedSettings, CourseTreeItem } from '../../../globalTypes';


interface ReportsParametersPreviewProps {

  units: UnitTreeItem[];
  courseList: CourseTreeItem[];
  pastcourseList?:CourseTreeItem[];
  filterFirstName: string;
  filterLastName: string;
  filterUserName: string;
  filterEmail: string;
  filterIncludeInactive?: boolean;
  classificationList?: any[];
  filterClassificationID?: number;
  reportId: number;
  report: string;
  filterDateFrom?: string;
  filterDateTo?: string;
  filterCourseStatus?: string;
  filterWarningPeriod?: number;
  filterDayType?: string;
  failCounter?: string;

}



const ReportsParametersPreview: React.FC<ReportsParametersPreviewProps> = ({ units, courseList,pastcourseList, filterFirstName, filterLastName, filterUserName, filterEmail, filterIncludeInactive, classificationList, filterClassificationID, reportId, report, filterDateFrom, filterDateTo, filterCourseStatus , filterWarningPeriod, filterDayType, failCounter}) => {
  const location = useLocation<any>();
  const [loading, setLoading] = useState(true);
  const [modalPopUpSettingsMessages, setModalPopUpSettingsMessages] = React.useState<Array<SelectedSettings> | null>(null);
  const [modalPopUpHeading, setModalPopUpHeading] = React.useState("Unsaved Changes");
  const [modalPopUpMessages, setModalPopUpMessages] = React.useState(["Do you want to proceed without saving changes?"]);

  useEffect(() => {
    setLoading(false);
    GenerateAPIRequestBody();
  }, []);



  const GenerateAPIRequestBody = () => {
    // Build the UI settings sub section values
    let allSettings = new Array<SelectedSettings>();

    // Selected Units
    var settings = getUnitSelectionSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }

    // Selected Courses
    settings = getCourseSelectionSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }

    if (!!report && report.toString().toLowerCase() == 'car' || report.toString().toLowerCase() == 'qhr') {
    // Selected PastCourses
    settings = getPastCourseSelectionSettings();
    if (settings !== null && settings !== undefined) {    
      allSettings.push(settings); 
    }
  }

    if (!!report && report.toString().toLowerCase() !== 'qhr') {
    settings = getGroupingSelectionSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }
  }

    // Selected Firstname Filter
    settings = getFirstnameFilterSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }

    // Selected Lastname Filter
    settings = getLastnameFilterSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }
    // Selected Username Filter
    settings = getUsernameFilterSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }
    // Selected Email Filter
    settings = getEmailFilterSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }

    if (!!report && report.toString().toLowerCase() !== 'sr' && report.toString().toLowerCase() !== 'cpdr') {
      // Selected User Status Filter
      settings = getUserStatusFilterSettings();
      if (settings !== null && settings !== undefined) {
        allSettings.push(settings);
      }
    }
    //Selected Warning Period
    if (!!report && report.toString().toLowerCase() === 'wr') {
      // Selected User Status Filter
      settings = getWarningPeriodFilterSettings();
      if (settings !== null && settings !== undefined) {       
        allSettings.push(settings); 
      }
    }

 // ATRiskReport Period
      if (!!report && report.toString().toLowerCase() === 'atriskreport') {
          // Selected User Status Filter
          settings = getAtRiskFailCounterSettings();
          if (settings !== null && settings !== undefined) {
              allSettings.push(settings);
          }
      }  


    // Selected Course Status Filter
    settings = getCSRCourseStatusSelectionSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }

    // Selected Course Status Date From Filter
    settings = getCSRDateFromSelectionSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }

    // Selected Course Status Date To Filter
    settings = getCSRDateToSelectionSettings();
    if (settings !== null && settings !== undefined) {
      allSettings.push(settings);
    }

    // Selected Summary Date From Filter
    settings = getSRDateFromSelectionSettings();
    if (settings !== null && settings !== undefined) { 
        allSettings.push(settings); 
    }
    // Selected Summary Date To Filter
    settings = getSRDateToSelectionSettings();
    if (settings !== null && settings !== undefined) { 
        allSettings.push(settings); 
    }
    setModalPopUpHeading("API Settings Confirmation");
    setModalPopUpMessages(["You have made the following selections to your scheduled report"]);
    if (allSettings.length <= 0) {
      setModalPopUpMessages(["No valid settings are given/selected, if proceeded the save will not be successful"]);
    }
    setModalPopUpSettingsMessages(allSettings);


    setLoading(false)
  }



  //Unit Selection Settings
  const unitArrayUnique = (array: UnitTreeItem[]) => {
    if (array === null || array === undefined || array.length <= 0) {
      return array;
    }
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }

    return a;
  }
  const getUnitSelectionSettings = () => {
    // Selected Units
    var unitNamesGiven: UnitTreeItem[] = [];
    var unitsToDisplay = getAllUnitSettingsArrayInTree(units, false, unitNamesGiven);//units.filter(u => u !== null && u !== undefined && u.isSelected);
    var isAllUnits = false;
    if (unitsToDisplay === null || unitsToDisplay === undefined || unitsToDisplay.length <= 0) {
      unitsToDisplay = getAllUnitSettingsArrayInTree(units, true, unitsToDisplay); //selectedUnitNames.map(u => u.name);
      isAllUnits = true;
    }
    let unitsToDisplayUnique = unitArrayUnique(unitsToDisplay);
    if (unitsToDisplayUnique !== null && unitsToDisplayUnique !== undefined && unitsToDisplayUnique.length > 0) {
      //var unitNames = unitsToDisplayUnique.filter(u => u !== null && u !== undefined).map(u => "•" + u.unitHierarchy);
      var unitNames = unitsToDisplayUnique.filter(u => u !== null && u !== undefined).map(u => u.unitHierarchy);
      let selectedUnitsListSection: SelectedSettings = {
        title: isAllUnits ? "UNITS (Considered all by default)" : "UNITS (Only selected)",
        rowDisplayValues: unitNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUnitsListSection;
    }
    return null;
  }

  const getAllUnitSettingsArrayInTree = (givenUnits: UnitTreeItem[] | null, isNotOnlySelected: boolean, returnUnits: UnitTreeItem[]) => {
    if (givenUnits === null || givenUnits === undefined || givenUnits.length <= 0) {
      return returnUnits;
    }
    if (returnUnits === null || returnUnits === undefined) {
      returnUnits = [];
    }
    var unitNames = givenUnits.map(u => getGivenUnitSettingsArray(u, isNotOnlySelected, returnUnits));
    let singleArr = appendAsSingleUnitArray(unitNames, returnUnits);
    if (singleArr === null || singleArr === undefined || singleArr.length <= 0) {
      return returnUnits;
    }
    returnUnits = returnUnits.concat(singleArr);
    return returnUnits;
  }

  const getGivenUnitSettingsArray = (givenUnit: UnitTreeItem, isNotOnlySelected: boolean, returnUnits: UnitTreeItem[]) => {
    if (givenUnit === null || givenUnit === undefined) {
      return returnUnits;
    }
    if (returnUnits === null || returnUnits === undefined) {
      returnUnits = [];
    }
    returnUnits = returnUnits.concat((isNotOnlySelected || givenUnit.isSelected) ? [givenUnit] : []);
    let subArray = getAllUnitSettingsArrayInTree(givenUnit.childUnitTreeItems, isNotOnlySelected, returnUnits);
    if (subArray !== null && subArray !== undefined && subArray.length >= 0) {
      returnUnits = returnUnits.concat(subArray);
    }
    return returnUnits;
  }

  const appendAsSingleUnitArray = (givenArrOfArr: UnitTreeItem[][] | null, returnUnitNames: UnitTreeItem[]) => {
    if (givenArrOfArr === null || givenArrOfArr === undefined || givenArrOfArr.length <= 0) {
      return returnUnitNames;
    }
    if (returnUnitNames === null || returnUnitNames === undefined) {
      returnUnitNames = [];
    }
    givenArrOfArr.map(aa => {
      if (aa !== null && aa !== undefined && aa.length >= 0) {
        returnUnitNames = returnUnitNames.concat(aa)
      }
      else {
        returnUnitNames = returnUnitNames;
      }
    });
    return returnUnitNames;
  }
  //Unit Selection Settings

  //course Selection Settings
  const getCourseSelectionSettings = () => {
    var selectedCourses = courseList.filter(u => u !== null && u !== undefined && u.isSelected);
    var isAllCourses = false;
    if (selectedCourses === null || selectedCourses === undefined || selectedCourses.length <= 0) {
      selectedCourses = courseList;
      isAllCourses = true;
    }
    if (selectedCourses !== null && selectedCourses !== undefined && selectedCourses.length > 0) {
      //var courseNames = selectedCourses.filter(u => u !== null && u !== undefined).map(u => "•" + u.name);
      var courseNames = selectedCourses.filter(u => u !== null && u !== undefined).map(u => u.name);
      let selectedCoursesListSection: SelectedSettings = {
        title: isAllCourses ? "COURSES (Considered all by default)" : "COURSES (Only selected)",
        rowDisplayValues: courseNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedCoursesListSection;
    }
    return null;
  }
  //Course Selection Settings  


  const getPastCourseSelectionSettings = () => {
    var selectedPastCourses = pastcourseList?.filter(u => u !== null && u !== undefined && u.isSelected);   
   
    if (selectedPastCourses !== null && selectedPastCourses !== undefined && selectedPastCourses.length > 0) {
      //var pastcourseNames = selectedPastCourses.filter(u => u !== null && u !== undefined).map(u => "•" + u.name);
      var pastcourseNames = selectedPastCourses.filter(u => u !== null && u !== undefined).map(u => u.name);
      let selectedPastCoursesListSection : SelectedSettings = {
        title: "PAST COURSES (Only selected)",
        rowDisplayValues: pastcourseNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedPastCoursesListSection;
    }
    return null;
  }
  

  const getFirstnameFilterSettings = () => {
    if (filterFirstName !== null && filterFirstName !== undefined && filterFirstName.trimLeft().trimRight() !== "") {
      let selectedFNFilterSection: SelectedSettings = {
        title: "FirstName Filter",
        rowDisplayValues: [filterFirstName],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedFNFilterSection;
    }
    return null;
  }

  const getLastnameFilterSettings = () => {
    if (filterLastName !== null && filterLastName !== undefined && filterLastName.trimLeft().trimRight() !== "") {
      let selectedLNFilterSection: SelectedSettings = {
        title: "LastName Filter",
        rowDisplayValues: [filterLastName],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedLNFilterSection;
    }
    return null;
  }

  const getUsernameFilterSettings = () => {
    if (filterUserName !== null && filterUserName !== undefined && filterUserName.trimLeft().trimRight() !== "") {
      let selectedUNFilterSection: SelectedSettings = {
        title: "UserName Filter",
        rowDisplayValues: [filterUserName],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUNFilterSection;
    }
    return null;
  }

  const getEmailFilterSettings = () => {
    if (filterEmail !== null && filterEmail !== undefined && filterEmail.trimLeft().trimRight() !== "") {
      let selectedEmailFilterSection: SelectedSettings = {
        title: "Email Filter",
        rowDisplayValues: [filterEmail],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedEmailFilterSection;
    }
    return null;
  }

  const getGroupingSelectionSettings = () => {
    let selectedGroupingSection: SelectedSettings = {
      title: "GROUPING OPTION",
      rowDisplayValues: [((filterClassificationID !== null && filterClassificationID !== undefined &&
        filterClassificationID > 0) ? classificationList?.find((item) => item.classificationID === filterClassificationID).text : "None")],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedGroupingSection;
  }
  const getUserStatusFilterSettings = () => {
    let selectedUserStatusFilterSection: SelectedSettings = {
      title: "ACCOUNT STATUS FILTER",
      rowDisplayValues: [filterIncludeInactive ? "Both active and inactive accounts" : "Only active accounts"],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedUserStatusFilterSection;
  }

  //CSR
  const getCSRDateFromSelectionSettings = () => {
    let filterDateFromConverted = getDateInWords(filterDateFrom || '');
    if (reportId === 19) {
      if (filterDateFromConverted !== null && filterDateFromConverted !== undefined &&
        filterDateFromConverted.trimLeft().trimRight() !== "") {
        let selectedCSDateFromSection: SelectedSettings = {
          title: "COURSE STATUS DATE FROM",
          rowDisplayValues: [filterDateFromConverted],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: ""
        };
        return selectedCSDateFromSection;
      }
    }

    if (report === "atriskreport") {
      if (filterDateFromConverted !== null && filterDateFromConverted !== undefined &&
        filterDateFromConverted.trimLeft().trimRight() !== "") {
        let selectedCSDateFromSection: SelectedSettings = {
          title: "DATE FROM",
          rowDisplayValues: [filterDateFromConverted],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: ""
        };
        return selectedCSDateFromSection;
      }
    }
    return null;
  }

  const getCSRDateToSelectionSettings = () => {
    let filterDateToConverted = getDateInWords(filterDateTo || '');
    if (reportId === 19) {
      if (filterDateToConverted !== null && filterDateToConverted !== undefined &&
        filterDateToConverted.trimLeft().trimRight() !== "") {
        // Selected Course Status Date To Filter
        let selectedCSDateToSection: SelectedSettings = {
          title: "COURSE STATUS DATE TO",
          rowDisplayValues: [filterDateToConverted],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: ""
        };
        return selectedCSDateToSection;
      }
    }

    if (report === "atriskreport") {
      if (filterDateToConverted !== null && filterDateToConverted !== undefined &&
        filterDateToConverted.trimLeft().trimRight() !== "") {
        // Selected Course Status Date To Filter
        let selectedCSDateToSection: SelectedSettings = {
          title: "DATE TO",
          rowDisplayValues: [filterDateToConverted],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: ""
        };
        return selectedCSDateToSection;
      }
    }

    return null;
  }

  const getCSRCourseStatusSelectionSettings = () => {
    if (reportId === 19) {
      let error: string = "";
      let isInvalid: boolean = false;
      isInvalid = (filterCourseStatus === "" || filterCourseStatus === null || filterCourseStatus === undefined ||
        (filterCourseStatus.toString() !== "0" && filterCourseStatus.toString() !== "1" &&
          filterCourseStatus.toString() !== "2" && filterCourseStatus.toString() !== "3" &&
          filterCourseStatus.toString() !== "4" && filterCourseStatus.toString() !== "5" && filterCourseStatus.toString() !== "6"));
      if (error === "") {
        error = isInvalid ? "Null/invalid 'Course Status' filter value is given for Course Status Report" : "";
      }
      let selectedCourseStatusFilterSection: SelectedSettings = {
        title: "COURSE STATUS FILTER",
        rowDisplayValues: [(isInvalid ? "???" :
          (filterCourseStatus?.toString() === "0" ? "Incomplete" :
            (filterCourseStatus?.toString() === "1" ? "Complete" :
              (filterCourseStatus?.toString() === "2" ? "Fail" :
                (filterCourseStatus?.toString() === "3" ? "Not Started" :
                  (filterCourseStatus?.toString() === "4" ? "Expired(Time Elapsed)" :
                    (filterCourseStatus?.toString() === "5" ? "Expired(New Content)" : (filterCourseStatus?.toString() === "6" ? "Incomplete(With Details)" : "???"))))))))],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: error
      };
      return selectedCourseStatusFilterSection;
    }
    return null;
  }

  const getDateInWords = (givenDateStr: string) => {
    if (givenDateStr === null || givenDateStr === undefined || givenDateStr.trimLeft().trimRight() === "") {
      return null;
    }
    try {
      var givenDate = new Date(givenDateStr);
      let weekday = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
      let months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
      var dayWord = weekday[givenDate.getDay()];
      var monthWord = months[givenDate.getMonth()];
      return dayWord + ", " + monthWord + " " + givenDate.getDate().toString() + " " + givenDate.getFullYear().toString();
    }
    catch (error: any) {
      return null;
    }
  }
  //CSR

//SummaryReport
 const getSRDateFromSelectionSettings = () => {
    let filterDateFromConverted = getDateInWords(filterDateFrom|| '');
    if (reportId === 22 && filterDateFromConverted !== null && filterDateFromConverted !== undefined &&
      filterDateFromConverted.trimLeft().trimRight() !== "") {
      let selectedSRDateFromSection : SelectedSettings = {
        title: "SUMMARY DATE FROM",
        rowDisplayValues: [filterDateFromConverted],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedSRDateFromSection;
    }
    return null;
  }

  const getSRDateToSelectionSettings = () => {
    let filterDateToConverted = getDateInWords(filterDateTo|| '');
    if (reportId === 22 && filterDateToConverted !== null && filterDateToConverted !== undefined &&
      filterDateToConverted.trimLeft().trimRight() !== "") {
      let selectedSRDateToSection : SelectedSettings = {
        title: "SUMMARY DATE TO",
        rowDisplayValues: [filterDateToConverted],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedSRDateToSection;
    }
    return null;
  }

//SummaryReport

//AtRiskReport
const getAtRiskFailCounterSettings = () => {
  if (report== "atriskreport") {
    let selectedUNFilterSection : SelectedSettings = {
      title: "FAIL COUNTER",
      rowDisplayValues: [failCounter?.toString() || '0'],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedUNFilterSection;
  }
  return null;
}

//AtRiskReport Period


//Warning Report 
  const getWarningPeriodFilterSettings = () => {
    if (filterWarningPeriod !== null && filterWarningPeriod !== undefined && filterWarningPeriod !== 0
      && filterDayType !== null && filterDayType !== undefined && filterDayType.trimLeft().trimRight() !== "") {
      let selectedUNFilterSection : SelectedSettings = {
        title: "WARNING PERIOD FILTER",
        rowDisplayValues: [filterWarningPeriod.toString() + " " + (filterDayType == "dd" ? "Days" : "Months")],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUNFilterSection;
    }
    return null;
  }
//Warning Period Filter

  const clickShowAllCommon = (sectionTitle: string) => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0) {
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => setAllAsDisplayRowCount(ms, sectionTitle));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowLessCommon = (sectionTitle: string) => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0) {
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => resetDisplayRowCount(ms, sectionTitle));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }
  const resetDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined &&
      title.trimLeft().trimRight().toLowerCase() !== "" &&
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      given.currentDisplayRowCount = 3;
    }
    return given;
  }

  const setAllAsDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined &&
      title.trimLeft().trimRight().toLowerCase() !== "" &&
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      if (given.rowDisplayValues !== null && given.rowDisplayValues !== undefined) {
        given.currentDisplayRowCount = given.rowDisplayValues.length;
      }
    }
    return given;
  }

  if (loading) return <LoaderContainer />;
  return (
    <>
      <div style={{ position: "relative", top: "-10px" }}>
        <h2 style={{ color: "#e7a652", width: "500px", marginTop: "4px" }}>{modalPopUpHeading}</h2>
        {
          (modalPopUpSettingsMessages === null ||
            modalPopUpSettingsMessages === undefined ||
            modalPopUpSettingsMessages.length <= 0) ? (
            <p>
              {modalPopUpMessages.map((e, index) => (
                <>
                  <br />
                  <span style={{ fontSize: "16px" }} key={`modalPopupMessages-${index}`}>{e}</span>
                  <br />
                </>
              ))}
            </p>
          )
            :
            (
              <p >
                {modalPopUpMessages.map((e, index) => (
                  <>
                    <div style={{ fontSize: "16px" }} key={`modalmsg-${index}`}>{e}</div>
                    <div key={`modalmsg2-${index}`} style={{ display: "flex" }} >
                      <div className={styles.popupsplitleft}>
                        {
                          modalPopUpSettingsMessages &&
                          modalPopUpSettingsMessages.
                            filter(ssm => modalPopUpSettingsMessages.indexOf(ssm) >= 0 && (modalPopUpSettingsMessages.indexOf(ssm) % 2) === 0).
                            map((eee, index) => (
                              <>
                                <div style={{ margin: "10px" }} key={`modalsettingmsg-${index}`}>
                                  <div style={{ fontSize: "14px", fontWeight: "bold" }}>{eee.title}</div>
                                  {
                                    eee.rowDisplayValues &&
                                    eee.rowDisplayValues.
                                      filter((rd, index) => index < eee.currentDisplayRowCount).
                                      map((ee) => (
                                        <>
                                          <div style={{ fontSize: "14px", color: "#21394F" }}>
                                            {
                                              (ee !== null && ee !== undefined && ee.indexOf("???") >= 0) ?
                                                ee.split("???").map(
                                                  (ees, index) => (
                                                    (ees.trimLeft().trimRight() === "" ?
                                                      <>{<span style={{ color: "red", fontWeight: "bold" }} key={`ees-${index}`}> ??? </span>}</>
                                                      :
                                                      <>{ees}{((ee.split("???").indexOf(ees) !== (ee.split("???").length - 1)) ? <span key={`ees-${index}`} style={{ color: "red", fontWeight: "bold" }}> ??? </span> : "")}</>)
                                                  )
                                                )
                                                :
                                                ((eee.title.toLowerCase().startsWith("units") ||
                                                  eee.title.toLowerCase().startsWith("course") ||
                                                  eee.title.toLowerCase().startsWith("cc")) ?
                                                  <>
                                                    <table style={{ width: "100%" }}>
                                                      <tr>
                                                        <td style={{ width: "1%" }}>•</td>
                                                        <td style={{ width: "95%", textAlign: "left" }}>{ee}</td>
                                                      </tr>
                                                    </table>
                                                  </>
                                                  : ee)
                                            }
                                          </div>
                                        </>
                                      ))
                                  }
                                  {
                                    (eee.rowDisplayValues.length > eee.currentDisplayRowCount)
                                      ?
                                      <>
                                        <div style={{ fontSize: "14px" }}><span onClick={() => clickShowAllCommon(eee.title)} style={{ fontSize: "14px", color: "blue" }}>+ Show All...</span></div>
                                      </>
                                      :
                                      <></>
                                  }
                                  {
                                    (eee.rowDisplayValues.length > 3 && eee.currentDisplayRowCount > 3)
                                      ?
                                      <>
                                        <div style={{ fontSize: "14px" }}><span onClick={() => clickShowLessCommon(eee.title)} style={{ fontSize: "14px", color: "blue" }}>- Show only 3...</span></div>
                                      </>
                                      :
                                      <></>
                                  }
                                </div>
                                <br />
                              </>
                            ))
                        }
                      </div>
                      <div className={styles.popupsplitright}>
                        {
                          modalPopUpSettingsMessages &&
                          modalPopUpSettingsMessages.
                            filter(ssm => modalPopUpSettingsMessages.indexOf(ssm) >= 0 && (modalPopUpSettingsMessages.indexOf(ssm) % 2) !== 0).
                            map((eee) => (
                              <>
                                <div style={{ margin: "10px" }}>
                                  <div style={{ fontSize: "14px", fontWeight: "bold" }}>{eee.title}</div>
                                  {
                                    eee.rowDisplayValues &&
                                    eee.rowDisplayValues.
                                      filter((rd, index) => index < eee.currentDisplayRowCount).
                                      map((ee) => (
                                        <>
                                          <div style={{ fontSize: "14px", color: "#21394F" }}>
                                            {
                                              (ee !== null && ee !== undefined && ee.indexOf("???") >= 0) ?
                                                ee.split("???").map(
                                                  (ees, i) => (
                                                    (ees.trimLeft().trimRight() === "" ?
                                                      <>{<span style={{ color: "red", fontWeight: "bold" }} key={`ees-${i}`}> ??? </span>}</>
                                                      :
                                                      <>{ees}{((ee.split("???").indexOf(ees) !== (ee.split("???").length - 1)) ? <span style={{ color: "red", fontWeight: "bold" }} key={`ees-${i}`}> ??? </span> : "")}</>)
                                                  )
                                                )
                                                :
                                                ((eee.title.toLowerCase().startsWith("units") ||
                                                  eee.title.toLowerCase().startsWith("course") ||
                                                  eee.title.toLowerCase().startsWith("cc")) ?
                                                  <>
                                                    <table style={{ width: "100%" }}>
                                                      <tr>
                                                        <td style={{ width: "1%" }}>•</td>
                                                        <td style={{ width: "95%", textAlign: "left" }}>{ee}</td>
                                                      </tr>
                                                    </table>
                                                  </>
                                                  : ee)
                                            }
                                          </div>
                                        </>
                                      ))
                                  }
                                  {/* {                                  
                                  (eee.rowDisplayValues.length > eee.currentDisplayRowCount) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={clickShowMoreCourses} style={{fontSize: "14px", color: "blue"}}>+ Show 10 more...</span></div>
                                  </>
                                  :
                                  <></>
                                }
                                {                                  
                                  (eee.rowDisplayValues.length > 10 && eee.currentDisplayRowCount > 10) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={clickShowLessCourses} style={{fontSize: "14px", color: "blue"}}>- Show 10 less...</span></div>
                                  </>
                                  :
                                  <></>
                                } */}
                                  {
                                    (eee.rowDisplayValues.length > eee.currentDisplayRowCount)
                                      ?
                                      <>
                                        <div style={{ fontSize: "14px" }}><span onClick={() => clickShowAllCommon(eee.title)} style={{ fontSize: "14px", color: "blue" }}>+ Show All...</span></div>
                                      </>
                                      :
                                      <></>
                                  }
                                  {
                                    (eee.rowDisplayValues.length > 3 && eee.currentDisplayRowCount > 3)
                                      ?
                                      <>
                                        <div style={{ fontSize: "14px" }}><span onClick={() => clickShowLessCommon(eee.title)} style={{ fontSize: "14px", color: "blue" }}>- Show only 3...</span></div>
                                      </>
                                      :
                                      <></>
                                  }
                                </div>
                                <br />
                              </>
                            ))
                        }
                      </div>
                    </div>
                  </>
                ))}
              </p>
            )
        }
      </div>
    </>
  );
};
export default ReportsParametersPreview;
