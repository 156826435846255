import React, { useState, useEffect, useContext } from "react";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";

import { useHistory } from "react-router";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import BrandedCard from "../../../Components/BrandedCard";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { Button,   Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@material-ui/core";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import getColor from "../../../functions/getColor";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon"; 
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import GetOrgAssignedCourseList from "../../../API/Administration/CourseSettings/GetOrgAssignedCourseList";
import { AssignedCourseList } from "../../../globalTypes";
import ActiveIcon from "../../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../../CustomIcons/Status_InActiveIcon";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from '@material-ui/core';
import ToggleRightIcon from "../../../CustomIcons/ToggleRightIcon";
import ToggleLeftIcon from "../../../CustomIcons/ToggleLeftIcon";
import AddIconMenu from "../../../CustomIcons/NavigationMenu/AddIconMenu";

const CourseDetails = () => {
  const history = useHistory();
  const {
    translations: { allResourcesToTranslations }, pageTitles
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute,superAdminSelectedBasicOrgData } =
    useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const {
      profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
      ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
      : "";
  const [loading, setLoading] = useState<boolean>(true);
  const { theme: { color } } = useContext(CorperateThemeContext);
  const [courseListResult, setCourseListResult] = useState<Array<AssignedCourseList>>([]);



  const [currentSortBy, setCurrentSortBy] = React.useState<string>("");
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false)
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [nameDescending, setNameDescending] = React.useState<boolean>(false);
  const [dateCreatedDescending, setDateCreatedDescending] = React.useState<boolean>(false);  
  const [dateUpdatedDescending, setDateUpdatedDescending] = React.useState<boolean>(false);  
  const [activeDescending, setActiveDescending] = React.useState<boolean>(false);
  const [filterCourseOrModuleName, setFilterCourseOrModuleName] = React.useState("");
   const [includeInactive, setIncludeInactive] = React.useState<boolean>(false);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);  

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element:  ".addNewCourse",
        title: "Add New", intro: allResourcesToTranslations.uploadnewcourseguideme 
      }
    ]
});


const setGuideMe = () => {
  let updateSteps = [...intro.steps];
      updateSteps.push(        
      {
        element:  ".courseeSearch",
        title: allResourcesToTranslations.searchcourse, 
        intro: allResourcesToTranslations.searchcourseguideme
      },
      
      {
        element:  ".ShowActiveInactive",
        title: allResourcesToTranslations.showactiveinactivecourse, 
        intro: allResourcesToTranslations.showactiveinactivecourseguideme  

      },
      {
        element:  ".namecolumnguideme",
        title: allResourcesToTranslations.coursename, intro: allResourcesToTranslations.coursenameguideme
      },      
      {
        element:  ".datecreatedguideme",
        title: allResourcesToTranslations.emrepgmdtcreate, intro: allResourcesToTranslations.coursedatecreatedguideme
      },
      {
        element:  ".dateupdatedguideme",
        title: allResourcesToTranslations.modifiedby, intro: allResourcesToTranslations.dateupdatedguideme
      },
      {
        element:  ".activeguideme",
        title: allResourcesToTranslations.active, intro: allResourcesToTranslations.courseactiveguideme
      },
      {
        element:  ".actionguideme",
        title: allResourcesToTranslations.action, intro: allResourcesToTranslations.courseactionguideme
      }
    );
  
  
  setIntro({ ...intro, steps: [...updateSteps] });
  };  
  const handleClosePopup = () => {
		setShowWarningModal(false);
	};
 
 const onExit = () => {
 setIntro({ ...intro, stepsEnabled: false });
 };
  
  useEffect(() => {
    setTitle(allResourcesToTranslations.publishcourse);
		setTitleEnglish(pageTitles.courselist);
    updateBackButtonRoute("");   
    LoadCourseDetailsList();
  }, []);

	useEffect(() => {
    setTitle(allResourcesToTranslations.publishcourse);
		setTitleEnglish(pageTitles.publishcourse);
    updateBackButtonRoute("");   
    LoadCourseDetailsList();
  }, [superAdminSelectedBasicOrgData]);

  useEffect(() => {   

    let curCourseListResult = courseListResult

    if(filterCourseOrModuleName!= null && filterCourseOrModuleName!="")
    {
        let courseData = curCourseListResult.filter(
        (item:any) => item.name.toString().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase()));
        setCourseListResult(courseData);
    }
    else
    {
      LoadCourseDetailsList();
    }

  },[filterCourseOrModuleName])

  useEffect(() => {   
      LoadCourseDetailsList();   
  },[includeInactive])
  
  const LoadCourseDetailsList = () => {
    GetOrgAssignedCourseList(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      includeInactive,
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),        
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result:any) => {     
        if(result !== undefined && result  != null) { 
        if (result.isSuccess && result.error == null) {
          setCourseListResult([...result.orgAssignedCourseListItems]);             
      
          setLoading(false);
          setGuideMe();    
        } else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage('Getting Course list has failed! Please try again!');
        }
        }
      });
  }

  const handleEdit = (courseIdToEdit: number) => { 
    history.push({
      pathname: `addcourse/`+ courseIdToEdit
  });
};

  const handleAddNewCourse = () => {
    history.push({
      pathname: `addcourse` 
  });
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const sortResultsByAsync = async (sortBy: 
    "Name" 
  | "DateCreated"
  | "DateUpdated"
  | "Active" , sortGivenItemInDesc: boolean) => {
    if (courseListResult == null || courseListResult.length <= 0) {
      return courseListResult;
    }
    var result = courseListResult.slice(0)
    setCurrentSortBy(sortBy)
    switch (sortBy) {
  
      case "Name":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.name === null) { return -1; }
            if (a === null || a.name === null) { return 1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setNameDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.name === null) { return 1; }
            if (a === null || a.name === null) { return -1; }
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setNameDescending(true)
          }
        }
        setCourseListResult(result);
        break;
        case "DateCreated":
          if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                  if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return -1; }
                  if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return 1; }
                  var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                  var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                  if(dta > dtb) { return -1; }
                  if(dta < dtb) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setDateCreatedDescending(false)
              }
          } else {
              result.sort((a, b) => {
                  if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return 1; }
                  if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return -1; }
                  var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                  var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                  if(dta > dtb) { return 1; }
                  if(dta < dtb) { return -1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setDateCreatedDescending(true)
              }
          }
          setCourseListResult(result);
          break;

        case "DateUpdated":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.dateUpdated === null || b.dateUpdated.trim() === "") { return -1; }
                        if (a === null || a.dateUpdated === null || a.dateUpdated.trim() === "") { return 1; }
                        var dta = new Date(a.dateUpdated.split("/").reverse().join("-"))
                        var dtb = new Date(b.dateUpdated.split("/").reverse().join("-"))
                        if(dta > dtb) { return -1; }
                        if(dta < dtb) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                      setDateUpdatedDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.dateUpdated === null || b.dateUpdated.trim() === "") { return 1; }
                        if (a === null || a.dateUpdated === null || a.dateUpdated.trim() === "") { return -1; }
                        var dta = new Date(a.dateUpdated.split("/").reverse().join("-"))
                        var dtb = new Date(b.dateUpdated.split("/").reverse().join("-"))
                        if(dta > dtb) { return 1; }
                        if(dta < dtb) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                      setDateUpdatedDescending(true)
                    }
                }
                setCourseListResult(result);
                break;     
      
      case "Active":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.active === null) { return -1; }
            if (a === null || a.active === null) { return 1; }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) { return -1; }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setActiveDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.active === null) { return 1; }
            if (a === null || a.active === null) { return -1; }
            if (a.active.toString().toLowerCase() < b.active.toString().toLowerCase()) { return -1; }
            if (a.active.toString().toLowerCase() > b.active.toString().toLowerCase()) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setActiveDescending(true)
          }
        }
        setCourseListResult(result);
      break;
         
      default:
        break;
    }
    return result.slice(0);
  }
  
  const sortResultsBy = async (sortBy:   "Name" 
  | "DateCreated"
  | "DateUpdated"
  | "Active", sortGivenItemInDesc: boolean) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy)
      setCourseListResult(result)
      setPage(0)
      setSortInProgress(false);
    });
  }
  

  const clickNameAsc = () => {
    setReverseNextSort(false);
    setNameDescending(false);
    sortResultsBy("Name", false);
  }
  
  const clickNameDesc = () => {
    setReverseNextSort(false);
    setNameDescending(true)
    sortResultsBy("Name", true)
  }

  const clickActiveAsc = () => {
    setReverseNextSort(false);
    setActiveDescending(false);
    sortResultsBy("Active", false);
  }
  
  const clickActiveDesc = () => {
    setReverseNextSort(false);
    setActiveDescending(true)
    sortResultsBy("Active", true)
  }
  const clickDateCreatedAsc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(false);
    sortResultsBy("DateCreated", false);
  }
  
  const clickDateCreatedDesc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(true)
    sortResultsBy("DateCreated", true)
  }
  
  const clickDateUpdatedAsc = () => {
    setReverseNextSort(false);
    setDateUpdatedDescending(false);
    sortResultsBy("DateUpdated", false);
  }
  
  const clickDateUpdatedDesc = () => {
    setReverseNextSort(false);
    setDateUpdatedDescending(true)
    sortResultsBy("DateUpdated", true)
  }
  
  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" }
    } else {
      return {}
    }
  }

  const handleConfirmModal = (value: boolean) => {
		setshowConfirmModal(value);
	}
  
  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return ((parseInt(day) < 10 ? "0" + day.toString() : day.toString()) + "/" + (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "/" + year.toString());
      }
      else {
          return "";
      }
  }

 

  if (loading) return <LoaderContainer />;  
  return ( 
   <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}/>
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>   

      <UnbrandedCard>
      <div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>             
            <div style={{ width: '100%' }} >
              <div style={{float:"left"}}>
                <CustomTooltip displayText={[allResourcesToTranslations.uploadnewcourse]}>               
                    <Button                    
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={`saveData addNewCourse`}
                        onClick={handleAddNewCourse}                     
                      >
                        <div style={{marginBottom: "-5px"}}>
                          <AddIconMenu/>
                        </div><span style={{marginLeft: "5px"}}> {allResourcesToTranslations.uploadnewcourse}</span>
                      </Button>                     
                </CustomTooltip>
              </div>  
              </div>
            <div style={{ width: '100%', clear: 'both' }} >
              <div style={{ paddingTop: "10px" }}>
                <p className={styles.blankContent}>Leave blank for all Courses</p>
              </div>
              <div  className={`${styles.courseModuleNameSearch} courseeSearch`} id="courseModuleNameSearch"  style={{ paddingTop: "10px" }}>
                <TextField style={{ width: "100%" }}
                  label={"Course Name Search"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={filterCourseOrModuleName} onChange={(e) => setFilterCourseOrModuleName(e.target.value)}
                  InputProps={{ endAdornment: (<IconButton><SearchIcon style={{ height: "20px" }} /></IconButton>) }} />
              </div>
              <div style={{ paddingTop: "5px", paddingLeft: "5px" }} >
                <div className='ShowActiveInactive'>
                  <div onClick={() => setIncludeInactive(!includeInactive)}>
                    <span style={{ marginTop: "-3px", paddingRight: "5px", float: "left" }} >{includeInactive ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
                  </div>
                  <div className={styles.inputContainer}>
                    <span id="allowLearnerswitch" style={{ fontSize: "12px" }}  className={styles.labelText} onClick={() => setIncludeInactive(!includeInactive)}>
                      {allResourcesToTranslations.showinactivecourse}</span>
                  </div>
                </div>
              </div>
            </div>           
            <div style={{ width: '100%', clear:"both" }} >
            {courseListResult != null && courseListResult.length > 0 && (
              <div>              

                <div className={styles.paginationControlsContainer}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 100]}
                      component="div"
                      count={courseListResult.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
                <div  className={`${styles.tableSection}`}>
                  <div className={styles.tableContainer}>
                    <BrandedCard>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>                              

                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                  borderRadius: "8px 0 0 0",
                                }}   className="namecolumnguideme"
                                align="left"
                              >
                                <div className={styles.tableHeadCellContainer}>
                                  <div
                                    onClick={() => {
                                      setNameDescending(!nameDescending);
                                      sortResultsBy("Name", !nameDescending);
                                    }}
                                  >
                                  {allResourcesToTranslations.name}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "DateCreated" &&
                                          nameDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickNameAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "Name" &&
                                         nameDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickNameDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                }} className="datecreatedguideme"
                                align="center"
                              >
                               
                               <div className={styles.tableHeadCellContainer}>
                                  <div
                                    onClick={() => {
                                      setNameDescending(!nameDescending);
                                      sortResultsBy("Name", !nameDescending);
                                    }}
                                  >
                                  {allResourcesToTranslations.emrepgmdtcreate}
                                  </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "DateCreated" &&
                                          dateCreatedDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickDateCreatedAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "DateCreated" &&
                                          dateCreatedDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickDateCreatedDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                                </div>
                              </TableCell>                             
                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,                                  
                                }} className="dateupdatedguideme"  
                                align="center"                              
                              >
                              <div className={styles.tableHeadCellContainer}>
                                <div
                                  onClick={() => {
                                    setNameDescending(!nameDescending);
                                    sortResultsBy("Name", !nameDescending);
                                  }}
                                >
                                {allResourcesToTranslations.modifiedby}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "DateCreated" &&
                                          dateUpdatedDescending === false
                                          ? "100%"
                                          : "50%",
                                      margin: "2px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickDateUpdatedAsc}
                                  >
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span
                                    style={{
                                      opacity:
                                        currentSortBy === "DateUpdated" &&
                                          dateUpdatedDescending === true
                                          ? "100%"
                                          : "50%",
                                      margin: "0px",
                                      height: "6px",
                                      display: "inline-flex",
                                    }}
                                    onClick={clickDateUpdatedDesc}
                                  >
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                                </div>
                              </TableCell>
                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                }} className="activeguideme"
                                align="left"
                              >
                                <div className={styles.tableHeadCellContainer}>
                                <div
                                    onClick={() => {
                                      setActiveDescending(!activeDescending);
                                      sortResultsBy("Active", !activeDescending);
                                    }}
                                  >
                                  {allResourcesToTranslations.active}
                                  </div>
                                  <div className={styles.columnHeadArea}>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "Active" &&
                                          activeDescending === false
                                            ? "100%"
                                            : "50%",
                                        margin: "2px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickActiveAsc}
                                    >
                                      <UpArrowIcon />{" "}
                                    </span>
                                    <span
                                      style={{
                                        opacity:
                                          currentSortBy === "Active" &&
                                          activeDescending === true
                                            ? "100%"
                                            : "50%",
                                        margin: "0px",
                                        height: "6px",
                                        display: "inline-flex",
                                      }}
                                      onClick={clickActiveDesc}
                                    >
                                      <DownArrowIcon />{" "}
                                    </span>
                                  </div>
                             
                                </div>
                              </TableCell>
                             
                              <TableCell
                                style={{                                
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                  borderRadius: "0 8px 0 0",
                                }}className="actionguideme"
                                align="center"
                              >
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                  { allResourcesToTranslations.actions}                                  
                                  </div>                              
                                </div>
                              </TableCell>
                             
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {courseListResult
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((result: any, i) => {
                                return (
                                  <TableRow
                                    key={`policybuilder-${i}`}
                                    style={rowBackgroundColor(i)}
                                  >
                                    <TableCell align="left" width={"40%"}>
                                      {result.name}
                                    </TableCell>
                                    <TableCell align="center">                                      
                                     {formatDate(new Date(result.dateCreated))}
                                    </TableCell>
                                    <TableCell align="center" >
                                      {result.updatedByName} {result.dateUpdated  === null ? "" : formatDate(new Date(result.dateUpdated))}
                                    </TableCell>
                                    <TableCell align="center">
                                    <div style={{clear:'both', width:'100%'}}>
                                      <div style={{float:'left', width:'8%'}}>{result.active ?
                                        <CustomTooltip displayText={["Active"]}>
                                        <span><ActiveIcon></ActiveIcon></span>
                                        </CustomTooltip>  
                                      : 
                                        <CustomTooltip displayText={["Inactive"]}>
                                        <span><InActiveIcon></InActiveIcon></span>
                                        </CustomTooltip>
                                        
                                        }</div>
                                        <div style={{float:'right', width:'92%', textAlign:'left'}}> &nbsp;&nbsp;{result.active? "Active" : "Inactive"}</div>
                                        </div>
                                    </TableCell>                                  
                                    <TableCell align="center">                                      
                                      <CustomTooltip displayText={["Edit"]}>                                       
                                      {!!result.courseOrganisationId && result.courseOrganisationId > 0 ?
                                            <Button onClick={() => handleEdit(result.courseID)} className={`${styles.moduleListButton} actioncolumnguideme${i}`} variant="contained" color="primary">
                                                <EditIcon />
                                            </Button>
                                          :<></>
                                      }
                                      </CustomTooltip>
                                    </TableCell>                                    
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </BrandedCard>
                  </div>
                </div>
                <div className={styles.paginationAddedUsersControl}>
                  <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20, 100]}
                      component="div"
                      count={courseListResult.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </div>               
              </div>
            )}
          </div>
          
        </div>
      </div>
      </UnbrandedCard>
    
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}      
      </>
  );
};

export default CourseDetails;
