import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import BrandedCard from "../../../Components/BrandedCard";
import SearchIcon from "@material-ui/icons/Search";
import UnSelectAllIcon from "../../../CustomIcons/UnSelectAllIcon";
import { Accordion, AccordionSummary, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, Radio, RadioGroup, Select, TextField, Typography, withWidth } from "@material-ui/core";
import CloseWhiteIcon from "@material-ui/icons/Close";
import SelectAllIcon from "../../../CustomIcons/SelectAllIcon";
import CustomTooltip from "../../../Components/CustomTooltip";
import { CourseAccessItem, CourseModuleAccessChanges, ModuleAccessChanges, ModuleAccessItem, UnitCourseAccessItem, UnitModuleAccessItem, UnitTreeItem } from "../../../globalTypes";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import getUnitAllCourseModulesAccess from "../../../API/Administration/Unit/GetUnitAllCourseModulesAccess";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useHistory, useParams } from "react-router";
import LoaderContainer from "../../../Components/LoaderContainer";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import SaveOrgComplianceDetails from "../../../API/Administration/Organisation/SaveOrgComplianceDetails";
import Modal from "../../../Components/Modal";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { Steps } from "intro.js-react";
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import { Units } from "../../ReportCommonComponents/Units";
import getRelevantUnitTree from "../../../API/GetRelevantUnitTree";
import GetOrgComplianceDetails from "../../../API/Administration/Organisation/GetOrgComplianceDetails";
import SaveOrgCourseBasedComplianceRules from "../../../API/Administration/Organisation/SaveOrgCourseBasedComplianceRules";
import CustomisedDatePicker from "../../../Components/CustomisedDatePicker";
import CourseProgressBar from "./CourseProgressBar";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import NotesIcon from "../../../CustomIcons/NotesIcon";
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import { ToggleLeftIcon } from '../../../CustomIcons/ToggleLeftIcon';

const CourseComplianceRules = (props: any) => {
  const { courseIdToEdit } = useParams<{ courseIdToEdit: string }>();

  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
  const { setTitle,setMenuSelection, setTitleEnglish, updateBackButtonRoute, IsEbookEnabled, EnableActivityLogs, timeZoneId } = useContext(UIContext);
  const [filterCourseOrModuleName, setFilterCourseOrModuleName] = React.useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [courseList, setCourseList] = useState<Array<UnitCourseAccessItem>>([]);
  const [courseListOriginal, setCourseListOriginal] = useState<Array<UnitCourseAccessItem>>([]);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website, },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const history = useHistory();
  const isMounted = useRef<boolean>(false);
  const [strdefaultquizpassmark, setStrDefaultquizpassmark] = useState<string>('');
  const [defaultquizpassmark, setDefaultquizpassmark] = useState<number>();
  const [expireSetting, setExpireSetting] = useState<number>(0);
  const [expireLessonafter, setExpireLessonafter] = useState<number>(0);
  const [expireQuizafter, setExpireQuizafter] = useState<number>(0);
  const [customisedLessonYears, setCustomisedLessonYears] = useState<number>(0);
  const [customisedQuizYears, setCustomisedQuizYears] = useState<number>(0);
  const [expireLessondate, setExpireLessondate] = useState("");
  const [expireQuizdate, setExpireQuizdate] = useState("");
  const [applyLessonOverriding, setApplyLessonOverriding] = useState<boolean>(false);
  const [applyQuizOverriding, setApplyQuizOverriding] = useState<boolean>(false);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);
  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [yearlyIncreamentLesson, setYearlyIncreamentLesson] = useState<number>(1);
  const [yearlyIncreamentQuiz, setYearlyIncreamentQuiz] = useState<number>(1);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [filterUnitName, setFilterUnitName] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [includeOptional, setIncludeOptional] = React.useState<boolean>(false);

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".inactiveoptional",
        title: "Enable  Compliance Rules ",
        intro: "Check to Enable  Compliance Rules.",
      },

    ]
  })

  const setGuideMe = (includeOptional: boolean = false) => {
    let updateSteps = []

    if (includeOptional) {
      updateSteps.push(
        {
          element: "#courseModuleNameSearch",
          title: "Filter",
          intro: allResourcesToTranslations.coursemodulenamesearch,
        },
        {
          element: ".selectAllButton",
          title: allResourcesToTranslations.selectall,
          intro: allResourcesToTranslations.selectallbuttoncoursemodule,
        },
        {
          element: ".unselectAllButton",
          title: allResourcesToTranslations.unselectall,
          intro: allResourcesToTranslations.unselectallbuttoncoursemodule,
        },
        {
          element: ".modulelist",
          title: "Modules",
          intro: allResourcesToTranslations.modulelistguide,
        },
        {
          element: ".defaultquizpassmark",
          title: allResourcesToTranslations.defaultquizpassmark,
          intro: allResourcesToTranslations.coursebaseddefaultquizpassmarkguideme,
        },
        {
          element: ".expiregiventime",
          title: allResourcesToTranslations.expirysettings,
          intro: allResourcesToTranslations.cbexpiregiventime,
        },
        {
          element: ".expirelesson",
          title: allResourcesToTranslations.expirelesson,
          intro: allResourcesToTranslations.cbexpirelessonguideme,
        },
        {
          element: ".expirequiz",
          title: allResourcesToTranslations.expirequiz,
          intro: allResourcesToTranslations.expirequizguideme,
        },
        {
          element: ".expireongivendate",
          title: allResourcesToTranslations.expirysettings,
          intro: allResourcesToTranslations.cbexpireongivendate,
        },
        {
          element: ".expireLessondate",
          title: allResourcesToTranslations.expirelessondate,
          intro: allResourcesToTranslations.cbexpirelessondateguideme,
        },
        {
          element: ".lessondateyearlyincrement",
          title: allResourcesToTranslations.yearlyincrement,
          intro: allResourcesToTranslations.lessonyearincrement,
        },
        {
          element: ".expireQuizdate",
          title: allResourcesToTranslations.expirequizdate,
          intro: allResourcesToTranslations.cbexpirequizdateguideme,
        },
        {
          element: ".quizdateyearlyincrement",
          title: allResourcesToTranslations.yearlyincrement,
          intro: allResourcesToTranslations.quizyearincrement,
        },
        {
          element: ".enableonetime",
          title: allResourcesToTranslations.enableonetime,
          intro: allResourcesToTranslations.cbonetimecompletionguideme,
        },
        {
          element: ".selectallunit",
          title: "Select All",
          intro: allResourcesToTranslations.selectallunits,
        },
        {
          element: ".deselectallunit",
          title: "Unselect All",
          intro: allResourcesToTranslations.deselectallunits,
        },
        {
          element: ".expandallunit",
          title: "Expand All",
          intro: allResourcesToTranslations.cbexpandallunit,
        },
        {
          element: ".collapseallunit",
          title: "Collapse All",
          intro: allResourcesToTranslations.cbcollapseallunit,
        },
        {
          element: ".unitFilterSearch",
          title: "Unit Filter",
          intro: "Search unit/subunits by typing unit name.",
        },
        {
          element: ".unittree",
          title: "Units",
          intro: allResourcesToTranslations.cbunittree,
        },
        {
          element: ".saveData",
          title: "Save Details",
          intro: allResourcesToTranslations.saveglobalcompliancerulesguideme
        },
        {
          element: ".nextData",
          title: "Move to Next",
          intro: allResourcesToTranslations.coursenext
        });
    }
    else {
      updateSteps.push(
        {
          element: ".inactiveoptional",
          title: "Enable  Compliance Rules ",
          intro: "Check to Enable  Compliance Rules.",
        });
    }
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.publishcourse + " - " + allResourcesToTranslations.step5);
    setTitleEnglish(pageTitles.publishcourse);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.publishcourse);
    isMounted.current = true;
    setLoading(false);
    GetOrgComplianceDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result) => {
      if (result.isSuccess && result.error == '') {
        if (result.orgComplianceDetails !== null) {
          setDefaultquizpassmark(result.orgComplianceDetails.defaultQuizMark);
          setStrDefaultquizpassmark(result.orgComplianceDetails.defaultQuizMark);
          setExpireLessonafter(result.orgComplianceDetails.defaultLessonFrequency);
          setExpireQuizafter(result.orgComplianceDetails.defaultQuizFrequency);
          setYearlyIncreamentLesson(result.orgComplianceDetails.yearlyIncreamentLesson);
          setYearlyIncreamentQuiz(result.orgComplianceDetails.yearlyIncreamentQuiz);

          setExpireLessondate(result.orgComplianceDetails.defaultLessonCompletionDate !== null ? formatDate(new Date(result.orgComplianceDetails.defaultLessonCompletionDate)) : "");
          setExpireQuizdate(result.orgComplianceDetails.defaultQuizCompletionDate !== null ? formatDate(new Date(result.orgComplianceDetails.defaultQuizCompletionDate)) : "");
          //setExpireQuizdate(result.orgComplianceDetails.defaultQuizCompletionDate);

          if (result.orgComplianceDetails.enableOneTimeCourseComplition) {
            setExpireSetting(2);
          }
          else {
            if (result.orgComplianceDetails.defaultQuizFrequency !== 0 && result.orgComplianceDetails.defaultLessonFrequency !== 0 && result.orgComplianceDetails.defaultLessonCompletionDate === null && result.orgComplianceDetails.defaultQuizCompletionDate === null) {
              setExpireSetting(0);
            }
            else if (result.orgComplianceDetails.defaultQuizFrequency === 0 && result.orgComplianceDetails.defaultLessonFrequency === 0 && result.orgComplianceDetails.defaultLessonCompletionDate !== null && result.orgComplianceDetails.defaultQuizCompletionDate !== null) {
              setExpireSetting(1);
            }
          }
        }
      }
      else {
        setShowModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load Compliance Rules details!");
      }
    });
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);
        refreshCourseModuleList(true);

      }
    });
    // at the end, return a cleanup method
    return () => {
      isMounted.current = false
    }
  }, []);

  // Only the filtered courses are selected or unselected
  const handleAllCoursesForGivenSelection = (givenFlag: boolean) => {

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch ||
        (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 &&
          item.unitModuleAccessItems.some((m: UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
        return {
          ...item
        }
      }
      if (!!item && !!item.courseID) {
        item.areAllModulesGranted = givenFlag;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          return {
            ...elem,
            isUnitGranted: item.areAllModulesGranted
          }
        }
        );
        return {
          ...item,
          isAnyUnitGranted: (!!item.unitModuleAccessItems &&
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length > 0)) ? true : false
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);

  }

  // Set the filter matched courses/modules 
  const setFilteredCoursesOnly = (givenCourseList: Array<UnitCourseAccessItem>) => {
    const filteredCourseList = givenCourseList.map((item: UnitCourseAccessItem) => {
      if (!!item && !!item.courseID) {
        if (!!item.courseName && (!!filterCourseOrModuleName !== true ||
          (!!filterCourseOrModuleName && item.courseName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {

          return {
            ...item,
            isSearchMatch: true
          }
        }
        if (!!item.unitModuleAccessItems) {
          item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
            if (!!elem.moduleName && (!!filterCourseOrModuleName !== true ||
              (!!filterCourseOrModuleName && elem.moduleName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {

              return {
                ...elem,
                isSearchMatch: true
              }
            }
            else {
              return {
                ...elem,
                isSearchMatch: false
              }
            }
          });
        }
        return {
          ...item,
          isSearchMatch: false
        }
      }
      return item;
    });

    setCourseList([...filteredCourseList]);

  }

  const refreshCourseModuleList = (resetLoading: boolean) => {
    getUnitAllCourseModulesAccess(oidcUser.access_token, userId, orgDomain, parseInt(orgId), 0).then((courseListRes) => {
      if (isMounted.current) {
        if (!!courseListRes && !!courseListRes.isSuccess && courseListRes.isSuccess && !!courseListRes.unitCourseAccessItems) {
          let currCourseList = courseListRes.unitCourseAccessItems.filter((item: any) => item.courseID === parseInt(courseIdToEdit));
          let unselectAllCoursesinList: any = unselectAllCoursesinGivenList(currCourseList);
          setCourseList(unselectAllCoursesinList);
          setFilteredCoursesOnly(unselectAllCoursesinList);
          cloneGivenListAndSetOriginalCourseList(unselectAllCoursesinList);
        }
      }
      if (resetLoading) {
        setLoading(false);
      }
    }).catch((error) => {
      if (resetLoading) {
        setLoading(false);
      }
    })
  }

  const checkIfBothModuleListsAreInvalid = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges) => {
    // Check if both the modules are invalid
    if ((modules1 == null || modules1 == undefined) && (modules2 == null || modules2 == undefined)) {
      moduleAccessChanges.reason = "Both of the given two Module lists are invalid";
      moduleAccessChanges.isAnyChanged = false;
      moduleAccessChanges.grantedModuleIDs = "";
      moduleAccessChanges.differedModuleIDs = "";
      return true;
    }
    return false;
  }

  const checkIfAnyModuleListIsInvalid = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges) => {
    // Check if any module list is invalid compared to other
    if (!!modules1 && modules1.length > 0 && (modules2 == null || modules2 == undefined)) {
      moduleAccessChanges.reason = "Second of the given two Module lists is invalid";
      moduleAccessChanges.isAnyChanged = true;
      moduleAccessChanges.grantedModuleIDs =
        modules1.filter(m => m.isUnitGranted).map(m => m.moduleID.toString()).toString();
      moduleAccessChanges.differedModuleIDs =
        modules1.map(m => m.moduleID.toString()).toString();
      return true;
    }
    // Check if any module list is invalid compared to other
    if (!!modules2 && (modules1 == null || modules1 == undefined)) {
      moduleAccessChanges.reason = "First of the given two Module lists is invalid";
      moduleAccessChanges.isAnyChanged = true;
      moduleAccessChanges.grantedModuleIDs =
        modules2.filter(m => m.isUnitGranted).map(m => m.moduleID.toString()).toString();
      moduleAccessChanges.differedModuleIDs =
        modules2.map(m => m.moduleID.toString()).toString();
      return true;
    }
    return false;
  }

  // Compare the modules and collect
  const collectModuleAccessChanges = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[]) => {
    let moduleAccessChanges: ModuleAccessChanges = {
      isAnyChanged: false,
      grantedModuleIDs: "",
      differedModuleIDs: "",
      reason: ""
    };
    if (checkIfBothModuleListsAreInvalid(modules1, modules2, moduleAccessChanges)) {
      return moduleAccessChanges;
    }
    if (checkIfAnyModuleListIsInvalid(modules1, modules2, moduleAccessChanges)) {
      return moduleAccessChanges;
    }
    moduleAccessChanges = collectDifferedModules(modules1, modules2, moduleAccessChanges, false);
    moduleAccessChanges = collectDifferedModules(modules2, modules1, moduleAccessChanges, true);
    return moduleAccessChanges;
  }

  // Compare the courses and their modules and collect the access changes
  const collectCourseModuleAccessChanges = () => {

    let courseAccessChanges: CourseModuleAccessChanges = {
      accessChangedCourseIDs: "",
      isAnyChanged: false,
      grantedModuleIDs: "",
      differedModuleIDs: "",
      reason: ""
    };
    // Collect course and module access changes
    for (let i = 0; i < courseListOriginal.length; i++) {
      let curCourseOriginal = courseListOriginal[i];
      let curModuleAccessChanges: ModuleAccessChanges = {
        isAnyChanged: false,
        grantedModuleIDs: "",
        differedModuleIDs: "",
        reason: ""
      };
      let courseMatched = courseList.filter((curCourse) => !!curCourse && !!curCourse.courseID && curCourse.courseID == curCourseOriginal.courseID);

      if (!!courseMatched && courseMatched.length > 0 &&
        (((curModuleAccessChanges = collectModuleAccessChanges(curCourseOriginal.unitModuleAccessItems, courseMatched[0].unitModuleAccessItems)) != null &&
          curModuleAccessChanges != undefined &&
          curModuleAccessChanges.isAnyChanged
        ))) {
        let curCourseIDTxt = curCourseOriginal.courseID.toString();
        courseAccessChanges.isAnyChanged = true;
        courseAccessChanges.accessChangedCourseIDs = (courseAccessChanges.accessChangedCourseIDs.trim() === "") ?
          curCourseIDTxt : courseAccessChanges.accessChangedCourseIDs + "," + curCourseIDTxt;
        courseAccessChanges.grantedModuleIDs = (courseAccessChanges.grantedModuleIDs.trim() === "") ?
          curModuleAccessChanges.grantedModuleIDs : courseAccessChanges.grantedModuleIDs + "," + curModuleAccessChanges.grantedModuleIDs;
        courseAccessChanges.differedModuleIDs = (courseAccessChanges.differedModuleIDs.trim() === "") ?
          curModuleAccessChanges.differedModuleIDs : courseAccessChanges.differedModuleIDs + "," + curModuleAccessChanges.differedModuleIDs;
      }
    }
    return courseAccessChanges;
  }

  const unselectAllCoursesinGivenList = (givenCourseList: Array<UnitCourseAccessItem>) => {
    if (!!givenCourseList !== true) {
      return givenCourseList;
    }
    let unselectAllCoursesinList: Array<UnitCourseAccessItem> = [];
    unselectAllCoursesinList = givenCourseList.map((c: UnitCourseAccessItem) => {
      let curCItem: UnitCourseAccessItem = {
        isAnyUnitGranted: false,
        courseID: c.courseID,
        courseName: c.courseName,
        expanded: c.expanded,
        areAllModulesGranted: false,
        isSearchMatch: c.isSearchMatch,
        unitModuleAccessItems: c.unitModuleAccessItems.map((m: UnitModuleAccessItem) => {
          let curMItem: UnitModuleAccessItem = {
            isUnitGranted: false,
            moduleID: m.moduleID,
            moduleName: m.moduleName,
            isSearchMatch: m.isSearchMatch
          };
          return curMItem;
        })
      }
      return curCItem;
    });
    return unselectAllCoursesinList;
  }

  const cloneGivenList = (givenCourseList: Array<UnitCourseAccessItem>) => {
    if (!!givenCourseList !== true) {
      return givenCourseList;
    }
    let originalList: Array<UnitCourseAccessItem> = [];
    originalList = givenCourseList.map((c: UnitCourseAccessItem) => {
      let curCItem: UnitCourseAccessItem = {
        isAnyUnitGranted: c.isAnyUnitGranted,
        courseID: c.courseID,
        courseName: c.courseName,
        expanded: c.expanded,
        areAllModulesGranted: c.areAllModulesGranted,
        isSearchMatch: c.isSearchMatch,
        unitModuleAccessItems: c.unitModuleAccessItems.map((m: UnitModuleAccessItem) => {
          let curMItem: UnitModuleAccessItem = {
            isUnitGranted: m.isUnitGranted,
            moduleID: m.moduleID,
            moduleName: m.moduleName,
            isSearchMatch: m.isSearchMatch
          };
          return curMItem;
        })
      }
      return curCItem;
    });
    return originalList;
  }


  const collectDifferedModules = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges, grantedModulesFromFirst: boolean = false) => {

    // Iterate modules list1 and collect extra or different
    for (let i = 0; i < modules1.length; i++) {
      let curLoopModule = modules1[i];
      // Skip invalid modules of first list
      if (!!curLoopModule !== true) {
        continue;
      }
      // Get the already collected differed and granted modules array
      let curGrantedArr = moduleAccessChanges.grantedModuleIDs.split(",");
      let curDiffArr = moduleAccessChanges.differedModuleIDs.split(",");
      // Get matched modules from second list and compare access changes 
      // Collect all the differed modules and collect only the granted modules of only one list based on the given flag grantedModulesFromFirst
      let mod2Matched = modules2.filter((curModule) => !!curModule && !!curModule.moduleID &&
        curModule.moduleID == curLoopModule.moduleID);
      // If there is a match found for the current module
      if (!!mod2Matched && mod2Matched.length > 0 && !!mod2Matched[0]) {
        let moduleIDTxtToAdd = curLoopModule.moduleID.toString();
        if (mod2Matched[0].isUnitGranted !== curLoopModule.isUnitGranted) {
          // If there is any access change
          moduleAccessChanges.isAnyChanged = true;
          if (!curDiffArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.differedModuleIDs = (moduleAccessChanges.differedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.differedModuleIDs + "," + moduleIDTxtToAdd;
          }
          // If the new granting is to the second list
          if (!grantedModulesFromFirst && (mod2Matched[0].isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
          // If the new granting is to the first list
          if (grantedModulesFromFirst && (curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
        }
        else {
          // Take unmodified grant from the second list
          if (!grantedModulesFromFirst && (mod2Matched[0].isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
          // Take unmodified grant from the first list
          if (grantedModulesFromFirst && (curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
        }
      }
      // If there is NO match found for the current module
      else {
        // If there is any access change
        moduleAccessChanges.isAnyChanged = true;
        let moduleIDTxtToAdd = curLoopModule.moduleID.toString();
        if (!curDiffArr.includes(moduleIDTxtToAdd)) {
          moduleAccessChanges.differedModuleIDs = (moduleAccessChanges.differedModuleIDs.trim() === "") ?
            moduleIDTxtToAdd : moduleAccessChanges.differedModuleIDs + "," + moduleIDTxtToAdd;
        }
        // If the new granting is to the first list
        if (grantedModulesFromFirst && (curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
          moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
            moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
        }
      }
    }
    return moduleAccessChanges;
  }

  const onAccordianChange = (event: any, expanded: boolean) => {
    if (!!event && !!event.currentTarget && !!event.currentTarget.ariaExpanded) {
      event.currentTarget.ariaExpanded = !!expanded ? "true" : "false";
    }
  }

  const handleCourseExpandClick = (e: any, courseData: UnitCourseAccessItem) => {
    handleCourseExpansion(courseData);
  }

  const handleCourseExpansion = (courseData: UnitCourseAccessItem) => {
    if (!!courseData.expanded) {
      courseData.expanded = !courseData.expanded;
    }
    else {
      courseData.expanded = true;
    }

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          return {
            ...elem
          }
        }
        );
        return {
          ...item,
          expanded: courseData.expanded
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);
  }

  // Only the filtered data is selected
  const handleModuleSelect = (moduleData: UnitModuleAccessItem, courseData: UnitCourseAccessItem) => {

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch ||
        (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 &&
          item.unitModuleAccessItems.some((m: UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
        return {
          ...item
        }
      }
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        moduleData.isUnitGranted = moduleData.isUnitGranted && !moduleData.isUnitGranted ? false : !moduleData.isUnitGranted;
        item.areAllModulesGranted = (!!item.unitModuleAccessItems &&
          ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length == item.unitModuleAccessItems.length)) ? true : false;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          if (moduleData.moduleID === elem.moduleID) {
            return {
              ...elem,
              isUnitGranted: moduleData.isUnitGranted
            }
          }
          return {
            ...elem,
            isUnitGranted: elem.isUnitGranted
          }
        }
        );
        return {
          ...item,
          isAnyUnitGranted: (!!item.unitModuleAccessItems &&
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length > 0)) ? true : false
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }


  const handleAllCourseSelection = () => {
    handleAllCoursesForGivenSelection(true);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const handleAllCourseUnselection = () => {
    handleAllCoursesForGivenSelection(false);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const handleExpirySettings = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setExpireSetting(parseInt(val));
  };

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (year.toString() + "-" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }
    else {
      return "";
    }
  }

  const cloneGivenListAndSetOriginalCourseList = (givenCourseList: Array<UnitCourseAccessItem>) => {
    let localList: Array<UnitCourseAccessItem> = [];
    localList = cloneGivenList(givenCourseList);
    setCourseListOriginal(localList);
  }

  const handleCourseSelect = (courseData: UnitCourseAccessItem) => {

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch ||
        (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 &&
          item.unitModuleAccessItems.some((m: UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
        return {
          ...item
        }
      }
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        item.areAllModulesGranted = !item.areAllModulesGranted;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          return {
            ...elem,
            isUnitGranted: item.areAllModulesGranted
          }
        }
        );
        return {
          ...item,
          isAnyUnitGranted: (!!item.unitModuleAccessItems &&
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length > 0)) ? true : false,
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const isAnyChanged = () => {
    let courseAccessChanges = collectCourseModuleAccessChanges();
    if ((!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs &&
      !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged)) {
      return true;
    }
    return false;
  }

  const GetIfAnyUnitSelected = () => {
    let unitIds: Array<number> = [];
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
          if (filterUnitName !== "") {
            if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
              unitIds.push(unit.unitID)
            }
          } else {
            unitIds.push(unit.unitID)
          }
        }
        getSelectedUnitIds(unit.childUnitTreeItems);
      }
    }
    getSelectedUnitIds(units);
    return unitIds;
  }

  const handleDefaultQuizPassMark = (e: any) => {
    if (isNaN(Number(e.target.value))) {
      return false;
    } else {
      if (Number(e.target.value) > 100) {
        return false;
      }
      if (e.target.value === "") {
        setStrDefaultquizpassmark("");
        setDefaultquizpassmark(0);
        return;
      }
      setStrDefaultquizpassmark(e.target.value);
      setDefaultquizpassmark(Number(e.target.value));
    }
  }

  const handleClosePopup = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (GetIfAnyUnitSelected().length > 0) {
      setIsSaving(true);
      handleConfirmModal(true);
    }
    else {
      setShowModal(true);
      setHasErrored(true);
      setSaveSuccess(false);
      setModalPopupMessage("Please select unit to apply the Course based compliance rules to");
    }
  }

  const handleConfirmModal = (value: boolean) => {
    setshowConfirmModal(value);
  }


  const handleNext = () => {
    history.push(`/coursesummary/${courseIdToEdit}`);
  }

  const handleProceed = () => {
    handleConfirmModal(false);
    let varexpirelessonafter = expireLessonafter;
    let varexpirequizafter = expireQuizafter;
    let varexpirelessondate = expireLessondate;
    let varexpirequizdate = expireQuizdate;
    if (expireSetting == 0) {
      if (expireLessondate.trim() !== "" || expireQuizdate.trim() !== "") {
        setExpireLessondate("");
        setExpireQuizdate("");
        varexpirelessondate = "";
        varexpirequizdate = "";
      }
    }
    else if (expireSetting == 1) {
      if (expireLessonafter > 0 || expireQuizafter > 0) {
        setExpireLessonafter(0);
        setExpireQuizafter(0);
        varexpirelessonafter = 0;
        varexpirequizafter = 0;
      }
    }
    else {
      setExpireLessondate("");
      setExpireQuizdate("");
      varexpirelessondate = "";
      varexpirequizdate = "";
      setExpireLessonafter(0);
      setExpireQuizafter(0);
      varexpirelessonafter = 0;
      varexpirequizafter = 0;
    }
    let courseAccessChanges = collectCourseModuleAccessChanges();
    let unitIds = GetIfAnyUnitSelected();
    if (!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && !!unitIds && unitIds.length > 0) {

      SaveOrgCourseBasedComplianceRules(oidcUser.access_token, userId, parseInt(orgId), orgDomain, strdefaultquizpassmark, varexpirelessonafter, varexpirequizafter, varexpirelessondate, varexpirequizdate, expireSetting === 2, applyLessonOverriding, applyQuizOverriding, courseAccessChanges.accessChangedCourseIDs, courseAccessChanges.grantedModuleIDs, yearlyIncreamentLesson, yearlyIncreamentQuiz, unitIds.toString()).then((result) => {
        if (!!result && result.isSuccess && result.error == '') {
          setShowModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Course based Compliance Rules saved successfully!");
          //history.push(`/coursesummary/${courseIdToEdit}`);
        }
        else {
          setShowModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Can not save Unit Compliance Rules! Please try again." + result.error !== "" ? ";" + result.error : "");
        }
        setLoading(false);
        setIsSaving(false);
      });
    }
  }

  const handleCancelFromConfirmPopup = () => {
    handleConfirmModal(false);
    setIsSaving(false);
  }

  const handleLessonDateSelection = (selectedDate: string) => {
    setExpireLessondate(selectedDate);
  }

  const handleQuizDateSelection = (selectedDate: string) => {
    setExpireQuizdate(selectedDate);
  }

  const handleLessonYearlyIncementSelection = (selectedIncrementYear: number) => {
    setYearlyIncreamentLesson(selectedIncrementYear);
  }

  const handleQuizYearlyIncementSelection = (selectedIncrementYear: number) => {
    setYearlyIncreamentQuiz(selectedIncrementYear);
  }

  useEffect(() => {
    setFilteredCoursesOnly(courseList);
  }, [filterCourseOrModuleName])

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setShowModal(false);
        setSaveSuccess(false);
      }, saveSuccess ? 3000 : 20000)
    }
  }, [showModal])

  const BackButtonRoute = () => {
    history.push({
      pathname: `/courseemailrulesadd/${courseIdToEdit}`,
    });
  }
  if (loading) return <LoaderContainer />;

  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              {allResourcesToTranslations.coursebasedconfirmmessage} <br></br><br></br>
              <b>{allResourcesToTranslations.proceedmessage} </b>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopup}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span>
                <Button
                  onClick={handleProceed}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <div>
        <div style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}>
          <CourseProgressBar courseIdToEdit={parseInt(courseIdToEdit)} step='5' />
        </div>
        <BrandedCard>
          <div style={{ paddingLeft: '20px', paddingTop: '15px', fontWeight: 'bold' }} >
            <div onClick={(e) => { setIncludeOptional(!includeOptional); setGuideMe(!includeOptional); }} style={{ float: 'left' }} className='inactiveoptional'>
              <span style={{ marginTop: "-3px", paddingRight: "5px", float: "left" }} >{includeOptional ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
            </div>
            <div style={{ float: 'left' }}>
              <span id="allowLearnerswitch" className={styles.labelText}
                onClick={(e) => { setIncludeOptional(!includeOptional); }}
              >
                &nbsp;&nbsp;<b>{allResourcesToTranslations.step5} (Optional)</b></span>
            </div>
          </div>
          {includeOptional &&
            <UnbrandedCard>
              <div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
                <div className={styles.Wrapper}>
                  <div>
                    <div className={styles.lableContainer} >
                      <span className={styles.labelText}>{allResourcesToTranslations.selectcourses}</span>
                    </div>
                    <div className={styles.inputContainer}>
                      <span className={styles.labelTextitalic}>{allResourcesToTranslations.selectcoursesinfo}</span>
                    </div>
                    <BrandedCard>
                      <div style={{ justifyContent: "space-between", padding: "20px 0px 20px 20px" }}>
                        <div style={{ display: "grid" }}>
                          <span style={{ fontWeight: "bold", fontSize: "15px", marginTop: "10px" }}>{allResourcesToTranslations.courseenrolments} </span>

                          <div className={styles.search_longtextbox_and_buttons_container} style={{ paddingTop: "15px" }}>
                            <div id="courseModuleNameSearch" className={styles.courseModuleNameSearch}>
                              <TextField style={{ width: "100%" }}
                                label={"Course/Module Name Search"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                value={filterCourseOrModuleName} onChange={(e) => setFilterCourseOrModuleName(e.target.value)}
                                InputProps={{ endAdornment: (<IconButton><SearchIcon style={{ height: "20px" }} /></IconButton>) }} />
                            </div>
                            <div className={styles.courseModuleNameButtons}>
                              <span>
                                <CustomTooltip displayText={[allResourcesToTranslations.selectall]}>
                                  <Button id="selectAllButton" className="selectAllButton" variant="contained" color="primary" onClick={handleAllCourseSelection}>
                                    <SelectAllIcon />
                                  </Button>
                                </CustomTooltip>
                              </span>
                              <span style={{ paddingRight: "2px" }}>
                                <CustomTooltip displayText={[allResourcesToTranslations.unselectall]}>
                                  <Button id="unselectAllButton" className="unselectAllButton" variant="contained" color="primary" onClick={handleAllCourseUnselection}>
                                    <UnSelectAllIcon />
                                  </Button>
                                </CustomTooltip>
                              </span>
                            </div>
                            <div style={{ clear: "both" }}></div>
                          </div>
                        </div>
                      </div>
                      <div id="orgGrantedCourseList" style={{ overflowY: "scroll", scrollbarWidth: "auto", maxHeight: "400px", borderTop: "3px solid #D3D6DF" }} className="modulelist">
                        {courseList.length > 0 && courseList.map((course) => {
                          return (!!course && (course.isSearchMatch || (!!course.unitModuleAccessItems && course.unitModuleAccessItems.length > 0 &&
                            course.unitModuleAccessItems.some((m) => !!m && m.isSearchMatch)))) ?
                            (

                              <Accordion key={course.courseID.toString() + course.courseName.toString()}
                                //expanded={course.expanded} aria-expanded={course.expanded}
                                style={course.expanded ?
                                  { backgroundColor: "#ffffff", paddingTop: '10px', marginBottom: '30px', boxShadow: 'none' } : { backgroundColor: "#FFFFFF", paddingTop: '10px', marginBottom: '30px' }}
                                onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded); }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  className={course.expanded ? "MuiButtonBase-root MuiAccordionSummary-root Mui-expanded" : "MuiButtonBase-root MuiAccordionSummary-root"}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  style={{ paddingBottom: "0px", minHeight: "0px" }}
                                  onClick={(e) => handleCourseExpandClick(e, course)}
                                >
                                  <Checkbox size="small" id="courseSelectionCheckbox"
                                    checked={course.areAllModulesGranted || course.isAnyUnitGranted}
                                    indeterminate={!!course && course.areAllModulesGranted == false && (course.isAnyUnitGranted)}
                                    onClick={(e) => { e.stopPropagation(); handleCourseSelect(course); }}
                                    color="primary" style={{ marginTop: "-5px" }} />
                                  <Typography style={(course.isAnyUnitGranted) ? { marginTop: '6px', fontWeight: "bold" } : { marginTop: '6px' }}>
                                    {course.courseName}</Typography>
                                </AccordionSummary>
                                {
                                  course.unitModuleAccessItems.length > 0 && course.unitModuleAccessItems.map((module) => {
                                    return (
                                      <>
                                        <span key={module.moduleID.toString() + module.moduleName.toString()}
                                          style={{ display: "inline", marginLeft: "30px", marginTop: "-20px" }}>
                                          <Checkbox size="small" id={course.expanded ? "moduleSelectionCheckbox" : "_moduleSelectionCheckbox"}
                                            checked={module.isUnitGranted}
                                            onClick={() => handleModuleSelect(module, course)}
                                            color="primary" />
                                          <span style={(module.isUnitGranted) ? { marginTop: '6px', fontWeight: "bold", fontSize: '14px' } : { marginTop: '6px', fontSize: '14px' }}>
                                            {module.moduleName}</span>
                                        </span>
                                        <br />
                                      </>
                                    )
                                  })
                                }
                              </Accordion>
                            ) :
                            (<></>)
                        })}

                      </div>
                    </BrandedCard>
                  </div>
                  <div style={{ paddingTop: "20px" }}>
                    <div className={styles.lableContainer} >
                      <span className={styles.labelText}>{"2. " + allResourcesToTranslations.quizsettings}</span>
                    </div>
                    <div className={styles.inputContainer}>
                      <span className={styles.labelTextitalic}>{allResourcesToTranslations.quizsettingsdetail}</span>
                    </div>
                    <div className="defaultquizpassmark" style={{ width: '100%', paddingLeft: '0px', paddingBottom: '20px' }} >
                      <TextField
                        className={styles.input}
                        style={{ width: "100%", marginBottom: "15px" }}
                        label={allResourcesToTranslations.defaultquizpassmark}
                        name="defaultquizpassmark"
                        inputProps={{ maxLength: 4 }}
                        variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                        value={strdefaultquizpassmark}
                        type="text"
                        onChange={(e) => handleDefaultQuizPassMark(e)}
                      />
                    </div>
                    <hr></hr>
                  </div>
                  <div>
                    <div className={styles.lableContainer} >
                      <span className={styles.labelText}>{"3. " + allResourcesToTranslations.expirysettings}</span>
                    </div>
                    <div className={styles.inputContainer}>
                      <span className={styles.labelTextitalic}>{allResourcesToTranslations.expirysettingsinfo}</span>
                    </div>
                    <div className={styles.inputContainer}>
                      <div className={styles.radiodivpadging}>
                        <RadioGroup
                          name="expirysettings"
                          value={expireSetting}
                          onChange={handleExpirySettings}
                          style={{ display: 'flex' }}
                        >
                          <FormControlLabel
                            value={0}
                            className={`${expireSetting !== 0 ? styles.disabledText : ""} expiregiventime`}
                            control={<Radio color="primary" className={styles.radiopadging} />}
                            label={allResourcesToTranslations.expireperiod}
                          />
                          <div className={responsivestyles.rowrule}>
                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                              <div className={`${styles.inputContainer} ${styles.datebasedexpiryleft}`} >
                                <FormControl variant="outlined" className={styles.formControl}>
                                  <InputLabel>{allResourcesToTranslations.expirelesson}</InputLabel>
                                  <Select className={`${styles.input} expirelesson`}
                                    value={expireLessonafter}
                                    disabled={expireSetting !== 0}
                                    native
                                    onChange={(e: any) => setExpireLessonafter(e.target.value)}
                                    variant="outlined"
                                    label={allResourcesToTranslations.expirelesson}
                                    id="expirelesson">
                                    <option value={0}>Please select</option>
                                    <option value={1}>1 Month</option>
                                    <option value={2}>2 Months</option>
                                    <option value={3}>3 Months</option>
                                    <option value={4}>4 Months</option>
                                    <option value={5}>5 Months</option>
                                    <option value={6}>6 Months</option>
                                    <option value={7}>7 Months</option>
                                    <option value={8}>8 Months</option>
                                    <option value={9}>9 Months</option>
                                    <option value={10}>10 Months</option>
                                    <option value={11}>11 Months</option>
                                    <option value={12}>12 Months</option>
                                    <option value={13}>13 Months</option>
                                    <option value={14}>14 Months</option>
                                    <option value={15}>15 Months</option>
                                    <option value={16}>16 Months</option>
                                    <option value={17}>17 Months</option>
                                    <option value={18}>18 Months</option>
                                    <option value={19}>19 Months</option>
                                    <option value={20}>20 Months</option>
                                    <option value={21}>21 Months</option>
                                    <option value={22}>22 Months</option>
                                    <option value={23}>23 Months</option>
                                    <option value={24}>24 Months</option>
                                    <option value={25}>25 Months</option>
                                    <option value={26}>26 Months</option>
                                    <option value={27}>27 Months</option>
                                    <option value={28}>28 Months</option>
                                    <option value={29}>29 Months</option>
                                    <option value={30}>30 Months</option>
                                    <option value={31}>31 Months</option>
                                    <option value={32}>32 Months</option>
                                    <option value={33}>33 Months</option>
                                    <option value={34}>34 Months</option>
                                    <option value={35}>35 Months</option>
                                    <option value={36}>36 Months</option>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>

                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                              <div className={`${styles.inputContainer} ${styles.datebasedexpiryright}`}>
                                <FormControl variant="outlined" className={styles.formControl}>
                                  <InputLabel>{allResourcesToTranslations.expirequiz}</InputLabel>
                                  <Select className={`${styles.input} expirequiz`}
                                    value={expireQuizafter}
                                    disabled={expireSetting !== 0}
                                    native
                                    onChange={(e: any) => setExpireQuizafter(e.target.value)}
                                    variant="outlined"
                                    label={allResourcesToTranslations.expirequiz}
                                    id="expirequiz">
                                    <option value={0}>Please select</option>
                                    <option value={1}>1 Month</option>
                                    <option value={2}>2 Months</option>
                                    <option value={3}>3 Months</option>
                                    <option value={4}>4 Months</option>
                                    <option value={5}>5 Months</option>
                                    <option value={6}>6 Months</option>
                                    <option value={7}>7 Months</option>
                                    <option value={8}>8 Months</option>
                                    <option value={9}>9 Months</option>
                                    <option value={10}>10 Months</option>
                                    <option value={11}>11 Months</option>
                                    <option value={12}>12 Months</option>
                                    <option value={13}>13 Months</option>
                                    <option value={14}>14 Months</option>
                                    <option value={15}>15 Months</option>
                                    <option value={16}>16 Months</option>
                                    <option value={17}>17 Months</option>
                                    <option value={18}>18 Months</option>
                                    <option value={19}>19 Months</option>
                                    <option value={20}>20 Months</option>
                                    <option value={21}>21 Months</option>
                                    <option value={22}>22 Months</option>
                                    <option value={23}>23 Months</option>
                                    <option value={24}>24 Months</option>
                                    <option value={25}>25 Months</option>
                                    <option value={26}>26 Months</option>
                                    <option value={27}>27 Months</option>
                                    <option value={28}>28 Months</option>
                                    <option value={29}>29 Months</option>
                                    <option value={30}>30 Months</option>
                                    <option value={31}>31 Months</option>
                                    <option value={32}>32 Months</option>
                                    <option value={33}>33 Months</option>
                                    <option value={34}>34 Months</option>
                                    <option value={35}>35 Months</option>
                                    <option value={36}>36 Months</option>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <FormControlLabel
                            value={1}
                            className={`${expireSetting !== 1 ? styles.disabledText : ""} expireongivendate`}
                            control={<Radio color="primary" className={styles.radiopadging} />}
                            label={allResourcesToTranslations.expiredate}
                          />

                          <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                              <div className={`${styles.inputContainer} ${styles.datebasedexpiryleft}`} >
                                <CustomisedDatePicker isDisabled={expireSetting !== 1} inputDate={expireLessondate} lableText={allResourcesToTranslations.expirelessondate}
                                  handleDateSelection={handleLessonDateSelection} yearlyIncrementdatepickerclassName="expireLessondate" yearlyIncrementddlclassName="lessondateyearlyincrement" yearlyincrementValue={yearlyIncreamentLesson} handleYearSelection={handleLessonYearlyIncementSelection}></CustomisedDatePicker>
                              </div>
                            </div>
                            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
                              <div className={`${styles.inputContainer} ${styles.datebasedexpiryright}`}>
                                <div>
                                  <CustomisedDatePicker isDisabled={expireSetting !== 1} inputDate={expireQuizdate} lableText={allResourcesToTranslations.expirequizdate}
                                    handleDateSelection={handleQuizDateSelection} yearlyIncrementdatepickerclassName="expireQuizdate" yearlyIncrementddlclassName="quizdateyearlyincrement" yearlyincrementValue={yearlyIncreamentQuiz} handleYearSelection={handleQuizYearlyIncementSelection}></CustomisedDatePicker>
                                </div>
                              </div>
                            </div>
                          </div>
                          <FormControlLabel
                            value={2}
                            className={`${expireSetting !== 2 ? styles.disabledText : ""} enableonetime`}
                            control={<Radio color="primary" className={styles.radiopadging} />}
                            label={allResourcesToTranslations.enableonetime}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} untitreeTitle={allResourcesToTranslations.unitpathway} unitTreeTitleInfo={allResourcesToTranslations.unitpathwayinfo} />
                  </div>
                  <div className={styles.tooltipcontainer}>
                    <div className={styles.tooltip} style={{ float: "right", width: "100%" }}>
                      <div style={{ float: "left", width: "24px", paddingTop: "20px" }}><NotesIcon></NotesIcon>
                      </div>
                      <div style={{ float: "right", width: "94%" }}><p>
                        <span className="heading">{allResourcesToTranslations.compliancerulesupdatenotes}</span>
                      </p> </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px" }}>
                    <span>
                      <CircularProgress
                        style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                        color="primary"
                        size="10"
                      />
                    </span>
                    <CustomTooltip displayText={["Save"]}>
                      <span>
                        <Button
                          disabled={
                            isAnyChanged() === false
                            || (strdefaultquizpassmark.trim() === "" || strdefaultquizpassmark.trim() === "%")
                            || (expireSetting == 0 && (expireLessonafter == 0 || expireQuizafter == 0))
                            || (expireSetting == 1 && (expireLessondate == "" || expireQuizdate == "" || yearlyIncreamentLesson == 0 || yearlyIncreamentQuiz == 0))}
                          onClick={handleSave}
                          variant="contained"
                          color="primary"
                          className="saveData">
                          Save
                        </Button>
                      </span>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </UnbrandedCard>
          }
        </BrandedCard>
        <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%", paddingTop: includeOptional ? '130px' : '0px', clear: "both" }}>
          <CustomTooltip displayText={["Next"]}>
            <span style={{ marginTop: "10px" }}>
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
                className="nextData"
              >
                Next
              </Button>
            </span>
          </CustomTooltip>
          <CustomTooltip displayText={["Back"]}>
            <span style={{ marginTop: "10px" }}>
              <Button
                onClick={BackButtonRoute}
                variant="contained"
                color="primary"
                className="Back"
              >
                Back
              </Button>
            </span>
          </CustomTooltip>
        </div>
        <div className={styles.mainWrapper}>
          <div style={{ width: '100%', clear: "both" }} >
          </div>
          {showModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
        </div>
      </div>
    </>
  );
};

export default CourseComplianceRules;
