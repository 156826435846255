//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import { addError } from '../../API/AddError'
import { GetOrgLinks } from '../../API/Administration/Organisation/GetOrgLinks';
import CloseWhiteIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import CourseIcon from '../../Components/CourseIcon';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import DraftsIcon from '@material-ui/icons/Drafts';
import Modal from '../../Components/Modal';
import ProceedIcon from "../../CustomIcons/ProceedIcon";
import styles from "../../styles/organisationmanagement.module.css";
import responsivestyles from "../../styles/responsiveGridLayout.module.css";
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard';
import { Button, ButtonGroup, Checkbox, FormControl, IconButton, InputLabel, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core';
import GetMyNewsfeedList from '../../API/Administration/Newsfeed/GetMyNewsfeedList';
import ToggleRightIcon from '../../CustomIcons/ToggleRightIcon';
import ToggleLeftIcon from '../../CustomIcons/ToggleLeftIcon';
import getColor from '../../functions/getColor';
import CustomTooltip from '../../Components/CustomTooltip';
import MyNewsfeedIcon from '../../Components/MyNewsfeedIcon';
import UnbrandedCard from '../../Components/UnbrandedCard';
import { useHistory } from 'react-router-dom';
import MarkFavouriteNewsfeed from '../../API/Administration/Newsfeed/MarkFavouriteNewsfeed';
import MarkUnreadNewsfeeds from '../../API/Administration/Newsfeed/MarkUnreadNewsfeeds';
import CheckIfHasCPDEnabledForNewsfeed from '../../API/Administration/Newsfeed/CheckIfHasCPDEnabledForNewsfeed';

const MyNewsfeed = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [selectedNewsfeedIds, setSelectedNewsfeedIds] = useState<string>("");

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");
  const [filterArticle, setFilterArticle] = React.useState("");
  const [newsfeedList, setNewsfeedList] = React.useState<Array<any>>([]);
  const [filteredListData, setFilteredListData] = React.useState<Array<any>>([]);
  const [categoryList, setCategoryList] = React.useState<Array<any>>([]);
  const [showUnread, setShowUnread] = useState<boolean>(false);
  const [showFavourites, setShowFavourites] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [sortby, setSortby] = React.useState<string>("publishdatedesc");
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [isCPDEnabled, setIsCPDEnabled] = React.useState<boolean>(false);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const loadNewsfeeds = () => {
    GetMyNewsfeedList(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.myNewsfeedList !== null) {
          let updatedItems = result.myNewsfeedList.map((item: any) => {
            return {
              ...item,
              isSelected: false
            }
          })
          setNewsfeedList(updatedItems);
          setFilteredListData(updatedItems);
        }
        if (result.newsfeedCategories !== null) {
          let updatedItems = result.newsfeedCategories.map((item: any) => {
            return {
              ...item,
              isCategorySelected: false
            }
          })
          setCategoryList(updatedItems);
        }
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load newsfeed!");
      }
      setLoading(false);
    });
  };

  const CheckCPDEnabledForNewsfeed = () => {
    CheckIfHasCPDEnabledForNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        setIsCPDEnabled(result.isCPDEnabled);        
      }
    });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.mynewsfeed)
    setTitleEnglish(pageTitles.mynewsfeed)
    updateBackButtonRoute("");
    setMenuSelection("My Newsfeed")
    setLoading(true);
    CheckCPDEnabledForNewsfeed();
    loadNewsfeeds();
    return () => { };
  }, []);

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 3000)
    }
  }, [showWarningModal]);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleSelectAll = (event: any) => {
    event.stopPropagation();
    setSelectAll(!selectAll);
    let newsfeedListData = [...filteredListData];
    newsfeedListData.map((item: any) => {
      item.isSelected = !selectAll;
    });
    setFilteredListData(newsfeedListData);
    var selectedNewsfeedIds = newsfeedListData.filter((item: any) => item.isSelected).map((item: any) => item.newsfeedId);
    setSelectedNewsfeedIds(selectedNewsfeedIds.join(','));
  }

  const handleSelectNewsfeed = (event: any, newsfeedId: number) => {
    event.stopPropagation();
    var selectedNewsfeedIds = "";
    let newsfeedListData = [...filteredListData];
    newsfeedListData.map((item: any) => {
      if (item.newsfeedId === newsfeedId) {
        item.isSelected = !item.isSelected;
      }
      if (item.isSelected) {
        selectedNewsfeedIds += item.newsfeedId + ",";
      }
    });
    setSelectedNewsfeedIds(selectedNewsfeedIds);
    setFilteredListData(newsfeedListData);
  }

  const handleMarkUnread = () => {
    var selectedNewsfeedIds = filteredListData.filter((item: any) => item.isSelected).map((item: any) => item.newsfeedId);
    MarkUnreadNewsfeeds(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedNewsfeedIds.join(','), false).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setModalPopupMessage("Selected newsfeed successfully marked unread.");
        setSelectAll(false);
        setSelectedNewsfeedIds("");
        loadNewsfeeds();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setSelectedNewsfeedIds("");
        setModalPopupMessage("Can not mark as unread!");
      }
    });
  }

  
  const handleMarkRead = () => {
    var selectedNewsfeedIds = filteredListData.filter((item: any) => item.isSelected).map((item: any) => item.newsfeedId);
    MarkUnreadNewsfeeds(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedNewsfeedIds.join(','), true).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setModalPopupMessage("Selected newsfeed successfully marked read.");
        setSelectAll(false);
        setSelectedNewsfeedIds("");
        loadNewsfeeds();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setSelectedNewsfeedIds("");
        setModalPopupMessage("Can not mark as read!");
      }
    });
  }

  const handleMarkFavoutite = (newsfeedId: number, isFavourite: boolean) => {
    MarkFavouriteNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedId, isFavourite).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        loadNewsfeeds();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not mark as favourite!");
      }
    });
  }

  const handleShowUnread = () => {
    if (!showUnread) {
      let updatedDataLocal = filteredListData.filter(
        (item: any) => item.dateRead === null
      );
      setFilteredListData([...updatedDataLocal]);
    }
    else {
      setFilteredListData([...newsfeedList]);
    }
    setShowUnread(!showUnread);
  }

  const handleFavourite = () => {
    if (!showFavourites) {
      let updatedDataLocal = filteredListData.filter(
        (item: any) => item.isFavourite === true
      );
      setFilteredListData([...updatedDataLocal]);
    }
    else {
      setFilteredListData([...newsfeedList]);
    }
    setShowFavourites(!showFavourites);
  }

  const handleFilterData = (e: any) => {
    let filteredText = e.target.value.toString().toLowerCase();
    let updatedDataLocal = newsfeedList.filter(
      (item: any) => item.heading.toString().toLowerCase().includes(filteredText)
        || item.subHeading.toString().toLowerCase().includes(filteredText)
    );

    setFilterArticle(e.target.value)
    setFilteredListData([...updatedDataLocal]);
  }

  const GetNfCategorynames = (categoryids: string) => {
    const ids = categoryids.split(',');
    const categoryNames = categoryList
      .filter(item => ids.includes(item.categoryId.toString()))
      .map(item => item.categoryName);

    return categoryNames.join(',');
  }

  const clearFilter = () => {
    let updatedItems = categoryList.map((item: any) => {
      item.isCategorySelected = false;
      return {
        ...item
      }
    })
    setCategoryList(updatedItems);
    setFilteredListData(newsfeedList);
  }

  const sortResult = (sortby: string) => {
    setSortby(sortby);
    let newsfeedListData = [...filteredListData];
    switch (sortby) {
      case "nameasc":
        newsfeedListData.sort((a, b) => a.heading.localeCompare(b.heading));
        break;
      case "namedesc":
        newsfeedListData.sort((a, b) => b.heading.localeCompare(a.heading));
        break;
      case "cpdasc":
        newsfeedListData.sort((a, b) => parseFloat(a.cpdPoints) - parseFloat(b.cpdPoints));
        break;
      case "cpddesc":
        newsfeedListData.sort((a, b) => parseFloat(b.cpdPoints) - parseFloat(a.cpdPoints));
        break;
      case "publishdateasc":
        newsfeedListData.sort((a, b) => new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime());
        break;
      case "publishdatedesc":
        newsfeedListData.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
        break;
    }
    setFilteredListData(newsfeedListData);
  }

  const filterCategorywise = (categoryId: number) => {
    let updatedItems = categoryList.map((item: any) => {
      if (item.categoryId === categoryId) {
        item.isCategorySelected = !item.isCategorySelected;
      }
      return {
        ...item
      }
    })
    setCategoryList(updatedItems);

    // Get all selected categories
    const selectedCategories = updatedItems
      .filter((item: any) => item.isCategorySelected)
      .map((item: any) => item.categoryId.toString());

    if (selectedCategories.length === 0) {
      setFilteredListData(newsfeedList);
    }
    else {
      // Filter newsfeedList based on selected categories
      let newsfeedListData = newsfeedList.filter((item: any) => {
        const itemCategories = item.nfCategoryCsv.split(',');
        return selectedCategories.some(categoryId => itemCategories.includes(categoryId));
      });

      setFilteredListData(newsfeedListData);
    }
  }

  const handleArticleDetails = (articleDetail: any) => {
    history.push({
      pathname: `mynewsfeeddetails/` + articleDetail.newsfeedId,
      state: {
        heading: articleDetail.heading,
        subHeading: articleDetail.subHeading,
        articleText: articleDetail.articleText,
        isFavourite: articleDetail.isFavourite,
        cpdPoints: articleDetail.cpdPoints,
        earnedCpdPoints: articleDetail.earnedCpdPoints == null || articleDetail.earnedCpdPoints == undefined ? "0" : articleDetail.earnedCpdPoints.toString(),
        dateCreated: articleDetail.dateCreated,
        datePublishedToUser: articleDetail.datePublishedToUser,
        dateRead: articleDetail.dateRead,
        dateOpened: articleDetail.dateOpened,
        nfCatedories: GetNfCategorynames(articleDetail.nfCategoryCsv),
        hasAssignedCpdPoints: articleDetail.hasAssignedCpdPoints && isCPDEnabled,
      },
    });
  }

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (
        (parseInt(year) < 10 ? "000" + year.toString()
          : ((parseInt(year) < 100 ? "00"
            : (((parseInt(year) < 1000 ? "0" : year.toString())))))) + "-" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }
    else {
      return "";
    }
  }

  if (loading) return <LoaderContainer />;
  return (
    <>

      <div className={styles.mainWrapper}>
        {newsfeedList.length > 0 &&
        <>
        <div style={{ width: '100%', clear: "both" }} >
          <UnbrandedCard>
            <div style={{ justifyContent: "space-between", padding: "20px" }}>
              <div style={{ display: "grid" }}>
                <span style={{ fontWeight: "bold", fontSize: "15px", marginTop: "10px" }}>{allResourcesToTranslations.articles}</span>
              </div>
              <div className={styles.search_longtextbox_and_buttons_container} style={{ paddingTop: "15px" }}>
                <div id="articleSearch" className={styles.courseModuleNameSearch}>
                  <TextField style={{ width: "50%" }}
                    placeholder={allResourcesToTranslations.searcharticles} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                    value={filterArticle} onChange={(e) => handleFilterData(e)}
                    InputProps={{ startAdornment: (<IconButton><SearchIcon style={{ height: "25px" }} /></IconButton>) }} />
                </div>
              </div>
              <div style={{clear: "both"}}></div>
            </div>
            <div style={{clear: "both"}}></div>
            <div style={{ padding: "0px 0px 20px 20px", width: "100%" }}>
              <div style={{ float: 'left', width: "80%" }}>
                <div style={{ float: 'left', marginTop: "20px" }}>
                <span style={{ paddingRight: "10px" }}> Showing: </span>
                </div>
                <div style={{ float: 'left', maxWidth: "80%", display: "block"  }}>
                  {categoryList.
                    map((result: any, i) => {
                      return (
                        result.isCategorySelected ?
                          <div style={{ float: "left", paddingRight: "10px", paddingTop: "10px" }} onClick={() => filterCategorywise(result.categoryId)}><span style={{ border: '2px solid #63C2FF', backgroundColor: "#63C2FF", color: "#fff", padding: '10px', borderRadius: "20px", cursor: "pointer", display: "block" }} className={styles.newsfeedcategorybutton} >{result.categoryName}</span></div>
                          :
                          <div style={{ float: "left", paddingRight: "10px", paddingTop: "10px" }} onClick={() => filterCategorywise(result.categoryId)}><span style={{ border: '2px solid #ccc', backgroundColor: "#fff", color: "gray", padding: '10px', borderRadius: "20px", cursor: "pointer", display: "block"  }} className={styles.newsfeedcategorybutton} >{result.categoryName}</span></div>
                      );
                    })}
                </div>
                <div style={{ float: 'left', maxWidth: "120px", display: "block", marginTop: "20px" }}>
                {categoryList.filter((item: any) => item.isCategorySelected).length > 0 &&
                    <span style={{ fontWeight: "bold", color: "#63C2FF", cursor: "pointer", fontSize: "small" }} onClick={() => clearFilter()}>Clear filters</span>
                  }
                  </div>
                <div style={{clear: "both"}}></div>
              </div>
           
              <div className={`${styles.inputContainer} newsfeedstatus`} style={{ float: "left", marginRight: "20px" }}>
                  <FormControl variant="outlined" className={styles.formControl} style={{ backgroundColor: "white", height: "55px" }}>
                    <InputLabel>Sort by:</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      value={sortby}
                      native
                      disabled={isSaving}
                      onChange={(e: any) => sortResult((e.target.value))}
                      variant="outlined"
                      className="sortby"
                      label="Sort by:">
                          <option value={"publishdatedesc"}>Publish date new-old</option>
                          <option value={"publishdateasc"}>Publish date old-new</option>
                        <option value={"nameasc"}>Name A-Z</option>
                        <option value={"namedesc"}>Name Z-A</option>
                        <option value={"cpdasc"}>CPD Points high-low</option>
                        <option value={"cpddesc"}>CPD Points low-high</option>
                    </Select>
                  </FormControl>
                </div>
                <div style={{clear: "both"}}></div>
            </div>
          </UnbrandedCard>
          <div className={styles.paginationControlsContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "95%",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={filteredListData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <BrandedCard>
            <div className={styles.expandContainer} style={{ backgroundColor: '#fff', padding: "20px 20px 0px 20px" }}>
              <div style={{ display: 'flex' }}>
                <div>
                  <div onClick={() => handleShowUnread()} style={{cursor:"pointer"}} >
                    {showUnread &&
                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                    }
                    {!showUnread &&
                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                    }
                  </div>
                  <div className={styles.inputContainer} style={{ width: "170px" }}>
                    <span id="showUnreadSwitch" className={styles.labelText2} style={{cursor:"pointer"}} onClick={() => handleShowUnread()}>
                      Show Unread</span>
                  </div>
                </div>

                <div>
                  <div onClick={() => handleFavourite()}  style={{cursor:"pointer"}} >
                    {showFavourites &&
                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                    }
                    {!showFavourites &&
                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                    }
                  </div>
                  <div className={styles.inputContainer} style={{ width: "200px" }}>
                    <span id="showUnreadSwitch" className={styles.labelText2} style={{cursor:"pointer"}} onClick={() => handleFavourite()}>
                      Show Favourites</span>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px" }}><b>{newsfeedList.length.toString()} Articles | {newsfeedList.filter((item: any) => item.dateRead === null).length.toString()} Unread</b></div>
            </div>
            <hr></hr>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell id="acMgmtAssignCol"
                      align="left"
                      width="120px"
                      style={{ padding: "0px 20px" }}
                    >
                      <div
                        className={styles.tableHeadCellContainer}
                        style={{ display: "flex", maxWidth: "150px" }}
                      >
                        <div>
                          <Checkbox
                            size="small"
                            color="primary"
                            checked={selectAll}
                            className={styles.checkBox}
                            style={{ color: 'grey' }}
                            onClick={(e: any) => handleSelectAll(e)}
                          />
                          {'Select All'}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left" className="markasUnread" style={{ padding: "0px 20px" }}>
                      {selectedNewsfeedIds !== "" &&
                        <div style={{float: "left"}}>
                          <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={() => handleMarkUnread()}>
                            <span style={{ marginTop: "-3px", paddingRight: "10px", float: "left" }} ><DraftsIcon /></span>
                          </div>
                          <div className={styles.inputContainer} style={{ width: "200px", cursor: "pointer" }}>
                            <span id="markasUnread" className={styles.labelText2} onClick={() => handleMarkUnread()}>
                              Mark as Unread</span>
                          </div>
                        </div>
                      }
                      {selectedNewsfeedIds == "" &&
                        <div style={{float: "left"}}>
                          <div style={{ paddingTop: "20px", color: "GrayText" }}>
                            <span style={{ marginTop: "-3px", paddingRight: "10px", float: "left" }} ><DraftsIcon /></span>
                          </div>
                          <div className={styles.inputContainer} style={{ width: "200px", color: "GrayText" }}>
                            <span id="markasUnread" className={styles.labelText2}>
                              Mark as Unread</span>
                          </div>
                        </div>
                      }

                      {selectedNewsfeedIds !== "" &&
                        <div style={{float: "left"}}>
                          <div style={{ paddingTop: "20px", cursor: "pointer" }} onClick={() => handleMarkRead()}>
                            <span style={{ marginTop: "-3px", paddingRight: "10px", float: "left" }} ><DraftsIcon /></span>
                          </div>
                          <div className={styles.inputContainer} style={{ width: "200px", cursor: "pointer" }}>
                            <span id="markasRead" className={styles.labelText2} onClick={() => handleMarkRead()}>
                              Mark as Read</span>
                          </div>
                        </div>
                      }
                      {selectedNewsfeedIds == "" &&
                        <div style={{float: "left"}}>
                          <div style={{ paddingTop: "20px", color: "GrayText" }}>
                            <span style={{ marginTop: "-3px", paddingRight: "10px", float: "left" }} ><DraftsIcon /></span>
                          </div>
                          <div className={styles.inputContainer} style={{ width: "200px", color: "GrayText" }}>
                            <span id="markasRead" className={styles.labelText2}>
                              Mark as Read</span>
                          </div>
                        </div>
                      }
                    <div style={{clear: "both"}}></div>
                    </TableCell>
                    <TableCell align="left" style={{ padding: "0px 20px" }}>
                      <span className={styles.nameHeading}>

                      </span>
                    </TableCell>
                    {isCPDEnabled &&
                      <TableCell align="center" id="cpdpoints" style={{ padding: "0px 20px" }}>{allResourcesToTranslations.cpdpointsavaliable}</TableCell>
                    }
                    <TableCell align="center" className="starred" style={{ padding: "0px 20px" }}>Starred</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredListData
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((result: any, i) => {
                      return (
                        <TableRow
                          key={`reportScheduler-${i}`}
                          style={rowBackgroundColor(i)}
                        >
                          <TableCell align="left" className={"assignCheckbox"} style={result.dateRead === null ? { borderLeft: "12px solid #21394F" } : { borderLeft: "0px" }}>
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={result.isSelected}
                              className={styles.checkBox}
                              onClick={(e: any) => handleSelectNewsfeed(e, result.newsfeedId)}
                            />
                          </TableCell>

                          <TableCell align="left" style={{width: "650px"}}>
                            <div className={styles.courseTitleContainer}>
                              <div style={{ float: "left", cursor: "pointer" }} onClick={() => handleArticleDetails(result)}>
                                <MyNewsfeedIcon IsGRCCreated={result.hasCreatedBySuperAdmin} />
                              </div>
                              <div>
                                <span className={styles.courseName} 
                                 style={{
                                  ...result.dateRead === null ? { fontWeight: 'bold' } : { fontWeight: "normal" },
                                  paddingLeft: "20px",
                                  fontSize: "20px",
                                  textDecoration: 'none',
                                  cursor: "pointer"
                                }} onClick={() => handleArticleDetails(result)}> 
                                {result.heading.length > 45 ? `${result.heading.substring(0, 45)}...` : result.heading}
                                </span> <br></br>
                                <span className={styles.courseName} 
                                 style={{
                                  ...result.dateRead === null ? { fontWeight: 'bold' } : { fontWeight: "normal" },
                                  paddingLeft: "20px",
                                  fontSize: "16px",
                                  textDecoration: 'none',
                                  cursor: "pointer"
                                }}
                                onClick={() => handleArticleDetails(result)}>
                                   {result.subHeading.length > 55 ? `${result.subHeading.substring(0, 55)}...` : result.subHeading}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="left" className={"unitpathwayCol"} valign="top">
                            <span>Date Created: {formatDate(new Date(result.dateCreated))}</span> <br></br>
                            {result.dateRead !== null ? <span>Date read: {formatDate(new Date(result.dateRead))}</span> : <span>Unread</span>}
                          </TableCell>
                          {isCPDEnabled &&
                            <TableCell align="center" className={"firstnameCol"}>
                              {result.cpdPoints !== "0.00" ? <span>{(((result.cpdPoints - result.earnedCpdPoints) < 0.0) ? 0.0 : (result.cpdPoints - result.earnedCpdPoints)).toFixed(2) }</span> : <span></span>}
                            </TableCell>
                          }
                          <TableCell align="center" className={"firstnameCol"}>
                            {result.isFavourite ? <span onClick={() => handleMarkFavoutite(result.newsfeedId, false)} style={{cursor: "pointer"}}><StarIcon></StarIcon></span> : <span onClick={() => handleMarkFavoutite(result.newsfeedId, true)}  style={{cursor: "pointer"}}><StarOutlineIcon ></StarOutlineIcon></span>}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
          </BrandedCard>
        </div>
        <div className={styles.paginationControlsContainer}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "95%",
            }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={filteredListData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
        </>
        }
         {filteredListData.length == 0 &&
          <div>
            <UnbrandedCard>
              <div style={{ padding: "20px" }}>
                <div style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "20px" }}>No articles found!</span>
                </div>
              </div>
            </UnbrandedCard>
          </div>
        }
      </div>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

    </>
  );
};
export default MyNewsfeed;
