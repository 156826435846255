//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";

//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
//MUI Components
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputLabel, Radio, RadioGroup, Select, TextField, Typography } from "@material-ui/core";
import CloseWhiteIcon from "@material-ui/icons/Close";
import SaveIcon from '../../../CustomIcons/SupportDetailSaveIcon';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import Modal from '../../../Components/Modal';
import { CourseModuleAccessChanges, ModuleAccessChanges, UnitCourseAccessItem, UnitModuleAccessItem, UnitTreeItem } from '../../../globalTypes';
import BrandedCard from '../../../Components/BrandedCard';
import SelectAllIcon from '../../../CustomIcons/SelectAllIcon';
import UnSelectAllIcon from '../../../CustomIcons/UnSelectAllIcon';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import getRelevantUnitTree from '../../../API/GetRelevantUnitTree';
import getUnitAllCourseModulesAccess from '../../../API/Administration/Unit/GetUnitAllCourseModulesAccess';
import Units from '../../ReportCommonComponents/Units';
import SaveMultiUnitMultiCourseModuleAccess from '../../../API/Administration/Unit/SaveMultiUnitMultiCourseModuleAccess';
import { useParams, useHistory } from 'react-router-dom';
import CourseProgressBar from './CourseProgressBar';
import GetCourseDetails from '../../../API/Administration/CourseSettings/GetCourseDetails';
import NotesIcon from '../../../CustomIcons/NotesIcon';
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import { ToggleLeftIcon } from '../../../CustomIcons/ToggleLeftIcon';

const CourseAccess = (props: any): JSX.Element => {
  const history = useHistory();
  const { courseIdToEdit } = useParams<{ courseIdToEdit: string }>();

  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, superAdminSelectedBasicOrgData } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [filterUnitName, setFilterUnitName] = useState("");
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);
  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [originalUnits, setOriginalUnits] = useState<Array<UnitTreeItem>>([]);
  const [filterCourseOrModuleName, setFilterCourseOrModuleName] = React.useState("");
  const [courseList, setCourseList] = useState<Array<UnitCourseAccessItem>>([]);
  const [courseListOriginal, setCourseListOriginal] = useState<Array<UnitCourseAccessItem>>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [howToOption, setHowToOption] = useState<number>(1);


  const [expandModuleList, setExpandModuleList] = useState<boolean>(true);
  const [expandUnitList, setExpandUnitList] = useState<boolean>(true);
  const [expandCourseList, setExpandCourseList] = useState<boolean>(true);
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [isCourseModuleAccess, setIsCourseModuleAccess] = useState<boolean>(false);
  const [isRemEmails, setIsRemEmails] = useState<boolean>(false);
  const [isUnitRule, setIsUnitRule] = useState<boolean>(false);
  const [reminderID, setreminderID] = useState<number>(0);

  const [includeOptional, setIncludeOptional] = React.useState<boolean>(false);

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".inactiveoptional",
        title: "Enable Assign Courses",
        intro: "Check to Enable Assign Courses",
      },

    ]
  })

  const setGuideMe = (includeOptional: boolean = false) => {
    let updateSteps = []
  
    if(includeOptional)
    {

    updateSteps.push(
      {
        element: "#courseModuleNameSearch",
        title: "Filter",
        intro: allResourcesToTranslations.coursemodulenamesearch,
      },
      // {
      //   element: ".selectAllButton",
      //   title: allResourcesToTranslations.selectall,
      //   intro: allResourcesToTranslations.selectallbuttoncoursemodule,
      // },
      // {
      //   element: ".unselectAllButton",
      //   title: allResourcesToTranslations.unselectall,
      //   intro: allResourcesToTranslations.unselectallbuttoncoursemodule,
      // },
      {
        element: ".modulelist",
        title: "Modules",
        intro: allResourcesToTranslations.modulelisttranngplanguide,
      },
      {
        element: ".selectallunit",
        title: "Select All",
        intro: allResourcesToTranslations.selectallunits,
      },
      {
        element: ".deselectallunit",
        title: "Unselect All",
        intro: allResourcesToTranslations.deselectallunits,
      },
      {
        element: ".expandallunit",
        title: "Expand All",
        intro: allResourcesToTranslations.cbexpandallunit,
      },
      {
        element: ".collapseallunit",
        title: "Collapse All",
        intro: allResourcesToTranslations.cbcollapseallunit,
      },
      {
        element: ".unitFilterSearch",
        title: "Unit Filter",
        intro: allResourcesToTranslations.unitfilter,
      },
      {
        element: ".unittree",
        title: "Units",
        intro: allResourcesToTranslations.unitstrainingplanguide,
      },
      {
        element: ".saveData",
        title: "Save Details",
        intro: allResourcesToTranslations.saveglobalcompliancerulesguideme
      },
      {
        element: ".nextData",
        title: "Move to Next",
        intro: allResourcesToTranslations.coursenext
      });
    }
    else
    {
      updateSteps.push( 
        {
          element: ".inactiveoptional",
        title: "Enable Assign Courses",
        intro: "Check to Enable Assign Courses",
        });
     }
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };


  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  const handleSave = () => {
    // Save
    setIsSaving(true);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    let unitIds = GetIfAnyUnitSelected();
    let errorString = '';
    if (unitIds.length === 0) {
      errorString = errorString + allResourcesToTranslations.unitselecterror;
    }

    if (errorString !== '') {
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      setSaveSuccess(false);
      setIsSaving(false);
      return;
    }

    if (!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && !!unitIds && unitIds.length > 0) {
      SaveMultiUnitMultiCourseModuleAccess(oidcUser.access_token, userId, orgDomain, parseInt(orgId), unitIds.toString(),
        courseAccessChanges.accessChangedCourseIDs, courseAccessChanges.grantedModuleIDs, howToOption).then((courseListSaveRes) => {
          if (!!courseListSaveRes) {
            let isSuccess = !!courseListSaveRes.isSuccess && courseListSaveRes.isSuccess;
            if (!!isSuccess && isSuccess) {

              let checkIfStatusUpdateRequired: number = 0;
              if (!!courseAccessChanges.accessChangedCourseIDs && courseAccessChanges.accessChangedCourseIDs.trim() != '') {
                checkIfStatusUpdateRequired = courseAccessChanges.accessChangedCourseIDs.split(',').length;
              }
            }
            let isStatusUpdateFailed = (isSuccess && courseListSaveRes.statusUpdateError != null && courseListSaveRes.statusUpdateError != undefined &&
              courseListSaveRes.statusUpdateError.trim() !== "");

            setShowWarningModal(true);
            setHasErrored(isSuccess ? isStatusUpdateFailed : isSuccess);
            setSaveSuccess(isSuccess && !isStatusUpdateFailed);
            setModalPopupMessage(isSuccess ? (howToOption === 1 ? allResourcesToTranslations.assigncoursetrainingplansuccess :
              howToOption === 2 ? allResourcesToTranslations.overwriteassigncoursetrainingplansuccess :
                howToOption === 3 ? allResourcesToTranslations.unassigncoursetrainingplansuccess :
                  allResourcesToTranslations.assigncoursetrainingplansuccess) + (isStatusUpdateFailed ? " But status updated is failed!" : "")
              : allResourcesToTranslations.assigncoursetrainingplanerror + (courseListSaveRes.error !== "" ? "; Reason: " + courseListSaveRes.error : ""));

            setCourseList([...courseListOriginal]);
            setFilterCourseOrModuleName('');
            refreshCourseModuleList(false);
            refreshUnitList(false);
            setisResetClicked(true);
            setHowToOption(1);

            setShowWarningModal(true);
            setHasErrored(false);
            setSaveSuccess(true);
            setModalPopupMessage("Course Access has been saved successfully!");
            setIsSaving(false);

          }
          else {
            setShowWarningModal(true);
            setHasErrored(true);
            setSaveSuccess(false);
            setModalPopupMessage((howToOption === 1 ? allResourcesToTranslations.assigncoursetrainingplanerror :
              howToOption === 2 ? allResourcesToTranslations.overwritecoursetrainingplanerror :
                howToOption === 3 ? allResourcesToTranslations.unassigncoursetrainingplanerror :
                  allResourcesToTranslations.assigncoursetrainingplanerror) + (courseListSaveRes.error !== "" ? "; Reason: " + courseListSaveRes.error : ""));
          }
          setIsSaving(false);
        }).catch((error) => {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage(allResourcesToTranslations.assigncoursetrainingplanerror + (!!error && error.toString().trim() != "" ? (" Reason: Exception while saving as " + error) : ""));
          setIsSaving(false)
          setIsChanged(true);
        });
    }

  }
  const handleNext = () => {
    // Save

    if (!isRemEmails) {
      history.push({
        pathname: `/courseemailrulesadd/` + courseIdToEdit
      });
    }
    else if (!isUnitRule) {
      history.push({
        pathname: `/coursecompliancerules/` + courseIdToEdit
      });
    }
    else {
      history.push({
        pathname: `/coursesummary/` + courseIdToEdit
      });
    }

  }

  const handleConfirmModal = (value: boolean) => {
    setshowConfirmModal(value);
  }

  const handleCancelFromConfirmPopup = () => {
    handleConfirmModal(false);
    setIsSaving(false);
  }
  const handleProceed = () => {
    handleConfirmModal(false);
  }

  // Only the filtered courses are selected or unselected
  const handleAllCoursesForGivenSelection = (givenFlag: boolean) => {

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch ||
        (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 &&
          item.unitModuleAccessItems.some((m: UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
        return {
          ...item
        }
      }
      if (!!item && !!item.courseID) {
        item.areAllModulesGranted = givenFlag;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          return {
            ...elem,
            isUnitGranted: item.areAllModulesGranted
          }
        }
        );
        return {
          ...item,
          isAnyUnitGranted: (!!item.unitModuleAccessItems &&
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length > 0)) ? true : false
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);

  }

  // Set the filter matched courses/modules 
  const setFilteredCoursesOnly = (givenCourseList: Array<UnitCourseAccessItem>) => {
    const filteredCourseList = givenCourseList.map((item: UnitCourseAccessItem) => {
      if (!!item && !!item.courseID) {
        if (!!item.courseName && (!!filterCourseOrModuleName !== true ||
          (!!filterCourseOrModuleName && item.courseName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {

          return {
            ...item,
            isSearchMatch: true
          }
        }
        if (!!item.unitModuleAccessItems) {
          item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
            if (!!elem.moduleName && (!!filterCourseOrModuleName !== true ||
              (!!filterCourseOrModuleName && elem.moduleName.trim().toLowerCase().includes(filterCourseOrModuleName.trim().toLowerCase())))) {

              return {
                ...elem,
                isSearchMatch: true
              }
            }
            else {
              return {
                ...elem,
                isSearchMatch: false
              }
            }
          });
        }
        return {
          ...item,
          isSearchMatch: false
        }
      }
      return item;
    });

    setCourseList([...filteredCourseList]);

  }

  const refreshUnitList = (resetLoading: boolean) => {
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);
        setOriginalUnits(unitTreeRes.unitTreeItems);
        if (resetLoading) {
          setLoading(false);
        }
      }
    }).catch((error) => {
      if (resetLoading) {
        setLoading(false);
      }
    });
  }

  const refreshCourseModuleList = (resetLoading: boolean) => {
    getUnitAllCourseModulesAccess(oidcUser.access_token, userId, orgDomain, parseInt(orgId), 0).then((courseListRes) => {
      //if (isMounted.current) {
      if (!!courseListRes && !!courseListRes.isSuccess && courseListRes.isSuccess && !!courseListRes.unitCourseAccessItems) {
        let currCourseList = courseListRes.unitCourseAccessItems.filter((item: any) => item.courseID === parseInt(courseIdToEdit));
        let unselectAllCoursesinList: any = unselectAllCoursesinGivenList(currCourseList);
        setCourseList(unselectAllCoursesinList);
        setCourseListOriginal(unselectAllCoursesinList);
        setFilteredCoursesOnly(unselectAllCoursesinList);
        cloneGivenListAndSetOriginalCourseList(unselectAllCoursesinList);
      }
      // }
      if (resetLoading) {
        setLoading(false);
      }
    }).catch((error) => {
      if (resetLoading) {
        setLoading(false);
      }
    })
  }

  const checkIfBothModuleListsAreInvalid = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges) => {
    // Check if both the modules are invalid
    if ((modules1 == null || modules1 == undefined) && (modules2 == null || modules2 == undefined)) {
      moduleAccessChanges.reason = "Both of the given two Module lists are invalid";
      moduleAccessChanges.isAnyChanged = false;
      moduleAccessChanges.grantedModuleIDs = "";
      moduleAccessChanges.differedModuleIDs = "";
      return true;
    }
    return false;
  }

  const checkIfAnyModuleListIsInvalid = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges) => {
    // Check if any module list is invalid compared to other
    if (!!modules1 && modules1.length > 0 && (modules2 == null || modules2 == undefined)) {
      moduleAccessChanges.reason = "Second of the given two Module lists is invalid";
      moduleAccessChanges.isAnyChanged = true;
      moduleAccessChanges.grantedModuleIDs =
        modules1.filter(m => m.isUnitGranted).map(m => m.moduleID.toString()).toString();
      moduleAccessChanges.differedModuleIDs =
        modules1.map(m => m.moduleID.toString()).toString();
      return true;
    }
    // Check if any module list is invalid compared to other
    if (!!modules2 && (modules1 == null || modules1 == undefined)) {
      moduleAccessChanges.reason = "First of the given two Module lists is invalid";
      moduleAccessChanges.isAnyChanged = true;
      moduleAccessChanges.grantedModuleIDs =
        modules2.filter(m => m.isUnitGranted).map(m => m.moduleID.toString()).toString();
      moduleAccessChanges.differedModuleIDs =
        modules2.map(m => m.moduleID.toString()).toString();
      return true;
    }
    return false;
  }

  // Compare the modules and collect
  const collectModuleAccessChanges = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[]) => {
    let moduleAccessChanges: ModuleAccessChanges = {
      isAnyChanged: false,
      grantedModuleIDs: "",
      differedModuleIDs: "",
      reason: ""
    };
    if (checkIfBothModuleListsAreInvalid(modules1, modules2, moduleAccessChanges)) {
      return moduleAccessChanges;
    }
    if (checkIfAnyModuleListIsInvalid(modules1, modules2, moduleAccessChanges)) {
      return moduleAccessChanges;
    }
    moduleAccessChanges = collectDifferedModules(modules1, modules2, moduleAccessChanges, false);
    moduleAccessChanges = collectDifferedModules(modules2, modules1, moduleAccessChanges, true);
    return moduleAccessChanges;
  }

  // Compare the courses and their modules and collect the access changes
  const collectCourseModuleAccessChanges = () => {

    let courseAccessChanges: CourseModuleAccessChanges = {
      accessChangedCourseIDs: "",
      isAnyChanged: false,
      grantedModuleIDs: "",
      differedModuleIDs: "",
      reason: ""
    };
    // Collect course and module access changes
    for (let i = 0; i < courseListOriginal.length; i++) {
      let curCourseOriginal = courseListOriginal[i];
      let curModuleAccessChanges: ModuleAccessChanges = {
        isAnyChanged: false,
        grantedModuleIDs: "",
        differedModuleIDs: "",
        reason: ""
      };
      let courseMatched = courseList.filter((curCourse) => !!curCourse && !!curCourse.courseID && curCourse.courseID == curCourseOriginal.courseID);

      if (!!courseMatched && courseMatched.length > 0 &&
        (((curModuleAccessChanges = collectModuleAccessChanges(curCourseOriginal.unitModuleAccessItems, courseMatched[0].unitModuleAccessItems)) != null &&
          curModuleAccessChanges != undefined &&
          curModuleAccessChanges.isAnyChanged
        ))) {
        let curCourseIDTxt = curCourseOriginal.courseID.toString();
        courseAccessChanges.isAnyChanged = true;
        courseAccessChanges.accessChangedCourseIDs = (courseAccessChanges.accessChangedCourseIDs.trim() === "") ?
          curCourseIDTxt : courseAccessChanges.accessChangedCourseIDs + "," + curCourseIDTxt;
        courseAccessChanges.grantedModuleIDs = (courseAccessChanges.grantedModuleIDs.trim() === "") ?
          curModuleAccessChanges.grantedModuleIDs : courseAccessChanges.grantedModuleIDs + "," + curModuleAccessChanges.grantedModuleIDs;
        courseAccessChanges.differedModuleIDs = (courseAccessChanges.differedModuleIDs.trim() === "") ?
          curModuleAccessChanges.differedModuleIDs : courseAccessChanges.differedModuleIDs + "," + curModuleAccessChanges.differedModuleIDs;
      }
    }
    return courseAccessChanges;
  }

  const unselectAllCoursesinGivenList = (givenCourseList: Array<UnitCourseAccessItem>) => {
    if (!!givenCourseList !== true) {
      return givenCourseList;
    }
    let unselectAllCoursesinList: Array<UnitCourseAccessItem> = [];
    unselectAllCoursesinList = givenCourseList.map((c: UnitCourseAccessItem) => {
      let curCItem: UnitCourseAccessItem = {
        isAnyUnitGranted: false,
        courseID: c.courseID,
        courseName: c.courseName,
        expanded: c.expanded,
        areAllModulesGranted: false,
        isSearchMatch: c.isSearchMatch,
        unitModuleAccessItems: c.unitModuleAccessItems.map((m: UnitModuleAccessItem) => {
          let curMItem: UnitModuleAccessItem = {
            isUnitGranted: false,
            moduleID: m.moduleID,
            moduleName: m.moduleName,
            isSearchMatch: m.isSearchMatch
          };
          return curMItem;
        })
      }
      return curCItem;
    });
    return unselectAllCoursesinList;
  }

  const cloneGivenList = (givenCourseList: Array<UnitCourseAccessItem>) => {
    if (!!givenCourseList !== true) {
      return givenCourseList;
    }
    let originalList: Array<UnitCourseAccessItem> = [];
    originalList = givenCourseList.map((c: UnitCourseAccessItem) => {
      let curCItem: UnitCourseAccessItem = {
        isAnyUnitGranted: c.isAnyUnitGranted,
        courseID: c.courseID,
        courseName: c.courseName,
        expanded: c.expanded,
        areAllModulesGranted: c.areAllModulesGranted,
        isSearchMatch: c.isSearchMatch,
        unitModuleAccessItems: c.unitModuleAccessItems.map((m: UnitModuleAccessItem) => {
          let curMItem: UnitModuleAccessItem = {
            isUnitGranted: m.isUnitGranted,
            moduleID: m.moduleID,
            moduleName: m.moduleName,
            isSearchMatch: m.isSearchMatch
          };
          return curMItem;
        })
      }
      return curCItem;
    });
    return originalList;
  }


  const collectDifferedModules = (modules1: UnitModuleAccessItem[], modules2: UnitModuleAccessItem[], moduleAccessChanges: ModuleAccessChanges, grantedModulesFromFirst: boolean = false) => {

    // Iterate modules list1 and collect extra or different
    for (let i = 0; i < modules1.length; i++) {
      let curLoopModule = modules1[i];
      // Skip invalid modules of first list
      if (!!curLoopModule !== true) {
        continue;
      }
      // Get the already collected differed and granted modules array
      let curGrantedArr = moduleAccessChanges.grantedModuleIDs.split(",");
      let curDiffArr = moduleAccessChanges.differedModuleIDs.split(",");
      // Get matched modules from second list and compare access changes 
      // Collect all the differed modules and collect only the granted modules of only one list based on the given flag grantedModulesFromFirst
      let mod2Matched = modules2.filter((curModule) => !!curModule && !!curModule.moduleID &&
        curModule.moduleID == curLoopModule.moduleID);
      // If there is a match found for the current module
      if (!!mod2Matched && mod2Matched.length > 0 && !!mod2Matched[0]) {
        let moduleIDTxtToAdd = curLoopModule.moduleID.toString();
        if (mod2Matched[0].isUnitGranted !== curLoopModule.isUnitGranted) {
          // If there is any access change
          moduleAccessChanges.isAnyChanged = true;
          if (!curDiffArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.differedModuleIDs = (moduleAccessChanges.differedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.differedModuleIDs + "," + moduleIDTxtToAdd;
          }
          // If the new granting is to the second list
          if (!grantedModulesFromFirst && (mod2Matched[0].isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
          // If the new granting is to the first list
          if (grantedModulesFromFirst && (curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
        }
        else {
          // Take unmodified grant from the second list
          if (!grantedModulesFromFirst && (mod2Matched[0].isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
          // Take unmodified grant from the first list
          if (grantedModulesFromFirst && (curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
            moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
              moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
          }
        }
      }
      // If there is NO match found for the current module
      else {
        // If there is any access change
        moduleAccessChanges.isAnyChanged = true;
        let moduleIDTxtToAdd = curLoopModule.moduleID.toString();
        if (!curDiffArr.includes(moduleIDTxtToAdd)) {
          moduleAccessChanges.differedModuleIDs = (moduleAccessChanges.differedModuleIDs.trim() === "") ?
            moduleIDTxtToAdd : moduleAccessChanges.differedModuleIDs + "," + moduleIDTxtToAdd;
        }
        // If the new granting is to the first list
        if (grantedModulesFromFirst && (curLoopModule.isUnitGranted) && !curGrantedArr.includes(moduleIDTxtToAdd)) {
          moduleAccessChanges.grantedModuleIDs = (moduleAccessChanges.grantedModuleIDs.trim() === "") ?
            moduleIDTxtToAdd : moduleAccessChanges.grantedModuleIDs + "," + moduleIDTxtToAdd;
        }
      }
    }
    return moduleAccessChanges;
  }

  const onAccordianChange = (event: any, expanded: boolean) => {
    if (!!event && !!event.currentTarget && !!event.currentTarget.ariaExpanded) {
      event.currentTarget.ariaExpanded = !!expanded ? "true" : "false";
    }
  }

  const handleCourseExpandClick = (e: any, courseData: UnitCourseAccessItem) => {
    handleCourseExpansion(courseData);
  }

  const handleCourseExpansion = (courseData: UnitCourseAccessItem) => {
    if (!!courseData.expanded) {
      courseData.expanded = !courseData.expanded;
    }
    else {
      courseData.expanded = true;
    }

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          return {
            ...elem
          }
        }
        );
        return {
          ...item,
          expanded: courseData.expanded
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);
  }

  // Only the filtered data is selected
  const handleModuleSelect = (moduleData: UnitModuleAccessItem, courseData: UnitCourseAccessItem) => {

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch ||
        (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 &&
          item.unitModuleAccessItems.some((m: UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
        return {
          ...item
        }
      }
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        moduleData.isUnitGranted = moduleData.isUnitGranted && !moduleData.isUnitGranted ? false : !moduleData.isUnitGranted;
        item.areAllModulesGranted = (!!item.unitModuleAccessItems &&
          ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length == item.unitModuleAccessItems.length)) ? true : false;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          if (moduleData.moduleID === elem.moduleID) {
            return {
              ...elem,
              isUnitGranted: moduleData.isUnitGranted
            }
          }
          return {
            ...elem,
            isUnitGranted: elem.isUnitGranted
          }
        }
        );
        return {
          ...item,
          isAnyUnitGranted: (!!item.unitModuleAccessItems &&
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length > 0)) ? true : false
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }


  const handleAllCourseSelection = () => {
    handleAllCoursesForGivenSelection(true);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const handleAllCourseUnselection = () => {
    handleAllCoursesForGivenSelection(false);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const cloneGivenListAndSetOriginalCourseList = (givenCourseList: Array<UnitCourseAccessItem>) => {
    let localList: Array<UnitCourseAccessItem> = [];
    localList = cloneGivenList(givenCourseList);
    setCourseListOriginal(localList);
  }

  const handleCourseSelect = (courseData: UnitCourseAccessItem) => {

    const updatedCourseData = courseList.map((item: UnitCourseAccessItem) => {
      if (!(!!item && (item.isSearchMatch ||
        (!!item.unitModuleAccessItems && item.unitModuleAccessItems.length > 0 &&
          item.unitModuleAccessItems.some((m: UnitModuleAccessItem) => !!m && m.isSearchMatch))))) {
        return {
          ...item
        }
      }
      if (!!item && !!courseData && !!item.courseID && !!courseData.courseID && item.courseID == courseData.courseID) {
        item.areAllModulesGranted = !item.areAllModulesGranted;
        item.unitModuleAccessItems = item.unitModuleAccessItems.slice(0).map((elem) => {
          return {
            ...elem,
            isUnitGranted: item.areAllModulesGranted
          }
        }
        );
        return {
          ...item,
          isAnyUnitGranted: (!!item.unitModuleAccessItems &&
            ((item.unitModuleAccessItems.filter((val) => { return val.isUnitGranted; })).length > 0)) ? true : false,
        }
      }
      return item;
    });

    setCourseList([...updatedCourseData]);
    let courseAccessChanges = collectCourseModuleAccessChanges();
    setIsChanged(!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs && !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged);
  }

  const isAnyChanged = () => {
    let courseAccessChanges = collectCourseModuleAccessChanges();
    if ((!!courseAccessChanges && !!courseAccessChanges.accessChangedCourseIDs &&
      !!courseAccessChanges.isAnyChanged && courseAccessChanges.isAnyChanged)) {
      return true;
    }
    return false;
  }

  const isAnyUnitsSelected = () => {
    let unitsSelected = GetIfAnyUnitSelected();
    if (unitsSelected.length > 0) {
      return true;
    }
    return false;
  }

  const GetIfAnyUnitSelected = () => {
    let unitIds: Array<number> = [];
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
          if (filterUnitName !== "") {
            if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
              unitIds.push(unit.unitID)
            }
          } else {
            unitIds.push(unit.unitID)
          }
        }
        getSelectedUnitIds(unit.childUnitTreeItems);
      }
    }
    getSelectedUnitIds(units);
    return unitIds;
  }

  const handleHowToOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    setHowToOption(parseInt(val));
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.publishcourse + " - " + allResourcesToTranslations.step3)
    setTitleEnglish(pageTitles.step3);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.publishcourse);
    setLoading(true);
    refreshCourseModuleList(true);
    refreshUnitList(true);
    LoadCourseDetail();
    return () => { };
  }, []);


  const LoadCourseDetail = () => {
    GetCourseDetails(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      parseInt(courseIdToEdit),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
        if (result != null && result.isSuccess && result.error == null) {
          if (result != null && result.isSuccess && result.error == null) {
            if (result != null && result.isSuccess && result.error == null) {
              if (result.getCourseDetailsItems[0].unitIds.length > 0) {
                setIsCourseModuleAccess(true);
              }
              if (result.getCourseDetailsItems[0].remEscId > 0) {
                setreminderID(result.getCourseDetailsItems[0].remEscId);
                setIsRemEmails(true);
              }
              if (result.getCourseDetailsItems[0].unitRuleId > 0) {
                setIsUnitRule(true);

              }
            }
          }
        }
      });
  }


  useEffect(() => {
    setFilteredCoursesOnly(courseList);
  }, [filterCourseOrModuleName, isResetClicked])

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])
  const handleExpandCourseListClick = () => {
    setExpandCourseList(!expandCourseList);
  }
  const handleExpandUnitListClick = () => {
    setExpandUnitList(!expandUnitList);
  }

  const handleExpandModuleListClick = () => {
    setExpandModuleList(!expandModuleList);
  }

  const BackButtonRoute = () => {
    history.push({
      pathname: `/courserecourse/${courseIdToEdit}`,
    });
  }

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '35px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              {allResourcesToTranslations.coursebasedconfirmmessage} <br></br><br></br>
              <b>{allResourcesToTranslations.proceedmessage} </b>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopup}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span>
                <Button
                  onClick={handleProceed}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>

      <div>
        <div style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}>
          <CourseProgressBar courseIdToEdit={parseInt(courseIdToEdit)} step='3'/>
        </div>
        <BrandedCard>
          <div style={{ paddingLeft: '20px', paddingTop: '15px', fontWeight: 'bold' }} >
            <div onClick={(e) => { setIncludeOptional(!includeOptional); setGuideMe(!includeOptional); }} style={{ float: 'left' }} className='inactiveoptional'>
              <span style={{ marginTop: "-3px", paddingRight: "5px", float: "left" }} >{includeOptional ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
            </div>
            <div style={{ float: 'left' }}>
              <span id="allowLearnerswitch" className={styles.labelText}
                onClick={(e) => { setIncludeOptional(!includeOptional); }}
              >
                &nbsp;&nbsp;<b>{allResourcesToTranslations.step3} (Optional)</b></span>
            </div>
          </div>
          {includeOptional &&
            <div className={styles.Wrapper} style={{ paddingTop: '35px' }}>
              <div>
                <div style={{ padding: "5px", border: '1px solid #D3D6DF', borderRadius: '5px' }}>
                  <Accordion style={{ marginTop: "10px", position: 'inherit', boxShadow: "none" }}
                    expanded={expandCourseList}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="unitadmin-content"
                      id="unitadmin-header"

                      onClick={(e) => handleExpandCourseListClick()}
                    >
                      <Typography>
                        <div className={styles.lableContainer} >
                          <span className={styles.labelText}>{allResourcesToTranslations.selecttrainingplan}</span>
                        </div>
                        <div className={styles.inputContainer}>
                          <span className={styles.labelTextitalic}>{allResourcesToTranslations.selecttrainingplanmessage}</span>
                        </div>
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "20px", width: "100%" }}>
                      <div style={{ width: '97%' }}>
                        <BrandedCard>
                          <div style={{ justifyContent: "space-between", padding: "20px 0px 20px 20px" }}>
                            <div style={{ display: "grid" }}>
                              <span style={{ fontWeight: "bold", fontSize: "15px", marginTop: "10px" }}>{allResourcesToTranslations.courseenrolments} </span>

                              <div className={styles.search_longtextbox_and_buttons_container} style={{ paddingTop: "15px" }}>
                                <div id="courseModuleNameSearch" className={styles.courseModuleNameSearch} style={{ float: 'left', width: "90%" }}>
                                  <TextField style={{ width: "100%" }}
                                    label={"Course/Module Name Search"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                    value={filterCourseOrModuleName} onChange={(e) => setFilterCourseOrModuleName(e.target.value)}
                                    InputProps={{ endAdornment: (<IconButton><SearchIcon style={{ height: "20px" }} /></IconButton>) }} />
                                </div>
                                <div className={styles.courseModuleNameButtons}>
                                  <span>
                                    <CustomTooltip displayText={[allResourcesToTranslations.selectall]}>
                                      <Button id="selectAllButton" className="selectAllButton" variant="contained" color="primary" onClick={handleAllCourseSelection}>
                                        <SelectAllIcon />
                                      </Button>
                                    </CustomTooltip>
                                  </span>
                                  <span style={{ paddingRight: "2px" }}>
                                    <CustomTooltip displayText={[allResourcesToTranslations.unselectall]}>
                                      <Button id="unselectAllButton" className="unselectAllButton" variant="contained" color="primary" onClick={handleAllCourseUnselection}>
                                        <UnSelectAllIcon />
                                      </Button>
                                    </CustomTooltip>
                                  </span>
                                </div>
                                <div style={{ clear: "both" }}></div>
                              </div>
                            </div>
                          </div>
                          <div id="orgGrantedCourseList" style={{ overflowY: "scroll", scrollbarWidth: "auto", maxHeight: "400px", borderTop: "3px solid #D3D6DF" }} className="modulelist">
                            {courseList.length > 0 && courseList.map((course) => {
                              return (!!course && (course.isSearchMatch || (!!course.unitModuleAccessItems && course.unitModuleAccessItems.length > 0 &&
                                course.unitModuleAccessItems.some((m) => !!m && m.isSearchMatch)))) ?
                                (

                                  <Accordion key={course.courseID.toString() + course.courseName.toString()}
                                    //expanded={course.expanded} aria-expanded={course.expanded}
                                    expanded={expandModuleList}
                                    style={course.expanded ?
                                      { backgroundColor: "#E0E0E0", paddingTop: '10px', marginBottom: '30px', boxShadow: 'none' } : { backgroundColor: "#FFFFFF", paddingTop: '10px', marginBottom: '30px' }}
                                    onChange={(event, isexpanded) => { onAccordianChange(event, isexpanded); }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      className={course.expanded ? "MuiButtonBase-root MuiAccordionSummary-root Mui-expanded" : "MuiButtonBase-root MuiAccordionSummary-root"}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      style={{ paddingBottom: "0px", minHeight: "0px" }}
                                      onClick={(e) => handleExpandModuleListClick()}
                                    >
                                      <Checkbox size="small" id="courseSelectionCheckbox"
                                        checked={course.areAllModulesGranted || course.isAnyUnitGranted}
                                        indeterminate={!!course && course.areAllModulesGranted == false && (course.isAnyUnitGranted)}
                                        onClick={(e) => { e.stopPropagation(); handleCourseSelect(course); }}
                                        color="primary" style={{ marginTop: "-5px" }} />
                                      <Typography style={(course.isAnyUnitGranted) ? { marginTop: '6px', fontWeight: "bold" } : { marginTop: '6px' }}>
                                        {course.courseName}</Typography>
                                    </AccordionSummary>
                                    {
                                      course.unitModuleAccessItems.length > 0 && course.unitModuleAccessItems.map((module) => {
                                        return (
                                          <>
                                            <span key={module.moduleID.toString() + module.moduleName.toString()}
                                              style={{ display: "inline", marginLeft: "30px", marginTop: "-20px" }}>
                                              <Checkbox size="small" id={course.expanded ? "moduleSelectionCheckbox" : "_moduleSelectionCheckbox"}
                                                checked={module.isUnitGranted}
                                                onClick={() => handleModuleSelect(module, course)}
                                                color="primary" />
                                              <span style={(module.isUnitGranted) ? { marginTop: '6px', fontWeight: "bold", fontSize: '14px' } : { marginTop: '6px', fontSize: '14px' }}>
                                                {module.moduleName}</span>
                                            </span>
                                            <br />
                                          </>
                                        )
                                      })
                                    }
                                  </Accordion>
                                ) :
                                (<></>)
                            })}

                          </div>
                        </BrandedCard>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {/* do not delete this div */}
                {/* <div style={{display:'none'}}>
              <div className={styles.inputContainer} style={{ paddingTop: "20px" }}>
                <span className={styles.labelText}>{allResourcesToTranslations.howtoassigntrainingplan}</span>
                <div className={styles.inputContainer}>
                  <span className={styles.labelTextitalic}>{allResourcesToTranslations.howtoassignmessage}</span>
                </div>
                <div className={styles.radiodivpadging}>
                  <RadioGroup
                    name="howtooptions"
                    value={howToOption}
                    onChange={handleHowToOption}
                    style={{ display: 'flex' }}
                  >
                    <FormControlLabel
                      value={1}
                      className="howtooption1"
                      control={<Radio color="primary" className={styles.radiopadging} />}
                      label={allResourcesToTranslations.howtooption1}
                    />
                    <FormControlLabel
                      value={2}
                      className="howtooption2"
                      control={<Radio color="primary" className={styles.radiopadging} />}
                      label={allResourcesToTranslations.howtooption2}
                    />
                    <FormControlLabel
                      value={3}
                      className="howtooption3"
                      control={<Radio color="primary" className={styles.radiopadging} />}
                      label={allResourcesToTranslations.howtooption3}
                    />
                  </RadioGroup>
                </div>
                <hr></hr>
              </div>
            </div> */}
                <div style={{ padding: "5px", border: '1px solid #D3D6DF', borderRadius: '5px', marginTop: '10px' }}>
                  <Accordion style={{ marginTop: "10px", position: 'inherit', boxShadow: "none" }}
                    expanded={expandUnitList}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="unitadmin-content"
                      id="unitadmin-header"
                      onClick={(e) => handleExpandUnitListClick()}
                    >
                      <Typography>
                        <div className={styles.lableContainer} >
                          <span className={styles.labelText}>{allResourcesToTranslations.unittrainigplan}</span>
                        </div>
                        <div className={styles.inputContainer}>
                          <span className={styles.labelTextitalic}>{allResourcesToTranslations.unitstrainingplaninfo}</span>
                        </div>
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails style={{ padding: "0px", width: "100%" }}>
                      <div style={{ width: '100%', padding: '20px' }}>
                        <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} untitreeTitle={''} unitTreeTitleInfo={''} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>

                  <div className={styles.tooltipcontainer}>
                    <div className={styles.tooltip} style={{ float: "right", width: "100%" }}>
                      <div style={{ float: "left", width: "24px", paddingTop: "20px" }}><NotesIcon></NotesIcon>
                      </div>
                      <div style={{ float: "right", width: "94%" }}><p>
                        <span className="heading">{allResourcesToTranslations.assigncourseupdatenotes}</span>
                      </p> </div>
                    </div>
                  </div>
                  {/* Add new Save button */}
                  <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "20px", width: "100%", clear: "both" }}>
                    <span><CircularProgress
                      style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                      color="primary"
                      size="10"
                    />
                    </span>
                    <CustomTooltip displayText={["Save"]}>
                      <span style={{ marginRight: "-5px" }}>
                        <Button
                          disabled={isSaving || isAnyChanged() === false || howToOption === 0}
                          onClick={handleSave}
                          variant="contained"
                          color="primary"
                          className="saveData"
                        >
                          Save
                        </Button>
                      </span>
                    </CustomTooltip>

                  </div>
                </div>
              </div>
            </div>
          }

        </BrandedCard>
        <div style={{ display: "flex", flexDirection: "row-reverse", width: "100%", paddingTop: includeOptional ? '90px' : '0px', clear: "both" }}>

          <CustomTooltip displayText={["Next"]}>
            <span style={{ marginTop: "10px" }}>
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
                className="nextData"
              >
                Next
              </Button>
            </span>
          </CustomTooltip>
          <CustomTooltip displayText={["Back"]}>
            <span style={{ marginTop: "10px" }}>
              <Button
                onClick={BackButtonRoute}
                variant="contained"
                color="primary"
                className="Back"
              >
                Back
              </Button>
            </span>
          </CustomTooltip>
        </div>
      </div>
      <div className={styles.mainWrapper}>
        <div style={{ width: '100%', clear: "both" }} >
        </div>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
      </div>
    </>
  );
};
export default CourseAccess;
