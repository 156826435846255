import React, { useState, useEffect, useContext } from "react";

//MUI
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";

//Styles
import styles from "../../../styles/organisationmanagement.module.css";
import { useHistory, useParams } from "react-router";
import OrgReminderEmailRules from "./OrgReminderEmailRules";
import OrgReminderEmailRulesPolicy from "./OrgReminderEmailRulesPolicy";
import OrgReminderEmailRulesCertificate from "./OrgReminderEmailRulesCertificate";
import CustomTooltip from "../../../Components/CustomTooltip";
import { UIContext } from "../../../contexts/UIContext";
import AddIconMenu from "../../../CustomIcons/NavigationMenu/AddIconMenu";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import Modal from "../../../Components/Modal";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import SaveOrgConfigItemDetails from "../../../API/Administration/Organisation/SaveOrgConfigItemDetails";
import CloseWhiteIcon from "@material-ui/icons/Close";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import GetOrganisationEmailRule from "../../../API/Administration/Organisation/GetOrganisationEmailRule";
import { OrgEmailRule } from "../../../globalTypes";
import { Steps } from "intro.js-react";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme: any) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '18px',
      background: '#E0E0E0',
      color: '#21394F',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px 8px 0px 0px',
    },
    selected: {
      backgroundColor: '#FFF',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
      color: '#21394F !important',
    }
  }),
)((props: any) => <Tab disableRipple {...props} />);

const ReminderEmailRulesTabView = (props: any) => {
  const {
    translations: { allResourcesToTranslations }, pageTitles,
  } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute } =
    useContext(UIContext);
  const history = useHistory();
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const [tabValue, setTabValue] = useState(0);
  const [selectedTab, setSelectedTab] = useState('c');
  const [orgEmailRuleResult, setOrgEmailRuleResult] = useState<Array<OrgEmailRule>>([]);
  const [courseOrgEmailRuleResult, setCourseOrgEmailRuleResult] = useState<Array<OrgEmailRule>>([]);
  const [selectedReminderType, setSelectedReminderType] = useState<string>(props?.location?.state?.selectedReminderType ?? 'c');
  const [policyOrgEmailRuleResult, setPolicyOrgEmailRuleResult] = useState<Array<OrgEmailRule>>([]);
  const [certificateOrgEmailRuleResult, setcertificateOrgEmailRuleResult] = useState<Array<OrgEmailRule>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [stopEmailRule, setStopEmailRule] = React.useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);

  const handleTabViewChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 0) {
      setSelectedTab('c');
    }
    else if (newValue === 1) {
      setSelectedTab('p');
    }
    else if (newValue === 2) {
      setSelectedTab('e');
    }
   
  };

  const handleAddNewEmailRule = () => {
    history.push({
      pathname: `orgemailrulesadd`,
      state: {
        selectedReminderID: "0",
        selectedTab: selectedTab
      },
    });
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const RefreshRules = () => {
    LoadOrganisationEmailRule();
  };

  const handleConfirmModal = (value: boolean) => {
    setshowConfirmModal(value);
  }

  const handleCancelFromConfirmPopup = () => {
    handleConfirmModal(false);
    setIsSaving(false);
  }

  const handleStopEmailRule = (stopEmailRule: boolean) => {
    setIsSaving(true);
    handleConfirmModal(true);
  }

  const handleProceed = (stopEmailRule: boolean) => {
    handleConfirmModal(false);
    SaveOrgConfigItemDetails(oidcUser.access_token, userId, parseInt(orgId), orgDomain, false, false, false,
      false, false, false, false, 0, false, false, false, false, "stopemail").then((res) => {
        setIsSaving(false);
        if (!!res && res.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Organisation automated email rule has been saved successfully!");
          LoadOrganisationEmailRule();
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Organisation automated email rule saving failed!");
        }
      })
  }

  const LoadOrganisationEmailRule = () => {
    GetOrganisationEmailRule(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId), "en-au").then((result: any) => {
        if (result.isSuccess && result.error == null) {
          setOrgEmailRuleResult([...result.orgEmailRuleItems]);

          let courseData = [...result.orgEmailRuleItems].filter(
            (item:any) => item.reminderType.toString().toLowerCase().includes('c'));
          setCourseOrgEmailRuleResult(courseData);

          let policyData = [...result.orgEmailRuleItems].filter(
            (item:any) => item.reminderType.toString().toLowerCase().includes('p'));
          setPolicyOrgEmailRuleResult(policyData);

          let externalCertificateData = [...result.orgEmailRuleItems].filter(
            (item:any) => item.reminderType.toString().toLowerCase().includes('e'));
          setcertificateOrgEmailRuleResult(externalCertificateData);

          setStopEmailRule(result.orgEmailConfigItems[0].stopEmail);
          setLoading(false);
        } else {
          setShowWarningModal(true);
          setSaveSuccess(false);
          setHasErrored(true);
          setModalPopupMessage('Getting Organisation Email Rule has failed! Please try again!');
        }
      });
  }

  useEffect(() => {
    setTitle(allResourcesToTranslations.orgemailrule);
    setTitleEnglish(pageTitles.reminderemailrules);
    updateBackButtonRoute("");
    setLoading(true);
    LoadOrganisationEmailRule();
    
    
    if (selectedReminderType.trim() === 'c') {
      setTabValue(0);
      //setGuideMe(true, 'c');
    } else  if (selectedReminderType.trim() === 'p') {
      setTabValue(1);
     // setGuideMe(true, 'p');
    } else  if (selectedReminderType.trim() === 'e') {
      setTabValue(2);
    //  setGuideMe(true, 'e');
    }
  }, []);

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`account-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} className={styles.tabContainer} >
            <Typography component={"div"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            {/* {stopEmailRule ? "Start Emails" : "Stop Emails"} */}
            <p>
              {stopEmailRule ? "Starting emails will enable welcome Emails, completion emails, manager notifications and all email reminders."
                : "Stopping emails will disable welcome Emails, completion emails, manager notifications and all email reminders."}
            </p>
            <p>
              <b>{allResourcesToTranslations.proceedmessage} </b>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelFromConfirmPopup}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  // onClick={handleProceed}  
                  onClick={() => handleProceed(stopEmailRule)}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <UnbrandedCard>
        <div className={styles.mainWrapper}>
          <div className={styles.Wrapper}>
            <div style={{ clear: "both" }}>
              <div className={styles.lableContainer} >
                <span className={styles.labelText}>{allResourcesToTranslations.automatedemailing}</span>
              </div>
              <div className={styles.inputContainer}>
                <span className={styles.labelTextitalic}>{allResourcesToTranslations.automatedemailingnotes}</span>
              </div>
              <div>
                <div style={{ float: "left" }} className="btnStopEmail" >
                  <CustomTooltip displayText={[allResourcesToTranslations.onoffemailnotification]}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={stopEmailRule ? { backgroundColor: 'green' } : { backgroundColor: 'red' }}
                      color="primary"
                      className="saveData"
                      onClick={() => handleStopEmailRule(stopEmailRule)}
                    >
                      <span style={{ paddingTop: '5px' }}>
                        {stopEmailRule ? "Start Emails" : "Stop Emails"}
                      </span>
                    </Button>
                  </CustomTooltip>

                  <CircularProgress
                    style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                    color="primary"
                    size="10"
                  />

                </div>
                <div style={{ float: "right", paddingBottom: "10px" }} className="addNewRule">
                  <CustomTooltip displayText={[allResourcesToTranslations.addnewemailrule]}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="saveData"
                      onClick={handleAddNewEmailRule}
                    >
                      <span style={{ paddingTop: '5px' }}>
                        <AddIconMenu />
                      </span>
                    </Button>
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </div>
          <div>
            <AppBar position="static" color="default">
              <StyledTabs
                variant="fullWidth"
                value={tabValue}
                onChange={handleTabViewChange}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <StyledTab label="Course" />
                <StyledTab label="Policy" />
                <StyledTab label="External Certificate" />
              </StyledTabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>            
              <OrgReminderEmailRules coursesEmailRules={courseOrgEmailRuleResult} refreshRules={RefreshRules}/>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <OrgReminderEmailRulesPolicy policyEmailRules={policyOrgEmailRuleResult} refreshRules = {RefreshRules} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <OrgReminderEmailRulesCertificate certificateEmailRules={certificateOrgEmailRuleResult} refreshRules = {RefreshRules}/>
            </TabPanel>
          </div>
        </div>
      </UnbrandedCard>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};

export default ReminderEmailRulesTabView;
