import axios from 'axios';
import { addError } from '../../AddError';

const SaveGivenCPDProfilePoints = async (token: string, requestinguserId: number, orgDomain: string, orgId: number, profileID: number, profilePeriodID: number, 
                                    applyToQuiz: boolean, applyToLesson: boolean, excludePreviousCompletions: boolean, 
                                    givenModuleIDToPoints: string, givenPolicyIDToPoints: string, givenExtCertIDToPoints: string, applyToNewsfeed: boolean, langCode: string) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/CPDProfiles/SaveGivenCPDProfilePoints`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestinguserId,
        "OrgID": orgId,
        "ProfileID": profileID,
        "ProfilePeriodID": profilePeriodID,
        "ApplyToQuiz": applyToQuiz,
        "ApplyToLesson": applyToLesson,
        "ExcludePreviousCompletions": excludePreviousCompletions,
        "GivenModuleIDToPoints": givenModuleIDToPoints,
        "GivenPolicyIDToPoints": givenPolicyIDToPoints,
        "GivenExtCertIDToPoints": givenExtCertIDToPoints,
        "ApplyToNewsfeed": applyToNewsfeed,
        "RequestingDomain": orgDomain
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "SaveGivenCPDProfilePoints.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "SaveGivenCPDProfilePoints.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "SaveGivenCPDProfilePoints.ts", 1)
    }
    return null;
  }
};

export default SaveGivenCPDProfilePoints;