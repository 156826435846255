import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepSixInactive = () => (    
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#21394F"/>
<path d="M14.7031 20.9355C14.7031 20.1152 14.7617 19.3047 14.8789 18.5039C15.0026 17.6966 15.2142 16.9382 15.5137 16.2285C15.8132 15.5124 16.2331 14.8809 16.7734 14.334C17.3138 13.7806 18.0007 13.3477 18.834 13.0352C19.6673 12.7161 20.6764 12.5566 21.8613 12.5566C22.1413 12.5566 22.4668 12.5697 22.8379 12.5957C23.2155 12.6152 23.528 12.651 23.7754 12.7031V15.1152C23.515 15.0566 23.235 15.0111 22.9355 14.9785C22.6426 14.9395 22.3496 14.9199 22.0566 14.9199C20.8717 14.9199 19.9538 15.1087 19.3027 15.4863C18.6582 15.8639 18.2025 16.388 17.9355 17.0586C17.6751 17.7227 17.5221 18.4974 17.4766 19.3828H17.6035C17.7858 19.0638 18.0137 18.7806 18.2871 18.5332C18.5671 18.2858 18.9056 18.0905 19.3027 17.9473C19.6999 17.7975 20.1654 17.7227 20.6992 17.7227C21.5326 17.7227 22.2552 17.9017 22.8672 18.2598C23.4792 18.6113 23.9512 19.1224 24.2832 19.793C24.6152 20.4635 24.7812 21.2806 24.7812 22.2441C24.7812 23.2728 24.5827 24.1582 24.1855 24.9004C23.7884 25.6426 23.2253 26.2122 22.4961 26.6094C21.7734 27 20.9141 27.1953 19.918 27.1953C19.1888 27.1953 18.5085 27.0716 17.877 26.8242C17.2454 26.5703 16.6921 26.1862 16.2168 25.6719C15.7415 25.1576 15.3704 24.5098 15.1035 23.7285C14.8366 22.9408 14.7031 22.0098 14.7031 20.9355ZM19.8594 24.7832C20.4518 24.7832 20.9336 24.5814 21.3047 24.1777C21.6758 23.7741 21.8613 23.1426 21.8613 22.2832C21.8613 21.5866 21.6986 21.0397 21.373 20.6426C21.054 20.2389 20.569 20.0371 19.918 20.0371C19.4753 20.0371 19.0846 20.138 18.7461 20.3398C18.4141 20.5352 18.1569 20.7858 17.9746 21.0918C17.7923 21.3913 17.7012 21.7005 17.7012 22.0195C17.7012 22.3516 17.7467 22.6803 17.8379 23.0059C17.929 23.3314 18.0658 23.6276 18.248 23.8945C18.4303 24.1615 18.6549 24.3763 18.9219 24.5391C19.1953 24.7018 19.5078 24.7832 19.8594 24.7832Z" fill="#21394F"/>
</svg>

);

export default StepSixInactive