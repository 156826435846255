import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const EditIcon = () => (    
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.7178 4.64844H4.71777C4.18734 4.64844 3.67863 4.85915 3.30356 5.23422C2.92849 5.6093 2.71777 6.118 2.71777 6.64844V20.6484C2.71777 21.1789 2.92849 21.6876 3.30356 22.0627C3.67863 22.4377 4.18734 22.6484 4.71777 22.6484H18.7178C19.2482 22.6484 19.7569 22.4377 20.132 22.0627C20.5071 21.6876 20.7178 21.1789 20.7178 20.6484V13.6484" stroke="#21394F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.2178 3.14842C19.6156 2.7506 20.1552 2.5271 20.7178 2.5271C21.2804 2.5271 21.8199 2.7506 22.2178 3.14842C22.6156 3.54624 22.8391 4.08581 22.8391 4.64842C22.8391 5.21103 22.6156 5.7506 22.2178 6.14842L12.7178 15.6484L8.71777 16.6484L9.71777 12.6484L19.2178 3.14842Z" stroke="#21394F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export default EditIcon
