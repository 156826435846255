//React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { UserDetailReportResultItem } from "../../globalTypes";
// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";

//Context
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { UIContext } from "../../contexts/UIContext";
//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";
import { TranslationsContext } from '../../contexts/TranslationsContext';

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { addError } from "../../API/AddError";
//Components
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import BrushIcon from "@material-ui/icons/Brush";
import ActiveIcon from "../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../CustomIcons/Status_InActiveIcon";

// Style
import styles from "../../styles/currentAdminReport.module.css";
import GuideIcon from "../../CustomIcons/GuideIcon";

const UserDetailReportResult = (props: any) => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  const [userDetailReport, setUserDetailReportResult] = useState<
    Array<UserDetailReportResultItem>
  >(props?.location?.state?.resultItems ?? []);
  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [openCriteria, setOpenCriteria] = useState(false);
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  
  const [unitPathwayDescending, SetUnitPathwayDescending] = useState<boolean>(false);
  const [firstNameDescending, setFirstNameDescending] =    useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] = useState<boolean>(false);
  const [userNameDescending, setUserNameDescending] = useState<boolean>(false);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);
  const [externalIDDescending, setExternalIDDescending] =    useState<boolean>(false);
  const [statusDescending, setStatusDescending] =    useState<boolean>(false);
  const [userIDDescending, setUserIDDescending] =    useState<boolean>(false);
  const [unitIDDescending, setUnitIDDescending] =    useState<boolean>(false);
  const [groupByDescending, setGroupByDescending] =    useState<boolean>(false);
  const [valueDescending, setValueDescending] =    useState<boolean>(false);
  const [courseDescending, setCourseDescending] = useState<boolean>(false);  
  const [dateCreatedDescending, setDateCreatedDescending] =    useState<boolean>(false);
  const [dateArchivedDescending, setDateArchivedDescending] =    useState<boolean>(false);
  const [managerEmailDescending, setManagerEmailDescending] = useState<boolean>(false);
  const [notifyManagerDescending, setnotifyManagerDescending] = useState<boolean>(false);
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");  
  const [lastLoginDescending, setLastLoginDescending] = useState<boolean>(false);
 
  const [resultError, setResultError] = useState<string>(
    props?.location?.state?.resultError ?? ""
  );
  const [includeInactive, setIncludeInactive] = useState<boolean>(
    props?.location?.state?.includeInactive ?? false
  );
  const [selectedUnitNames, setSelectedUnitNames] = useState<string>(
    props?.location?.state?.selectedUnits ?? []
  );
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".userDetailReportList",
        title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta        
      },      
    ]
  })

  useEffect(() => 
  {
      let updateSteps = [...intro.steps];
        updateSteps.push( 
        {
        element: ".xlxs",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
        },
        {
        element: ".csv",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
        },
        {
            element: ".pdf",
            title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
        }
        // {
        //     element: ".rowShading",
        //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
        // }           
    );
  setIntro({...intro, steps: [...updateSteps]});
  },[])
  
  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };

  //const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    
  const GetDate_yymmdd = () => {
      var d =  new Date(Date.now());
      let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
      return day;   
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < userDetailReport.length; i++) {
        const r = userDetailReport[i];
        if (includeInactive) {
          resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.userName}<#>${r.email}<#>${r.externalID}<#>${r.status}<#>${r.userID}<#>${r.unitID}<#>${r.groupBy}<#>${r.value}<#>${r.delinquencyManagerEmail}<#>${r.notifyMgr}<#>${r.createdByUserName}<#>${r.updatedByUserName}<#>${r.dateCreated}<#>${(r.lastLoginDate == null || r.lastLoginDate == "") ? "Never" : r.lastLoginDate}<#>${r.dateUpdated}<#>${r.dateArchived}`);
          }
          else {
            resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.userName}<#>${r.email}<#>${r.externalID}<#>${r.status}<#>${r.userID}<#>${r.unitID}<#>${r.groupBy}<#>${r.value}<#>${r.delinquencyManagerEmail}<#>${r.notifyMgr}<#>${r.createdByUserName}<#>${r.updatedByUserName}<#>${r.dateCreated}<#>${(r.lastLoginDate == null || r.lastLoginDate == "") ? "Never" : r.lastLoginDate}<#>${r.dateUpdated}`);
          }
    }
    return resultArray;
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Account Detail Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "UserDetailReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactive?19:18, "UserDetailReport", enableRowBanding, "","","","",includeInactive,false, "", "", "", "", "", "", "").then((xlxsRes) => {
        if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            );            
            a.download = "AccountDetailReport-"+  GetDate_yymmdd() + ".xlsx" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
        }
        setExporting(false);
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed User Detail Report XLSX-EXPORT in NEWSALT ClientApp", null, "UserDetailReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started UserDetail Report CSV-EXPORT in NEWSALT ClientApp",
      null,
      "UserDetailReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactive?19:18, "UserDetailReport", "","","","",includeInactive,false, "",false, "", "", "", "", "", "").then((csvRes) => {
        if (csvRes.isSuccess) {
            const fileData = csvRes.csvDataOutput.fileData
            var a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "AccountDetailReport-"+  GetDate_yymmdd() + ".csv" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (csvRes.error !== undefined && csvRes.error !== null) {
            setExportError(csvRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed UserDetail Report CSV-EXPORT in NEWSALT ClientApp", null, "UserDetailReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFXExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started UserDetail Report PDF-EXPORT in NEWSALT ClientApp",
      null,
      "UserDetailReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactive?19:18, "UserDetailReport", enableRowBanding,"","","","",includeInactive,false, "", "", "", "", "").then((pdfRes) => {
        if (pdfRes.isSuccess) {
            const fileData = pdfRes.pdfDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "AccountDetailReport-"+  GetDate_yymmdd() + ".PDF" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
        }
        else if (pdfRes.error !== undefined && pdfRes.error !== null) {
            setExportError(pdfRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed UserDetail Report PDF-EXPORT in NEWSALT ClientApp", null, "UserDetailReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
      | "UnitPathway"
      | "FirstName"
      | "LastName"
      | "UserName"
      | "UnitID"
      | "Email"
      | "ExternalID"
      | "Status"
      | "UserID"
      | "GroupBy"
      | "Value"
      | "DateCreated"
      | "DateArchived"
      | "ManagerEmail"
      | "NotifyManager"
      |"LastLogin",
    sortGivenItemInDesc: boolean
  ) => {
    if (userDetailReport == null || userDetailReport.length <= 0) {
      return userDetailReport;
    }
    var result = userDetailReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      
      case "UnitPathway":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.unitPathway === null) {
                return -1;
              }
              if (a === null || a.unitPathway === null) {
                return 1;
              }
              if (a.unitPathway > b.unitPathway) {
                return -1;
              }
              if (a.unitPathway < b.unitPathway) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              SetUnitPathwayDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.unitPathway === null) {
                return 1;
              }
              if (a === null || a.unitPathway === null) {
                return -1;
              }
              if (a.unitPathway > b.unitPathway) {
                return 1;
              }
              if (a.unitPathway < b.unitPathway) {
                return -1;
              }
              return 0;
            });
            if (reverseNextSort) {
              SetUnitPathwayDescending(true);
            }
          }
          setUserDetailReportResult(result);
          break;     
      case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setUserDetailReportResult(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setUserDetailReportResult(result);
        break;
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setUserDetailReportResult(result);
        break;
      case "UserName":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.userName === null) {
                return -1;
              }
              if (a === null || a.userName === null) {
                return 1;
              }
              if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
                return -1;
              }
              if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUserNameDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.userName === null) {
                return 1;
              }
              if (a === null || a.userName === null) {
                return -1;
              }
              if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
                return -1;
              }
              if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUserNameDescending(true);
            }
          }
          setUserDetailReportResult(result);
          break;
      case "ExternalID":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.externalID === null) {
              return -1;
            }
            if (a === null || a.externalID === null) {
              return 1;
            }
            if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) {
              return -1;
            }
            if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setExternalIDDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.externalID === null) {
              return 1;
            }
            if (a === null || a.externalID === null) {
              return -1;
            }
            if (a.externalID.toLowerCase() < b.externalID.toLowerCase()) {
              return -1;
            }
            if (a.externalID.toLowerCase() > b.externalID.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setExternalIDDescending(true);
          }
        }
        setUserDetailReportResult(result);
        break;
      case "Status":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.status === null) {
                return -1;
              }
              if (a === null || a.status === null) {
                return 1;
              }
              if (a.status.toLowerCase() > b.status.toLowerCase()) {
                return -1;
              }
              if (a.status.toLowerCase() < b.status.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setStatusDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.status === null) {
                return 1;
              }
              if (a === null || a.status === null) {
                return -1;
              }
              if (a.status.toLowerCase() < b.status.toLowerCase()) {
                return -1;
              }
              if (a.status.toLowerCase() > b.status.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setStatusDescending(true);
            }
          }
          setUserDetailReportResult(result);
          break;
      case "UnitID":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.unitID === null) {
                  return -1;
                }
                if (a === null || a.unitID === null) {
                  return 1;
                }
                if (a.unitID > b.unitID) {
                  return -1;
                }
                if (a.unitID < b.unitID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUnitIDDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.unitID === null) {
                  return 1;
                }
                if (a === null || a.unitID === null) {
                  return -1;
                }
                if (a.unitID < b.unitID) {
                  return -1;
                }
                if (a.unitID > b.unitID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUnitIDDescending(true);
              }
            }
            setUserDetailReportResult(result);
            break;
      case "UserID":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.userID === null) {
                  return -1;
                }
                if (a === null || a.userID === null) {
                  return 1;
                }
                if (a.userID > b.userID) {
                  return -1;
                }
                if (a.userID < b.userID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUserIDDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.userID === null) {
                  return 1;
                }
                if (a === null || a.userID === null) {
                  return -1;
                }
                if (a.userID < b.userID) {
                  return -1;
                }
                if (a.userID > b.userID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUserIDDescending(true);
              }
            }
            setUserDetailReportResult(result);
            break;
      case "DateCreated":
          if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                  if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return -1; }
                  if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return 1; }
                  var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                  var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                  if(dta > dtb) { return -1; }
                  if(dta < dtb) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setDateCreatedDescending(false)
              }
          } else {
              result.sort((a, b) => {
                  if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return 1; }
                  if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return -1; }
                  var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                  var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                  if(dta > dtb) { return 1; }
                  if(dta < dtb) { return -1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setDateCreatedDescending(true)
              }
          }
          setUserDetailReportResult(result);
          break;
      case "GroupBy":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.groupBy === null) {
                  return -1;
                }
                if (a === null || a.groupBy === null) {
                  return 1;
                }
                if (a.groupBy.toLowerCase() > b.groupBy.toLowerCase()) {
                  return -1;
                }
                if (a.groupBy.toLowerCase() < b.groupBy.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setGroupByDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.groupBy === null) {
                  return 1;
                }
                if (a === null || a.groupBy === null) {
                  return -1;
                }
                if (a.groupBy.toLowerCase() < b.groupBy.toLowerCase()) {
                  return -1;
                }
                if (a.groupBy.toLowerCase() > b.groupBy.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setGroupByDescending(true);
              }
            }
            setUserDetailReportResult(result);
            break;
      case "Value":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.value === null) {
                    return -1;
                  }
                  if (a === null || a.value === null) {
                    return 1;
                  }
                  if (a.value.toLowerCase() > b.value.toLowerCase()) {
                    return -1;
                  }
                  if (a.value.toLowerCase() < b.value.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setValueDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.value === null) {
                    return 1;
                  }
                  if (a === null || a.value === null) {
                    return -1;
                  }
                  if (a.value.toLowerCase() < b.value.toLowerCase()) {
                    return -1;
                  }
                  if (a.value.toLowerCase() > b.value.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setValueDescending(true);
                }
              }
              setUserDetailReportResult(result);
              break;
      case "DateArchived":
            if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                    if (b === null || b.dateArchived === null || b.dateArchived.trim() === "") { return -1; }
                    if (a === null || a.dateArchived === null || a.dateArchived.trim() === "") { return 1; }
                    var dta = new Date(a.dateArchived.split("/").reverse().join("-"))
                    var dtb = new Date(b.dateArchived.split("/").reverse().join("-"))
                    if(dta > dtb) { return -1; }
                    if(dta < dtb) { return 1; }
                    return 0;
                })
                if (reverseNextSort) {
                    setDateArchivedDescending(false)
                }
            } else {
                result.sort((a, b) => {
                    if (b === null || b.dateArchived === null || b.dateArchived.trim() === "") { return 1; }
                    if (a === null || a.dateArchived === null || a.dateArchived.trim() === "") { return -1; }
                    var dta = new Date(a.dateArchived.split("/").reverse().join("-"))
                    var dtb = new Date(b.dateArchived.split("/").reverse().join("-"))
                    if(dta > dtb) { return 1; }
                    if(dta < dtb) { return -1; }
                    return 0;
                })
                if (reverseNextSort) {
                    setDateArchivedDescending(true)
                }
            }
            setUserDetailReportResult(result);
            break;
      case "ManagerEmail":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.delinquencyManagerEmail === null) {
                  return -1;
                }
                if (a === null || a.delinquencyManagerEmail === null) {
                  return 1;
                }
                if (a.delinquencyManagerEmail.toLowerCase() > b.delinquencyManagerEmail.toLowerCase()) {
                  return -1;
                }
                if (a.delinquencyManagerEmail.toLowerCase() < b.delinquencyManagerEmail.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setManagerEmailDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.delinquencyManagerEmail === null) {
                  return 1;
                }
                if (a === null || a.delinquencyManagerEmail === null) {
                  return -1;
                }
                if (a.delinquencyManagerEmail.toLowerCase() < b.delinquencyManagerEmail.toLowerCase()) {
                  return -1;
                }
                if (a.delinquencyManagerEmail.toLowerCase() > b.delinquencyManagerEmail.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setManagerEmailDescending(true);
              }
            }
            setUserDetailReportResult(result);
            break;
      case "LastLogin":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.lastLoginDate === null || b.lastLoginDate.trim() === "") { return -1; }
                        if (a === null || a.lastLoginDate === null || a.lastLoginDate.trim() === "") { return 1; }
                        var dta = new Date(a.lastLoginDate.split("/").reverse().join("-"))
                        var dtb = new Date(b.lastLoginDate.split("/").reverse().join("-"))
                        if(dta > dtb) { return -1; }
                        if(dta < dtb) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                      setLastLoginDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.lastLoginDate === null || b.lastLoginDate.trim() === "") { return 1; }
                        if (a === null || a.lastLoginDate === null || a.lastLoginDate.trim() === "") { return -1; }
                        var dta = new Date(a.lastLoginDate.split("/").reverse().join("-"))
                        var dtb = new Date(b.lastLoginDate.split("/").reverse().join("-"))
                        if(dta > dtb) { return 1; }
                        if(dta < dtb) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                      setLastLoginDescending(true)
                    }
                }
                setUserDetailReportResult(result);
                break;
                case "DateCreated":
                  if (sortGivenItemInDesc) {
                      result.sort((a, b) => {
                          if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return -1; }
                          if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return 1; }
                          var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                          var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                          if(dta > dtb) { return -1; }
                          if(dta < dtb) { return 1; }
                          return 0;
                      })
                      if (reverseNextSort) {
                          setDateCreatedDescending(false)
                      }
                  } else {
                      result.sort((a, b) => {
                          if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return 1; }
                          if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return -1; }
                          var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                          var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                          if(dta > dtb) { return 1; }
                          if(dta < dtb) { return -1; }
                          return 0;
                      })
                      if (reverseNextSort) {
                          setDateCreatedDescending(true)
                      }
                  }
                  setUserDetailReportResult(result);
                  break;
      default:
      break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "UnitPathway"
      | "FirstName"
      | "LastName"
      | "UserName"
      | "UnitID"
      | "Email"
      | "ExternalID"
      | "Status"
      | "UserID"
      | "GroupBy"
      | "Value"
      | "DateCreated"
      | "DateArchived"
      | "ManagerEmail"
      | "NotifyManager"
      |"LastLogin",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setUserDetailReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    SetUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    SetUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickUnitIDAsc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(false);
    sortResultsBy("UnitID", false);
  };

  const clickUnitIDDesc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(true);
    sortResultsBy("UnitID", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };
  const clickUserNameAsc = () => {
    setReverseNextSort(false);
    setUserNameDescending(false);
    sortResultsBy("UserName", false);
  };

  const clickUserNameDesc = () => {
    setReverseNextSort(false);
    setUserNameDescending(true);
    sortResultsBy("UserName", true);
  };
  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickExternalIDAsc = () => {
    setReverseNextSort(false);
    setExternalIDDescending(false);
    sortResultsBy("ExternalID", false);
  };

  const clickExternalIDDesc = () => {
    setReverseNextSort(false);
    setExternalIDDescending(true);
    sortResultsBy("ExternalID", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickUserIDAsc = () => {
    setReverseNextSort(false);
    setUserIDDescending(false);
    sortResultsBy("UserID", false);
  };

  const clickUserIDDesc = () => {
    setReverseNextSort(false);
    setUserIDDescending(true);
    sortResultsBy("UserID", true);
  };

  const clickGroupByAsc = () => {
    setReverseNextSort(false);
    setGroupByDescending(false);
    sortResultsBy("GroupBy", false);
  };

  const clickGroupByDesc = () => {
    setReverseNextSort(false);
    setGroupByDescending(true);
    sortResultsBy("GroupBy", true);
  };

  const clickValueAsc = () => {
    setReverseNextSort(false);
    setValueDescending(false);
    sortResultsBy("Value", false);
  };

  const clickValueDesc = () => {
    setReverseNextSort(false);
    setValueDescending(true);
    sortResultsBy("Value", true);
  };


  const clickDateCreatedAsc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(false);
    sortResultsBy("DateCreated", false);
  };

  const clickDateCreatedDesc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(true);
    sortResultsBy("DateCreated", true);
  };

  const clickDateArchivedAsc = () => {
    setReverseNextSort(false);
    setDateArchivedDescending(false);
    sortResultsBy("DateArchived", false);
  };

  const clickDateArchivedDesc = () => {
    setReverseNextSort(false);
    setDateArchivedDescending(true);
    sortResultsBy("DateArchived", true);
  };

  const clickManagerEmailAsc = () => {
    setReverseNextSort(false);
    setManagerEmailDescending(false);
    sortResultsBy("ManagerEmail", false);
  };

  const clickManagerEmailDesc = () => {
    setReverseNextSort(false);
    setManagerEmailDescending(true);
    sortResultsBy("ManagerEmail", true);
  };

  const clickNotifyManagerAsc = () => {
    setReverseNextSort(false);
    setnotifyManagerDescending(false);
    sortResultsBy("NotifyManager", false);
  };

  const clickNotifyManagerDesc = () => {
    setReverseNextSort(false);
    setnotifyManagerDescending(true);
    sortResultsBy("NotifyManager", true);
  };


  const clickLastLoginAsc = () => {
    setReverseNextSort(false);
    setLastLoginDescending(false);
    sortResultsBy("LastLogin", false);
  };

  const clickLastLoginDesc = () => {
    setReverseNextSort(false);
    setLastLoginDescending(true);
    sortResultsBy("LastLogin", true);
  };
  const initialSort = () => {
    setCurrentSortBy("UnitPathway");
    setUnitIDDescending(false);  
    setLastNameDescending(false);
    setFirstNameDescending(false);
    setEmailDescending(false);

    setStatusDescending(false);
    setUserIDDescending(false);
    setGroupByDescending(false);
    setValueDescending(false);
    setLastLoginDescending(false);
    setDateCreatedDescending(false);
    setDateArchivedDescending(false);
    setManagerEmailDescending(false);
    setnotifyManagerDescending(false);
    getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      if (res.isSuccess) {
        let curdt = res.currentDateTimeText;
        setReportRunTimeTaken(curdt);
      } else {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
      }
     }).catch ((error) => {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
     });
    setPage(0);
  };

  useEffect(() => {
    initialSort();
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Completed userdetail Report in NEWSALT ClientApp",
      null,
      "UserDetailReportResult.tsx",
      6, EnableActivityLogs
    );
  }, []);

  if (
    resultError !== null &&
    resultError !== undefined &&
    resultError.trimLeft().trimRight().length > 0
  ) {
    setTitle(allResourcesToTranslations.userdetailreport);
    setTitleEnglish(pageTitles.userdetailreport);
    updateBackButtonRoute("/userdetailreport");
    //setExportError("");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div style={{ color: "red" }}>Error: {resultError}</div>
          <br />
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else if (userDetailReport === null || userDetailReport.length < 1) {
    setTitle(allResourcesToTranslations.userdetailreport);
    setTitleEnglish(pageTitles.userdetailreport);
    //setExportError("");
    updateBackButtonRoute("/userdetailreport");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div>No Results to Display</div>
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else {
    setTitle(allResourcesToTranslations.userdetailreport);
    setTitleEnglish(pageTitles.userdetailreport);
    updateBackButtonRoute("/userdetailreport");
  }

  const GetSelectedUnitsSummary = () => {
    if (!!selectedUnitNames) {
      let arr = selectedUnitNames.split(",");
      return selectedUnitNames != "" && arr.length > 5 ? (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",", 5).map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
          <CustomTooltip
            displayText={selectedUnitNames.split(",").splice(5, arr.length)}
            placement="bottom"
          >
            <span className={styles.reportDetail}>
              {arr.length > 5 ? "+" + (arr.length - 5) + " more..." : ""}
            </span>
          </CustomTooltip>
        </div>
      ) : (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",").map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  if (exporting) return <LoaderContainer />;

  return (
    <>
      {
        <div style={{ color: "red" }}>
          {exportError === undefined ||
          exportError === null ||
          exportError === ""
            ? ""
            : "Export Error: " + exportError}
        </div>
      }
      <hr />
      <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>                
                <div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}>
                                { reportRunTimeTaken }
                            </span>
                        </div>
                        {GetSelectedUnitsSummary()}
                        <div className={styles.reportDetailContainer}>
                          <span className={styles.reportDetailTitle}>
                            {"Include Inactive Accounts" /* TODO Tranlation */}
                          </span>
                          <span className={styles.reportDetail}>
                            {" "}
                            {includeInactive ? "Yes" : "No"}
                          </span>
                        </div>
                    </div>   
                   
                    <div className={styles.reportExportContainer}>
                                <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                        <Button  className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                                            <ExcelFileIcon/>
                                        </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                    <Button   className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                                        <CSVFileIcon/>
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                    <Button  className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCARReportExportPDF">
                                        <PDFIcon/>
                                    </Button>
                                </CustomTooltip>
                               {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading"  color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/> 
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                    
                </div>
               
            </div>
            </BrandedCard>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={userDetailReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="userDetailReportList">
                  <TableRow>
                  <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,borderRadius: "8px 0 0 0",}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {SetUnitPathwayDescending(!unitPathwayDescending);sortResultsBy("UnitPathway", !unitPathwayDescending);}}>
                          {"Unit Pathway" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "UnitPathway" &&unitPathwayDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickUnitPathwayAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "UnitPathway" &&unitPathwayDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickUnitPathwayDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => { setLastNameDescending(!lastNameDescending);sortResultsBy("LastName", !lastNameDescending);}}>
                          {"Last Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "LastName" && lastNameDescending === false ? "100%": "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickLastNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "LastName" &&lastNameDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickLastNameDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setFirstNameDescending(!firstNameDescending);sortResultsBy("FirstName", !firstNameDescending);}}>
                          {"First Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "FirstName" &&firstNameDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickFirstNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "FirstName" &&firstNameDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickFirstNameDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div  onClick={() => { setUserNameDescending(!userNameDescending);sortResultsBy("UserName", !userNameDescending); }} >
                          {"Username" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "UserName" &&userNameDescending === false ? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex", }} onClick={clickUserNameAsc} >
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "UserName" &&userNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px",display: "inline-flex", }} onClick={clickUserNameDesc} >
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setEmailDescending(!emailDescending);sortResultsBy("Email", !emailDescending);}}>
                          {"Email" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "Email" &&emailDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickEmailAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "Email" &&emailDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickEmailDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setExternalIDDescending(!externalIDDescending);sortResultsBy("ExternalID", !externalIDDescending);}}>
                          {"External ID" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "ExternalID" &&externalIDDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickExternalIDAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "ExternalID" &&externalIDDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickExternalIDDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setStatusDescending(!statusDescending);sortResultsBy("Status", !statusDescending);}}>
                          {"Status" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "Status" &&statusDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickStatusAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "Status" &&statusDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickStatusDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> 
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setUserIDDescending(!userIDDescending);sortResultsBy("UserID", !userIDDescending);}}>
                          {"Account ID" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "UserID" &&userIDDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickUserIDAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "UserID" &&userIDDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickUserIDDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> 
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setUnitIDDescending(!unitIDDescending);sortResultsBy("UnitID", !unitIDDescending);}}>
                          {"Unit ID" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "UnitID" &&unitIDDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickUnitIDAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "UnitID" &&unitIDDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickUnitIDDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>                          
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setGroupByDescending(!groupByDescending);sortResultsBy("GroupBy", !groupByDescending);}}>
                          {"Group By" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "GroupBy" &&groupByDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickGroupByAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "GroupBy" &&groupByDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickGroupByDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> 
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setValueDescending(!valueDescending);sortResultsBy("Value", !valueDescending);}}>
                          {"Value" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "Value" &&valueDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickValueAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "Value" &&valueDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickValueDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setDateCreatedDescending(!dateCreatedDescending);sortResultsBy("DateCreated",!dateCreatedDescending);}}>
                          {"Date Created" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "DateCreated" &&dateCreatedDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickDateCreatedAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{opacity:currentSortBy === "DateCreated" &&dateCreatedDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickDateCreatedDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    
                    {includeInactive?
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setDateArchivedDescending(!dateArchivedDescending);sortResultsBy("DateArchived",!dateArchivedDescending);}}>
                          {"Date Archived" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "DateArchived" &&dateArchivedDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickDateArchivedAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "DateArchived" &&dateArchivedDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickDateArchivedDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>:<span></span>}
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setManagerEmailDescending(!managerEmailDescending);sortResultsBy("ManagerEmail",!managerEmailDescending);}}>
                          {"Manager Email" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "ManagerEmail" &&managerEmailDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickManagerEmailAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "ManagerEmail" &&managerEmailDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickManagerEmailDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setnotifyManagerDescending(!notifyManagerDescending);sortResultsBy("NotifyManager",!notifyManagerDescending);}}>
                          {"Notify Manager" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "NotifyManager" &&notifyManagerDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickNotifyManagerAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "NotifyManager" &&notifyManagerDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickNotifyManagerDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,borderRadius: "0 8px 0 0"}}
                        align="left"
                      >
                        <div
                          className={styles.tableHeadCellContainer}
                          style={{ display: "flex" }}
                        >
                          <div
                            onClick={() => {
                              setLastLoginDescending(!lastLoginDescending);
                              sortResultsBy("LastLogin", !lastLoginDescending);
                            }}
                          >
                            {"Last Login" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "LastLogin" &&
                                    lastLoginDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickLastLoginAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "LastLogin" &&
                                    lastLoginDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickLastLoginDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                    </TableCell>                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userDetailReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: UserDetailReportResultItem, i) => {
                      return (
                        <TableRow
                          key={`UserDetailReportResult-${i}`}
                          style={rowBackgroundColor(i)}
                        >
                          <TableCell align="left">
                            {result.unitPathway}
                          </TableCell>
                          <TableCell align="left">{result.lastName}</TableCell>
                          <TableCell align="left">{result.firstName}</TableCell>
                          <TableCell align="left">{result.userName}</TableCell>
                          <TableCell align="left">{result.email}</TableCell>
                          <TableCell align="left">{result.externalID}</TableCell>
                          <TableCell align="left">
                              {result.status.toLowerCase() === "active" ?
                                <CustomTooltip displayText={["Active"]}>
                                  <span style={{ paddingRight: "4px" }}><ActiveIcon></ActiveIcon></span>
                                </CustomTooltip>
                                :
                                <CustomTooltip displayText={["Inactive"]}>
                                  <span style={{ paddingRight: "4px" }}><InActiveIcon></InActiveIcon></span>
                                </CustomTooltip>
                              }
                              {result.status}</TableCell>
                          <TableCell align="center">{result.userID}</TableCell>
                          <TableCell align="center">{result.unitID}</TableCell>
                          <TableCell align="left">{result.groupBy}</TableCell>
                          <TableCell align="left">{result.value}</TableCell>                          
                          <TableCell align="left">{result.dateCreated}</TableCell>
                          {includeInactive? <TableCell align="left"> {result.dateArchived}</TableCell> :<span></span>}  
                          <TableCell align="left">{result.delinquencyManagerEmail}</TableCell>
                          <TableCell align="left">
                            {result.notifyMgr}
                          </TableCell>
                          <TableCell align="left">{result.lastLogin}</TableCell>      
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          component="div"
          count={userDetailReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default UserDetailReportResult;
