//React + Typescript
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  User,
  Language,
  UnitTreeItem,
  CourseTreeItem,
  SelectedSettings,
  PolicyTreeItem, 
  CPDItem
} from "../../globalTypes";
import { useHistory, useLocation, useParams } from "react-router-dom";
// Libs
import { useReactOidc, getUserManager } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";
//Contexts
import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from "../../contexts/TranslationsContext";
import { LoaderContext } from "../../contexts/LoaderContext";
//Icons
import AlertGreenIcon from "../../CustomIcons/AlertGreenIcon";
//API Functions
import { getUnitTree } from "../../API/GetUnitTree";
import { getRelevantUnitTree } from "../../API/GetRelevantUnitTree";
import { getCourseListByOrganisation } from "../../API/GetCourseListByOrganisation";
import { getPastCourseListByOrganisation } from "../../API/GetPastCourseListByOrganisation";
import { addOrUpdateReportScheduler } from "../../API/AddOrUpdateReportScheduler";
import { addError } from "../../API/AddError";
import { getClassificationListByType } from "../../API/GetClassificationListByType";
import getClassificationType from "../../API/GetClassificationType";
import { getOrgDensityDetails } from "../../API/GetOrgDensityDetails";
import getOrgCPDProfile from "../../API/GetOrgCPDProfile";
import getOrgPolicies from "../../API/GetOrgPolicies";

//Components
import LoaderContainer from "../../Components/LoaderContainer";
import BrandedCard from "../../Components/BrandedCard";
import CustomTooltip from "../../Components/CustomTooltip";
import CourseListTree from "../CurrentAdminReport/CourseListTree";
import UnitFilterButtons from "../CurrentAdminReport/UnitFilterButtons";
import CourseFilterButtons from "../CurrentAdminReport/CourseFilterButtons";
import UnitTree from "../CurrentAdminReport/UnitTree";
import { FrequencySection } from "./FrequencySection";
import UserSection from './UserSection';
import PeriodSection from './PeriodSection';
import ReportSaveModal from './ReportSaveModal';
import SaveIcon from '../../CustomIcons/SaveIcon';
import ResetIcon from "../../CustomIcons/ResetIcon";
import Modal from '../../Components/Modal';
import GuideIcon from '../../CustomIcons/GuideIcon';
import PolicySelection from "../PolicyBuilderReport/PolicySelection";
//import MUICustomSelect from '../../Components/MUICustomSelect';

//MUI Components
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CircularProgress from '@material-ui/core/CircularProgress';
import { StylesContext } from '@material-ui/styles';
/*import { Warning } from '@material-ui/icons';*/

import Button from "@material-ui/core/Button";
// import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProceedIcon from '../../CustomIcons/ProceedIcon';
import CloseIcon from '../../CustomIcons/CloseIcon';

//Styles
import styles from "../../styles/currentAdminReport.module.css";
import { utimes } from "fs";
import ResultsModalContent from "../LearningModules/ResultsModalContent";
import { LocalTaxiOutlined } from "@material-ui/icons";
import DocumentExportSection from "./DocumentType";

interface Props {}

const ReportScheduler = (props: any) => {
  
  const location = useLocation<any>();
  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [interactionCount, setInteractionCount] = useState<number>(0);
  const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([]);
  const [pastcourseList, setPastCourseList] = useState<Array<CourseTreeItem>>([]);
  const [classificationList, setClassificationList] = useState<any[]>([]);
  const [groupingOption, setGroupingOption] = useState<boolean>(false);
  const [courseStatus, setCourseStatus] = useState<any>(-1);
  const [isSmallUnitTree, setSmallUnitTree] = useState<boolean>(true);
  const [filterUnitName, setFilterUnitName]  = useState("");
  const [filterCourseName, setFilterCourseName]  = useState("");
  const [filterWarningPeriod, setFilterWarningPeriod]  = useState<any>(0);
  const [filterDayType, setFilterDayType]  =  useState<any>("dd");
  const [filterPastCourseName, setFilterPastCourseName]  = useState("");
  const [filterFirstName, setFilterFirstName] = React.useState("");
  const [filterLastName, setFilterLastName] = React.useState("");
  const [filterUserName, setFilterUserName] = React.useState("");
  const [filterEmail, setFilterEmail] = React.useState("");
  const [filterIncludeInactive, setFilterIncludeInactive] = useState<boolean>(false);
  const [isHistoryChecked, setIsHistoryChecked] = useState<boolean>(false);
  const [filterHistoricDateFrom, setFilterHistoricDateFrom] = useState("");
  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");

  const [searchPolicy, setSearchPolicy] = useState("");
  const [orgPolicyOptions, setOrgPolicyOptions] = useState<any>([]);
  const [policyAcceptance, setPolicyAcceptance] = useState<any>("both");

  const [filterSubject, setFilterSubject] = useState("");
  const [filterBody, setFilterBody] = useState("");
  const [filterQuizStatus, setFilterQuizStatus] = useState("");
  const [filterSortBy, setFilterSortBy] = useState("0");
  const [filterExpiryValue, setFilterExpiryValue] = useState(0);
  const [filterExpiryUnit, setFilterExpiryUnit] = useState("d");
  const [filterAcceptance, setFilterAcceptance] = useState("0");
  const [filterOnlyUsersWithShortfall, setFilterOnlyUsersWithShortfall] = useState("N");
  const [filterLicensingPeriod, setFilterLicensingPeriod] = useState("");
  const [filterPolicyIds, setFilterPolicyIds] = useState("");
  const [filterCourseStatus, setFilterCourseStatus] = useState<any>(0);
  const [scheduleReportTitle, setScheduleReportTitle] = useState("");

  const [filterGroupBy, setFilterGroupBy] = useState<any>("");
  const [filterGroupByTypeID, setFilterGroupByTypeID] = useState<any>("");
  const [filterClassificationID, setFilterClassificationID] = useState(-1);
  const [selectedClassification, setSelectedClassification] = useState<any>(location?.state?.selectedClassification);

  const [isGroupingOptionFocussed, setIsGroupingOptionFocussed] = useState<boolean>(false);

  const [orgCPDProfileResult, setOrgCPDProfileResult] = useState<any>(null);  
  const [orgCPDListToPass, setOrgCPDListToPass] = useState<Array<any>>([]);  
  const [orgCPDPeriodListToPass, setOrgCPDPeriodListToPass] = useState<Array<any>>([]);  
  const [shortfallUsers, setShortfallUsers] = useState<boolean>(false);
  const [selectedProfileName, setSelectedProfileName] = useState<string>("");
  const [selectedProfilePeriodName, setSelectedProfilePeriodName] = useState<string>("");
  const [selectedProfileID, setSelectedProfileID] = useState<any>(-1);
  const [selectedProfilePeriodID, setSelectedProfilePeriodID] = useState<any>(-1);
  const [selectedCPDProfile, setSelectedCPDProfile] = useState<any>({});
  const [selectedCPDProfilePeriod, setSelectedCPDProfilePeriod] = useState<any>({});
  const [selectedCPDProfilePeriodList, setSelectedCPDProfilePeriodList] = useState<any>([]);

  const [isCPDProfileFocussed, setIsCPDProfileFocussed] = useState<boolean>(false);
  const [isCPDProfilePeriodFocussed, setIsCPDProfilePeriodFocussed] = useState<boolean>(false);
  
  const [profileId, setProfileId] = useState(-1);
  const [reportId, setReportId] = useState(-1);
  const [failCount, setFailCount] = useState(-1);
  const [retryCountIfFailed, setRetryCountIfFailed] = useState(3);
  const [langCode, setLangCode] = useState("");
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [reportRunDate, setReportRunDate] = useState(((new Date()).getDate().toString() + "/" + ((new Date()).getMonth() + 1).toString() + "/" + (new Date()).getFullYear().toString()));
  const [frequencyValue, setFrequencyValue] = React.useState("N");
  const [documentType, setDocumentType] = React.useState("C");
  const [periodType, setPeriodType] = React.useState(1);
  const [recipientType, setRecipientType] = useState('admins');
  const [isCCSectionOpen, setCCSectionOpen] = React.useState(false);
  const [isCCAddedUserSectionOpen, setCCAddedUserSectionOpen] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState("");
  const [isCCSearchResultSectionOpen, setCCSearchResultSectionOpen] = React.useState(false);
  const [lastName, setLastName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [onceOnlyDate, setOnceOnlyDate] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [noEndDate, setNoEndDate] = React.useState(false);
  const [everyInterval, setEveryInterval] = React.useState<any>("*");
  const [daysType, setDaysType] = React.useState<any>("D");
  const [timeIntervalValue, setTimeInterval] = React.useState("");
  const [endAfter, setEndAfter] = React.useState("");
  const [ccUserList, setCCUserList] = React.useState("");
  const [ccUserEmailList, setCCUserEmailList] = React.useState("");
  const [ccUserObjList, setCCUserObjList] = React.useState([]);
  const [modalPopUpHeading, setModalPopUpHeading] = React.useState("Unsaved Changes");
  const [modalPopUpMessages, setModalPopUpMessages] = React.useState(["Do you want to proceed without saving changes?"]);
  const [modalPopUpSettingsMessages, setModalPopUpSettingsMessages] = React.useState<Array<SelectedSettings> | null>(null);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [scheduleId, setScheduleId] = React.useState(-1);
  const [showResponseModal, setShowResponseModal] = React.useState(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [showModal,setModalOpen] = useState<boolean>(false);
  const [nonWarningError,setNonWarningError] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [isDueToday, setIsDueToday] = useState(false);
  const [previousLocation,setPreviousLocation] = useState<string>('');
  const [goBack, setGoBack] = useState<boolean>(false);
  const [dynamicUIExtension, setDynamicUIExtension] = useState<boolean>(false);
  const { setMessage } = useContext(LoaderContext)
  const { translations: {allResourcesToTranslations}, currentLanguage, updateSiteLanguage, pageTitles} = useContext(TranslationsContext)
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".rptTitle",
        title: "Report Title", intro: "Add Schedule report title."        
      },      
    ]
  });
  const schIdGiven = parseInt(props.match.params.scheduleIdToEdit);
  const scheduleIdToEdit: number = ((schIdGiven <= 0) ? -1 : schIdGiven);

  useEffect(() => 
  {
    setGuideMe();     
  },[])
  
  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };

  const setGuideMe = () => {
    let updateSteps = [{
      element: ".rptTitle",
      title: "Report Title", intro: "Add Schedule report title."        
    }];
    updateSteps.push(      
        {
          element: ".chkfrequency",                   
          title: "Select frequency", intro: "<ul><li>'Now' to send one report immediately<br/></li><li>'Once only' to send one report on any specific date<br/><br/></li><li>'More than once' to schedule repeated reports for Days/Weeks/Months/Years with 'No end date' or with number of reports as 'End after' or with specific 'Report End Date'</li></ul>"                    
        }
    );   
    if ((reportId === 19 || reportId === 22) && frequencyData.frequencyValue.toLowerCase() === "m") {
      updateSteps.push(      
        {
          element: ".periodReport",                   
          title: "Select Period", intro: "<ul><li>To display all historical data up to and including the report generation date, select Option 1.<br/>e.g. If the report is run on 29 Sep, then all the data until '29 Sep' will be included<br/><br/></li><li>To display only the data within the last period of the selected frequency and up to the report generation date, select Option 2.<br/>e.g. If the report is run on 29 Sep with every week frequency, then the data from '22 Sep' until '29 Sep' will be included. Next report on '06 Oct' will then include the data from '29 Sep' until '06 Oct' and so on<br/><br/></li><li>To display only the data from the given date until the report generation date, select Option 3.<br/>e.g. If the report is run on 29 Sep with the given 'From' date as '01 Apr', then the data from '01 Apr' until '29 Sep' will be included</li></ul>"                    
        }
      ); 
    }
    updateSteps.push(       
      {
          element: ".exportReport",                   
          title: "Document Type", intro: "Select document type to receive scheduled reports data."                    
      },
      {
          element: ".ccuserSection",
          title: "CC Accounts", intro: "Add CC emails to receive schedule report."
      },
      {
          element:  ".saveReport",
          title: "Save", intro: "Click to save Report."
      },
      {
          element:  ".resetAll",
          title: "Reset", intro: "Reset all applied filters."
      }   
    );
    setIntro({...intro, steps: [...updateSteps]});   
    return updateSteps;
  };

  const [frequencyData, setFrequencyData] = React.useState({
    frequencyValue: "N",
    onceOnlyDate: "",
    endAfter: "",
    timeIntervalValue: "0",
    daysType: "D",
    noEndDate: false,
    startDate: "",
    endDate: "",
    periodFromDate: "",
  });

  const [userDetails, setUserDetails] = React.useState({
    firstName:'',
    lastName:''
  })
  const [schedulerDetails, setSchedulerDetails] = useState<any>({
    
  })
  const [reportTitle, setReportTitle] = useState<string>('');

  const { oidcUser, logout } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website.toLowerCase().replace("altlogin-", "")
    : "";
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setIsReportSchedulerChangedAndNotSaved, IsReportSchedulerChangedAndNotSaved, IsLoggingOut, setIsLoggingOut, EnableActivityLogs } = useContext(UIContext);
  const history = useHistory();
  let {report} = useParams<any>();


  useEffect(() => {

    if( report == 'CAR') {
      setReportTitle(isHistoryChecked ? 'Historic Admin Report' : 'Current Admin Report');
    }else if( report == 'CSR') {
      setReportTitle('Course Status Report');
    }else if( report == 'PR') {
      setReportTitle('Progress Report');
    }else if( report == 'SR') {
      setReportTitle('Summary Report');
    }else if( report == 'ER') {
      setReportTitle('Email Report');
    }else if( report == 'CPDR') {
      setReportTitle('CPD Report');
    }else if( report == 'PBR') {
      setReportTitle('Policy Builder Report');
    } else if( report == 'WR') {
      setReportTitle('Warning Report');
    }

    setGuideMe();
  },[report])

  useEffect(() => {
    //debugger
    updateCurrentProfileSelection(selectedCPDProfile);
    var cpdProfilePeriods = (!!selectedCPDProfile === false || !!selectedCPDProfile.profilePeriodItems === false) ? null : selectedCPDProfile.profilePeriodItems.map((cpdp : any) => {
        if (!!cpdp){
            return {value: cpdp.id, text: cpdp.name}; 
        }
    });
    setOrgCPDPeriodListToPass(cpdProfilePeriods);
  }, [selectedCPDProfile]);

  useEffect(() => {
    //debugger
    updateCurrentProfilePeriodSelection(selectedCPDProfilePeriod);
  }, [selectedCPDProfilePeriod]);

  const changeGivenDateIfOlderThanToday = (givenDate: Date) => {
    if (givenDate === null || givenDate === undefined) {
      return givenDate;
    }
    let curDate : Date = new Date();
    let curDateToChk : Date = new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate());
    if (givenDate < curDateToChk) {
      return curDateToChk;
    }
    return givenDate;
  }

  
  const handleRecipientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;

    setRecipientType(val);
  };

  const getFormattedCurrentDay = () => {
    var curDate = new Date();
    return formatDate(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate()));
  }

  const getFormattedDayOfCurrentDayOffsetWithGivenDayCount = (offsetDays: number) => {
    var curDate = new Date();
    curDate.setDate(curDate.getDate() + offsetDays)
    return formatDate(new Date(curDate.getFullYear(), curDate.getMonth(), curDate.getDate()));
  }

  useEffect(() => {
    if (location !== null && location !== undefined && location.state != null && location.state != undefined) {
      setIsDueToday(location.state.isDueToday);
      setFilterFirstName(location.state.firstName);
      setFilterLastName(location.state.lastName);
      setFilterUserName(location.state.userName);
      setFilterEmail(location.state.email);
      setFilterIncludeInactive(location.state.isIncludeInactive);
      setIsHistoryChecked(location.state.isHistoryChecked);
      setScheduleReportTitle(location.state.scheduleReportTitle);
      setPeriodType(location.state.periodType);
      setFilterSubject(location.state.subject);
      setFilterBody(location.state.body);
      setFilterQuizStatus(location.state.quizStatus);
      setFilterSortBy(location.state.sortBy);
      setFilterExpiryValue(location.state.expiryValue);
      setFilterExpiryUnit(location.state.expiryUnit);
      setFilterAcceptance(location.state.acceptance);
      setPolicyAcceptance(location.state.acceptance);
      setFilterOnlyUsersWithShortfall(!!location.state.onlyUsersWithShortfall && location.state.onlyUsersWithShortfall ? "Y" : "N");
      setShortfallUsers(!!location.state.onlyUsersWithShortfall && location.state.onlyUsersWithShortfall ? true : false);
      setFilterLicensingPeriod(location.state.licensingPeriod);      
      setFilterPolicyIds(location.state.policyIds);
      setFilterCourseStatus(location.state.status);
      setCourseStatus((location.state.status === null || location.state.status === undefined || location.state.status.toString().trimLeft().trimRight() === "") ? -1 : parseInt(location.state.status.toString()));      
      setFilterGroupBy(location.state.groupBy);
      if (Object.keys(location.state.selectedClassification).length > 0) {
        setFilterClassificationID(location.state.selectedClassification.value);
        setGroupingOption(location.state.groupingOption);
        setClassificationList(location.state.classificationList);
        setSelectedClassification(location.state.selectedClassification);
        setFilterGroupByTypeID(location.state.selectedClassification);
        setCCUserObjList(location.state.selectedClassification.ccUserObjList);
        let givenCCObjList : Array<any> = location.state.selectedClassification.ccUserObjList;
        if (givenCCObjList !== null && givenCCObjList !== undefined && givenCCObjList.length > 0) {        
        }
      }
      setProfileId(location.state.profileID);
      setReportId(location.state.reportId);
      setLangCode(location.state.langCode);
      setFilterWarningPeriod(location.state.warningPeriod);
      setFilterDayType(location.state.dayType);
      //debugger
      // Only for CPDR
      if (!!location && !!location.state && !!location.state.reportId && location.state.reportId === 30) {
        let selPID = !!location.state.selectedProfileID ? parseInt(location.state.selectedProfileID.toString()) : -1; 
        setSelectedProfileID(selPID);    
        let selPPID = !!location.state.selectedProfilePeriodID ? parseInt(location.state.selectedProfilePeriodID.toString()) : -1; 
        setSelectedProfilePeriodID(selPPID);

        // Only for CPDR, get the CPD profiles of org
        if (!!location.state.givenOrgCPDList === false && location.state.reportId === 30) {
          getOrgCPDProfile(
            oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId)).then(
              (orgCPDProfRes) => {
                if (orgCPDProfRes.isSuccess){
                  //debugger
                  setOrgCPDProfileResult(orgCPDProfRes);

                  if (!!orgCPDProfRes === false || !!orgCPDProfRes.cpdProfileItems === false || orgCPDProfRes.cpdProfileItems.length <= 0) {
                    setSelectedCPDProfile(null);
                    setSelectedCPDProfilePeriod(null);
                    setOrgCPDListToPass([]);
                    setOrgCPDPeriodListToPass([]);
                  }
                  else {      
                    let matchedProf : any = null;
                    let pidFromList = !!orgCPDProfRes && !!orgCPDProfRes.cpdProfileItems ? 
                      (matchedProf = orgCPDProfRes.cpdProfileItems.filter((ii : any) => !!ii.profileID && parseInt(ii.profileID.toString()) === (selPID <= 0 ? 0 : selPID ))[0]).profileID : 0;
                    setSelectedCPDProfile({value: pidFromList.toString(), profileID: pidFromList.toString()});
                    let ppidFromList = !!orgCPDProfRes && !!orgCPDProfRes.cpdProfileItems && !!matchedProf && !!matchedProf.profilePeriodItems ? 
                    matchedProf.profilePeriodItems.filter((ii : any) => !!ii.profilePeriodID && parseInt(ii.profilePeriodID.toString()) === (selPPID <= 0 ? 0 : selPPID ))[0].profilePeriodID : 0;
                    setSelectedCPDProfilePeriod({value: ppidFromList.toString(), profilePeriodID: ppidFromList.toString()});
       
                    var cpdProfiles = orgCPDProfRes.cpdProfileItems.map((cpd : any) => {
                      if (!!cpd){
                          let cpdToAdd : CPDItem = {id: 0, name: '', period: '', requiredPoints: 0, earnedPoints: 0};
                          cpdToAdd.id = cpd.profileID;
                          cpdToAdd.name = cpd.profileName;
                          return {value: cpdToAdd.id, text: cpdToAdd.name}; 
                      }
                    });
                    setOrgCPDListToPass(cpdProfiles);
                    var cpdProfilePeriods = !!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems ? selectedCPDProfile.profilePeriodItems.map((cpdp : any) => {
                        if (!!cpdp){
                            return {value: cpdp.id, text: cpdp.name}; 
                        }
                    }) : null;
                    setOrgCPDPeriodListToPass(cpdProfilePeriods);
                  }
                }
              }
            ); 
        }
        else if (location.state.reportId === 30) {
            setOrgCPDProfileResult(location.state.givenOrgCPDList);

          let matchedProf : any = null;
          let pidFromList = !!location.state.givenOrgCPDList && !!location.state.givenOrgCPDList.cpdProfileItems ? 
            (matchedProf = location.state.givenOrgCPDList.cpdProfileItems.filter((ii : any) => !!ii.profileID && parseInt(ii.profileID.toString()) === (selPID <= 0 ? 0 : selPID ))[0]).profileID : 0;          
            setSelectedCPDProfile({value: pidFromList, profileID: pidFromList});
          let ppidFromList = !!location.state.givenOrgCPDList && !!location.state.givenOrgCPDList.cpdProfileItems && !!matchedProf && !!matchedProf.profilePeriodItems ? 
          matchedProf.profilePeriodItems.filter((ii : any) => !!ii.profilePeriodID && parseInt(ii.profilePeriodID.toString()) === (selPPID <= 0 ? 0 : selPPID ))[0].profilePeriodID : 0;
          setSelectedCPDProfilePeriod({value: ppidFromList, profilePeriodID: ppidFromList});   
        }   
      }

      // PBR
      let orgPolicyOptionsLocal : any = null;
      if (location.state.reportId === 36 && !!location.state.givenOrgPolicies === false) {
        getOrgPolicies(
          oidcUser.access_token,
          userId,
          orgDomain,
          parseInt(orgId),
          true
        ).then((orgPoliciesRes) => {
          //debugger
          if (!!orgPoliciesRes && !!orgPoliciesRes.isSuccess && orgPoliciesRes.isSuccess) {
            
            let updatedItems = orgPoliciesRes.policyItems.map((item:any) => {
              return {
                ...item,
                isSelected: false,
                isHidden: false
              }
            })
            setOrgPolicyOptions(updatedItems);
            orgPolicyOptionsLocal = updatedItems;
            if (!!location.state.policyIdsArr && !!orgPolicyOptionsLocal) {
              if(orgPolicyOptionsLocal.length != 0) {
                let updatedPolicyOptions = orgPolicyOptionsLocal.map((item:any) => {
                  return {
                    ...item,
                    isSelected: location.state.policyIdsArr.includes(item.policyID)
                  }
                })    
                setOrgPolicyOptions(updatedPolicyOptions);
              }
            }
          }
        });
      }
      else if (location.state.reportId === 36 && !!location.state.givenOrgPolicies) {
        setOrgPolicyOptions(location.state.givenOrgPolicies);
        orgPolicyOptionsLocal = location.state.givenOrgPolicies;
        if (!!location.state.policyIdsArr && !!orgPolicyOptionsLocal) {
          if(orgPolicyOptionsLocal.length != 0) {
            let updatedPolicyOptions = orgPolicyOptionsLocal.map((item:any) => {
              return {
                ...item,
                isSelected: location.state.policyIdsArr.includes(item.policyID)
              }
            })    
            setOrgPolicyOptions(updatedPolicyOptions);
          }
        }
      }

      let formatDateTo = "";
      let tempDateTo = [""];
      if (location.state.dateTo !== null && location.state.dateTo !== undefined && location.state.dateTo !== ""){
        formatDateTo = location.state.dateTo.trimLeft().trimRight().split(" ")[0];
        if (formatDateTo.indexOf("/") > 0) {
          tempDateTo = formatDateTo.split("/");
          setFilterDateTo(formatDate(new Date(parseInt(tempDateTo[2]), parseInt(tempDateTo[1])-1, parseInt(tempDateTo[0]))));
        } else {
          setFilterDateTo(formatDateTo);
        }       
      }
      let formatDateFrom = "";
      let tempDateFrom = [""];
      if (location.state.dateFrom !== null && location.state.dateFrom !== undefined && location.state.dateFrom !== ""){
        formatDateFrom = location.state.dateFrom.trimLeft().trimRight().split(" ")[0];
        if (formatDateFrom.indexOf("/") > 0) {
          tempDateFrom = formatDateFrom.split("/");
          setFilterDateFrom(formatDate(new Date(parseInt(tempDateFrom[2]), parseInt(tempDateFrom[1])-1, parseInt(tempDateFrom[0]))));
        } else {
          setFilterDateFrom(formatDateFrom);
        }
      }

      const perType = ((location.state.periodType === null || location.state.periodType === undefined || location.state.periodType <= 0) ? 1 : location.state.periodType);
      setPeriodType(perType);
      
      const freqVal = ((location.state.reportFrequency === null || location.state.reportFrequency === undefined || location.state.reportFrequency.trimLeft().trimRight() === "") ? 1 : parseInt(location.state.reportFrequency));
      
      if (location.state.isPeriodic) {
        let formatEndDate = "";
        let tempEndDate = [""];
        let formattedDate = location.state.reportStartDate.split(" ")[0];
        let tempDate = formattedDate.split("/");
        if (location.state.isPeriodic === "M" && 
          location.state.reportEndDate !== null && location.state.reportEndDate !== undefined && location.state.reportEndDate !== "") {
          formatEndDate = location.state.reportEndDate.trimLeft().trimRight().split(" ")[0];
          tempEndDate = formatEndDate.split("/");
        } else if (location.state.isPeriodic === "M" && 
          location.state.reportEndDate !== null && location.state.reportEndDate !== undefined && location.state.reportEndDate === "") {
            setFrequencyData({
              ...frequencyData,
              noEndDate: true
            });
        }
        let formatDateForPerRepFrom = "";
        let tempDateForPerRepFrom = [""];
        if (location.state.reportFromDate !== null && location.state.reportFromDate !== undefined && location.state.reportFromDate !== ""){
          formatDateForPerRepFrom = location.state.reportFromDate.trimLeft().trimRight().split(" ")[0];
          tempDateForPerRepFrom = formatDateForPerRepFrom.split("/");
        }
      
        setScheduleId(location.state.scheduleId);
        setDocumentType(location.state.documentType);
        if (location.state.reportFromDate !== null && location.state.reportFromDate !== undefined && location.state.reportFromDate !== "" &&
            (location.state.reportId === 19 || location.state.reportId === 22) && location.state.isPeriodic == "M" )
        {
          setFrequencyData({
            ...frequencyData,
            frequencyValue: location.state.isPeriodic.toUpperCase(),
            onceOnlyDate: formatDate(new Date(parseInt(tempDate[2]), parseInt(tempDate[1])-1, parseInt(tempDate[0]))),
            startDate: formatDate(new Date(parseInt(tempDate[2]), parseInt(tempDate[1])-1, parseInt(tempDate[0]))),
            endDate:  location.state.isPeriodic == "M" ? formatDate(new Date(parseInt(tempEndDate[2]), parseInt(tempEndDate[1])-1, parseInt(tempEndDate[0]))): "",
            noEndDate: location.state.isPeriodic == "M" && location.state.reportEndDate === "" ? true : false,
            timeIntervalValue: freqVal.toString(),
            daysType:  location.state.reportDaysType,
            endAfter: location.state.numberOfReports,
            periodFromDate: formatDate(new Date(parseInt(tempDateForPerRepFrom[2]), parseInt(tempDateForPerRepFrom[1])-1, parseInt(tempDateForPerRepFrom[0])))
          })
        }
        else
        {
          setFrequencyData({
            ...frequencyData,
            frequencyValue: location.state.isPeriodic.toUpperCase(),
            onceOnlyDate: formatDate(new Date(parseInt(tempDate[2]), parseInt(tempDate[1])-1, parseInt(tempDate[0]))),
            startDate: formatDate(new Date(parseInt(tempDate[2]), parseInt(tempDate[1])-1, parseInt(tempDate[0]))),
            endDate:  location.state.isPeriodic == "M" ? formatDate(new Date(parseInt(tempEndDate[2]), parseInt(tempEndDate[1])-1, parseInt(tempEndDate[0]))): "",
            noEndDate: location.state.isPeriodic == "M" && location.state.reportEndDate === "" ? true : false,
            timeIntervalValue: freqVal.toString(),
            daysType:  location.state.reportDaysType,
            endAfter: location.state.numberOfReports
          })
        }
      }

      if(report == "ER") {
        setRecipientType(location.state.selectedRecipient);
      }
    }
    else{
      history.push(window.location.pathname.toLowerCase().replaceAll("/csr/reportscheduler", "/coursestatusreport").replaceAll("\\csr\\reportscheduler", "\\coursestatusreport"));
    }

    if (location !== null && location !== undefined && location.state != null && location.state != undefined &&
        (location.state.scheduleReportTitle === null || location.state.scheduleReportTitle === undefined || 
         location.state.scheduleReportTitle.trimLeft().trimRight().toLowerCase() === "")) {
      if (location.state.reportId !== null || location.state.reportId !== undefined) {
        setScheduleReportTitle("Salt Schedule Report: " + 
          (location.state.reportId === 19 ? "Course Status Report" : 
          (location.state.reportId === 3 ? (location.state.isHistoryChecked ? "Historic Admin Report" : "Current Admin Report") : 
          (location.state.reportId === 21 ? "Progress Report" : 
          (location.state.reportId === 22 ? "Summary Report" :
          (location.state.reportId === 30 ? "CPD Report" :
          (location.state.reportId === 36 ? "Policy Builder Report" :
          (location.state.reportId === 29 ? "Warning Report" :
          "Unknown Report"))))))));
      }
    }
    if (!(location !== null && location !== undefined && location.state != null && location.state != undefined)) {
      history.push(`/mytraining`);
    }

  }, [location]);


  const documentTypeList = [
    {
      value: "C",
      label: "CSV",
    },

    {
      value: "E",
      label: "EXCEL",
    },

    {
      value: "P",
      label: "PDF",
    },
  ];

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    const perType = ((val === null || val === undefined || val.trimLeft().trimRight() === "") ? 1 : parseInt(val));

    if (perType == 3 && (filterDateFrom !== null || filterDateFrom === undefined)) {
      setFrequencyData({
        ...frequencyData,
        periodFromDate: filterDateFrom
      });
    }
    setPeriodType(perType);
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocumentType((event.target as HTMLInputElement).value);
  };

  const isGivenItemNameMatched = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
  {
      if (item === null || item === undefined || item.name === null || item.name === undefined) {
          return false;
      }
      if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
          return true;
      }
      else
      {
          if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
              return true;
          }
          return false;
      }
  }

  const isAnyChildItemNameMatched = (list: UnitTreeItem[] | null, givenFilterUnitName: string): boolean | undefined =>
  {
      if (list === null || list === undefined || list.length <= 0) {
          return false;
      }
      if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
          return true;
      }
      else
      {
          var newList = list.filter((e) => { return (isGivenItemNameMatched(e, givenFilterUnitName) === true || isAnyChildItemNameMatched(e.childUnitTreeItems, givenFilterUnitName) === true); });
          if (newList !== null && newList !== undefined && newList.length > 0) {
              return true;
          }
          return false;
      }
  }

  const updateUnitItemBasedOnNameMatch = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
  {
      let isMatchFound = false;
      if ((givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") && 
          item !== null && item !== undefined) {
          isMatchFound = true;
          item.isDisabled = !isMatchFound;
          item.isHidden = !isMatchFound;
          if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
          {
              item.childUnitTreeItems.forEach(function (e) {            
                  let isMatchFoundSub : boolean | undefined = false;
                  isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
              });   
          }
      }
      else if (givenFilterUnitName !== null && givenFilterUnitName !== undefined && givenFilterUnitName.trimLeft().trimRight() !== "" && 
          item !== null && item !== undefined) {
              
          isMatchFound = (item.name !== null && item.name !== undefined && item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase()));
          if (isMatchFound) {
              item.isDisabled = false;
              item.isHidden = false;
          }
          else if (!isMatchFound && (item.childUnitTreeItems == null || item.childUnitTreeItems === undefined || item.childUnitTreeItems.length <= 0)){
              item.isDisabled = true;
              item.isHidden = true;
          }
          else if (!isMatchFound){
              let curAnyChildNameMatched = isAnyChildItemNameMatched(item.childUnitTreeItems, givenFilterUnitName);
              item.isDisabled = (curAnyChildNameMatched === true);
              item.isHidden = (curAnyChildNameMatched === false);
              isMatchFound = (curAnyChildNameMatched === true);
          }
          if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
          {
              item.childUnitTreeItems.forEach(function (e) {            
                  let isMatchFoundSub : boolean | undefined = false;
                  isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
              });   
          }
      }
      return isMatchFound;
  }

  useEffect(() => {   
    setTitle(allResourcesToTranslations.reportscheduler);
    setTitleEnglish(pageTitles.reportscheduler)
    updateBackButtonRoute("");
   
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        if (location.state !== null && location.state !== undefined && location.state.selectedUnits.length > 0) {
           setUnits(location.state.selectedUnits);
        } else {
          setUnits(unitTreeRes.unitTreeItems);
        }

        setSmallUnitTree(
          unitTreeRes.unitTreeItems === null ||
            unitTreeRes.unitTreeItems.length <= 10
        );
      }
      getCourseListByOrganisation(
        oidcUser.access_token,
        userId,
        orgDomain,
        parseInt(orgId),
        true
      ).then((courseListRes) => {
        if (location !== null && location.state !== null && location.state.selectedCourses && location.state.selectedCourses.length > 0) {
          setCourseList(location.state.selectedCourses);          
        } else if (courseListRes !== null && courseListRes.isSuccess) {        
          setCourseList(courseListRes.courselistItem);
        }     
        
        getPastCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((pastcourseListRes) => {  
         // console.log('location.state.selectedPastCourses' + location.state.selectedPastCourses)    
          if (location !== null && location.state !== null && location.state.selectedPastCourses && location.state.selectedPastCourses.length > 0) {                              
            setPastCourseList(location.state.selectedPastCourses);                   
            } else if (pastcourseListRes !== null && pastcourseListRes.isSuccess) {     
              setPastCourseList(pastcourseListRes.pastCourseListItems);
            }  
            getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {
                      
              if(classType !== null && classType.classificationTypeIDs !== null && 
                classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                  getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {
                    
                      if (data !== null && data.classificationlistItem !== null) {

                        if (location !== null && location.state !== null && location.state.classificationList !== null && location.state.classificationList !== undefined) {
                          setClassificationList(location.state.classificationList);
                        } else {   
                          setClassificationList(data.classificationlistItem)
                        }
                        if (location !== null && location.state !== null && location.state.groupingOption !== null && location.state.groupingOption !== undefined) {
                          setGroupingOption(location.state.groupingOption);
                        } else {   
                          setGroupingOption(true)
                        }
                        if (location !== null && location !== undefined && location.state != null && location.state != undefined &&
                          location.state.selectedClassification !== null && location.state.selectedClassification !== null && Object.keys(location.state.selectedClassification).length > 0) {
                          setFilterClassificationID(location.state.selectedClassification.value.toString());
                          setSelectedClassification(location.state.selectedClassification);
                        }
                      }
                      else {
                        if (location !== null && location.state !== null && location.state.groupingOption !== null && location.state.groupingOption !== undefined) {
                          setGroupingOption(location.state.groupingOption);
                        } else {   
                          setGroupingOption(false)
                        }
                      }
                  })
              }
              else {
                if (location !== null && location.state !== null && location.state.groupingOption !== null && location.state.groupingOption !== undefined) {
                  setGroupingOption(location.state.groupingOption);
                } else {   
                  setGroupingOption(false)
                }
              }
              
            })
          })
        })
    });
  }, []);

  React.useEffect(() => {
    // @ts-ignore: Unreachable code error
    const unblock = history.block((location, action) => {
      if(goBack) {
        if(saveClicked) {
            // if (frequencyData.frequencyValue.toLowerCase() != "n"
            // ) {
            //   setIsReportSchedulerChangedAndNotSaved(true); 
            //   setPreviousLocation(location.pathname)
            //   setModalOpen(true);
            //   return false
            // }
            // else {
            //   return true
            // }
            return true
        }
        return true
      } else {
          if (frequencyData.frequencyValue.toLowerCase() !== "n"
            ) {
              setIsReportSchedulerChangedAndNotSaved(true); 
              setPreviousLocation(location.pathname)
              setModalOpen(true);
              return false
          }
          else {
            return true
          }
      }      
    }); 
    setGuideMe();
    if(frequencyData.frequencyValue.toLowerCase() !== "n"
      ) {
        setIsReportSchedulerChangedAndNotSaved(true); 
    }
    else
    {
      setIsReportSchedulerChangedAndNotSaved(false); 
    }  

    if(frequencyData.timeIntervalValue !== "0"
    ) {
      if (frequencyData.daysType.toLowerCase() === "d") {
        setEveryInterval(frequencyData.timeIntervalValue.toString() + " " + "day(s) ");
      } else if (frequencyData.daysType.toLowerCase() === "w") {
        setEveryInterval(frequencyData.timeIntervalValue.toString() + " " + "week(s) "); 
      } else if (frequencyData.daysType.toLowerCase() === "m") {
        setEveryInterval(frequencyData.timeIntervalValue.toString() + " " + "month(s) "); 
      } else if (frequencyData.daysType.toLowerCase() === "y") {
        setEveryInterval(frequencyData.timeIntervalValue.toString() + " " + "year(s) "); 
      } else
      {
        setEveryInterval("*"); 
      }  
    }
    else
    {
      setEveryInterval("*"); 
    }  
    
    if(!saveClicked) {
      if(goBack) {
        history.push(previousLocation)
      
      }
    }
    
    return () => {
      unblock();
    };
  }, [goBack,saveClicked,frequencyData,reportRunDate]);

  useEffect(() => {
      setFilterCourseStatus((courseStatus === null || courseStatus === undefined || courseStatus.toString().trimRight().trimLeft() === "") ? "-1" : courseStatus);
  },[courseStatus]) 

  useEffect(() => {
    setFilterGroupBy(((selectedClassification === null || selectedClassification === undefined || 
      selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value.toString()));
    setFilterClassificationID(((selectedClassification === null || selectedClassification === undefined) ? -1 : selectedClassification.value));
    
    setFilterGroupByTypeID(((selectedClassification === null || selectedClassification === undefined || 
      selectedClassification.classificationTypeID === null || selectedClassification.value === undefined) ? "" : selectedClassification.value));

  },[selectedClassification]) 

  useEffect(() => {
      if (units !== null && units !== undefined && units.length > 0) {
          var newUnits = units.map(function(e) {  
            // const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && findUnitItemByText(e, filterUnitName.toLowerCase())); 
            //     e.isHidden = retFlag;
            //     e.isDisabled = retFlag;
            const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())); 
            return e;
          });
          setUnits(newUnits);
      }
  },[filterUnitName]) 

  useEffect(() => {
      if (courseList !== null && courseList !== undefined && courseList.length > 0) {
          var newCourses = courseList.map(function(e) { 
              if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === ""){
                  e.isHidden = false; 
              }
              else {
                  e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase())); 
              }
              return e;
          });
          setCourseList(newCourses);
      }
  },[filterCourseName]) 


  useEffect(() => {
    if (pastcourseList !== null && pastcourseList !== undefined && pastcourseList.length > 0) {
        var newCourses = pastcourseList.map(function(e) { 
            if (filterPastCourseName === null || filterPastCourseName === undefined || filterPastCourseName.trimRight().trimLeft() === ""){
                e.isHidden = false; 
            }
            else {
                e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterPastCourseName.toLowerCase())); 
            }
            return e;
        });
        setPastCourseList(newCourses);
    }
},[filterPastCourseName]) 

  const handleModal = (value:boolean) => {
    setModalOpen(value);
  }

  const getDateStringDDMMYYYY = (givenDate: Date) => {
    return ((givenDate === null || givenDate === undefined) ? givenDate : 
            (givenDate.getDate().toString() + "/" + (givenDate.getMonth() + 1).toString() + "/" + givenDate.getFullYear().toString()))
  }

  const getDateStringYYYYMMDDhhmmssms = (givenDateStr: string) => {
    try {
      var givenDate = new Date(); // Current date
      if (givenDateStr !== null && givenDateStr !== undefined && givenDateStr.trimLeft().trimRight() !== "") {
        givenDate = new Date(givenDateStr);
      }
      else {
        return "";
      }
      return ((givenDate === null || givenDate === undefined) ? givenDate : 
              (givenDate.getFullYear().toString() + "-" + (givenDate.getMonth() + 1).toString() + "-" + givenDate.getDate().toString() + " " + 
              givenDate.getHours().toString() + ":" + givenDate.getMinutes().toString() + ":" + givenDate.getSeconds().toString() + "." + givenDate.getMilliseconds().toString()))
    }
    catch (error: any){
      return "";
    }
  }
  
  const getUTCDateStringYYYYMMDDhhmmssms = (givenDateStr: string) => {
    
    try {
      var givenDate = new Date(); // Current date
      if (givenDateStr !== null && givenDateStr !== undefined && givenDateStr.trimLeft().trimRight() !== "") {
        givenDate = new Date(givenDateStr);
      }
      else {
        return "";
      }
      return ((givenDate === null || givenDate === undefined) ? givenDate : 
            (givenDate.getUTCFullYear().toString() + "-" + (givenDate.getUTCMonth() + 1).toString() + "-" + givenDate.getUTCDate().toString() + " " + 
            givenDate.getUTCHours().toString() + ":" + givenDate.getUTCMinutes().toString() + ":" + givenDate.getUTCSeconds().toString() + "." + givenDate.getUTCMilliseconds().toString()))
    }
    catch (error: any){
      return "";
    }
  }

  const getCurrentDateStringYYYYMMDDhhmmssms = () => {
    try {
      var givenDate = new Date(); // Current date
      return ((givenDate === null || givenDate === undefined) ? givenDate : 
              (givenDate.getFullYear().toString() + "-" + (givenDate.getMonth() + 1).toString() + "-" + givenDate.getDate().toString() + " " + 
              givenDate.getHours().toString() + ":" + givenDate.getMinutes().toString() + ":" + givenDate.getSeconds().toString() + "." + givenDate.getMilliseconds().toString()))
    }
    catch (error: any){
      return "";
    }
  }
  
  const getUTCCurrentDateStringYYYYMMDDhhmmssms = () => {
    
    try {
      var givenDate = new Date(); // Current date
      return ((givenDate === null || givenDate === undefined) ? givenDate : 
            (givenDate.getUTCFullYear().toString() + "-" + (givenDate.getUTCMonth() + 1).toString() + "-" + givenDate.getUTCDate().toString() + " " + 
            givenDate.getUTCHours().toString() + ":" + givenDate.getUTCMinutes().toString() + ":" + givenDate.getUTCSeconds().toString() + "." + givenDate.getUTCMilliseconds().toString()))
    }
    catch (error: any){
      return "";
    }
  }

  const handleSelectAllPolicies = () => {
    if(orgPolicyOptions.length != 0) {
      let updatedPolicyOptions = orgPolicyOptions.map((item:any) => {
        return {
          ...item,
          isSelected: true
        }
      })

      setOrgPolicyOptions(updatedPolicyOptions);
      setFilterPolicyIds(!!updatedPolicyOptions ? updatedPolicyOptions.filter((o : any)=>o.isSelected).map((o : any)=>o.policyID.toString()).join(',') : "");
    }
  }

  const handleUnSelectAllPolicies = () => {
    if(orgPolicyOptions.length != 0) {
      let updatedPolicyOptions = orgPolicyOptions.map((item:any) => {
        return {
          ...item,
          isSelected: false
        }
      })

      setOrgPolicyOptions(updatedPolicyOptions);
      setFilterPolicyIds(!!updatedPolicyOptions ? updatedPolicyOptions.filter((o : any)=>o.isSelected).map((o : any)=>o.policyID.toString()).join(',') : "");
    }
  }

  const handlePolicyAcceptance = (event:any) => {
    const val = (event.target as HTMLInputElement).value;
    setPolicyAcceptance(val);
  }

  const handlePolicySearch = (e:any) => {
    setSearchPolicy(e.target.value);
  }
  
  const handlePolicyOptionsUpdate = (data:any) => {  
    setOrgPolicyOptions(data);
  }; 


  const updateReportSchdulerDataGiven = () => {
    let unitIds: Array<number> = []
    let courseIds: Array<number> = []   
    let pastcourseIds: Array<number> = []
    let allunitIds: Array<number> = []
    let allcourseIds: Array<number> = []
    let allpastcourseIds: Array<number> = []
    let paramDateToWR: string = "";
    let paramDateFromWR: string = "";
  
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit === null || unit === undefined) {
            continue;
          }
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
            if (filterUnitName !== "") {
                if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                unitIds.push(unit.unitID)
                }
            } else {
                unitIds.push(unit.unitID)
            }
        }
          allunitIds.push(unit.unitID)
          getSelectedUnitIds(unit.childUnitTreeItems)
        }
    }
    getSelectedUnitIds(units);

    const getSelectedCourses = () => {
      for (let i = 0; i < courseList.length; i++) {
        const curcourse = courseList[i];
        if (curcourse === null || curcourse === undefined) {
          continue;
        }
        if (curcourse.isSelected) {
            courseIds.push(curcourse.courseID)
        }
        allcourseIds.push(curcourse.courseID)
      }
    }
    getSelectedCourses();

   const getSelectedPastCourses = () => {
      for (let i = 0; i < pastcourseList.length; i++) {
        const curcourse = pastcourseList[i];
        if (curcourse === null || curcourse === undefined) {
          continue;
        }
        if (curcourse.isSelected) {
            pastcourseIds.push(curcourse.courseID)
        }
        allpastcourseIds.push(curcourse.courseID)
      }
    }
    getSelectedPastCourses();

    const getToAndFromDateForWarningReport = () => {
      if (report == 'WR') {
        if (filterWarningPeriod !== 0 && (filterDayType !== null || filterDayType !== "")){
          
          let formatDateFrom = new Date();
          let formatDateTo = new Date();
          paramDateFromWR = formatDate(formatDateFrom);
          if (filterDayType == "dd") {
            formatDateTo.setDate(formatDateTo.getDate() + parseInt(filterWarningPeriod));
            paramDateToWR = formatDate(formatDateTo);
          } 
          else if (filterDayType == "mm") {
            formatDateTo.setMonth(formatDateTo.getMonth() + parseInt(filterWarningPeriod));
            paramDateToWR = formatDate(formatDateTo);
          }
        }
      }
      
    }
    getToAndFromDateForWarningReport();


    let data = {
      frequencyValue: frequencyData.frequencyValue,
      documentType,
      periodType,
      firstName,
      lastName,
      onceOnlyDate,
      startDate,
      endDate,
      noEndDate,
      daysType: frequencyData.daysType,
      every: frequencyData.timeIntervalValue,
      endAfter: frequencyData.endAfter,
      orgId: parseInt(orgId),
      unitIds: unitIds.toString(),
      courseIds: courseIds.toString(),     
      pastcourseIds: pastcourseIds.toString(),  
      filterFirstName, 
      filterLastName, 
      filterUserName, 
      filterEmail
    };
    //console.log("usersData",ccUserList)
    const date = new Date()
    let startReportDate : string | null;
    startReportDate = null;
    let endReportDate : string | null;
    endReportDate = null;

    if (frequencyData.frequencyValue == 'N') {

      startReportDate = `${date.getUTCFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}` //TODO fix hard coded date;
      
    }else if (frequencyData.frequencyValue == 'O') {

      startReportDate = 
        (frequencyData.onceOnlyDate === null || frequencyData.onceOnlyDate === undefined || 
          frequencyData.onceOnlyDate.trimLeft().trimRight() === "") ? 
          null
          :
          getDateStringYYYYMMDDhhmmssms(frequencyData.onceOnlyDate + " 0:0:0.0");
    }else {

      startReportDate = 
        (frequencyData.startDate === null || frequencyData.startDate === undefined || 
          frequencyData.startDate.trimLeft().trimRight() === "") ? 
          null
          :
          getDateStringYYYYMMDDhhmmssms(frequencyData.startDate + " 0:0:0.0");
      endReportDate = 
        (frequencyData.endDate === null || frequencyData.endDate === undefined || 
          frequencyData.endDate.trimLeft().trimRight() === "") ? 
          null
          :
          getDateStringYYYYMMDDhhmmssms(frequencyData.endDate + " 0:0:0.0");
    }    
    const nextDate2 = new Date();
    nextDate2.setDate(nextDate2.getDate()+1);
    addOrUpdateReportScheduler(
      (scheduleIdToEdit <= 0 ? false : true),
      oidcUser.access_token,
      userId, 
      parseInt(orgId),
      ((unitIds === null || unitIds === undefined || unitIds.length <= 0) ? "" : unitIds.join(",")),
      ((courseIds === null || courseIds === undefined || courseIds.length <= 0) ? "" : courseIds.join(",")),
      filterFirstName, 
      filterLastName, 
      filterUserName, 
      filterEmail,
      filterSubject,
      filterBody,
      (courseStatus === -1 ? "" : courseStatus.toString()),
      (filterCourseStatus === -1 ? "" : filterCourseStatus.toString()),
      (filterIncludeInactive ? "Y" : "N"),
      filterGroupBy,
      filterSortBy,
      filterClassificationID !== null && filterClassificationID !== undefined ?  parseInt(filterClassificationID.toString()) : -1,
      langCode,
      failCount,
      retryCountIfFailed,
      (filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? 
        (reportId === 36 ? formatDate(nextDate2) : (reportId === 29 ? paramDateToWR : null)) 
        : 
        filterDateTo,
      (filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.trimLeft().trimRight() === "") 
        ?         
        (reportId === 36 ? "1996-12-31" : (reportId === 29 ? paramDateFromWR : null))
        : 
       filterDateFrom,
      (
        (frequencyData === null || frequencyData === undefined || frequencyData.periodFromDate === null || 
          frequencyData.periodFromDate === undefined || frequencyData.periodFromDate.trimLeft().trimRight() === "") ? 
          null 
          : 
          frequencyData.periodFromDate //getUTCDateStringYYYYMMDDhhmmssms(frequencyData.periodFromDate + " 0:0:0.0")
      ), 
      (
        frequencyData.frequencyValue === "N" ? 
          getCurrentDateStringYYYYMMDDhhmmssms() : // Current day
          (
            (frequencyData.frequencyValue === "O" && frequencyData.onceOnlyDate !== null && 
              frequencyData.onceOnlyDate.trimLeft().trimRight() !== "") ? 

                getDateStringYYYYMMDDhhmmssms(frequencyData.onceOnlyDate + " 0:0:0.0") 
                : 
                getDateStringYYYYMMDDhhmmssms(
                    (frequencyData.startDate !== null && frequencyData.startDate !== undefined && 
                     frequencyData.startDate.trimLeft().trimRight() !== "") ? 
                      (frequencyData.startDate + " 0:0:0.0") 
                      : 
                      "" // If empty string is passed, empty string is returned
                  )
          )
      ) , 
      (frequencyData.frequencyValue === "N" 
        ? 
        getUTCCurrentDateStringYYYYMMDDhhmmssms() :  // Current day
        (
          (frequencyData.frequencyValue === "O" && frequencyData.onceOnlyDate !== null && 
            frequencyData.onceOnlyDate.trimLeft().trimRight() !== "") 
            ? 
            getUTCDateStringYYYYMMDDhhmmssms(frequencyData.onceOnlyDate + " 0:0:0.0") 
            : 
            getUTCDateStringYYYYMMDDhhmmssms(
              (
                (frequencyData.startDate !== null && frequencyData.startDate !== undefined && 
                  frequencyData.startDate.trimLeft().trimRight() !== "") 
                ? 
                frequencyData.startDate + " 0:0:0.0"
                : 
                "" // If empty string is passed, empty string is returned
              )
            )
        )
      ) , 
      getUTCCurrentDateStringYYYYMMDDhhmmssms(),//lastUpdatedDate in UTC, // Current day
      userId,
      filterExpiryValue,
      filterExpiryUnit,
      profileId,
      filterAcceptance,
      filterOnlyUsersWithShortfall,
      filterLicensingPeriod,
      orgDomain,
      frequencyData.frequencyValue, // TO DO rename
      frequencyData.daysType, // TO DO rename
      frequencyData.timeIntervalValue, // TO DO rename
      documentType,
      (frequencyData !== null && frequencyData.endAfter !== null && frequencyData.endAfter.trimLeft().trimRight() !== "") 
        ? 
        frequencyData.endAfter 
        : 
        "", // TO DO rename
      periodType, // TO DO check & rename
      startReportDate,
      scheduleReportTitle,
      reportId,
      frequencyData.noEndDate,
      scheduleId,
      endReportDate,
      ccUserList,
      //ccUserEmailList,     
      pastcourseList.filter(c => c !== null && c.isSelected).map(c => c.courseID.toString()).join(",") , // filterHistoricCourseIds, 
      //filterPolicyIds,
      !!orgPolicyOptions ? orgPolicyOptions.filter((o : any)=>o.isSelected).map((o : any)=>o.policyID.toString()).join(',') : "",
      false, 
      selectedProfileID,
      selectedProfilePeriodID,
      reportId === 29 ? filterWarningPeriod.toString() + " " + filterDayType : "", 
      isHistoryChecked
    ).then((response) => {
      if (response !== undefined && response !== null && response.isSuccess === true){
        // get the scheduleid and keep it locally in the state if required in future
        //console.log(response.addedOrUpdatedScheduleID);
        setNonWarningError("");
        handleReset()
        setScheduleId(response.addedOrUpdatedScheduleID);
        setShowResponseModal(true);
        setSaveSuccess(true)
        setIsSaving(false)
        setSaveClicked(false)
        setGoBack(false)
        setModalOpen(false);
         setTimeout(() => {
         history.push("/reportschedulerlist")
         },2500)
        // redirect to the report scheduler list  
      }
      else {
        const errorVal = (response !== undefined && response !== null && response.error !== undefined && response.error !== null) ? response.error : "Saving report scheduler settings failed returning null/invalid";
        setNonWarningError(errorVal);
        setModalPopUpHeading("Settings Error");
        setModalPopUpMessages([
          errorVal,
        ]);
        setShowResponseModal(true);
        setSaveSuccess(false)
        setIsSaving(false)
        setSaveClicked(false)
        setGoBack(false)
        setModalOpen(true);
      }

      // set the schedule ID and userID and the CC user list to the UserSelection component
    }).catch((error) => {
      const errorVal = (error !== undefined && error !== null) ? error : "Saving report scheduler settings failed as exception"
      setNonWarningError(errorVal);
      setModalPopUpHeading("Settings Error");
      setModalPopUpMessages([
        errorVal,
      ]);
      setShowResponseModal(true);
      setSaveSuccess(false);
      setIsSaving(false)
      setSaveClicked(false)
      setGoBack(false)
      setModalOpen(true);
    });
  }

  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
      if (!isNaN(givenDate.getTime())) {
          const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
          const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
          const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
  
          return (year.toString() + "-" + 
          (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
          (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
      }
      else {
          return "";
      }
  }
  const getOrgDensity = async () => {
    const date = new Date()
    const dateString = formatDate(date);

    let unitIds: Array<number> = []
    let courseIds: Array<number> = []   
    let pastcourseIds: Array<number> = []
    let allunitIds: Array<number> = []
    let allcourseIds: Array<number> = []
    let allpastcourseIds: Array<number> = []
  
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit === null || unit === undefined) {
            continue;
          }
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
            if (filterUnitName !== "") {
                if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                unitIds.push(unit.unitID)
                }
            } else {
                unitIds.push(unit.unitID)
            }
          }
          allunitIds.push(unit.unitID)
          getSelectedUnitIds(unit.childUnitTreeItems)
        }
    }
    getSelectedUnitIds(units);

    const getSelectedCourses = () => {
      for (let i = 0; i < courseList.length; i++) {
        const curcourse = courseList[i];
        if (curcourse === null || curcourse === undefined) {
          continue;
        }
        if (curcourse.isSelected) {
            courseIds.push(curcourse.courseID)
        }
        allcourseIds.push(curcourse.courseID)
      }
    }
    getSelectedCourses();

   const getSelectedPastCourses = () => {
      for (let i = 0; i < pastcourseList.length; i++) {
        const curcourse = pastcourseList[i];
        if (curcourse === null || curcourse === undefined) {
          continue;
        }
        if (curcourse.isSelected) {
            pastcourseIds.push(curcourse.courseID)
        }
        allpastcourseIds.push(curcourse.courseID)
      }
    }
    getSelectedPastCourses();


    const result = await getOrgDensityDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId),
                            //units.filter(u => u !== null && u.isSelected).map(u => u.unitID.toString()).join(","), 
                            //courseList.filter(c => c !== null && c.isSelected).map(c => c.courseID.toString()).join(","),

                            unitIds.length > 0 ? unitIds.toString() : allunitIds.toString(), 
                            (courseIds.length > 0 && pastcourseIds.length > 0) 
                             ? (courseIds.toString() +","+ pastcourseIds.toString()) :
                              ((isHistoryChecked && pastcourseIds.toString().length >=1) ? pastcourseIds.toString() : (courseIds.length > 0 ? courseIds.toString() : allcourseIds.toString())),

                            dateString,  
                            "Unit_User", 
                            "LAST_NAME", 
                            false, 
                            filterClassificationID < 0 ? 0 : filterClassificationID, 
                            filterFirstName == null || filterFirstName == undefined ? "" : filterFirstName, 
                            filterLastName == null || filterLastName == undefined ? "" : filterLastName, 
                            filterUserName == null || filterUserName == undefined ? "" : filterUserName, 
                            filterEmail == null || filterEmail == undefined ? "" : filterEmail); 
    return result
  }

  const appendAsSinglePolicyArray = (givenArrOfArr: PolicyTreeItem[][] | null, returnPolicyNames: PolicyTreeItem[]) => {
    if (givenArrOfArr === null || givenArrOfArr === undefined || givenArrOfArr.length <= 0) {
      return returnPolicyNames;
    }
    if (returnPolicyNames === null || returnPolicyNames === undefined) {
      returnPolicyNames = [];
    }
    givenArrOfArr.map(aa => 
      { 
        if (aa !== null && aa !== undefined && aa.length >= 0) {
          returnPolicyNames = returnPolicyNames.concat(aa) 
        }
        else {
          returnPolicyNames = returnPolicyNames;
        }
      });
    return returnPolicyNames;
  }

  const getAllPolicySettingsArrayInTree = (givenPolicies: PolicyTreeItem[] | null, isNotOnlySelected: boolean, returnPolcies: PolicyTreeItem[]) => {
    if (givenPolicies === null || givenPolicies === undefined || givenPolicies.length <= 0) {
      return returnPolcies;
    }
    if (returnPolcies === null || returnPolcies === undefined) {
      returnPolcies = [];
    }
    var policyNames = givenPolicies.map(u => getGivenPolicySettingsArray(u, isNotOnlySelected, returnPolcies));
    let singleArr = appendAsSinglePolicyArray(policyNames, returnPolcies);
    if (singleArr === null || singleArr === undefined || singleArr.length <= 0) {
      return returnPolcies;
    }
    returnPolcies = returnPolcies.concat(singleArr);    
    return returnPolcies;
  }

  const policyArrayUnique = (array: PolicyTreeItem[]) => {
    if (array === null || array === undefined || array.length <= 0) {
      return array;
    }
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
  }

  const getGivenPolicySettingsArray = (givenPolicy: PolicyTreeItem, isNotOnlySelected: boolean, returnPolicies: PolicyTreeItem[]) => {
    if (givenPolicy === null || givenPolicy === undefined) {
      return returnPolicies;
    }
    if (returnPolicies === null || returnPolicies === undefined) {
      returnPolicies = [];
    }
    returnPolicies = returnPolicies.concat((isNotOnlySelected || givenPolicy.isSelected) ? [givenPolicy] : []);
    let subArray = getAllPolicySettingsArrayInTree([], isNotOnlySelected, returnPolicies);
    if (subArray !== null && subArray !== undefined && subArray.length >= 0) {
      returnPolicies = returnPolicies.concat(subArray);
    }
    return returnPolicies;
  }

  const appendAsSingleUnitArray = (givenArrOfArr: UnitTreeItem[][] | null, returnUnitNames: UnitTreeItem[]) => {
    if (givenArrOfArr === null || givenArrOfArr === undefined || givenArrOfArr.length <= 0) {
      return returnUnitNames;
    }
    if (returnUnitNames === null || returnUnitNames === undefined) {
      returnUnitNames = [];
    }
    givenArrOfArr.map(aa => 
      { 
        if (aa !== null && aa !== undefined && aa.length >= 0) {
          returnUnitNames = returnUnitNames.concat(aa) 
        }
        else {
          returnUnitNames = returnUnitNames;
        }
      });
    return returnUnitNames;
  }

  const getAllUnitSettingsArrayInTree = (givenUnits: UnitTreeItem[] | null, isNotOnlySelected: boolean, returnUnits: UnitTreeItem[]) => {
    if (givenUnits === null || givenUnits === undefined || givenUnits.length <= 0) {
      return returnUnits;
    }
    if (returnUnits === null || returnUnits === undefined) {
      returnUnits = [];
    }
    var unitNames = givenUnits.map(u => getGivenUnitSettingsArray(u, isNotOnlySelected, returnUnits));
    let singleArr = appendAsSingleUnitArray(unitNames, returnUnits);
    if (singleArr === null || singleArr === undefined || singleArr.length <= 0) {
      return returnUnits;
    }
    returnUnits = returnUnits.concat(singleArr);    
    return returnUnits;
  }

  const unitArrayUnique = (array: UnitTreeItem[]) => {
    if (array === null || array === undefined || array.length <= 0) {
      return array;
    }
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
  }

  const getGivenUnitSettingsArray = (givenUnit: UnitTreeItem, isNotOnlySelected: boolean, returnUnits: UnitTreeItem[]) => {
    if (givenUnit === null || givenUnit === undefined) {
      return returnUnits;
    }
    if (returnUnits === null || returnUnits === undefined) {
      returnUnits = [];
    }
    returnUnits = returnUnits.concat((isNotOnlySelected || givenUnit.isSelected) ? [givenUnit] : []);
    let subArray = getAllUnitSettingsArrayInTree(givenUnit.childUnitTreeItems, isNotOnlySelected, returnUnits);
    if (subArray !== null && subArray !== undefined && subArray.length >= 0) {
      returnUnits = returnUnits.concat(subArray);
    }
    return returnUnits;
  }

  const isStartGreaterThanEndDate = (startDateStr: string, endDateStr: string) => {
    if (startDateStr === null || startDateStr === undefined || startDateStr.trimLeft().trimRight() === ""){
      return false;
    }
    if (endDateStr === null || endDateStr === undefined || endDateStr.trimLeft().trimRight() === ""){
      return false;
    }
    try {
      var givenStartDate = new Date(startDateStr); 
      var givenEndDate = new Date(endDateStr);  
      return (givenStartDate > givenEndDate);
    }
    catch (error: any){
      return false;
    }
  }

  const getDateInWords = (givenDateStr: string) => {
    if (givenDateStr === null || givenDateStr === undefined || givenDateStr.trimLeft().trimRight() === ""){
      return null;
    }
    try {
      var givenDate = new Date(givenDateStr);
      let weekday = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
      let months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');	  
      var dayWord = weekday[givenDate.getDay()];
      var monthWord = months[givenDate.getMonth()];      
      return dayWord + ", " + monthWord + " " + givenDate.getDate().toString() + " " + givenDate.getFullYear().toString();
    }
    catch (error: any){
      return null;
    }
  }

  const checkIfGivenDateIsOlderThanToday = (givenDateStr: string) => {
    if (givenDateStr === null || givenDateStr === undefined || givenDateStr.trimLeft().trimRight() === ""){
      return false;
    }
    try {
      var givenDate = new Date(givenDateStr);
      var curDay = new Date();
      curDay.setHours(0,0,0,0);
      return givenDate < curDay;
    }
    catch (error: any){
      return false;
    }
  }

  const getUnitSelectionSettings = () => {
    // Selected Units
    var unitNamesGiven : UnitTreeItem[] = [];
    var unitsToDisplay = getAllUnitSettingsArrayInTree(units, false, unitNamesGiven);//units.filter(u => u !== null && u !== undefined && u.isSelected);
    var isAllUnits = false;
    if (unitsToDisplay === null || unitsToDisplay === undefined || unitsToDisplay.length <= 0){
      unitsToDisplay = getAllUnitSettingsArrayInTree(units, true, unitsToDisplay); //selectedUnitNames.map(u => u.name);
      isAllUnits = true;
    }
    let unitsToDisplayUnique = unitArrayUnique(unitsToDisplay);    
    if (unitsToDisplayUnique !== null && unitsToDisplayUnique !== undefined && unitsToDisplayUnique.length > 0) {
      //var unitNames = unitsToDisplayUnique.filter(u => u !== null && u !== undefined).map(u => "•" + u.unitHierarchy);
      var unitNames = unitsToDisplayUnique.filter(u => u !== null && u !== undefined).map(u => u.unitHierarchy);
      let selectedUnitsListSection : SelectedSettings = {
        title: isAllUnits ? "UNITS (Considered all by default)" : "UNITS (Only selected)",
        rowDisplayValues: unitNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUnitsListSection;
    }
    return null;
  }

  const getPolicySelectionSettings = () => {
    // Selected Policies
    var policyNamesGiven : PolicyTreeItem[] = [];
    var policiesToDisplay = getAllPolicySettingsArrayInTree(orgPolicyOptions, false, policyNamesGiven);
    var isAllPolicies = false;
    if (policiesToDisplay === null || policiesToDisplay === undefined || policiesToDisplay.length <= 0){
      policiesToDisplay = getAllPolicySettingsArrayInTree(orgPolicyOptions, true, policiesToDisplay); 
      isAllPolicies = true;
    }
    let policiesToDisplayUnique = policyArrayUnique(policiesToDisplay);    
    if (policiesToDisplayUnique !== null && policiesToDisplayUnique !== undefined && policiesToDisplayUnique.length > 0) {
      var policyNames = policiesToDisplayUnique.filter(u => u !== null && u !== undefined).map(u => u.policyName);
      let selectedUnitsListSection : SelectedSettings = {
        title: isAllPolicies ? "POLICIES (Considered all by default)" : "POLICIES (Only selected)",
        rowDisplayValues: policyNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUnitsListSection;
    }
    return null;
  }

  const getCourseSelectionSettings = () => {
    var selectedCourses = courseList.filter(u => u !== null && u !== undefined && u.isSelected);
    var isAllCourses = false;
    if (selectedCourses === null || selectedCourses === undefined || selectedCourses.length <= 0){
      selectedCourses = courseList;
      isAllCourses = true;
    }
    if (selectedCourses !== null && selectedCourses !== undefined && selectedCourses.length > 0) {
      //var courseNames = selectedCourses.filter(u => u !== null && u !== undefined).map(u => "•" + u.name);
      var courseNames = selectedCourses.filter(u => u !== null && u !== undefined).map(u => u.name);
      let selectedCoursesListSection : SelectedSettings = {
        title: isAllCourses ? "COURSES (Considered all by default)" : "COURSES (Only selected)",
        rowDisplayValues: courseNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedCoursesListSection;
    }
    return null;
  }


  const getPastCourseSelectionSettings = () => {
    var selectedPastCourses = pastcourseList.filter(u => u !== null && u !== undefined && u.isSelected);   
   
    if (selectedPastCourses !== null && selectedPastCourses !== undefined && selectedPastCourses.length > 0) {
      //var pastcourseNames = selectedPastCourses.filter(u => u !== null && u !== undefined).map(u => "•" + u.name);
      var pastcourseNames = selectedPastCourses.filter(u => u !== null && u !== undefined).map(u => u.name);
      let selectedPastCoursesListSection : SelectedSettings = {
        title: "PAST COURSES (Only selected)",
        rowDisplayValues: pastcourseNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedPastCoursesListSection;
    }
    return null;
  }

  const getFrequencySelectionSettings = () => {
    let frequencySettingPopupValues : string[] = []
    let error : string = "";
    let isInvalid : boolean = false;
    if (frequencyData !== null && frequencyData !== undefined && 
        frequencyData.frequencyValue !== null && frequencyData.frequencyValue !== undefined) {
       if (frequencyData.frequencyValue.toLowerCase() === "n") {
        frequencySettingPopupValues.push("Report Start Date is NOW (Only one report)");
       }
       else if (frequencyData.frequencyValue.toLowerCase() === "o") {
        var startDateLocal = getDateInWords(frequencyData.onceOnlyDate);
        isInvalid = (frequencyData.onceOnlyDate === null || 
          frequencyData.onceOnlyDate === undefined || 
          frequencyData.onceOnlyDate.trimLeft().trimRight() === "");
        error = isInvalid ? "Null/invalid 'Start Date' is given for the 'Once only' frequency" : "";
        if (error === "") {
          error = (startDateLocal === "" || startDateLocal === null || startDateLocal === undefined) ? "Cannot convert 'Start Date' of the 'Once only' frequency to readable date" : "";
        }
        if (error === "" && checkIfGivenDateIsOlderThanToday(frequencyData.onceOnlyDate)) {
          error = "Once Only report schedule start date as older than today is invalid input";
        }
        frequencySettingPopupValues.push("Report Start Date is " + 
          (isInvalid ? "??? (Only one report)" : 
                (((startDateLocal === "" || startDateLocal === null || startDateLocal === undefined) ? "???" : startDateLocal) + " (Only one report)")));
       }
       else if (frequencyData.frequencyValue.toLowerCase() === "m") {
        var startDateLocal = getDateInWords(frequencyData.startDate);
        var endDate = getDateInWords(frequencyData.endDate);
        isInvalid = (startDateLocal === "" || startDateLocal === null || startDateLocal === undefined);
        error = isInvalid ? "Null/invalid 'Start Date' is given for the 'More than once' frequency" : "";
        frequencySettingPopupValues.push("Report Start Date " + 
              (isInvalid ? "???" : startDateLocal));
        isInvalid = (frequencyData.noEndDate === false && (endDate === "" || endDate === null || endDate === undefined) && !!frequencyData.endAfter === false);
        if (error === "") {
          error = isInvalid ? "Null/invalid 'End Date' is given for the 'More than once' frequency" : "";
        }
        if (error === "" && !frequencyData.noEndDate) {
          error = isStartGreaterThanEndDate(frequencyData.startDate, frequencyData.endDate) ? "'Start Date' is greater than the 'End Date' for the 'More than once' frequency" : "";
        }
        if (error === "") {
          error = ((frequencyData.endAfter !== null && frequencyData.endAfter !== undefined &&
                      frequencyData.endAfter.trimLeft().trimRight() !== "" && 
                      (Number.isNaN(parseInt(frequencyData.endAfter)) || parseInt(frequencyData.endAfter) <= 0)
                     )) ? "Invalid 'End after' value is given for the 'More than once' frequency" : "";
        }
        // if (error === "") {
        //   error = ((endDate === "" || endDate === null || endDate === undefined) &&
        //             (frequencyData.endAfter === null || frequencyData.endAfter === undefined ||
        //               frequencyData.endAfter.trimLeft().trimRight() === "" || 
        //               parseInt(frequencyData.endAfter) === NaN || parseInt(frequencyData.endAfter) <= 0)) ? 
        //               "Both 'End after' and 'End Date' values cannot be null/invalid for the 'More than once' frequency" : "";
        // }
        frequencySettingPopupValues.push(
              (frequencyData.noEndDate ? ("No End Date" + 
                ((frequencyData.endAfter !== null && frequencyData.endAfter !== undefined && 
                  frequencyData.endAfter.trimLeft().trimRight() !== "") ?
                    (" (End after " + frequencyData.endAfter + " report(s))") : "")) : 
                (isInvalid ? "Report End Date ???" : 
                    ("Report End Date " + endDate))));
        isInvalid = (frequencyData.timeIntervalValue === null || 
                      frequencyData.timeIntervalValue === undefined || 
                      frequencyData.timeIntervalValue.trimLeft().trimRight() === "" ||
                      frequencyData.timeIntervalValue.trimLeft().trimRight() === "0" ||
                      parseInt(frequencyData.timeIntervalValue.trimLeft().trimRight()) <= 0);
        if (error === "") {
          error = isInvalid ? "Null/invalid 'Every' value is given for the 'More than once' frequency" : "";
        }
        if (error === "") {
          error = (frequencyData.daysType === null || 
                    frequencyData.daysType === undefined || 
                    frequencyData.daysType.trimLeft().trimRight() === "" || 
                    (frequencyData.daysType !== "D" && frequencyData.daysType !== "W" && 
                       frequencyData.daysType !== "M" && frequencyData.daysType !== "Y")) ? 
                       "Null/invalid 'Every interval' value is given for the 'More than once' frequency" : "";
        }
        frequencySettingPopupValues.push("Every " + 
              (isInvalid ? "???" : frequencyData.timeIntervalValue) + " " +
              ((frequencyData.daysType === null || 
                frequencyData.daysType === undefined || 
                frequencyData.daysType.trimLeft().trimRight() === "") ? "???" : 
                  (frequencyData.daysType === "D" ? "day(s)" :
                    (frequencyData.daysType === "W" ? "week(s)" :
                      (frequencyData.daysType === "M" ? "month(s)" :
                        (frequencyData.daysType === "Y" ? "year(s)" :
                          ("???")))))));        
       }
    }
    if (frequencySettingPopupValues === null || frequencySettingPopupValues === undefined || frequencySettingPopupValues.length <= 0) {
      if (error === ""){
        error = "Frequency input values cannot be collected";
      }
      let selectedFrequencySection : SelectedSettings = {
          title: "FREQUENCY",
          rowDisplayValues: [],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: error
        };
      return selectedFrequencySection;
    }
    else {
      let selectedFrequencySection : SelectedSettings = {
        title: "FREQUENCY",
        rowDisplayValues: frequencySettingPopupValues,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: error
      };
      return selectedFrequencySection;
    }
  }

  const getDataDateRangeSelectionSettings = () => {
    var periodFromDate = getDateInWords(frequencyData.periodFromDate);
    let error : string = "";
    let isInvalid : boolean = false;
    isInvalid = 
      (frequencyData !== null && frequencyData !== undefined && frequencyData.frequencyValue !== null && 
      frequencyData.frequencyValue !== undefined && frequencyData.frequencyValue.toLowerCase() === "m" &&
      periodType === 3 && (periodFromDate === "" || periodFromDate === null || periodFromDate === undefined));
    if (error === "") {
      error = isInvalid ? "Null/invalid 'From' date value is given in the 'DATA DATE RANGE' section" : "";
    }
    if (error === "") {
      error = (periodType !== 1 && periodType !== 2 && periodType !== 3) ? "Null/invalid option is chosen in the 'DATA DATE RANGE' section" : "";
    }
    let selectedDateRangeSection : SelectedSettings = {
      title: "DATA DATE RANGE",
      rowDisplayValues: (periodType === 1 ? ["All data up to and including report date"] :
                          (periodType === 2 ? ["All data up to report date"] :
                            (periodType === 3 ? 
                              ["All data from '" + (isInvalid ? "???" : periodFromDate) + "' up to and including report date"] :
                                  (["???"])))),
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: error
    };
    return selectedDateRangeSection;
  }

  const getPBRDateFromSelectionSettings = () => {
    let filterDateFromConverted = getDateInWords(filterDateFrom);
    if (reportId === 36) {
      if (filterDateFromConverted !== null && filterDateFromConverted !== undefined &&
        filterDateFromConverted.trimLeft().trimRight() !== "") {          
          let selectedCSDateFromSection : SelectedSettings = {
            title: "POLICY ACCEPTED AND UPLOADED DATE FROM",
            rowDisplayValues: [filterDateFromConverted],
            isHidden: false,
            currentDisplayRowCount: 3,
            defaultDisplayRowCount: 10,
            error: ""
          };
          return selectedCSDateFromSection;
      }
    }
    return null;
  }

  const getPBRDateToSelectionSettings = () => {
    let filterDateToConverted = getDateInWords(filterDateTo);
    if (reportId === 36) {
      if (filterDateToConverted !== null && filterDateToConverted !== undefined &&
        filterDateToConverted.trimLeft().trimRight() !== "") {
        // Selected Course Status Date To Filter
        let selectedCSDateToSection : SelectedSettings = {
          title: "POLICY ACCEPTED AND UPLOADED DATE TO",
          rowDisplayValues: [filterDateToConverted],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: ""
        };
        return selectedCSDateToSection;
      }
    }
    return null;
  }

  const getCSRDateFromSelectionSettings = () => {
    let filterDateFromConverted = getDateInWords(filterDateFrom);
    if (reportId === 19) {
      if (filterDateFromConverted !== null && filterDateFromConverted !== undefined &&
        filterDateFromConverted.trimLeft().trimRight() !== "") {          
          let selectedCSDateFromSection : SelectedSettings = {
            title: "COURSE STATUS DATE FROM",
            rowDisplayValues: [filterDateFromConverted],
            isHidden: false,
            currentDisplayRowCount: 3,
            defaultDisplayRowCount: 10,
            error: ""
          };
          return selectedCSDateFromSection;
      }
    }
    return null;
  }

  const getCSRDateToSelectionSettings = () => {
    let filterDateToConverted = getDateInWords(filterDateTo);
    if (reportId === 19) {
      if (filterDateToConverted !== null && filterDateToConverted !== undefined &&
        filterDateToConverted.trimLeft().trimRight() !== "") {
        // Selected Course Status Date To Filter
        let selectedCSDateToSection : SelectedSettings = {
          title: "COURSE STATUS DATE TO",
          rowDisplayValues: [filterDateToConverted],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: ""
        };
        return selectedCSDateToSection;
      }
    }
    return null;
  }
  const getCARDateToSelectionSettings = () => {
    let filterDateToConverted = getDateInWords(filterDateTo);
    if (reportId === 3) {
      if (filterDateToConverted !== null && filterDateToConverted !== undefined &&
        filterDateToConverted.trimLeft().trimRight() !== "") {
        // Selected Current Admin Report Date To Filter
        let selectedCSDateToSection : SelectedSettings = {
          title: "CURRENT ADMIN HISTORIC DATE",
          rowDisplayValues: [filterDateToConverted],
          isHidden: false,
          currentDisplayRowCount: 3,
          defaultDisplayRowCount: 10,
          error: ""
        };
        return selectedCSDateToSection;
      }
    }
    return null;
  }
  const getCSRCourseStatusSelectionSettings = () => {
    if (reportId === 19) {
      let error : string = "";
      let isInvalid : boolean = false;
      isInvalid = (filterCourseStatus === "" || filterCourseStatus === null || filterCourseStatus === undefined ||
                    (filterCourseStatus.toString() !== "0" && filterCourseStatus.toString() !== "1" &&
                     filterCourseStatus.toString() !== "2" && filterCourseStatus.toString() !== "3" &&
                     filterCourseStatus.toString() !== "4" && filterCourseStatus.toString() !== "5" && filterCourseStatus.toString() !== "6"));
      if (error === "") {
        error = isInvalid ? "Null/invalid 'Course Status' filter value is given for Course Status Report" : "";
      }
      let selectedCourseStatusFilterSection : SelectedSettings = {
        title: "COURSE STATUS FILTER",
        rowDisplayValues: [(isInvalid ? "???" : 
                             (filterCourseStatus.toString() === "0" ? "Incomplete" : 
                              (filterCourseStatus.toString() === "1" ? "Complete" : 
                                (filterCourseStatus.toString() === "2" ? "Fail" : 
                                  (filterCourseStatus.toString() === "3" ? "Not Started" : 
                                    (filterCourseStatus.toString() === "4" ? "Expired(Time Elapsed)" : 
                                      (filterCourseStatus.toString() === "5" ? "Expired(New Content)" : (filterCourseStatus.toString() === "6" ? "Incomplete(With Details)" : "???"))))))))],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: error
      };
      return selectedCourseStatusFilterSection;
    }
    return null;
  }

  const getSRDateFromSelectionSettings = () => {
    let filterDateFromConverted = getDateInWords(filterDateFrom);
    if (reportId === 22 && filterDateFromConverted !== null && filterDateFromConverted !== undefined &&
      filterDateFromConverted.trimLeft().trimRight() !== "") {
      let selectedSRDateFromSection : SelectedSettings = {
        title: "SUMMARY DATE FROM",
        rowDisplayValues: [filterDateFromConverted],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedSRDateFromSection;
    }
    return null;
  }

  const getSRDateToSelectionSettings = () => {
    let filterDateToConverted = getDateInWords(filterDateTo);
    if (reportId === 22 && filterDateToConverted !== null && filterDateToConverted !== undefined &&
      filterDateToConverted.trimLeft().trimRight() !== "") {
      let selectedSRDateToSection : SelectedSettings = {
        title: "SUMMARY DATE TO",
        rowDisplayValues: [filterDateToConverted],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedSRDateToSection;
    }
    return null;
  }


  const getFirstnameFilterSettings = () => {
    if (filterFirstName !== null && filterFirstName !== undefined && filterFirstName.trimLeft().trimRight() !== "") {
      let selectedFNFilterSection : SelectedSettings = {
        title: "FIRSTNAME FILTER",
        rowDisplayValues: [filterFirstName],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedFNFilterSection;
    }
    return null;
  }

  const getLastnameFilterSettings = () => {
    if (filterLastName !== null && filterLastName !== undefined && filterLastName.trimLeft().trimRight() !== "") {
      let selectedLNFilterSection : SelectedSettings = {
        title: "LASTNAME FILTER",
        rowDisplayValues: [filterLastName],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedLNFilterSection;
    }
    return null;
  }

  const getUsernameFilterSettings = () => {
    if (filterUserName !== null && filterUserName !== undefined && filterUserName.trimLeft().trimRight() !== "") {
      let selectedUNFilterSection : SelectedSettings = {
        title: "USERNAME FILTER",
        rowDisplayValues: [filterUserName],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUNFilterSection;
    }
    return null;
  }

  const getEmailFilterSettings = () => {
    if (filterEmail !== null && filterEmail !== undefined && filterEmail.trimLeft().trimRight() !== "") {
      let selectedEmailFilterSection : SelectedSettings = {
        title: "EMAIL FILTER",
        rowDisplayValues: [filterEmail],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedEmailFilterSection;
    }
    return null;
  }

  const getUserStatusFilterSettings = () => {
    let selectedUserStatusFilterSection : SelectedSettings = {
      title: "ACCOUNT STATUS FILTER",
      rowDisplayValues: [filterIncludeInactive ? "Both active and inactive accounts" : "Only active accounts"],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedUserStatusFilterSection;
  }

  const getWarningPeriodFilterSettings = () => {
    if (filterWarningPeriod !== null && filterWarningPeriod !== undefined && filterWarningPeriod !== 0
      && filterDayType !== null && filterDayType !== undefined && filterDayType.trimLeft().trimRight() !== "") {
      let selectedUNFilterSection : SelectedSettings = {
        title: "WARNING PERIOD FILTER",
        rowDisplayValues: [filterWarningPeriod.toString() + " " + (filterDayType == "dd" ? "Days" : "Months")],
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUNFilterSection;
    }
    return null;
  }

  const getShortfallUsersFilterSettings = () => {
    let selectedUserStatusFilterSection : SelectedSettings = {
      title: "SHOW ONLY SHORTFALL USERS",
      rowDisplayValues: [shortfallUsers ? "YES" : "NO"],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedUserStatusFilterSection;
  }

  const getPBRPolicyAcceptanceFilterSettings = () => {
    let selectedUserStatusFilterSection : SelectedSettings = {
      title: "POLICY ACCEPTANCE FILTER",
      rowDisplayValues: [policyAcceptance === "both" ? "Both" : (policyAcceptance === "accepted" ? "Accepted" : (policyAcceptance === "not_accepted" ? "Not Accepted" : ""))],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedUserStatusFilterSection;
  }

  const getSelectedCPDProfileSettings = () => {
    let selectedUserStatusFilterSection : SelectedSettings = {
      title: "SELECTED CPD PROFILE",
      rowDisplayValues: [selectedProfileName],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedUserStatusFilterSection;
  }

  const getSelectedCPDProfilePeriodSettings = () => {
    let selectedUserStatusFilterSection : SelectedSettings = {
      title: "SELECTED CPD PROFILE PERIOD",
      rowDisplayValues: [selectedProfilePeriodName],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedUserStatusFilterSection;
  }

  const getGroupingSelectionSettings = () => {
    let selectedGroupingSection : SelectedSettings = {
      title: "GROUPING OPTION",
      rowDisplayValues: [((filterClassificationID !== null && filterClassificationID !== undefined && 
                            filterClassificationID > 0) ? classificationList.find((item) => item.value === filterClassificationID).text : "None")],
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedGroupingSection;
  }

  const getCCListSelectionSettings = () => {
    //var ccUsersArray = ((ccUserList === null || ccUserList === undefined) ? null : ccUserList.split(",").filter(cc => cc !== null && cc !== undefined && cc.trimLeft().trimRight() != ""));
    var ccUserEmailsArray = ((ccUserEmailList === null || ccUserEmailList === undefined) ? null : ccUserEmailList.split(",").filter(cc => cc !== null && cc !== undefined && cc.trimLeft().trimRight() != ""));
    let selectedCCListSection : SelectedSettings = {
      title: "CC EMAIL LIST",
      //rowDisplayValues: (ccUsersArray === null || ccUsersArray === undefined || ccUsersArray.length <= 0) ? ["None"] : ccUsersArray,
      rowDisplayValues: (ccUserEmailsArray === null || ccUserEmailsArray === undefined || ccUserEmailsArray.length <= 0) ? ["None"] : ccUserEmailsArray,
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedCCListSection;
  }

  const getDocTypeSelectionSettings = () => {
    let error : string = "";
    let isInvalid : boolean = false;
    isInvalid = (documentType === "" || documentType === null || documentType === undefined ||
                  (documentType !== "C" && documentType !== "E" && documentType !== "P"));
    if (error === "") {
      error = isInvalid ? "Null/invalid 'DOCUMENT TYPE' value is given" : "";
    }
    let selectedDocTypeSection : SelectedSettings = {
      title: "DOCUMENT TYPE",
      rowDisplayValues: (documentType === "C" ? ["CSV (.csv)"] :
                          (documentType === "P" ? ["PDF (.pdf)"] :
                            (documentType === "E" ? ["EXCEL (.xlsx)"] :
                              (["???"])))),
      isHidden: false,
      currentDisplayRowCount: 3,
      defaultDisplayRowCount: 10,
      error: ""
    };
    return selectedDocTypeSection;
  }

  const openSettingsError = (error : string) => {
    if (error === null || error === undefined) {
      return;
    }
    setNonWarningError(error);
      setModalPopUpHeading("Settings Error");
      setModalPopUpMessages([
        error,
      ]);
      setShowResponseModal(true);
      setSaveSuccess(false)
      setIsSaving(false)
      setSaveClicked(false)
      setGoBack(false)
      setModalOpen(true);
  }

  const isErrorPresentInSettings = (settings: SelectedSettings) => {    
    if (settings !== null && settings !== undefined && 
        settings.error !== null && settings.error !== undefined && 
        settings.error.trimLeft().trimRight() !== "") {        
          openSettingsError(settings.error);
      return true;
    }
    return false;
  }

  const handleSaveClick = () => {
    if (scheduleReportTitle === null || scheduleReportTitle === undefined || scheduleReportTitle.trimLeft().trimRight() === "") {
      openSettingsError("Schedule report title cannot be empty");
      return;
    }
    // Build the UI settings sub section values
    let allSettings = new Array<SelectedSettings>();
    // Selected Units
    var settings = getUnitSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    if (!!report && report.toString().toLowerCase() === 'pbr') {
      // Selected Policies
      settings = getPolicySelectionSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
      // Selected Policy Acceptance Filter
      settings = getPBRPolicyAcceptanceFilterSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }
    if (!!report && report.toString().toLowerCase() !== 'pbr' && report.toString().toLowerCase() !== 'cpdr') {
      // Selected Courses
      settings = getCourseSelectionSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
      // Selected PastCourses
      settings = getPastCourseSelectionSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }

    }
    // Selected Frequency
    settings = getFrequencySelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    if (!!report && report.toString().toLowerCase() !== 'pbr' && report.toString().toLowerCase() !== 'cpdr') {
      // Selected Grouping option if selected 
      settings = getGroupingSelectionSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }
    // Selected Data Date Range
    settings = getDataDateRangeSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Document Type
    settings = getDocTypeSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Course Status Date From Filter
    settings = getCSRDateFromSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }

    // Selected CAR Date To Filter
    settings = getCARDateToSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }

    // Selected Course Status Date To Filter
    settings = getCSRDateToSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    if (!!report && report.toString().toLowerCase() === 'pbr') {
      // Selected Policy Accepted And Uploaded Date From Filter
      settings = getPBRDateFromSelectionSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
      // Selected Policy Accepted And Uploaded Date To Filter
      settings = getPBRDateToSelectionSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }
    // Selected Course Status Filter
    settings = getCSRCourseStatusSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Summary Date From Filter
    settings = getSRDateFromSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Summary Date To Filter
    settings = getSRDateToSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Firstname Filter
    settings = getFirstnameFilterSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Lastname Filter
    settings = getLastnameFilterSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Username Filter
    settings = getUsernameFilterSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    // Selected Email Filter
    settings = getEmailFilterSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    //Selected Warning Period
    if (!!report && report.toString().toLowerCase() === 'wr') {
      // Selected User Status Filter
      settings = getWarningPeriodFilterSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }
    if (!!report && report.toString().toLowerCase() !== 'sr' && report.toString().toLowerCase() !== 'cpdr') {
      // Selected User Status Filter
      settings = getUserStatusFilterSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }
    // Selected CC List
    settings = getCCListSelectionSettings();
    if (settings !== null && settings !== undefined) { 
      if (isErrorPresentInSettings(settings) === true){
        return;
      }
      allSettings.push(settings); 
    }
    if (!!report && report.toString().toLowerCase() === 'cpdr') {
      // Selected CPD profile
      settings = getSelectedCPDProfileSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }
    if (!!report && report.toString().toLowerCase() === 'cpdr') {
      // Selected CPD profile
      settings = getSelectedCPDProfilePeriodSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }
    if (!!report && report.toString().toLowerCase() === 'cpdr') {
      // Selected CPD profile
      settings = getShortfallUsersFilterSettings();
      if (settings !== null && settings !== undefined) { 
        if (isErrorPresentInSettings(settings) === true){
          return;
        }
        allSettings.push(settings); 
      }
    }

    // Removal of scheduler settings error pop-up to allow the same due-day editing
    // // If the schedule is currently due, don't allow it to update
    // if (scheduleIdToEdit > 0 && isDueToday){
    //   openSettingsError("The selected schedule cannot be changed at the moment as it is possibly running the current-day's due");
    //   return;
    // }

    setIsSaving(true);
    setSaveClicked(true);  
    setModalPopUpHeading("Scheduled Report Settings Confirmation");
    setModalPopUpMessages(["You have made the following selections to your scheduled report"]);  
    if (allSettings.length <= 0){
      setModalPopUpMessages(["No valid settings are given/selected, if proceeded the save will not be successful"]);  
    }
    setModalPopUpSettingsMessages(allSettings);
    setModalOpen(true);
  }

  const handleSave = () => {
    setIsSaving(true)
    setSaveClicked(true)    
    getOrgDensity().then((res) => {
      if (res !== undefined && res !== null && res.isSuccess) {
        setModalPopUpHeading((res.delayWarning === undefined || res.delayWarning === null || res.delayWarning.trimLeft().trimRight() === "") ? "Warning" : "Large report size warning");
        setModalPopUpMessages((((res.delayWarning === undefined || res.delayWarning === null) ? "" : ((String)(res.delayWarning))) + "Do you want to proceed saving?;").split(";").filter(e => e !== undefined && e !== null && e.trimLeft().trimRight() !== ""));
        const isModelOpenSet = res.delayWarning !== undefined && res.delayWarning !== null && res.delayWarning.trimLeft().trimRight() !== "";
        setModalOpen(isModelOpenSet)
        if (!isModelOpenSet) {
          setModalOpen(isModelOpenSet)
          updateReportSchdulerDataGiven();
          //setSaveClicked(false)
          //setIsReportSchedulerChangedAndNotSaved(false); 
          //setIsSaving(false);
          //setModalOpen(false);
          setModalPopUpHeading("Unsaved Changes");
          setModalPopUpMessages(["Do you want to proceed without saving changes?"]);
        }
      }
      else {
        setModalPopUpHeading("Error in report size prediction");
        setModalPopUpMessages(((res === undefined || res === null || res.error === null || res.error === undefined) ? 
              "The report result size and performance cannot be predicted.; Do you want to proceed saving?;" : 
              "Error in predicting the report result size and performance as '" + res.error + "'.; Do you want to proceed saving?;").split(";").filter(e => e !== undefined && e !== null && e.trimLeft().trimRight() !== ""));
        setModalOpen(true)
      }
    });    
  };

  useEffect(() => {

    if(showResponseModal) {
      setTimeout(() => {
        setShowResponseModal(false);
        setSaveSuccess(false);
      },3000)
    }
  },[showResponseModal])

  const handleReset = () => {
 
    setDocumentType("C");
    setPeriodType(1);
    setCCSectionOpen(false);
    setCCAddedUserSectionOpen(false);
    setCCSearchResultSectionOpen(false);
    setUserDetails({
      firstName:'',
      lastName:''
    });

    setFrequencyData({
      frequencyValue: "N",
      onceOnlyDate: "",
      endAfter: "",
      timeIntervalValue: "0",
      daysType: "D",
      noEndDate: false,
      startDate: "",
      endDate: "",
      periodFromDate: "",
    });
    setisResetClicked(true);

    setFilterFirstName('');
    setFilterLastName('');
    setFilterEmail('');
    setFilterUserName('');
    setSelectedClassification({});
    deselectAllUnits();
    deselectAllCourses();
    setFilterDateFrom('');
    setFilterDateTo('');
    setFilterCourseStatus(0);
    setFilterIncludeInactive(false);
    setIsHistoryChecked(false);
  };

  const expandAllUnits = () => {
    let updatedUnits = units;
    const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = true;
          checkForSelected(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const collapseAllUnits = () => {
    let updatedUnits = units;
    const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          unit.isExpanded = false;
          checkForSelected(unit.childUnitTreeItems);
        }
      }
    };
    checkForSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const selectAllUnits = () => {
    let updatedUnits = units;
    const setAllSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          if (!unit.isDisabled) {
              unit.isSelected = true;
	      }
          setAllSelected(unit.childUnitTreeItems);
        }
      }
    };
    setAllSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const deselectAllUnits = () => {
    let updatedUnits = units;
    const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        if (!unitArray[i].isHidden) {
          const unit = unitArray[i];
          if (!unit.isDisabled) {
            unit.isSelected = false;
          }
          setAllDeselected(unit.childUnitTreeItems);
        }
      }
    };
    setAllDeselected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const updateSelectedUnit = (id: number) => {
    let updatedUnits = units;
    const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === id) {
          unit.isSelected = !unit.isSelected;
        }
        updateSelected(unit.childUnitTreeItems);
      }
    };
    updateSelected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const updateExpandedUnit = (id: number) => {
    let updatedUnits = units;
    const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID === id) {
          unit.isExpanded = !unit.isExpanded;
        }
        checkForExpanded(unit.childUnitTreeItems);
      }
    };
    checkForExpanded(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const selectAllCourses = () => {
    let updatedCourses = courseList;
    for (let i = 0; i < updatedCourses.length; i++) {
      if (!updatedCourses[i].isHidden) {
        updatedCourses[i].isSelected = true;
      }
    }
    setInteractionCount(interactionCount + 1);
    setCourseList(updatedCourses);
  };

  const deselectAllCourses = () => {
    let updatedCourses = courseList;
    for (let i = 0; i < updatedCourses.length; i++) {
      if (!updatedCourses[i].isHidden) {
        updatedCourses[i].isSelected = false;
      }
    }
    setInteractionCount(interactionCount + 1);
    setCourseList(updatedCourses);
  };

  const updateSelectedCourse = (id: number) => {
    let updatedCourses = courseList;
    for (let i = 0; i < updatedCourses.length; i++) {
      if (updatedCourses[i].courseID === id) {
        updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
      }
    }
    setInteractionCount(interactionCount + 1);
    setCourseList(updatedCourses);
  };  

  const selectAllPastCourses = () => {
    let updatedCourses = pastcourseList;
    for (let i = 0; i < updatedCourses.length; i++) {
      if (!updatedCourses[i].isHidden) {
        updatedCourses[i].isSelected = true;
      }
    }
    setInteractionCount(interactionCount + 1);
    setPastCourseList(updatedCourses);
  };

  const deselectAllPastCourses = () => {
    let updatedCourses = pastcourseList;
    for (let i = 0; i < updatedCourses.length; i++) {
      if (!updatedCourses[i].isHidden) {
        updatedCourses[i].isSelected = false;
      }
    }
    setInteractionCount(interactionCount + 1);
    setPastCourseList(updatedCourses);
  };

  const updateSelectedPastCourse = (id: number) => {
    let updatedCourses = pastcourseList;
    for (let i = 0; i < updatedCourses.length; i++) {
      if (updatedCourses[i].courseID === id) {
        updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
      }
    }
    setInteractionCount(interactionCount + 1);
    setPastCourseList(updatedCourses);
  };

  const handleClassification = (e:any) =>{
    console.log("Event",e.target);
    let selectedItem = classificationList.find((item) => item.value == e.target.value )
     setSelectedClassification(selectedItem)}

  const selectAllChildUnits = (id: number) => {
    let updatedUnits = units;
    let parentList = [id];
    const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
          parentList.push(unit.unitID);
          unit.isSelected = true;
        }
        updateAllChildUnits(unit.childUnitTreeItems);
      }
    };
    updateAllChildUnits(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const deselectAllChildUnits = (id: number) => {
    let updatedUnits = units;
    let parentList = [id];
    const updateAllChildUnitsToUnselected = (
      unitArray: Array<UnitTreeItem> | null
    ) => {
      if (unitArray === null) return; //Break if no children
      if (unitArray.length === 0) return;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
          parentList.push(unit.unitID);
          unit.isSelected = false;
        }
        updateAllChildUnitsToUnselected(unit.childUnitTreeItems);
      }
    };
    updateAllChildUnitsToUnselected(updatedUnits);
    setInteractionCount(interactionCount + 1);
    setUnits(updatedUnits);
  };

  const isAnyUnitSelected = () => {
    let updatedUnits = units;
    const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return false; //Break if no children
      if (unitArray.length === 0) return false;
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.isSelected) {
          return true;
        }
        if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
          return true;
        }
      }
      return false;
    };
    return checkIfAnyUnitSelected(updatedUnits);
  };

  const handlePeriodSectionFromChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.type === "date" && event.target.name === "periodFromDate") {
      setFrequencyData({
        ...frequencyData,
        [event.target.name]: 
          ((event.target.value !== null && event.target.value !== undefined && 
            event.target.value.toString().trimLeft().trimRight() !== "") 
            ? 
            formatDate(new Date(event.target.value)) 
            : 
            event.target.value),
      });
    }
  }

  const handleFrequencySectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const prevNoEndDateFlag = frequencyData.noEndDate;
    if (event.target.type == "checkbox") {
      setFrequencyData({
        ...frequencyData,
        noEndDate: !prevNoEndDateFlag,
        endAfter: (event.target.name !== "noEndDate" ? (frequencyData.noEndDate ? frequencyData.endAfter : "") : 
                    prevNoEndDateFlag ? "" : frequencyData.endAfter),
        endDate: ((event.target.name === "noEndDate" && prevNoEndDateFlag === true) ? "" : frequencyData.endDate),
      });
    } else {
      setFrequencyData({
        ...frequencyData,
        [event.target.name]: (((event.target.name === "onceOnlyDate" || 
                                event.target.name === "startDate" ||
                                event.target.name === "periodFromDate" ||
                                event.target.name === "endDate"        
                               ) && 
                               event.target.value !== null && 
                               event.target.value !== undefined && 
                               event.target.value.toString().trimLeft().trimRight() !== ""
                              ) 
                              ? 
                              formatDate(new Date(event.target.value))
                              : 
                              event.target.value),
        endAfter: (
                    event.target.name === "endAfter" 
                    ? 
                    event.target.value 
                    : 
                    (
                      event.target.name !== "noEndDate" 
                      ? 
                      (
                        frequencyData.endAfter
                      ) 
                      : 
                      (
                        prevNoEndDateFlag 
                        ? 
                        "" 
                        : 
                        frequencyData.endAfter
                      )
                    )
                  ),
      });
      setGuideMe();
    }
    //console.log("Event=>Value   " + event.target.name + "=>" + event.target.value.toString() + " PrevEndDate " + prevNoEndDateFlag + " CurEndDate " + frequencyData.noEndDate)
    setPeriodType(periodType);
    //debugger
    if (frequencyData.frequencyValue === "N"){
      const curDate = new Date();
      setReportRunDate(getDateStringDDMMYYYY(curDate));
    }
    else if (frequencyData.frequencyValue === "O"){
      setReportRunDate((frequencyData.onceOnlyDate === null || frequencyData.onceOnlyDate === undefined) ? "report generation date" : frequencyData.onceOnlyDate);
    }
    else if (frequencyData.frequencyValue === "M"){
      setReportRunDate("report generation date");
    }
    else if (frequencyData === null || frequencyData === undefined || frequencyData.frequencyValue === null || frequencyData.frequencyValue === undefined){
      const curDate = new Date();
      setReportRunDate(getDateStringDDMMYYYY(curDate));
    }

  };

  const handleUserDetails = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {

      setUserDetails({
        ...userDetails,
        [event.target.name]: event.target.value,
      });

  };

  const handleAddedUsers = (data:any) => {
    let updatedData = [];
    let ccEmailList = [];
    if(data !== null && data !== undefined && data.length > 0) {
      updatedData = data.map((item:any) => item.userID);
      ccEmailList = data.map((item:any) => item.email);
      setCCUserList(updatedData.join(","))
      setCCUserEmailList(ccEmailList.join(","))
    }else {
      setCCUserList("");
      setCCUserEmailList("")
    }
    setCCAddedUserSectionOpen(true)
    setCCSearchResultSectionOpen(false)
  }

  const handleCCSearchClick = () => {
    setCCSearchResultSectionOpen(true)
    setCCAddedUserSectionOpen(false)
  }

  const handleUserSection = () => {
    var valueToSet = !isCCSectionOpen
    setCCSectionOpen(valueToSet)
  }

  const handleAddedUserSection = () => {
    var valueToSet = !isCCAddedUserSectionOpen
    setCCAddedUserSectionOpen(valueToSet)
  }

  const handleUserSearchResultSection = () => {
    var valueToSet = !isCCSearchResultSectionOpen
    setCCSearchResultSectionOpen(valueToSet)
  }


  const increaseDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined && 
      title.trimLeft().trimRight().toLowerCase() !== "" && 
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      given.currentDisplayRowCount = given.currentDisplayRowCount + given.defaultDisplayRowCount;
    }
    return given;
  }

  const decreaseDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined && 
      title.trimLeft().trimRight().toLowerCase() !== "" && 
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      let diff = (given.currentDisplayRowCount - given.defaultDisplayRowCount);
      given.currentDisplayRowCount = (diff < 3) ? 3 : diff;
    }
    return given;
  }

  const setAllAsDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined && 
      title.trimLeft().trimRight().toLowerCase() !== "" && 
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      if (given.rowDisplayValues !== null && given.rowDisplayValues !== undefined) {
        given.currentDisplayRowCount = given.rowDisplayValues.length;
      }
    }
    return given;
  }

  const resetDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined && 
      title.trimLeft().trimRight().toLowerCase() !== "" && 
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      given.currentDisplayRowCount = 3;
    }
    return given;
  }

  const clickShowMoreUnits = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => increaseDisplayRowCount(ms, "Units"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowAllUnits = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => setAllAsDisplayRowCount(ms, "Units"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickUnitDisplayListReset = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => resetDisplayRowCount(ms, "Units"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowLessUnits = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => decreaseDisplayRowCount(ms, "Units"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowMoreCourses = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => increaseDisplayRowCount(ms, "Courses"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowAllCourses = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => setAllAsDisplayRowCount(ms, "Courses"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickCourseDisplayListReset = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => resetDisplayRowCount(ms, "Courses"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowAllCommon = (sectionTitle: string) => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => setAllAsDisplayRowCount(ms, sectionTitle));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowLessCommon = (sectionTitle: string) => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => resetDisplayRowCount(ms, sectionTitle));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowLessCourses = () => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0){
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => decreaseDisplayRowCount(ms, "Courses"));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const updateCurrentProfileSelection = (givenSelectedCPDProfile: any) => {
    
    setSelectedProfileID(!!givenSelectedCPDProfile.profileID ? parseInt(givenSelectedCPDProfile.profileID.toString()) : -1);
    setSelectedProfileName(givenSelectedCPDProfile.profileName);
    if (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems && !!givenSelectedCPDProfile.profileID) {
      var filtered = orgCPDProfileResult.cpdProfileItems.filter((item:any) => item.profileID.toString() === givenSelectedCPDProfile.profileID.toString());
      setSelectedCPDProfilePeriodList((!!filtered === false || filtered.length <= 0 || !!filtered[0].profilePeriodItems === false) ? null : filtered[0].profilePeriodItems);
      setSelectedProfileName((!!filtered === false || filtered.length <= 0 || !!filtered[0].profileName === false) ? "" : filtered[0].profileName);
      if (!(!!filtered === false || filtered.length <= 0 || !!filtered[0] === false || !!filtered[0].profileName === false)) {
        updateCurrentProfilePeriodSelection({value: filtered[0].profilePeriodItems[0].profilePeriodID, profilePeriodID: filtered[0].profilePeriodItems[0].profilePeriodID});
      }
    }
    else {
      setSelectedCPDProfilePeriodList(null);
      setSelectedProfileName("");
    }
  }

  const updateCurrentProfilePeriodSelection = (givenSelectedCPDProfilePeriod: any) => {
    //debugger
    setSelectedProfilePeriodID(!!givenSelectedCPDProfilePeriod.profilePeriodID ? parseInt(givenSelectedCPDProfilePeriod.profilePeriodID.toString()) : -1);
    if (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems) {
      var filtered = !!selectedCPDProfile.profileID ? orgCPDProfileResult.cpdProfileItems.filter((item:any) => item.profileID.toString() === selectedCPDProfile.profileID.toString()) : undefined;
      if (!!filtered === false || filtered.length <= 0 || !!filtered[0].profilePeriodItems === false || !!givenSelectedCPDProfilePeriod.profilePeriodID === false) {
        setSelectedProfilePeriodName("")
      }
      else {
        var matchedPP = filtered[0].profilePeriodItems.filter((pp:any) => pp.profilePeriodID.toString() === givenSelectedCPDProfilePeriod.profilePeriodID.toString());
        if (!!matchedPP && matchedPP.length > 0) {
          setSelectedProfilePeriodName(matchedPP[0].profilePeriodName)
        }
        else {
          setSelectedProfilePeriodName("")
        }
      }                      
    }
    else {
      setSelectedProfilePeriodName("");
    }
  }

  return (
    <>
    <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
     onExit={onExit}
       options={{
       nextLabel: 'Next',
       prevLabel: 'Back',
       tooltipClass: 'sample-class',
       showBullets: true,
     }}/>
    <CustomTooltip displayText={["Guide Me"]}>
       <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
       <GuideIcon />
       </span>
    </CustomTooltip>
      <Modal
        type="warning"
        modalOpen={showModal}
        setModalOpen={handleModal}
        style={{ height: "232px", width: "381px", display: "flex"}}
        hideCloseBtn={true}
      >
        <div style={{ position: "relative", top: "-10px" }}>
          <h2 style={{ color: "#e7a652", width: "500px", marginTop: "4px" }}>{modalPopUpHeading}</h2>
          {
            (modalPopUpSettingsMessages === null ||
              modalPopUpSettingsMessages === undefined ||
              modalPopUpSettingsMessages.length <= 0) ? (
                 <p>
                  {modalPopUpMessages.map((e,index) => (
                    <>
                      <br />
                      <span style={{fontSize: "16px"}}  key={`modalPopupMessages-${index}`}>{e}</span>
                      <br />
                    </>
                  ))}
                </p>
              )
              :
              (
                <p >
                  {modalPopUpMessages.map((e,index) => (
                    <>
                      <div style={{fontSize: "16px"}}  key={`modalmsg-${index}`}>{e}</div>
                      <div key={`modalmsg2-${index}`}>
                        <div className={styles.popupsplitleft}>
                          { 
                            modalPopUpSettingsMessages && 
                            modalPopUpSettingsMessages.
                            filter(ssm => modalPopUpSettingsMessages.indexOf(ssm) >= 0 && (modalPopUpSettingsMessages.indexOf(ssm) % 2) === 0).
                            map((eee,index) => (
                              <>
                                <div style={{ margin: "10px" }}  key={`modalsettingmsg-${index}`}>
                                  <div style={{fontSize: "14px", fontWeight: "bold"}}>{eee.title}</div>
                                {
                                  eee.rowDisplayValues && 
                                  eee.rowDisplayValues.
                                    filter((rd, index) => index < eee.currentDisplayRowCount).
                                                  map((ee) => (
                                                    <>
                                                      <div style={{fontSize: "14px", color: "#21394F"}}>
                                                        {
                                                          (ee !== null && ee !== undefined && ee.indexOf("???") >= 0) ?
                                                          ee.split("???").map(
                                                            (ees,index) => (
                                                              (ees.trimLeft().trimRight() === "" ? 
                                                              <>{<span style={{color:"red", fontWeight: "bold"}} key={`ees-${index}`}> ??? </span>}</>
                                                              : 
                                                              <>{ees}{((ee.split("???").indexOf(ees) !== (ee.split("???").length-1)) ? <span key={`ees-${index}`} style={{color:"red", fontWeight: "bold"}}> ??? </span> : "")}</>)
                                                            )
                                                          )
                                                          :
                                                          ((eee.title.toLowerCase().startsWith("units") || 
                                                          eee.title.toLowerCase().startsWith("course") || 
                                                          eee.title.toLowerCase().startsWith("cc")) ?
                                                          <>
                                                            <table style={{width:"100%"}}>
                                                              <tr>
                                                                <td style={{width:"1%"}}>•</td>
                                                                <td style={{width:"95%", textAlign: "left"}}>{ee}</td>
                                                              </tr>
                                                            </table> 
                                                          </>
                                                          : ee)
                                                        }
                                                      </div>
                                                    </>
                                                  ))
                                }
                                {                                  
                                  (eee.rowDisplayValues.length > eee.currentDisplayRowCount) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={() => clickShowAllCommon(eee.title)} style={{fontSize: "14px", color: "blue"}}>+ Show All...</span></div>
                                  </>
                                  :
                                  <></>
                                }
                                {                                  
                                  (eee.rowDisplayValues.length > 3 && eee.currentDisplayRowCount > 3) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={() => clickShowLessCommon(eee.title)} style={{fontSize: "14px", color: "blue"}}>- Show only 3...</span></div>
                                  </>
                                  :
                                  <></>
                                }
                                </div>
                                <br/>
                              </>
                            ))
                          }
                        </div>
                        <div className={styles.popupsplitright}>
                          { 
                            modalPopUpSettingsMessages && 
                            modalPopUpSettingsMessages.
                            filter(ssm => modalPopUpSettingsMessages.indexOf(ssm) >= 0 && (modalPopUpSettingsMessages.indexOf(ssm) % 2) !== 0).
                            map((eee) => (
                              <>
                                <div style={{ margin: "10px" }}>
                                  <div style={{fontSize: "14px", fontWeight: "bold"}}>{eee.title}</div>
                                {
                                  eee.rowDisplayValues && 
                                  eee.rowDisplayValues.
                                    filter((rd, index) => index < eee.currentDisplayRowCount).
                                                  map((ee) => (
                                                    <>
                                                      <div style={{fontSize: "14px", color: "#21394F"}}>
                                                        {
                                                          (ee !== null && ee !== undefined && ee.indexOf("???") >= 0) ?
                                                          ee.split("???").map(
                                                            (ees,i) => (
                                                              (ees.trimLeft().trimRight() === "" ? 
                                                              <>{<span style={{color:"red", fontWeight: "bold"}} key={`ees-${i}`}> ??? </span>}</>
                                                              : 
                                                              <>{ees}{((ee.split("???").indexOf(ees) !== (ee.split("???").length-1)) ? <span style={{color:"red", fontWeight: "bold"}} key={`ees-${i}`}> ??? </span> : "")}</>)
                                                            )
                                                          )
                                                          :
                                                          ((eee.title.toLowerCase().startsWith("units") || 
                                                          eee.title.toLowerCase().startsWith("course") || 
                                                          eee.title.toLowerCase().startsWith("cc")) ?
                                                          <>
                                                            <table style={{width:"100%"}}>
                                                              <tr>
                                                                <td style={{width:"1%"}}>•</td>
                                                                <td style={{width:"95%", textAlign: "left"}}>{ee}</td>
                                                              </tr>
                                                            </table> 
                                                          </>
                                                          : ee)
                                                        }
                                                      </div>
                                                    </>
                                                  ))
                                }
                                {/* {                                  
                                  (eee.rowDisplayValues.length > eee.currentDisplayRowCount) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={clickShowMoreCourses} style={{fontSize: "14px", color: "blue"}}>+ Show 10 more...</span></div>
                                  </>
                                  :
                                  <></>
                                }
                                {                                  
                                  (eee.rowDisplayValues.length > 10 && eee.currentDisplayRowCount > 10) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={clickShowLessCourses} style={{fontSize: "14px", color: "blue"}}>- Show 10 less...</span></div>
                                  </>
                                  :
                                  <></>
                                } */}
                                {                                  
                                  (eee.rowDisplayValues.length > eee.currentDisplayRowCount) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={() => clickShowAllCommon(eee.title)} style={{fontSize: "14px", color: "blue"}}>+ Show All...</span></div>
                                  </>
                                  :
                                  <></>
                                }
                                {                                  
                                  (eee.rowDisplayValues.length > 3 && eee.currentDisplayRowCount > 3) 
                                  ? 
                                  <>
                                    <div style={{fontSize: "14px"}}><span onClick={() => clickShowLessCommon(eee.title)} style={{fontSize: "14px", color: "blue"}}>- Show only 3...</span></div>
                                  </>
                                  :
                                  <></>
                                }
                                </div>
                                <br/>
                              </>
                            ))
                          }
                        </div>
                      </div>
                    </>
                  ))}
                </p>
              )
          }
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "10px",
          }}
        >
          <CustomTooltip displayText={["Return"]}>
            <span className={styles.returnIcon}>
              <span
                onClick={() => {
                  if (
                    modalPopUpHeading !== undefined &&
                    modalPopUpHeading !== null &&
                    (modalPopUpHeading.toLowerCase().indexOf("report size") >= 0 ||
                     modalPopUpHeading.toLowerCase().indexOf("scheduled report settings confirmation") >= 0 ||
                     modalPopUpHeading.toLowerCase().indexOf("settings error") >= 0)
                  ) {                    
                    setSaveClicked(false);
                    setIsSaving(false);
                    setModalOpen(false);
                    setModalPopUpHeading("Unsaved Changes");
                    setModalPopUpMessages([
                      "Do you want to proceed without saving changes?",
                    ]);
                  }
                  setModalOpen(false);
                  setIsLoggingOut(false);
                  setModalPopUpSettingsMessages(null);
                }}
              >
                <CloseIcon />
              </span>
            </span>
          </CustomTooltip>
          <CustomTooltip displayText={["Proceed"]} 
            hidden={
                    (modalPopUpHeading !== null && modalPopUpHeading !== undefined && 
                     modalPopUpHeading.toLowerCase().indexOf("settings error") >= 0 && 
                     nonWarningError !== null && nonWarningError !== undefined && 
                     nonWarningError.trimLeft().trimRight() !== "") ? true : false}>
            <span className={styles.proceedIcon}>
              <span
                onClick={() => {
                  if (IsLoggingOut) {
                    setMessage(allResourcesToTranslations.loggingout);
                    logout();
                    addError(
                      oidcUser.access_token,
                      userId,
                      orgDomain,
                      "Logged out",
                      null,
                      "UserControls.tsx",
                      6, EnableActivityLogs
                    );
                    getUserManager()
                      .signoutRedirect()
                      .catch((error) => {
                        //TODO Error API here
                      });
                    getUserManager()
                      .signoutRedirectCallback()
                      .then(() => {
                        getUserManager().removeUser();
                      })
                      .catch((error) => {
                        //TODO Error API here
                      });
                  } else {
                    if (
                      modalPopUpHeading !== undefined &&
                      modalPopUpHeading !== null &&
                      modalPopUpHeading.toLowerCase().indexOf("report size") >= 0
                    ) {
                      updateReportSchdulerDataGiven();
                      setSaveClicked(false);
                      setIsReportSchedulerChangedAndNotSaved(false);
                      setIsSaving(false);
                      setModalOpen(false);
                      setModalPopUpHeading("Unsaved Changes");
                      setModalPopUpMessages([
                        "Do you want to proceed without saving changes?",
                      ]);
                    }
                    else if (modalPopUpHeading !== undefined &&
                             modalPopUpHeading !== null &&
                             modalPopUpHeading.toLowerCase().indexOf("scheduled report settings confirmation") >= 0) {
                      setModalOpen(false);
                      setModalPopUpHeading("Unsaved Changes");
                      setModalPopUpMessages([
                        "Do you want to proceed without saving changes?",
                      ]);
                      handleSave();
                    }
                    else {
                      setSaveClicked(false);
                      setIsReportSchedulerChangedAndNotSaved(false);
                      setIsSaving(false);
                      setGoBack(true);
                    }
                  }
                  setModalPopUpSettingsMessages(null);
                }}
              >
                <ProceedIcon />
              </span>
            </span>
          </CustomTooltip>
        </div>
      </Modal>
      <Accordion
        style={{ margin: "10px 0px", borderRadius: "6px 6px 6px 6px", border:'0px dashed transparent', position: 'inherit' }}
      >
        { report != "CPDR" && report != "PBR" && (
          <>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <b>Unit & Course Selections</b>
          </AccordionSummary>
          </>
          )
        }
        { report == "CPDR" && (
          <>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <b>Unit & CPD Selections</b>
          </AccordionSummary>
          </>
          )
        }
        { report == "PBR" && (
          <>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <b>Unit & Policy Selections</b>
          </AccordionSummary>
          </>
          )
        }
        <div className={styles.reportWrraper}>
          <div className={styles.unittreesection}>
            <div>
              <h4 className={styles.sectionTitle}>Unit</h4>
              <p className={styles.blankContent}>Leave blank for all Units</p>
              <UnitFilterButtons
                expandAllUnits={expandAllUnits}
                colapseAllUnits={collapseAllUnits}
                selectAllUnits={selectAllUnits}
                deselectAllUnits={deselectAllUnits}
              />
            </div>
            <div className={styles.inputContainer}>
              <TextField
                className={styles.input}
                label={"Unit Search"}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={filterUnitName}
                onChange={(e) => setFilterUnitName(e.target.value)}
              />
            </div>
            <div className={styles.treeContianer}>
              <UnitTree
                units={units}
                updateSelectedUnit={updateSelectedUnit}
                updateExpandedUnit={updateExpandedUnit}
                selectAllChildUnits={selectAllChildUnits}
                deselectAllChildUnits={deselectAllChildUnits}
                isAnyUnitSelected={isAnyUnitSelected}
              />
            </div>
            { 
              report != "CPDR" && report != "PBR" && (
              <>
              <div hidden={isSmallUnitTree === false}>
                <h4 className={styles.sectionTitle}>Course</h4>
                <p className={styles.blankContent}>Leave blank for all Courses</p>
                <CourseFilterButtons
                  selectAllCourses={selectAllCourses}
                  deselectAllCourses={deselectAllCourses}
                />
              </div>
              <div
                className={styles.inputContainer}
                hidden={isSmallUnitTree === false}
              >
                <TextField
                  className={styles.input}
                  label={"Course Search"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterCourseName}
                  onChange={(e) => setFilterCourseName(e.target.value)}
                />
              </div>
              <div
                className={styles.courseTreeContianer}
                hidden={isSmallUnitTree === false}
              >
                <CourseListTree
                  courseList={courseList}
                  uniquePrefix="chkNewSaltCAR_"
                  updateSelectedCourse={updateSelectedCourse}
                />
              </div>
              </>
              )
            }
          </div>
          
          <div className={styles.coursetreesection}>

            { 
              report != "CPDR" && report != "PBR" && (
              <>
              <div hidden={isSmallUnitTree === true}>
                <h4 className={styles.sectionTitle}>Course</h4>
                <p className={styles.blankContent}>Leave blank for all Courses</p>
                <CourseFilterButtons
                  selectAllCourses={selectAllCourses}
                  deselectAllCourses={deselectAllCourses}
                />
              </div>
              <div
                className={styles.inputContainer}
                hidden={isSmallUnitTree === true}
              >
                <TextField
                  className={styles.input}
                  label={"Course Search"}
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  value={filterCourseName}
                  onChange={(e) => setFilterCourseName(e.target.value)}
                />
              </div>
              <div
                className={styles.courseTreeContianer}
                hidden={isSmallUnitTree === true}
              >
                <CourseListTree
                  courseList={courseList}
                  uniquePrefix="chkNewSaltCAR_"
                  updateSelectedCourse={updateSelectedCourse}
                />
              </div>
              </>
              )
            }
            {
              report == "CAR" && (
                <>      
                { isHistoryChecked &&         
                <div className={styles.inputContainer}>
                                  <TextField
                                    className={styles.input}
                                    defaultValue={filterDateTo}
                                    label={"Historic Date"}
                                    variant="outlined"
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    type="date"                    
                                    onChange={(e) => setFilterDateTo(e.target.value)}
                                  />
                                </div>   
                }           
                {isHistoryChecked ? (                        
                        <div>
                                <div>&nbsp;</div>    
                                {/* <div className={`${styles.inactiveContainer} includeHistoricdata`} >                           
                                                <Checkbox
                                                    size="small"
                                                    color="primary"                                       
                                                    className={styles.checkBox}
                                                    checked={isHistoryChecked}
                                                    onClick={() => setIsHistoryChecked(!isHistoryChecked)}
                                            /> {"Include Historic Course"}                             
                                </div> */}
                                <div>
                                      <h4 className={styles.sectionTitle}>Past course</h4>
                                      {/* <p className={styles.blankContentwithcolor}>Please select for Past Course Search</p> */}
                                      <div className={styles.children_centering_container}>
                                        <div className={styles.icon_and_text_container}>
                                            <div className={styles.icon_and_text_container_icon}>
                                                <AlertGreenIcon />
                                            </div>
                                            <div className={styles.icon_and_text_container_text}>
                                            <div>
                                                <span className={styles.blankContentwithgreencolor}>To report on an inactive course, please make your selection below</span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                      {/* <p className={styles.blankContentwithgreencolor}>You must select a Historic course to continue</p> */}
                                      <CourseFilterButtons
                                        selectAllCourses={selectAllPastCourses}
                                        deselectAllCourses={deselectAllPastCourses}
                                      />
                                </div>
                                <div>&nbsp;</div> 
                                <div className={styles.inputContainer}>
                                      <TextField
                                        className={styles.input}
                                        label={"Past Course Search"}
                                        variant="outlined"
                                        autoComplete="off"
                                        InputLabelProps={{ shrink: true }}
                                        value={filterPastCourseName}
                                        onChange={(e) => setFilterPastCourseName(e.target.value)}
                                      />
                                </div>
                                <div className={styles.courseTreeContianer}>
                                      <CourseListTree
                                        courseList={pastcourseList}
                                        uniquePrefix="chkNewSaltHCAR_"
                                        updateSelectedCourse={updateSelectedPastCourse}/>
                                </div>           
                                        
                        </div>
                        ) : (
                        ''
                )}                   
                </>
                )
            }
            { 
              report == "PBR" && 
              <div className={styles.rightSection}>  
                <PolicySelection selectAllPolicies={handleSelectAllPolicies} handlePolicySearch={handlePolicySearch} searchPolicy={searchPolicy} deselectAllPolicies={handleUnSelectAllPolicies} orgPolicyOptions={orgPolicyOptions} updatePolicyOptions={handlePolicyOptionsUpdate} sectionFlag={isSmallUnitTree} />      
                <div style={{marginTop:'15px'}} className="policyAcceptance">
                  <FormControl component="fieldset" style={{ width: '100%' }} className="recipient">
                      <FormLabel component="legend">Policy Acceptance</FormLabel>
                      <div className={styles.childContainer}>
                          <RadioGroup
                              name="periodType"
                              value={policyAcceptance}
                              onChange={handlePolicyAcceptance}
                              style={{ display: 'flex', flexDirection: 'row' }}
                          >
                              <FormControlLabel
                                  value={"accepted"}
                                  control={<Radio color="primary" />}
                                  label={"Accepted"}
                              />
                              <FormControlLabel
                                  value={"not_accepted"}
                                  control={<Radio color="primary" />}
                                  label={"Not Accepted"}
                              />
                              <FormControlLabel
                                  value={"both"}
                                  control={<Radio color="primary" />}
                                  label={"Both"}
                              />
                          </RadioGroup>
                      </div>
                  </FormControl>
                </div>
              </div>
            }
            {(report == "CSR" || report == "ER") && (
              <div className={styles.inputContainer}>
                <span>Course Status:</span>
                <Select
                  id="selNewSaltCSRCourseStatus"
                  native
                  value={filterCourseStatus}
                  className={styles.input}
                  variant="outlined"
                  onChange={(e) => setFilterCourseStatus(e.target.value)}
                >
                  <option value={0}>Incomplete</option>
                  <option value={6}>Incomplete(With Details)</option>
                  <option value={1}>Complete</option>
                  <option value={2}>Fail</option>
                  <option value={3}>Not Started</option>
                  <option value={4}>Expired(Time Elapsed)</option>
                  <option value={5}>Expired(New Content)</option>
                </Select>
              </div>
            )}

            {(report == "CSR" || report == "SR" || report == "ER" || report == "PBR") && (
              <>
               {periodType !== 3 && (
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    defaultValue={filterDateFrom}
                    label={"Date From"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    type="date"                    
                    onChange={(e) => setFilterDateFrom(e.target.value)}
                  />
                </div>
                 )}
                {(report !== "ER") && (
                  <div className={styles.inputContainer}>
                    <TextField
                      className={styles.input}
                      defaultValue={filterDateTo}
                      label={"Date To"}
                      variant="outlined"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      onChange={(e) => setFilterDateTo(e.target.value)}
                    />
                  </div>
                )}
              </>
            )}
            {(report !== "SR" && report != "ER" && report != "CPDR" && report != "PBR" && report != "WR") && (
              <>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"First Name"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterFirstName}
                    onChange={(e) => setFilterFirstName(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"Last Name"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterLastName}
                    onChange={(e) => setFilterLastName(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"Username"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterUserName}
                    onChange={(e) => setFilterUserName(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"Email"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </div>
              </>
            )}
            
            {(report == "WR") && (
              <>
                <div className={`${styles.daysField} expiredIn`} style={{textAlign:'left'}} >
                <FormControl variant="outlined" style={{width:'49%',textAlign:'initial'}}>
                <TextField  className={styles.expiredIn} label={"Expired in"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                                value={filterWarningPeriod} onChange={(e) => setFilterWarningPeriod(e.target.value)}/>
                </FormControl>
                <FormControl variant="outlined" style={{width:'49%',textAlign:'initial', marginTop:'10px', marginLeft:'12px'}}>
                    <Select
                                value={filterDayType}
                                name="daysType"
                                style={{ backgroundColor: "white" }}
                                onChange={(e) => setFilterDayType(e.target.value)}
                            >
                                <MenuItem value={"dd"}>Day/s</MenuItem>
                                <MenuItem value={"mm"}>Month/s</MenuItem>
                            </Select>
                    </FormControl>
                </div>
              </>
            )}

            {(report == "CPDR") && (
              <>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"First Name"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterFirstName}
                    onChange={(e) => setFilterFirstName(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"Last Name"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterLastName}
                    onChange={(e) => setFilterLastName(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"Username"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterUserName}
                    onChange={(e) => setFilterUserName(e.target.value)}
                  />
                </div>
                {/* <div className={styles.inputContainer}>
                  <TextField
                    className={styles.input}
                    label={"Email"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    value={filterEmail}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </div> */}
              </>
            )}
            {report != "CPDR" && report != "PBR" && groupingOption && selectedClassification && (
              <div className={styles.groupingOption}>
                <FormControl variant="outlined" className={styles.formControl}>
                  <InputLabel id="grouping2">Grouping Option</InputLabel>
                  <Select
                                    value={selectedClassification.value}
                                    native
                                    onChange={(e) => setSelectedClassification({value: e.target.value, classificationID: e.target.value})}
                                    className={styles.input}
                                    variant="outlined" 
                                    label="Grouping Option"
                                    id="groupingOption"
                                    >
                                    {classificationList.map((item:any,index) => {
                                        return <option value={item.value} key={`classificationList-${index}`}>{item.text}</option>
                                    })}
                    </Select>
                </FormControl> 
                              {/* <MUICustomSelect idName={"groupingOption"} labelName={"Grouping Option"} currentValue={selectedClassification.value}
                    setCurrentValue={(e : any) => 
                    {
                        if (!!e.target) {
                            setSelectedClassification({ value: e.target.value, classificationID: e.target.value, 
                                text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text });
                        }
                        else {
                            let filtered = !!classificationList ? classificationList.filter((cur : any) => !!cur && 
                                            parseInt(cur.classificationID) === parseInt(e)) : null;
                            if (!!filtered && filtered.length > 0){                                                                
                                setSelectedClassification({ value: filtered[0].value, classificationID: filtered[0].value, 
                                    text: filtered[0].text });
                            }
                        }
                    } }
                    isFocussed={isGroupingOptionFocussed} setIsFocussed={setIsGroupingOptionFocussed}
                    inputLabelStyle={{}} listOfItems={classificationList}/>*/}
              </div>
            )}
            {report == "CPDR" && (
              <>
              <div>
              <div className={styles.groupingOption} >
                                      {orgCPDProfileResult?.cpdProfileItems.length > 0 &&
                                       <FormControl variant="outlined" className={`${styles.formControl} cpdProfile`}>                      
                                         <InputLabel id="cpdProfileID">CPD Profile</InputLabel>
                                             <Select
                                             value={selectedCPDProfile.value}
                                             native
                                             onChange={(e : any) => { 
                                              let profID : string = e.target.value.toString();
                                              let matched = !!orgCPDProfileResult ? orgCPDProfileResult.cpdProfileItems.filter((cur : any) => !!cur && 
                                              parseInt(cur.profileID) === parseInt(profID)) : null;
                                              var curSel = {value: e.target.value, profileID: e.target.value, profileName: (!!matched && !!matched[0]) ? matched[0].profileName : ""};
                                              setSelectedCPDProfile(curSel);
                                              updateCurrentProfileSelection(curSel);
                                             }}
                                             className={styles.input}
                                             variant="outlined" 
                                             label="CPD Profile"
                                             id="cpdProfileID"
                                             >
                                             {!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems && orgCPDProfileResult.cpdProfileItems.map((item:any) => {
                                                 return <option key={item.profileID} value={item.profileID} >{item.profileName}</option>
                                             })}
                                         </Select>
                                       </FormControl>
                       /*<MUICustomSelect idName={"cpdProfileID"} labelName={"CPD Profile"} currentValue={selectedCPDProfile.value}
                        setCurrentValue={(e : any) => 
                        {
                            if (!!e.target) {
                                let curSel = { value: e.target.value, profileID: e.target.value, 
                                text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text };
                                setSelectedCPDProfile(curSel);
                                updateCurrentProfileSelection(curSel);
                            }
                            else {
                                let filtered = (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems) ? 
                                            orgCPDProfileResult.cpdProfileItems.filter((cur : any) => !!cur && 
                                                parseInt(cur.profileID) === parseInt(e)) : null;
                                if (!!filtered && filtered.length > 0){     
                                  let curSel = { value: filtered[0].value, profileID: filtered[0].value, text: filtered[0].text }                                                           
                                    setSelectedClassification(curSel);
                                    updateCurrentProfileSelection(curSel);
                                }
                            }
                        } }
                        isFocussed={isCPDProfileFocussed} setIsFocussed={setIsCPDProfileFocussed}
                        inputLabelStyle={{}} listOfItems={orgCPDListToPass} formStylePostfix={"cpdProfile"}/>*/
                }
              </div>
            </div>
            <div >
              <div className={styles.groupingOption} >
                                      {!!selectedCPDProfilePeriodList &&
                                       <FormControl variant="outlined" className={`${styles.formControl} cpdProfilePeriod`} >                      
                                         <InputLabel id="cpdProfilePeriodID">CPD Profile Period</InputLabel>
                                             <Select
                                             value={selectedCPDProfilePeriod.value}
                                             native
                                             onChange={(e : any) => 
                                               {
                                                let profPrdID : string = e.target.value.toString();
                                                let matched = !!selectedCPDProfilePeriodList ? selectedCPDProfilePeriodList.filter((cur : any) => !!cur && 
                                                parseInt(cur.profilePeriodID) === parseInt(profPrdID)) : null;
                                                var curSel = {value: e.target.value, profilePeriodID: e.target.value, profilePeriodName: 
                                                (!!matched && !!matched[0]) ? matched[0].profilePeriodName : ""};
                                                setSelectedCPDProfilePeriod({value: e.target.value, profilePeriodID: e.target.value});
                                                updateCurrentProfilePeriodSelection(curSel);
                                               }}
                                             className={styles.input}
                                             variant="outlined" 
                                             label="CPD Profile Period"
                                             id="cpdProfilePeriodID"
                                             >
                                             {
                                               !!selectedCPDProfilePeriodList && 
                                               selectedCPDProfilePeriodList.map((item:any) => { 
                                                 //debugger
                                                 return <option key={item.profilePeriodID} value={item.profilePeriodID} >{item.profilePeriodName}</option>
                                               })
                                             }
                                         </Select>
                                       </FormControl>
                                      /*<MUICustomSelect idName={"cpdProfilePeriodID"} labelName={"CPD Profile Period"} currentValue={selectedCPDProfilePeriod.value}
                setCurrentValue={(e : any) => 
                {
                    if (!!e.target) {
                      let curSel = { value: e.target.value, profilePeriodID: e.target.value, 
                      text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text };
                      setSelectedCPDProfilePeriod(curSel);
                      updateCurrentProfilePeriodSelection(curSel);
                    }
                    else {
                      let filtered = (!!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems) ? selectedCPDProfile.profilePeriodItems.filter((cur : any) => !!cur && 
                                      parseInt(cur.profilePeriodID) === parseInt(e)) : null;
                      if (!!filtered && filtered.length > 0){     
                        let curSel = { value: filtered[0].value, profilePeriodID: filtered[0].value, text: filtered[0].text }                                                           
                        setSelectedCPDProfilePeriod(curSel);
                        updateCurrentProfilePeriodSelection(curSel);
                      }
                    }
                } }
                isFocussed={isCPDProfilePeriodFocussed} setIsFocussed={setIsCPDProfilePeriodFocussed}
                inputLabelStyle={{}} listOfItems={orgCPDPeriodListToPass} formStylePostfix={"cpdProfilePeriod"}/>*/
              }
              </div>
            </div>
            <div className={`${styles.inactiveContainer} includeInactive`} >
              <Checkbox
                size="small"
                color="primary"
                checked={shortfallUsers}
                className={styles.checkBox}
                onClick={
                  () => {
                    let sfusers : boolean = false; 
                    sfusers = !shortfallUsers;            
                    setShortfallUsers(sfusers);
                    setFilterOnlyUsersWithShortfall(sfusers ? "Y" : "N");
                  }
                }
              />{" "}
              {"Show only users with Shortfall"}
            </div>
            </>
            )
            }
            {
              (report == "ER") && (
                <div>
                <FormControl component="fieldset" style={{ width: '100%'  }}>
                <FormLabel component="legend">Recipient</FormLabel>
                <div className={styles.childContainer}>
                  <RadioGroup
                    name="periodType"
                    value={recipientType}
                    onChange={handleRecipientChange}
                    style={{display:'flex', flexDirection:'row'}}
                  >                    
                    <FormControlLabel
                      value={"admins"}
                      control={<Radio color="primary" />}
                      label={"Administrators"}
                    />
                    <FormControlLabel
                            value={"users"}
                            control={<Radio color="primary" />}
                            label={"Users"}
                    />                    
                  </RadioGroup>
                </div>
              </FormControl>
            </div>
              )
            }

            {(report !== "SR" && report != "ER" && report != "CPDR" && report != "WR" && !(report == "CAR" && !isHistoryChecked)) && (
              <div className={styles.inputContainer}>
                <Checkbox
                  size="small"
                  color="primary"
                  checked={filterIncludeInactive}
                  className={styles.checkBox}
                  onClick={() =>
                    setFilterIncludeInactive(!filterIncludeInactive)
                  }
                />{" "}
                {report != "PBR" ? "Include Inactive Accounts" : "Include Inactive Accounts"}
              </div>
            )}
          </div>
        </div>
      </Accordion>
      <BrandedCard>
        <div style={{  }}>
        <br/>
        <div className="rptTitle">
        <div className={styles.leftSection} style={{ display: "flex", marginLeft: "10px"}}>
            <TextField
                    className={styles.inputBold}
                    label={"Report Title"}
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    style={{ marginLeft: "10px", marginRight: "30px", marginTop: "15px" }}
                    onChange={(e) => setScheduleReportTitle(e.target.value)}
                    value={scheduleReportTitle}                   
          />
        </div>
        </div>
        <br/>
        <div style={{ display: "flex",margin:"10px" }}>
          
          <div className={styles.leftSection}>
            <div className="chkfrequency">
            <FrequencySection
              handleFrequencySectionChange={handleFrequencySectionChange}
              frequencyData={frequencyData}
              getFormattedDayOfCurrentDayOffsetWithGivenDayCount={getFormattedDayOfCurrentDayOffsetWithGivenDayCount}
            />
            </div>
          </div>
          {
            <div className={styles.rightSection}>
              <div className="periodReport">
              <PeriodSection
                frequencyData={frequencyData}
                periodType={periodType.toString()}
                everyInterval={everyInterval.toString()}
                handlePeriodChange={handlePeriodChange}
                dateVal={"report generation date"}
                handlePeriodSectionFromChange={handlePeriodSectionFromChange}
                reportId={reportId}
              />
              </div>
              <div className="exportReport">
              <DocumentExportSection
                documentType={documentType}
                handleDocumentChange={handleDocumentChange}
              />
              </div>
            </div>
          }
        </div>

        <div
        style={{margin:"0px 20px 0px 10px"}}
        >
          <div className="ccuserSection">
          <UserSection
            handleUserSection={handleUserSection}
            handleCCSearchClick={handleCCSearchClick}
            handleAddedUserSection={handleAddedUserSection}
            handleUserSearchResultSection={handleUserSearchResultSection}
            isCCSectionOpen={isCCSectionOpen}
            isCCAddedUserSectionOpen={isCCAddedUserSectionOpen}
            isCCSearchResultSectionOpen={isCCSearchResultSectionOpen}
            handleUserDetails={handleUserDetails}
            userDetails={userDetails}
            handleAddedUsers={handleAddedUsers}
            scheduleId={scheduleId}
            isResetClicked={isResetClicked}
          />
        </div>

        <div className={styles.lowerButtonPanel} style={{marginRight:'0px', paddingBottom: '5px'}}>
            <div className="resetAll" style={{marginBottom:'10px'}}>
                <CustomTooltip displayText={["Reset"]}>                  
                    <span className={styles.emailResetIcon} onClick={handleReset}>
                      <ResetIcon />
                    </span>
                  </CustomTooltip>
              </div>
              <div className="saveReport">
                 <CustomTooltip displayText={["Save"]}>
                        <span>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="save"
                            onClick={handleSaveClick}
                            size="medium"
                          >
                            <SaveIcon />
                          </Button>
                        </span>
                      </CustomTooltip>
              </div>                       

          <div className={styles.loaderContainer}>
            <CircularProgress
              style={{ width: isSaving ? "20px" : "0px" }}
              color="primary"
              size="10"
            />
            {/* <span style={{ opacity: isSaving ? "1" : "0" }} className={styles.profileUpdatedText}>
                {savedMessage}
              </span> */}
          </div>
        </div>
        </div>
        </div>
      </BrandedCard>

      {showResponseModal && <ReportSaveModal saveSuccess={saveSuccess} />}
    </>
  );
};

export default ReportScheduler;
