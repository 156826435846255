import axios from 'axios';
import { addError } from '../../AddError';

const SaveGivenCPDProfile = async (token: string, requestinguserId: number, orgDomain: string, orgId: number, profileID: number, profilePeriodID: number, isProfileEnabled: boolean,
                                    isNewProfile: boolean, profileName: string, currentStartDate: string, currentEndDate: string,
                                    currentPoints: number, endOfPeriodAction: string, futureMonthIncrement: number, 
                                    futureStartDate: string, futureEndDate: string, futurePoints: number, clonedProfileID: number = -1, clonedProfilePeriodID: number = -1, applyNewsfeedCPDPoints: boolean = false, langCode: string = "en-Au") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/CPDProfiles/SaveGivenCPDProfile`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        "RequestingUserID": requestinguserId,
        "OrgID": orgId,
        "ProfileID": profileID,
        "ProfilePeriodID": profilePeriodID,
        "IsNewProfile": isNewProfile,
        "ProfileName": profileName,
        "CurrentStartDate": currentStartDate,
        "CurrentEndDate": currentEndDate,
        "CurrentPoints": currentPoints,
        "EndOfPeriodAction": endOfPeriodAction,
        "FutureMonthIncrement": futureMonthIncrement,
        "FutureStartDate": futureStartDate,
        "FutureEndDate": futureEndDate,
        "FuturePoints": futurePoints,
        "IsProfileEnabled": isProfileEnabled,
        "ClonedProfileID": clonedProfileID,
        "ClonedProfilePeriodID": clonedProfilePeriodID,
        "ApplyNewsfeedCPDPoints": applyNewsfeedCPDPoints,
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "SaveGivenCPDProfile.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "SaveGivenCPDProfile.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "SaveGivenCPDProfile.ts", 1)
    }
    return null;
  }
};

export default SaveGivenCPDProfile;