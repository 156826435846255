import React, { useEffect, useContext } from "react";
import { Link, useHistory } from 'react-router-dom';
import BrandedCard from "../../../Components/BrandedCard";

//Styles
import styles from "../../../styles/addAccount.module.css";
import {
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import getColor from "../../../functions/getColor";
import { Checkbox } from "@material-ui/core";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import ActiveIcon from "../../../CustomIcons/Status_ActiveIcon";
import InActiveIcon from "../../../CustomIcons/Status_InActiveIcon";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import CustomTooltip from "../../../Components/CustomTooltip";
import LoaderContainer from "../../../Components/LoaderContainer";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import PropTypes from "prop-types";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';

interface SubscriberGropuListProps {
  listData: any;
  needLatestList: boolean;
  setUpdatedSelectedName: Function;
  handleEdit: Function;
}
const SubscriberGropuList = ({ listData, needLatestList = false, setUpdatedSelectedName, handleEdit }: SubscriberGropuListProps) => {
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
      .toLowerCase()
      .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    translations: { allResourcesToTranslations },
  } = useContext(TranslationsContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [nfSubscriberListNameDescending, setNfSubscriberListNameDescending] = React.useState<boolean>(false);
  const [statusDescending, setStatusDescending] = React.useState<boolean>(false);
  const [createdDateDescending, setCreatedDateDescending] = React.useState<boolean>(false);
  const [modifiedDayDescending, setModifiedDayDescending] = React.useState<boolean>(false);
  const [currentSortBy, setCurrentSortBy] = React.useState<string>("");
  const [reverseNextSort, setReverseNextSort] = React.useState<boolean>(false);
  const [sortInProgress, setSortInProgress] = React.useState<boolean>(false);
  const [assignAll, setAssignAll] = React.useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [subscriberGroupList, setSubscriberGroupList] = React.useState<Array<any>>(listData);
  const history = useHistory();

  const {
    theme: { color },
  } = useContext(CorperateThemeContext);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const sortResultsByAsync = async (
    sortBy: "NfSubscriberListName" | "CreatedDate" | "ModifiedDate" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    if (subscriberGroupList == null || subscriberGroupList.length <= 0) {
      return subscriberGroupList;
    }
    var result = subscriberGroupList.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "NfSubscriberListName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.nfSubscriberListName === null) {
              return -1;
            }
            if (a === null || a.nfSubscriberListName === null) {
              return 1;
            }
            if (a.nfSubscriberListName.toLowerCase() > b.nfSubscriberListName.toLowerCase()) {
              return -1;
            }
            if (a.nfSubscriberListName.toLowerCase() < b.nfSubscriberListName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNfSubscriberListNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.nfSubscriberListName === null) {
              return 1;
            }
            if (a === null || a.nfSubscriberListName === null) {
              return -1;
            }
            if (a.nfSubscriberListName.toLowerCase() < b.nfSubscriberListName.toLowerCase()) {
              return -1;
            }
            if (a.nfSubscriberListName.toLowerCase() > b.nfSubscriberListName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNfSubscriberListNameDescending(true);
          }
        }
        setSubscriberGroupList(result);
        break;
      case "Status":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.isActive === null) {
              return -1;
            }
            if (a === null || a.isActive === null) {
              return 1;
            }
            if (a.isActive.toString().toLowerCase() > b.isActive.toString().toLowerCase()) {
              return -1;
            }
            if (a.isActive.toString().toLowerCase() < b.isActive.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.isActive === null) {
              return 1;
            }
            if (a === null || a.isActive === null) {
              return -1;
            }
            if (a.isActive.toString().toLowerCase() < b.isActive.toString().toLowerCase()) {
              return -1;
            }
            if (a.isActive.toString().toLowerCase() > b.isActive.toString().toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(true);
          }
        }
        setSubscriberGroupList(result);
        break;
      case "CreatedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.createdDate === null || b.createdDate.trim() === "") { return -1; }
            if (a === null || a.createdDate === null || a.createdDate.trim() === "") { return 1; }
            var dta = new Date(a.createdDate.split("/").reverse().join("-"))
            var dtb = new Date(b.createdDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setCreatedDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.createdDate === null || b.createdDate.trim() === "") { return 1; }
            if (a === null || a.createdDate === null || a.createdDate.trim() === "") { return -1; }
            var dta = new Date(a.createdDate.split("/").reverse().join("-"))
            var dtb = new Date(b.createdDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setCreatedDateDescending(true)
          }
        }
        setSubscriberGroupList(result);
        break;
      case "ModifiedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.modifiedDate === null || b.modifiedDate.trim() === "") { return -1; }
            if (a === null || a.modifiedDate === null || a.modifiedDate.trim() === "") { return 1; }
            var dta = new Date(a.modifiedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.modifiedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDayDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.modifiedDate === null || b.modifiedDate.trim() === "") { return 1; }
            if (a === null || a.modifiedDate === null || a.modifiedDate.trim() === "") { return -1; }
            var dta = new Date(a.modifiedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.modifiedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDayDescending(true)
          }
        }
        setSubscriberGroupList(result);
        break;
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy: "NfSubscriberListName" | "CreatedDate" | "ModifiedDate" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setSubscriberGroupList(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickNfSubscriberListNameAsc = () => {
    setReverseNextSort(false);
    setNfSubscriberListNameDescending(false);
    sortResultsBy("NfSubscriberListName", false);
  };

  const clickNfSubscriberListNameDesc = () => {
    setReverseNextSort(false);
    setNfSubscriberListNameDescending(true);
    sortResultsBy("NfSubscriberListName", true);
  };

  const clickCreatedDateAsc = () => {
    setReverseNextSort(false);
    setCreatedDateDescending(false);
    sortResultsBy("CreatedDate", false);
  };

  const clickCreatedDateDesc = () => {
    setReverseNextSort(false);
    setCreatedDateDescending(true);
    sortResultsBy("CreatedDate", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickModifiedDateAsc = () => {
    setReverseNextSort(false);
    setModifiedDayDescending(false);
    sortResultsBy("ModifiedDate", false);
  };

  const clickModifiedDateDesc = () => {
    setReverseNextSort(false);
    setModifiedDayDescending(true);
    sortResultsBy("ModifiedDate", true);
  };

  useEffect(() => {
    return () => { };
  }, []);


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  useEffect(() => {
    setSubscriberGroupList(listData);
    if (needLatestList) {
      setUpdatedSelectedName(subscriberGroupList, "subscriberGroupListsection");
    }
  }, [needLatestList, listData]);

  if (loading) return <LoaderContainer />;
  return (
    <div style={{ marginTop: "20px" }}>
      <UnbrandedCard>
        <div style={{ padding: "10px 30px 0px 23px" }}>
          <div className={styles.paginationControlsContainer}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "95%",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={subscriberGroupList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div className={styles.tableSection} id="subscriberGroupList">
            <div className={styles.tableContainer}>
              <BrandedCard>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell id="NfSubscriberListNameCol"
                          style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setNfSubscriberListNameDescending(!nfSubscriberListNameDescending);
                                sortResultsBy("NfSubscriberListName", !nfSubscriberListNameDescending);
                              }}
                            >
                              {"Subscribers group Name" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "NfSubscriberListName" &&
                                      nfSubscriberListNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickNfSubscriberListNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "NfSubscriberListName" &&
                                      nfSubscriberListNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickNfSubscriberListNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell id="createdDateCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setCreatedDateDescending(!createdDateDescending);
                                sortResultsBy("CreatedDate", !createdDateDescending);
                              }}
                            >
                              {"Created Date" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "CreatedDate" &&
                                      createdDateDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickCreatedDateAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "CreatedDate" &&
                                      createdDateDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickCreatedDateDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell id="modifiedDateCol"
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setModifiedDayDescending(!modifiedDayDescending);
                                sortResultsBy("ModifiedDate", !modifiedDayDescending);
                              }}
                            >
                              {"Modified Date" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "ModifiedDate" &&
                                      modifiedDayDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickModifiedDateAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "ModifiedDate" &&
                                      modifiedDayDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickModifiedDateDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div
                            className={styles.tableHeadCellContainer}
                            style={{ display: "flex" }}
                          >
                            <div
                              onClick={() => {
                                setStatusDescending(!statusDescending);
                                sortResultsBy("Status", !statusDescending);
                              }}
                            >
                              {"Status" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Status" &&
                                      statusDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickStatusAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "Status" &&
                                      statusDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickStatusDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="Edit"
                          style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0", paddingRight: "60px" }}
                          align="left">
                          <div className={styles.tableHeadCellContainer}>
                            <div>
                              {"Modify" /* TODO translate */}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subscriberGroupList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((result: any, i) => {
                          return (
                            <TableRow
                              key={`row-${i}`}
                              style={rowBackgroundColor(i)}
                            >
                              <TableCell align="left" className={"nfSubscriberListNamecol"}>
                                {result.nfSubscriberListName}
                              </TableCell>
                              <TableCell align="left" className={"createdDateCol"}>
                                {result.createdDate}
                              </TableCell>
                              <TableCell align="left" className={"modifiedDateCol"}>
                                {result.modifiedDate}
                              </TableCell>
                              <TableCell align="left">
                                {result.isActive ?
                                  <CustomTooltip displayText={["Active"]}>
                                    <span style={{ paddingRight: "5px" }}><ActiveIcon></ActiveIcon></span>
                                  </CustomTooltip>
                                  :
                                  <CustomTooltip displayText={["Inactive"]}>
                                    <span style={{ paddingRight: "5px" }}><InActiveIcon></InActiveIcon></span>
                                  </CustomTooltip>
                                }
                                {result.isActive ? "Active" : "Inactive"}</TableCell>
                              <TableCell align="left" width={"5%"}>
                                <CustomTooltip displayText={["Edit"]}>
                                  <Button className="ruleedit" onClick={() => handleEdit(result.nfSubscriberListCommonId)} variant="contained" style={{ backgroundColor: "#63C2FF" }}>
                                    <EditIcon />
                                  </Button>
                                </CustomTooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </BrandedCard>

              <div className={styles.paginationAddedUsersControl}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "95%",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={subscriberGroupList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </UnbrandedCard>
    </div>
  );
};

export default SubscriberGropuList;
