import axios from 'axios';
import { addError } from '../../AddError';

const GetOrgAssignedCourseList = async (token: string, requestinguserId: number, orgDomain: string, orgId:number,
  IncludeInactive:boolean = false,
  selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = ""
) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/administration/GetOrgAssignedCourseList`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        reportRequestingUserID: requestinguserId,
        orgId: orgId,
        orgDomainName: orgDomain,
        includeInactive:IncludeInactive,
        selectedOrgID: selectedOrgID,
        selectedOrgDomainName: selectedOrgDomainName, 
        selectOrgSiteName: selectOrgSiteName
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "GetPolicyBuilderList.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "GetPolicyBuilderList.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "GetPolicyBuilderList.ts", 1)
    }
    return null;
  }
};

export default GetOrgAssignedCourseList;