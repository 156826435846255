import React, { useState, useRef, useContext, useEffect } from "react";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import GetCourseDetails from "../../../API/Administration/CourseSettings/GetCourseDetails";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";


import StepOneActive from "../../../CustomIcons/Administration/CoursePublish/StepOneActive";
import StepOneComplete from "../../../CustomIcons/Administration/CoursePublish/StepOneComplete";

import StepTwoActive from "../../../CustomIcons/Administration/CoursePublish/StepTwoActive";
import StepTwoComplete from "../../../CustomIcons/Administration/CoursePublish/StepTwoComplete";
import StepTwoInactive from "../../../CustomIcons/Administration/CoursePublish/StepTwoInactive";

import StepThreeActive from "../../../CustomIcons/Administration/CoursePublish/StepThreeActive";
import StepThreeComplete from "../../../CustomIcons/Administration/CoursePublish/StepThreeComplete";
import StepThreeInactive from "../../../CustomIcons/Administration/CoursePublish/StepThreeInactive";

import StepFourComplete from "../../../CustomIcons/Administration/CoursePublish/StepFourComplete";
import StepFourInactive from '../../../CustomIcons/Administration/CoursePublish/StepFourInactive';
import StepFourActive from "../../../CustomIcons/Administration/CoursePublish/StepFourActive";

import StepFiveInactive from '../../../CustomIcons/Administration/CoursePublish/StepFiveInactive';
import StepFiveComplete from "../../../CustomIcons/Administration/CoursePublish/StepFiveComplete";
import StepFiveActive from "../../../CustomIcons/Administration/CoursePublish/StepFiveActive";


import StepSixInactive from '../../../CustomIcons/Administration/CoursePublish/StepSixInactive';
import StepSixComplete from "../../../CustomIcons/Administration/CoursePublish/StepSixComplete";
import StepSixActive from "../../../CustomIcons/Administration/CoursePublish/StepSixActive";

import ProgressInComplete from '../../../CustomIcons/Administration/CoursePublish/ProgressInComplete';
import ProgressComplete from '../../../CustomIcons/Administration/CoursePublish/ProgressComplete';
import { useHistory } from "react-router-dom";
import StepOneInactive from "../../../CustomIcons/Administration/CoursePublish/StepOneInactive";
import AddCourse from "../../../CustomIcons/Administration/CoursePublish/AddCourse";
import AdditionalResource from "../../../CustomIcons/Administration/CoursePublish/AdditionalResource";
import CourseAccess from "../../../CustomIcons/Administration/CoursePublish/CourseAccess";
import ReminderEmails from "../../../CustomIcons/Administration/CoursePublish/ReminderEmails";
import Compliance from "../../../CustomIcons/Administration/CoursePublish/Compliance";
import Summary from "../../../CustomIcons/Administration/CoursePublish/Summary";
import CustomTooltip from "../../../Components/CustomTooltip";


interface Props {
	courseIdToEdit: number;
	step: string ;
}

const CourseProgressBar = ({ courseIdToEdit, step }: Props) => {
	const history = useHistory();
	const { translations: { allResourcesToTranslations } } = useContext(TranslationsContext);
	const { superAdminSelectedBasicOrgData } = useContext(UIContext);
	const { oidcUser } = useReactOidc();
	const orgId = oidcUser.profile.organisation_id ?? -1;
	const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
	const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

	const [isCourseAdded, setIsCourseAdded] = useState<boolean>(false);
	const [isCourseAdditionalRecourse, setIsCourseAdditionalRecourse] = useState<boolean>(false);
	const [isCourseModuleAccess, setIsCourseModuleAccess] = useState<boolean>(false);
	const [isRemEmails,setIsRemEmails ] = useState<boolean>(false);
	const [isUnitRule, setIsUnitRule ] = useState<boolean>(false);
	const [reminderID, setreminderID] = useState<number>(0);
	const [selectedTab, setSelectedTab] = useState('c');

	useEffect(() => {
		if (courseIdToEdit > 0) {
			LoadCourseDetail();
		}
		return () => { };
	}, [courseIdToEdit]);

	useEffect(() => {
		if (courseIdToEdit > 0) {
			LoadCourseDetail();

		}
		return () => { };
	}, [superAdminSelectedBasicOrgData]);

	const LoadCourseDetail = () => {
		GetCourseDetails(oidcUser.access_token,
			userId,
			orgDomain,
			parseInt(orgId),
			courseIdToEdit,
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
				if (result != null && result.isSuccess && result.error == null) {
					if (result.getCourseDetailsItems.length > 0) {
						setIsCourseAdded(true);
					}
					if (result != null && result.isSuccess && result.error == null) {						
						if (result.getCourseDetailsItems[0].unitIds.length > 0)
						{
							setIsCourseModuleAccess(true);
						}					
						if (result.getCourseDetailsItems[0].additionalRecourseIds > 0)
						{
							setIsCourseAdditionalRecourse(true);
						}						
						if (result.getCourseDetailsItems[0].remEscId > 0)
						{
							setIsRemEmails(true);
							setreminderID(result.getCourseDetailsItems[0].remEscId);
						}						
						if (result.getCourseDetailsItems[0].unitRuleId > 0)
						{
							setIsUnitRule(true);							
						}
					}

				}
			});
}

	const redirectCourseAdditional = (addedCourseID: number) => {
		history.push({
			pathname: `/courserecourse/` + addedCourseID
		});
	}
	const redirectAddCourse = (addedCourseID: number) => {
		history.push({
			pathname: `/addcourse/` + addedCourseID
		});
	}
	const redirectCourseAcess = (addedCourseID: number) => {
		history.push({
			pathname: `/assigncourseaccess/` + addedCourseID
		});
	}
	const redirectCourseCompliancerules = (addedCourseID: number) => {
		history.push({
			pathname: `/coursecompliancerules/` + addedCourseID
		});
	}
	const redirectCourseSummary = (addedCourseID: number) => {
		history.push({
			pathname: `/coursesummary/` + addedCourseID
		});
	}

	
	const redirectcoursereminderemail = (addedCourseID: number) => {
		if (reminderID == 0) {
			history.push({
				pathname: `/courseemailrulesadd/` + addedCourseID,
				state: {
					selectedReminderID: "0",
					selectedTab: selectedTab
				},
			});
		} else {
			history.push({
				pathname: `/courseemailrulesadd/` + addedCourseID + '/' + reminderID,
				state: {
					selectedReminderID: "0",
					selectedTab: selectedTab
				},
			});
		}
	}
	return (
		<>
			<div className={responsivestyles.rowrule} style={{ paddingLeft: '20%', textAlign: "center" }}>
				<div style={{ float: 'left' }} >
					
					{courseIdToEdit > 0 ?
						<CustomTooltip displayText={[allResourcesToTranslations.step1]}>
							<button onClick={() => redirectAddCourse(courseIdToEdit)} style={{ border: 'none', background: 'none', cursor: 'pointer' , margin:'0px', padding:'0px'}}>
								{isCourseAdded ? <StepOneComplete /> : step=='1' ? <StepOneActive /> : <StepOneInactive />	}
							</button></CustomTooltip>
						:
						<CustomTooltip displayText={[allResourcesToTranslations.step1]}>
							<span>{step=='1' ? <StepOneActive /> : <StepOneInactive />}</span></CustomTooltip>}

				</div>
				<div style={{ float: 'left', paddingTop: '10px' }}>
					{isCourseAdded ? <ProgressComplete /> : <ProgressInComplete />}
				</div>
				<div style={{ float: 'left' }}>
					
					{courseIdToEdit > 0 ?
						<CustomTooltip displayText={[allResourcesToTranslations.step2]}>
							<button onClick={() => redirectCourseAdditional(courseIdToEdit)} style={{ border: 'none', background: 'none', cursor: 'pointer', margin:'0px', padding:'0px' }}>
								{isCourseAdditionalRecourse ? <StepTwoComplete /> : step=='2' ?	<StepTwoActive /> : <StepTwoInactive />}
							</button></CustomTooltip>
						: <CustomTooltip displayText={[allResourcesToTranslations.step2]}>
							<span><StepTwoInactive /></span></CustomTooltip>}
				</div>
				<div style={{ float: 'left', paddingTop: '10px' }}>
					{isCourseAdditionalRecourse ? <ProgressComplete /> : <ProgressInComplete />}
				</div>
				<div style={{ float: 'left' }} >	
					
					{(courseIdToEdit > 0 && isCourseModuleAccess)	?						
						 <CustomTooltip displayText={[allResourcesToTranslations.assigncourseupdatenotessummary]}>
						 <span><StepThreeComplete /></span></CustomTooltip> 
						 :	
						(courseIdToEdit > 0) ?				
						<CustomTooltip displayText={[allResourcesToTranslations.step3]}>		
						<button   onClick={() => redirectCourseAcess(courseIdToEdit)}   style={{ border: 'none', background: 'none' , cursor: 'pointer', margin:'0px', padding:'0px'}}>
						{step=='3' ?	<StepThreeActive /> : <StepThreeInactive />}</button></CustomTooltip>
							
						: <CustomTooltip displayText={[allResourcesToTranslations.step3]}>
							<span>{step=='3' ?	<StepThreeActive /> : <StepThreeInactive />}</span></CustomTooltip>}

				</div>
				<div style={{ float: 'left', paddingTop: '10px' }}>
					{isRemEmails ? <ProgressComplete /> : <ProgressInComplete />}
				</div>
				<div style={{ float: 'left' }} >
					
					{(courseIdToEdit > 0) ?
					<CustomTooltip displayText={[allResourcesToTranslations.step4]}>
						<button onClick={() => redirectcoursereminderemail(courseIdToEdit)} style={{ border: 'none', background: 'none', cursor: 'pointer', margin:'0px', padding:'0px' }}>
						{isRemEmails ? <StepFourComplete /> : step=='4' ?	<StepFourActive /> : <StepFourInactive />}
						</button></CustomTooltip>
						: <CustomTooltip displayText={[allResourcesToTranslations.step4]}>
							<span>{step=='4' ?	<StepFourActive /> : <StepFourInactive />}</span></CustomTooltip>}
				</div>
				<div style={{ float: 'left', paddingTop: '10px' }}>
					{isRemEmails ? <ProgressComplete /> : <ProgressInComplete />}
				</div>
				<div style={{ float: 'left' }} >
				
					{(courseIdToEdit > 0 && isUnitRule)	?						
						 <CustomTooltip displayText={[allResourcesToTranslations.compliancerulesupdatenotessummary]}>
						 <span><StepFiveComplete /></span></CustomTooltip> 
						 :	
						(courseIdToEdit > 0) ?				
						<CustomTooltip displayText={[allResourcesToTranslations.step5]}>		
						<button   onClick={() => redirectCourseCompliancerules(courseIdToEdit)}   style={{ border: 'none', background: 'none' , cursor: 'pointer', margin:'0px', padding:'0px'}}>
						{step=='5' ?	<StepFiveActive /> : <StepFiveInactive />}</button></CustomTooltip>
							
						: <CustomTooltip displayText={[allResourcesToTranslations.step5]}>
							<span>{step=='5' ?	<StepFiveActive /> : <StepFiveInactive />}</span></CustomTooltip>}		

				</div>
				<div style={{ float: 'left', paddingTop: '10px' }}>
					{isUnitRule ? <ProgressComplete /> : <ProgressInComplete />}
				</div>
				<div style={{ float: 'left' }}>
					
					{(courseIdToEdit > 0) ?
						<CustomTooltip displayText={[allResourcesToTranslations.step6]}>
							<button  onClick={() => redirectCourseSummary(courseIdToEdit)}  style={{ border: 'none', background: 'none', cursor: 'pointer', margin:'0px', padding:'0px' }}>
							{(isUnitRule && isRemEmails && isCourseModuleAccess && isCourseAdditionalRecourse  && isCourseAdded )? 
							<StepSixComplete /> : step=='6' ?	<StepSixActive /> : <StepSixInactive />} 
							</button></CustomTooltip>
						: <CustomTooltip displayText={[allResourcesToTranslations.step6]}>
							<span>{step=='6' ?	<StepSixActive /> : <StepSixInactive />}</span></CustomTooltip>}
				</div>
			</div>
			<div className={responsivestyles.rowrule} style={{ paddingLeft: '18%', paddingTop: '5px', textAlign: "center", width: '59%' }}>
				<div style={{ float: 'left' }} >
					<CustomTooltip displayText={[allResourcesToTranslations.step1]}><span><AddCourse /></span></CustomTooltip>
				</div>
				<div style={{ float: 'left', paddingLeft: '5%' }}>
					<CustomTooltip displayText={[allResourcesToTranslations.step2]}><span><AdditionalResource /></span></CustomTooltip>
				</div>
				<div style={{ float: 'left', paddingLeft: '2%' }}>
					<CustomTooltip displayText={[allResourcesToTranslations.step3]}><span><CourseAccess /></span></CustomTooltip>
				</div>
				<div style={{ float: 'left', paddingLeft: '5%' }}>
					<CustomTooltip displayText={[allResourcesToTranslations.step4]}><span><ReminderEmails /></span></CustomTooltip>
				</div>
				<div style={{ float: 'left', paddingLeft: '3%' }} >
					<CustomTooltip displayText={[allResourcesToTranslations.step5]}><span><Compliance /></span></CustomTooltip>
				</div>
				<div style={{ float: 'left', paddingLeft: '5%' }}>
					<CustomTooltip displayText={[allResourcesToTranslations.step6]}><span><Summary /></span></CustomTooltip>
				</div>
			</div>
		</>
	);
};

export default CourseProgressBar;
