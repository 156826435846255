import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepTwoInactive = () => (    
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#21394F"/>
<path d="M25.2812 27H15.3008V24.9004L18.8848 21.2773C19.6074 20.5352 20.1868 19.9167 20.623 19.4219C21.0658 18.9206 21.3848 18.4648 21.5801 18.0547C21.7819 17.6445 21.8828 17.2051 21.8828 16.7363C21.8828 16.1699 21.7233 15.7467 21.4043 15.4668C21.0918 15.1803 20.6719 15.0371 20.1445 15.0371C19.5911 15.0371 19.054 15.1641 18.5332 15.418C18.0124 15.6719 17.4688 16.0332 16.9023 16.502L15.2617 14.5586C15.6719 14.207 16.1048 13.875 16.5605 13.5625C17.0228 13.25 17.5566 12.9993 18.1621 12.8105C18.7741 12.6152 19.5065 12.5176 20.3594 12.5176C21.2969 12.5176 22.1009 12.6868 22.7715 13.0254C23.4486 13.3639 23.9694 13.8262 24.334 14.4121C24.6986 14.9915 24.8809 15.6491 24.8809 16.3848C24.8809 17.1725 24.7246 17.8919 24.4121 18.543C24.0996 19.194 23.6439 19.8385 23.0449 20.4766C22.4525 21.1146 21.7363 21.821 20.8965 22.5957L19.0605 24.3242V24.4609H25.2812V27Z" fill="#21394F"/>
</svg>


);

export default StepTwoInactive