import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export const TinyMCEEditor = ({editorData,isTemplate,handleTextEditorChange, is200Tall, index}) => {
  
  useEffect(() => {
        const tinyMCEScript = document.createElement('script')
        tinyMCEScript.innerHTML = "ChangeMCEContent();";
        tinyMCEScript.async = true;
        document.body.appendChild(tinyMCEScript);
        document.body.removeChild(tinyMCEScript);
    }, [editorData])


    return( <>
      <div>
      { isTemplate ? 
       <Helmet  script={[{ 
         type: 'text/javascript', 
         innerHTML: `initMCEexact("","templates", "mceEditor` + `${index}")`
         }]} />                
       : 
       <Helmet 
         script={[{           
            type: 'text/javascript', 
            innerHTML: `initMCEexact("","textarea", "mceEditor` + `${index}")`
         }]} />
       
       }
         <textarea id={"mceEditor"+ index} style={{ width: "100%", height: is200Tall ? "200px" : "500px", marginTop: "10px", marginBottom: "10px" }} value={editorData} onChange={handleTextEditorChange()}></textarea>
         <input type="text" id="editorChangedText" value={editorData} hidden></input>
         <input type="text" id="editorText" hidden></input>
     </div>
     </>
     )
   }

export default TinyMCEEditor;
