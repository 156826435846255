import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import BrandedCard from "../../Components/BrandedCard";
//Components
import LoaderContainer from '../../Components/LoaderContainer';
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
import ResetIcon from "../../CustomIcons/ResetIcon";
import DeleteIcon from "../../CustomIcons/DeleteIcon";
import CustomTooltip from "../../Components/CustomTooltip";
import { getSchedulerList } from "../../API/GetReportSchedulerList";
import { reassignSelectedUsers } from '../../API/ReassignReportUsers';
import { getSchedulerListExport } from "../../API/GetReportSchedulerListExport";
import { deleteSchedulerList } from '../../API/DeleteReportSchedulerList';
import SelectAllIcon from "../../CustomIcons/SelectAllIcon";
import UnSelectAllIcon from "../../CustomIcons/UnSelectAllIcon";
import SaveIcon from '../../CustomIcons/SaveIcon';
import SearchIcon from "@material-ui/icons/Search";
import { addError } from "../../API/AddError";
import GuideIcon from '../../CustomIcons/GuideIcon';
//Contexts
import { UIContext } from "../../contexts/UIContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API functions
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { getScheduleReportByScheduleId } from '../../API/GetScheduleReportByScheduleId';
import { getRelevantUnitTree } from "../../API/GetRelevantUnitTree";
import { getCourseListByOrganisation } from "../../API/GetCourseListByOrganisation";
import { getPastCourseListByOrganisation } from "../../API/GetPastCourseListByOrganisation";
import getUsersList from "../../API/GetUsersList";

//MUI Components
import TextField from "@material-ui/core/TextField";
import BrushIcon from "@material-ui/icons/Brush";
import Button from "@material-ui/core/Button";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Checkbox from "@material-ui/core/Checkbox";

// Libs
import { useReactOidc, getUserManager } from "@axa-fr/react-oidc-context";

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";

//styles
import styles from "../../styles/scheduleReportList.module.css";
import Modal from "../../Components/Modal";
import CloseIcon from "../../CustomIcons/CloseIcon";
import { FormControlLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import CommonPopupModalForResult from "../../Components/CommonPopupModalForResult";

const ReportSchedulerList = () => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const [schedulerListData, setSchedulerListData] = React.useState<Array<any>>([]);
  const [schedulerListDataExport, setSchedulerListDataExport] = React.useState<Array<any>>([]);
  const [filteredListData, setFilteredListData] = React.useState<Array<any>>([]);
  const [page, setPage] = useState<number>(0);
  const [reassignOpen, setReassignOpen] = useState(false);
  const [reassignUser, setReassignUser] = useState<number>(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [usersList, setUsersList] = React.useState<Array<any>>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [reportNameDescending, setReportNameDescending] =
    useState<boolean>(false);
  const [reportTypeDescending, setReportTypeDescending] =
    useState<boolean>(false);
  const [frequencyDescending, setFrequencyDescending] =
    useState<boolean>(false);
  const [dateCreatedDescending, setDateCreatedDescending] =
    useState<boolean>(false);
  const [startDateDescending, setStartDateDescending] =
    useState<boolean>(false);
  const [endDateDescending, setEndDateDescending] = useState<boolean>(false);
  const [nextRunDescending, setNextRunDescending] = useState<boolean>(false);
  const [reportOwnerDescending, setReportOwnerDescending] =
    useState<boolean>(false);
  const [administrationDescending, setAdministrationDescending] =
    useState<boolean>(false);
  const [recipientsDescending, setRecipientsDescending] =
    useState<boolean>(false);
  const [uniqueIDDescending, setUniqueIDDescending] = useState<boolean>(false);
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const [exportError, setExportError] = useState<string>("");
  const [deletedScheduleList, setDeletedScheduleList] = useState([]);
  const { setMessage } = useContext(LoaderContext);
  const [filterScheduleReport, setFilterScheduleReport]  = useState("");

  const [pageuser, setPageuser] = useState<number>(0);
  const [rowsPerPageuser, setRowsPerPageuser] = useState<number>(10);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      { 
        element: ".search",
        title: "Filter",
        intro: "Search Schedule Report by typing any keyword."
            
      },      
    ]
  })
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const history = useHistory();
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, setExpandedMenuSelection, EnableActivityLogs } = useContext(UIContext);
  const { oidcUser, logout } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website.toLowerCase().replace("altlogin-", "")
    : "";

  const getSchedulerData = () => {
      getSchedulerList(
        oidcUser.access_token,
        userId,
        orgDomain,  
        parseInt(orgId),
        false
      ).then((result) => {
        if (result !== null && result !== undefined && result.isSuccess && result.schedulerReportListItem !== null) {
          setLoading(false);
          let formattedList = result.schedulerReportListItem.map((data: any) => {
            return {
              ...data,
              isChecked: false,
            };
          });
         let updatedDataLocal = formattedList.sort((a: any, b: any) => {
            if (b === null || b.scheduleID === null) {
              return -1;
            }
            if (a === null || a.scheduleID === null) {
              return 1;
            }
            if (a.scheduleID > b.scheduleID) {
              return -1;
            }
            if (a.scheduleID < b.scheduleID) {
              return 1;
            }
            return 0;
          });
          setReverseNextSort(false);
          setUniqueIDDescending(true);
          setCurrentSortBy("ScheduleID");
          setSchedulerListData(updatedDataLocal);
          setFilteredListData(updatedDataLocal);
          
        }
      }).catch((error) => {
        setLoading(false);
      });
    }

  // const getSchedulerDataExport = () => {
  //    getSchedulerListExport(
  //        oidcUser.access_token,
  //        userId,
  //        orgDomain,
  //        parseInt(orgId)
  //      ).then((result) => {
  //        if (result.isSuccess) {
  //          setLoading(false);
  //          let formattedList = result.schedulerReportExportItem.map((data: any) => {           
  //            return {
  //              ...data,
  //              isChecked: false,
  //            };
  //          });        
  //          setSchedulerListDataExport(formattedList);
  //        }
  //      }).catch((error) => {
  //        setLoading(false);
  //      });
  // }


  useEffect(() => 
  {
    let updateSteps = [...intro.steps]
    updateSteps.push( 
      {
      element: ".xlxs",
        title: "Export", intro:"Click to export an XLSX file containing all Scheduled Reports."
      },
      {
      element: ".csv",
      title: "Export", intro:"Click to export a CSV file containing all Scheduled Reports."
      }
      // {
      //     element: ".rowShading",
      //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
      // }             
    )      

    setIntro({...intro, steps: [...updateSteps]})              
  },[])

  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };


  useEffect(() => {
    setTitle(allResourcesToTranslations.scheduledreports);
    setTitleEnglish(pageTitles.reportscheduler)
    setMessage("Fetching Scheduler List");
    updateBackButtonRoute("");
    setLoading(true);
    getSchedulerData();
   //getSchedulerDataExport();
  }, []);



//const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleReassignUsers = (e: any, data: any) => {
    e.stopPropagation();

    let updatedDataLocal = usersList.map((item:any) => {
      if(data.userID == item.userID) {
        if(!item.isChecked) {
          setReassignUser(item.userID)
        }
        return {
          ...item,
          isChecked: !item.isChecked
        }
      }
      return item
    })

    setUsersList(updatedDataLocal)

  }

  const updateSelectedUnit = (units: any, id: string) => {
    let updatedUnits = units
    const updateSelected = (unitArray:any) => {
        if (unitArray === null) return //Break if no children
        if (unitArray.length === 0) return
        for (let i = 0; i < unitArray.length; i++) {
            const unit = unitArray[i];
            if (unit.unitID == id) {
                unit.isSelected = !unit.isSelected
            } 
            updateSelected(unit.childUnitTreeItems)
        }
    }
    updateSelected(updatedUnits)
    return updatedUnits;
}

const updateSelectedCourse = (courses:any, id: number) => {
  let updatedCourses = courses 
  for (let i = 0; i < updatedCourses.length; i++) {
      if (updatedCourses[i].courseID == id) {
          updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
      }
  }
  return updatedCourses
}


const updateSelectedPastCourse = (courses:any, id: number) => {
  let updatedPastCourses = courses 
  for (let i = 0; i < updatedPastCourses.length; i++) {
      if (updatedPastCourses[i].courseID == id) {
        updatedPastCourses[i].isSelected = !updatedPastCourses[i].isSelected;
      }
  }
  return updatedPastCourses
}

  const handleOpeningScheduleReport = (data:any) => {
    getScheduleReportByScheduleId(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      data.scheduleID
      ).then((result:any) => {
        if (result === null || result === undefined || !result.isSuccess ||
          result.scheduleReportlistItem === null || result.scheduleReportlistItem === undefined ||
          result.scheduleReportlistItem.length <= 0)
        {
          //console.log('getScheduleReportByScheduleId failed');
          return;
        }
        let selectedUnits = (result.scheduleReportlistItem[0].paramUnitIDs !== null && result.scheduleReportlistItem[0].paramUnitIDs !== undefined && result.scheduleReportlistItem[0].paramUnitIDs != "") ? result.scheduleReportlistItem[0].paramUnitIDs.split(",") : [];
        let selectedCourses = (result.scheduleReportlistItem[0].paramCourseIDs !== null && result.scheduleReportlistItem[0].paramCourseIDs !== undefined && result.scheduleReportlistItem[0].paramCourseIDs != "") ? result.scheduleReportlistItem[0].paramCourseIDs.split(",") : [];
        let selectedPastCourses = (result.scheduleReportlistItem[0].paramHistoricCourseIDs !== null && result.scheduleReportlistItem[0].paramHistoricCourseIDs !== undefined && result.scheduleReportlistItem[0].paramHistoricCourseIDs != "") ? result.scheduleReportlistItem[0].paramHistoricCourseIDs.split(",") : [];
        
        let updatedDataLocal:any = [];
        let updatedCourseData:any = [];
        let updatedPastCourseData:any = [];
        getRelevantUnitTree(
          oidcUser.access_token,
          userId,
          orgDomain,
          true,
          parseInt(orgId)
        ).then((unitTreeRes:any) => {
          if (unitTreeRes !== null && unitTreeRes !== undefined && unitTreeRes.isSuccess) {
            if(selectedUnits.length > 0) {
              updatedDataLocal = selectedUnits.map((item:any) => {
                return updateSelectedUnit(unitTreeRes.unitTreeItems, item)
              })
            }

            getCourseListByOrganisation(
              oidcUser.access_token,
              userId,
              orgDomain,
              parseInt(orgId),
              true
            ).then((courseListRes) => {
              if (courseListRes !== null && courseListRes !== undefined && courseListRes.isSuccess) {
                if (selectedCourses.length > 0) {
                  updatedCourseData = selectedCourses.map((item:any) => {
                    return updateSelectedCourse(courseListRes.courselistItem,item)
                  })
                }

                getPastCourseListByOrganisation(
                  oidcUser.access_token,
                  userId,
                  orgDomain,
                  parseInt(orgId)
                ).then((pastcourseListRes:any) => {
                  if (pastcourseListRes !== null && pastcourseListRes !== undefined && pastcourseListRes.isSuccess) {
                          if (selectedPastCourses.length > 0) {                          
                            updatedPastCourseData = selectedPastCourses.map((item:any) => {
                            return  updateSelectedPastCourse(pastcourseListRes.pastCourseListItems,item)
                            })                                                
                          }           

                          let reportType = "";
                          if(data.reportID ==19) {
                            reportType = "CSR";
                            setExpandedMenuSelection("User Activity Reports");
                            setMenuSelection("Course Status Report");
                          }else if(data.reportID ==3) {
                            reportType = "CAR";
                            setExpandedMenuSelection("User Activity Reports");
                            setMenuSelection("Current Admin Report");
                          }else if(data.reportID ==21) {
                            reportType = "PR";
                            setExpandedMenuSelection("User Activity Reports");
                            setMenuSelection("Progress Report");
                          }else if(data.reportID ==22) {
                            reportType = "SR";
                            setExpandedMenuSelection("User Activity Reports");
                            setMenuSelection("Summary Report");
                          }else if(data.reportID ==30) {
                            reportType = "CPDR";
                            setExpandedMenuSelection("CPD Reports");
                            setMenuSelection("CPD Report");
                          } else if(data.reportID ==36) {
                            reportType = "PBR";
                            setExpandedMenuSelection("Policy Reports");
                            setMenuSelection("Policy Builder Report");
                          } else if(data.reportID ==29) {
                            reportType = "WR";
                            setExpandedMenuSelection("Warning Reports");
                            setMenuSelection("Warning Report");
                          } else {
                            return;
                          }                  
                            history.push({
                                pathname: `/${reportType}/reportscheduler/${data.scheduleID.toString()}`,
                                state: {
                                    reportId: result.scheduleReportlistItem[0].reportId,
                                    scheduleReportTitle:result.scheduleReportlistItem[0].reportTitle,                  
                                    periodType:(result.scheduleReportlistItem[0].reportPeriodType !== null && result.scheduleReportlistItem[0].reportPeriodType !== undefined && result.scheduleReportlistItem[0].reportPeriodType > 0) ? result.scheduleReportlistItem[0].reportPeriodType : "",
                                    selectedUnits: updatedDataLocal.length > 0 ? updatedDataLocal[selectedUnits.length - 1] : [],
                                    selectedCourses: updatedCourseData.length > 0 ? updatedCourseData[selectedCourses.length - 1] : [],
                                    selectedPastCourses: updatedPastCourseData.length > 0 ? updatedPastCourseData[selectedPastCourses.length - 1] : [],
                                    firstName: result.scheduleReportlistItem[0].paramFirstName,
                                    lastName: result.scheduleReportlistItem[0].paramLastName,
                                    userName: result.scheduleReportlistItem[0].paramUserName,
                                    email: result.scheduleReportlistItem[0].paramEmail,
                                    historicdate: result.scheduleReportlistItem[0].paramDateTo,
                                    dateFrom: result.scheduleReportlistItem[0].paramDateFrom,
                                    dateTo: result.scheduleReportlistItem[0].paramDateTo,
                                    subject: result.scheduleReportlistItem[0].paramSubject,
                                    body: result.scheduleReportlistItem[0].paramBody,
                                    status: result.scheduleReportlistItem[0].paramQuizStatus,
                                    quizStatus: result.scheduleReportlistItem[0].paramQuizStatus,
                                    isIncludeInactive: result.scheduleReportlistItem[0].paramIncludeInactive === "Y" ? true : false, // result.scheduleReportlistItem[0].paramIncludeInactive,
                                    //isHistoryChecked: (result.scheduleReportlistItem[0].paramHistoricCourseIDs !== null && result.scheduleReportlistItem[0].paramHistoricCourseIDs !== undefined && result.scheduleReportlistItem[0].paramHistoricCourseIDs !== "") ? true : false,                   
                                    isHistoryChecked: (result.scheduleReportlistItem[0].paramIsHistoricAdminReport !== null && result.scheduleReportlistItem[0].paramIsHistoricAdminReport !== undefined && result.scheduleReportlistItem[0].paramIsHistoricAdminReport !== 0) ? true : false,                   
                                    sortBy: result.scheduleReportlistItem[0].paramSortBy,
                                    langCode: result.scheduleReportlistItem[0].paramLangCode,
                                    expiryValue: result.scheduleReportlistItem[0].paramTimeExpired,
                                    expiryUnit: result.scheduleReportlistItem[0].paramTimeExpiredPeriod,
                                    profileID: result.scheduleReportlistItem[0].paramProfileId,
                                    acceptance: result.scheduleReportlistItem[0].paramAcceptance,
                                    onlyUsersWithShortfall: result.scheduleReportlistItem[0].paramOnlyUsersWithShortfall,
                                    licensingPeriod: result.scheduleReportlistItem[0].paramLicensingPeriod,
                                    historicCourseIds: result.scheduleReportlistItem[0].paramHistoricCourseIDs, 
                                    policyIds: result.scheduleReportlistItem[0].paramPolicyIDs,
                                    isPeriodic: result.scheduleReportlistItem[0].isPeriodic,
                                    reportFrequencyPeriod: result.scheduleReportlistItem[0].reportFrequencyPeriod,
                                    reportStartDate: result.scheduleReportlistItem[0].reportStartDate,
                                    scheduleId: result.scheduleReportlistItem[0].scheduleId, 
                                    documentType: result.scheduleReportlistItem[0].documentType,
                                    reportEndDate:  result.scheduleReportlistItem[0].reportEndDate,
                                    reportFromDate:  result.scheduleReportlistItem[0].reportFromDate,
                                    reportFrequency: (result.scheduleReportlistItem[0].reportFrequency !== null && result.scheduleReportlistItem[0].reportFrequency !== undefined && result.scheduleReportlistItem[0].reportFrequency > 0) ? result.scheduleReportlistItem[0].reportFrequency.toString() : "",
                                    numberOfReports: (result.scheduleReportlistItem[0].numberOfReports !== null && result.scheduleReportlistItem[0].numberOfReports !== undefined && result.scheduleReportlistItem[0].numberOfReports > 0) ? result.scheduleReportlistItem[0].numberOfReports.toString() : "",
                                    reportDaysType: result.scheduleReportlistItem[0].reportFrequencyPeriod,
                                    selectedClassification: { classificationID: result.scheduleReportlistItem[0].paramClassificationId, classificationTypeID: result.scheduleReportlistItem[0].paramClassificationId, value: result.scheduleReportlistItem[0].paramClassificationId },
                                    selectedClassificationID: result.scheduleReportlistItem[0].paramClassificationId,
                                    groupList: '',
                                    ccUserObjList: result.scheduleReportlistItem[0].listOfSchedulerCCs,
                                    isDueToday: result.scheduleReportlistItem[0].isDueToday,
                  
                                    policyIdsArr: !!result.scheduleReportlistItem[0].paramPolicyIDs ? result.scheduleReportlistItem[0].paramPolicyIDs.split(",").filter((ii : any) => !!ii).map((j : any) => parseInt(j)) : [],
                                    selectedProfileID: !!result.scheduleReportlistItem[0].paramProfileId ? result.scheduleReportlistItem[0].paramProfileId : -1,
                                    selectedProfilePeriodID: !!result.scheduleReportlistItem[0].paramProfilePeriodId ? result.scheduleReportlistItem[0].paramProfilePeriodId : -1,
                                    warningPeriod: (data.reportID === 29 ? (result.scheduleReportlistItem[0].paramWarningExpiredIn !== "" ? result.scheduleReportlistItem[0].paramWarningExpiredIn.split(" ")[0] : "") : ""),
                                    dayType: (data.reportID === 29 ? (result.scheduleReportlistItem[0].paramWarningExpiredIn !== "" ? result.scheduleReportlistItem[0].paramWarningExpiredIn.split(" ")[1] : "") : "")
                                },
                            }); 

             }})

            }})
          
          }
      })
    })
  }

  const handleSelectedUsers = (e: any, data: any) => {
    e.stopPropagation();

    let updatedDataLocal: any = schedulerListData.map((item: any) => {
      if (data.scheduleID == item.scheduleID) {
        return {
          ...item,
          isChecked: !item.isChecked,
        };
      }
      return item;
    });
    setFilteredListData(updatedDataLocal);
    setSchedulerListData(updatedDataLocal);
  };

  const sortResultsByAsync = async (
    sortBy:
      | "ReportTitle"
      | "ScheduleID"
      | "ReportType"
      | "Frequency"
      | "DateCreated"
      | "StartDate"
      | "EndDate"
      | "NextRun"
      | "ReportOwner"
      | "AdminLevel"
      | "Recipients",
    sortGivenItemInDesc: boolean
  ) => {
    if (schedulerListData == null || schedulerListData.length <= 0) {
      return schedulerListData;
    }
    var result: any = schedulerListData;
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "ReportTitle":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportTitle === null) {
              return -1;
            }
            if (a === null || a.reportTitle === null) {
              return 1;
            }
            if (a.reportTitle.toLowerCase() > b.reportTitle.toLowerCase()) {
              return -1;
            }
            if (a.reportTitle.toLowerCase() < b.reportTitle.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setReportNameDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportTitle === null) {
              return 1;
            }
            if (a === null || a.reportTitle === null) {
              return -1;
            }
            if (a.reportTitle.toLowerCase() > b.reportTitle.toLowerCase()) {
              return 1;
            }
            if (a.reportTitle.toLowerCase() < b.reportTitle.toLowerCase()) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setReportNameDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "ReportType":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.type === null) {
              return -1;
            }
            if (a === null || a.type === null) {
              return 1;
            }
            if (a.type.toLowerCase() > b.type.toLowerCase()) {
              return -1;
            }
            if (a.type.toLowerCase() < b.type.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setReportTypeDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.type === null) {
              return 1;
            }
            if (a === null || a.type === null) {
              return -1;
            }
            if (a.type.toLowerCase() < b.type.toLowerCase()) {
              return -1;
            }
            if (a.type.toLowerCase() > b.type.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setReportTypeDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "Frequency":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportFrequency === null) {
              return -1;
            }
            if (a === null || a.reportFrequency === null) {
              return 1;
            }
            if (
              a.reportFrequency.toLowerCase() > b.reportFrequency.toLowerCase()
            ) {
              return -1;
            }
            if (
              a.reportFrequency.toLowerCase() < b.reportFrequency.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFrequencyDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportFrequency === null) {
              return 1;
            }
            if (a === null || a.reportFrequency === null) {
              return -1;
            }
            if (
              a.reportFrequency.toLowerCase() < b.reportFrequency.toLowerCase()
            ) {
              return -1;
            }
            if (
              a.reportFrequency.toLowerCase() > b.reportFrequency.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFrequencyDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "DateCreated":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.dateCreated === null) {
              return -1;
            }
            if (a === null || a.dateCreated === null) {
              return 1;
            }
            if (a.dateCreated.toLowerCase() > b.dateCreated.toLowerCase()) {
              return -1;
            }
            if (a.dateCreated.toLowerCase() < b.dateCreated.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setDateCreatedDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.dateCreated === null) {
              return 1;
            }
            if (a === null || a.dateCreated === null) {
              return -1;
            }
            if (a.dateCreated.toLowerCase() < b.dateCreated.toLowerCase()) {
              return -1;
            }
            if (a.dateCreated.toLowerCase() > b.dateCreated.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setDateCreatedDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "StartDate":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportStartDate === null) {
              return -1;
            }
            if (a === null || a.reportStartDate === null) {
              return 1;
            }
            if (
              a.reportStartDate.toLowerCase() > b.reportStartDate.toLowerCase()
            ) {
              return -1;
            }
            if (
              a.reportStartDate.toLowerCase() < b.reportStartDate.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStartDateDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportStartDate === null) {
              return 1;
            }
            if (a === null || a.reportStartDate === null) {
              return -1;
            }
            if (
              a.reportStartDate.toLowerCase() < b.reportStartDate.toLowerCase()
            ) {
              return -1;
            }
            if (
              a.reportStartDate.toLowerCase() > b.reportStartDate.toLowerCase()
            ) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStartDateDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "EndDate":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportEndDate === null) {
              return -1;
            }
            if (a === null || a.reportEndDate === null) {
              return 1;
            }
            if (a.reportEndDate.toLowerCase() > b.reportEndDate.toLowerCase()) {
              return -1;
            }
            if (a.reportEndDate.toLowerCase() < b.reportEndDate.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEndDateDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.reportEndDate === null) {
              return 1;
            }
            if (a === null || a.reportEndDate === null) {
              return -1;
            }
            if (a.reportEndDate.toLowerCase() < b.reportEndDate.toLowerCase()) {
              return -1;
            }
            if (a.reportEndDate.toLowerCase() > b.reportEndDate.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEndDateDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "NextRun":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.nextRun === null) {
              return -1;
            }
            if (a === null || a.nextRun === null) {
              return 1;
            }
            if (a.nextRun.toLowerCase() > b.nextRun.toLowerCase()) {
              return -1;
            }
            if (a.nextRun.toLowerCase() < b.nextRun.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNextRunDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.nextRun === null) {
              return 1;
            }
            if (a === null || a.nextRun === null) {
              return -1;
            }
            if (a.nextRun.toLowerCase() < b.nextRun.toLowerCase()) {
              return -1;
            }
            if (a.nextRun.toLowerCase() > b.nextRun.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setNextRunDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "ReportOwner":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.username === null) {
              return -1;
            }
            if (a === null || a.username === null) {
              return 1;
            }
            if (a.username.toLowerCase() > b.username.toLowerCase()) {
              return -1;
            }
            if (a.username.toLowerCase() < b.username.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setReportOwnerDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.username === null) {
              return 1;
            }
            if (a === null || a.username === null) {
              return -1;
            }
            if (a.username.toLowerCase() < b.username.toLowerCase()) {
              return -1;
            }
            if (a.username.toLowerCase() > b.username.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setReportOwnerDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "AdminLevel":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.type === null) {
              return -1;
            }
            if (a === null || a.type === null) {
              return 1;
            }
            if (a.type.toLowerCase() > b.type.toLowerCase()) {
              return -1;
            }
            if (a.type.toLowerCase() < b.type.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setAdministrationDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.type === null) {
              return 1;
            }
            if (a === null || a.type === null) {
              return -1;
            }
            if (a.type.toLowerCase() < b.type.toLowerCase()) {
              return -1;
            }
            if (a.type.toLowerCase() > b.type.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setAdministrationDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "Recipients":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (a.ccUser > b.ccUser) {
              return -1;
            }
            if (a.ccUser < b.ccUser) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setRecipientsDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (a.ccUser > b.ccUser) {
              return 1;
            }
            if (a.ccUser < b.ccUser) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setRecipientsDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      case "ScheduleID":
        if (sortGivenItemInDesc) {
          result.sort((a: any, b: any) => {
            if (b === null || b.scheduleID === null) {
              return -1;
            }
            if (a === null || a.scheduleID === null) {
              return 1;
            }
            if (a.scheduleID > b.scheduleID) {
              return -1;
            }
            if (a.scheduleID < b.scheduleID) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUniqueIDDescending(false);
          }
        } else {
          result.sort((a: any, b: any) => {
            if (b === null || b.scheduleID === null) {
              return 1;
            }
            if (a === null || a.scheduleID === null) {
              return -1;
            }
            if (a.scheduleID > b.scheduleID) {
              return 1;
            }
            if (a.scheduleID < b.scheduleID) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUniqueIDDescending(true);
          }
        }
        setFilteredListData(result);
        setSchedulerListData(result);
        break;
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
      | "ReportTitle"
      | "ReportType"
      | "Frequency"
      | "DateCreated"
      | "StartDate"
      | "EndDate"
      | "NextRun"
      | "ReportOwner"
      | "AdminLevel"
      | "Recipients"
      | "ScheduleID",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setFilteredListData(result);
      setSchedulerListData(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickReportNameAsc = () => {
    setReverseNextSort(false);
    setReportNameDescending(false);
    sortResultsBy("ReportTitle", false);
  };

  const clickReportNameDesc = () => {
    setReverseNextSort(false);
    setReportNameDescending(true);
    sortResultsBy("ReportTitle", true);
  };

  const clickReportTypeAsc = () => {
    setReverseNextSort(false);
    setReportTypeDescending(false);
    sortResultsBy("ReportType", false);
  };

  const clickReportTypeDesc = () => {
    setReverseNextSort(false);
    setReportTypeDescending(true);
    sortResultsBy("ReportType", true);
  };

  const clickFrequencyAsc = () => {
    setReverseNextSort(false);
    setFrequencyDescending(false);
    sortResultsBy("Frequency", false);
  };

  const clickFrequencyDesc = () => {
    setReverseNextSort(false);
    setFrequencyDescending(true);
    sortResultsBy("Frequency", true);
  };

  const clickDateCreatedAsc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(false);
    sortResultsBy("DateCreated", false);
  };

  const clickdateCreatedDesc = () => {
    setReverseNextSort(false);
    setDateCreatedDescending(true);
    sortResultsBy("DateCreated", true);
  };

  const clickStartDateAsc = () => {
    setReverseNextSort(false);
    setStartDateDescending(false);
    sortResultsBy("StartDate", false);
  };

  const clickStartDateDesc = () => {
    setReverseNextSort(false);
    setStartDateDescending(true);
    sortResultsBy("StartDate", true);
  };

  const clickEndDateAsc = () => {
    setReverseNextSort(false);
    setEndDateDescending(false);
    sortResultsBy("EndDate", false);
  };

  const clickEndDateDesc = () => {
    setReverseNextSort(false);
    setEndDateDescending(true);
    sortResultsBy("EndDate", true);
  };

  const clickNextRunAsc = () => {
    setReverseNextSort(false);
    setNextRunDescending(false);
    sortResultsBy("NextRun", false);
  };

  const clickNextRunDesc = () => {
    setReverseNextSort(false);
    setNextRunDescending(true);
    sortResultsBy("NextRun", true);
  };

  const clickReportOwnerAsc = () => {
    setReverseNextSort(false);
    setReportOwnerDescending(false);
    sortResultsBy("ReportOwner", false);
  };

  const clickReportOwnerDesc = () => {
    setReverseNextSort(false);
    setReportOwnerDescending(true);
    sortResultsBy("ReportOwner", true);
  };

  const clickAdminLevelAsc = () => {
    setReverseNextSort(false);
    setAdministrationDescending(false);
    sortResultsBy("AdminLevel", false);
  };

  const clickAdminLevelDesc = () => {
    setReverseNextSort(false);
    setAdministrationDescending(true);
    sortResultsBy("AdminLevel", true);
  };

  const clickRecipientsAsc = () => {
    setReverseNextSort(false);
    setRecipientsDescending(false);
    sortResultsBy("Recipients", false);
  };

  const clickRecipientsDesc = () => {
    setReverseNextSort(false);
    setRecipientsDescending(true);
    sortResultsBy("Recipients", true);
  };

  const clickUniqueIDAsc = () => {
    setReverseNextSort(false);
    setUniqueIDDescending(false);
    sortResultsBy("ScheduleID", false);
  };

  const clickUniqueIDDesc = () => {
    setReverseNextSort(false);
    setUniqueIDDescending(true);
    sortResultsBy("ScheduleID", true);
  };

  const handleSelectAll = () => {
    let updatedDataLocal: any = schedulerListData.map((item: any) => {
      return {
        ...item,
        isChecked: true,
      };
    });

    setFilteredListData(updatedDataLocal);

    setSchedulerListData(updatedDataLocal);
  };

  const handleDeselectAll = () => {
    let updatedDataLocal: any = schedulerListData.map((item: any) => {
      return {
        ...item,
        isChecked: false,
      };
    });

    setFilteredListData(updatedDataLocal);
    setSchedulerListData(updatedDataLocal);
  };

  // const buildRequestStringArray = () => {
  //   let resultArray : Array<string> = []
  //   for (let i = 0; i < adminReportResult.length; i++) {
  //       const r = adminReportResult[i]
  //       resultArray.push(`${r.unit}<#>${r.userLastName}<#>${r.userFirstName}<#>${r.email}<#>${r.externalID}<#>${r.course}<#>${r.module}<#>${r.lessonStatus}<#>${r.quizStatus}<#>${(r.quizStatus.toLowerCase() == "passed" || r.quizStatus.toLowerCase() == "failed") ? r.quizScore : ""}<#>${r.quizStatusDate}`)
  //   }
  //   return resultArray
  // }

  const handleModal = (value: boolean) => {
    setReassignOpen(value);
    if(!value) {
      setUsersList([]);
      setFirstName('');
      setLastName('');
    }
  };

  const handleFilterData = (e:any) =>{
    let filteredText = e.target.value.toString().toLowerCase();
    let updatedDataLocal = schedulerListData.filter(
      (item:any) => item.scheduleID.toString().includes(filteredText)
          ||        item.reportTitle.toString().toLowerCase().includes(filteredText)
          ||        item.reportName.toString().toLowerCase().includes(filteredText)
          ||        item.reportStartDate.toString().toLowerCase().includes(filteredText)
          ||        item.reportEndDate.toString().toLowerCase().includes(filteredText)
          ||        item.reportFrequency.toString().toLowerCase().includes(filteredText)
          ||        item.username.toString().toLowerCase().includes(filteredText)
          ||        item.type.toString().toLowerCase().includes(filteredText)
          ||        item.ccUser.toString().toLowerCase().includes(filteredText)
    );

     setFilterScheduleReport(e.target.value);
     setFilteredListData([...updatedDataLocal]);
    }

  const handleSave = () => {

    let updatedDataLocal = [];
    let scheduleIds = "";
    if(schedulerListData.length > 0) {
      updatedDataLocal = schedulerListData.filter((item:any) => item.isChecked);
     let scheduleList = updatedDataLocal.map((value:any) => value.scheduleID);
      scheduleIds = scheduleList.join(",");
    } 
   setReassignOpen(false);
   setLoading(true);
   setFirstName('');
   setLastName('');
   setMessage("Loading..");
      reassignSelectedUsers( oidcUser.access_token,
        userId,
        orgDomain,
        parseInt(orgId),
        scheduleIds,
        reassignUser
        ).then((result) => {
        
          if(result.isSuccess) {      
            setLoading(true);   
            getSchedulerData();
          }
        }).catch((error) => {
          setLoading(false);
        })        
        setUsersList([]);    
  }

  const handleUserSearch = () => {   
    getUsersList(oidcUser.access_token, userId, orgDomain, firstName, lastName,parseInt(orgId)).then(
      (response) => {
        let filterusertype = response.listOfUsers.filter((u: any) => u.userTypeId === 2 || u.userTypeId === 3);
        if(filterusertype.length > 0)
        {
            let updatedUsersList = filterusertype.map((item:any) => {          
                    return {
                        ...item,
                        isChecked:false
                    }
                })
            setPageuser(0);
            setUsersList(updatedUsersList);
        }      
     }
    );
  };


    // const buildRequestStringArray = () => {    
    //     let resultArray : Array<string> = []
    //     const r = schedulerListDataExport[0]      
    //   console.log('length' + schedulerListDataExport.length);
    //     for (let i = 0; i < schedulerListDataExport.length; i++) {
    //         const r = schedulerListDataExport[i]                       
    //         resultArray.push(`${r.reportTitle}<#>${r.reportName}<#>${r.reportInterval}<#>${r.dateCreated}<#>${r.reportStartDate}<#>${r.reportEndDate}<#>${r.nextRun}<#>${r.username}<#>${r.administrationLevel}<#>${r.deliveredTo}<#>${r.uniqueID}`)
           
    //     }
    //     return resultArray
    // }

  const getCheckedSchedules = () => {
    let updatedDataLocal = [];
    updatedDataLocal = schedulerListData.filter((item:any) => item.isChecked);
    return updatedDataLocal;
  };
  const GetDate_yymmdd = () => {
    var d =  new Date(Date.now());
    let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
     return day;         
  }
  const handleXLSXExport = () => {    
    getSchedulerListExport( oidcUser.access_token,userId,orgDomain,parseInt(orgId)).then((result) => {
      if (result.isSuccess) {
        setLoading(false);
        let formattedList = result.schedulerReportExportItem.map((data: any) => {
          return {
            ...data,
            isChecked: false,
          };
        });
        setSchedulerListDataExport(formattedList);

        let resultArray : Array<string> = []
        const r = formattedList[0]
        //console.log('length' + formattedList.length);
        for (let i = 0; i < formattedList.length; i++) {
          const r = formattedList[i]
          resultArray.push(`${r.uniqueID}<#>${r.reportTitle}<#>${r.reportName}<#>${r.dateCreated}<#>${r.reportStartDate}<#>${r.reportEndDate}<#>${r.reportInterval}<#>${r.nextRun}<#>${r.username}<#>${r.administrationLevel}<#>${r.deliveredTo}`)
        }

        setMessage("Exporting XLSX");
        setExportError("");      
        addError(oidcUser.access_token,userId,orgDomain,"Started  Scheduler Report XLSX-EXPORT in NEWSALT ClientApp",null,"ReportSchedulerList.tsx",6);
        setExporting(true);
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), resultArray, 11, "schedulereport", enableRowBanding, "", "", "", "", false, false, "", "", "", "", "").then((xlxsRes) => {
          if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
              new Blob([serialToArray(atob(fileData))], {
                type: ''
              })
            )

            a.download = "ReportSchedulerList-" + GetDate_yymmdd() + ".xlsx";
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

          }
          else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
          }
          setExporting(false)
          // Activity log
          addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report XLSX-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        })  

      }
    }).catch((error) => {
      setLoading(false);
    });       
  };

  
  const handleCSVExport = () => {       
    
    getSchedulerListExport( oidcUser.access_token,userId,orgDomain,parseInt(orgId)).then((result) => {
      if (result.isSuccess) {
        setLoading(false);
        let formattedList = result.schedulerReportExportItem.map((data: any) => {           
          return {
            ...data,
            isChecked: false,
          };
        });        
        setSchedulerListDataExport(formattedList);
        
        let resultArray : Array<string> = []
        const r = formattedList[0]
        //console.log('length' + formattedList.length);
        for (let i = 0; i < formattedList.length; i++) {
          const r = formattedList[i]
          resultArray.push(`${r.uniqueID}<#>${r.reportTitle}<#>${r.reportName}<#>${r.dateCreated}<#>${r.reportStartDate}<#>${r.reportEndDate}<#>${r.reportInterval}<#>${r.nextRun}<#>${r.username}<#>${r.administrationLevel}<#>${r.deliveredTo}`)
        }
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Scheduler Report CSV-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true);           
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), resultArray, 11, "schedulereport","","","","",false,false, "",false, "", "", "","","","").then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "ReportSchedulerList-"+ GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Progress Report CSV-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
        })  


      }
    }).catch((error) => {
      setLoading(false);
    });
}

// const handlePDFExport = () => {
//   setMessage("Exporting PDF");
//   setExportError("");
//   // Activity log 
//   addError(oidcUser.access_token, userId, orgDomain, "Started Scheduler Report CSV-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
//   setExporting(true);
//   const sortedArr = buildRequestStringArray();
//   const r = schedulerListDataExport[0];
//   getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), sortedArr, 11, "schedulereport","","","","",false,false, "",false, "", "", "","","","").then((csvRes) => {            
//       if (csvRes.isSuccess) {
//           const fileData = csvRes.csvDataOutput.fileData
//           var a = window.document.createElement('a');
          
//           a.href = window.URL.createObjectURL(
//               new Blob([serialToArray(atob(fileData))], {
//                   type: ''
//               })
//           )
//           a.download = "ReportSchedulerList-"+ GetDate_yymmdd() + ".pdf";
//           document.body.appendChild(a)
//           a.click();
//           document.body.removeChild(a)

//       }
//       else if (csvRes.error !== undefined && csvRes.error !== null) {
//           setExportError(csvRes.error);
//       }
//       setExporting(false)
//       // Activity log 
//       addError(oidcUser.access_token, userId, orgDomain, "Completed Progress Report CSV-EXPORT in NEWSALT ClientApp", null, "ProgressReportResult.tsx", 6, EnableActivityLogs);
//   })
// }
  const handleReassign = () => {
    setReassignUser(0)
    setReassignOpen(true);
  };

  const handleDeleteReports = () => {
    
    let deletedData = schedulerListData.filter((item:any) => item.isChecked);
    let deletedScheduleIds = "";

    let updatedDataLocal = deletedData.map((value:any) => value.scheduleID)
    deletedScheduleIds = updatedDataLocal.join(",");
    deleteSchedulerList(
      oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      deletedScheduleIds
    ).then((response:any) => {
      if(response.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setModalPopupMessage("Scheduled Report has been deleted successfully!");
        getSchedulerData();
      }
    })
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleChangePageUser = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPageuser(newPage);
  };

  const handleChangeRowsPerPageUser = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPageuser(parseInt(event.target.value, 10));
    setPageuser(0);
  };

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  if (loading) return <LoaderContainer/>;



  return (
  <div>

  <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          onExit={onExit}
          options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}
        />

    <div>
      <Modal modalOpen={reassignOpen} setModalOpen={handleModal}>
        <div>
        {(schedulerListData != null && (getCheckedSchedules()).length == 0) && (
          <div style={{top: "-10px" }}>
            <h3 style={{ color: "#e7a652", width: "500px", marginTop: "4px" }}>Warning</h3>
            <p>Please select the schedule to reassign.</p>
            <div className={styles.lowerButtonPanel}>
            <CustomTooltip displayText={["Return"]}>
              <Button
                onClick={() => setReassignOpen(false)}
                className={styles.returnIcon}
                variant="contained"
                color="primary"
              >
                <CloseIcon />
              </Button>
            </CustomTooltip>
          </div>
          </div>
        )}
        {(schedulerListData != null && (getCheckedSchedules()).length > 0) && (
          <div>
          <div style={{ position: "relative", top: "-10px" }}>
          <h2 style={{ color: "primary", width: "500px", marginTop: "4px" }}>Select account(s) to reassign</h2>            
          </div>
          <div className={styles.inputContainer}>
            <p>
            Only organisation or unit admins can be report owners
            </p>
          </div>
          <div className={styles.expandContainer}>
            <div className={styles.listField}>
              <TextField
                className={styles.input}
                label={"First Name"}
                name="firstName"
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className={styles.listField}>
              <TextField
                className={styles.input}
                label={"Last Name"}
                name="lastName"
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{ shrink: true }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.lowerButtonPanel}>
            <CustomTooltip displayText={["Search"]}>
              <Button
                onClick={handleUserSearch}
                className={styles.moduleListButton}
                variant="contained"
                color="primary"
              >
                <SearchIcon />
              </Button>
            </CustomTooltip>
          </div>
          </div>
        )}
        {(usersList != null && usersList.length > 0) && ( 
          <div style={{width:'92%', marginLeft:'15px' }}>
     
     
              <div className={styles.paginationControlsContainer}>
            
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 100]}
                  component="div"
                  style={{position:'relative',right:'250px'}}
                  count={usersList.length}
                  rowsPerPage={rowsPerPageuser}
                  page={pageuser}
                  onChangePage={handleChangePageUser}
                  onChangeRowsPerPage={handleChangeRowsPerPageUser}
                />
              </div>

              <div className={styles.tableSection} >
                <div className={styles.tableContainer}> 
                <BrandedCard >
                        <TableContainer style={{height:'400px'}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        
                                    <TableCell 
                                        style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "8px 0 0 0"}}
                                         align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                >
                                                {"Select"}
                                                </div>
                                                
                                            </div>
                                        </TableCell>
                                        
                                        <TableCell 
                                        style={{color: getColor(color.substring(1)),backgroundColor: color}}
                                         align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        // setLastNameDescending(!lastNameDescending);
                                                        // sortResultsBy("LastName", !lastNameDescending);
                                                    }}>
                                                {"Last Name" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: ( false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={() => {}}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: ( true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={() => {}}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell 
                                        style={{color: getColor(color.substring(1)),backgroundColor: color}} 
                                        align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        // setFirstNameDescending(!firstNameDescending);
                                                        // sortResultsBy("FirstName", !firstNameDescending);
                                                    }}>
                                                {"First Name" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={() => {}}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={() => {}}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>                                       
                                        <TableCell 
                                        style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"}} 
                                        align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        // setEmailDescending(!emailDescending);
                                                        // sortResultsBy("Email", !emailDescending);
                                                    }}>
                                                {"Email" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: ( false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={() => {}}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: ( true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={() => {}}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                       
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        usersList.slice(pageuser * rowsPerPageuser, pageuser * rowsPerPageuser + rowsPerPageuser).map((result: any, i ) => {
                                            return(
                                                <TableRow key={`reportScheduler-${i}`} 
                                                style={rowBackgroundColor(i)}
                                                >
                                                  <TableCell align="left"> 
                                                          <RadioGroup
                                                              name="periodType"   
                                                              value={reassignUser}                                                         
                                                              onChange={(e) => handleReassignUsers(e,result)}
                                                              style={{ display: 'flex', flexDirection: 'row' }}
                                                          >
                                                              <FormControlLabel
                                                                  id="excludePreviousCompletionsInAssignPoints"
                                                                  value={result.userID}
                                                                  control={<Radio color="primary" />}
                                                                  label={result.ID}
                                                              />                                                                                                                         
                                                          </RadioGroup> 
                                                    </TableCell>
                                                    
                                                    <TableCell align="left">
                                                        {result.lastName}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.firstName}
                                                    </TableCell>                                                    
                                                    <TableCell align="left" >
                                                        {result.email}
                                                    </TableCell>
                                                    
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>

                    <div className={styles.paginationControlsContainer}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={usersList.length}
                        rowsPerPage={rowsPerPageuser}
                        style={{position:'relative',right:'250px'}}
                        page={pageuser}
                        onChangePage={handleChangePageUser}
                        onChangeRowsPerPage={handleChangeRowsPerPageUser}
                      />
                    </div>

                </div>
              </div>
          </div>)}
          {(usersList != null && usersList.length > 0) && (  <CustomTooltip displayText={["Save"]}>
            <div style={{display:'flex', justifyContent:'end'}}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="save"
                onClick={handleSave}
                size="medium"
                disabled={(reassignUser===0)}
              >
                <SaveIcon />
              </Button>
            </div >
          </CustomTooltip>)}
        </div>
      </Modal>

      <div className={styles.searchCard} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
      <div className={styles.searchContainer}>
                          <div className="search" style={{width:'500px'}}> 
                           <TextField  className={styles.input}  label={"Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}  
                            value={filterScheduleReport} onChange={handleFilterData} placeholder="Search by Keyword"
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}
                            ></TextField>
                           
                            </div>
                            {/* <Button style={{position:'relative', left:'-55px'}}>
                            <SearchIcon fontSize="large" color="disabled"/>
                            </Button> */}
                        </div>
                       
      <div>
      <CustomTooltip displayText={["Guide Me"]}>
               
               <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                      <GuideIcon />
                    </span>
            </CustomTooltip>
           
        <CustomTooltip
          displayText={["Export to XLSX"] /*TODO: Add translations */}
        >
          <Button
            className="xlxs"
            color="primary"
            onClick={handleXLSXExport}
            id="btnNewSaltCARReportExportXLSX"
          >
            <ExcelFileIcon />
            {/* <GetAppIcon/> */}
          </Button>
        </CustomTooltip>
        <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>
          <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
            <CSVFileIcon />
            {/* <GetAppIcon/> */}
          </Button>
        </CustomTooltip>

        {/* <CustomTooltip displayText={["Export to PDF"] }>
          <Button className="csv" color="primary" onClick={handlePDFExport} id="btnNewSaltCARReportExportPDF">
            <PDFIcon />           
          </Button>
        </CustomTooltip> */}
        
       {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading"  color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/> 
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
        </div>
      </div>
      </div>
      <div style={{ marginTop: "15px" }}>

     {filteredListData.length == 0 ? <div className={styles.scheduleNotFound}><h3>No reports scheduled</h3></div> : <div>
        <div style={{ display: "flex" }}>
          <div>
            <ButtonGroup
              variant="contained"
              color="primary"
              aria-label="Filter"
            >
              <Button className={styles.button} onClick={handleSelectAll}>
                <SelectAllIcon />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>{
                  ` Select All` /*TODO Add translation */
                }</span>
              </Button>
              <Button
                className={styles.button}
                onClick={handleDeselectAll}
                style={{ marginLeft: "-5px" }}
              >
                <UnSelectAllIcon />
                <span style={{ fontSize: "12px", marginLeft:'5px' }}>{
                  ` Unselect All` /*TODO Add translation */
                }</span>
              </Button>
            </ButtonGroup>
          </div>
          <div
            className={styles.paginationControlsContainer}
            style={{ flexGrow: 2, marginRight: "38%" }}
          >
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 100]}
              component="div"
              count={filteredListData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      
        <div className={styles.tableSection} style={{ marginTop: "10px" }}>
          <div className={styles.tableContainer}>
            <BrandedCard>
              <TableContainer>
                <Table>
                  <TableHead className="scheduleReportList">
                    <TableRow>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                          borderRadius: "8px 0 0 0",
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div>{"Select"}</div>
                        </div>
                      </TableCell>
                    
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setUniqueIDDescending(!uniqueIDDescending);
                              sortResultsBy("ScheduleID", !uniqueIDDescending);
                            }}
                          >
                            {"Schedule ID" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ScheduleID" &&
                                  uniqueIDDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickUniqueIDAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ScheduleID" &&
                                  uniqueIDDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickUniqueIDDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setReportNameDescending(!reportNameDescending);
                              sortResultsBy(
                                "ReportTitle",
                                !reportNameDescending
                              );
                            }}
                          >
                            {"Report Title" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ReportTitle" &&
                                  reportNameDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickReportNameAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ReportTitle" &&
                                  reportNameDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickReportNameDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setReportTypeDescending(!reportTypeDescending);
                              sortResultsBy(
                                "ReportType",
                                !reportTypeDescending
                              );
                            }}
                          >
                            {"Report Type " /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ReportType" &&
                                  reportTypeDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickReportTypeAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ReportType" &&
                                  reportTypeDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickReportTypeDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                     
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setStartDateDescending(!startDateDescending);
                              sortResultsBy("StartDate", !startDateDescending);
                            }}
                          >
                            {"Start Date" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "StartDate" &&
                                  startDateDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickStartDateAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "StartDate" &&
                                  startDateDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickStartDateDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setEndDateDescending(!endDateDescending);
                              sortResultsBy("EndDate", !endDateDescending);
                            }}
                          >
                            {"End Date" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "EndDate" &&
                                  endDateDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickEndDateAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "EndDate" &&
                                  endDateDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickEndDateDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                     
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setFrequencyDescending(!frequencyDescending);
                              sortResultsBy("Frequency", !frequencyDescending);
                            }}
                          >
                            {"Frequency" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Frequency" &&
                                  frequencyDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickFrequencyAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Frequency" &&
                                  frequencyDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickFrequencyDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>
                    
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                          borderRadius: "0 0 0 0",
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setReportOwnerDescending(!reportOwnerDescending);
                              sortResultsBy(
                                "ReportOwner",
                                !reportOwnerDescending
                              );
                            }}
                          >
                            {"Report Owner" /* TODO translate */}
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ReportOwner" &&
                                  reportOwnerDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickReportOwnerAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "ReportOwner" &&
                                  reportOwnerDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickReportOwnerDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell>                     
                      <TableCell
                        style={{
                          color: getColor(color.substring(1)),
                          backgroundColor: color,
                          borderRadius: "0 8px 0 0",
                        }}
                        align="left"
                      >
                        <div className={styles.tableHeadCellContainer}>
                          <div
                            onClick={() => {
                              setRecipientsDescending(!recipientsDescending);
                              sortResultsBy(
                                "Recipients",
                                !recipientsDescending
                              );
                            }}
                          >
                            {"Recipients" }
                          </div>
                          <div className={styles.columnHeadArea}>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Recipients" &&
                                  recipientsDescending === false
                                    ? "100%"
                                    : "50%",
                                margin: "2px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickRecipientsAsc}
                            >
                              <UpArrowIcon />{" "}
                            </span>
                            <span
                              style={{
                                opacity:
                                  currentSortBy === "Recipients" &&
                                  recipientsDescending === true
                                    ? "100%"
                                    : "50%",
                                margin: "0px",
                                height: "6px",
                                display: "inline-flex",
                              }}
                              onClick={clickRecipientsDesc}
                            >
                              <DownArrowIcon />{" "}
                            </span>
                          </div>
                        </div>
                      </TableCell> 
                     
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredListData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((result: any, i) => {
                        return (
                          <TableRow
                            key={`reportscheduleList-${i}`}
                            style={rowBackgroundColor(i)}
                          >
                            <TableCell align="left" className="select">
                              <Checkbox
                                size="small"
                                color="primary"
                                className={styles.checkBox}
                                name="selectedUser"
                                key={result.userID}
                                checked={result.isChecked}
                                onChange={(e) => handleSelectedUsers(e, result)}
                              />
                            </TableCell>
                            <TableCell align="left">
                              {result.scheduleID}
                            </TableCell>
                            <TableCell align="left">
                            <a onClick={() => handleOpeningScheduleReport(result)} className={styles.titleLink}>{result.reportTitle}</a>
                            </TableCell>
                            <TableCell align="left">{result.reportName}</TableCell>
                            
                            <TableCell align="left">
                              {result.reportStartDate}
                            </TableCell>
                            <TableCell align="left">
                              {result.reportEndDate}
                            </TableCell>                        
                            <TableCell align="left">{result.reportFrequency}</TableCell>                        
                            <TableCell align="left">{result.username}</TableCell> 
                            <TableCell align="left">                               
                              {
                                // <CustomTooltip displayText={schedulerListDataExport.filter((item:any) => item.uniqueID == (result.scheduleID)).map(str => {
                                //     return(`${str.deliveredTo}`)
                                // })}>
                                    <div>{result.ccUser}</div>
                                //  </CustomTooltip> 
                                } 
                            </TableCell> 
                            
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </BrandedCard>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{ flexGrow: 2, marginRight: "30%" }}
            className={styles.paginationControlsContainer}
          >
            <TablePagination
              component="div"
              count={filteredListData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
          <CustomTooltip displayText={["Delete Selected"]}>
            <span className={styles.deleteIcon} onClick={handleDeleteReports}>
              <DeleteIcon />
            </span>
          </CustomTooltip>

          <CustomTooltip displayText={["Reassign Selected"]}>
            <span className={styles.reassignIcon} onClick={handleReassign}>
              <ResetIcon />
            </span>
          </CustomTooltip>
        </div>
      </div>}
    </div>
      <div className={styles.mainWrapper}>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
      </div>
    </div>
);
};

export default ReportSchedulerList;
