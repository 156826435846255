import axios from 'axios';
import { addError } from '../../AddError';
import { CourseAdditionalResources } from '../../../globalTypes';

const SaveCourseAdditionalResources = async (token: string, requestinguserId: number, orgDomain: string, orgId:number,courseId:number,
 courseAdditionalResources: Array<CourseAdditionalResources>) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/administration/SaveCourseAdditionalResources`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        reportRequestingUserID: requestinguserId,
        courseID: courseId,
        organisationID: orgId,       
        courseAdditionalResourcesItems: courseAdditionalResources
    },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.response, "SaveCourseAdditionalResources.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.request, "SaveCourseAdditionalResources.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, requestinguserId, orgDomain, "Rest Exception", error.message, "SaveCourseAdditionalResources.ts", 1)
    }
    return null;
  }
};
export default SaveCourseAdditionalResources;