//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/organisationmanagement.module.css";

//General Functions
import getColor from '../../../functions/getColor';
import validateEmail from '../../../functions/valudateEmail';
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';

import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';
import SaveIcon from '../../../CustomIcons/SupportDetailSaveIcon';
import CancelIcon from '../../../CustomIcons/SupportDetailCancelIcon';

import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import { AccountManager } from '../../../globalTypes';
import { Checkbox, CircularProgress, TableContainer, TablePagination } from '@material-ui/core';
import DeleteIcon from '../../../CustomIcons/DeleteIcon';

import GetOrgAccountManagers from '../../../API/SuperAdmin/GetOrgAccountManagers';
import SaveOrgAccountManagers from '../../../API/SuperAdmin/SaveOrgAccountManagers';

const AccountManagers = () => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;

  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection,superAdminSelectedBasicOrgData} = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [orgAccountManager, setorgAccountManager] = useState<Array<AccountManager>>([]);
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);
 
  const [accManagerID, setAccManagerID] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);  
  const [firstNameadd, setfirstNameadd] = useState<string>("");
  const [lastNameadd, setlastNameadd] = useState<string>("");
  const [emailadd, setEmailadd] = useState<string>("");
  const [activeadd, setActiveadd] = useState<boolean>(false);

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);  
  const [page, setPage] = React.useState<number>(0);


  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
 const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";


  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".FirstNameadd",
        title: "First Name",
        intro: "Enter a first name for the Account Manager."
      },

    ]
  })

  useEffect(() => {    
    setGuideMe(false);
    loadOrgAccountManager();
    return () => { };
  }, [superAdminSelectedBasicOrgData]);

  useEffect(() => {
    setGuideMe(false);
    setTitle(allResourcesToTranslations.orgaccountmanagers)
    setTitleEnglish(pageTitles.orgaccountmanagers);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.orgaccountmanagers);
    setLoading(true);
    loadOrgAccountManager();
    return () => { };
  }, [superAdminSelectedBasicOrgData]);


  const setGuideMe = (showaccounts:boolean = false) => {

    let updateSteps = [];
  
    updateSteps.push(
      {
        element: ".FirstNameadd",
        title: "First Name",
        intro: "Enter a first name for the Account Manager."
      },
      {
        element: ".LastNameadd",
        title: "Last Name",
        intro: "Enter a last name for the Account Manager."
      },
      {
        element: ".Emailadd",
        title: "Email",
        intro: "Enter a email for the Account Manager."
     
      },
      {
        element: ".Activeadd",
        title: "Active",
        intro: "Check to activate an Account Manager."
     
      },
      {
        element: ".Save",
        title: "Save Account Manager",
        intro: "Click to save the new Account Manager.",
      });
      if (showaccounts)
      updateSteps.push(
      {
        element: ".FirstName",
        title: "First Name",
        intro: "Column displays all Account Manager First Name."
      },
      {
        element: ".LastName",
        title: "Last Name",
        intro: "Column displays all Account Manager Last Name."
      },
      {
        element: ".Email",
        title: "Email",
        intro: "Column displays all Account Manager Email."
      },
      {
        element: ".Active",
        title: "Active",
        intro: "Column displays Account Manager status.",
      },
      {
        element: ".Edit",
        title: "Modify",
        intro: "Click the modify button for the relevant Account Manager to modify the details.",
      },
      {
        element: ".Delete",
        title: "Delete",
        intro: "Click the delete button for the relevant Account Manager to remove.",
      }
    );
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };


  const loadOrgAccountManager = () => {    
    GetOrgAccountManagers(oidcUser.access_token, userId, orgDomain, 
        parseInt(orgId),
        (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),        
        (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
          (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")
      ).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.orgAccountManagers !== null) {
          setorgAccountManager([...result.orgAccountManagers]);
          if(result.orgAccountManagers.length > 0)
          {
          setGuideMe(true);         
          }
          else{
            setGuideMe(false);
          }
        }
      }
      else {
        setGuideMe(false);  
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load Organisation Account Managers!");
      }
     // setLoading(false);
    });
	setLoading(false);
  };
  

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }

  const handleEdit = (firstName: any,lastName:string,email:string,index: number,showActive: boolean) => {
    setAccManagerID(index);
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setActive(showActive);    
    let updatedSelectedLink = orgAccountManager.map((item: AccountManager) => {
      if (!!item) {
        handleCancel(item.firstName);
      }
      if (item.firstName === firstName) {        
        item.isBeingEdit = true;
      }
      return item;
    });
    setorgAccountManager([...updatedSelectedLink]);
  };

  const handleCancel = (firstName: any) => {
    const updatedSelectedLink = orgAccountManager.map((item: AccountManager) => {
      if (!!item && item.firstName === firstName) {
        item.isBeingEdit = false;
      }
      return item;
    });
    setorgAccountManager([...updatedSelectedLink]);
  };

  const handleDelete = (index: number) => {
    handleConfirmModalDelete(true);
    setAccManagerID(index);
  }

  const handleConfirmModalDelete = (value: boolean) => {
    setshowConfirmModalDelete(value);
  }

  const handleCancelConfirmPopupDelete = () => {
    handleConfirmModalDelete(false);
    setIsSaving(false);
  }

  const handleResetData = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setAccManagerID(0);
    setActive(false);
    setfirstNameadd('');
    setlastNameadd('');  
    setEmailadd(''); 
    setActiveadd(false);
  }

  const handleProceedDelete = () => {
    handleConfirmModalDelete(false);
    if (accManagerID > 0) {

      SaveOrgAccountManagers(oidcUser.access_token, userId, orgDomain, 
      parseInt(orgId),firstName, lastName,email, active,accManagerID, "delete",    
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),        
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
        (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result) => {
        if (result.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          loadOrgAccountManager();
          setModalPopupMessage("Organisation Account Manager has been deleted successfully!");
          handleResetData();
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Account Manager delete failed, already active in another organisations deactivation process.");
        }
      })
    }
  }

  const handleSave = () => {
    setIsSaving(true);
    let curAccManagerID = 0;
    //Add New Link  
    if ((accManagerID == null || accManagerID == undefined)) {
      curAccManagerID = 0;
    }
    else {
      //Update Link
      curAccManagerID = accManagerID;
    }
    let errorString = '';

    let curEmail = curAccManagerID== 0 ? emailadd :email;
       

    if (!validateEmail(curEmail.trim())) {           
       errorString = errorString + 'Please enter valid Email.;'
    }    
    
    if (errorString !== ''){
      setShowWarningModal(true);
      setModalPopupMessage(errorString);
      setHasErrored(true);
      setSaveSuccess(false);
      return;
    }    
    SaveOrgAccountManagers(oidcUser.access_token, userId, orgDomain, 
    parseInt(orgId), (curAccManagerID == 0 ? firstNameadd : firstName), (curAccManagerID== 0 ? lastNameadd :lastName ),(curAccManagerID== 0 ? emailadd :email ),(curAccManagerID== 0 ? activeadd : active),  curAccManagerID,curAccManagerID == 0 ? "add":"update",
    (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),        
    (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result) => {
      if (result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true); 
        setIsSaving(false);      
        loadOrgAccountManager();
        setModalPopupMessage("Organisation Account Manager has been saved successfully!");
        handleResetData();
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Please select valid details to continue!");
      }
    })
  }
  
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>

      <Modal modalOpen={showConfirmModalDelete} setModalOpen={handleConfirmModalDelete} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              Are you sure you want to delete this Account?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelConfirmPopupDelete}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceedDelete}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <UnbrandedCard>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                align="left">
                <div className={styles.tableHeadCellContainer}>
                  <div>
                    {"Add Account Details"/* TODO translate */}
                  </div>
                </div>
              </TableCell>
              <TableCell
                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                align="left">
                <div className={styles.tableHeadCellContainer}>
                  &nbsp;
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ backgroundColor: '#fff' }} >
              <TableCell align="left">
                <span>{allResourcesToTranslations.firstname}</span>
              </TableCell>
              <TableCell align="left">
                <TextField style={{ width: '100%' }} variant="outlined" className="FirstNameadd"
                  autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={firstNameadd} onChange={(e) => setfirstNameadd(e.target.value)} />
              </TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: '#eee' }}>
              <TableCell align="left">
                <span>{allResourcesToTranslations.lastname}</span>
              </TableCell>
              <TableCell align="left">
                <TextField style={{ width: '100%' }} variant="outlined" className="LastNameadd"
                  autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={lastNameadd} onChange={(e) => setlastNameadd(e.target.value)} />
              </TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: '#fff' }}>
              <TableCell align="left">
                <span>{allResourcesToTranslations.emrepprvgmtembod}</span>
              </TableCell>
              <TableCell align="left">
                <TextField style={{ width: '100%' }} variant="outlined" className="Emailadd"
                  autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={emailadd} onChange={(e) => setEmailadd(e.target.value)} />
              </TableCell>
            </TableRow>
            <TableRow style={{ backgroundColor: '#eee' }}>
              <TableCell align="left" colSpan={2}>
            
            <Checkbox
                  style={{ paddingLeft: '0px' }}
                  size="small"
                  color="primary"
                  checked={activeadd}
                  className="Activeadd"
                  onClick={() => setActiveadd(!activeadd)}
                />{" "}               
                <span className={styles.labelText}>{allResourcesToTranslations.active}</span>
                
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </UnbrandedCard>
      <div>
        {/* Add new Save button */}
        <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px",paddingTop:"20px", width: "100%", clear: "both" }}>
          <CustomTooltip displayText={[allResourcesToTranslations.saveorgmanagement]}>
            <span className="saveData" style={{ marginRight: "-5px" }}>
              <Button
                disabled={firstNameadd.trim() === "" || lastNameadd.trim() === "" || emailadd.trim() === ""}
                onClick={handleSave}
                variant="contained"
                color="primary"
                className="Save"
              >
                <SaveIcon />
              </Button>
            </span>
          </CustomTooltip>
          <div className={styles.loaderContainer}>
            <CircularProgress
              style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
              color="primary"
              size="10"
            />
          </div>
        </div>
      </div>
       <div className={styles.mainWrapper}>
        <div style={{ width: '100%', clear: "both" }} >
          {orgAccountManager != null && orgAccountManager.length > 0 && (
            <div>
              <div className={styles.paginationControlsContainer}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgAccountManager.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
              <div className={`${styles.tableSection}`}>
                <div className={styles.tableContainer}>
                  <BrandedCard>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell   className="FirstName"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"First Name"}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell  className="LastName"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"Last Name" }
                                </div>
                              </div>
                            </TableCell>
                            <TableCell  className="Email"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color}}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"Email"}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell  className="Active"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div >
                                  {"Active" }
                                </div>

                              </div>
                            </TableCell>
                            <TableCell className="Edit"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"Modify" }
                                </div>
                              </div>
                            </TableCell>
                            <TableCell  className="Delete"
                              style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  {"Delete" }
                                </div>
                              </div>
                            </TableCell>                            
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orgAccountManager.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                            .map((result: any, i) => {
                              return (
                                <TableRow  style={rowBackgroundColor(i)}>
                                  <TableCell align="left" width={"15%"}>
                                      {result.isBeingEdit ?                                       
                                      <TextField style={{width: '100%'}} variant="outlined"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.firstName} onChange={(e) => setFirstName(e.target.value)} />
                                      :
                                      <span className={result.firstName}>{result.firstName}</span>
                                      }
                                  </TableCell>
                                  <TableCell align="left" width={"20%"}>
                                  {result.isBeingEdit ?                                       
                                      <TextField style={{ width: '100%' }} variant="outlined"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.lastName} onChange={(e) => setLastName(e.target.value)} />
                                      :
                                      <span className={result.lastName}>{result.lastName}</span>
                                      }                                    
                                  </TableCell>
                                  <TableCell align="left" width={"20%"}>
                                  {result.isBeingEdit ?                                       
                                      <TextField style={{ width: '100%' }} variant="outlined"
                                      autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      defaultValue={result.email} onChange={(e) => setEmail(e.target.value)} />
                                      :
                                      <span className={result.email}>{result.email}</span>
                                      }                                    
                                  </TableCell>
                                  <TableCell align="left" className={`usedefault${i}`} width={"15%"}>
                                   
                                  {result.isBeingEdit ?                                       
                                      <Checkbox
                                      size="small"
                                      color="primary"                                     
                                      className={styles.checkBox}
                                      name="selectedshowDisclaimeredit"
                                      checked={active}
                                      onClick={() => setActive(!active)}
                                    />
                                      :
                                      <Checkbox
                                      size="small"
                                      color="primary"
                                      className={styles.checkBox}
                                      name="selectedshowDisclaimer"
                                      checked={result.active}
                                      disabled
                                    />
                                      }                                     
                                  </TableCell>
                                  <TableCell align="left" width={"15%"}>                                    
                                       {result.isBeingEdit ? 
                                            <>
                                                <CustomTooltip displayText={["Save"]}>
                                                   <Button  disabled={firstName.trim() === "" || lastName.trim() === "" || email.trim() === ""} onClick={() => handleSave()} className={styles.moduleListButton} variant="contained" color="primary" >
                                                     <SaveIcon />
                                                   </Button>
                                                </CustomTooltip> &nbsp;&nbsp;
                                                <CustomTooltip displayText={["Cancel"]}>
                                                   <Button onClick={() => handleCancel(result.firstName)} className={styles.moduleListButton} variant="contained" color="primary">
                                                     <CancelIcon />
                                                   </Button>
                                                 </CustomTooltip>
                                            </>
                                            :
                                                <CustomTooltip displayText={["Edit"]}>
                                                  <Button className="ruleedit" onClick={() => handleEdit(result.firstName,result.lastName,result.email,result.accountManagerID,result.active)} variant="contained" color="primary">
                                                    <EditIcon />
                                                  </Button>
                                                </CustomTooltip>
                                            }       
                                  </TableCell>
                                  <TableCell align="left" width={"5%"}>
                                    <CustomTooltip displayText={["Delete"]}>
                                      <Button className="ruledelete" onClick={() => handleDelete(result.accountManagerID)} variant="contained" style={{ backgroundColor: "red" }}>
                                        <DeleteIcon />
                                      </Button>
                                    </CustomTooltip>
                                  </TableCell>
                                 
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </BrandedCard>
                </div>
              </div>
              <div className={styles.paginationAddedUsersControl}>
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={orgAccountManager.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

      </div>

    </>
  );
};
export default AccountManagers;
