//React + Typescript
import React, { useContext, useEffect, useState } from 'react';
// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
//Intro Lib
//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import LoaderContainer from '../../../Components/LoaderContainer';
import CustomTooltip from '../../../Components/CustomTooltip';
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";

import { Link } from 'react-router-dom';
import { useHistory, useParams, useLocation } from 'react-router';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import { Steps } from "intro.js-react";
import BrandedCard from '../../../Components/BrandedCard';
import { AppBar, Box, Button, createStyles, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, withStyles, TablePagination, Step } from '@material-ui/core';

import { createGuid } from '../../../functions/generateGuid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import getColor from '../../../functions/getColor';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
import APIParameters from './APIParameters';
import GetOrgAPIKey from '../../../API/Administration/Organisation/GetOrgAPIKey';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import SideMenuOptionAPI from '../../../Layout/SideMenu/SideMenuOptionAPI';
import APILibraryCARIconMenu from '../../../CustomIcons/APILibraryCARIconMenu';
import APILibraryCSRIconMenu from '../../../CustomIcons/APILibraryCSRIconMenu';
import APILibraryPRIconMenu from '../../../CustomIcons/APILibraryPRIconMenu';
import APILibraryAtRiskIconMenu from '../../../CustomIcons/APILibraryAtRiskIconMenu';
import APILibraryWRIconMenu from '../../../CustomIcons/APILibraryWRIconMenu';
import APILibraryQHRIconMenu from '../../../CustomIcons/APILibraryQHRIconMenu';
import APILibrarySRIconMenu from '../../../CustomIcons/APILibrarySRIconMenu';
import { OrgAPIList } from '../../../globalTypes';
import DeleteIcon from '../../../CustomIcons/DeleteIcon';
import GetOrgAPIList from '../../../API/Administration/Organisation/GetOrgAPIList';
import getRelevantUnitTree from '../../../API/GetRelevantUnitTree';
import getCourseListByOrganisation from '../../../API/GetCourseListByOrganisation';
import getClassificationType from '../../../API/GetClassificationType';
import getClassificationListByType from '../../../API/GetClassificationListByType';
import SaveAPILibraryLogs from '../../../API/Administration/Organisation/SaveAPILibraryLogs';
import getPastCourseListByOrganisation from '../../../API/GetPastCourseListByOrganisation';
import GetOrgAPIListByLogID from '../../../API/Administration/Organisation/GetOrgAPIListByLogID';
import APILibraryIconMenu from '../../../CustomIcons/NavigationMenu/APILibraryIconMenu';
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import ToggleLeftIcon from '../../../CustomIcons/ToggleLeftIcon';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'white',
    },
  },
})((props: any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme: any) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: '18px',
      background: '#E0E0E0',
      color: '#21394F',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px 8px 0px 0px',
    },
    selected: {
      backgroundColor: '#FFF',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
      color: '#21394F !important',
    }
  }),
)((props: any) => <Tab disableRipple {...props} />);


const APILibraryDetails = (props: any): JSX.Element => {
  const location = useLocation<any>();
  const { color } = useContext(CorperateThemeContext).theme;
  const { reportname } = useParams<{ reportname: string }>();
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const [loading, setLoading] = useState<boolean>(true);

  const [orgAPIList, setOrgAPIList] = useState<Array<OrgAPIList>>([]);
  const [orgAPIListByLogID, setOrgAPIListByLogID] = useState<Array<OrgAPIList>>([]);
  const [orgAPIListEndpointURLView, setOrgAPIListEndpointURLView] = useState<Array<OrgAPIList>>([]);
  const [deletedlinkID, setDeletedlinkID] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [showConfirmModalDelete, setshowConfirmModalDelete] = useState<boolean>(false);

  const [enableRowBanding] = React.useState<boolean>(true);
  const { setTitle, setTitleEnglish, superAdminSelectedBasicOrgData, sideMenuOpen } = useContext(UIContext);

  const { oidcUser } = useReactOidc();

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";


  const [requestBodyLogId, setRequestBodyLogId] = React.useState<number>(0);
  const [orgAPIKey, setOrgAPIKey] = React.useState<string>("");
  const [selectedOrgDomainName, setSelectedOrgDomainName] = useState<string>("");
  const [selectedOrgId, setSelectedOrgId] = React.useState<string>("");
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  //car


  //car

  const [inputLogId, setInputLogId] = useState<number>(props?.location?.state?.inputLogId ?? 0)
  const [allowLearnerInDays, setAllowLearnerInDays] = useState("1");

  const [allowtodisplaykey, setAllowtodisplaykeyt] = useState<boolean>(false);

  const [tabValue, setTabValue] = useState(0);
  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`account-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} className={styles.tabContainer} >
            <Typography component={"div"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".APIMenu",
        title: allResourcesToTranslations.apiparameterstitle1,
        intro: allResourcesToTranslations.apiparameters1,
      },
      {
        element: ".APIOverview",
        title: allResourcesToTranslations.apiparameterstitle2,
        intro: allResourcesToTranslations.apiparameters2,
      },
      {
        element: ".ClientInformation",
        title: allResourcesToTranslations.apiparameterstitle3,
        intro: allResourcesToTranslations.apiparameters3,
      },
      {
        element: ".APIMethod",
        title: allResourcesToTranslations.apiparameterstitle4,
        intro: allResourcesToTranslations.apiparameters4,
      },
      {
        element: ".APIParameters",
        title: allResourcesToTranslations.apiparameterstitle5,
        intro: allResourcesToTranslations.apiparameters5,
      },
      {
        element: ".APIResponse",
        title: allResourcesToTranslations.apiparameterstitle6,
        intro: allResourcesToTranslations.apiparameters6,
      }
    ],
    Step: [
      {
        element: ".APIID",
        title: allResourcesToTranslations.apioverviewtitle1,
        intro: allResourcesToTranslations.apiDetailoverview1,
      },
      {
        element: ".APIName",
        title: allResourcesToTranslations.apioverviewtitle2,
        intro: allResourcesToTranslations.apiDetailoverview2,
      },
      {
        element: ".APIDate",
        title: allResourcesToTranslations.apioverviewtitle3,
        intro: allResourcesToTranslations.apiDetailoverview3,
      },
      {
        element: ".APICreatedBy",
        title: allResourcesToTranslations.apioverviewtitle4,
        intro: allResourcesToTranslations.apiDetailoverview4,
      },
      {
        element: ".APIView",
        title: allResourcesToTranslations.apioverviewtitle5,
        intro: allResourcesToTranslations.apiDetailoverview5,
      },
      {
        element: ".APIDelete",
        title: allResourcesToTranslations.apioverviewtitle6,
        intro: allResourcesToTranslations.apiDetailoverview6,
      }
    ]
  });
  useEffect(() => {
    let updateSteps = [...intro.steps];

    setIntro({ ...intro, steps: [...updateSteps] });
  }, []);

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.apilibrary)
    setTitleEnglish(pageTitles.apilibrary)
    setLoading(false);
    loadOrgAPIKey();
    loadOrgAPIList(reportname);
    if (!!props?.location?.state?.inputLogId) {
      loadOrgAPIByLogId(reportname, inputLogId);
    }
    return () => { };
  }, []);

  const handleTabViewChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSelection = (reportname: string) => {
    setInputLogId(0);
    setOrgAPIList([]);
    loadOrgAPIList(reportname);
    setOrgAPIListByLogID([]);
    setDeletedlinkID(0);
  };

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }

  const handleBackButtonclick = () => {
    history.push("/apilibrary");
  }

  const loadOrgAPIKey = () => {
    GetOrgAPIKey(oidcUser.access_token, userId, orgDomain, parseInt(orgId),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
        if (!!result && result.isSuccess) {
          if (result.orgAPIKey !== null) {
            setOrgAPIKey(result.orgAPIKey[0].apiKey);
            setSelectedOrgDomainName(result.orgAPIKey[0].organisationDomainName);
            setSelectedOrgId(result.orgAPIKey[0].organisationID);
            setLoading(false);
          }
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Can not load organisation API Secret Key!");
        }
        setLoading(false);
      });

  };

  const loadOrgAPIList = (reportname: string) => {
    GetOrgAPIList(oidcUser.access_token, userId, orgDomain, parseInt(orgId), reportname).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.orgAPILogList !== null) {
          setOrgAPIList([...result.orgAPILogList]);
        }
      }
      setLoading(false);
    });
  }
  const loadOrgAPIByLogId = (reportname: string, LogId: number) => {
    GetOrgAPIListByLogID(oidcUser.access_token, userId, orgDomain, parseInt(orgId), reportname, LogId).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.orgAPILogList !== null) {
          setOrgAPIListByLogID([...result.orgAPIByLogID]);
        }
      }
      setLoading(false);
    });
  }

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (index: number) => {
    handleConfirmModalDelete(true);
    setDeletedlinkID(index);
  }

  const updateSelectedUnit = (units: any, id: string) => {
    let updatedUnits = units
    const updateSelected = (unitArray: any) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
        const unit = unitArray[i];
        if (unit.unitID == id) {
          unit.isSelected = !unit.isSelected
        }
        updateSelected(unit.childUnitTreeItems)
      }
    }
    updateSelected(updatedUnits)
    return updatedUnits;
  }

  const updateSelectedCourse = (courses: any, id: number) => {
    let updatedCourses = courses
    for (let i = 0; i < updatedCourses.length; i++) {
      if (updatedCourses[i].courseID == id) {
        updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
      }
    }
    return updatedCourses
  }

  const updateSelectedPastCourse = (courses: any, id: number) => {
    let updatedPastCourses = courses
    for (let i = 0; i < updatedPastCourses.length; i++) {
      if (updatedPastCourses[i].courseID == id) {
        updatedPastCourses[i].isSelected = !updatedPastCourses[i].isSelected;
      }
    }
    return updatedPastCourses
  }
  const handlePreviewAPI = (logID: number) => {
    setLoading(true);
    GetOrgAPIListByLogID(oidcUser.access_token, userId, orgDomain, parseInt(orgId), reportname, logID).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        if (result.orgAPILogList !== null) {
          //var newList = result.orgAPILogList.filter((item:any) => item.logId === data.logId);
          var newList = result.orgAPIByLogID;
          newList.forEach((item: any) => {
            setInputLogId(item.logId);
            let selectedUnits = (item.paramUnitIDs !== null && item.paramUnitIDs !== undefined && item.paramUnitIDs != "") ? item.paramUnitIDs.split(",") : [];
            let selectedCourses = (item.paramCourseIDs !== null && item.paramCourseIDs !== undefined && item.paramCourseIDs != "") ? item.paramCourseIDs.split(",") : [];
            let selectedPastCourses = (item.paramHistoricCourseIDs !== null && item.paramHistoricCourseIDs !== undefined && item.paramHistoricCourseIDs != "") ? item.paramHistoricCourseIDs.split(",") : [];

            let updatedDataLocal: any = [];
            let updatedCourseData: any = [];
            let updatedPastCourseData: any = [];
            let groupingOption = false;
            let classificationdata: any = [];
            getRelevantUnitTree(
              oidcUser.access_token,
              userId,
              orgDomain,
              true,
              parseInt(orgId)
            ).then((unitTreeRes: any) => {
              if (unitTreeRes !== null && unitTreeRes !== undefined && unitTreeRes.isSuccess) {
                if (selectedUnits.length > 0) {
                  updatedDataLocal = selectedUnits.map((item: any) => {
                    return updateSelectedUnit(unitTreeRes.unitTreeItems, item)
                  })
                }

                getCourseListByOrganisation(
                  oidcUser.access_token,
                  userId,
                  orgDomain,
                  parseInt(orgId),
                  true
                ).then((courseListRes) => {
                  if (courseListRes !== null && courseListRes !== undefined && courseListRes.isSuccess) {
                    if (selectedCourses.length > 0) {
                      updatedCourseData = selectedCourses.map((item: any) => {
                        return updateSelectedCourse(courseListRes.courselistItem, item)
                      })
                    }

                    getPastCourseListByOrganisation(
                      oidcUser.access_token,
                      userId,
                      orgDomain,
                      parseInt(orgId)
                    ).then((pastcourseListRes: any) => {
                      if (pastcourseListRes !== null && pastcourseListRes !== undefined && pastcourseListRes.isSuccess) {
                        if (selectedPastCourses.length > 0) {
                          updatedPastCourseData = selectedPastCourses.map((item: any) => {
                            return updateSelectedPastCourse(pastcourseListRes.pastCourseListItems, item)
                          })
                        }

                        getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {

                          if (classType !== null && classType.classificationTypeIDs !== null &&
                            classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                            getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {

                              if (data !== null && data.classificationlistItem !== null) {
                                classificationdata = data.classificationlistItem;
                                groupingOption = true;
                              }
                              else {
                                groupingOption = false;
                              }

                              history.push({
                                //pathname: `/${item.apiName}`,
                                pathname: `/${item.apiName == "HistoricAdminReport" ? "CurrentAdminReport" : item.apiName}`,
                                state: {
                                  selectedUnits: updatedDataLocal.length > 0 ? updatedDataLocal[selectedUnits.length - 1] : [],
                                  selectedCourses: updatedCourseData.length > 0 ? updatedCourseData[selectedCourses.length - 1] : [],
                                  selectedPastCourses: updatedPastCourseData.length > 0 ? updatedPastCourseData[selectedPastCourses.length - 1] : [],
    
                                  inputFilterFirstName: item.paramFirstName,
                                  inputFilterLastName: item.paramLastName,
                                  inputFilterEmail: item.paramEmail,
                                  inputFilterUserName: item.paramUserName,
    
                                  groupingOption: classificationdata == null ? false : groupingOption,
                                  classificationList: classificationdata == null ? [] : classificationdata,
                                  selectedClassification: classificationdata == null ? { classificationID: 0, classificationTypeID: 0, value: 0 } : { classificationID: item.paramClassificationId, classificationTypeID: item.paramClassificationId, value: item.paramClassificationId },
                                  selectedClassificationID: classificationdata == null ? 0 : item.paramClassificationId,
    
                                  //CAR
                                  inputHistoricData: item.paramIsHistoricReport,
                                  //CAR
    
                                  //CSR
                                  inputFilterDateFrom: item.paramDateFrom,
                                  inputFilterDateTo: item.paramDateTo,
                                  inputFilterCourseStatus: item.paramCourseStatus,
                                  inputFilterIncludeInactive: item.paramIncludeInactive,
                                  //CSR
                                  //QHR
                                  inputFilterIncludeHistoricCourse: item.paramIncludeHistoricCourse,
                                  //QHR
                                  //WR
                                  inputFilterWarningPeriod: item.paramWarningPeriod !== "" ? item.paramWarningPeriod : 30,
                                  inputFilterWarningUnit: item.paramWarningUnit !== "" ? item.paramWarningUnit : "dd",
                                  //WR                                                                      
                                  //ATR
                                  inputFilterFailCounter: item.paramFailCounter !== "" ? item.paramFailCounter : 0,
                                  //ATR
                                },
                              });

                            })
                          }
                          else {
                            groupingOption = false;
                            history.push({
                              //pathname: `/${item.apiName}`,
                              pathname: `/${item.apiName == "HistoricAdminReport" ? "CurrentAdminReport" : item.apiName}`,
                              state: {
                                selectedUnits: updatedDataLocal.length > 0 ? updatedDataLocal[selectedUnits.length - 1] : [],
                                selectedCourses: updatedCourseData.length > 0 ? updatedCourseData[selectedCourses.length - 1] : [],
                                selectedPastCourses: updatedPastCourseData.length > 0 ? updatedPastCourseData[selectedPastCourses.length - 1] : [],
  
                                inputFilterFirstName: item.paramFirstName,
                                inputFilterLastName: item.paramLastName,
                                inputFilterEmail: item.paramEmail,
                                inputFilterUserName: item.paramUserName,
  
                                groupingOption: classificationdata == null ? false : groupingOption,
                                classificationList: classificationdata == null ? [] : classificationdata,
                                selectedClassification: classificationdata == null ? { classificationID: 0, classificationTypeID: 0, value: 0 } : { classificationID: item.paramClassificationId, classificationTypeID: item.paramClassificationId, value: item.paramClassificationId },
                                selectedClassificationID: classificationdata == null ? 0 : item.paramClassificationId,
  
                                //CAR
                                inputHistoricData: item.paramIsHistoricReport,
                                //CAR
  
                                //CSR
                                inputFilterDateFrom: item.paramDateFrom,
                                inputFilterDateTo: item.paramDateTo,
                                inputFilterCourseStatus: item.paramCourseStatus,
                                inputFilterIncludeInactive: item.paramIncludeInactive,
                                //CSR
                                //QHR
                                inputFilterIncludeHistoricCourse: item.paramIncludeHistoricCourse,
                                //QHR
                                //WR
                                inputFilterWarningPeriod: item.paramWarningPeriod !== "" ? item.paramWarningPeriod : 30,
                                inputFilterWarningUnit: item.paramWarningUnit !== "" ? item.paramWarningUnit : "dd",
                                //WR                                                                      
                                //ATR
                                inputFilterFailCounter: item.paramFailCounter !== "" ? item.paramFailCounter : 0,
                                //ATR
                              },
                            });
                          }                         

                        })
                      }
                    });
                  }
                })

              }
            })

          });
        }
      }

    });
  }
  const handleViewProperties = (RequestBodyLogId: number) => {
    setRequestBodyLogId(RequestBodyLogId);
    loadOrgAPIByLogId(reportname, RequestBodyLogId);
    let element = document.getElementById("RequestBodyDatalist");
    if (!!element) {
      element.scrollIntoView();
    }
  }

  const handleConfirmModalDelete = (value: boolean) => {
    setshowConfirmModalDelete(value);
  }
  const handleCancelConfirmPopupDelete = () => {
    handleConfirmModalDelete(false);

  }
  const handleProceedDelete = () => {
    handleConfirmModalDelete(false);

    SaveAPILibraryLogs(oidcUser.access_token, userId, orgDomain, parseInt(orgId), reportname, "", "delete", deletedlinkID).then((result) => {
      if (result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        loadOrgAPIList(reportname);
        setModalPopupMessage("API has been deleted successfully!");
       
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("API deleted failed!");
      }
    });
    setOrgAPIListByLogID([]);
    setDeletedlinkID(0);
    setOrgAPIListEndpointURLView([]);
  }


  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={tabValue == 0 ? intro.steps : intro.Step}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: 'Next',
          prevLabel: 'Back',
          tooltipClass: 'sample-class',
          showBullets: true,
        }}
      />
      <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
        <span style={{ right: "105px" }} onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <div className={styles.backButtonSpecific}>
        <CustomTooltip displayText={[allResourcesToTranslations.back]}>
          <Button
            onClick={() => handleBackButtonclick()}
            variant="contained"
            color="primary"
            disabled={false}
            className="back"
          >
            <ArrowBackIcon />
          </Button>
        </CustomTooltip>
      </div>

      <Modal modalOpen={showConfirmModalDelete} setModalOpen={handleConfirmModalDelete} type="warning">
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
          <div className={styles.inputContainer}>
            <p>
              Are you sure you want to delete this API?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelConfirmPopupDelete}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleProceedDelete}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <div className={styles.reportWrraper} style={{ width: '100%' }}>
        <div style={{ padding: '20px' }}>
          <div className={`${responsivestyles.rowrule}`} style={{ width: '100%' }} >
            <div className={`${responsivestyles.columnrule_2} `} >
              <div style={{ paddingRight: '30px', paddingTop: '20px' }}>
                <div style={{ background: '#ffffff', borderRadius: '10px', padding: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)', marginBottom: '20px' }}
                  className="APIMenu">
                  <SideMenuOptionAPI
                    icon={<APILibraryCARIconMenu />}
                    optionName={"Current Admin Report"}
                    displayName={allResourcesToTranslations.currentadminreport}
                    isSelected={reportname === "CurrentAdminReport"}
                    customClickEvent={() => handleSelection('CurrentAdminReport')}
                    sideMenuOpen={sideMenuOpen}
                    route="/apilibrarydetail/CurrentAdminReport"
                    isNested
                    key={createGuid()}
                  />
                  <SideMenuOptionAPI
                    icon={<APILibraryCSRIconMenu />}
                    optionName={"Course Status Report"}
                    displayName={allResourcesToTranslations.coursestatusreport}
                    isSelected={reportname === "CourseStatusReport"}
                    customClickEvent={() => handleSelection("CourseStatusReport")}
                    sideMenuOpen={sideMenuOpen}
                    route="/apilibrarydetail/CourseStatusReport"
                    isNested
                    key={createGuid()}
                  />
                  <SideMenuOptionAPI
                    icon={<APILibraryPRIconMenu />}
                    optionName={"Progress Report"}
                    displayName={allResourcesToTranslations.progressreport}
                    isSelected={reportname === "ProgressReport"}
                    customClickEvent={() => handleSelection("ProgressReport")}
                    sideMenuOpen={sideMenuOpen}
                    route="/apilibrarydetail/ProgressReport"
                    isNested
                    key={createGuid()}
                  />
                  <SideMenuOptionAPI
                    icon={<APILibrarySRIconMenu />}
                    optionName={"Summary Report"}
                    displayName={allResourcesToTranslations.summaryreport}
                    isSelected={reportname === "SummaryReport"}
                    customClickEvent={() => handleSelection("SummaryReport")}
                    sideMenuOpen={sideMenuOpen}
                    route="/apilibrarydetail/SummaryReport"
                    isNested
                    key={createGuid()}
                  />
                  <SideMenuOptionAPI
                    icon={<APILibraryQHRIconMenu />}
                    optionName={"Quiz History Report"}
                    displayName={allResourcesToTranslations.quizhistoryreport}
                    isSelected={reportname === "QuizHistoryReport"}
                    customClickEvent={() => handleSelection("QuizHistoryReport")}
                    sideMenuOpen={sideMenuOpen}
                    route="/apilibrarydetail/QuizHistoryReport"
                    isNested
                    key={createGuid()}
                  />
                  <SideMenuOptionAPI
                    icon={<APILibraryWRIconMenu />}
                    optionName={"Warning Report"}
                    displayName={allResourcesToTranslations.warningreport}
                    isSelected={reportname === "WarningReport"}
                    customClickEvent={() => handleSelection("WarningReport")}
                    sideMenuOpen={sideMenuOpen}
                    route="/apilibrarydetail/WarningReport"
                    isNested
                    key={createGuid()}
                  />
                  <SideMenuOptionAPI
                    icon={<APILibraryAtRiskIconMenu />}
                    optionName={"At Risk Report"}
                    displayName={allResourcesToTranslations.atriskreport}
                    isSelected={reportname === "AtRiskReport"}
                    customClickEvent={() => handleSelection("AtRiskReport")}
                    sideMenuOpen={sideMenuOpen}
                    route="/apilibrarydetail/AtRiskReport"
                    isNested
                    key={createGuid()}
                  />
                </div>
              </div>
            </div>
            <div className={`${responsivestyles.columnrule_10}`} >
              {(reportname) == 'CurrentAdminReport' &&
                <h2>{allResourcesToTranslations.currentadminreport}</h2>
              }
              {(reportname) == 'CourseStatusReport' &&
                <h2>{allResourcesToTranslations.coursestatusreport}</h2>
              }
              {(reportname) == 'ProgressReport' &&
                <h2>{allResourcesToTranslations.progressreport}</h2>
              }
              {(reportname) == 'SummaryReport' &&
                <h2>{allResourcesToTranslations.summaryreport}</h2>
              }
              {(reportname) == 'QuizHistoryReport' &&
                <h2>{allResourcesToTranslations.quizhistoryreport}</h2>
              }
              {(reportname) == 'WarningReport' &&
                <h2>{allResourcesToTranslations.warningreport}</h2>
              }
              {(reportname) == 'AtRiskReport' &&
                <h2>{allResourcesToTranslations.atriskreport}</h2>
              }
              <AppBar position="static" color="default">
                <StyledTabs
                  variant="fullWidth"
                  value={tabValue}
                  onChange={handleTabViewChange}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  aria-label="Organisation Email Templates">
                  <StyledTab label="Parameters" />
                  <StyledTab label="Overview" className="APIOverview" />
                </StyledTabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <div>
                  <br></br>
                  <BrandedCard>
                    <div className={`${styles.tableContentDescription} ClientInformation`} >
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="Caption"
                                style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 8px 0 0" }}
                                align="left" colSpan={2}>
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    {"Client Information" /* TODO translate */}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow style={rowBackgroundColor(0)} >
                            <TableCell align="left" width={"50%"}  colSpan={2}>  
                                <div className='allowtodisplaykey'>
                                  <div onClick={() => setAllowtodisplaykeyt(!allowtodisplaykey)}>
                                    {allowtodisplaykey &&
                                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                                    }
                                    {!allowtodisplaykey &&
                                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                                    }
                                  </div>
                                  <div className={styles.inputContainer}>
                                    <span style={{fontWeight:'bold'}} onClick={() => setAllowtodisplaykeyt(!allowtodisplaykey)}>
                                      {allowtodisplaykey?'Hide Secret key' : 'Show Secret key'}</span>
                                  </div>
                                  </div>                               
                              </TableCell>
                              </TableRow>
                            <TableRow style={rowBackgroundColor(0)} >
                              <TableCell align="left" width={"50%"} >                               
                                <div className={styles.inputContainer}>                                    
                                      <TextField
                                      className={styles.input}
                                      label={"Secret key" /* TODO translate */}
                                      name="apitoken"
                                      variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      value={orgAPIKey}
                                      type= {allowtodisplaykey ? 'text' : 'password'}
                                      disabled
                                      // onChange={(e) => setApitoken(e.target.value)}
                                      placeholder={allResourcesToTranslations.requiredfield} />                                  
                                </div>                                
                              </TableCell>
                              <TableCell align="left" width={"50%"}>    
                                   <div className={styles.inputContainer}>                               
                                    <TextField 
                                      className={styles.input}
                                      label={"OrganisationId" /* TODO translate */}
                                      name="apitoken"
                                      variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                      value={selectedOrgId}
                                      disabled
                                      placeholder={allResourcesToTranslations.requiredfield} />  
                                    </div>                             
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </BrandedCard>

                  {(inputLogId == 0) &&
                    <>
                      <br></br>
                      <BrandedCard>
                        <div className={`${styles.tableContentDescription} APIMethod`}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="Caption"
                                    style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                                    align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                      <div>
                                        {"API Name" /* TODO translate */}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="Caption"
                                    style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                    align="left" >
                                    <div className={styles.tableHeadCellContainer}>
                                      <div>
                                        {"HTTP Method" /* TODO translate */}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="Caption"
                                    style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                    align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                      <div>
                                        {"Endpoint URL" /* TODO translate */}
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow style={rowBackgroundColor(0)}>
                                  <TableCell align="left" width={"25%"}>
                                    {reportname.toLowerCase()}
                                  </TableCell>
                                  <TableCell align="left" width={"25%"}>
                                    <span style={{ color: 'green' }}>GET/</span><br></br>
                                    or<br></br>
                                    <span style={{ color: 'green' }}>POST/</span>
                                  </TableCell>
                                  <TableCell align="left" width={"25%"}>
                                    <span><b> Generate Endpoint URL for  <Link style={{ color: '#63C2FF', textDecoration: 'underline' }} to={`/${reportname}`}>{reportname}</Link></b></span>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </BrandedCard>
                    </>
                  }

                  {(inputLogId > 0) &&
                    <> 
                      {orgAPIListByLogID != null && orgAPIListByLogID.length > 0 &&
                        <>
                          <div className={styles.mainWrapper}>
                          
                            {(orgAPIListByLogID.length > 0) && 
                              <div className={styles.tableSection}>
                                <BrandedCard>
                                  <TableContainer>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className="API Name"
                                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                                            align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                              <div>
                                                {"API Name"/* TODO translate */}
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell className="HTTP Method"
                                            style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                            align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                              <div >
                                                {"HTTP Method" /* TODO translate */}
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell className="Endpoint URL"
                                            style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                                            align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                              <div >
                                                {"Endpoint URL" /* TODO translate */}
                                              </div>
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {orgAPIListByLogID.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                          .map((result: any, j) => {
                                            return (
                                              <><TableRow style={rowBackgroundColor(j)}>
                                                <TableCell align="left" width={"25%"}>
                                                  <span>{result.apiName}</span>
                                                </TableCell>
                                                <TableCell align="left" width={"25%"}>
                                                  <span style={{ color: 'green' }}>GET/</span>
                                                </TableCell>
                                                <TableCell align="left" width={"50%"}>
                                                  <span>{process.env.REACT_APP_API_URL}/apilibrary/{reportname.toLowerCase()}/{result.logID}/{result.uniqueID}</span>
                                                </TableCell>
                                              </TableRow>
                                                <TableRow>
                                                  <TableCell align="left" width={"25%"}>
                                                    <span>{result.apiName}</span>
                                                  </TableCell>
                                                  <TableCell align="left" width={"25%"}>
                                                    <span style={{ color: 'green' }}>POST/</span>
                                                  </TableCell>
                                                  <TableCell align="left" width={"50%"} style={{ wordBreak: "break-all" }}>
                                                    <span>{process.env.REACT_APP_API_URL}/apilibrary/{reportname.toLowerCase()}</span>
                                                    <br></br><br></br>
                                                    <span>{result.requestBody}</span>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow style={rowBackgroundColor(j)}>
                                                  <TableCell align="left">
                                                    Header
                                                  </TableCell>
                                                  <TableCell align="left" style={{ wordBreak: "break-all" }}>
                                                    Example:<br></br>
                                                    Content-Type: application/json<br></br>
                                                    OrgId: {selectedOrgId} <br></br>
                                                    {allowtodisplaykey ? <span>XApiKey:{orgAPIKey} </span>: ''}<br></br>                                             
                                                  </TableCell>
                                                  <TableCell>
                                                  <div className='allowtodisplaykey'>
                                                      <div onClick={() => setAllowtodisplaykeyt(!allowtodisplaykey)}>
                                                        {allowtodisplaykey &&
                                                          <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                                                        }
                                                        {!allowtodisplaykey &&
                                                          <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                                                        }
                                                      </div>
                                                      <div className={styles.inputContainer}>
                                                        <span style={{fontWeight:'bold'}} onClick={() => setAllowtodisplaykeyt(!allowtodisplaykey)}>
                                                          {allowtodisplaykey?'Hide Secret key' : 'Show Secret key'}</span>
                                                      </div>
                                                      </div>  
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            );
                                          })}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </BrandedCard>
                              </div>
                            }
                          </div>
                        </>
                        }    
                    </>
                  }
                  <br></br>
                  <div className="APIParameters">
                    {reportname == 'CurrentAdminReport' &&
                      <APIParameters templates="CurrentAdminReport" type="Parameters"></APIParameters>
                    }
                    {reportname == 'CourseStatusReport' &&
                      <APIParameters templates="CourseStatusReport" type="Parameters"></APIParameters>
                    }
                    {reportname == 'ProgressReport' &&
                      <APIParameters templates="ProgressReport" type="Parameters"></APIParameters>
                    }
                    {reportname == 'SummaryReport' &&
                      <APIParameters templates="SummaryReport" type="Parameters"></APIParameters>
                    }
                    {reportname == 'QuizHistoryReport' &&
                      <APIParameters templates="QuizHistoryReport" type="Parameters"></APIParameters>
                    }
                    {reportname == 'WarningReport' &&
                      <APIParameters templates="WarningReport" type="Parameters"></APIParameters>
                    }
                    {reportname == 'AtRiskReport' &&
                      <APIParameters templates="AtRiskReport" type="Parameters"></APIParameters>
                    }
                  </div>
                  <br></br>
                  <div className="APIResponse">
                    {reportname == 'CurrentAdminReport' &&
                      <APIParameters templates="CurrentAdminReport" type="Response"></APIParameters>
                    }
                    {reportname == 'CourseStatusReport' &&
                      <APIParameters templates="CourseStatusReport" type="Response"></APIParameters>
                    }
                    {reportname == 'ProgressReport' &&
                      <APIParameters templates="ProgressReport" type="Response"></APIParameters>
                    }
                    {reportname == 'SummaryReport' &&
                      <APIParameters templates="SummaryReport" type="Response"></APIParameters>
                    }
                    {reportname == 'QuizHistoryReport' &&
                      <APIParameters templates="QuizHistoryReport" type="Response"></APIParameters>
                    }
                    {reportname == 'WarningReport' &&
                      <APIParameters templates="WarningReport" type="Response"></APIParameters>
                    }
                    {reportname == 'AtRiskReport' &&
                      <APIParameters templates="AtRiskReport" type="Response"></APIParameters>
                    }
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1} >
                {orgAPIList != null && orgAPIList.length > 0 &&
                  <>
                  <div><b>Click on API Name to view API Details</b></div>
                    <div className={styles.mainWrapper}>
                      <div style={{ width: '100%', clear: "both" }} >
                        <div>
                          <div className={styles.paginationControlsContainer}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 100]}
                                component="div"
                                count={orgAPIList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </div>
                          </div>
                          <div className={`${styles.tableSection}`}>
                            <div className={styles.tableContainer}>
                              <BrandedCard>
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="APIID"
                                          style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                                          align="left">
                                          <div className={styles.tableHeadCellContainer}>
                                            <div>
                                              {"LogID"/* TODO translate */}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className="APIName"
                                          style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                          align="left">
                                          <div className={styles.tableHeadCellContainer}>
                                            <div >
                                              {"API Name" /* TODO translate */}
                                            </div>
                                          </div>
                                        </TableCell>

                                        <TableCell className="APIDate"
                                          style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                                          align="left">
                                          <div className={styles.tableHeadCellContainer}>
                                            <div>
                                              {"Date Created" /* TODO translate */}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className="APICreatedBy"
                                          style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                                          align="left">
                                          <div className={styles.tableHeadCellContainer}>
                                            <div>
                                              {"Created By" /* TODO translate */}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className="APIView"
                                          style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                                          align="left">
                                          <div className={styles.tableHeadCellContainer}>
                                            <div>
                                              {"View Endpoint URL" /* TODO translate */}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell className="APIDelete"
                                          style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px", borderRadius: "0 8px 0 0" }}
                                          align="left">
                                          <div className={styles.tableHeadCellContainer}>
                                            <div>
                                              {"Delete" /* TODO translate */}
                                            </div>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {orgAPIList.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                      )
                                        .map((result: any, i) => {
                                          return (

                                            <TableRow style={rowBackgroundColor(i)}>
                                              <TableCell align="left" width={"5%"}>
                                                <span>{result.logID}</span>
                                              </TableCell>
                                              <TableCell align="left" width={"10%"}>
                                                <CustomTooltip displayText={[allResourcesToTranslations.clicktoviewdetails]}>
                                                  <a onClick={() => handlePreviewAPI(result.logID)} className={styles.titleLink}>{result.apiName}</a>
                                                </CustomTooltip>
                                              </TableCell>
                                              <TableCell align="left" width={"5%"}>
                                                <span>{result.dateCreated}</span>
                                              </TableCell>
                                              <TableCell align="left" width={"5%"}>
                                                <span>{result.createdByName}</span>
                                              </TableCell>
                                              <TableCell align="center" width={"5%"}>
                                                <CustomTooltip displayText={["View Details"]}>
                                                  <Button className="ruledelete" onClick={() => handleViewProperties(result.logID)} variant="contained" style={{ backgroundColor: "red" }}>
                                                    <APILibraryIconMenu />
                                                  </Button>
                                                </CustomTooltip>
                                              </TableCell>

                                              <TableCell align="left" width={"5%"}>
                                                <CustomTooltip displayText={["Delete"]}>
                                                  <Button className="ruledelete" onClick={() => handleDelete(result.logID)} variant="contained" style={{ backgroundColor: "red" }}>
                                                    <DeleteIcon />
                                                  </Button>
                                                </CustomTooltip>
                                              </TableCell>
                                            </TableRow>

                                          );
                                        })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </BrandedCard>

                            </div>
                          </div>
                          <div className={styles.paginationAddedUsersControl}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 100]}
                                component="div"
                                count={orgAPIList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {(orgAPIListByLogID.length > 0) && requestBodyLogId > 0 &&
                        <div className={styles.tableSection}>
                          <BrandedCard>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="API Name"
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer}>
                                        <div>
                                          {"API Name"/* TODO translate */}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell className="HTTP Method"
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer}>
                                        <div >
                                          {"HTTP Method" /* TODO translate */}
                                        </div>
                                      </div>
                                    </TableCell>
                                    <TableCell className="Endpoint URL"
                                      style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                                      align="left">
                                      <div className={styles.tableHeadCellContainer}>
                                        <div >
                                          {"Endpoint URL" /* TODO translate */}
                                        </div>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {orgAPIListByLogID.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                    .map((result: any, j) => {
                                      return (
                                        <><TableRow style={rowBackgroundColor(j)}>
                                          <TableCell align="left" width={"25%"}>
                                          <CustomTooltip displayText={[allResourcesToTranslations.clicktoviewdetails]}>
                                                  <a onClick={() => handlePreviewAPI(result.logID)} className={styles.titleLink}>{result.apiName}</a>
                                                </CustomTooltip>
                                          </TableCell>
                                          <TableCell align="left" width={"25%"}>
                                            <span style={{ color: 'green' }}>GET/</span>
                                          </TableCell>
                                          <TableCell align="left" width={"50%"}>
                                            <span>{process.env.REACT_APP_API_URL}/apilibrary/{reportname.toLowerCase()}/{result.logID}/{result.uniqueID}</span>
                                          </TableCell>
                                        </TableRow>
                                          <TableRow>
                                            <TableCell align="left" width={"25%"}>
                                            <CustomTooltip displayText={[allResourcesToTranslations.clicktoviewdetails]}>
                                                  <a onClick={() => handlePreviewAPI(result.logID)} className={styles.titleLink}>{result.apiName}</a>
                                                </CustomTooltip>
                                            </TableCell>
                                            <TableCell align="left" width={"25%"}>
                                              <span style={{ color: 'green' }}>POST/</span>
                                            </TableCell>
                                            <TableCell align="left" width={"50%"} style={{ wordBreak: "break-all" }}>
                                              <span>{process.env.REACT_APP_API_URL}/apilibrary/{reportname.toLowerCase()}</span>
                                              <br></br><br></br>
                                              <span>{result.requestBody}</span>
                                            </TableCell>
                                          </TableRow>
                                          <TableRow style={rowBackgroundColor(j)}>
                                            <TableCell align="left">
                                              Header
                                            </TableCell>
                                            <TableCell align="left" style={{ wordBreak: "break-all" }}>
                                              Example:<br></br>
                                              Content-Type: application/json<br></br>
                                              OrgId: {selectedOrgId} <br></br>
                                              {allowtodisplaykey ? <span>XApiKey:{orgAPIKey} </span>: ''}<br></br>                                             
                                            </TableCell>
                                            <TableCell>
                                            <div className='allowtodisplaykey'>
                                                <div onClick={() => setAllowtodisplaykeyt(!allowtodisplaykey)}>
                                                  {allowtodisplaykey &&
                                                    <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                                                  }
                                                  {!allowtodisplaykey &&
                                                    <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                                                  }
                                                </div>
                                                <div className={styles.inputContainer}>
                                                  <span style={{fontWeight:'bold'}} onClick={() => setAllowtodisplaykeyt(!allowtodisplaykey)}>
                                                    {allowtodisplaykey?'Hide Secret key' : 'Show Secret key'}</span>
                                                </div>
                                                </div>  
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      );
                                    })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </BrandedCard>
                        </div>
                      }

                    </div>
                  </>}
                {(orgAPIList.length == 0) &&
                  <BrandedCard>
                    <div className={styles.noResultsCard}>
                      <div>No Results to Display</div>
                    </div>
                  </BrandedCard>
                }
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", paddingTop: "60px" }} >
        <div id="RequestBodyDatalist">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
      </div>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};
export default APILibraryDetails;
