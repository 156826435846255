import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepFourComplete = () => (    
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#63C2FF"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#63C2FF"/>
<path d="M15.18 27.88C15.18 27.7 15.16 27.62 15.08 27.62L14.62 27.84C14.62 27.74 14.56 27.68 14.46 27.64L14.3 27.62C14.16 27.62 14.1 27.64 13.9 27.76C13.84 27.64 13.76 27.5 13.7 27.38C13.18 26.38 12.64 24.88 12.4 24.22C12.28 23.88 12.16 23.18 12.02 22.12C12.18 22.22 12.3 22.26 12.36 22.26C12.44 22.26 12.54 22.12 12.62 21.84C12.66 21.9 12.74 21.92 12.84 21.92C12.9 21.92 12.98 21.9 13.02 21.84L13.34 21.36L13.7 21.48H13.72C13.76 21.48 13.82 21.42 13.92 21.36C14.02 21.3 14.1 21.26 14.16 21.26L14.22 21.28C14.54 21.44 14.76 21.72 14.86 22.16C15.1 23.18 15.32 23.68 15.58 23.68C15.8 23.68 16.1 23.42 16.42 22.92C16.74 22.42 17.06 21.74 17.42 20.92C17.44 21.08 17.46 21.16 17.5 21.16C17.62 21.16 17.92 20.46 18.66 19.28C19.76 17.5 22.48 14.08 23.18 13.6C23.7 13.24 24.1 12.9 24.38 12.6C24.34 12.8 24.3 12.94 24.3 13C24.3 13.06 24.34 13.08 24.38 13.08L24.94 12.8V12.88C24.94 12.98 24.96 13.04 25.02 13.04C25.1 13.04 25.42 12.72 25.46 12.6L25.42 12.88L26.1 12.48L25.94 12.84C26.14 12.7 26.3 12.62 26.4 12.62C26.5 12.62 26.56 12.78 26.56 12.88C26.56 13.04 26.42 13.26 26.2 13.54C25.96 13.86 25.36 14.48 23.56 16.54C22.78 17.42 19.32 22.12 18.66 23.24L17.42 25.34C16.88 26.24 16.54 26.82 16.36 27.04C16.18 27.26 15.96 27.48 15.7 27.68L15.52 27.58L15.36 27.68L15.18 27.88Z" fill="white"/>
</svg>


);

export default StepFourComplete