import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const APILibraryCARIcon = () => (
<svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4750_6370)">
<path d="M79.5416 72.625V65.7083C79.5392 62.6432 78.5193 59.6659 76.6415 57.2434C74.7636 54.8212 72.1342 53.091 69.1666 52.3246" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M58.7917 72.625V65.7083C58.7917 62.0394 57.3344 58.5209 54.7399 55.9268C52.1458 53.3323 48.6273 51.875 44.9584 51.875H17.2917C13.6229 51.875 10.1043 53.3323 7.51005 55.9268C4.91582 58.5209 3.45837 62.0394 3.45837 65.7083V72.625" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M55.3334 10.8246C58.3089 11.5865 60.9463 13.317 62.8297 15.7434C64.7131 18.1698 65.7353 21.154 65.7353 24.2256C65.7353 27.2972 64.7131 30.2814 62.8297 32.7078C60.9463 35.1342 58.3089 36.8648 55.3334 37.6267" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.125 38.0417C38.7648 38.0417 44.9583 31.8483 44.9583 24.2083C44.9583 16.5684 38.7648 10.375 31.125 10.375C23.485 10.375 17.2916 16.5684 17.2916 24.2083C17.2916 31.8483 23.485 38.0417 31.125 38.0417Z" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_4750_6370">
<rect width="83" height="83" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default APILibraryCARIcon
