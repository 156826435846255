import axios from 'axios';
import { addError } from '../AddError';

export const SaveNewsfeedEntry = async (token: string, userId: number, orgDomain: string, orgId: number, newsfeedId: number, heading: string, subheading: string, articleText: string,
  nfSubscriberIdCsv: string, nfCategoryIdCsv: object, isActiveStatus: boolean, hasCpdPoins: boolean, cpdPoints: number,
  selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = "") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/Newsfeed/SaveNewsfeed`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        RequestingDomain: orgDomain,
        RequestingOrgID: orgId,
        RequestingUserID: userId,
        NewsfeedId: newsfeedId,
        Heading: heading,
        Subheading: subheading,
        ArticleText: articleText,
        NfSubscriberIdCsv: nfSubscriberIdCsv,
        NfCategoryIdCsv: nfCategoryIdCsv,
        IsActiveStatus: isActiveStatus,
        HasCpdPoins: hasCpdPoins,
        CpdPoints: cpdPoints,
        SelectedOrgID: selectedOrgID,
        SelectedOrgDomainName: selectedOrgDomainName, 
        SelectOrgSiteName: selectOrgSiteName
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveNewsfeedEntry.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveNewsfeedEntry.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveNewsfeedEntry.ts", 1)
    }
    return null;
  }
};

export default SaveNewsfeedEntry;