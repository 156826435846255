import React from 'react'
import Button from "@material-ui/core/Button"
import CustomTooltip from '../../Components/CustomTooltip'
import AssessmentIcon from '@material-ui/icons/Assessment';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RefreshIcon from '@material-ui/icons/Refresh';
import ResetIcon from "../../CustomIcons/ResetIcon";
import styles from '../../styles/currentAdminReport.module.css'
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import APILibraryIconMenu from '../../CustomIcons/NavigationMenu/APILibraryIconMenu';

interface Props {
  openReportSchedulerModal: (isOpen: boolean) => any 
  resetAll: () => void
  runReport: Function
  showScheduler?: boolean
  showAPIbutton?: boolean
  runAPIQuery?: Function
}

const ButtonPanel = ({ showScheduler, openReportSchedulerModal, runReport, resetAll,showAPIbutton,runAPIQuery }: Props) => {

    const handleOpenModal = () => {
      openReportSchedulerModal(true)
    }
    const handleOpenAPIModal = () => {
      runAPIQuery && runAPIQuery()
    }
    const handleRunReport = () => {
      runReport()
    }

    return (
        <div className={styles.lowerButtonPanel}>
            <CustomTooltip displayText={["Reset"] /*TODO: Add translations */}>
              <span className="resetAll">
                <Button
                  onClick={resetAll}
                  type="submit"
                  variant="outlined"
                  color="primary"
                  size="small" 
                  id="btnNewSaltResetReport"
                >
                  <ResetIcon />
                  {/* <RefreshIcon/> */}
                </Button>
              </span>
            </CustomTooltip>
            {showScheduler && <CustomTooltip displayText={["Schedule Report"]}>
                <span className="scheduleReport">
                  <Button
                    onClick={handleOpenModal}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    id="btnNewSaltScheduleReport"
                  >
                    <InsertInvitationIcon/>
                    <AssessmentIcon />
                  </Button>

                </span>
              </CustomTooltip>}
              {showAPIbutton && <CustomTooltip displayText={["Generate Report API"]}>
                <span className="apicall">
                  <Button
                    onClick={handleOpenAPIModal}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    id="btnNewSaltAPIReport"
                  >
                    <APILibraryIconMenu/>
                    <AssessmentIcon />
                  </Button>

                </span>
              </CustomTooltip>}
            <CustomTooltip displayText={["Run Report"] /*TODO: Add translations */}>
                <span className="runReport">
                  <Button
                    onClick={handleRunReport}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small" 
                    id="btnNewSaltRunReport"
                  >
                    <PlayArrowIcon />
                    <AssessmentIcon />
                  </Button>
                </span>
              </CustomTooltip>
        </div>
    )
}

export default ButtonPanel
