import React, {useEffect, useState} from 'react'
import styles from '../../styles/sideMenu.module.css';
import { Link } from 'react-router-dom';
import CustomTooltip from '../../Components/CustomTooltip';

interface Props {
    icon: React.ReactNode
    optionName: string
    displayName: string
    isSelected: boolean
    customClickEvent: Function
    sideMenuOpen: boolean
    route?: string
    isNested?: boolean
    className?: string
    isMultiSectionModulePlayer?: boolean
}

export const SideMenuOptionAPI = ({ icon, optionName, displayName, isSelected, customClickEvent, sideMenuOpen, route, isNested, className, isMultiSectionModulePlayer}: Props) => {
 
	useEffect(() => {
	}, [])

    const handleClick = () => {
        customClickEvent(optionName)
    }  


    const baseAccordionStyle = isNested ? (sideMenuOpen? styles.accordionNestedAPI : styles.accordionNestedsmallAPI) : (!!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? styles.accordion_MulSecModPlayer : styles.accordion); //Nested accordion options have a different background colour 
    return (
        <Link to={route? route : ""} onClick={handleClick} className={`${className}`}>
        <div className={ isSelected ? styles.accordionSelectedAPI : baseAccordionStyle } onClick={handleClick} >
            <div className={styles.sideMenuTextIconContainer}>
                { icon }
                <span className={styles.sideMenuText} title={!!displayName &&  displayName.length > 22 ? displayName: ''} style={{ width: sideMenuOpen? "150px" : "0px" }}>
                    { !!displayName ? displayName : "" }
                </span>
            </div>
        </div>
    </Link>

    )
}

export default SideMenuOptionAPI;
