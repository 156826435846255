//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc, getUserManager } from '@axa-fr/react-oidc-context';
import CloseWhiteIcon from "@material-ui/icons/Close";
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/organisationmanagement.module.css";
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import LoaderContainer from '../../../Components/LoaderContainer';
import { Button, Chip, CircularProgress, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import UnbrandedCard from '../../../Components/UnbrandedCard';
import CustomTooltip from '../../../Components/CustomTooltip';
import TinyMCEEditor from '../../../Components/TinyMCEEditorComponent';
import SaveIcon from '../../../CustomIcons/SaveIcon';
import oidcConfig from '../../../configuration';
import getNewsfeedSubscriberList from '../../../API/SuperAdmin/GetNewsfeedSubscriberList';
import GetNfCategories from '../../../API/SuperAdmin/GetNfCategories';
import SaveNewsfeedEntry from '../../../API/SuperAdmin/SaveNewsfeedEntry';
import { OrgNoteResultItem, SaveCategoryDataResult, SentEmailReportResultItem } from '../../../globalTypes';
import GetNewsfeed from '../../../API/SuperAdmin/GetNewsfeed';
import { useHistory, useParams } from 'react-router-dom';
import AssignButtonPanel from '../PolicyBuilderSettings/AssignButtonPanel';
import AssignUnits from '../PolicyBuilderSettings/AssignUnits';
import AccountManagement from '../AccountManagement/AccountManagement';
import UserListSection from '../AccountManagement/UserListSection';
import AssignNewsfeedUnits from '../../../API/Administration/Newsfeed/AssignNewsfeedUnits';
import GetNewsfeedAssignedUserIds from '../../../API/Administration/Newsfeed/GetNewsfeedAssignedUserIds';
import { MyTrainingListContext } from '../../../contexts/MyTrainingListContext';
import AssignNewsfeedUsers from '../../../API/Administration/Newsfeed/AssignNewsfeedUsers';
import ViewNewsfeedAssignedUsers from '../../../API/Administration/Newsfeed/ViewNewsfeedAssignedUsers';
import Modal from '../../../Components/Modal';
import { setTimeout } from 'timers';
import { Steps } from 'intro.js-react';
import GuideIcon from '../../../CustomIcons/GuideIcon';
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import ToggleLeftIcon from '../../../CustomIcons/ToggleLeftIcon';
import CheckIfOrgHasCPDEnabled from '../../../API/Administration/CPDProfiles/CheckIfOrgHasCPDEnabled';
import CloseIcon from '../../../CustomIcons/CloseIcon';
import addError from '../../../API/AddError';

const NewsfeedDetails = (props: any): JSX.Element => {
  const { newsfeedIdToEdit } = useParams<{ newsfeedIdToEdit: string }>();
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
  const { setDashboardUpdateRequired, setTrainingListUpdateRequired } = useContext(MyTrainingListContext);
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, showCPD, setShowCPD, superAdminSelectedBasicOrgData, IsLoggingOut, setIsLoggingOut, EnableActivityLogs} = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser, logout } = useReactOidc();
  const [superAdminDomain, setSuperAdminDomain] = useState<string>(oidcConfig.currentdomainSA);
  const [newsfeedIdToUpdate, setNewsfeedIdToUpdate] = useState(0);
  const [isResettingAcceptances, setIsResettingAcceptances] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [articleName, setArticleName] = useState<string>("");
  const [articleSubheading, setArticleSubheading] = useState<string>("");
  const [articleText, setArticleText] = useState<string>("");
  const [selectedSubscriberGroupId, setSelectedSubscriberGroupId] = useState<number>(-1);
  const [subscriberGroupList, setSubscriberGroupList] = useState<any>([{ value: -1, subscriberGroupId: -1, subscriberGroupName: "" }]);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");

  const [existingNewsfeedCategories, setExistingNewsfeedCategories] = useState<any>([]);
  const [addedNewsfeedCategories, setAddedNewsfeedCategories] = useState<any>([]);
  const [finalNewsfeedCategories, setFinalNewsfeedCategories] = useState<any>([]);
  const [value, setValue] = useState<any>([...existingNewsfeedCategories]);
  const [isActiveStatus, setIsActiveStatus] = useState<number>(0);
  const [articleBodyTemplate, setArticleBodyTemplate] = useState<string>("");

  const [needLatestUnits, setNeedLatestUnits] = useState<boolean>(false);
  const [needLatestUsers, setNeedLatestUsers] = useState<boolean>(false);
  const [assignedUserIds, setAssignedUserIds] = useState<Array<number>>([]);
  const [hasSelectedUsersChanged, setHasSelectedUsersChanged] = useState<boolean>(false);
  const [assignUsers, setAssignUsers] = useState<boolean>(false);
  const [isSavingAssignment, setIsSavingAssignment] = useState<boolean>(false);
  const [hasAccountsSearched, setHasAccountsSearched] = useState<boolean>(false);
  const [hasUnitsLoaded, setHasUnitsLoaded] = useState<boolean>(false);
  const [viewNewsfeedLoading, setViewNewsfeedLoading] = useState<boolean>(false);
  const [showAssignUnits, setshowAssignUnits] = useState<boolean>(false);
  const [showAssignAccounts, setshowAssignAccounts] = useState<boolean>(false);
  const [showViewAccounts, setshowViewAccounts] = useState<boolean>(false);
  const [showResetAccounts, setshowResetAccounts] = useState<boolean>(false);
  const [initaialUsersList, setInitaialUsersList] = React.useState<Array<any>>([]);
  const [finallySelectedUsersList, setFinallySelectedUsersList] = React.useState<Array<any>>([]);
  const [finallySelectedUnitList, setFinallySelectedUnitList] = React.useState<Array<any>>([]);
  const [newsfeedAssignedUsersList, setNewsfeedAssignedUsersList] = React.useState<Array<any>>([]);
  const [initialNewsfeedAcceptedUsersList, setInititalNewsfeedAcceptedUsersList] = React.useState<Array<any>>([]);
  const [selectedUnits, setselectedUnits] = useState<string>("");
  const [updatedSelectedUnits, setupdatedSelectedUnits] = useState<Array<string>>([]);
  const [csvOfAllDeniedUnits, setCSVOfAllDeniedUnits] = useState<string>("");
  const [showSameUnitModalForConfirmation, setShowSameUnitModalForConfirmation] = useState<boolean>(false);
  const [sameUnitModelMessage, setSameUnitModelMessage] = useState<string>("");
  const [selectedUsersCSVInitial, setSelectedUsersCSVInitial] = useState<string>("");
  // const [showArticleEditor, setShowArticleEditor] = useState<boolean>(true);
  const [enableCpdPoints, setEnableCpdPoints] = useState<boolean>(false);
  const [cpdPoints, setCpdPoints] = useState<number>(0.0);
  const [cpdPointsText, setCpdPointsText] = useState<string>("");
  const [showCPDLocal, setShowCPDLocal] = useState<boolean>(false);
  const [goBack, setGoBack] = useState<boolean>(false);
  const [previousLocation,setPreviousLocation] = useState<string>('');
  const [showUnsavedModal, setUnsavedModalOpen] = useState<boolean>(false);
  const [isNewsfeedSettingsChangedAndNotSaved, setIsNewsfeedSettingsChangedAndNotSaved] = useState<boolean>(false);
  const [currentNewsfeeddetails, setCurrentNewsfeeddetails] = useState<any>({
      articleName:'',
      articleSubheading:'',
      articleText:'',
      category: ''
    })


  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".articlename",
        title: allResourcesToTranslations.nfarticletitle, intro: allResourcesToTranslations.nfarticle
      }
    ]
  });

  const setGuideMe = () => {
    let updateSteps = [...intro.steps];
    if (updateSteps.length > 1) {
      updateSteps.splice(1, updateSteps.length - 1);
    }
    updateSteps.push(
      {
        element: ".subheading",
        title: allResourcesToTranslations.nfsubheadingtitle, intro: allResourcesToTranslations.nfsubheading
      },
      {
        element: ".articleeditor",
        title: allResourcesToTranslations.nfcontenttitle, intro: allResourcesToTranslations.nfcontent
      },
      {
        element: ".nfCategories",
        title: allResourcesToTranslations.nfcategoriestitle, intro: allResourcesToTranslations.nfcategories
      },
      {
        element: ".newsfeedstatus",
        title: allResourcesToTranslations.nfstatustitle, intro: allResourcesToTranslations.nfstatus
      },
      {
        element: ".sendWelcomeEmail",
        title: "Publish Newsfeed",
        intro: (newsfeedIdToUpdate > 0) ? "Click to publish newsfeed. " : "Article must be assigned to a unit\account before sending a Welcome Email",
      },
      {
        element: ".saveData",
        title: "Save",
        intro: "Click to save newsfeed. ",
      }
    );
    if (parseInt(newsfeedIdToEdit) > 0) {
      updateSteps.push(
        {
          element: ".AssignUnits",
          title: "Assign Units", intro: allResourcesToTranslations.nfassignunits
        },
        {
          element: ".AssignAccounts",
          title: "Assign Accounts", intro: allResourcesToTranslations.nfassignaccounts
        },
        {
          element: ".ViewAccounts",
          title: "View Accounts", intro: allResourcesToTranslations.nfviewaccounts
        });
    }
    if ((parseInt(newsfeedIdToEdit) > 0) && showAssignUnits) {
      updateSteps.push(
        {
          element: ".unittree",
          title: "Assign Units", intro: "Select the units to assign this Newsfeed Article to. Any Accounts in these units will be assigned the Newsfeed Article. "
        },
        {
          element: ".saveAssignedData",
          title: "Save Assignment",
          intro: "Click to save unit assignments. ",
        });
    }

    if (parseInt(newsfeedIdToEdit) > 0 && showAssignAccounts) {
      updateSteps.push(
        {
          element: ".accountSearch",
          title: "Search Accounts", intro: allResourcesToTranslations.nfsearchaccount
        });
      if (hasAccountsSearched) {
        updateSteps.push(
          {
            element: ".userList",
            title: "Accounts", intro: allResourcesToTranslations.nfselectedaccount
          },
          {
            element: ".assignCheckbox",
            title: "Assign", intro: allResourcesToTranslations.nfdeseltaccount
          },
          {
            element: ".saveAssignedData",
            title: "Save Assignment",
            intro: "Click to save unit assignments. ",
          });
      }
    }

    if (parseInt(newsfeedIdToEdit) > 0 && showViewAccounts && newsfeedAssignedUsersList.length > 0) {
      updateSteps.push(
        {
          element: ".userList",
          title: "Accounts", intro: allResourcesToTranslations.nfselectedaccount
        },
        {
          element: ".assignCheckbox",
          title: "Assign", intro: allResourcesToTranslations.nfdeseltaccount
        },
        {
          element: ".saveAssignedData",
          title: "Save Assignment",
          intro: "Click to save unit assignments. ",
        });
    }
    setIntro({ ...intro, steps: [...updateSteps] });
  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const CheckIfCPDEnabledForOrg = () => {
    CheckIfOrgHasCPDEnabled(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((cpdorgsettingRes) => {
      if (!!cpdorgsettingRes && cpdorgsettingRes.isSuccess) {
        if (cpdorgsettingRes.isCPDEnabled !== null && cpdorgsettingRes.isCPDEnabled !== undefined) {
          setShowCPD(cpdorgsettingRes.isCPDEnabled);
          setShowCPDLocal(cpdorgsettingRes.isCPDEnabled);
        }
        else {
          setShowCPD(false);
          setShowCPDLocal(false);
        }
      }
      else {
        setShowCPD(false);
        setShowCPDLocal(false);
      }
    }).catch((error) => {
      setShowCPD(false);
      setShowCPDLocal(false);
    });
  }

  const handleKeyDown = (event: any) => {    
    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",": {
        event.preventDefault();
        event.stopPropagation();    
 
        if (event.target.value.length > 0) {
          // Check if the value exists in the array
          const exists = value.some((v: any) => v.toString().trim() === event.target.value.trim());
         
          if (!exists) {
            // Add the new value to the array if it doesn't exist
            setValue([...value, event.target.value.trim()]);
          }
          else
          {
            setShowWarningModal(true);
            setModalPopupMessage('Category already exists.');
            setHasErrored(true);
            return;
          }
        }
        break;
      }
      default:
    }
  };

  React.useEffect(() => {

    var articleTextContent = (document.getElementById('editorText') as HTMLInputElement)?.value;
    var articleTextInitialContent = (document.getElementById('editorChangedText') as HTMLInputElement)?.value;
    var updatedArticleText = (!!articleTextContent === false || articleTextContent.trim() === "") ? articleTextInitialContent : articleTextContent;

    // @ts-ignore: Unreachable code error
    const unblock = history.block((location, action) => {
    
      if(goBack) {
        if(isSaving) {
            if (currentNewsfeeddetails.articleText != updatedArticleText || 
              currentNewsfeeddetails.articleName != articleName   ||
              currentNewsfeeddetails.articleSubheading != articleSubheading 
            ) {
              setPreviousLocation(location.pathname)
              setUnsavedModalOpen(true);
              return false;
            }
            else {
              return true;
            }
        }
        return true;
      } else {
          if (currentNewsfeeddetails.articleText != updatedArticleText || 
            currentNewsfeeddetails.articleName != articleName   ||
            currentNewsfeeddetails.articleSubheading != articleSubheading 
          ) {
            setPreviousLocation(location.pathname)
            setUnsavedModalOpen(true);
            return false;
          }
          else {
            return true;
          }
      }      
    }); 
     
    if (currentNewsfeeddetails.articleText != updatedArticleText || 
      currentNewsfeeddetails.articleName != articleName   ||
      currentNewsfeeddetails.articleSubheading != articleSubheading 
      ) {
        setIsNewsfeedSettingsChangedAndNotSaved(true); 
    }
    else
    {
      setIsNewsfeedSettingsChangedAndNotSaved(false); 
    }  

    if(!isSaving) {
      if(goBack) {
        history.push(previousLocation);      
      }
    }
    
    return () => {
      unblock();
    };
  }, [articleSubheading,articleText,articleName,goBack, currentNewsfeeddetails]);

  useEffect(() => {
      setTitle(allResourcesToTranslations.newsfeeddetails)
      setTitleEnglish(pageTitles.newsfeeddetails)
    updateBackButtonRoute("/Newsfeed");
    setMenuSelection("Newsfeed");
    CheckIfCPDEnabledForOrg();
    if (parseInt(newsfeedIdToEdit) > 0) {
      setNewsfeedIdToUpdate(parseInt(newsfeedIdToEdit));
      GetNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(newsfeedIdToEdit), 
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((res) => {
        if (!!res && !!res.isSuccess) {

          currentNewsfeeddetails.articleName = res.heading;
          setCurrentNewsfeeddetails({
            ...currentNewsfeeddetails,
            articleName:res.heading,
            articleSubheading:res.subHeading,
            articleText:res.articleText,
            category:res.nfCategoryIdCsv
            }
          )

          setArticleName(res.heading);
          setArticleSubheading(res.subHeading);

          setIsActiveStatus(res.status ? 1 : 0);
          GetNewsfeedCategory(res.newsfeedCategories);
          setselectedUnits(res.unitIds);
          setAssignedUserIds(!!res.userIds && res.userIds !== null ? res.userIds.split(',').map(function (id: any) { return parseInt(id); }) : "");
          setSelectedUsersCSVInitial(res.userIds);

          setArticleText(res.articleText);

          setCpdPoints(res.cpdPoints);
          setCpdPointsText(res.cpdPoints.toString());
          setEnableCpdPoints(res.hasAssignedCpdPoints);

          if ((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) || (res.nfSubscriberIdCsv !== "" && res.nfSubscriberIdCsv !== "-1")) {
            setLoading(true);
            GetNewsfeedSubscribers(parseInt(res.nfSubscriberIdCsv));
          }
        }
      });
    }
    else {
      setIsActiveStatus(1);
      GetNewsfeedCategory([]);
      GetNewsfeedSubscribers(-1);
    }
    setGuideMe();
  }, []);

  const GetNewsfeedSubscribers = (selectedSubscriberGroupId: number) => {

    getNewsfeedSubscriberList(oidcUser.access_token, userId, orgDomain, parseInt(orgId),
    (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
    (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
    (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")
  ).then((res) => {
      setLoading(false);
      if (!!res && !!res.isSuccess) {
        if (!!res.newsfeedSubscriberList && res.newsfeedSubscriberList.length > 0) {
          let subscriberGroup = ([{ value: -1, subscriberGroupId: -1, subscriberGroupName: "" }]);
          let activeSubscriberGroup = res.newsfeedSubscriberList.filter((r: any) => r.isActive === true);
          let allActiveSubscriberGroup = activeSubscriberGroup.map((item: any) => {
            if (!!item && !!item.nfSubscriberListId && !!item.nfSubscriberListName) {
              return {
                value: item.nfSubscriberListCommonId,
                subscriberGroupId: item.nfSubscriberListCommonId,
                subscriberGroupName: item.nfSubscriberListName
              }
            }

          });
          subscriberGroup.push(...allActiveSubscriberGroup);
          setSubscriberGroupList(subscriberGroup);

          if (selectedSubscriberGroupId > 0) {
            setSelectedSubscriberGroupId(selectedSubscriberGroupId);
          }
        }
      }
    }).catch((errorVal) => {
      console.error(errorVal);
    })
  };

  const GetNewsfeedCategory = (existingNewsfeedCategories: any) => {

    GetNfCategories(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      if (!!res && !!res.isSuccess) {
        if (!!res.newsfeedCategoryList && res.newsfeedCategoryList.length > 0) {
          let updatenewsfeedCategory = res.newsfeedCategoryList.map((item: any) => {
            return {
              title: item.nfCategoryName,
              id: item.nfCategoryId
            }
          });
          setAddedNewsfeedCategories([...updatenewsfeedCategory]);
          setExistingNewsfeedCategories([...existingNewsfeedCategories]);

          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    setValue([...existingNewsfeedCategories]);
  }, [existingNewsfeedCategories]);

  useEffect(() => {
    if (!!value && value.length > 0) {
      let updatedSelectedCategory = value.map((item: any) => {
        if (!!item.title) {
          return {
            title: item.title,
            id: item.id
          }
        }
        else {
          return {
            title: item,
            id: 0
          }
        }
      });
      setFinalNewsfeedCategories([...updatedSelectedCategory]);
    }
  }, [value]);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };
  const handleUnSavedModal = (value: boolean) => {
    setUnsavedModalOpen(value);
  }
  const HandleSelectionOfselectedSubscriberGroup = (e: any) => {
    let GroupID: string = !!e && !!e.target.value ? e.target.value.toString() : "";
    setSelectedSubscriberGroupId(parseInt(GroupID));
  }

  const handleSubmit = () => {
    setIsSaving(true);
    let updatedSelectedCategory = finalNewsfeedCategories.map((item: SaveCategoryDataResult) => {
      if (!!item.title) {
        return {
          title: item.title,
          id: item.id
        }
      }
      else {
        return {
          title: item,
          id: 0
        }
      }
    });
    var updatedArticleText = "";
    var articleTextContent = (document.getElementById('editorText') as HTMLInputElement).value;
    var articleTextInitialContent = (document.getElementById('editorChangedText') as HTMLInputElement).value;
    var updatedArticleText = (!!articleTextContent === false || articleTextContent.trim() === "") ? articleTextInitialContent : articleTextContent;

    if(!!updatedArticleText === false || updatedArticleText.trim() === "")
    {
      setShowWarningModal(true);
      setHasErrored(true);
      setSaveSuccess(false);
      setModalPopupMessage("Please enter the newsfeed article content.");
      setIsSaving(false);
    }
    else {
    SaveNewsfeedEntry(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedIdToUpdate == -1 ? 0 : newsfeedIdToUpdate, articleName, articleSubheading,
    updatedArticleText, selectedSubscriberGroupId.toString(), updatedSelectedCategory, isActiveStatus == 0 ? false : true, enableCpdPoints, cpdPoints,		
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : parseInt(orgId)),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : orgDomain),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((res) => {
        setIsSaving(false);         
        setGoBack(false)
        setUnsavedModalOpen(false);
        handleUnSavedModal(false);
        if (!!res && !!res.isSuccess) {
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Newsfeed details have been saved successfully!");
          setUnsavedModalOpen(false);
          handleUnSavedModal(false);

          setArticleText(updatedArticleText);
          setCurrentNewsfeeddetails({
            ...currentNewsfeeddetails,
            articleName:articleName,
            articleSubheading:articleSubheading,
            articleText: updatedArticleText
            }
          )

          setNewsfeedIdToUpdate(parseInt(res.newsfeedId));
          if ((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) {
            history.push({
              pathname: `/newsfeed`
            });
          }
          else {
            history.push({
              pathname: `../newsfeeddetails/` + res.newsfeedId.toString()
            });
          }
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Newsfeed details saving have been failed!");
        }
      });
    }
  }

  const handleAssignUnits = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewNewsfeedLoading(false);

    setshowAssignUnits(!showAssignUnits);
    setshowAssignAccounts(false);
    setshowViewAccounts(false);
    setshowResetAccounts(false);
    setAssignUsers(false);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleAssignAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewNewsfeedLoading(false);

    setshowAssignAccounts(!showAssignAccounts);
    setshowAssignUnits(false);
    setshowViewAccounts(false);
    setshowResetAccounts(false);
    setAssignUsers(true);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleViewAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewNewsfeedLoading(false);

    setshowViewAccounts(!showViewAccounts);
    setshowAssignAccounts(false);
    setshowAssignUnits(false);
    setshowResetAccounts(false);
    setNewsfeedAssignedUsersList([]);
    setInititalNewsfeedAcceptedUsersList([]);
    LoadNewsfeedAssignedUsers(newsfeedIdToUpdate);
    setAssignUsers(true);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const LoadNewsfeedAssignedUsers = (NewsfeedId: number) => {
    setViewNewsfeedLoading(true);
    ViewNewsfeedAssignedUsers(
      oidcUser.access_token,
      userId,
      orgDomain,
      newsfeedIdToUpdate,
      parseInt(orgId)
    ).then((response: any) => {
      setViewNewsfeedLoading(false);
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.newsfeedAssignedUserItem !== null &&
        response.newsfeedAssignedUserItem !== undefined
      ) {
        if (response.newsfeedAssignedUserItem.length > 0) {
          let updatedUsersList = response.newsfeedAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          let initialUsersList = response.newsfeedAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isInitial: true,
            };
          });
          setNewsfeedAssignedUsersList(updatedUsersList);
          setInititalNewsfeedAcceptedUsersList(initialUsersList);
          let initialGrantedList = initialUsersList.filter((initialItem: any) => initialItem.granted === true);
          const selectedIds = initialGrantedList.map(({ userID }: any) => userID);
          setSelectedUsersCSVInitial(selectedIds.join(','));
          setAssignedUserIds(selectedIds);
          return;

        } else {
          setHasErrored(false);
          setSaveSuccess(false);
          return;
        }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Fetching record has failed, please try again.');
        setHasErrored(true);
        return;
      }
    });
  }

  const handleResetAccounts = () => {
  }

  const setupdatedSelectedUnitsFunction = (unitIds: string[]) => {
    if (newsfeedIdToUpdate) {
      setupdatedSelectedUnits(unitIds);
      setFinallySelectedUnitList(unitIds);
      SaveNewsfeedAssignClick(unitIds, []);
    }
  }
  const setUnitsLoadedFunction = (hasUnitsLoaded: boolean) => {
    setHasUnitsLoaded(hasUnitsLoaded);
    setGuideMe();
  }
  const setAllDeniedUnitsAsCSVFunction = (csvGiven: string) => {
    setCSVOfAllDeniedUnits(csvGiven);
  }

  const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {
    setFinallySelectedUsersList(usersData);
  }

  const getValidIntNumberValue = (givenTxt: string) => {
    let numval = -1;
    if (!!givenTxt === false || givenTxt.trim() == "") {
      return -1;
    }
    try {
      numval = parseInt(givenTxt);
      if (!!numval == false || isNaN(numval) || Number.isNaN(numval)) {
        numval = -1;
      }
    }
    catch { numval = -1; }
    return numval;
  }

  const getNewlyModifiedUserIDs = (updatedSelectedUsers: any[]) => {
    let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
    let newlyGrantedUserIDsCSV = "";
    let newlyDeniedUserIDsCSV = "";

    let initialSelectedUserIDs: string[] = [];
    initialSelectedUserIDs = selectedUsersCSVInitial.split(',');
    // Get the newly granted and newly denied users
    if (!!updatedSelectedUsers && updatedSelectedUsers.length > 0 &&
      updatedSelectedUsers.some(id => !!id && !!id.userID && id.userID.toString().trim() != "")) {
      // Collect only newly granted in first loop of updated user-list checked against initial granted user-list
      for (let i = 0; i < updatedSelectedUsers.length; i++) {
        let curVal = updatedSelectedUsers[i];
        let curValId = "";
        if (!!curVal === false || !!curVal.userID === false || (curValId = curVal.userID.toString().trim()) == "" || curVal.granted !== true) {
          continue;
        }
        let numVal = getValidIntNumberValue(curValId);
        let numvalTxt = numVal.toString();
        // Extra not overlapping with initial list - collect only the denied to granted
        if (!initialSelectedUserIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
          if (curVal.granted === true) {
            newlyGrantedUserIDsCSV = ((newlyGrantedUserIDsCSV == "") ? numvalTxt : (newlyGrantedUserIDsCSV + "," + numvalTxt));
          }
        }
      }
      // Collect only newly denied in second loop of initial granted user-list checked against updated user-list
      for (let i = 0; i < initialSelectedUserIDs.length; i++) {
        let curVal = initialSelectedUserIDs[i];
        if (!!curVal === false || (curVal = curVal.toString().trim()) == "") {
          continue;
        }
        let numVal = getValidIntNumberValue(curVal);
        let numvalTxt = numVal.toString();
        // Overlapping with initial list - collect only granted to denied
        if (updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt) &&
          updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === false)) {
          newlyDeniedUserIDsCSV = ((newlyDeniedUserIDsCSV == "") ? numvalTxt : (newlyDeniedUserIDsCSV + "," + numvalTxt));
        }
      }
    }
    retVal.newlyDeniedIDsCSV = newlyDeniedUserIDsCSV;
    retVal.newlyGrantedIDsCSV = newlyGrantedUserIDsCSV;
    return retVal;
  }

  const SaveNewsfeedAssignClick = (unitIds: string[], usersData: any[]) => {
    setNeedLatestUnits(false);
    if (showAssignUnits) {
      let anyChangeFound = false;
      if (unitIds.length === selectedUnits.split(",").length) {
        for (let curIdx = 0; curIdx < unitIds.length; curIdx++) {
          let currentValue = unitIds[curIdx];
          let unit = (selectedUnits.split(",").find(v => !!v && !!currentValue && v.toString().toLowerCase().trim() === currentValue.toString().toLowerCase().trim()));
          if (anyChangeFound === false && (unit === null || unit === undefined)) {
            anyChangeFound = true;
            break;
          }
        }
      }
      else {
        anyChangeFound = true;
      }
      if (anyChangeFound) {
        AssignUnitsToNewsfeedFunction(unitIds);
      }
      else {
        handleSameUnitModalForConfirmation(true);
        setSameUnitModelMessage("There are no changes to Unit Assignments. Do you want to re-apply the existing Unit Assignments which will override any Account specific assignments?");
      }
    }
    if (showViewAccounts) {
      let anyUserChangeFound: boolean = false;
      let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
      let initialUserGrantedData = !!initialNewsfeedAcceptedUsersList !== true ? null : initialNewsfeedAcceptedUsersList.filter((initialItem) => initialItem.granted === true);
      if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length) {
        for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
          let currentItem = usersData[curIdx];
          let userdetail = (initialNewsfeedAcceptedUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
          if (anyUserChangeFound === false && !!userdetail) {
            anyUserChangeFound = true;
            break;
          }
        }
      }
      else {
        anyUserChangeFound = true;
      }
      if (anyUserChangeFound) {
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = getNewlyModifiedUserIDs(usersData);
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

        setIsSavingAssignment(true);
        AssignNewsfeedUsers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedIdToUpdate.toString(), newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
          .then((res) => {
            setIsSavingAssignment(false);
            setNeedLatestUnits(false);
            setNeedLatestUsers(false);
            if (!!res && res.isSuccess) {
              let initialUsersList = usersData.map((item: any) => {
                return {
                  ...item,
                  isInitialChanged: true,
                };
              });
              setInitaialUsersList(initialUsersList);
              let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
              const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
              setSelectedUsersCSVInitial(selectedIds.join(','));

              setTrainingListUpdateRequired(true);
              setDashboardUpdateRequired(true);
              setShowWarningModal(true);
              setHasErrored(false);
              setSaveSuccess(true);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been saved successful. ");
              GetAssignedUserIds();
              if (showViewAccounts) {
                setNewsfeedAssignedUsersList([]);
                setNeedLatestUnits(false);
                setNeedLatestUsers(false);
                LoadNewsfeedAssignedUsers(newsfeedIdToUpdate);
              }
            }
            else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage("Assign/Unasssign users to the external certificate have been failed.");
            }
          });
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(false);
        setModalPopupMessage("Please select the users to assign/unassign to the external certificate");
      }
    }
    if (showAssignAccounts) {
      let anyUserChangeFound: boolean = false;
      let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
      //console.log(currentUserGrantedData);
      //console.log(initaialUsersList);
      let initialUserGrantedData = !!initaialUsersList !== true ? null : initaialUsersList.filter((initialItem) => initialItem.granted === true);
      if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length) {
        // Setting external variable using the lambda of map function is avoided
        for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
          let currentItem = usersData[curIdx];
          let userdetail = (initaialUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
          if (anyUserChangeFound === false && !!userdetail) {
            anyUserChangeFound = true;
          }
        }
      }
      else {
        anyUserChangeFound = true;
      }
      if (anyUserChangeFound) {
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = getNewlyModifiedUserIDs(usersData);
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

        setIsSavingAssignment(true);
        AssignNewsfeedUsers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedIdToUpdate.toString(), newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
          .then((res) => {
            setIsSavingAssignment(false);
            setNeedLatestUnits(false);
            setNeedLatestUsers(false);

            if (!!res && res.isSuccess) {
              let initialUsersList = usersData.map((item: any) => {
                return {
                  ...item,
                  isInitial: true,
                };
              });
              setInitaialUsersList(initialUsersList);
              let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
              const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
              setSelectedUsersCSVInitial(selectedIds.join(','));

              setTrainingListUpdateRequired(true);
              setDashboardUpdateRequired(true);
              setShowWarningModal(true);
              setHasErrored(false);
              setSaveSuccess(true);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been saved successful. ");
              GetAssignedUserIds();
            }
            else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been failed.");
            }
          });
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(false);
        setModalPopupMessage("Please select the user to assign/unassign to the newsfeed");
      }
    }
  }

  const getNumberFloatValueOrDefault = (givenTxt: string, defaultVal: number) => {
    if (!!givenTxt === false) {
      return defaultVal;
    }
    try {
      let givenVal = parseFloat(givenTxt);
      if (givenVal < 0 || Number.isNaN(givenVal)) {
        return defaultVal;
      }
      return givenVal;
    }
    catch {
      return defaultVal;
    }
  }

  const setCPDProfilePoints = (givenValTxt: string) => {
    if (!!givenValTxt && givenValTxt.trim() != "" && givenValTxt.endsWith(".")) {
      setCpdPointsText(givenValTxt.trim());
      return;
    }
    let val = getNumberFloatValueOrDefault(givenValTxt, 0.0);
    setCpdPoints(val);
    setCpdPointsText(givenValTxt);
  }

  const handleSameUnitModalForConfirmation = (value: boolean) => {
    setShowSameUnitModalForConfirmation(value);
  }

  const GetAssignedUserIds = () => {
    GetNewsfeedAssignedUserIds(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedIdToUpdate)
      .then((res) => {
        if (!!res && res.isSuccess) {
          setAssignedUserIds(res.newsfeedAssignedUserIds);
        }
      })
  }

  const AssignUnitsToNewsfeedFunction = (unitIds: string[]) => {
    handleSameUnitModalForConfirmation(false);
    setIsSavingAssignment(true);
    AssignNewsfeedUnits(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedIdToUpdate.toString(), unitIds.join(","))
      .then((res) => {
        setIsSavingAssignment(false);
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setselectedUnits(unitIds.join(","));
        if (!!res && res.isSuccess) {
          setTrainingListUpdateRequired(true);
          setDashboardUpdateRequired(true);        
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Assign units to this Newsfeed have been saved successful. ");
          GetAssignedUserIds();
        }
      })
  }

  const handleCancelSameUnitModalForConfirmation = () => {
    setShowSameUnitModalForConfirmation(false);
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
  }

  const handleSaveConfirmProceedSameUnitModalForConfirmation = () => {
    AssignUnitsToNewsfeedFunction(selectedUnits.split(','));
  }

  const setRecordsSearchStartedFunction = (hasSearchStarted: boolean) => {
  }

  const setRecordsSearchedFunction = (hasSearched: boolean) => {
    setHasAccountsSearched(hasSearched);
  }
  const setUserSelectionChangedFunction = (hasSelectedUsersChanged: boolean) => {
    setHasSelectedUsersChanged(hasSelectedUsersChanged);
  }

  const handleSaveAssignment = () => {
    if (showAssignUnits) {
      setNeedLatestUnits(true);
    }
    if (showViewAccounts || showAssignAccounts) {
      setNeedLatestUsers(true);
      SaveNewsfeedAssignClick([], finallySelectedUsersList);
    }
  }

  const setInitialUserListFunction = (usersData: Array<any>[]) => {
    setInitaialUsersList(usersData);
    let initialGrantedList = !!usersData !== true ? null : usersData.filter((initialItem: any) => initialItem.granted === true);
    const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
    setSelectedUsersCSVInitial(selectedIds.join(','));
    setGuideMe();
  }
  const handleArticleTextEditorChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event !== undefined) {
      setArticleBodyTemplate(event.target.value);
      console.log(event.target.value);
    }
  };

  useEffect(() => {
  }, [assignedUserIds]);

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 3000)
    }
  }, [showWarningModal])

  useEffect(() => {
    setGuideMe();
  }, [showAssignAccounts, showAssignUnits, showViewAccounts, hasAccountsSearched, newsfeedAssignedUsersList]);

  if (loading) return <LoaderContainer />;
  return (
    <>
     <Modal type="warning" modalOpen={showUnsavedModal} setModalOpen={handleUnSavedModal} style={{ height: '232px', width: '381px' }}
        hideCloseBtn={true}>
        <div style={{ position: 'relative', top: '-30px' }}>
          <h3 style={{ color: '#e7a652' }}>Unsaved Changes</h3>
          <p>Do you want to proceed without saving changes?</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '10px' }}>


          <CustomTooltip displayText={["Return"]}>
            <span className={styles.returnIcon} >
              <span
                onClick={() => {
                  setUnsavedModalOpen(false);
                  setIsLoggingOut(false);
                }}
              >
                <CloseIcon />
              </span>
            </span>

          </CustomTooltip>
          <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className={styles.proceedIcon}>
                <Button
                  onClick=
                  {() => {
                    if (IsLoggingOut) {
                      setMessage(allResourcesToTranslations.loggingout);
                      logout();
                      addError(oidcUser.access_token, userId, orgDomain, "Logged out", null, "NewsfeedDetails.tsx", 6, EnableActivityLogs);
                      getUserManager().signoutRedirect()
                        .catch((error) => {
                          //TODO Error API here
                        });
                      getUserManager().signoutRedirectCallback()
                        .then(() => {
                          getUserManager().removeUser();
                        })
                        .catch((error) => {
                          //TODO Error API here
                        });
                    } else {
                      setIsSaving(false);
                      setGoBack(true)
                    }
                  }}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>

        </div>
      </Modal>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <Modal modalOpen={showSameUnitModalForConfirmation} setModalOpen={handleSameUnitModalForConfirmation} type="warning" hideCloseBtn={true}>
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
          <div className={styles.inputContainer}>

            <p>{sameUnitModelMessage}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelSameUnitModalForConfirmation}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleSaveConfirmProceedSameUnitModalForConfirmation}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
      <UnbrandedCard>
        <div className={styles.mainWrapper}>
          <div className={styles.Wrapper}>
            <div style={{ width: '100%', clear: "both", paddingBottom: "10px" }} >
              { ((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) || (selectedSubscriberGroupId !== null && selectedSubscriberGroupId !== -1)) &&
                <div className="subscriberOrgGroup" style={{ backgroundColor: "white", height: "45px", paddingBottom: "20px" }}>
                  <FormControl variant="outlined" className={`${styles.formControl} cpdProfileForExtCertFormCtrl`}>
                    <InputLabel id="subscriberorggroup">{allResourcesToTranslations.subscriberorggroup + ":"}</InputLabel>
                    <Select
                      value={selectedSubscriberGroupId}
                      native
                      onChange={(e: any) => {
                        HandleSelectionOfselectedSubscriberGroup(e);
                      }}
                      defaultValue={{ value: "-1", subscriberGroupId: "-1", subscriberGroupName: "" }}
                      className={styles.inputSelect}
                      variant="outlined"
                      label={allResourcesToTranslations.subscriberorggroup + ":"}
                      disabled={(!!orgDomain && orgDomain.trim().toLowerCase() !== superAdminDomain)}
                      id="subscriberorggroup"
                    >
                      {
                        subscriberGroupList.length > 0 &&
                        subscriberGroupList.map((item: any) => {
                          return <option key={item.subscriberGroupId} value={item.value}>{item.subscriberGroupName}</option>
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
             } 
            </div>
            <div style={{ width: '100%', clear: "both", paddingBottom: "20px" }} >
              <div className="articlename" style={{ backgroundColor: "white", height: "45px", paddingBottom: "10px" }}>
                <TextField
                  className={styles.input}
                  label={allResourcesToTranslations.articlename}
                  variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={articleName}
                  onChange={(e) => setArticleName(e.target.value)}
                  placeholder={allResourcesToTranslations.requiredfield}
                />
              </div>
            </div>
            <div style={{ width: '100%', clear: "both", paddingBottom: "20px" }} >
              <div className="subheading" style={{ backgroundColor: "white", height: "45px", paddingBottom: "10px" }}>
                <TextField
                  className={styles.input}
                  label={allResourcesToTranslations.articlesubheading}
                  variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={articleSubheading}
                  onChange={(e) => setArticleSubheading(e.target.value)}
                />
              </div>
            </div>
            <div className="articleeditor" style={{ width: '100%', paddingBottom: '20px' }} >
              {((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) ?
                <TinyMCEEditor index={100} key={"tinyMCEEditor100"} editorData={articleText} isTemplate={true} is200Tall={true} handleTextEditorChange={handleArticleTextEditorChange} />
                :
                <TinyMCEEditor index={100} key={"tinyMCEEditor100"} editorData={articleText} isTemplate={false} is200Tall={true} handleTextEditorChange={handleArticleTextEditorChange} />
                // <div>
                //   <span style={{ fontSize: '14px', fontWeight: 700, color: '#767676' }}> Article Text: <br></br></span>
                //   <div
                //     dangerouslySetInnerHTML={{
                //       __html: articleText //bodyFormatted
                //     }}></div>
                // </div>
              }
            </div>
            <div style={{ width: '100%', clear: "both", paddingBottom: "10px" }} className="nfCategories">
              <Autocomplete
                multiple
                id="tags-outlined"
                options={addedNewsfeedCategories}
                getOptionLabel={option => {
                  if (typeof option === "string") {
                    return option;
                  }
                  return option.title;
                }}
                value={value}
                onChange={(event, newValue: any) => setValue(newValue)}
                filterSelectedOptions
                renderInput={(params: any) => {
                  params.inputProps.onKeyDown = handleKeyDown;
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Newsfeed Category"
                      placeholder="New Category"
                      margin="normal"
                      fullWidth
                    />
                  );
                }}
              />
            </div>
            <div className={`${styles.inputContainer} ${responsivestyles.rowrule}`} style={{ width: "100%" }}>
              <div className={showCPD ? `${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}` : `${responsivestyles.columnrule_s_12}`}>
                <div className={`${styles.inputContainer} newsfeedstatus`}>
                  <FormControl variant="outlined" className={styles.formControl} style={{ backgroundColor: "white", height: "55px" }}>
                    <InputLabel>{allResourcesToTranslations.status}</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      value={isActiveStatus}
                      native
                      disabled={isSaving}
                      onChange={(e: any) => setIsActiveStatus(parseInt(e.target.value))}
                      variant="outlined"
                      className={allResourcesToTranslations.status}
                      label={allResourcesToTranslations.status}>
                      <option value={1}>{allResourcesToTranslations.active}</option>
                      <option value={0}>{allResourcesToTranslations.inactive}</option>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {showCPD &&
                <div className={`${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_6}`}>
                  <div style={{ paddingLeft: "20px" }} onClick={() => setEnableCpdPoints(!enableCpdPoints)}>
                    {enableCpdPoints &&
                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                    }
                    {!enableCpdPoints &&
                      <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                    }
                  </div>
                  <div className={styles.inputContainer}>
                    <span id="enableCpdPointswitch" className={styles.labelText2} onClick={() => setEnableCpdPoints(!enableCpdPoints)}>
                      {allResourcesToTranslations.enablecpdpoints}</span>
                  </div>
                </div>
              }
              {showCPD &&
                <div className={`${responsivestyles.columnrule_3} ${responsivestyles.columnrule_s_6}`}>
                  {enableCpdPoints &&
                    <div className="cpdPoints" style={{ backgroundColor: "white", height: "45px", paddingBottom: "10px" }}>
                      <TextField
                        className={styles.input}
                        label={allResourcesToTranslations.cpdpoints}
                        variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                        value={cpdPointsText}
                        onChange={(e) => setCPDProfilePoints(e.target.value)}
                        placeholder={allResourcesToTranslations.requiredfield}
                      />
                    </div>}
                </div>
              }
            </div>
          </div>
          <div style={{ display: "flex", width: "100%", clear: "both" }}>
            <div style={{ display: "flex", flexDirection: "row-reverse", paddingRight: "20px", paddingBottom: "10px", marginRight: "-5px", width: "100%", clear: "both" }}>
              <CustomTooltip displayText={["Save"]}>
                <span className="saveData">
                  <Button
                    disabled={
                      isSaving || articleName == "" || (!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain && selectedSubscriberGroupId == -1)
                    }
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    className="save"
                  >
                    <SaveIcon />
                  </Button>
                </span>
              </CustomTooltip>
              <div className={styles.loaderContainer}>
                                <CircularProgress
                                    style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                                    color="primary"
                                    size="10"
                                />
                            </div>
              <div style={{ paddingTop: "20px" }}>
              </div>
            </div>
          </div>
        </div>
      </UnbrandedCard>
    
    {newsfeedIdToUpdate > 0 && (
            <div className="buttonspanel">
              <AssignButtonPanel isCalledFrom={((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) ? (!!superAdminSelectedBasicOrgData && superAdminSelectedBasicOrgData.siteName !== "") ? "NewsfeedSuperOrgAdmin" : "NewsfeedSuperAdmin" : "Newsfeed"} handleAssignUnitsClick={handleAssignUnits} handleAssignAccountsClick={handleAssignAccounts} handleViewAccountsClick={handleViewAccounts} handleResetAccountsClick={handleResetAccounts} isResetInProgress={isResettingAcceptances}></AssignButtonPanel>
            </div>
          )}
          <div id="assign">
            {(newsfeedIdToUpdate > 0) && showAssignUnits && (
              <AssignUnits isCalledFrom="Newsfeed" needLatestUnits={needLatestUnits} selectedUnitIds={selectedUnits} setUpdatedSelectedUnits={setupdatedSelectedUnitsFunction} setUnitsLoaded={setUnitsLoadedFunction} setAllDeniedUnitsAsCSV={setAllDeniedUnitsAsCSVFunction}></AssignUnits>
            )}

            {(newsfeedIdToUpdate > 0) && showAssignAccounts && (
              <div className={styles.mainWrapper}>
                <AccountManagement isCalledFrom="Newsfeed" needLatestUsers={needLatestUsers} setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} assignedUserIds={assignedUserIds}
                  setInitialUsersData={setInitialUserListFunction} setSearchStarted={setRecordsSearchStartedFunction} setRecordsSearched={setRecordsSearchedFunction}
                  policyId={newsfeedIdToUpdate} sethasSelectionChanged={setUserSelectionChangedFunction}></AccountManagement>
              </div>
            )}

            {(newsfeedIdToUpdate > 0) && showViewAccounts && !viewNewsfeedLoading && newsfeedAssignedUsersList.length > 0 && (
              <div className="userList">
                <UserListSection usersData={newsfeedAssignedUsersList} isCalledFrom="Newsfeed" setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} needLatestUsers={needLatestUsers}
                  profileID={-1} profilePeriodID={-1} selectedUnitIDs={[]} allUnitIDs={[]} filterFirstName={""} filterLastName={""} filterUserName={""} filterEmail={""} />
              </div>
            )}

            {(newsfeedIdToUpdate > 0) && showViewAccounts && viewNewsfeedLoading && (
              <LoaderContainer />
            )}

            {(newsfeedIdToUpdate > 0) && showViewAccounts && !viewNewsfeedLoading && newsfeedAssignedUsersList.length <= 0 && (
              <BrandedCard>
                <div className={styles.noResultsCard} style={{ marginTop: "20px" }}>
                  <div>No Results to Display</div>
                </div>
              </BrandedCard>
            )}
          </div>
          {(newsfeedIdToUpdate > 0 &&
            !((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) &&
            ((showAssignUnits && hasUnitsLoaded) ||
              (showAssignAccounts && hasAccountsSearched) ||
              (showViewAccounts && !viewNewsfeedLoading && newsfeedAssignedUsersList.length > 0))) && (
              <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
                <CustomTooltip displayText={showAssignUnits ? ["Assign Units"] : (showAssignAccounts ? ["Assign Accounts"] : (showViewAccounts ? ["Unassign Accounts"] : ["Save"]))}>
                  <span className="saveAssignedData" style={{ marginRight: "-5px" }}>
                    <Button
                      onClick={handleSaveAssignment}
                      variant="contained"
                      color="primary"
                      className="save"
                    >
                      <SaveIcon />
                    </Button>
                  </span>
                </CustomTooltip>
                <div className={styles.loaderContainer}>
                  <CircularProgress
                    style={{ width: isSavingAssignment ? "20px" : "0px", padding: "15px 10px" }}
                    color="primary"
                    size="10"
                  />
                </div>
              </div>
            )}
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

    </>
  );
};
export default NewsfeedDetails;
