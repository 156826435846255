import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const NotesIcon = () => (    
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path style={{ fill: '#0c0505' }} d="M22.5,1.77h-3.49v-.87c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.87h-2.27v-.87c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.87h-2.27v-.87c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.87h-2.27v-.87c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.87h-2.27v-.87c0-.5-.4-.9-.9-.9s-.9.4-.9.9v.87H1.5c-.34,0-.62.28-.62.62v21c0,.34.28.62.62.62h21c.34,0,.62-.28.62-.62V2.38c0-.34-.28-.62-.62-.62ZM21.88,22.77H2.12V3h2.88v.77c0,.5.4.9.9.9s.9-.4.9-.9v-.77h2.27v.77c0,.5.4.9.9.9s.9-.4.9-.9v-.77h2.27v.77c0,.5.4.9.9.9s.9-.4.9-.9v-.77h2.27v.77c0,.5.4.9.9.9s.9-.4.9-.9v-.77h2.88v19.76Z"/>
  <path style={{ fill: '#0c0505' }} d="M4.95,7.64c-.38,0-.68.3-.68.68s.31.68.68.68h14.1c.38,0,.68-.3.68-.68s-.31-.68-.68-.68H4.95Z"/>
  <path style={{ fill: '#0c0505' }} d="M19.05,12.11H4.95c-.38,0-.68.3-.68.68s.31.68.68.68h14.1c.38,0,.68-.3.68-.68s-.31-.68-.68-.68Z"/>
  <path style={{ fill: '#0c0505' }} d="M11.61,16.58h-6.66c-.38,0-.68.3-.68.68s.31.68.68.68h6.66c.38,0,.68-.3.68-.68s-.31-.68-.68-.68Z"/>
</svg>
);

export default NotesIcon
