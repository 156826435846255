//React + Typescript
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

// React YouTube player
import ReactPlayer from 'react-player'

// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';

//Contexts
import { UIContext } from '../contexts/UIContext';
import { TranslationsContext } from '../contexts/TranslationsContext';

//API Functions
import { GetTipVideos } from '../API/Administration/Organisation/GetTipVideos';
import { addError } from '../API/AddError'

//Icons
import DashboardIcon from '../CustomIcons/NavigationMenu/DashboardIcon';
import MyTrainingIcon from '../CustomIcons/NavigationMenu/MyTrainingIcon';
import MyReportIcon from '../CustomIcons/NavigationMenu/MyReportIcon';
import ModifyLinkIcon from '../CustomIcons/NavigationMenu/ModifyLinkIcon';
import ManageTipVideosIcon from '../CustomIcons/NavigationMenu/ManageTipVideosIcon';
import PolicyBuilderIcon from '../CustomIcons/NavigationMenu/PolicyBuilderIcon';
import CpdProfileIcon from '../CustomIcons/NavigationMenu/CpdProfileIcon';
import ThemeIcon from '../CustomIcons/NavigationMenu/ThemeIcon';
import SupportDetailsIcon from '../CustomIcons/NavigationMenu/SupportDetailIcon';
import AddAccountIcon from '../CustomIcons/NavigationMenu/AddIcon';
import AccountManagementIcon from '../CustomIcons/NavigationMenu/ManagementIcon';
import ManagementDeactiveIcon from '../CustomIcons/NavigationMenu/ManagementDeactiveIcon';
import UnitSettingsIcon from '../CustomIcons/NavigationMenu/UnitSettingsIcon';
import ImportAccountsIcon from '../CustomIcons/NavigationMenu/ImportIcon';
import SingleSignOnIcon from '../CustomIcons/NavigationMenu/SingleSignOnIcon';
import MoveIcon from '../CustomIcons/NavigationMenu/MoveIcon';
import ConfigItemIcon from '../CustomIcons/NavigationMenu/ConfigItemIcon';
import ComplianceRulesIcon from '../CustomIcons/NavigationMenu/ComplianceRulesIcon';
import AccountSettingIcon from '../CustomIcons/NavigationMenu/AccountSettingIcon';
import AdminIcon from '../CustomIcons/NavigationMenu/AdminIcon';
import EmailIcon from '../CustomIcons/NavigationMenu/EmailIcon';
import UnitComplianceIcon from '../CustomIcons/NavigationMenu/UnitComplianceIcon';
import LicenseReportIcon from '../CustomIcons/NavigationMenu/LicenseReportIcon';
import WarningReportIcon from '../CustomIcons/NavigationMenu/WarningReportIcon';
import AtRiskReportIcon from '../CustomIcons/NavigationMenu/AtRiskReportIcon';
import TemplateIcon from '../CustomIcons/NavigationMenu/TemplateIcon';
import ReminderEmailRuleIcon from '../CustomIcons/NavigationMenu/ReminderEmailRuleIcon';
import QuizHistoryReportIconMenu from '../CustomIcons/NavigationMenu/QuizHistoryReportIcon';
import ProgressIcon from '../CustomIcons/NavigationMenu/ProgressIcon';
import SentEmailIcon from '../CustomIcons/NavigationMenu/SentEmailIcon';
import SummaryReportIcon from '../CustomIcons/NavigationMenu/SummaryReportIcon';
import ArchiveIcon from '../CustomIcons/NavigationMenu/ArchiveIcon';

//Components
import CustomTooltip from './CustomTooltip';
import SettingsIcon from '../CustomIcons/SettingsIcon';
import Modal from './Modal';

//MUI Components
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

//Styles
import styles from '../styles/titleBar.module.css'
import ExternalCertificateIcon from '../CustomIcons/NavigationMenu/ExternalCertificateIcon';
import ManageTipVideosIconNonWhiteMenu from '../CustomIcons/NavigationMenu/ManageTipVideosMenuNonWhiteIcon';
import { TipVideo } from '../globalTypes';
import AssignCoursesIcon from '../CustomIcons/NavigationMenu/AssignCoursesIcon';
import SaltCertificatesReportIcon from '../CustomIcons/NavigationMenu/SaltCertificatesReportIcon';
import NewsfeedIcon from '../CustomIcons/NavigationMenu/NewsfeedIcon';
import MyNewsfeedIcon from '../CustomIcons/NavigationMenu/MyNewsfeedIcon';
import PublishContentIconMenu from '../CustomIcons/NavigationMenu/PublishContentIconMenu';
import PublishContentIcon from '../CustomIcons/NavigationMenu/PublishContentIcon';
import APILibraryIcon from '../CustomIcons/NavigationMenu/APILibraryIcon';

export const TitleBar = () => {
    const { translations: { allResourcesToTranslations } } = useContext(TranslationsContext)
    const { title, titleEnglish, backRoute, backRouteProps, showBackButton, EnableActivityLogs, accountMgmntInputProps,
            unitMgmntInputProps , emailSettingsRuleInputProps, setPageHelpVideoURL, pageHelpVideoURL, subComponentName,
            allTipVideos, setAllTipVideos, tipVideosReloadRequired, setTipVideosReloadRequired, superAdminSelectedBasicOrgData } = useContext(UIContext);
    const history = useHistory()
    const { oidcUser } = useReactOidc();
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const userId = oidcUser.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgDomain = oidcUser.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  
    const [showModal, setShowModal] = useState<boolean>(false);
    const [matchedTipVideo, setMatchedTipVideo] = useState<TipVideo | null>(null); 

    const handleGoBack = () => {
      addError(oidcUser.access_token, userId, orgDomain, `Pressed back to navigate to ${backRoute}`, null, "LearningModuleList.tsx", 6, EnableActivityLogs)
      if (backRoute !== "") {        
        if (!!backRoute && backRoute.trim().toLowerCase() === "/currentadminreport") {
          history.push(backRoute, backRouteProps);
        }       
        else if (!!backRoute && backRoute.trim().toLowerCase() === "/accountmanagement") {
          history.push(backRoute, accountMgmntInputProps);
        }
        else if (!!backRoute && backRoute.trim().toLowerCase() === "/unitmanagement") {
          history.push(backRoute, unitMgmntInputProps);
        }
        else if (!!backRoute && backRoute.trim().toLowerCase() === "/orgemailrulesadd") {
          history.push(backRoute, emailSettingsRuleInputProps);
        }
        else if (!!backRoute && backRoute.trim().toLowerCase() === "/orgemailrules") {
          history.push(backRoute, emailSettingsRuleInputProps);
        }
        else {
          history.push(backRoute);
        }
      }
    }

    const handleVideoClick = () => {
      setShowModal(true);
      // if (!!matchedTipVideo === false) {
      //   GetTipVideos(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
      //     if (result.isSuccess && result.error == '') {
      //       if (result.tipVideos !== null && !!(result.tipVideos as TipVideo[])) {
      //         let tvms = (result.tipVideos as TipVideo[]).filter
      //           (tv => !!tv && !!tv.pageReference && !!pageReference && tv.pageReference.trim().toLowerCase() === title.trim().toLowerCase());
      //         if (!!tvms !== true || tvms.length <= 0) {
      //           tvms = (result.tipVideos as TipVideo[]).filter
      //             (tv => !!tv && !!tv.pageReference && tv.pageReference.trim().toLowerCase() === "default");
      //         }
      //         if (!!tvms && tvms.length > 0) {
      //           setMatchedTipVideo(tvms[0]);
      //           setShowModal(true);
      //         }
      //       }
      //     }
      //     else {
      //     }
      //   });
      // }
      // else {
      //   setShowModal(true);
      // }
    }

    let icon;
    
  
    if (title === 'Progress Report' || title === allResourcesToTranslations.progressreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ProgressIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Quiz History Report' || title === allResourcesToTranslations.quizhistoryreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><QuizHistoryReportIconMenu />&nbsp;&nbsp;</span>;
    } else if (title === 'Course Status Report' || title === allResourcesToTranslations.coursestatusreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyTrainingIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Current Admin Report' || title === allResourcesToTranslations.currentadminreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AdminIcon />&nbsp;&nbsp;</span>;
    }else if (title === 'Historic Admin Report') {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AdminIcon />&nbsp;&nbsp;</span>;
    }else if (title === 'Current - Historic Admin Report') {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AdminIcon />&nbsp;&nbsp;</span>;
    }else if (title === 'Sent Email Report' || title === allResourcesToTranslations.sentemailreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><SentEmailIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Email Report' || title === allResourcesToTranslations.emailreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><TemplateIcon />&nbsp;&nbsp;</span>;
    }  else if (title === 'Account Detail Report' || title === allResourcesToTranslations.userdetailreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AccountSettingIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Unit Pathway Report' || title === allResourcesToTranslations.unitpathwayreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><UnitSettingsIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Warning Report' || title === allResourcesToTranslations.warningreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><WarningReportIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'At Risk Report' || title === allResourcesToTranslations.atriskreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AtRiskReportIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Unit Admin Report' || title === allResourcesToTranslations.unitadminreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AdminIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Unit Compliance Report' || title === allResourcesToTranslations.unitcompliancereport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><UnitComplianceIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Summary Report' || title === allResourcesToTranslations.summaryreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><SummaryReportIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Salt Completion Certificates Report' || title === allResourcesToTranslations.summaryreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><SaltCertificatesReportIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'My Dashboard' || title === allResourcesToTranslations.mydashboard) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><DashboardIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'My Training' || title === allResourcesToTranslations.mytraining) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyTrainingIcon />&nbsp;&nbsp;</span>;
    } else if (!!title && (title.toString().includes("My Report") || title.toString().includes("Personal Report") || title.toString().includes("Quiz History")|| title.toString().replace("{NAME}","").replace("{DISPLAYNAME}","").includes(allResourcesToTranslations.personalreportfor) || title.toString().replace("{NAME}","").replace("{DISPLAYNAME}","").includes(allResourcesToTranslations.quizhistory))) {
       icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyReportIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'My Newsfeed' || title === allResourcesToTranslations.mynewsfeed) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyNewsfeedIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Newsfeed Subscribers' || title === allResourcesToTranslations.newsfeedsubscribedorgs) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyNewsfeedIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Newsfeed Assigned group' || title === allResourcesToTranslations.newsfeedassignedgroup) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyNewsfeedIcon />&nbsp;&nbsp;</span>;
    } 
    // else if (!!title || title.indexOf('My Report') === 0 || title.indexOf('Personal Report') === 0 || title.toString().includes(allResourcesToTranslations.personalreportfor) || title.toString().includes(allResourcesToTranslations.quizhistory)) {
    //   icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyReportIcon />&nbsp;&nbsp;</span>;
    // } 
    else if (title === 'Theme' || title === allResourcesToTranslations.theme0) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ThemeIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Support Details' || title === allResourcesToTranslations.supportdetails) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><SupportDetailsIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Account Settings'  || title === allResourcesToTranslations.accountsettings) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><SettingsIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Scheduled Reports' || title === allResourcesToTranslations.scheduledreports ) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MyReportIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'CPD Report'|| title === allResourcesToTranslations.cpdreport ) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><CpdProfileIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'CPD Reports' || title === allResourcesToTranslations.cpdreports) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><CpdProfileIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Policy Reports' || title === 'Policy Builder'  || title === allResourcesToTranslations.policyreports || title === allResourcesToTranslations.policybuilder) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><PolicyBuilderIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Policy Builder Report' || title === allResourcesToTranslations.policybuilderreport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><PolicyBuilderIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'License Report' || title === allResourcesToTranslations.licensereport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><LicenseReportIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Add Account' || title === 'Account Details' || title === allResourcesToTranslations.addaccount) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AddAccountIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Account Management' || title === allResourcesToTranslations.accountmanagement) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AccountManagementIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Organisation Deactivation' || title === allResourcesToTranslations.organisationdeactivation) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ManagementDeactiveIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Newsfeeds' || title === allResourcesToTranslations.newsfeeds || title === 'Newsfeed Details' || title === allResourcesToTranslations.newsfeeddetails) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><NewsfeedIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Account Managers' || title === allResourcesToTranslations.orgaccountmanagers) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AdminIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Unit Management' || title === 'Unit Details' || title ==='Add New Account to Unit'|| title ==='Assign Modules to Unit'|| title ==='Set Unit Compliance Rule'|| title ==='Manage Unit Administrators'  || title === allResourcesToTranslations.unitmanagement) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AccountManagementIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Import Accounts' || title === allResourcesToTranslations.importaccounts) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ImportAccountsIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Add Unit' || title === allResourcesToTranslations.addunit) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AddAccountIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Move Unit' || title === allResourcesToTranslations.moveunit || title === 'Move Accounts to Unit' || title === allResourcesToTranslations.moveaccountstounit) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><MoveIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Organisation Management' || title === allResourcesToTranslations.addorganisation) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AddAccountIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Compliance Rules'|| title === allResourcesToTranslations.compliancerules) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ComplianceRulesIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Configuration Items'|| title === allResourcesToTranslations.configurationitems) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ConfigItemIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Single Sign On' || title === allResourcesToTranslations.singlesignon) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><SingleSignOnIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Reminder Email Rules' || title ==='Add Reminder Email Rules' || title === allResourcesToTranslations.orgemailrule) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ReminderEmailRuleIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'CPD Profile' || title ==='Cpd Profile' || title === allResourcesToTranslations.cpdprofile) { /* Removed capital letter */
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><CpdProfileIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Application Email Templates' || title === allResourcesToTranslations.applicationemailtemplates) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><EmailIcon />&nbsp;&nbsp;</span>;
    }else if (title === 'Email Templates' || title === allResourcesToTranslations.orgemailtemplates) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><EmailIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Modify Links' || title === 'Links' || title === allResourcesToTranslations.modifylinks || title === allResourcesToTranslations.links) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ModifyLinkIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'API Library' || title === allResourcesToTranslations.apilibrary || title === 'API Library Details' || title === allResourcesToTranslations.apilibrarydetails){
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><APILibraryIcon />&nbsp;&nbsp;</span>;      
    }else if (title === 'Assign Courses' || title === allResourcesToTranslations.assigncourses) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AssignCoursesIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Manage Tip Videos' || title === 'Tip Videos' || title === allResourcesToTranslations.managetipvideostitle || title === allResourcesToTranslations.tipvideos) {
      icon = <span style={{position:'relative', top:'25px', marginLeft:'10px', marginRight:'0px'}}><ManageTipVideosIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Organisation Administrators' || title === 'Add Organisation Administrator'  || title === allResourcesToTranslations.organisationadministrators || title === allResourcesToTranslations.addneworgadmin) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><AdminIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'Archive Accounts' ||  title === allResourcesToTranslations.archiveaccounts ) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ArchiveIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'External Certificates' || title === 'My External Certificates'|| title === "External Certificate Details" || title === allResourcesToTranslations.extcertificate ||  title === allResourcesToTranslations.myextcertificate ||  title === allResourcesToTranslations.externalcertificatedetails) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ExternalCertificateIcon />&nbsp;&nbsp;</span>;
    } else if (title === 'External Certificates Report'|| title === allResourcesToTranslations.extcertificatereport) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><ExternalCertificateIcon />&nbsp;&nbsp;</span>;
    }else if (title === 'Publish Course' || title === allResourcesToTranslations.publishcourse  || title === 'Add Course' || title === allResourcesToTranslations.addcoursedetails  || title === allResourcesToTranslations.publishcourse +  " - " +allResourcesToTranslations.step2 || title === allResourcesToTranslations.publishcourse +  " - " +allResourcesToTranslations.step3 || title === allResourcesToTranslations.publishcourse +  " - " + allResourcesToTranslations.step4 || title === allResourcesToTranslations.publishcourse +  " - " + allResourcesToTranslations.step5 || title === allResourcesToTranslations.publishcourse +  " - " + allResourcesToTranslations.step6) {
      icon = <span style={{position:'relative', top:'20px', marginLeft:'10px', marginRight:'0px'}}><PublishContentIcon />&nbsp;&nbsp;</span>;
    }else {
      icon = <span />;
    }

    useEffect(() => {
      if (!tipVideosReloadRequired && !!allTipVideos) {
        if (allTipVideos !== null && !!(allTipVideos as TipVideo[])) {
          let tvms = (allTipVideos as TipVideo[]).filter
            (tv => !!tv && !!tv.pageReference && !!titleEnglish && tv.orgID == parseInt(orgId) && tv.pageReference.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
          if (!!tvms !== true || tvms.length <= 0) {
            tvms = (allTipVideos as TipVideo[]).filter
              (tv => !!tv && !!tv.pageReference && !!titleEnglish && tv.orgID == null && tv.pageReference.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
          }
          if (!!tvms !== true || tvms.length <= 0) {
            tvms = (allTipVideos as TipVideo[]).filter
              (tv => !!tv && !!tv.title && !!titleEnglish && tv.orgID == parseInt(orgId) && tv.title.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
          }
          if (!!tvms !== true || tvms.length <= 0) {
            tvms = (allTipVideos as TipVideo[]).filter
              (tv => !!tv && !!tv.title && !!titleEnglish && tv.orgID == null && tv.title.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
          }
          if (!!tvms !== true || tvms.length <= 0) {
            tvms = (allTipVideos as TipVideo[]).filter
              (tv => !!tv && !!tv.title && tv.title.trim().toLowerCase() === "default");
          }
          if (!!tvms && tvms.length > 0 && !!tvms[0].url) {
            setMatchedTipVideo(tvms[0]);
            setPageHelpVideoURL(tvms[0].url);
          }
          else {
            setMatchedTipVideo(null);
            setPageHelpVideoURL("");
          }
        }
        else {
          setMatchedTipVideo(null);
          setPageHelpVideoURL("");
        }
      }
      else {
        GetTipVideos(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
          if (!!result && result.isSuccess && result.error == '') {
            if (result.tipVideos !== null && !!(result.tipVideos as TipVideo[])) {
              let tvms = (result.tipVideos as TipVideo[]).filter
                (tv => !!tv && !!tv.pageReference && !!titleEnglish && tv.orgID == parseInt(orgId) && tv.pageReference.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
              if (!!tvms !== true || tvms.length <= 0) {
                tvms = (result.tipVideos as TipVideo[]).filter
                  (tv => !!tv && !!tv.pageReference && !!titleEnglish && tv.orgID == null && tv.pageReference.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
              }
              if (!!tvms !== true || tvms.length <= 0) {
                tvms = (result.tipVideos as TipVideo[]).filter
                  (tv => !!tv && !!tv.title && !!titleEnglish && tv.orgID == parseInt(orgId) && tv.title.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
              }
              if (!!tvms !== true || tvms.length <= 0) {
                tvms = (result.tipVideos as TipVideo[]).filter
                  (tv => !!tv && !!tv.title && !!titleEnglish && tv.orgID == null && tv.title.trim().toLowerCase() === titleEnglish.trim().toLowerCase());
              }
              if (!!tvms !== true || tvms.length <= 0) {
                tvms = (result.tipVideos as TipVideo[]).filter
                  (tv => !!tv && !!tv.title && tv.title.trim().toLowerCase() === "default");
              }
              setAllTipVideos(result.tipVideos);
              setTipVideosReloadRequired(false);
              if (!!tvms && tvms.length > 0 && !!tvms[0].url) {
                setMatchedTipVideo(tvms[0]);
                setPageHelpVideoURL(tvms[0].url);
              }
              else {
                setMatchedTipVideo(null);
                setPageHelpVideoURL("");
              }
            }
            else {
              setMatchedTipVideo(null);
              setPageHelpVideoURL("");
            }
          }
          else {
            setMatchedTipVideo(null);
            setPageHelpVideoURL("");
          }
        });
      }
    }, [ titleEnglish])

    useEffect(() => {
      let changing = true;
      let ischanging = changing;
    }, [matchedTipVideo, pageHelpVideoURL, subComponentName])
   

    return (
      <>
        {
          showModal && !!matchedTipVideo && !!pageHelpVideoURL &&
          <div>
            <Modal modalOpen={showModal} setModalOpen={setShowModal}>
                <div className={styles.modalContentWrapper}>
                  <ReactPlayer key={"tipvideo-" + (!!titleEnglish ? titleEnglish : "common") + "-" + pageHelpVideoURL} width="1020px" height="780px" 
                      url={pageHelpVideoURL} style={{borderRadius: "100px"}} /> 
                </div>
            </Modal>
          </div>
        }
        <div className={styles.titleContainer}>
          <div style={{display:'flex'}}>
            {icon}
            <div>
              <h1 className={styles.title}>{title + ((!!titleEnglish && titleEnglish != "Account Settings" && titleEnglish != "My Dashboard" && !titleEnglish.startsWith("My Training") && titleEnglish != "My External Certificates" && titleEnglish != "Personal Report" && titleEnglish != "Personal Report" &&
                                !!superAdminSelectedBasicOrgData) ? 
                                  " (" + (!!superAdminSelectedBasicOrgData.orgName ? superAdminSelectedBasicOrgData.orgName : "") + " - " + 
                                    (!!superAdminSelectedBasicOrgData.domainName ? superAdminSelectedBasicOrgData.domainName : "") + ")" : "")}</h1>
            </div>
          </div>
          {/* <div
            className={styles.buttonContainer}
            style={{ display: showBackButton ? "block" : "none" }}
          >
            <div>
            <CustomTooltip displayText={["Guide Me"]}>
                  <Button variant="outlined" color="primary" onClick={() => {}} className={styles.guideMeButtonSpecific}>
                      <GuideIcon />
                      </Button>
              </CustomTooltip>
            </div>
          </div> */}
          <div style={{ display: "flex", flexDirection: "row-reverse"}}>
            { showBackButton &&
              <div
                className={styles.buttonContainer}
                //style={{ display: showBackButton ? "block" : "none" }}
              >
                <div>
                  <CustomTooltip displayText={[allResourcesToTranslations.back]}>
                    <Button
                      onClick={() => handleGoBack()}
                      variant="contained"
                      color="primary"
                      disabled={false}
                      className="back"
                    >
                      <ArrowBackIcon />
                    </Button>
                  </CustomTooltip>
                </div>
              </div> 
            }
            {
              !!matchedTipVideo &&
              <div style={!!matchedTipVideo.title && matchedTipVideo.title == "Email Report" ?
              { marginTop: "25px" } :
              { marginTop: "0px"}}>
                <CustomTooltip displayText={[allResourcesToTranslations.tipvideo + " - " + matchedTipVideo.title]}>
                  <span id="tipVideoPopupHelp" 
                      //style={{paddingLeft: "15px", marginRight: "55px", marginTop: "20px", float: "right"}} 
                      style={!!matchedTipVideo.title && matchedTipVideo.title == "Email Report" ?
                              { marginRight: "120px", marginTop: "25px" } :
                              ( (!!matchedTipVideo.title && matchedTipVideo.title == "Current Admin Report" && 
                                titleEnglish == "Current Admin Report" && !!title && title.startsWith("Quiz History")) ? 
                                { paddingLeft: "15px", marginRight: "95px", marginTop: "15px", float: "right" } :
                                { paddingLeft: "15px", marginRight: "55px", marginTop: "15px", float: "right", })
                            }
                      onClick={handleVideoClick}><ManageTipVideosIconNonWhiteMenu /></span>
                </CustomTooltip>  
              </div>
            }
            </div>
        </div>
      </>
    );
}

export default TitleBar;

