import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const APILibraryIcon = () => (    

<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 6.333a3.167 3.167 0 0 1 3.167 -3.167h3.167c0.576 0 1.118 0.154 1.583 0.424A3.167 3.167 0 0 1 14.25 3.167h3.167c1.151 0 2.16 0.614 2.714 1.534 0.332 -0.266 0.722 -0.469 1.159 -0.586l3.059 -0.819a3.167 3.167 0 0 1 3.879 2.239l6.555 24.47a3.167 3.167 0 0 1 -2.239 3.879l-3.059 0.819a3.167 3.167 0 0 1 -3.879 -2.239L20.583 13.716V31.667a3.167 3.167 0 0 1 -3.167 3.167H14.25a3.167 3.167 0 0 1 -1.583 -0.424A3.167 3.167 0 0 1 11.083 34.833H7.917a3.167 3.167 0 0 1 -3.167 -3.167zm3.167 0h3.167v25.333H7.917zm9.5 25.333H14.25V6.333h3.167zm4.693 -24.493 3.057 -0.819 6.558 24.47 -3.059 0.819z" fill="#21394F"/></svg>


);

export default APILibraryIcon
