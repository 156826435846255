//React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { UnitAdministratorReportResultItem } from "../../../globalTypes";
// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from "../../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import { UIContext } from "../../../contexts/UIContext";
//Components
import BrandedCard from "../../../Components/BrandedCard";
import LoaderContainer from "../../../Components/LoaderContainer";
import CustomTooltip from "../../../Components/CustomTooltip";

//General Functions
import getColor from "../../../functions/getColor";
import serialToArray from "../../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getCurrentTimeOfOrg } from "../../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from "../../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../../API/GetExportedPDFData";
import { addError } from "../../../API/AddError";
//Components
import ExcelFileIcon from "../../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import BrushIcon from "@material-ui/icons/Brush";

// Style
import styles from "../../../styles/currentAdminReport.module.css";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { any } from "prop-types";

import DeleteIcon from "../../../CustomIcons/DeleteIcon";
import removeUnitAdministrator from "../../../API/Administration/Unit/RemoveUnitAdministrator";
import getFullUnitAdministratorReport from "../../../API/GetFullUnitAdministratorReport";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";


interface UnitAdministratorReportListProps {
  unitAdministratorData: any;
  unitIdToEdit:any;
}
const UnitAdministratorReportSection = ({ unitAdministratorData,unitIdToEdit }: UnitAdministratorReportListProps) => {
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const {
    translations: { allResourcesToTranslations },
  } = useContext(TranslationsContext);

  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  
  const [unitAdministratorReport, setUnitAdministratorReportResult] = React.useState<Array<any>>(unitAdministratorData);

  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [openCriteria, setOpenCriteria] = useState(false);
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);

  const [unitpathwayDescending, setUnitpathwayDescending] = useState<boolean>(false);
  const [lastnameDescending, setLastnameDescending] = useState<boolean>(false);
  const [firstnameDescending, setFirstnameDescending] = useState<boolean>(false);
  const [usernameDescending, setUsernameDescending] = useState<boolean>(false);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);
  const [externalidDescending, setExternalidDescending] = useState<boolean>(false);
  const [groupbyDescending, setGroupbyDescending] = useState<boolean>(false);
  const [valueDescending, setValueDescending] = useState<boolean>(false);
  const [flagDescending, setFlagDescending] = useState<boolean>(false);
  
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");
  const [resultError, setResultError] = useState<string>( "");
  const [includeInactiveUsers, setIncludeInactiveUsers] = useState<boolean>( true  );
  const [selectedUnitNames, setSelectedUnitNames] = useState<string>();
  const [deletedUnitadminID, setDeletedUnitadminID] = useState<number>(0);


  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false); 
  const [loading, setLoading] = useState<boolean>(true);
  const [reportingAccessOnlyDescending, setReportingAccessOnlyDescending] = useState<boolean>(false);
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);  

  //const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const GetDate_yymmdd = () => {
    var d =  new Date(Date.now());
    let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
    return day;   
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < unitAdministratorReport.length; i++) {
        const r = unitAdministratorReport[i];   
        if(includeInactiveUsers)
        {             
        resultArray.push(`${r.unitpathway}<#>${r.lastname}<#>${r.firstname}<#>${r.username}<#>${r.flag}<#>${r.email}<#>${r.reportingAccessOnly}<#>${r.externalid}<#>${r.groupby}<#>${r.value}`);     
        }
        else
        {
          resultArray.push(`${r.unitpathway}<#>${r.lastname}<#>${r.firstname}<#>${r.username}<#>${r.email}<#>${r.reportingAccessOnly}<#>${r.externalid}<#>${r.groupby}<#>${r.value}`);     
        }
    }
    return resultArray;
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Unit Administrator Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "UnitAdministratorReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactiveUsers?10:9, "UnitAdministratorReport", enableRowBanding, "","","","",includeInactiveUsers,false, "", "", "", "", "", "", "").then((xlxsRes) => {
        if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            );

            a.download = "UnitAdministratorReport-"+ GetDate_yymmdd() + ".xlsx" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
        }
        setExporting(false);
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Unit Administrator Report XLSX-EXPORT in NEWSALT ClientApp", null, "UnitAdministratorReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
    | "UnitPathway"
    | "LastName"
    | "FirstName"
    | "Flag"
    | "UserName"
    | "Email"
    | "ReportingAccessOnly"
    | "ExternalID"
    | "GroupBy"
    | "Value",
    sortGivenItemInDesc: boolean
  ) => {
    if (unitAdministratorReport == null || unitAdministratorReport.length <= 0) {
      return unitAdministratorReport;
    }
    var result = unitAdministratorReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {
      case "UnitPathway":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.unitpathway === null) {
              return -1;
            }
            if (a === null || a.unitpathway === null) {
              return 1;
            }
            if (a.unitpathway > b.unitpathway) {
              return -1;
            }
            if (a.unitpathway < b.unitpathway) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitpathwayDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.unitpathway === null) {
              return 1;
            }
            if (a === null || a.unitpathway === null) {
              return -1;
            }
            if (a.unitpathway > b.unitpathway) {
              return 1;
            }
            if (a.unitpathway < b.unitpathway) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setUnitpathwayDescending(true);
          }
        }
        setUnitAdministratorReportResult(result);
        break;     
      case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastname === null) {
              return -1;
            }
            if (a === null || a.lastname === null) {
              return 1;
            }
            if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
              return -1;
            }
            if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastnameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastname === null) {
              return 1;
            }
            if (a === null || a.lastname === null) {
              return -1;
            }
            if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
              return -1;
            }
            if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastnameDescending(true);
          }
        }
        setUnitAdministratorReportResult(result);
        break;
      case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstname === null) {
              return -1;
            }
            if (a === null || a.firstname === null) {
              return 1;
            }
            if (a.firstname > b.firstname) {
              return -1;
            }
            if (a.firstname < b.firstname) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstnameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstname === null) {
              return 1;
            }
            if (a === null || a.firstname === null) {
              return -1;
            }
            if (a.firstname > b.firstname) {
              return 1;
            }
            if (a.firstname < b.firstname) {
              return -1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstnameDescending(true);
          }
        }
        setUnitAdministratorReportResult(result);
        break;
      case "Flag":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.flag === null) {
                  return -1;
                }
                if (a === null || a.flag === null) {
                  return 1;
                }
                if (a.flag.toLowerCase() > b.flag.toLowerCase()) {
                  return -1;
                }
                if (a.flag.toLowerCase() < b.flag.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setFlagDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.flag === null) {
                  return 1;
                }
                if (a === null || a.flag === null) {
                  return -1;
                }
                if (a.flag.toLowerCase() < b.flag.toLowerCase()) {
                  return -1;
                }
                if (a.flag.toLowerCase() > b.flag.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setFlagDescending(true);
              }
            }
            setUnitAdministratorReportResult(result);
            break;
      case "UserName":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.username === null) {
                    return -1;
                  }
                  if (a === null || a.username === null) {
                    return 1;
                  }
                  if (a.username.toLowerCase() > b.username.toLowerCase()) {
                    return -1;
                  }
                  if (a.username.toLowerCase() < b.username.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUsernameDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.username === null) {
                    return 1;
                  }
                  if (a === null || a.username === null) {
                    return -1;
                  }
                  if (a.username.toLowerCase() < b.username.toLowerCase()) {
                    return -1;
                  }
                  if (a.username.toLowerCase() > b.username.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUsernameDescending(true);
                }
              }
              setUnitAdministratorReportResult(result);
              break;
      case "Email":
                if (sortGivenItemInDesc) {
                  result.sort((a, b) => {
                    if (b === null || b.email === null) {
                      return -1;
                    }
                    if (a === null || a.email === null) {
                      return 1;
                    }
                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                      return -1;
                    }
                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  });
                  if (reverseNextSort) {
                    setEmailDescending(false);
                  }
                } else {
                  result.sort((a, b) => {
                    if (b === null || b.email === null) {
                      return 1;
                    }
                    if (a === null || a.email === null) {
                      return -1;
                    }
                    if (a.email.toLowerCase() < b.email.toLowerCase()) {
                      return -1;
                    }
                    if (a.email.toLowerCase() > b.email.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  });
                  if (reverseNextSort) {
                    setEmailDescending(true);
                  }
                }
                setUnitAdministratorReportResult(result);
                break;
      case "ExternalID":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.externalid === null) {
                    return -1;
                  }
                  if (a === null || a.externalid === null) {
                    return 1;
                  }
                  if (a.externalid.toLowerCase() > b.externalid.toLowerCase()) {
                    return -1;
                  }
                  if (a.externalid.toLowerCase() < b.externalid.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setExternalidDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.externalid === null) {
                    return 1;
                  }
                  if (a === null || a.externalid === null) {
                    return -1;
                  }
                  if (a.externalid.toLowerCase() < b.externalid.toLowerCase()) {
                    return -1;
                  }
                  if (a.externalid.toLowerCase() > b.externalid.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setExternalidDescending(true);
                }
              }
              setUnitAdministratorReportResult(result);
              break;
      case "ReportingAccessOnly":
                if (sortGivenItemInDesc) {
                  result.sort((a, b) => {
                    if (b === null || b.email === null) {
                      return -1;
                    }
                    if (a === null || a.email === null) {
                      return 1;
                    }
                    if (a.reportingAccessOnly > b.reportingAccessOnly) {
                      return -1;
                    }
                    if (a.reportingAccessOnly < b.reportingAccessOnly) {
                      return 1;
                    }
                    return 0;
                  });
                  if (reverseNextSort) {
                    setReportingAccessOnlyDescending(false);
                  }
                } else {
                  result.sort((a, b) => {
                    if (b === null || b.email === null) {
                      return 1;
                    }
                    if (a === null || a.email === null) {
                      return -1;
                    }
                    if (a.reportingAccessOnly < b.reportingAccessOnly) {
                      return -1;
                    }
                    if (a.reportingAccessOnly > b.reportingAccessOnly) {
                      return 1;
                    }
                    return 0;
                  });
                  if (reverseNextSort) {
                    setReportingAccessOnlyDescending(true);
                  }
                }
                setUnitAdministratorReportResult(result);
                break;    
      case "GroupBy":
                if (sortGivenItemInDesc) {
                  result.sort((a, b) => {
                    if (b === null || b.groupby === null) {
                      return -1;
                    }
                    if (a === null || a.groupby === null) {
                      return 1;
                    }
                    if (a.groupby.toLowerCase() > b.groupby.toLowerCase()) {
                      return -1;
                    }
                    if (a.groupby.toLowerCase() < b.groupby.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  });
                  if (reverseNextSort) {
                    setGroupbyDescending(false);
                  }
                } else {
                  result.sort((a, b) => {
                    if (b === null || b.groupby === null) {
                      return 1;
                    }
                    if (a === null || a.groupby === null) {
                      return -1;
                    }
                    if (a.groupby.toLowerCase() < b.groupby.toLowerCase()) {
                      return -1;
                    }
                    if (a.groupby.toLowerCase() > b.groupby.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  });
                  if (reverseNextSort) {
                    setGroupbyDescending(true);
                  }
                }
                setUnitAdministratorReportResult(result);
                break;
      case "Value":
                  if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                      if (b === null || b.value === null) {
                        return -1;
                      }
                      if (a === null || a.value === null) {
                        return 1;
                      }
                      if (a.value.toLowerCase() > b.value.toLowerCase()) {
                        return -1;
                      }
                      if (a.value.toLowerCase() < b.value.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    });
                    if (reverseNextSort) {
                      setValueDescending(false);
                    }
                  } else {
                    result.sort((a, b) => {
                      if (b === null || b.value === null) {
                        return 1;
                      }
                      if (a === null || a.value === null) {
                        return -1;
                      }
                      if (a.value.toLowerCase() < b.value.toLowerCase()) {
                        return -1;
                      }
                      if (a.value.toLowerCase() > b.value.toLowerCase()) {
                        return 1;
                      }
                      return 0;
                    });
                    if (reverseNextSort) {
                      setValueDescending(true);
                    }
                  }
                  setUnitAdministratorReportResult(result);
                  break;
                            
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
    | "UnitPathway"
    | "LastName"
    | "FirstName"
    | "Flag"
    | "UserName"
    | "Email"
    | "ReportingAccessOnly"
    | "ExternalID"
    | "GroupBy"
    | "Value",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setUnitAdministratorReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitpathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitpathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastnameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastnameDescending(true);
    sortResultsBy("LastName", true);
  };

  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstnameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstnameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickFlagAsc = () => {
    setReverseNextSort(false);
    setFlagDescending(false);
    sortResultsBy("Flag", false);
  };

  const clickFlagDesc = () => {
    setReverseNextSort(false);
    setFlagDescending(true);
    sortResultsBy("Flag", true);
  };

  const clickUserNameAsc = () => {
    setReverseNextSort(false);
    setUsernameDescending(false);
    sortResultsBy("UserName", false);
  };

  const clickUserNameDesc = () => {
    setReverseNextSort(false);
    setUsernameDescending(true);
    sortResultsBy("UserName", true);
  };

  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };
  const clickReportingAccessOnlyAsc = () => {
    setReverseNextSort(false);
    setReportingAccessOnlyDescending(false);
    sortResultsBy("ReportingAccessOnly", false);
  };
  const clickReportingAccessOnlyDesc = () => {
    setReverseNextSort(false);
    setReportingAccessOnlyDescending(true);
    sortResultsBy("ReportingAccessOnly", true);
  };
  const clickExternalIDAsc = () => {
    setReverseNextSort(false);
    setExternalidDescending(false);
    sortResultsBy("ExternalID", false);
  };

  const clickExternalIDDesc = () => {
    setReverseNextSort(false);
    setExternalidDescending(true);
    sortResultsBy("ExternalID", true);
  };
  const clickGroupByAsc = () => {
    setReverseNextSort(false);
    setGroupbyDescending(false);
    sortResultsBy("GroupBy", false);
  };

  const clickGroupByDesc = () => {
    setReverseNextSort(false);
    setGroupbyDescending(true);
    sortResultsBy("GroupBy", true);
  };

  const clickValueAsc = () => {
    setReverseNextSort(false);
    setValueDescending(false);
    sortResultsBy("Value", false);
  };

  const clickValueDesc = () => {
    setReverseNextSort(false);
    setValueDescending(true);
    sortResultsBy("Value", true);
  };

  const initialSort = () => {
   setCurrentSortBy("UnitPathway");
   setLastnameDescending(false);
   setFirstnameDescending(false);
   setFlagDescending(false);  
   setUsernameDescending(false);
   setEmailDescending(false);
   setExternalidDescending(false);
   setGroupbyDescending(false);
   setValueDescending(false);
   
   getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
    if (res.isSuccess) {
      let curdt = res.currentDateTimeText;
      setReportRunTimeTaken(curdt);
    } else {
      let curdt = getCurrentTimeDDMMYYYYFormat();
      setReportRunTimeTaken(curdt);
    }
   }).catch ((error) => {
      let curdt = getCurrentTimeDDMMYYYYFormat();
      setReportRunTimeTaken(curdt);
   });
   setPage(0);
  };

  useEffect(() => {
    setUnitAdministratorReportResult(unitAdministratorData);
    setLoading(false);
  }, [unitAdministratorData]);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleDeleteUnitAdmin = (index: number) => {   
		handleConfirmModal(true);   
    setDeletedUnitadminID(index);
  }
  
  const loadUnitAdministratorReport = () => {
 
    getFullUnitAdministratorReport(
      oidcUser.access_token, userId, orgDomain, parseInt(orgId),
      unitIdToEdit, true          
    ).then((response) => {
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.unitAdministratorReportItems !== null &&
        response.unitAdministratorReportItems !== undefined
      ) { 
          let updatedUnitAdministratorList = response.unitAdministratorReportItems.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          setUnitAdministratorReportResult(updatedUnitAdministratorList);     
        
      } else {                          
        return;
      }
    });  
  
  };

  const handleConfirmModal = (value: boolean) => {
		setshowConfirmModal(value);
	}

	const handleCancelFromConfirmPopup = () => {
		handleConfirmModal(false);	
	}	
  const handleProceed = () => {
  handleConfirmModal(false);    
  if(deletedUnitadminID > 0)
  {
    removeUnitAdministrator(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(unitIdToEdit),deletedUnitadminID).
    then((unitadminSaveRes) => {           
        if (unitadminSaveRes.isSuccess) {  
        setShowWarningModal(true);
        setModalPopupMessage('Unit admin have been deleted successfully!');
        setSaveSuccess(true);
        setHasErrored(false);
        loadUnitAdministratorReport();
      }      
      else {
        setShowWarningModal(true);
        setModalPopupMessage(unitadminSaveRes.error);
        setHasErrored(true);
        return;
      }   
    });	
  }	
	}
  if (exporting) return <LoaderContainer />;
  return (
    <><Modal modalOpen={showConfirmModal} setModalOpen={handleConfirmModal} type="warning">
      <div className={styles.modalContentWrapper}>
        <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>{allResourcesToTranslations.confirmation}</h2>
        <div className={styles.inputContainer}>       
          <p>
            <b>Are you sure you want to delete the unit administrator?</b>
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "10px",
          }}
        >
          <CustomTooltip displayText={[allResourcesToTranslations.return]}>
            <span className={styles.confirmationmodelCloseIcon} onClick={handleCancelFromConfirmPopup}>
              <CloseWhiteIcon />
            </span>
          </CustomTooltip>
          <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
            <span className="saveData">
              <Button
                // onClick={handleProceed}  
                onClick={handleProceed}
                variant="contained"
                color="primary"
                className="save"
              >
                <ProceedIcon />
              </Button>
            </span>
          </CustomTooltip>
        </div>
      </div>
    </Modal><>
        {unitAdministratorReport.length > 0 &&
          <div>
            <div style={{ float: "right" }} className={styles.reportExportContainer}>
              <span><b>{unitAdministratorReport.length}&nbsp;Results</b></span>&nbsp;&nbsp;<CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>
                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                  <ExcelFileIcon />
                </Button>
              </CustomTooltip>
            </div>
            <div
              className={styles.paginationControlsContainer}
              style={{ justifyContent: "center" }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={unitAdministratorReport.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
            </div>
            <div className={styles.tableSection}>
              <div className={styles.tableContainer}>
                <BrandedCard>
                  <TableContainer>
                    <Table>
                      <TableHead className="unitAdministratorReportList">
                        <TableRow>
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setUnitpathwayDescending(!unitpathwayDescending);
                                sortResultsBy("UnitPathway", !unitpathwayDescending);
                              } }>
                                {"Administered Unit Pathway" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "UnitPathway" && unitpathwayDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUnitPathwayAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "UnitPathway" && unitpathwayDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUnitPathwayDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setLastnameDescending(!lastnameDescending);
                                sortResultsBy("LastName", !lastnameDescending);
                              } }>
                                {"Last Name" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "LastName" && lastnameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickLastNameAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "LastName" && lastnameDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickLastNameDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setFirstnameDescending(!firstnameDescending);
                                sortResultsBy("FirstName", !firstnameDescending);
                              } }>
                                {"First Name" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "FirstName" && firstnameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickFirstNameAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "FirstName" && firstnameDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickFirstNameDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setUsernameDescending(!usernameDescending);
                                sortResultsBy("UserName", !usernameDescending);
                              } }>
                                {"User Name" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "UserName" && usernameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUserNameAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "UserName" && usernameDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickUserNameDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          {includeInactiveUsers &&
                            <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                              <div className={styles.tableHeadCellContainer}>
                                <div onClick={() => {
                                  setFlagDescending(!flagDescending);
                                  sortResultsBy("Flag", !flagDescending);
                                } }>
                                  {"Status" /* TODO translate */}
                                </div>
                                <div className={styles.columnHeadArea}>
                                  <span style={{ opacity: currentSortBy === "Flag" && flagDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickFlagAsc}>
                                    <UpArrowIcon />{" "}
                                  </span>
                                  <span style={{ opacity: currentSortBy === "Flag" && flagDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickFlagDesc}>
                                    <DownArrowIcon />{" "}
                                  </span>
                                </div>
                              </div>
                            </TableCell>}
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setEmailDescending(!emailDescending);
                                sortResultsBy("Email", !emailDescending);
                              } }>
                                {"Email" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "Email" && emailDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickEmailAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "Email" && emailDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickEmailDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                            setEmailDescending(!reportingAccessOnlyDescending);
                            sortResultsBy("Email", !reportingAccessOnlyDescending);
                          }}>
                          {"Report Only Admin" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "ReportingAccessOnly" &&reportingAccessOnlyDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickReportingAccessOnlyAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "ReportingAccessOnly" &&reportingAccessOnlyDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickReportingAccessOnlyDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>  
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setExternalidDescending(!externalidDescending);
                                sortResultsBy("ExternalID", !externalidDescending);
                              } }>
                                {"External ID" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "ExternalID" && externalidDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickExternalIDAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "ExternalID" && externalidDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickExternalIDDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setGroupbyDescending(!groupbyDescending);
                                sortResultsBy("GroupBy", !groupbyDescending);
                              } }>
                                {"Group By" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "GroupBy" && groupbyDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickGroupByAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "GroupBy" && groupbyDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickGroupByDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 0 0 0" }} align="left">
                            <div className={styles.tableHeadCellContainer}>
                              <div onClick={() => {
                                setValueDescending(!valueDescending);
                                sortResultsBy("Value", !valueDescending);
                              } }>
                                {"Value" /* TODO translate */}
                              </div>
                              <div className={styles.columnHeadArea}>
                                <span style={{ opacity: currentSortBy === "Value" && valueDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickValueAsc}>
                                  <UpArrowIcon />{" "}
                                </span>
                                <span style={{ opacity: currentSortBy === "Value" && valueDescending === true ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }} onClick={clickValueDesc}>
                                  <DownArrowIcon />{" "}
                                </span>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="left">
                            <div className={styles.tableHeadCellContainer}>&nbsp; </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {unitAdministratorReport
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((result: UnitAdministratorReportResultItem, i) => {
                            return (
                              <TableRow key={`UserDetailReportResult-${i}`} style={rowBackgroundColor(i)}>
                                <TableCell align="left">{result.unitpathway}</TableCell>
                                <TableCell align="left">{result.lastname}</TableCell>
                                <TableCell align="left">{result.firstname}</TableCell>
                                <TableCell align="left">{result.username}</TableCell>
                                {includeInactiveUsers && <TableCell align="center">{result.flag}</TableCell>}
                                <TableCell align="left">{result.email}</TableCell>
                                <TableCell align="left">{result.reportingAccessOnly}</TableCell>
                                <TableCell align="left">{result.externalid}</TableCell>
                                <TableCell align="left">{result.groupby}</TableCell>
                                <TableCell align="left">{result.value}</TableCell>
                                <TableCell align="left">
                                  <CustomTooltip displayText={["Remove Unit Admin"]}>
                                    <span className={styles.deleteIcon} 
                                      // onClick={() => handleDeleteUnitAdmin(result.userID)}
                                      onClick={() => handleDeleteUnitAdmin(result.userID)}>
                                      <DeleteIcon />
                                    </span>
                                  </CustomTooltip>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </BrandedCard>
              </div>
            </div>
            <div
              className={styles.paginationControlsContainer}
              style={{ justifyContent: "center" }}
            >
              <TablePagination
                component="div"
                count={unitAdministratorReport.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
            </div>
          </div>}
        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
      </></>
  );
};

export default UnitAdministratorReportSection;
