//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { User, Language, UnitTreeItem, CourseTreeItem } from '../../globalTypes';
import { useHistory , useLocation } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import oidcConfig from '../../configuration';
//API Functions
import { getUnitTree } from '../../API/GetUnitTree';
import { getRelevantUnitTree } from '../../API/GetRelevantUnitTree';
import { getCourseListByOrganisation } from '../../API/GetCourseListByOrganisation'
import { getFullSummaryReport } from '../../API/GetFullSummaryReport' 
import { addError } from '../../API/AddError'
import { getClassificationListByType } from '../../API/GetClassificationListByType';
import getClassificationType from '../../API/GetClassificationType';
//Components
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard'
import UnitFilterButtons from '../CurrentAdminReport/UnitFilterButtons'
import CourseFilterButtons from '../CurrentAdminReport/CourseFilterButtons'
import CourseListTree from '../CurrentAdminReport/CourseListTree'
import UnitTree from '../CurrentAdminReport/UnitTree'
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
import ReportSchedulerModal from '../CurrentAdminReport/ReportSchedulerModal'
import CustomTooltip from "../../Components/CustomTooltip";
import GuideIcon from '../../CustomIcons/GuideIcon';
//import MUICustomSelect from '../../Components/MUICustomSelect';
//MUI Components
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from "@material-ui/icons/Search";
//Styles
import styles from '../../styles/currentAdminReport.module.css'
import { IconButton } from '@material-ui/core';

import SaveAPILibraryLogs from '../../API/Administration/Organisation/SaveAPILibraryLogs';
import Modal from '../../Components/Modal';
import ProceedIcon from '../../CustomIcons/ProceedIcon';
import CloseIcon from '../../CustomIcons/CloseIcon';
import ReportsParametersPreview from '../Administration/APILibrary/ReportParametersPreview';
interface Props {
    
}

const SummaryReport = (props: Props) => {
     const location = useLocation<any>();
    const [units, setUnits] = useState<Array<UnitTreeItem>>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([]) 
    const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree
    const [loading, setLoading] = useState<boolean>(true)
    
    const [filterDateFrom, setFilterDateFrom] = useState("");
    const [filterUnitName, setFilterUnitName]  = useState("");
    const [filterCourseName, setFilterCourseName]  = useState("");
    const [filterIncludeInactive, setFilterIncludeInactive] = useState<boolean>(true);

    const [filterSubject, setFilterSubject] = useState("");
    const [filterBody, setFilterBody] = useState("");
    const [filterQuizStatus, setFilterQuizStatus] = useState("");
    const [filterSortBy, setFilterSortBy] = useState("0");
    const [filterExpiryValue, setFilterExpiryValue] = useState(0);
    const [filterExpiryUnit, setFilterExpiryUnit] = useState("d");
    const [filterAcceptance, setFilterAcceptance] = useState("0");
    const [filterOnlyUsersWithShortfall, setFilterOnlyUsersWithShortfall] = useState("N");
    const [filterLicensingPeriod, setFilterLicensingPeriod] = useState("");
    const [filterHistoricCourseIds, setFilterHistoricCourseIds] = useState("");
    const [filterPolicyIds, setFilterPolicyIds] = useState("");
    const [filterCourseStatus, setFilterCourseStatus] = useState("");
    const [filterDateTo, setFilterDateTo] = useState("");
    
    const [complete, setCompletee] = useState<number>(0) 
    const [incomplete, setIncomplete] = useState<number>(0)     
    const [count, setCount] = useState<number>(0) 
    const [selectedCourseID, setSelectedCourseID] = useState<number>(0) 
    const [selectedCourseName, setSelectedCourseName] = useState<string>("") 
    //const [classificationList, setClassificationList] = useState<Array<any>>([]);
    const [classificationList, setClassificationList] = useState([]);
    const [selectedClassification, setSelectedClassification] = useState<any>({});
    const [groupingOption, setGroupingOption] = useState<boolean>(false);
    const [isSmallUnitTree, setSmallUnitTree] = useState<boolean>(true);

    const [isGroupingOptionFocussed, setIsGroupingOptionFocussed] = useState<boolean>(false);

    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);

    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();

    const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles} = useContext(TranslationsContext)
    const { showSSOExitButton, EnableActivityLogs,IsAPILibraryEnabled} = useContext(UIContext);
    const { cpdList, currentCpd } = useContext(CPDPanelContext);
    const orgDomainWithAltLogin = window.location.host.toLowerCase();


 const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);
 const [showAPIModal, setAPIModalOpen] = useState<boolean>(false);   



    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".unitFilterButtons",
            title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,  
          }              
        ]
      })
const setGuideMe = (groupingOption: boolean) => {
                    let updateSteps = [...intro.steps]
                    updateSteps.push(       
                        {
                            element: ".unitFilterSearch",
                            title: "Filter Units", intro: allResourcesToTranslations.unitfiltersearch                  
                        }
                    );   
                    if (units.length <= 10) {
                    updateSteps.push(      
                        {
                        element: ".courseFilterButtons",                   
                        title: "Courses", intro: allResourcesToTranslations.emrepgmcsm
                        },
                        {
                            element: ".courseFilterSearch",
                            title: "Filter Courses", intro: allResourcesToTranslations.emrepgmfcsm
                        }
                    ); 
                    }
                    else {
                        updateSteps.push(      
                        {
                            element: ".courseFilterButtonsnew",                   
                            title: "Courses", intro: allResourcesToTranslations.emrepgmcsm
                        },
                        {
                            element: ".courseFilterSearchnew",
                            title: "Filter Courses", intro: allResourcesToTranslations.emrepgmfcsm
                        }
                        ); 
                    }                  
                    updateSteps.push(      
                    {
                        element:  ".dateFrom",
                        title: "Filter", intro: allResourcesToTranslations.emrepgmfdfm
                    },
                    {
                        element:  ".dateTo",
                        title: "Filter", intro: allResourcesToTranslations.emrepgmfdtm
                    }
                    );
                    if (groupingOption)
                    {
                        updateSteps.push({
                            element: ".groupby",
                            title: "Filter", intro: allResourcesToTranslations.repgmgroupingoption
                        });
                    }
                    updateSteps.push(
                        {
                            element:  ".runReport",
                            title: "Run Report", intro: allResourcesToTranslations.sntemrepgmmrunrep
                        }); 
                
                        if (IsAPILibraryEnabled)
                            {
                                updateSteps.push({
                                    element:  ".apicall",
                                    title: "API Call", intro: allResourcesToTranslations.guidemeapicall
                                });
                            }
    
                        updateSteps.push(
                        {
                          element:  ".scheduleReport",
                          title: "Schedule Report", intro: allResourcesToTranslations.repgmschedulereport
                        },
                        {
                          element:  ".resetAll",
                          title: "Reset Filters", intro: allResourcesToTranslations.emrepgmram
                        }    
                    )
                    setIntro({...intro, steps: [...updateSteps]})              
   };
   const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
    };

 useEffect(() => {
        setGuideMe(groupingOption);
    },[IsAPILibraryEnabled]);

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (!isNaN(givenDate.getTime())) {
            const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
            const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
            const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
    
            return (year.toString() + "-" + 
            (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
            (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
        }
        else {
            return "";
        }
    }

    const updateSelectedUnit = (id: number) => {
        let updatedUnits = units
        const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isSelected = !unit.isSelected
                } 
                updateSelected(unit.childUnitTreeItems)
            }
        }
        updateSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const updateExpandedUnit = (id: number) => {
        let updatedUnits = units
        const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isExpanded = !unit.isExpanded
                } 
                checkForExpanded(unit.childUnitTreeItems)
            }
        }
        checkForExpanded(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = true;
                }
                updateAllChildUnits(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnits(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnitsToUnselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = false;
                }
                updateAllChildUnitsToUnselected(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnitsToUnselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
        let updatedUnits = givenUnits
        const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return false //Break if no children
            if (unitArray.length === 0) return false;
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected) {
                    return true;
                }
                if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
                    return true;
                }
            }
            return false;
        }
        return checkIfAnyUnitSelected(updatedUnits);
    }

    const expandAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = true;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const colapseAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = false;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllUnits = () => {
        let updatedUnits = units
        const setAllSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = true;
                    }
                    setAllSelected(unit.childUnitTreeItems);
                }                
            }
        }
        setAllSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllUnits = () => {
        let updatedUnits = units
        const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = false;
                    }
                    setAllDeselected(unit.childUnitTreeItems);
                }
            }
        }
        setAllDeselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const updateSelectedCourse = (id: number) => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (updatedCourses[i].courseID === id) {
                updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const selectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = true;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const deselectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = false;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const resetAll = () => {
        deselectAllCourses();
        deselectAllUnits();
        setFilterDateFrom('');
        setFilterDateTo('');
        setSelectedClassification({});
    }

     const handleCourseSelection =(courseId: number, courseName: string) =>
     {
         setSelectedCourseID(courseId);
         setSelectedCourseName(courseName);
     }

     const renderSelectValue = (values: Array<CourseTreeItem>, courseId: number) => {
         return values && values.find(v => v.courseID === courseId)?.name;
     }
    
     const getValidClassificationID = (selectedClassification: any) => {
         var classID : number = 0;
         if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
             classID = ((typeof selectedClassification.classificationID) !== 'number') ? 
                 parseInt(selectedClassification.classificationID.toString()) : 
                 selectedClassification.classificationID;
         }
         return classID;
     }

    const runReport = () => {
        let unitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        const firstName: string = oidcUser.profile.given_name ?? "";
        const lastName: string = oidcUser.profile.family_name ?? "";
        const email: string = oidcUser.profile.email ?? "";
        const userName: string = oidcUser.profile.user_name ?? "";
        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + 1);
        const nextDateString = `${nextDate.getUTCFullYear()}-${("0" + (nextDate.getUTCMonth() + 1)).slice(-2)}-${('0' + nextDate.getUTCDate() ).slice(-2)}` //TODO fix hard coded date
        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected) {
                    unitIds.push(unit.unitID)
                } 
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems)
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
             for (let i = 0; i < courseList.length; i++) {
                 if (courseList[i].isSelected) {
                     courseIds.push(courseList[i].courseID)
                 }
                 allCourseIds.push(courseList[i].courseID);
             }
        }
        getSelectedCourses()
        setMessage("Fetching your report");
        setLoading(true)

        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Summary Report in NEWSALT ClientApp", null, "SummaryReport.tsx", 6, EnableActivityLogs);

        getFullSummaryReport(oidcUser.access_token,
                    userId, 
                    orgDomain, 
                    parseInt(orgId), 
                    unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
                    courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString(),      
                    ((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.length < 8) ? "1990-01-01" : filterDateFrom),
                    ((filterDateTo === null || filterDateTo === undefined || filterDateTo.length < 8) ? formatDate(nextDate) : filterDateTo),
                    "",
                    "",
                    filterIncludeInactive,
                    getValidClassificationID(selectedClassification),
                    complete,
                    incomplete,
                    count               
            ).then((result) => {
                
            setLoading(false);
            var summaryUnitModifiedItems = (result === null ? null : 
                result.summaryreportItem.slice(0).filter(
                    (val : any, idx: number, arr: any[]) => arr.map(val2 => val2.unitPathway).
                    indexOf(val.unitPathway) === idx));

            var summaryParentChildMerged = [];       

            if(summaryUnitModifiedItems != null)
            {
                let isAnyHavingMultiCourses = false;
                for (let i = 0; summaryUnitModifiedItems.length > 1 && i < summaryUnitModifiedItems.length; i++) {
                    var curUnitPathway = summaryUnitModifiedItems[i].unitPathway;
                    let reqdArr  =  result.summaryreportItem.slice(0).filter((val : any, idx: number, arr: any[]) => val.unitPathway === curUnitPathway);
                    if (reqdArr !== null && 
                        reqdArr !== undefined &&
                        reqdArr.length > 1) {
                        isAnyHavingMultiCourses = true;
                        break;
                    }
                }

                // Iterate summaryUnitParentItems 
                // For each unit, get the subtotal complete, subtotal incomplete, subtotal  resp percent, subtotal total users, get new subarray also
                // and assign to the item of summaryUnitParentItems
                for (let i = 0; summaryUnitModifiedItems.length > 1 && i < summaryUnitModifiedItems.length; i++) {
                    var curUnitPathway = summaryUnitModifiedItems[i].unitPathway;

                    summaryUnitModifiedItems[i]["childrenOfUnitSummry"]  =  result.summaryreportItem.slice(0).filter((val : any, idx: number, arr: any[]) => val.unitPathway === curUnitPathway);
                    if (summaryUnitModifiedItems[i]["childrenOfUnitSummry"] !== null && 
                        summaryUnitModifiedItems[i]["childrenOfUnitSummry"] !== undefined &&
                        (isAnyHavingMultiCourses || summaryUnitModifiedItems[i]["childrenOfUnitSummry"].length > 1)) {
                        summaryUnitModifiedItems[i]["unitPathway2"] = curUnitPathway + " - " + "Sub Total";
                        summaryUnitModifiedItems[i]["courseName2"] =  "";
                        summaryUnitModifiedItems[i]["parentIndex"] =  i;
                        summaryUnitModifiedItems[i]["incomplete2"] = summaryUnitModifiedItems[i].childrenOfUnitSummry.map((v:any) => v.incomplete).reduce((s: any, a: any) => (s as number) + (a as number), 0);
                        summaryUnitModifiedItems[i]["complete2"] = summaryUnitModifiedItems[i].childrenOfUnitSummry.map((v:any) => v.complete).reduce((s: any, a: any) => (s as number) + (a as number), 0);
                        summaryUnitModifiedItems[i]["totalUsers2"] = summaryUnitModifiedItems[i].childrenOfUnitSummry.map((v:any) => v.totalUsers).reduce((s: any, a: any) => (s as number) + (a as number), 0);
                        //summaryUnitModifiedItems[i]["totalUsers2"] = result.summaryreportItem.reduce((s: any, a: any) => s + (a.unitPathway !== curUnitPathway ? 0 : a.totalUsers), 0);
                        summaryUnitModifiedItems[i]["incompletePercentage2"] = (result.summaryreportItem.reduce((s: any, a: any) => s + (a.unitPathway !== curUnitPathway ? 0 : a.incompletePercentage), 0)) / (summaryUnitModifiedItems[i]["childrenOfUnitSummry"]).length;
                        summaryUnitModifiedItems[i]["completePercentage2"] = (result.summaryreportItem.reduce((s: any, a: any) => s + (a.unitPathway !== curUnitPathway ? 0 : a.completePercentage), 0)) / (summaryUnitModifiedItems[i]["childrenOfUnitSummry"]).length;
                        summaryUnitModifiedItems[i]["length"] =  summaryUnitModifiedItems[i]["childrenOfUnitSummry"].length + 1;
                        
                    }
                    else if (summaryUnitModifiedItems[i]["childrenOfUnitSummry"] !== null && 
                            summaryUnitModifiedItems[i]["childrenOfUnitSummry"] !== undefined &&
                            (isAnyHavingMultiCourses || summaryUnitModifiedItems[i]["childrenOfUnitSummry"].length <= 1))
                    {
                        //summaryUnitModifiedItems[i]["childrenOfUnitSummry"] = [];
                    }

                    if (summaryUnitModifiedItems[i]["childrenOfUnitSummry"] !== null && 
                        summaryUnitModifiedItems[i]["childrenOfUnitSummry"] !== undefined &&
                        curUnitPathway !== "Grand Total" &&
                        (isAnyHavingMultiCourses || summaryUnitModifiedItems[i]["childrenOfUnitSummry"].length > 1)){
                        //summaryUnitModifiedItems[i]["childrenOfUnitSummry"].push(summaryUnitModifiedItems[i]);
                        var currntList: any = { ...summaryUnitModifiedItems[i] }
                        currntList.courseName = "";
                        (summaryUnitModifiedItems[i]["childrenOfUnitSummry"]).push(currntList);                       
                    }
                    summaryParentChildMerged.push(...summaryUnitModifiedItems[i]["childrenOfUnitSummry"]);
                } 
            }
          

            history.push({
                pathname: `/summaryreport/result`,
                state: {
                    //summaryUnitModifiedItems: summaryUnitModifiedItems,
                    //resultItems: (result === null ? null : result.summaryreportItem),
                    resultItems: summaryUnitModifiedItems,
                    resultItemsNested: summaryParentChildMerged,                    
                    resultSelectedCourse: (result === null ? null : result.selectedCourseName),
                    resultSummaryReportUniqueCourseListItems: (result === null ? null : result.summaryReportUniqueCourseListItems),
                    resultError: ((result === null || result === undefined || result.isSuccess || result.error === null || result.error === undefined) ? "" : result.error)
                },
            });
        })
    }
    
    const parseAdminConsoleUrl = (url: string): string => {
        if (url === "https://" + oidcConfig.stagingdomain || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomain + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainSA || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainSA + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainForDeactTest || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainForDeactTest + ":444";
        } else {
            if (showSSOExitButton && orgDomainWithAltLogin.toLowerCase().indexOf("altlogin-") < 0) {
                if (url.toLowerCase().includes('https:')) {
                    return `${url}:444`;
                }
                else if  (url.toLowerCase().includes('http:')) {
                    var locUrl = url.toLowerCase().replace("http:", "https:")
                    return `${locUrl}:444`;
                }
                else {
                    return `https://${url}:444`;
                }
            }
            else {
                return `${url}:444/altlogin.aspx`;
            }
        }
    }

    const setModalOpen = (isOpen: boolean) => 
    {
        let cpdProfileId = -1
        cpdProfileId = (
            (cpdList !== null && 
            cpdList !== undefined && 
            cpdList.length > 0 && 
            currentCpd >= 0 && 
            cpdList.length > currentCpd) ? 
            cpdList[currentCpd].id : -1)
        history.push({
            pathname: `/SR/reportscheduler/-1`,
            state: {                
                selectedUnits: units,
                selectedCourses: courseList,
                firstName: "",
                lastName: "",
                userName: "",
                email: "",
                isIncludeInactive: filterIncludeInactive,
                classificationList: classificationList,
                groupingOption: groupingOption,
                selectedClassification: selectedClassification,
                groupBy: ((selectedClassification === null || selectedClassification === undefined || 
                    selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value),
                selectedClassificationID: getValidClassificationID(selectedClassification),
                dateFrom: filterDateFrom,
                dateTo: filterDateTo,
                subject: filterSubject,
                body: filterBody,
                status: filterCourseStatus,
                quizStatus: filterQuizStatus,
                sortBy: filterSortBy,
                langCode: currentLanguage,
                expiryValue: filterExpiryValue,
                expiryUnit: filterExpiryUnit,
                profileID: cpdProfileId,
                acceptance: filterAcceptance,
                onlyUsersWithShortfall: filterOnlyUsersWithShortfall,
                licensingPeriod: filterLicensingPeriod,
                reportId: 22,  // 22 is for SR
                historicCourseIds: filterHistoricCourseIds, 
                policyIds: filterPolicyIds
            },
        }); 
    }

    const isGivenItemNameMatched = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        if (item === null || item === undefined || item.name === null || item.name === undefined) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
                return true;
            }
            return false;
        }
    }

    const isAnyChildItemNameMatched = (list: UnitTreeItem[] | null, givenFilterUnitName: string): boolean | undefined =>
    {
        if (list === null || list === undefined || list.length <= 0) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            var newList = list.filter((e) => { return (isGivenItemNameMatched(e, givenFilterUnitName) === true || 
                isAnyChildItemNameMatched(e.childUnitTreeItems, givenFilterUnitName) === true); });
            if (newList !== null && newList !== undefined && newList.length > 0) {
                return true;
            }
            return false;
        }
    }

    const updateUnitItemBasedOnNameMatch = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        let isMatchFound = false;
        if ((givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") && 
            item !== null && item !== undefined) {
            isMatchFound = true;
            item.isDisabled = !isMatchFound;
            item.isHidden = !isMatchFound;
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        else if (givenFilterUnitName !== null && givenFilterUnitName !== undefined && givenFilterUnitName.trimLeft().trimRight() !== "" && 
            item !== null && item !== undefined) {
                
            isMatchFound = (item.name !== null && item.name !== undefined && item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase()));
            if (isMatchFound) {
                item.isDisabled = false;
                item.isHidden = false;
            }
            else if (!isMatchFound && (item.childUnitTreeItems == null || item.childUnitTreeItems === undefined || item.childUnitTreeItems.length <= 0)){
                item.isDisabled = true;
                item.isHidden = true;
            }
            else if (!isMatchFound){
                let curAnyChildNameMatched = isAnyChildItemNameMatched(item.childUnitTreeItems, givenFilterUnitName);
                item.isDisabled = (curAnyChildNameMatched === true);
                item.isHidden = (curAnyChildNameMatched === false);
                isMatchFound = (curAnyChildNameMatched === true);
            }
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        return isMatchFound;
    }

    useEffect(() => {
        setTitle(allResourcesToTranslations.summaryreport)
        setTitleEnglish(pageTitles.summaryreport)
        updateBackButtonRoute("");
        getRelevantUnitTree(oidcUser.access_token, userId, orgDomain, true, parseInt(orgId)).then((unitTreeRes) => {
             if (unitTreeRes.isSuccess) {
                 //setUnits(unitTreeRes.unitTreeItems)
                 if (!!location && !!location.state) {
                    setUnits(location.state.selectedUnits);
                    } else {
                    setUnits(unitTreeRes.unitTreeItems);
                    }
                 setSmallUnitTree(unitTreeRes.unitTreeItems === null || unitTreeRes.unitTreeItems.length <= 10);
             }
             getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
                 if (courseListRes.isSuccess) {
                     //setCourseList(courseListRes.courselistItem)
                     if (!!location && !!location.state) {
                        setCourseList(location.state.selectedCourses);          
                      } else {        
                        setCourseList(courseListRes.courselistItem);
                      }  
                     handleCourseSelection(courseListRes.courselistItem[0].courseID, courseListRes.courselistItem[0].name)
                 }

                 getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {
                   
                    if(classType !== null && classType.classificationTypeIDs !== null && 
                       classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                        getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {
                            
                            if (data !== null && data.classificationlistItem !== null) {
                                setClassificationList(data.classificationlistItem)
                                setGroupingOption(true)
                                units.length = unitTreeRes.unitTreeItems.length;  
                                setGuideMe(true)
                            }
                            else {
                                setGroupingOption(false);
                            }
                        })
                    }
                    else {
                        setGroupingOption(false);
                    }
                    setLoading(false);
                    units.length = unitTreeRes.unitTreeItems.length;  
                    setGuideMe(groupingOption)
                })                                         
             })
         })
    },[]) 

    useEffect(() => {
        if (units !== null && units !== undefined && units.length > 0) {
            var newUnits = units.map(function(e) { 
                // const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && findUnitItemByText(e, filterUnitName.toLowerCase())); 
                //     e.isHidden = retFlag;
                //     e.isDisabled = retFlag;
                const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())); 
                return e;
            });
            setUnits(newUnits);
        }
    },[filterUnitName]) 

    useEffect(() => {
        if (courseList !== null && courseList !== undefined && courseList.length > 0) {
            var newCourses = courseList.map(function(e) { 
                if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === ""){
                    e.isHidden = false; 
                }
                else {
                    e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase())); 
                }
                return e;
            });
            setCourseList(newCourses);
        }
    },[filterCourseName]) 

 //#region API Library

    const GenerateAPIRequestBody = () => {       
        setAPIModalOpen(true);   
        setLoading(false)         
    } 

    const handleAPIModal = (value:boolean) => {
    setAPIModalOpen(value);
    }

    const runAPIQuery = () => {     
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        
    
        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate()+1);
        const nextDateString = formatDate(nextDate); 


        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                if (filterUnitName !== "") {
                    if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    unitIds.push(unit.unitID)
                    }
                } else {
                    unitIds.push(unit.unitID)
                }
                } 
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {        
            for (let i = 0; i < courseList.length; i++) {                
                if (courseList[i].isSelected) {                  
                    courseIds.push(courseList[i].courseID);
                }    
                allCourseIds.push(courseList[i].courseID);           
            }           
        }
        getSelectedCourses() 

let str=`{"ReportRequestingUserID":${userId},
"OrganisationID":${parseInt(orgId)},
"UnitIDs":"${unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString()}",
"CourseIDs":"${courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString()}",
"EffectiveFromDateOrg":"${((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.length < 8) ? "1990-01-01" : filterDateFrom)}",
"EffectiveToDateOrg":"${((filterDateTo === null || filterDateTo === undefined || filterDateTo.length < 8) ? formatDate(nextDate) : filterDateTo)}",
"ClassificationID":${getValidClassificationID(selectedClassification)}
}`;
     
        SaveAPILibraryLogs(oidcUser.access_token, userId, orgDomain, parseInt(orgId), "SummaryReport", str).then((res) => {
            if (res.isSuccess) { 
                history.push({
                    pathname: `apilibrarydetail/SummaryReport`,
                    state: {             
                        inputLogId: res.logsId      
                    },
                });

            }
            else {              
                setModalPopupMessage("API Library Log Save Failed!");
                setHasErrored(false);
                setShowWarningModal(true);
            }
        });
        setLoading(false)         
    } 

useEffect(() => {
            if (!!location && !!location.state) {  
            setClassificationList(location.state.classificationList);
            setGroupingOption(location.state.groupingOption);
            setSelectedClassification(location.state.selectedClassification);   
            setFilterIncludeInactive(location.state.inputFilterIncludeInactive);
            
            
                    
            let formatDateFrom = "";
            let tempDateFrom = [""];
            if (location.state.inputFilterDateFrom !== null && location.state.inputFilterDateFrom !== undefined && location.state.inputFilterDateFrom !== ""){
                formatDateFrom = location.state.inputFilterDateFrom.trimLeft().trimRight().split(" ")[0];
                if (formatDateFrom.indexOf("/") > 0) {
                  tempDateFrom = formatDateFrom.split("/");
                  setFilterDateFrom(formatDate(new Date(parseInt(tempDateFrom[2]), parseInt(tempDateFrom[1])-1, parseInt(tempDateFrom[0]))));
                } else {
                  setFilterDateFrom(formatDateFrom);
                }
              }  

              let formatDateTo = "";
              let tempDateTo = [""];
              if (location.state.inputFilterDateTo !== null && location.state.inputFilterDateTo !== undefined && location.state.inputFilterDateTo !== ""){
                formatDateTo = location.state.inputFilterDateTo.trimLeft().trimRight().split(" ")[0];
                if (formatDateTo.indexOf("/") > 0) {
                  tempDateTo = formatDateTo.split("/");
                  setFilterDateTo(formatDate(new Date(parseInt(tempDateTo[2]), parseInt(tempDateTo[1])-1, parseInt(tempDateTo[0]))));
                } else {
                  setFilterDateTo(formatDateTo);
                }       
              }


            }     
        
        }, [location]);
 //#endregion       
    
    if (loading) return <LoaderContainer/>

    return (
        <>
         <Modal type="warning" modalOpen={showAPIModal} setModalOpen={handleAPIModal} style={{ height: '232px', width: '381px' }}
            hideCloseBtn={true}>  
                    <ReportsParametersPreview  
                    units={units} 
                    courseList={courseList}
                    filterFirstName={''}
                    filterLastName={''}
                    filterUserName={''}
                    filterEmail={''}       
                    filterIncludeInactive={filterIncludeInactive}               
                    classificationList={classificationList}   
                    filterClassificationID={getValidClassificationID(selectedClassification)}
                    reportId={22}
                    report={'sr'}
                    filterDateFrom={filterDateFrom}
                    filterDateTo={filterDateFrom}
                    filterCourseStatus={filterCourseStatus}
                    ></ReportsParametersPreview>

            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '10px' }}>
            <CustomTooltip displayText={["Return"]}>
                <span className={styles.returnIcon} >
                <span
                    onClick={() => {
                    setAPIModalOpen(false)               
                    }}>
                    <CloseIcon />
                </span>
                </span>
            </CustomTooltip>
            <CustomTooltip displayText={["Proceed"]}>
                <span className={styles.proceedIcon}>
                <span
                    onClick={() => {
                        runAPIQuery();
                    }}>
                    <ProceedIcon />
                </span>
                </span>
            </CustomTooltip>
            </div>
            </Modal>
          <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep} onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
            }} />      
            <CustomTooltip displayText={["Guide Me"]}>               
                <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                        <GuideIcon />
                    </span>
            </CustomTooltip> 
            <BrandedCard>                
                <div className={styles.reportWrraper}>
                <div className={styles.unittreesection}>
                <div>
                            <h4 className={styles.sectionTitle}>Unit</h4>
                            <p className={styles.blankContent}>Leave blank for all Units</p>
                            <div className="unitFilterButtons"><UnitFilterButtons expandAllUnits={expandAllUnits} colapseAllUnits={colapseAllUnits} 
                            selectAllUnits={selectAllUnits} deselectAllUnits={deselectAllUnits}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="unitFilterSearch"><TextField  className={styles.input}  label={"Unit Search"} variant="outlined" autoComplete="off"  
                            InputLabelProps={{shrink: true,}}                      
                            value={filterUnitName} onChange={(e) => setFilterUnitName(e.target.value)} 
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.treeContianer}>
                            <UnitTree units={units} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits}
                             deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} />
                        </div>
                         <div hidden={isSmallUnitTree === false}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div className="courseFilterButtons"><CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === false}>
                            <div className="courseFilterSearch"> <TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  
                            InputLabelProps={{shrink: true,}}           
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === false}>                        
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltCSR_" />
                        </div> 
                    </div>
                    <div className={styles.coursetreesection}>
                    <div hidden={isSmallUnitTree === true}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div className="courseFilterButtonsnew"> <CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === true}>
                            <div className="courseFilterSearchnew"><TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>       
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === true}>
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltSR_" />
                        </div>                       
                        {/* <div className={styles.groupingOption}>
                            <FormControl variant="outlined" className={styles.formControl}>            
                                <InputLabel id="coursecombolistinp">Course</InputLabel>
                                <Select                                   
                                    value={courseList} 
                                    renderValue={() => renderSelectValue(courseList, selectedCourseID)}
                                    onChange=
                                    {
                                        (e) => 
                                        {
                                            setSelectedCourseID((e.target.value as any).courseID as number)
                                            setSelectedCourseName((e.target.value as any).name as string)
                                            //console.log("selectedCourseID " + selectedCourseID)
                                            //console.log("selectedCourseName " + selectedCourseName)
                                        }
                                    }
                                    className={styles.input}
                                    variant="outlined" 
                                    label="Course List"
                                    id="coursecombolist">
                                    {
                                        courseList?.map((item:any) => {
                                            return (
                                                <MenuItem value={item}>{item.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div> */}
                        <div className={styles.inputContainer}>
                            <div className="dateFrom"><TextField  className={styles.input}  label={"Date From"} variant="outlined" autoComplete="off"  
                            InputLabelProps={{shrink: true,}}                      
                            type="date" defaultValue={filterDateFrom} onChange={(e) => setFilterDateFrom(formatDate(new Date(e.target.value)))}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="dateTo"><TextField  className={styles.input}  label={"Date To"} variant="outlined" autoComplete="off"  
                            InputLabelProps={{shrink: true,}}                      
                            type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))}/></div>
                        </div>
                        {groupingOption && 
                         <div className="groupby">
                            <div className={styles.groupingOption}>
                                <FormControl variant="outlined" className={styles.formControl}>
                        
                                    <InputLabel id="grouping">Grouping Option</InputLabel>
                                    <Select
                                    value={selectedClassification.value}
                                    native
                                    onChange={(e) => setSelectedClassification({value: e.target.value, classificationID: e.target.value})}
                                    className={styles.input}
                                    variant="outlined" 
                                    label="Grouping Option"
                                    id="grouping"
                                    >
                                    {classificationList.map((item:any) => {
                                        return <option value={item.value}>{item.text}</option>
                                    })}
                                     </Select> 
                                </FormControl>
                                {/* <MUICustomSelect idName={"groupingOption"} labelName={"Grouping Option"} currentValue={selectedClassification.value} 
                                                setCurrentValue={(e : any) => 
                                                {
                                                    if (!!e.target) {
                                                        setSelectedClassification({ value: e.target.value, classificationID: e.target.value, 
                                                            text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text });
                                                    }
                                                    else {
                                                        let filtered = !!classificationList ? classificationList.filter((cur : any) => !!cur && 
                                                                        parseInt(cur.classificationID) === parseInt(e)) : null;
                                                        if (!!filtered && filtered.length > 0){                                                                
                                                            setSelectedClassification({ value: filtered[0].value, classificationID: filtered[0].value, 
                                                                text: filtered[0].text });
                                                        }
                                                    }
                                                } }
                                                isFocussed={isGroupingOptionFocussed} setIsFocussed={setIsGroupingOptionFocussed}
                                                inputLabelStyle={{}} listOfItems={classificationList}/> */}
                            </div>
                        </div>}
                        <div>
                            <ButtonPanel openReportSchedulerModal={setModalOpen} showScheduler runReport={runReport}  resetAll={resetAll} showAPIbutton={IsAPILibraryEnabled ? true:false}  runAPIQuery={GenerateAPIRequestBody}/>
                        </div>
                    </div>    
                </div>
            </BrandedCard>
            {/* <ReportSchedulerModal showModal={showModal} setModalOpen={setModalOpen} /> */}
        </>
    )
}

export default SummaryReport
