import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ProgressComplete = () => (    
<svg width="125" height="2" viewBox="0 0 125 2" fill="none" xmlns="http://www.w3.org/2000/svg">
<path width="125" height="2" fill="#63C2FF" d="M0 0H125V3.571H0V0z"/>
</svg>

);

export default ProgressComplete