// React
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps } from "intro.js-react";

//Styles
//import styles from "../../../styles/organisationmanagement.module.css";
import styles from "../../../styles/cpddetails.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";

// Componenets
import CustomTooltip from "../../../Components/CustomTooltip";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import BrandedCard from "../../../Components/BrandedCard";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import LoaderContainer from "../../../Components/LoaderContainer";

// Functions
import getColor from "../../../functions/getColor";

// Contexts
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";

// Types
import { CPDProfile, CPDReportResultMetaData } from "../../../globalTypes";
import { ChangedUsersAndUnits } from "../../../globalTypes";

// ICONs
import SaveIcon from "../../../CustomIcons/SaveIcon";
import SaveIconClone from "../../../CustomIcons/SaveIconClone";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import Modal from '../../../Components/Modal';
import CloseIcon from "../../../CustomIcons/Administration/closeIcon";
import ProceedIcon from "../../../CustomIcons/ProceedIcon";

// APIs
import GetGivenCPDProfile from "../../../API/Administration/CPDProfiles/GetGivenCPDProfile";
import SaveGivenCPDProfile from "../../../API/Administration/CPDProfiles/SaveGivenCPDProfile";
import SaveGivenCPDProfilePoints from "../../../API/Administration/CPDProfiles/SaveGivenCPDProfilePoints";
import GetProfileAccess from "../../../API/Administration/CPDProfiles/GetProfileAccess";
import SetProfileAccess from "../../../API/Administration/CPDProfiles/SetProfileAccess";
import SetProfileAccessSpecific from "../../../API/Administration/CPDProfiles/SetProfileAccessSpecific";
import GetProfileUsers from "../../../API/Administration/CPDProfiles/GetProfileUsers";
import getOrgCPDProfile from "../../../API/GetOrgCPDProfile";
import GetCPDProfilesList from "../../../API/Administration/CPDProfiles/GetCPDProfilesList";
import getFullCPDReport from "../../../API/GetFullCPDReport";

// Material UI
import { Accordion, AccordionDetails, AccordionSummary, Typography, 
         Button, Checkbox, CircularProgress, FormControl, InputLabel, 
         Select, TextField, Radio, FormLabel, FormControlLabel, RadioGroup } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseWhiteIcon from "@material-ui/icons/Close";

// For Advanced CPD Profile Edit functionalities
import AssignButtonPanel from "../PolicyBuilderSettings/AssignButtonPanel";
import AssignUnits from "../PolicyBuilderSettings/AssignUnits";
import AssignPoints from "./AssignPoints";
import AccountManagement from "../AccountManagement/AccountManagement";
import UserListSection from "../AccountManagement/UserListSection";
import CPDReportSection from "./CPDReportSection";

const CPDProfileDetails = () => {
    const { cpdProfileIdToEdit, cpdProfilePeriodIdToEdit } = 
            useParams<{ cpdProfileIdToEdit: string, cpdProfilePeriodIdToEdit: string }>();
    const history = useHistory();
    const {
        translations: { allResourcesToTranslations }, pageTitles
    } = useContext(TranslationsContext);
    const { setTitle, updateBackButtonRoute, setTitleEnglish, setIsCPDAssignPointsGuidemeClicked } =
        useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const {
        profile: { user_id, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
        ? website
            .toLowerCase()
            .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
        : "";
    const [loading, setLoading] = useState<boolean>(false);
    const { theme: { color } } = useContext(CorperateThemeContext);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [showWindowMidWarningModalForWarning, setShowWindowMidWarningModalForWarning] = useState<boolean>(false);
    const [showWindowMidWarningModalForConfirmation, setShowWindowMidWarningModalForConfirmation] = useState<boolean>(false);
    const [windowMidModelMessage, setWindowMidModelMessage] = useState<string>("");    
    const [statusfield, setStatusfield] = useState<number>(1);
    const [confirmationMessage, setConfirmationMessage] = useState<string>("");

    // CPD Details
    const [cpdProfileID, setProfileID] = useState<number>(-1);
    const [cpdProfilePeriodID, setProfilePeriodID] = useState<number>(-1);
    const [cpdProfileStatus, setCPDProfileStatus] = useState<string>("");
    const [cpdProfileName, setCPDProfileName] = useState<string>("");
    const [cpdDateStart, setCPDDateStart] = useState<Date | null>(null);
    const [cpdDateEnd, setCPDDateEnd] = useState<Date | null>(null);
    const [cpdFutureDateStart, setCPDFutureDateStart] = useState<Date | null>(null);
    const [cpdFutureDateEnd, setCPDFutureDateEnd] = useState<Date | null>(null);
    const [cpdPoints, setCPDPoints] = useState<number>(-1);
    const [cpdFuturePoints, setCPDFuturePoints] = useState<number>(-1);
    const [cpdEndOfPeriodAction, setCPDEndOfPeriodAction] = useState<string>("1");
    const [cpdFutureMonthIncrement, setCPDFutureMonthIncrement] = useState<number | null>(null);
    const [cpdApplyToQuiz, setCPDApplyToQuiz] = useState<number | null>(null);
    const [cpdApplyToLesson, setCPDApplyToLesson] = useState<number | null>(null);
    const [cpdApplyToNewsfeed, setCPDApplyToNewsfeed] = useState<number>(0);
    const [cpdCurrentPeriod, setCPDCurrentPeriod] = useState<string>("");
    const [cpdFuturePeriod, setCPDFuturePeriod] = useState<string>("");
    const [savedProfileID, setSavedProfileID] = useState<number>(-1);
    const [savedProfilePeriodID, setSavedProfilePeriodID] = useState<number>(-1);
    const [refreshAfterSave, setRefreshAfterSave] = useState<boolean>(false);
    const [finalSave, setFinalSave] = useState<boolean>(false);
    const [guideMeClicked, setGuideMeClicked] = useState<boolean>(false);
    // CPD Details 

    const [currentPeriodStartDate, setCurrentPeriodStartDate] = useState<string>("");
    const [currentPeriodEndDate, setCurrentPeriodEndDate] = useState<string>("");
    const [futurePeriodStartDate, setFuturePeriodStartDate] = useState<string>("");
    const [futurePeriodEndDate, setFuturePeriodEndDate] = useState<string>("");   
    const [postEndOfPeriodActionType, setPostEndOfPeriodActionType] = 
                    useState<string>(allResourcesToTranslations.attheendofperiodnoaction);
    
    const [profileEnabled, setProfileEnabled] = useState<boolean>(true);
    const [profileEnabledModified, setProfileEnabledModified] = useState<boolean>(true);
    const [cpdProfileNameModified, setCPDProfileNameModified] = useState<string>("");
    const [cpdPointsModified, setCPDPointsModified] = useState<number>(-1);
    const [cpdPointsModifiedTxt, setCPDPointsModifiedTxt] = useState<string>("");
    const [cpdFuturePointsModified, setCPDFuturePointsModified] = useState<number>(-1);
    const [cpdFuturePointsModifiedTxt, setCPDFuturePointsModifiedTxt] = useState<string>("");
    const [cpdFutureMonthIncrementModified, setCPDFutureMonthIncrementModified] = useState<number | null>(null);
    const [currentPeriodStartDateModified, setCurrentPeriodStartDateModified] = useState<string>("");
    const [currentPeriodEndDateModified, setCurrentPeriodEndDateModified] = useState<string>("");
    const [futurePeriodStartDateModified, setFuturePeriodStartDateModified] = useState<string>("");
    const [futurePeriodEndDateModified, setFuturePeriodEndDateModified] = useState<string>("");   
    const [postEndOfPeriodActionTypeModified, setPostEndOfPeriodActionTypeModified] = 
                    useState<string>(allResourcesToTranslations.attheendofperiodnoaction);

    // CPD Cloning    
    const [clonedCPDProfileID, setClonedCPDProfileID] = useState<number>(-1);   
    const [clonedCPDProfilePeriodID, setClonedCPDProfilePeriodID] = useState<number>(-1);   
    const [isOrgCPDsLoading, setIsOrgCPDsLoading] = useState<boolean>(true);   
    const [orgCPDProfileResult, setOrgCPDProfileResult] = useState<any>(null);   
    const [selectedCPDProfile, setSelectedCPDProfile] = useState<any>({profileID : -1, profileName: "", profilePeriodId: -1, profileStatus: "",
                                                                        dateStart: null, dateEnd: null, futureDateStart: null, futureDateEnd: null, dateStartUTC: null, dateEndUTC: null, 
                                                                        futureDateStartUTC: null, futureDateEndUTC: null, currentPeriod: "", futurePeriod: "", points: 0.0, futurePoints: 0.0,
                                                                        endOfPeriodAction: "1", futureMonthIncrement: null, applyToQuiz: null, applyToLesson: null});
    // CPD Cloning

    // Accordian Expander Related states
    const [expandProfileBasicDetails, setExpandProfileBasicDetails] = useState<boolean>(true);
    // Accordian Expander Related states

    // For Advanced CPD Profile Edit functionalities
    const [profileAssignedUsersList, setProfileAssignedUsersList] = React.useState<Array<any>>([]);
    //const [selectedUnits, setSelectedUnits] = useState<string>("");
    const [showBasicCPDDetails, setShowBasicCPDDetails] = useState<boolean>(true);
    const [showAssignPoints, setShowAssignPoints] = useState<boolean>(false);
    const [showAssignUnits, setShowAssignUnits] = useState<boolean>(false);
    const [showAssignAccounts, setShowAssignAccounts] = useState<boolean>(false);
    const [showViewAccounts, setShowViewAccounts] = useState<boolean>(false);
    //const [showResetAccounts, setShowResetAccounts] = useState<boolean>(false);
    //const [updatedSelectedUnits, setUpdatedSelectedUnits] = useState<string>("");
    const [completedUsersToExclude, setCompletedUsersToExclude] = useState<boolean>(true);
    const [changedModuleIDsToPoints, setChangedModuleIDsToPoints] = useState<string>(""); // <moduleid1>:<points>&<moduleid2>:<points>.....
    const [changedPolicyIDsToPoints, setChangedPolicyIDsToPoints] = useState<string>(""); // <policyid1>:<points>&<policyid2>:<points>.....
    const [changedExtCertIDsToPoints, setChangedExtCertIDsToPoints] = useState<string>(""); // <extCertid1>:<points>&<extCertid2>:<points>.....
    const [moduleIDsToPointsInitial, setModuleIDsToPointsInitial] = useState<string>(""); // <moduleid1>:<points>&<moduleid2>:<points>.....
    const [policyIDsToPointsInitial, setPolicyIDsToPointsInitial] = useState<string>(""); // <policyid1>:<points>&<policyid2>:<points>.....
    const [extCertIDsToPointsInitial, setExtCertIDsToPointsInitial] = useState<string>(""); // <policyid1>:<points>&<policyid2>:<points>.....
    const [applyToQuizValueInitial, setApplyToQuizValueInitial] = useState<boolean>(false);
    const [applyToLessonValueInitial, setApplyToLessonValueInitial] = useState<boolean>(false);
    const [applyToNewsfeedValueInitial, setApplyToNewsfeedValueInitial] = useState<boolean>(false);
    const [excludeCompletedUsersValueInitial, setExcludeCompletedUsersValueInitial] = useState<boolean>(true);
    const [csvOfAllDeniedUnits, setCSVOfAllDeniedUnits] = useState<string>("");

    const [hasPointsLoaded, setHasPointsLoaded] = useState<boolean>(true);
    const [hasUnitsLoaded, setHasUnitsLoaded] = useState<boolean>(true);
    const [hasAccountsSearched, setHasAccountsSearched] = useState<boolean>(true);
    const [hasAccountsSearchStarted, setHasAccountsSearchStarted] = useState<boolean>(false);
    const [hasAccountsViewed, setHasAccountsViewed] = useState<boolean>(true);
    const [selectedUnitsCSVInitial, setSelectedUnitsCSVInitial] = useState<string>("");
    const [selectedUsersCSVInitial, setSelectedUsersCSVInitial] = useState<string>("");
    const [updatedSelectedUnitIds, setUpdatedSelectedUnitIds] = useState<string[]>([]);
    const [updatedSelectedUsers, setUpdatedSelectedUsers] = useState<any[]>([]);
    const [finalSavePoints, setFinalSavePoints] = useState<boolean>(false);
    const [finalSaveUnits, setFinalSaveUnits] = useState<boolean>(false);
    const [finalSaveAccounts, setFinalSaveAccounts] = useState<boolean>(false);
    const [finalSaveRemoveAccounts, setFinalSaveRemoveAccounts] = useState<boolean>(false);
    const [isSavingPoints, setIsSavingPoints] = useState<boolean>(false);
    const [isSavingUnits, setIsSavingUnits] = useState<boolean>(false);
    const [isSavingAccounts, setIsSavingAccounts] = useState<boolean>(false);
    const [isSavingAccountRemovals, setIsSavingAccountRemovals] = useState<boolean>(false);
    const [needLatestUnits, setNeedLatestUnits] = useState<boolean>(false);
    const [checkForNoUnitChanges, setCheckForNoUnitChanges] = useState<boolean>(false);
    const [needLatestAccounts, setNeedLatestAccounts] = useState<boolean>(false);
    const [needLatestViewAccounts, setNeedLatestViewAccounts] = useState<boolean>(false);
    //const [hasSelectedUsersChanged, setHasSelectedUsersChanged] = useState<boolean>(false);
    const [showViewAccountsLoaded, setShowViewAccountsLoaded] = useState<boolean>(false);
    const [isSameUnitOverriding, setIsSameUnitOverriding] = useState<boolean>(false);
    const [isSameUserOverriding, setIsSameUserOverriding] = useState<boolean>(false);
    // For Advanced CPD Profile Edit functionalities

    const [currentCPDReportOpen, setCurrentCPDReportOpen] = useState<boolean>(false);
    const [expandCurrentCPDReportList, setExpandCurrentCPDReportList] = useState<boolean>(false);
    const [currentCPDReportResultList, setCurrentCPDReportResultList] = useState<Array<any>>([]);
    const [loadedProfile, setLoadedProfile] = useState<any>({});
    const [currentCPDReportError, setCurrentCPDReportError] = useState<string>("");
    const [currentCPDReportResult, setCurrentCPDReportResult] = useState<any>({});
    const [isReportRunning, setIsReportRunning] = useState<boolean>(false);

    const [isGuideMeWithAssignPoints, setIsGuideMeWithAssignPoints] = useState<boolean>(false);
        
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: parseInt(cpdProfileIdToEdit) < 0 ? ".addnewprofileguideme" : ".editprofileguideme",
                title: parseInt(cpdProfileIdToEdit) < 0 ? allResourcesToTranslations.addnewprofile : allResourcesToTranslations.editprofile, 
                intro: parseInt(cpdProfileIdToEdit) < 0 ? allResourcesToTranslations.addnewprofileguideme : allResourcesToTranslations.editprofileguideme
            }
        ]
    });

    const resetAllAssignLoadedFlags = () => { 
        setHasPointsLoaded(false);   
        setHasUnitsLoaded(false);   
        setHasAccountsSearched(false);
        setHasAccountsViewed(false);
    }

    const handleAssignPoints = () => {
        resetAllAssignLoadedFlags();

        setShowAssignPoints(true);
        setExpandProfileBasicDetails(false);
        setShowAssignAccounts(false);
        setShowAssignUnits(false);
        setShowViewAccounts(false);
        setGuideMe(true, false, false, false);
    }

    const handleAssignUnits = () => {
        resetAllAssignLoadedFlags();
        
        setShowAssignUnits(true);
        //loadGrantedUnits();
        loadGrantedUnitsAndUsers(); // Need both users and units to store the initial list for calculating only the changes
        loadGrantedUserDetails("view");
        setExpandProfileBasicDetails(false);
        setShowAssignPoints(false);
        setShowAssignAccounts(false);
        setShowViewAccounts(false);
        setGuideMe(false, true, false, false);
    }

    const handleAssignAccounts = () => {
        resetAllAssignLoadedFlags();

        setShowAssignAccounts(true);
        loadGrantedUsers();
        loadGrantedUserDetails("view");
        setExpandProfileBasicDetails(false);
        setShowAssignPoints(false);
        setShowAssignUnits(false);
        setShowViewAccounts(false);
        setGuideMe(false, false, true, false);
    }

    const handleViewAccounts = () => {
        resetAllAssignLoadedFlags();

        setShowViewAccounts(true);
        loadGrantedUserDetails("view");
        setExpandProfileBasicDetails(false);
        setShowAssignPoints(false);
        setShowAssignAccounts(false);
        setShowAssignUnits(false);
        setGuideMe(false, false, false, true);
    }

    const setRecordsSearchStartedFunction = (hasSearchStarted: boolean) => {
        setHasAccountsSearchStarted(hasSearchStarted);
    }

    const isAnyValueChanged = () => {
        if (parseInt(cpdProfileIdToEdit) < 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return true;
        }
        if (profileEnabled != profileEnabledModified || 
            cpdProfileName != cpdProfileNameModified || 
            cpdPoints != cpdPointsModified || 
            cpdFuturePoints != cpdFuturePointsModified || 
            cpdFutureMonthIncrement != cpdFutureMonthIncrementModified ||
            currentPeriodStartDate != currentPeriodStartDateModified || currentPeriodEndDate != currentPeriodEndDateModified || 
            futurePeriodStartDate != futurePeriodStartDateModified || futurePeriodEndDate != futurePeriodEndDateModified || 
            postEndOfPeriodActionType != postEndOfPeriodActionTypeModified) {
            return true;
        } 
        return false;
    }
    
    const checkIfModulePointsChanged = () => {
        return false;
    }
    
    const checkIfPolicyPointsChanged = () => {
        return false;
    }

    const isAnyPointsValueChanged = () => {
        return true; // For assign point always enable the save as the last card options may be applied anytime without any other changes

        // if (parseInt(cpdProfileIdToEdit) < 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
        //     return true;
        // }
        // if (((cpdApplyToLesson == 1) != (applyToLessonValueInitial == true)) || 
        //     ((cpdApplyToQuiz == 1) != (applyToQuizValueInitial == true)) || 
        //     excludeCompletedUsersValueInitial != true || 
        //     checkIfModulePointsChanged() ||
        //     checkIfPolicyPointsChanged() ||
        //     (!!changedModuleIDsToPoints && changedModuleIDsToPoints.trim() != "" && changedModuleIDsToPoints.trim().includes(":")) ||
        //     (!!changedPolicyIDsToPoints && changedPolicyIDsToPoints.trim() != "" && changedPolicyIDsToPoints.trim().includes(":"))) {
        //     return true;
        // } 
        // return false;
    }

    const isAnyUnitsSelectionChanged = () => {
        if (parseInt(cpdProfileIdToEdit) < 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return true;
        }
        return true; // For now always return as something changed
    }

    const isAnyAccountsSelectionChanged = () => {
        if (parseInt(cpdProfileIdToEdit) < 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return true;
        }
        return true; // For now always return as something changed
    }

    const isAnyViewAccountsChanged = () => {
        if (parseInt(cpdProfileIdToEdit) < 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return true;
        }
        return true; // For now always return as something changed
    }    

    const setGuideMe = (givenShowAssignPoints: boolean = false, givenShowAssignUnits: boolean = false, givenShowAssignAccounts: boolean = false, givenShowViewAccounts: boolean = false) => {
        
        let elem = document.getElementById("userResultTableOfSearch");
        let updateSteps = [];
        if (parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0)
        {    
            updateSteps.push(        
            {
                element: "#isProfileEnabled",
                title: allResourcesToTranslations.profileenableguidemetitle, 
                intro: allResourcesToTranslations.profileenableguideme
            },
            {
                element: parseInt(cpdProfileIdToEdit) < 0 ? ".addnewprofileguideme" : ".editprofileguideme",
                title: parseInt(cpdProfileIdToEdit) < 0 ? allResourcesToTranslations.addnewprofile : allResourcesToTranslations.editprofile, 
                intro: parseInt(cpdProfileIdToEdit) < 0 ? allResourcesToTranslations.addnewprofileguideme : allResourcesToTranslations.editprofileguideme
            });
        }
        else 
        {
            updateSteps.push(        
                {
                    element: "#cpdProfileCloningDiv",
                    title: allResourcesToTranslations.profilecloningguidemetitle, 
                    intro: allResourcesToTranslations.profilecloningguideme
                },
                {
                element: parseInt(cpdProfileIdToEdit) < 0 ? ".addnewprofileguideme" : ".editprofileguideme",
                title: parseInt(cpdProfileIdToEdit) < 0 ? allResourcesToTranslations.addnewprofile : allResourcesToTranslations.editprofile, 
                intro: parseInt(cpdProfileIdToEdit) < 0 ? allResourcesToTranslations.addnewprofileguideme : allResourcesToTranslations.editprofileguideme
            });
        }
        if (parseInt(cpdProfileIdToEdit) <= 0) {
            updateSteps.push(
            {
                element: ".profilenewperiodguideme",
                title: allResourcesToTranslations.profilenewperiod, intro: allResourcesToTranslations.profilenewperiodguideme
            },
            {
                element: ".profilenewperiodtoguidemefrom",
                title: allResourcesToTranslations.profilenewperiod, intro: allResourcesToTranslations.profilenewperiodguidemefrom
            },
            {
                element: ".profilenewperiodtoguidemeto",
                title: allResourcesToTranslations.profilenewperiod, intro: allResourcesToTranslations.profilenewperiodguidemeto
            },
            {
                element: ".profilenewperiodrequiredpointsguideme",
                title: allResourcesToTranslations.profilenewperiod, intro: allResourcesToTranslations.profilenewperiodrequiredpointsguideme
            },
            {
                element: ".attheendofperiodnoactionguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodnoactionguideme
            },
            {
                element: ".attheendofperiodautoincrementguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodautoincrementguideme
            },
            {
                element: ".attheendofperiodgivenmonthincrementguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenmonthincrementguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodfromguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodfromguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodtoguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodtoguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodpointsguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodpointsguideme
            },
            {
                element: "#saveprofiledataguidemenew",
                title: allResourcesToTranslations.saveprofiledata, intro: allResourcesToTranslations.saveprofiledataguideme
            },
            {
                element: "#cpdReportForSpecificProfile",
                title: allResourcesToTranslations.currentcpdreportsection, intro: allResourcesToTranslations.currentcpdreportsectionguideme
            });
        }
        else {
            updateSteps.push(
            {
                element: ".profilecurrentperiodguideme",
                title: allResourcesToTranslations.currentperiod, intro: allResourcesToTranslations.profilecurrentperiodguideme
            },
            {
                element: ".profilecurrentperiodtoguidemefrom",
                title: allResourcesToTranslations.currentperiod, intro: allResourcesToTranslations.profilecurrentperiodguidemefrom
            },
            {
                element: ".profilecurrentperiodtoguidemeto",
                title: allResourcesToTranslations.currentperiod, intro: allResourcesToTranslations.profilecurrentperiodguidemeto
            },
            {
                element: ".profilecurrentperiodrequiredpointsguideme",
                title: allResourcesToTranslations.currentperiod, intro: allResourcesToTranslations.profilecurrentperiodrequiredpointsguideme
            },
            {
                element: ".attheendofperiodnoactionguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodnoactionguideme
            },
            {
                element: ".attheendofperiodautoincrementguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodautoincrementguideme
            },
            {
                element: ".attheendofperiodgivenmonthincrementguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenmonthincrementguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodfromguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodfromguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodtoguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodtoguideme
            },
            {
                element: ".attheendofperiodgivenfutureperiodpointsguideme",
                title: allResourcesToTranslations.attheendofperiod, intro: allResourcesToTranslations.attheendofperiodgivenfutureperiodpointsguideme
            },
            {
                element: "#saveprofiledataguidemenew",
                title: allResourcesToTranslations.saveprofiledata, intro: allResourcesToTranslations.saveprofiledataguideme
            },
            {
                element: "#cpdReportForSpecificProfile",
                title: allResourcesToTranslations.currentcpdreportsection, intro: allResourcesToTranslations.currentcpdreportsectionguideme
            });
        }
        if ((parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) && 
            !(isSavingPoints || isSaving || isSavingUnits || isSavingAccounts || isSavingAccountRemovals)) {            
            updateSteps.push(
                {
                    element: "#assignButtonPanel",
                    title: allResourcesToTranslations.assignbuttonspaneltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelmessage2
                },
                {
                    element: "#btnNewSaltAssignPoints2",
                    title: allResourcesToTranslations.assignpoints, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsmessage2
                },
                {
                    element: "#btnNewSaltAssignUnits2",
                    title: allResourcesToTranslations.assignunits, 
                    intro: allResourcesToTranslations.assignbuttonspanelunitmessage
                },
                {
                    element: "#btnNewSaltAssignAccounts2",
                    title: allResourcesToTranslations.assignaccounts, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountmessage
                },
                {
                    element: "#btnNewSaltViewAccounts2",
                    title: allResourcesToTranslations.assignbuttonspanelviewaccounttitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelviewaccountmessage
                }
            )
        }
        if ((parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) && givenShowAssignPoints) {            
            updateSteps.push(
                {
                    element: "#courseModuleAssignPointsToLessonQuizOnly",
                    title: allResourcesToTranslations.assignbuttonspanelpointslqtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointslqmessage
                },
                {
                    element: "#showOnlyPointsAssignedCoursesWithLabel",
                    title: allResourcesToTranslations.assignbuttonspanelpointscmptfiltertitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointscmptfiltermessage
                },
                {
                    element: "#applyCommonPointsToFilteredCMList",
                    title: allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcmodsguidemet, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcmodsguidemem
                },
                {
                    element: "#courseModuleAssignPointsSearchTextField",
                    title: allResourcesToTranslations.assignbuttonspanelpointscmseektitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointscmseekmessage
                },
                {
                    element: "#expandAllCourseListAssignPointsspan",
                    title: allResourcesToTranslations.assignbuttonspanelpointscmexpalltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointscmexpallmessage
                },
                {
                    element: "#collapseAllCourseListAssignPointsspan",
                    title: allResourcesToTranslations.assignbuttonspanelpointscmclpalltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointscmclpallmessage
                },
                {
                    element: "#firstCourseRowOfAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointsclvltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsclvlmessage
                },
                {
                    element: "#firstModuleOfFirstCourseRowOfAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointsmlvltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsmlvlmessage
                },
                {
                    element: "#totalPointsOfCourseModulesOfAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointscmtottitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointscmtotmessage
                },
                {
                    element: "#policiesAssignPointsWhenAcceptedOnly",
                    title: allResourcesToTranslations.assignbuttonspanelpointslqtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointspmessage
                },
                {
                    element: "#showOnlyPointsAssignedPoliciesWithLabel",
                    title: allResourcesToTranslations.assignbuttonspanelpointspptfiltertitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointspptfiltermessage
                },
                {
                    element: "#applyCommonPointsToFilteredPList",
                    title: allResourcesToTranslations.assignbuttonspanelpointsapplycommontofpolsguidemet, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsapplycommontofpolsguidemem
                },
                {
                    element: "#policyAssignPointsSearch",
                    title: allResourcesToTranslations.assignbuttonspanelpointspseektitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointspseekmessage
                },
                {
                    element: "#firstPolicyRowOfAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointsplvltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsplvlmessage
                },
                {
                    element: "#totalPointsOfPoliciesOfAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointsptottitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsptotmessage
                },
                {
                    element: "#certsAssignPointsWhenProvidedOnly",
                    title: allResourcesToTranslations.assignbuttonspanelpointslqtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsecmessage
                },
                {
                    element: "#showOnlyPointsAssignedCertsWithLabel",
                    title: allResourcesToTranslations.assignbuttonspanelpointsecptfiltertitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsecptfiltermessage
                },
                {
                    element: "#applyCommonPointsToFilteredECList",
                    title: allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcertsguidemet, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsapplycommontofcertsguidemem
                },
                {
                    element: "#certAssignPointsSearch",
                    title: allResourcesToTranslations.assignbuttonspanelpointsecseektitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsecseekmessage
                },
                {
                    element: "#firstCertRowOfAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointseclvltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointseclvlmessage
                },
                {
                    element: "#totalPointsOfCertsOfAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointsectottitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsectotmessage
                },
                {
                    element: "#includePreviousCompletionsInAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointsincltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsinclmessagenew
                },
                {
                    element: "#excludePreviousCompletionsInAssignPoints",
                    title: allResourcesToTranslations.assignbuttonspanelpointsexcltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointsexclmessagenew
                },
                {
                    element: "#saveprofiledatapointsguidemenew",
                    title: allResourcesToTranslations.assignbuttonspanelpointssavetitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelpointssavemessagenew
                }
            )
        }
        if ((parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) && givenShowAssignUnits) {            
            updateSteps.push(
                {
                    element: ".unitFilterButtons",
                    title: allResourcesToTranslations.assignbuttonspanelunitpaneltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelunitpanelmessage
                },
                {
                    element: ".unitFilterSearch",
                    title: allResourcesToTranslations.assignbuttonspanelunitsearchtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelunitsearchmessage
                },
                {
                    element: ".unittree",
                    title: allResourcesToTranslations.assignbuttonspanelunittreetitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelunittreemessage
                },
                {
                    element: "#saveprofiledataunitsguidemenew",
                    title: allResourcesToTranslations.assignbuttonspanelunitsavetitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelunitsavemessage
                }
            )
        }
        if ((parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) && givenShowAssignAccounts) {            
            updateSteps.push(
                {
                    element: ".unitFilterButtons",
                    title: allResourcesToTranslations.assignbuttonspanelaccountpaneltitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelunitpanelmessage
                },
                {
                    element: ".unitFilterSearch",
                    title: allResourcesToTranslations.assignbuttonspanelunitsearchtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelunitsearchmessage
                },
                {
                    element: ".unittree",
                    title: allResourcesToTranslations.assignbuttonspanelunittreetitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountunittreemessage
                },
                {
                    element: ".firstName",
                    title: allResourcesToTranslations.assignbuttonspanelaccountfntitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountfnmessage
                },
                {
                    element: ".lastName",
                    title: allResourcesToTranslations.assignbuttonspanelaccountlntitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountlnmessage
                },
                {
                    element: ".userName",
                    title: allResourcesToTranslations.assignbuttonspanelaccountuntitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountunmessage
                },
                {
                    element: ".email",
                    title: allResourcesToTranslations.assignbuttonspanelaccountemtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountemmessage
                },
                {
                    element: ".userID",
                    title: allResourcesToTranslations.assignbuttonspanelaccountaidtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountaidmessage
                }
                ,
                // {
                //     element: ".includeInactive",
                //     title: allResourcesToTranslations.assignbuttonspanelaccountininatitle, 
                //     intro: allResourcesToTranslations.assignbuttonspanelaccountininamessage
                // }
                // ,
                {
                    element: ".searchUsers",
                    title: allResourcesToTranslations.assignbuttonspanelaccountsrchtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountsrchmessage
                },
                {
                    element: ".resetAll",
                    title: allResourcesToTranslations.assignbuttonspanelaccountfclrtitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountfclrmessage
                });
                if (hasAccountsSearched || (elem != null && elem != undefined && !elem.hidden)) {
                    updateSteps.push(
                            {
                                element: "#acMgmtAssignCol",
                                title: allResourcesToTranslations.assignbuttonspanelaccountasnttitle, 
                                intro: allResourcesToTranslations.assignbuttonspanelaccountasntmessage
                            },
                            {
                                element: "#acMgmtUPCol",
                                title: allResourcesToTranslations.assignbuttonspanelaccountupttitle, 
                                intro: allResourcesToTranslations.assignbuttonspanelaccountuptmessage
                            },
                            {
                                element: "#acMgmtLNCol",
                                title: allResourcesToTranslations.assignbuttonspanelaccountlnttitle, 
                                intro: allResourcesToTranslations.assignbuttonspanelaccountlntmessage
                            },
                            {
                                element: "#acMgmtFNCol",
                                title: allResourcesToTranslations.assignbuttonspanelaccountfnttitle, 
                                intro: allResourcesToTranslations.assignbuttonspanelaccountfntmessage
                            },
                            {
                                element: "#acMgmtAsignTypeCol",
                                title: allResourcesToTranslations.assigntypegmt,
                                intro: allResourcesToTranslations.assigntypegmm  
                            });
                }
                updateSteps.push( 
                {
                    element: "#saveprofiledatausersguidemenew",
                    title: allResourcesToTranslations.assignbuttonspanelaccountsavetitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelaccountsavemessage
                }
            );
        }
        if ((parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) && givenShowViewAccounts) {            
            updateSteps.push(
                {
                    element: "#acMgmtAssignCol",
                    title: allResourcesToTranslations.assignbuttonspanelaccountasnttitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelviewaccountasnmessage
                },
                {
                    element: "#acMgmtUPCol",
                    title: allResourcesToTranslations.assignbuttonspanelaccountupttitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelviewaccountupmessage
                },
                {
                    element: "#acMgmtLNCol",
                    title: allResourcesToTranslations.assignbuttonspanelaccountlnttitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelviewaccountlnmessage
                },
                {
                    element: "#acMgmtFNCol",
                    title: allResourcesToTranslations.assignbuttonspanelaccountfnttitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelviewaccountfnmessage
                },
                {
                    element: "#acMgmtAsignTypeCol",
                    title: allResourcesToTranslations.assigntypegmt,
                    intro: allResourcesToTranslations.assigntypegmm  
                },
                {
                    element: "#assignSaveButtonOfCPDProfile",
                    title: allResourcesToTranslations.assignbuttonspanelviewaccountsavetitle, 
                    intro: allResourcesToTranslations.assignbuttonspanelviewaccountsavemessage
                }
            )
        }
        
        setIntro({ ...intro, steps: [...updateSteps] });
    };


    const onExit = () => {
        setIntro({ ...intro, stepsEnabled: false });
    };

    const triggerErrorPopUp = (error: string) => {
        if (!!error && error.trim() != "") {
            setModalPopupMessage(error);
            setHasErrored(true);
            setSaveSuccess(false);            
            setShowWarningModal(true);
            return true;
        }
        return false;
    }

    const checkIfValidGivenFirstDateIsLessThanOrEqualToSecond = (dateTxtStart: string, dateTxtEnd: string) => { // Input format YYYY-MM-DD 
        try 
        {
            let dtStart = new Date(dateTxtStart);
            let dtEnd = new Date(dateTxtEnd);
            return (dtStart <= dtEnd);
        }
        catch {
            return false;
        }
    }

    const checkIfValidDateText_YYYY_MM_DD = (dateTxt: string, checkIfNotOldThanToday: boolean = false) => {        
        if (!!dateTxt === false || dateTxt.trim() == "" || dateTxt.split("-").length != 3) {
            return false;
        }
        let dtFmtd = "";
        try 
        {
            let dt = new Date(dateTxt);
            if (checkIfNotOldThanToday && ((!!dt !== true) || !((new Date()) <= dt))) {
                return false;
            }
            dtFmtd = formatDate(dt);
        }
        catch {
            dtFmtd = "";
        }
        if (!!dtFmtd === false || dtFmtd.trim() == "" || dtFmtd.split("-").length != 3) {
            return false;
        }
        return true;
    }

    

    const checkIfValidEndDateText_YYYY_MM_DD = (dateTxt: string) => {        
        if (!!dateTxt === false || dateTxt.trim() == "" || dateTxt.split("-").length != 3) {
            return false;
        }
        let dtFmtd = "";
        try 
        {
            let dt = new Date(dateTxt);
            // Check If Not Older Than Today
            let curDateTime = new Date();
            let curDayTxt = curDateTime.getFullYear().toString().padStart(4, "0") + "-" + 
                        (curDateTime.getMonth() + 1).toString().padStart(2, "0") + "-" + 
                        curDateTime.getDate().toString().padStart(2, "0");
            if (((!!dt !== true) || !((new Date(curDayTxt)) <= dt))) {
                return false;
            }
            dtFmtd = formatDate(dt);
        }
        catch {
            dtFmtd = "";
        }
        if (!!dtFmtd === false || dtFmtd.trim() == "" || dtFmtd.split("-").length != 3) {
            return false;
        }
        return true;
    }

    const validateCPDInput = () => {
        let toReturn = false;
        if (!!cpdProfileNameModified === false || cpdProfileNameModified.trim() == "") {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidname); if (toReturn) { return true; } 
        }
        if (!!cpdProfileNameModified && cpdProfileNameModified.trim() != "" && cpdProfileNameModified.length > 255) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgnamelen); if (toReturn) { return true; } 
        }
        if ((profileEnabledModified || (!profileEnabledModified && !!currentPeriodStartDateModified)) && !checkIfValidDateText_YYYY_MM_DD(currentPeriodStartDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidstdtcur); if (toReturn) { return true; }  
        }
        if ((profileEnabledModified || (!profileEnabledModified && !!currentPeriodEndDateModified)) && !checkIfValidDateText_YYYY_MM_DD(currentPeriodEndDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidendtcur); if (toReturn) { return true; }  
        }
        if ((profileEnabledModified || (!profileEnabledModified && !!currentPeriodEndDateModified)) && !checkIfValidEndDateText_YYYY_MM_DD(currentPeriodEndDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidendttdcur); if (toReturn) { return true; }  
        }
        if ((profileEnabledModified || (!profileEnabledModified && (!!currentPeriodStartDateModified || !!currentPeriodEndDateModified))) && 
            !checkIfValidGivenFirstDateIsLessThanOrEqualToSecond(currentPeriodStartDateModified, currentPeriodEndDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidsteddtcur); if (toReturn) { return true; }  
        }
        if (cpdPointsModified < 0) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidrqptcur); if (toReturn) { return true; }          
        }
        let postEndOfPeriodActionTypeModifiedOption = getEndOfPeriodTypeInNumber(postEndOfPeriodActionTypeModified).toString();
        if (postEndOfPeriodActionTypeModifiedOption == "3" && !!cpdFutureMonthIncrementModified === false) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidincmncur); if (toReturn) { return true; }          
        }
        if (postEndOfPeriodActionTypeModifiedOption == "3" && cpdFutureMonthIncrementModified != null && cpdFutureMonthIncrementModified <= 0) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidincmncur); if (toReturn) { return true; }          
        }
        if (postEndOfPeriodActionTypeModifiedOption == "4" && !checkIfValidDateText_YYYY_MM_DD(futurePeriodStartDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidstdtfut); if (toReturn) { return true; }  
        }
        if (postEndOfPeriodActionTypeModifiedOption == "4" && !checkIfValidDateText_YYYY_MM_DD(futurePeriodEndDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidendtfut); if (toReturn) { return true; }  
        }
        if (profileEnabledModified && postEndOfPeriodActionTypeModifiedOption == "4" && !checkIfValidGivenFirstDateIsLessThanOrEqualToSecond(currentPeriodEndDateModified, futurePeriodStartDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidstdtfutwithcur); if (toReturn) { return true; }  
        }
        if (profileEnabledModified && postEndOfPeriodActionTypeModifiedOption == "4" && !checkIfValidGivenFirstDateIsLessThanOrEqualToSecond(currentPeriodEndDateModified, futurePeriodEndDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalideddtfutwithcur); if (toReturn) { return true; }  
        }
        if ((postEndOfPeriodActionTypeModifiedOption == "2" || postEndOfPeriodActionTypeModifiedOption == "3" || postEndOfPeriodActionTypeModifiedOption == "4") && cpdFuturePointsModified < 0) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidrqptfut); if (toReturn) { return true; }  
        }
        if (postEndOfPeriodActionTypeModifiedOption == "4" && !checkIfValidGivenFirstDateIsLessThanOrEqualToSecond(futurePeriodStartDateModified, futurePeriodEndDateModified)) {
            toReturn = triggerErrorPopUp(allResourcesToTranslations.cpdmsgvalidsteddtfut); if (toReturn) { return true; }  
        }
        return false;
    }

    const handleSave = () => {
        setShowBasicCPDDetails(true);
        setShowAssignPoints(false);
        setShowAssignUnits(false);
        setShowAssignAccounts(false);
        setShowViewAccounts(false);

        setSaveSuccess(false);
        setIsSaving(true);

        setNeedLatestUnits(false);
        setCheckForNoUnitChanges(false);
        setNeedLatestAccounts(false);
        setNeedLatestViewAccounts(false);

        setFinalSave(true);
        setFinalSavePoints(false);
        setFinalSaveUnits(false);
        setFinalSaveAccounts(false);
        setFinalSaveRemoveAccounts(false);

        let currentAsFutureMessage = "";
        let curDateTime = new Date();
        let curDayTxt = curDateTime.getFullYear().toString().padStart(4, "0") + "-" + 
                        (curDateTime.getMonth() + 1).toString().padStart(2, "0") + "-" + 
                        curDateTime.getDate().toString().padStart(2, "0");
        let validCurrentPeriodDisabledMessage = "";
        if ((parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0 && !profileEnabledModified) ) {
                validCurrentPeriodDisabledMessage = allResourcesToTranslations.cpdmsgsavedefp3;  
        }
        else if (!!currentPeriodStartDateModified && !!currentPeriodEndDateModified && 
            !(checkIfValidGivenFirstDateIsLessThanOrEqualToSecond(currentPeriodStartDateModified, curDayTxt) && 
              checkIfValidGivenFirstDateIsLessThanOrEqualToSecond(curDayTxt, currentPeriodEndDateModified)) ) {
                currentAsFutureMessage = allResourcesToTranslations.cpdmsgsavedefp4;  
        }        

        //setShowWarningModal(true);
        //setHasErrored(false);
        //setModalPopupMessage("You are saving only general CPD Profile details. Please close this warning to save changes to CPD Profile details.");
        setWindowMidModelMessage(allResourcesToTranslations.cpdmsgsavedefp1 + 
                                    currentAsFutureMessage + validCurrentPeriodDisabledMessage + 
                                    allResourcesToTranslations.cpdmsgsavedefp2);
        setShowWindowMidWarningModalForWarning(true);
        setShowWindowMidWarningModalForConfirmation(false);
    }

    const handleSavePoints = () => {
        setShowBasicCPDDetails(false);
        setShowAssignPoints(true);
        setShowAssignUnits(false);
        setShowAssignAccounts(false);
        setShowViewAccounts(false);

        setSaveSuccess(false);
        setIsSaving(true);

        setNeedLatestUnits(false);
        setCheckForNoUnitChanges(false);
        setNeedLatestAccounts(false);
        setNeedLatestViewAccounts(false);

        setFinalSave(false);
        setFinalSavePoints(true);
        setFinalSaveUnits(false);
        setFinalSaveAccounts(false);
        setFinalSaveRemoveAccounts(false);
        
        //setShowWarningModal(true);
        //setHasErrored(false);
        //setModalPopupMessage("You are saving only updates to Assign Points. Please close this warning to save changes to Assign Points.");
        setWindowMidModelMessage(allResourcesToTranslations.cpdmsgsavepts1);
        setShowWindowMidWarningModalForWarning(true);
        setShowWindowMidWarningModalForConfirmation(false);
    }

    const handleSaveUnits = () => {
        setShowBasicCPDDetails(false);
        setShowAssignPoints(false);
        setShowAssignUnits(true);
        setShowAssignAccounts(false);
        setShowViewAccounts(false);

        setSaveSuccess(false);
        setIsSaving(true);

        setNeedLatestUnits(true);
        setCheckForNoUnitChanges(true);
        setNeedLatestAccounts(false);
        setNeedLatestViewAccounts(false);

        setFinalSave(false);
        setFinalSavePoints(false);
        setFinalSaveUnits(true);
        setFinalSaveAccounts(false);
        setFinalSaveRemoveAccounts(false);   
        
        // setWindowMidModelMessage(allResourcesToTranslations.cpdmsgsaveunits1);
        // setShowWindowMidWarningModalForWarning(true);
        // setShowWindowMidWarningModalForConfirmation(false);
    }

    const handleSaveAccounts = () => {
        setShowBasicCPDDetails(false);
        setShowAssignPoints(false);
        setShowAssignUnits(false);
        setShowAssignAccounts(true);
        setShowViewAccounts(false);

        setSaveSuccess(false);
        setIsSaving(true);

        setNeedLatestUnits(false);
        setCheckForNoUnitChanges(false);
        setNeedLatestAccounts(true);
        setNeedLatestViewAccounts(false);

        setFinalSave(false);
        setFinalSavePoints(false);
        setFinalSaveUnits(false);
        setFinalSaveAccounts(true);
        setFinalSaveRemoveAccounts(false);
        
        
        // let changedIDs = getNewlyModifiedUserIDs();
        // let selectedUsersCSVInitialArr = !!selectedUsersCSVInitial ? selectedUsersCSVInitial.split(",") : [];
        // if (!!selectedUsersCSVInitialArr && selectedUsersCSVInitialArr.length > 0 &&
        //     ((!!changedIDs && changedIDs.newlyDeniedIDsCSV != null && changedIDs.newlyDeniedIDsCSV != undefined && changedIDs.newlyDeniedIDsCSV.trim() == "") && 
        //      (!!changedIDs && changedIDs.newlyGrantedIDsCSV != null && changedIDs.newlyGrantedIDsCSV != undefined && changedIDs.newlyGrantedIDsCSV.trim() == ""))) {
        //     setWindowMidModelMessage("There are no changes to the account assignments. Do you want to re-apply the same selected assignments to override the unit specific profile assignments for the account(s)?");
        //     setShowWindowMidWarningModalForWarning(false);
        //     setShowWindowMidWarningModalForConfirmation(true);
        //     setIsSameUserOverriding(true);
        //     setIsSameUnitOverriding(false);
        // }
        // else {
            //setShowWarningModal(true);
            //setHasErrored(false);
            //setModalPopupMessage("You are saving only updates to Assign Accounts. Please close this warning to save changes to Assign Accounts.");
            setWindowMidModelMessage(allResourcesToTranslations.cpdmsgsaveac1);
            setShowWindowMidWarningModalForWarning(true);
            setShowWindowMidWarningModalForConfirmation(false);
            setIsSameUserOverriding(false);
            setIsSameUnitOverriding(false);
        //}
    }

    const handleSaveRemoveAccounts = () => {
        setShowBasicCPDDetails(false);
        setShowAssignPoints(false);
        setShowAssignUnits(false);
        setShowAssignAccounts(false);
        setShowViewAccounts(true);

        setSaveSuccess(false);
        setIsSaving(true);

        setNeedLatestUnits(false);
        setCheckForNoUnitChanges(false);
        setNeedLatestAccounts(false);
        setNeedLatestViewAccounts(true);

        setFinalSave(false);
        setFinalSavePoints(false);
        setFinalSaveUnits(false);
        setFinalSaveAccounts(false);
        setFinalSaveRemoveAccounts(true);
        
        //setShowWarningModal(true);
        //setHasErrored(false);
        //setModalPopupMessage("You are saving only updates to Accounts. Please close this warning to save changes to Accounts");
        setWindowMidModelMessage(allResourcesToTranslations.cpdmsgsavevac1);
        setShowWindowMidWarningModalForWarning(true);
        setShowWindowMidWarningModalForConfirmation(false);
    }

    const doFinalSave = () => {
        
        if (validateCPDInput()) {
            return;
        }

        setIsSaving(true);
        let isNewProfile = parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0;
        //console.log(cpdProfileIdToEdit);
        SaveGivenCPDProfile(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(cpdProfileIdToEdit), parseInt(cpdProfilePeriodIdToEdit), profileEnabledModified,
                                parseInt(cpdProfileIdToEdit) <= 0, cpdProfileNameModified, currentPeriodStartDateModified, currentPeriodEndDateModified,
                                cpdPointsModified, getEndOfPeriodTypeInNumber(postEndOfPeriodActionTypeModified).toString(), cpdFutureMonthIncrementModified == null ? -1 : cpdFutureMonthIncrementModified, 
                                futurePeriodStartDateModified, futurePeriodEndDateModified, cpdFuturePointsModified, 
                                isNewProfile ? clonedCPDProfileID : -1, isNewProfile ? clonedCPDProfilePeriodID : -1, cpdApplyToNewsfeed == 1 ? true : false, "en-au").then((res) => {
            setIsSaving(false);
            if (!!res && res.isSuccess) {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(true);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsavedefpass);
                //if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
                    setRefreshAfterSave(true);
                //}
                setSavedProfileID(res.cpdProfileIDSaved);
                setSavedProfilePeriodID(res.cpdProfilePeriodIDSaved);
            }
            else {
                setShowWarningModal(true);
                setHasErrored(true);
                setSaveSuccess(false);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsavedeffail + (!!res && !!res.error && res.error.trim() !== "" ? (" Reason: " + res.error) : ""));
                if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
                    setSavedProfileID(-1);
                    setSavedProfilePeriodID(-1);
                }
            }
        })
    }

    const updateInitialValuesGiven = (givenInitial: string, givenModified: string, type: string) => {
        if (!!type === false) {
            type = "";
        }
        let validarri : string[] = [];
        if (!!givenInitial && givenInitial.trim() !== "") {
            let arri = givenInitial.split("&");
            if (arri != null && arri.length > 0) {
                validarri = arri.filter(a => !!a && a.includes(":"));
            }
        }
        let validarr : string[] = [];
        if (!!givenModified && givenModified.trim() !== "") {
            let arr = givenModified.split("&");
            if (arr != null && arr.length > 0) {
                validarr = arr.filter(a => !!a && a.includes(":"));
                if (validarr != null && validarr.length > 0 && (validarri == null || validarri.length <= 0)) {
                    validarri = validarr;
                }
                else if (validarr != null && validarr.length > 0 && validarri != null && validarri.length > 0) {
                    for (let idx=0; idx<validarr.length; idx++) {
                        let sidx = validarr[idx].trim().indexOf(":");
                        if (sidx >= 0) {
                            let marr = validarri.filter(a => !!a && a.trim().startsWith(validarr[idx].substring(0, sidx+1)));
                            if (marr != null && marr.length > 0) {
                                let midx = validarri.indexOf(marr[0]);
                                if (midx >= 0) {
                                    validarri[midx] = validarr[idx];
                                }
                                else {
                                    validarri.push(validarr[idx]);
                                }
                            }
                            else {
                                validarri.push(validarr[idx]);
                            }
                        }
                    }
                }
            }
        }

        if (validarri == null || validarri.length <= 0) {
            switch (type.toLowerCase().trim())
            {
                case "module" :
                    setModuleIDsToPointsInitial("");
                    break;
                case "policy" :
                    setPolicyIDsToPointsInitial("");
                    break;
                case "extcert" :
                    setExtCertIDsToPointsInitial("");
                    break;
            }
        }
        else 
        {
            switch (type.toLowerCase().trim())
            {
                case "module" :
                    setModuleIDsToPointsInitial(validarri.join("&"));
                    break;
                case "policy" :
                    setPolicyIDsToPointsInitial(validarri.join("&"));
                    break;
                case "extcert" :
                    setExtCertIDsToPointsInitial(validarri.join("&"));
                    break;
            }
        }
    }

    const updateInitialValues = () => {
        updateInitialValuesGiven(moduleIDsToPointsInitial, changedModuleIDsToPoints, "module");
        updateInitialValuesGiven(policyIDsToPointsInitial, changedPolicyIDsToPoints, "policy");
        updateInitialValuesGiven(extCertIDsToPointsInitial, changedExtCertIDsToPoints, "extcert");
    }

    const doFinalSavePoints = () => {
        if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return;
        }
        setIsSaving(true);
        setIsSavingPoints(true);
        //console.log(cpdProfileIdToEdit);
        SaveGivenCPDProfilePoints(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(cpdProfileIdToEdit), parseInt(cpdProfilePeriodIdToEdit), 
                                cpdApplyToQuiz == 1, cpdApplyToLesson == 1, completedUsersToExclude, changedModuleIDsToPoints, changedPolicyIDsToPoints, changedExtCertIDsToPoints, cpdApplyToNewsfeed == 1 ? true : false, "en-au").then((res) => {
            setIsSavingPoints(false);
            setIsSaving(false);
            if (!!res && res.isSuccess) {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(true);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveptspass);
                //if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
                    setRefreshAfterSave(true);
                //}
                setSavedProfileID(res.cpdProfileIDSaved);
                setSavedProfilePeriodID(res.cpdProfilePeriodIDSaved);

                updateInitialValues();

                setChangedModuleIDsToPoints("");
                setChangedPolicyIDsToPoints("");
                setChangedExtCertIDsToPoints("");
            }
            else {
                setShowWarningModal(true);
                setHasErrored(true);
                setSaveSuccess(false);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveptsfail.toString() + 
                    (!!res && !!res.error && res.error.trim() !== "" ? (" " + allResourcesToTranslations.cpdmsgreason.toString() + ": " + res.error) : ""));                
            }
        })
    }

    const getValidIntNumberValue = (givenTxt: string) => {
        let numval = -1;
        if (!!givenTxt === false || givenTxt.trim() == "") {
            return -1;
        }
        try
        {
            numval = parseInt(givenTxt);
            if (!!numval == false || isNaN(numval) || Number.isNaN(numval)) {
                numval = -1;
            }
        }
        catch { numval = -1; }
        return numval;
    }

    const getNewlyModifiedUnitIDs = () => {
        let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
        let newlyGrantedUnitIDsCSV = "";
        let newlyDeniedUnitIDsCSV = "";
        let initialSelectedUnitIDs : string[] = [];
        if (!!selectedUnitsCSVInitial && selectedUnitsCSVInitial.trim() != "") {
            initialSelectedUnitIDs = selectedUnitsCSVInitial.trim().split(",");
        }
        // Get the newly granted and newly denied units
        if (!!updatedSelectedUnitIds && !!initialSelectedUnitIDs && 
            (updatedSelectedUnitIds.length > 0 && updatedSelectedUnitIds.some(id => !!id && id.toString().trim() != "") || 
            initialSelectedUnitIDs.length > 0 && initialSelectedUnitIDs.some(id => !!id && id.toString().trim() != ""))) {
            for (let i=0; i<updatedSelectedUnitIds.length; i++) {
                let curVal = updatedSelectedUnitIds[i];
                if (!!curVal === false || (curVal=curVal.toString().trim()) == "") {
                    continue;
                }
                let numVal = getValidIntNumberValue(curVal);
                let numvalTxt = numVal.toString();
                if (!initialSelectedUnitIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
                    newlyGrantedUnitIDsCSV = ((newlyGrantedUnitIDsCSV == "") ? numvalTxt : (newlyGrantedUnitIDsCSV + "," + numvalTxt));
                }
            }
            for (let i=0; i<initialSelectedUnitIDs.length; i++) {
                let curVal = initialSelectedUnitIDs[i];
                if (!!curVal === false || (curVal=curVal.toString().trim()) == "") {
                    continue;
                }
                let numVal = getValidIntNumberValue(curVal);
                let numvalTxt = numVal.toString();
                if (!updatedSelectedUnitIds.some(v => !!v && v.toString().trim() == numvalTxt)) {
                    newlyDeniedUnitIDsCSV = ((newlyDeniedUnitIDsCSV == "") ? numvalTxt : (newlyDeniedUnitIDsCSV + "," + numvalTxt));
                }
            }
        }
        retVal.newlyDeniedIDsCSV = newlyDeniedUnitIDsCSV;
        retVal.newlyGrantedIDsCSV = newlyGrantedUnitIDsCSV;
        return retVal;
    }

    const getNewlyModifiedUnitIDsFromGiven = (givenUpdatedSelectedUnitIds : string[]) => {
        let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
        let newlyGrantedUnitIDsCSV = "";
        let newlyDeniedUnitIDsCSV = "";
        let initialSelectedUnitIDs : string[] = [];
        if (!!selectedUnitsCSVInitial && selectedUnitsCSVInitial.trim() != "") {
            initialSelectedUnitIDs = selectedUnitsCSVInitial.trim().split(",");
        }
        // Get the newly granted and newly denied units
        if (!!givenUpdatedSelectedUnitIds && !!initialSelectedUnitIDs && 
            (givenUpdatedSelectedUnitIds.length > 0 && givenUpdatedSelectedUnitIds.some(id => !!id && id.toString().trim() != "") ||
             initialSelectedUnitIDs.length > 0 && initialSelectedUnitIDs.some(id => !!id && id.toString().trim() != "") )) {
            for (let i=0; i<givenUpdatedSelectedUnitIds.length; i++) {
                let curVal = givenUpdatedSelectedUnitIds[i];
                if (!!curVal === false || (curVal=curVal.toString().trim()) == "") {
                    continue;
                }
                let numVal = getValidIntNumberValue(curVal);
                let numvalTxt = numVal.toString();
                if (!initialSelectedUnitIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
                    newlyGrantedUnitIDsCSV = ((newlyGrantedUnitIDsCSV == "") ? numvalTxt : (newlyGrantedUnitIDsCSV + "," + numvalTxt));
                }
            }
            for (let i=0; i<initialSelectedUnitIDs.length; i++) {
                let curVal = initialSelectedUnitIDs[i];
                if (!!curVal === false || (curVal=curVal.toString().trim()) == "") {
                    continue;
                }
                let numVal = getValidIntNumberValue(curVal);
                let numvalTxt = numVal.toString();
                if (!givenUpdatedSelectedUnitIds.some(v => !!v && v.toString().trim() == numvalTxt)) {
                    newlyDeniedUnitIDsCSV = ((newlyDeniedUnitIDsCSV == "") ? numvalTxt : (newlyDeniedUnitIDsCSV + "," + numvalTxt));
                }
            }
        }
        retVal.newlyDeniedIDsCSV = newlyDeniedUnitIDsCSV;
        retVal.newlyGrantedIDsCSV = newlyGrantedUnitIDsCSV;
        return retVal;
    }

    const getNewlyModifiedUserIDs = () => {
        let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let initialSelectedUserIDs : string[] = [];
        if (!!selectedUsersCSVInitial && selectedUsersCSVInitial.trim() != "") {
            initialSelectedUserIDs = selectedUsersCSVInitial.trim().split(",");
        }
        // Get the newly granted and newly denied users
        if (!!updatedSelectedUsers && !!initialSelectedUserIDs && 
            ((updatedSelectedUsers.length > 0 && updatedSelectedUsers.some(id => !!id && !!id.userID && id.userID.toString().trim() != "")) || 
             (initialSelectedUserIDs.length > 0 && initialSelectedUserIDs.some(id => !!id && id.toString().trim() != "")))) {
            // Collect only newly granted in first loop of updated user-list checked against initial granted user-list
            for (let i=0; i<updatedSelectedUsers.length; i++) {
                let curVal = updatedSelectedUsers[i];
                let curValId = "";
                if (!!curVal === false || !!curVal.userID === false || (curValId=curVal.userID.toString().trim()) == "" || curVal.granted !== true) {
                    continue;
                }
                let numVal = getValidIntNumberValue(curValId);
                let numvalTxt = numVal.toString();
                // Extra not overlapping with initial list - collect only the denied to granted
                if (!initialSelectedUserIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
                    if (curVal.granted === true) {
                        newlyGrantedUserIDsCSV = ((newlyGrantedUserIDsCSV == "") ? numvalTxt : (newlyGrantedUserIDsCSV + "," + numvalTxt));
                    }
                } 
            }
            // Collect only newly denied in second loop of initial granted user-list checked against updated user-list
            for (let i=0; i<initialSelectedUserIDs.length; i++) {
                let curVal = initialSelectedUserIDs[i];
                if (!!curVal === false || (curVal=curVal.toString().trim()) == "") {
                    continue;
                }
                let numVal = getValidIntNumberValue(curVal);
                let numvalTxt = numVal.toString();
                // Overlapping with initial list - collect only granted to denied
                if (updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt) &&
                    updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === false)) {
                    newlyDeniedUserIDsCSV = ((newlyDeniedUserIDsCSV == "") ? numvalTxt : (newlyDeniedUserIDsCSV + "," + numvalTxt));
                }
            }
        }
        retVal.newlyDeniedIDsCSV = newlyDeniedUserIDsCSV;
        retVal.newlyGrantedIDsCSV = newlyGrantedUserIDsCSV;
        return retVal;
    }

    const doFinalSaveUnits = () => {
        //newlyGrantedUnitIDsCSV, newlyGrantedUserIDsCSV, newlyDeniedUnitIDsCSV, newlyDeniedUserIDsCSV
        if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return;
        }
        let newlyGrantedUnitIDsCSV = "";
        let newlyDeniedUnitIDsCSV = "";
        let ids = isSameUnitOverriding ? { newlyGrantedIDsCSV: selectedUnitsCSVInitial, newlyDeniedIDsCSV: csvOfAllDeniedUnits } : getNewlyModifiedUnitIDs();
        newlyGrantedUnitIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUnitIDsCSV;
        newlyDeniedUnitIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUnitIDsCSV;
        var finalGrantedUnitsCSV = newlyGrantedUnitIDsCSV;
        let newlyDeniedArr = !!newlyDeniedUnitIDsCSV && newlyDeniedUnitIDsCSV.trim() != "" ? newlyDeniedUnitIDsCSV.split(",") : [];
        let newlyGrantedArr = !!newlyGrantedUnitIDsCSV && newlyGrantedUnitIDsCSV.trim() != "" ? newlyGrantedUnitIDsCSV.split(",") : [];
        let initialGrantedArr = (!!selectedUnitsCSVInitial && selectedUnitsCSVInitial.trim() != "") ? selectedUnitsCSVInitial.split(",") : [];
        let allGrantedArr = initialGrantedArr.filter(s => !!s && !newlyDeniedArr.some(d => !!d && d.trim() == s.trim()) && !newlyGrantedArr.some(g => !!g && g.trim() == s.trim()));
        finalGrantedUnitsCSV = (!!finalGrantedUnitsCSV && finalGrantedUnitsCSV.trim() != "") ? (finalGrantedUnitsCSV + "," + allGrantedArr.join(',')) : allGrantedArr.join(',');
        setIsSaving(true);
        setIsSavingUnits(true);
        //console.log(cpdProfileIdToEdit);
        SetProfileAccessSpecific(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(cpdProfileIdToEdit), parseInt(cpdProfilePeriodIdToEdit), 
                                    (isSameUnitOverriding ? "" : newlyGrantedUnitIDsCSV), "", finalGrantedUnitsCSV, "", newlyDeniedUnitIDsCSV, "").then((res) => {
            setIsSavingUnits(false);
            setIsSaving(false);
            if (!!res && res.isSuccess) {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(true);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveunitpass);
                //if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
                    setRefreshAfterSave(true);
                //}
                setSavedProfileID(res.cpdProfileIDSaved);
                setSavedProfilePeriodID(res.cpdProfilePeriodIDSaved);
                //loadGrantedUnits();
                loadGrantedUnitsAndUsers(); // Need both users and units to store the initial list for calculating only the changes
                loadGrantedUserDetails("view");
            }
            else {
                setShowWarningModal(true);
                setHasErrored(true);
                setSaveSuccess(false);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveunitfail + 
                    (!!res && !!res.error && res.error.trim() !== "" ? (" " + allResourcesToTranslations.cpdmsgreason.toString() + ": " + res.error) : ""));
            }
        })
    }

    const doFinalSaveAccounts = () => {
        //newlyGrantedUnitIDsCSV, newlyGrantedUserIDsCSV, newlyDeniedUnitIDsCSV, newlyDeniedUserIDsCSV
        if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return;
        }
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = isSameUserOverriding ? { newlyGrantedIDsCSV: selectedUsersCSVInitial, newlyDeniedIDsCSV: "" } : getNewlyModifiedUserIDs();
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;
        setIsSaving(true);
        setIsSavingAccounts(true);
        //console.log(cpdProfileIdToEdit);
        SetProfileAccessSpecific(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(cpdProfileIdToEdit), parseInt(cpdProfilePeriodIdToEdit), 
                                    "", (isSameUserOverriding ? "" : newlyGrantedUserIDsCSV), "", newlyGrantedUserIDsCSV, "", newlyDeniedUserIDsCSV).then((res) => {
            setIsSavingAccounts(false);
            setIsSaving(false);
            if (!!res && res.isSuccess) {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(true);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveuserpass);
                //if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
                    setRefreshAfterSave(true);
                //}
                setSavedProfileID(res.cpdProfileIDSaved);
                setSavedProfilePeriodID(res.cpdProfilePeriodIDSaved);
                loadGrantedUsers();
                loadGrantedUserDetails("view");
                //loadGrantedUserDetails("search");
            }
            else {
                setShowWarningModal(true);
                setHasErrored(true);
                setSaveSuccess(false);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveuserfail + 
                    (!!res && !!res.error && res.error.trim() !== "" ? (" " + allResourcesToTranslations.cpdmsgreason.toString() + ": " + res.error) : ""));
            }
        })
    }

    const doFinalSaveAccountRemovals = () => {
        //newlyGrantedUnitIDsCSV, newlyGrantedUserIDsCSV, newlyDeniedUnitIDsCSV, newlyDeniedUserIDsCSV
        if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
            return;
        }
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let initialSelectedUserIDs : string[] = [];
        if (!!selectedUsersCSVInitial && selectedUsersCSVInitial.trim() != "") {
            initialSelectedUserIDs = selectedUsersCSVInitial.trim().split(",");
        }
        // Get the newly denied users
        if (!!initialSelectedUserIDs && initialSelectedUserIDs.length > 0 && 
            initialSelectedUserIDs.some(id => !!id && id.toString().trim() != "")) {
            // for (let i=0; i<updatedSelectedUsers.length; i++) {
            //     let curVal = updatedSelectedUsers[i];
            //     let curValId = "";
            //     if (!!curVal === false || !!curVal.userID === false || (curValId=curVal.userID.toString().trim()) == "") {
            //         continue;
            //     }
            //     let numVal = getValidIntNumberValue(curValId);
            //     let numvalTxt = numVal.toString();
            //     if (!initialSelectedUserIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
            //         newlyGrantedUserIDsCSV = ((newlyGrantedUserIDsCSV == "") ? numvalTxt : (newlyGrantedUserIDsCSV + "," + numvalTxt));
            //     }
            // }
            for (let i=0; i<initialSelectedUserIDs.length; i++) {
                let curVal = initialSelectedUserIDs[i];
                if (!!curVal === false || (curVal=curVal.toString().trim()) == "") {
                    continue;
                }
                let numVal = getValidIntNumberValue(curVal);
                let numvalTxt = numVal.toString();
                if (updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt) && 
                    (!updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === true) ||
                     updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === false))) {
                    newlyDeniedUserIDsCSV = ((newlyDeniedUserIDsCSV == "") ? numvalTxt : (newlyDeniedUserIDsCSV + "," + numvalTxt));
                }
            }
        }
        setIsSaving(true);
        setIsSavingAccountRemovals(true);
        //console.log(cpdProfileIdToEdit);
        SetProfileAccessSpecific(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(cpdProfileIdToEdit), parseInt(cpdProfilePeriodIdToEdit), 
                                    "", "", "", newlyGrantedUserIDsCSV, "", newlyDeniedUserIDsCSV).then((res) => {
            setIsSavingAccountRemovals(false);
            setIsSaving(false);
            if (!!res && res.isSuccess) {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(true);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveuserpass);
                //if (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) {
                    setRefreshAfterSave(true);
                //}
                setSavedProfileID(res.cpdProfileIDSaved);
                setSavedProfilePeriodID(res.cpdProfilePeriodIDSaved);
                loadGrantedUsers();
                loadGrantedUserDetails("view");
            }
            else {
                setShowWarningModal(true);
                setHasErrored(true);
                setSaveSuccess(false);
                setModalPopupMessage(allResourcesToTranslations.cpdmsgsaveuserfail + 
                    (!!res && !!res.error && res.error.trim() !== "" ? 
                    (" " + allResourcesToTranslations.cpdmsgreason.toString() + ": " + res.error) : ""));
            }
        })
    }

    const handleSetOfUpdatedSelectedUnitsFunction = (unitIds: string[]) => {
        setUpdatedSelectedUnitIds(unitIds);

        if (checkForNoUnitChanges) {
            setCheckForNoUnitChanges(false);
            let changedIDs = getNewlyModifiedUnitIDsFromGiven(unitIds);
            let selectedUnitsCSVInitialArr = !!selectedUnitsCSVInitial ? selectedUnitsCSVInitial.split(",") : [];
            if (!!selectedUnitsCSVInitialArr && selectedUnitsCSVInitialArr.length >= 0 &&
                ((!!changedIDs && changedIDs.newlyDeniedIDsCSV != null && 
                    changedIDs.newlyDeniedIDsCSV != undefined && changedIDs.newlyDeniedIDsCSV.trim() == "") && 
                (!!changedIDs && changedIDs.newlyGrantedIDsCSV != null && 
                    changedIDs.newlyGrantedIDsCSV != undefined && changedIDs.newlyGrantedIDsCSV.trim() == ""))) {
                setWindowMidModelMessage(allResourcesToTranslations.cpdmsgsaveunitwarn);
                setShowWindowMidWarningModalForWarning(false);
                setShowWindowMidWarningModalForConfirmation(true);
                setIsSameUnitOverriding(true);
                setIsSameUserOverriding(false);
            }
            else {
                //setShowWarningModal(true);
                //setHasErrored(false);
                //setModalPopupMessage("You are saving only updates to Assign Units. Please close this warning to save changes to Assign Units.");

                // setWindowMidModelMessage("You are saving only updates to Assign Units. Please close this warning to save changes to Assign Units.");
                // setShowWindowMidWarningModalForWarning(true);
                // setShowWindowMidWarningModalForConfirmation(false);
                // setIsSameUnitOverriding(false);
                // setIsSameUserOverriding(false);

                setWindowMidModelMessage(allResourcesToTranslations.cpdmsgsaveunitwarn2);
                setShowWindowMidWarningModalForWarning(false);
                setShowWindowMidWarningModalForConfirmation(true);
                setIsSameUnitOverriding(false);
                setIsSameUserOverriding(false);
            }
        }
    }

    const handleSetOfUpdatedSelectedUsersFunction = (userIds: any[], sourceComp: string = "") => {
        setUpdatedSelectedUsers(userIds);
        if (!!sourceComp && sourceComp.trim().toLowerCase() == "accountmanagement") {
            setHasAccountsSearched(true);
        }
        if (!!sourceComp && sourceComp.trim().toLowerCase() == "userlistsection") {
            setHasAccountsViewed(true);
        }
    }

    // const setUserSelectionChangedFunction = (hasSelectedUsersChanged: boolean) => {
    //     setHasSelectedUsersChanged(hasSelectedUsersChanged);
    //     // if (!hasSelectedUsersChanged) {
    //     //     setShowWarningModal(true);
    //     //     setHasErrored(true);
    //     //     setModalPopupMessage("No users found!");
    //     //     setHasAccountsSearched(true);            
    //     // }
    // }

    const setPointsLoadedFunction = (hasPointsLoaded: boolean) => {
        setHasPointsLoaded(hasPointsLoaded);
    }

    const setUnitsLoadedFunction = (hasUnitsLoaded: boolean) => {
        setHasUnitsLoaded(hasUnitsLoaded);
    }

    const setRecordsSearchedFunction = (flag: boolean) => {
        setHasAccountsSearched(flag);        
    }

    const handleApplyToQuizSelection = (flag: boolean) => {
        setCPDApplyToQuiz(flag ? 1 : 0);
    }

    const handleApplyToLessonSelection = (flag: boolean) => {
        setCPDApplyToLesson(flag ? 1 : 0);
    }

    const handleApplyToNewsfeedSelection = (flag: boolean) => {
        setCPDApplyToNewsfeed(flag ? 1 : 0);
    }

    const handleClosePopup = () => {
        setShowWarningModal(false);
        setNeedLatestUnits(false);
        setCheckForNoUnitChanges(false);
        if (finalSave) {
            doFinalSave();
            setFinalSave(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSavePoints) {
            doFinalSavePoints();
            setFinalSavePoints(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSaveUnits) {
            doFinalSaveUnits();
            setFinalSaveUnits(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSaveAccounts) {
            doFinalSaveAccounts();
            setFinalSaveAccounts(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSaveRemoveAccounts) {
            doFinalSaveAccountRemovals();
            setFinalSaveRemoveAccounts(false);
            setShowBasicCPDDetails(true);
        }
        else if (refreshAfterSave) {
            setRefreshAfterSave(false);
            if (!!history.location && !!history.location.pathname) {
                loadProfile(savedProfileID, savedProfilePeriodID);
                history.location.pathname = history.location.pathname.replace(`cpdprofile/-1/-1`, `cpdprofile/`+ savedProfileID.toString() + `/` + savedProfilePeriodID.toString());                
                history.push({
                    pathname: ``
                });
            }
            // if (!!window && !!window.location && !!window.location.href) {
            //     window.location.href = window.location.href.replace(`cpdprofile/-1/-1`, `cpdprofile/`+ savedProfileID.toString() + `/` + savedProfilePeriodID.toString());                
            //     window.location.reload();
            // }
        }
        else 
        {
            setIsSaving(false);
            setIsSavingPoints(false);
            setIsSavingUnits(false);
            setIsSavingAccounts(false);
            setIsSavingAccountRemovals(false);
        }
    };

    const getFormatedDateContents = (givenDate: string) => {
        if (!!givenDate === false || givenDate.trim() == "")
            return null;
        let formatedDate = givenDate.trim().split(" ")[0];
        if (formatedDate.indexOf("/") > 0) {
            return formatedDate.split("/");
        }
        else {
            return null;
        }
    }

    const loadGrantedUsers = () => {
        if (parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) {           
            GetProfileAccess(
                oidcUser.access_token,
                userId,
                orgDomain,
                parseInt(orgId),
                parseInt(cpdProfileIdToEdit),
                parseInt(cpdProfilePeriodIdToEdit),
                "UsersOnly"
            ).then((res) => {
                if (res.isSuccess) {
                    let grantedUserIDsCSV: string = "";
                    if (!!res.grantedUserIDs && res.grantedUserIDs.length >= 0) {
                        grantedUserIDsCSV = res.grantedUserIDs.join(",");
                        setSelectedUsersCSVInitial(grantedUserIDsCSV);
                    }
                }
                else {
                    console.log("Failed to get the user accesses for the CPD profile");
                }
                });
        }
    }

    const loadGrantedUserDetails = (getType: string = "view") => {
        if (parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) { 
            GetProfileUsers(
                oidcUser.access_token,
                userId,
                orgDomain,
                parseInt(orgId),
                parseInt(cpdProfileIdToEdit),
                parseInt(cpdProfilePeriodIdToEdit),
                "", 
                "",
                "",
                "",
                "",
                "",
                getType
              ).then((response) => {
                if (
                  response !== null &&
                  response !== undefined &&
                  response.isSuccess &&
                  response.profileUsers !== null &&
                  response.profileUsers !== undefined
                ) {
                  if (response.profileUsers.length >= 0) {
                    let updatedUsersList = response.profileUsers.map((item: any) => {
                      return {
                        ...item,
                        isChecked: false,
                      };
                    });
                    setProfileAssignedUsersList(updatedUsersList);
                    //setUserListOpen(true);
                    //setIsSearchClicked(false);
            
                    // let element = document.getElementById("userList");
                    // if (!!element)
                    //   element.scrollIntoView();
            
                  } else {
                    // setShowWarningModal(true);
                    // setModalPopupMessage('No Records found, please try again.');
                    setHasErrored(false);
                    //setSaveSuccess(false);
                    return;
                  }
                } else {
                  setShowWarningModal(true);
                  setModalPopupMessage(allResourcesToTranslations.fetchfailed);
                  setHasErrored(true);
                  return;
                }
              });
        }
    }

    const loadGrantedUnits = () => {
        if (parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) {           
            GetProfileAccess(
                oidcUser.access_token,
                userId,
                orgDomain,
                parseInt(orgId),
                parseInt(cpdProfileIdToEdit),
                parseInt(cpdProfilePeriodIdToEdit),
                "UnitsOnly"
            ).then((res) => {
                if (res.isSuccess) {
                    let grantedUnitIDsCSV: string = "";
                    if (!!res.grantedUnitIDs && res.grantedUnitIDs.length >= 0) {
                        grantedUnitIDsCSV = res.grantedUnitIDs.join(",");
                        setSelectedUnitsCSVInitial(grantedUnitIDsCSV);
                    }
                }
                else {
                    console.log("Failed to get the unit accesses for the CPD profile");
                }
                });
        }
    }

    const loadGrantedUnitsAndUsers = () => {
        if (parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) {           
            GetProfileAccess(
                oidcUser.access_token,
                userId,
                orgDomain,
                parseInt(orgId),
                parseInt(cpdProfileIdToEdit),
                parseInt(cpdProfilePeriodIdToEdit),
                "Both"
            ).then((res) => {
                if (res.isSuccess) {
                    let grantedUnitIDsCSV: string = "";
                    let grantedUserIDsCSV: string = "";
                    if (!!res.grantedUnitIDs && res.grantedUnitIDs.length >= 0) {
                        grantedUnitIDsCSV = res.grantedUnitIDs.join(",");
                        setSelectedUnitsCSVInitial(grantedUnitIDsCSV);
                    }
                    if (!!res.grantedUserIDs && res.grantedUserIDs.length >= 0) {
                        grantedUserIDsCSV = res.grantedUserIDs.join(",");
                        setSelectedUsersCSVInitial(grantedUserIDsCSV);
                    }
                }
                else {
                    console.log("Failed to get the unit/user accesses for the CPD profile");
                }
                });
        }
    }


    const loadProfile = (profileId: number, profilePeriodId: number) => {
        setChangedModuleIDsToPoints("");
        setChangedPolicyIDsToPoints("");
        setChangedExtCertIDsToPoints("");

        GetGivenCPDProfile(oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId), 
            profileId, 
            profilePeriodId, 
            "en-au").then((result: any) => {
                if (!!result && result.isSuccess && (result.error == null || result.error.trim() == "")) {
                    if (!!result.cpdProfile) {

                        let profile : CPDProfile = result.cpdProfile;
                        setLoadedProfile(profile);
                        if (!!profile) {
                            setProfileID(profile.profileID);
                            setProfilePeriodID(profile.profilePeriodID);
                            setCPDProfileStatus(profile.profileStatus);
                            setProfileEnabled(!!profile.profileStatus ? profile.profileStatus.trim().toLowerCase() === "enabled" : false)
                            setProfileEnabledModified(!!profile.profileStatus ? profile.profileStatus.trim().toLowerCase() === "enabled" : false)
                            setCPDProfileName(profile.profileName);
                            setCPDProfileNameModified(profile.profileName);
                            setCPDDateEnd(profile.dateEnd);
                            setCPDFutureDateStart(profile.futureDateStart);
                            setCPDFutureDateEnd(profile.futureDateEnd);
                            setCPDPoints(profile.points);
                            setCPDPointsModified(profile.points);
                            setCPDPointsModifiedTxt(!!profile.points ? profile.points.toFixed(2) : "0.00");
                            setCPDFuturePoints(profile.futurePoints);
                            setCPDFuturePointsModified(profile.futurePoints);
                            setCPDFuturePointsModifiedTxt(!!profile.futurePoints ? profile.futurePoints.toFixed(2) : "0.00");
                            setCPDEndOfPeriodAction(profile.endOfPeriodAction);
                            setCPDFutureMonthIncrement(profile.futureMonthIncrement);
                            setCPDFutureMonthIncrementModified(profile.futureMonthIncrement);
                            setCPDApplyToQuiz(profile.applyToQuiz);
                            setCPDApplyToLesson(profile.applyToLesson);
                            setApplyToLessonValueInitial(profile.applyToLesson == 1);
                            setApplyToNewsfeedValueInitial(profile.applyToNewsfeed == 1);
                            setCPDApplyToNewsfeed(profile.applyToNewsfeed == 1 ? 1 : 0);
                            setApplyToQuizValueInitial(profile.applyToQuiz == 1);
                            setCPDCurrentPeriod(profile.currentPeriod);
                            setCPDFuturePeriod(profile.futurePeriod);

                            if (!!profile.currentPeriod && profile.currentPeriod.trim() != "") {
                                const curPrdTxtArr = profile.currentPeriod.trim().split("-");
                                if (!!curPrdTxtArr && curPrdTxtArr.length > 0 && !!curPrdTxtArr[0]) {
                                    let tempDateSt = curPrdTxtArr[0].trim().split("/");
                                    let val = formatDate(new Date(parseInt(tempDateSt[2]), parseInt(tempDateSt[1])-1, parseInt(tempDateSt[0])));
                                    setCurrentPeriodStartDateModified(val);
                                    setCurrentPeriodStartDate(val);
                                }
                                else {
                                    setCurrentPeriodStartDateModified("");
                                    setCurrentPeriodStartDate("");
                                }
                                if (!!curPrdTxtArr && curPrdTxtArr.length > 1 && !!curPrdTxtArr[1]) {
                                    let tempDateSt = curPrdTxtArr[1].trim().split("/");
                                    let val = formatDate(new Date(parseInt(tempDateSt[2]), parseInt(tempDateSt[1])-1, parseInt(tempDateSt[0])));
                                    setCurrentPeriodEndDateModified(val);
                                    setCurrentPeriodEndDate(val);
                                }
                                else {
                                    setCurrentPeriodEndDateModified("");
                                    setCurrentPeriodEndDate("");
                                }
                            }
                            else {
                                setCurrentPeriodStartDateModified("");
                                setCurrentPeriodStartDate("");
                                setCurrentPeriodEndDateModified("");
                                setCurrentPeriodEndDate("");
                            }

                            if (!!profile.futurePeriod && profile.futurePeriod.trim() != "") {
                                const curPrdTxtArr2 = profile.futurePeriod.trim().split("-");
                                if (!!curPrdTxtArr2 && curPrdTxtArr2.length > 0 && !!curPrdTxtArr2[0]) {
                                    let tempDateSt = curPrdTxtArr2[0].trim().split("/");
                                    let val = formatDate(new Date(parseInt(tempDateSt[2]), parseInt(tempDateSt[1])-1, parseInt(tempDateSt[0])));
                                    setFuturePeriodStartDateModified(val);
                                    setFuturePeriodStartDate(val);
                                }
                                if (!!curPrdTxtArr2 && curPrdTxtArr2.length > 1 && !!curPrdTxtArr2[1]) {
                                    let tempDateSt = curPrdTxtArr2[1].trim().split("/");
                                    let val = formatDate(new Date(parseInt(tempDateSt[2]), parseInt(tempDateSt[1])-1, parseInt(tempDateSt[0])));
                                    setFuturePeriodEndDateModified(val);
                                    setFuturePeriodEndDate(val);
                                }
                            }
                            else {
                                setFuturePeriodStartDateModified("");
                                setFuturePeriodStartDate("");
                                setFuturePeriodEndDateModified("");
                                setFuturePeriodEndDate("");
                            }

                            let val2 = getEndOfPeriodTypeInText(getNumberValueOrDefault(profile.endOfPeriodAction, 1));
                            setPostEndOfPeriodActionTypeModified(val2);
                            setPostEndOfPeriodActionType(val2);
                        }
                    }
                    setLoading(false);
                    setGuideMe();
                } else {
                    setShowWarningModal(true);
                    setHasErrored(true);
                    setSaveSuccess(false);
                    setModalPopupMessage(allResourcesToTranslations.cpdmsggetcpdwarn);
                }
            });
    }

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (!isNaN(givenDate.getTime())) {
            const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
            const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
            const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
    
            return (year.toString() + "-" + 
            (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
            (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
        }
        else {
            return "";
        }
    }

    const handleExclusionOfCompletedUsers = (isExcluded: boolean) => {
        setCompletedUsersToExclude(isExcluded);
    }

    useEffect(() => {
        setIsOrgCPDsLoading(true);
        setTitle(allResourcesToTranslations.cpdprofile); /* Removed capital letter */
        setTitleEnglish(pageTitles.cpdprofile);
        updateBackButtonRoute("/cpdprofileslist");
        if (parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) {           
            loadGrantedUnitsAndUsers();
            loadGrantedUserDetails("view");
            loadProfile(parseInt(cpdProfileIdToEdit), parseInt(cpdProfilePeriodIdToEdit)); 
        }
        else {
            
            // getOrgCPDProfile(
            //     oidcUser.access_token,
            //     userId,
            //     orgDomain,
            //     parseInt(orgId)).then(
            //       (orgCPDProfRes) => {
                
            GetCPDProfilesList(oidcUser.access_token,
                userId,
                orgDomain,
                parseInt(orgId),"en-au").then((orgCPDProfRes:any) => {   
                  
                    if (!!orgCPDProfRes && orgCPDProfRes.isSuccess) {
                        let cpdProfilesMod : CPDProfile[] = [];
                        if (!!orgCPDProfRes.cpdProfiles && orgCPDProfRes.cpdProfiles.length > 0) {
                            cpdProfilesMod.push({profileID : -1, profileName: "", profilePeriodID: -1, profileStatus: "",
                                                dateStart: null, dateEnd: null, futureDateStart: null, futureDateEnd: null, dateStartUTC: null, dateEndUTC: null, 
                                                futureDateStartUTC: null, futureDateEndUTC: null, currentPeriod: "", futurePeriod: "", points: 0.0, futurePoints: 0.0,
                                                endOfPeriodAction: "1", futureMonthIncrement: null, applyToQuiz: null, applyToLesson: null, applyToNewsfeed: null});
                            cpdProfilesMod.push(...orgCPDProfRes.cpdProfiles);
                            orgCPDProfRes.cpdProfiles = cpdProfilesMod;
                        }
                        else 
                        {
                            cpdProfilesMod.push({profileID : -1, profileName: "", profilePeriodID: -1, profileStatus: "",
                                                dateStart: null, dateEnd: null, futureDateStart: null, futureDateEnd: null, dateStartUTC: null, dateEndUTC: null, 
                                                futureDateStartUTC: null, futureDateEndUTC: null, currentPeriod: "", futurePeriod: "", points: 0.0, futurePoints: 0.0,
                                                endOfPeriodAction: "1", futureMonthIncrement: null, applyToQuiz: null, applyToLesson: null, applyToNewsfeed: null});
                            cpdProfilesMod.push(...orgCPDProfRes.cpdProfiles);
                            orgCPDProfRes.cpdProfiles = cpdProfilesMod;
                        }
                        setOrgCPDProfileResult(orgCPDProfRes);
                        let defaultSelectionAsNone = orgCPDProfRes.cpdProfiles[0];
                        setSelectedCPDProfile(defaultSelectionAsNone);
                        updateCurrentProfileSelection(defaultSelectionAsNone);
                    }
                    else {
                        // let defaultSelectionAsNone = orgCPDProfRes.cpdProfiles[orgCPDProfRes.cpdProfiles.length - 1];
                        // setSelectedCPDProfile(defaultSelectionAsNone);
                        // updateCurrentProfileSelection(defaultSelectionAsNone);
                        console.log("Getting the other CPD profiles of the organisation for cloning is failed");
                    }
                    setIsOrgCPDsLoading(false);
                });
        }
        setGuideMe();
        return () => { };
    }, []);

    const updateCurrentProfileSelection = (givenSelectedCPDProfile: any) => {
      let matchedCPDProfiles : CPDProfile[] | null = null;
      if (!!givenSelectedCPDProfile && !!givenSelectedCPDProfile.profileID && !!givenSelectedCPDProfile.profileName && givenSelectedCPDProfile.profileID > 0) {
        if (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfiles && 
            (matchedCPDProfiles = (orgCPDProfileResult.cpdProfiles as CPDProfile[]).filter(c => !!c && !!c.profileID && !!c.profileName && c.profileID == givenSelectedCPDProfile.profileID)) != null &&
            matchedCPDProfiles.length > 0 && matchedCPDProfiles[0] != null) {
                let matchedCPD : CPDProfile = matchedCPDProfiles[0];
                setClonedCPDProfileID(matchedCPD.profileID);
                setClonedCPDProfilePeriodID(matchedCPD.profilePeriodID);

                setCPDProfileNameModified(matchedCPD.profileName);
                setCPDPointsModified(matchedCPD.points);
                setCPDPointsModifiedTxt(!!matchedCPD.points ? matchedCPD.points.toFixed(2) : "0.00");
                setCPDFuturePointsModified(matchedCPD.futurePoints);
                setCPDFuturePointsModifiedTxt(!!matchedCPD.futurePoints ? matchedCPD.futurePoints.toFixed(2) : "0.00");

                const curPrdTxtArr = !!matchedCPD.currentPeriod ? matchedCPD.currentPeriod.trim().split("-") : [];
                if (!!curPrdTxtArr && curPrdTxtArr.length > 0 && !!curPrdTxtArr[0]) {
                    let tempDateSt = curPrdTxtArr[0].trim().split("/");
                    let val = formatDate(new Date(parseInt(tempDateSt[2]), parseInt(tempDateSt[1])-1, parseInt(tempDateSt[0])));
                    setCurrentPeriodStartDateModified(val);
                }
                else {
                    setCurrentPeriodStartDateModified("");
                }
                if (!!curPrdTxtArr && curPrdTxtArr.length > 1 && !!curPrdTxtArr[1]) {
                    let tempDateSt2 = curPrdTxtArr[1].trim().split("/");
                    let val2 = formatDate(new Date(parseInt(tempDateSt2[2]), parseInt(tempDateSt2[1])-1, parseInt(tempDateSt2[0])));
                    setCurrentPeriodEndDateModified(val2);
                }
                else {
                    setCurrentPeriodEndDateModified("");
                }
                const futPrdTxtArr = !!matchedCPD.futurePeriod ? matchedCPD.futurePeriod.trim().split("-") : [];
                if (!!futPrdTxtArr && futPrdTxtArr.length > 0 && !!futPrdTxtArr[0]) {
                    let tempDateSt3 = futPrdTxtArr[0].trim().split("/");
                    let val3 = formatDate(new Date(parseInt(tempDateSt3[2]), parseInt(tempDateSt3[1])-1, parseInt(tempDateSt3[0])));
                    setFuturePeriodStartDateModified(val3);
                }
                else {
                    setFuturePeriodStartDateModified("");
                }
                if (!!futPrdTxtArr && futPrdTxtArr.length > 1 && !!futPrdTxtArr[1]) {
                    let tempDateSt4 = futPrdTxtArr[1].trim().split("/");
                    let val4 = formatDate(new Date(parseInt(tempDateSt4[2]), parseInt(tempDateSt4[1])-1, parseInt(tempDateSt4[0])));
                    setFuturePeriodEndDateModified(val4);
                }
                else {
                    setFuturePeriodEndDateModified("");
                }
                let eopAction = getEndOfPeriodTypeInText(getNumberValueOrDefault(matchedCPD.endOfPeriodAction, 1));
                setPostEndOfPeriodActionTypeModified(eopAction);
                setCPDFutureMonthIncrementModified(matchedCPD.futureMonthIncrement);
        }
        else {
        }
      }
    }

    useEffect(() => {
      //debugger
      updateCurrentProfileSelection(selectedCPDProfile);
    //   var cpdProfilePeriods = (!!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems) ? selectedCPDProfile.profilePeriodItems.map((cpdp : any) => {
    //       if (!!cpdp){
    //           return {value: cpdp.id, text: cpdp.name, profilePeriodName:cpdp.name, profilePeriodID: cpdp.id }; 
    //       }
    //   }) : null;
    }, [selectedCPDProfile]);

    // useEffect(() => {
    //     if (showViewAccounts && !showViewAccountsLoaded) {         
    //         loadGrantedUserDetails("view");
    //         setShowViewAccountsLoaded(true);
    //     }
    // }, [showAssignAccounts, showAssignPoints, showAssignUnits, showViewAccounts, selectedUnitsCSVInitial, selectedUsersCSVInitial, profileAssignedUsersList]);

    //useEffect(() => {
    //    console.log("changedExtCertIDsToPoints: " + changedExtCertIDsToPoints);
    //    console.log("changedModuleIDsToPoints: " + changedModuleIDsToPoints);
    //    console.log("changedPolicyIDsToPoints: " + changedPolicyIDsToPoints);
    //}, [changedExtCertIDsToPoints, changedModuleIDsToPoints, changedPolicyIDsToPoints]);

    const getEndOfPeriodTypeInNumber = (val: string) => {
        if (val === null || val === undefined || val.trimLeft().trimRight() === "")
        {
            return 1;
        }
        let trimmedVal =  val.trimLeft().trimRight();
        if (trimmedVal.toLowerCase() == allResourcesToTranslations.attheendofperiodnoaction.toLowerCase()) {
            return 1;
        }
        else if (trimmedVal.toLowerCase() == allResourcesToTranslations.attheendofperiodautoincrement.toLowerCase()) {
            return 2;
        }
        else if (trimmedVal.toLowerCase() == allResourcesToTranslations.attheendofperiodgivenmonthincrementguideme.toLowerCase()) {
            return 3;
        }
        else if (trimmedVal.toLowerCase() == allResourcesToTranslations.attheendofperiodgivenfutureperiod.toLowerCase()) {
            return 4;
        }
        return 1;
    }

    const getEndOfPeriodTypeInText = (val: number) => {
        if (val === null || val === undefined || val <= 0)
        {
            return allResourcesToTranslations.attheendofperiodnoaction;
        }
        if (val == 1) {
            return allResourcesToTranslations.attheendofperiodnoaction;
        }
        else if (val == 2) {
            return allResourcesToTranslations.attheendofperiodautoincrement;
        }
        else if (val == 3) {
            return allResourcesToTranslations.attheendofperiodgivenmonthincrementguideme;
        }
        else if (val == 4) {
            return allResourcesToTranslations.attheendofperiodgivenfutureperiod
        }
        return allResourcesToTranslations.attheendofperiodnoaction;
    }

    const handlePostEndOfPeriodActionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = (event.target as HTMLInputElement).value;
      const actionType = ((val === null || val === undefined || val.trimLeft().trimRight() === "") ? 1 : getEndOfPeriodTypeInNumber(val.trimLeft().trimRight()));  
      //setPostEndOfPeriodActionType(actionType);
      setPostEndOfPeriodActionTypeModified(val.trimLeft().trimRight());
    };

    const getNumberValueOrDefault = (givenTxt: string, defaultVal: number) => {
        if (!!givenTxt === false) {
            return defaultVal;
        }
        try {
            let givenVal = parseInt(givenTxt);
            if (givenVal <= 0 || Number.isNaN(givenVal)) {
                return defaultVal;
            }
            return givenVal;
        }
        catch {
            return defaultVal;
        }
    }

    const getNumberFloatValueOrDefault = (givenTxt: string, defaultVal: number) => {
        if (!!givenTxt === false) {
            return defaultVal;
        }
        try {
            let givenVal = parseFloat(givenTxt);
            if (givenVal < 0 || Number.isNaN(givenVal)) {
                return defaultVal;
            }
            return givenVal;
        }
        catch {
            return defaultVal;
        }
    }

    const handleModuleProfilePointChanges = (changedVal: string) => {
        setChangedModuleIDsToPoints(changedVal);
    }

    const handlePolicyProfilePointChanges = (changedVal: string) => {
        setChangedPolicyIDsToPoints(changedVal);
    }

    const handleExtCertProfilePointChanges = (changedVal: string) => {
        setChangedExtCertIDsToPoints(changedVal);
    }

    const handleModuleProfilePointInitialValues = (changedVal: string) => {
        setModuleIDsToPointsInitial(changedVal);
    }

    const handlePolicyProfilePointInitialValues = (changedVal: string) => {
        setPolicyIDsToPointsInitial(changedVal);
    }

    const handleExtCertProfilePointInitialValues = (changedVal: string) => {
        setExtCertIDsToPointsInitial(changedVal);
    }

    const handleWindowMidModalForConfirmation = (value: boolean) => {
        setShowWindowMidWarningModalForConfirmation(value);
    }

    const getLatestValueOfModifiedModules = () => {
        return changedModuleIDsToPoints;
    }

    const getLatestValueOfModifiedPolicies = () => {
        return changedPolicyIDsToPoints;
    }

    const getLatestValueOfModifiedExtCerts = () => {
        return changedExtCertIDsToPoints;
    }

    const getLatestValueOfInitialModules = () => {
        if (showWarningModal) {
            handleClosePopup();
        }
        return moduleIDsToPointsInitial;
    }

    const getLatestValueOfInitialPolicies = () => {
        if (showWarningModal) {
            handleClosePopup();
        }
        return policyIDsToPointsInitial;
    }

    const getLatestValueOfInitialExtCerts = () => {
        if (showWarningModal) {
            handleClosePopup();
        }
        return extCertIDsToPointsInitial;
    }

    const setAllDeniedUnitsAsCSVFunction = (csvGiven: string) => {
        setCSVOfAllDeniedUnits(csvGiven);
    }

    useEffect(() => {
        setCurrentCPDReportResultList(currentCPDReportResultList);
      }, [currentCPDReportResultList]);

    const handleCPDReportSectionExpandClick = () => {	      
      setExpandCurrentCPDReportList(!expandCurrentCPDReportList);
      if (!expandCurrentCPDReportList) {
        if (!!loadedProfile && parseInt(loadedProfile.profileID) > 0 && parseInt(loadedProfile.profilePeriodID) > 0) {
            setIsReportRunning(true);	
            getFullCPDReport(
            oidcUser.access_token, userId, orgDomain, parseInt(orgId),
            "", parseInt(loadedProfile.profileID), parseInt(loadedProfile.profilePeriodID),
            loadedProfile.profileName, !!selectedCPDProfile && !!selectedCPDProfile.cpdProfileItems && selectedCPDProfile.cpdProfileItems.length > 0 && 
            !!selectedCPDProfile.cpdProfileItems[0] && !!selectedCPDProfile.cpdProfileItems[0].profilePeriodItems && selectedCPDProfile.cpdProfileItems[0].profilePeriodItems.length > 0 && 
            !!selectedCPDProfile.cpdProfileItems[0].profilePeriodItems[0] ? selectedCPDProfile.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName : "", 
            "", "", "", 0, true, true, true
          ).then((response) => {
            setIsReportRunning(false);	
            if (
              response !== null &&
              response !== undefined &&
              response.isSuccess &&
              response.cpdReportItems !== null &&
              response.cpdReportItems !== undefined
            ) {
              //console.log("CPD rows: " + response.cpdReportItems.length);
              if (response.cpdReportItems.length > 0) {
                setCurrentCPDReportResult(response);
                setCurrentCPDReportResultList(response.cpdReportItems);
                setCurrentCPDReportOpen(true);              
                //let element = document.getElementById("currentCPDReportList");
                // if (!!element)
                //   element.scrollIntoView();      
              } 
            } else { 
                setCurrentCPDReportResultList([]);
                setCurrentCPDReportError("Current CPD profile report failed to load");                       
                return;
            }
          });
        }
        else {
            setCurrentCPDReportResultList([]);
        }
      }
    }

    const handleCancelFromWindowMidModalForConfirmation = () => {
        setShowWindowMidWarningModalForConfirmation(false);
        setShowWindowMidWarningModalForWarning(false);
        
        setFinalSave(false);
        setFinalSavePoints(false);
        setFinalSaveUnits(false);
        setFinalSaveAccounts(false);
        setFinalSaveRemoveAccounts(false);
        
        setRefreshAfterSave(false);
        setShowWarningModal(false);
        setNeedLatestUnits(false);
        setCheckForNoUnitChanges(false);

        setIsSaving(false);
        setIsSavingPoints(false);
        setIsSavingUnits(false);
        setIsSavingAccounts(false);
        setIsSavingAccountRemovals(false);

        setShowBasicCPDDetails(true);
    }

    const handleSaveConfirmProceedFromWindowMidModalForConfirmation = () => {

        setShowWindowMidWarningModalForConfirmation(false);
        setShowWindowMidWarningModalForWarning(false);
        handleCancelFromWindowMidModalForWarning();
    }

    const handleWindowMidModalForWarning = (value: boolean) => {
        setShowWindowMidWarningModalForWarning(value);
    }

    const handleCancelFromWindowMidModalForWarning = () => {
        setShowWindowMidWarningModalForWarning(false);
        if (finalSave) {
            doFinalSave();
            setFinalSave(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSavePoints) {
            doFinalSavePoints();
            setFinalSavePoints(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSaveUnits) {
            doFinalSaveUnits();
            setFinalSaveUnits(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSaveAccounts) {
            doFinalSaveAccounts();
            setFinalSaveAccounts(false);
            setShowBasicCPDDetails(true);
        }
        else if (finalSaveRemoveAccounts) {
            doFinalSaveAccountRemovals();
            setFinalSaveRemoveAccounts(false);
            setShowBasicCPDDetails(true);
        }
        else if (isGuideMeWithAssignPoints) {
            setGuideMeClicked(true); 
            setExpandProfileBasicDetails(true); 
            setGuideMe(showAssignPoints, showAssignUnits, showAssignAccounts, showViewAccounts); 
            setIntro({ ...intro, stepsEnabled: true });
        }
    }

    if (loading) return <LoaderContainer />;
    return (
        <>
            <Modal modalOpen={showWindowMidWarningModalForWarning} setModalOpen={handleWindowMidModalForWarning} type="warning" hideCloseBtn={true}>
                <div className={styles.modalContentWrapper}>
                    <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Warning</h2>
                    <div className={styles.inputContainer}>

                        <p>{windowMidModelMessage}</p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "10px",
                        }}
                    >
                        <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                            <span className={styles.returnIcon} onClick={handleCancelFromWindowMidModalForWarning}>
                                <CloseWhiteIcon />
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            </Modal>
            <Modal modalOpen={showWindowMidWarningModalForConfirmation} setModalOpen={handleWindowMidModalForConfirmation} type="warning" hideCloseBtn={true}>
                <div className={styles.modalContentWrapper}>
                    <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
                    <div className={styles.inputContainer}>

                        <p>{windowMidModelMessage}</p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                            top: "10px",
                        }}
                    >
                        <CustomTooltip displayText={[allResourcesToTranslations.return]}>
                            <span className={styles.returnIcon} onClick={handleCancelFromWindowMidModalForConfirmation}>
                                <CloseWhiteIcon />
                            </span>
                        </CustomTooltip>
                        <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
                            <span className="saveData">
                                <Button
                                    onClick={handleSaveConfirmProceedFromWindowMidModalForConfirmation}
                                    variant="contained"
                                    color="primary"
                                    className="save"
                                >
                                    <ProceedIcon />
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            </Modal>
            <Steps
                enabled={intro.stepsEnabled}
                steps={intro.steps}
                initialStep={intro.initialStep}
                onExit={onExit}
                options={{
                    nextLabel: "Next",
                    prevLabel: "Back",
                    tooltipClass: "sample-class",
                    showBullets: true,
                }} />
            <CustomTooltip displayText={["Guide Me"]}>
                <span 
                    onClick={() => { 
                        if (showAssignPoints) {
                            setIsCPDAssignPointsGuidemeClicked(true);
                            setIsGuideMeWithAssignPoints(true);
                            setWindowMidModelMessage(allResourcesToTranslations.guidemewithassignpointswarning);
                            setShowWindowMidWarningModalForWarning(true);
                        }
                        else {
                            setGuideMeClicked(true); 
                            setExpandProfileBasicDetails(true); 
                            setGuideMe(showAssignPoints, showAssignUnits, showAssignAccounts, showViewAccounts); 
                            setIntro({ ...intro, stepsEnabled: true })
                        }
                    }}
                    className={styles.guideMeButtonSpecific} style={{ right: '100px', top: '100px' }}>
                    <GuideIcon />
                </span>
            </CustomTooltip>

            <UnbrandedCard hidden={!showBasicCPDDetails}>
                <Accordion style={{ margin: "0px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none", backgroundColor: "#FFFFFF" }} 
                            expanded={expandProfileBasicDetails} hidden={!showBasicCPDDetails}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="unitadmin-content"
                        id="unitadmin-header"
                        onClick={(e) => setExpandProfileBasicDetails(!expandProfileBasicDetails) }
                    >
                        {/* <Typography> */}
                        <div style={{ justifyContent: "space-between", padding: "15px 0px 20px 20px", marginLeft: "-15px"}} key={"m1mainDivOfProfilePoints"}>
                            <div className={styles.lableContainer} >
                                <span className={`${styles.labelText}`}>{parseInt(cpdProfileIdToEdit) <= 0 ? allResourcesToTranslations.addnewprofile : allResourcesToTranslations.editprofile}</span>
                                {/* {(expandPolicyAcceptanceChart && <p
                                                            style={{
                                                                color: "#D3D6DF",
                                                                fontSize: "12px",
                                                            }}> {acceptedUsers + ' Accounts / ' + totalAssignedUsersToPolicy + ' Accounts'}</p>)} */}
                            </div>
                        </div>
                        {/* </Typography> */}
                    </AccordionSummary>
                    {/* <AccordionDetails style={{ paddingTop: "0px", width: '98%' }}>
                        <Typography style={{ width: '100%' }}> */}
                        <div style={{ padding: "10px 20px 20px 20px", width: '97%' }}>
                            <div className={styles.mainWrapper}>
                                {/* <div className={styles.inputContainer} >
                                    <span className={`${styles.labelText}`}>{parseInt(cpdProfileIdToEdit) <= 0 ? allResourcesToTranslations.addnewprofile : allResourcesToTranslations.editprofile}</span>
                                </div>
                                <br/> */}
                                {
                                    parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0 && 
                                    <>
                                        <div>
                                            <div id="isProfileEnabled" style={{marginTop: "-10px", marginLeft: "-10px"}}>                    
                                                <span >
                                                    <Checkbox disabled={false} id="isProfileEnabledCheck"
                                                            size="small"
                                                            color="primary"
                                                            checked={profileEnabledModified}
                                                            className={styles.checkBox}
                                                            onClick={(e) => { setProfileEnabledModified(!profileEnabledModified); }}
                                                        /> 
                                                    <span id="isProfileEnabledLabel" 
                                                        onClick={(e) => { setProfileEnabledModified(!profileEnabledModified); }}
                                                        style={{fontSize: '16px', fontWeight: 'bold', color: '#21394F'}}>{allResourcesToTranslations.enableemailrule}</span>   
                                                </span>
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                }
                                {      
                                    (parseInt(cpdProfileIdToEdit) <= 0 || parseInt(cpdProfilePeriodIdToEdit) <= 0) && 
                                    <>     
                                        {
                                            isOrgCPDsLoading &&
                                            <>
                                                <div className={styles.loaderContainer}>
                                                    <CircularProgress
                                                        style={{ width: isOrgCPDsLoading ? "20px" : "0px", padding: "15px 10px" }}
                                                        color="primary"
                                                        size="10"
                                                    />
                                                </div>
                                            </>
                                        }   
                                        {
                                            !isOrgCPDsLoading &&
                                            <>                          
                                                <div>
                                                    <div className={styles.groupingOption} id="cpdProfileCloningDiv">                                                
                                                        <FormControl variant="outlined" className={`${styles.formControl} cpdProfileCloningFormCtrl`}>                      
                                                            <InputLabel id="cpdProfileIDClone">{allResourcesToTranslations.profilecloninglabel + ":"}</InputLabel>
                                                            <Select
                                                            value={
                                                                !!selectedCPDProfile ? selectedCPDProfile.value : "-1"
                                                            }
                                                            native
                                                            onChange={(e : any) => { 
                                                                let profID : string = !!e.target.value ? e.target.value.toString() : "";
                                                                let matched = !!profID && !!orgCPDProfileResult ? 
                                                                                orgCPDProfileResult.cpdProfiles.filter((cur : any) => !!cur && 
                                                                                    parseInt(cur.profileID) === parseInt(profID)) : null;
                                                                if (!!matched)
                                                                {
                                                                    var curSel = {value: e.target.value, profileID: e.target.value, 
                                                                        profileName: (!!matched && !!matched[0]) ? matched[0].profileName : ""};
                                                                    setSelectedCPDProfile(curSel);
                                                                    updateCurrentProfileSelection(curSel);
                                                                }
                                                            }}
                                                            defaultValue={{value: "-1", profileID: "-1", profileName: ""}}
                                                            className={styles.inputSelect}
                                                            variant="outlined" 
                                                            label={allResourcesToTranslations.profilecloninglabel + ":"}
                                                            id="cpdProfileIDCloneSelect"
                                                            >
                                                            {
                                                                !!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfiles && 
                                                                orgCPDProfileResult.cpdProfiles.map((item:any) => {
                                                                    return <option key={item.profileID} value={item.profileID}>{item.profileID <= 0 ? allResourcesToTranslations.profilecloningnoselection : item.profileName}</option>
                                                                })
                                                            }
                                                        </Select>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <br />
                                        <br />
                                    </>
                                }
                                <div className={responsivestyles.rowrule} style={{ width: '100%' }} >
                                    <div >
                                        <div className={parseInt(cpdProfileIdToEdit) <= 0 ? `${styles.inputContainer} addnewprofileguideme` : `${styles.inputContainer} editprofileguideme`}>
                                            <TextField className={styles.input} variant="outlined" label={"Profile Name"}
                                                autoComplete="off" InputLabelProps={{ shrink: true, }}
                                                value={cpdProfileNameModified} onChange={(e) => setCPDProfileNameModified(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                {
                                    parseInt(cpdProfileIdToEdit) <= 0 ?
                                    <>
                                        <div className={`${styles.inputContainer} profilenewperiodguideme`} >
                                            <span className={`${styles.labelText} profilenewperiodguideme`}>{allResourcesToTranslations.profilenewperiod}</span>
                                        </div>    
                                        <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
                                            <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12} profilenewperiodtoguidemefrom`} >
                                                <TextField className={styles.input} label={"Start Date"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                                                            type="date" defaultValue={currentPeriodStartDateModified} value={currentPeriodStartDateModified} onChange={(e) => setCurrentPeriodStartDateModified(e.target.value)}/>
                                            </div>                                            
                                            <div className={`${responsivestyles.columnrule_l1} ${responsivestyles.columnrule_s_12}`} >
                                                <span style={{justifyContent: 'center', display: 'flex', padding: '20px 0px 0px 0px'}}> to </span>
                                            </div>
                                            <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12} profilenewperiodtoguidemeto`} >
                                                <TextField className={styles.input} label={"End Date"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                                                            type="date" defaultValue={currentPeriodEndDateModified} value={currentPeriodEndDateModified} onChange={(e) => setCurrentPeriodEndDateModified(e.target.value)}/>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className={responsivestyles.rowrule} style={{ width: '100%' }} >
                                            <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12}`}>
                                                <div className={`${styles.inputContainer} profilenewperiodrequiredpointsguideme`}>
                                                    <TextField className={styles.input} label={allResourcesToTranslations.requiredpoints} variant="outlined"
                                                        autoComplete="off" InputLabelProps={{ shrink: true, }}
                                                        //value={(!!cpdPointsModified && cpdPointsModified >= 0) ? cpdPointsModified.toFixed(2) : ""} 
                                                        value={(!!cpdPointsModifiedTxt && cpdPointsModifiedTxt.trim() != "") ? cpdPointsModifiedTxt : ""} 
                                                        onChange={(e) => {setCPDPointsModifiedTxt(e.target.value); setCPDPointsModified(getNumberFloatValueOrDefault(e.target.value, -1)) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                    </>
                                    :
                                    <>
                                        <div className={styles.inputContainer} >
                                            <span className={`${styles.labelText} profilecurrentperiodguideme`}>{allResourcesToTranslations.currentperiod}</span>
                                        </div>    
                                        <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
                                            <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12} profilecurrentperiodtoguidemefrom`} >
                                                <TextField className={styles.input} label={"Start Date"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                                                            type="date" defaultValue={currentPeriodStartDateModified} value={currentPeriodStartDateModified} onChange={(e) => setCurrentPeriodStartDateModified(e.target.value)}/>
                                            </div>    
                                            <div className={`${responsivestyles.columnrule_l1} ${responsivestyles.columnrule_s_12}`} >
                                                <span style={{justifyContent: 'center', display: 'flex', padding: '20px 0px 0px 0px'}}> to </span>
                                            </div>
                                            <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12} profilecurrentperiodtoguidemeto`} >
                                                <TextField className={styles.input} label={"End Date"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                                                            type="date" defaultValue={currentPeriodEndDateModified} value={currentPeriodEndDateModified} onChange={(e) => setCurrentPeriodEndDateModified(e.target.value)}/>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className={responsivestyles.rowrule} style={{ width: '100%' }} >
                                            <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12}`}>
                                                <div className={`${styles.inputContainer} profilecurrentperiodrequiredpointsguideme`}>
                                                    <TextField className={styles.input} label={allResourcesToTranslations.requiredpoints} variant="outlined"
                                                        autoComplete="off" InputLabelProps={{ shrink: true, }}
                                                        //value={(!!cpdPointsModified && cpdPointsModified >= 0) ? cpdPointsModified.toFixed(2) : ""} 
                                                        value={(!!cpdPointsModifiedTxt && cpdPointsModifiedTxt.trim() != "") ? cpdPointsModifiedTxt : ""} 
                                                        onChange={(e) => {setCPDPointsModifiedTxt(e.target.value); setCPDPointsModified(getNumberFloatValueOrDefault(e.target.value, -1))}} />
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                    </>
                                }
                                <div>
                                    <FormControl component="fieldset" style={{ width: '100%'  }}>
                                        {/* <FormLabel component="legend" >At the End of Period</FormLabel> */}
                                        <div className={styles.inputContainer} >
                                            <span className={`${styles.labelText}`}>{allResourcesToTranslations.attheendofperiod}</span>
                                        </div>                        
                                        <div className={styles.childContainer}>
                                            <RadioGroup row={false} name="attheendoftheperiod" 
                                                                    value={postEndOfPeriodActionTypeModified} 
                                                                    onChange={handlePostEndOfPeriodActionTypeChange}>
                                                <FormControlLabel value={allResourcesToTranslations.attheendofperiodnoaction} 
                                                                    className={`attheendofperiodnoactionguideme`}
                                                                    control={<Radio color="primary" />} 
                                                                    label={allResourcesToTranslations.attheendofperiodnoaction} 
                                                                    key={`attheendoftheperioditem-1`} />
                                                <FormControlLabel value={allResourcesToTranslations.attheendofperiodautoincrement} 
                                                                    className={`attheendofperiodautoincrementguideme`}
                                                                    control={<Radio color="primary" />} 
                                                                    label={allResourcesToTranslations.attheendofperiodautoincrement}
                                                                    key={`attheendoftheperioditem-2`} />
                                                <FormControlLabel value={allResourcesToTranslations.attheendofperiodgivenmonthincrementguideme} 
                                                                    className={`attheendofperiodgivenmonthincrementguideme`} 
                                                                    control={<Radio color="primary" />} 
                                                                    label={<span style={{display:'flex', fontWeight: 'normal'}}>
                                                                            <p style={{color:'rgba(0,0,0,0.8)'}}>{allResourcesToTranslations.attheendofperiodgivenmonthincrement1} &nbsp;</p>
                                                                            <div className={styles.inputContainerDatePicker}>
                                                                                <TextField
                                                                                    name={"periodFromDate"}
                                                                                    label=""
                                                                                    variant="outlined"
                                                                                    autoComplete="off"
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                    value={(!!cpdFutureMonthIncrementModified && cpdFutureMonthIncrementModified > 0) ? 
                                                                                                cpdFutureMonthIncrementModified.toString() : ""} 
                                                                                    onChange={(e) => setCPDFutureMonthIncrementModified(getNumberValueOrDefault(e.target.value, 1))} 
                                                                                    style={{width: '80px', marginTop: '-5px'}}
                                                                                />
                                                                            </div>
                                                                            <br />
                                                                            <p style={{display:'block',color:'rgba(0,0,0,0.8)'}}>&nbsp; {(!!cpdFutureMonthIncrementModified && cpdFutureMonthIncrementModified > 1) ? allResourcesToTranslations.attheendofperiodgivenmonthincrement3 : allResourcesToTranslations.attheendofperiodgivenmonthincrement2} </p>
                                                                            </span>} 
                                                                    key={`attheendoftheperioditem-3`} />
                                                <FormControlLabel value={allResourcesToTranslations.attheendofperiodgivenfutureperiod} 
                                                                    className={`attheendofperiodgivenfutureperiodguideme`}
                                                                    control={<Radio color="primary" />} 
                                                                    label={allResourcesToTranslations.attheendofperiodgivenfutureperiod} 
                                                                    key={`attheendoftheperioditem-4`} />
                                            </RadioGroup>                            
                                        </div>
                                    </FormControl>  
                                    <br/> 
                                    <br/> 
                                    {/* {
                                        postEndOfPeriodActionTypeModified.toLowerCase() == allResourcesToTranslations.attheendofperiodgivenfutureperiod.toLowerCase() &&
                                        <> */}
                                            <div className={responsivestyles.rowrule} style={{ width: '100%' }}>
                                                <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12} attheendofperiodgivenfutureperiodfromguideme`} >
                                                    <TextField className={styles.input} label={"Start Date"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}  
                                                                disabled={postEndOfPeriodActionTypeModified.trim().toLowerCase() != allResourcesToTranslations.attheendofperiodgivenfutureperiod.toLowerCase()}                    
                                                                type="date" defaultValue={futurePeriodStartDateModified} value={futurePeriodStartDateModified} onChange={(e) => setFuturePeriodStartDateModified(e.target.value)}/>
                                                </div>
                                                <div className={`${responsivestyles.columnrule_l1} ${responsivestyles.columnrule_s_12}`} >
                                                    <span style={{justifyContent: 'center', display: 'flex', padding: '20px 0px 0px 0px'}}> to </span>
                                                </div>
                                                <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12} attheendofperiodgivenfutureperiodtoguideme`} >
                                                    <TextField className={styles.input} label={"End Date"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}  
                                                                disabled={postEndOfPeriodActionTypeModified.trim().toLowerCase() != allResourcesToTranslations.attheendofperiodgivenfutureperiod.toLowerCase()}                    
                                                                type="date" defaultValue={futurePeriodEndDateModified} value={futurePeriodEndDateModified} onChange={(e) => setFuturePeriodEndDateModified(e.target.value)}/>
                                                </div>
                                            </div>
                                            <br/>
                                            <br/>
                                            <div className={responsivestyles.rowrule} style={{ width: '100%' }} >
                                                <div className={`${responsivestyles.columnrule_l6} ${responsivestyles.columnrule_s_12}`}>
                                                    <div className={`${styles.inputContainer} attheendofperiodgivenfutureperiodpointsguideme`}>
                                                        <TextField className={styles.input} label={allResourcesToTranslations.requiredpoints} variant="outlined"
                                                            autoComplete="off" InputLabelProps={{ shrink: true, }}
                                                            disabled={postEndOfPeriodActionTypeModified.trim().toLowerCase() == allResourcesToTranslations.attheendofperiodnoaction.toLowerCase()}
                                                            //value={(!!cpdFuturePointsModified && cpdFuturePointsModified >= 0) ? cpdFuturePointsModified.toFixed(2) : ""} 
                                                            value={(!!cpdFuturePointsModifiedTxt && cpdFuturePointsModifiedTxt.trim() != "") ? cpdFuturePointsModifiedTxt : ""} 
                                                            onChange={(e) => {setCPDFuturePointsModifiedTxt(e.target.value); setCPDFuturePointsModified(getNumberFloatValueOrDefault(e.target.value, -1))}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
                                                <span id={"saveprofiledataguidemenew"} >
                                                    <CustomTooltip displayText={[allResourcesToTranslations.saveprofiledata]} >
                                                        <span className={`saveData saveprofiledataguideme`} style={{ marginRight: "-5px" }}>
                                                            <Button 
                                                                disabled={cpdProfileNameModified.trim() === "" || isAnyValueChanged() == false ||  isSavingPoints || isSaving || isSavingUnits || isSavingAccounts || isSavingAccountRemovals}
                                                                //disabled={true}  
                                                                onClick={handleSave}
                                                                variant="contained"
                                                                color="primary"
                                                                className="save"
                                                            >
                                                                <SaveIcon key="saveProfile"/>
                                                            </Button>
                                                        </span>
                                                    </CustomTooltip>
                                                </span>
                                                <div className={styles.loaderContainer}>
                                                    <CircularProgress
                                                        style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                                                        color="primary"
                                                        size="10"
                                                    />
                                                </div>
                                            </div>
                                            <br/>
                                            {
                                                !!loadedProfile && 
                                                <div id="cpdReportForSpecificProfile">
                                                <Accordion style={{ margin: "5px 0px", borderRadius: "6px 6px 6px 6px", position: 'inherit', boxShadow: "none" }} expanded={expandCurrentCPDReportList}>
                                                    <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="managerSettings-content"
                                                    id="managerSettings-header"
                                                    onClick={(e) => handleCPDReportSectionExpandClick()}
                                                    >
                                                    <Typography className="typographyFonts"><b>{allResourcesToTranslations.cpdreport}</b></Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{padding: "0px"}}> 
                                                        {   
                                                        isReportRunning &&
                                                        <div className={styles.loaderContainer}>
                                                            <CircularProgress
                                                                style={{ width: isReportRunning ? "20px" : "0px", padding: "15px 10px" }}
                                                                color="primary"
                                                                size="10"
                                                            />
                                                        </div>  
                                                        }     
                                                        {!isReportRunning && currentCPDReportOpen && currentCPDReportResultList != null && currentCPDReportResultList.length > 0 && !!loadedProfile && (
                                                        <div id="currentCPDReportList" style={{width: "100%"}}>
                                                            <CPDReportSection  cpdReportResultItems={currentCPDReportResultList}
                                                                                        cpdReportResultMetaData={(currentCPDReportResult === null ? null : 
                                                                                            { isCourseModulePresent: currentCPDReportResult.isCourseModulePresent, isPolicyPresent: currentCPDReportResult.isPolicyPresent,
                                                                                            isExtCertPresent: currentCPDReportResult.isExtCertPresent, isEventPresent: currentCPDReportResult.isEventPresent } as CPDReportResultMetaData)} 
                                                                                        givenFirstName={""} 
                                                                                        givenLastName={""} 
                                                                                        givenUserName={""} 
                                                                                        givenEmail={""} 
                                                                                        resultError={currentCPDReportError} 
                                                                                        onlyShortFallUsers={false} 
                                                                                        selectedProfileName={loadedProfile.profileName} 
                                                                                        selectedProfilePeriodName={!!selectedCPDProfile && !!selectedCPDProfile.cpdProfileItems && selectedCPDProfile.cpdProfileItems.length > 0 && 
                                                                                            !!selectedCPDProfile.cpdProfileItems[0] && !!selectedCPDProfile.cpdProfileItems[0].profilePeriodItems && selectedCPDProfile.cpdProfileItems[0].profilePeriodItems.length > 0 && 
                                                                                            !!selectedCPDProfile.cpdProfileItems[0].profilePeriodItems[0] ? selectedCPDProfile.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName : ""} 
                                                                                        includeCourseModules={currentCPDReportResult.isCourseModulePresent} 
                                                                                        includePolicies={currentCPDReportResult.isPolicyPresent} 
                                                                                        includeExternalCertificates={currentCPDReportResult.isExtCertPresent} 
                                                                                        selectedUnitNames={""} />
                                                        </div>
                                                        )}    
                                                    </AccordionDetails>             
                                                </Accordion>
                                                </div>
                                            }
                                        {/* </>
                                    } */}
                                </div>
                            </div>
                        </div>
                        {/* </Typography>
                    </AccordionDetails> */}
                </Accordion>
            </UnbrandedCard>

            {(
                (parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0) && 
                !(isSavingPoints || isSaving || isSavingUnits || isSavingAccounts || isSavingAccountRemovals) && 
                <AssignButtonPanel isCalledFrom="profile" 
                    handleAssignUnitsClick={() => { if (showWarningModal || showWindowMidWarningModalForConfirmation || showWindowMidWarningModalForWarning) { handleClosePopup(); } resetAllAssignLoadedFlags();  handleAssignUnits();} } 
                    handleAssignAccountsClick={() => { if (showWarningModal || showWindowMidWarningModalForConfirmation || showWindowMidWarningModalForWarning) { handleClosePopup(); } resetAllAssignLoadedFlags();  handleAssignAccounts();} } 
                    handleViewAccountsClick={() => { if (showWarningModal || showWindowMidWarningModalForConfirmation || showWindowMidWarningModalForWarning) { handleClosePopup(); } resetAllAssignLoadedFlags();  handleViewAccounts();} }
                    handleAssignPointsClick={() => { if (showWarningModal || showWindowMidWarningModalForConfirmation || showWindowMidWarningModalForWarning) { handleClosePopup(); } resetAllAssignLoadedFlags();  handleAssignPoints();} }></AssignButtonPanel> 
            )}

            <br />

            {parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0 && showAssignPoints && (
               <AssignPoints isCalledFrom="profile" 
                             profileID={parseInt(cpdProfileIdToEdit)} 
                             profilePeriodID={parseInt(cpdProfilePeriodIdToEdit)} 
                             handleApplyToQuiz={handleApplyToQuizSelection} 
                             handleApplyToLesson={handleApplyToLessonSelection} 
                             handleApplyToNewsfeed={handleApplyToNewsfeedSelection} 
                             handleExclusionOfCompletedUsers={handleExclusionOfCompletedUsers} 
                             handleModuleProfilePointChanges={handleModuleProfilePointChanges}
                             handlePolicyProfilePointChanges={handlePolicyProfilePointChanges}
                             handleExtCertProfilePointChanges={handleExtCertProfilePointChanges}
                             handleModuleProfilePointInitialValues={handleModuleProfilePointInitialValues}
                             handlePolicyProfilePointInitialValues={handlePolicyProfilePointInitialValues}
                             handleExtCertProfilePointInitialValues={handleExtCertProfilePointInitialValues}
                             getLatestValueOfModifiedModules={getLatestValueOfModifiedModules}
                             getLatestValueOfModifiedPolicies={getLatestValueOfModifiedPolicies}
                             getLatestValueOfModifiedExtCerts={getLatestValueOfModifiedExtCerts}
                             getLatestValueOfInitialModules={getLatestValueOfInitialModules}
                             getLatestValueOfInitialPolicies={getLatestValueOfInitialPolicies}
                             getLatestValueOfInitialExtCerts={getLatestValueOfInitialExtCerts}
                             excludeCompletedUsersValue={completedUsersToExclude}
                             applyToQuizValue={cpdApplyToQuiz == 1 ? true : false} 
                             applyToLessonValue={cpdApplyToLesson == 1 ? true : false}
                             applyToNewsfeedValue={cpdApplyToNewsfeed == 1 ? true : false}
                             setPointsLoaded={setPointsLoadedFunction}
                             guideMeClickTriggered={guideMeClicked}></AssignPoints>
            )}

            {parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0 && showAssignUnits && (
               <AssignUnits isCalledFrom="profile" selectedUnitIds={selectedUnitsCSVInitial} setUpdatedSelectedUnits={handleSetOfUpdatedSelectedUnitsFunction} 
               needLatestUnits={needLatestUnits} setUnitsLoaded={setUnitsLoadedFunction} setAllDeniedUnitsAsCSV={setAllDeniedUnitsAsCSVFunction}></AssignUnits>
            )}

            {parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0 && showAssignAccounts && (
               <AccountManagement isCalledFrom={"profile"} needLatestUsers={needLatestAccounts} setUpdatedSelectedUsers={handleSetOfUpdatedSelectedUsersFunction} 
                setRecordsSearched={setRecordsSearchedFunction} setSearchStarted={setRecordsSearchStartedFunction} 
                givenProfileID={parseInt(cpdProfileIdToEdit)} givenProfilePeriodID={parseInt(cpdProfilePeriodIdToEdit)}></AccountManagement>
            )}

            {parseInt(cpdProfileIdToEdit) > 0 && parseInt(cpdProfilePeriodIdToEdit) > 0 && showViewAccounts && (
                <UserListSection isCalledFrom={"profile"} usersData={profileAssignedUsersList} setUpdatedSelectedUsers={handleSetOfUpdatedSelectedUsersFunction} 
                needLatestUsers={needLatestViewAccounts} profileID={parseInt(cpdProfileIdToEdit)} profilePeriodID={parseInt(cpdProfilePeriodIdToEdit)}
                selectedUnitIDs={[]} allUnitIDs={[]} filterFirstName={""} filterLastName={""} filterUserName={""} filterEmail={""}
                />
            )}

            <div id="assignSaveButtonOfCPDProfileDiv" style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
                <CustomTooltip displayText={[(showAssignPoints ? allResourcesToTranslations.saveprofiledatapoints : (showAssignUnits ? allResourcesToTranslations.saveprofiledataunits : (showAssignAccounts ? allResourcesToTranslations.saveprofiledatausers : allResourcesToTranslations.saveprofiledata)))]}
                                hidden={(hasAccountsSearchStarted)
                                                ||                                                
                                                (((!!showAssignPoints === false) && (!!showAssignUnits === false) && (!!showAssignAccounts === false) && (!!showViewAccounts === false)) ||
                                            (!hasPointsLoaded && !hasUnitsLoaded && !hasAccountsSearched && !hasAccountsViewed) ||
                                            isSavingPoints || isSaving || isSavingUnits || isSavingAccounts || isSavingAccountRemovals ||
                                            (showAssignPoints ? (isAnyPointsValueChanged() == false && hasPointsLoaded) : 
                                                (showAssignUnits ? (isAnyUnitsSelectionChanged() == false && hasUnitsLoaded) : 
                                                    (showAssignAccounts ? (isAnyAccountsSelectionChanged() == false && hasAccountsSearched) : 
                                                        (showViewAccounts ? (isAnyViewAccountsChanged() == false && hasAccountsViewed) : (isAnyValueChanged() == false))))))}
                                >
                    <span id={(showAssignPoints ? `saveprofiledatapointsguidemenew` : (showAssignUnits ? `saveprofiledataunitsguidemenew` : (showAssignAccounts ? `saveprofiledatausersguidemenew` : `saveprofiledataguidemenew`)))} 
                        className={`saveData ` + (showAssignPoints ? `saveprofiledatapointsguideme` : (showAssignUnits ? `saveprofiledataunitsguideme` : (showAssignAccounts ? `saveprofiledatausersguideme` : `saveprofiledataguideme`)))} style={{ marginRight: "-5px" }}>
                        <Button id="assignSaveButtonOfCPDProfile" 
                            //disabled={cpdProfileNameModified.trim() === "" || isAnyValueChanged() == false}
                            disabled={(hasAccountsSearchStarted)
                                ||                                                
                                ((!hasPointsLoaded && !hasUnitsLoaded && !hasAccountsSearched && !hasAccountsViewed) ||
                                isSavingPoints || isSaving || isSavingUnits || isSavingAccounts || isSavingAccountRemovals ||
                                (showAssignPoints ? (isAnyPointsValueChanged() == false && hasPointsLoaded) : 
                                    (showAssignUnits ? (isAnyUnitsSelectionChanged() == false && hasUnitsLoaded) : 
                                        (showAssignAccounts ? (isAnyAccountsSelectionChanged() == false && hasAccountsSearched) : 
                                            (showViewAccounts ? (isAnyViewAccountsChanged() == false && hasAccountsViewed) : (isAnyValueChanged() == false))))))}
                            onClick={(showAssignPoints ? handleSavePoints : 
                                        (showAssignUnits ? handleSaveUnits : 
                                            (showAssignAccounts ? handleSaveAccounts : 
                                                (showViewAccounts ? handleSaveRemoveAccounts : ()=>{}))))}
                            variant="contained"
                            color="primary"
                            className={"save"}
                        >
                            <SaveIconClone key="saveProfilePointsUnitsAccounts"/>
                        </Button>
                    </span>
                </CustomTooltip>
                <div className={styles.loaderContainer}>
                    <CircularProgress
                        style={{ width: (isSavingPoints || isSavingUnits || isSavingAccounts || isSavingAccountRemovals) ? "20px" : "0px", padding: "15px 10px" }}
                        color="primary"
                        size="10"
                    />
                </div>
            </div>
            {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} 
                            handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} 
                            bodyText={modalPopupMessage} />}

        </>
    );
};

export default CPDProfileDetails;

