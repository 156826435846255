import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const NewsfeedMenuIcon = () => (    
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.87744 1.5H14.8774C15.7058 1.5 16.3774 2.17163 16.3774 3V4H8.37744V3C8.37744 2.17163 9.04907 1.5 9.87744 1.5ZM21.8774 4H17.8774V3C17.8774 1.34326 16.5342 0 14.8774 0H9.87744C8.2207 0 6.87744 1.34326 6.87744 3V4H2.87744C1.77295 4 0.877441 4.89551 0.877441 6V19C0.877441 20.1045 1.77295 21 2.87744 21H13.8774V19.5H2.87744C2.69751 19.5 2.53955 19.4048 2.45166 19.2622C2.40454 19.186 2.37744 19.0962 2.37744 19V10H6.87744V12H10.8774V10H13.8774V12H17.8774V10H22.3774V13H23.8774V6C23.8774 4.89551 22.9819 4 21.8774 4ZM21.8774 5.5H2.87744C2.69507 5.5 2.5354 5.59766 2.44824 5.74365C2.40332 5.8186 2.37744 5.90625 2.37744 6V8H22.3774V6C22.3774 5.72388 22.1536 5.5 21.8774 5.5ZM24.0442 20.3H18.0857V20.75H24.0442V20.3ZM18.0857 18.95H24.0442V19.3999H18.0857V18.95ZM21.7524 16.25H24.0442V16.7H21.7524V16.25ZM21.7524 17.6001H24.0442V18.05H21.7524V17.6001ZM16.2524 21.7395V14H25.8774V22.0911C25.8774 22.5933 25.4626 23 24.9507 23H15.8032C15.2922 23 14.8774 22.5928 14.8774 22.0911V14.8999H15.7942V21.7395C15.7942 22.0864 16.2524 22.0864 16.2524 21.7395ZM17.1692 21.6499H24.9607V14.8999H17.1692V21.6499ZM18.0857 16.25H20.8357V18.05H18.0857V16.25Z" fill="white"/>
</svg>

);

export default NewsfeedMenuIcon
