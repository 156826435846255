import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/importAccounts.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { Button } from "@material-ui/core";
import ReuploadIcon from "../../../CustomIcons/Administration/ReuploadIcon";

interface Props {
	selectedFile: string;
	uploadFile: Function;
	setSelectedFile: Function;
	setSelectedFileSize: Function;
	recourseID: number;
}

const CourseRecourseReUploadFileDropBox = ({ selectedFile, uploadFile, setSelectedFile, setSelectedFileSize,recourseID }: Props) => {
	const { translations: { allResourcesToTranslations } } = useContext(TranslationsContext);
	const inputEl = useRef<HTMLInputElement>(null);
	const [dragBoxMessage, setDragBoxMessage] = useState<string>(allResourcesToTranslations.dropanddropimportfile);
	const [draggedOver, setDraggedOver] = useState<boolean>(false);
	const [uploadingFile, setUploadingFile] = useState<boolean>(false);
	const [dragError, setDragError] = useState<boolean>(false);
	const [filename, setFilename] = useState<string>("");

	const handleDragOver = (event: any) => {
		event.preventDefault();
		setDraggedOver(true);
	};

	const handleDragEnd = () => {
		setDraggedOver(false);
	};

	const handleClick = (event: any) => {
		inputEl?.current?.click()
	}

	const showError = (errorMessage: string) => {
		setDragError(true);
		setUploadingFile(false);
		setDragBoxMessage(errorMessage);
		setTimeout(() => {
			setDragError(false);
			setDragBoxMessage(allResourcesToTranslations.dropanddropimportfile);
			setDraggedOver(false);
		}, 3000);
	};

	const handleFileDrop = (event: any) => {
		event.preventDefault();

		let sizeLimitMb = 5240000; // 5MB
		if (event.nativeEvent.dataTransfer !== undefined) {
			if (event.nativeEvent.dataTransfer.files.length) {
				const file = event.nativeEvent.dataTransfer.files[0];
				var fileExtension =  (file.name.split('.').pop()).toLowerCase();
				if (file.name !== '' && fileExtension !== '') {
					if (fileExtension.includes("doc") || fileExtension.includes("docx") || fileExtension.includes("pdf") || fileExtension.includes("ppt") || fileExtension.includes("pptx")) {
						if (file.size / 1000000 > sizeLimitMb) {
							return showError("Files must be less than 5MB"); 
						}
						setUploadingFile(true);
						if (inputEl.current) inputEl.current.files = event.nativeEvent.srcElement.files;
						setDragError(false);
						setFilename(file.name);
						setSelectedFile(file);
						setSelectedFileSize(parseInt(file.size));
						let base64String;
						const reader = new FileReader()
						reader.onload = () => {
							base64String = reader?.result?.toString().replace("data:", "").replace(/^.+,/, "");
							uploadFile(base64String, fileExtension,file.name.replace("."+ file.name.split('.').pop(), ""), file.name,recourseID);
						}
						reader.readAsDataURL(file)
					} else {
						showError(allResourcesToTranslations.therewasanissueuploadingfile);
					}
				} else {
					showError(allResourcesToTranslations.therewasanissueuploadingfile);
				}
			}
		}
		else {
			if (event.nativeEvent.srcElement.files.length) {
				let file = event.nativeEvent.srcElement.files[0];
				
				//let splitedFileName = file.name.split(".");
				var fileExtension =  (file.name.split('.').pop()).toLowerCase();
				if (file.name !== '' && fileExtension !== '') {

					if (fileExtension.includes("doc") || fileExtension.includes("docx") || fileExtension.includes("pdf") || fileExtension.includes("ppt") || fileExtension.includes("pptx")) {
						if (file.size / 1000000 > sizeLimitMb) {
							return showError("Files must be less than 5MB");
						}
						if (inputEl.current) inputEl.current.files = event.nativeEvent.srcElement.files;
						setDragError(false);
						setFilename(file.name);
						setSelectedFile(file);
						setSelectedFileSize(parseInt(file.size));
						let base64String;
						const reader = new FileReader()
						reader.onload = () => {
							base64String = reader?.result?.toString().replace("data:", "").replace(/^.+,/, "");
							uploadFile(base64String, fileExtension,file.name.replace("."+ file.name.split('.').pop(), ""), file.name,recourseID);
						}
						reader.readAsDataURL(file)
					} else {
						showError(allResourcesToTranslations.therewasanissueuploadingfile);
					}
				} else {
					showError(allResourcesToTranslations.therewasanissueuploadingfile);
				}
			}
		}
	};

	useEffect(() => {
		if (selectedFile === "") {
			setDraggedOver(false);
		}
	}, [selectedFile]);


	useEffect(() => {
		return () => { };
	}, []);

	return (
		<>
		
				<div
				//className={`${styles.dropZonesmall} ${draggedOver ? styles.dropZoneOver : styles.dragZoneExit} ${dragError ? styles.dropZoneError : ""}`}
				onDrop={(e) => handleFileDrop(e)}
				onDragOver={(e) => handleDragOver(e)}
				onDragEnd={handleDragEnd}
				onDragLeave={handleDragEnd}
				onClick={(e) => handleClick(e)}>					
				<Button className="reuploadcourse" variant="contained" color="primary"> 
						<ReuploadIcon/>			
					</Button>	
					{uploadingFile && <CircularProgress className={styles.uploadingFile} />}
				</div>		
		
			<input ref={inputEl} onInput={handleFileDrop} className={styles.hiddenInput} name="selected-image" type="file" accept=".pdf,.docx,.doc,.PDF,.DOCX,.DOC,.Pdf,.Docx,.Doc"></input>
				
			
		</>
	);
};

export default CourseRecourseReUploadFileDropBox;
