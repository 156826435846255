import axios from 'axios';
import { addError } from './AddError';

export const getCourseStatusReport = async ( token: string, 
    userId: number,
    orgDomain: string,
    orgId: number,
    unitIds: string,
    courseIds: string,
    userFirstName: string,
    userLastName: string,
    userName: string,
    userEmail: string,
    effectiveDateOrg: string,
    groupBy: string, 
    sortBy: string,
    includeInactive: boolean,
    classificationId: number, 
    courseStatus: string,
    fromDate: string,
    toDate: string,
    filterFirstName: string, 
    filterLastName: string,
    filterUserName: string,
    filterEmail: string,
    isSaltCertificatesReport: boolean = false,
    ) => {

      // console.log({
      //   ReportRequestingUserID : userId,
      //   OrganisationID : orgId,
      //   UnitIDs : unitIds,
      //   CourseIDs : courseIds,
      //   CourseModuleStatus : parseInt(courseStatus),
      //   DateFromOrg: fromDate,
      //   DateToOrg: toDate,
      //   IncludeInactive : includeInactive,
      //   ClassificationID : classificationId,
      //   UserFirstName : filterFirstName, //Filter params
      //   UserLastName : filterLastName, //Filter params
      //   UserName :  filterUserName, //Filter params
      //   UserEmail : filterEmail,//Filter params
      //   // effectiveDateOrg : "12/31/2021",
      //   // groupBy : "", //Filter params
      //   // sortBy : "", //Filter params
      //   // adminUserID : userId,
      // })
    try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/userreport/GetFullCourseStatusReport`,
          headers:{
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${token}`
          },
         
    
          data: {
            ReportRequestingUserID : userId,
            OrganisationID : orgId,
            UnitIDs : unitIds,
            CourseIDs : courseIds,
            CourseModuleStatus : parseInt(courseStatus),
            IsIncompleteWithDetails : parseInt(courseStatus) == 6 ? true : false,
            DateFromOrg: fromDate,
            DateToOrg: toDate,
            IncludeInactive : includeInactive,
            ClassificationID : classificationId,
            UserFirstName : filterFirstName, //Filter params
            UserLastName : filterLastName, //Filter params
            UserName :  filterUserName, //Filter params
            UserEmail : filterEmail,//Filter params
            // effectiveDateOrg : "12/31/2021",
            // groupBy : "", //Filter params
            // sortBy : "", //Filter params
            // adminUserID : userId,
            IsSaltCertificatesReport: isSaltCertificatesReport
          }
        });
        const data = await response.data;
        return data;
      }catch (error: any) {
        if (error.response) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.response);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.response, "GetCourseStatusReport.tsx", 1)
        } else if (error.request) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.request);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.request, "GetCourseStatusReport.tsx", 1)
        } else if (error.message) {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log(error.message);
          };    
          addError(token, userId, orgDomain, "Rest Exception", error.message, "GetCourseStatusReport.tsx", 1)
        }
        return null;
      }
}