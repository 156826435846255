//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { User, Language, UnitTreeItem, CourseTreeItem } from '../../globalTypes';
import { useHistory, useLocation } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";

//MUI
import { Button, Checkbox } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
//API Functions
import { getUnitTree } from '../../API/GetUnitTree';
import { getRelevantUnitTree } from '../../API/GetRelevantUnitTree';
import { getCourseListByOrganisation } from '../../API/GetCourseListByOrganisation'
import { getPastCourseListByOrganisation } from '../../API/GetPastCourseListByOrganisation'
import { getFullQuizHistoryReport } from '../../API/GetFullQuizHistoryReport'
import { addError } from '../../API/AddError'
import { getClassificationListByType } from '../../API/GetClassificationListByType';
import getClassificationType from '../../API/GetClassificationType';
//Components

import ResetIcon from "../../CustomIcons/ResetIcon";
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard'
import UnitFilterButtons from '../CurrentAdminReport/UnitFilterButtons'
import CourseFilterButtons from '../CurrentAdminReport/CourseFilterButtons'
import CourseListTree from '../CurrentAdminReport/CourseListTree'
import UnitTree from '../CurrentAdminReport/UnitTree'
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
import ReportSchedulerModal from '../CurrentAdminReport/ReportSchedulerModal'

import GuideIcon from '../../CustomIcons/GuideIcon';
import CustomTooltip from "../../Components/CustomTooltip";
//MUI Components
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

//Styles
import styles from '../../styles/currentAdminReport.module.css'

//ReportCommon
import Units from '../ReportCommonComponents/Units';
import Courses from '../ReportCommonComponents/Courses';

import SaveAPILibraryLogs from '../../API/Administration/Organisation/SaveAPILibraryLogs';

import Modal from '../../Components/Modal';
import ProceedIcon from '../../CustomIcons/ProceedIcon';
import CloseIcon from '../../CustomIcons/CloseIcon';
import ReportsParametersPreview from '../Administration/APILibrary/ReportParametersPreview';


const QuizHistoryReport = () => {
    const location = useLocation<any>();
    const [units, setUnits] = useState<Array<UnitTreeItem>>([])
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([])
    const [pastcourseList, setPastCourseList] = useState<Array<CourseTreeItem>>([]) 
    const [loading, setLoading] = useState<boolean>(true)
    const [isResetClicked, setisResetClicked] = useState<boolean>(false);

    const [filterFirstName, setFilterFirstName] = useState("");
    const [filterLastName, setFilterLastName] = useState("");
    const [filterUserName, setFilterUserName] = useState("");
    const [filterEmail, setFilterEmail] = useState("");
    const [filterUnitName, setFilterUnitName] = useState("");

    const [filterIncludeInactive, setFilterIncludeInactive] = useState<boolean>(false);
    const [isSmallUnitTree, setSmallUnitTree] = useState<boolean>(true);
    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute,menuSelection } = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website } } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();

    const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles } = useContext(TranslationsContext)
    const { showSSOExitButton, EnableActivityLogs ,IsAPILibraryEnabled} = useContext(UIContext);
    const { cpdList, currentCpd } = useContext(CPDPanelContext);
    const orgDomainWithAltLogin = window.location.host.toLowerCase();
    const [unitsLoaded, setUnitsLoaded] = useState<boolean>(false);
    const [courseListLoaded, setCourseListLoaded] = useState<boolean>(false);
    const [isHistoryCourse, setIsHistoryCourse] = useState<boolean>(false);
    const [isHistoryChecked, setIsHistoryChecked] = useState<boolean>(false);


    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);
    const [showAPIModal, setAPIModalOpen] = useState<boolean>(false);



    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: ".unitFilterButtons",
                title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,
            },
        ]
    })

    const setGuideMe = () => {
        let updateSteps = [...intro.steps]
        updateSteps.push({
            element: ".unitFilterSearch",
            title: "Filter Units", intro: allResourcesToTranslations.unitfiltersearch
        },
        {
            element:  (units.length <= 10) ? ".courseFilterButtonsnew" : ".courseFilterButtons",
            title: "Courses", intro: allResourcesToTranslations.emrepgmcsm
        },
        {
            element:  (units.length <= 10) ? ".courseFilterSearchnew" : ".courseFilterSearch",
            title: "Filter Courses", intro: allResourcesToTranslations.emrepgmfcsm
        },
        {
            element: ".firstName",
            title: "Filter", intro: allResourcesToTranslations.firstnamemsg
        },
        {
            element: ".lastName",
            title: "Filter", intro: allResourcesToTranslations.lastnamemsg
        },
        {
            element: ".userName",
            title: "Filter", intro: allResourcesToTranslations.usernamemsg
        },
        {
            element: ".email",
            title: "Filter", intro: allResourcesToTranslations.emailmsg
        },
        {
            element: ".includeInactive",
            title: "Filter", intro: allResourcesToTranslations.prrepgminactiveaccount
        },
        {
            element: ".runReport",
            title: "Run Report", intro: allResourcesToTranslations.sntemrepgmmrunrep
            }); 
                
            if (IsAPILibraryEnabled)
                {
                    updateSteps.push({
                        element:  ".apicall",
                        title: "API Call", intro: allResourcesToTranslations.guidemeapicall
                    });
                }

            updateSteps.push(
        {
            element: ".resetAll",
            title: "Reset Filters", intro: allResourcesToTranslations.emrepgmram
        },
        )
        setIntro({ ...intro, steps: [...updateSteps] })
    };

    const onExit = () => {
        setIntro({ ...intro, stepsEnabled: false });
    };


useEffect(() => {
        setGuideMe();
    },[IsAPILibraryEnabled])  


    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (givenDate === null || givenDate === undefined)
        {
            return "";
        }

        const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
        const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
        const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);

        return (year.toString() + "-" + 
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }

    useEffect(() => {
        setTitle(allResourcesToTranslations.quizhistoryreport);
        setTitleEnglish(pageTitles.quizhistoryreport)
        updateBackButtonRoute("");
        setLoading(true);

        setFilterIncludeInactive(true);
        // //Courses
        // getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
        //     if (courseListRes.isSuccess) {
        //         setCourseList(courseListRes.courselistItem);
        //         setCourseListLoaded(true);     
        //     }
        // });
        //Units      
        getRelevantUnitTree(oidcUser.access_token, userId, orgDomain, true, parseInt(orgId)).then((unitTreeRes) => {
            if (unitTreeRes.isSuccess) {
                //setUnits(unitTreeRes.unitTreeItems);
                if (!!location && !!location.state) {
                    setUnits(location.state.selectedUnits);
                    } else {
                    setUnits(unitTreeRes.unitTreeItems);
                    }
                //Courses
                getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
                    if (courseListRes.isSuccess) {
                        //setCourseList(courseListRes.courselistItem);                       

                            if (!!location && !!location.state) {
                                setCourseList(location.state.selectedCourses);          
                              } else {        
                                setCourseList(courseListRes.courselistItem);
                              }  
                        setCourseListLoaded(true);
                    }
                  
                    setLoading(false);
                    units.length = unitTreeRes.unitTreeItems.length;
                    setGuideMe();
                    getPastCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((pastcourseListRes) => {
                        if (pastcourseListRes.isSuccess) {
                            if (pastcourseListRes !== null && pastcourseListRes.pastCourseListItems !== null && pastcourseListRes.pastCourseListItems.length > 0) {
                                setIsHistoryCourse(true);                                
                                //setPastCourseList(pastcourseListRes.pastCourseListItems)
                                if (!!location && !!location.state) { 
                                    setPastCourseList(location.state.selectedPastCourses);          
                                } else {        
                                    setPastCourseList(pastcourseListRes.pastCourseListItems)
                                  }  
                            }
                        }
                    });
                });
            }
        });        
    }, [menuSelection]);

    const handlerunReport = () => {
        // setIsFetchingData(true);
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let pastcourseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let arrUnitNames: Array<string> = [];
        let arrCourseNames: Array<string> = [];

        let strUnitNames: string = "";
        let strCourseNames: string = "";

        const nextDate = new Date();
        nextDate.setDate(nextDate.getDate() + 1);

        // const filterDateFrom = (fromDate === null || fromDate === undefined || fromDate.length < 8) ? "1990-01-01" : fromDate;
        // const filterDateTo = (toDate === null || toDate === undefined || toDate.length < 8) ? formatDate(nextDate) : toDate;

        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                    if (filterUnitName !== "") {
                        if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                        unitIds.push(unit.unitID);
                        arrUnitNames.push(unit.name);
                        }
                    } else {
                        unitIds.push(unit.unitID);
                        arrUnitNames.push(unit.name);
                    }
                }
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
            for (let i = 0; i < courseList.length; i++) {
                if (courseList[i].isSelected) {
                    courseIds.push(courseList[i].courseID);
                    arrCourseNames.push(courseList[i].name);
                }
                allCourseIds.push(courseList[i].courseID);
            }
        }
        getSelectedCourses();

        const getSelectedPastCourses = () => {           
            for (let i = 0; i < pastcourseList.length; i++) {
                if (pastcourseList[i].isSelected) {   
                    pastcourseIds.push(pastcourseList[i].courseID);  
                }               
            }     
        }       
        getSelectedPastCourses()


        
        strUnitNames = (units.length === arrUnitNames.length) || arrUnitNames.length === 0 ? "ALL UNITS" : arrUnitNames.join(",");
        strCourseNames = (courseList.length === arrCourseNames.length) || arrCourseNames.length === 0 ? "ALL COURSES" : arrCourseNames.join(",");
      
        setMessage("Fetching your report");
        setLoading(true)
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Quiz History Report in NEWSALT ClientApp", null, "QuizHistoryReport.tsx", 6, EnableActivityLogs);
        getFullQuizHistoryReport(oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId),
            unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(),
           // courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString(),

           (courseIds.length > 0 && pastcourseIds.length > 0) 
           ? (courseIds.toString() +","+ pastcourseIds.toString()) :
            ((isHistoryChecked && pastcourseIds.length > 0) ? pastcourseIds.toString() :  (courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString())),

            filterIncludeInactive,
            filterFirstName, filterLastName, filterUserName, filterEmail
        ).then((result: any) => {
            setLoading(false)
            history.push({
                pathname: `quizhistoryreport/result`,
                state: {
                    inputFilterFirstName: filterFirstName,
                    inputFilterLastName: filterLastName,
                    inputFilterUserName: filterUserName,
                    inputFilterEmail: filterEmail,                 
                    inputHistoricData: (isHistoryChecked && pastcourseIds.length > 0) ? true : false,
                    inputFilterIncludeInactive: (filterIncludeInactive),
                    resultItems: (result === null ? null : result.quizHistoryReportItems),
                    resultError: ((result === null || result === undefined || result.isSuccess || result.error === null || result.error === undefined) ? "" : result.error)
                },
            });
        })

    };
    const resetAll = () => {
        setFilterIncludeInactive(false);
        setisResetClicked(!isResetClicked);        
        setFilterFirstName('');
        setFilterLastName('');
        setFilterEmail('');
        setFilterUserName('');
    };

    //#region API Library
    const GenerateAPIRequestBody = () => {
        setAPIModalOpen(true);
        setLoading(false)
    }
    const handleAPIModal = (value:boolean) => {
        setAPIModalOpen(value);
    }
    const runAPIQuery = () => {
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let pastcourseIds: Array<number> = [];
   

        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate()+1);
        const nextDateString = formatDate(nextDate);


        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    if (filterUnitName !== "") {
                        if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                            unitIds.push(unit.unitID)
                        }
                    } else {
                        unitIds.push(unit.unitID)
                    }
                }
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
            for (let i = 0; i < courseList.length; i++) {
                if (courseList[i].isSelected) {
                    courseIds.push(courseList[i].courseID);
                }
                allCourseIds.push(courseList[i].courseID);
            }
        }
        getSelectedCourses()

        const getSelectedPastCourses = () => {
            for (let i = 0; i < pastcourseList.length; i++) {
                if (pastcourseList[i].isSelected) {
                    pastcourseIds.push(pastcourseList[i].courseID);
                }
            }
        }
        getSelectedPastCourses()

let str=`{"ReportRequestingUserID":${userId},
"OrganisationID":${parseInt(orgId)},
"UnitIDs":"${unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString()}",
"CourseIDs":"${   (courseIds.length > 0 && pastcourseIds.length > 0)
    ? (courseIds.toString() + "," + pastcourseIds.toString()) :
    ((isHistoryChecked && pastcourseIds.length > 0) ? pastcourseIds.toString() : (courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString()))}",     
"HistoricCourseIDs":"${((isHistoryChecked && pastcourseIds.length > 0) ? pastcourseIds.toString(): "")}",  
"UserFirstName":"${filterFirstName}",
"UserLastName":"${filterLastName}",
"UserName":"${filterUserName}",
"UserEmail":"${filterEmail}",
"IncludeInactive":${filterIncludeInactive},
"IncludeHistoricCourse":${isHistoryChecked}
}`;

        SaveAPILibraryLogs(oidcUser.access_token, userId, orgDomain, parseInt(orgId), "QuizHistoryReport", str).then((res) => {
            if (res.isSuccess) {
                history.push({
                    pathname: `apilibrarydetail/QuizHistoryReport`,
                    state: {
                        inputLogId: res.logsId
                    },
                });

            }
            else {
                setModalPopupMessage("API Library Log Save Failed!");
                setHasErrored(false);
                setShowWarningModal(true);
            }
        });
        setLoading(false)
    }

    useEffect(() => {
        if (!!location && !!location.state) {
            setFilterFirstName(location.state.inputFilterFirstName);
            setFilterLastName(location.state.inputFilterLastName);
            setFilterUserName(location.state.inputFilterUserName);
            setFilterEmail(location.state.inputFilterEmail);
            setFilterIncludeInactive(location.state.inputFilterIncludeInactive);
            setIsHistoryChecked(location.state.inputFilterIncludeHistoricCourse);
            setIsHistoryCourse(location.state.inputFilterIncludeHistoricCourse);
            
        }
    }, [location]);

    //#endregion

    if (loading) return <LoaderContainer />;
    return (
        <>
            <Modal type="warning" modalOpen={showAPIModal} setModalOpen={handleAPIModal} style={{ height: '232px', width: '381px' }}
                hideCloseBtn={true}>
                <ReportsParametersPreview
                    units={units}
                    courseList={courseList}
                    pastcourseList={pastcourseList}
                    filterFirstName={filterFirstName}
                    filterLastName={filterLastName}
                    filterUserName={filterUserName}
                    filterEmail={filterEmail}
                    filterIncludeInactive={filterIncludeInactive}
                    filterClassificationID={0}
                    reportId={0}
                    report={'qhr'}
                ></ReportsParametersPreview>

                <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '10px' }}>
                    <CustomTooltip displayText={["Return"]}>
                        <span className={styles.returnIcon} >
                            <span
                                onClick={() => {
                                    setAPIModalOpen(false)
                                }}>
                                <CloseIcon />
                            </span>
                        </span>
                    </CustomTooltip>
                    <CustomTooltip displayText={["Proceed"]}>
                        <span className={styles.proceedIcon}>
                            <span
                                onClick={() => {
                                    runAPIQuery();
                                }}>
                                <ProceedIcon />
                            </span>
                        </span>
                    </CustomTooltip>
                </div>
            </Modal>
            <Steps
                enabled={intro.stepsEnabled}
                steps={intro.steps}
                initialStep={intro.initialStep}
                onExit={onExit}
                options={{
                    nextLabel: 'Next',
                    prevLabel: 'Back',
                    tooltipClass: 'sample-class',
                    showBullets: true,
                }}
            />

            <CustomTooltip displayText={["Guide Me"]}>
                <span onClick={() => setIntro({ ...intro, stepsEnabled: true })} className={styles.guideMeButtonSpecific}>
                    <GuideIcon />
                </span>
            </CustomTooltip>

            <BrandedCard>
            <div className={styles.reportWrraper}>
                    <div className={styles.unittreesection}>
                        <div>
                            <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName}/>
                        </div>
                        <div hidden={(units === null || units.length <= 10) === false} className={styles.paddingTop}>
                            <Courses isResetClicked={isResetClicked} givenCourses={courseList} hasSmallUnits={true} />
                        </div>
                    </div>
                    <div className={styles.coursetreesection}>
                        <div hidden={(units === null || units.length <= 10) === true}>
                            <Courses isResetClicked={isResetClicked} givenCourses={courseList} hasSmallUnits={false} />
                        </div>                    
                        {isHistoryCourse ? (
                            <div>
                                <div>&nbsp;</div>  
                                <div className={`${styles.inactiveContainer} includeHistoricdata`} >
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={isHistoryChecked}
                                        className={styles.checkBox}
                                        onClick={() => setIsHistoryChecked(!isHistoryChecked)}
                                    /> {"Include Historic Course"}
                                </div>                               
                            </div>                            
                        ) : ('')}

                        {isHistoryChecked ? (                           
                            <div>
                                    <Courses isResetClicked={isResetClicked} givenCourses={pastcourseList} hasSmallUnits={false} CourseTitle={"Past Course"} />
                            </div>  
                        ) : ('')}
                        <div className={styles.inputContainer}>
                            <div className="firstName"><TextField className={styles.input} label={"First Name"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                value={filterFirstName} onChange={(e) => setFilterFirstName(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="lastName"><TextField className={styles.input} label={"Last Name"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }} value={filterLastName} onChange={(e) => setFilterLastName(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="userName"> <TextField className={styles.input} label={"Username"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }} value={filterUserName} onChange={(e) => setFilterUserName(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer} style={{paddingBottom:'20px'}}>
                            <div className="email"> <TextField className={styles.input} label={"Email"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }} value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} /></div>
                        </div>
                        <div className={`${styles.inactiveContainer} includeInactive`}>
                            <div>
                                <Checkbox                                
                                    size="small"
                                    color="primary"
                                    checked={filterIncludeInactive}
                                    className={styles.checkBox}
                                    onClick={() => setFilterIncludeInactive(!filterIncludeInactive)}
                                /> {"Include Inactive Accounts"}
                            </div>
                        </div> 
                        <div>
                            <ButtonPanel openReportSchedulerModal={() => false} showScheduler={false} runReport={handlerunReport} resetAll={resetAll} showAPIbutton={IsAPILibraryEnabled ? true:false}  runAPIQuery={GenerateAPIRequestBody}/>
                        </div>
                        {/* <div style={{ display: 'none', justifyContent: 'flex-end', marginTop: '10px' }} >
                            <div className="runReport">
                                <CustomTooltip displayText={["Run Report"]}>
                                    <Button
                                        onClick={handlerunReport}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className='runReport'
                                        id="btnNewSaltScheduleReport"
                                    >
                                        <AssessmentIcon />
                                    </Button>
                                </CustomTooltip>
                            </div>
                            <div className="resetAll">
                                <CustomTooltip displayText={["Reset"]}>
                                    <span className={styles.emailResetIcon} onClick={resetAll}>
                                        <ResetIcon />
                                    </span>
                                </CustomTooltip>
                            </div>
                        </div>    */}
                    </div>
                </div>
            </BrandedCard>
        </>
    );
};

export default QuizHistoryReport
