import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const APILibraryCSRIcon = () => (
<svg width="83" height="83" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.6666 72.625H55.3333" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.5 58.7917V72.625" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M69.1666 10.375H13.8333C10.0133 10.375 6.91663 13.4717 6.91663 17.2917V51.875C6.91663 55.6951 10.0133 58.7917 13.8333 58.7917H69.1666C72.9867 58.7917 76.0833 55.6951 76.0833 51.875V17.2917C76.0833 13.4717 72.9867 10.375 69.1666 10.375Z" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export default APILibraryCSRIcon
