//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from '../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip'
//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
import { getCourseListByCourseIds } from '../../API/GetCourseListByCourseIds';
import { get2DigitDayOrMonth } from '../../functions/get2DigitDayOrMonth'
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData' 
import { addError } from '../../API/AddError'
//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon';
import CSVFileIcon from '../../CustomIcons/CSVFileIcon';
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import BrushIcon from '@material-ui/icons/Brush';
// Style
import styles from '../../styles/currentAdminReport.module.css'


//Chart
import PieChart from "../../Components/HighChart/PieChartComponent";
//import ColumnChart from "../../Components/HighChart/ColumnChartComponent";
//import ComboColumnPieChart from "../../Components/HighChart/ComboColumnPieComponant";
import DualaxeslineStakedColumnChart from "../../Components/HighChart/SummaryReport_StakedColumn_ChartComponent";
import { Select } from '@material-ui/core';

const CurrentAdminReportResult = (props: any) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, family_name, given_name, website  }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const { theme: { color }} = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    //const { curSummaryReportResult, setCurSummaryReportResult } = useContext(SessionContext);
    //const [adminReportResultActual, setAdminReportResultActual] = useState<Array<any>>(props?.location?.state?.resultItemsActual ?? [])
    const [adminReportResult, setAdminReportResult] = useState<Array<any>>(props?.location?.state?.resultItems ?? [])
    const [originaladminReportResult, setOriginalAdminReportResult] = useState<Array<any>>(props?.location?.state?.resultItems ?? [])
    const [selectedCourseName, setSelectedCourseName] = useState<string>(props?.location?.state?.resultSelectedCourse ?? "")
    const [summaryReportUniqueCourseListItems, setSummaryReportUniqueCourseListItems] = useState<Array<any>>(props?.location?.state?.resultSummaryReportUniqueCourseListItems ?? [])
    const [originalsummaryReportUniqueCourseListItems, setOriginalSummaryReportUniqueCourseListItems] = useState<Array<any>>(props?.location?.state?.resultSummaryReportUniqueCourseListItems ?? [])
    const [exporting, setExporting] = useState<boolean>(false)
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [unitDescending, setUnitDescending] = useState<boolean>(true)
    const [courseNameDescending, setCourseNameDescending] = useState<boolean>(true)
    const [countDescending, setCountDescending] = useState<boolean>(true)
    const [incompleteDescending, setIncompleteDescending] = useState<boolean>(true)
    const [completeDescending, setCompleteDescending] = useState<boolean>(true)
    const [incompletePercentageDescending, setIncompletePercentageDescending] = useState<boolean>(true)
    const [completePercentageDescending, setCompletePercentageDescending] = useState<boolean>(true)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    // const [completionReasonDesceding, setCompletionReasonDesceding] = useState<boolean>(true)
    const [courseList, setCourseList] = useState([]);
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const [resultError, setResultError] =  useState<string>(props?.location?.state?.resultError ?? "")
    const [exportError, setExportError] = useState<string>("")
    //const toggleBanding = () => setEnableRowBanding(!enableRowBanding)

    const [selectedCategory, setSelectedCategory] = useState<any>({value: ""});
    const [categoriesArray, setCategoriesArray] = useState<any>([]) 
    const [arrTotalIncomplete, setArrTotalIncomplete] = useState<any>([])
    const [arrTotalcomplete, setArrTotalcomplete] = useState<any>([])
    const [arrTotalIncompletePercentage, setArrTotalIncompletePercentage] = useState<any>([])
    const [arrTotalcompletePercentage, setArrTotalcompletePercentage] = useState<any>([])
    const [seriesPieChart, setSeriesDataPieChart] = useState<any>([])


    const [coursefilterincomplete, setCoursefilterincomplete] =  useState<number>(0)
    const [coursefiltercomplete, setCoursefiltercomplete] =  useState<number>(0)
    const [coursefilterincompletePercentage, setCoursefilterincompletePercentage] =  useState<string>("")
    const [coursefiltercompletePercentage, setCoursefiltercompletePercentage] =    useState<string>("")
    

    
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".summaryReportList",
            title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta       
          },      
        ]
      })

      useEffect(() => 
      {
                    let updateSteps = [...intro.steps]
                    updateSteps.push( 
                    {
                    element: ".xlxs",
                    title: allResourcesToTranslations.sntemrepgmtexpxls, intro:allResourcesToTranslations.sntemrepgmmexpxls
                    },
                    {
                    element: ".csv",
                    title: allResourcesToTranslations.sntemrepgmtexpcsv, intro:allResourcesToTranslations.sntemrepgmmexpcsv
                    },
                    {
                        element: ".pdf",
                        title: allResourcesToTranslations.exporttopdf, intro:allResourcesToTranslations.sntemrepgmmexppdf
                    }
                    // {
                    //     element: ".rowShading",
                    //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
                    // }        
                )      
      
                setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };
    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : ""}
        } else {
            return {}
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    
    const GetDate_yymmdd = () => {
        var d =  new Date(Date.now());
        let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
        return day;   
    }
    
    const buildRequestStringArray = () => {
        let resultArray: Array<string> = []
       
        if(selectedCategory.value ===""){
            for (let i = 0; i < adminReportResult.length; i++) {
                const r1 = adminReportResult[i];

                for (let j = 0; j < r1.childrenOfUnitSummry.length; j++) {
                    const r = r1.childrenOfUnitSummry[j];
                
                        if(j === r1.childrenOfUnitSummry.length-1 && r.hasOwnProperty("unitPathway2") ){

                            resultArray.push(`${ i===adminReportResult.length-1 ? r.unitPathway : r.unitPathway2}<#>${r.courseName2}<#>${r.totalUsers2}<#>${r.incomplete2.toString()}<#>${GetRoundedIncompletePercentage(r.incompletePercentage2, r.completePercentage2).toString()+'%'}<#>${r.complete2.toString()}<#>${GetRoundedCompletePercentage(r.incompletePercentage2, r.completePercentage2).toString()+'%'}`)
                        }
                        else{
                            resultArray.push(`${r.unitPathway}<#>${r.courseName}<#>${r.totalUsers}<#>${r.incomplete.toString()}<#>${GetRoundedIncompletePercentage(r.incompletePercentage, r.completePercentage).toString()+'%'}<#>${r.complete.toString()}<#>${GetRoundedCompletePercentage(r.incompletePercentage, r.completePercentage).toString()+'%'}`)
                        }               
                }
            }
        }
        else{

                for (let i = 0; i < adminReportResult.length-1; i++) {
                    const r1 = adminReportResult[i];

                    for (let j = 0; j < r1.childrenOfUnitSummry.length; j++) {
                        const r = r1.childrenOfUnitSummry[j];

                        resultArray.push(`${r.unitPathway}<#>${r.courseName}<#>${r.totalUsers}<#>${r.incomplete.toString()}<#>${GetRoundedIncompletePercentage(r.incompletePercentage, r.completePercentage).toString() + '%'}<#>${r.complete.toString()}<#>${GetRoundedCompletePercentage(r.incompletePercentage, r.completePercentage).toString() + '%'}`)
                       
                    }
                }
                resultArray.push(`${'Grand Total'}<#>${''}<#>${(coursefilterincomplete + coursefiltercomplete).toString()}<#>${coursefilterincomplete.toString()}<#>${coursefilterincompletePercentage.toString()}<#>${coursefiltercomplete.toString()}<#>${coursefiltercompletePercentage.toString()}`)
        }
      
        return resultArray
    }

    const handleCourseSelection =(CourseId:number) =>
    {
        const r = adminReportResult[0]
    }
    const handleXLSXExport = () => {
        setMessage("Exporting XLSX");
        setExportError("");

        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Summary Report XLSX-EXPORT in NEWSALT ClientApp", null, "SummaryReportResult.tsx", 6, EnableActivityLogs);

        setExporting(true)
        const r = adminReportResult[0]
        let ClassificationValue  = r.classificationValue ;
        let DateEffectiveFrom  = r.dateEffectiveFrom ;    
        let DateEffectiveTo  = r.dateEffectiveTo;  
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(),  7, "summaryreport", enableRowBanding,ClassificationValue,"",DateEffectiveFrom, DateEffectiveTo,false,false, selectedCategory.value ==="" ?  selectedCourseName: selectedCategory.value).then((xlxsRes) => {            
            if (xlxsRes.isSuccess) {
                const fileData = xlxsRes.xlsxDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "SummaryReport-"+ GetDate_yymmdd() + ".xlsx";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
                setExportError(xlxsRes.error);
            }
            setExporting(false)

            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Summary Report XLSX-EXPORT in NEWSALT ClientApp", null, "SummaryReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExport = () => {
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Summary Report CSV-EXPORT in NEWSALT ClientApp", null, "SummaryReportResult.tsx", 6, EnableActivityLogs);

        setExporting(true)
        const r = adminReportResult[0]
        let ClassificationValue  = r.classificationValue ;
        let DateEffectiveFrom  = r.dateEffectiveFrom ;
        let DateEffectiveTo  = r.dateEffectiveTo;
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 7, "summaryreport",ClassificationValue,"",DateEffectiveFrom,DateEffectiveTo,false,false, selectedCategory.value ==="" ?  selectedCourseName: selectedCategory.value).then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "SummaryReport-"+ GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)
            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)

            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Summary Report CSV-EXPORT in NEWSALT ClientApp", null, "SummaryReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handlePDFXExport = () => {
        setMessage("Exporting PDF");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Summary Report PDF-EXPORT in NEWSALT ClientApp", null, "SummaryReportResult.tsx", 6, EnableActivityLogs);

        setExporting(true)
        const r = adminReportResult[0]
        let ClassificationValue  = r.classificationValue ;
        let DateEffectiveFrom  = r.dateEffectiveFrom ;
        let DateEffectiveTo  = r.dateEffectiveTo;
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 7, "summaryreport", enableRowBanding,ClassificationValue,"",DateEffectiveFrom,DateEffectiveTo,false,false, selectedCategory.value ==="" ?  selectedCourseName: selectedCategory.value).then((pdfRes) => {            
             if (pdfRes.isSuccess) {
                const fileData = pdfRes.pdfDataOutput.fileData
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "SummaryReport-"+ GetDate_yymmdd() + ".PDF";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

             }
             else if (pdfRes.error !== undefined && pdfRes.error !== null) {
                 setExportError(pdfRes.error);
             }
            setExporting(false)

            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Summary Report PDF-EXPORT in NEWSALT ClientApp", null, "SummaryReportResult.tsx", 6, EnableActivityLogs);
        })
    }
   
    const sortResultsByAsync = async (sortBy: "Unit" |"CourseName"  | "Count" | "Incomplete" | "Complete" | "IncompletePercentage" | "CompletePercentage", sortGivenItemInDesc: boolean ) => {
        var result = adminReportResult.slice(0);
        setCurrentSortBy(sortBy)
        switch(sortBy) {
            case "Unit":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (('' + a.unitPathway).localeCompare(b.unitPathway) < 0) { return 1;};
                        if (('' + a.unitPathway).localeCompare(b.unitPathway) > 0) { return -1;};
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUnitDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        }
                        if (('' + a.unitPathway).localeCompare(b.unitPathway) < 0) { return -1;};
                        if (('' + a.unitPathway).localeCompare(b.unitPathway) > 0) { return 1;};
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUnitDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CourseName":
                    if (sortGivenItemInDesc) {
                        result.sort((a, b) => {
                            if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                                return 0;
                            } 
                            if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                                return 0;
                            } 
    
                        if (b === null || b.courseName === null) { return -1; }
                        if (a === null || a.courseName === null) { return 1; }
                        if (a.courseName > b.courseName) { return -1; }
                        if (a.courseName < b.courseName) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        
                        if (b === null || b.courseName === null) { return 1; }
                        if (a === null || a.courseName === null) { return -1; }
                        if (a.courseName > b.courseName) { return 1; }
                        if (a.courseName < b.courseName) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;

            case "Count":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {         
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("totalUsers2") ? a.totalUsers : a.totalUsers2;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("totalUsers2") ? b.totalUsers : b.totalUsers2;
                        } 

                        if (aValToCompare > bValToCompare) { return -1; }
                        if (aValToCompare < bValToCompare) { return 1; }
                        return 0;
                   
                    })
                    if (reverseNextSort) {
                        setCountDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("totalUsers2") ? a.totalUsers : a.totalUsers2;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("totalUsers2") ? b.totalUsers : b.totalUsers2;
                        } 

                        if (aValToCompare > bValToCompare) { return 1; }
                        if (aValToCompare < bValToCompare) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCountDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;                
            case "Incomplete":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 

                        if (b === null || b.incomplete === null) { return -1; }
                        if (a === null || a.incomplete === null) { return 1; }

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("incomplete2") ? a.incomplete : a.incomplete2;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("incomplete2") ? b.incomplete : b.incomplete2;
                        } 

                        if (aValToCompare > bValToCompare) { return -1; }
                        if (aValToCompare < bValToCompare) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setIncompleteDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        
                        if (b === null || b.incomplete === null) { return 1; }
                        if (a === null || a.incomplete === null) { return -1; }

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("incomplete2") ? a.incomplete : a.incomplete2;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("incomplete2") ? b.incomplete : b.incomplete2;
                        } 

                        if (aValToCompare > bValToCompare) { return 1; }
                        if (aValToCompare < bValToCompare) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setIncompleteDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Complete":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        
                        if (b === null || b.complete === null) { return -1; }
                        if (a === null || a.complete === null) { return 1; }

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("complete2") ? a.complete : a.complete2;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("complete2") ? b.complete : b.complete2;
                        } 

                        if (aValToCompare > bValToCompare) { return -1; }
                        if (aValToCompare < bValToCompare) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompleteDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        
                        if (b === null || b.complete === null) { return 1; }
                        if (a === null || a.complete === null) { return -1; }

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("complete2") ? a.complete : a.complete2;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("complete2") ? b.complete : b.complete2;
                        } 

                        if (aValToCompare > bValToCompare) { return 1; }
                        if (aValToCompare < bValToCompare) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompleteDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "IncompletePercentage":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("incompletePercentage2") ? parseFloat(a.incompletePercentage) * 100 : parseFloat(a.incompletePercentage2) * 100;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("incompletePercentage2") ? parseFloat(b.incompletePercentage) * 100 : parseFloat(b.incompletePercentage2) * 100;
                        } 

                        return bValToCompare - aValToCompare;
                    })
                    if (reverseNextSort) {
                        setIncompletePercentageDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("incompletePercentage2") ? parseFloat(a.incompletePercentage) * 100 : parseFloat(a.incompletePercentage2) * 100;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("incompletePercentage2") ? parseFloat(b.incompletePercentage) * 100 : parseFloat(b.incompletePercentage2) * 100;
                        } 

                        return aValToCompare - bValToCompare;
                    })
                    if (reverseNextSort) {
                        setIncompletePercentageDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CompletePercentage":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        
                        if (b === null || b.completePercentage === null) { return -1; }
                        if (a === null || a.completePercentage === null) { return 1; }

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("completePercentage2") ? parseFloat(a.completePercentage) * 100 : parseFloat(a.completePercentage2) * 100;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("completePercentage2") ? parseFloat(b.completePercentage) * 100 : parseFloat(b.completePercentage2) * 100;
                        } 

                        return bValToCompare - aValToCompare;
                    })
                    if (reverseNextSort) {
                        setCompletePercentageDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total") {
                            return 0;
                        } 
                        
                        if (b === null || b.completePercentage === null) { return 1; }
                        if (a === null || a.completePercentage === null) { return -1; }

                        let aValToCompare : any;
                        let bValToCompare : any;
                        if (a !== null) {
                            aValToCompare = !a.hasOwnProperty("completePercentage2") ? parseFloat(a.completePercentage) * 100 : parseFloat(a.completePercentage2) * 100;
                        } 
                        if (b !== null) {
                            bValToCompare = !b.hasOwnProperty("completePercentage2") ? parseFloat(b.completePercentage) * 100 : parseFloat(b.completePercentage2) * 100;
                        } 

                        return aValToCompare - bValToCompare;
                    })
                    if (reverseNextSort) {
                        setCompletePercentageDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;            
            default:
                break;
        }
        return result.slice(0);
    }
   
    const sortResultsBy = async (sortBy: "Unit" | "CourseName" | "Count" | "Incomplete" | "Complete" | "IncompletePercentage" | "CompletePercentage", sortGivenItemInDesc: boolean ) => {
      
        setSortInProgress(true);
        setExportError("");
        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
           
            setCurrentSortBy(sortBy);
            setAdminReportResult(result);
            setPage(0);
            setSortInProgress(false)
        });
    }

    const initialSort = () => {
        setUnitDescending(false)
        setCurrentSortBy("Unit")
        getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
            if (res.isSuccess) {
              let curdt = res.currentDateTimeText;
              setReportRunTimeTaken(curdt);
            } else {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
            }
           }).catch ((error) => {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
           });
        setPage(0)
    }

    useEffect(() => {
        initialSort();
            
        if (adminReportResult !== null && adminReportResult.length > 0) {
            const r = adminReportResult[0]
            let CourseIdList = r.courseIds ;
            // getCourseListByCourseIds(oidcUser.access_token, userId, orgDomain, parseInt(orgId), CourseIdList).then((data) => {
            //     setCourseList(data === null ? null : data.courselistItem)
            // })
            GetSummaryReportChart("");  
        }        
    },[])

    const clickUnitAsc = () => {
        setReverseNextSort(false);
        setUnitDescending(false)
        sortResultsBy("Unit", false)
    }

    const clickUnitDesc = () => {
        setReverseNextSort(false);
        setUnitDescending(true)
        sortResultsBy("Unit", true)
    }


    const clickCourseNameAsc = () => {
        setReverseNextSort(false);
        setCourseNameDescending(false)        
        sortResultsBy("CourseName", false)        
    }

    const clickCourseNameDesc = () => {
        setReverseNextSort(false);
        setCourseNameDescending(true)
        sortResultsBy("CourseName", true)
    }


    const clickCountAsc = () => {
        setReverseNextSort(false);
        setCountDescending(false);        
        sortResultsBy("Count", false);
    }

    const clickCountDesc = () => {
        setReverseNextSort(false);
        setCountDescending(true)
        sortResultsBy("Count", true)
    }

    const clickIncompleteAsc = () => {
        setReverseNextSort(false);
        setIncompleteDescending(false);        
        sortResultsBy("Incomplete", false);
    }

    const clickIncompleteDesc = () => {
        setReverseNextSort(false);
        setIncompleteDescending(true)
        sortResultsBy("Incomplete", true)
    }

    const clickIncompletePercentageAsc = () => {
        setReverseNextSort(false);
        setIncompletePercentageDescending(false);
        sortResultsBy("IncompletePercentage", false);
    }

    const clickIncompletePercentageDesc = () => {
        setReverseNextSort(false);
        setIncompletePercentageDescending(true)
        sortResultsBy("IncompletePercentage", true)
    }

    const clickCompleteAsc = () => {
        setReverseNextSort(false);
        setCompleteDescending(false);        
        sortResultsBy("Complete", false);
    }

    const clickCompleteDesc = () => {
        setReverseNextSort(false);
        setCompleteDescending(true)
        sortResultsBy("Complete", true)
    }

    const clickCompletePercentageAsc = () => {
        setReverseNextSort(false);
        setCompletePercentageDescending(false);        
        sortResultsBy("CompletePercentage", false);
    }

    const clickCompletePercentageDesc = () => {
        setReverseNextSort(false);
        setCompletePercentageDescending(true)
        sortResultsBy("CompletePercentage", true)
    }

    const GetClassificationname = () => {        
        const r = adminReportResult[0]
        let classificationValue  = r.classificationValue != null ? r.classificationValue : "";
 
        return classificationValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Grouping Option" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {r.classificationValue}</span></div> : ""
          
        
     }

     const GetSelectedCourseNameSummary = () => { 
         
        let arr = selectedCourseName.split(',');   
        return (selectedCourseName != "" && arr.length > 5) ?<div className={styles.reportDetailContainer}>
                              <span className={styles.reportDetailTitle}>{"Selected Course" /* TODO Tranlation */}</span>    
                                {selectedCourseName.split(',',5).map((step:any)=> <span className={styles.reportDetail}>{step}</span>)}
                                <span className={styles.reportDetail}>{ arr.length> 6 ? "+" + (arr.length - 6) + " more..." : ""}</span>
                              </div> : 
                              
                              <div className={styles.reportDetailContainer}>
                              <span className={styles.reportDetailTitle}>{"Selected Course" /* TODO Tranlation */}</span>    
                                {selectedCourseName.split(',').map((step:any)=> <span className={styles.reportDetail}>{step}</span>)}
                              </div>
      }

     const GetDateFrom = () => {        
        const r = adminReportResult[0]
        let DateEffectiveFrom  = r.dateEffectiveFrom != null ? r.dateEffectiveFrom : "";
        if (DateEffectiveFrom !== "" && DateEffectiveFrom !== undefined && DateEffectiveFrom !== null) {
            const tokens = DateEffectiveFrom.split("-");
            if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                DateEffectiveFrom = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
            }
        }
       
        return DateEffectiveFrom != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Date From" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {DateEffectiveFrom}</span></div> : ""          
        
    }

    const GetDateTo = () => {        
        const r = adminReportResult[0]
        let DateEffectiveTo  = r.dateEffectiveTo != null ? r.dateEffectiveTo : "";
        if (DateEffectiveTo !== "" && DateEffectiveTo !== undefined && DateEffectiveTo !== null) {
            const tokens = DateEffectiveTo.split("-");
            if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                DateEffectiveTo = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
            }
        }
       
        return DateEffectiveTo != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Date To" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {DateEffectiveTo}</span></div> : ""          
        
    }

    const GetRoundedIncompletePercentage = (incomplPercentage: number, complPercentage: number) => {
        const incomplPercentageStr = incomplPercentage.toString();
        const incomplPercentageRnd = Math.round(incomplPercentage);
        const complPercentageRnd = Math.round(complPercentage);
        return (
            incomplPercentageRnd === 100 ? 
            parseInt(incomplPercentageStr) : 
            (
                (incomplPercentage !== 0 && incomplPercentage <= 1) ?
                1 :
                (
                (incomplPercentageRnd + complPercentageRnd) > 100 ? 
                parseInt(incomplPercentageStr) : 
                incomplPercentageRnd
                )
            )
        );
    }

    const GetRoundedCompletePercentage = (incomplPercentage: number, complPercentage: number) => {
        const complPercentageStr = complPercentage.toString();
        const incomplPercentageRnd = Math.round(incomplPercentage);
        const complPercentageRnd = Math.round(complPercentage);
        return (
            complPercentageRnd === 100 ? 
            parseInt(complPercentageStr) : 
            (
                (complPercentage !== 0 && complPercentage <= 1) ?
                1 :
                complPercentageRnd
            )
        );
    }


    if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0)
    {
        setTitle(allResourcesToTranslations.summaryreport)
        setTitleEnglish(pageTitles.summaryreport)
        updateBackButtonRoute("/summaryreport");
        //setExportError("");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div style={{color:"red"}}>Error: {resultError}</div>
                <br/>
                <div>Select the Back Button to return to Previous Page</div>
            </div>
        </BrandedCard>)
    }
    else if (adminReportResult === null || adminReportResult.length < 1) {
        setTitle(allResourcesToTranslations.summaryreport)
        setTitleEnglish(pageTitles.summaryreport)
        //setExportError("");
        updateBackButtonRoute("/summaryreport");
            return (<BrandedCard>
            <div className={styles.noResultsCard}>
            <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
                <br/>
                <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>
                </div>
                </BrandedCard>)
    } else {
        setTitle(allResourcesToTranslations.summaryreport)
        setTitleEnglish(pageTitles.summaryreport)
        updateBackButtonRoute("/summaryreport");
    }
 
//#region HIghChart 

const GetSummaryReportChart = (givenSelectedcategory: any) => {
    let categories: Array<string> = [];
    let incompleteUsers: Array<number> = [];
    let completeUsers: Array<number> = [];
    let incompleteUsersPercentage: Array<number> = [];
    let completeUsersPercentage: Array<number> = [];
    let seriesDataPieChart: Array<any> = [];      
    let incompleteCount: number;
    let completeCount: number;
    incompleteCount = 0;
    completeCount = 0;
    if (!!summaryReportUniqueCourseListItems) {      
        let filterdSummaryReportItem = originalsummaryReportUniqueCourseListItems ;  
        let filterdAdminSummaryReportItem = originaladminReportResult  
        setPage(0);     
        if(givenSelectedcategory != ""){           
            filterdSummaryReportItem =  originalsummaryReportUniqueCourseListItems.filter((item:any) => item.courseName.toString() === givenSelectedcategory.toString());       
            filterdAdminSummaryReportItem = originaladminReportResult
            .map(item => ({
              ...item,
              childrenOfUnitSummry: item.childrenOfUnitSummry
                .filter((item:any) => item.courseName.toString() === givenSelectedcategory.toString() && item.unitPathway!="Grand Total")
            }))
            .filter(item => item.childrenOfUnitSummry.length > 0)
            setSummaryReportUniqueCourseListItems(filterdSummaryReportItem)            
            filterdAdminSummaryReportItem.push(filterdAdminSummaryReportItem[0])         
            setAdminReportResult(filterdAdminSummaryReportItem)
        }    
        else{
            filterdSummaryReportItem = originalsummaryReportUniqueCourseListItems;    
            filterdAdminSummaryReportItem = originaladminReportResult;           
            setSummaryReportUniqueCourseListItems(originalsummaryReportUniqueCourseListItems)
            setAdminReportResult(originaladminReportResult)
        }
        for (let i = 0; i < filterdSummaryReportItem.length; i++) {
                const r1 = filterdSummaryReportItem[i];               
                categories.push(`${r1.courseName}`);      
                incompleteUsers.push(parseInt(r1.incomplete.toString()))
                completeUsers.push(parseInt(r1.complete.toString()))                   
                incompleteUsersPercentage.push(parseInt(r1.incompletePercentage.toString()))
                completeUsersPercentage.push(parseInt(r1.completePercentage.toString()))  

                incompleteCount += r1.incomplete;
                completeCount += r1.complete;

        }  
        setCategoriesArray(categories);         
        setArrTotalIncomplete(incompleteUsers);
        setArrTotalcomplete(completeUsers);
        setArrTotalIncompletePercentage(incompleteUsersPercentage);
        setArrTotalcompletePercentage(completeUsersPercentage);

        
        setCoursefilterincomplete(incompleteCount)
        setCoursefiltercomplete(completeCount)
        setCoursefilterincompletePercentage(Math.round(((incompleteCount / (incompleteCount + completeCount)) * 100)) > 100 ? "100" : Math.round(((incompleteCount / (incompleteCount + completeCount)) * 100)).toString()+'%')
        setCoursefiltercompletePercentage(Math.round(((completeCount / (incompleteCount + completeCount)) * 100)) > 100 ? "100" : Math.round(((completeCount / (incompleteCount + completeCount)) * 100)).toString()+'%')
      
        seriesDataPieChart.push({
          name: "Complete (%)",
          y: completeCount
        })
        seriesDataPieChart.push({
          name: "Incomplete (%)",
          y: incompleteCount
        })
  
        setSeriesDataPieChart(seriesDataPieChart);

        //  //uncommet for Pie chart    
        //  let filterdGrandTotalSummaryReportItem = adminReportResult ;        
        //  filterdGrandTotalSummaryReportItem =  adminReportResult.filter((item:any) => item.unitPathway.toString().toLowerCase() === 'grand total');       
        //   if(filterdGrandTotalSummaryReportItem!= null)
        //      { 
        //          const r2 = filterdGrandTotalSummaryReportItem[0] ;     
                 
        //          let IncompletePercentage = GetRoundedIncompletePercentage(r2.incompletePercentage, r2.completePercentage);
        //          let completePercentage = GetRoundedCompletePercentage(r2.incompletePercentage, r2.completePercentage);
        
        //              seriesDataPieChart.push({
        //              name: "Complete (%)",
        //              y: completePercentage
        //              })                  
        //              seriesDataPieChart.push({           
        //                  name: "Incomplete (%)",              
        //                  y: IncompletePercentage
        //              })
                                           
        //              setSeriesDataPieChart(seriesDataPieChart);
        //     }           
           
    }  
}
//#endregion


if (exporting) return <LoaderContainer/>     
return (
<>
    <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
    onExit={onExit}
    options={{
    nextLabel: 'Next',
    prevLabel: 'Back',
    tooltipClass: 'sample-class',
    showBullets: true,
    }}/>
    <CustomTooltip displayText={["Guide Me"]}>
    <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
    <GuideIcon />
    </span>
    </CustomTooltip>
    <BrandedCard>
        <div className={styles.reportPageTitleSection}>       
            <div className={styles.exportBtnContainer}>
                <div className={styles.reportContainer}>
                    <div className={styles.reportDetailContainer}>
                        <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                        <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                    </div>
                    <div className={styles.reportDetailContainer}>
                        <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                        <span className={styles.reportDetail}>
                            { reportRunTimeTaken }
                        </span>
                    </div> 
                    {GetClassificationname()}     
                    {GetDateFrom()}    
                    {GetDateTo()}    
                    {(selectedCourseName!="")? GetSelectedCourseNameSummary():""}
                
                </div>
                    <div className={styles.reportExportContainer}>
                        <CustomTooltip displayText={[allResourcesToTranslations.exporttoxlsx]}>                                    
                            <Button className="xlxs"  color="primary" onClick={handleXLSXExport} id="btnNewSaltSRReportExportXLSX">
                                <ExcelFileIcon/>
                                {/* <GetAppIcon/> */}
                            </Button>
                        </CustomTooltip>
                        <CustomTooltip displayText={[allResourcesToTranslations.exporttocsv]}>         
                            <Button className="csv" color="primary"  onClick={handleCSVExport} id="btnNewSaltSRReportExportCSV">
                                <CSVFileIcon/>
                                {/* <GetAppIcon/> */}
                            </Button>
                        </CustomTooltip>
                        <CustomTooltip displayText={[allResourcesToTranslations.exporttopdf]}>        
                            <Button className="pdf"  color="primary" onClick={handlePDFXExport}>
                            <PDFIcon/>
                                {/* <GetAppIcon/> */}
                            </Button>
                        </CustomTooltip>
                        {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading"  color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/> 
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                
            </div>                
        </div>
    </BrandedCard>
    <hr/>
    {<div style={{color:"red"}}>{(exportError === undefined || exportError === null  || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}
    <hr/>            
    <BrandedCard>
            <div style={{ padding: "15px", display: "flex" }}>
                <div className={styles.row} style={{  width: "100%" }}>
                    <div className={styles.column} style={{ paddingTop: "10px",width: "37%", fontWeight:"bold"  }}>
                        Grand Total
                    </div>
                    <div className={styles.column} style={{ width: "63%" }}>
                        <div className={styles.row} style={{ width: "100%" }}>
                            <div className={styles.column} style={{paddingTop: "10px", float: "left",fontWeight:"bold" }}> Course Summary</div>
                            <div className={styles.column} style={{ float: "right" }}>      
                            <Select style={{ width: "500px" }}
                                // onChange={(e) => {
                                //     var curSel = { courseName: e.target.value };
                                //     GetSummaryReportChart(curSel.courseName);
                                //     setSelectedCategory(curSel.courseName);
                                // }}
                                onChange={(e) => {setSelectedCategory({value: e.target.value});  GetSummaryReportChart(e.target.value);}}
                                className={styles.input}
                                variant="outlined"
                                value={selectedCategory.value}
                                native
                            >
                                <option value="" selected > -- All Courses -- </option>
                                {originalsummaryReportUniqueCourseListItems.map((item: any) => <option value={item.courseName}>{item.courseName}</option>
                                )}
                            </Select>    </div>
                        </div>
                    </div>                   
                </div>                
            </div>     
            <div className={styles.row} style={{ paddingLeft: "10px", paddingRight:"10px"}}>
                <hr></hr>
                </div>      
            <div  style={{width:"100%",display: "flex" }}>              
                <div style={{paddingTop: "20px",paddingBottom: "20px",paddingRight: "20px", width:"37%" }} >
                    <PieChart  seriesData={seriesPieChart} ChartTitle={"Grand Total"} seriesName={"Total Course"} innerSize="40%" themecolor={color}   />                
                </div>                
                <div style={{paddingTop: "20px",paddingBottom: "20px",width:"63%" ,flex: "1"}} > 
                    <DualaxeslineStakedColumnChart categoriesArray={categoriesArray} incomplete={arrTotalIncomplete} complete={arrTotalcomplete} 
                    incompletePercentage={arrTotalIncompletePercentage} completePercentage={arrTotalcompletePercentage}   seriesIncompleteName={"Incomplete"}  seriesCompleteName={"Complete"}                   
                    ChartTitle={"Course Summary"} 
                    yAxisPrimaryTitle={"Total Incomplete/Complete Courses"} yAxisSecondaryTitle={"Total Incomplete/Complete Users"} themecolor={color} />         
                </div>    
            </div>   
    </BrandedCard>              
    {/* <BrandedCard>               
        <div style={{ height: "500px", paddingTop: "20px", width:"100%" }} >                    
            <ColumnChart categoriesArray={categoriesArray} seriesData={seriesDataColumnChart} ChartTitle={"Summary Report"}                     
            yAxisTitle={"Total Incomplete/ Complete"}  themecolor={color}    /> 
        </div>
    </BrandedCard>
    
    <div><span>&nbsp;</span></div>
    <BrandedCard>
            <div >
                <div style={{ height: "400px", paddingTop: "20px", width:"100%" }} >      
                <ComboColumnPieChart categoriesArray={categoriesArray} arrTotalIncomplete={arrTotalIncomplete}  
                arrTotalComplete={arrTotalcomplete} TotalIncomplete={TotalIncomplete} TotalComplete={TotalComplete} ChartTitle={"Summary Report"}                     
                yAxisTitle={"Total Incomplete/ Complete"}  themecolor={color}   /> 
                </div> 
            </div>            
        </BrandedCard> */}
    <div><span>&nbsp;</span></div>
    <div className={ styles.paginationControlsContainer }>
        <TablePagination
            rowsPerPageOptions={[5, 10, 20, 100]}
            component="div"
            count={adminReportResult.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div> 
    <div className={styles.tableSection}>
        <div className={styles.tableContainer}> 
            <BrandedCard>
                <TableContainer>
                    <Table>
                    <TableHead className="summaryReportList">
                            <TableRow>
                                <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", width:"50%"  }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setUnitDescending(!unitDescending);
                                                        sortResultsBy("Unit", !unitDescending);
                                                    }}>
                                                {"Unit Pathway"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUnitAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUnitDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                </TableCell>  
                                <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, width:"50%"  }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                        {"Course"}
                                        </div>
                                        {/* <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setCourseNameDescending(!courseNameDescending);
                                                        sortResultsBy("CourseName", !courseNameDescending);
                                                    }}>
                                                {"Course"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "CourseName" && courseNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickCourseNameAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "CourseName" && courseNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickCourseNameDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div> */}
                                </TableCell>                                                                              
                                <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div
                                            onClick={() => 
                                                {
                                                    setCountDescending(!countDescending);
                                                    sortResultsBy("Count", !countDescending);
                                                }} >
                                            {"Accounts"}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                            <span   style={{ opacity: (currentSortBy === "Count" && countDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickCountAsc}><UpArrowIcon /> </span>
                                            <span   style={{ opacity: (currentSortBy === "Count" && countDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickCountDesc}><DownArrowIcon /> </span>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div 
                                            onClick={() => 
                                                {
                                                    setIncompleteDescending(!incompleteDescending);
                                                    sortResultsBy("Incomplete", !incompleteDescending);
                                                }}>
                                            {"Incomplete"}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                            <span   style={{ opacity: (currentSortBy === "Incomplete" && incompleteDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickIncompleteAsc}> <UpArrowIcon /> 
                                            </span>
                                            <span   style={{ opacity: (currentSortBy === "Incomplete" && incompleteDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickIncompleteDesc}> <DownArrowIcon /> 
                                            </span>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div 
                                            onClick={() => 
                                                {
                                                    setIncompletePercentageDescending(!incompletePercentageDescending);
                                                    sortResultsBy("IncompletePercentage", !incompletePercentageDescending);
                                                }}>
                                            {"Incomplete (%)"}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                            <span   style={{ opacity: (currentSortBy === "IncompletePercentage" && incompletePercentageDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickIncompletePercentageAsc}> <UpArrowIcon /> 
                                            </span>
                                            <span   style={{ opacity: (currentSortBy === "IncompletePercentage" && incompletePercentageDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickIncompletePercentageDesc}> <DownArrowIcon /> 
                                            </span>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div 
                                            onClick={() => 
                                                {
                                                    setCompleteDescending(!completeDescending);
                                                    sortResultsBy("Complete", !completeDescending);
                                                }}>
                                            {"Complete"}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                            <span   style={{ opacity: (currentSortBy === "Complete" && completeDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickCompleteAsc}> <UpArrowIcon /> 
                                            </span>
                                            <span   style={{ opacity: (currentSortBy === "Complete" && completeDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickCompleteDesc}> <DownArrowIcon /> 
                                            </span>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="left">
                                    <div className={styles.tableHeadCellContainer}>
                                        <div 
                                            onClick={() => 
                                                {
                                                    setCompletePercentageDescending(!completePercentageDescending);
                                                    sortResultsBy("CompletePercentage", !completePercentageDescending);
                                                }}>
                                            {"Complete (%)"}
                                        </div>
                                        <div className={styles.columnHeadArea}>
                                            <span   style={{ opacity: (currentSortBy === "CompletePercentage" && completePercentageDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickCompletePercentageAsc}> <UpArrowIcon /> 
                                            </span>
                                            <span   style={{ opacity: (currentSortBy === "CompletePercentage" && completePercentageDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                    onClick={clickCompletePercentageDesc}> <DownArrowIcon /> 
                                            </span>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                            {selectedCategory.value == "" ?
                                
                                    adminReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: any, i) => {
                                        return(
                                            (adminReportResult.length-1 !== ((page*rowsPerPage) + i)) ?
                                                (
                                                    <>
                                                        {
                                                            result.childrenOfUnitSummry.map((result2: any, j: any) => {
                                                                return (
                                                                    <TableRow key={`summaryreportresult-${j}-unit-${result.unitPathway}`}  style={ rowBackgroundColor(i) }>
                                                                        {/* <TableCell align="left" rowSpan={1} style={{  border: "none", padding:"5px 10px 5px 10px" }}>                                                                                       
                                                            {j === 0 ?  result2.unitPathway  : (j === result2.length-1 ? <b>{result2.unitPathway2}</b> : "")}                                                                                                                                                                          
                                                            </TableCell> */}
                                                                        {
                                                                            j === 0 ?
                                                                                ( //Dispaly Parent Unit
                                                                                    <TableCell align="left" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                                        {result2.unitPathway}
                                                                                    </TableCell>)
                                                                                :
                                                                                (
                                                                                    j === result2.length-1 ?
                                                                                        (//Display Sub Total
                                                                                            <TableCell align="right" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                                                <b>{result2.unitPathway2}</b>
                                                                                            </TableCell>
                                                                                        )
                                                                                        :
                                                                                        (//Display Sub Unit for Sub Course
                                                                                            <TableCell align="left" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                                                {""}
                                                                                            </TableCell>
                                                                                        )
                                                                                )
                                                                        }

                                                                        <TableCell align="left" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                            {j === result2.length-1 ? result2.courseName2 : result2.courseName}
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                            {j === result2.length-1 ? <b>{result2.totalUsers2.toString()}</b> : result2.totalUsers.toString()}
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                            {j === result2.length-1 ? <b>{result2.incomplete2.toString()}</b> : result2.incomplete.toString()}
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                            {j === result2.length-1 ? <b>{ GetRoundedIncompletePercentage(result2.incompletePercentage2, result2.completePercentage2).toString()+'%' }</b>:  GetRoundedIncompletePercentage(result2.incompletePercentage, result2.completePercentage).toString()+'%'}
                                                                        </TableCell> 
                                                                        <TableCell align="center" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                            {j === result2.length-1 ? <b>{result2.complete2.toString()}</b> : result2.complete.toString()}
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none", padding:"5px 10px 5px 10px"  }}>
                                                                            {j === result2.length-1 ? <b>{GetRoundedCompletePercentage(result2.incompletePercentage2, result2.completePercentage2).toString()+'%'}</b> : GetRoundedCompletePercentage(result2.incompletePercentage, result2.completePercentage).toString()+'%'}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                        }

                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        {
                                                            result.childrenOfUnitSummry.map((result2: any, j: any) => {
                                                                return (
                                                                    <TableRow key={`summaryreportresult-${j}-head`}   style={ rowBackgroundColor(i) }>
                                                                        <TableCell align="right" rowSpan={1} style={{  border: "none" }}>
                                                                            <b>{result2.unitPathway}</b>
                                                                        </TableCell>
                                                                        <TableCell align="left" style={{  border: "none" }}>
                                                                            <b>{result2.courseName}</b>
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none" }}>
                                                                            <b>{result2.totalUsers.toString()}</b>
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none" }}>
                                                                            <b>{result2.incomplete.toString()}</b>
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none" }}>
                                                                            <b>{ GetRoundedIncompletePercentage(result2.incompletePercentage, result2.completePercentage).toString()+'%'}</b>
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none" }}>
                                                                            <b>{result2.complete.toString()}</b>
                                                                        </TableCell>
                                                                        <TableCell align="center" style={{  border: "none" }}>
                                                                            <b>{GetRoundedCompletePercentage(result2.incompletePercentage, result2.completePercentage).toString()+'%'}</b>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            })
                                                        }

                                                    </>
                                                )
                                        );                                    
                                    })
                                :
                                
                                
                                adminReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: any, i) => {
                                    return(
                                        (adminReportResult.length-1 !== ((page*rowsPerPage) + i)) ?
                                            (
                                  
                                            <>{
                                               
                                                result.childrenOfUnitSummry.map((result2: any, j: any) => {
                                                    return (
                                                        <TableRow key={`summaryreportresult-${j}-unit-${result.unitPathway}`} style={rowBackgroundColor(i)}>                                                  
                                                            {
                                                                j === 0 ?
                                                                    ( //Dispaly Parent Unit
                                                                        <TableCell align="left" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                            {result2.unitPathway}
                                                                        </TableCell>)
                                                                    :
                                                                    (
                                                                        j === result2.length - 1 ?
                                                                            (//Display Sub Total
                                                                                <TableCell align="right" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                                    <b>{result2.unitPathway2}</b>
                                                                                </TableCell>
                                                                            )
                                                                            :
                                                                            (//Display Sub Unit for Sub Course
                                                                                <TableCell align="left" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                                    {""}
                                                                                </TableCell>
                                                                            )
                                                                    )
                                                            }
                                                            <TableCell align="left" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                {j === result2.length - 1 ? result2.courseName2 : result2.courseName}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                {j === result2.length - 1 ? <b>{result2.totalUsers2.toString()}</b> : result2.totalUsers.toString()}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                {j === result2.length - 1 ? <b>{result2.incomplete2.toString()}</b> : result2.incomplete.toString()}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                {j === result2.length - 1 ? <b>{GetRoundedIncompletePercentage(result2.incompletePercentage2, result2.completePercentage2).toString() + '%'}</b> : GetRoundedIncompletePercentage(result2.incompletePercentage, result2.completePercentage).toString() + '%'}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                {j === result2.length - 1 ? <b>{result2.complete2.toString()}</b> : result2.complete.toString()}
                                                            </TableCell>
                                                            <TableCell align="center" style={{ border: "none", padding: "5px 10px 5px 10px" }}>
                                                                {j === result2.length - 1 ? <b>{GetRoundedCompletePercentage(result2.incompletePercentage2, result2.completePercentage2).toString() + '%'}</b> : GetRoundedCompletePercentage(result2.incompletePercentage, result2.completePercentage).toString() + '%'}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                             
                                            }</>
                                                                      
                                        )
                                        :
                                        (
                                            <>
                                                {
                                                    result.childrenOfUnitSummry.map((result2: any, j: any) => {
                                                        return (
                                                            <TableRow key={`summaryreportresult-${j}-head`}   style={ rowBackgroundColor(i) }>
                                                                <TableCell align="right" rowSpan={1} style={{  border: "none" }}>
                                                                    <b>Grand Total</b>
                                                                </TableCell>
                                                                <TableCell align="left" style={{  border: "none" }}>
                                                                    <b></b>
                                                                </TableCell>
                                                                <TableCell align="center" style={{  border: "none" }}>
                                                                    <b>{coursefilterincomplete + coursefiltercomplete}</b>
                                                                </TableCell>
                                                                <TableCell align="center" style={{  border: "none" }}>
                                                                    <b>{coursefilterincomplete.toString()}</b>
                                                                </TableCell>
                                                                <TableCell align="center" style={{  border: "none" }}>
                                                               
                                                                <b>{coursefilterincompletePercentage}</b>
                                                                </TableCell>
                                                                <TableCell align="center" style={{  border: "none" }}>
                                                                    <b>{coursefiltercomplete.toString()}</b>
                                                                </TableCell>
                                                                <TableCell align="center" style={{  border: "none" }}>
                                                                <b>{coursefiltercompletePercentage}</b>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }

                                            </>
                                        )
                                    );                                    
                                })                              
                            }
                            </TableBody>
                    </Table>
                </TableContainer>
            </BrandedCard>
        </div>
    </div>
    <div className={ styles.paginationControlsContainer }>
        <TablePagination
            component="div"
            count={adminReportResult.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div>
</>
);    
}

export default CurrentAdminReportResult
