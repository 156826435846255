//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import styles from "../../../styles/addAccount.module.css";

//General Functions
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
import LoaderContainer from '../../../Components/LoaderContainer';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import ToggleLeftIcon from '../../../CustomIcons/ToggleLeftIcon';
import GetCourseDetails from '../../../API/Administration/CourseSettings/GetCourseDetails';
import getColor from '../../../functions/getColor';import { CourseSCORMcontentList, CourseAdditionalResources, UnitTreeItem, UnitComplianceReportResultItem, SentEmailReportResultItem, OrgNoteResultItem, SelectedSettings } from "../../../globalTypes";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '../../../CustomIcons/EditIcon';
import CourseProgressBar from './CourseProgressBar';
import GetCourseAdditionalResourcesList from '../../../API/Administration/CourseSettings/GetCourseAdditionalResourcesList';
import getRelevantUnitTree from '../../../API/GetRelevantUnitTree';

import getOrgMailSetup from '../../../API/Administration/Organisation/GetOrgMailSetup';
import getFullCourseComplianceReport from '../../../API/Administration/CourseSettings/GetFullCourseComplianceReport';

import ActiveIcon from "../../../CustomIcons/Status_ActiveIcon";
import UnbrandedCard from '../../../Components/UnbrandedCard';
import TinyMCEEditor from '../../../Components/TinyMCEEditorComponent';
import SendEmailReportIcon from '../../../CustomIcons/SendEmailReportIcon';
import addEmailingReport from '../../../API/AddEmailingReport';
import ResetIcon from '../../../CustomIcons/ResetIcon';
import { decode } from "html-entities";
import NotesIcon from '../../../CustomIcons/NotesIcon';

const CourseSummary = (props: any) => {
  const { courseIdToEdit } = useParams<{ courseIdToEdit: string }>();
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, superAdminSelectedBasicOrgData } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const [courseSCORMcontentList, setCourseSCORMcontentList] = useState<Array<CourseSCORMcontentList>>([]);
  const [CourseAdditionalResources, setCourseAdditionalResources] = useState<Array<CourseAdditionalResources>>([]);


  const [coursename, setcourseName] = useState<string>("");
  const [expandCourseList, setExpandCourseList] = useState<boolean>(true);
  const [expandCourseRecourseList, setExpandCourseRecourseList] = useState<boolean>(true);
  const [expandUnitModuleList, setExpandUnitModuleList] = useState<boolean>(true);

  const [expandReminderEmailsList, setExpandReminderEmailsList] = useState<boolean>(true);
  const [expandComplianceRuleList, setExpandComplianceRuleList] = useState<boolean>(true);
  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);


  const [initialEnrolmentEnable, setInitialEnrolmentEnable] = useState<boolean>(false);
  const [preExpiryEnable, setPreExpiryEnable] = useState<boolean>(false);
  const [postExpiryEnable, setPostExpiryEnable] = useState<boolean>(false);
  const [managerEnable, setManagerEnable] = useState<boolean>(false);
  const [remescIdToUpdate, setRemescIdToUpdate] = useState<number>(0);

  const [unitComplianceReport, setUnitComplianceReportResult] = useState<Array<UnitComplianceReportResultItem>>([]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);

  //welcomeEmail
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [welcomeMessageBodyTemplateOriginal, setWelcomeMessageBodyTemplateOriginal] = useState<string>("");
  const [welcomeMessageBodyTemplate, setWelcomeMessageBodyTemplate] = useState<string>("");
  const [welcomeMessageBodyResult, setWelcomeMessageBodyResult] = useState<Array<OrgNoteResultItem>>([]);
  const [assignedUnitIds, setAssignedUnitIds] = useState<Array<number>>([]);
  const [assignedUserIds, setAssignUserIds] = useState<Array<number>>([]);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [saveSuccess, setSaveSuccess] = React.useState(false);

  const [modalPopUpSettingsMessages, setModalPopUpSettingsMessages] = React.useState<Array<SelectedSettings> | null>(null);
  const [isCourseAdditionalRecourse, setIsCourseAdditionalRecourse] = useState<boolean>(false);
	const [isRemEmails,setIsRemEmails ] = useState<boolean>(false);
	const [isUnitRule, setIsUnitRule ] = useState<boolean>(false);
  const [includeWelcomeEmail, setIncludeWelcomeEmail] = React.useState<boolean>(false);

  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".Editcourse",
        title: "Edit Course",
        intro: allResourcesToTranslations.summaryucourseguideme
      },

    ]
  })
  const setGuideMe = (includeWelcomeEmail: boolean = false, isCourseAdditionalRecourse: boolean = false,isRemEmails: boolean = false, isUnitRule: boolean = false) => {

    let updateSteps = [];

    updateSteps.push(
      {
        element: ".Editcourse",
        title: "Edit Course",
        intro: allResourcesToTranslations.summaryucourseguideme
      })
      if(courseSCORMcontentList  !==null || courseSCORMcontentList !== undefined)
      {
        updateSteps.push(
          {
            element: ".CourseDetails",
            title: "Course Summary",
            intro: allResourcesToTranslations.summaryycoursedetails
          })
      }
    
      if(isCourseAdditionalRecourse) 
      {
        updateSteps.push(
          {
            element: ".resource",
            title: "Edit Additional Resource",
            intro: allResourcesToTranslations.summaryyadditionalresourcesedit
          },
          {
            element: ".ResourcesList",
            title: "Additional Resource",
            intro:allResourcesToTranslations.summaryyadditionalresources
          })
      }     

      if(isRemEmails)
        {
          updateSteps.push(
            {
              element: ".ReminderEmails",
              title: "Edit Reminder Email Rules",
              intro: allResourcesToTranslations.summaryreminderemails
            })
        }

      if(isUnitRule)
        {
          updateSteps.push(           
            {
              element: ".compliancerulelist",
              title: "Compliance Report",
              intro: allResourcesToTranslations.summarycompliancerulelist
            })
        }

      if(includeWelcomeEmail)
        {
          updateSteps.push(
            {
              element: ".emailSubject",
              title: "Email Subject",
              intro: allResourcesToTranslations.summaryemailsubject
            },
          {
            element: ".editorText",
            title: "Email Body",
            intro: allResourcesToTranslations.summaryemailtext
          },
          {
            element: ".resetEmail",
            title: "Reset Email",
            intro: allResourcesToTranslations.summaryemailreset
          },
          {
            element: ".sendEmail",
            title: "Send Email",
            intro: allResourcesToTranslations.summaryemailsend

          })
        }      
    
    setIntro({ ...intro, steps: [...updateSteps] });
  };
 
  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  
  const resetAll = () => {
    setWelcomeMessageBodyResult([{ body: "", orgId: parseInt(orgId) }]);
    setTimeout(() => {
      LoadOriginalWelcomeTemplate();
    }, 100);
  };

  const LoadOriginalWelcomeTemplate = () => {
    var html = decode(welcomeMessageBodyTemplateOriginal);
    setWelcomeMessageBodyResult([{ body: html, orgId: parseInt(orgId) }]);
  }

  useEffect(() => {
    setGuideMe(includeWelcomeEmail,isCourseAdditionalRecourse,isRemEmails,isUnitRule);		
    setLoading(false);
    setTitle(allResourcesToTranslations.publishcourse + " - " + allResourcesToTranslations.step6)
    setTitleEnglish(pageTitles.publishcourse);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.publishcourse);
    if (parseInt(courseIdToEdit) > 0) {     
      LoadCourseRecourse();
      refreshUnitList();
      LoadOrganisationEmailRule();
      LoadCourseComplianceRule();
    }
    return () => { };
  }, [superAdminSelectedBasicOrgData]);

  const LoadCourseRecourse = () => {
    GetCourseAdditionalResourcesList(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId), parseInt(courseIdToEdit)).then((result: any) => {
        if (!!result && result.isSuccess && result.error == null) {
          setCourseAdditionalResources([...result.courseAdditionalResourcesListItems]);
          setLoading(false);                    
        }
      });
  }
  const updateSelection = (unit: any, selectedUnits: string[]) => {
    unit.isSelected = selectedUnits.includes(unit.unitID.toString());
    if (unit.childUnitTreeItems && unit.childUnitTreeItems.length > 0) {
      unit.childUnitTreeItems.forEach((childUnit: any) => {
        updateSelection(childUnit, selectedUnits);
      });
    }
  };

  const refreshUnitList = () => {
    var selectedUnitIds = '';
    GetCourseDetails(oidcUser.access_token,
      userId,
      orgDomain,
      parseInt(orgId),
      parseInt(courseIdToEdit),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
      (!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
        if (result != null && result.isSuccess && result.error == null) {
          selectedUnitIds = result.getCourseDetailsItems[0].unitIds;

          setCourseSCORMcontentList([...result.getCourseModuleDetailsItems]);
          setcourseName(result.getCourseDetailsItems[0].name);
          setRemescIdToUpdate(result.getCourseDetailsItems[0].remEscId);
          setAssignedUnitIds(result.getCourseDetailsItems[0].unitIds);
          setAssignUserIds(result.getCourseDetailsItems[0].userIds);
          setWelcomeMessageBodyTemplateOriginal(result.getCourseDetailsItems[0].welcomEmailTemplate);
          setWelcomeMessageBodyTemplate(result.getCourseDetailsItems[0].welcomEmailTemplate);
          var html = decode(result.getCourseDetailsItems[0].welcomEmailTemplate);
          setWelcomeMessageBodyResult([{ body: html, orgId: parseInt(orgId) }]);        
          if (result.getCourseDetailsItems[0].additionalRecourseIds > 0)
						{	
              setIsCourseAdditionalRecourse(true);
              setGuideMe(includeWelcomeEmail,true,result.getCourseDetailsItems[0].remEscId > 0? true : false ,result.getCourseDetailsItems[0].unitRuleId > 0?true:false);
						}		
						if (result.getCourseDetailsItems[0].remEscId > 0)
						{		
              setIsRemEmails(true);
              setGuideMe(includeWelcomeEmail,result.getCourseDetailsItems[0].additionalRecourseIds > 0?true:false,true ,result.getCourseDetailsItems[0].unitRuleId > 0?true:false);
						}	
            if (result.getCourseDetailsItems[0].unitRuleId > 0)            
            {    
              setIsUnitRule(true);            				
              setGuideMe(includeWelcomeEmail,result.getCourseDetailsItems[0].additionalRecourseIds > 0?true:false,result.getCourseDetailsItems[0].remEscId > 0? true : false ,true);		
            }

             
          setLoading(false);
        }
      });
    selectedUnitIds = assignedUnitIds.join(',');

    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {

        if (unitTreeRes.unitTreeItems != null && unitTreeRes.unitTreeItems != undefined) {
          let selectedUnits = selectedUnitIds.toString().split(',');
          let currunitTreeRes = unitTreeRes.unitTreeItems;
          currunitTreeRes.forEach((unit: any) => {
            updateSelection(unit, selectedUnits);
          });
          setUnits(currunitTreeRes);
          setLoading(false);
        }

        // Build the UI settings sub section values
        let allSettings = new Array<SelectedSettings>();
        // Selected Units
        var settings = getUnitSelectionSettings(unitTreeRes.unitTreeItems);
        if (settings !== null && settings !== undefined) {
          if (isErrorPresentInSettings(settings) === true) {
            return;
          }
          allSettings.push(settings);
        }
        if (allSettings.length > 0) {
          setModalPopUpSettingsMessages(allSettings);
        }
      }
    }).catch((error) => {
    });
  }

  const isErrorPresentInSettings = (settings: SelectedSettings) => {
    if (settings !== null && settings !== undefined &&
      settings.error !== null && settings.error !== undefined &&
      settings.error.trimLeft().trimRight() !== "") {
      //openSettingsError(settings.error);
      return true;
    }
    return false;
  }
  const getUnitSelectionSettings = (units: UnitTreeItem[]) => {
    // Selected Units
    var unitNamesGiven: UnitTreeItem[] = [];
    var unitsToDisplay = getAllUnitSettingsArrayInTree(units, false, unitNamesGiven);//units.filter(u => u !== null && u !== undefined && u.isSelected);
    var isAllUnits = false;
    if (unitsToDisplay === null || unitsToDisplay === undefined || unitsToDisplay.length <= 0) {
      unitsToDisplay = getAllUnitSettingsArrayInTree(units, true, unitsToDisplay); //selectedUnitNames.map(u => u.name);
      isAllUnits = true;
    }
    let unitsToDisplayUnique = unitArrayUnique(unitsToDisplay);
    if (unitsToDisplayUnique !== null && unitsToDisplayUnique !== undefined && unitsToDisplayUnique.length > 0) {
      //var unitNames = unitsToDisplayUnique.filter(u => u !== null && u !== undefined).map(u => "�" + u.unitHierarchy);
      var unitNames = unitsToDisplayUnique.filter(u => u !== null && u !== undefined).map(u => u.unitHierarchy);
      let selectedUnitsListSection: SelectedSettings = {
        title: isAllUnits ? "Units" : "Units",
        rowDisplayValues: unitNames,
        isHidden: false,
        currentDisplayRowCount: 3,
        defaultDisplayRowCount: 10,
        error: ""
      };
      return selectedUnitsListSection;
    }
    return null;
  }
  const getAllUnitSettingsArrayInTree = (givenUnits: UnitTreeItem[] | null, isNotOnlySelected: boolean, returnUnits: UnitTreeItem[]) => {
    if (givenUnits === null || givenUnits === undefined || givenUnits.length <= 0) {
      return returnUnits;
    }
    if (returnUnits === null || returnUnits === undefined) {
      returnUnits = [];
    }
    var unitNames = givenUnits.map(u => getGivenUnitSettingsArray(u, isNotOnlySelected, returnUnits));
    let singleArr = appendAsSingleUnitArray(unitNames, returnUnits);
    if (singleArr === null || singleArr === undefined || singleArr.length <= 0) {
      return returnUnits;
    }
    returnUnits = returnUnits.concat(singleArr);
    return returnUnits;
  }
  const getGivenUnitSettingsArray = (givenUnit: UnitTreeItem, isNotOnlySelected: boolean, returnUnits: UnitTreeItem[]) => {
    if (givenUnit === null || givenUnit === undefined) {
      return returnUnits;
    }
    if (returnUnits === null || returnUnits === undefined) {
      returnUnits = [];
    }
    returnUnits = returnUnits.concat((isNotOnlySelected || givenUnit.isSelected) ? [givenUnit] : []);
    let subArray = getAllUnitSettingsArrayInTree(givenUnit.childUnitTreeItems, isNotOnlySelected, returnUnits);
    if (subArray !== null && subArray !== undefined && subArray.length >= 0) {
      returnUnits = returnUnits.concat(subArray);
    }
    return returnUnits;
  }
  const appendAsSingleUnitArray = (givenArrOfArr: UnitTreeItem[][] | null, returnUnitNames: UnitTreeItem[]) => {
    if (givenArrOfArr === null || givenArrOfArr === undefined || givenArrOfArr.length <= 0) {
      return returnUnitNames;
    }
    if (returnUnitNames === null || returnUnitNames === undefined) {
      returnUnitNames = [];
    }
    givenArrOfArr.map(aa => {
      if (aa !== null && aa !== undefined && aa.length >= 0) {
        returnUnitNames = returnUnitNames.concat(aa)
      }
      else {
        returnUnitNames = returnUnitNames;
      }
    });
    return returnUnitNames;
  }

  const unitArrayUnique = (array: UnitTreeItem[]) => {
    if (array === null || array === undefined || array.length <= 0) {
      return array;
    }
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }

    return a;
  }

  const LoadOrganisationEmailRule = () => {
    getOrgMailSetup(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(courseIdToEdit), null, null, 'c').then((result) => {
      if (result.isSuccess) {
        setPreExpiryEnable(result.orgEmailSetupDetail.quizExpiryWarn)
        setPostExpiryEnable(result.orgEmailSetupDetail.postExpReminder)
        setInitialEnrolmentEnable(result.orgEmailSetupDetail.remindUsers);
        setManagerEnable(result.orgEmailSetupDetail.notifyMgr)        
      }
    })

  }

  const LoadCourseComplianceRule = () => {
    getFullCourseComplianceReport(
      oidcUser.access_token, userId, orgDomain, parseInt(orgId),
      parseInt(courseIdToEdit), false
    ).then((response) => {
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.courseComplianceReportItems !== null &&
        response.courseComplianceReportItems !== undefined
      ) {

        if (response.courseComplianceReportItems.length > 0) {
          let updatedComplianceList = response.courseComplianceReportItems.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          setUnitComplianceReportResult(updatedComplianceList);                	
        }
      } else {
        return;
      }
    });
  }

  const redirectCourseAdditional = (addedCourseID: number) => {
    history.push({
      pathname: `/courserecourse/` + addedCourseID
    });
  }
  const redirectAddCourse = (addedCourseID: number) => {
    history.push({
      pathname: `/addcourse/` + addedCourseID
    });
  }

  const redirectReminderEmails = (addedCourseID: number) => {
    if (remescIdToUpdate > 0) {
      history.push({
        pathname: `/courseemailrulesadd/` + addedCourseID + '/' + remescIdToUpdate
      });
    } else {
      history.push({
        pathname: `/courseemailrulesadd/` + addedCourseID
      });
    }
  }

  const handleExpandCourseListClick = () => {
    setExpandCourseList(!expandCourseList);
  }
  const handleExpandCourseRecourseListClick = () => {
    setExpandCourseRecourseList(!expandCourseRecourseList);
  }

  const handleExpandUnitModuleListClick = () => {
    setExpandUnitModuleList(!expandUnitModuleList);
  }


  const handleExpandReminderEmailsListClick = () => {
    setExpandReminderEmailsList(!expandReminderEmailsList);
  }

  const handleExpandComplianceRulesListClick = () => {
    setExpandComplianceRuleList(!expandComplianceRuleList);
  }
  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };


  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEmailSubject = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if ((event.target.value != '')) {
      setEmailIsValid(false)
    }
    setEmailSubject(event.target.value);
  };
  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (
        (parseInt(year) < 10 ? "000" + year.toString()
          : ((parseInt(year) < 100 ? "00"
            : (((parseInt(year) < 1000 ? "0" : year.toString())))))) + "-" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" +
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }
    else {
      return "";
    }
  }

  const handleSendEmail = () => {
    if (assignedUserIds.length > 0) {
      const nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 1);
      let popupMessage = '';

      var BodyTemplateContent = (document.getElementById('editorText') as HTMLInputElement).value;
      var BodyTemplateInitialContent = (document.getElementById('editorChangedText') as HTMLInputElement).value;
      setWelcomeMessageBodyTemplate((!!BodyTemplateContent === false || BodyTemplateContent.trim() === "") ? BodyTemplateInitialContent : BodyTemplateContent);


      addEmailingReport(oidcUser.access_token,
        orgDomain, userId, parseInt(orgId), assignedUnitIds.toString(), courseIdToEdit, assignedUserIds.toString(),
        assignedUserIds.length, emailSubject, (!!BodyTemplateContent === false || BodyTemplateContent.trim() === "") ? BodyTemplateInitialContent : BodyTemplateContent,
        0, "1990-01-01", formatDate(nextDate), 2, 0,
        assignedUserIds.length, '', 6, '', 0, '', 1, 0, 0, false, false, '', false).then(response => {
          if (!!response && response.isSuccess) {
            popupMessage = popupMessage + " Welcome emails are queued and will start sending soon."
            setShowWarningModal(true);
            setModalPopupMessage(popupMessage);
            setSaveSuccess(true);
            setHasErrored(false);
          } else {
            const errorVal = popupMessage + "Sending welcome emails have failed! Please try again sending them from Email report or it will be picked up by overnight job.";
            setModalPopupMessage(errorVal);
            setHasErrored(true);
            setShowWarningModal(true);
          }
        }).catch((error) => {
          const errorVal = popupMessage + "Sending welcome emails have failed! Please try again sending them from Email report or it will be picked up by overnight job.";
          setModalPopupMessage(errorVal);
          setHasErrored(true);
          setShowWarningModal(true);
        });
    }
    else {
      setShowWarningModal(true);
      setHasErrored(false);
      setModalPopupMessage("Can't find assigned accounts to this certificate; Please assign the users to certificate before sending an email.");
    }

  }

  const handleTextEditorChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event !== undefined) {
      setWelcomeMessageBodyTemplate(event.target.value);
    }
  };

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };


  //Unit Accecc Functions
  const clickShowAllCommon = (sectionTitle: string) => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0) {
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => setAllAsDisplayRowCount(ms, sectionTitle));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }

  const clickShowLessCommon = (sectionTitle: string) => {
    if (modalPopUpSettingsMessages !== null && modalPopUpSettingsMessages !== undefined && modalPopUpSettingsMessages.length > 0) {
      var newModalPopUpSettingsMessages = modalPopUpSettingsMessages.map(ms => resetDisplayRowCount(ms, sectionTitle));
      setModalPopUpSettingsMessages(newModalPopUpSettingsMessages);
    }
  }
  const setAllAsDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined &&
      title.trimLeft().trimRight().toLowerCase() !== "" &&
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      if (given.rowDisplayValues !== null && given.rowDisplayValues !== undefined) {
        given.currentDisplayRowCount = given.rowDisplayValues.length;
      }
    }
    return given;
  }
  const resetDisplayRowCount = (given: SelectedSettings, title: string) => {
    if (given !== null && given !== undefined && title !== null && title !== undefined &&
      title.trimLeft().trimRight().toLowerCase() !== "" &&
      given.title.trimLeft().trimRight().toLowerCase().startsWith(title.trimLeft().trimRight().toLowerCase())) {
      given.currentDisplayRowCount = 3;
    }
    return given;
  }

  const handleNext = () => {

    history.push({
      pathname: `/coursedetails`
    });


  }
  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
        <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <div style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}>
        <CourseProgressBar courseIdToEdit={parseInt(courseIdToEdit)} step='6'/>
      </div>
      <BrandedCard>
        <div className={styles.reportWrraper}>
          <div className={styles.accountDetails} >
            {"Course Summary "/* TODO translate */}
          </div>
          <Accordion expanded={expandCourseList}
            style={{ margin: "15px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="managerSettings-content"
              id="course-header"
              onClick={(e) => handleExpandCourseListClick()}>
              <Typography className="typographyFonts">
                <div style={{ marginTop: '5px', float: 'left' }}> <b>Course</b> </div>
                <div style={{ marginTop: '5px', paddingLeft: '10px', float: 'left' }} onClick={() => redirectAddCourse(parseInt(courseIdToEdit))} className={`${styles.moduleListButton} Editcourse`} color="primary">
                  <EditIcon />
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
              <Typography className="typographyFonts" style={{ display: "inline" }}>
                {courseSCORMcontentList.length > 0 &&
                  <div className={`${styles.tableSection}`} style={{ paddingTop: "10px" }}>
                    <div className={styles.tableContainer}>
                      <UnbrandedCard>
                        <Table aria-label="simple table" className="CourseDetails">
                          <TableHead>
                            <TableRow>
                              <TableCell
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                  borderRadius: "8px 8px 0 0",
                                }} className="namecolumnguideme"
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    Item
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow className={styles.tableRow}>
                              <TableCell align="left"  >
                                <b>Course Name:</b> {coursename}
                              </TableCell>
                            </TableRow>
                            <TableRow className={styles.tableRow}>
                              <TableCell align="left" >
                                <b>Description</b>
                              </TableCell>
                            </TableRow>
                            {courseSCORMcontentList.slice().map((result: any, index: any) => (
                              <TableRow
                                key={result.moduleName}
                                className={styles.tableRow}
                              >
                                <TableCell align="left" width={"1%"} >
                                  {result.sequence}.&nbsp; {result.moduleName}
                                </TableCell>
                              </TableRow>
                            ))}

                          </TableBody>

                        </Table>
                      </UnbrandedCard>
                    </div>
                  </div>
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
          {CourseAdditionalResources.length  > 0 &&
          <Accordion expanded={expandCourseRecourseList}
            style={{ margin: "15px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="managerSettings-content"
              id="additionalrecourse-header" onClick={(e) => handleExpandCourseRecourseListClick()}>
              <Typography className="typographyFonts">
                <div style={{ marginTop: '5px', float: 'left' }}> <b>{allResourcesToTranslations.step2}</b> </div>
                <div style={{ marginTop: '5px', paddingLeft: '10px', float: 'left' }} onClick={() => redirectCourseAdditional(parseInt(courseIdToEdit))} className={`${styles.moduleListButton} resource`} color="primary">
                  <EditIcon />
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
              <Typography className="typographyFonts" style={{ display: "inline" }}>               
                  <div className={`${styles.tableSection} ResourcesList`} style={{ paddingTop: "10px" }}>
                    <div className={styles.tableContainer}>
                      <UnbrandedCard>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell width={"1%"}
                                style={{
                                  color: getColor(color.substring(1)),
                                  backgroundColor: color,
                                  borderRadius: "8px 8px 0 0",
                                }} className="namecolumnguideme"
                                align="left">
                                <div className={styles.tableHeadCellContainer}>
                                  <div>
                                    Item
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {CourseAdditionalResources.slice().map((result: any, index: any) => (
                              <TableRow
                                key={result.name}
                                className={styles.tableRow}>
                                <TableCell align="left" width={"1%"}>
                                  {result.sequence}.&nbsp;{result.name}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </UnbrandedCard>
                    </div>
                  </div>
                
              </Typography>
            </AccordionDetails>
          </Accordion>
          }
          {assignedUnitIds.length > 0 &&
          <Accordion expanded={expandUnitModuleList}
            style={{ margin: "15px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="managerSettings-content"
              id="assignedunits-header" onClick={(e) => handleExpandUnitModuleListClick()}>
              <Typography className="typographyFonts"><b>View Assigned Units </b>
              <div>
                <div  style={{ float: "right", width: "100%" , backgroundColor:'#ffdd40' , borderRadius:'5px' }}>
                  <div style={{ float: "left", width: "24px", padding: "10px" }}><NotesIcon></NotesIcon>
                  </div>
                  <div style={{ float: "right", width: "97%" }}><p>
                    <span>{allResourcesToTranslations.assigncourseupdatenotessummary}</span>
                  </p> </div>
                </div>
              </div>              
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
              <Typography className="typographyFonts" style={{ display: "inline" }}>
                {/* <div style={{ width: '100%', display:'none' }}>
                  <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} untitreeTitle={allResourcesToTranslations.unitstrainingplan} unitTreeTitleInfo={allResourcesToTranslations.unitstrainingplaninfo} />
                </div>  */}
               
                  <div className={styles.popupsplitleft}>
                    {
                      modalPopUpSettingsMessages &&
                      modalPopUpSettingsMessages.
                        filter(ssm => modalPopUpSettingsMessages.indexOf(ssm) >= 0 && (modalPopUpSettingsMessages.indexOf(ssm) % 2) === 0).
                        map((eee, index) => (
                          <>
                            <div style={{ margin: "10px" }} key={`modalsettingmsg-${index}`}>
                              <div style={{ fontSize: "14px", fontWeight: "bold" }}>{eee.title}</div>
                              {
                                eee.rowDisplayValues &&
                                eee.rowDisplayValues.
                                  filter((rd, index) => index < eee.currentDisplayRowCount).
                                  map((ee) => (
                                    <>
                                      <div style={{ fontSize: "14px", color: "#21394F" }}>
                                        {
                                          (ee !== null && ee !== undefined && ee.indexOf("???") >= 0) ?
                                            ee.split("???").map(
                                              (ees, index) => (
                                                (ees.trimLeft().trimRight() === "" ?
                                                  <>{<span style={{ color: "red", fontWeight: "bold" }} key={`ees-${index}`}> ??? </span>}</>
                                                  :
                                                  <>{ees}{((ee.split("???").indexOf(ees) !== (ee.split("???").length - 1)) ? <span key={`ees-${index}`} style={{ color: "red", fontWeight: "bold" }}> ??? </span> : "")}</>)
                                              )
                                            )
                                            :
                                            ((eee.title.toLowerCase().startsWith("units") ||
                                              eee.title.toLowerCase().startsWith("course") ||
                                              eee.title.toLowerCase().startsWith("cc")) ?
                                              <>
                                                <table style={{ width: "100%" }}>
                                                  <tr>
                                                    <td style={{ width: "1%" }}>•</td>
                                                    <td style={{ width: "95%", textAlign: "left" }}>{ee}</td>
                                                  </tr>
                                                </table>
                                              </>
                                              : ee)
                                        }
                                      </div>
                                    </>
                                  ))
                              }
                              {
                                (eee.rowDisplayValues.length > eee.currentDisplayRowCount)
                                  ?
                                  <>
                                    <div style={{ fontSize: "14px" }}><span onClick={() => clickShowAllCommon(eee.title)} style={{ fontSize: "14px", color: "blue" }}>+ Show All...</span></div>
                                  </>
                                  :
                                  <></>
                              }
                              {
                                (eee.rowDisplayValues.length > 3 && eee.currentDisplayRowCount > 3)
                                  ?
                                  <>
                                    <div style={{ fontSize: "14px" }}><span onClick={() => clickShowLessCommon(eee.title)} style={{ fontSize: "14px", color: "blue" }}>- Show only 3...</span></div>
                                  </>
                                  :
                                  <></>
                              }
                            </div>
                            <br />
                          </>
                        ))
                    }
                  </div>
            
              </Typography>
            </AccordionDetails>
          </Accordion>
          }
          {isRemEmails &&
          <Accordion expanded={expandReminderEmailsList}
            style={{ margin: "15px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="managerSettings-content"
              id="reminderemails-header" onClick={(e) => handleExpandReminderEmailsListClick()}>
              <Typography className="typographyFonts">
                <div style={{ marginTop: '5px', float: 'left' }}> <b>{allResourcesToTranslations.step4}</b> </div>
                <div  style={{ marginTop: '5px', paddingLeft: '10px', float: 'left' }}
                  onClick={() => redirectReminderEmails(parseInt(courseIdToEdit))} className={`${styles.moduleListButton} ReminderEmails`} color="primary">
                  <EditIcon />
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
              <Typography className="typographyFonts" style={{ display: "inline" }}>              
                <div className={`${styles.tableSection}`} style={{ paddingTop: "10px" }}>
                  <div className={styles.tableContainer}>
                    <UnbrandedCard>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell width={"1%"}
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "8px 0 0 0",
                              }} className="namecolumnguideme"
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  Reminders
                                </div>
                              </div>
                            </TableCell>
                            <TableCell width={"1%"}
                              style={{
                                color: getColor(color.substring(1)),
                                backgroundColor: color,
                                borderRadius: "0 8px 0 0",
                              }} className="namecolumnguideme"
                              align="left">
                              <div className={styles.tableHeadCellContainer}>
                                <div>
                                  Enabled
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            className={styles.tableRow}>
                            <TableCell align="left" width={"1%"}>
                              <b>Initial Enrolment Period</b>
                            </TableCell>
                            <TableCell align="left" width={"1%"}>
                              <span>
                                <Checkbox disabled={false} id="isCertyActive"
                                  size="small"
                                  color="primary"
                                  checked={initialEnrolmentEnable}
                                /></span>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={styles.tableRow}>
                            <TableCell align="left" width={"1%"}>
                              <b>Pre Expiry Reminder Emails</b>
                            </TableCell>
                            <TableCell align="left" width={"1%"}>
                              <span>
                                <Checkbox disabled={false} id="isCertyActive"
                                  size="small"
                                  color="primary"
                                  checked={preExpiryEnable}
                                /></span>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={styles.tableRow}>
                            <TableCell align="left" width={"1%"}>
                              <b>Post Expiry Reminder Emails</b>
                            </TableCell>
                            <TableCell align="left" width={"1%"}>
                              <span>
                                <Checkbox disabled={false} id="isCertyActive"
                                  size="small"
                                  color="primary"
                                  checked={postExpiryEnable}
                                /></span>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={styles.tableRow}>
                            <TableCell align="left" width={"1%"}>
                              <b> Manager Notifications</b>
                            </TableCell>
                            <TableCell align="left" width={"1%"}>
                              <span>
                                <Checkbox disabled={false} id="isCertyActive"
                                  size="small"
                                  color="primary"
                                  checked={managerEnable}
                                /></span>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </UnbrandedCard>
                  </div>
                </div>              
              </Typography>
            </AccordionDetails>
          </Accordion>
           }
           {(unitComplianceReport.length > 0 ) && isUnitRule &&
          <Accordion expanded={expandComplianceRuleList}
            style={{ margin: "15px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="managerSettings-content"
              id="compliance-header" onClick={(e) => handleExpandComplianceRulesListClick()}>
              <Typography className="typographyFonts"> <b>{allResourcesToTranslations.step5}</b>
              <div>
                <div  style={{ float: "right", width: "100%" , backgroundColor:'#ffdd40' , borderRadius:'5px' }}>
                <div style={{ float: "left", width: "24px", padding: "10px" }}><NotesIcon></NotesIcon>
                  </div>
                  <div style={{ float: "right", width: "97%" }}><p>
                    <span>{allResourcesToTranslations.compliancerulesupdatenotessummary}</span>
                  </p> </div>
                </div>
              </div> 
              </Typography>
            </AccordionSummary>            
            <AccordionDetails style={{ paddingTop: "0px", display: "block" }}>
              <Typography className="typographyFonts" style={{ display: "inline" }}>               
                  <><div style={{ width: '100%', clear: 'both' }}>
                    <div style={{ width: '50%', float: 'left', padding: '15px' }}>
                      <b>Abbreviations:</b>
                      <div style={{ width: '100%', clear: 'both' }}>
                        <div style={{ width: '50%', float: 'left' }}>OTCC: One time course completion  </div>
                        <div style={{ width: '50%', float: 'right' }}>LEI: Lesson expiry increment  </div>
                      </div>
                      <div style={{ width: '100%', clear: 'both' }}>
                        <div style={{ width: '50%', float: 'left' }}>LF: Lesson Frequency  </div>
                        <div style={{ width: '50%', float: 'right' }}>QEI: Quiz expiry increment  </div>
                      </div>
                      <div style={{ width: '100%', clear: 'both' }}>
                        <div style={{ width: '50%', float: 'left' }}>QF: Quiz Frequency  </div>
                        <div style={{ width: '50%', float: 'right' }}>&nbsp; </div>
                      </div>
                    </div>
                    <div style={{ width: '45%', float: 'right', paddingTop: '55px' }}>
                      <div
                        className={styles.paginationControlsContainer}
                        style={{ justifyContent: "center" }}
                      >
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 20, 100]}
                          component="div"
                          count={unitComplianceReport.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage} />
                      </div>
                    </div>
                  </div><div  className={`${styles.tableSection}`}>                      
                        <UnbrandedCard>
                          <TableContainer>
                            <Table className='compliancerulelist'>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                      borderRadius: "8px 0 0 0",
                                    }}
                                    align="left"
                                  > {"Course" /* TODO translate */}

                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="left"
                                  >
                                    {"Module" /* TODO translate */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="left"
                                  >
                                    {"Unit Pathway" /* TODO translate */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="center"
                                  >
                                    {"LF/Date" /* TODO translate */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="center"
                                  >
                                    {"QF/Date"}
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="center"
                                  >
                                    {"OTCC" /* TODO translate */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="center"
                                  >
                                    {"LEI" /* TODO translate */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="center"
                                  >
                                    {"QEI" /* TODO translate */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                    }}
                                    align="center"
                                  >
                                    {"Passmark %" /* TODO translate */}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: getColor(color.substring(1)),
                                      backgroundColor: color,
                                      borderRadius: "0 8px 0 0",
                                    }}
                                    align="center"
                                  >
                                    {"Default" /* TODO translate */}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {unitComplianceReport
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((result: UnitComplianceReportResultItem, i) => {
                                    return (
                                      <TableRow
                                        key={`UnitComplianceReportResult-${i}`}
                                        style={rowBackgroundColor(i)}
                                      >
                                        <TableCell align="left">{result.courseName}
                                        </TableCell>
                                        <TableCell align="left">{result.moduleName}</TableCell>
                                        <TableCell align="left"> {result.unitPathway}</TableCell>
                                        <TableCell align="center">
                                          {result.lessonExpiry}
                                        </TableCell>
                                        <TableCell align="center">
                                          {result.quizExpiry}
                                        </TableCell>
                                        <TableCell align="center">
                                          {result.enableOneTimeCourseComplition.toLowerCase() === "active" ?
                                            <span>
                                              <CustomTooltip displayText={["Active"]}>
                                                <ActiveIcon></ActiveIcon>
                                              </CustomTooltip>&nbsp;{result.enableOneTimeCourseComplition}
                                            </span>
                                            :
                                            <span>&nbsp;</span>}
                                        </TableCell>
                                        <TableCell align="center">
                                          {result.yearlyIncrementLesson}
                                        </TableCell>
                                        <TableCell align="center">
                                          {result.yearlyIncrementQuiz}
                                        </TableCell>
                                        <TableCell align="center">
                                          {result.quizPassmark.toString()}
                                        </TableCell>
                                        <TableCell align="center">
                                          {result.isUsingDefault}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </UnbrandedCard>
                      
                    </div></>                
              </Typography>
            </AccordionDetails>             
          </Accordion>
          }
        </div>
      </BrandedCard>
      <div style={{paddingTop:'10px'}}>&nbsp;</div>
      <BrandedCard>
        <div className={styles.reportWrraper} >
          <div onClick={(e) => { setIncludeWelcomeEmail(!includeWelcomeEmail); 
            setGuideMe(true,isCourseAdditionalRecourse,isRemEmails,isUnitRule);		
           }} style={{ float: 'left' }}>
            <span style={{ marginTop: "-3px", paddingRight: "5px", float: "left" }} >{includeWelcomeEmail ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
          </div>
          <div style={{ float: 'left' }}>
            <span id="allowLearnerswitch" className={styles.labelText}
              onClick={(e) => { setIncludeWelcomeEmail(!includeWelcomeEmail);  }}
            >
              &nbsp;&nbsp;<b>Welcome Email (Optional)</b></span>
          </div>
          {includeWelcomeEmail &&
            <><div className={styles.tableSection} style={{height:'470px'}}>
              <div className={styles.modalContentWrapper}>
                <div className="emailSubject" style={{ marginTop: "50px" }}>
                  <TextField
                    className={styles.input}
                    label={"Email Subject"}
                    style={{ width: "99%", marginBottom: "20px" }}
                    variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                    value={emailSubject}
                    onChange={handleEmailSubject}
                    error={emailIsValid}
                    placeholder="*This Field is required"
                  />
                </div>
                <div className="editorText" style={{ paddingTop: "10px", width: '99%', paddingBottom: '10px' }} >
                  <div style={{ width: '100%' }} >
                    <span></span>
                    {welcomeMessageBodyResult.map((result1: any, j) => {
                      if (j == 0) {
                        return (
                          <TinyMCEEditor index={j} key="tinyMCEEditor" editorData={result1.body} isTemplate={false} is200Tall={true} handleTextEditorChange={handleTextEditorChange} />
                        );
                      }
                    })}
                  </div>
                </div>
                <div className={styles.sendEmail}>                 
                  <div  style={{ float: 'left' }}>
                    <CustomTooltip displayText={["Reset"]}>
                      <span className="resetEmail">
                        <Button
                          onClick={resetAll}
                          type="submit"
                          variant="outlined"
                          color="primary"
                          size="small"
                          id="btnNewSaltResetReport"
                        >
                          <ResetIcon />
                        </Button>
                      </span>
                    </CustomTooltip>
                    <CustomTooltip displayText={["Send Email"]} >
                      <span className="sendEmail">
                        <Button
                          onClick={handleSendEmail}
                          disabled={emailSubject === ""}
                          variant="contained"
                          color="primary"
                          id="btnNewSaltSendEmailReport">
                          <SendEmailReportIcon />
                        </Button>
                      </span>
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </div></>}
        </div>
      </BrandedCard>
      <div style={{ float: 'right', paddingTop: '20px' }}>
        <CustomTooltip displayText={["Exit"]}>
          <span className="Exit" style={{ marginRight: "-5px" }}>
            <Button
              onClick={handleNext}
              variant="contained"
              color="primary"
              className="Exit">
              Exit
            </Button>
          </span>
        </CustomTooltip>
      </div>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};
export default CourseSummary;
