import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepThreeComplete = () => (    
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="#63C2FF"/>
<rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#63C2FF"/>
<path d="M15.68 27.88C15.68 27.7 15.66 27.62 15.58 27.62L15.12 27.84C15.12 27.74 15.06 27.68 14.96 27.64L14.8 27.62C14.66 27.62 14.6 27.64 14.4 27.76C14.34 27.64 14.26 27.5 14.2 27.38C13.68 26.38 13.14 24.88 12.9 24.22C12.78 23.88 12.66 23.18 12.52 22.12C12.68 22.22 12.8 22.26 12.86 22.26C12.94 22.26 13.04 22.12 13.12 21.84C13.16 21.9 13.24 21.92 13.34 21.92C13.4 21.92 13.48 21.9 13.52 21.84L13.84 21.36L14.2 21.48H14.22C14.26 21.48 14.32 21.42 14.42 21.36C14.52 21.3 14.6 21.26 14.66 21.26L14.72 21.28C15.04 21.44 15.26 21.72 15.36 22.16C15.6 23.18 15.82 23.68 16.08 23.68C16.3 23.68 16.6 23.42 16.92 22.92C17.24 22.42 17.56 21.74 17.92 20.92C17.94 21.08 17.96 21.16 18 21.16C18.12 21.16 18.42 20.46 19.16 19.28C20.26 17.5 22.98 14.08 23.68 13.6C24.2 13.24 24.6 12.9 24.88 12.6C24.84 12.8 24.8 12.94 24.8 13C24.8 13.06 24.84 13.08 24.88 13.08L25.44 12.8V12.88C25.44 12.98 25.46 13.04 25.52 13.04C25.6 13.04 25.92 12.72 25.96 12.6L25.92 12.88L26.6 12.48L26.44 12.84C26.64 12.7 26.8 12.62 26.9 12.62C27 12.62 27.06 12.78 27.06 12.88C27.06 13.04 26.92 13.26 26.7 13.54C26.46 13.86 25.86 14.48 24.06 16.54C23.28 17.42 19.82 22.12 19.16 23.24L17.92 25.34C17.38 26.24 17.04 26.82 16.86 27.04C16.68 27.26 16.46 27.48 16.2 27.68L16.02 27.58L15.86 27.68L15.68 27.88Z" fill="white"/>
</svg>



);

export default StepThreeComplete