//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { User, Language, UnitTreeItem, CourseTreeItem, CPDItem  } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from 'moment';
import { Steps, Hints } from "intro.js-react";
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import oidcConfig from '../../configuration';
//API Functions
import { getUnitTree } from '../../API/GetUnitTree';
import { getRelevantUnitTree } from '../../API/GetRelevantUnitTree';
import { getCourseListByOrganisation } from '../../API/GetCourseListByOrganisation'
import { getFullCurrentAdminReport } from '../../API/GetFullCurrentAdminReport'
import { getCourseStatusReport } from '../../API/GetCourseStatusReport' 
import { addError } from '../../API/AddError'
import { getClassificationListByType } from '../../API/GetClassificationListByType';
import getClassificationType from '../../API/GetClassificationType';
import getOrgCPDProfile from "../../API/GetOrgCPDProfile";
//Components
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard'
import UnitFilterButtons from '../CurrentAdminReport/UnitFilterButtons'
import CourseFilterButtons from '../CurrentAdminReport/CourseFilterButtons'
import CourseListTree from '../CurrentAdminReport/CourseListTree'
import UnitTree from '../CurrentAdminReport/UnitTree'
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
import ReportSchedulerModal from '../CurrentAdminReport/ReportSchedulerModal'
import CustomTooltip from "../../Components/CustomTooltip";
import GuideIcon from '../../CustomIcons/GuideIcon';
//import MUICustomSelect from '../../Components/MUICustomSelect';
//MUI Components
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from "@material-ui/icons/Search";
//Styles
import styles from '../../styles/currentAdminReport.module.css'
import { IconButton } from '@material-ui/core';
import CommonPopupModalForResult from "../../Components/CommonPopupModalForResult";


interface Props {
    
}

const SaltCertificatesReport = (props: Props) => {
    const [units, setUnits] = useState<Array<UnitTreeItem>>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([]) 
    const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree
    const [loading, setLoading] = useState<boolean>(true)
    const [isSmallUnitTree, setSmallUnitTree] = useState<boolean>(true);

    const [filterDateFrom, setFilterDateFrom] = useState("");
    const [filterDateTo, setFilterDateTo] = useState("");
    const [filterUnitName, setFilterUnitName]  = useState("");
    const [filterCourseName, setFilterCourseName]  = useState("");
    const [filterFirstName, setFilterFirstName]  = useState("");
    const [filterLastName, setFilterLastName] = useState("");
    const [filterUserName, setFilterUserName] = useState("");
    const [filterEmail, setFilterEmail] = useState("");
    const [filterIncludeInactive, setFilterIncludeInactive] = useState<boolean>(false);

    const [filterSubject, setFilterSubject] = useState("");
    const [filterBody, setFilterBody] = useState("");
    const [filterQuizStatus, setFilterQuizStatus] = useState("");
    const [filterSortBy, setFilterSortBy] = useState("0");
    const [filterExpiryValue, setFilterExpiryValue] = useState(0);
    const [filterExpiryUnit, setFilterExpiryUnit] = useState("d");
    const [filterAcceptance, setFilterAcceptance] = useState("0");
    const [filterOnlyUsersWithShortfall, setFilterOnlyUsersWithShortfall] = useState("N");
    const [filterLicensingPeriod, setFilterLicensingPeriod] = useState("");
    const [filterHistoricCourseIds, setFilterHistoricCourseIds] = useState("");
    const [filterPolicyIds, setFilterPolicyIds] = useState("");

    const [isCourseStatusFocussed, setIsCourseStatusFocussed] = useState<boolean>(false);
    const [isGroupingOptionFocussed, setIsGroupingOptionFocussed] = useState<boolean>(false);

    //const [classificationList, setClassificationList] = useState<Array<any>>([]);
    const [classificationList, setClassificationList] = useState([]);
    const [selectedClassification, setSelectedClassification] = useState<any>({});
    const [groupingOption, setGroupingOption] = useState<boolean>(false);
    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute, latestUserCPDs, selectedCPDIndex, IsCPDEnabled } = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();

    const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles} = useContext(TranslationsContext)
    const { showSSOExitButton, EnableActivityLogs} = useContext(UIContext);
    const { cpdList, currentCpd } = useContext(CPDPanelContext);
    const orgDomainWithAltLogin = window.location.host.toLowerCase();

 
    const [selectedProfileID, setSelectedProfileID] = useState<any>(-1);
    const [selectedProfilePeriodID, setSelectedProfilePeriodID] = useState<any>(-1);
    const [selectedCPDProfile, setSelectedCPDProfile] = useState<any>({});
    const [selectedCPDProfilePeriod, setSelectedCPDProfilePeriod] = useState<any>({});
    const [selectedCPDProfilePeriodList, setSelectedCPDProfilePeriodList] = useState<any>([]);
    const [orgCPDProfileResult, setOrgCPDProfileResult] = useState<any>(null);  
    const [selectedProfileName, setSelectedProfileName] = useState<string>("");
    const [selectedProfilePeriodName, setSelectedProfilePeriodName] = useState<string>("");
    const [defaultCPDProfile, setDefaultCPDProfile] = useState<any>({});
    const [defaultCPDProfilePeriod, setDefaultCPDProfilePeriod] = useState<any>({});
    // const [orgCPDListToPass, setOrgCPDListToPass] = useState<Array<any>>([]);  
    // const [orgCPDPeriodListToPass, setOrgCPDPeriodListToPass] = useState<Array<any>>([]);  
  
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".unitFilterButtons",
            title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,     
          },      
        ]
      })

    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);

    const setGuideMe = (groupingOption: boolean) => {     
            let updateSteps = [...intro.steps]
            updateSteps.push(       
                {
                    element: ".unitFilterSearch",
                    title: allResourcesToTranslations.saltcertrepfilterunitguidemetitle, intro: allResourcesToTranslations.unitfiltersearch               
                }
            );   
            if (units.length <= 10) {
                updateSteps.push(      
                {
                    element: ".courseFilterButtons",                   
                    title: allResourcesToTranslations.saltcertrepselectcourseguidemetitle, intro: allResourcesToTranslations.emrepgmcsm
                },
                {
                    element: ".courseFilterSearch",
                    title: allResourcesToTranslations.saltcertrepfiltercourseguidemetitle, intro: allResourcesToTranslations.emrepgmfcsm
                }
                ); 
            }
            else{
                updateSteps.push(      
                    {
                    element: ".courseFilterButtonsnew",                   
                    title: allResourcesToTranslations.saltcertrepselectcourseguidemetitle, intro: allResourcesToTranslations.emrepgmcsm
                    },
                    {
                    element: ".courseFilterSearchnew",
                    title: allResourcesToTranslations.saltcertrepfiltercourseguidemetitle, intro: allResourcesToTranslations.emrepgmfcsm
                }
                ); 
            }   
            updateSteps.push(
            {
                element:  ".dateFrom",
                title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.emrepgmfdfm
            },
            {
                element:  ".dateTo",
                title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.emrepgmfdtm
            },
            {
                element:  ".firstName",
                title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.firstnamemsg
            } ,
            {
                element:  ".lastName",
                title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.lastnamemsg
            } ,
            {
                element:  ".userName",
                title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.usernamemsg
            } ,
            {
                element:  ".email",
                title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.emailmsg
            } );
            if (IsCPDEnabled)
            {
                updateSteps.push({
                    element: ".cpdprofile",
                    title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.saltcertrepcpdprofilevalue
                });
                updateSteps.push({
                    element: ".cpdprofileperiod",
                    title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.saltcertrepcpdprofileprdvalue
                });
            }
            if (groupingOption)
            {
                updateSteps.push({
                    element: ".groupby",
                    title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.repgmgroupingoption
                });
            }
            updateSteps.push(
            {
                element:  ".includeInactive",
                title: allResourcesToTranslations.saltcertrepcommonguidemetitle, intro: allResourcesToTranslations.prrepgminactiveaccount
            },
            {
                element:  ".runReport",
                title: allResourcesToTranslations.saltcertrepreportguidemetitle, intro: allResourcesToTranslations.sntemrepgmmrunrep
            }, 
            {                    
                element:  ".resetAll",
                title: allResourcesToTranslations.saltcertrepresetfilterguidemetitle, intro: allResourcesToTranslations.emrepgmram
            }         
        )
        setIntro({...intro, steps: [...updateSteps]})              
    };

    const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
    };
      

    const updateSelectedUnit = (id: number) => {
        let updatedUnits = units
        const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isSelected = !unit.isSelected
                } 
                updateSelected(unit.childUnitTreeItems)
            }
        }
        updateSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const updateExpandedUnit = (id: number) => {
        let updatedUnits = units
        const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isExpanded = !unit.isExpanded
                } 
                checkForExpanded(unit.childUnitTreeItems)
            }
        }
        checkForExpanded(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = true;
                }
                updateAllChildUnits(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnits(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnitsToUnselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = false;
                }
                updateAllChildUnitsToUnselected(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnitsToUnselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
        let updatedUnits = givenUnits
        const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return false //Break if no children
            if (unitArray.length === 0) return false;
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected) {
                    return true;
                }
                if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
                    return true;
                }
            }
            return false;
        }
        return checkIfAnyUnitSelected(updatedUnits);
    }

    const expandAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = true;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const colapseAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (unit.isExpanded) unit.isExpanded = false;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllUnits = () => {
        let updatedUnits = units
        const setAllSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
          	        if (!unit.isDisabled) {
              		    unit.isSelected = true;
	  	            }
                    setAllSelected(unit.childUnitTreeItems);
                }
            }
        }
        setAllSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllUnits = () => {
        let updatedUnits = units
        const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = false;
                    }
                    setAllDeselected(unit.childUnitTreeItems);
                }
            }
        }
        setAllDeselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const handleClosePopup = () => {
      setShowWarningModal(false);
    };

    const updateSelectedCourse = (id: number) => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (updatedCourses[i].courseID === id) {
                updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const selectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = true;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const deselectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = false;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const resetAll = () => {
        deselectAllCourses();
        deselectAllUnits();
        setFilterFirstName('');
        setFilterLastName('');
        setFilterUserName('');
        setFilterEmail('');
        setFilterDateFrom('');
        setFilterDateTo('');
        setSelectedClassification({});
        setFilterIncludeInactive(false);
    }

    const getValidClassificationID = (selectedClassification: any) => {
        var classID : number = 0;
        if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
            classID = ((typeof selectedClassification.classificationID) !== 'number') ? 
                parseInt(selectedClassification.classificationID.toString()) : 
                selectedClassification.classificationID;
        }
        return classID;
    }

    const runReport = () => {
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let courseIds: Array<number> = []
        const firstName: string = oidcUser.profile.given_name ?? ""
        const lastName: string = oidcUser.profile.family_name ?? ""
        const email: string = oidcUser.profile.email ?? ""
        const userName: string = oidcUser.profile.user_name ?? ""
        const date = new Date()
        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate()+1);
        const dateString = `${date.getUTCFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}` 
        const nextDateString = formatDate(nextDate); //`${nextDate.getUTCFullYear()}/${("0" + (nextDate.getUTCMonth() + 1)).slice(-2)}/${('0' + (nextDate.getUTCDate())).slice(-2)}` 
        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                if (filterUnitName !== "") {
                    if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    unitIds.push(unit.unitID)
                    }
                } else {
                    unitIds.push(unit.unitID)
                }
                }
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems)
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {
            for (let i = 0; i < courseList.length; i++) {
                if (courseList[i].isSelected) {
                    courseIds.push(courseList[i].courseID)
                }
                allCourseIds.push(courseList[i].courseID);           
            }
        }
        getSelectedCourses()
        setMessage("Fetching your report");
        setLoading(true)

        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CourseStatus Report in NEWSALT ClientApp", null, "SaltCertificatesReport.tsx", 6, EnableActivityLogs);
        getCourseStatusReport(oidcUser.access_token,
                userId, 
                orgDomain, 
                parseInt(orgId), 
                unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
                courseIds.length > 0 ? courseIds.toString() : allCourseIds.toString(), 
                firstName, 
                lastName, 
                userName, 
                email, 
                dateString,
                "",
                "",
                filterIncludeInactive,
                getValidClassificationID(selectedClassification),
                "1", //Always 1 for complete for this report
                ((filterDateFrom === null || filterDateFrom === undefined || filterDateFrom.trimLeft().trimRight() === "") ? "1996-12-31" :  filterDateFrom),
                ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? nextDateString : filterDateTo),
                filterFirstName, filterLastName, filterUserName, filterEmail, true
                 ).then((result) => {
                    setLoading(false)
                    history.push({
                        pathname: `saltcertificatesreport/result`,
                        state: {
                            inputFilterFirstName: filterFirstName,
                            inputFilterLastName: filterLastName,
                            inputFilterUserName: filterUserName,
                            inputFilterEmail: filterEmail,
                            filterCourseStatus: 1,
                            inputFilterIncludeInactive: (filterIncludeInactive ? "Yes" : "No"),               
                            resultItems: (result === null ? null : result.coursestatusreportItem),
                            resultError: ((result === null || result === undefined || result.isSuccess || result.error === null || result.error === undefined) ? "" : result.error),
                            selectedCPDProfileId: !!selectedProfileID && parseInt(selectedProfileID) > 0 ? parseInt(selectedProfileID) : -1,
                            selectedCPDProfilePeriodId: !!selectedProfilePeriodID && parseInt(selectedProfilePeriodID) > 0 ? parseInt(selectedProfilePeriodID) : -1,
                            selectedProfileAndPeriodName: !!selectedProfileID && !!selectedProfilePeriodID ? `${selectedProfileName} (${selectedProfilePeriodName})` : "",
                        },
                    });
                })
    }

    const parseAdminConsoleUrl = (url: string): string => {
        if (url === "https://" + oidcConfig.stagingdomain || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomain + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainSA || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainSA + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainForDeactTest || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainForDeactTest + ":444";
        }  else {
            if (showSSOExitButton && orgDomainWithAltLogin.toLowerCase().indexOf("altlogin-") < 0) {
                if (url.toLowerCase().includes('https:')) {
                    return `${url}:444`;
                }
                else if  (url.toLowerCase().includes('http:')) {
                    var locUrl = url.toLowerCase().replace("http:", "https:")
                    return `${locUrl}:444`;
                }
                else {
                    return `https://${url}:444`;
                }
            }
            else {
                return `${url}:444/altlogin.aspx`;
            }
        }
    }

    const setModalOpen = () => 
    {        
        let cpdProfileId = -1
        cpdProfileId = (
            (cpdList !== null && 
            cpdList !== undefined && 
            cpdList.length > 0 && 
            currentCpd >= 0 && 
            cpdList.length > currentCpd) ? 
            cpdList[currentCpd].id : -1)
        history.push({
            pathname: `/CSR/reportscheduler/-1`,
            state: {
                selectedUnits: units,
                selectedCourses: courseList,
                firstName: filterFirstName,
                lastName: filterLastName,
                userName: filterUserName,
                email: filterEmail,
                isIncludeInactive: filterIncludeInactive,
                classificationList: classificationList,
                groupingOption: groupingOption,
                selectedClassification: selectedClassification,
                groupBy: ((selectedClassification === null || selectedClassification === undefined || 
                    selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value),
                selectedClassificationID: getValidClassificationID(selectedClassification),
                dateFrom: filterDateFrom,
                dateTo: filterDateTo,
                subject: filterSubject,
                body: filterBody,
                status: 1,
                quizStatus: filterQuizStatus,
                sortBy: filterSortBy,
                langCode: currentLanguage,
                expiryValue: filterExpiryValue,
                expiryUnit: filterExpiryUnit,
                profileID: cpdProfileId,
                acceptance: filterAcceptance,
                onlyUsersWithShortfall: filterOnlyUsersWithShortfall,
                licensingPeriod: filterLicensingPeriod,
                reportId: 19,  // 19 is for CSR
                historicCourseIds: filterHistoricCourseIds, 
                policyIds: filterPolicyIds
            },
        }); 
    }

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (!isNaN(givenDate.getTime())) {
            const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
            const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
            const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);
    
            return (year.toString() + "-" + 
            (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
            (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
        }
        else {
            return "";
        }
    }

    const isGivenItemNameMatched = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        if (item === null || item === undefined || item.name === null || item.name === undefined) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
                return true;
            }
            return false;
        }
    }

    const isAnyChildItemNameMatched = (list: UnitTreeItem[] | null, givenFilterUnitName: string): boolean | undefined =>
    {
        if (list === null || list === undefined || list.length <= 0) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            var newList = list.filter((e) => { return (isGivenItemNameMatched(e, givenFilterUnitName) === true || isAnyChildItemNameMatched(e.childUnitTreeItems, givenFilterUnitName) === true); });
            if (newList !== null && newList !== undefined && newList.length > 0) {
                return true;
            }
            return false;
        }
    }

    const updateUnitItemBasedOnNameMatch = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        let isMatchFound = false;
        if ((givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") && 
            item !== null && item !== undefined) {
            isMatchFound = true;
            item.isDisabled = !isMatchFound;
            item.isHidden = !isMatchFound;
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        else if (givenFilterUnitName !== null && givenFilterUnitName !== undefined && givenFilterUnitName.trimLeft().trimRight() !== "" && 
            item !== null && item !== undefined) {
                
            isMatchFound = (item.name !== null && item.name !== undefined && item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase()));
            if (isMatchFound) {
                item.isDisabled = false;
                item.isHidden = false;
            }
            else if (!isMatchFound && (item.childUnitTreeItems == null || item.childUnitTreeItems === undefined || item.childUnitTreeItems.length <= 0)){
                item.isDisabled = true;
                item.isHidden = true;
            }
            else if (!isMatchFound){
                let curAnyChildNameMatched = isAnyChildItemNameMatched(item.childUnitTreeItems, givenFilterUnitName);
                item.isDisabled = (curAnyChildNameMatched === true);
                item.isHidden = (curAnyChildNameMatched === false);
                isMatchFound = (curAnyChildNameMatched === true);
            }
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        return isMatchFound;
    }

    const updateCurrentProfileSelection = (givenSelectedCPDProfile: any) => {
      
      
      let curProfObj = {
        value: -1, 
        profileID: -1,
        text: '', 
        profileName: '',
        profilePeriodItems: []
      }
      
      if (!!givenSelectedCPDProfile && !!givenSelectedCPDProfile.profileID && !!givenSelectedCPDProfile.profileName) {
        setSelectedProfileID(givenSelectedCPDProfile.profileID);
        setSelectedProfileName(givenSelectedCPDProfile.profileName);
        curProfObj.value = givenSelectedCPDProfile.profileID;
        curProfObj.profileID = givenSelectedCPDProfile.profileID;
        curProfObj.text = givenSelectedCPDProfile.profileName;
        curProfObj.profileName = givenSelectedCPDProfile.profileName;
      }
      if (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems && !!givenSelectedCPDProfile.profileID) {
        var filtered = orgCPDProfileResult.cpdProfileItems.filter((item:any) => item.profileID.toString() === givenSelectedCPDProfile.profileID.toString());
        let ppList = (!!filtered === false || filtered.length <= 0 || !!filtered[0].profilePeriodItems === false) ? null : filtered[0].profilePeriodItems;
        setSelectedCPDProfilePeriodList(ppList);
        curProfObj.profilePeriodItems = ppList;
        let pname = (!!filtered === false || filtered.length <= 0 || !!filtered[0].profileName === false) ? "" : filtered[0].profileName;
        setSelectedProfileName(pname);
        curProfObj.text = pname;
        curProfObj.profileName = pname;
        //setSelectedCPDProfile(curProfObj);
        if (!(!!filtered === false || filtered.length <= 0 || !!filtered[0] === false || !!filtered[0].profileName === false)) {
          updateCurrentProfilePeriodSelection({value: filtered[0].profilePeriodItems[0].profilePeriodID, profilePeriodID: filtered[0].profilePeriodItems[0].profilePeriodID,
          text: filtered[0].profilePeriodItems[0].profilePeriodName, profilePeriodName: filtered[0].profilePeriodItems[0].profilePeriodName});
        }
      }
      else {
        setSelectedCPDProfilePeriodList(null);
        setSelectedProfileName("");
        curProfObj.profilePeriodItems = [];
        curProfObj.text = "";
        curProfObj.profileName = "";
        //setSelectedCPDProfile(curProfObj);
      }
    }
  
    const updateCurrentProfilePeriodSelection = (givenSelectedCPDProfilePeriod: any) => {
      if (!!givenSelectedCPDProfilePeriod !== true || !!givenSelectedCPDProfilePeriod.profilePeriodID !== true) {
        setSelectedProfilePeriodName("");
        return;
      }
      setSelectedProfilePeriodID(givenSelectedCPDProfilePeriod.profilePeriodID);
      if (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems) {
        var filtered = orgCPDProfileResult.cpdProfileItems.filter((item:any) => item.profileID.toString() === selectedCPDProfile.profileID.toString());
        if (!!filtered === false || filtered.length <= 0 || !!filtered[0].profilePeriodItems === false || !!givenSelectedCPDProfilePeriod.profilePeriodID === false) {
          setSelectedProfilePeriodName("")
        }
        else {
          var matchedPP = filtered[0].profilePeriodItems.filter((pp:any) => pp.profilePeriodID.toString() === givenSelectedCPDProfilePeriod.profilePeriodID.toString());
          if (!!matchedPP && matchedPP.length > 0) {
            setSelectedProfilePeriodName(matchedPP[0].profilePeriodName)
          }
          else {
            setSelectedProfilePeriodName("")
          }
        }                      
      }
      else {
        setSelectedProfilePeriodName("");
      }
      // let firstPPObj = {
      //   value: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodID, 
      //   profilePeriodID: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodID,
      //   text: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName, 
      //   profilePeriodName: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName
      // }
    }

    const handleChange = (e: any) => {
        if (!!e === false || !!e.target === false || !!e.target.value === false) {
            return;
        }
        const profID = e.target.value.toString();
        const matched = orgCPDProfileResult?.cpdProfileItems?.filter((cur: any) => !!cur && parseInt(cur.profileID) === parseInt(profID));
        if (!!matched === false || matched.length <= 0) {
            return;
        }
        const curSel = {
            value: e.target.value,
            profileID: e.target.value,
            profileName: matched && matched[0] ? matched[0].profileName : '',
        };
        setSelectedCPDProfile(curSel);
        updateCurrentProfileSelection(curSel);
    };

    useEffect(() => {
        setTitle(allResourcesToTranslations.saltcertificatesreport)
        setTitleEnglish(pageTitles.saltcertificatesreport)
        updateBackButtonRoute("");
        //getUnitTree(oidcUser.access_token, userId, orgDomain, true, "A", parseInt(orgId)).then((unitTreeRes) => {
        getRelevantUnitTree(oidcUser.access_token, userId, orgDomain, true, parseInt(orgId)).then((unitTreeRes) => {
            if (unitTreeRes.isSuccess) {
                setUnits(unitTreeRes.unitTreeItems)
                setSmallUnitTree(unitTreeRes.unitTreeItems === null || unitTreeRes.unitTreeItems.length <= 10);
            }
            getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
                if (courseListRes.isSuccess) {
                    setCourseList(courseListRes.courselistItem)
                }
                
                getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {
                   
                    if(classType !== null && classType.classificationTypeIDs !== null && 
                       classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                        getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {
                            
                            if (data !== null && data.classificationlistItem !== null) {
                                setClassificationList(data.classificationlistItem)
                                setGroupingOption(true)
                                units.length = unitTreeRes.unitTreeItems.length ;  
                                setGuideMe(true)
                            }
                            else {
                                setGroupingOption(false)
                            }
                        })
                    }
                    else {
                        setGroupingOption(false)
                        
                    }

                    //Collect CPDs
                    getOrgCPDProfile(
                        oidcUser.access_token,
                        userId,
                        orgDomain,
                        parseInt(orgId)).then(
                          (orgCPDProfRes) => {
                            if (orgCPDProfRes.isSuccess){
                              setOrgCPDProfileResult(orgCPDProfRes);
                              if (!!orgCPDProfRes === false || !!orgCPDProfRes.cpdProfileItems === false || orgCPDProfRes.cpdProfileItems.length <= 0) {
                                setSelectedCPDProfile(null);
                                setSelectedCPDProfilePeriod(null);
                              }
                              else {
                                let firstPObj = {
                                  value: orgCPDProfRes.cpdProfileItems[0].profileID, 
                                  profileID: orgCPDProfRes.cpdProfileItems[0].profileID,
                                  text: orgCPDProfRes.cpdProfileItems[0].profileName, 
                                  profileName: orgCPDProfRes.cpdProfileItems[0].profileName,
                                  profilePeriodItems: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems
                                }
                                let firstPPObj = {
                                  value: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodID, 
                                  profilePeriodID: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodID,
                                  text: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName, 
                                  profilePeriodName: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName
                                }

                                if (!!latestUserCPDs === false || latestUserCPDs.length <= 0 || selectedCPDIndex < 0) {
                                    setSelectedCPDProfile(firstPObj);
                                    setDefaultCPDProfile(firstPObj);
                                    setSelectedCPDProfilePeriod(firstPPObj);
                                    setDefaultCPDProfilePeriod(firstPPObj);
                                }
                                else {
                                    let selectedCPDProfileID = latestUserCPDs[selectedCPDIndex].id;
                                    let selectedCPDProfilePeriodID = latestUserCPDs[selectedCPDIndex].periodID;
                                    if (!!selectedCPDProfileID && !!selectedCPDProfilePeriodID) {
                                        let selectedCPDProfileCur = orgCPDProfRes.cpdProfileItems.filter((item:any) => item.profileID.toString() === selectedCPDProfileID.toString());
                                        let selectedCPDProfilePeriodCur = selectedCPDProfileCur[0].profilePeriodItems.filter((pp:any) => pp.profilePeriodID.toString() === selectedCPDProfilePeriodID.toString());
                                        if (!!selectedCPDProfileCur && selectedCPDProfileCur.length > 0 && !!selectedCPDProfilePeriodCur && selectedCPDProfilePeriodCur.length > 0) {
                                            let selPObj = {
                                                value: selectedCPDProfileCur[0].profileID, 
                                                profileID: selectedCPDProfileCur[0].profileID,
                                                text: selectedCPDProfileCur[0].profileName, 
                                                profileName: selectedCPDProfileCur[0].profileName,
                                                profilePeriodItems: selectedCPDProfileCur[0].profilePeriodItems
                                              }
                                            setSelectedCPDProfile(selPObj);
                                            setDefaultCPDProfile(selPObj);
                                            setSelectedCPDProfilePeriod(selectedCPDProfilePeriodCur[0]);
                                            setDefaultCPDProfilePeriod(selectedCPDProfilePeriodCur[0]);
                                        }
                                        else {                                      
                                            setSelectedCPDProfile(firstPObj);
                                            setDefaultCPDProfile(firstPObj);
                                            setSelectedCPDProfilePeriod(firstPPObj);
                                            setDefaultCPDProfilePeriod(firstPPObj);
                                        }
                                    }
                                    else {                                      
                                        setSelectedCPDProfile(firstPObj);
                                        setDefaultCPDProfile(firstPObj);
                                        setSelectedCPDProfilePeriod(firstPPObj);
                                        setDefaultCPDProfilePeriod(firstPPObj);
                                    }
                                }
                              }
                              setLoading(false);
                            }
                          }
                        ); 

                    setLoading(false);
                    units.length = unitTreeRes.unitTreeItems.length ;  
                    setGuideMe(groupingOption);
                })                               
            })
        })
    },[])     

    useEffect(() => {
        updateCurrentProfileSelection(selectedCPDProfile);
    }, [selectedCPDProfile]);

    useEffect(() => {
        updateCurrentProfilePeriodSelection(selectedCPDProfilePeriod);
    }, [selectedCPDProfilePeriod]);

    useEffect(() => {
    },[filterDateFrom]) 

    useEffect(() => {
           if (units !== null && units !== undefined && units.length > 0) {
            var newUnits = units.map(function(e) {  
                // const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && findUnitItemByText(e, filterUnitName.toLowerCase())); 
                //     e.isHidden = retFlag;
                //     e.isDisabled = retFlag;
                const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())); 
                return e;
            });
            setUnits(newUnits);
        }
    },[filterUnitName]) 

    useEffect(() => {
          if (courseList !== null && courseList !== undefined && courseList.length > 0) {
            var newCourses = courseList.map(function(e) { 
                if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === ""){
                    e.isHidden = false; 
                }
                else {
                    e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase())); 
                }
                return e;
            });
            setCourseList(newCourses);
        }
    },[filterCourseName]) 

    if (loading) return <LoaderContainer/>

    return (
        <>
          <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep} onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
            }} />      
            <CustomTooltip displayText={["Guide Me"]}>               
                <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                        <GuideIcon />
                    </span>
            </CustomTooltip>     
            <BrandedCard>                     
                <div className={styles.reportWrraper}>
                <div className={styles.unittreesection}>
                        <div>
                            <h4 className={styles.sectionTitle}>Unit</h4>
                            <p className={styles.blankContent}>Leave blank for all Units</p>
                            <div className="unitFilterButtons"><UnitFilterButtons expandAllUnits={expandAllUnits} colapseAllUnits={colapseAllUnits} selectAllUnits={selectAllUnits} deselectAllUnits={deselectAllUnits}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="unitFilterSearch"><TextField  className={styles.input}  label={"Unit Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterUnitName} onChange={(e) => setFilterUnitName(e.target.value)} 
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.treeContianer}>
                            <UnitTree units={units} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} />
                        </div>
                         <div hidden={isSmallUnitTree === false}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div className="courseFilterButtons"><CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === false}>
                            <div className="courseFilterSearch"> <TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}           
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === false}>                        
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltCSR_" />
                        </div> 
                </div>
                <div className={styles.coursetreesection}>
                        <div hidden={isSmallUnitTree === true}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div id="idcourseFilterButtonsnew" className="courseFilterButtonsnew"> <CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === true}>
                            <div id="idcourseFilterSearchnew" className="courseFilterSearchnew"><TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>                       
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === true}>
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltCSR_" />
                        </div>                     
                        <div className={styles.inputContainer}>
                            <div className="dateFrom"><TextField className={styles.input} label={"Date From"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            type="date" defaultValue={filterDateFrom} onChange={(e) => setFilterDateFrom(formatDate(new Date(e.target.value)))}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="dateTo"><TextField  className={styles.input}  label={"Date To"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="firstName"><TextField  className={styles.input}  label={"First Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterFirstName} onChange={(e) => setFilterFirstName(e.target.value)}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="lastName"><TextField   className={styles.input}  label={"Last Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            value={filterLastName} onChange={(e) => setFilterLastName(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="userName"><TextField  className={styles.input}  label={"Username"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            value={filterUserName} onChange={(e) => setFilterUserName(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="email"><TextField  className={styles.input}  label={"Email"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} /></div>
                        </div> 
                        {IsCPDEnabled &&
                        !!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems && orgCPDProfileResult.cpdProfileItems.length > 0 &&
                        <>
                            <div className="cpdprofile">
                                <div className={styles.groupingOption} >
                                    <FormControl variant="outlined" className={styles.formControl}>                      
                                        <InputLabel id="cpdProfileID">CPD Profile</InputLabel>
                                            <Select
                                            value={!!selectedCPDProfile && !!selectedCPDProfile.value ? selectedCPDProfile.value : ""}
                                            native
                                            onChange={(e) => handleChange(e)}
                                            className={styles.input}
                                            variant="outlined" 
                                            label="CPD Profile"
                                            id="cpdProfileID"
                                            >
                                            {orgCPDProfileResult.cpdProfileItems.map((item:any) => {
                                                return <option key={"ProfSaltCertificatesReport" + item.profileID.toString()} value={item.profileID}>{item.profileName}</option>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {!!selectedCPDProfilePeriodList && selectedCPDProfilePeriodList.length > 0 &&
                            <div className="cpdprofileperiod">
                                <div className={styles.groupingOption} >
                                    <FormControl variant="outlined" className={styles.formControl} >                      
                                        <InputLabel id="cpdProfilePeriodID">CPD Profile Period</InputLabel>
                                            <Select
                                            value={!!selectedCPDProfilePeriod && !!selectedCPDProfilePeriod.value ? selectedCPDProfilePeriod.value : ""}
                                            native
                                            onChange={(e : any) => 
                                                {
                                                    if (!!e === false || !!e.target === false || !!e.target.value === false) {
                                                        return;
                                                    }
                                                    let profPrdID : string = e.target.value.toString();
                                                    let matched = 
                                                        !!selectedCPDProfilePeriodList ? 
                                                            selectedCPDProfilePeriodList.filter((cur : any) => !!cur && parseInt(cur.profilePeriodID) === parseInt(profPrdID)) 
                                                            : 
                                                            null;
                                                    if (!!matched === false || matched.length <= 0) {
                                                        return;
                                                    }
                                                    var curSel = {value: e.target.value, 
                                                                profilePeriodID: e.target.value, 
                                                                profilePeriodName: (!!matched && !!matched[0]) ? matched[0].profilePeriodName : ""};
                                                    setSelectedCPDProfilePeriod({value: e.target.value, profilePeriodID: e.target.value});
                                                    updateCurrentProfilePeriodSelection(curSel);
                                                }}
                                            className={styles.input}
                                            variant="outlined" 
                                            label="CPD Profile Period"
                                            id="cpdProfilePeriodID"
                                            >
                                            {
                                                selectedCPDProfilePeriodList.map((item:any) => { 
                                                    return <option key={"ProfPrdSaltCertificatesReport" + item.profilePeriodID.toString()} value={item.profilePeriodID}>{item.profilePeriodName}</option>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        }
                        </>}
                        {groupingOption && 
                          <div className="groupby">
                            <div className={styles.groupingOption}>
                                <FormControl variant="outlined" className={styles.formControl}>                        
                                    <InputLabel id="grouping">Grouping Option</InputLabel>
                                        <Select
                                        value={selectedClassification.value}
                                        native
                                        onChange={(e) => setSelectedClassification({value: e.target.value, classificationID: e.target.value})}
                                        className={styles.input}
                                        variant="outlined" 
                                        label="Grouping Option"
                                        id="grouping"
                                        >
                                        {classificationList.map((item:any) => {
                                            return <option key={"SaltCertificatesReportClassification" + item.value} value={item.value}>{item.text}</option>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                           </div>
                        }
                        <div className={`${styles.inactiveContainer} includeInactive`}>
                            <div>
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={filterIncludeInactive}
                                        className={styles.checkBox}
                                        onClick={() => setFilterIncludeInactive(!filterIncludeInactive)}
                                /> {"Include Inactive Accounts"}  
                            </div> 
                        </div>

                        <div>
                            <ButtonPanel openReportSchedulerModal={() => setModalOpen()} runReport={runReport}  resetAll={resetAll}/>
                        </div>
                    </div>    
                </div>
            </BrandedCard>
            {/* showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />*/}
            {/* <ReportSchedulerModal showModal={showModal} setModalOpen={setModalOpen} /> */}
        </>
    )
}

export default SaltCertificatesReport
