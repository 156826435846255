import axios from 'axios';
import { addError } from './AddError';

export const getRelevantUnitTree = async (token: string, userId: number, orgDomain: string, includeInactive: boolean, orgId: number) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/userreport/GetRelevantUnitTree`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        UserID: userId,
        OrgID: orgId,
        IncludeInactive: includeInactive
      },
    });
    const data = await response.data;

    // data.unitTreeItems.sort((a: any,b: any) => {
    //    if (a.name > b.name) return 1
    //    if (a.name < b.name) return -1
    //    return 0 
    // })

    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GetRelevantUnitTree.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GetRelevantUnitTree.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GetRelevantUnitTree.ts", 1)
    }
    return null;
  }
};

export default getRelevantUnitTree;
