import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepFourInactive = () => (    
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#21394F"/>
<path d="M25.1035 24.041H23.3848V27H20.4355V24.041H14.3418V21.9414L20.6016 12.7227H23.3848V21.6973H25.1035V24.041ZM20.4355 21.6973V19.2754C20.4355 19.0475 20.4388 18.7773 20.4453 18.4648C20.4583 18.1523 20.4714 17.8398 20.4844 17.5273C20.4974 17.2148 20.5104 16.9382 20.5234 16.6973C20.543 16.4499 20.556 16.2773 20.5625 16.1797H20.4844C20.3607 16.4466 20.2305 16.707 20.0938 16.9609C19.957 17.2083 19.7975 17.4688 19.6152 17.7422L16.998 21.6973H20.4355Z" fill="#21394F"/>
</svg>

    
);

export default StepFourInactive