// React + Typescript
import React, { useContext, useEffect, useState } from 'react';

// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';

 // Global Types
 import { LearningModule, OrgBasicData } from "../../globalTypes";

// Contexts
import { UIContext } from '../../contexts/UIContext';
import { SessionContext } from '../../contexts/SessionContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';

// API Functions
import { addError } from '../../API/AddError';
import { getAllOrgs } from '../../API/SuperAdmin/GetAllOrgs';

// Functions
import { createGuid } from '../../../src/functions/generateGuid'

//Components
import CustomTooltip from '../../Components/CustomTooltip';
import SideMenuCloseButton from './SideMenuCloseButton';
import SideMenuOption from './SideMenuOption';
import SideMenuAdmin from './SideMenuAdmin';
import SideMenuOptionExpandable from './SideMenuOptionExpandable';
import SideMenuReports from './SideMenuReports';
import SideMenuDivider from './SideMenuDivider'

// MUI Components
import { IconButton, FormControl, Select, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

// Stlyes
import styles from '../../styles/sideMenu.module.css';

// Icons
import DashboardIconMenu from '../../CustomIcons/NavigationMenu/DashboardIconMenu';
import MyTrainingMenu from '../../CustomIcons/NavigationMenu/MyTrainingIconMenu';
import MyReportIconMenu from '../../CustomIcons/NavigationMenu/MyReportIconMenu';
import ModifyLinkIconMenu from '../../CustomIcons/NavigationMenu/ModifyLinkIconMenu';
import ManageTipVideosIconMenu from '../../CustomIcons/NavigationMenu/ManageTipVideosMenuIcon';
import AccountSettingIconMenu from '../../CustomIcons/NavigationMenu/AccountSettingIconMenu';
import AddIconMenu from '../../CustomIcons/NavigationMenu/AddIconMenu';
import ManagementIconMenu from '../../CustomIcons/NavigationMenu/ManagementIconMenu';
import ManagementDeactiveMenuIcon from '../../CustomIcons/NavigationMenu/ManagementDeactiveMenuIcon';
import ImportIconMenu from '../../CustomIcons/NavigationMenu/ImportIconMenu';
import MoveIconMenu from '../../CustomIcons/NavigationMenu/MoveIconMenu';
import OrgSettingsIconMenu from '../../CustomIcons/NavigationMenu/OrgSettingsIconMenu';
import UnitSettingsIconMenu from '../../CustomIcons/NavigationMenu/UnitSettingsIconMenu';
import AdminIconMenu from '../../CustomIcons/NavigationMenu/AdminIconMenu';
import ConfigItemIconMenu from '../../CustomIcons/NavigationMenu/ConfigItemIconMenu';
import ComplianceRulesIconMenu from '../../CustomIcons/NavigationMenu/ComplianceRulesIconMenu';
import SingleSignOnIconMenu from '../../CustomIcons/NavigationMenu/SingleSignOnIconMenu';
import ThemeIconMenu from '../../CustomIcons/NavigationMenu/ThemeIconMenu';
import SupportDetailsIconMenu from '../../CustomIcons/NavigationMenu/SupportDetailIconMenu';
import EmailIconMenu from '../../CustomIcons/NavigationMenu/EmailIconMenu';
import ReminderEmailRuleIconMenu from '../../CustomIcons/NavigationMenu/ReminderEmailRuleIconMenu';
import ProgressIconMenu from '../../CustomIcons/NavigationMenu/ProgressIconMenu';
import SentEmailIconMenu from '../../CustomIcons/NavigationMenu/SentEmailIconMenu';
import SummaryReportIconMenu from '../../CustomIcons/NavigationMenu/SummaryReportIconMenu';
import QuizHistoryIconMenu from '../../CustomIcons/NavigationMenu/QuizHistoryIconMenu';
import WarningReportIconMenu from '../../CustomIcons/NavigationMenu/WarningReportIconMenu';
import AtRiskReportIconMenu from '../../CustomIcons/NavigationMenu/AtRiskReportIconMenu';
import TemplateIconMenu from '../../CustomIcons/NavigationMenu/TemplateIconMenu';
import UnitComplianceIconMenu from '../../CustomIcons/NavigationMenu/UnitComplianceIconMenu';
import PolicyBuilderIconMenu from '../../CustomIcons/NavigationMenu/PolicyBuilderIconMenu';
import LicenseReportMenuIcon from '../../CustomIcons/NavigationMenu/LicenseReportMenuIcon';
import CpdProfileIconMenu from '../../CustomIcons/NavigationMenu/CpdProfileIconMenu';
import ArchiveIconMenu from '../../CustomIcons/NavigationMenu/ArchiveIconMenu';


import PlayerSectionCompletionIcon from '../../CustomIcons/PlayerSectionCompletionIcon';
import PlayerSectionIncompleteIcon from '../../CustomIcons/PlayerSectionIncompleteIcon';
import PlayerSectionProgressIcon from '../../CustomIcons/PlayerSectionProgressIcon';
import ExternalCertificateMenuIcon from '../../CustomIcons/NavigationMenu/ExternalCertificateMenuIcon';
import AssignCoursesIconMenu from '../../CustomIcons/NavigationMenu/AssignCoursesIconMenu';
import { LeakRemoveTwoTone } from '@material-ui/icons';
import getUser from '../../API/GetUser';

import oidcConfig from '../../configuration';
import SaltCertificatesReportIcon from '../../CustomIcons/NavigationMenu/SaltCertificatesReportIcon';
import SaltCertificatesReportIconMenu from '../../CustomIcons/NavigationMenu/SaltCertificatesReportIconMenu';
import NewsfeedMenuIcon from '../../CustomIcons/NavigationMenu/NewsfeedMenuIcon';
import MyNewsfeedIcon from '../../CustomIcons/NavigationMenu/MyNewsfeedIcon';
import MyNewsfeedMenuIcon from '../../CustomIcons/NavigationMenu/MyNewsfeedMenuIcon';
import CourseSettingsIconMenu from '../../CustomIcons/NavigationMenu/CourseSettingsIconMenu';
import PublishContentIconMenu from '../../CustomIcons/NavigationMenu/PublishContentIconMenu';
import APILibraryIconMenu from '../../CustomIcons/NavigationMenu/APILibraryIconMenu';
import { useHistory } from 'react-router-dom';


const SideMenu = (props: any): JSX.Element => {
	const [ assignReportOnlyUnitAdmin, setassignReportOnlyUnitAdmin ] = useState(false)
	const { isMultiSectionModulePlayer, moduleName, sectionCount, learningModuleData, courseWindow, moduleSectionList } = props;
	const { multiSectionCurrentSelection, setMultiSectionCurrentSelection, multiSectionCurrentSelectionOnQuizOpen,
		multiSectionSessionDetails, setMultiSectionSessionDetails } = useContext(SessionContext);
  	const history = useHistory();
	const [moduleSectionTitles, setModuleSectionTitles] = useState<string[]>([]);

	const [moduleSectionsCompleted, setModuleSectionsCompleted] = useState<string[]>([]);

	const [moduleSectionsInprogress, setModuleSectionsInprogress] =  useState<string[]>(["Section 1"]);

  	const { translations: {allResourcesToTranslations } } = useContext(TranslationsContext)
	const { menuSelection, setMenuSelection, showUnitAdminUI, showOrgAdminUI, showSuperAdminUI, 
		  sideMenuOpen, modulePlayerLayoutSideMenuOpen, expandedMenuSelection, setExpandedMenuSelection,
		  IsCPDEnabled, IsPolicyEnabled, IsExtCertEnabled, EnableActivityLogs, IsSSOEnabled,IsLinkEnabled,IsAPILibraryEnabled,
		showMyCertificateUI, superAdminSelectedBasicOrgData, setSuperAdminSelectedBasicOrgData, IsNewsfeedEnabled} = useContext(UIContext);
	const { oidcUser } = useReactOidc();
	const orgDomain = oidcUser.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
	const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
	const orgName = oidcUser?.profile.OrganisationName ? oidcUser.profile.OrganisationName : "";
	const [scrollRequired, setScrollRequired] = useState<boolean>(false);
	const [ superAdminDomain, setSuperAdminDomain ] = useState<string>(oidcConfig.currentdomainSA);

	// Super Admin Org Selection
	const [allOrgsBasicData, setAllOrgsBasicData] = useState<Array<OrgBasicData>>([]); 
    const [selectedOrgID, setSelectedOrgID] = useState<number>(-1);   
    const [selectedOrgDomain, setSelectedOrgDomain] = useState<string>("");   
    const [isAllOrgsListLoading, setIsAllOrgsListLoading] = useState<boolean>(true);  
    const [selectedOrgBasicData, setSelectedOrgBasicData] = useState<OrgBasicData>({orgID : -1, orgName: "", siteName: "", domainName: "", uniqueValue: "", isActive: false});  
    const [filterOrgName, setFilterOrgName] = useState<string>("");   
    // Super Admin Org Selection

	useEffect(() => {
		document.title =  "Salt" + " - " + orgName ;
	}, []);

	const handleSelection = (selection: string) => {
		addError(oidcUser.access_token, userId, orgDomain, `Selected ${selection} from the side menu`, null, "SideMenu.tsx", 6, EnableActivityLogs);
		if (!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer) {
			// Dont do any menu selection updates
		}
		else {
			if (!!menuSelection && selection == menuSelection.trimEnd()) {
				setMenuSelection(menuSelection + " ");
			} else {
				setMenuSelection(selection);
			}
		}
	};

	const handleSelectionForModulePlayerLayout = (selectedSectionRef: string) => {
		//addError(oidcUser.access_token, userId, orgDomain, `Selected ${selectedSectionRef} from the side menu`, null, "SideMenu.tsx", 6, EnableActivityLogs);
		if (!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer && !!learningModuleData) {
			var clickedSections = (learningModuleData as LearningModule).quizInfo.split("<%$%>").
									filter((word) => word.toString().startsWith('SectionSCOStartList<:#:>'))[0].
										replace('SectionSCOStartList<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "").
											filter((curSection, index) => (
												!!curSection && index != undefined && index != null && index >= 0 && "Section " + 
													(index+1).toString() == selectedSectionRef
											));
			if (!!clickedSections && clickedSections.length > 0 && !!clickedSections[0] && 
				(!!courseWindow && typeof courseWindow.LMSInitialize == "function" && typeof courseWindow.LMSSetValue == "function")) {
				//console.log("Lesson location change to " + clickedSections[0] + " => " + selectedSectionRef)
				if (moduleSectionsInprogress != null && moduleSectionsInprogress != undefined &&
					!moduleSectionsInprogress.includes(selectedSectionRef)) {
						let tempArr = [...moduleSectionsInprogress];
						tempArr.push(selectedSectionRef);
						setModuleSectionsInprogress(tempArr);
				}
				courseWindow.LMSSetValue("cmi.custom_current_section", selectedSectionRef);
				// courseWindow.LMSInitialize('LectoraLQ<&>cmi.core.lesson_location<&>' + clickedSections[0]);
				//courseWindow.LMSSetValue("cmi.core.lesson_location", clickedSections[0]);
				
				setMultiSectionCurrentSelection(selectedSectionRef);
			}
		}
	};

	const handleExpandedSelection = (selection: string) => {
		if (selection === expandedMenuSelection) {
			setExpandedMenuSelection("")
		}
		addError(oidcUser.access_token, userId, orgDomain, `Expanded ${selection} from the side menu`, null, "SideMenu.tsx", 6, EnableActivityLogs);
		setExpandedMenuSelection(selection);
	}

	const checkForSideMenuScroll = () => {
		const sideMenuHeight = document.getElementById("sideMenuWrapper")?.clientHeight ?? 0
		sideMenuHeight > window.innerHeight ? setScrollRequired(true) : setScrollRequired(false)
	}

    const updateCurrentOrgSelection = (givenSelectedOrgData: OrgBasicData) => {
      let matchedOrgData : OrgBasicData[] | null = null;
      if (!!givenSelectedOrgData && !!givenSelectedOrgData.orgID && givenSelectedOrgData.orgID > 0 && !!givenSelectedOrgData.orgName && 
			!!givenSelectedOrgData.domainName && !!givenSelectedOrgData.siteName) {
				//console.log("Value of givenSelectedOrgData orgID " + givenSelectedOrgData.orgID);
				//console.log("Value of givenSelectedOrgData siteName " + givenSelectedOrgData.siteName);
				//console.log("Value of givenSelectedOrgData domainName " + givenSelectedOrgData.domainName);
				//console.log("Value of givenSelectedOrgData orgName " + givenSelectedOrgData.orgName);
        if (!!allOrgsBasicData &&
            (matchedOrgData = allOrgsBasicData.filter(c => !!c && !!c.orgID && !!c.domainName && 
				c.orgID === givenSelectedOrgData.orgID && 
				c.domainName.trim().toLowerCase() === givenSelectedOrgData.domainName.trim().toLowerCase())) != null &&
				matchedOrgData.length > 0 && matchedOrgData[0] != null) {
                let matchedOrg : OrgBasicData = matchedOrgData[0];
                setSelectedOrgID(matchedOrg.orgID);
                setSelectedOrgDomain(matchedOrg.domainName);
				//setSelectedOrgBasicData(matchedOrg);
				setSuperAdminSelectedBasicOrgData(matchedOrg);
        }
        else {
			//console.log("Setting null when invalid allOrgsBasicData");
			setSuperAdminSelectedBasicOrgData(null);
        }
      }
	  else {
		//console.log("Setting null when invalid givenSelectedOrgData");
		setSuperAdminSelectedBasicOrgData(null);
	  }
    }

	useEffect(() => {
		if (!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) {
			getAllOrgs(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
				if (!!res && !!res.isSuccess) {
					if (!!res.allOrgData && res.allOrgData.length > 0) {
						let resultList : OrgBasicData[] = [];
						//resultList.push({uniqueValue: allResourcesToTranslations.allorgsnoselection, orgID: -1, orgName: allResourcesToTranslations.allorgsnoselection, domainName: "", siteName: ""});
						resultList.push(...res.allOrgData);
						let resultSubList0 : OrgBasicData[] = [];
						let resultSubList1 : OrgBasicData[] = [];
						let resultSubList2 : OrgBasicData[] = [];
						let resultListFinal : OrgBasicData[] = [];
						resultSubList0.push({uniqueValue: allResourcesToTranslations.allorgsnoselection, orgID: -1, orgName: allResourcesToTranslations.allorgsnoselection, domainName: "", siteName: "", isActive: false});
						resultListFinal.push(...resultSubList0);
						var filtered1 = resultList.filter((o) => !!o && !!o.orgName && !!o.domainName && 
										!o.orgName.toLowerCase().includes("z_") && 
										!o.orgName.toLowerCase().includes("zorg") && 
										!o.orgName.toLowerCase().includes("pilot ") && 
										!o.orgName.toLowerCase().includes("discontinue") && 
										!o.domainName.toLowerCase().includes("discontinue") && 
										!o.orgName.toLowerCase().includes("test"));
						if (!!filtered1 && filtered1.length > 0) {
							resultListFinal.push(...filtered1);
						}
						var filtered2 = resultList.filter((o) => (!!o !== true || !!o.orgName !== true || 
										!!o.domainName !== true || 
										o.orgName.toLowerCase().includes("z_") ||
										o.orgName.toLowerCase().includes("zorg") || 
										o.orgName.toLowerCase().includes("pilot ") ||
										o.orgName.toLowerCase().includes("discontinue") || 
										o.domainName.toLowerCase().includes("discontinue") || 
										o.orgName.toLowerCase().includes("test")));
						if (!!filtered2 && filtered2.length > 0) {
							resultListFinal.push(...filtered2);
						}
						setAllOrgsBasicData(resultListFinal);
						setSuperAdminSelectedBasicOrgData(null);
					}
					else {
						console.error("API getAllOrgs returned empty list");
					}
				}
				else {
					console.error("Failed API getAllOrgs with " + (!!res === false ? "null result" : (!res.isSuccess ? (!!res.error ? "error as " + res.error : "unknown error") : "")));
				}
			}).catch((errorVal) => {
				console.error(errorVal);
			})
		}
		else {
			setAllOrgsBasicData([]);
			setSuperAdminSelectedBasicOrgData(null);
		}

		checkForSideMenuScroll();
		window.addEventListener('resize', checkForSideMenuScroll);
        return () => { 
			window.removeEventListener('resize', checkForSideMenuScroll); 
        } 
	}, [])

	useEffect(() => {
		if (!!multiSectionCurrentSelection &&
			!!courseWindow && 
			typeof courseWindow.LMSGetValue == 'function' && 
			typeof courseWindow.LMSSetValue == 'function') {
			let existingSection = "";
			existingSection = courseWindow.LMSGetValue("cmi.custom_current_section");
			if (!!existingSection === false || existingSection.trim() == "" || existingSection.trim() != multiSectionCurrentSelection) {
				courseWindow.LMSSetValue("cmi.custom_current_section", multiSectionCurrentSelection);
				//setMultiSectionSessionDetails(courseWindow.LMSGetValue("cmi.custom_lectoralq_multi_session_details"));
			}
		}
	}, [multiSectionCurrentSelection])

	useEffect(() => {
		if (!!multiSectionCurrentSelectionOnQuizOpen &&
			!!courseWindow && 
			typeof courseWindow.LMSGetValue == 'function' && 
			typeof courseWindow.LMSSetValue == 'function') {
			//courseWindow.LMSSetValue("cmi.custom_lectoralq_multi_session_details", null);
		}
		getUser(oidcUser.access_token, userId, orgDomain).then((userData) => {
			if (userData !== null) {
				setassignReportOnlyUnitAdmin(userData.reportingAccessOnly)
			}
		  })
	}, [multiSectionCurrentSelectionOnQuizOpen])

	useEffect(() => {
		if (!!learningModuleData && !!learningModuleData.quizInfo && learningModuleData.quizInfo.trim() != "") {
			let arrTitles = !!learningModuleData && !!learningModuleData.quizInfo &&
							(learningModuleData.quizInfo as string).split("<%$%>").length > 0 &&
							(learningModuleData.quizInfo as string).split("<%$%>").
							filter((word) => word.toString().startsWith('SectionTitles<:#:>')).length > 0 &&
							(learningModuleData.quizInfo as string).split("<%$%>").
							filter((word) => word.toString().startsWith('SectionTitles<:#:>'))[0].
							replace('SectionTitles<:#:>', '').split("<&>").length > 0 &&
							(learningModuleData.quizInfo as string).split("<%$%>").
							filter((word) => word.toString().startsWith('SectionTitles<:#:>'))[0].
							replace('SectionTitles<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "").length > 0 ? 
							(learningModuleData.quizInfo as string).split("<%$%>").
							filter((word) => word.toString().startsWith('SectionTitles<:#:>'))[0].
							replace('SectionTitles<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "") : [];
			setModuleSectionTitles(arrTitles);
		}
		else {
			setModuleSectionTitles([]);
		}
		if (!!multiSectionSessionDetails && multiSectionSessionDetails.trim() != "") {
			let modCompArr = !!multiSectionSessionDetails &&
				multiSectionSessionDetails.split("<%$%>").length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToCompletionFlag<:#:>')).length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToCompletionFlag<:#:>'))[0].
				replace('SectionToCompletionFlag<:#:>', '').split("<&>").length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToCompletionFlag<:#:>'))[0].
				replace('SectionToCompletionFlag<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "").length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToCompletionFlag<:#:>'))[0].
				replace('SectionToCompletionFlag<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "" && e.toString().trim().includes("<=>>") && 
							e.toString().trim().substring(e.toString().trim().indexOf("<=>>") + ("<=>>").length).trim() == "1").
				map((curSection, index) => (
					!!curSection && index != undefined && index != null && index >= 0 && curSection.trim().includes("<=>>") ?
					curSection.substring(0, curSection.indexOf("<=>>")).trim()
					:
					""
				)).filter((a) => !!a && a.trim() != "").length > 0 ? 
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToCompletionFlag<:#:>'))[0].
				replace('SectionToCompletionFlag<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "" && e.toString().trim().includes("<=>>") && 
							e.toString().trim().substring(e.toString().trim().indexOf("<=>>") + ("<=>>").length).trim() == "1").
				map((curSection, index) => (
					!!curSection && index != undefined && index != null && index >= 0 && curSection.trim().includes("<=>>") ?
					curSection.substring(0, curSection.indexOf("<=>>")).trim()
					:
					""
				)).filter((a) => !!a && a.trim() != "") : [];
			setModuleSectionsCompleted(modCompArr);
			let arrPrg = (!!multiSectionSessionDetails &&
				multiSectionSessionDetails.split("<%$%>").length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToBookmark<:#:>')).length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToBookmark<:#:>'))[0].
				replace('SectionToBookmark<:#:>', '').split("<&>").length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToBookmark<:#:>'))[0].
				replace('SectionToBookmark<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "").length > 0 &&
				multiSectionSessionDetails.split("<%$%>").
				filter((word) => word.toString().startsWith('SectionToBookmark<:#:>'))[0].
				replace('SectionToBookmark<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "").
				map((curSection, index) => (
					((!!curSection && index != undefined && index != null && index >= 0 && curSection.trim().includes("<=>>")) ?
					curSection.substring(0, curSection.indexOf("<=>>")).trim()
					:
					"")
				)).filter((a) => !!a && a.trim() != "").length > 0) ? 
				multiSectionSessionDetails.split("<%$%>").
					filter((word) => word.toString().startsWith('SectionToBookmark<:#:>'))[0].
					replace('SectionToBookmark<:#:>', '').split("<&>").filter(e => !!e && e.toString().trim() != "").
					map((curSection, index) => (
						((!!curSection && index != undefined && index != null && index >= 0 && curSection.trim().includes("<=>>")) ?
						curSection.substring(0, curSection.indexOf("<=>>")).trim()
						:
						"")
					)).filter((a) => !!a && a.trim() != "") 
				: 
				["Section 1"];
			setModuleSectionsInprogress(arrPrg);

			let arr : string[] = [];
			arr = multiSectionSessionDetails.split("<%$%>");
			arr = arr.length <= 0 ? [] : arr.filter((word) => word.toString().startsWith('SectionToBookmark<:#:>'));
			let toReset = false;
			toReset = arr.length >= 0;
			arr = arr.length <= 0 ? [] : arr[0].replace('SectionToBookmark<:#:>', '').split("<&>");
			arr = arr.length <= 0 ? [] : arr.filter(e => !!e && e.toString().trim() != "");
			arr = arr.length <= 0 ? [] : arr.map((curSection, index) => (
								!!curSection && index != undefined && index != null && index >= 0 && curSection.trim().includes("<=>>") ?
								curSection.substring(0, curSection.indexOf("<=>>")).trim()
								:
								""
							));
			arr = arr.length < 0 ? [] : arr.filter((a) => !!a && a.trim() != "");
			let arrnew : string[] = [];
			if (!!arr && arr.length > 0 && (moduleSectionsInprogress.length <= 0 || 
												(arrnew=arr.filter((a) => !!a && a.trim() != "" && !moduleSectionsInprogress.includes(a))).length > 0) || 
												(arrnew=moduleSectionsInprogress.filter((a) => !!a && a.trim() != "" && !arr.includes(a))).length > 0) {
				let arrnewfinal : string[] = [];
				for (let idx =0; idx < arr.length; idx++) {
					if (!!arr[idx] && arr[idx].trim() != "" && !arrnewfinal.includes(arr[idx].trim()))
					{
						arrnewfinal.push(arr[idx]);
					}
				}
				// for (let idx =0; idx < moduleSectionsInprogress.length; idx++) {
				// 	if (!!moduleSectionsInprogress[idx] && moduleSectionsInprogress[idx].trim() != "" && !arrnewfinal.includes(moduleSectionsInprogress[idx].trim()))
				// 	{
				// 		arrnewfinal.push(moduleSectionsInprogress[idx]);
				// 	}
				// }
				setModuleSectionsInprogress(arrnewfinal);
			}
			else if (arr.length == 0 && toReset && !!moduleSectionsInprogress && moduleSectionsInprogress.length > 0 && moduleSectionsInprogress.filter((c) => !!c && c.trim() != "").length > 0) {
				let arrnewfinal : string[] = ["Section 1"]; // By default set the Section 1 is in progress, if none is in progress
				setModuleSectionsInprogress(arrnewfinal);
			}
		}
	}, [multiSectionSessionDetails, learningModuleData])

	useEffect(() => {
		if (!!multiSectionSessionDetails && multiSectionSessionDetails.trim() != "") {
			let arr2 : string[] = [];
			arr2 = multiSectionSessionDetails.split("<%$%>");
			arr2 = arr2.length <= 0 ? [] : arr2.filter((word) => word.toString().startsWith('SectionToCompletionFlag<:#:>'));
			let toReset = false;
			toReset = arr2.length >= 0;
			arr2 = arr2.length <= 0 ? [] : arr2[0].replace('SectionToCompletionFlag<:#:>', '').split("<&>");
			arr2 = arr2.length <= 0 ? [] : arr2.filter(e => !!e && e.toString().trim() != "" && e.toString().trim().includes("<=>>") && 
												e.toString().trim().substring(e.toString().trim().indexOf("<=>>") + ("<=>>").length).trim() == "1");
			arr2 = arr2.length <= 0 ? [] : arr2.map((curSection, index) => (
								!!curSection && index != undefined && index != null && index >= 0 && curSection.trim().includes("<=>>") ?
								curSection.substring(0, curSection.indexOf("<=>>")).trim()
								:
								""
							));
			arr2 = arr2.length <= 0 ? [] : arr2.filter((a) => !!a && a.trim() != "");
			let arrnew2 : string[] = [];
			if (!!arr2 && arr2.length > 0 && (moduleSectionsCompleted.length <= 0 || 
												(arrnew2=arr2.filter((a) => !!a && a.trim() != "" && !moduleSectionsCompleted.includes(a))).length > 0) ||
												(arrnew2=moduleSectionsCompleted.filter((a) => !!a && a.trim() != "" && !arr2.includes(a))).length > 0) {
				let arrnewfinal : string[] = [];
				for (let idx =0; idx < arr2.length; idx++) {
					if (!!arr2[idx] && arr2[idx].trim() != "" && !arrnewfinal.includes(arr2[idx].trim()))
					{
						arrnewfinal.push(arr2[idx]);
					}
				}
				// for (let idx =0; idx < moduleSectionsCompleted.length; idx++) {
				// 	if (!!moduleSectionsCompleted[idx] && moduleSectionsCompleted[idx].trim() != "" && !arrnewfinal.includes(moduleSectionsCompleted[idx].trim()))
				// 	{
				// 		arrnewfinal.push(moduleSectionsCompleted[idx]);
				// 	}
				// }
				setModuleSectionsCompleted(arrnewfinal);
			}
			else if (arr2.length == 0 && toReset && !!moduleSectionsCompleted && moduleSectionsCompleted.length > 0 && moduleSectionsCompleted.filter((c) => !!c && c.trim() != "").length > 0) {
				let arrnewfinal : string[] = [];
				setModuleSectionsCompleted(arrnewfinal);
			}
		}
	}, [multiSectionSessionDetails, learningModuleData]);

	useEffect(() => {
		
	}, [moduleSectionsCompleted])

	return (
		!!props && !!isMultiSectionModulePlayer && isMultiSectionModulePlayer ? 
		<>
			<SideMenuCloseButton isMultiSectionModulePlayer={isMultiSectionModulePlayer} />
			<div id="sideMenu_MulSecModPlayer" className={modulePlayerLayoutSideMenuOpen ? styles.sideMenu_MulSecModPlayer : styles.sideMenuClosed_MulSecModPlayer} 
												style={{ overflow: scrollRequired ? "scroll" : "hidden" }}>
				<div id="sideMenuWrapper_MulSecModPlayer">
					<div className={styles.sideMenuSpacer_MulSecModPlayer} />
					<SideMenuDivider sectionName={modulePlayerLayoutSideMenuOpen ? "Module Name - " + (!!moduleName ? moduleName : "") : ""}/>
					{
						!!sectionCount && sectionCount > 0 && !!moduleSectionList && moduleSectionList.length > 0 ? 
						(moduleSectionList as string[]).
							map((curSection, index) => (
								!!curSection && index != undefined && index != null && index >= 0 ? 
								<SideMenuOption
									key={(index+1).toString()}
									icon={!!moduleSectionsCompleted && moduleSectionsCompleted.includes("Section " + (index+1).toString()) ? 
											<PlayerSectionCompletionIcon /> : 
												(!!moduleSectionsInprogress && moduleSectionsInprogress.includes("Section " + (index+1).toString()) ? 
													<PlayerSectionProgressIcon /> : 
														((!!multiSectionCurrentSelection ? 
															(multiSectionCurrentSelection.trim().toLowerCase() == ("Section " + (index+1).toString()).toLowerCase()) : 
															("section 1" == ("Section " + (index+1).toString()).toLowerCase())) ? 
															<PlayerSectionProgressIcon /> :
															<PlayerSectionIncompleteIcon />))}
									optionName={"Section " + (index+1).toString()}
									displayName={(!!moduleSectionTitles && moduleSectionTitles.length > index) ? moduleSectionTitles[index] : "Section " + (index+1).toString()}
									isSelected={(!!multiSectionCurrentSelection ? 
										(multiSectionCurrentSelection.trim().toLowerCase() == ("Section " + (index+1).toString()).toLowerCase()) : 
										("section 1" == ("Section " + (index+1).toString()).toLowerCase()))}
									customClickEvent={handleSelectionForModulePlayerLayout}
									sideMenuOpen={modulePlayerLayoutSideMenuOpen}
									route=""
									className=""
									isMultiSectionModulePlayer={isMultiSectionModulePlayer}
								/>
								: 
								null
							))
						: 
						null
					}
				</div>
			</div>
		</> 
		:
		<>
			<SideMenuCloseButton />
			<div id="sideMenu" className={sideMenuOpen ? styles.sideMenu : styles.sideMenuClosed} style={{ overflow: "auto" }}>
				<div id="sideMenuWrapper">
					<div className={styles.sideMenuSpacer} />
					{
						!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain &&
						//!!orgDomain && orgDomain.trim().toLowerCase() == "devstagingsite6.saltcompliance.com" &&
						<div style={{marginTop: "10px"}}>
							{/* <div key="orgNameSearch" id="orgNameSearch" style={{padding: '2px', flexGrow: 1, width: "100%"}}> 
								<label id="lbl-filter-org" style={{color: "white"}}>{allResourcesToTranslations.superadminsearchorgname}</label>   
								<TextField style={{width: "100%"}} id="orgNameSearchFilter" aria-labelledby="lbl-filter-org" className={styles.textField}
									variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
									value={filterOrgName} onChange={(e) => setFilterOrgName(e.target.value)} 
									InputProps={{endAdornment: ( <IconButton><SearchIcon style={{height: "20px"}} /></IconButton> )}}/>
							</div> */}
							{/* <div className={styles.groupingOption} id="allOrgSitesListDiv"> 
								<label id="lbl-select-orgsite" style={{color: "white"}}>{allResourcesToTranslations.superadminselectorg}</label>                                               
								<FormControl variant="outlined" className={`${styles.formControl} allOrgSitesListFormCtrl`}>                      
									<Select aria-labelledby="lbl-select-orgsite"
									value={
										!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : ""
									}
									native
									onChange={(e : any) => { 
										let orgID_SiteName : string = !!e.target.value ? e.target.value.toString() : "";
										let matched = !!orgID_SiteName && !!allOrgsBasicData ? 
														allOrgsBasicData.filter((cur : OrgBasicData) => !!cur && !!cur.uniqueValue &&
															cur.uniqueValue.trim().toLowerCase() === orgID_SiteName.trim().toLowerCase()) : null;
										//console.log("Changing orgID_SiteName " + orgID_SiteName);
										
										if (!!matched && matched.length > 0 && matched[0].orgID > 0) {
											//console.log("Value of matched orgID " + ((!!matched && !!matched[0]) ? matched[0].orgID : -1));
											//console.log("Value of matched siteName " +  ((!!matched && !!matched[0]) ? matched[0].siteName : ""));
											//console.log("Value of matched domainName " +  ((!!matched && !!matched[0]) ? matched[0].domainName : ""));
											//console.log("Value of matched orgName " +  ((!!matched && !!matched[0]) ? matched[0].orgName : ""));
											var curSel = {
												uniqueValue: (!!matched && !!matched[0]) ? matched[0].orgID.toString() + "_" + matched[0].siteName : "", 
												orgID: (!!matched && !!matched[0]) ? matched[0].orgID : -1, 
												orgName: (!!matched && !!matched[0]) ? matched[0].orgName : "",
												domainName: (!!matched && !!matched[0]) ? matched[0].domainName : "", 
												siteName: (!!matched && !!matched[0]) ? matched[0].siteName : "",
											};
											setSelectedOrgBasicData(curSel);
											updateCurrentOrgSelection(curSel);
										}
										else {
											//console.log("matched is NULL or undefined");
											setSelectedOrgBasicData({orgID : -1, orgName: "", siteName: "", domainName: "", uniqueValue: ""});
											updateCurrentOrgSelection({orgID : -1, orgName: "", siteName: "", domainName: "", uniqueValue: ""});
										}
									}}
									//defaultValue={{value: "", orgID: "-1", orgName: "", domainName: "", siteName: ""}}
									className={styles.inputSelect}
									variant="outlined" 
									//label={allResourcesToTranslations.profilecloninglabel + ":"}
									id="allOrgSitesListSelect"
									>
									{
										!!allOrgsBasicData &&  
										[...new Set(allOrgsBasicData.filter(o => !!o && !!o.siteName && o.siteName.trim() != "").map(o => o.siteName))].
										map((item:any) => {
											return <option style={{color: 'black'}} key={item.siteName} value={item.siteName}>{((!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.uniqueValue : "")=="") ? allResourcesToTranslations.allorgsnoselection : item.siteName}</option>
										})
									}
								</Select>
								</FormControl>
							</div> */}
							<div className={styles.groupingOption} id="allOrgsListDiv"> 
								<label id="lbl-select-org" style={{color: "white"}}>{allResourcesToTranslations.superadminselectorg}</label>                                               
								<FormControl variant="outlined" className={`${styles.formControl} allOrgsListFormCtrl`}>                      
									<Select aria-labelledby="lbl-select-org"
									value={
										!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.uniqueValue : ""
									}
									native
									onChange={(e : any) => { 
										let orgID_SiteName : string = !!e.target.value ? e.target.value.toString() : "";
										let matched = !!orgID_SiteName && !!allOrgsBasicData ? 
														allOrgsBasicData.filter((cur : OrgBasicData) => !!cur && !!cur.uniqueValue &&
															cur.uniqueValue.trim().toLowerCase() === orgID_SiteName.trim().toLowerCase()) : null;
										//console.log("Changing orgID_SiteName " + orgID_SiteName);
										
										if (!!matched && matched.length > 0 && matched[0].orgID > 0) {
											//console.log("Value of matched orgID " + ((!!matched && !!matched[0]) ? matched[0].orgID : -1));
											//console.log("Value of matched siteName " +  ((!!matched && !!matched[0]) ? matched[0].siteName : ""));
											//console.log("Value of matched domainName " +  ((!!matched && !!matched[0]) ? matched[0].domainName : ""));
											//console.log("Value of matched orgName " +  ((!!matched && !!matched[0]) ? matched[0].orgName : ""));
											var curSel = {
												uniqueValue: (!!matched && !!matched[0]) ? matched[0].orgID.toString() + "_" + matched[0].siteName : "", 
												orgID: (!!matched && !!matched[0]) ? matched[0].orgID : -1, 
												orgName: (!!matched && !!matched[0]) ? matched[0].orgName : "",
												domainName: (!!matched && !!matched[0]) ? matched[0].domainName : "", 
												siteName: (!!matched && !!matched[0]) ? matched[0].siteName : "",
												isActive: false
											};
											setSelectedOrgBasicData(curSel);
											updateCurrentOrgSelection(curSel);
											history.push('/dashboard');
										}
										else {
											//console.log("matched is NULL or undefined");
											setSelectedOrgBasicData({orgID : -1, orgName: "", siteName: "", domainName: "", uniqueValue: "", isActive: false});
											updateCurrentOrgSelection({orgID : -1, orgName: "", siteName: "", domainName: "", uniqueValue: "", isActive: false});
											   history.push('/dashboard');
										}
									}}
									//defaultValue={{value: "", orgID: "-1", orgName: "", domainName: "", siteName: ""}}
									className={styles.inputSelect}
									variant="outlined" 
									//label={allResourcesToTranslations.profilecloninglabel + ":"}
									id="allOrgsListSelect"
									>
									{
										!!allOrgsBasicData &&  
										allOrgsBasicData.filter(o => !!o && !!o.orgName && (!!filterOrgName === false || (!!filterOrgName && (o.orgName.toLowerCase().includes(filterOrgName.toLowerCase()) || o.domainName.toLowerCase().includes(filterOrgName.toLowerCase()))))).
										map((item:OrgBasicData) => {
											return <option style={{color: 'black'}} key={item.orgID.toString() + "_" + item.siteName} value={item.orgID.toString() + "_" + item.siteName}>{item.orgID <= 0 ? allResourcesToTranslations.allorgsnoselection : (item.siteName.replace("SaltSite", "") + " - " + item.orgName + " (" + item.domainName + ")")}</option>
										})
									}
								</Select>
								</FormControl>
							</div>
						</div>
					}
					<SideMenuOption
						icon={<DashboardIconMenu />}
						optionName={"My Dashboard"}
						displayName={allResourcesToTranslations.mydashboard}
						isSelected={menuSelection === "My Dashboard"}
						customClickEvent={handleSelection}
						sideMenuOpen={sideMenuOpen}
						route="/"
					/>
					<SideMenuOption
						icon={<MyTrainingMenu />}
						optionName={"My Training"}
						displayName={allResourcesToTranslations.mytraining}
						isSelected={menuSelection === "My Training"}
						customClickEvent={handleSelection}
						sideMenuOpen={sideMenuOpen}
						route="/mytraining"
						className="myTraining"
					/>
					<SideMenuOption
						icon={<MyReportIconMenu />}
						optionName={"My Report"}
						displayName={allResourcesToTranslations.myreport}
						isSelected={menuSelection === "My Report"}
						customClickEvent={handleSelection}
						sideMenuOpen={sideMenuOpen}
						route="/myreport"
						className="myReport"
					/>
					{
						IsExtCertEnabled && showMyCertificateUI &&
						<SideMenuOption
							icon={<ExternalCertificateMenuIcon />}
							optionName={"My External Certificates"}
							displayName={allResourcesToTranslations.myextcertificate}
							isSelected={menuSelection === "My External Certificates"}
							customClickEvent={handleSelection}
							sideMenuOpen={sideMenuOpen}
							route="/mycertificates"
							className="mycertificates"
						/>
					}
					{
						IsNewsfeedEnabled &&
						<SideMenuOption
							icon={<MyNewsfeedMenuIcon />}
							optionName={allResourcesToTranslations.mynewsfeed}
							displayName={allResourcesToTranslations.mynewsfeed}
							isSelected={menuSelection === allResourcesToTranslations.mynewsfeed}
							customClickEvent={handleSelection}
							sideMenuOpen={sideMenuOpen}
							route="/mynewsfeed"
							className="mynewsfeed"
						/>
					}
					
					{
						IsLinkEnabled ?
						<SideMenuOption
							icon={<ModifyLinkIconMenu />}
							optionName={"Links"}
							displayName={allResourcesToTranslations.links}
							isSelected={menuSelection === "Links"}
							customClickEvent={handleSelection}
							sideMenuOpen={sideMenuOpen}
							route="/links"
							className="links"
						/>: ""
					}					
					{
						!assignReportOnlyUnitAdmin ?
						<SideMenuAdmin showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}>
						{
							!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							<SideMenuOptionExpandable
								icon={<AccountSettingIconMenu />}
								optionName={"Account Settings"}
								displayName={allResourcesToTranslations.accountsettings}
								isSelected={expandedMenuSelection === "Account Settings"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>
								<SideMenuOption
									icon={<AddIconMenu />}
									optionName={"Add Account"}
									displayName={allResourcesToTranslations.addaccount}
									isSelected={menuSelection === "Add Account"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/addaccount"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<ManagementIconMenu />}
									optionName={"Account Management"}
									displayName={allResourcesToTranslations.accountmanagement}
									isSelected={menuSelection === "Account Management"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/accountmanagement"
									isNested
									key={createGuid()}
								/>		
								<SideMenuOption
									icon={<ImportIconMenu />}
									optionName={"Import Accounts"}
									displayName={allResourcesToTranslations.importaccounts}
									isSelected={menuSelection === "Import Accounts"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/importaccounts"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<ArchiveIconMenu />}
									optionName={"Archive Accounts"}
									displayName={allResourcesToTranslations.archiveaccounts}
									isSelected={menuSelection === "Archive Accounts"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/archiveaccounts"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<MoveIconMenu />}
									optionName={"Move Accounts to Unit"}
									displayName={allResourcesToTranslations.moveaccountstounit}
									isSelected={menuSelection === "Move Accounts to Unit"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/moveaccountstounit"
									isNested
									key={createGuid()}
								/>
							</SideMenuOptionExpandable>	
						}	
						{							
							!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							<SideMenuOptionExpandable
								icon={<UnitSettingsIconMenu />}
								optionName={"Unit Settings"}
								displayName={allResourcesToTranslations.unitsettings}
								isSelected={expandedMenuSelection === "Unit Settings"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>
								<SideMenuOption
									icon={<AddIconMenu />}
									optionName={"Add Unit"}
									displayName={allResourcesToTranslations.addunit}
									isSelected={menuSelection === "Add Unit"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/addunit"
									isNested
									key={createGuid()}
								/>							
								<SideMenuOption
									icon={<ManagementIconMenu />}
									optionName={"Unit Managment"}
									displayName={allResourcesToTranslations.unitmanagement}
									isSelected={menuSelection === "Unit Management"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/unitmanagement"
									isNested
									key={createGuid()}
								/>
								{
									(showOrgAdminUI || showSuperAdminUI) ? 
									<SideMenuOption 
										icon={<MoveIconMenu />}
										optionName={"Move Unit"}
										displayName={allResourcesToTranslations.moveunit}
										isSelected={menuSelection === "Move Unit"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/moveunit"
										isNested
										key={createGuid()}
									/> : <></>		
								}			
							</SideMenuOptionExpandable>
						}
						<SideMenuOptionExpandable
							icon={<OrgSettingsIconMenu />}
							optionName={"Organisation Settings"}
							displayName={allResourcesToTranslations.organisationsettings}
							isSelected={expandedMenuSelection === "Organisation Settings"}
							customClickEvent={handleExpandedSelection}
							sideMenuOpen={sideMenuOpen}
							showAdminMenuItems={(showOrgAdminUI || showSuperAdminUI)}
							//noOfChildrenToRemove={((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? (IsSSOEnabled ? 3 : 4) : (IsSSOEnabled ? 2 : 3))}
							>
							{
								<SideMenuOption
									icon={<ManagementIconMenu />}
									optionName={"Organisation Management"}
									displayName={allResourcesToTranslations.organisationmanagement}
									isSelected={menuSelection === "Organisation Management"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/organisationmanagement"
									isNested
									key={createGuid()}						
								/>
							}
							{
								(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<SideMenuOption
												icon={<ManagementDeactiveMenuIcon />}
												optionName={"Organisation Deactivation"}
												displayName={allResourcesToTranslations.organisationdeactivation}
												isSelected={menuSelection === "Organisation Deactivation"}
												customClickEvent={handleSelection}
												sideMenuOpen={sideMenuOpen}
												route="/organisationdeactivation"
												isNested
												key={createGuid()} />
							}
							{
								(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<SideMenuOption
												icon={<AdminIconMenu />}
												optionName={"Account Managers"}
												displayName={allResourcesToTranslations.orgaccountmanagers}
												isSelected={menuSelection === "Account Managers"}
												customClickEvent={handleSelection}
												sideMenuOpen={sideMenuOpen}
												route="/accountmanagers"
												isNested
												key={createGuid()} />
							}
							{
								((showOrgAdminUI || showSuperAdminUI) || (!!orgDomain && orgDomain.trim().toLowerCase() === superAdminDomain)) &&
								<SideMenuOption
										icon={<ConfigItemIconMenu />}
										optionName={"Configuration Items"}
										displayName={allResourcesToTranslations.configurationitems}
										isSelected={menuSelection === "Configuration Items"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/organisationmanagement/configurationitems"
										isNested
										key={createGuid()}
								/>
							}
							{
								!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<SideMenuOption
										icon={<AdminIconMenu />}
										optionName={"Organisation Administrators"}
										displayName={allResourcesToTranslations.organisationadministrators}
										isSelected={menuSelection === "Organisation Administrators"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/organisationadmin"
										isNested
										key={createGuid()}
								/>
							}												
							{
								
								IsSSOEnabled && 
								<SideMenuOption
									icon={<SingleSignOnIconMenu />}
									optionName={"Single Sign On"}
									displayName={allResourcesToTranslations.singlesignon}
									isSelected={menuSelection === "Single Sign On"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/organisationmanagement/singlesignon"
									isNested
									key={createGuid()}
								/>
							}
							{
								!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<SideMenuOption
										icon={<SupportDetailsIconMenu />}
										optionName={"Support Details"}
										displayName={allResourcesToTranslations.supportdetails}
										isSelected={menuSelection === "Support Details"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/supportdetails"
										isNested
										key={createGuid()}
								/>
							}
							{
								<SideMenuOption
										icon={<ThemeIconMenu />}
										optionName={"Theme"}
										displayName={allResourcesToTranslations.theme0}
										isSelected={menuSelection === "Theme"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/theme"
										isNested
										key={createGuid()}
								/>
							}
							{
								!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<SideMenuOption
										icon={<ModifyLinkIconMenu />}
										optionName={"Modify Links"}
										displayName={allResourcesToTranslations.modifylinks}
										isSelected={menuSelection === "Modify Links"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/modifylinks"
										isNested
										key={createGuid()}
								/>
							}
							{
								IsAPILibraryEnabled &&  (!!orgDomain && orgDomain.trim().toLowerCase() !== superAdminDomain) &&
								<SideMenuOption
									icon={<APILibraryIconMenu />}
									optionName={"API Library"}
									displayName={allResourcesToTranslations.apilibrary}
									isSelected={menuSelection === "API Library"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/apilibrary"
									isNested
									key={createGuid()}
								/>
							}
						</SideMenuOptionExpandable>
						{		
							!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&					
							<SideMenuOptionExpandable
								icon={<CourseSettingsIconMenu />}
								optionName={"Course Settings"}
								displayName={allResourcesToTranslations.coursesettings}
								isSelected={expandedMenuSelection === "Course Settings"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>								
								<SideMenuOption
									icon={<PublishContentIconMenu />}
									optionName={"Publish Course"}
									displayName={allResourcesToTranslations.publishcourse}
									isSelected={menuSelection === "Publish Course"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/coursedetails"
									isNested
									key={createGuid()}
								/>	
								<SideMenuOption
										icon={<AssignCoursesIconMenu />}
										optionName={"Assign Courses"}
										displayName={allResourcesToTranslations.assigncourses}
										isSelected={menuSelection === "Assign Courses"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/assigncourses"
										isNested
										key={createGuid()}
								/>									
								<SideMenuOption
										icon={<ComplianceRulesIconMenu />}
										optionName={"Compliance Rules"}
										displayName={allResourcesToTranslations.compliancerules}
										isSelected={menuSelection === "Compliance Rules"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/organisationmanagement/compliancerules"
										isNested
										key={createGuid()}
								/>		
							</SideMenuOptionExpandable>
						}	
						{  
							!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&							
							<SideMenuOptionExpandable
								icon={<EmailIconMenu/>}
								optionName={allResourcesToTranslations.emailsettings}
								displayName={allResourcesToTranslations.emailsettings}
								isSelected={expandedMenuSelection === allResourcesToTranslations.emailsettings}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>		
								<SideMenuOption
									icon={<EmailIconMenu />}
									optionName={"Organisation Email Templates"}
									displayName={allResourcesToTranslations.orgemailtemplates}
									isSelected={menuSelection === "Organisation Email Templates"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/orgemailtemplates"
									isNested
									key={createGuid()}						
								/>								
								<SideMenuOption
									icon={<ReminderEmailRuleIconMenu />}
									optionName={"Reminder Email Rules"}
									displayName={allResourcesToTranslations.orgemailrule}
									isSelected={menuSelection === "Reminder Email Rules"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/orgemailrules"
									isNested
									key={createGuid()}
								/>	
							</SideMenuOptionExpandable>
						}
						{
							!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							IsCPDEnabled && (showOrgAdminUI || showSuperAdminUI) &&
							<SideMenuOption
								icon={<CpdProfileIconMenu />}
								optionName={"CPD Profile"}
								displayName={allResourcesToTranslations.cpdprofile /* Removed capital letter */}
								isSelected={menuSelection === "CPD Profile"}
								customClickEvent={handleSelection}
								sideMenuOpen={sideMenuOpen}
								route="/cpdProfilesList"
								key={createGuid()}						
							/>
						}
						{/* <SideMenuOptionExpandable
							icon={<CpdSettingsIconMenu />}
							optionName={"CPD Settings"}
							displayName={allResourcesToTranslations.cpdsetting}
							isSelected={expandedMenuSelection === "CPD Settings"}
							customClickEvent={handleExpandedSelection}
							sideMenuOpen={sideMenuOpen}
							showAdminMenuItems={(showOrgAdminUI || showSuperAdminUI)}
							//noOfChildrenToRemove={0}
						>		
							<SideMenuOption
								icon={<CpdProfileIconMenu />}
								optionName={"CPD Profile"}
								displayName={allResourcesToTranslations.cpdprofile  Removed capital letter }
								isSelected={menuSelection === "CPD Profile"}
								customClickEvent={handleSelection}
								sideMenuOpen={sideMenuOpen}
								route="/cpdProfilesList"
								isNested
								key={createGuid()}						
							/>
						</SideMenuOptionExpandable> */}
						{
							!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							IsPolicyEnabled && (showOrgAdminUI || showSuperAdminUI) &&
							<SideMenuOption
								icon={<PolicyBuilderIconMenu />}
								optionName={"Policy Builder"}
								displayName={allResourcesToTranslations.policybuilder}
								isSelected={menuSelection === "Policy Builder"}
								customClickEvent={handleSelection}
								sideMenuOpen={sideMenuOpen}
								route="/policyBuilderList"								
								key={createGuid()}						
							/>
						}
						{
							!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							IsExtCertEnabled && (showOrgAdminUI || showSuperAdminUI) &&
							<SideMenuOption
								icon={<ExternalCertificateMenuIcon />}
								optionName={allResourcesToTranslations.extcertificate}
								displayName={allResourcesToTranslations.extcertificate}
								isSelected={menuSelection === allResourcesToTranslations.extcertificate}
								customClickEvent={handleSelection}
								sideMenuOpen={sideMenuOpen}
								route="/externalcertificates"
								className="externalcertificates"
							/>
						}
					</SideMenuAdmin>: ""
					}
					
					{
					((IsNewsfeedEnabled && ((showOrgAdminUI || showSuperAdminUI)) || (!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain))) &&			
							<SideMenuOptionExpandable
								icon={<NewsfeedMenuIcon />}
								optionName={"Newsfeed Settings"}
								displayName={allResourcesToTranslations.newsfeedsettings}
								isSelected={expandedMenuSelection === "Newsfeed Settings"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? 1 : 1}
							>
								<SideMenuOption
									icon={<NewsfeedMenuIcon />}
									optionName={"Newsfeeds"}
									displayName={allResourcesToTranslations.newsfeeds}
									isSelected={menuSelection === "Newsfeeds"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/newsfeed"
									isNested
									key={createGuid()}
								/>
								{(!!orgDomain && orgDomain.trim().toLowerCase() !== superAdminDomain) &&
								<SideMenuOption
									icon={<MyReportIconMenu />}
									optionName={allResourcesToTranslations.newsfeedassignedgroup}
									displayName={allResourcesToTranslations.newsfeedassignedgroup}
									isSelected={menuSelection === allResourcesToTranslations.newsfeedassignedgroup}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/newsfeedAssignedGroup"
									className="newsfeedAssignedGroup"
									isNested
									key={createGuid()}
								/>		
								}
								{(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) && (superAdminSelectedBasicOrgData === null || superAdminSelectedBasicOrgData === undefined) &&
								<SideMenuOption
									icon={<MyReportIconMenu />}
									optionName={allResourcesToTranslations.newsfeedsubscribedorgs}
									displayName={allResourcesToTranslations.newsfeedsubscribedorgs}
									isSelected={menuSelection === allResourcesToTranslations.newsfeedsubscribedorgs}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/newsfeedsubscribers"
									className="newsfeedsubscribers"
									isNested
									key={createGuid()}
								/>		
								}
							</SideMenuOptionExpandable>
					}					
					{  
							((!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain)) &&							
								<SideMenuOption
									icon={<EmailIconMenu />}
									optionName={"Application Email Templates"}
									displayName={allResourcesToTranslations.applicationemailtemplates}
									isSelected={menuSelection === "Application Email Templates"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/organisationapplicationtemplate"
									
									key={createGuid()}						
								/>							
								
					}

					{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
						<SideMenuReports showReportMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}>
							{
							<SideMenuOptionExpandable
								icon={<MyReportIconMenu />}
								optionName={"Account Activity Reports"}
								displayName={allResourcesToTranslations.useractivityreports}
								isSelected={expandedMenuSelection === "Account Activity Reports"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>
								<SideMenuOption
									icon={<AdminIconMenu />}
									optionName={"Current Admin Report"}
									displayName={allResourcesToTranslations.currentadminreport}
									isSelected={menuSelection === "Current Admin Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/currentadminreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<MyTrainingMenu />}
									optionName={"Course Status Report"}
									displayName={allResourcesToTranslations.coursestatusreport}
									isSelected={menuSelection === "Course Status Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/coursestatusreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<ProgressIconMenu />}
									optionName={"Progress Report"}
									displayName={allResourcesToTranslations.progressreport}
									isSelected={menuSelection === "Progress Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/progressreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<QuizHistoryIconMenu />}
									optionName={"Quiz History Report"}
									displayName={allResourcesToTranslations.quizhistoryreport}
									isSelected={menuSelection === "Quiz History Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/quizhistoryreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<WarningReportIconMenu />}
									optionName={"Warning Report"}
									displayName={allResourcesToTranslations.warningreport}
									isSelected={menuSelection === "Warning Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/warningreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<AtRiskReportIconMenu />}
									optionName={"At Risk Report"}
									displayName={allResourcesToTranslations.atriskreport}
									isSelected={menuSelection === "At Risk Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/atriskreport"
									isNested
									key={createGuid()}
								/>
							</SideMenuOptionExpandable>}
							{ !(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							<SideMenuOptionExpandable
								icon={<MyReportIconMenu />}
								optionName={"Organisation Reports"}
								displayName={allResourcesToTranslations.organisationreports}	
								isSelected={expandedMenuSelection === "Organisation Reports"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>
								{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<SideMenuOption
									icon={<SummaryReportIconMenu />}
									optionName={"Summary Report"}
									displayName={allResourcesToTranslations.summaryreport}
									isSelected={menuSelection === "Summary Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/summaryreport"
									isNested
									key={createGuid()}
								/>}
								{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
								<SideMenuOption
									icon={<SaltCertificatesReportIconMenu />}
									optionName={"Salt Completion Certificates Report"}
									displayName={allResourcesToTranslations.saltcertificatesreportmenu}
									isSelected={menuSelection === "Salt Completion Certificates Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/saltcertificatesreport"
									isNested
									key={createGuid()}
								/>}
							</SideMenuOptionExpandable>}
							{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							<SideMenuOptionExpandable
								icon={<MyReportIconMenu />}
								optionName={"Email Sending Reports"}
								displayName={allResourcesToTranslations.emailsendingreport}
								isSelected={expandedMenuSelection === "Email Sending Reports"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>
								<SideMenuOption
									icon={<TemplateIconMenu />}
									optionName={"Email Report"}
									displayName={allResourcesToTranslations.emailreport}
									isSelected={menuSelection === "Email Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/emailreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<SentEmailIconMenu />}
									optionName={"Sent Email Report"}
									displayName={allResourcesToTranslations.sentemailreport}
									isSelected={menuSelection === "Sent Email Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/sentemailreport"
									isNested
									key={createGuid()}
								/>
							</SideMenuOptionExpandable>}
							{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							<SideMenuOptionExpandable
								icon={<MyReportIconMenu />}
								optionName={"Administration Reports"}
								displayName={allResourcesToTranslations.administrationreports}
								isSelected={expandedMenuSelection === "Administration Reports"}
								customClickEvent={handleExpandedSelection}
								sideMenuOpen={sideMenuOpen}
								showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
								//noOfChildrenToRemove={0}
							>
								<SideMenuOption
									icon={<AccountSettingIconMenu />}
									optionName={"Account Detail Report"}
									displayName={allResourcesToTranslations.userdetailreport}
									isSelected={menuSelection === "Account Detail Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/userdetailreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<UnitSettingsIconMenu />}
									optionName={"Unit Pathway Report"}
									displayName={allResourcesToTranslations.unitpathwayreport}
									isSelected={menuSelection === "Unit Pathway Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/unitpathwayreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<UnitComplianceIconMenu />}
									optionName={"Unit Compliance Report"}
									displayName={allResourcesToTranslations.unitcompliancereport}
									isSelected={menuSelection === "Unit Compliance Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/unitcompliancereport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption
									icon={<AdminIconMenu />}
									optionName={"Unit Admin Report"}
									displayName={allResourcesToTranslations.unitadminreport}
									isSelected={menuSelection === "Unit Admin Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/unitadministratorreport"
									isNested
									key={createGuid()}
								/>
								<SideMenuOption 
									icon={<LicenseReportMenuIcon />} 
									optionName={"License Report"}
									displayName={allResourcesToTranslations.licensereport}
									isSelected={menuSelection === "License Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/licensingreport"
									isNested
									key={createGuid()}
								/>
							</SideMenuOptionExpandable >}
							{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							 IsCPDEnabled ?
								<SideMenuOptionExpandable
									icon={<MyReportIconMenu />}
									optionName={"CPD Reports"}
									displayName={allResourcesToTranslations.cpdreports}								
									isSelected={expandedMenuSelection === "CPD Reports"}
									customClickEvent={handleExpandedSelection}
									sideMenuOpen={sideMenuOpen}
									showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
									//noOfChildrenToRemove={0}
								>
									<SideMenuOption
										icon={<CpdProfileIconMenu />}
										optionName={"CPD Report"}
										displayName={allResourcesToTranslations.cpdreport}									
										isSelected={menuSelection === "CPD Report"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/cpdreport"
										isNested
										key={createGuid()}
									/>
								</SideMenuOptionExpandable> : ""
							}						
							{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							IsPolicyEnabled ?
								<SideMenuOptionExpandable
									icon={<MyReportIconMenu />}
									optionName={"Policy Reports"}
									displayName={allResourcesToTranslations.policyreports}
									isSelected={expandedMenuSelection === "Policy Reports"}
									customClickEvent={handleExpandedSelection}
									sideMenuOpen={sideMenuOpen}
									showAdminMenuItems={(showUnitAdminUI || showOrgAdminUI || showSuperAdminUI)}
									//noOfChildrenToRemove={0}
								>
									<SideMenuOption
										icon={<PolicyBuilderIconMenu />}
										optionName={"Policy Builder Report"}
										displayName={allResourcesToTranslations.policybuilderreport}
										isSelected={menuSelection === "Policy Builder Report"}
										customClickEvent={handleSelection}
										sideMenuOpen={sideMenuOpen}
										route="/policybuilderreport"
										isNested
										key={createGuid()}
									/>
								</SideMenuOptionExpandable> : ""
							}

							{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							<SideMenuOption
								icon={<MyReportIconMenu />}
								optionName={"Scheduled Reports"}
								displayName={allResourcesToTranslations.scheduledreports}
								isSelected={menuSelection === "Scheduled Reports"}
								customClickEvent={handleSelection}
								sideMenuOpen={sideMenuOpen}
								route="/reportschedulerlist"
							/>}	

							{!(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) &&
							IsExtCertEnabled && (showOrgAdminUI || showSuperAdminUI) &&
							<SideMenuOption
									icon={<ExternalCertificateMenuIcon />}
									optionName={"External Certificates Report"}
									displayName={allResourcesToTranslations.extcertificatereport}
									isSelected={menuSelection === "External Certificates Report"}
									customClickEvent={handleSelection}
									sideMenuOpen={sideMenuOpen}
									route="/externalcertificatesreport"						
								
								/>
							}
						</SideMenuReports>
					}	
				</div>
			</div>
		</>
	);
};

export default SideMenu;