import axios from 'axios';
import { addError } from './AddError';

export const generateCertificate = async (token: string, userId: number, orgDomain: string, 
                                          courseId: number, currentCulture: string, targetFolder: string, 
                                          color: string, selectedProfileID: number, selectedProfilePeriodID: number,
                                          calledFromSaltCertificateReport: boolean = false, 
                                          forcedCertificateName: string = "", userIDForCertificateReport: number = -1,
                                          uniqueKey: string = "", repeatSeq: number = 1, inputFilterSummary: string = ""
                                        ) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/GenerateCertificate`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        userId : userId,
        courseId : courseId,
        currentCulture: currentCulture,
        sendCertificate: false,
        targetFolder: targetFolder,
        color: color,
        SelectedProfileID: selectedProfileID,
        SelectedProfilePeriodID: selectedProfilePeriodID,
        CalledFromSaltCertificateReport: calledFromSaltCertificateReport,
        ForcedCertificateName: forcedCertificateName,
        UserIDForCertificateReport: userIDForCertificateReport,
        UniqueKey: uniqueKey,
        RepeatSeq: repeatSeq,
        InputFilterSummary: inputFilterSummary
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GenerateCertificate.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GenerateCertificate.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GenerateCertificate.ts", 1)
    }
    return null;
  }
};

export default generateCertificate