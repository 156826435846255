import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const MyNewsfeedMenuIcon = () => (    
<svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.29411 12.8333H19.2108V13.75H7.29411V12.8333ZM19.2108 10.0833H7.29411V11H19.2108V10.0833ZM19.2108 4.58333H14.6274V5.5H19.2108V4.58333ZM19.2108 7.33333H14.6274V8.25H19.2108V7.33333ZM3.62744 0V15.7657C3.62744 16.4725 2.71077 16.4725 2.71077 15.7657V1.83333H0.877441V16.4817C0.877441 17.5037 1.70702 18.3333 2.72911 18.3333H21.0239C22.0479 18.3333 22.8774 17.5047 22.8774 16.4817V0H3.62744ZM21.0441 15.5833H5.46077V1.83333H21.0441V15.5833ZM12.7941 4.58333H7.29411V8.25H12.7941V4.58333Z" fill="white"/>
</svg>


);

export default MyNewsfeedMenuIcon
