//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { SentEmailReportResultItem } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps } from "intro.js-react";
//Context
import { LoaderContext } from '../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip';
import ReportSaveModal from '../../Components/CommonPopupModalForResult';
//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
import { get2DigitDayOrMonth } from '../../functions/get2DigitDayOrMonth'
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'
import { sendOrGetFullSentEmailReport } from '../../API/SendOrGetFullSentEmailReport' 
import { addError } from '../../API/AddError'
//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon'
import CSVFileIcon from '../../CustomIcons/CSVFileIcon'
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import EmailIcon from '@material-ui/icons/EmailOutlined';
// Style
import styles from '../../styles/currentAdminReport.module.css'

const SentEmailReportResult = (props: any) => {    
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc(); 
    const { profile: { user_id, family_name, given_name,website }} = oidcUser || {};
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const { theme: { color }} = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    const [adminReportResult, setAdminReportResult] =  useState<Array<SentEmailReportResultItem>>(props?.location?.state?.resultItems ?? [])
    const [inputFilterFromDate, setInputFilterFromDate] =  useState<string>(props?.location?.state?.inputFilterFromDate ?? "")
    const [inputFilterToDate, setInputFilterToDate] =  useState<string>(props?.location?.state?.inputFilterToDate ?? "")
    const [inputFilterToEmail, setInputFilterToEmail] =  useState<string>(props?.location?.state?.inputFilterToEmail ?? "")
    const [inputFilterSubject, setInputFilterSubject] =  useState<string>(props?.location?.state?.inputFilterSubject ?? "")
    const [inputFilterBody, setInputFilterBody] =  useState<string>(props?.location?.state?.inputFilterBody ?? "")
    const [resultError, setResultError] =  useState<string>(props?.location?.state?.resultError ?? "")
    const [exporting, setExporting] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [dateFromDescending, setDateFromDescending] = useState<boolean>(true)
    const [toEmailDescending, settoEmailDescending] = useState<boolean>(true)    
    const [bccDescending, setbccDescending] = useState<boolean>(true)  
    const [subjectDescending, setSubjectDescending] = useState<boolean>(true)
    const [bodyDescending, setBodyDescending] = useState<boolean>(true)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const [showModal,setModalOpen] = useState<boolean>(false)
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const [exportError, setExportError] = useState<string>("")

    // Modal Pop-up related  
    const [resendEmailStatus, setResendEmailStatus] = React.useState(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);
   
    const [loading, setLoading] = useState<boolean>(true);
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".sentemailReportList",
            title: allResourcesToTranslations.emrepprvgmtrepdta,
            intro: allResourcesToTranslations.emrepprvgmmrepdta,
          },      
        ]
      })

    useEffect(() => 
    {
        let updateSteps = [...intro.steps]
        updateSteps.push(                         
            {
                element: ".xlxs",
                title: allResourcesToTranslations.sntemrepgmtexpxls,
                intro: allResourcesToTranslations.sntemrepgmmexpxls,
            },
            {
                element: ".csv",
                title: allResourcesToTranslations.sntemrepgmtexpcsv,
                intro: allResourcesToTranslations.sntemrepgmmexpcsv,
            }         
        )
        setIntro({...intro, steps: [...updateSteps]})              
    },[])
      
    const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
    };
    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : ""}
        } else {
            return {}
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const GetDate_yymmdd = () => {
        var d =  new Date(Date.now());
        let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
         return day;         
      }
    
    const buildRequestStringArray = () => {
        let resultArray : Array<string> = []
        for (let i = 0; i < adminReportResult.length; i++) {
            const r = adminReportResult[i]
            resultArray.push(`${r.dateCreated}<#>${r.toEmail}<#>${r.subject}<#>${r.body}`)
        }
        return resultArray
    }

    const buildRequestStringArrayDatetxt = () => {
        let resultArray : Array<string> = []
        for (let i = 0; i < adminReportResult.length; i++) {
            const r = adminReportResult[i]           
            resultArray.push(`${r.dateCreatedText}<#>${r.toEmail}<#>${r.subject}<#>${r.body}`)
        }
        return resultArray
    }

    const buildRequestStringArrayFormatted = () => {
        let resultArray : Array<string> = []
        for (let i = 0; i < adminReportResult.length; i++) {
            const r = adminReportResult[i]
            resultArray.push(`${r.dateCreated}<#>${r.toEmail}<#>${r.subject}<#>${r.body}`)
        }
        return resultArray
    }

    const handleXLSXExport = () => {
        setMessage(allResourcesToTranslations.sntemrepgmmxlsm);
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started SentEmail Report XLSX-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);

        setExporting(true)
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(),  4, "sentemailreport", enableRowBanding,"","",inputFilterFromDate,inputFilterToDate,false,false, "","","","",inputFilterToEmail,inputFilterSubject, inputFilterBody).then((xlxsRes) => {            
            if (xlxsRes.isSuccess) {
                const fileData = xlxsRes.xlsxDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "SentEmailReport-"+ GetDate_yymmdd() + ".xlsx";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
                setExportError(xlxsRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed SentEmail Report XLSX-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExport = () => {
        setMessage(allResourcesToTranslations.sntemrepgmmcsvm);
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started SentEmail Report CSV-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);

        setExporting(true)
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArrayDatetxt(), 4, "sentemailreport","","",inputFilterFromDate,inputFilterToDate,false,false,"",false,"","","",inputFilterToEmail,inputFilterSubject, inputFilterBody).then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "SentEmailReport-"+ GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed SentEmail Report CSV-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExportRowsFixed = () => {
        setMessage(allResourcesToTranslations.sntemrepgmmcsvrfm);
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started SentEmail Report Fixed Rows CSV-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);

        setExporting(true)
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArrayFormatted(), 4, "sentemailreport","","",inputFilterFromDate,inputFilterToDate,false,false, "", true,"","","",inputFilterToEmail,inputFilterSubject,inputFilterBody).then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: 'text/html;charset=utf-8'
                    })
                )
                a.download = "SentEmailReport-FixedRows-"+ GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed SentEmail Report Fixed Rows CSV-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handlePDFXExport = () => {
        setMessage(allResourcesToTranslations.sntemrepgmmpdfm);
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started SentEmail Report PDF-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);

        setExporting(true)
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 4, "sentemailreport", enableRowBanding,"","",inputFilterFromDate,inputFilterToDate,false,false, "","","","",inputFilterToEmail,inputFilterSubject,inputFilterBody).then((pdfRes) => {            
             if (pdfRes.isSuccess) {
                const fileData = pdfRes.pdfDataOutput.fileData
                var a = window.document.createElement('a');
                //console.log(fileData);
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "SentEmailReport-"+ GetDate_yymmdd() + ".PDF";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

             }
             else if (pdfRes.error !== undefined && pdfRes.error !== null) {
                 setExportError(pdfRes.error);
             }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed SentEmail Report PDF-EXPORT in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleClosePopup = () => {
      setShowWarningModal(false);
    };

    const handleReSendEmail = (newEmailId: number) =>{
        setShowWarningModal(false);
        setModalPopupMessage('Sending Email in progress...');
        setMessage(allResourcesToTranslations.sntemrepgmsndem);
        setExportError("");
        const r = adminReportResult[0];      
        sendOrGetFullSentEmailReport(oidcUser.access_token, userId,orgDomain, parseInt(orgId), inputFilterFromDate, inputFilterToDate,
                inputFilterToEmail, inputFilterSubject, inputFilterBody, newEmailId, "resend").then((result) => {
            if (!!result && result.isSuccess && (result.error === null || result.error.toString().trim() === "")) {
                if (!!result.sentemailreportItem) {
                    setAdminReportResult(result.sentemailreportItem);
                } 
                setShowWarningModal(true);
                setResendEmailStatus(true);
                setHasErrored(false);
                setModalPopupMessage('Resend email is successful!');
            } else {
                setShowWarningModal(true);
                setResendEmailStatus(false);
                setHasErrored(true);
                setModalPopupMessage('Resend email has failed!' + ((!!result && !!result.error) ? (' Reason: ' + result.error.toString().trim()) : ''));
            }            
        })
    }
   
    const sortResultsByAsync = async (sortBy: "DateSent" | "To" | "CC" | "Subject" | "Body", sortGivenItemInDesc: boolean ) => {
        if (adminReportResult == null || adminReportResult.length <= 0) {
            return adminReportResult;
        }
        var result = adminReportResult.slice(0)
        setCurrentSortBy(sortBy)
        switch(sortBy) {
            case "DateSent":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.createdDate === null) { return -1; }
                        if (a === null || a.createdDate === null) { return 1; }
                        if(a.createdDate > b.createdDate) { return -1; }
                        if(a.createdDate < b.createdDate) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateFromDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.createdDate === null) { return 1; }
                        if (a === null || a.createdDate === null) { return -1; }
                        if(a.createdDate < b.createdDate) { return -1; }
                        if(a.createdDate > b.createdDate) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateFromDescending(true) 
                    }
                }
                setAdminReportResult(result);
                break;
            case "To":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.toEmail === null) { return -1; }
                        if (a === null || a.toEmail === null) { return 1; }
                        if(a.toEmail.toLowerCase() > b.toEmail.toLowerCase()) { return -1; }
                        if(a.toEmail.toLowerCase() < b.toEmail.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        settoEmailDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.toEmail === null) { return 1; }
                        if (a === null || a.toEmail === null) { return -1; }
                        if(a.toEmail.toLowerCase() < b.toEmail.toLowerCase()) { return -1; }
                        if(a.toEmail.toLowerCase() > b.toEmail.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        settoEmailDescending(true) 
                    }
                }
                setAdminReportResult(result);
                break;  
            case "CC":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.bcc === null) { return -1; }
                        if (a === null || a.bcc === null) { return 1; }
                        if(a.bcc.toLowerCase() > b.bcc.toLowerCase()) { return -1; }
                        if(a.bcc.toLowerCase() < b.bcc.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setbccDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.bcc === null) { return 1; }
                        if (a === null || a.bcc === null) { return -1; }
                        if(a.bcc.toLowerCase() < b.bcc.toLowerCase()) { return -1; }
                        if(a.bcc.toLowerCase() > b.bcc.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setbccDescending(true) 
                    }
                }
                setAdminReportResult(result);
                break;         
            case "Subject":
                if (subjectDescending) {
                    result.sort((a, b) => {
                        if (b === null || b.subject === null) { return -1; }
                        if (a === null || a.subject === null) { return 1; }
                        if(a.subject.toLowerCase() > b.subject.toLowerCase()) { return -1; }
                        if(a.subject.toLowerCase() < b.subject.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setSubjectDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.subject === null) { return 1; }
                        if (a === null || a.subject === null) { return -1; }
                        if(a.subject.toLowerCase() < b.subject.toLowerCase()) { return -1; }
                        if(a.subject.toLowerCase() > b.subject.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setSubjectDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Body":
                if (bodyDescending) {
                    result.sort((a, b) => {
                        if (b === null || b.body === null) { return -1; }
                        if (a === null || a.body === null) { return 1; }
                        if(a.body.toLowerCase() > b.body.toLowerCase()) { return -1; }
                        if(a.body.toLowerCase() < b.body.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setBodyDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.body === null) { return 1; }
                        if (a === null || a.body === null) { return -1; }
                        if(a.body.toLowerCase() < b.body.toLowerCase()) { return -1; }
                        if(a.body.toLowerCase() > b.body.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setBodyDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            default:
                break;
        }

        return result.slice(0);
    }
   
    const sortResultsBy = async (sortBy: "DateSent" | "To" | "CC" | "Subject" | "Body", sortGivenItemInDesc: boolean ) => {
        setSortInProgress(true);
        setExportError("");
        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
            setCurrentSortBy(sortBy);
            setAdminReportResult(result);
            setPage(0);
            setSortInProgress(false);
        });
    }
    
    const clickDateSentAsc = () => {
        setReverseNextSort(false);
        setDateFromDescending(false);
        sortResultsBy("DateSent", false);
    }

    const clickDateSentDesc = () => {
        setReverseNextSort(false);
        setDateFromDescending(true);
        sortResultsBy("DateSent", true);
    }

    const clickToEmailAsc = () => {
        setReverseNextSort(false);
        settoEmailDescending(false);        
        sortResultsBy("To", false);
    }

    const clickToEmailDesc = () => {
        setReverseNextSort(false);
        settoEmailDescending(true);
        sortResultsBy("To", true);
    }

    const clickBodyAsc = () => {
        setReverseNextSort(false);
        setBodyDescending(false);        
        sortResultsBy("Body", false);
    }

    const clickBodyDesc = () => {
        setReverseNextSort(false);
        setBodyDescending(true);
        sortResultsBy("Body", true);
    }

    const clickSubjectAsc = () => {
        setReverseNextSort(false);
        setSubjectDescending(false);
        sortResultsBy("Subject", false);
    }

    const clickSubjectDesc = () => {
        setReverseNextSort(false);
        setSubjectDescending(true);
        sortResultsBy("Subject", true);
    }

    const clickCCAsc = () => {
        setReverseNextSort(false);
        setbccDescending(false);
        sortResultsBy("CC", false);
    }

    const clickCCDesc = () => {
        setReverseNextSort(false);
        setbccDescending(true);
        sortResultsBy("CC", true);
    }

    const initialSort = () => {         
        setDateFromDescending(true)
        settoEmailDescending(false)
        setbccDescending(false)
        setSubjectDescending(false)
        setCurrentSortBy("DateSent")
        getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
            if (res.isSuccess) {
              let curdt = res.currentDateTimeText;
              setReportRunTimeTaken(curdt);
            } else {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
            }
           }).catch ((error) => {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
           });
        setPage(0)
    }

    const handleModal = (value:boolean) => {
        setModalOpen(value);
    }
 
    const GetInputFilterFromDate = () => {         
        let curValue  = inputFilterFromDate != null ? inputFilterFromDate : ""; 
        if (curValue !== "" && curValue !== undefined && curValue !== null) {
            const tokens = curValue.split("-");
            if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                curValue = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
            }
        }
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{allResourcesToTranslations.sntemrepgmmdtfrml}</span>
                             <span className={styles.reportDetail}> {curValue}</span></div> : ""       
     }

    const GetInputFilterToDate = () => {             
        let curValue  = inputFilterToDate != null ? inputFilterToDate : ""; 
        if (curValue !== "" && curValue !== undefined && curValue !== null) {
            const tokens = curValue.split("-");
            if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                curValue = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
            }
        }
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.sntemrepgmmdttol}</span>
                                <span className={styles.reportDetail}> {curValue}</span></div> : ""
    }

    const GetInputFilterEmail = () => {             
        let curValue  = inputFilterToEmail != null ? inputFilterToEmail : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.emrepprvgmtembod}</span>
                                <span className={styles.reportDetail}> {inputFilterToEmail}</span></div> : "" 
    }

    const GetInputFilterSubject = () => {           
        let curValue  = inputFilterSubject != null ? inputFilterSubject : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.emrepprvgmtsub}</span>
                                <span className={styles.reportDetail}> {inputFilterSubject}</span></div> : "" 
    }

    const GetInputFilterBody = () => {              
        let curValue  = inputFilterBody != null ? inputFilterBody : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.sntemrepgmmbdyl}</span>
                                <span className={styles.reportDetail}> {inputFilterBody}</span></div> : ""
    }

    useEffect(() => {
      if (setResendEmailStatus && resendEmailStatus) {
        setTimeout(() => {
          setShowWarningModal(false);
          setResendEmailStatus(false);
        }, 5000)
      }
    }, [showWarningModal])

    useEffect(() => {
        initialSort()
        setLoading(false)
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Completed SentEmail Report in NEWSALT ClientApp", null, "SentEmailReportResult.tsx", 6, EnableActivityLogs);
    },[])
   
    if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0)
    {
        setTitle(allResourcesToTranslations.sentemailreport)
        setTitleEnglish(pageTitles.sentemailreport)
        updateBackButtonRoute("/sentemailreport");
        //setExportError("");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div style={{color:"red"}}>{allResourcesToTranslations.sntemrepgmmerrl + ": " + resultError}</div>
                <br/>
                <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>
            </div>
        </BrandedCard>)
    }
    else if (adminReportResult === null || adminReportResult.length < 1) {
        setTitle(allResourcesToTranslations.sentemailreport)
        setTitleEnglish(pageTitles.sentemailreport)
        updateBackButtonRoute("/sentemailreport");
        //setExportError("");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
                <br/>
                <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>
            </div>
        </BrandedCard>)
    } else {
        setTitle(allResourcesToTranslations.sentemailreport)
        setTitleEnglish(pageTitles.sentemailreport)
        updateBackButtonRoute("/sentemailreport");
    }

    if (exporting) return <LoaderContainer/>
    
    return (
        <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={[allResourcesToTranslations.guideme]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>       
           	<div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>                        
                                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.reportyrunby}</span>
                                <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>   
                                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.reportruntime}</span>
                                <span className={styles.reportDetail}>
                                    { reportRunTimeTaken }
                                </span>     
                        </div>                       
                        { GetInputFilterFromDate() }     
                        { GetInputFilterToDate() }  
                        { GetInputFilterEmail() }  
                        { GetInputFilterSubject() }                 
                        { GetInputFilterBody() } 
                    </div>                         
                    <div className={styles.reportExportContainer}>                       
                            <CustomTooltip displayText={[allResourcesToTranslations.exporttoxlsx]}>                                    
                                    <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltSERReportExportXLSX">
                                        <ExcelFileIcon/>
                                    </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={[allResourcesToTranslations.exporttocsv]}>         
                                <Button  className="csv" color="primary"  onClick={handleCSVExport} id="btnNewSaltSERReportExportCSV">
                                    <CSVFileIcon/>
                                </Button>
                            </CustomTooltip>
                       
                    </div>
                </div>               
            </div>
            </BrandedCard>
            <hr/>
            {<div style={{color:"red"}}>{(exportError === undefined || exportError === null  || exportError === "") ? "" : (allResourcesToTranslations.sntemrepgmmexerrm + ": " + exportError)}</div>}
            <hr/>
            <div className={ styles.paginationControlsContainer }>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={adminReportResult.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    </div> 
            <div className={styles.tableSection}>
                <div className={styles.emailReportContainer}> 
                    <BrandedCard>
                        <TableContainer>
                            <Table>
                                <TableHead className="sentemailReportList">
                                    <TableRow>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", width:"150px"  }} align="center">
                                            <div className={styles.tableHeadCellContainer}
                                                onClick={() => 
                                                    {
                                                        setDateFromDescending(!dateFromDescending);
                                                        sortResultsBy("DateSent", !dateFromDescending);
                                                    }}>
                                                {allResourcesToTranslations.sntemrepgmmdtsntl}
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "DateSent" && dateFromDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickDateSentAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "DateSent" && dateFromDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickDateSentDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>                                     
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                            <div className={styles.tableHeadCellContainer}
                                                onClick={() => 
                                                    {
                                                        settoEmailDescending(!toEmailDescending);
                                                        sortResultsBy("To", !toEmailDescending);
                                                    }}>
                                                {allResourcesToTranslations.sntemrepgmmemtgtl}
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "To" && toEmailDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickToEmailAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "To" && toEmailDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickToEmailDesc}><DownArrowIcon /> </span>
                                                </div>
                                           </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                            <div className={styles.tableHeadCellContainer}
                                                onClick={() => 
                                                    {
                                                        setSubjectDescending(!subjectDescending);
                                                        sortResultsBy("Subject", !subjectDescending);
                                                    }}>
                                                {allResourcesToTranslations.emrepprvgmtsub}
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "To" && subjectDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickSubjectAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "To" && subjectDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickSubjectDesc}><DownArrowIcon /> </span>
                                                </div>
                                           </div>
                                        </TableCell>                                        
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                                            <div className={styles.tableHeadCellContainer}
                                                onClick={() => 
                                                    {
                                                        setBodyDescending(!bodyDescending);
                                                        sortResultsBy("Body", !bodyDescending);
                                                    }}>
                                                {allResourcesToTranslations.sntemrepgmmbdyl}
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "Body" && bodyDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickBodyAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "Body" && bodyDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickBodyDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell> 
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="center">
                                            {allResourcesToTranslations.action}
                                        </TableCell>                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        adminReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: SentEmailReportResultItem, i ) => {
                                            return(
                                                <TableRow key={`sentemailreportresult-${i}`} style={rowBackgroundColor(i)}>                                                
                                                <TableCell align="left"> 
                                                    {result.dateCreatedText}
                                                </TableCell>                                                
                                                <TableCell align="left">
                                                    {result.toEmail}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.subject}
                                                </TableCell>
                                                <TableCell align="left">
                                                 <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: result.body //bodyFormatted
                                                    }}></div>                                                                                                     
                                                </TableCell>  
                                                <TableCell className="hideForPrint history" align="center" >                                                    
                                                    <CustomTooltip displayText={[allResourcesToTranslations.sntemrepgmreseml]}>  
                                                        <Button variant="contained" color="primary" onClick={() => { handleReSendEmail(result.emailid)}}>
                                                        <EmailIcon/>
                                                        </Button>
                                                    </CustomTooltip>                                                   
                                                </TableCell>                                             
                                            </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>
                </div>
            </div>
            <div className={ styles.paginationControlsContainer }>
                <TablePagination
                    component="div"
                    count={adminReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
            {showWarningModal && <ReportSaveModal saveSuccess={resendEmailStatus} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={resendEmailStatus ? 'Success' : hasErrored ? 'Error' : 'Resend Email'} bodyText={modalPopupMessage} />}
        </>
      );    
}

export default SentEmailReportResult
