//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from '../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip'
//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
import { get2DigitDayOrMonth } from '../../functions/get2DigitDayOrMonth'
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'
import { addError } from '../../API/AddError'
//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon';
import CSVFileIcon from '../../CustomIcons/CSVFileIcon';
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import BrushIcon from '@material-ui/icons/Brush';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


// Style
import styles from '../../styles/currentAdminReport.module.css'

const WarningReportResult = (props: any) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, family_name, given_name, website  }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const { theme: { color }} = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    const [warningReportResult, setwarningReportResult] = useState<Array<any>>(props?.location?.state?.resultItems ?? [])
    const [inputFilterIncludeInactive, setInputFilterIncludeInactive] = useState<string>(props?.location?.state?.inputFilterIncludeInactive ?? "")
   
    const [exporting, setExporting] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [courseDescending, setCourseDescending] = useState<boolean>(true)
    const [unitDescending, setUnitDescending] = useState<boolean>(true)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const [exportError, setExportError] = useState<string>("")
    const [resultError, setResultError] =  useState<string>(props?.location?.state?.resultError ?? "")

    const [isLengthyCoursePresent, setIsLengthyCoursePresent] = useState<boolean>(false)

    //const toggleBanding = () => setEnableRowBanding(!enableRowBanding)
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".warningreportList",
            title: "Warning Data", intro: "Unit Pathway and Course Name are sortable by clicking on the column title or relevant arrows."        
          },      
        ]
      })

      useEffect(() => {
        if (!!warningReportResult && warningReportResult.length > 0) {
            setIsLengthyCoursePresent(warningReportResult.some((item) => !!item.courseName && item.courseName.length > 100))
        }
      }, [warningReportResult]);

      useEffect(() => 
      {
                    let updateSteps = [...intro.steps]
                    updateSteps.push( 
                    {
                    element: ".xlxs",
                    title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
                    },
                    {
                    element: ".csv",
                    title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
                    },
                    {
                        element: ".pdf",
                        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
                    }
                    // {
                    //     element: ".rowShading",
                    //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
                    // }               
         )      
      
                setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };
      
      const rowBackgroundColor = (rowBackgroungColourValue: string) => {
        if (enableRowBanding) {
            return { backgroundColor: rowBackgroungColourValue };
        } else {
            return {};
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    
    const GetDate_yymmdd = () => {
        var d =  new Date(Date.now());
        let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
        return day;   
    }
    
    const buildRequestStringArray = () => {
        let resultArray : Array<string> = []
        const r = warningReportResult[0]      

        for (let i = 0; i < warningReportResult.length; i++) {
            const r = warningReportResult[i];
            if (r.grandTotalRow) {
                resultArray.push(`${"Grand Total"}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${""}<#>${r.courseName}<#>${r.rowBackGroundColour}`)
            } else if (!r.grandTotalRow) {
                resultArray.push(`${r.showUnit ? r.unitPathway : ""}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.showCourse ? r.courseName : ""}<#>${r.completionDate}<#>${r.expiryDate}<#>${r.totalAccount + " Accounts"}<#>${r.rowBackGroundColour}`)
            }
        }
        return resultArray
    }

    const handleXLSXExport = () => {
        setMessage("Exporting XLSX");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Warning Report XLSX-EXPORT in NEWSALT ClientApp", null, "WarningReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = warningReportResult[0]
        let ClassificationValue  = r.classificationValue;
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(),  10, "warningreport", enableRowBanding, ClassificationValue, "","","", inputFilterIncludeInactive === "Yes", false, "", "", "", "", "").then((xlxsRes) => {            
            if (xlxsRes.isSuccess) {
                const fileData = xlxsRes.xlsxDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download =  "WarningReport - "+ GetDate_yymmdd() + ".xlsx" ;
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
                setExportError(xlxsRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Warning Report XLSX-EXPORT in NEWSALT ClientApp", null, "warningreportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExport = () => {
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Warning Report CSV-EXPORT in NEWSALT ClientApp", null, "WarningReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = warningReportResult[0]
        let ClassificationValue  = r.classificationValue;
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 10, "warningreport", ClassificationValue,"","","", inputFilterIncludeInactive === "Yes", false, "",false, "", "", "", "").then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "WarningReport - " +  GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Warning Report CSV-EXPORT in NEWSALT ClientApp", null, "WarningReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handlePDFXExport = () => {
        setMessage("Exporting PDF");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started Warning Report PDF-EXPORT in NEWSALT ClientApp", null, "WarningReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = warningReportResult[0]
        let ClassificationValue  = r.classificationValue;       
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 10, "warningreport", enableRowBanding, ClassificationValue,"","","", inputFilterIncludeInactive === "Yes", false, "", "", "", "", "").then((pdfRes) => {            
            if (pdfRes.isSuccess) {
                const fileData = pdfRes.pdfDataOutput.fileData
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "WarningReport - " + GetDate_yymmdd() + ".PDF";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)
            }
            else if (pdfRes.error !== undefined && pdfRes.error !== null) {
                setExportError(pdfRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed Warning Report PDF-EXPORT in NEWSALT ClientApp", null, "WarningReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const sortResultsByAsync = async (sortBy: "Course" | "FirstName" | "LastName" | "Email" | "ExternalID" |  "Unit" | "CompletionDate" | "ExpiryDate", sortGivenItemInDesc: boolean ) => {
        if (warningReportResult == null || warningReportResult.length <= 0) {
            return warningReportResult;
        }
        var result = warningReportResult.slice(0)
        setCurrentSortBy(sortBy)
        switch(sortBy) {
            case "Course":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b === null || b.courseName === null) { return -1; }
                        if (a === null || a.courseName === null) { return 1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return -1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b === null || b.courseName === null) { return 1; }
                        if (a === null || a.courseName === null) { return -1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return 1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(true)
                    }
                }
                setwarningReportResult(result);
                break;
            case "Unit":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b === null || b.unitPathway === null) { return -1; }
                        if (a === null || a.unitPathway === null) { return 1; }
                        if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return -1; }
                        if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return 1; }
                        return 0;
                        //return a.unitID-b.unitID
                    })
                    if (reverseNextSort) {
                        setUnitDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (a !== null && a.unitPathway !== null && a.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b !== null && b.unitPathway !== null && b.unitPathway.trim().toLowerCase() === "grand total" ) {
                            return 0;
                        } 
                        if (b === null || b.unitPathway === null) { return 1; }
                        if (a === null || a.unitPathway === null) { return -1; }
                        if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return 1; }
                        if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return -1; }
                        return 0;
                        //return a.unitID+b.unitID
                    })
                    if (reverseNextSort) {
                        setUnitDescending(true)
                        }
                    }
                    setwarningReportResult(result);
                    break;
           default:
        }

        return result.slice(0);
    }
   
    const sortResultsBy = async (sortBy: "Course" | "Unit" , sortGivenItemInDesc: boolean ) => {

        setSortInProgress(true);
        setExportError("");
        
        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
            setCurrentSortBy(sortBy)
            setwarningReportResult(result)
            setPage(0)
            setSortInProgress(false);
        });
    }
    
    const clickUnitAsc = () => {
        setReverseNextSort(false);
        setUnitDescending(false)
        sortResultsBy("Unit", false)
    }

    const clickUnitDesc = () => {
        setReverseNextSort(false);
        setUnitDescending(true)
        sortResultsBy("Unit", true)
    }

    const clickCourseAsc = () => {
        setReverseNextSort(false);
        setCourseDescending(false);
        sortResultsBy("Course", false);
    }

    const clickCourseDesc = () => {
        setReverseNextSort(false);
        setCourseDescending(true)
        sortResultsBy("Course", true)
    }

    const initialSort = () => {
        setCurrentSortBy("Unit");
        setUnitDescending(false);
        getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
            if (res.isSuccess) {
              let curdt = res.currentDateTimeText;
              setReportRunTimeTaken(curdt);
            } else {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
            }
           }).catch ((error) => {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
           });
        setPage(0)
    }

    useEffect(() => {
        initialSort()
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Completed warning Report in NEWSALT ClientApp", null, "warningreportResult.tsx", 6, EnableActivityLogs);
    },[])

    const GetClassificationname = () => {        
        const r = warningReportResult[0]
        let classificationValue  = r.classificationValue != null ? r.classificationValue : "";
 
        return classificationValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Grouping Option" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {r.classificationValue}</span></div> : ""
          
        
     }
    const GetBlankRowData = (lastRow: boolean, textToDisplay: string) => {       
        return lastRow ? <div> <span> { textToDisplay }</span> <br />&nbsp;</div> :<div> <span>  { textToDisplay }</span> </div>
    }   

    if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0)
    {
        setTitle(allResourcesToTranslations.warningreport)
        setTitleEnglish(pageTitles.warningreport)
        updateBackButtonRoute("/warningreport");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div style={{color:"red"}}>Error: {resultError}</div>
                <br/>
                <div>Select the Back Button to return to Previous Page</div>
            </div>
        </BrandedCard>)
    }
    else if (warningReportResult === null || warningReportResult.length < 1) {
        setTitle(allResourcesToTranslations.warningreport)
        setTitleEnglish(pageTitles.warningreport)
         updateBackButtonRoute("/warningreport");
            return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div>No Results to Display</div>
                <div>Select the Back Button to return to Previous Page</div>
                </div>
                </BrandedCard>)
    } else {
        setTitle(allResourcesToTranslations.warningreport)
        setTitleEnglish(pageTitles.warningreport)
        updateBackButtonRoute("/warningreport");
    }
 
    if (exporting) return <LoaderContainer/>
    
    return (
        <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>       
                <div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}>
                                { reportRunTimeTaken }
                            </span>
                        </div>
                        { GetClassificationname() }                            
                    </div>   
                   
                    <div className={styles.reportExportContainer}>
                            <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                <Button  className="xlxs"  color="primary" onClick={handleXLSXExport} id="btnNewSaltCSRReportExportXLSX">
                                    <ExcelFileIcon/>
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                <Button  className="csv" color="primary"  onClick={handleCSVExport} id="btnNewSaltCSRReportExportCSV">
                                    <CSVFileIcon/>
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCSRReportExportPDF">
                                    <PDFIcon/>
                                </Button>
                            </CustomTooltip>
                            {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading"  color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/> 
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                    
                </div>               
            </div>
            </BrandedCard>
            <hr/>
            {<div style={{color:"red"}}>{(exportError === undefined || exportError === null  || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}
            <hr/>
            <div className={ styles.paginationControlsContainer }>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={warningReportResult.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    </div> 
            <div className={styles.tableSection}>
                <div className={styles.tableContainer}> 
                    <BrandedCard>
                        <TableContainer>
                            <Table>
                                <TableHead className="warningreportList">
                                <TableRow>
                                    
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", width:"100px" }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                        <div 
                                                        onClick={() => 
                                                            {
                                                                setUnitDescending(!unitDescending);
                                                                sortResultsBy("Unit", !unitDescending);
                                                            }}>
                                                        {"Unit Pathway"}
                                                    </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUnitAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUnitDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                            <div className={styles.tableHeadCellContainer}>
                                                <div>{"Last Name"} </div>
                                           </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div>
                                            {"First Name"}
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div>
                                            {"Email"}
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={isLengthyCoursePresent ? styles.tableHeadCellContainerShort : styles.tableHeadCellContainer}>
                                            <div>
                                            {"External ID"}
                                            </div>
                                        </div>
                                        </TableCell>
                                       

                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color}} align="left">
                                        <div className={isLengthyCoursePresent ? styles.tableHeadCellContainerLong : styles.tableHeadCellContainer}>
                                            <div onClick={() => 
                                                    {
                                                        setCourseDescending(!courseDescending);
                                                        sortResultsBy("Course", !courseDescending);
                                                    }}>
                                            {"Course Name"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickCourseAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickCourseDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>                                           
                                        </TableCell>

                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color}} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div>
                                            {"Completion Date"}
                                            </div>
                                        </div>                                           
                                        </TableCell>

                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color}} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div>
                                            {"Expiry Date"}
                                            </div>
                                        </div>                                           
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0"}} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div>
                                            {"Course Total"}
                                            </div>
                                        </div>                                           
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        warningReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(
                                            (result: any, i ) => {
                                                if (result.grandTotalRow){
                                                    return(
                                                        <TableRow key={`warningreportresult-${i}`} >
                                                        <TableCell colSpan = {9} style={{padding: "0px"}}>
                                                           <table style={{ width: "100%", padding: "10px", borderColor: color, borderStyle: "solid", borderWidth: "2px 2px 0px 2px", borderRadius: "5px"}}>
                                                                <tr>
                                                                    <td style={{width: "90%", padding: "10px"}} >
                                                                        <b> { result.unitPathway }</b>
                                                                    </td>
                                                                    <td style={{width: "10%", padding: "0px 0px 0px 30px"}} >
                                                                    <b> { result.courseName } </b>
                                                                    </td>                   
                                                                </tr>
                                                            </table>
                                                        </TableCell>                                           
                                                    </TableRow>
                                                    )
                                                } else {
                                                return(
                                                    <TableRow key={`warningreportresult-${i}`} style={rowBackgroundColor(result.rowBackGroundColour)}>
                                                    <TableCell align="left"  style={{width: "15%"}}>
                                                        { result.showUnit && GetBlankRowData(result.lastRow, result.unitPathway) }
                                                    </TableCell>
                                                    <TableCell align="left"  style={{width: "10%"}}>
                                                   {/* {result.userStatus == 0 ? <div style={{display:'flex'}}><FiberManualRecordIcon style={{color:'red', fontSize:'14px', marginTop:'3px'}}/> { GetBlankRowData(result.lastRow, result.lastName) }</div>
                                                        :  */
                                                        result.lastName }
                                                    </TableCell>
                                                    <TableCell align="left"  style={{width: "10%"}}>
                                                        { result.firstName }
                                                    </TableCell>
                                                    <TableCell align="left" style={{width: "15%"}}>
                                                        { result.email }
                                                    </TableCell>
                                                    <TableCell align="left" style={{width: "5%"}}>
                                                        { result.externalID }
                                                    </TableCell>
                                                    <TableCell align="left" style={{width: "15%"}}>
                                                        { result.showCourse ? result.courseName :"" }
                                                    </TableCell>
                                                    <TableCell align="left" style={{width: "10%"}}>
                                                        { result.completionDate }
                                                    </TableCell>
                                                    <TableCell align="left" style={{width: "10%"}}>
                                                        {  result.expiryDate.toString() }
                                                    </TableCell>          
                                                    <TableCell align="left" style={{width: "10%"}}>
                                                        { result.totalAccount + " Accounts" }
                                                    </TableCell>                                             
                                                </TableRow>
                                                )
                                            }
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>
                </div>
            </div>
            <div className={ styles.paginationControlsContainer }>
                <TablePagination
                    component="div"
                    count={warningReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </>
      );    
}

export default WarningReportResult
