import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const successIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1412_2154)">
      <path d="M8.85783 15.4273C12.5397 15.4273 15.5245 12.4426 15.5245 8.76066C15.5245 5.07876 12.5397 2.09399 8.85783 2.09399C5.17593 2.09399 2.19116 5.07876 2.19116 8.76066C2.19116 12.4426 5.17593 15.4273 8.85783 15.4273Z" fill="#4CAF50" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.85791 8.76066L8.85791 10.7607L12.8579 6.76066" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1412_2154">
        <rect width="16" height="16" fill="white" transform="translate(0.85791 0.760742)"/>
      </clipPath>
    </defs>
  </svg>
);

export default successIcon;