//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import { addError } from '../../../API/AddError'
import { GetOrgLinks } from '../../../API/Administration/Organisation/GetOrgLinks';
import CloseWhiteIcon from "@material-ui/icons/Close";
import Modal from '../../../Components/Modal';
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import styles from "../../../styles/organisationmanagement.module.css";
import responsivestyles from "../../../styles/responsiveGridLayout.module.css";

import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import LoaderContainer from '../../../Components/LoaderContainer';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, CircularProgress, FormControl, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UnbrandedCard from '../../../Components/UnbrandedCard';
import { OrgBasicData } from '../../../globalTypes';
import getAllOrgs from '../../../API/SuperAdmin/GetAllOrgs';
import CustomTooltip from '../../../Components/CustomTooltip';
import SaveIcon from '../../../CustomIcons/SaveIcon';
import getNewsfeedSubscriberList from '../../../API/SuperAdmin/GetNewsfeedSubscriberList';
import SubscriberGropuList from './SubscriberGropuList';
import { AddCircleOutlineRounded } from '@material-ui/icons';
import GetAllActiveOrgs from '../../../API/SuperAdmin/GetAllActiveOrgs';
import GetNfSubscribers from '../../../API/SuperAdmin/GetNfSubscribers';
import SaveNfSubscribers from '../../../API/SuperAdmin/SaveNfSubscribers';

const SubscribedOrgs = (props: any): JSX.Element => {
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, setSuperAdminSelectedBasicOrgData } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [needLatestList, setNeedLatestList] = useState<boolean>(true);
  const [showsitewiseOrgs, setShowsitewiseOrgs] = useState<boolean>(false);
  const [allOrgsBasicData, setAllOrgsBasicData] = useState<Array<OrgBasicData>>([]);
  const [newsfeedSubscriberListData, setNewsfeedSubscriberListData] = useState<Array<any>>([]);
  const { oidcUser } = useReactOidc();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [subscriberGroupName, setSubscriberGroupName] = useState<string>("");
  const [isActiveStatus, setIsActiveStatus] = useState<number>(1);
  const [nfSubscriberListId, setNfSubscriberListId] = useState<number>(0);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");


  const loadAllSubscriberList = () => {
    getNewsfeedSubscriberList(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      if (!!res && !!res.isSuccess) {
        if (!!res.newsfeedSubscriberList && res.newsfeedSubscriberList.length > 0) {
          setNewsfeedSubscriberListData(res.newsfeedSubscriberList);
        }
        setLoading(false);
      }
    }).catch((errorVal) => {
      console.error(errorVal);
    })
  }

  const loadAllActiveOrgs = () => {

    GetAllActiveOrgs(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      if (!!res && !!res.isSuccess) {
        if (!!res.allOrgsNfSubscribersData && res.allOrgsNfSubscribersData.length > 0) {
          let resultList: OrgBasicData[] = [];
          resultList.push(...res.allOrgsNfSubscribersData);

          var allActiveOrgs = resultList.filter((o) => !!o && !!o.orgName);

          setAllOrgsBasicData(allActiveOrgs);
          setSuperAdminSelectedBasicOrgData(null);
          setLoading(false);
        }
        else {
          console.error("API getAllOrgs returned empty list");
        }
      }
      else {
        console.error("Failed API getAllOrgs with " + (!!res === false ? "null result" : (!res.isSuccess ? (!!res.error ? "error as " + res.error : "unknown error") : "")));
      }
    }).catch((errorVal) => {
      console.error(errorVal);
    })
  };

  useEffect(() => {
    setTitle(allResourcesToTranslations.newsfeedsubscribedorgs)
    setTitleEnglish(pageTitles.newsfeedsubscribedorgs)
    updateBackButtonRoute("");
    setMenuSelection("Newsfeed Subscribers")
    setLoading(true);
    loadAllSubscriberList();
    loadAllActiveOrgs();
    return () => { };
  }, []);


  const handleClosePopup = () => {
    setShowWarningModal(false);
  };


  const setupdatedSelectedFunctionsFunction = (listData: Array<any>[]) => {
    setNewsfeedSubscriberListData(listData);
  }

  const handleEdit = (idToEdit: number) => {
    setNfSubscriberListId(idToEdit);
    GetNfSubscribers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), idToEdit).then((res) => {
      if (!!res && !!res.isSuccess) {
        if (!!res.orgNfSubscribersData && res.orgNfSubscribersData.length > 0) {
          let resultList: OrgBasicData[] = [];
          resultList.push(...res.orgNfSubscribersData);
          let updatedallOrgsBasicData = allOrgsBasicData.map((item: OrgBasicData) => {
            if (!!item && !!item.orgID && !!item.siteName) {
              var matchedItem = resultList.find(v => v.siteName.toLowerCase().trim() === item.siteName.toLowerCase().trim() && v.orgID === item.orgID);
              if (!!matchedItem) {
                return {
                  ...item,
                  isActive: matchedItem.isActive
                }
              }
            }
            return item;
          });
          setAllOrgsBasicData([...updatedallOrgsBasicData]);
          setSuperAdminSelectedBasicOrgData(null);
          setLoading(false);
          setShowsitewiseOrgs(true);
        }
        else {
          console.error("API getAllOrgs returned empty list");
        }
        setSubscriberGroupName(res.subscriberListName);
        setIsActiveStatus(res.isActive === true ? 1 : 0);
      }
      else {
        console.error("Failed API getAllOrgs with " + (!!res === false ? "null result" : (!res.isSuccess ? (!!res.error ? "error as " + res.error : "unknown error") : "")));
      }
    }).catch((errorVal) => {
      console.error(errorVal);
    })
  };

  const handleSubmit = () => {
    setIsSaving(true);
    let selectedOrgs = allOrgsBasicData.filter(o => o.isActive == true);
    SaveNfSubscribers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), nfSubscriberListId, subscriberGroupName, isActiveStatus === 0 ? false : true, selectedOrgs).then((res) => {
      if (!!res && !!res.isSuccess) {
        if (res.isSuccess && res.error == "") {
          setShowWarningModal(true);
          setModalPopupMessage('Subscribed Organisation group saved successfully.');
          setSaveSuccess(true);
          setHasErrored(false);
          setIsSaving(false);

          loadAllSubscriberList();
          setShowsitewiseOrgs(false);
        } else {
          setSaveSuccess(false);
          setShowWarningModal(true);
          setModalPopupMessage(res.error);
          setHasErrored(true);
          setIsSaving(false);
          return;
        }
      }
      else {
        console.error("Failed API getAllOrgs with " + (!!res === false ? "null result" : (!res.isSuccess ? (!!res.error ? "error as " + res.error : "unknown error") : "")));
      }
    }).catch((errorVal) => {
      console.error(errorVal);
    })
  };

  const SelectOrganisation = (event: any, siteName: string, orgID: number) => {
    event.stopPropagation();
    let updatedallOrgsBasicData = allOrgsBasicData.map((item: OrgBasicData) => {
      if (!!item && !!item.orgID && !!item.siteName) {
        if (item.siteName.toLowerCase().trim() === siteName.toLowerCase().trim() && item.orgID === orgID) {
          return {
            ...item,
            isActive: !item.isActive
          }
        }
      }
      return item;
    });
    setAllOrgsBasicData([...updatedallOrgsBasicData]);
  };

  const handleAddNewSubscriberGroup = () => {
    setSubscriberGroupName('');
    let updatedallOrgsBasicData = allOrgsBasicData.map((item: OrgBasicData) => {
      if (!!item && !!item.orgID && !!item.siteName) {
        return {
          ...item,
          isActive: false
        }
      }
      return item;
    });
    setAllOrgsBasicData([...updatedallOrgsBasicData]);

    setNfSubscriberListId(0);
    setShowsitewiseOrgs(true);
  }

  const CheckboxList = (siteName: string) => {
    let items: OrgBasicData[] = allOrgsBasicData.filter((o) => o.siteName.toLowerCase().includes(siteName));

    if (items.length > 0) {
      const rows = [];

      for (let i = 0; i < items.length; i += 3) {
        rows.push(items.slice(i, i + 3));
      }

      return (
        <div style={{ width: "100%" }} >
          {rows.map((row, rowIndex) => (

            <div style={{ width: '100%' }} key={rowIndex} className={responsivestyles.rowrule}>
              {row.map((item, colIndex) => (
                <div key={colIndex} className={`${responsivestyles.columnrule_4} ${responsivestyles.columnrule_s_12}`} >
                  <div style={{ width: '100%', paddingLeft: '0px' }} >
                    <Checkbox
                      style={{ paddingLeft: '0px' }}
                      size="small"
                      color="primary"
                      checked={item.isActive}
                      className={styles.checkBox}
                      onClick={(e) => SelectOrganisation(e, item.siteName, item.orgID)}
                    />{" "}
                    <span className={styles.labelText}>{item.orgName}</span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
    else {
      return (
        <div style={{ width: "100%" }} >
          <span>No active organisation(s) found</span>
        </div>
      );
    }
  };

  useEffect(() => {
    if (showWarningModal && saveSuccess) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 3000)
    }
  }, [showWarningModal, saveSuccess])

  useEffect(() => {
    setNewsfeedSubscriberListData(newsfeedSubscriberListData);
  }, [newsfeedSubscriberListData])

  if (loading) return <LoaderContainer />;
  return (
    <>
      {(newsfeedSubscriberListData === null || newsfeedSubscriberListData.length < 1) &&
        (<BrandedCard>
          <div className={styles.noResultsCard}>
            <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
            <br />
            <div>{allResourcesToTranslations.createnewsubscribergroup}</div>
          </div>
        </BrandedCard>)
      }
      {(newsfeedSubscriberListData !== null && newsfeedSubscriberListData.length > 0) &&
        (
          <SubscriberGropuList listData={newsfeedSubscriberListData} setUpdatedSelectedName={setupdatedSelectedFunctionsFunction} needLatestList={needLatestList} handleEdit={handleEdit} />
        )}
      <div className="Addsubscribergroup" style={{ width: "50%", marginTop: "20px" }}>
        <CustomTooltip displayText={[allResourcesToTranslations.addsubscribergroup]}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`saveData addNewPolicy`}
            onClick={handleAddNewSubscriberGroup}
          >
            <div style={{ marginBottom: "-5px" }}>
              <AddCircleOutlineRounded />
            </div><span style={{ marginLeft: "5px" }}> {allResourcesToTranslations.addsubscribergroup}</span>
          </Button>
        </CustomTooltip>
      </div>
      {showsitewiseOrgs &&
        <div className={styles.mainWrapper}>
          <div className={responsivestyles.rowrule} style={{ width: '100%', clear: "both", paddingBottom: "10px" }} >
            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
              <div className={`${styles.inputContainer} subscribergroupname`} 
              >
                <div style={{paddingRight: "10px"}}>
                <TextField
                  className={styles.input}
                  style={{ backgroundColor: "white", height: "55px" }}
                  label={allResourcesToTranslations.subscribergroupname}
                  name="subscribergroupname"
                  variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                  value={subscriberGroupName}
                  onChange={(e) => setSubscriberGroupName(e.target.value)}
                  placeholder={allResourcesToTranslations.requiredfield}
                />
                </div>
              </div>
            </div>
            <div className={`${responsivestyles.columnrule_6} ${responsivestyles.columnrule_s_12}`}>
              <div className={`${styles.inputContainer} subscribergroupnamestatus`}>              
              <div style={{paddingLeft: "10px"}}>
                  <FormControl variant="outlined" className={styles.formControl} style={{ backgroundColor: "white", height: "55px" }}>
                    <InputLabel>{allResourcesToTranslations.status}</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      value={isActiveStatus}
                      native
                      disabled={isSaving}
                      onChange={(e: any) => setIsActiveStatus(parseInt(e.target.value))}
                      variant="outlined"
                      className={allResourcesToTranslations.status}
                      label={allResourcesToTranslations.status}>
                      <option value={1}>{allResourcesToTranslations.active}</option>
                      <option value={0}>{allResourcesToTranslations.inactive}</option>
                    </Select>
                  </FormControl>
                  </div>
              </div>
            </div>
          </div>
          <UnbrandedCard>
            <div className={styles.mainWrapper} style={{ marginTop: "0px" }}>
              <div className={styles.Wrapper}>
                <div className={styles.lableContainer} >
                  <span className={styles.labelText}>{allResourcesToTranslations.organisations}</span>
                </div>
                <div>
                  <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="siteDemoOrgs"
                      id="siteDemoOrgs"
                      style={{ minHeight: "20px" }}
                    >
                      <Typography><b>Site Demo</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <Typography>
                        {CheckboxList("saltsitedemo")}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="site1orgs"
                      id="site1orgs"
                      style={{ minHeight: "20px" }}
                    >
                      <Typography><b>Site 1</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <Typography>
                        {CheckboxList("saltsite1")}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="site2orgs"
                      id="site2orgs"
                      style={{ minHeight: "20px" }}
                    >
                      <Typography><b>Site 2</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <Typography>
                        {CheckboxList("saltsite2")}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="site4Orgs"
                      id="site4Orgs"
                      style={{ minHeight: "20px" }}
                    >
                      <Typography><b>Site 4</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <Typography>
                        {CheckboxList("saltsite4")}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="site5Orgs"
                      id="site5Orgs"
                      style={{ minHeight: "20px" }}
                    >
                      <Typography><b>Site 5</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <Typography>
                        {CheckboxList("saltsite5")}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <Accordion style={{ margin: "20px 0px", borderRadius: "6px 6px 6px 6px", border: '1px solid #D3D6DF', position: 'inherit', boxShadow: "none" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="site6Orgs"
                      id="site6Orgs"
                      style={{ minHeight: "20px" }}
                    >
                      <Typography><b>Site 6</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0px" }}>
                      <Typography>
                        {CheckboxList("saltsite6")}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

              </div>
            </div>
          </UnbrandedCard>
          <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>
            <CustomTooltip displayText={["Save"]}>
              <span className="saveData">
                <Button
                  disabled={
                    isSaving || subscriberGroupName == ""
                  }
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <SaveIcon />
                </Button>
              </span>
            </CustomTooltip>
            <div className={styles.loaderContainer}>
              <CircularProgress
                style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
                color="primary"
                size="10"
              />
            </div>
            <div style={{ paddingTop: "20px" }}>
            </div>
          </div>
        </div>
      }
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};
export default SubscribedOrgs;
