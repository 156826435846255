//React + typescript
import React, { useContext } from 'react'
//Context
import { CorperateThemeContext } from '../contexts/CorperateThemeContext';
//Functions
import generateGradientCss from '../functions/generateGradientCss';
//Components
import CustomTooltip from './CustomTooltip';
//MUI Components
import styles from '../styles/courseIcon.module.css';
import NewsfeedIcon from '../CustomIcons/NewsfeedIcon';
import GrcNewsfeedIcon from '../CustomIcons/GrcNewsfeedIcon';

interface Props {
    IsGRCCreated: boolean
}

const MyNewsfeedIcon = ({IsGRCCreated}: Props): JSX.Element => {
    const {color} = useContext(CorperateThemeContext).theme
    const backgroundCSS = generateGradientCss(color);

    return (
        <CustomTooltip displayText={["Newsfeed"]} >
            <div className={styles.courseIconSmall} style={{backgroundImage: backgroundCSS}}>
                {IsGRCCreated && <GrcNewsfeedIcon></GrcNewsfeedIcon> }
                {!IsGRCCreated && <NewsfeedIcon></NewsfeedIcon> }
            </div>
        </CustomTooltip>
    )
        
}

export default MyNewsfeedIcon;