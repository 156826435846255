import React from 'react';

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Checkbox from "@material-ui/core/Checkbox";

//Styles
import styles from "../../styles/currentAdminReport.module.css";


export const FrequencySection = (props:any) => {

  const { frequencyData, handleFrequencySectionChange, getFormattedDayOfCurrentDayOffsetWithGivenDayCount } = props;
    
  const frequencyList = [
    {
      value: "N",
      label: "Now",
    },

    {
      value: "O",
      label: "Once only",
    },

    {
      value: "M",
      label: "More than once",
    },
  ];

    return (
        <FormControl component="fieldset" style={{ margin: "10px", width: '95%'  }}>
        <FormLabel component="legend">Frequency</FormLabel>
        <div className={styles.childContainer}>
          <RadioGroup
            row
            name="frequencyValue"
            value={frequencyData.frequencyValue}
            onChange={handleFrequencySectionChange}
          >
            {frequencyList.map((item,index) => {
              return (
                <FormControlLabel
                  value={item.value}
                  control={<Radio color="primary" />}
                  label={item.label}
                  key={`item-${index}`}
                />
              );
            })}
          </RadioGroup>

          {frequencyData.frequencyValue == "O" && (
            <div
              style={{ display: "flex", justifyContent: "flex-start",marginLeft:"110px" }}
            >
              <div className={styles.inputContainer}>
                <TextField
                  name={"onceOnlyDate"}
                  label="Once Only Start Date"
                  variant="outlined"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  onChange={handleFrequencySectionChange}
                  defaultValue={frequencyData.onceOnlyDate}
                  inputProps={{ min:getFormattedDayOfCurrentDayOffsetWithGivenDayCount(1)}}
                />
              </div>
            </div>
          )}

          {frequencyData.frequencyValue == "M" && (
            <div
              style={{ display: "flex",marginLeft:"230px" }}
            >
              <div>
                <div className={styles.inputContainer}>
                  <TextField
                    name={"startDate"}
                    label="Start Date"
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    onChange={handleFrequencySectionChange}
                    defaultValue={frequencyData.startDate}
                    inputProps={{ min:getFormattedDayOfCurrentDayOffsetWithGivenDayCount(0)}}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    name="endDate"
                    label="End Date"
                    variant="outlined"
                    autoComplete="off"
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    onChange={handleFrequencySectionChange}
                    defaultValue={frequencyData.noEndDate ? "" : frequencyData.endDate}
                    disabled={frequencyData.noEndDate}
                    inputProps={{ min:getFormattedDayOfCurrentDayOffsetWithGivenDayCount(1)}}
                  />
                </div>
                <div style={{ display: "flex", position:'relative', left:'-103px' }}>
                  <p>No End Date</p>
                  <Checkbox
                    color="primary"
                    name="noEndDate"
                    checked={frequencyData.noEndDate}
                    onClick={handleFrequencySectionChange}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    left: "-50px",
                    width: "210px",
                    top: "-10px"
                  }}
                >
                  <p style={{ marginRight: "10px", marginTop:"20px" }}>Every</p>
                  <div className={styles.daysField}>
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      style={{ marginRight: "10px" }}
                      type="text"
                      name="timeIntervalValue"
                      onChange={handleFrequencySectionChange}
                      value={frequencyData.timeIntervalValue}
                    />
                  </div>

                  <div className={styles.daysField}>
                    <FormControl variant="outlined">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={frequencyData.daysType}
                        name="daysType"
                        style={{ backgroundColor: "white" }}
                        onChange={handleFrequencySectionChange}
                      >
                        <MenuItem value={"D"}>Day/s</MenuItem>
                        <MenuItem value={"W"}>Week/s</MenuItem>
                        <MenuItem value={"M"}>Month/s</MenuItem>
                        <MenuItem value={"Y"}>Year/s</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    left: "-74px",
                  }}
                >
                  <p style={{ marginRight: "10px", marginLeft: "0px", marginTop:"20px" }}>End after</p>
                  <div
                    className={styles.daysField}
                    style={{ display: "flex" }}
                  >
                    <TextField
                      variant="outlined"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      style={{ marginRight: "10px", width: "70px" }}
                      type="text"
                      name="endAfter"
                      onChange={handleFrequencySectionChange}
                      value={frequencyData.endAfter}                      
                      // disabled={(frequencyData !== null && frequencyData !== undefined && frequencyData.noEndDate) ? false : 
                      //   ((frequencyData !== null && frequencyData !== undefined && frequencyData.endDate !== null && 
                      //     frequencyData.endDate !== undefined && frequencyData.endDate.toString() === "") ? false : true)}                      
                    />
                    <p style={{ marginTop:"20px"}}>report(s)</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </FormControl>
    )
}