import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const APILibraryWRIcon = () => (
<svg width="83" height="88" viewBox="0 0 83 88" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.5862 15.9952L6.2942 64.8959C5.69028 65.9417 5.37073 67.1276 5.36734 68.3352C5.36395 69.5432 5.67686 70.7308 6.27494 71.7797C6.87299 72.829 7.7354 73.7036 8.77628 74.3161C9.81717 74.9285 11.0003 75.2578 12.208 75.2709H70.792C71.9997 75.2578 73.1828 74.9285 74.2238 74.3161C75.2647 73.7036 76.1272 72.829 76.7252 71.7797C77.3231 70.7308 77.6361 69.5432 77.6326 68.3352C77.6295 67.1276 77.31 65.9417 76.7058 64.8959L47.4137 15.9952C46.7974 14.9788 45.9291 14.1384 44.8933 13.5553C43.8575 12.9721 42.6889 12.6657 41.5 12.6657C40.3113 12.6657 39.1428 12.9721 38.1067 13.5553C37.0709 14.1384 36.2028 14.9788 35.5862 15.9952Z" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.5 33.7709V47.6042" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M41.5 61.4376H41.5346" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


);

export default APILibraryWRIcon
