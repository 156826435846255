import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const APILibraryQHRIcon = () => (
<svg width="83" height="87" viewBox="0 0 83 87" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.4167 9.10419H20.75C18.9156 9.10419 17.1563 9.83289 15.8592 11.13C14.5621 12.4272 13.8334 14.1865 13.8334 16.0209V71.3542C13.8334 73.1885 14.5621 74.9477 15.8592 76.245C17.1563 77.5422 18.9156 78.2709 20.75 78.2709H62.25C64.0843 78.2709 65.8436 77.5422 67.1408 76.245C68.438 74.9477 69.1667 73.1885 69.1667 71.3542V29.8542L48.4167 9.10419Z" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M48.4166 9.10419V29.8542H69.1666" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M31.125 54.0626H51.875" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


);

export default APILibraryQHRIcon
