//React + Typescript
import React, { useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { UIContext } from '../../contexts/UIContext';
//API Functions
import { addError } from '../../API/AddError'
//Components
import PDFPlayer from '../../Players/PDFPlayer'
//MUI Components
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//Styles
import styles from '../../styles/pdfplayer.module.css';

export const QuickfactsWrapper = (props: any) : JSX.Element => {
    const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const { EnableActivityLogs } = useContext(UIContext);
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();
    const courseId: number = parseInt(props.match.params.courseId);

    const state = props.location.state || (window as any).MOBILE_LOCATION_STATE
    if (!state) return <Redirect to={`/mytraining/${courseId}`} push />;
    const { url } = state;

    const handleExit = () => {
        addError(oidcUser.access_token, userId, orgDomain, "Exited Quick Facts", null, "QuickfactsWrapper.tsx", 6, EnableActivityLogs)
        history.goBack()
      };

    return(
        <>
          <PDFPlayer url={url}/> 
          <div className={styles.controls}>
            <Button
              id="exitButton"
              onClick={() => handleExit()}
              variant="contained"
              color="primary"
            >
                <ExitToAppIcon/>
                { allResourcesToTranslations.exit }
            </Button>
          </div>
        </>
    ) 
}

export default QuickfactsWrapper;
