//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
import CloseWhiteIcon from "@material-ui/icons/Close";
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { LoaderContext } from '../../../contexts/LoaderContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import styles from "../../../styles/organisationmanagement.module.css";
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';
import LoaderContainer from '../../../Components/LoaderContainer';
import { Button, CircularProgress, FormControl, InputLabel, Select } from '@material-ui/core';
import getNewsfeedSubscriberList from '../../../API/SuperAdmin/GetNewsfeedSubscriberList';
import UnbrandedCard from '../../../Components/UnbrandedCard';
import AssignButtonPanel from '../PolicyBuilderSettings/AssignButtonPanel';
import AccountManagement from '../AccountManagement/AccountManagement';
import AssignUnits from '../PolicyBuilderSettings/AssignUnits';
import UserListSection from '../AccountManagement/UserListSection';
import BrandedCard from '../../../Components/BrandedCard';
import CustomTooltip from '../../../Components/CustomTooltip';
import SaveIcon from '../../../CustomIcons/SaveIcon';
import ViewNewsfeedGroupAssignedUsers from '../../../API/Administration/Newsfeed/ViewNewsfeedGroupAssignedUsers';
import AssignNewsfeedGroupUnits from '../../../API/Administration/Newsfeed/AssignNewsfeedGroupUnits';
import GetNewsfeedGroupAssignedUserIds from '../../../API/Administration/Newsfeed/GetNewsfeedGroupAssignedUserIds';
import ProceedIcon from '../../../CustomIcons/ProceedIcon';
import Modal from '../../../Components/Modal';
import AssignNewsfeedGroupUsers from '../../../API/Administration/Newsfeed/AssignNewsfeedGroupUsers';
import { useHistory, useParams } from 'react-router-dom';

const NewsfeedAssignedGroups = (props: any): JSX.Element => {
  // const { nfSubscriberId } = useParams<{ nfSubscriberId: string }>();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
  const history = useHistory();
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();
  const [showConfirmModal, setshowConfirmModal] = useState<boolean>(false);

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [subscriberGroupList, setSubscriberGroupList] = useState<any>([{ value: -1, subscriberGroupId: -1, subscriberGroupName: "" }]);
  const [selectedSubscriberGroupId, setSelectedSubscriberGroupId] = useState<number>(-1);
  const [needLatestUnits, setNeedLatestUnits] = useState<boolean>(false);
  const [needLatestUsers, setNeedLatestUsers] = useState<boolean>(false);
  const [assignedUserIds, setAssignedUserIds] = useState<Array<number>>([]);
  const [hasSelectedUsersChanged, setHasSelectedUsersChanged] = useState<boolean>(false);
  const [assignUsers, setAssignUsers] = useState<boolean>(false);
  const [isSavingAssignment, setIsSavingAssignment] = useState<boolean>(false);
  const [resetAcceptances, setResetAcceptances] = useState<boolean>(false);
  const [hasAccountsSearched, setHasAccountsSearched] = useState<boolean>(false);
  const [hasUnitsLoaded, setHasUnitsLoaded] = useState<boolean>(false);
  const [viewNewsfeedLoading, setViewNewsfeedLoading] = useState<boolean>(false);
  const [showAssignUnits, setshowAssignUnits] = useState<boolean>(false);
  const [showAssignAccounts, setshowAssignAccounts] = useState<boolean>(false);
  const [showViewAccounts, setshowViewAccounts] = useState<boolean>(false);
  const [initaialUsersList, setInitaialUsersList] = React.useState<Array<any>>([]);
  const [newsfeedAssignedUsersList, setNewsfeedAssignedUsersList] = React.useState<Array<any>>([]);
  const [updatedSelectedUnits, setUpdatedSelectedUnits] = React.useState<Array<string>>([]);
  const [showAssignButtons, setShowAssignButtons] = useState<boolean>(false);
  const [resetAssignButtons, setResetAssignButtons] = useState<boolean>(false);
  const [selectedUsersCSVInitial, setSelectedUsersCSVInitial] = useState<string>("");
  const [initialNewsfeedGroupAcceptedUsersList, setInititalNewsfeedGroupAcceptedUsersList] = React.useState<Array<any>>([]);
  const [selectedUnits, setselectedUnits] = useState<string>("");
  const [csvOfAllDeniedUnits, setCSVOfAllDeniedUnits] = useState<string>("");
  const [updatedinitaialUsersList, setUpdatedInitaialUsersList] = React.useState<Array<any>>([]);
  const [finallySelectedUsersList, setFinallySelectedUsersList] = React.useState<Array<any>>([]);
  const [finallySelectedUnitList, setFinallySelectedUnitList] = React.useState<Array<any>>([]);
  const [showSameUnitModalForConfirmation, setShowSameUnitModalForConfirmation] = useState<boolean>(false);
  const [sameUnitModelMessage, setSameUnitModelMessage] = useState<string>("");
  const [initialNewsfeedAcceptedUsersList, setInititalNewsfeedAcceptedUsersList] = React.useState<Array<any>>([]);

  
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");

  useEffect(() => {
    setTitle(allResourcesToTranslations.newsfeedassignedgroup)
    setTitleEnglish(pageTitles.newsfeedassignedgroup)
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.newsfeedassignedgroup);
    setLoading(true);
    GetNewsfeedSubscribers(-1);
    //setSelectedSubscriberGroupId(parseInt(nfSubscriberId));

    return () => { };
  }, []);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleAssignUnits = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewNewsfeedLoading(false);

    setshowAssignUnits(!showAssignUnits);
    setshowAssignAccounts(false);
    setshowViewAccounts(false);
    setAssignUsers(false);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleAssignAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewNewsfeedLoading(false);

    setshowAssignAccounts(!showAssignAccounts);
    setshowAssignUnits(false);
    setshowViewAccounts(false);
    setAssignUsers(true);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleViewAccounts = () => {
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setShowWarningModal(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewNewsfeedLoading(false);

    setshowViewAccounts(!showViewAccounts);
    setshowAssignAccounts(false);
    setshowAssignUnits(false);
    setNewsfeedAssignedUsersList([]);
    LoadSubscriberGroupAssignedUsers(selectedSubscriberGroupId);
    setAssignUsers(true);
    let element = document.getElementById("assign");
    if (!!element)
      element.scrollIntoView();
  }

  const handleResetAccounts = () => {
  }

  
  const handleSameUnitModalForConfirmation = (value: boolean) => {
    setShowSameUnitModalForConfirmation(value);
  }

  const AssignUnitsToNewsfeedFunction = (unitIds: string[]) => {
    handleSameUnitModalForConfirmation(false);
    setIsSavingAssignment(true);
    AssignNewsfeedGroupUnits(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedSubscriberGroupId, unitIds.join(","))
      .then((res) => {
        setIsSavingAssignment(false);
        setNeedLatestUnits(false);
        setNeedLatestUsers(false);
        setselectedUnits(unitIds.join(","));
        if (!!res && res.isSuccess) { 
          setShowWarningModal(true);
          setHasErrored(false);
          setSaveSuccess(true);
          setModalPopupMessage("Assign units to this Subscriber group have been saved successful. ");
          GetAssignedUserIds();
        }
      })
  }

  const GetAssignedUserIds = () => {
    GetNewsfeedGroupAssignedUserIds(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedSubscriberGroupId)
      .then((res) => {
        if (!!res && res.isSuccess) {
          setAssignedUserIds(res.newsfeedAssignedUserIds);
        }
      })
  }
  
  const setupdatedSelectedUnitsFunction = (unitIds: string[]) => {
    if (selectedSubscriberGroupId > 0) {
      setUpdatedSelectedUnits(unitIds);
      setFinallySelectedUnitList(unitIds);
      SaveNewsfeedGroupAssignClick(unitIds, []);
    }
  }

  const getValidIntNumberValue = (givenTxt: string) => {
    let numval = -1;
    if (!!givenTxt === false || givenTxt.trim() == "") {
      return -1;
    }
    try {
      numval = parseInt(givenTxt);
      if (!!numval == false || isNaN(numval) || Number.isNaN(numval)) {
        numval = -1;
      }
    }
    catch { numval = -1; }
    return numval;
  }

  const getNewlyModifiedUserIDs = (updatedSelectedUsers: any[]) => {
    let retVal = { newlyDeniedIDsCSV: "", newlyGrantedIDsCSV: "" }
    let newlyGrantedUserIDsCSV = "";
    let newlyDeniedUserIDsCSV = "";

    let initialSelectedUserIDs: string[] = [];
    initialSelectedUserIDs = selectedUsersCSVInitial.split(',');
    // Get the newly granted and newly denied users
    if (!!updatedSelectedUsers && updatedSelectedUsers.length > 0 &&
      updatedSelectedUsers.some(id => !!id && !!id.userID && id.userID.toString().trim() != "")) {
      // Collect only newly granted in first loop of updated user-list checked against initial granted user-list
      for (let i = 0; i < updatedSelectedUsers.length; i++) {
        let curVal = updatedSelectedUsers[i];
        let curValId = "";
        if (!!curVal === false || !!curVal.userID === false || (curValId = curVal.userID.toString().trim()) == "" || curVal.granted !== true) {
          continue;
        }
        let numVal = getValidIntNumberValue(curValId);
        let numvalTxt = numVal.toString();
        // Extra not overlapping with initial list - collect only the denied to granted
        if (!initialSelectedUserIDs.some(v => !!v && v.toString().trim() == numvalTxt)) {
          if (curVal.granted === true) {
            newlyGrantedUserIDsCSV = ((newlyGrantedUserIDsCSV == "") ? numvalTxt : (newlyGrantedUserIDsCSV + "," + numvalTxt));
          }
        }
      }
      // Collect only newly denied in second loop of initial granted user-list checked against updated user-list
      for (let i = 0; i < initialSelectedUserIDs.length; i++) {
        let curVal = initialSelectedUserIDs[i];
        if (!!curVal === false || (curVal = curVal.toString().trim()) == "") {
          continue;
        }
        let numVal = getValidIntNumberValue(curVal);
        let numvalTxt = numVal.toString();
        // Overlapping with initial list - collect only granted to denied
        if (updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt) &&
          updatedSelectedUsers.some(v => !!v && !!v.userID && v.userID.toString().trim() == numvalTxt && v.granted === false)) {
          newlyDeniedUserIDsCSV = ((newlyDeniedUserIDsCSV == "") ? numvalTxt : (newlyDeniedUserIDsCSV + "," + numvalTxt));
        }
      }
    }
    retVal.newlyDeniedIDsCSV = newlyDeniedUserIDsCSV;
    retVal.newlyGrantedIDsCSV = newlyGrantedUserIDsCSV;
    return retVal;
  }

  const SaveNewsfeedGroupAssignClick = (unitIds: string[], usersData: any[]) => {
    setNeedLatestUnits(false);
    if (showAssignUnits) {
      let anyChangeFound = false;
      if (unitIds.length === selectedUnits.split(",").length) {
        for (let curIdx = 0; curIdx < unitIds.length; curIdx++) {
          let currentValue = unitIds[curIdx];
          let unit = (selectedUnits.split(",").find(v => !!v && !!currentValue && v.toString().toLowerCase().trim() === currentValue.toString().toLowerCase().trim()));
          if (anyChangeFound === false && (unit === null || unit === undefined)) {
            anyChangeFound = true;
            break;
          }
        }
      }
      else {
        anyChangeFound = true;
      }
      if (anyChangeFound) {
        AssignUnitsToNewsfeedFunction(unitIds);
      }
      else {
        handleSameUnitModalForConfirmation(true);
        setSameUnitModelMessage("There are no changes to Unit Assignments. Do you want to re-apply the existing Unit Assignments which will override any Account specific assignments?");
      }
    }
    if (showViewAccounts) {
      let anyUserChangeFound: boolean = false;
      let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
      let initialUserGrantedData = !!initialNewsfeedAcceptedUsersList !== true ? null : initialNewsfeedAcceptedUsersList.filter((initialItem) => initialItem.granted === true);
      if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length) {
        for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
          let currentItem = usersData[curIdx];
          let userdetail = (initialNewsfeedAcceptedUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
          if (anyUserChangeFound === false && !!userdetail) {
            anyUserChangeFound = true;
            break;
          }
        }
      }
      else {
        anyUserChangeFound = true;
      }
      if (anyUserChangeFound) {
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = getNewlyModifiedUserIDs(usersData);
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

        setIsSavingAssignment(true);
        AssignNewsfeedGroupUsers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedSubscriberGroupId.toString(), newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
          .then((res) => {
            setIsSavingAssignment(false);
            setNeedLatestUnits(false);
            setNeedLatestUsers(false);
            if (!!res && res.isSuccess) {
              let initialUsersList = usersData.map((item: any) => {
                return {
                  ...item,
                  isInitialChanged: true,
                };
              });
              setInitaialUsersList(initialUsersList);
              let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
              const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
              setSelectedUsersCSVInitial(selectedIds.join(','));

              setShowWarningModal(true);
              setHasErrored(false);
              setSaveSuccess(true);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been saved successful. ");
              GetAssignedUserIds();
              if (showViewAccounts) {
                setNewsfeedAssignedUsersList([]);
                setNeedLatestUnits(false);
                setNeedLatestUsers(false);
                LoadSubscriberGroupAssignedUsers(selectedSubscriberGroupId);
              }
            }
            else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage("Assign/Unasssign users to the external certificate have been failed.");
            }
          });
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(false);
        setModalPopupMessage("Please select the users to assign/unassign to the external certificate");
      }
    }
    if (showAssignAccounts) {
      let anyUserChangeFound: boolean = false;
      let currentUserGrantedData = !!usersData !== true ? null : usersData.filter((item) => item.granted === true);
      //console.log(currentUserGrantedData);
      //console.log(initaialUsersList);
      let initialUserGrantedData = !!initaialUsersList !== true ? null : initaialUsersList.filter((initialItem) => initialItem.granted === true);
      if (!!currentUserGrantedData && !!initialUserGrantedData && currentUserGrantedData.length === initialUserGrantedData.length) {
        // Setting external variable using the lambda of map function is avoided
        for (let curIdx = 0; curIdx < usersData.length; curIdx++) {
          let currentItem = usersData[curIdx];
          let userdetail = (initaialUsersList.find(v => !!v && !!currentItem && v.userID.toString().toLowerCase() === currentItem.userID.toString().toLowerCase() && v.granted !== currentItem.granted));
          if (anyUserChangeFound === false && !!userdetail) {
            anyUserChangeFound = true;
          }
        }
      }
      else {
        anyUserChangeFound = true;
      }
      if (anyUserChangeFound) {
        let newlyGrantedUserIDsCSV = "";
        let newlyDeniedUserIDsCSV = "";
        let ids = getNewlyModifiedUserIDs(usersData);
        newlyGrantedUserIDsCSV = !!ids ? ids.newlyGrantedIDsCSV : newlyGrantedUserIDsCSV;
        newlyDeniedUserIDsCSV = !!ids ? ids.newlyDeniedIDsCSV : newlyDeniedUserIDsCSV;

        setIsSavingAssignment(true);
        AssignNewsfeedGroupUsers(oidcUser.access_token, userId, orgDomain, parseInt(orgId), selectedSubscriberGroupId.toString(), newlyGrantedUserIDsCSV, newlyDeniedUserIDsCSV)
          .then((res) => {
            setIsSavingAssignment(false);
            setNeedLatestUnits(false);
            setNeedLatestUsers(false);

            if (!!res && res.isSuccess) {
              let initialUsersList = usersData.map((item: any) => {
                return {
                  ...item,
                  isInitial: true,
                };
              });
              setInitaialUsersList(initialUsersList);
              let initialGrantedList = !!initialUsersList !== true ? null : initialUsersList.filter((initialItem: any) => initialItem.granted === true);
              const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
              setSelectedUsersCSVInitial(selectedIds.join(','));

              setShowWarningModal(true);
              setHasErrored(false);
              setSaveSuccess(true);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been saved successful. ");
              GetAssignedUserIds();
            }
            else {
              setShowWarningModal(true);
              setHasErrored(true);
              setSaveSuccess(false);
              setModalPopupMessage("Assign/Unasssign users to the newsfeed have been failed.");
            }
          });
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(false);
        setModalPopupMessage("Please select the user to assign/unassign to the newsfeed");
      }
    }
  }

  const LoadSubscriberGroupAssignedUsers = (selectedSubscriberGroupId: number) => {
    setViewNewsfeedLoading(true);
    ViewNewsfeedGroupAssignedUsers(
      oidcUser.access_token,
      userId,
      orgDomain,
      selectedSubscriberGroupId,
      parseInt(orgId)
    ).then((response: any) => {
      setViewNewsfeedLoading(false);
      if (
        response !== null &&
        response !== undefined &&
        response.isSuccess &&
        response.newsfeedGroupAssignedUserItem !== null &&
        response.newsfeedGroupAssignedUserItem !== undefined
      ) {
        if (response.newsfeedGroupAssignedUserItem.length > 0) {
          let updatedUsersList = response.newsfeedGroupAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isChecked: false,
            };
          });
          let initialUsersList = response.newsfeedGroupAssignedUserItem.map((item: any) => {
            return {
              ...item,
              isInitial: true,
            };
          });
          setNewsfeedAssignedUsersList(updatedUsersList);
          setInititalNewsfeedGroupAcceptedUsersList(initialUsersList);
          let initialGrantedList = initialUsersList.filter((initialItem: any) => initialItem.granted === true);
          const selectedIds = initialGrantedList.map(({ userID }: any) => userID);
          setSelectedUsersCSVInitial(selectedIds.join(','));
          setAssignedUserIds(selectedIds);
          return;

        } else {
          setHasErrored(false);
          setSaveSuccess(false);
          return;
        }
      } else {
        setShowWarningModal(true);
        setModalPopupMessage('Fetching record has failed, please try again.');
        setHasErrored(true);
        return;
      }
    });
  }

  const GetNewsfeedSubscribers = (selectedSubscriberGroupId: number) => {

    getNewsfeedSubscriberList(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      setLoading(false);
      if (!!res && !!res.isSuccess) {
        if (!!res.newsfeedSubscriberList && res.newsfeedSubscriberList.length > 0) {
          let subscriberGroup = ([{ value: -1, subscriberGroupId: -1, subscriberGroupName: "" }]);
          let activeSubscriberGroup = res.newsfeedSubscriberList.filter((r: any) => r.isActive === true);
          let allActiveSubscriberGroup = activeSubscriberGroup.map((item: any) => {
            if (!!item && !!item.nfSubscriberListId && !!item.nfSubscriberListName) {
              
              return {
                value: item.nfSubscriberListId,
                subscriberGroupId: item.nfSubscriberListCommonId,
                subscriberGroupName: item.nfSubscriberListName,
                assignedUsers: !!item.userIds && item.userIds !== null ? item.userIds : "",
                assignedUnits: !!item.unitIds && item.unitIds !== null ? item.unitIds : ""
              }
            }

          });
          subscriberGroup.push(...allActiveSubscriberGroup);
          setSubscriberGroupList(subscriberGroup);

          if (selectedSubscriberGroupId > 0) {
            setShowAssignButtons(true);
          }
        }
      }
    }).catch((errorVal) => {
      console.error(errorVal);
    })
  };

  const setInitialUserListFunction = (usersData: Array<any>[]) => {
    setInitaialUsersList(usersData);
    let initialGrantedList = !!usersData !== true ? null : usersData.filter((initialItem: any) => initialItem.granted === true);
    const selectedIds = !!initialGrantedList ? initialGrantedList.map(({ userID }: any) => userID) : [];
    setSelectedUsersCSVInitial(selectedIds.join(','));
  }

  const handleCancelSameUnitModalForConfirmation = () => {
    setShowSameUnitModalForConfirmation(false);
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
  }

  const setUnitsLoadedFunction = (hasUnitsLoaded: boolean) => {
    setHasUnitsLoaded(hasUnitsLoaded);
  }
  
  const handleSaveConfirmProceedSameUnitModalForConfirmation = () => {
    AssignUnitsToNewsfeedFunction(selectedUnits.split(','));
  }

  const setupdatedSelectedUsersFunction = (usersData: Array<any>[]) => {
    setFinallySelectedUsersList(usersData);
  }
  
  const setRecordsSearchStartedFunction = (hasSearchStarted: boolean) => {
  }

  const setRecordsSearchedFunction = (hasSearched: boolean) => {
    setHasAccountsSearched(hasSearched);
  }
  const handleSaveAssignment = () => {
    if (showAssignUnits) {
      setNeedLatestUnits(true);
    }
    if (showViewAccounts || showAssignAccounts) {
      setNeedLatestUsers(true);
      SaveNewsfeedGroupAssignClick([], finallySelectedUsersList);
    }
  }
  const setUserSelectionChangedFunction = (hasSelectedUsersChanged: boolean) => {
    setHasSelectedUsersChanged(hasSelectedUsersChanged);
  }
  const HandleSelectionOfselectedSubscriberGroup = (e: any) => {
    setShowAssignButtons(false);
    setSelectedSubscriberGroupId(-1);
    let selectedGroupId = parseInt(e.target.value);
    let assignedUserIds = !!subscriberGroupList && subscriberGroupList.length > 0 ? (subscriberGroupList.find((item: any) => item.value === selectedGroupId).assignedUsers) : null;
    let assignedUnitIds = !!subscriberGroupList && subscriberGroupList.length > 0 ? (subscriberGroupList.find((item: any) => item.value === selectedGroupId).assignedUnits) : null;
    setselectedUnits(assignedUnitIds);
    setAssignedUserIds(assignedUserIds !== null ? assignedUserIds.split(',').map(function (id: any) { return parseInt(id); }) : "");
  
    setshowAssignUnits(false);
    setshowAssignAccounts(false);
    setshowViewAccounts(false);
    setHasUnitsLoaded(false);
    setHasAccountsSearched(false);
    setViewNewsfeedLoading(false);
    setNeedLatestUnits(false);
    setNeedLatestUsers(false);
    setAssignUsers(false);
    setHasSelectedUsersChanged(false);
    setNeedLatestUsers(false);
    setNewsfeedAssignedUsersList([]);
    setTimeout(() => {
      setShowAssignButtons(true);    
      setSelectedSubscriberGroupId(selectedGroupId);
    }, 100);
  }

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])

  useEffect(() => {
    if(selectedSubscriberGroupId == -1 && !showAssignButtons){      
      setResetAssignButtons(true);
    }
  }, [selectedSubscriberGroupId, showAssignButtons])

  useEffect(() => {

    return () => { };
  }, [resetAssignButtons])

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Modal modalOpen={showSameUnitModalForConfirmation} setModalOpen={handleSameUnitModalForConfirmation} type="warning" hideCloseBtn={true}>
        <div className={styles.modalContentWrapper}>
          <h2 style={{ color: "#f9a23b", width: "500px", marginTop: "4px" }}>Confirmation</h2>
          <div className={styles.inputContainer}>

            <p>{sameUnitModelMessage}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "10px",
            }}
          >
            <CustomTooltip displayText={[allResourcesToTranslations.return]}>
              <span className={styles.returnIcon} onClick={handleCancelSameUnitModalForConfirmation}>
                <CloseWhiteIcon />
              </span>
            </CustomTooltip>
            <CustomTooltip displayText={[allResourcesToTranslations.proceed]}>
              <span className="saveData">
                <Button
                  onClick={handleSaveConfirmProceedSameUnitModalForConfirmation}
                  variant="contained"
                  color="primary"
                  className="save"
                >
                  <ProceedIcon />
                </Button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </Modal>
     
      <div className={styles.mainWrapper}>
      <UnbrandedCard>
        <div className={styles.Wrapper}>
            <i><span>Note: Please select subscriber group from below to assign to units / accounts</span></i> <br /> <br />
            <div className="subscriberOrgGroup" style={{ backgroundColor: "white", height: "45px", paddingBottom: "20px" }}>
              <FormControl variant="outlined" className={`${styles.formControl} cpdProfileForExtCertFormCtrl`}>
                <InputLabel id="subscriberorggroup">{allResourcesToTranslations.subscriberorggroup + ":"}</InputLabel>
                <Select
                  value={selectedSubscriberGroupId}
                  native
                  onChange={(e: any) => {
                    HandleSelectionOfselectedSubscriberGroup(e);
                  }}
                  defaultValue={{ value: "-1", subscriberGroupId: "-1", subscriberGroupName: "" }}
                  className={styles.inputSelect}
                  variant="outlined"
                  label={allResourcesToTranslations.subscriberorggroup + ":"}
                  id="subscriberorggroup"
                >
                  {
                    subscriberGroupList.length > 0 &&
                    subscriberGroupList.map((item: any) => {
                      return <option key={item.subscriberGroupId}  value={item.value}>{item.subscriberGroupName}</option>
                    })
                  }
                </Select>
              </FormControl>
            </div>
            </div>
            </UnbrandedCard>
            {showAssignButtons && selectedSubscriberGroupId > 0  && 
              <>
                <div className="buttonspanel">
                  <AssignButtonPanel isCalledFrom="Newsfeed" handleAssignUnitsClick={handleAssignUnits} handleAssignAccountsClick={handleAssignAccounts} handleViewAccountsClick={handleViewAccounts} handleResetAccountsClick={handleResetAccounts} resetPage={resetAssignButtons}></AssignButtonPanel>
                </div>
              <div id="assign">
                {showAssignUnits && (
                  <AssignUnits isCalledFrom="NewsfeedList" needLatestUnits={needLatestUnits} selectedUnitIds={selectedUnits} setUpdatedSelectedUnits={setupdatedSelectedUnitsFunction} setUnitsLoaded={setUnitsLoadedFunction} setAllDeniedUnitsAsCSV={() => { }}></AssignUnits>
                )}
    
                {showAssignAccounts && (
                  <div className={styles.mainWrapper}>
                    <AccountManagement isCalledFrom="Newsfeed" needLatestUsers={needLatestUsers} setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} assignedUserIds={assignedUserIds}
                      setInitialUsersData={setInitialUserListFunction} setSearchStarted={setRecordsSearchStartedFunction} setRecordsSearched={setRecordsSearchedFunction}
                      policyId={selectedSubscriberGroupId} sethasSelectionChanged={setUserSelectionChangedFunction}></AccountManagement>
                  </div>
                )}
    
                {showViewAccounts && !viewNewsfeedLoading && newsfeedAssignedUsersList.length > 0 && (
                  <div className="userList">
                    <UserListSection usersData={newsfeedAssignedUsersList} isCalledFrom="Newsfeed" setUpdatedSelectedUsers={setupdatedSelectedUsersFunction} needLatestUsers={needLatestUsers}
                      profileID={-1} profilePeriodID={-1} selectedUnitIDs={[]} allUnitIDs={[]} filterFirstName={""} filterLastName={""} filterUserName={""} filterEmail={""} />
                  </div>
                )}
    
                {showViewAccounts && viewNewsfeedLoading && (
                  <LoaderContainer />
                )}
    
                {showViewAccounts && !viewNewsfeedLoading && newsfeedAssignedUsersList.length <= 0 && (
                  <BrandedCard>
                    <div className={styles.noResultsCard} style={{ marginTop: "20px" }}>
                      <div>No Results to Display</div>
                    </div>
                  </BrandedCard>
                )}
              </div>
              {
                ((showAssignUnits && hasUnitsLoaded) ||
                  (showAssignAccounts && hasAccountsSearched) ||
                  (showViewAccounts && !viewNewsfeedLoading && newsfeedAssignedUsersList.length > 0)) && (
                  <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px", clear: "both" }}>
                    <CustomTooltip displayText={showAssignUnits ? ["Assign Units"] : (showAssignAccounts ? ["Assign Accounts"] : (showViewAccounts ? ["Unassign Accounts"] : ["Save"]))}>
                      <span className="saveAssignedData" style={{ marginRight: "-5px" }}>
                        <Button
                          disabled={selectedSubscriberGroupId < 0}
                          onClick={handleSaveAssignment}
                          variant="contained"
                          color="primary"
                          className="save"
                        >
                          <SaveIcon />
                        </Button>
                      </span>
                    </CustomTooltip>
                    <div className={styles.loaderContainer}>
                      <CircularProgress
                        style={{ width: isSavingAssignment ? "20px" : "0px", padding: "15px 10px" }}
                        color="primary"
                        size="10"
                      />
                    </div>
                  </div>
                )
              }
            </>
          }
         

        {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

      </div>

    </>
  );
};
export default NewsfeedAssignedGroups;
