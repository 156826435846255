import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UnitTreeItem, CourseTreeItem, CPDItem, CPDReportResultMetaData  } from "../../globalTypes";

//libs
import { Steps, Hints } from "intro.js-react";

//MUI
import { Button, Checkbox } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//Contexts
import { UIContext } from "../../contexts/UIContext";
import { LoaderContext } from "../../contexts/LoaderContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';

//API Functions
import { getRelevantUnitTree } from "../../API/GetRelevantUnitTree";
import { useReactOidc } from "@axa-fr/react-oidc-context";

//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";
import ResetIcon from "../../CustomIcons/ResetIcon";
import Units from "../ReportCommonComponents/Units";
import GuideIcon from '../../CustomIcons/GuideIcon';
import ButtonPanel from '../CurrentAdminReport/ButtonPanel'
//import MUICustomSelect from '../../Components/MUICustomSelect';

//Styles
import styles from "../../styles/cpdReport.module.css";
import getFullCPDReport from "../../API/GetFullCPDReport";
import getOrgCPDProfile from "../../API/GetOrgCPDProfile";

const CPDReport = () => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext);
  const history = useHistory();
  const { setMessage } = useContext(LoaderContext);
  const { currentLanguage} = useContext(TranslationsContext)
  const { setTitle, setTitleEnglish, updateBackButtonRoute } = useContext(UIContext);
  const { cpdList, currentCpd } = useContext(CPDPanelContext);
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";

  const [units, setUnits] = useState<Array<UnitTreeItem>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isResetClicked, setisResetClicked] = useState<boolean>(false);
  const [shortfallUsers, setShortfallUsers] = useState<boolean>(false);
  const [includeCourseModules, setIncludeCourseModules] = useState<boolean>(true);
  const [includePolicies, setIncludePolicies] = useState<boolean>(true);
  const [includeExternalCertificates, setIncludeExternalCertificates] = useState<boolean>(true);
  const [selectedProfileID, setSelectedProfileID] = useState<any>(-1);
  const [selectedProfilePeriodID, setSelectedProfilePeriodID] = useState<any>(-1);
  const [givenFirstName, setGivenFirstName] = useState<string>("");
  const [givenLastName, setGivenLastName] = useState<string>("");
  const [givenUserName, setGivenUserName] = useState<string>("");
  const [selectedProfileName, setSelectedProfileName] = useState<string>("");
  const [selectedProfilePeriodName, setSelectedProfilePeriodName] = useState<string>("");
  const [orgCPDProfileResult, setOrgCPDProfileResult] = useState<any>(null);  
  const [orgCPDListToPass, setOrgCPDListToPass] = useState<Array<any>>([]);  
  const [orgCPDPeriodListToPass, setOrgCPDPeriodListToPass] = useState<Array<any>>([]);  
  const [selectedCPDProfile, setSelectedCPDProfile] = useState<any>({});
  const [selectedCPDProfilePeriod, setSelectedCPDProfilePeriod] = useState<any>({});
  const [defaultCPDProfile, setDefaultCPDProfile] = useState<any>({});
  const [defaultCPDProfilePeriod, setDefaultCPDProfilePeriod] = useState<any>({});
  const [selectedCPDProfilePeriodList, setSelectedCPDProfilePeriodList] = useState<any>([]);

  // Extra states for report scheduler input
  const [courseList, setCourseList] = useState<Array<CourseTreeItem>>([]) 
  const [filterEmail, setFilterEmail] = useState("");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterBody, setFilterBody] = useState("");
  const [filterQuizStatus, setFilterQuizStatus] = useState("");
  const [filterSortBy, setFilterSortBy] = useState("0");
  const [filterExpiryValue, setFilterExpiryValue] = useState(0);
  const [filterExpiryUnit, setFilterExpiryUnit] = useState("d");
  const [filterAcceptance, setFilterAcceptance] = useState("0");
  const [filterLicensingPeriod, setFilterLicensingPeriod] = useState("");
  const [filterHistoricCourseIds, setFilterHistoricCourseIds] = useState("");
  const [filterPolicyIds, setFilterPolicyIds] = useState("");
  const [filterIncludeInactive, setFilterIncludeInactive] = useState<boolean>(false);
  const [filterCourseStatus, setFilterCourseStatus] = useState("");
  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");
  const [classificationList, setClassificationList] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState<any>({});
  const [groupingOption, setGroupingOption] = useState<boolean>(false);
  const [filterUnitName, setFilterUnitName] = useState("");

  const [isCPDProfileFocussed, setIsCPDProfileFocussed] = useState<boolean>(false);
  const [isCPDProfilePeriodFocussed, setIsCPDProfilePeriodFocussed] = useState<boolean>(false);
  const [cpdProfileListForSelect, setCPDProfileListForSelect] = useState<Array<any>>([]);
  const [cpdProfilePeriodListForSelect, setCPDProfilePeriodListForSelect] = useState<Array<any>>([]);

  const [intro, setIntro] = useState({
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".unitFilterButtons",
          title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,
        },      
      ]
    })

    useEffect(() => 
    {
      let updateSteps = [...intro.steps]
      updateSteps.push( 
                        {
                          element: ".unitFilterSearch",
                          title: "Filter Units", intro: allResourcesToTranslations.unitfiltersearch
                        },
                        {
                          element:  ".firstName",
                          title: "Filter", intro: allResourcesToTranslations.firstnamemsg
                      } ,
                      {
                          element:  ".lastName",
                          title: "Filter", intro: allResourcesToTranslations.lastnamemsg
                      } ,
                      {
                          element:  ".userName",
                          title: "Filter", intro: allResourcesToTranslations.usernamemsg
                      } ,
                      {
                        element:  ".cpdProfile",
                        title: "Filter", intro: allResourcesToTranslations.cpdrptgmfilter
                    } ,
                    {
                      element:  ".cpdProfilePeriod",
                      title: "Filter", intro: allResourcesToTranslations.cpdrptgmppfilter
                  } ,
                        {
                          element:  ".includeInactive",
                          title: "Filter", intro: allResourcesToTranslations.cpdrptgmcpdpointss
                        }, 
                        {
                          element:  ".runReport",
                          title: "Run Report", intro: allResourcesToTranslations.sntemrepgmmrunrep
                        },
                        {
                          element:  ".scheduleReport",
                          title: "Schedule Report", intro: allResourcesToTranslations.repgmschedulereport
                       }, 
                        {
                          element:  ".resetAll",
                          title: "Reset Filters", intro: allResourcesToTranslations.emrepgmram
                        } 
                      )
        setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };

    
  useEffect(() => {
    setTitle(allResourcesToTranslations.cpdreport);
    setTitleEnglish(pageTitles.cpdreport)
    updateBackButtonRoute("");
    setLoading(true);
    getRelevantUnitTree(
      oidcUser.access_token,
      userId,
      orgDomain,
      true,
      parseInt(orgId)
    ).then((unitTreeRes) => {
      if (unitTreeRes.isSuccess) {
        setUnits(unitTreeRes.unitTreeItems);
        getOrgCPDProfile(
          oidcUser.access_token,
          userId,
          orgDomain,
          parseInt(orgId)).then(
            (orgCPDProfRes) => {
              if (orgCPDProfRes.isSuccess){
                //debugger
                setOrgCPDProfileResult(orgCPDProfRes);
                if (!!orgCPDProfRes === false || 
                    !!orgCPDProfRes.cpdProfileItems === false || 
                    orgCPDProfRes.cpdProfileItems.length <= 0 || 
                    !!orgCPDProfRes.cpdProfileItems[0] === false ||
                    !!orgCPDProfRes.cpdProfileItems[0].profileID === false || 
                    !!orgCPDProfRes.cpdProfileItems[0].profilePeriodItems === false) {
                  setSelectedCPDProfile(null);
                  setSelectedCPDProfilePeriod(null);
                  setOrgCPDListToPass([]);
                  setOrgCPDPeriodListToPass([]);
                }
                else {
                  let firstPObj = {
                    value: orgCPDProfRes.cpdProfileItems[0].profileID, 
                    profileID: orgCPDProfRes.cpdProfileItems[0].profileID,
                    text: orgCPDProfRes.cpdProfileItems[0].profileName, 
                    profileName: orgCPDProfRes.cpdProfileItems[0].profileName,
                    profilePeriodItems: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems
                  }
                  let firstPPObj = {
                    value: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodID, 
                    profilePeriodID: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodID,
                    text: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName, 
                    profilePeriodName: orgCPDProfRes.cpdProfileItems[0].profilePeriodItems[0].profilePeriodName
                  }
                  
                  setSelectedCPDProfile(firstPObj);
                  setDefaultCPDProfile(firstPObj);
                  setSelectedCPDProfilePeriod(firstPPObj);
                  setDefaultCPDProfilePeriod(firstPPObj);
                  
                  var cpdProfiles = orgCPDProfRes.cpdProfileItems.map((cpd : any) => {
                      if (!!cpd && !!cpd.profileID){
                          let cpdToAdd : CPDItem = {id: 0, name: '', period: '', requiredPoints: 0, earnedPoints: 0};
                          cpdToAdd.id = cpd.profileID;
                          cpdToAdd.name = cpd.profileName;
                          return {value: cpdToAdd.id, text: cpdToAdd.name, profileID: cpdToAdd.id, profileName: cpdToAdd.name}; 
                      }
                  });
                  if (!!cpdProfiles && cpdProfiles.length > 0) {
                    setOrgCPDListToPass(cpdProfiles);
                  }
                  var cpdProfilePeriods = (!!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems) ? selectedCPDProfile.profilePeriodItems.map((cpdp : any) => {
                      if (!!cpdp){
                          return {value: cpdp.id, text: cpdp.name, profilePeriodID: cpdp.id, profilePeriodName: cpdp.name}; 
                      }
                  }) : null;
                  if (!!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems && selectedCPDProfile.profilePeriodItems.length > 0) {
                    setOrgCPDPeriodListToPass(selectedCPDProfile.profilePeriodItems);
                  }
                }
                setLoading(false);
              }
            }
          );        
      }
    });
  }, []);

  useEffect(() => {
    //debugger
    updateCurrentProfileSelection(selectedCPDProfile);
    var cpdProfilePeriods = (!!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems) ? selectedCPDProfile.profilePeriodItems.map((cpdp : any) => {
        if (!!cpdp){
            return {value: cpdp.id, text: cpdp.name, profilePeriodName:cpdp.name, profilePeriodID: cpdp.id }; 
        }
    }) : null;
    if (!!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems) {
      setOrgCPDPeriodListToPass(selectedCPDProfile.profilePeriodItems);
    }
  }, [selectedCPDProfile]);

  useEffect(() => {
    //debugger
    updateCurrentProfilePeriodSelection(selectedCPDProfilePeriod);
  }, [selectedCPDProfilePeriod]);

  const updateCurrentProfileSelection = (givenSelectedCPDProfile: any) => {
    //debugger
    if (!!givenSelectedCPDProfile && !!givenSelectedCPDProfile.profileID && !!givenSelectedCPDProfile.profileName) {
      setSelectedProfileID(givenSelectedCPDProfile.profileID);
      setSelectedProfileName(givenSelectedCPDProfile.profileName);
    }
    if (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems && !!givenSelectedCPDProfile.profileID) {
      var filtered = orgCPDProfileResult.cpdProfileItems.filter((item:any) => item.profileID.toString() === givenSelectedCPDProfile.profileID.toString());
      setSelectedCPDProfilePeriodList((!!filtered === false || filtered.length <= 0 || !!filtered[0].profilePeriodItems === false) ? null : filtered[0].profilePeriodItems);
      setSelectedProfileName((!!filtered === false || filtered.length <= 0 || !!filtered[0].profileName === false) ? "" : filtered[0].profileName);
      if (!(!!filtered === false || filtered.length <= 0 || !!filtered[0] === false || !!filtered[0].profileName === false || !!filtered[0].profilePeriodItems[0] === false)) {
        updateCurrentProfilePeriodSelection({value: filtered[0].profilePeriodItems[0].profilePeriodID, profilePeriodID: filtered[0].profilePeriodItems[0].profilePeriodID,
        text: filtered[0].profilePeriodItems[0].profilePeriodName, profilePeriodName: filtered[0].profilePeriodItems[0].profilePeriodName});
      }
    }
    else {
      setSelectedCPDProfilePeriodList(null);
      setSelectedProfileName("");
    }
  }

  const updateCurrentProfilePeriodSelection = (givenSelectedCPDProfilePeriod: any) => {
    if (!!givenSelectedCPDProfilePeriod !== true || !!givenSelectedCPDProfilePeriod.profilePeriodID !== true) {
      setSelectedProfilePeriodName("");
      return;
    }
    setSelectedProfilePeriodID(givenSelectedCPDProfilePeriod.profilePeriodID);
    if (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems) {
      var filtered = orgCPDProfileResult.cpdProfileItems.filter((item:any) => item.profileID.toString() === selectedCPDProfile.profileID.toString());
      if (!!filtered === false || filtered.length <= 0 || !!filtered[0].profilePeriodItems === false || !!givenSelectedCPDProfilePeriod.profilePeriodID === false) {
        setSelectedProfilePeriodName("")
      }
      else {
        var matchedPP = filtered[0].profilePeriodItems.filter((pp:any) => pp.profilePeriodID.toString() === givenSelectedCPDProfilePeriod.profilePeriodID.toString());
        if (!!matchedPP && matchedPP.length > 0) {
          setSelectedProfilePeriodName(matchedPP[0].profilePeriodName)
        }
        else {
          setSelectedProfilePeriodName("")
        }
      }                      
    }
    else {
      setSelectedProfilePeriodName("");
    }
  }
    
  const getValidClassificationID = (selectedClassification: any) => {
      var classID : number = 0;
      if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
          classID = ((typeof selectedClassification.classificationID) !== 'number') ? 
              parseInt(selectedClassification.classificationID.toString()) : 
              selectedClassification.classificationID;
      }
      return classID;
  }

  const setModalOpen = (isOpen: boolean) => 
  {
      let cpdProfileId = -1
      cpdProfileId = (
          (cpdList !== null && 
          cpdList !== undefined && 
          cpdList.length > 0 && 
          currentCpd >= 0 && 
          cpdList.length > currentCpd) ? 
          cpdList[currentCpd].id : -1)
      history.push({
          pathname: `/CPDR/reportscheduler/-1`,
          state: {                
              selectedUnits: units,
              firstName: givenFirstName,
              lastName: givenLastName,
              userName: givenUserName,
              profileID: cpdProfileId,
              selectedProfileID: !!selectedProfileID ? selectedProfileID : -1,
              selectedProfilePeriodID: !!selectedProfilePeriodID ? selectedProfilePeriodID : -1,
              reportId: 30,  // 30 is for CPDR
              onlyUsersWithShortfall: shortfallUsers,
              langCode: currentLanguage,
              givenOrgCPDList: orgCPDProfileResult,
              
              // Extra default for scheduler
              selectedCourses: courseList,
              email: filterEmail,
              isIncludeInactive: filterIncludeInactive,
              classificationList: classificationList,
              groupingOption: groupingOption,
              selectedClassification: selectedClassification,
              groupBy: ((selectedClassification === null || selectedClassification === undefined || 
                  selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value),
              selectedClassificationID: !!selectedClassification ? getValidClassificationID(selectedClassification.classificationID) : 0,
              dateFrom: filterDateFrom,
              dateTo: filterDateTo,
              subject: filterSubject,
              body: filterBody,
              status: filterCourseStatus,
              quizStatus: filterQuizStatus,
              sortBy: filterSortBy,
              expiryValue: filterExpiryValue,
              expiryUnit: filterExpiryUnit,
              acceptance: filterAcceptance,
              licensingPeriod: filterLicensingPeriod,
              historicCourseIds: filterHistoricCourseIds, 
              policyIds: filterPolicyIds
          },
      }); 
  }

  const handlerunReport = () => {

    let unitIds: Array<number> = [];
    let allUnitIds: Array<number> = [];
    let unitData: Array<string> = [];
    setLoading(true);
    setMessage("Fetching your report");
    const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
      if (unitArray === null) return //Break if no children
      if (unitArray.length === 0) return
      for (let i = 0; i < unitArray.length; i++) {
          const unit = unitArray[i];
          if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
            if (filterUnitName !== "") {
              if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                unitIds.push(unit.unitID);
                unitData.push(unit.name);
              }
            } else {
              unitIds.push(unit.unitID);
              unitData.push(unit.name);
            }
          }
          allUnitIds.push(unit.unitID);
          getSelectedUnitIds(unit.childUnitTreeItems);
      }
  }

  getSelectedUnitIds(units);


//debugger
    getFullCPDReport(oidcUser.access_token, userId, orgDomain, parseInt(orgId), unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
                     parseInt(!!selectedProfileID ? selectedProfileID.toString().trim() : "-1"), 
                     parseInt(!!selectedProfilePeriodID ? selectedProfilePeriodID.toString().trim() : "-1"), 
                     selectedProfileName, selectedProfilePeriodName, 
                     givenFirstName, givenLastName, givenUserName, (shortfallUsers ? 1 : 0),
                    includeCourseModules, includePolicies, includeExternalCertificates).then((reportRes) => {
      //debugger
      setLoading(false);
      history.push({
        pathname: `cpdreport/result`,               
        state: {
            selectedUnits: !!unitData ? unitData.toString() : "",
            givenFirstName: !!givenFirstName ? givenFirstName : "",
            shortfallUsers: !!shortfallUsers ? shortfallUsers : false,
            givenLastName: !!givenLastName ? givenLastName : "",
            givenUserName: !!givenUserName ? givenUserName : "",
            selectedProfileName: !!selectedProfileName ? selectedProfileName : "",
            selectedProfilePeriodName: !!selectedProfilePeriodName ? selectedProfilePeriodName : "", 
            includeCourseModules: includeCourseModules,              
            includePolicies: includePolicies,
            includeExternalCertificates: includeExternalCertificates,
            resultItems: (reportRes === null ? null : reportRes.cpdReportItems),
            resultMetaData: (reportRes === null ? null : { isCourseModulePresent: reportRes.isCourseModulePresent, isPolicyPresent: reportRes.isPolicyPresent,
              isExtCertPresent: reportRes.isExtCertPresent, isEventPresent: reportRes.isEventPresent } as CPDReportResultMetaData),
            resultError: ((reportRes === null || reportRes === undefined || reportRes.isSuccess || reportRes.error === null || reportRes.error === undefined) ? "" : reportRes.error)
        },
    });

    })
  };

  const resetAll = () => {
    setShortfallUsers(false);
    setGivenFirstName("");
    setGivenLastName("");
    setGivenUserName("");
    setSelectedProfileName("");
    setSelectedProfileID(-1);
    setSelectedProfilePeriodName("");
    setSelectedProfilePeriodID(-1);
    setisResetClicked(!isResetClicked);
    setSelectedCPDProfile(defaultCPDProfile);
    setDefaultCPDProfilePeriod(defaultCPDProfilePeriod);
  };

  if (loading) return <LoaderContainer />;

  return (
    <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
    <BrandedCard>
      <div className={styles.reportWrraper}>
        <div className={styles.unittreesection}>
          <div>
            <Units isResetClicked={isResetClicked} givenUnits={units} givenFilterUnitName={filterUnitName} setGivenFilterUnitName={setFilterUnitName} />
          </div>
        </div>
        <div className={styles.rightSection}> 
          <div className={styles.inputContainer}>
                <div className="firstName"><TextField  className={styles.input}  label={"First Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                  value={givenFirstName} onChange={(e) => setGivenFirstName(e.target.value)}/></div>
          </div>
          <div className={styles.inputContainer}>
              <div className="lastName"><TextField   className={styles.input}  label={"Last Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
              value={givenLastName} onChange={(e) => setGivenLastName(e.target.value)} /></div>
          </div>
          <div className={styles.inputContainer}>
              <div className="userName"><TextField  className={styles.input}  label={"Username"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
              value={givenUserName} onChange={(e) => setGivenUserName(e.target.value)} /></div>
          </div>
          <div>
            <div className={styles.groupingOption} >
              <FormControl variant="outlined" className={`${styles.formControl} cpdProfile`}>                      
                  <InputLabel id="cpdProfileID">CPD Profile</InputLabel>
                      <Select
                      value={!!selectedCPDProfile && !!selectedCPDProfile.value ? selectedCPDProfile.value : ""}
                      native
                      onChange={(e : any) => { 
                        let profID : string = e.target.value.toString();
                        let matched = !!orgCPDProfileResult ? orgCPDProfileResult.cpdProfileItems.filter((cur : any) => !!cur && 
                        parseInt(cur.profileID) === parseInt(profID)) : null;
                        var curSel = {value: e.target.value, profileID: e.target.value, profileName: (!!matched && !!matched[0]) ? matched[0].profileName : ""};
                        setSelectedCPDProfile(curSel);
                        updateCurrentProfileSelection(curSel);
                      }}
                      className={styles.input}
                      variant="outlined" 
                      label="CPD Profile"
                      id="cpdProfileID"
                      >
                      {!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems && orgCPDProfileResult.cpdProfileItems.map((item:any) => {
                          return <option key={item.profileID} value={item.profileID}>{item.profileName}</option>
                      })}
                  </Select>
              </FormControl>
                              {/* <MUICustomSelect idName={"cpdProfileID"} labelName={"CPD Profile"} currentValue={selectedCPDProfile.value}
                  setCurrentValue={(e : any) => 
                  {
                    //debugger
                    if (!!e.target) {
                      let curobj : any = new Object({ 
                        value: e.target.value, 
                        profileID: e.target.value, 
                        text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text, 
                        profileName: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text 
                      })
                      setSelectedCPDProfile({ 
                        value: e.target.value, 
                        profileID: e.target.value, 
                        text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text, 
                        profileName: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text 
                      });
                      updateCurrentProfileSelection({ 
                        value: e.target.value, 
                        profileID: e.target.value, 
                        text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text, 
                        profileName: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text 
                      });
                    }
                    else {
                        let filtered = (!!orgCPDProfileResult && !!orgCPDProfileResult.cpdProfileItems) ? 
                                    orgCPDProfileResult.cpdProfileItems.filter((cur : any) => !!cur && 
                                        parseInt(cur.profileID) === parseInt(e)) : null;
                        let curobj : any = new Object({ 
                          value: filtered[0].profileID, 
                          profileID: filtered[0].profileID, 
                          text: filtered[0].profileName, 
                          profileName:filtered[0].profileName,
                          profilePeriodItems: filtered[0].profilePeriodItems.map((pp : any) => { return {
                            value: pp.profilePeriodID, 
                            profilePeriodID: pp.profilePeriodID, 
                            text: pp.profilePeriodName, 
                            profilePeriodName: pp.profilePeriodName 
                          } }) 
                        });
                        if (!!filtered && filtered.length > 0){     
                          setSelectedCPDProfile({ 
                            value: filtered[0].profileID, 
                            profileID: filtered[0].profileID, 
                            text: filtered[0].profileName, 
                            profileName:filtered[0].profileName,
                            profilePeriodItems: filtered[0].profilePeriodItems.map((pp : any) => { return {
                              value: pp.profilePeriodID, 
                              profilePeriodID: pp.profilePeriodID, 
                              text: pp.profilePeriodName, 
                              profilePeriodName: pp.profilePeriodName 
                            } }) 
                          });
                          updateCurrentProfileSelection({ 
                            value: filtered[0].profileID, 
                            profileID: filtered[0].profileID, 
                            text: filtered[0].profileName, 
                            profileName:filtered[0].profileName,
                            profilePeriodItems: filtered[0].profilePeriodItems.map((pp : any) => { return {
                              value: pp.profilePeriodID, 
                              profilePeriodID: pp.profilePeriodID, 
                              text: pp.profilePeriodName, 
                              profilePeriodName: pp.profilePeriodName 
                            } }) 
                          });
                        }
                    }
                  } }
                  isFocussed={isCPDProfileFocussed} setIsFocussed={setIsCPDProfileFocussed}
                  inputLabelStyle={{}} listOfItems={orgCPDListToPass} /> */}
            </div>
          </div>
          <div >
            <div className={styles.groupingOption} >
              <FormControl variant="outlined" className={`${styles.formControl} cpdProfilePeriod`} >                      
                  <InputLabel id="cpdProfilePeriodID">CPD Profile Period</InputLabel>
                      <Select
                      value={!!selectedCPDProfilePeriod && !!selectedCPDProfilePeriod.value ? selectedCPDProfilePeriod.value : ""}
                      native
                      onChange={(e : any) => 
                        {
                          let profPrdID : string = e.target.value.toString();
                          let matched = !!selectedCPDProfilePeriodList ? selectedCPDProfilePeriodList.filter((cur : any) => !!cur && 
                          parseInt(cur.profilePeriodID) === parseInt(profPrdID)) : null;
                          var curSel = {value: e.target.value, profilePeriodID: e.target.value, profilePeriodName: 
                          (!!matched && !!matched[0]) ? matched[0].profilePeriodName : ""};
                          setSelectedCPDProfilePeriod({value: e.target.value, profilePeriodID: e.target.value});
                          updateCurrentProfilePeriodSelection(curSel);
                        }}
                      className={styles.input}
                      variant="outlined" 
                      label="CPD Profile Period"
                      id="cpdProfilePeriodID"
                      >
                      {
                        !!selectedCPDProfilePeriodList && 
                        selectedCPDProfilePeriodList.map((item:any) => { 
                          //debugger
                          return <option key={item.profilePeriodID} value={item.profilePeriodID}>{item.profilePeriodName}</option>
                        })
                      }
                  </Select>
              </FormControl>
                              {/* <MUICustomSelect idName={"cpdProfilePeriodID"} labelName={"CPD Profile Period"} currentValue={selectedCPDProfilePeriod.value}
                  setCurrentValue={(e : any) => 
                  {
                    //debugger
                      if (!!e.target) {
                        let curSel = 
                        { 
                          value: e.target.value, 
                          profilePeriodID: e.target.value, 
                          text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text, 
                          profilePeriodName: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text 
                        };
                        setSelectedCPDProfilePeriod({ 
                          value: e.target.value, 
                          profilePeriodID: e.target.value, 
                          text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text, 
                          profilePeriodName: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text 
                        });
                        updateCurrentProfilePeriodSelection({ 
                          value: e.target.value, 
                          profilePeriodID: e.target.value, 
                          text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text, 
                          profilePeriodName: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text 
                        });
                      }
                      else {
                        let filtered = (!!selectedCPDProfile && !!selectedCPDProfile.profilePeriodItems) ? 
                                        selectedCPDProfile.profilePeriodItems.filter((cur : any) => !!cur && 
                                        parseInt(cur.profilePeriodID) === parseInt(e)) : null;
                        if (!!filtered && filtered.length > 0){     
                          let curSel =
                          { 
                            value: filtered[0].profilePeriodID, 
                            profilePeriodID: filtered[0].profilePeriodID, 
                            text: filtered[0].profilePeriodName, 
                            profilePeriodName: filtered[0].profilePeriodName 
                          }                                                           
                          setSelectedCPDProfilePeriod({ 
                            value: filtered[0].profilePeriodID, 
                            profilePeriodID: filtered[0].profilePeriodID, 
                            text: filtered[0].profilePeriodName, 
                            profilePeriodName: filtered[0].profilePeriodName 
                          } );
                          updateCurrentProfilePeriodSelection({ 
                            value: filtered[0].profilePeriodID, 
                            profilePeriodID: filtered[0].profilePeriodID, 
                            text: filtered[0].profilePeriodName, 
                            profilePeriodName: filtered[0].profilePeriodName 
                          } );
                        }
                      }
                  } }
                  isFocussed={isCPDProfilePeriodFocussed} setIsFocussed={setIsCPDProfilePeriodFocussed}
                  inputLabelStyle={{}} listOfItems={orgCPDPeriodListToPass} /> */}

            </div>
          </div>
          {/* <div className={`includeCourseModules`} >
            <Checkbox
              size="small"
              color="primary"
              checked={includeCourseModules}
              className={styles.checkBox}
              onClick={() => setIncludeCourseModules(!includeCourseModules)}
            />{" "}
            {"Include Course Modules"}
          </div>
          <div className={`includePolicies`} >
            <Checkbox
              size="small"
              color="primary"
              checked={includePolicies}
              className={styles.checkBox}
              onClick={() => setIncludePolicies(!includePolicies)}
            />{" "}
            {"Include Policies"}
          </div>
          <div className={`includeExternalCertificates`} >
            <Checkbox
              size="small"
              color="primary"
              checked={includeExternalCertificates}
              className={styles.checkBox}
              onClick={() => setIncludeExternalCertificates(!includeExternalCertificates)}
            />{" "}
            {"Include External Certificates"}
          </div> */}
          <div className={`${styles.inactiveContainer} includeInactive`} >
            <Checkbox
              size="small"
              color="primary"
              checked={shortfallUsers}
              className={styles.checkBox}
              onClick={() => setShortfallUsers(!shortfallUsers)}
            />{" "}
            {"Show only users with Shortfall"}
          </div>
          {/* <div className={styles.buttonSection}>
          <CustomTooltip displayText={["Run Report"]}>
            <Button
              onClick={handlerunReport}
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              className='runReport'
              id="btnNewSaltCPDReportRun"
            >
              <AssessmentIcon />
            </Button>
            </CustomTooltip>
            <CustomTooltip displayText={["Reset"]}>
              <span className={`${styles.emailResetIcon} resetAll`} onClick={resetAll}>
                <ResetIcon />
              </span>
            </CustomTooltip>
          </div> */}
                        
          <div>
              <ButtonPanel openReportSchedulerModal={setModalOpen} showScheduler runReport={handlerunReport} resetAll={resetAll}/>
          </div> 
        </div>

        <div></div>
      </div>
    </BrandedCard>
    </>
  );
};

export default CPDReport;
