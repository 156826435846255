import axios from 'axios';
import { addError } from '../../AddError';
import { CourseSCORMcontentList } from '../../../globalTypes';

export const SaveCourse = async (token: string, userId: number, orgDomain: string, orgId: number | null,
  CourseID: number, Name:string,DurationInMins:Number ,Notes:string,Active:boolean,CreatedBy: number,UpdatedBy: number,act: string,
  selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = ""
  ,getSCORMcontentItems: Array<CourseSCORMcontentList>) => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/administration/SaveCourses`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        organisationID: orgId,      
        orgDomainName: orgDomain,
        requestedUserID: userId, 
        selectedOrgID: selectedOrgID,
        selectedOrgDomainName: selectedOrgDomainName, 
        selectOrgSiteName: selectOrgSiteName,
        courseID:  CourseID,       
        name: Name,
        notes:Notes,
        durationInMins: DurationInMins,
        active: Active, 
        createdBy: CreatedBy,
        updatedBy:UpdatedBy,
        act:act,
        GetSCORMcontentItems:getSCORMcontentItems
       
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.response, "SaveOrgDeactivation.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.request, "SaveOrgDeactivation.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }; 
      addError(token, userId, orgDomain, "Rest Exception", error.message, "SaveOrgDeactivation.ts", 1)
    }
    return null;
  }
};

export default SaveCourse;