//React + Typescript
import React, { useContext, useEffect, useState, useRef } from 'react';

// Libsimport
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//Intro Lib
import { Steps, Hints } from "intro.js-react";
//API Functions 
import styles from "../../styles/organisationmanagement.module.css";
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard';
import { useParams, useHistory } from 'react-router-dom';
import GetAssignedNewsfeed from '../../API/Administration/Newsfeed/GetAssignedNewsfeed';
import CustomTooltip from '../../Components/CustomTooltip';
import { Button } from '@material-ui/core';
import SendEmailReportIcon from '../../CustomIcons/SendEmailReportIcon';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import MarkReadNewsfeed from '../../API/Administration/Newsfeed/MarkReadNewsfeed';
import MarkFavouriteNewsfeed from '../../API/Administration/Newsfeed/MarkFavouriteNewsfeed';
import EarnNewsfeedProfilePoints from '../../API/Administration/Newsfeed/EarnNewsfeedProfilePoints';
import CheckIfHasCPDEnabledForNewsfeed from '../../API/Administration/Newsfeed/CheckIfHasCPDEnabledForNewsfeed';

const MyNewsfeedDetails = (props: any): JSX.Element => {
  const { newsfeedIdToRead } = useParams<{ newsfeedIdToRead: string }>();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
  const history = useHistory();
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(true);
  const { oidcUser } = useReactOidc();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);

  const [heading, setHeading] = useState<string>(props?.location?.state?.heading ?? "");
  const [subHeading, setSubHeading] = useState<string>(props?.location?.state?.subHeading ?? "");
  const [articleText, setArticleText] = useState<string>(props?.location?.state?.articleText ?? "");
  const [isFavourite, setIsFavourite] = useState<boolean>(props?.location?.state?.isFavourite ?? false);
  const [cpdPoints, setCpdPoints] = useState<string>(props?.location?.state?.cpdPoints ?? "");
  const [earnedCpdPoints, setEarnedCpdPoints] = useState<string>(props?.location?.state?.earnedCpdPoints ?? "");
  const [dateCreated, setDateCreated] = useState<string>(props?.location?.state?.dateCreated ?? "");
  const [datePublishedToUser, setDatePublishedToUser] = useState<string>(props?.location?.state?.datePublishedToUser ?? "");
  const [dateRead, setDateRead] = useState<string>(props?.location?.state?.dateRead ?? "");
  const [dateOpened, setDateOpened] = useState<string>(props?.location?.state?.dateOpened ?? "");
  const [nfCategories, setNfCategories] = useState<string>(props?.location?.state?.nfCatedories ?? "");
  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(props?.location?.state?.nfCatedories.split(",") ?? []); 

  const [hasAssignedCpdPoints, setHasAssignedCpdPoints] = useState<boolean>(props?.location?.state?.hasAssignedCpdPoints ?? false);

  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const language = useRef<string>("en-AU");

  const loadNewsfeed = () => {

    GetAssignedNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(newsfeedIdToRead)).then((result: any) => {
      if (result.isSuccess && result.error == '') {
        setHeading(result.heading);
        setSubHeading(result.subHeading);
        setArticleText(result.articleText);
        setIsFavourite(result.isFavourite);
        setCpdPoints(result.cpdPoints);
        setEarnedCpdPoints(result.earnedPoints.toString());

        if(result.hasAssignedCpdPoints) {
            CheckIfHasCPDEnabledForNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result1: any) => {
              if (result1.isSuccess && result1.error == '') {
                setHasAssignedCpdPoints(result.hasAssignedCpdPoints && result1.isCPDEnabled);  
              }
            });
        } else {
          setHasAssignedCpdPoints(result.hasAssignedCpdPoints);
        }
       
        setDateCreated(result.dateCreated);
        setDatePublishedToUser(result.publishedDate);
        setDateRead(result.readDate);
        setDateOpened(result.openDate);
        setNfCategories(result.nfCategoryCsv);
        setSelectedCategories(result.nfCategoryCsv.split(","));
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load newsfeed!");
      }
      setLoading(false);
    });
  }
  useEffect(() => {
    setTitle(allResourcesToTranslations.mynewsfeed)
    setTitleEnglish(pageTitles.mynewsfeed)
    updateBackButtonRoute("/mynewsfeed");
    setMenuSelection("My Newsfeed");
    setLoading(true);
    MarkReadNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId), parseInt(newsfeedIdToRead)).then((result: any) => {
      if (!result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not mark newsfeed as read!");
      }
    });
    if (heading === "" && subHeading === "" && articleText === "") {
      loadNewsfeed();
    }
    else {
      CheckIfHasCPDEnabledForNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result: any) => {
        if (result.isSuccess && result.error == '') {
          setHasAssignedCpdPoints(result.isCPDEnabled);  
        }
      });
      setLoading(false);
    }
    return () => { };
  }, []);

  const handleClosePopup = () => {
    setShowWarningModal(false);
  };

  const handleEarnCPDPoints = () => {
    EarnNewsfeedProfilePoints(oidcUser.access_token, userId, orgDomain, parseInt(newsfeedIdToRead)).then((result: any) => {
      if (!result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage(result.error != "" ? result.error : "Can not earn CPD points!");
      }
      else {
        setShowWarningModal(true);
        setHasErrored(false);
        setSaveSuccess(true);
        setModalPopupMessage("CPD points earned!");
        loadNewsfeed();
      }
    });
  };
  
  const handleMarkFavoutite = (newsfeedId : number, isFavourite: boolean) => {
    MarkFavouriteNewsfeed(oidcUser.access_token, userId, orgDomain, parseInt(orgId), newsfeedId, isFavourite).then((result: any) => {
      if (!result.isSuccess) {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not mark as favourite!");
      }
      else{
        loadNewsfeed();
        setIsFavourite(!isFavourite);
      }
    });
  }
  const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
  {
    if (!isNaN(givenDate.getTime())) {
      const year = new Intl.DateTimeFormat('en-AU', { year: 'numeric' }).format(givenDate);
      const month = new Intl.DateTimeFormat('en-AU', { month: 'numeric' }).format(givenDate);
      const day = new Intl.DateTimeFormat('en-AU', { day: 'numeric' }).format(givenDate);

      return (
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString())) + "/" +
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "/" +
        (parseInt(year) < 10 ? "000" + year.toString()
          : ((parseInt(year) < 100 ? "00"
            : (((parseInt(year) < 1000 ? "0" : year.toString()))))));
    }
    else {
      return "";
    }
  }

  useEffect(() => {
    if (showWarningModal && saveSuccess) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
        history.push({
          pathname: `/mynewsfeed`
        });
      }, 3000)
    }
  }, [showWarningModal, saveSuccess])

  if (loading) return <LoaderContainer />;
  return (
    <>
      <div className={styles.mainWrapper}>
        <BrandedCard>
          <div style={{ clear: "both", padding: "20px" }} >
            <span style={{ fontSize: "20px" }}>{heading}</span>
            {isFavourite ? 
                  <span style={{ padding: "0px 20px", marginTop: "5px", cursor: "pointer" }} onClick={() => handleMarkFavoutite(parseInt(newsfeedIdToRead), false)}><StarIcon></StarIcon></span> 
                : <span style={{ padding: "0px 20px", marginTop: "5px", cursor: "pointer" }}  onClick={() => handleMarkFavoutite(parseInt(newsfeedIdToRead), true)}><StarOutlineIcon ></StarOutlineIcon></span>
            }
            <br></br>
            <div style={{ fontSize: "16px", paddingTop: "15px" }}>Date created: {formatDate((new Date(dateCreated)))}</div> <br></br> 
            <div>
              {
                selectedCategories.map((category: any) => {
                  return (
                    category !== "" && category !== null &&
                    <div style={{ float: "left", paddingRight: "10px" }}><span style={{ border: '2px solid #ccc', backgroundColor: "#ccc", color: "#000", padding: '8px 10px', borderRadius: "20px" }} className={styles.newsfeedcategorybutton} >{category}</span></div>
                  );
                })
              }                 
            </div> <br></br> 
            <div style={{ fontSize: "16px", paddingTop: "15px" }}>{subHeading}</div>
            <hr/>
            <div
              dangerouslySetInnerHTML={{
                __html: articleText //bodyFormatted
              }}>
            </div> <br></br> <br></br>

          </div>
        </BrandedCard>
        {hasAssignedCpdPoints &&
          <div className="ernCPDPoints">
            <CustomTooltip displayText={["Earn CPD Point"]} >
              <span>
                <Button
                  onClick={handleEarnCPDPoints}
                  disabled={earnedCpdPoints !== "0"}
                  variant="contained"
                  color="primary"
                  id="btnNewSaltSendEmailReport">
                  {allResourcesToTranslations.earncpdpoints}
                </Button>
              </span>
            </CustomTooltip>
          </div>}
      </div>
      {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
    </>
  );
};
export default MyNewsfeedDetails;
