//React + Typescript
import React, { useContext, useEffect, useState } from 'react';
import { CPDItem } from '../globalTypes'
//Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Context
import { TranslationsContext } from '../contexts/TranslationsContext'
import { UIContext } from '../contexts/UIContext';
import { CPDPanelContext } from "../contexts/CPDPanelContext";
//API Functions
import { addError } from '../API/AddError'
import { getUserCPDs } from '../API/UserCPDs';
//Components
import BrandedCard from './BrandedCard';
//MUI Components
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
//Stypes
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styles from '../styles/cpdPanel.module.css'
import { CircularProgress } from '@material-ui/core';

import loadingGif from '../CustomIcons/GIFs/loading_dots_blue.gif';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%"
    }, 
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    tableCell: {
      borderBottom: "none"
    }
  }),
);

interface Props {
  setCurrentCpd: (cpdIndex: number) => void
  currentCpd: number
  cpdList: Array<CPDItem>
  showCPD: boolean
  isCPDLoading: boolean
  calledFrom: string
}

export const CPDPanel = ({setCurrentCpd, currentCpd, cpdList, showCPD, isCPDLoading, calledFrom}: Props): JSX.Element => {
  const { translations: {allResourcesToTranslations} } = useContext(TranslationsContext);
  const { setShowCPD, EnableActivityLogs, latestUserCPDs, setLatestUserCPDs, setSelectedCPDIndex } = useContext(UIContext);
  const { setCpdList } = useContext(CPDPanelContext);
  let cpd: CPDItem = !!cpdList ? cpdList[(!!currentCpd ? parseInt(currentCpd.toString()) : 0)] : { id:-1, name: '', period: '', requiredPoints: 0, earnedPoints: 0 };
  const classes = useStyles();
  const { oidcUser } = useReactOidc();
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0;
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
  const [isCPDLoadingLocal, setCPDLoadingLocal] = useState<boolean>(false);
  const [showCPDLocal, setShowCPDLocal] = useState<boolean>(false);
  const [cpdListLocal, setCPDListLocal] = useState<CPDItem[]>([]);
  const [isLocalUpdateHappened, setIsLocalUpdateHappened] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<any>) => {
    setCurrentCpd(event.target.value as number);
    setSelectedCPDIndex(event.target.value as number);
    const cpdName = ((!!cpd && cpd.id >= 0) ? cpdList : cpdListLocal)[event.target.value].name
    addError(oidcUser.access_token, userId, orgDomain, `Selected ${cpdName} from CPD card dropdown`, null, "CPDPanel.tsx", 6, EnableActivityLogs)
  };

  const getLatestCPDs = (changeRender: boolean = false) => {
    let cpdId = -1;
    let cpdPrdId = -1;
    setCPDLoadingLocal(true);
    getUserCPDs(oidcUser.access_token, userId, orgDomain).then((cpdData) => {
      setLatestUserCPDs(cpdData);
      setCPDLoadingLocal(false);

      let cpdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].id : -1
      let cpdPrdIdTmp = !!cpdData && cpdData.length > 0 ? cpdData[0].periodID : -1
      setShowCPD(cpdIdTmp > 0 && cpdPrdIdTmp > 0);
      setShowCPDLocal(cpdIdTmp > 0 && cpdPrdIdTmp > 0);
      setCpdList(cpdData);
      setCPDListLocal(cpdData);

      cpdId = (!!cpdData && cpdData.length > 0) ? cpdData[(!!currentCpd ? parseInt(currentCpd.toString()) : 0)].id : -1
      cpdPrdId = (!!cpdData && cpdData.length > 0) ? cpdData[(!!currentCpd ? parseInt(currentCpd.toString()) : 0)].periodID : -1
      if (changeRender)
        setIsLocalUpdateHappened(true);      

    }).catch((e) => {
      setCPDLoadingLocal(false);
    });
  }

  useEffect(() => {  
    // If given CPDs are invalid, get the CPDs again from API
    if (!(!!cpd && !!cpdList && (!!currentCpd ? parseInt(currentCpd.toString()) : 0) >= 0 && cpdList.length > (!!currentCpd ? parseInt(currentCpd.toString()) : 0))) {
      getLatestCPDs();
    }

    return () => { };
  }, []);

  useEffect(() => {  
    if (!(!!cpdListLocal && cpdListLocal.length > 0)) {
      getLatestCPDs(true);
    }
    else {
      cpd = (!!cpdListLocal && cpdListLocal.length > 0 ? cpdListLocal[(!!currentCpd ? parseInt(currentCpd.toString()) : 0)] : { id:-1, name: '', period: '', requiredPoints: 0, earnedPoints: 0 });
    }
  }, [isLocalUpdateHappened, cpdList]);

  const renderCpd = (cpdItem: CPDItem): JSX.Element | void => {
    let isGiven = (!!cpd && cpd.id >= 0);
    // if (!isGiven && !!calledFrom && calledFrom.trim().toLowerCase() == "modulelist") {
    //   getLatestCPDs(true);
    // }
    if ((isCPDLoading || isCPDLoadingLocal) && (showCPD || showCPDLocal)) {
      return (
        <TableBody>
          <TableRow>
            <TableCell align="center" >
              {/* <CircularProgress id="cpdCardLoadingCommon" /> */}
              <nav  style={{width:"40px", height: "40px"}}><img src={loadingGif} id={"cpdCardLoadingCommon" } style={{width:"40px", height: "40px", marginLeft: "20px"}}  /></nav>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    else if ((showCPD || showCPDLocal) && !!cpdItem && cpdItem.id >= 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell align="left" className={`${classes.tableCell} profile`}>
              <FormControl className={classes.formControl}>
                <NativeSelect onChange={handleChange} defaultValue={cpdItem.name}>
                  <option disabled>
                    {cpdItem.name}
                  </option>
                  {((!!cpd && cpd.id >= 0) ? cpdList : cpdListLocal).map((cpdOption, index) => {
                      return (
                        <option key={cpdOption.name + index} value={index}>
                          {cpdOption.name}
                        </option>
                      );
                  })}
                </NativeSelect>
              </FormControl>
            </TableCell>
            <TableCell className={`${classes.tableCell} period`} align="center">
              {cpdItem.period}
            </TableCell>
            <TableCell className={`${classes.tableCell} points`} align="center">
              {cpdItem.requiredPoints}
            </TableCell>
            <TableCell className={`${classes.tableCell} earnedPoints`} align="center">
              {cpdItem.earnedPoints}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    else {
      return <></>;
    }
    // else if ((showCPD || showCPDLocal) && !!cpdItem && !!cpdList && (!!currentCpd ? parseInt(currentCpd.toString()) : 0) >= 0 && cpdList.length > (!!currentCpd ? parseInt(currentCpd.toString()) : 0)) {
    //   return (
    //     <TableBody>
    //       <TableRow>
    //         <TableCell align="left" className={`${classes.tableCell} profile`}>
    //           <FormControl className={classes.formControl}>
    //             <NativeSelect onChange={handleChange} defaultValue={cpdList[(!!currentCpd ? parseInt(currentCpd.toString()) : 0)].name}>
    //               <option disabled>
    //                 {cpdList[(!!currentCpd ? parseInt(currentCpd.toString()) : 0)].name}
    //               </option>
    //               {cpdList.map((cpdOption, index) => {
    //                   return (
    //                     <option key={cpdOption.name + index} value={index}>
    //                       {cpdOption.name}
    //                     </option>
    //                   );
    //               })}
    //             </NativeSelect>
    //           </FormControl>
    //         </TableCell>
    //         <TableCell className={`${classes.tableCell} period`} align="center">
    //           {cpdItem.period}
    //         </TableCell>
    //         <TableCell className={`${classes.tableCell} points`} align="center">
    //           {cpdItem.requiredPoints}
    //         </TableCell>
    //         <TableCell className={`${classes.tableCell} earnedPoints`} align="center">
    //           {cpdItem.earnedPoints}
    //         </TableCell>
    //       </TableRow>
    //     </TableBody>
    //   );
    // }
    // else {
    //   return <></>;
    // }
  };

  if (showCPD || showCPDLocal){
    return (
      <BrandedCard>
        <div className={styles.tableContainer} id="CPDCard"  style={{ height:"225px"}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {allResourcesToTranslations.cpdprofile  /* Removed capital letter */}
                </TableCell>
                <TableCell align="center">
                  {allResourcesToTranslations.period}
                </TableCell>
                <TableCell align="center">
                  {allResourcesToTranslations.requiredpoints}
                </TableCell>
                <TableCell align="center">
                  {allResourcesToTranslations.pointsearned}
                </TableCell>
              </TableRow>
            </TableHead>
            {renderCpd((!!cpd && cpd.id >= 0) ? cpd : (!!cpdListLocal && cpdListLocal.length > 0 ? cpdListLocal[(!!currentCpd ? parseInt(currentCpd.toString()) : 0)] : { id:-1, name: '', period: '', requiredPoints: 0, earnedPoints: 0 }))}
          </Table>
        </div>
      </BrandedCard>
    )
  } else {
    return <></>
  }
};

export default CPDPanel;
