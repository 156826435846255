import axios from "axios";
import { addError } from '../../AddError';

export const ImportGroupByReadFileData = async (token: string, userId: number, orgDomain: string, orgId: number, byteArray: string, fileExtension: string, filename: string, groupByName: string,selectedOrgID: number = -1, selectedOrgDomainName: string = "", selectOrgSiteName: string = "") => {

  try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/organisation/ImportGroupByReadFileData`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        RequestingUserID: userId,
        OrganisationID: orgId,
        FileData: byteArray,
        FileExtension: fileExtension,
        FileName: filename,
        GroupByName: groupByName,
        OrgDomainName: orgDomain,
        SelectedOrgID: selectedOrgID,
        SelectedOrgDomainName: selectedOrgDomainName, 
        SelectOrgSiteName: selectOrgSiteName
      }
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.response);
      }
      addError(token, userId, orgDomain, "Rest Exception", error.response, "ImportGroupByReadGivenFile.ts", 1)
    } else if (error.request) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.request);
      }
      addError(token, userId, orgDomain, "Rest Exception", error.request, "ImportGroupByReadGivenFile.ts", 1)
    } else if (error.message) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(error.message);
      }
      addError(token, userId, orgDomain, "Rest Exception", error.message, "ImportGroupByReadGivenFile.ts", 1)
    }
    return null;
  }
};

export default ImportGroupByReadFileData;
