import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/singlesignon.module.css";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { Steps } from "intro.js-react";
import CustomTooltip from "../../../Components/CustomTooltip";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import { Button, Checkbox, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, InputAdornment, IconButton } from "@material-ui/core";
import SaveIcon from "../../../CustomIcons/SaveIcon";
import CommonPopupModalForResult from "../../../Components/CommonPopupModalForResult";
import PDFFileIcon from "../../../CustomIcons/PDFFileIcon";
import getOrgSamlSSOSettings from "../../../API/GetOrgSamlSSOSettings";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import serialToArray from "../../../functions/serialToArray";
import addError from "../../../API/AddError";
import GetOrgSamlDetails from "../../../API/Administration/Organisation/GetOrgSamlDetails";
import SaveOrgSamlDetails from "../../../API/Administration/Organisation/SaveOrgSamlDetails";
import GetSAMLSetupGuide from "../../../API/Administration/Organisation/GetSAMLSetupGuide";
import SSOGoogleIcon from "../../../CustomIcons/Administration/SSOGoogleIcon";
import SSOAzureIcon from "../../../CustomIcons/Administration/SSOAzureIcon";
import SSOOktaIcon from "../../../CustomIcons/Administration/SSOOktaIcon";
import SSOMSIcon from "../../../CustomIcons/Administration/SSOMSIcon";
import ErrorIcon from "../../../CustomIcons/Administration/errorIcon";
import SuccessIcon from "../../../CustomIcons/Administration/successIcon";
import ProceedIcon from "../../../CustomIcons/ProceedIcon";
import oidcConfig from '../../../configuration';
import { v4 as uuidv4 } from "uuid"; // Using uuid to generate a unique key

const SingleSignOn = () => {
	const [ superAdminDomain, setSuperAdminDomain ] = useState<string>(oidcConfig.currentdomainSA);
	const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext);
	const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, IsEbookEnabled, EnableActivityLogs, timeZoneId, superAdminSelectedBasicOrgData } = useContext(UIContext);
	const { oidcUser } = useReactOidc();
	const {
		profile: { user_id, website,  },
	} = oidcUser || {};
	const userId = user_id ? parseInt(user_id) : 0;
	const orgId = oidcUser.profile.organisation_id ?? -1;
	const orgDomain = website
		? website
			.toLowerCase()
			.replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
		: "";

	const [enablesso, setEnablesso] = useState<boolean>(true);
	const [samlcertificatekey, setSamlcertificatekey] = useState<string>('');
	const [samlcertificatekeyadmin, setSamlCertificatekeyadmin] = useState<string>('');
	const [samlconsumerendpoint, setSamlconsumerendpoint] = useState<string>('');
	const [samlproviderendpoint, setSamlproviderendpoint] = useState<string>('');
	const [samlproviderendpointadmin, setSamlproviderendpointadmin] = useState<string>('');

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [saveSuccess, setSaveSuccess] = React.useState(false);
	const [modalPopupMessage, setModalPopupMessage] = useState('');
	const [hasErrored, setHasErrored] = useState<boolean>(false);

	const [enableSsoEventsIntegration, setEnableSsoEventsIntegration] = useState(false);
    const [ssoProvider, setSsoProvider] = useState<any>({value: 'None'});
    const [ssoAppName, setSsoAppName] = useState('');
    const [ssoAppId, setSsoAppId] = useState('');
    const [ssoApiToken, setSsoApiToken] = useState('');
    const [saltAPIAuthenticationSecret, setSaltAPIAuthenticationSecret] = useState('');
    const [ssoClientSecret, setSsoClientSecret] = useState('');
    const [ssoTenantID, setSsoTenantID] = useState('');
    const [ssoAdminEmail, setSsoAdminEmail] = useState('');
    const [ssoIsGrpSubscriptionActive, setSsoIsGrpSubscriptionActive] = useState(false);
    const [ssoIsUserSubscriptionActive, setSsoIsUserSubscriptionActive] = useState(false);
    const [ssoGrpSubscriptionExpiryDate, setGrpSsoSubscriptionExpiryDate] = useState('');
    const [ssoUserSubscriptionExpiryDate, setUserSsoSubscriptionExpiryDate] = useState('');

	const [intro, setIntro] = useState({
		stepsEnabled: false,
		initialStep: 0,
		steps: [
			{
				element: ".shibbolethguideme",
				title: allResourcesToTranslations.ssosetupguides,
				intro: allResourcesToTranslations.shibbolethguideme,
				
			},
			{
				element: ".oktassosetupguides",
				title: allResourcesToTranslations.oktassosetupguides,
				intro: allResourcesToTranslations.oktassosetupguideme,
			},
			{
				element: ".azureadssosetupguides",
				title: allResourcesToTranslations.azureadssosetupguides,
				intro: allResourcesToTranslations.azureadssosetupguideme,
			},
			{
				element: ".adfsssosetupguides",
				title: allResourcesToTranslations.adfsssosetupguides,
				intro: allResourcesToTranslations.adfsssosetupguideme,
			},
			{
				element: ".googlessosetupguides",
				title: allResourcesToTranslations.googlessosetupguides,
				intro: allResourcesToTranslations.googlesssosetupguideme,
			}, 
			{
				element: ".enablesso",
				title: allResourcesToTranslations.enablesso,
				intro: allResourcesToTranslations.enablessoguideme,
			},
			{
				element: ".consumerEndPoint",
				title: allResourcesToTranslations.consumerEndPoint,
				intro: allResourcesToTranslations.samlconsumerendpointguideme,
			},
			{
				element: ".samlcertificatekey",
				title: allResourcesToTranslations.samlcertificatekey,
				intro: allResourcesToTranslations.samlcertificatekeyguideme,
			},
			{
				element: ".samlproviderendpoint",
				title: allResourcesToTranslations.samlproviderendpoint,
				intro: allResourcesToTranslations.samlproviderendpointguideme,
			},
			{
				element: "#ssoaccountmanagement",
				title: allResourcesToTranslations.ssoaccountmanagementtitle,
				intro: allResourcesToTranslations.ssoaccountmanagementguideme2,
			},
			{
				element: ".saveData",
				title: "Save",
				intro: "Click here to save all changes",
			}
		],
	});

	const onExit = () => {
		setIntro({ ...intro, stepsEnabled: false });
	};

	const handleClosePopup = () => {
		setShowModal(false);
	};

	useEffect(() => {
		// setTitle(allResourcesToTranslations.singlesignon);
		// setTitleEnglish(pageTitles.singlesignon);
		// updateBackButtonRoute("");
		// setLoading(true);
		// setMenuSelection("Support Details");
		// GetOrgSamlDetails(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((result) => {
		// 	if (result.isSuccess && result.error == '') {
		// 		if (result.orgSamlDetail !== null) {
		// 			setSamlconsumerendpoint(result.orgSamlDetail.samlConsumerEndPoint);
		// 			setSamlcertificatekey(result.orgSamlDetail.samlCertificate === null ? "" : result.orgSamlDetail.samlCertificate);
		// 			setSamlproviderendpoint(result.orgSamlDetail.samlProviderEndpoint === null ? "" : result.orgSamlDetail.samlProviderEndpoint);
		// 			setSamlCertificatekeyadmin(result.orgSamlDetail.samlCertificateAdmin === null ? "" : result.orgSamlDetail.samlCertificateAdmin);
		// 			setSamlproviderendpointadmin(result.orgSamlDetail.samlProviderEndpointAdmin === null ? "" : result.orgSamlDetail.samlProviderEndpointAdmin);
		// 			setEnablesso(result.orgSamlDetail.enableSamlSSO);
		// 		}
		// 	}
		// 	else {
		// 		setShowModal(true);
		// 		setHasErrored(true);
		// 		setSaveSuccess(false);
		// 		setModalPopupMessage("Can not load Organisation details!");
		// 	}
		// 	setLoading(false);
		// });

		fetchAndSetOrgSamlDetails(
			userId,
			orgDomain,
			parseInt(!!orgId ? orgId.toString() : "-1"),
			setTitle,
			setTitleEnglish,
			updateBackButtonRoute,
			setLoading,
			setMenuSelection,
			setSamlconsumerendpoint,
			setSamlcertificatekey,
			setSamlproviderendpoint,
			setSamlCertificatekeyadmin,
			setSamlproviderendpointadmin,
			setEnablesso,
			setShowModal,
			setHasErrored,
			setSaveSuccess,
			setModalPopupMessage
		  );

		// at the end, return a cleanup method
		return () => { };
	}, []);

	const fetchAndSetOrgSamlDetails = async (
	  userId: number,
	  orgDomain: string,
	  orgId: number,
	  setTitle: (title: string) => void,
	  setTitleEnglish: (title: string) => void,
	  updateBackButtonRoute: (route: string) => void,
	  setLoading: (loading: boolean) => void,
	  setMenuSelection: (selection: string) => void,
	  setSamlconsumerendpoint: (endpoint: string) => void,
	  setSamlcertificatekey: (key: string) => void,
	  setSamlproviderendpoint: (endpoint: string) => void,
	  setSamlCertificatekeyadmin: (key: string) => void,
	  setSamlproviderendpointadmin: (endpoint: string) => void,
	  setEnablesso: (enabled: boolean) => void,
	  setShowModal: (show: boolean) => void,
	  setHasErrored: (errored: boolean) => void,
	  setSaveSuccess: (success: boolean) => void,
	  setModalPopupMessage: (message: string) => void
	) => {
		setTitle(allResourcesToTranslations.singlesignon);
		setTitleEnglish(pageTitles.singlesignon);
		updateBackButtonRoute("");
		setLoading(true);
		setMenuSelection("Support Details");
		GetOrgSamlDetails(oidcUser.access_token, userId, orgDomain, orgId, 
			(superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
			(superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName.toLowerCase().replace("altlogin-", "") : "",
			(superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "").then((result) => {
			if (!!result && result.isSuccess && result.error == '') {
				if (result.orgSamlDetail !== null) {
					setSamlconsumerendpoint(result.orgSamlDetail.samlConsumerEndPoint);
					setSamlcertificatekey(result.orgSamlDetail.samlCertificate === null ? "" : result.orgSamlDetail.samlCertificate);
					setSamlproviderendpoint(result.orgSamlDetail.samlProviderEndpoint === null ? "" : result.orgSamlDetail.samlProviderEndpoint);
					setSamlCertificatekeyadmin(result.orgSamlDetail.samlCertificateAdmin === null ? "" : result.orgSamlDetail.samlCertificateAdmin);
					setSamlproviderendpointadmin(result.orgSamlDetail.samlProviderEndpointAdmin === null ? "" : result.orgSamlDetail.samlProviderEndpointAdmin);
					setEnablesso(result.orgSamlDetail.enableSamlSSO);
					// SSO event integration values
					setEnableSsoEventsIntegration(result.orgSamlDetail.isSSOEventIntegrationEnabled);
					setSsoProvider({value: result.orgSamlDetail.ssoType});
					setSsoAppName(result.orgSamlDetail.ssoAppName);
					setSsoAdminEmail(result.orgSamlDetail.ssoAppAdminEmail);
					setSsoApiToken(result.orgSamlDetail.ssoApiToken);
					setSaltAPIAuthenticationSecret(result.orgSamlDetail.saltAPIAuthenticationSecret);
					setSsoAppId(result.orgSamlDetail.ssoAppID);
					setSsoClientSecret(result.orgSamlDetail.ssoClientSecret);
					setSsoTenantID(result.orgSamlDetail.ssoTenantID);
					setGrpSsoSubscriptionExpiryDate(result.orgSamlDetail.ssoSubscriptionExpiryForGroupEvents);
					setUserSsoSubscriptionExpiryDate(result.orgSamlDetail.ssoSubscriptionExpiryForUserEvents);
					setSsoIsGrpSubscriptionActive(!result.orgSamlDetail.ssoSubscriptionExpiredForGroupEvents);
					setSsoIsUserSubscriptionActive(!result.orgSamlDetail.ssoSubscriptionExpiredForUserEvents);
				}
			}
			else {
				setShowModal(true);
				setHasErrored(true);
				setSaveSuccess(false);
				setModalPopupMessage("Can not load Organisation details!");
			}
			setLoading(false);
		});

		//   setTitle(allResourcesToTranslations.singlesignon);
		//   setTitleEnglish(pageTitles.singlesignon);
		//   updateBackButtonRoute("");
		//   setLoading(true);
		//   setMenuSelection("Support Details");
		
		//   try {
		// 	const result = await GetOrgSamlDetails(oidcUserAccessToken, userId, orgDomain, orgId);
		// 	if (result.isSuccess && result.error === '') {
		// 	  if (result.orgSamlDetail !== null) {
		// 		setSamlconsumerendpoint(result.orgSamlDetail.samlConsumerEndPoint);
		// 		setSamlcertificatekey(result.orgSamlDetail.samlCertificate ?? "");
		// 		setSamlproviderendpoint(result.orgSamlDetail.samlProviderEndpoint ?? "");
		// 		setSamlCertificatekeyadmin(result.orgSamlDetail.samlCertificateAdmin ?? "");
		// 		setSamlproviderendpointadmin(result.orgSamlDetail.samlProviderEndpointAdmin ?? "");
		// 		setEnablesso(result.orgSamlDetail.enableSamlSSO);
		// 	  }
		// 	} else {
		// 	  setShowModal(true);
		// 	  setHasErrored(true);
		// 	  setSaveSuccess(false);
		// 	  setModalPopupMessage("Can not load Organisation details!");
		// 	}
		//   } catch (error) {
		// 	setShowModal(true);
		// 	setHasErrored(true);
		// 	setSaveSuccess(false);
		// 	setModalPopupMessage("An error occurred while loading Organisation details!");
		//   } finally {
		// 	setLoading(false);
		//   }
	};
	
	// Usage in useEffect
	useEffect(() => {
	  fetchAndSetOrgSamlDetails(
		userId,
		orgDomain,
		parseInt(!!orgId ? orgId.toString() : "-1"),
		setTitle,
		setTitleEnglish,
		updateBackButtonRoute,
		setLoading,
		setMenuSelection,
		setSamlconsumerendpoint,
		setSamlcertificatekey,
		setSamlproviderendpoint,
		setSamlCertificatekeyadmin,
		setSamlproviderendpointadmin,
		setEnablesso,
		setShowModal,
		setHasErrored,
		setSaveSuccess,
		setModalPopupMessage
	  );
	
	  // Cleanup function
	  return () => { };
	}, [superAdminSelectedBasicOrgData]);

	const handleEnableSsoChange = () => {
		setEnablesso(!enablesso);
	}
	
	const handleSave = () => {
		setIsSaving(true);
		SaveOrgSamlDetails(oidcUser.access_token, userId, parseInt(orgId), orgDomain, samlcertificatekey, samlproviderendpoint, samlcertificatekeyadmin, samlproviderendpointadmin, enablesso, 
							enableSsoEventsIntegration, ssoAppId,  (!!ssoProvider && !!ssoProvider.value) ? ssoProvider.value.toString().trim().toLowerCase() : "", ssoAppName, ssoApiToken, saltAPIAuthenticationSecret, ssoAdminEmail,
							ssoClientSecret, ssoTenantID,
							(superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.orgID : -1,
							(superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.domainName.toLowerCase().replace("altlogin-", "") : "",
							(superAdminSelectedBasicOrgData != null && !!superAdminSelectedBasicOrgData) ? superAdminSelectedBasicOrgData.siteName : "").then((res) => {
				setIsSaving(false);
				if (!!res && res.isSuccess) {
					if (!!res.orgSamlDetail) {
						setGrpSsoSubscriptionExpiryDate(res.orgSamlDetail.ssoSubscriptionExpiryForGroupEvents);
						setUserSsoSubscriptionExpiryDate(res.orgSamlDetail.ssoSubscriptionExpiryForUserEvents);
						setSsoIsGrpSubscriptionActive(!res.orgSamlDetail.ssoSubscriptionExpiredForGroupEvents);
						setSsoIsUserSubscriptionActive(!res.orgSamlDetail.ssoSubscriptionExpiredForUserEvents);
					}
					setShowModal(true);
					setHasErrored(false);
					setSaveSuccess(true);
					setModalPopupMessage("Single Sign On details have been saved successfully!");
				}
				else {
					setShowModal(true);
					setHasErrored(true);
					setSaveSuccess(false);
					setModalPopupMessage("Single Sign On details saving have been failed!");
				}
			})
	}
	
	const generateKey = () => {
		try {
			const newKey = uuidv4(); // Generate a new unique key
			setSaltAPIAuthenticationSecret("Bearer " + newKey);
			setShowModal(true);
			setHasErrored(false);
			setSaveSuccess(true);
			setModalPopupMessage("New key generated, please save to map this key to your organisation.");
		}
		catch (error) {
			console.error('Failed to generate new key: ', error);
			setShowModal(true);
			setHasErrored(true);
			setSaveSuccess(false);
			setModalPopupMessage('Failed to generate new key due to error as ' + error);
		}
    };

    const copyToClipboard = (text: string) => {
		if (!!text === false || text.trim() === "") {
			return;
		}
        navigator.clipboard.writeText(text).then(() => {
            console.log('Text copied to clipboard');
			setShowModal(true);
			setHasErrored(false);
			setSaveSuccess(true);
			setModalPopupMessage("Key copied to clipboard, please save to map this key to your organisation.");
        }).catch(err => {
            console.error('Failed to copy text: ', err);
			setShowModal(true);
			setHasErrored(true);
			setSaveSuccess(false);
			setModalPopupMessage('Failed to copy key due to error as ' + err);
        });
    };

	const handleDownloadSetupguides = (fileType: string) => {
		GetSAMLSetupGuide(oidcUser.access_token, userId, orgDomain, parseInt(orgId), fileType).then((Res) => {
			if (Res.isSuccess) {
				const fileData = Res.fileDataOutput.data;
				let a = window.document.createElement('a');
				a.href = window.URL.createObjectURL(
					new Blob([serialToArray(atob(fileData))], {
						type: ''
					})
				);

				a.download = Res.fileDataOutput.resultFileFullName;
				document.body.appendChild(a)
				a.click();
				document.body.removeChild(a)

			}
			else if (Res.error !== undefined && Res.error !== null) {
			}
			// Activity log
			addError(oidcUser.access_token, userId, orgDomain, "Completed Download Sample SAML setup Guide in NEWSALT ClientApp", null, "OrganisationManagement.tsx", 6, EnableActivityLogs, false,
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : -1), 
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""), 
				(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : ""));
		})
	}
	useEffect(() => {
		if (showModal && !hasErrored) {
			setTimeout(() => {
				setShowModal(false);
				setSaveSuccess(false);
			}, 3000)
		}
	}, [showModal])

	if (loading) return <LoaderContainer />;


	return (
		<>
			<Steps
				enabled={intro.stepsEnabled}
				steps={intro.steps}
				initialStep={intro.initialStep}
				onExit={onExit}
				options={{
					nextLabel: "Next",
					prevLabel: "Back",
					tooltipClass: "sample-class",
					showBullets: true,
				}}
			/>
			<CustomTooltip displayText={["Guide Me"]}>
				<span
					onClick={() => setIntro({ ...intro, stepsEnabled: true })}
					className={styles.guideMeButtonSpecific}
				>
					<GuideIcon />
				</span>
			</CustomTooltip>
			<UnbrandedCard>
				<div className={styles.mainWrapper} style={{marginTop: "0px"}}>
					<div className={styles.Wrapper}>
						<div className={`${styles.lableContainer} shibbolethguideme`} >
							<span className={styles.labelText}>{allResourcesToTranslations.ssosetupguides}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.ssosetupguidesnotes}</span>
						</div>
						<div className={styles.inputContainer}>
							{/*<FileDownloadIcon /> */}
							<CustomTooltip displayText={[allResourcesToTranslations.oktassosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('okta'); }}
									className="oktassosetupguides">
									<SSOOktaIcon></SSOOktaIcon>
								</Button>
							</CustomTooltip>
							<CustomTooltip displayText={[allResourcesToTranslations.azureadssosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('azuread'); }}
									className="azureadssosetupguides">
									<SSOAzureIcon></SSOAzureIcon>
								</Button>
							</CustomTooltip>
							<CustomTooltip displayText={[allResourcesToTranslations.adfsssosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('adfs'); }}
									className="adfsssosetupguides">
									<SSOMSIcon></SSOMSIcon>
								</Button>
							</CustomTooltip>							
							<CustomTooltip displayText={[allResourcesToTranslations.googlessosetupguides]}>
								<Button onClick={() => { handleDownloadSetupguides('google'); }}
									className="googlessosetupguides">
									<SSOGoogleIcon></SSOGoogleIcon>
								</Button>
							</CustomTooltip>
						</div>
						<div className="enablesso" style={{ width: '100%', paddingLeft: '0px' }} >
							<Checkbox
								style={{ paddingLeft: '0px' }}
								size="small"
								color="primary"
								checked={enablesso}
								className={styles.checkBox}
								onClick={() => handleEnableSsoChange()}
							/>{" "}
							<span className={styles.labelText}>{allResourcesToTranslations.enablesso}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.enablessonote}</span>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlconsumerendpoint}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlconsumerendpointnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="consumerEndPoint">
								<TextField
									className={styles.input}
									style={{ width: "100%", marginBottom: "15px" }}
									label={allResourcesToTranslations.url}
									name="consumerEndPointUrl"
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlconsumerendpoint}
									disabled
								/>
							</div>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlcertificatekey}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlcertificatekeynote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlcertificatekey" style={{ height: "130px" }}>
								<TextField
									className={styles.input}
									name="samlcertificatekey"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlcertificatekey}
									multiline={true}
									rows={5}
									onChange={(e) => setSamlcertificatekey(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlproviderendpoint}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlproviderendpointnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlproviderendpoint">
								<TextField
									className={styles.input}
									label={allResourcesToTranslations.url}
									name="samlproviderendpoint"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlproviderendpoint}
									onChange={(e) => setSamlproviderendpoint(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</UnbrandedCard>

			{/* <UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper}>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlcertificatekeyadmin}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlcertificatekeyadminnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlcertificatekeyadmin" style={{ height: "130px" }}>
								<TextField
									className={styles.input}
									name="samlcertificatekeyadmin"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlcertificatekeyadmin}
									multiline={true}
									rows={5}
									onChange={(e) => setSamlCertificatekeyadmin(e.target.value)}
								/>
							</div>
						</div>
						<div className={styles.lableContainer} >
							<span className={styles.labelText}>{allResourcesToTranslations.samlproviderendpointadmin}</span>
						</div>
						<div className={styles.inputContainer}>
							<span className={styles.labelTextitalic}>{allResourcesToTranslations.samlproviderendpointadminnote}</span>
						</div>
						<div className={styles.inputContainer}>
							<div className="samlproviderendpointadmin">
								<TextField
									className={styles.input}
									label={allResourcesToTranslations.url}
									name="samlproviderendpointadmin"
									style={{ width: "100%", marginBottom: "15px" }}
									variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
									value={samlproviderendpointadmin}
									onChange={(e) => setSamlproviderendpointadmin(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</UnbrandedCard> */}

			<UnbrandedCard>
				<div className={styles.mainWrapper}>
					<div className={styles.Wrapper} id="ssoaccountmanagement">
						<div className="enableSsoEventsIntegration" style={{ width: '100%', paddingLeft: '0px' }} >
							<Checkbox
								style={{ paddingLeft: '0px' }}
								size="small"
								color="primary"
								checked={enableSsoEventsIntegration}
								className={styles.checkBox}
								onClick={() => setEnableSsoEventsIntegration(!enableSsoEventsIntegration)}
								disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : true}
							/>{" "}
							<span className={styles.labelText}>SSO Account Management</span>
						</div>
						{enableSsoEventsIntegration && (
							<>
								<br/>
								<div className={styles.inputContainer}>
									<FormControl variant="outlined" className={styles.input} style={{ width: "100%", marginBottom: "15px" }}
										disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}>
										<InputLabel>SSO Provider</InputLabel>
										<Select
											value={(!!ssoProvider && !!ssoProvider.value) ? ssoProvider.value.toString().toLowerCase().trim() : ""}
											onChange={(e) => setSsoProvider({value: e.target.value})}
											label="SSO Provider"
											disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
										>
											<MenuItem value="okta">Okta</MenuItem>
											<MenuItem value="azure">Azure</MenuItem>
										</Select>
									</FormControl>
								</div>
								{!!ssoProvider && !!ssoProvider.value && ssoProvider.value.toString().trim().toLowerCase() === 'okta' && (
									<>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO App Name"
												name="ssoAppName"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoAppName}
												onChange={(e) => setSsoAppName(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO App ID"
												name="ssoAppId"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoAppId}
												onChange={(e) => setSsoAppId(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO API Token"
												name="ssoApiToken"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoApiToken}
												onChange={(e) => setSsoApiToken(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												type="password"
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										<div className={styles.inputContainer2}>
											
											<Button
												variant="contained"
												color="primary"
												onClick={generateKey}
												style={{ marginLeft: "-1px", marginRight: "15px", height: "40px" }} // Adjust height to match the TextField
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() === superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											>
												{allResourcesToTranslations.generatebuttonlabel}
											</Button>
											<TextField
												className={styles.input2}
												label="Salt API Authentication Secret"
												name="saltAPIAuthenticationSecret"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={saltAPIAuthenticationSecret}
												onChange={(e) => setSaltAPIAuthenticationSecret(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												type="password"
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															{/* <IconButton
																onClick={() => copyToClipboard(saltAPIAuthenticationSecret)}
																disabled={(!!orgDomain && orgDomain.trim().toLowerCase() === superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
															>
																{allResourcesToTranslations.copybuttonlabel}
															</IconButton> */}
															<Button
																variant="contained"
																color="primary"
																onClick={() => copyToClipboard(saltAPIAuthenticationSecret)}
																style={{ height: "40px" }} // Adjust height to match the TextField
																disabled={(!!orgDomain && orgDomain.trim().toLowerCase() === superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
															>
																{allResourcesToTranslations.copybuttonlabel}
															</Button>
														</InputAdornment>
													),
												}}
											/>
											
										</div>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO Admin Email"
												name="ssoAdminEmail"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoAdminEmail}
												onChange={(e) => setSsoAdminEmail(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												error={!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(ssoAdminEmail)}
												helperText={!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(ssoAdminEmail) ? "Invalid email format" : ""}
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
									</>
								)}
								{!!ssoProvider && !!ssoProvider.value && ssoProvider.value.toString().trim().toLowerCase() === 'azure' && (
									<>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO App Name"
												name="ssoAppName"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoAppName}
												onChange={(e) => setSsoAppName(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO App/Client ID"
												name="ssoAppId"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoAppId}
												onChange={(e) => setSsoAppId(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO Client Secret"
												name="ssoClientSecret"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoClientSecret}
												onChange={(e) => setSsoClientSecret(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												type="password"
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO Tenant ID"
												name="ssoTenantId"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoTenantID}
												onChange={(e) => setSsoTenantID(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										<div className={styles.inputContainer}>
											<TextField
												className={styles.input}
												label="SSO Admin Email"
												name="ssoAdminEmail"
												variant="outlined"
												autoComplete="off"
												InputLabelProps={{ shrink: true }}
												value={ssoAdminEmail}
												onChange={(e) => setSsoAdminEmail(e.target.value)}
												style={{ width: "100%", marginBottom: "15px" }}
												error={!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(ssoAdminEmail)}
												helperText={!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(ssoAdminEmail) ? "Invalid email format" : ""}
												disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
											/>
										</div>
										{
										(!!ssoIsGrpSubscriptionActive && !!ssoGrpSubscriptionExpiryDate && !!ssoIsUserSubscriptionActive && !!ssoUserSubscriptionExpiryDate) ?
										<div>
											<div id="attentionInfoSuccess" className={styles.icon_and_text_container_leftshift}>
												<div className={styles.icon_and_text_container_icon}>
												<SuccessIcon></SuccessIcon>
												</div>
												<div className={styles.icon_and_text_container_text}>
												<div>
													<span style={{fontWeight: "normal", color: "green", fontSize:"12px", fontStyle: "normal", marginTop:"20px"}}>
														{allResourcesToTranslations.azureeventsubscriptionactivated}</span>
												</div>
												</div>
											</div>
											<div className={styles.inputContainer}>
												<TextField
													className={styles.input}
													label="SSO Group Change Subscription Expiry Date"
													name="ssoSubscriptionGrpExpiryDate"
													variant="outlined"
													autoComplete="off"
													InputLabelProps={{ shrink: true }}
													value={ssoGrpSubscriptionExpiryDate}
													onChange={(e) => setGrpSsoSubscriptionExpiryDate(e.target.value)}
													style={{ width: "100%", marginBottom: "15px" }}
													disabled={true}
													//disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
												/>
											</div>
											<div className={styles.inputContainer}>
												<TextField
													className={styles.input}
													label="SSO User Change Subscription Expiry Date"
													name="ssoSubscriptionUsrExpiryDate"
													variant="outlined"
													autoComplete="off"
													InputLabelProps={{ shrink: true }}
													value={ssoUserSubscriptionExpiryDate}
													onChange={(e) => setUserSsoSubscriptionExpiryDate(e.target.value)}
													style={{ width: "100%", marginBottom: "15px" }}
													disabled={true}
													//disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
												/>
											</div>
										</div>
										:
										<div>
											<div id="attentionInfoError" className={styles.icon_and_text_container_leftshift}>
												<div className={styles.icon_and_text_container_icon}>
												<ErrorIcon></ErrorIcon>
												</div>
												<div className={styles.icon_and_text_container_text}>
												<div>
													<span style={{fontWeight: "normal", color: "red", fontSize:"12px", fontStyle: "normal", marginTop:"20px"}}>
														{allResourcesToTranslations.azureeventsubscriptionnotactivated}</span>
												</div>
												</div>
											</div>
											<div className={styles.inputContainer}>
												<TextField
													className={styles.input}
													label="SSO Group Change Subscription Expiry Date"
													name="ssoSubscriptionGrpExpiryDate"
													variant="outlined"
													autoComplete="off"
													InputLabelProps={{ shrink: true }}
													value={ssoGrpSubscriptionExpiryDate}
													onChange={(e) => setGrpSsoSubscriptionExpiryDate(e.target.value)}
													style={{ width: "100%", marginBottom: "15px" }}
													disabled={true}
													InputProps={{
														readOnly: true,
													}}
													defaultValue={""}
													//disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
												/>
											</div>
											<div className={styles.inputContainer}>
												<TextField
													className={styles.input}
													label="SSO User Change Subscription Expiry Date"
													name="ssoSubscriptionUsrExpiryDate"
													variant="outlined"
													autoComplete="off"
													InputLabelProps={{ shrink: true }}
													value={ssoUserSubscriptionExpiryDate}
													onChange={(e) => setUserSsoSubscriptionExpiryDate(e.target.value)}
													style={{ width: "100%", marginBottom: "15px" }}
													disabled={true}
													InputProps={{
														readOnly: true,
													}}
													defaultValue={""}
													//disabled={(!!orgDomain && orgDomain.trim().toLowerCase() == superAdminDomain) ? false : (enableSsoEventsIntegration ? false : true)}
												/>
											</div>
										</div>
										}
									</>
								)}
							</>
						)}
					</div>
				</div>
			</UnbrandedCard>
			<div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", width: "100%", paddingTop: "10px" }}>
				<CustomTooltip displayText={[allResourcesToTranslations.savessodetails]}>
					<span className="saveData" style={{ marginRight: "-5px" }}>
						<Button
							disabled={!!ssoProvider && !!ssoProvider.value && 
										((ssoProvider.value.toString().trim().toLowerCase() === 'okta' && 
											(samlcertificatekey.trim() === "" || samlproviderendpoint.trim() === "" || 
											(samlcertificatekeyadmin.trim() !== "" && samlproviderendpointadmin.trim() === ""))) || 
										 (ssoProvider.value.toString().trim().toLowerCase() === 'azure' && 
											(samlcertificatekey.trim() === "" || samlproviderendpoint.trim() === "" || 
											 (!!ssoTenantID === false || ssoTenantID.trim() === "") || (!!ssoClientSecret === false || ssoClientSecret.trim() === "") || 
											(samlcertificatekeyadmin.trim() !== "" && samlproviderendpointadmin.trim() === ""))))}
							onClick={handleSave}
							variant="contained"
							color="primary"
							className="save"
						>
							<SaveIcon />
						</Button>
					</span>
				</CustomTooltip>
				<div className={styles.loaderContainer}>
					<CircularProgress
						style={{ width: isSaving ? "20px" : "0px", padding: "15px 10px" }}
						color="primary"
						size="10"
					/>
				</div>
			</div>
			{showModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}

		</>
	);
};

export default SingleSignOn;
