//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { CPDReportResultItem, CPDReportResultMetaData } from '../../../globalTypes';
import { useHistory } from 'react-router-dom';

// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";

//Context
import { LoaderContext } from '../../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';

//Components
import BrandedCard from '../../../Components/BrandedCard';
import LoaderContainer from '../../../Components/LoaderContainer';
import CustomTooltip from '../../../Components/CustomTooltip'
import GuideIcon from '../../../CustomIcons/GuideIcon';

//General Functions
import getColor from '../../../functions/getColor';
import serialToArray from '../../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../../functions/getCurrentTimeDDMMYYYYFormat'

//API functions
import { getCurrentTimeOfOrg } from "../../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from '../../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../../API/GetExportedPDFData'
import { addError } from '../../../API/AddError'

//Components
import ExcelFileIcon from '../../../CustomIcons/ExcelFileIcon'
import CSVFileIcon from '../../../CustomIcons/CSVFileIcon'
import PDFIcon from '../../../CustomIcons/PDFFileIcon';
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';

//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import ViewListIcon from '@material-ui/icons/ViewList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Style
import styles from '../../../styles/cpdReport.module.css'

interface CurrentCPDReportListProps {
    cpdReportResultItems: Array<CPDReportResultItem>;
    cpdReportResultMetaData: CPDReportResultMetaData | null;
    givenFirstName: string;
    givenLastName: string;
    givenUserName: string;
    givenEmail: string;
    resultError: string;
    onlyShortFallUsers: boolean;
    selectedProfileName: string;
    selectedProfilePeriodName: string;
    includeCourseModules: boolean;
    includePolicies: boolean;
    includeExternalCertificates: boolean;
    selectedUnitNames: string;
  }

//const CPDReportSection = (props: any) => {
const CPDReportSection = ({ cpdReportResultItems, cpdReportResultMetaData, givenFirstName, givenLastName, 
                            givenUserName, givenEmail, 
                            resultError, onlyShortFallUsers, selectedProfileName, 
                            selectedProfilePeriodName, includeCourseModules, includePolicies, includeExternalCertificates,
                            selectedUnitNames
                         }: CurrentCPDReportListProps) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const {
      profile: { user_id, family_name, given_name, website },
    } = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website
      ? website
          .toLowerCase()
          .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
      : "";
    const {
      theme: { color },
    } = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    //const [cpdReportResultMetaData, setCPDReportResultMetaData] = useState<CPDReportResultMetaData | null>(props?.location?.state?.resultMetaData ?? null);
    const [cpdReportResult, setCPDReportResult] = useState<Array<CPDReportResultItem>>(cpdReportResultItems ?? []);
    const [exporting, setExporting] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
    const [currentSortBy, setCurrentSortBy] = useState<string>("");
    const [openCriteria, setOpenCriteria] = useState(false);
    const [sortInProgress, setSortInProgress] = useState<boolean>(false);
    const [courseNameDescending, setCourseNameDescending] = useState<boolean>(false);
    const [moduleNameDescending, setModuleNameDescending] = useState<boolean>(false);
    const [firstNameDescending, setFirstNameDescending] = useState<boolean>(false);
    const [lastNameDescending, setLastNameDescending] = useState<boolean>(false);
    const [userNameDescending, setUserNameDescending] = useState<boolean>(false);
    const [userEmailDescending, setUserEmailDescending] = useState<boolean>(false);
    const [unitDescending, setUnitDescending] = useState<boolean>(false);
    const [earnedPointsDescending, setEarnedPointsDescending] = useState<boolean>(false);
    const [requiredPointsDescending, setRequiredPointsDescending] = useState<boolean>(false);
    const [dateAssignedDescending, setDateAssignedDescending] = useState<boolean>(false);
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const [exportError, setExportError] = useState<string>("");
    // const [resultError, setResultError] = useState<string>(
    //   props?.location?.state?.resultError ?? ""
    // );
    // const [includeCourseModules, setIncludeCourseModules] = useState<boolean>(
    //   props?.location?.state?.includeCourseModules ?? false
    // );
    // const [includePolicies, setIncludePolicies] = useState<boolean>(
    //   props?.location?.state?.includePolicies ?? false
    // );
    // const [includeExternalCertificates, setIncludeExternalCertificates] = useState<boolean>(
    //   props?.location?.state?.includeExternalCertificates ?? false
    // );
    // const [onlyShortFallUsers, setOnlyShortFallUsers] = useState<boolean>(
    //   props?.location?.state?.shortfallUsers ?? false
    // );
    // const [givenFirstName, setGivenFirstName] = useState<string>(
    //   props?.location?.state?.givenFirstName ?? []
    // );
    // const [givenLastName, setGivenLastName] = useState<string>(
    //   props?.location?.state?.givenLastName ?? []
    // );
    // const [givenUserName, setGivenUserName] = useState<string>(
    //   props?.location?.state?.givenUserName ?? []
    // );
    // const [selectedProfileName, setSelectedProfileName] = useState<string>(
    //   props?.location?.state?.selectedProfileName ?? []
    // );
    // const [selectedProfilePeriodName, setSelectedProfilePeriodName] = useState<string>(
    //   props?.location?.state?.selectedProfilePeriodName ?? []
    // );
    // const [selectedUnitNames, setSelectedUnitNames] = useState<string>(
    //   props?.location?.state?.selectedUnits ?? []
    // );
    const [intro, setIntro] = useState({
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: ".cpdReportList",
          title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta        
        },      
      ]
    })
    
    // const [intro, setIntro] = useState({
    //     stepsEnabled: false,
    //     initialStep: 0,
    //     steps: [
    //       {
    //         element: ".cpdReportList",
    //         title: "Report Data", intro: "All columns are sortable by clicking on the column title or relevant arrows."        
    //       },      
    //     ]
    // })

    // useEffect(() => 
    // {
    //   let updateSteps = [...intro.steps]
    //   updateSteps.push( 
    //       {
    //       element: ".xlxs",
    //       title: "Export", intro:"Click to export an XLSX file containing all Current Admin Report results."
    //       },
    //       {
    //       element: ".csv",
    //       title: "Export", intro:"Click to export a CSV file containing all Current Admin Report results."
    //       },
    //       {
    //           element: ".pdf",
    //           title: "Export", intro:"Click to export a PDF file containing all Current Admin Report results."
    //       }      
    //   )    
    //   setIntro({...intro, steps: [...updateSteps]})              

    // },[menuSelection])
      
    // const onExit = () => {
    //     setIntro({...intro, stepsEnabled: false });
    // };

    // useEffect(() => {
    //     setAdminReportResult(cpdReportData);
    //   }, [cpdReportData]);


    const toggleBanding = () => setEnableRowBanding(!enableRowBanding)
    const rowBackgroundColor = (index: number) => {
      if (enableRowBanding) {
        return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
      } else {
        return {};
      }
    };
  
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    
    const GetDate_yymmdd = () => {
      var d =  new Date(Date.now());
      let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
      return day;   
    }
  
    const buildRequestStringArray = () => {
      let resultArray: Array<string> = [];
      for (let i = 0; i < cpdReportResult.length; i++) {
          const r = cpdReportResult[i];
          resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.userName}<#>${r.userEmail}<#>${r.categoryOrCourseName}<#>${r.moduleOrPolicyName}<#>${r.earnedPoints.toString()}<#>${r.totalRequiredPoints.toString()}<#>${r.dateAssignedTxt}`);
      }
      return resultArray;
    };

    useEffect(() => {
        setCPDReportResult(cpdReportResult);
      }, [cpdReportResult]);
  
    const handleXLSXExport = () => {
      setMessage("Exporting XLSX");
      setExportError("");
      // Activity log
      addError(
        oidcUser.access_token,
        userId,
        orgDomain,
        "Started CPD Report XLSX-EXPORT in NEWSALT ClientApp",
        null,
        "CPDReportResult.tsx",
        6
      );
      setExporting(true);
      getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 10, "CPDReport", enableRowBanding, "","","","",
                        onlyShortFallUsers,false, "", givenFirstName, givenLastName, givenUserName, "", "", "", false, onlyShortFallUsers, onlyShortFallUsers ? 1 : 0, 
                        selectedProfileName, selectedProfilePeriodName, -1, -1, "", "", "", [], 0, false, false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isCourseModulePresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isPolicyPresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isExtCertPresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isEventPresent : false).then((xlxsRes) => {
          if (xlxsRes.isSuccess) {
              const fileData = xlxsRes.xlsxDataOutput.fileData
              var a = window.document.createElement('a');
              a.href = window.URL.createObjectURL(
                  new Blob([serialToArray(atob(fileData))], {
                      type: ''
                  })
              );
  
              a.download = "CPDReport-"+  GetDate_yymmdd() + ".xlsx" ;
              document.body.appendChild(a)
              a.click();
              document.body.removeChild(a)
  
          }
          else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
              setExportError(xlxsRes.error);
          }
          setExporting(false);
          // Activity log
          addError(oidcUser.access_token, userId, orgDomain, "Completed CPD Report XLSX-EXPORT in NEWSALT ClientApp", null, "CPDReportResult.tsx", 6, EnableActivityLogs);
      })
    };
  
    const handleCSVExport = () => {
      setMessage("Exporting CSV");
      setExportError("");
      // Activity log
      addError(
        oidcUser.access_token,
        userId,
        orgDomain,
        "Started CPD Report CSV-EXPORT in NEWSALT ClientApp",
        null,
        "CPDReportResult.tsx",
        6, EnableActivityLogs
      );
      setExporting(true);
      getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 10, "CPDReport", "","","","",
                        onlyShortFallUsers,false, "",false, givenFirstName, givenLastName, givenUserName, "", "", "", false, onlyShortFallUsers, onlyShortFallUsers ? 1 : 0, 
                        selectedProfileName, selectedProfilePeriodName, -1, -1, "", "", "", [], 0, false, false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isCourseModulePresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isPolicyPresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isExtCertPresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isEventPresent : false).then((csvRes) => {
          if (csvRes.isSuccess) {
              const fileData = csvRes.csvDataOutput.fileData
              var a = window.document.createElement('a');
  
              a.href = window.URL.createObjectURL(
                  new Blob([serialToArray(atob(fileData))], {
                      type: ''
                  })
              )
              a.download = "CPDReport-"+ GetDate_yymmdd() + ".csv" ;
              document.body.appendChild(a)
              a.click();
              document.body.removeChild(a)
  
          }
          else if (csvRes.error !== undefined && csvRes.error !== null) {
              setExportError(csvRes.error);
          }
          setExporting(false)
          // Activity log
          addError(oidcUser.access_token, userId, orgDomain, "Completed CPD Report CSV-EXPORT in NEWSALT ClientApp", null, "CPDReportResult.tsx", 6, EnableActivityLogs);
      })
    };
  
    const handlePDFXExport = () => {
      setMessage("Exporting PDF");
      setExportError("");
      // Activity log
      addError(
        oidcUser.access_token,
        userId,
        orgDomain,
        "Started CPD Report PDF-EXPORT in NEWSALT ClientApp",
        null,
        "CPDReportResult.tsx",
        6, EnableActivityLogs
      );
      setExporting(true);
      getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), 10, "CPDReport", enableRowBanding,"","","","",
                        onlyShortFallUsers,false, "", givenFirstName, givenLastName, givenUserName, "", "", "", false, onlyShortFallUsers, onlyShortFallUsers ? 1 : 0, 
                        selectedProfileName, selectedProfilePeriodName, -1, -1, "", "", "", [], 0, false, false,  
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isCourseModulePresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isPolicyPresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isExtCertPresent : false, 
                        !!cpdReportResultMetaData ? cpdReportResultMetaData.isEventPresent : false).then((pdfRes) => {
          if (pdfRes.isSuccess) {
              const fileData = pdfRes.pdfDataOutput.fileData
              var a = window.document.createElement('a');
              a.href = window.URL.createObjectURL(
                  new Blob([serialToArray(atob(fileData))], {
                      type: ''
                  })
              )
              a.download = "CPDReport-"+ GetDate_yymmdd() + ".PDF" ;
              document.body.appendChild(a)
              a.click();
              document.body.removeChild(a)
          }
          else if (pdfRes.error !== undefined && pdfRes.error !== null) {
              setExportError(pdfRes.error);
          }
          setExporting(false)
          // Activity log
          addError(oidcUser.access_token, userId, orgDomain, "Completed CPD Report PDF-EXPORT in NEWSALT ClientApp", null, "CPDReportResult.tsx", 6, EnableActivityLogs);
      })
    };
  
    const sortResultsByAsync = async (
      sortBy:
        | "CourseName"
        | "ModuleName"
        | "FirstName"
        | "LastName"
        | "UserName"
        | "UserEmail"
        | "UnitPathway"
        | "EarnedPoints"
        | "RequiredPoints"
        | "DateAssigned",
      sortGivenItemInDesc: boolean
    ) => {
      if (cpdReportResult == null || cpdReportResult.length <= 0) {
        return cpdReportResult;
      }
      var result = cpdReportResult.slice(0);
      setCurrentSortBy(sortBy);
      switch (sortBy) {
        case "UnitPathway":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.unitPathway === null) {
                return -1;
              }
              if (a === null || a.unitPathway === null) {
                return 1;
              }
              if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
                return -1;
              }
              if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUnitDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.unitPathway === null) {
                return 1;
              }
              if (a === null || a.unitPathway === null) {
                return -1;
              }
              if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) {
                return 1;
              }
              if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) {
                return -1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUnitDescending(true);
            }
          }
          setCPDReportResult(result);
          break;
        case "CourseName":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.categoryOrCourseName === null) {
                return -1;
              }
              if (a === null || a.categoryOrCourseName === null) {
                return 1;
              }
              if (a.categoryOrCourseName.toLowerCase() > b.categoryOrCourseName.toLowerCase()) {
                return -1;
              }
              if (a.categoryOrCourseName.toLowerCase() < b.categoryOrCourseName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setCourseNameDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.categoryOrCourseName === null) {
                return 1;
              }
              if (a === null || a.categoryOrCourseName === null) {
                return -1;
              }
              if (a.categoryOrCourseName.toLowerCase() < b.categoryOrCourseName.toLowerCase()) {
                return -1;
              }
              if (a.categoryOrCourseName.toLowerCase() > b.categoryOrCourseName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setCourseNameDescending(true);
            }
          }
          setCPDReportResult(result);
          break;
        case "ModuleName":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.moduleOrPolicyName === null) {
                return -1;
              }
              if (a === null || a.moduleOrPolicyName === null) {
                return 1;
              }
              if (a.moduleOrPolicyName.toLowerCase() > b.moduleOrPolicyName.toLowerCase()) {
                return -1;
              }
              if (a.moduleOrPolicyName.toLowerCase() < b.moduleOrPolicyName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setModuleNameDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.moduleOrPolicyName === null) {
                return 1;
              }
              if (a === null || a.moduleOrPolicyName === null) {
                return -1;
              }
              if (a.moduleOrPolicyName.toLowerCase() < b.moduleOrPolicyName.toLowerCase()) {
                return -1;
              }
              if (a.moduleOrPolicyName.toLowerCase() > b.moduleOrPolicyName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setModuleNameDescending(true);
            }
          }
          setCPDReportResult(result);
          break;
          case "LastName":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.lastName === null) {
                  return -1;
                }
                if (a === null || a.lastName === null) {
                  return 1;
                }
                if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                  return -1;
                }
                if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setLastNameDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.lastName === null) {
                  return 1;
                }
                if (a === null || a.lastName === null) {
                  return -1;
                }
                if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
                  return -1;
                }
                if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setLastNameDescending(true);
              }
            }
            setCPDReportResult(result);
            break;
          case "FirstName":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.firstName === null) {
                  return -1;
                }
                if (a === null || a.firstName === null) {
                  return 1;
                }
                if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                  return -1;
                }
                if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setFirstNameDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.firstName === null) {
                  return 1;
                }
                if (a === null || a.firstName === null) {
                  return -1;
                }
                if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
                  return -1;
                }
                if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setFirstNameDescending(true);
              }
            }
            setCPDReportResult(result);
            break;
            case "UserName":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.userName === null) {
                    return -1;
                  }
                  if (a === null || a.userName === null) {
                    return 1;
                  }
                  if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
                    return -1;
                  }
                  if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUserNameDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.userName === null) {
                    return 1;
                  }
                  if (a === null || a.userName === null) {
                    return -1;
                  }
                  if (a.userName.toLowerCase() < b.userName.toLowerCase()) {
                    return -1;
                  }
                  if (a.userName.toLowerCase() > b.userName.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUserNameDescending(true);
                }
              }
              setCPDReportResult(result);
              break;
            case "UserEmail":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.userEmail === null) {
                    return -1;
                  }
                  if (a === null || a.userEmail === null) {
                    return 1;
                  }
                  if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) {
                    return -1;
                  }
                  if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUserEmailDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.userEmail === null) {
                    return 1;
                  }
                  if (a === null || a.userEmail === null) {
                    return -1;
                  }
                  if (a.userEmail.toLowerCase() < b.userEmail.toLowerCase()) {
                    return -1;
                  }
                  if (a.userEmail.toLowerCase() > b.userEmail.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUserEmailDescending(true);
                }
              }
              setCPDReportResult(result);
              break;
          case "EarnedPoints":
              if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                  if (b === null || b.earnedPoints === null) {
                    return -1;
                  }
                  if (a === null || a.earnedPoints === null) {
                    return 1;
                  }
                  if (a.earnedPoints > b.earnedPoints) {
                    return -1;
                  }
                  if (a.earnedPoints < b.earnedPoints) {
                    return 1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUnitDescending(false);
                }
              } else {
                result.sort((a, b) => {
                  if (b === null || b.earnedPoints === null) {
                    return 1;
                  }
                  if (a === null || a.earnedPoints === null) {
                    return -1;
                  }
                  if (a.earnedPoints > b.earnedPoints) {
                    return 1;
                  }
                  if (a.earnedPoints < b.earnedPoints) {
                    return -1;
                  }
                  return 0;
                });
                if (reverseNextSort) {
                  setUnitDescending(true);
                }
              }
              setCPDReportResult(result);
              break;
            case "RequiredPoints":
                  if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                      if (b === null || b.totalRequiredPoints === null) {
                        return -1;
                      }
                      if (a === null || a.totalRequiredPoints === null) {
                        return 1;
                      }
                      if (a.totalRequiredPoints > b.totalRequiredPoints) {
                        return -1;
                      }
                      if (a.totalRequiredPoints < b.totalRequiredPoints) {
                        return 1;
                      }
                      return 0;
                    });
                    if (reverseNextSort) {
                      setUnitDescending(false);
                    }
                  } else {
                    result.sort((a, b) => {
                      if (b === null || b.totalRequiredPoints === null) {
                        return 1;
                      }
                      if (a === null || a.totalRequiredPoints === null) {
                        return -1;
                      }
                      if (a.totalRequiredPoints > b.totalRequiredPoints) {
                        return 1;
                      }
                      if (a.totalRequiredPoints < b.totalRequiredPoints) {
                        return -1;
                      }
                      return 0;
                    });
                    if (reverseNextSort) {
                      setUnitDescending(true);
                    }
                  }
                  setCPDReportResult(result);
                  break;
  
          case "DateAssigned":
            if (sortGivenItemInDesc) {
                result.sort((a, b) => {
                    if (b === null || b.dateAssignedTxt === null || b.dateAssignedTxt.trim() === "") { return -1; }
                    if (a === null || a.dateAssignedTxt === null || a.dateAssignedTxt.trim() === "") { return 1; }
                    var dta = a.dateAssigned; // new Date(a.dateAssigned.split("/").reverse().join("-"))
                    var dtb = b.dateAssigned; // new Date(b.dateAssigned.split("/").reverse().join("-"))
                    if(dta > dtb) { return -1; }
                    if(dta < dtb) { return 1; }
                    return 0;
                })
                if (reverseNextSort) {
                    setDateAssignedDescending(false)
                }
            } else {
                result.sort((a, b) => {
                    if (b === null || b.dateAssignedTxt === null || b.dateAssignedTxt.trim() === "") { return 1; }
                    if (a === null || a.dateAssignedTxt === null || a.dateAssignedTxt.trim() === "") { return -1; }
                    var dta = a.dateAssigned; // new Date(a.dateAssigned.split("/").reverse().join("-"))
                    var dtb = b.dateAssigned; // new Date(b.dateAssigned.split("/").reverse().join("-"))
                    if(dta > dtb) { return 1; }
                    if(dta < dtb) { return -1; }
                    return 0;
                })
                if (reverseNextSort) {
                    setDateAssignedDescending(true)
                }
            }
            setCPDReportResult(result);
            break;
        default:
          break;
      }
      return result.slice(0);
    };
  
    const sortResultsBy = async (
      sortBy:
        | "CourseName"
        | "ModuleName"
        | "FirstName"
        | "LastName"
        | "UserName"
        | "UserEmail"
        | "UnitPathway"
        | "EarnedPoints"
        | "RequiredPoints"
        | "DateAssigned",
      sortGivenItemInDesc: boolean
    ) => {
      setSortInProgress(true);
      setExportError("");
      await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
        setCurrentSortBy(sortBy);
        setCPDReportResult(result);
        setPage(0);
        setSortInProgress(false);
      });
    };
  
    const clickUnitAsc = () => {
      setReverseNextSort(false);
      setUnitDescending(false);
      sortResultsBy("UnitPathway", false);
    };
  
    const clickUnitDesc = () => {
      setReverseNextSort(false);
      setUnitDescending(true);
      sortResultsBy("UnitPathway", true);
    };
  
    const clickFirstNameAsc = () => {
      setReverseNextSort(false);
      setFirstNameDescending(false);
      sortResultsBy("FirstName", false);
    };
  
    const clickFirstNameDesc = () => {
      setReverseNextSort(false);
      setFirstNameDescending(true);
      sortResultsBy("FirstName", true);
    };
  
    const clickLastNameAsc = () => {
      setReverseNextSort(false);
      setLastNameDescending(false);
      sortResultsBy("LastName", false);
    };
  
    const clickLastNameDesc = () => {
      setReverseNextSort(false);
      setLastNameDescending(true);
      sortResultsBy("LastName", true);
    };
  
    const clickUserNameAsc = () => {
      setReverseNextSort(false);
      setUserNameDescending(false);
      sortResultsBy("UserName", false);
    };
  
    const clickUserNameDesc = () => {
      setReverseNextSort(false);
      setUserNameDescending(true);
      sortResultsBy("UserName", true);
    };
  
    const clickUserEmailAsc = () => {
      setReverseNextSort(false);
      setUserEmailDescending(false);
      sortResultsBy("UserEmail", false);
    };
  
    const clickUserEmailDesc = () => {
      setReverseNextSort(false);
      setUserEmailDescending(true);
      sortResultsBy("UserEmail", true);
    };
  
    const clickCourseNameAsc = () => {
      setReverseNextSort(false);
      setCourseNameDescending(false);
      sortResultsBy("CourseName", false);
    };
  
    const clickCourseNameDesc = () => {
      setReverseNextSort(false);
      setCourseNameDescending(true);
      sortResultsBy("CourseName", true);
    };
  
    const clickModuleNameAsc = () => {
      setReverseNextSort(false);
      setModuleNameDescending(false);
      sortResultsBy("ModuleName", false);
    };
  
    const clickModuleNameDesc = () => {
      setReverseNextSort(false);
      setModuleNameDescending(true);
      sortResultsBy("ModuleName", true);
    };
  
    const clickEarnedPointsAsc = () => {
      setReverseNextSort(false);
      setEarnedPointsDescending(false);
      sortResultsBy("EarnedPoints", false);
    };
  
    const clickEarnedPointsDesc = () => {
      setReverseNextSort(false);
      setEarnedPointsDescending(true);
      sortResultsBy("EarnedPoints", true);
    };
  
    const clickRequiredPointsAsc = () => {
      setReverseNextSort(false);
      setRequiredPointsDescending(false);
      sortResultsBy("RequiredPoints", false);
    };
  
    const clickRequiredPointsDesc = () => {
      setReverseNextSort(false);
      setRequiredPointsDescending(true);
      sortResultsBy("RequiredPoints", true);
    };
  
    const clickDateAssignedAsc = () => {
      setReverseNextSort(false);
      setDateAssignedDescending(false);
      sortResultsBy("DateAssigned", false);
    };
  
    const clickDateAssignedDesc = () => {
      setReverseNextSort(false);
      setDateAssignedDescending(true);
      sortResultsBy("DateAssigned", true);
    };
  
    const initialSort = () => {
      setCurrentSortBy("UnitPathway");
      setUnitDescending(false);
      setModuleNameDescending(false);
      setCourseNameDescending(false);
      setFirstNameDescending(false);
      setLastNameDescending(false);
      setUserNameDescending(false);
      setUserEmailDescending(false);
      setEarnedPointsDescending(false);
      setRequiredPointsDescending(false);
      setDateAssignedDescending(false);
      getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
        if (res.isSuccess) {
          let curdt = res.currentDateTimeText;
          setReportRunTimeTaken(curdt);
        } else {
          let curdt = getCurrentTimeDDMMYYYYFormat();
          setReportRunTimeTaken(curdt);
        }
       }).catch ((error) => {
          let curdt = getCurrentTimeDDMMYYYYFormat();
          setReportRunTimeTaken(curdt);
       });
      setPage(0);
    };
  
    const getDynamicModuleTitle = (cpdReportResultMetaData: CPDReportResultMetaData | null) => {
      if (!!cpdReportResultMetaData) {
        let dynTitle = "";
        if (cpdReportResultMetaData.isCourseModulePresent) {
          dynTitle = "Module";
        }
        if (cpdReportResultMetaData.isPolicyPresent) {
          dynTitle = dynTitle == "" ? "Policy" : dynTitle + " / Policy";
        }
        if (cpdReportResultMetaData.isExtCertPresent) {
          dynTitle = dynTitle == "" ? "External Certificate" : dynTitle + " / External Certificate";
        }
        if (dynTitle == "") { 
          dynTitle = "Module / Policy / External Certificate"; // Default
        }
        return dynTitle;
      }
      return "Module / Policy / External Certificate"; // Default
    }
  
    const getDynamicCourseTitle = (cpdReportResultMetaData: CPDReportResultMetaData | null) => {
      if (!!cpdReportResultMetaData) {
        let dynTitle = "";
        if (cpdReportResultMetaData.isCourseModulePresent) {
          dynTitle = "Course";
        }
        if (cpdReportResultMetaData.isPolicyPresent) {
          dynTitle = dynTitle == "" ? "Policy" : dynTitle + " / Policy";
        }
        if (cpdReportResultMetaData.isExtCertPresent) {
          dynTitle = dynTitle == "" ? "External Certificate" : dynTitle + " / External Certificate";
        }
        if (dynTitle == "") { 
          dynTitle = "Course / Policy / External Certificate"; // Default
        }
        return dynTitle;
      }
      return "Course / Policy / External Certificate"; // Default
    }
     
    useEffect(() => {
        initialSort();
        // Activity log
        addError(
          oidcUser.access_token,
          userId,
          orgDomain,
          "Completed cpd Report in NEWSALT ClientApp",
          null,
          "CPDReportResult.tsx",
          6, EnableActivityLogs
        );
      }, []);
    
        if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0)
        {
            return (<BrandedCard>
                <div className={styles.noResultsCard}>
                    <div style={{color:"red"}}>Error: {resultError}</div>
                    <br/>
                </div>
            </BrandedCard>)
        }
        else if (cpdReportResult === null || cpdReportResult.length < 1) {
            return (<BrandedCard>
                <div className={styles.noResultsCard}>
                    <div>No Results to Display</div>
                    </div>
                    </BrandedCard>)
        } 
        else {
        }
    
      if (exporting) return <LoaderContainer />;
    
      return (
        <>
            <div className={styles.reportPageTitleSection}>                
                <div className={styles.exportBtnContainer}>                       
                    <div className={styles.reportExportContainer}>
                                <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                        <Button  className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCPDReportSectionExportXLSX">
                                            <ExcelFileIcon/>
                                        </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                    <Button   className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCPDReportSectionExportCSV">
                                        <CSVFileIcon/>
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                    <Button  className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCPDReportSectionExportPDF">
                                        <PDFIcon/>
                                    </Button>
                                </CustomTooltip>
                                    {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading" color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/>
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                    
                </div>
                
            </div>
            <div
                className={styles.paginationControlsContainer}
                style={{ justifyContent: "center" }}
            >
                <TablePagination
                rowsPerPageOptions={[5, 10, 20, 100]}
                component="div"
                count={cpdReportResult.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
          <div className={styles.tableSection}>
            <div className={styles.tableContainer}>
              <BrandedCard>
                <TableContainer>
                  <Table>
                    <TableHead className="cpdReportList">
                      <TableRow>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                            borderRadius: "8px 0 0 0",
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setUnitDescending(!unitDescending);
                                sortResultsBy("UnitPathway", !unitDescending);
                              }}
                            >
                              {"Unit Pathway" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitPathway" &&
                                    unitDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UnitPathway" &&
                                    unitDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUnitDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setLastNameDescending(!lastNameDescending);
                                sortResultsBy("LastName", !lastNameDescending);
                              }}
                            >
                              {"Last Name" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "LastName" &&
                                    lastNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickLastNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "LastName" &&
                                    lastNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickLastNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setFirstNameDescending(!firstNameDescending);
                                sortResultsBy("FirstName", !firstNameDescending);
                              }}
                            >
                              {"First Name" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "FirstName" &&
                                    firstNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickFirstNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "FirstName" &&
                                    firstNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickFirstNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setUserNameDescending(!userNameDescending);
                                sortResultsBy("UserName", !userNameDescending);
                              }}
                            >
                              {"Username" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UserName" &&
                                    userNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUserNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UserName" &&
                                    userNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUserNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setUserEmailDescending(!userEmailDescending);
                                sortResultsBy("UserEmail", !userEmailDescending);
                              }}
                            >
                              {"Email" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UserEmail" &&
                                    userEmailDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUserEmailAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "UserEmail" &&
                                    userEmailDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickUserEmailDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setCourseNameDescending(!courseNameDescending);
                                sortResultsBy("CourseName", !courseNameDescending);
                              }}
                            >
                              {getDynamicCourseTitle(cpdReportResultMetaData) /* "Course / Policy / External Certificate" TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "CourseName" &&
                                    courseNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickCourseNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "CourseName" &&
                                    courseNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickCourseNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setModuleNameDescending(!moduleNameDescending);
                                sortResultsBy("ModuleName", !moduleNameDescending);
                              }}
                            >
                              {getDynamicModuleTitle(cpdReportResultMetaData) /* "Module / Policy / External Certificate" TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "ModuleName" &&
                                    moduleNameDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickModuleNameAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "ModuleName" &&
                                    moduleNameDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickModuleNameDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="center"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setEarnedPointsDescending(!earnedPointsDescending);
                                sortResultsBy("EarnedPoints", !earnedPointsDescending);
                              }}
                            >
                              {"Earned Points" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "EarnedPoints" &&
                                    earnedPointsDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickEarnedPointsAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "EarnedPoints" &&
                                    earnedPointsDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickEarnedPointsDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="center"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setRequiredPointsDescending(!requiredPointsDescending);
                                sortResultsBy("RequiredPoints", !requiredPointsDescending);
                              }}
                            >
                              {"Required Points" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "RequiredPoints" &&
                                    requiredPointsDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickRequiredPointsAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "RequiredPoints" &&
                                    requiredPointsDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickRequiredPointsDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          style={{
                            color: getColor(color.substring(1)),
                            backgroundColor: color,
                          }}
                          align="left"
                        >
                          <div className={styles.tableHeadCellContainer}>
                            <div
                              onClick={() => {
                                setDateAssignedDescending(!dateAssignedDescending);
                                sortResultsBy(
                                  "DateAssigned",
                                  !dateAssignedDescending
                                );
                              }}
                            >
                              {"Date Earned" /* TODO translate */}
                            </div>
                            <div className={styles.columnHeadArea}>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "DateAssigned" &&
                                    dateAssignedDescending === false
                                      ? "100%"
                                      : "50%",
                                  margin: "2px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickDateAssignedAsc}
                              >
                                <UpArrowIcon />{" "}
                              </span>
                              <span
                                style={{
                                  opacity:
                                    currentSortBy === "DateAssigned" &&
                                    dateAssignedDescending === true
                                      ? "100%"
                                      : "50%",
                                  margin: "0px",
                                  height: "6px",
                                  display: "inline-flex",
                                }}
                                onClick={clickDateAssignedDesc}
                              >
                                <DownArrowIcon />{" "}
                              </span>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cpdReportResult
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((result: CPDReportResultItem, i) => {
                          return (
                            <TableRow
                              key={`CPDReportResult-${i}`}
                              style={rowBackgroundColor(i)}
                            >
                              <TableCell align="left">{result.unitPathway}</TableCell>
                              <TableCell align="left">{result.lastName}</TableCell>
                              <TableCell align="left">{result.firstName}</TableCell>
                              <TableCell align="left">{result.userName}</TableCell>
                              <TableCell align="left">{result.userEmail}</TableCell>
                              <TableCell align="left">{result.categoryOrCourseName}</TableCell>
                              <TableCell align="left">{result.moduleOrPolicyName}</TableCell>
                              <TableCell align="center">{(result.earnedPoints.toString().trim().startsWith("-")) ? "" : result.earnedPoints.toString()}</TableCell>
                              <TableCell align="center">{(result.totalRequiredPoints.toString().trim().startsWith("-")) ? "" : result.totalRequiredPoints.toString()}</TableCell>
                              <TableCell align="left">{result.dateAssignedTxt}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                </BrandedCard>
            </div>
          </div>
          <div
            className={styles.paginationControlsContainer}
            style={{ justifyContent: "center" }}
          >
            <TablePagination
              component="div"
              count={cpdReportResult.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </>
      ); 
}

export default CPDReportSection