//React + Typescript
import React, { useState, useContext, useEffect } from "react";
import { QuizHistoryReportResultItem } from "../../globalTypes";
// Libs
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { Steps, Hints } from "intro.js-react";

//Context
import { LoaderContext } from "../../contexts/LoaderContext";
import { CorperateThemeContext } from "../../contexts/CorperateThemeContext";
import { UIContext } from "../../contexts/UIContext";
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from "../../Components/BrandedCard";
import LoaderContainer from "../../Components/LoaderContainer";
import CustomTooltip from "../../Components/CustomTooltip";

//General Functions
import getColor from "../../functions/getColor";
import serialToArray from "../../functions/serialToArray";
import { getCurrentTimeDDMMYYYYFormat } from "../../functions/getCurrentTimeDDMMYYYYFormat";
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from "../../API/GetExportedXLSXData";
import { getExportedCSVData } from "../../API/GetExportedCSVData";
import { getExportedPDFData } from "../../API/GetExportedPDFData";
import { addError } from "../../API/AddError";
//Components
import ExcelFileIcon from "../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../CustomIcons/CSVFileIcon";
import PDFIcon from "../../CustomIcons/PDFFileIcon";
import UpArrowIcon from "../../CustomIcons/UpArrowIcon";
import DownArrowIcon from "../../CustomIcons/DownArrowIcon";
//MUI Components
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import BrushIcon from "@material-ui/icons/Brush";

// Style
import styles from "../../styles/currentAdminReport.module.css";
import GuideIcon from "../../CustomIcons/GuideIcon";

const QuizHistoryReportResult = (props: any) => {
  const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
  const { oidcUser } = useReactOidc();
  const {
    profile: { user_id, family_name, given_name, website },
  } = oidcUser || {};
  const userId = user_id ? parseInt(user_id) : 0;
  const orgId = oidcUser.profile.organisation_id ?? -1;
  const orgDomain = website
    ? website
        .toLowerCase()
        .replace("altlogin-", "") /* To support the non-sso users of SSO orgs */
    : "";
  const {
    theme: { color },
  } = useContext(CorperateThemeContext);
  const { setMessage } = useContext(LoaderContext);
  const [quizHistoryReport, setQuizHistoryReportResult] = useState<
    Array<QuizHistoryReportResultItem>
  >(props?.location?.state?.resultItems ?? []);
  const [exporting, setExporting] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true);
  const [currentSortBy, setCurrentSortBy] = useState<string>(""); 
  const [sortInProgress, setSortInProgress] = useState<boolean>(false);
  
  
  const [unitIDDescending, setUnitIDDescending] =    useState<boolean>(false);
  const [unitPathwayDescending, setUnitPathwayDescending] =    useState<boolean>(false);
  const [userIDDescending, setUserIDDescending] =    useState<boolean>(false);
  const [firstNameDescending, setFirstNameDescending] =    useState<boolean>(false);
  const [lastNameDescending, setLastNameDescending] = useState<boolean>(false);
  const [emailDescending, setEmailDescending] = useState<boolean>(false);  
  const [userNameDescending, setUserNameDescending] = useState<boolean>(false);
  const [activeDescending, setActiveDescending] =    useState<boolean>(false);
  const [courseNameDescending, setCourseNameDescending] = useState<boolean>(false);
  const [moduleNameDescending, setModuleNameDescending] =    useState<boolean>(false); 
  const [quizStatusDescending, setQuizStatusDescending] =    useState<boolean>(false);
  const [quizScoreDescending, setQuizScoreDescending] = useState<boolean>(false);  
  const [quizStatusDateDescending, setQuizStatusDateDescending] =    useState<boolean>(false);

  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("");
  const { setTitle, setTitleEnglish, updateBackButtonRoute ,menuSelection, EnableActivityLogs} = useContext(UIContext);
  const [exportError, setExportError] = useState<string>("");
  const [resultError, setResultError] = useState<string>(props?.location?.state?.resultError ?? "" );

  const [includeInactive, setIncludeInactive] = useState<boolean>(props?.location?.state?.inputFilterIncludeInactive ?? "");
  const [inputFilterFirstName, setInputFilterFirstName] = useState<string>(props?.location?.state?.inputFilterFirstName ?? "")
  const [inputFilterLastName, setInputFilterLastName] = useState<string>(props?.location?.state?.inputFilterLastName ?? "")
  const [inputFilterUserName, setInputFilterUserName] = useState<string>(props?.location?.state?.inputFilterUserName ?? "")
  const [inputFilterEmail, setInputFilterEmail] = useState<string>(props?.location?.state?.inputFilterEmail ?? "")
  const [selectedUnitNames, setSelectedUnitNames] = useState<string>(
    props?.location?.state?.selectedUnits ?? []
  );
  const [inputHistoricData, setInputHistoricData] = useState<boolean>(
    props?.location?.state?.inputHistoricData ?? false
  );  
  
  const [isLengthyCoursePresent, setIsLengthyCoursePresent] = useState<boolean>(false)
    
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".quizHistoryReportList",
        title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta       
      },      
    ]
  })

  useEffect(() => {
    if (!!quizHistoryReport && quizHistoryReport.length > 0) {
        setIsLengthyCoursePresent(quizHistoryReport.some((item) => !!item.courseName && item.courseName.length > 100))
    }
  }, [quizHistoryReport]);

  useEffect(() => 
  {
      let updateSteps = [...intro.steps];
        updateSteps.push( 
        {
        element: ".xlxs",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
        },
        {
        element: ".csv",
        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
        },
        {
            element: ".pdf",
            title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
        }
        // {
        //     element: ".rowShading",
        //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
        // }           
    );
  setIntro({...intro, steps: [...updateSteps]});
  },[])
  
  const onExit = () => {
    setIntro({...intro, stepsEnabled: false });
  };

 // const toggleBanding = () => setEnableRowBanding(!enableRowBanding);

  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "" };
    } else {
      return {};
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
    
  const GetDate_yymmdd = () => {
      var d =  new Date(Date.now());
      let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
      return day;   
  }

  const buildRequestStringArray = () => {
    let resultArray: Array<string> = [];
    for (let i = 0; i < quizHistoryReport.length; i++) {
        const r = quizHistoryReport[i];
        if (includeInactive) {
          resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.userName}<#>${r.active}<#>${r.unitID}<#>${r.userID}<#>${r.courseName}<#>${r.moduleName}<#>${r.quizScore + '%'}<#>${r.quizStatus}<#>${r.quizStatusDate}`);
        }
        else {
          resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.userName}<#>${r.unitID}<#>${r.userID}<#>${r.courseName}<#>${r.moduleName}<#>${r.quizScore + '%'}<#>${r.quizStatus}<#>${r.quizStatusDate}`);
        }
    }
    return resultArray;
  };

  const handleXLSXExport = () => {
    setMessage("Exporting XLSX");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Quiz Histiry Report XLSX-EXPORT in NEWSALT ClientApp",
      null,
      "QuizHistoryReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactive?13:12, "QuizHistoryReport", enableRowBanding, "","","","",includeInactive,false, "", inputFilterFirstName, inputFilterLastName,inputFilterUserName,inputFilterEmail,"","",false,false,0,"","",0,0,"","","",undefined,0,false,inputHistoricData).then((xlxsRes) => {
        if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            );            
            a.download = "QuizHistoryReport-"+  GetDate_yymmdd() + ".xlsx" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
        }
        setExporting(false);
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Quiz History Report XLSX-EXPORT in NEWSALT ClientApp", null, "QuizHistoryReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handleCSVExport = () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Quiz History Report CSV-EXPORT in NEWSALT ClientApp",
      null,
      "QuizHistoryReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactive?13:12, "QuizHistoryReport", "","","","",includeInactive,false, "",false, "", inputFilterFirstName, inputFilterLastName,inputFilterUserName,inputFilterEmail,"",false,false,0,"","",0,0,"","","",undefined,0,false,inputHistoricData).then((csvRes) => {
        if (csvRes.isSuccess) {
            const fileData = csvRes.csvDataOutput.fileData
            var a = window.document.createElement('a');

            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "QuizHistoryReport-"+  GetDate_yymmdd() + ".csv" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)

        }
        else if (csvRes.error !== undefined && csvRes.error !== null) {
            setExportError(csvRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Quiz History Report CSV-EXPORT in NEWSALT ClientApp", null, "QuizHistoryReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFXExport = () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Started Quiz History Report PDF-EXPORT in NEWSALT ClientApp",
      null,
      "QuizHistoryReportResult.tsx",
      6, EnableActivityLogs
    );
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), includeInactive?13:12, "QuizHistoryReport", enableRowBanding,"","","","",includeInactive,false, "", inputFilterFirstName, inputFilterLastName,inputFilterUserName,inputFilterEmail,"","",false,false,0,"","",0,0,"","","",undefined,0,false,inputHistoricData).then((pdfRes) => {
        if (pdfRes.isSuccess) {
            const fileData = pdfRes.pdfDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
                new Blob([serialToArray(atob(fileData))], {
                    type: ''
                })
            )
            a.download = "QuizHistoryReport-"+  GetDate_yymmdd() + ".PDF" ;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
        }
        else if (pdfRes.error !== undefined && pdfRes.error !== null) {
            setExportError(pdfRes.error);
        }
        setExporting(false)
        // Activity log
        addError(oidcUser.access_token, userId, orgDomain, "Completed Quiz History Report PDF-EXPORT in NEWSALT ClientApp", null, "QuizHistoryReportResult.tsx", 6, EnableActivityLogs);
    })
  };

  const sortResultsByAsync = async (
    sortBy:
      | "UnitPathway"
      | "UnitID"
      | "UserID"
      | "FirstName"
      | "LastName"     
      | "Email"
      | "Username"
      | "Active"
      | "CourseName"
      | "ModuleName"
      | "QuizStatus"
      | "QuizScore"
      | "QuizStatusDate",
    sortGivenItemInDesc: boolean
  ) => {
    if (quizHistoryReport == null || quizHistoryReport.length <= 0) {
      return quizHistoryReport;
    }
    var result = quizHistoryReport.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {      
    case "UnitID":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.unitID === null) {
                  return -1;
                }
                if (a === null || a.unitID === null) {
                  return 1;
                }
                if (a.unitID > b.unitID) {
                  return -1;
                }
                if (a.unitID < b.unitID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUnitIDDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.unitID === null) {
                  return 1;
                }
                if (a === null || a.unitID === null) {
                  return -1;
                }
                if (a.unitID < b.unitID) {
                  return -1;
                }
                if (a.unitID > b.unitID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUnitIDDescending(true);
              }
            }
            setQuizHistoryReportResult(result);
            break;
    case "UnitPathway":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.unitPathway === null) {
                return -1;
              }
              if (a === null || a.unitPathway === null) {
                return 1;
              }
              if (a.unitPathway > b.unitPathway) {
                return -1;
              }
              if (a.unitPathway < b.unitPathway) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUnitPathwayDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.unitPathway === null) {
                return 1;
              }
              if (a === null || a.unitPathway === null) {
                return -1;
              }
              if (a.unitPathway > b.unitPathway) {
                return 1;
              }
              if (a.unitPathway < b.unitPathway) {
                return -1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUnitPathwayDescending(true);
            }
          }
          setQuizHistoryReportResult(result);
          break; 
    case "UserID":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.userID === null) {
                  return -1;
                }
                if (a === null || a.userID === null) {
                  return 1;
                }
                if (a.userID > b.userID) {
                  return -1;
                }
                if (a.userID < b.userID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUserIDDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.userID === null) {
                  return 1;
                }
                if (a === null || a.userID === null) {
                  return -1;
                }
                if (a.userID < b.userID) {
                  return -1;
                }
                if (a.userID > b.userID) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setUserIDDescending(true);
              }
            }
            setQuizHistoryReportResult(result);
            break;  
    case "Email":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return -1;
            }
            if (a === null || a.email === null) {
              return 1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.email === null) {
              return 1;
            }
            if (a === null || a.email === null) {
              return -1;
            }
            if (a.email.toLowerCase() < b.email.toLowerCase()) {
              return -1;
            }
            if (a.email.toLowerCase() > b.email.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setEmailDescending(true);
          }
        }
        setQuizHistoryReportResult(result);
        break;
    case "Username":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.userName === null) {
                return -1;
              }
              if (a === null || a.userName === null) {
                return 1;
              }
              if (a.userName.trim().toLowerCase() > b.userName.trim().toLowerCase()) {
                return -1;
              }
              if (a.userName.trim().toLowerCase() < b.userName.trim().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setEmailDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.userName === null) {
                return 1;
              }
              if (a === null || a.userName === null) {
                return -1;
              }
              if (a.userName.trim().toLowerCase() < b.userName.trim().toLowerCase()) {
                return -1;
              }
              if (a.userName.trim().toLowerCase() > b.userName.trim().toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setUserNameDescending(true);
            }
          }
          setQuizHistoryReportResult(result);
          break;
    case "FirstName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return -1;
            }
            if (a === null || a.firstName === null) {
              return 1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.firstName === null) {
              return 1;
            }
            if (a === null || a.firstName === null) {
              return -1;
            }
            if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
              return -1;
            }
            if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setFirstNameDescending(true);
          }
        }
        setQuizHistoryReportResult(result);
        break;
    case "LastName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return -1;
            }
            if (a === null || a.lastName === null) {
              return 1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.lastName === null) {
              return 1;
            }
            if (a === null || a.lastName === null) {
              return -1;
            }
            if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) {
              return -1;
            }
            if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setLastNameDescending(true);
          }
        }
        setQuizHistoryReportResult(result);
        break;
    case "CourseName":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.courseName === null) {
                return -1;
              }
              if (a === null || a.courseName === null) {
                return 1;
              }
              if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) {
                return -1;
              }
              if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setCourseNameDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.courseName === null) {
                return 1;
              }
              if (a === null || a.courseName === null) {
                return -1;
              }
              if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) {
                return -1;
              }
              if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setCourseNameDescending(true);
            }
          }
          setQuizHistoryReportResult(result);
          break;
    case "ModuleName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.moduleName === null) {
              return -1;
            }
            if (a === null || a.moduleName === null) {
              return 1;
            }
            if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) {
              return -1;
            }
            if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModuleNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.moduleName === null) {
              return 1;
            }
            if (a === null || a.moduleName === null) {
              return -1;
            }
            if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) {
              return -1;
            }
            if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModuleNameDescending(true);
          }
        }
        setQuizHistoryReportResult(result);
        break;
    case "Active":
          if (sortGivenItemInDesc) {
            result.sort((a, b) => {
              if (b === null || b.active === null) {
                return -1;
              }
              if (a === null || a.active === null) {
                return 1;
              }
              if (a.active.toLowerCase() > b.active.toLowerCase()) {
                return -1;
              }
              if (a.active.toLowerCase() < b.active.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setActiveDescending(false);
            }
          } else {
            result.sort((a, b) => {
              if (b === null || b.active === null) {
                return 1;
              }
              if (a === null || a.active === null) {
                return -1;
              }
              if (a.active.toLowerCase() < b.active.toLowerCase()) {
                return -1;
              }
              if (a.active.toLowerCase() > b.active.toLowerCase()) {
                return 1;
              }
              return 0;
            });
            if (reverseNextSort) {
              setActiveDescending(true);
            }
          }
          setQuizHistoryReportResult(result);
          break;
    case "QuizStatusDate":
          if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                  if (b === null || b.quizStatusDate === null || b.quizStatusDate.trim() === "") { return -1; }
                  if (a === null || a.quizStatusDate === null || a.quizStatusDate.trim() === "") { return 1; }
                  var dta = new Date(a.quizStatusDate.split("/").reverse().join("-"))
                  var dtb = new Date(b.quizStatusDate.split("/").reverse().join("-"))
                  if(dta > dtb) { return -1; }
                  if(dta < dtb) { return 1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setQuizStatusDateDescending(false)
              }
          } else {
              result.sort((a, b) => {
                  if (b === null || b.quizStatusDate === null || b.quizStatusDate.trim() === "") { return 1; }
                  if (a === null || a.quizStatusDate === null || a.quizStatusDate.trim() === "") { return -1; }
                  var dta = new Date(a.quizStatusDate.split("/").reverse().join("-"))
                  var dtb = new Date(b.quizStatusDate.split("/").reverse().join("-"))
                  if(dta > dtb) { return 1; }
                  if(dta < dtb) { return -1; }
                  return 0;
              })
              if (reverseNextSort) {
                  setQuizStatusDateDescending(true)
              }
          }
          setQuizHistoryReportResult(result);
          break;
    case "QuizStatus":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.quizStatus === null) {
                  return -1;
                }
                if (a === null || a.quizStatus === null) {
                  return 1;
                }
                if (a.quizStatus.trim().toLowerCase() > b.quizStatus.trim().toLowerCase()) {
                  return -1;
                }
                if (a.quizStatus.trim().toLowerCase() < b.quizStatus.trim().toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setQuizStatusDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.quizStatus === null) {
                  return 1;
                }
                if (a === null || a.quizStatus === null) {
                  return -1;
                }
                if (a.quizStatus.trim().toLowerCase() < b.quizStatus.trim().toLowerCase()) {
                  return -1;
                }
                if (a.quizStatus.trim().toLowerCase() > b.quizStatus.trim().toLowerCase()) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setQuizStatusDescending(true);
              }
            }
            setQuizHistoryReportResult(result);
            break;
   case "QuizScore":
            if (sortGivenItemInDesc) {
              result.sort((a, b) => {
                if (b === null || b.quizScore === null) {
                  return -1;
                }
                if (a === null || a.quizScore === null) {
                  return 1;
                }
                if (a.quizScore > b.quizScore) {
                  return -1;
                }
                if (a.quizScore < b.quizScore) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setQuizScoreDescending(false);
              }
            } else {
              result.sort((a, b) => {
                if (b === null || b.quizScore === null) {
                  return 1;
                }
                if (a === null || a.quizScore === null) {
                  return -1;
                }
                if (a.quizScore < b.quizScore) {
                  return -1;
                }
                if (a.quizScore > b.quizScore) {
                  return 1;
                }
                return 0;
              });
              if (reverseNextSort) {
                setQuizScoreDescending(true);
              }
            }
            setQuizHistoryReportResult(result);
            break;
        default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy:
    | "UnitPathway"
    | "UnitID"
    | "UserID"
    | "FirstName"
    | "LastName"     
    | "Email"
    | "Username"
    | "Active"
    | "CourseName"
    | "ModuleName"
    | "QuizStatus"
    | "QuizScore"
    | "QuizStatusDate",
    sortGivenItemInDesc: boolean
  ) => {
    setSortInProgress(true);
    setExportError("");
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setQuizHistoryReportResult(result);
      setPage(0);
      setSortInProgress(false);
    });
  };

  const clickUnitPathwayAsc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(false);
    sortResultsBy("UnitPathway", false);
  };

  const clickUnitPathwayDesc = () => {
    setReverseNextSort(false);
    setUnitPathwayDescending(true);
    sortResultsBy("UnitPathway", true);
  };

  const clickUnitIDAsc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(false);
    sortResultsBy("UnitID", false);
  };

  const clickUnitIDDesc = () => {
    setReverseNextSort(false);
    setUnitIDDescending(true);
    sortResultsBy("UnitID", true);
  };
  const clickUserIDAsc = () => {
    setReverseNextSort(false);
    setUserIDDescending(false);
    sortResultsBy("UserID", false);
  };

  const clickUserIDDesc = () => {
    setReverseNextSort(false);
    setUserIDDescending(true);
    sortResultsBy("UserID", true);
  };
  const clickFirstNameAsc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(false);
    sortResultsBy("FirstName", false);
  };

  const clickFirstNameDesc = () => {
    setReverseNextSort(false);
    setFirstNameDescending(true);
    sortResultsBy("FirstName", true);
  };

  const clickLastNameAsc = () => {
    setReverseNextSort(false);
    setLastNameDescending(false);
    sortResultsBy("LastName", false);
  };

  const clickLastNameDesc = () => {
    setReverseNextSort(false);
    setLastNameDescending(true);
    sortResultsBy("LastName", true);
  };
  
  const clickEmailAsc = () => {
    setReverseNextSort(false);
    setEmailDescending(false);
    sortResultsBy("Email", false);
  };

  const clickEmailDesc = () => {
    setReverseNextSort(false);
    setEmailDescending(true);
    sortResultsBy("Email", true);
  };

  const clickUserNameAsc = () => {
    setReverseNextSort(false);
    setUserNameDescending(false);
    sortResultsBy("Username", false);
  };

  const clickUserNameDesc = () => {
    setReverseNextSort(false);
    setUserNameDescending(true);
    sortResultsBy("Username", true);
  };
  const clickActiveAsc = () => {
    setReverseNextSort(false);
    setActiveDescending(false);
    sortResultsBy("Active", false);
  };

  const clickActiveDesc = () => {
    setReverseNextSort(false);
    setActiveDescending(true);
    sortResultsBy("Active", true);
  };

  const clickCourseNameAsc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(false);
    sortResultsBy("CourseName", false);
  };

  const clickCourseNameDesc = () => {
    setReverseNextSort(false);
    setCourseNameDescending(true);
    sortResultsBy("CourseName", true);
  };

  const clickModuleNameAsc = () => {
    setReverseNextSort(false);
    setModuleNameDescending(false);
    sortResultsBy("ModuleName", false);
  };

  const clickModuleNameDesc = () => {
    setReverseNextSort(false);
    setModuleNameDescending(true);
    sortResultsBy("ModuleName", true);
  };

  const clickQuizScoreAsc = () => {
    setReverseNextSort(false);
    setQuizScoreDescending(false);
    sortResultsBy("QuizScore", false);
  };

  const clickQuizScoreDesc = () => {
    setReverseNextSort(false);
    setQuizScoreDescending(true);
    sortResultsBy("QuizScore", true);
  };

  const clickQuizStatusAsc = () => {
    setReverseNextSort(false);
    setQuizStatusDescending(false);
    sortResultsBy("QuizStatus", false);
  };

  const clickQuizStatusDesc = () => {
    setReverseNextSort(false);
    setQuizStatusDescending(true);
    sortResultsBy("QuizStatus", true);
  };


  const clickQuizStatusDateAsc = () => {
    setReverseNextSort(false);
    setQuizStatusDateDescending(false);
    sortResultsBy("QuizStatusDate", false);
  };

  const clickQuizStatusDateDesc = () => {
    setReverseNextSort(false);
    setQuizStatusDateDescending(true);
    sortResultsBy("QuizStatusDate", true);
  };


  const initialSort = () => {
    setCurrentSortBy("UnitPathway");
    setUnitIDDescending(false);  
    setUserIDDescending(false);
    setLastNameDescending(false);
    setFirstNameDescending(false);
    setEmailDescending(false);
    setActiveDescending(false);   
    setCourseNameDescending(false);
    setModuleNameDescending(false);
    setQuizScoreDescending(false);
    setQuizStatusDescending(false);
    setQuizStatusDateDescending(false);   
    getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
      if (res.isSuccess) {
        let curdt = res.currentDateTimeText;
        setReportRunTimeTaken(curdt);
      } else {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
      }
     }).catch ((error) => {
        let curdt = getCurrentTimeDDMMYYYYFormat();
        setReportRunTimeTaken(curdt);
     });
    setPage(0);
  };

  useEffect(() => {
    initialSort();
    // Activity log
    addError(
      oidcUser.access_token,
      userId,
      orgDomain,
      "Completed Quiz History Report in NEWSALT ClientApp",
      null,
      "Quiz HistoryReportResult.tsx",
      6, EnableActivityLogs
    );
  }, [menuSelection]);

  if (
    resultError !== null &&
    resultError !== undefined &&
    resultError.trimLeft().trimRight().length > 0
  ) {
    setTitle(allResourcesToTranslations.quizhistoryreport);
    setTitleEnglish(pageTitles.quizhistoryreport)
    updateBackButtonRoute("/quizhistoryreport");
    //setExportError("");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div style={{ color: "red" }}>Error: {resultError}</div>
          <br />
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else if (quizHistoryReport === null || quizHistoryReport.length < 1) {
    setTitle(allResourcesToTranslations.quizhistoryreport);
    setTitleEnglish(pageTitles.quizhistoryreport)
    //setExportError("");
    updateBackButtonRoute("/quizhistoryreport");
    return (
      <BrandedCard>
        <div className={styles.noResultsCard}>
          <div>No Results to Display</div>
          <div>Select the Back Button to return to Previous Page</div>
        </div>
      </BrandedCard>
    );
  } else {
    setTitle(allResourcesToTranslations.quizhistoryreport);
    updateBackButtonRoute("/quizhistoryreport");
    setTitleEnglish(pageTitles.quizhistoryreport)
  }
  const GetInputFilterFirstName = () => {        
    let curValue  = inputFilterFirstName != null ? inputFilterFirstName : ""; 
    return curValue != "" ?<div className={styles.reportDetailContainer}>
                         <span className={styles.reportDetailTitle}>{"First Name" /* TODO Tranlation */}</span>
                         <span className={styles.reportDetail}> {inputFilterFirstName}</span></div> : ""
}

const GetInputFilterLastName = () => {       
    let curValue  = inputFilterLastName != null ? inputFilterLastName : ""; 
    return curValue != "" ?<div className={styles.reportDetailContainer}>
                         <span className={styles.reportDetailTitle}>{"Last Name" /* TODO Tranlation */}</span>
                         <span className={styles.reportDetail}> {inputFilterLastName}</span></div> : ""
}

const GetInputFilterUserName = () => {        
    let curValue  = inputFilterUserName != null ? inputFilterUserName : ""; 
    return curValue != "" ?<div className={styles.reportDetailContainer}>
                         <span className={styles.reportDetailTitle}>{"Username" /* TODO Tranlation */}</span>
                         <span className={styles.reportDetail}> {inputFilterUserName}</span></div> : ""
}

const GetInputFilterEmail = () => {       
    let curValue  = inputFilterEmail != null ? inputFilterEmail : ""; 
    return curValue != "" ?<div className={styles.reportDetailContainer}>
                         <span className={styles.reportDetailTitle}>{"Email" /* TODO Tranlation */}</span>
                         <span className={styles.reportDetail}> {inputFilterEmail}</span></div> : ""
}
const GetInputFilterHistoricData = () => {       
  let curValue  = inputHistoricData != null ? inputHistoricData : ""; 
  return curValue != "" ?<div className={styles.reportDetailContainer}>
                       <span className={styles.reportDetailTitle}>{"Include Historic Course" /* TODO Tranlation */}</span>
                       <span className={styles.reportDetail}> {inputHistoricData ? "Yes" : "No"}</span></div> : ""
}

  const GetSelectedUnitsSummary = () => {
    if (!!selectedUnitNames) {
      let arr = selectedUnitNames.split(",");
      return selectedUnitNames != "" && arr.length > 5 ? (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",", 5).map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
          <CustomTooltip
            displayText={selectedUnitNames.split(",").splice(5, arr.length)}
            placement="bottom"
          >
            <span className={styles.reportDetail}>
              {arr.length > 5 ? "+" + (arr.length - 5) + " more..." : ""}
            </span>
          </CustomTooltip>
        </div>
      ) : (
        <div className={styles.reportDetailContainer}>
          <span className={styles.reportDetailTitle}>
            {"Unit(s) where applicable" /* TODO Tranlation */}
          </span>
          {selectedUnitNames.split(",").map((step: any) => (
            <span key={step} className={styles.reportDetail}>
              {step}
            </span>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  if (exporting) return <LoaderContainer />;

  return (
    <>
      {
        <div style={{ color: "red" }}>
          {exportError === undefined ||
          exportError === null ||
          exportError === ""
            ? ""
            : "Export Error: " + exportError}
        </div>
      }
      <hr />
      <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>                
                <div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}>
                                { reportRunTimeTaken }
                            </span>
                        </div>
                         {/* {GetSelectedUnitsSummary()}  */}
                        { GetInputFilterFirstName() }      
                        { GetInputFilterLastName() }      
                        { GetInputFilterUserName() }      
                        { GetInputFilterEmail() }    
                        { GetInputFilterHistoricData() }                                            
                        <div className={styles.reportDetailContainer}>
                          <span className={styles.reportDetailTitle}>
                            {"Include Inactive Accounts" /* TODO Tranlation */}
                          </span>
                          <span className={styles.reportDetail}>
                            {" "}
                            {includeInactive ? "Yes" : "No"}
                          </span>
                        </div>
                    </div>   
                   
                    <div className={styles.reportExportContainer}>
                                <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                        <Button  className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                                            <ExcelFileIcon/>
                                        </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                    <Button   className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                                        <CSVFileIcon/>
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                    <Button  className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCARReportExportPDF">
                                        <PDFIcon/>
                                    </Button>
                                </CustomTooltip>
                                {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading"  color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/> 
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                    
                </div>
               
            </div>
            </BrandedCard>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          component="div"
          count={quizHistoryReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <div className={styles.tableSection}>
        <div className={styles.tableContainer}>
          <BrandedCard>
            <TableContainer>
              <Table>
                <TableHead className="quizHistoryReportList">
                  <TableRow>
                  <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,borderRadius: "8px 0 0 0",}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setUnitPathwayDescending(!unitPathwayDescending);sortResultsBy("UnitPathway", !unitPathwayDescending);}}>
                          {"Unit Pathway" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "UnitPathway" &&unitPathwayDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickUnitPathwayAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "UnitPathway" &&unitPathwayDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickUnitPathwayDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => { setLastNameDescending(!lastNameDescending);sortResultsBy("LastName", !lastNameDescending);}}>
                          {"Last Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "LastName" && lastNameDescending === false ? "100%": "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickLastNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "LastName" &&lastNameDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickLastNameDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setFirstNameDescending(!firstNameDescending);sortResultsBy("FirstName", !firstNameDescending);}}>
                          {"First Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "FirstName" &&firstNameDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickFirstNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "FirstName" &&firstNameDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickFirstNameDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> 
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setEmailDescending(!emailDescending);sortResultsBy("Email", !emailDescending);}}>
                          {"Email" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "Email" &&emailDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickEmailAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "Email" &&emailDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickEmailDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {
                          setUserNameDescending(!userNameDescending);
                          sortResultsBy("Username", !userNameDescending);
                        }}>
                          {"Username" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{ opacity: currentSortBy === "Username" && userNameDescending === false ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex", }} onClick={clickUserNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{ opacity: currentSortBy === "Username" && userNameDescending === true ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex", }} onClick={clickUserNameDesc}>                           <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    {includeInactive &&
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setActiveDescending(!activeDescending);sortResultsBy("Active", !activeDescending);}}>
                          {"Status" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "Active" &&activeDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickActiveAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "Active" &&activeDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickActiveDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> 
                    }
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setUnitIDDescending(!unitIDDescending);sortResultsBy("UnitID", !unitIDDescending);}}>
                          {"Unit ID" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "UnitID" &&unitIDDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickUnitIDAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity:currentSortBy === "UnitID" &&unitIDDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickUnitIDDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setUserIDDescending(!userIDDescending);sortResultsBy("UserID", !userIDDescending);}}>
                          {"Account ID" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "UserID" &&userIDDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickUserIDAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "UserID" &&userIDDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickUserIDDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>                      
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                      <div className={isLengthyCoursePresent ? styles.tableHeadCellContainerLong : styles.tableHeadCellContainer}>
                        <div onClick={() => {setCourseNameDescending(!courseNameDescending);sortResultsBy("CourseName", !courseNameDescending);}}>
                          {"Course Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "CourseName" &&courseNameDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickCourseNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "CourseName" &&courseNameDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickCourseNameDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>                          
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setModuleNameDescending(!moduleNameDescending);sortResultsBy("ModuleName", !moduleNameDescending);}}>
                          {"Module Name" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "ModuleName" &&moduleNameDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickModuleNameAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "ModuleName" &&moduleNameDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickModuleNameDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell> 
                    <TableCell style={{ color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setQuizScoreDescending(!quizScoreDescending);sortResultsBy("QuizScore", !quizScoreDescending);}}>
                          {"Quiz Score" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity: currentSortBy === "QuizScore" &&quizScoreDescending === false ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickQuizScoreAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span style={{opacity: currentSortBy === "QuizScore" &&quizScoreDescending === true ? "100%": "50%", margin: "2px",height: "6px",display: "inline-flex" }} onClick={clickQuizScoreDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color,}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setQuizStatusDescending(!quizStatusDescending);sortResultsBy("QuizStatus",!quizStatusDescending);}}>
                          {"Quiz Status" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "QuizStatus" &&quizStatusDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickQuizStatusAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{opacity:currentSortBy === "QuizStatus" &&quizStatusDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickQuizStatusDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>  
                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"}}align="left">
                      <div className={styles.tableHeadCellContainer}>
                        <div onClick={() => {setQuizStatusDateDescending(!quizStatusDateDescending);sortResultsBy("QuizStatusDate",!quizStatusDateDescending);}}>
                          {"Quiz Status Date" /* TODO translate */}
                        </div>
                        <div className={styles.columnHeadArea}>
                          <span style={{opacity:currentSortBy === "QuizStatusDate" &&quizStatusDateDescending === false? "100%": "50%",margin: "2px",height: "6px",display: "inline-flex",}}onClick={clickQuizStatusDateAsc}>
                            <UpArrowIcon />{" "}
                          </span>
                          <span
                            style={{opacity:currentSortBy === "QuizStatusDate" &&quizStatusDateDescending === true? "100%": "50%",margin: "0px",height: "6px",display: "inline-flex",}}onClick={clickQuizStatusDateDesc}>
                            <DownArrowIcon />{" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>                   
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quizHistoryReport
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((result: QuizHistoryReportResultItem, i) => {
                      return (
                        <TableRow
                          key={`QuizHistoryReportResult-${i}`}
                          style={rowBackgroundColor(i)}>
                          <TableCell align="left">{result.unitPathway}</TableCell>
                          <TableCell align="left">{result.lastName}</TableCell>                          
                          <TableCell align="left">{result.firstName}</TableCell>                          
                          <TableCell align="left">{result.email}</TableCell>
                          <TableCell align="left">{result.userName}</TableCell>
                          {includeInactive &&<TableCell align="center" >{result.active}</TableCell>}
                          <TableCell align="left">{result.unitID}</TableCell>
                          <TableCell align="left">{result.userID}</TableCell>                       
                          <TableCell align="left">{result.courseName}</TableCell>
                          <TableCell align="left">{result.moduleName}</TableCell>
                          <TableCell align="center">{result.quizScore+'%'}</TableCell>
                          <TableCell align="left">{result.quizStatus}</TableCell>                          
                          <TableCell align="left">{result.quizStatusDate}</TableCell>                         
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </BrandedCard>
        </div>
      </div>
      <div
        className={styles.paginationControlsContainer}
        style={{ justifyContent: "center" }}
      >
        <TablePagination
          component="div"
          count={quizHistoryReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default QuizHistoryReportResult;
