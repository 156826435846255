import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/importAccounts.module.css";
import CustomTooltip from '../../../Components/CustomTooltip';
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import { Button, Checkbox, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@material-ui/core';
import { CorperateThemeContext } from "../../../contexts/CorperateThemeContext";
import { LoaderContext } from "../../../contexts/LoaderContext";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { UIContext } from "../../../contexts/UIContext";
import LoaderContainer from "../../../Components/LoaderContainer";
import { Steps } from "intro.js-react";
import GuideIcon from "../../../CustomIcons/GuideIcon";
import { useHistory } from "react-router";
import BrandedCard from "../../../Components/BrandedCard";
import getColor from "../../../functions/getColor";
import EditIcon from "../../../CustomIcons/SupportDetailsEditIcon";
import GetExternalCertificates from "../../../API/Administration/ExternalCertificate/GetExternalCertificates";

import { AddCircleOutlineRounded } from "@material-ui/icons";
import CertificateLists from "./CertificateLists";
import UnbrandedCard from "../../../Components/UnbrandedCard";
import ProvidedIcon from "../../../CustomIcons/ProvidedIcon";
import NotProvidedIcon from "../../../CustomIcons/NotProvidedIcon";
import DownArrowIcon from "../../../CustomIcons/DownArrowIcon";
import UpArrowIcon from "../../../CustomIcons/UpArrowIcon";
import ExcelFileIcon from "../../../CustomIcons/ExcelFileIcon";
import CSVFileIcon from "../../../CustomIcons/CSVFileIcon";
import PDFIcon from '../../../CustomIcons/PDFFileIcon';
import { addError } from '../../../API/AddError'
import getExportedPDFData from "../../../API/GetExportedPDFData";
import serialToArray from "../../../functions/serialToArray";
import getExportedXLSXData from "../../../API/GetExportedXLSXData";
import getExportedCSVData from "../../../API/GetExportedCSVData";
import GetFullExternalCertificateAdminReport from "../../../API/Administration/ExternalCertificate/GetFullExternalCertificateAdminReport";
import GetExternalCertificatesAssignedUsers from "../../../API/Administration/ExternalCertificate/GetExternalCertificatesAssignedUsers";

const ExternalCertificates = (props: any): JSX.Element => {
  const history = useHistory();
  const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
  const { color } = useContext(CorperateThemeContext).theme;
  const { setMessage } = useContext(LoaderContext);
  const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, EnableActivityLogs } = useContext(UIContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { oidcUser } = useReactOidc();

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [enableRowBanding, setEnableRowBanding] = React.useState<boolean>(true);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = React.useState(false);
  const [modalPopupMessage, setModalPopupMessage] = useState('');
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [externalCertificates, setExternalCertificates] = useState<Array<any>>([]);


  const [externalCertificatesExport, setExternalCertificatesExport] = useState<Array<any>>([]);


  const orgId = oidcUser.profile.organisation_id ?? -1;
  const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
  const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";

  const [certificateIdToEdit, setCertificateIdToEdit] = useState<number>(0);  
  const [certificateHasNoDueDates, setCertificateHasNoDueDates] = useState<boolean>(false);
  const [showUserAssignedCertificate, setshowUserAssignedCertificate] = useState<boolean>(false);
  const [showbuttons, setshowbuttons] = useState<boolean>(false);
  const [certificateListAction, setCertificateListAction] = useState('')
  const [currentPage, setcurrentPage] = useState<string>("");

  //rowshorting
  const [currentSortBy, setCurrentSortBy] = useState<string>("");
  const [reverseNextSort, setReverseNextSort] = useState<boolean>(false);
  const [certificateNameDescending, setCertificateNameDescending] = useState<boolean>(false);
  const [providedDescending, setProvidedDescending] = useState<boolean>(false);
  const [createdDateDescending, setCreatedDateDescending] = useState<boolean>(false);
  const [assignedLernerDescending, setAssignedLernerDescending] = useState<boolean>(false);
  const [modifiedDateDescending, setModifiedDateDescending] = useState<boolean>(false);
  const [statusDescending, setStatusDescending] = useState<boolean>(false);
  const [modifiedByDescending, setModifiedByDescending] = useState<boolean>(false);
  const [exportError, setExportError] = useState<string>("")
  const [exporting, setExporting] = useState<boolean>(false)
  const [externalCertificatesAssignedUsers, setExternalCertificatesAssignedUsers] = useState<boolean>(false)
 


  const handleCertificateList = async(act: string) => {
    setshowUserAssignedCertificate(true);
    setCertificateListAction(act)
    setcurrentPage(act);  


     const result = await GetExternalCertificatesAssignedUsers(oidcUser.access_token, userId, certificateIdToEdit, orgDomain, parseInt(orgId), act);

       if (result.isSuccess && result.error == '') {
        
         if(result.externalCertificatesAssignedUsers.length>0)
            {
              setExternalCertificatesAssignedUsers(true);  
              setGuideMe(true,certificateHasNoDueDates, act==="notprovidedcertificates" ? false : true,showbuttons);        
            }
            else
            {
              setExternalCertificatesAssignedUsers(false);  
              setGuideMe(false,certificateHasNoDueDates,act==="notprovidedcertificates" ? false : true,showbuttons);        
            }
       }     
  }

  const handleViewProperties = (externalcertificateId: number, certificateHasNoDueDates: boolean) => {
    setcurrentPage("");    
    setCertificateIdToEdit(externalcertificateId);
    setCertificateHasNoDueDates(certificateHasNoDueDates);  
    setshowUserAssignedCertificate(false);
    setshowbuttons(true);
    setGuideMe(false,certificateHasNoDueDates, false,true);   

    //  let element = document.getElementById("certificateListsDatalist");
    //  if (!!element)
    //  {
    //    element.scrollIntoView();
    //  }

     let element = document.getElementById("certificateListsDatalist");
     if (!!element)
      {
        element.scrollIntoView();      
      }

  }
  
  const [intro, setIntro] = useState({
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: ".Addextcertificate",
        title: allResourcesToTranslations.addextcertificate, intro: allResourcesToTranslations.addextcertificateguideme

      }
    ]
  });

  const setGuideMe = (externalCertificatesAssignedUsers:boolean,certificateHasNoDueDates:boolean,notprovidedcertificates:boolean,setshowbuttons:boolean) => {
    let updateSteps = [];
    updateSteps.push(
      {
        element: ".Addextcertificate",
        title: allResourcesToTranslations.addextcertificate, intro: allResourcesToTranslations.addextcertificateguideme

      },
      {
        element: ".exportData",
        title: allResourcesToTranslations.certificatexportdatatitle, intro: allResourcesToTranslations.certificatexportdatainfo
      },
      {
        element: ".CertificateName",
        title: allResourcesToTranslations.certificatename, intro: allResourcesToTranslations.certificatelistgmtext
      });
      if(setshowbuttons)
      {  
        updateSteps.push( 
          {
            element: ".validcertificates",
            title: allResourcesToTranslations.providedcertificates, intro: allResourcesToTranslations.ecprovidedguideme
          },
          {
            element: ".notprovidedcertificates",
            title: allResourcesToTranslations.notprovidedcertificates, intro: allResourcesToTranslations.ecnotprovidedguideme
          });
      }
      if(!certificateHasNoDueDates)
      {
          updateSteps.push(
          {
            element: ".expiredcertificates",
            title: allResourcesToTranslations.expiredcertificates, intro: allResourcesToTranslations.eccloseguideme
          });
      }
        
     if(externalCertificatesAssignedUsers)      
      {
          if(notprovidedcertificates)
          {
            updateSteps.push(
            {
                element: ".chkselectfile",
                title: allResourcesToTranslations.downloadcertificates, intro: allResourcesToTranslations.fileselectiongm       
            },
            {
              element: ".downloadfile",
              title: allResourcesToTranslations.downloadcertificates, intro: allResourcesToTranslations.downloadcertificatesgm       
            });
          }
          updateSteps.push(            
          {
            element: ".ViewCertificate",
            title: allResourcesToTranslations.viewpdf, intro: allResourcesToTranslations.ecviedpdfgm       
          },
          {
            element: ".Action",
            title: allResourcesToTranslations.action, intro: allResourcesToTranslations.ecactiongm       
          },
          {
            element: ".LastModifiedDate",
            title: allResourcesToTranslations.lastmodifieddate, intro: allResourcesToTranslations.eclastmodifiedgm       
          }
          );
      }
      
    setIntro({ ...intro, steps: [...updateSteps] });

  };

  const onExit = () => {
    setIntro({ ...intro, stepsEnabled: false });
  };

  const handleAddNewCertificate = () => {
    history.push({
      pathname: `externalcertificatedetails/-1`
    });
  }



  const handleEdit = (externalcertificateId: number) => {
    history.push({
      pathname: `externalcertificatedetails/` + externalcertificateId.toString()
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const rowBackgroundColor = (index: number) => {
    if (enableRowBanding) {
      return { backgroundColor: index % 2 == 0 ? "#eee" : "#fff" }
    } else {
      return {}
    }
  }

  useEffect(() => {
    setTitle(allResourcesToTranslations.extcertificate);
    setTitleEnglish(pageTitles.extcertificate);
    updateBackButtonRoute("");
    setMenuSelection(allResourcesToTranslations.extcertificate);
    setLoading(true);
    GetExternalCertificates(oidcUser.access_token, userId, userId, orgDomain, parseInt(orgId), "").then((result: any) => {
      if (!!result) {
        if (result.isSuccess && result.error == '') {
          if (result.externalCertificates !== null) {
            setExternalCertificates([...result.externalCertificates]);
            setGuideMe(false,true,false,false);
          }
        }
        else {
          setShowWarningModal(true);
          setHasErrored(true);
          setSaveSuccess(false);
          setModalPopupMessage("Can not load External Certificate!");
        }
      }
      else {
        setShowWarningModal(true);
        setHasErrored(true);
        setSaveSuccess(false);
        setModalPopupMessage("Can not load External Certificate!");
      }
      setLoading(false);
    });
    return () => { };
  }, []);

  useEffect(() => {
    if (showWarningModal) {
      setTimeout(() => {
        setShowWarningModal(false);
        setSaveSuccess(false);
      }, 5000)
    }
  }, [showWarningModal])


  //sorting

  const sortResultsByAsync = async (
    sortBy: "CertificateName" | "Provided" | "AssignedLerner" | "CreatedDate" | "ModifiedDate" | "ModifiedByUserName" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    if (externalCertificates == null || externalCertificates.length <= 0) {
      return externalCertificates;
    }
    var result = externalCertificates.slice(0);
    setCurrentSortBy(sortBy);
    switch (sortBy) {

      case "CertificateName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return -1;
            }
            if (a === null || a.certificateName === null) {
              return 1;
            }
            if (a.certificateName.toLowerCase() > b.certificateName.toLowerCase()) {
              return -1;
            }
            if (a.certificateName.toLowerCase() < b.certificateName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.certificateName === null) {
              return 1;
            }
            if (a === null || a.certificateName === null) {
              return -1;
            }
            if (a.certificateName.toLowerCase() < b.certificateName.toLowerCase()) {
              return -1;
            }
            if (a.certificateName.toLowerCase() > b.certificateName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setCertificateNameDescending(true);
          }
        }
        setExternalCertificates(result);
        break;
      case "Status":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.active === null) {
              return -1;
            }
            if (a === null || a.active === null) {
              return 1;
            }
            if (a.active > b.active) {
              return -1;
            }
            if (a.active < b.active) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.active === null) {
              return 1;
            }
            if (a === null || a.active === null) {
              return -1;
            }
            if (a.active < b.active) {
              return -1;
            }
            if (a.active > b.active) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setStatusDescending(true);
          }
        }
        setExternalCertificates(result);
        break;
      case "CreatedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.createdDate === null || b.createdDate.trim() === "") { return -1; }
            if (a === null || a.createdDate === null || a.createdDate.trim() === "") { return 1; }
            var dta = new Date(a.createdDate.split("/").reverse().join("-"))
            var dtb = new Date(b.createdDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setCreatedDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.createdDate === null || b.createdDate.trim() === "") { return 1; }
            if (a === null || a.createdDate === null || a.createdDate.trim() === "") { return -1; }
            var dta = new Date(a.createdDate.split("/").reverse().join("-"))
            var dtb = new Date(b.createdDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setCreatedDateDescending(true)
          }
        }
        setExternalCertificates(result);
        break;
      case "ModifiedDate":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.updatedDate === null || b.updatedDate.trim() === "") { return -1; }
            if (a === null || a.updatedDate === null || a.updatedDate.trim() === "") { return 1; }
            var dta = new Date(a.updatedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.updatedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return -1; }
            if (dta < dtb) { return 1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDateDescending(false)
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.updatedDate === null || b.updatedDate.trim() === "") { return 1; }
            if (a === null || a.updatedDate === null || a.updatedDate.trim() === "") { return -1; }
            var dta = new Date(a.updatedDate.split("/").reverse().join("-"))
            var dtb = new Date(b.updatedDate.split("/").reverse().join("-"))
            if (dta > dtb) { return 1; }
            if (dta < dtb) { return -1; }
            return 0;
          })
          if (reverseNextSort) {
            setModifiedDateDescending(true)
          }
        }
        setExternalCertificates(result);
        break;
      case "ModifiedByUserName":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.modifiedByUserName === null) {
              return -1;
            }
            if (a === null || a.modifiedByName === null) {
              return 1;
            }
            if (a.modifiedByName.toLowerCase() > b.modifiedByName.toLowerCase()) {
              return -1;
            }
            if (a.modifiedByName.toLowerCase() < b.modifiedByName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModifiedByDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.modifiedByName === null) {
              return 1;
            }
            if (a === null || a.modifiedByName === null) {
              return -1;
            }
            if (a.modifiedByName.toLowerCase() < b.modifiedByName.toLowerCase()) {
              return -1;
            }
            if (a.modifiedByName.toLowerCase() > b.modifiedByName.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setModifiedByDescending(true);
          }
        }
        setExternalCertificates(result);
        break;
      case "Provided":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.percentageProvided === null) {
              return -1;
            }
            if (a === null || a.percentageProvided === null) {
              return 1;
            }
            if (a.percentageProvided > b.percentageProvided) {
              return -1;
            }
            if (a.percentageProvided < b.percentageProvided) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setProvidedDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.percentageProvided === null) {
              return 1;
            }
            if (a === null || a.percentageProvided === null) {
              return -1;
            }
            if (a.percentageProvided < b.percentageProvided) {
              return -1;
            }
            if (a.percentageProvided > b.percentageProvided) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setProvidedDescending(true);
          }
        }
        setExternalCertificates(result);
        break;
      case "AssignedLerner":
        if (sortGivenItemInDesc) {
          result.sort((a, b) => {
            if (b === null || b.assignLerners === null) {
              return -1;
            }
            if (a === null || a.assignLerners === null) {
              return 1;
            }
            if (a.assignLerners > b.assignLerners) {
              return -1;
            }
            if (a.assignLerners < b.assignLerners) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setProvidedDescending(false);
          }
        } else {
          result.sort((a, b) => {
            if (b === null || b.assignLerners === null) {
              return 1;
            }
            if (a === null || a.assignLerners === null) {
              return -1;
            }
            if (a.assignLerners < b.assignLerners) {
              return -1;
            }
            if (a.assignLerners > b.assignLerners) {
              return 1;
            }
            return 0;
          });
          if (reverseNextSort) {
            setProvidedDescending(true);
          }
        }
        setExternalCertificates(result);
        break;
      default:
        break;
    }
    return result.slice(0);
  };

  const sortResultsBy = async (
    sortBy: "CertificateName" | "Provided" | "AssignedLerner" | "CreatedDate" | "ModifiedDate" | "ModifiedByUserName" | "Status",
    sortGivenItemInDesc: boolean
  ) => {
    await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
      setCurrentSortBy(sortBy);
      setExternalCertificates(result);
      setPage(0);
    });
  };


  const clickCertificateNameAsc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(false);
    sortResultsBy("CertificateName", false);
  };

  const clickCertificateNameDesc = () => {
    setReverseNextSort(false);
    setCertificateNameDescending(true);
    sortResultsBy("CertificateName", true);
  };


  const clickAssignedLernerAsc = () => {
    setReverseNextSort(false);
    setAssignedLernerDescending(false);
    sortResultsBy("AssignedLerner", false);
  };

  const clickAssignedLernerDesc = () => {
    setReverseNextSort(false);
    setAssignedLernerDescending(true);
    sortResultsBy("AssignedLerner", true);
  };


  const clickProvidedAsc = () => {
    setReverseNextSort(false);
    setProvidedDescending(false);
    sortResultsBy("Provided", false);
  };

  const clickProvidedDesc = () => {
    setReverseNextSort(false);
    setProvidedDescending(true);
    sortResultsBy("Provided", true);
  };



  const clickCreatedDateAsc = () => {
    setReverseNextSort(false);
    setCreatedDateDescending(false);
    sortResultsBy("CreatedDate", false);
  };

  const clickCreatedDateDesc = () => {
    setReverseNextSort(false);
    setCreatedDateDescending(true);
    sortResultsBy("CreatedDate", true);
  };

  const clickStatusAsc = () => {
    setReverseNextSort(false);
    setStatusDescending(false);
    sortResultsBy("Status", false);
  };

  const clickStatusDesc = () => {
    setReverseNextSort(false);
    setStatusDescending(true);
    sortResultsBy("Status", true);
  };

  const clickModifiedDateAsc = () => {
    setReverseNextSort(false);
    setModifiedDateDescending(false);
    sortResultsBy("ModifiedDate", false);
  };

  const clickModifiedDateDesc = () => {
    setReverseNextSort(false);
    setModifiedDateDescending(true);
    sortResultsBy("ModifiedDate", true);
  };



  const clickModifiedByAsc = () => {
    setReverseNextSort(false);
    setModifiedByDescending(false);
    sortResultsBy("ModifiedByUserName", false);
  };

  const clickModifiedByDesc = () => {
    setReverseNextSort(false);
    setModifiedByDescending(true);
    sortResultsBy("ModifiedByUserName", true);
  };


  const GetCertificateDetails = async () => {
    let resultArray: Array<string> = []
    const result = await GetFullExternalCertificateAdminReport(oidcUser.access_token, userId, userId, orgDomain, parseInt(orgId))
    if (!!result) {
      if (result.isSuccess) {       
        if (result.externalCertificateAdminReportItems.length)
          for (let i = 0; i < result.externalCertificateAdminReportItems.length; i++) {
            const r = result.externalCertificateAdminReportItems[i]
            resultArray.push(`${r.certificateName}<#>${r.assignLerners}<#>${r.provided}<#>${r.createdDate}<#>${r.createdBy}<#>${r.modifiedDate}<#>${r.modifiedBy}<#>${r.active}<#>${r.firstName}<#>${r.lastName}<#>${r.email}<#>${r.validFromDate}<#>${r.validToDate}<#>${r.userCertificateModifiedDate}<#>${r.userCertificateModifiedBy}<#>${r.fileName}<#>${r.certificateStatus}<#>${r.certificateType}`)
          }
      }
    }
    return resultArray
  }
  
  const GetDate_yymmdd = () => {
    var d =  new Date(Date.now());
    let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
    return day;   
  }

  const handleXLSXExport = async () => {
    setMessage(allResourcesToTranslations.sntemrepgmmxlsm);
    setExportError("");
    // Activity log
 
    addError(oidcUser.access_token, userId, orgDomain, "Started External Certificates Report XLSX-EXPORT in NEWSALT ClientApp", null, "ExternalCertificates.tsx", 6, EnableActivityLogs);
    setExporting(true);
    getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), await GetCertificateDetails(), 18, "ExternalCertificates", enableRowBanding, "", "", "", "", false, false, "", "", "", "", "", "", "", false, false).then((xlxsRes) => {
      if (xlxsRes.isSuccess) {
        const fileData = xlxsRes.xlsxDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        );

        a.download = "ExternalCertificates-" + GetDate_yymmdd() + ".xlsx";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
        setExportError(xlxsRes.error);
      }
      setExporting(false);
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed External Certificates Report XLSX-EXPORT in NEWSALT ClientApp", null, "ExternalCertificates.tsx", 6, EnableActivityLogs);
    })
  }
  const handleCSVExport =async () => {
    setMessage("Exporting CSV");
    setExportError("");
    // Activity log
    addError(oidcUser.access_token, userId, orgDomain, "Started External Certificates Report CSV-EXPORT in NEWSALT ClientApp", null, "ExternalCertificates.tsx", 6, EnableActivityLogs);
    setExporting(true);
    getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), await GetCertificateDetails(), 18, "ExternalCertificates", "", "", "", "", false, false, "", false, "", "", "", "", "", "", false, false).then((csvRes) => {
      if (csvRes.isSuccess) {
        const fileData = csvRes.csvDataOutput.fileData
        var a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "ExternalCertificates-" + GetDate_yymmdd() + ".csv";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)

      }
      else if (csvRes.error !== undefined && csvRes.error !== null) {
        setExportError(csvRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed ExternalCertificates Report CSV-EXPORT in NEWSALT ClientApp", null, "ExternalCertificates.tsx", 6, EnableActivityLogs);
    })
  };

  const handlePDFXExport =  async () => {
    setMessage("Exporting PDF");
    setExportError("");
    // Activity log
    addError(oidcUser.access_token, userId, orgDomain, "Started ExternalCertificates Report PDF-EXPORT in NEWSALT ClientApp", null, "ExternalCertificates.tsx", 6, EnableActivityLogs);
    setExporting(true);
    getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), await GetCertificateDetails(), 18, "ExternalCertificates", enableRowBanding, "", "", "", "", false, false, "", "", "", "", "", "", "", false, false).then((pdfRes) => {
      if (pdfRes.isSuccess) {
        const fileData = pdfRes.pdfDataOutput.fileData
        var a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(
          new Blob([serialToArray(atob(fileData))], {
            type: ''
          })
        )
        a.download = "ExternalCertificates-" + GetDate_yymmdd() + ".PDF";
        document.body.appendChild(a)
        a.click();
        document.body.removeChild(a)
      }
      else if (pdfRes.error !== undefined && pdfRes.error !== null) {
        setExportError(pdfRes.error);
      }
      setExporting(false)
      // Activity log
      addError(oidcUser.access_token, userId, orgDomain, "Completed ExternalCertificates Report PDF-EXPORT in NEWSALT ClientApp", null, "ExternalCertificates.tsx", 6, EnableActivityLogs);
    })
  };

  if (loading) return <LoaderContainer />;
  return (
    <>
      <Steps
        enabled={intro.stepsEnabled}
        steps={intro.steps}
        initialStep={intro.initialStep}
        onExit={onExit}
        options={{
          nextLabel: "Next",
          prevLabel: "Back",
          tooltipClass: "sample-class",
          showBullets: true,
        }} />
      <CustomTooltip displayText={["Guide Me"]}>
        <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
          className={styles.guideMeButtonSpecific} style={{ right: '35px', top: '100px' }}>
          <GuideIcon />
        </span>
      </CustomTooltip>
      <UnbrandedCard>
        <div className={styles.mainWrapper}>
          <div className={styles.Wrapper}>
            <div style={{ width: '100%', clear: "both", display: "flex" }}  >
              <div className="Addextcertificate" style={{ width: "50%" }}>
                <CustomTooltip displayText={[allResourcesToTranslations.addextcertificate]}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={`saveData addNewPolicy`}
                    onClick={handleAddNewCertificate}
                  >
                    <div style={{ marginBottom: "-5px" }}>
                      <AddCircleOutlineRounded />
                    </div><span style={{ marginLeft: "5px" }}> {allResourcesToTranslations.addextcertificate}</span>
                  </Button>
                </CustomTooltip>
              </div>
              <div className={styles.reportExportContainer} style={{ width: "50%", textAlign: "right" }}>
                <div className="exportData">
                <CustomTooltip displayText={[allResourcesToTranslations.exporttoxlsx]}>
                  <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCSRReportExportXLSX">
                    <ExcelFileIcon />

                  </Button>
                </CustomTooltip>
                <CustomTooltip displayText={[allResourcesToTranslations.exporttocsv]}>
                  <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCSRReportExportCSV">
                    <CSVFileIcon />

                  </Button>
                </CustomTooltip>
                <CustomTooltip displayText={[allResourcesToTranslations.exporttopdf] /*TODO: Add translations */}>
                  <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCSRReportExportPDF">
                    <PDFIcon />

                  </Button>
                </CustomTooltip>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer} style={{ width: '100%', clear: "both", paddingTop: "15px", color: "#21394F", fontWeight: "bold" }} >
              <span>{allResourcesToTranslations.selectcertitiviewusers}</span>
            </div>
            <div style={{ width: '100%', clear: "both" }} >
              {externalCertificates != null && externalCertificates.length > 0 && (
                <div>
                  {/* <div className='allowLearner'>
                      <div onClick={() => setShowExpiredCertificate(!showExpiredCertificate)}>
                        {showExpiredCertificate &&
                          <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleRightIcon /></span>
                        }
                        {!showExpiredCertificate &&
                          <span style={{ marginTop: "-3px", paddingRight: "20px", float: "left" }} ><ToggleLeftIcon /></span>
                        }
                      </div>
                      <div className={styles.inputContainer}>
                        <span id="allowLearnerswitch" className={styles.labelText} onClick={() => setShowExpiredCertificate(!showExpiredCertificate)}>
                          {allResourcesToTranslations.allowlearnertoedit}</span>
                      </div>
                    </div> */}
                  <div className={styles.paginationControlsContainer}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={externalCertificates.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                  <div className={`${styles.tableSection} CertificateName`}>
                    <div className={styles.tableContainer}>
                      <BrandedCard>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0" }}
                                  align="left">
                                  <div className={styles.tableHeadCellContainer} style={{ display: "flex", }}>
                                    <div
                                      onClick={() => {
                                        setCertificateNameDescending(!certificateNameDescending);
                                        sortResultsBy("CertificateName", !certificateNameDescending);
                                      }}
                                    >
                                      {allResourcesToTranslations.certificatename}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "CertificateName" &&
                                            certificateNameDescending === false
                                            ? "100%"
                                            : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCertificateNameAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "CertificateName" &&
                                            certificateNameDescending === true
                                            ? "100%"
                                            : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCertificateNameDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                  align="center">
                                  <div className={styles.tableHeadCellContainer}>
                                    <div
                                      onClick={() => {
                                        setAssignedLernerDescending(!assignedLernerDescending);
                                        sortResultsBy("AssignedLerner", !assignedLernerDescending);
                                      }}
                                    >
                                      {allResourcesToTranslations.assignedlearners}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "AssignedLerner" &&
                                            assignedLernerDescending === false
                                            ? "100%"
                                            : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickAssignedLernerAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "AssignedLerner" &&
                                            assignedLernerDescending === true
                                            ? "100%"
                                            : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickAssignedLernerDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                  align="center">
                                  <div className={styles.tableHeadCellContainer}>
                                    <div
                                      onClick={() => {
                                        setProvidedDescending(!providedDescending);
                                        sortResultsBy("Provided", !providedDescending);
                                      }}
                                    >
                                      {allResourcesToTranslations.provided}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "Provided" &&
                                            providedDescending === false
                                            ? "100%"
                                            : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickProvidedAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "Provided" &&
                                            providedDescending === true
                                            ? "100%"
                                            : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickProvidedDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                  align="left">
                                  <div className={styles.tableHeadCellContainer} >
                                    <div
                                      onClick={() => {
                                        setCreatedDateDescending(!createdDateDescending);
                                        sortResultsBy("CreatedDate", !createdDateDescending);
                                      }}
                                    >
                                      {allResourcesToTranslations.createddate}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "CreatedDate" &&
                                            createdDateDescending === false
                                            ? "100%"
                                            : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCreatedDateAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "CreatedDate" &&
                                            createdDateDescending === true
                                            ? "100%"
                                            : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickCreatedDateDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                  align="left">
                                  <div className={styles.tableHeadCellContainer}>

                                    <div
                                      onClick={() => {
                                        setModifiedDateDescending(!modifiedDateDescending);
                                        sortResultsBy("ModifiedDate", !modifiedDateDescending);
                                      }}
                                    >
                                      {allResourcesToTranslations.lastmodifieddate}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "ModifiedDate" &&
                                            modifiedDateDescending === false
                                            ? "100%"
                                            : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickModifiedDateAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "ModifiedDate" &&
                                            modifiedDateDescending === true
                                            ? "100%"
                                            : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickModifiedDateDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>

                                  </div>
                                </TableCell>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                  align="left">
                                  <div className={styles.tableHeadCellContainer} >

                                    <div
                                      onClick={() => {
                                        setModifiedByDescending(!modifiedByDescending);
                                        sortResultsBy("ModifiedByUserName", !modifiedByDescending);
                                      }}
                                    >
                                      {allResourcesToTranslations.modifiedby}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "ModifiedByUserName" &&
                                            modifiedByDescending === false
                                            ? "100%"
                                            : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickModifiedByAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "ModifiedByUserName" &&
                                            modifiedByDescending === true
                                            ? "100%"
                                            : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickModifiedByDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color }}
                                  align="left">
                                  <div className={styles.tableHeadCellContainer}>

                                    <div
                                      onClick={() => {
                                        setStatusDescending(!statusDescending);
                                        sortResultsBy("Status", !statusDescending);
                                      }}
                                    >
                                      {allResourcesToTranslations.status}
                                    </div>
                                    <div className={styles.columnHeadArea}>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "Status" &&
                                            statusDescending === false
                                            ? "100%"
                                            : "50%",
                                          margin: "2px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickStatusAsc}
                                      >
                                        <UpArrowIcon />{" "}
                                      </span>
                                      <span
                                        style={{
                                          opacity: currentSortBy === "Status" &&
                                            statusDescending === true
                                            ? "100%"
                                            : "50%",
                                          margin: "0px",
                                          height: "6px",
                                          display: "inline-flex",
                                        }}
                                        onClick={clickStatusDesc}
                                      >
                                        <DownArrowIcon />{" "}
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell 
                                  style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }}
                                  align="left">
                                  <div className={styles.tableHeadCellContainer}>
                                    <div >
                                      {allResourcesToTranslations.actions}
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {externalCertificates.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                                .map((result: any, i) => {
                                  return (
                                    <CustomTooltip displayText={[allResourcesToTranslations.selectcertitiviewusers]}>
                                     
                                      <TableRow  style={certificateIdToEdit === result.externalCertificateId ? { backgroundColor: "#F0F9FF" } : rowBackgroundColor(i)} onClick={() => handleViewProperties(result.externalCertificateId, result.hasNoValidDates)}   className={styles.highlightrowhover}>
                                      <TableCell align="left" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <span className={result.caption}>{result.certificateName}</span>
                                      </TableCell>
                                      <TableCell align="center" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <span className={result.caption}>{result.assignLerners}</span>
                                      </TableCell>
                                      <TableCell align="center" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <span className={result.caption}>{result.percentageProvided}</span>
                                      </TableCell>
                                      <TableCell align="left" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <span className={result.caption}>{result.createdDate !== null ? result.createdDate : ""}</span>
                                      </TableCell>
                                      <TableCell align="left" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <span className={result.caption}>{result.updatedDate !== null ? result.updatedDate : ""}</span>
                                      </TableCell>
                                      <TableCell align="left" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <span className={result.caption}>{result.modifiedByName}</span>
                                      </TableCell>
                                      <TableCell align="left" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <span className={result.caption}>{result.active === true ? "Active" : "Inactive"}</span>
                                      </TableCell>
                                      <TableCell align="left" style={certificateIdToEdit === result.externalCertificateId ? { borderBottom: "solid 1px #63c2ff" } : {}} >
                                        <CustomTooltip displayText={["Edit"]}>
                                          <Button onClick={() => handleEdit(result.externalCertificateId)} className={`${styles.moduleListButton} actioncolumnguideme${i}`} variant="contained" color="primary">
                                            <EditIcon />
                                          </Button>
                                        </CustomTooltip>
                                      </TableCell>
                                    </TableRow>
                                    
                                    </CustomTooltip>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </BrandedCard>
                    </div>
                  </div>
                  <div className={styles.paginationAddedUsersControl}>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={externalCertificates.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </UnbrandedCard>
          {(certificateIdToEdit > 0) && (
            <div style={{ textAlign: "center", paddingTop: "20px" }} >
              <CustomTooltip displayText={[allResourcesToTranslations.providedcertificates]}>
                <span className="validcertificates">
                  <Button
                    onClick={() => handleCertificateList('providedcertificates')}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    id="btnNewSaltvalidcertificates"
                    disabled={currentPage == 'providedcertificates'}
                  >
                    <ProvidedIcon />
                    <span style={{ paddingLeft: '7px' }}>{allResourcesToTranslations.providedcertificates}</span>
                  </Button>
                </span>
              </CustomTooltip>
              <CustomTooltip displayText={[allResourcesToTranslations.notprovidedcertificates]}>
                <span className="notprovidedcertificates">
                  <Button
                    onClick={() => handleCertificateList('notprovidedcertificates')}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    id="btnnotprovidedcertificates"
                    disabled={currentPage == 'notprovidedcertificates'}
                  >
                    <NotProvidedIcon />
                    <span style={{ paddingLeft: '7px' }}>{allResourcesToTranslations.notprovidedcertificates}</span>
                  </Button>
                </span>
              </CustomTooltip>
              {!certificateHasNoDueDates &&
              <CustomTooltip displayText={[allResourcesToTranslations.expiredcertificates]}>
                <span className="expiredcertificates">
                  <Button
                    onClick={() => handleCertificateList('expiredcertificates')}
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    id="btnexpiredcertificates"
                    disabled={currentPage == 'expiredcertificates'}
                  ><NotProvidedIcon />

                    <span style={{ paddingLeft: '7px' }}>{allResourcesToTranslations.expiredcertificates}</span>
                  </Button>
                </span>
              </CustomTooltip>
              }
            </div>

          )}
          
         {(certificateIdToEdit > 0 && showUserAssignedCertificate) &&
              <div id="assign" style={{ width: '100%', clear: "both", paddingTop: "20px" }}>
                <CertificateLists isCalledFrom={certificateListAction} certificateIdToEdit={certificateIdToEdit} certificateHasNoDueDates={certificateHasNoDueDates}></CertificateLists>
              </div>
          }
          
          <div style={{ textAlign: "center", paddingTop: "60px" }} >
              <div id="certificateListsDatalist">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </div>
    </>
  );
};
export default ExternalCertificates;
