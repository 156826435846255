import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const StepFiveInactive = () => (    
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
    <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#21394F"/>
    <path d="M20.4961 17.8789C21.3685 17.8789 22.1432 18.0449 22.8203 18.377C23.5039 18.709 24.041 19.1973 24.4316 19.8418C24.8223 20.4863 25.0176 21.2806 25.0176 22.2246C25.0176 23.2533 24.8027 24.1419 24.373 24.8906C23.9499 25.6328 23.3151 26.2025 22.4688 26.5996C21.6289 26.9967 20.5807 27.1953 19.3242 27.1953C18.5755 27.1953 17.8691 27.1302 17.2051 27C16.5475 26.8763 15.9714 26.6842 15.4766 26.4238V23.8164C15.9714 24.0768 16.5638 24.2982 17.2539 24.4805C17.9505 24.6562 18.6048 24.7441 19.2168 24.7441C19.8158 24.7441 20.3203 24.666 20.7305 24.5098C21.1406 24.347 21.4499 24.0996 21.6582 23.7676C21.873 23.429 21.9805 22.9993 21.9805 22.4785C21.9805 21.7819 21.7461 21.248 21.2773 20.877C20.8086 20.5059 20.0892 20.3203 19.1191 20.3203C18.748 20.3203 18.3607 20.3561 17.957 20.4277C17.5599 20.4993 17.2279 20.571 16.9609 20.6426L15.7598 19.998L16.2969 12.7227H24.041V15.2812H18.9434L18.6797 18.084C18.901 18.0384 19.1419 17.9928 19.4023 17.9473C19.6628 17.9017 20.0273 17.8789 20.4961 17.8789Z" fill="#21394F"/>
    </svg>
    
);

export default StepFiveInactive