import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const NewsfeedIcon = () => (
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.298 28.264h22.841v1.756H12.298zm22.841 -5.271H12.298v1.756h22.841zm0 -10.542h-8.785v1.756h8.785zm0 5.271h-8.785v1.756h8.785zM5.271 3.667v30.217c0 1.357 -1.756 1.357 -1.756 0V7.181H0V35.255c0 1.958 1.589 3.549 3.549 3.549h35.064c1.962 0 3.553 -1.589 3.553 -3.549V3.667zm33.381 29.869H8.785V7.181h29.867zm-15.813 -21.083h-10.542v7.027h10.542z" fill="#fff"/></svg>

   
);

export default NewsfeedIcon
