import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const APILibrarySRIcon = () => (
<svg width="83" height="87" viewBox="0 0 83 87" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M55.3334 15.5625H62.25C64.0843 15.5625 65.8436 16.2912 67.1408 17.5884C68.438 18.8855 69.1667 20.6448 69.1667 22.4792V70.8958C69.1667 72.7301 68.438 74.4894 67.1408 75.7866C65.8436 77.0838 64.0843 77.8125 62.25 77.8125H20.75C18.9156 77.8125 17.1563 77.0838 15.8592 75.7866C14.5621 74.4894 13.8334 72.7301 13.8334 70.8958V22.4792C13.8334 20.6448 14.5621 18.8855 15.8592 17.5884C17.1563 16.2912 18.9156 15.5625 20.75 15.5625H27.6667" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M51.875 8.64581H31.125C29.215 8.64581 27.6666 10.1942 27.6666 12.1041V19.0208C27.6666 20.9308 29.215 22.4791 31.125 22.4791H51.875C53.785 22.4791 55.3333 20.9308 55.3333 19.0208V12.1041C55.3333 10.1942 53.785 8.64581 51.875 8.64581Z" stroke="#21394F" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default APILibrarySRIcon
