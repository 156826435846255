//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { CurrentAdminReportResultItem } from '../../globalTypes';
import { useHistory } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps, Hints } from "intro.js-react";
//Context
import { LoaderContext } from '../../contexts/LoaderContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../Components/BrandedCard';
import LoaderContainer from '../../Components/LoaderContainer';
import CustomTooltip from '../../Components/CustomTooltip'
//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
import { get2DigitDayOrMonth } from '../../functions/get2DigitDayOrMonth'
//API functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'
import { addError } from '../../API/AddError'
//Components
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon';
import CSVFileIcon from '../../CustomIcons/CSVFileIcon';
import PDFIcon from '../../CustomIcons/PDFFileIcon';
import UpArrowIcon from '../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import BrushIcon from '@material-ui/icons/Brush';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

// Style
import styles from '../../styles/currentAdminReport.module.css'

const CurrentAdminReportResult = (props: any) => {
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, family_name, given_name, website  }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const { theme: { color }} = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    const [adminReportResult, setAdminReportResult] = useState<Array<any>>(props?.location?.state?.resultItems ?? [])
    const [inputFilterFirstName, setInputFilterFirstName] = useState<string>(props?.location?.state?.inputFilterFirstName ?? "")
    const [inputFilterLastName, setInputFilterLastName] = useState<string>(props?.location?.state?.inputFilterLastName ?? "")
    const [inputFilterUserName, setInputFilterUserName] = useState<string>(props?.location?.state?.inputFilterUserName ?? "")
    const [inputFilterEmail, setInputFilterEmail] = useState<string>(props?.location?.state?.inputFilterEmail ?? "")
    const [inputFilterIncludeInactive, setInputFilterIncludeInactive] = useState<string>(props?.location?.state?.inputFilterIncludeInactive ?? "")
    const [filterCourseStatus, setFilterCourseStatus] = useState<any>(props?.location?.state?.filterCourseStatus ?? "")
    const [exporting, setExporting] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [firstNameDescending, setFirstNameDescending] = useState<boolean>(true)
    const [lastNameDescending, setLastNameDescending] = useState<boolean>(true)
    const [courseDescending, setCourseDescending] = useState<boolean>(true)
    const [unitDescending, setUnitDescending] = useState<boolean>(true)
    const [emailDescending, setEmailDescending] = useState<boolean>(true)
    const [externalIDDescending, setExternalIDDescending] = useState<boolean>(true)
    const [userStatusDescending, setUserStatusDescending] = useState<boolean>(true)
    const [statusDateDescending, setStatusDateDescending] = useState<boolean>(true)
    const [completionDateDescending, setCompletionDateDescending] = useState<boolean>(true)
    const [userNameDescending, setUserNameDescending] = useState<boolean>(true)
    const [completionReasonDescending, setCompletionReasonDescending] = useState<boolean>(true)
    const [dateCreatedDescending, setDateCreatedDescending] = useState<boolean>(true)
    const [dateArchivedDescending, setDateArchivedDescending] = useState<boolean>(true)
    const [dueDateDescending, setDueDateDescending] = useState<boolean>(false)  
    const [moduleNameDescending, setModuleNameDescending] = useState<boolean>(true)
    const [quizStatusDescending, setQuizStatusDescending] = useState<boolean>(true)
    const [quizStatusDateDescending, setQuizStatusDateDescending] = useState<boolean>(true)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const { setTitle, setTitleEnglish, updateBackButtonRoute, EnableActivityLogs } = useContext(UIContext);
    const [exportError, setExportError] = useState<string>("")
    const [resultError, setResultError] =  useState<string>(props?.location?.state?.resultError ?? "")

    const [isLengthyCoursePresent, setIsLengthyCoursePresent] = useState<boolean>(false)

   // const toggleBanding = () => setEnableRowBanding(!enableRowBanding)
    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".courseStatusReportList",
            title: "Report Data", intro: allResourcesToTranslations.emrepprvgmmrepdta        
          },      
        ]
      })

      useEffect(() => {
        if (!!adminReportResult && adminReportResult.length > 0) {
            setIsLengthyCoursePresent(adminReportResult.some((item) => !!item.courseName && item.courseName.length > 100))
        }
      }, [adminReportResult]);

      useEffect(() => 
      {
                    let updateSteps = [...intro.steps]
                    updateSteps.push( 
                    {
                    element: ".xlxs",
                    title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpxls
                    },
                    {
                    element: ".csv",
                    title: "Export", intro:allResourcesToTranslations.sntemrepgmmexpcsv
                    },
                    {
                        element: ".pdf",
                        title: "Export", intro:allResourcesToTranslations.sntemrepgmmexppdf
                    }
                    // {
                    //     element: ".rowShading",
                    //     intro: "Click icon to Show/Hide Row Shading, also applies to Export XLSX file."
                    // }               
         )      
      
                setIntro({...intro, steps: [...updateSteps]})              
      },[])
      
      const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
      };

    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : ""}
        } else {
            return {}
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    
    const GetDate_yymmdd = () => {
        var d =  new Date(Date.now());
        let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
        return day;   
    }
    
    const buildRequestStringArray = () => {
        let resultArray : Array<string> = []
        const r = adminReportResult[0]      
        let IsEnabledateArchived  = r.isEnabledateArchived ;
        let IsNotCompletedStatus  = (r.courseStatus.toString().toLowerCase().trim() === "not started");
        for (let i = 0; i < adminReportResult.length; i++) {
            const r = adminReportResult[i]
            if(IsEnabledateArchived)
            {
                if (parseInt(filterCourseStatus.toString()) === 6)
                {
                    resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.userStatus}<#>${r.courseName}<#>${r.courseStatusDate}<#>${r.moduleName}<#>${r.quizStatus}<#>${r.quizStatusDate}<#>${r.dateCreated}<#>${r.dateArchived}`)
                }
                else
                {
                    resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.userStatus}<#>${r.courseName}<#>${r.courseStatusDate}<#>${r.dateCreated}<#>${r.dateArchived}`)
                }
            }
            else
            {
                if (parseInt(filterCourseStatus.toString()) === 6)
                {
                    resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.userStatus}<#>${r.courseName}<#>${r.courseStatusDate}<#>${r.moduleName}<#>${r.quizStatus}<#>${r.quizStatusDate}<#>${r.dateCreated}`)
                }
                else
                {
                    if (IsNotCompletedStatus) 
                    {
                        resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.userStatus}<#>${r.courseName}<#>${r.courseStatusDate}<#>${r.dateCreated}<#>${r.dueDateTxt}`)
                    }
                    else {
                        resultArray.push(`${r.unitPathway}<#>${r.lastName}<#>${r.firstName}<#>${r.email}<#>${r.externalID}<#>${r.userStatus}<#>${r.courseName}<#>${r.courseStatusDate}<#>${r.dateCreated}`)
                    }
                }
            }
        }
        return resultArray
    }

    const handleXLSXExport = () => {
        setMessage("Exporting XLSX");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CourseStatus Report XLSX-EXPORT in NEWSALT ClientApp", null, "CourseStatusReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = adminReportResult[0]
        let ClassificationValue  = r.classificationValue ;
        let CourseStatus  = parseInt(filterCourseStatus.toString()) === 6 ? "Incomplete (With Details)" : r.courseStatus ;
        let DateEffectiveFrom  = r.dateEffectiveFrom ;
        let DateEffectiveTo  = r.dateEffectiveTo ;
        let IsEnabledateArchived  = r.isEnabledateArchived;
        let numFilterCourseStatus = parseInt(filterCourseStatus.toString());
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(),  IsEnabledateArchived ? (
                                            numFilterCourseStatus === 6 ? 13 : 
                                            (numFilterCourseStatus === 3 ? 11 : 10)) : (numFilterCourseStatus === 6 ? 12 : (numFilterCourseStatus === 3 ? 10 : 9)), "coursestatusreport", enableRowBanding, ClassificationValue,CourseStatus,
                            DateEffectiveFrom,DateEffectiveTo, inputFilterIncludeInactive === "Yes", inputFilterIncludeInactive === "Yes", "", inputFilterFirstName, inputFilterLastName, inputFilterUserName, 
                            inputFilterEmail, "", "", false, false, 0, "", "", -1,-1, "", "", "", [], 0, numFilterCourseStatus === 6, false, false, false, false, false, numFilterCourseStatus === 3).then((xlxsRes) => {            
            if (xlxsRes.isSuccess) {
                const fileData = xlxsRes.xlsxDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "CourseStatusReport - " + ((CourseStatus !== null && CourseStatus !== undefined) ? ((CourseStatus.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")) + " - ") : "") + GetDate_yymmdd() + ".xlsx";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
                setExportError(xlxsRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed CourseStatus Report XLSX-EXPORT in NEWSALT ClientApp", null, "CourseStatusReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handleCSVExport = () => {
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CourseStatus Report CSV-EXPORT in NEWSALT ClientApp", null, "CourseStatusReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = adminReportResult[0]
        let ClassificationValue  = r.classificationValue ;
        let CourseStatus  = parseInt(filterCourseStatus.toString()) === 6 ? "Incomplete (With Details)" : r.courseStatus ;
        let DateEffectiveFrom  = r.dateEffectiveFrom ;
        let DateEffectiveTo  = r.dateEffectiveTo ;
        let IsEnabledateArchived  = r.isEnabledateArchived ;
        let numFilterCourseStatus = parseInt(filterCourseStatus.toString());
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), IsEnabledateArchived ? (numFilterCourseStatus === 6 ? 13 : numFilterCourseStatus == 3 ? 11 : 10) : (numFilterCourseStatus === 6 ? 12 : numFilterCourseStatus == 3 ? 10 : 9), "coursestatusreport", ClassificationValue,CourseStatus,
                            DateEffectiveFrom,DateEffectiveTo, inputFilterIncludeInactive === "Yes", inputFilterIncludeInactive === "Yes", "",false, inputFilterFirstName, inputFilterLastName,
                            inputFilterUserName, inputFilterEmail, "", "", false, false, 0, "", "", -1,-1, "", "", "", [], 0, numFilterCourseStatus === 6, false, false, false, false, false, numFilterCourseStatus === 3).then((csvRes) => {            
            if (csvRes.isSuccess) {
                const fileData = csvRes.csvDataOutput.fileData
                var a = window.document.createElement('a');
                
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "CourseStatusReport - " + ((CourseStatus !== null && CourseStatus !== undefined) ? ((CourseStatus.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")) + " - ") : "") +  GetDate_yymmdd() + ".csv";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)

            }
            else if (csvRes.error !== undefined && csvRes.error !== null) {
                setExportError(csvRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed CourseStatus Report CSV-EXPORT in NEWSALT ClientApp", null, "CourseStatusReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const handlePDFXExport = () => {
        setMessage("Exporting PDF");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CourseStatus Report PDF-EXPORT in NEWSALT ClientApp", null, "CourseStatusReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = adminReportResult[0]
        let ClassificationValue  = r.classificationValue ;
        let CourseStatus  = parseInt(filterCourseStatus.toString()) === 6 ? "Incomplete (With Details)" : r.courseStatus ;
        let DateEffectiveFrom  = r.dateEffectiveFrom ;
        let DateEffectiveTo  = r.dateEffectiveTo ;
        let IsEnabledateArchived  = r.isEnabledateArchived ;        
        let numFilterCourseStatus = parseInt(filterCourseStatus.toString());
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId) , buildRequestStringArray(), IsEnabledateArchived ? (numFilterCourseStatus === 6 ? 13 : (numFilterCourseStatus == 3 ? 11 : 10)) : (numFilterCourseStatus === 6 ? 12 : (numFilterCourseStatus == 3 ? 10 : 9)), "coursestatusreport", enableRowBanding, ClassificationValue,CourseStatus,
                           DateEffectiveFrom,DateEffectiveTo, inputFilterIncludeInactive === "Yes", inputFilterIncludeInactive === "Yes", "", inputFilterFirstName, inputFilterLastName, 
                           inputFilterUserName, inputFilterEmail, "", "", false, false, 0, "", "", -1,-1, "", "", "", [], 0, numFilterCourseStatus === 6, false, false, false, false, false, numFilterCourseStatus === 3).then((pdfRes) => {            
            if (pdfRes.isSuccess) {
                const fileData = pdfRes.pdfDataOutput.fileData
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(
                    new Blob([serialToArray(atob(fileData))], {
                        type: ''
                    })
                )
                a.download = "CourseStatusReport - " + ((CourseStatus !== null && CourseStatus !== undefined) ? ((CourseStatus.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")) + " - ") : "") +   GetDate_yymmdd() + ".PDF";
                document.body.appendChild(a)
                a.click();
                document.body.removeChild(a)
            }
            else if (pdfRes.error !== undefined && pdfRes.error !== null) {
                setExportError(pdfRes.error);
            }
            setExporting(false)
            // Activity log 
            addError(oidcUser.access_token, userId, orgDomain, "Completed CourseStatus Report PDF-EXPORT in NEWSALT ClientApp", null, "CourseStatusReportResult.tsx", 6, EnableActivityLogs);
        })
    }

    const sortResultsByAsync = async (sortBy: "Course" | "FirstName" | "LastName" | "Email" | "ExternalID" |  "Unit" | "UserStatus" | "ModuleName" |"QuizStatusDate"| "QuizStatus" |"StatusDate" | "CompletionDate" | "CompletionReason" | "UserName" | "DateCreated" | "DateArchived" | "DueDate", sortGivenItemInDesc: boolean ) => {
        if (adminReportResult == null || adminReportResult.length <= 0) {
            return adminReportResult;
        }
        var result = adminReportResult.slice(0)
        setCurrentSortBy(sortBy)
        switch(sortBy) {
            case "Course":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.courseName === null) { return -1; }
                        if (a === null || a.courseName === null) { return 1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return -1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.courseName === null) { return 1; }
                        if (a === null || a.courseName === null) { return -1; }
                        if (a.courseName.toLowerCase() > b.courseName.toLowerCase()) { return 1; }
                        if (a.courseName.toLowerCase() < b.courseName.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCourseDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Unit":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.unitPathway === null) { return -1; }
                        if (a === null || a.unitPathway === null) { return 1; }
                        if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return -1; }
                        if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return 1; }
                        return 0;
                        //return a.unitID-b.unitID
                    })
                    if (reverseNextSort) {
                        setUnitDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.unitPathway === null) { return 1; }
                        if (a === null || a.unitPathway === null) { return -1; }
                        if (a.unitPathway.toLowerCase() > b.unitPathway.toLowerCase()) { return 1; }
                        if (a.unitPathway.toLowerCase() < b.unitPathway.toLowerCase()) { return -1; }
                        return 0;
                        //return a.unitID+b.unitID
                    })
                    if (reverseNextSort) {
                        setUnitDescending(true)
                        }
                    }
                    setAdminReportResult(result);
                    break;
            case "Email":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.email === null) { return -1; }
                        if (a === null || a.email === null) { return 1; }
                        if (a.email.toLowerCase() > b.email.toLowerCase()) { return -1; }
                        if (a.email.toLowerCase() < b.email.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setEmailDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.email === null) { return 1; }
                        if (a === null || a.email === null) { return -1; }
                        if (a.email.toLowerCase() < b.email.toLowerCase()) { return -1; }
                        if (a.email.toLowerCase() > b.email.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setEmailDescending(true) 
                    }
                }
                setAdminReportResult(result);
                break
            case "FirstName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.firstName === null) { return -1; }
                        if (a === null || a.firstName === null) { return 1; }
                        if(a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return -1; }
                        if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setFirstNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.firstName === null) { return 1; }
                        if (a === null || a.firstName === null) { return -1; }
                        if(a.firstName.toLowerCase() < b.firstName.toLowerCase()) { return -1; }
                        if(a.firstName.toLowerCase() > b.firstName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setFirstNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "LastName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.lastName === null) { return -1; }
                        if (a === null || a.lastName === null) { return 1; }
                        if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return -1; }
                        if(a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLastNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.lastName === null) { return 1; }
                        if (a === null || a.lastName === null) { return -1; }
                        if(a.lastName.toLowerCase() < b.lastName.toLowerCase()) { return -1; }
                        if(a.lastName.toLowerCase() > b.lastName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setLastNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "ExternalID":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.externalID === null) { return -1; }
                        if (a === null || a.externalID === null) { return 1; }
                        if(a.externalID.toLowerCase() > b.externalID.toLowerCase()) { return -1; }
                        if(a.externalID.toLowerCase() < b.externalID.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setExternalIDDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.externalID === null) { return 1; }
                        if (a === null || a.externalID === null) { return -1; }
                        if(a.externalID.toLowerCase() < b.externalID.toLowerCase()) { return -1; }
                        if(a.externalID.toLowerCase() > b.externalID.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setExternalIDDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "UserStatus":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.userStatus === null) { return -1; }
                        if (a === null || a.userStatus === null) { return 1; }
                        if(a.userStatus.toLowerCase() > b.userStatus.toLowerCase()) { return -1; }
                        if(a.userStatus.toLowerCase() < b.userStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUserStatusDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.userStatus === null) { return 1; }
                        if (a === null || a.userStatus === null) { return -1; }
                        if(a.userStatus.toLowerCase() < b.userStatus.toLowerCase()) { return -1; }
                        if(a.userStatus.toLowerCase() > b.userStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUserStatusDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "StatusDate":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        // if (b === null || b.courseStatusDate === null || b.courseStatusDate.trim() === "") { return -1; }
                        // if (a === null || a.courseStatusDate === null || a.courseStatusDate.trim() === "") { return 1; }
                        // var dta = new Date(a.courseStatusDate.split("/").reverse().join("-"))
                        // var dtb = new Date(b.courseStatusDate.split("/").reverse().join("-"))
                        // if(dta > dtb) { return -1; }
                        // if(dta < dtb) { return 1; }
                        if (b === null || !!b.courseStatusDateWithTime === false) { return -1; }
                        if (a === null || !!a.courseStatusDateWithTime === false) { return 1; }
                        if(a.courseStatusDateWithTime > b.courseStatusDateWithTime) { return -1; }
                        if(a.courseStatusDateWithTime < b.courseStatusDateWithTime) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setStatusDateDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        // if (b === null || b.courseStatusDate === null || b.courseStatusDate.trim() === "") { return 1; }
                        // if (a === null || a.courseStatusDate === null || a.courseStatusDate.trim() === "") { return -1; }
                        // var dta = new Date(a.courseStatusDate.split("/").reverse().join("-"))
                        // var dtb = new Date(b.courseStatusDate.split("/").reverse().join("-"))
                        // if(dta > dtb) { return 1; }
                        // if(dta < dtb) { return -1; }
                        if (b === null || !!b.courseStatusDateWithTime === false) { return 1; }
                        if (a === null || !!a.courseStatusDateWithTime === false) { return -1; }
                        if(a.courseStatusDateWithTime > b.courseStatusDateWithTime) { return 1; }
                        if(a.courseStatusDateWithTime < b.courseStatusDateWithTime) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setStatusDateDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CompletionDate":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.actualLastCourseCompletionDate === null || b.actualLastCourseCompletionDate.trim() === "") { return -1; }
                        if (a === null || a.actualLastCourseCompletionDate === null || a.actualLastCourseCompletionDate.trim() === "") { return 1; }
                        var dta = new Date(a.actualLastCourseCompletionDate.split("/").reverse().join("-"))
                        var dtb = new Date(b.actualLastCourseCompletionDate.split("/").reverse().join("-"))
                        if(dta > dtb) { return -1; }
                        if(dta < dtb) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompletionDateDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.actualLastCourseCompletionDate === null || b.actualLastCourseCompletionDate.trim() === "") { return 1; }
                        if (a === null || a.actualLastCourseCompletionDate === null || a.actualLastCourseCompletionDate.trim() === "") { return -1; }
                        var dta = new Date(a.actualLastCourseCompletionDate.split("/").reverse().join("-"))
                        var dtb = new Date(b.actualLastCourseCompletionDate.split("/").reverse().join("-"))
                        if(dta > dtb) { return 1; }
                        if(dta < dtb) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompletionDateDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CompletionReason":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.lastCourseCompletionReason === null) { return -1; }
                        if (a === null || a.lastCourseCompletionReason === null) { return 1; }
                        if(a.lastCourseCompletionReason.toLowerCase() > b.lastCourseCompletionReason.toLowerCase()) { return -1; }
                        if(a.lastCourseCompletionReason.toLowerCase() < b.lastCourseCompletionReason.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompletionReasonDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.lastCourseCompletionReason === null) { return 1; }
                        if (a === null || a.lastCourseCompletionReason === null) { return -1; }
                        if(a.lastCourseCompletionReason.toLowerCase() < b.lastCourseCompletionReason.toLowerCase()) { return -1; }
                        if(a.lastCourseCompletionReason.toLowerCase() > b.lastCourseCompletionReason.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setCompletionReasonDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "UserName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.userName === null) { return -1; }
                        if (a === null || a.userName === null) { return 1; }
                        if(a.userName.toLowerCase() > b.userName.toLowerCase()) { return -1; }
                        if(a.userName.toLowerCase() < b.userName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUserNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.userName === null) { return 1; }
                        if (a === null || a.userName === null) { return -1; }
                        if(a.userName.toLowerCase() < b.userName.toLowerCase()) { return -1; }
                        if(a.userName.toLowerCase() > b.userName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setUserNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "DateCreated":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return -1; }
                        if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return 1; }
                        var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                        var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                        if(dta > dtb) { return -1; }
                        if(dta < dtb) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateCreatedDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.dateCreated === null || b.dateCreated.trim() === "") { return 1; }
                        if (a === null || a.dateCreated === null || a.dateCreated.trim() === "") { return -1; }
                        var dta = new Date(a.dateCreated.split("/").reverse().join("-"))
                        var dtb = new Date(b.dateCreated.split("/").reverse().join("-"))
                        if(dta > dtb) { return 1; }
                        if(dta < dtb) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateCreatedDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "DateArchived":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.dateArchived === null || b.dateArchived.trim() === "") { return -1; }
                        if (a === null || a.dateArchived === null || a.dateArchived.trim() === "") { return 1; }
                        var dta = new Date(a.dateArchived.split("/").reverse().join("-"))
                        var dtb = new Date(b.dateArchived.split("/").reverse().join("-"))
                        if(dta > dtb) { return -1; }
                        if(dta < dtb) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateArchivedDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.dateArchived === null || b.dateArchived.trim() === "") { return 1; }
                        if (a === null || a.dateArchived === null || a.dateArchived.trim() === "") { return -1; }
                        var dta = new Date(a.dateArchived.split("/").reverse().join("-"))
                        var dtb = new Date(b.dateArchived.split("/").reverse().join("-"))
                        if(dta > dtb) { return 1; }
                        if(dta < dtb) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateArchivedDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
                case "ModuleName":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.moduleName === null) { return -1; }
                        if (a === null || a.moduleName === null) { return 1; }
                        if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) { return -1; }
                        if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setModuleNameDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.moduleName === null) { return 1; }
                        if (a === null || a.moduleName === null) { return -1; }
                        if (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) { return 1; }
                        if (a.moduleName.toLowerCase() < b.moduleName.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setModuleNameDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;

                case "QuizStatus":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) =>{
                        if (b === null || b.quizStatus === null) { return -1; }
                        if (a === null || a.quizStatus === null) { return 1; }
                        if (a.quizStatus.toLowerCase() > b.quizStatus.toLowerCase()) { return -1; }
                        if (a.quizStatus.toLowerCase() < b.quizStatus.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizStatusDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.quizStatus === null) { return 1; }
                        if (a === null || a.quizStatus === null) { return -1; }
                        if (a.quizStatus.toLowerCase() > b.quizStatus.toLowerCase()) { return 1; }
                        if (a.quizStatus.toLowerCase() < b.quizStatus.toLowerCase()) { return -1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setQuizStatusDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
                case "QuizStatusDate":
                    if (sortGivenItemInDesc) {
                        result.sort((a, b) => {
                            // if (b === null || b.quizStatusDate === null || b.quizStatusDate.trim() === "") { return -1; }
                            // if (a === null || a.quizStatusDate === null || a.quizStatusDate.trim() === "") { return 1; }
                            // var dta = new Date(a.quizStatusDate.split("/").reverse().join("-"))
                            // var dtb = new Date(b.quizStatusDate.split("/").reverse().join("-"))
                            // if(dta > dtb) { return -1; }
                            // if(dta < dtb) { return 1; }
                            if (b === null || !!b.quizStatusDateWithTime === false) { return -1; }
                            if (a === null || !!a.quizStatusDateWithTime === false) { return 1; }
                            if (a.quizStatusDateWithTime > b.quizStatusDateWithTime) { return -1; }
                            if (a.quizStatusDateWithTime < b.quizStatusDateWithTime) { return 1; }
                            return 0;
                        })
                        if (reverseNextSort) {
                            setQuizStatusDateDescending(false)
                        }
                    } else {
                        result.sort((a, b) => {
                            // if (b === null || b.quizStatusDate === null || b.quizStatusDate.trim() === "") { return 1; }
                            // if (a === null || a.quizStatusDate === null || a.quizStatusDate.trim() === "") { return -1; }
                            // var dta = new Date(a.quizStatusDate.split("/").reverse().join("-"))
                            // var dtb = new Date(b.quizStatusDate.split("/").reverse().join("-"))
                            // if(dta > dtb) { return 1; }
                            // if(dta < dtb) { return -1; }
                            if (b === null || !!b.quizStatusDateWithTime === false) { return 1; }
                            if (a === null || !!a.quizStatusDateWithTime === false) { return -1; }
                            if (a.quizStatusDateWithTime > b.quizStatusDateWithTime) { return 1; }
                            if (a.quizStatusDateWithTime < b.quizStatusDateWithTime) { return -1; }
                            return 0;
                        })
                        if (reverseNextSort) {
                            setQuizStatusDateDescending(true)
                        }
                    }
                    setAdminReportResult(result);
                    break;
                    case "DueDate":
                        if (sortGivenItemInDesc) {
                            result.sort((a, b) => {
                                if (b === null || b.dueDate === null) { return -1; }
                                if (a === null || a.dueDate === null) { return 1; }
                                if(a.dueDate > b.dueDate) { return -1; }
                                if(a.dueDate < b.dueDate) { return 1; }
                                return 0;
                            })
                            if (reverseNextSort) {
                                setDueDateDescending(false)
                            }
                        } else {
                            result.sort((a, b) => {
                                if (b === null || b.dueDate === null) { return 1; }
                                if (a === null || a.dueDate === null) { return -1; }
                                if(a.dueDate > b.dueDate) { return 1; }
                                if(a.dueDate < b.dueDate) { return -1; }
                                return 0;
                            })
                            if (reverseNextSort) {
                                setDueDateDescending(true)
                            }
                        }
                        setAdminReportResult(result);
                            break;
                    
                
            default:
        }

        return result.slice(0);
    }
   
    const sortResultsBy = async (sortBy: "Course" | "FirstName" | "LastName" | "Email" | "ExternalID" |   "Unit" | "UserStatus" | "ModuleName" |"QuizStatusDate" | "QuizStatus" | "StatusDate" | "CompletionDate" | "CompletionReason" | "UserName" | "DateCreated" | "DateArchived" | "DueDate", sortGivenItemInDesc: boolean ) => {

        setSortInProgress(true);
        setExportError("");
        
        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
            setCurrentSortBy(sortBy)
            setAdminReportResult(result)
            setPage(0)
            setSortInProgress(false);
        });
    }
    
    const clickUnitAsc = () => {
        setReverseNextSort(false);
        setUnitDescending(false)
        sortResultsBy("Unit", false)
    }

    const clickUnitDesc = () => {
        setReverseNextSort(false);
        setUnitDescending(true)
        sortResultsBy("Unit", true)
    }

    const clickFirstNameAsc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(false);        
        sortResultsBy("FirstName", false);
    }

    const clickFirstNameDesc = () => {
        setReverseNextSort(false);
        setFirstNameDescending(true)
        sortResultsBy("FirstName", true)
    }

    const clickLastNameAsc = () => {
        setReverseNextSort(false);
        setLastNameDescending(false);        
        sortResultsBy("LastName", false);
    }

    const clickLastNameDesc = () => {
        setReverseNextSort(false);
        setLastNameDescending(true)
        sortResultsBy("LastName", true)
    }

    const clickEmailAsc = () => {
        setReverseNextSort(false);
        setEmailDescending(false);
        sortResultsBy("Email", false);
    }

    const clickEmailDesc = () => {
        setReverseNextSort(false);
        setEmailDescending(true)
        sortResultsBy("Email", true)
    }

    const clickExternalIDAsc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(false);        
        sortResultsBy("ExternalID", false);
    }

    const clickExternalIDDesc = () => {
        setReverseNextSort(false);
        setExternalIDDescending(true)
        sortResultsBy("ExternalID", true)
    }

    const clickCourseAsc = () => {
        setReverseNextSort(false);
        setCourseDescending(false);
        sortResultsBy("Course", false);
    }

    const clickCourseDesc = () => {
        setReverseNextSort(false);
        setCourseDescending(true)
        sortResultsBy("Course", true)
    }

    const clickUserNameAsc = () => {
        setReverseNextSort(false);
        setUserNameDescending(false);
        sortResultsBy("UserName", false);
    }

    const clickUserNameDesc = () => {
        setReverseNextSort(false);
        setUserNameDescending(true)
        sortResultsBy("UserName", true)
    }

    const clickUserStatusAsc = () => {
        setReverseNextSort(false);
        setUserStatusDescending(false);
        sortResultsBy("UserStatus", false);
    }

    const clickUserStatusDesc = () => {
        setReverseNextSort(false);
        setUserStatusDescending(true)
        sortResultsBy("UserStatus", true)
    }

    const clickCompletionDateAsc = () => {
        setReverseNextSort(false);
        setCompletionDateDescending(false);
        sortResultsBy("CompletionDate", false);
    }

    const clickCompletionDateDesc = () => {
        setReverseNextSort(false);
        setCompletionDateDescending(true)
        sortResultsBy("CompletionDate", true)
    }

    const clickCompletionReasonAsc = () => {
        setReverseNextSort(false);
        setCompletionReasonDescending(false);
        sortResultsBy("CompletionReason", false);
    }

    const clickCompletionReasonDesc = () => {
        setReverseNextSort(false);
        setCompletionReasonDescending(true)
        sortResultsBy("CompletionReason", true)
    }

    const clickStatusDateAsc = () => {
        setReverseNextSort(false);
        setStatusDateDescending(false);
        sortResultsBy("StatusDate", false);
    }

    const clickStatusDateDesc = () => {
        setReverseNextSort(false);
        setStatusDateDescending(true)
        sortResultsBy("StatusDate", true)
    }

    const clickDateCreatedAsc = () => {
        setReverseNextSort(false);
        setDateCreatedDescending(false);
        sortResultsBy("DateCreated", false);
    }

    const clickDateCreatedDesc = () => {
        setReverseNextSort(false);
        setDateCreatedDescending(true)
        sortResultsBy("DateCreated", true)
    }

    const clickDateArchivedAsc = () => {
        setReverseNextSort(false);
        setDateArchivedDescending(false);
        sortResultsBy("DateArchived", false);
    }

    const clickDateArchivedDesc = () => {
        setReverseNextSort(false);
        setDateArchivedDescending(true)
        sortResultsBy("DateArchived", true)
    }

    const clickModuleNameAsc = () => {
        setReverseNextSort(false);
        setModuleNameDescending(false);
        sortResultsBy("ModuleName", false);
    }

    const clickModuleNameDesc = () => {
        setReverseNextSort(false);
        setModuleNameDescending(true)
        sortResultsBy("ModuleName", true)
    }

    const clickQuizStatusAsc = () => {
        setReverseNextSort(false);
        setQuizStatusDescending(false);
        sortResultsBy("QuizStatus", false);
    }

    const clickQuizStatusDesc = () => {
        setReverseNextSort(false);
        setQuizStatusDescending(true)
        sortResultsBy("QuizStatus", true)
    }

    const clickQuizStatusDateAsc = () => {
        setReverseNextSort(false);
        setQuizStatusDateDescending(false);
        sortResultsBy("QuizStatusDate", false);
    }

    const clickQuizStatusDateDesc = () => {
        setReverseNextSort(false);
        setQuizStatusDateDescending(true)
        sortResultsBy("QuizStatusDate", true)
    }

    const clickDueDateAsc = () => {
        setReverseNextSort(false);
        setDueDateDescending(false)
        sortResultsBy("DueDate", false)
    }

    const clickDueDateDesc = () => {
        setReverseNextSort(false);
        setDueDateDescending(true)
        sortResultsBy("DueDate", true)
    }

    const initialSort = () => {
        setCurrentSortBy("Unit");
        setUnitDescending(false);
        getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
            if (res.isSuccess) {
              let curdt = res.currentDateTimeText;
              setReportRunTimeTaken(curdt);
            } else {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
            }
           }).catch ((error) => {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
           });
        setPage(0)
    }

    useEffect(() => {
        initialSort()
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Completed CourseStatus Report in NEWSALT ClientApp", null, "CourseStatusReportResult.tsx", 6, EnableActivityLogs);
    },[])

    const GetClassificationname = () => {        
        const r = adminReportResult[0]
        let classificationValue  = r.classificationValue != null ? r.classificationValue : "";
 
        return classificationValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Grouping Option" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {r.classificationValue}</span></div> : ""
          
        
     }

     const renderDateArchivedHeader = () => {        
        const r = adminReportResult[0]
        let IsEnabledateArchived  = r.isEnabledateArchived;
        let CourseStatus  = r.courseStatus;
        return IsEnabledateArchived && (CourseStatus.toString().toLowerCase().trim() !== "not started")?
        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"  }} align="left">
            <div className={styles.tableHeadCellContainer}
                onClick={() => 
                    {
                        setDateArchivedDescending(!dateArchivedDescending);
                        sortResultsBy("DateArchived", !dateArchivedDescending);
                    }}>
                    {"Date Archived" /* TODO translate */}
                    <div className={styles.columnHeadArea}>
                        <span   style={{ opacity: (currentSortBy === "DateArchived" && dateArchivedDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                onClick={clickDateArchivedAsc}><UpArrowIcon /> </span>
                        <span   style={{ opacity: (currentSortBy === "DateArchived" && dateArchivedDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                onClick={clickDateArchivedDesc}><DownArrowIcon /> </span>
                    </div>
            </div>
        </TableCell> 
        : IsEnabledateArchived && (CourseStatus.toString().toLowerCase().trim() === "not started")?
        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color  }} align="left">
            <div className={styles.tableHeadCellContainer}
                onClick={() => 
                    {
                        setDateArchivedDescending(!dateArchivedDescending);
                        sortResultsBy("DateArchived", !dateArchivedDescending);
                    }}>
                    {"Date Archived" /* TODO translate */}
                    <div className={styles.columnHeadArea}>
                        <span   style={{ opacity: (currentSortBy === "DateArchived" && dateArchivedDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                onClick={clickDateArchivedAsc}><UpArrowIcon /> </span>
                        <span   style={{ opacity: (currentSortBy === "DateArchived" && dateArchivedDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                onClick={clickDateArchivedDesc}><DownArrowIcon /> </span>
                    </div>
            </div>
        </TableCell> : null    
     }

     const renderDateCreatedHeader = () => {          
        const r = adminReportResult[0]
        let IsEnabledateArchived  = r.isEnabledateArchived;
        let CourseStatus  = r.courseStatus
        return IsEnabledateArchived || (CourseStatus.toString().toLowerCase().trim() === "not started")?
                                    <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}
                                            onClick={() => 
                                            {
                                                setDateCreatedDescending(!dateCreatedDescending);
                                                sortResultsBy("DateCreated", !dateCreatedDescending)
                                            }}>
                                            {"Date Created"}
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "DateCreated" && dateCreatedDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickDateCreatedAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "DateCreated" && dateCreatedDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickDateCreatedDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                    </TableCell>  : 
                                     <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"  }} align="left">
                                        <div className={styles.tableHeadCellContainer}
                                            onClick={() => 
                                            {
                                                setDateCreatedDescending(!dateCreatedDescending);
                                                sortResultsBy("DateCreated", !dateCreatedDescending)
                                            }}>
                                            {"Date Created"}
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "DateCreated" && dateCreatedDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickDateCreatedAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "DateCreated" && dateCreatedDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickDateCreatedDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                     </TableCell>  
     }
     const GetCourseStatus = () => {        
        const r = adminReportResult[0]
        let CourseStatus  = r.courseStatus != null ? (parseInt(filterCourseStatus.toString()) === 6 ? "Incomplete (With Details)" : r.courseStatus) : "";
       // setIsNotStartedCourseStatus(CourseStatus.toString().toLowerCase().trim() === "not started");
        return CourseStatus != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Status" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {CourseStatus}</span></div> : ""
          
        
     }
     const GetDateFrom = () => {        
        const r = adminReportResult[0]
        let DateEffectiveFrom  = r.dateEffectiveFrom != null ? r.dateEffectiveFrom : "";
        if (DateEffectiveFrom !== "" && DateEffectiveFrom !== undefined && DateEffectiveFrom !== null) {
            const tokens = DateEffectiveFrom.split("-");
            if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                DateEffectiveFrom = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
            }
        }
        return DateEffectiveFrom != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Date From" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {DateEffectiveFrom}</span></div> : ""
          
        
     }
     const GetDateTo = () => {        
        const r = adminReportResult[0]
        let DateEffectiveTo  = r.dateEffectiveTo != null ? r.dateEffectiveTo : "";
        if (DateEffectiveTo !== "" && DateEffectiveTo !== undefined && DateEffectiveTo !== null) {
            const tokens = DateEffectiveTo.split("-");
            if (tokens !== null && tokens !== undefined && tokens.length == 3) {
                DateEffectiveTo = get2DigitDayOrMonth(tokens[2]) + "/" + get2DigitDayOrMonth(tokens[1]) + "/" + tokens[0];
            }
        }
       
        return DateEffectiveTo != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Date To" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {DateEffectiveTo}</span></div> : ""
          
        
     }
     const GetInputFilterFirstName = () => {        
        let curValue  = inputFilterFirstName != null ? inputFilterFirstName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"First Name" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterFirstName}</span></div> : ""
    }

    const GetInputFilterLastName = () => {       
        let curValue  = inputFilterLastName != null ? inputFilterLastName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Last Name" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterLastName}</span></div> : ""
    }

    const GetInputFilterUserName = () => {        
        let curValue  = inputFilterUserName != null ? inputFilterUserName : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Username" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterUserName}</span></div> : ""
    }

    const GetInputFilterEmail = () => {       
        let curValue  = inputFilterEmail != null ? inputFilterEmail : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Email" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterEmail}</span></div> : ""
    }

    const GetInputFilterIncludeInactive = () => {       
        let curValue  = inputFilterIncludeInactive != null ? inputFilterIncludeInactive : ""; 
        return curValue != "" ?<div className={styles.reportDetailContainer}>
                             <span className={styles.reportDetailTitle}>{"Include Inactive Accounts" /* TODO Tranlation */}</span>
                             <span className={styles.reportDetail}> {inputFilterIncludeInactive}</span></div> : ""
    }
    if (resultError !== null && resultError !== undefined && resultError.trimLeft().trimRight().length > 0)
    {
        setTitle(allResourcesToTranslations.coursestatusreport)
        setTitleEnglish(pageTitles.coursestatusreport)
        updateBackButtonRoute("/coursestatusreport");
        //setExportError("");
        return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div style={{color:"red"}}>Error: {resultError}</div>
                <br/>
                <div>Select the Back Button to return to Previous Page</div>
            </div>
        </BrandedCard>)
    }
    else if (adminReportResult === null || adminReportResult.length < 1) {
        setTitle(allResourcesToTranslations.coursestatusreport)
        setTitleEnglish(pageTitles.coursestatusreport)
        updateBackButtonRoute("/coursestatusreport");
            return (<BrandedCard>
            <div className={styles.noResultsCard}>
                <div>No Results to Display</div>
                <div>Select the Back Button to return to Previous Page</div>
                </div>
                </BrandedCard>)
    } else {
        setTitle(allResourcesToTranslations.coursestatusreport)
        setTitleEnglish(pageTitles.coursestatusreport)
        updateBackButtonRoute("/coursestatusreport");
    }
 
    if (exporting) return <LoaderContainer/>
    
    return (
        <>
         <Steps  enabled={intro.stepsEnabled}   steps={intro.steps}  initialStep={intro.initialStep}
          onExit={onExit}
            options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}/>
         <CustomTooltip displayText={["Guide Me"]}>
            <span style={{right:"105px"}} onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
            <GuideIcon />
            </span>
         </CustomTooltip>
         <BrandedCard>
           <div className={styles.reportPageTitleSection}>       
                <div className={styles.exportBtnContainer}>
                    <div className={styles.reportContainer}>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run by" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
                        </div>
                        <div className={styles.reportDetailContainer}>
                            <span className={styles.reportDetailTitle}>{"Report run at" /* TODO Tranlation */}</span>
                            <span className={styles.reportDetail}>
                                { reportRunTimeTaken }
                            </span>
                        </div>
                        { GetClassificationname() }    
                        { GetCourseStatus() }        
                        { GetDateFrom() }     
                        { GetDateTo() }  
                        { GetInputFilterFirstName() }      
                        { GetInputFilterLastName() }      
                        { GetInputFilterUserName() }      
                        { GetInputFilterEmail() }         
                        { GetInputFilterIncludeInactive() }                        
                    </div>   
                   
                    <div className={styles.reportExportContainer}>
                            <CustomTooltip displayText={["Export to XLSX"] /*TODO: Add translations */}>                                    
                                <Button  className="xlxs"  color="primary" onClick={handleXLSXExport} id="btnNewSaltCSRReportExportXLSX">
                                    <ExcelFileIcon/>
                                    {/* <GetAppIcon/> */}
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to CSV"] /*TODO: Add translations */}>         
                                <Button  className="csv" color="primary"  onClick={handleCSVExport} id="btnNewSaltCSRReportExportCSV">
                                    <CSVFileIcon/>
                                    {/* <GetAppIcon/> */}
                                </Button>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Export to PDF"] /*TODO: Add translations */}>        
                                <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCSRReportExportPDF">
                                    <PDFIcon/>
                                    {/* <GetAppIcon/> */}
                                </Button>
                            </CustomTooltip>
                            {/* <CustomTooltip displayText={["Show/Hide Row Shading"]}>        
                                <Button className="rowShading" color="primary"  onClick={toggleBanding}>
                                    <BrushIcon/>
                                    <CalendarViewDayIcon/>
                                </Button>
                            </CustomTooltip> */}
                    </div>
                    
                </div>               
            </div>
            </BrandedCard>
            <hr/>
            {<div style={{color:"red"}}>{(exportError === undefined || exportError === null  || exportError === "") ? "" : ("Export Error: " + exportError)}</div>}
            <hr/>
            <div className={ styles.paginationControlsContainer }>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 100]}
                        component="div"
                        count={adminReportResult.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    </div> 
            <div className={styles.tableSection}>
                <div className={styles.tableContainer}> 
                    <BrandedCard>
                        <TableContainer>
                            <Table>
                                <TableHead className="courseStatusReportList">
                                <TableRow>
                                    
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", width:"100px" }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                        <div 
                                                        onClick={() => 
                                                            {
                                                                setUnitDescending(!unitDescending);
                                                                sortResultsBy("Unit", !unitDescending);
                                                            }}>
                                                        {"Unit Pathway"}
                                                    </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUnitAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "Unit" && unitDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUnitDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setLastNameDescending(!lastNameDescending);
                                                        sortResultsBy("LastName", !lastNameDescending);
                                                    }}>
                                                         {"Last Name"}
                                                    </div>
                                               
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "LastName" && lastNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastNameAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "LastName" && lastNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickLastNameDesc}><DownArrowIcon /> </span>
                                                </div>
                                           </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setFirstNameDescending(!firstNameDescending);
                                                        sortResultsBy("FirstName", !firstNameDescending);
                                                    }}>
                                            {"First Name"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "FirstName" && firstNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickFirstNameAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "FirstName" && firstNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickFirstNameDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setEmailDescending(!emailDescending);
                                                        sortResultsBy("Email", !emailDescending);
                                                    }}>
                                            {"Email"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "Email" && emailDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickEmailAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "Email" && emailDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickEmailDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={isLengthyCoursePresent ? styles.tableHeadCellContainerShort : styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setExternalIDDescending(!externalIDDescending);
                                                        sortResultsBy("ExternalID", !externalIDDescending);
                                                    }}>
                                            {"External ID"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "ExternalID" && externalIDDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickExternalIDAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "ExternalID" && externalIDDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickExternalIDDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setUserStatusDescending(!userStatusDescending);
                                                        sortResultsBy("UserStatus", !userStatusDescending);
                                                    }}>
                                            {"Account Status"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "UserStatus" && userStatusDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUserStatusAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "UserStatus" && userStatusDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickUserStatusDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>

                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color}} align="left">
                                        <div className={isLengthyCoursePresent ? styles.tableHeadCellContainerLong : styles.tableHeadCellContainer}>
                                            <div onClick={() => 
                                                    {
                                                        setCourseDescending(!courseDescending);
                                                        sortResultsBy("Course", !courseDescending);
                                                    }}>
                                            {"Course"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickCourseAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "Course" && courseDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickCourseDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>                                           
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setStatusDateDescending(!statusDateDescending);
                                                        sortResultsBy("StatusDate", !statusDateDescending);
                                                    }}>
                                            {"Course Status Date"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "StatusDate" && statusDateDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickStatusDateAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "StatusDate" && statusDateDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickStatusDateDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        {parseInt(filterCourseStatus.toString()) == 6 && <><TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setModuleNameDescending(!moduleNameDescending);
                                                        sortResultsBy("ModuleName", !moduleNameDescending);
                                                    }}>
                                            {"Module Name"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "ModuleName" && moduleNameDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickModuleNameAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "ModuleName" && moduleNameDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickModuleNameDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setQuizStatusDescending(!quizStatusDescending);
                                                        sortResultsBy("QuizStatus", !quizStatusDescending);
                                                    }}>
                                            {"Quiz Status"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "QuizStatus" && quizStatusDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickQuizStatusAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "QuizStatus" && quizStatusDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickQuizStatusDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell>
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color }} align="left">
                                        <div className={styles.tableHeadCellContainer}>
                                            <div 
                                                onClick={() => 
                                                    {
                                                        setQuizStatusDateDescending(!quizStatusDateDescending);
                                                        sortResultsBy("QuizStatusDate", !quizStatusDateDescending);
                                                    }}>
                                            {"Quiz Status Date"}
                                            </div>
                                            <div className={styles.columnHeadArea}>
                                                <span   style={{ opacity: (currentSortBy === "QuizStatusDate" && quizStatusDateDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickQuizStatusDateAsc}><UpArrowIcon /> </span>
                                                <span   style={{ opacity: (currentSortBy === "QuizStatusDate" && quizStatusDateDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                        onClick={clickQuizStatusDateDesc}><DownArrowIcon /> </span>
                                            </div>
                                        </div>
                                        </TableCell></>}
                                        {renderDateCreatedHeader()}                      
                                        {renderDateArchivedHeader()} 
                                        { adminReportResult[0].courseStatus.toString().toLowerCase().trim() === "not started" &&
                                        <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0"}} align="left">                                            
                                            <div className={styles.tableHeadCellContainer}>
                                                <div 
                                                onClick={() => 
                                                    {
                                                        setDueDateDescending(!dueDateDescending);
                                                        sortResultsBy("DueDate", !dueDateDescending);
                                                    }}>
                                                {"Due Date" /* TODO translate */}
                                                </div>
                                                <div className={styles.columnHeadArea}>
                                                    <span   style={{ opacity: (currentSortBy === "DueDate" && dueDateDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickDueDateAsc}><UpArrowIcon /> </span>
                                                    <span   style={{ opacity: (currentSortBy === "DueDate" && dueDateDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display:"inline-flex" }} 
                                                            onClick={clickDueDateDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>  
                                        }  
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        adminReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: any, i ) => {
                                            return(
                                                <TableRow key={`coursestatusreportresult-${i}`} style={rowBackgroundColor(i)}>
                                                
                                                <TableCell align="left">
                                                    {result.unitPathway}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.lastName}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.firstName}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.email}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.externalID}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.userStatus}
                                                </TableCell>
                                                <TableCell align="left"> 
                                                    {result.courseName}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.courseStatusDate/*result.courseStatusDate + " " + (!!result.courseStatusDateWithTime ? result.courseStatusDateWithTime.toString() : "")*/}
                                                </TableCell>
                                                {parseInt(filterCourseStatus.toString()) == 6 && <><TableCell align="left">
                                                    {result.moduleName}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.quizStatus}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {result.quizStatusDate/*result.quizStatusDate + " " + (!!result.quizStatusDateWithTime ? result.quizStatusDateWithTime.toString() : "")*/}
                                                </TableCell> </>}
                                                <TableCell align="left">
                                                    {result.dateCreated}
                                                </TableCell>
                                                {result.isEnabledateArchived ?
                                                <TableCell align="left">
                                                    {result.dateArchived}
                                                </TableCell> : null }        
                                                {adminReportResult[0].courseStatus.toString().toLowerCase().trim() === "not started" &&
                                                <TableCell align="left">
                                                    { result.dueDateTxt }
                                                </TableCell>  
                                                }                                     
                                            </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>
                </div>
            </div>
            <div className={ styles.paginationControlsContainer }>
                <TablePagination
                    component="div"
                    count={adminReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        </>
      );    
}

export default CurrentAdminReportResult
