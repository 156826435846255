import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "../../../styles/importAccounts.module.css";
import CustomTooltip from '../../../Components/CustomTooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import { TranslationsContext } from "../../../contexts/TranslationsContext";
import CloudUploadIcon from "../../../CustomIcons/Administration/ImportAccounts_CloudUpload";
import ReuploadIcon from "../../../CustomIcons/Administration/ReuploadIcon";
import { Button } from "@material-ui/core";

interface Props {
	selectedFile: string;
	uploadFile: Function;	
	setSelectedFile: Function;
}

const CourseReupload = ({ selectedFile, uploadFile, setSelectedFile }: Props) => {
	const { translations: { allResourcesToTranslations }} = useContext(TranslationsContext);
	const inputEl = useRef<HTMLInputElement>(null);
	const [dragBoxMessage, setDragBoxMessage] = useState<string>(allResourcesToTranslations.dropanddropimportfile);
	const [draggedOver, setDraggedOver] = useState<boolean>(false);
	const [uploadingFile, setUploadingFile] = useState<boolean>(false);
	const [dragError, setDragError] = useState<boolean>(false);
	const [filename, setFilename] = useState<string>("");
	
	const handleDragOver = (event: any) => {
		event.preventDefault();
		setDraggedOver(true);
	};

	const handleDragEnd = () => {
		setDraggedOver(false);
	};

    const handleClick = (event: any) => {
        inputEl?.current?.click()
    }

	const showError = (errorMessage: string) => {
		setDragError(true);		
		setUploadingFile(false);
		setDragBoxMessage(errorMessage);
		setTimeout(() => {
			setDragError(false);
			setDragBoxMessage(allResourcesToTranslations.dropanddropimportfile);
			setDraggedOver(false);
		}, 3000);
	};

	const handleFileDrop = (event: any) => {
		event.preventDefault();
		if (event.nativeEvent.dataTransfer !== undefined) {
			if (event.nativeEvent.dataTransfer.files.length) {
				const file = event.nativeEvent.dataTransfer.files[0];			
				let splitedFileName = file.name.split(".");
				if(splitedFileName.length >= 2)
				{
					//let fileExtension = splitedFileName[1];
					let fileExtension = (file.name.split('.').pop()).toLowerCase();
					if (fileExtension.includes("zip")) {						
						setUploadingFile(true);
						if (inputEl.current) inputEl.current.files = event.nativeEvent.srcElement.files;
						setDragError(false);						
						setFilename(file.name);
						setSelectedFile(file);
						let base64String;
						const reader = new FileReader()
						reader.onload = () => {						
							base64String = reader?.result?.toString().replace("data:", "").replace(/^.+,/, "");
							uploadFile(base64String, fileExtension, file.name).then((res: any) => {
								if (res.success) {
									setUploadingFile(false);
								} else {
									showError(allResourcesToTranslations.therewasanissueuploadingfile);
								}	
							})
						}
						reader.readAsDataURL(file)
					} else {						
						showError(allResourcesToTranslations.therewasanissueuploadingfile);
					}
				}else {
				showError(allResourcesToTranslations.therewasanissueuploadingfile);
				}
			}
		} 
	else 
	{
		if (event.nativeEvent.srcElement.files.length) {
				let file = event.nativeEvent.srcElement.files[0];
				let splitedFileName = file.name.split(".");				
				if(splitedFileName.length >= 2)
				{
					//let fileExtension = splitedFileName[1];
					let fileExtension = (file.name.split('.').pop()).toLowerCase();
					if (fileExtension.includes("zip")) {						
						if (inputEl.current) inputEl.current.files = event.nativeEvent.srcElement.files;
						setDragError(false);						
						setFilename(file.name);
						setSelectedFile(file);
						let base64String;
						const reader = new FileReader()
						reader.onload = () => {						
							base64String = reader?.result?.toString().replace("data:", "").replace(/^.+,/, "");
							uploadFile(base64String, fileExtension, file.name);
						}
						reader.readAsDataURL(file)
					} else {
						showError(allResourcesToTranslations.therewasanissueuploadingfile);
					}
				} else {
					showError(allResourcesToTranslations.therewasanissueuploadingfile);
				}
			}
	}
	};

	useEffect(() => {		
		if (selectedFile === "" ) {			
			setDraggedOver(false);
		}
		return () => { };
	}, [selectedFile]);

	useEffect(() => {		
		// at the end, return a cleanup method
		return () => { };
	}, []);

	return (
		<>
		<form>			
			<div
				//className={`${styles.dropZonesmall} ${draggedOver ? styles.dropZoneOver : styles.dragZoneExit} ${dragError ? styles.dropZoneError : ""}`}
				onDrop={(e) => handleFileDrop(e)}
				onDragOver={(e) => handleDragOver(e)}
				onDragEnd={handleDragEnd}
				onDragLeave={handleDragEnd}
				onClick={(e) => handleClick(e)}>					
				  <Button className="reuploadcourse" variant="contained" color="primary"> 
					<ReuploadIcon/>			
				  </Button>	
			</div>			
			<input ref={inputEl} onInput={handleFileDrop} className={styles.hiddenInput} name="selected-image" type="file" accept="application/zip"></input>			
		</form>
		</>
	);
};

export default CourseReupload;
