//React + Typescript
import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { QuizHistoryItem } from '../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import moment from 'moment';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { SessionContext } from '../../contexts/SessionContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../contexts/CorperateThemeContext';
//API Functions
import { getCurrentTimeOfOrg } from "../../API/GetCurrentTimeOfOrg";
import { addError } from '../../API/AddError'
import { getQuizHistory } from '../../API/GetQuizHistory';
import { getUserCourseModules } from '../../API/UserCourseModules'
//General Functions
import getColor from '../../functions/getColor';
import serialToArray from '../../functions/serialToArray'
import { getCurrentTimeDDMMYYYYFormat } from '../../functions/getCurrentTimeDDMMYYYYFormat'
//Components
import UnbrandedCard from '../../Components/UnbrandedCard';
import CustomTooltip from '../../Components/CustomTooltip';
import LoaderContainer from '../../Components/LoaderContainer';
import HistoryProgressBar from '../../Components/HistoryProgressBar'
import BrandedCard from '../../Components/BrandedCard'
import { LoaderContext } from '../../contexts/LoaderContext';
//Icon
import ExcelFileIcon from '../../CustomIcons/ExcelFileIcon'
import CSVFileIcon from '../../CustomIcons/CSVFileIcon'
import PDFIcon from '../../CustomIcons/PDFFileIcon';
//API functions
import { getExportedXLSXData } from '../../API/GetExportedXLSXData'
import { getExportedCSVData } from '../../API/GetExportedCSVData'
import { getExportedPDFData } from '../../API/GetExportedPDFData'

//MUI Components
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import ViewListIcon from '@material-ui/icons/ViewList';
//Styles
import styles from '../../styles/quizHistory.module.css'

const QuizHistory = (props: any): JSX.Element => {
    const { color } = useContext(CorperateThemeContext).theme;
    const { translations: { allResourcesToTranslations }, pageTitles} = useContext(TranslationsContext)
    const { setTitle, setTitleEnglish, updateBackButtonRoute, checkScrollReminder, setShowScrollArrow, displayName, EnableActivityLogs } = useContext(UIContext);
    const { moduleId } = useParams<{ moduleId: string }>();
    const { cpdList, currentCpd } = useContext(CPDPanelContext);
    const [quizHistoryItems, setQuizHistoryItems] = useState<Array<QuizHistoryItem>>((props?.resultItems ?? []));
    const { setCurSessionID, curCourseName, setCurCourseName } = useContext(SessionContext)
    const [ curModuleName, setCurModuleName ] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(true);
    const [disableSummaryBtn, setDisableSummaryBtn] = useState<boolean>(false)
    const { oidcUser } = useReactOidc();
    const { profile: { profile, user_id, website,family_name, given_name }} = oidcUser || {};
    
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const userId = user_id ? parseInt(user_id) : 0;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    //Export Report
    const { setMessage } = useContext(LoaderContext);
    const [exportError, setExportError] = useState<string>("")
    const [resultError, setResultError] = useState<string>(props?.location?.state?.resultError ?? "")
    const [exporting, setExporting] = useState<boolean>(false)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")

    const getStatusCSSClass = (status: string) => {
      switch(status) {
        case "Passed":
          return styles.passed
        case "Failed":
          return styles.failed
        default:
          return
      }
    }

    const getStatusTranslation = (status: string) => {
      switch(status) {
        case "Passed":
          return allResourcesToTranslations.passed
        case "Failed":
          return allResourcesToTranslations.failed
        case "Not Started":
          return allResourcesToTranslations.notstarted
        case "Completed":
          return allResourcesToTranslations.completed
        case "Expired (Time Elapsed)":
          return allResourcesToTranslations.expiredtimeelapsed
        case "Expired (New Content)":
          return allResourcesToTranslations.expirednewcontent
        case "In Progress":
          return allResourcesToTranslations.inprogress
        default:
          return ""
      }
    }

    useEffect(() => {
      updateBackButtonRoute("/myreport")
      getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
        if (res.isSuccess) {
          let curdt = res.currentDateTimeText;
          setReportRunTimeTaken(curdt);
        } else {
          let curdt = getCurrentTimeDDMMYYYYFormat();
          setReportRunTimeTaken(curdt);
        }
       }).catch ((error) => {
          let curdt = getCurrentTimeDDMMYYYYFormat();
          setReportRunTimeTaken(curdt);
       });
      setTitle(`${allResourcesToTranslations.quizhistory.replace('{DISPLAYNAME}', displayName)}`)
      setTitleEnglish(pageTitles.quizhistory)
      getQuizHistory(oidcUser.access_token, userId, orgDomain, parseInt(moduleId), !orgId? -1 : parseInt(orgId)).then((data) => {
            setCurCourseName(data.courseName);
            setCurModuleName(data.moduleName);
            setQuizHistoryItems(data.listOfQuizEntries);
            checkScrollReminder()
            let cpdId = -1; 
            if(cpdList != null)
            {
            cpdId = cpdList.length > 0 ? cpdList[currentCpd].id : -1 //If no CPD list value is defaulted to -1
            }
            getUserCourseModules(oidcUser.access_token, userId, orgDomain, data.courseID, cpdId).then((res) => {
              if (res[0].quizType === "SA") {
                setDisableSummaryBtn(true)
                setLoading(false)
              } else {
                setLoading(false)
              }
            })
        })
        return () => {
          setShowScrollArrow(false)
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleQuizSelection = (hItem:QuizHistoryItem ) => {
        setCurSessionID(hItem.quizSessionID);
        addError(oidcUser.access_token, userId, orgDomain, `Opened ${formatToDateTimestamp(hItem.dateCreated)} Quiz Summary`, null, "QuizHistory.tsx", 6, EnableActivityLogs)
        updateBackButtonRoute(`/myreport/${moduleId}/history`)
    }

    const formatedDateTimestamp_DDMMMYYYYhhmmss = (d: Date)=> {
      if (d === null)
        return '';
      var datearr = (d.toLocaleDateString().split('T')[0]).split('/').reverse();
      [datearr[1], datearr[2]] = [datearr[2], datearr[1]]
      const date = datearr.join('-');
      const time = d.toTimeString().split(' ')[0];
      const datestring = `${date} ${time}`
      //console.log("datestring " + datestring);
      let momentVariable = moment(datestring, 'YYYY-MM-DD hh:mm:ss a');  
      let finalStringValue = momentVariable.format('YYYY-MM-DD hh:mm:ss a');
      finalStringValue = finalStringValue.replace('T', ' ')
      //console.log("finalStringValue " + finalStringValue);
      // let splitArray = finalStringValue.split(' ')
      // if (splitArray !== null && splitArray.length > 3 && splitArray[3].charAt(0) === '0')
      //   splitArray[3] = splitArray[3].substring(1, splitArray[3].length - 1);
      // let finalText = "";
      // for (let i = 0; splitArray !== null && i < splitArray.length; i++) {
      //   finalText += splitArray[i] + " ";
      // }
      //return finalText;
      return finalStringValue;
    }

    const formatToDateTimestamp = (dateString: string)=> {
      if (dateString === null)
        return '';
      return formatedDateTimestamp_DDMMMYYYYhhmmss(new Date(dateString));
    }

    const renderQuizHistoryRows = () => {
        if (quizHistoryItems != null) {
          const renderSummaryButton = (hi: QuizHistoryItem) => {
              if (disableSummaryBtn || (hi.status !== "Passed" && hi.status !== "Failed")) {
                return (
                  <CustomTooltip displayText={[allResourcesToTranslations.nosummaryavailable]}>
                    <span>
                    <Button 
                      variant="contained" 
                      color="primary"
                      disabled={disableSummaryBtn}
                      >
                      <ViewListIcon />
                    </Button>
                  </span>
                </CustomTooltip>
                )
              } else {
                return(
                <Link
                  to={{
                    pathname: `/myreport/${moduleId}/history/summary`,
                  }}
                  onClick={() => handleQuizSelection(hi)}
                >
                  <CustomTooltip displayText={[allResourcesToTranslations.summary]}>
                    <span>
                      <Button 
                        variant="contained" 
                        color="primary"
                        disabled={disableSummaryBtn}
                        >
                        <ViewListIcon />
                      </Button>
                    </span>
                  </CustomTooltip>
                </Link>
                )
              }
          }
          
          return quizHistoryItems.map((hItem: QuizHistoryItem, i) => { 
              return (
                <TableRow key={`historyrow-${i}`}>
                  <TableCell align="left">
                    <span className={styles.courseName}>{i === 0 ? curCourseName : ""}</span>
                  </TableCell>
                  <TableCell align="left">
                    {curModuleName}
                  </TableCell>
                  <TableCell align="center">
                    {/* { !hItem.dateCreated ? "": formatToDateTimestamp(hItem.dateCreated) }  */}
                    { !hItem.dateCompletedLocal ? "": hItem.dateCompletedLocal }
                  </TableCell>
                  <TableCell align="center">
                    { !!hItem.quizSessionID ? hItem.quizSessionID : "" }
                  </TableCell>
                  <TableCell align="center">
                    <span className={ getStatusCSSClass(hItem.status) }>
                      { getStatusTranslation(hItem.status) }
                    </span>
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        display:
                          (hItem.status !== "Passed" && hItem.status !== "Failed") ? "none" : "block",
                      }}
                    >
                      <HistoryProgressBar
                        userScore={hItem.quizScore}
                        passmark={hItem.quizPassmark}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        opacity: (hItem.status !== "Passed" && hItem.status !== "Failed") ? "0" : "1",
                        pointerEvents:
                          (hItem.status !== "Passed" && hItem.status !== "Failed") ? "none" : "all", //Hiding with opacity to keep the colomn the same height when hidden
                      }}
                    >
                    {
                      renderSummaryButton(hItem)
                    }
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          );
        }
    }
    
    const renderQuizHistoryHead = () => {
          return(
          <TableRow>
             <TableCell style={{color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0"}} align="left">
                {allResourcesToTranslations.course}
              </TableCell>
              <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}}align="left">
                {allResourcesToTranslations.module}
              </TableCell>
              <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                {allResourcesToTranslations.quizstatusdate}
              </TableCell>
              <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                {allResourcesToTranslations.quizsessionid}
              </TableCell>
              <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                {allResourcesToTranslations.status}
              </TableCell>
              <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color}} align="center">
                {allResourcesToTranslations.yourscore}
              </TableCell>
              <TableCell style={{color: getColor(color.substring(1)),backgroundColor: color, borderRadius: "0 8px 0 0" }} align="center">
                {allResourcesToTranslations.summary}
              </TableCell>
          </TableRow>
        )
    };


    const GetDate_yymmdd = () => {
      var d =  new Date(Date.now());
      let day = d.getFullYear().toString().substring(2,4)  + (d.getMonth() + 1).toString().padStart(2, "0") + d.getDate().toString().padStart(2, "0")
       return day;         
    }
    
      const buildRequestStringArray = () => {
        let resultArray: Array<string> = []
        for (let i = 0; i < quizHistoryItems.length; i++) {
          const r = quizHistoryItems[i]
          resultArray.push(`${curCourseName}<#>${curModuleName}<#>${r.dateCompletedLocal}<#>${(!!r.quizSessionID ? r.quizSessionID : "")}<#>${r.status}<#>${r.quizPassmark + "%"}<#>${r.quizScore+ "%"}`)
    
        }
        return resultArray
      }
    
      const handleXLSXExport = () => {
        setMessage("Exporting XLSX");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report XLSX-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportQuizHistory.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = quizHistoryItems[0]
    
        getExportedXLSXData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 6, "PersonalReporQuizHistory", enableRowBanding, "", "", "", "", false, false, "").then((xlxsRes) => {
          if (xlxsRes.isSuccess) {
            const fileData = xlxsRes.xlsxDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
              new Blob([serialToArray(atob(fileData))], {
                type: ''
              })
            )
    
            a.download = "PersonalReport-" + GetDate_yymmdd() + ".xlsx";
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
    
          }
          else if (xlxsRes.error !== undefined && xlxsRes.error !== null) {
            setExportError(xlxsRes.error);
          }
          setExporting(false)
          // Activity log 
          addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report XLSX-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportQuizHistory.tsx", 6, EnableActivityLogs);
        })
      }
    
      const handleCSVExport = () => {
        setMessage("Exporting CSV");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report CSV-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = quizHistoryItems[0]
        getExportedCSVData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 6, "PersonalReporQuizHistory", "", "", "", "", false, false, "", false).then((csvRes) => {
          if (csvRes.isSuccess) {
            const fileData = csvRes.csvDataOutput.fileData
            var a = window.document.createElement('a');
    
            a.href = window.URL.createObjectURL(
              new Blob([serialToArray(atob(fileData))], {
                type: ''
              })
            )
            a.download = "PersonalReport-" + GetDate_yymmdd() + ".csv";
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
    
          }
          else if (csvRes.error !== undefined && csvRes.error !== null) {
            setExportError(csvRes.error);
          }
          setExporting(false)
          // Activity log 
          addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report CSV-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportQuizHistory.tsx", 6, EnableActivityLogs);
        })
      }
    
      const handlePDFXExport = () => {
        setMessage("Exporting PDF");
        setExportError("");
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report PDF-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        setExporting(true)
        const r = quizHistoryItems[0]
        getExportedPDFData(oidcUser.access_token, userId, parseInt(orgId), buildRequestStringArray(), 7, "PersonalReporQuizHistory", enableRowBanding, "", "", "", "", false, false, "").then((pdfRes) => {
          if (pdfRes.isSuccess) {
            const fileData = pdfRes.pdfDataOutput.fileData
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(
              new Blob([serialToArray(atob(fileData))], {
                type: ''
              })
            )
            a.download = "PersonalReport-" + GetDate_yymmdd() + ".PDF";
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
          }
          else if (pdfRes.error !== undefined && pdfRes.error !== null) {
            setExportError(pdfRes.error);
          }
          setExporting(false)
          // Activity log 
          addError(oidcUser.access_token, userId, orgDomain, "Completed CurrentAdmin Report PDF-EXPORT in NEWSALT ClientApp", null, "CurrentAdminReportResult.tsx", 6, EnableActivityLogs);
        })
      }
    
      if (loading){
        return <LoaderContainer/>
      }
      return (
      <>
      <BrandedCard>
        <div className={styles.reportPageTitleSection}>
          <div className={styles.exportBtnContainer}>
            <div className={styles.reportContainer} style={{ width: "97%" }}>
            <div className={styles.reportDetailContainer}>
                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.reportyrunby}</span>
                <span className={styles.reportDetail}> {`${given_name} ${family_name}`}</span>
              </div>
              <div className={styles.reportDetailContainer} >
                <span className={styles.reportDetailTitle}>{allResourcesToTranslations.reportruntime}</span>
                <span className={styles.reportDetail}>
                  {reportRunTimeTaken}
                </span>
              </div>           
            </div>
            <div className={styles.reportExportContainer} style={{ width: "3%" }}>
              {/* <CustomTooltip displayText={["Export to XLSX"] }>
                <Button className="xlxs" color="primary" onClick={handleXLSXExport} id="btnNewSaltCARReportExportXLSX">
                  <ExcelFileIcon />
                </Button>
              </CustomTooltip>
              <CustomTooltip displayText={["Export to CSV"] }>
                <Button className="csv" color="primary" onClick={handleCSVExport} id="btnNewSaltCARReportExportCSV">
                  <CSVFileIcon />
                </Button>
              </CustomTooltip> */}
              <CustomTooltip displayText={[allResourcesToTranslations.exporttopdf]}>
                <Button className="pdf" color="primary" onClick={handlePDFXExport} id="btnNewSaltCARReportExportPDF">
                  <PDFIcon />
                </Button>
              </CustomTooltip>
            </div>            
          </div>
        </div>
      </BrandedCard>
      <hr />
      <div>
        <UnbrandedCard>
          <Table>
            <TableHead>
              {renderQuizHistoryHead()}
            </TableHead>
            <TableBody>
              {renderQuizHistoryRows()}
            </TableBody>
          </Table>
        </UnbrandedCard>
      </div>
      </>
      );
}

export default QuizHistory
