import axios from 'axios';
import { addError } from './AddError';

export const generateAndSendCertificate = async (token: string, userId: number, orgDomain: string, 
                                                  courseId: number, currentCulture: string, 
                                                  sendCertificate: boolean, targetFolder: string, 
                                                  selectedProfileID: number, selectedProfilePeriodID: number,
                                                  calledFromSaltCertificateReport: boolean = false, 
                                                  forcedCertificateName: string = "", 
                                                  userIDForCertificateReport: number = -1) => {

    try {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/api/usersessions/GenerateCertificate`,
      headers:{
        'Content-Type': 'application/json',
        'Authorization' : `Bearer ${token}`
      },
      data: {
        userId : userId,
        courseId : courseId,
        currentCulture: currentCulture,
        sendCertificate: sendCertificate,
        targetFolder: targetFolder,
        SelectedProfileID: selectedProfileID,
        SelectedProfilePeriodID: selectedProfilePeriodID,
        CalledFromSaltCertificateReport: calledFromSaltCertificateReport,
        ForcedCertificateName: forcedCertificateName,
        UserIDForCertificateReport: userIDForCertificateReport
      },
    });
    const data = await response.data;
    return data;
  } catch (error: any) {
    if (error.response) {
      console.log(error.response);
      addError(token, userId, orgDomain, "Rest Exception", error.response, "GenerateAndSendCertificate.ts", 1)
    } else if (error.request) {
      console.log(error.request);
      addError(token, userId, orgDomain, "Rest Exception", error.request, "GenerateAndSendCertificate.ts", 1)
    } else if (error.message) {
      console.log(error.message);
      addError(token, userId, orgDomain, "Rest Exception", error.message, "GenerateAndSendCertificate.ts", 1)
    }
    return null;
  }
};

export default generateAndSendCertificate