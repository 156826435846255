//React + Typescript
import React, { useState, useContext, useEffect } from 'react';
import { SentEmailReportResultItem } from '../../../globalTypes';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Steps } from "intro.js-react";
//Context
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
//Components
import BrandedCard from '../../../Components/BrandedCard';
import LoaderContainer from '../../../Components/LoaderContainer';
import CustomTooltip from '../../../Components/CustomTooltip';
import ReportSaveModal from '../../../Components/CommonPopupModalForResult';
//General Functions
import getColor from '../../../functions/getColor';
import { getCurrentTimeDDMMYYYYFormat } from '../../../functions/getCurrentTimeDDMMYYYYFormat'
//API functions
import { getCurrentTimeOfOrg } from "../../../API/GetCurrentTimeOfOrg";
import { sendOrGetFullSentEmailReport } from '../../../API/SendOrGetFullSentEmailReport'
import { addError } from '../../../API/AddError'
//Components
import UpArrowIcon from '../../../CustomIcons/UpArrowIcon';
import DownArrowIcon from '../../../CustomIcons/DownArrowIcon';
import GuideIcon from '../../../CustomIcons/GuideIcon';
//MUI Components
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
// Style
import currentstyles from '../../../styles/currentAdminReport.module.css'
import { LoaderContext } from '../../../contexts/LoaderContext';

const DeactivationSentEmailReport = (props: any) => {
    const {sentEmailReportResult, inputFilterFromDate, inputFilterToDate, inputFilterToEmail, inputFilterSubject, inputFilterBody, resultError} = props;
    const { translations: { allResourcesToTranslations } } = useContext(TranslationsContext)
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, family_name, given_name, website } } = oidcUser || {};
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const { theme: { color } } = useContext(CorperateThemeContext);
    const { setMessage } = useContext(LoaderContext);
    const [adminReportResult, setAdminReportResult] = useState<Array<SentEmailReportResultItem>>(sentEmailReportResult ?? []);
    const [exporting, setExporting] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [enableRowBanding, setEnableRowBanding] = useState<boolean>(true)
    const [currentSortBy, setCurrentSortBy] = useState<string>("")
    const [sortInProgress, setSortInProgress] = useState<boolean>(false)
    const [dateFromDescending, setDateFromDescending] = useState<boolean>(true)
    const [toEmailDescending, settoEmailDescending] = useState<boolean>(true)
    const [bccDescending, setbccDescending] = useState<boolean>(true)
    const [subjectDescending, setSubjectDescending] = useState<boolean>(true)
    const [bodyDescending, setBodyDescending] = useState<boolean>(true)
    const [reverseNextSort, setReverseNextSort] = useState<boolean>(false)
    const [reportRunTimeTaken, setReportRunTimeTaken] = useState<string>("")
    const { EnableActivityLogs } = useContext(UIContext);
    const [showModal, setModalOpen] = useState<boolean>(false)
    const [exportError, setExportError] = useState<string>("")

    // Modal Pop-up related  
    const [resendEmailStatus, setResendEmailStatus] = React.useState(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const rowBackgroundColor = (index: number) => {
        if (enableRowBanding) {
            return { backgroundColor: index % 2 == 0 ? "#eee" : "" }
        } else {
            return {}
        }
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleClosePopup = () => {
        setShowWarningModal(false);
    };

    const sortResultsByAsync = async (sortBy: "DateSent" | "To" | "CC" | "Subject" | "Body", sortGivenItemInDesc: boolean) => {
        if (adminReportResult == null || adminReportResult.length <= 0) {
            return adminReportResult;
        }
        var result = adminReportResult.slice(0)
        setCurrentSortBy(sortBy)
        switch (sortBy) {
            case "DateSent":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.createdDate === null) { return -1; }
                        if (a === null || a.createdDate === null) { return 1; }
                        if (a.createdDate > b.createdDate) { return -1; }
                        if (a.createdDate < b.createdDate) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateFromDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.createdDate === null) { return 1; }
                        if (a === null || a.createdDate === null) { return -1; }
                        if (a.createdDate < b.createdDate) { return -1; }
                        if (a.createdDate > b.createdDate) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setDateFromDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "To":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.toEmail === null) { return -1; }
                        if (a === null || a.toEmail === null) { return 1; }
                        if (a.toEmail.toLowerCase() > b.toEmail.toLowerCase()) { return -1; }
                        if (a.toEmail.toLowerCase() < b.toEmail.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        settoEmailDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.toEmail === null) { return 1; }
                        if (a === null || a.toEmail === null) { return -1; }
                        if (a.toEmail.toLowerCase() < b.toEmail.toLowerCase()) { return -1; }
                        if (a.toEmail.toLowerCase() > b.toEmail.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        settoEmailDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "CC":
                if (sortGivenItemInDesc) {
                    result.sort((a, b) => {
                        if (b === null || b.bcc === null) { return -1; }
                        if (a === null || a.bcc === null) { return 1; }
                        if (a.bcc.toLowerCase() > b.bcc.toLowerCase()) { return -1; }
                        if (a.bcc.toLowerCase() < b.bcc.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setbccDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.bcc === null) { return 1; }
                        if (a === null || a.bcc === null) { return -1; }
                        if (a.bcc.toLowerCase() < b.bcc.toLowerCase()) { return -1; }
                        if (a.bcc.toLowerCase() > b.bcc.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setbccDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Subject":
                if (subjectDescending) {
                    result.sort((a, b) => {
                        if (b === null || b.subject === null) { return -1; }
                        if (a === null || a.subject === null) { return 1; }
                        if (a.subject.toLowerCase() > b.subject.toLowerCase()) { return -1; }
                        if (a.subject.toLowerCase() < b.subject.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setSubjectDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.subject === null) { return 1; }
                        if (a === null || a.subject === null) { return -1; }
                        if (a.subject.toLowerCase() < b.subject.toLowerCase()) { return -1; }
                        if (a.subject.toLowerCase() > b.subject.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setSubjectDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            case "Body":
                if (bodyDescending) {
                    result.sort((a, b) => {
                        if (b === null || b.body === null) { return -1; }
                        if (a === null || a.body === null) { return 1; }
                        if (a.body.toLowerCase() > b.body.toLowerCase()) { return -1; }
                        if (a.body.toLowerCase() < b.body.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setBodyDescending(false)
                    }
                } else {
                    result.sort((a, b) => {
                        if (b === null || b.body === null) { return 1; }
                        if (a === null || a.body === null) { return -1; }
                        if (a.body.toLowerCase() < b.body.toLowerCase()) { return -1; }
                        if (a.body.toLowerCase() > b.body.toLowerCase()) { return 1; }
                        return 0;
                    })
                    if (reverseNextSort) {
                        setBodyDescending(true)
                    }
                }
                setAdminReportResult(result);
                break;
            default:
                break;
        }

        return result.slice(0);
    }

    const sortResultsBy = async (sortBy: "DateSent" | "To" | "CC" | "Subject" | "Body", sortGivenItemInDesc: boolean) => {
        setSortInProgress(true);
        setExportError("");
        await sortResultsByAsync(sortBy, sortGivenItemInDesc).then((result) => {
            setCurrentSortBy(sortBy);
            setAdminReportResult(result);
            setPage(0);
            setSortInProgress(false);
        });
    }

    const clickDateSentAsc = () => {
        setReverseNextSort(false);
        setDateFromDescending(false);
        sortResultsBy("DateSent", false);
    }

    const clickDateSentDesc = () => {
        setReverseNextSort(false);
        setDateFromDescending(true);
        sortResultsBy("DateSent", true);
    }

    const clickToEmailAsc = () => {
        setReverseNextSort(false);
        settoEmailDescending(false);
        sortResultsBy("To", false);
    }

    const clickToEmailDesc = () => {
        setReverseNextSort(false);
        settoEmailDescending(true);
        sortResultsBy("To", true);
    }

    const clickBodyAsc = () => {
        setReverseNextSort(false);
        setBodyDescending(false);
        sortResultsBy("Body", false);
    }

    const clickBodyDesc = () => {
        setReverseNextSort(false);
        setBodyDescending(true);
        sortResultsBy("Body", true);
    }

    const clickSubjectAsc = () => {
        setReverseNextSort(false);
        setSubjectDescending(false);
        sortResultsBy("Subject", false);
    }

    const clickSubjectDesc = () => {
        setReverseNextSort(false);
        setSubjectDescending(true);
        sortResultsBy("Subject", true);
    }
    const initialSort = () => {
        setDateFromDescending(true)
        settoEmailDescending(false)
        setbccDescending(false)
        setSubjectDescending(false)
        setCurrentSortBy("DateSent")
        getCurrentTimeOfOrg(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((res) => {
            if (res.isSuccess) {
              let curdt = res.currentDateTimeText;
              setReportRunTimeTaken(curdt);
            } else {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
            }
           }).catch ((error) => {
              let curdt = getCurrentTimeDDMMYYYYFormat();
              setReportRunTimeTaken(curdt);
           });
        setPage(0)
    }

    const handleModal = (value: boolean) => {
        setModalOpen(value);
    }

    useEffect(() => {
        if (setResendEmailStatus && resendEmailStatus) {
            setTimeout(() => {
                setShowWarningModal(false);
                setResendEmailStatus(false);
            }, 5000)
        }
    }, [showWarningModal])

    useEffect(() => {
        setAdminReportResult(sentEmailReportResult);
    },[sentEmailReportResult]);

    useEffect(() => {
        initialSort()
        setLoading(false);
        setAdminReportResult(sentEmailReportResult);
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Completed SentEmail Report in NEWSALT ClientApp", null, "DeactivationSentEmailReportResult.tsx", 6, EnableActivityLogs);
        return () => { };
    }, [])

    if (resultError !== null && resultError !== undefined && resultError.length > 0) {
        return (<BrandedCard>
            <div className={currentstyles.noResultsCard}>
                <div style={{ color: "red" }}>{allResourcesToTranslations.sntemrepgmmerrl + ": " + resultError}</div>
                <br />
                <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div>
            </div>
        </BrandedCard>)
    }
    else if (adminReportResult === null || adminReportResult.length < 1) {
        return (<BrandedCard>
            <div className={currentstyles.noResultsCard}>
                <div>{allResourcesToTranslations.sntemrepgmmnoresm}</div>
                 <br />
                <div>{allResourcesToTranslations.sntemrepgmmselbkprevm}</div> 
            </div>
        </BrandedCard>)
    }

    if (exporting) return <LoaderContainer />

    return (
        <>
            {<div style={{ color: "red" }}>{(exportError === undefined || exportError === null || exportError === "") ? "" : (allResourcesToTranslations.sntemrepgmmexerrm + ": " + exportError)}</div>}
            <div className={currentstyles.paginationControlsContainer}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 100]}
                    component="div"
                    count={adminReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
            <div className={currentstyles.tableSection}>
                <div className={currentstyles.emailReportContainer}>
                    <BrandedCard>
                        <TableContainer>
                            <Table>
                                <TableHead className="sentemailReportList">
                                    <TableRow>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "8px 0 0 0", width: "150px" }} align="center">
                                            <div className={currentstyles.tableHeadCellContainer}
                                                onClick={() => {
                                                    setDateFromDescending(!dateFromDescending);
                                                    sortResultsBy("DateSent", !dateFromDescending);
                                                }}>
                                                {allResourcesToTranslations.sntemrepgmmdtsntl}
                                                <div className={currentstyles.columnHeadArea}>
                                                    <span style={{ opacity: (currentSortBy === "DateSent" && dateFromDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickDateSentAsc}><UpArrowIcon /> </span>
                                                    <span style={{ opacity: (currentSortBy === "DateSent" && dateFromDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickDateSentDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                            <div className={currentstyles.tableHeadCellContainer}
                                                onClick={() => {
                                                    settoEmailDescending(!toEmailDescending);
                                                    sortResultsBy("To", !toEmailDescending);
                                                }}>
                                                {allResourcesToTranslations.sntemrepgmmemtgtl}
                                                <div className={currentstyles.columnHeadArea}>
                                                    <span style={{ opacity: (currentSortBy === "To" && toEmailDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickToEmailAsc}><UpArrowIcon /> </span>
                                                    <span style={{ opacity: (currentSortBy === "To" && toEmailDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickToEmailDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color }} align="center">
                                            <div className={currentstyles.tableHeadCellContainer}
                                                onClick={() => {
                                                    setSubjectDescending(!subjectDescending);
                                                    sortResultsBy("Subject", !subjectDescending);
                                                }}>
                                                {allResourcesToTranslations.emrepprvgmtsub}
                                                <div className={currentstyles.columnHeadArea}>
                                                    <span style={{ opacity: (currentSortBy === "To" && subjectDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickSubjectAsc}><UpArrowIcon /> </span>
                                                    <span style={{ opacity: (currentSortBy === "To" && subjectDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickSubjectDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ color: getColor(color.substring(1)), backgroundColor: color, borderRadius: "0 8px 0 0" }} align="center">
                                            <div className={currentstyles.tableHeadCellContainer}
                                                onClick={() => {
                                                    setBodyDescending(!bodyDescending);
                                                    sortResultsBy("Body", !bodyDescending);
                                                }}>
                                                {allResourcesToTranslations.sntemrepgmmbdyl}
                                                <div className={currentstyles.columnHeadArea}>
                                                    <span style={{ opacity: (currentSortBy === "Body" && bodyDescending === false) ? "100%" : "50%", margin: "2px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickBodyAsc}><UpArrowIcon /> </span>
                                                    <span style={{ opacity: (currentSortBy === "Body" && bodyDescending === true) ? "100%" : "50%", margin: "0px", height: "6px", display: "inline-flex" }}
                                                        onClick={clickBodyDesc}><DownArrowIcon /> </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        adminReportResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result: SentEmailReportResultItem, i) => {
                                            return (
                                                <TableRow key={`sentemailreportresult-${i}`} style={rowBackgroundColor(i)}>
                                                    <TableCell align="left">
                                                        {result.dateCreatedText}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.toEmail}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {result.subject}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: result.body //bodyFormatted
                                                            }}></div>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BrandedCard>
                </div>
            </div>
            <div className={currentstyles.paginationControlsContainer}>
                <TablePagination
                    component="div"
                    count={adminReportResult.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
            {showWarningModal && <ReportSaveModal saveSuccess={resendEmailStatus} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={resendEmailStatus ? 'Success' : hasErrored ? 'Error' : 'Resend Email'} bodyText={modalPopupMessage} />}
        </>
    );
}

export default DeactivationSentEmailReport
