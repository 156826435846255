import React, { useContext, useEffect, useState } from 'react';

import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../../contexts/UIContext';
import { TranslationsContext } from '../../../contexts/TranslationsContext';
import { CorperateThemeContext } from '../../../contexts/CorperateThemeContext';

//API Functions 
import styles from "../../../styles/addAccount.module.css";
//Intro Lib
import { Steps } from "intro.js-react";

//General Functions
//Components
import CustomTooltip from '../../../Components/CustomTooltip';
//MUI Components
import Button from '@material-ui/core/Button';
import SaveIcon from '../../../CustomIcons/SupportDetailSaveIcon';
import BrandedCard from '../../../Components/BrandedCard';
import CommonPopupModalForResult from '../../../Components/CommonPopupModalForResult';

import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import ToggleRightIcon from '../../../CustomIcons/ToggleRightIcon';
import ToggleLeftIcon from '../../../CustomIcons/ToggleLeftIcon';
import getColor from '../../../functions/getColor';

import { CourseAdditionalResources } from "../../../globalTypes";
import CancelIcon from '../../../CustomIcons/SupportDetailCancelIcon';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import EditIcon from '../../../CustomIcons/SupportDetailsEditIcon';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CourseAdditionalRecourseFileDropBox from './CourseAdditionalRecourseFileDropBox';
import GetCourseAdditionalResourcesList from '../../../API/Administration/CourseSettings/GetCourseAdditionalResourcesList';
import SaveCourseAdditionalResources from '../../../API/Administration/CourseSettings/SaveCourseAdditionalResources';
import CourseProgressBar from './CourseProgressBar';
import CourseRecourseReUploadFileDropBox from './CourseRecourseReUploadFileDropBox';
import GetCourseDetails from '../../../API/Administration/CourseSettings/GetCourseDetails';
import GuideIcon from '../../../CustomIcons/GuideIcon';

const CourseAdditionalRecourse = (props: any) => {
    const history = useHistory();
    const { translations: { allResourcesToTranslations }, pageTitles } = useContext(TranslationsContext)
    const { color } = useContext(CorperateThemeContext).theme;
    const { setTitle, setTitleEnglish, updateBackButtonRoute, setMenuSelection, superAdminSelectedBasicOrgData } = useContext(UIContext);
    const [loading, setLoading] = useState<boolean>(true);
    const { oidcUser } = useReactOidc();
      const [active, setActive] = useState<boolean>(true);
    const [editedresourcesName, seteditedresourcesName] = useState<string>("");
    const [editedResourcesID, seteditedResourcesID] = useState<number>(0);
    const [selectedFileSize, setSelectedFileSize] = useState<number>(0);
    const [selectedFileData, setSelectedFileData] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<string>("");

    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [modalPopupMessage, setModalPopupMessage] = useState('');
    const [hasErrored, setHasErrored] = useState<boolean>(false);

    const orgId = oidcUser.profile.organisation_id ?? -1;
    const userId = oidcUser?.profile.user_id ? parseInt(oidcUser.profile.user_id) : 0; //TODO UserId to come from the correct Param
    const orgDomain = oidcUser?.profile.website ? oidcUser.profile.website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const { courseIdToEdit } = useParams<{ courseIdToEdit: string }>();
    const [CourseAdditionalResources, setCourseAdditionalResources] = useState<Array<CourseAdditionalResources>>([]);
 
    const [savedMessageFlash, setSavedMessageFlash] = useState<boolean>(false)


	const [isCourseModuleAccess, setIsCourseModuleAccess] = useState<boolean>(false);
	const [isRemEmails,setIsRemEmails ] = useState<boolean>(false);
	const [isUnitRule, setIsUnitRule ] = useState<boolean>(false);
    const [reminderID, setreminderID] = useState<number>(0);

    const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
            {
                element: ".Filedropbox",
                title: "Description",
                intro: allResourcesToTranslations.coursesupportedfiles
            },
    
        ]
      })
      const setGuideMe = (CourseAdditionalResources: boolean = false) => {
    
        let updateSteps = [];    
        updateSteps.push(  
          {
            element: ".Filedropbox",
            title: "Description",
            intro: allResourcesToTranslations.coursesupportedfiles
          });         
       

          if(CourseAdditionalResources)
            {
                  updateSteps.push(
                    {
                        element: ".resourceorderguideme",
                        title: "Order",
                        intro: allResourcesToTranslations.resourceorderguideme
                      }, 
                      {
                        element: ".resourcenameguideme",
                        title: "Name",
                        intro: allResourcesToTranslations.resourcenameguideme
                      }, 
                      {
                        element: ".resourcefilenameguideme",
                        title: "File Name",
                        intro: allResourcesToTranslations.resourcefilenameguideme
                      },
                      {
                        element: ".resourcefiletypeguideme",
                        title: "File Type",
                        intro: allResourcesToTranslations.resourcefiletypeguideme
                      },
                      {
                        element: ".resourcemodifyguideme",
                        title: "Modify",
                        intro: allResourcesToTranslations.resourcemodifyguideme
          
                    },
                    {
                      element: ".resourcereuploadguideme",
                      title: "Re-upload",
          
                      intro: allResourcesToTranslations.resourcereuploadguideme
                    },
                    {
                        element: ".resourceactiveguideme",
                        title: "Active",
                        intro: allResourcesToTranslations.resourceactiveguideme
                    },
                    {
                        element: ".resourcereorderguideme",
                        title: "Reorder",
                        intro: allResourcesToTranslations.resourcereorderguideme            
                    });        
            }
            updateSteps.push(      
                {
                  element: ".saveData",
                  title: "Save Details",
                  intro: allResourcesToTranslations.coursesave
                },
                {
                    element: ".nextData",
                    title: "Move to Next",
                    intro: allResourcesToTranslations.coursenext
                  });
              
        setIntro({ ...intro, steps: [...updateSteps] });
      };
     
      const onExit = () => {
        setIntro({ ...intro, stepsEnabled: false });
      };


    useEffect(() => {
        setLoading(false);
        setGuideMe(false);
        setTitle(allResourcesToTranslations.publishcourse +  " - " +allResourcesToTranslations.step2);
        setTitleEnglish(pageTitles.publishcourse);
        updateBackButtonRoute("");
        setMenuSelection(allResourcesToTranslations.publishcourse);
        if (parseInt(courseIdToEdit) > 0) {
            LoadCourseDetail();
            LoadCourseRecourse();
           
        }
        return () => { };
    }, []);


    const LoadCourseDetail = () => {	
    

		GetCourseDetails(oidcUser.access_token,
			userId,
			orgDomain,
			parseInt(orgId),
			parseInt(courseIdToEdit),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.orgID : 0),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.domainName : ""),
			(!!superAdminSelectedBasicOrgData ? superAdminSelectedBasicOrgData.siteName : "")).then((result: any) => {
				if (result != null && result.isSuccess && result.error == null) {				
					if (result != null && result.isSuccess && result.error == null) {						
						if (result != null && result.isSuccess && result.error == null) {                           
                            if (result.getCourseDetailsItems[0].unitIds.length > 0)
                            {
                                setIsCourseModuleAccess(true);
                            }     
                            if (result.getCourseDetailsItems[0].remEscId    > 0)
                            {
                                setreminderID(result.getCourseDetailsItems[0].remEscId                                );
                                setIsRemEmails(true);                               
                            }    
                            if (result.getCourseDetailsItems[0].unitRuleId > 0)
                            {
                                setIsUnitRule(true);
                                
                            }
                        }
					}
				}
			});
	}
    const LoadCourseRecourse = () => {
        GetCourseAdditionalResourcesList(oidcUser.access_token,
            userId,
            orgDomain,
            parseInt(orgId), parseInt(courseIdToEdit)).then((result: any) => {
                if (!!result && result.isSuccess && result.error == null) {
                    setCourseAdditionalResources([...result.courseAdditionalResourcesListItems]);
                    setLoading(false);
                    setGuideMe(true);
                } 
            });
    }
    const BackButtonRoute = () => {
        history.push({
            pathname: `/addcourse/` + courseIdToEdit
        });
    }

    const handleClosePopup = () => {
        setShowWarningModal(false);
    };

    useEffect(() => {
        if (showWarningModal) {
            setTimeout(() => {
                setShowWarningModal(false);
                setSaveSuccess(false);
            }, 5000)
        }
    }, [showWarningModal])

    const handleSave = () => {
        setIsSaving(true);
        SaveCourseAdditionalResources(oidcUser.access_token, userId, orgDomain,
            parseInt(orgId),
            parseInt(courseIdToEdit),                   
            CourseAdditionalResources
        ).then((result: any) => {
            if (!!result   && result.isSuccess) {
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(true);
                setModalPopupMessage("Course Additional Resources has been saved successfully!");
                setIsSaving(false);
              
            }
            else {
                setShowWarningModal(true);
                setHasErrored(true);
                setModalPopupMessage(result.error);
            }
        });
    };
    const handleNext = () => {
        if(!isCourseModuleAccess)
            {
                history.push({
                    pathname: `/assigncourseaccess/` + courseIdToEdit
                });
            }
            else if (!isRemEmails)
            {
                history.push({
                    pathname: `/courseemailrulesadd/` + courseIdToEdit 
                });
            }
            else if (!isUnitRule)
                {
                    history.push({
                        pathname: `/coursecompliancerules/` + courseIdToEdit
                    });
                }
            else
            {
                history.push({
                    pathname: `/coursesummary/` + courseIdToEdit
                });
            }
    };
    const handleFileUpload = (byteArray: string, fileExtension: string, name: string, filename: string,fileSize:number) => {
        setSelectedFileData(byteArray);
        setSelectedFile(filename);
        setSelectedFileSize(fileSize);
        setShowWarningModal(false);
        setModalPopupMessage("");
        setIsFileUploading(true);

        let currentRecourseList: Array<CourseAdditionalResources> = []
        currentRecourseList = [...CourseAdditionalResources];

        let duplicate = currentRecourseList.filter((item: CourseAdditionalResources) => {
        return item.name === name;
        });  

        if(!duplicate || duplicate.length == 0)
        {       
            let  maxSequence = 0;
            let newRecourseList: Array<CourseAdditionalResources> = []
            newRecourseList.push({
                sequence: maxSequence + 1,
                name: name,
                active: active,
                fileName: filename,
                fileSize: fileSize,
                fileData: byteArray,
                fileExtension: fileExtension,
                resourceID: 0,
                strResourcesLink: '',
                isBeingEdited: false
            });
            currentRecourseList.push(...newRecourseList);

            let i = 1;
            let Curupdatelist = currentRecourseList.map((item: CourseAdditionalResources) => {
                return {
                    ...item,
                    sequence: i++,
                }
            });

            setCourseAdditionalResources(Curupdatelist);
            setShowWarningModal(true);
            setHasErrored(false);
            setSaveSuccess(true);
            setIsSaving(false);
            setModalPopupMessage("Course Additional Recourse added successfully.");
            setIsFileUploading(false);  
        } 
        else{
            setShowWarningModal(true);
            setHasErrored(true);     
            setModalPopupMessage("Course Additional Recourse file found, Please selecte another file.");  
            setTimeout(() => { setSavedMessageFlash(false) }, 10000);
            setIsSaving(false);   
            setIsFileUploading(false);  
        }

    }
    const handleFileReupload = (byteArray: string, fileExtension: string, name: string, filename: string, fileSize:number,resourceID:number) => {
  
        setShowWarningModal(false);
        setModalPopupMessage("");
        setSelectedFile(filename);
        setIsFileUploading(true);
        setIsSaving(true);


     
        let currentRecourseList: Array<CourseAdditionalResources> = []
        currentRecourseList = [...CourseAdditionalResources];

          let duplicate = currentRecourseList.filter((item: CourseAdditionalResources) => {
            return item.name === name;
          });  

        if(!duplicate || duplicate.length == 0)
        { 
                const filteredList = CourseAdditionalResources.map((item: CourseAdditionalResources) => {
                    if (!!item && !!item.name && item.resourceID === resourceID) {
                    return {
                        ...item,        
                        name: name, 
                        fileData: byteArray,
                        fileName: filename, 
                        fileExtension: fileExtension,
                        fileSize: fileSize
                    }
                        
                    }
                    return item;
                });
                setCourseAdditionalResources(filteredList);
                setShowWarningModal(true);
                setHasErrored(false);
                setSaveSuccess(true);
                setIsSaving(false);
                setModalPopupMessage("Course Additional Recourse file updated successfully.");
                setIsFileUploading(false);
        }
        else{
            setShowWarningModal(true);
            setHasErrored(true);         
            setModalPopupMessage("Course Additional Recourse file found, Please selecte another file.");    
            setIsSaving(false); 
            setIsFileUploading(false);  
        }
    }    

    const handleSetSelectedFileName = (filename: string) => {
        setSelectedFile(filename);
    }

    const handleRecourseActive = (name: string,fileName:string, action: boolean, sequence: number, ResourcesID: number) => {
        seteditedResourcesID(ResourcesID);
        const filteredCourseList = CourseAdditionalResources.map((item: CourseAdditionalResources) => {
            if (!!item && !!item.name && item.name === name && item.fileName === fileName) {
                if (!!item.name && (!!filteredCourseList !== true ||
                    (!!filteredCourseList && item.name.trim().toLowerCase().includes(name.trim().toLowerCase()))) && item.sequence === sequence) {
                    return {
                        ...item,
                        active: action
                    }
                }
            }
            return item;
        });
        setCourseAdditionalResources([...filteredCourseList]);

    }

    const handleSaveRecourse = (Name: any, editedresourcesName: string, sequence: number) => {
        const filteredCourseList = CourseAdditionalResources.map((item: CourseAdditionalResources) => {
            if (!!item && !!item.name && item.name === Name) {
                if (!!item.name && item.name.trim().toLowerCase().includes(Name.trim().toLowerCase()) && item.sequence === sequence) {
                    return {
                        ...item,
                        name: editedresourcesName,
                        isBeingEdited: false
                    }
                }
            }
            return item;
        });
        setCourseAdditionalResources([...filteredCourseList]);

    };

    const handleEdit = (name: any, sequence: number, resourcesID: number) => {
        seteditedResourcesID(resourcesID);
        let updatedSelectedSCORMcontentList = CourseAdditionalResources.map((item: CourseAdditionalResources) => {
            if (!!item) {
                handleCancel(item.name);
            }
            if (item.name === name && item.sequence == sequence) {
                item.isBeingEdited = true;
            }
            return item;
        });
        setCourseAdditionalResources([...updatedSelectedSCORMcontentList]);
    };

    const handleCancel = (name: any) => {
        const updatedSelectedEmailTemplates = CourseAdditionalResources.map((item: CourseAdditionalResources) => {
            if (!!item && item.name === name) {
                item.isBeingEdited = false;
            }
            return item;
        });
        setCourseAdditionalResources([...updatedSelectedEmailTemplates]);
    };

    const handleDragEnd = (e: any) => {
        if (!e.destination) return;
        const rows = CourseAdditionalResources; // Define rows based on courseSCORMcontentList
        let tempData = Array.from(rows);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        tempData = tempData.map((item: CourseAdditionalResources, index: number) => {
            return {
                ...item,
                sequence: index + 1
            }
        });
        setCourseAdditionalResources(tempData);
    };

    const handleSetSelectedFileSize = (filesize: number) => {
        setSelectedFileSize(filesize);
    }

    return (
        <><Steps
            enabled={intro.stepsEnabled}
            steps={intro.steps}
            initialStep={intro.initialStep}
            onExit={onExit}
            options={{
                nextLabel: "Next",
                prevLabel: "Back",
                tooltipClass: "sample-class",
                showBullets: true,
            }} /><CustomTooltip displayText={["Guide Me"]}>
                <span onClick={() => setIntro({ ...intro, stepsEnabled: true })}
                    className={styles.guideMeButtonSpecific} style={{ right: '95px', top: '100px' }}>
                    <GuideIcon />
                </span>
            </CustomTooltip>            
       
            <div>
                <div style={{ width: "100%", textAlign: "center", paddingBottom: "10px" }}>
                    <CourseProgressBar courseIdToEdit={parseInt(courseIdToEdit)}  step='2'/>
                </div>
                <BrandedCard>
                    <div className={styles.reportWrraper}>
                        <div className={styles.accountDetails}>
                            {"Upload Additional Resources (Optional) " /* TODO translate */}
                        </div>
                        <div className={`${styles.tableSection}`} style={{ paddingTop: "20px" }}>
                            <div className={styles.tableContainer}>
                                <BrandedCard>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            color: getColor(color.substring(1)),
                                                            backgroundColor: color,
                                                            borderRadius: "8px 0 0 0",
                                                        }} className="resourceorderguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>
                                                                Order
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: getColor(color.substring(1)),
                                                            backgroundColor: color,
                                                        }} className="resourcenameguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>Name
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: getColor(color.substring(1)),
                                                            backgroundColor: color,
                                                        }} className="resourcefilenameguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>File Name
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: getColor(color.substring(1)),
                                                            backgroundColor: color,
                                                        }} className="resourcefiletypeguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>File Type
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell 
                                                        style={{ color: getColor(color.substring(1)), backgroundColor: color, width: "150px" }}
                                                        className="resourcemodifyguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>
                                                                {"Modify"}
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: getColor(color.substring(1)),
                                                            backgroundColor: color,
                                                        }} className="resourcereuploadguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>Reupload
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: getColor(color.substring(1)),
                                                            backgroundColor: color,
                                                        }} className="resourceactiveguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>
                                                                Active
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            color: getColor(color.substring(1)),
                                                            backgroundColor: color,
                                                            borderRadius: "0 8px 0 0",
                                                        }} className="resourcereorderguideme"
                                                        align="left">
                                                        <div className={styles.tableHeadCellContainer}>
                                                            <div>
                                                                Reorder
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <Droppable droppableId="droppable-1">
                                                {(provider) => (
                                                    <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                                                        <TableRow>
                                                            <TableCell colSpan={8}>
                                                                <div style={{ width: '100%' }}>
                                                                    <CustomTooltip displayText={[allResourcesToTranslations.draganddropcustomtooltip]}>
                                                                        <div className='Filedropbox'>
                                                                            <CourseAdditionalRecourseFileDropBox selectedFile={selectedFile} uploadFile={handleFileUpload} setSelectedFile={handleSetSelectedFileName} />
                                                                        </div>
                                                                    </CustomTooltip>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                        {CourseAdditionalResources.map((result: any, index: any) => (
                                                            <Draggable key={result.name} draggableId={result.name} index={index}>
                                                                {(provider) => (
                                                                    <TableRow
                                                                        key={result.name}
                                                                        className={styles.tableRow}
                                                                        {...provider.draggableProps}
                                                                        ref={provider.innerRef}
                                                                    >
                                                                        <TableCell align="left">
                                                                            {result.sequence}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {result.isBeingEdited ?
                                                                                <TextField style={{ width: '100%' }} variant="outlined"
                                                                                    autoComplete="off" InputLabelProps={{ shrink: true, }}
                                                                                    defaultValue={result.name} onChange={(e) => seteditedresourcesName(e.target.value)} />
                                                                                :
                                                                                <span className={result.name}>{result.name}</span>}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {result.fileName}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {result.fileExtension}
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            {result.isBeingEdited ?
                                                                                <>
                                                                                    <CustomTooltip displayText={["Save"]}>
                                                                                        <Button disabled={editedresourcesName.trim() === ""} onClick={() => handleSaveRecourse(result.name, editedresourcesName, result.sequence)} className={styles.moduleListButton} variant="contained" color="primary">
                                                                                            <SaveIcon />
                                                                                        </Button>
                                                                                    </CustomTooltip> &nbsp;&nbsp;
                                                                                    <CustomTooltip displayText={["Cancel"]}>
                                                                                        <Button onClick={() => handleCancel(result.name)} className={styles.moduleListButton} variant="contained" color="primary">
                                                                                            <CancelIcon />
                                                                                        </Button>
                                                                                    </CustomTooltip>
                                                                                </>
                                                                                :
                                                                                <CustomTooltip displayText={["Edit"]}>
                                                                                    <Button className="ruleedit" onClick={() => handleEdit(result.name, result.sequence, result.resourceId)} variant="contained" color="primary">
                                                                                        <EditIcon />
                                                                                    </Button>
                                                                                </CustomTooltip>}

                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <CustomTooltip displayText={["Reupload"]}>
                                                                                <div style={{ paddingTop: "5px" }}>
                                                                                    {/* <CourseReupload selectedFile={selectedFile} uploadFile={handleFileReUpload} setSelectedFile={handleSetSelectedFileName} /> */}
                                                                                    <CourseRecourseReUploadFileDropBox selectedFile={selectedFile} uploadFile={handleFileReupload} setSelectedFile={handleSetSelectedFileName} setSelectedFileSize={handleSetSelectedFileSize} recourseID={result.resourceId} />
                                                                                </div>
                                                                            </CustomTooltip>
                                                                        </TableCell>
                                                                        <TableCell align="left">
                                                                            <span onClick={() => handleRecourseActive(result.name, result.fileName, !result.active, result.sequence, result.resourceId)}>{result.active ? <ToggleRightIcon /> : <ToggleLeftIcon />}</span>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            component="th"
                                                                            scope="row"
                                                                            {...provider.dragHandleProps}
                                                                        >
                                                                            <DragIndicatorIcon />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provider.placeholder}
                                                    </TableBody>
                                                )}
                                            </Droppable>
                                        </Table>
                                    </DragDropContext>
                                </BrandedCard>
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", marginRight: "-5px", paddingTop: "10px" }}>
                            <span>
                                <CircularProgress
                                    style={{ width: (isFileUploading || isSaving) ? "20px" : "0px", marginTop: "5px" }}
                                    color="primary"
                                    size="10" />
                            </span>
                            <CustomTooltip displayText={["Next"]}>
                                <span>
                                    <Button                                        
                                        onClick={handleNext}
                                        variant="contained"
                                        color="primary"
                                        className="nextData">
                                         Next
                                    </Button>
                                </span>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Save"]}>
                                <span>
                                    <Button
                                        disabled={(isSaving || CourseAdditionalResources.length == 0)}
                                        onClick={handleSave}
                                        variant="contained"
                                        color="primary"
                                        className="saveData">
                                        Save
                                    </Button>
                                </span>
                            </CustomTooltip>
                            <CustomTooltip displayText={["Back"]}>
                                <span>
                                    <Button
                                        onClick={BackButtonRoute}
                                        variant="contained"
                                        color="primary"
                                        className="save"
                                    >
                                        Back
                                    </Button>
                                </span>
                            </CustomTooltip>
                        </div>
                    </div>
                </BrandedCard>
                <div className={styles.mainWrapper}>
                    {showWarningModal && <CommonPopupModalForResult saveSuccess={saveSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={saveSuccess ? 'Success' : hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
                </div>
            </div></>
    );
};

export default CourseAdditionalRecourse;