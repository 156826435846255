//React + Typescript
import React, { useEffect, useState, useContext, useRef } from 'react';
import { User, Language, UnitTreeItem, CourseTreeItem, UserModuleCombo } from '../../globalTypes';
import { useHistory, useLocation } from 'react-router-dom';
// Libs
import { useReactOidc } from '@axa-fr/react-oidc-context';
//Contexts
import { UIContext } from '../../contexts/UIContext';
import { TranslationsContext } from '../../contexts/TranslationsContext';
import { LoaderContext } from '../../contexts/LoaderContext';
import { CPDPanelContext } from '../../contexts/CPDPanelContext';
import oidcConfig from '../../configuration';
//Intro Lib
import { Steps } from "intro.js-react";
//API Functions
import { getRelevantUnitTree } from '../../API/GetRelevantUnitTree';
import { getCourseListByOrganisation } from '../../API/GetCourseListByOrganisation'
import { getPastCourseListByOrganisation } from '../../API/GetPastCourseListByOrganisation'
import { getFullCurrentAdminReport } from '../../API/GetFullCurrentAdminReport'
import { getUserModulesOfQuizStatusPending } from '../../API/GetUserModulesOfQuizStatusPending'
import { addError } from '../../API/AddError'
import { getClassificationListByType } from '../../API/GetClassificationListByType';
import getClassificationType from '../../API/GetClassificationType';
import TriggerStatusUpdateOrgWiseForToday from "../../API/Administration/Users/TriggerStatusUpdateOrgWiseForToday";
import TriggerStatusUpdateUnitAndCourseBased from "../../API/Administration/Users/TriggerStatusUpdateUnitAndCourseBased";
//Components
import CustomTooltip from "../../Components/CustomTooltip";
import LoaderContainer from '../../Components/LoaderContainer';
import BrandedCard from '../../Components/BrandedCard'
import UnitFilterButtons from './UnitFilterButtons'
import CourseFilterButtons from './CourseFilterButtons'
import CourseListTree from './CourseListTree'
import UnitTree from './UnitTree'
import ButtonPanel from './ButtonPanel'
import CommonPopupModalForResult from '../../Components/CommonPopupModalForResult';
//import MUICustomSelect from '../../Components/MUICustomSelect';
//MUI Components
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from "@material-ui/icons/Search";
//Styles
import styles from '../../styles/currentAdminReport.module.css'

import GuideIcon from '../../CustomIcons/GuideIcon';

//ReportCommon
import Courses from '../ReportCommonComponents/Courses';
import { Checkbox, IconButton } from '@material-ui/core';

// Infor/Error/Warning/Settings Pop-up
import InfoPopUp from "../../Components/InfoPopUp";
import SaveAPILibraryLogs from '../../API/Administration/Organisation/SaveAPILibraryLogs';
import Modal from '../../Components/Modal';
import ProceedIcon from '../../CustomIcons/ProceedIcon';
import CloseIcon from '../../CustomIcons/CloseIcon';
import ReportsParametersPreview from '../Administration/APILibrary/ReportParametersPreview';

interface Props {
    
}

const CurrentAdminReport = (props: Props) => {
    const location = useLocation<any>();
    const [units, setUnits] = useState<Array<UnitTreeItem>>(!!location && !!location.state && !!location.state.selectedUnits ? (location.state.selectedUnits as Array<UnitTreeItem>) : [])
    const [courseList, setCourseList] = useState<Array<CourseTreeItem>>(!!location && !!location.state && !!location.state.selectedCourses ? (location.state.selectedCourses as Array<CourseTreeItem>) : [])
    const [pastcourseList, setPastCourseList] = useState<Array<CourseTreeItem>>(!!location && !!location.state && !!location.state.selectedPastCourses ? (location.state.selectedPastCourses as Array<CourseTreeItem>) : []) 
    const [updatedPastCourseList, setUpdatedPastCourseList] = useState<Array<CourseTreeItem>>(!!location && !!location.state && !!location.state.selectedPastCourses ? (location.state.selectedPastCourses as Array<CourseTreeItem>) : []) 
    const [interactionCount, setInteractionCount] = useState<number>(0) //Required to force update the tree
    const [loading, setLoading] = useState<boolean>(true)
    const [isSmallUnitTree, setSmallUnitTree] = useState<boolean>(!!location && !!location.state && !!location.state.selectedUnits ? (location.state.selectedUnits as Array<UnitTreeItem>).length <= 10 : true);
    const [filterUnitName, setFilterUnitName]  = useState(!!location && !!location.state && !!location.state.filterUnitName ? location.state.filterUnitName.toString() : "");
    const [filterCourseName, setFilterCourseName]  = useState(!!location && !!location.state && !!location.state.filterCourseName ? location.state.filterCourseName.toString() : "");
    const [filterPastCourseName, setFilterPastCourseName]  = useState(!!location && !!location.state && !!location.state.filterPastCourseName ? location.state.filterPastCourseName.toString() : "");
    const [filterFirstName, setFilterFirstName] = useState(!!location && !!location.state && !!location.state.inputFilterFirstName ? location.state.inputFilterFirstName.toString() : "");
    const [filterLastName,setFilterLastName] = useState(!!location && !!location.state && !!location.state.inputFilterLastName ? location.state.inputFilterLastName.toString() : "");
    const [filterUserName,setFilterUserName] = useState(!!location && !!location.state && !!location.state.inputFilterUserName ? location.state.inputFilterUserName.toString() : "");
    const [filterEmail, setFilterEmail] = useState(!!location && !!location.state && !!location.state.inputFilterEmail ? location.state.inputFilterEmail.toString() : "");

    const [isResetClicked, setisResetClicked] = useState<boolean>(false);
    const [filterSubject, setFilterSubject] = useState("");
    const [filterBody, setFilterBody] = useState("");
    const [filterQuizStatus, setFilterQuizStatus] = useState("");
    const [filterSortBy, setFilterSortBy] = useState("0");
    const [filterExpiryValue, setFilterExpiryValue] = useState(0);
    const [filterExpiryUnit, setFilterExpiryUnit] = useState("d");
    const [filterAcceptance, setFilterAcceptance] = useState("0");
    const [filterOnlyUsersWithShortfall, setFilterOnlyUsersWithShortfall] = useState("N");
    const [filterLicensingPeriod, setFilterLicensingPeriod] = useState("");
    const [filterHistoricCourseIds, setFilterHistoricCourseIds] = useState("");
    const [filterPolicyIds, setFilterPolicyIds] = useState("");
    const [filterIncludeInactive, setFilterIncludeInactive] = useState<boolean>(!!location && !!location.state ? (location.state.inputFilterIncludeInactive === true) : false); // Only for HAR and by default true
    const [filterCourseStatus, setFilterCourseStatus] = useState("");
    const [filterDateFrom, setFilterDateFrom] = useState("");
    const [filterDateTo, setFilterDateTo] = useState(!!location && !!location.state && !!location.state.inputFilterDateTo ? location.state.inputFilterDateTo.toString() : "");

    const [isARTypeFocussed, setIsARTypeFocussed] = React.useState(false);
    const [filterAdminReportType, setFilterAdminReportType] = useState<any>(!!location && !!location.state ? (location.state.inputHistoricData === true ? 1 : 0) : 0);

    //const [classificationList, setClassificationList] = useState<Array<any>>([]);
    const [classificationList, setClassificationList] = useState(!!location && !!location.state && !!location.state.classificationList ? location.state.classificationList : []);
    const [selectedClassification, setSelectedClassification] = useState<any>(!!location && !!location.state && !!location.state.selectedClassification ? location.state.selectedClassification : {});
    const [groupingOption, setGroupingOption] = useState<boolean>(!!location && !!location.state ? (location.state.groupingOption === true) : false);
    const { setMessage } = useContext(LoaderContext);
    const { setTitle, setTitleEnglish, updateBackButtonRoute, updateBackButtonRouteProps, showSSOExitButton, EnableActivityLogs,IsAPILibraryEnabled } = useContext(UIContext);
    const { oidcUser } = useReactOidc();
    const { profile: { user_id, website }} = oidcUser || {};
    const userId = user_id ? parseInt(user_id) : 0;
    const orgId = oidcUser.profile.organisation_id ?? -1;
    const orgDomain = website ? website.toLowerCase().replace("altlogin-", "") /* To support the non-sso users of SSO orgs */ : "";
    const history = useHistory();
    const [isHistoryCourse, setIsHistoryCourse] = useState<boolean>(!!location && !!location.state && !!location.state.inputHistoricData ? (location.state.inputHistoricData === true) : false);
    //const [isHistoryChecked, setIsHistoryChecked] = useState<boolean>(false);
    
    const [isGroupingOptionFocussed, setIsGroupingOptionFocussed] = useState<boolean>(false);

    const { translations: { allResourcesToTranslations }, currentLanguage, pageTitles} = useContext(TranslationsContext)
    const { cpdList, currentCpd } = useContext(CPDPanelContext);
    const orgDomainWithAltLogin = window.location.host.toLowerCase();    
  
    // States for PopUp-MidOfTheWindow
    // const [isInputErrored, setIsInputErrored] = useState<boolean>(false);
    // const [showPopUp, setShowPopUp] = useState<boolean>(false);
    // const [popUpHeading, setPopUpHeading] = useState<string>("Error");
    // const [popUpMessages, setPopUpMessages] = useState<Array<string>>(["Info"]);

    // States for PopUp-BottomRight
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [modalPopupMessage, setModalPopupMessage] = useState("");
    const [hasErrored, setHasErrored] = useState<boolean>(false);

    // States related to status updates
    const [isStatusUpdatedForced, setIsStatusUpdatedForced] = useState<boolean>(false);
    const [statusPendingItems, setStatusPendingItems] = useState<Array<UserModuleCombo>>([]);
    const [isQuizStatusPending, setIsQuizStatusPending] = useState<boolean>(false);
    const [isToContinueRunReport, setIsToContinueRunReport] = useState<boolean>(false);
    const [isForceUpdateSuccess, setIsForceUpdateSuccess] = useState<boolean>(false);

    const [showAPIModal, setAPIModalOpen] = useState<boolean>(false);    
     const [intro, setIntro] = useState({
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: ".adminReportType",
            title: "Admin Report Type", intro: "Select 'Current Admin Report' to get a snapshot of a users current position, today, of their currently assigned course(s). Select 'Historic Admin Report' to get a snapshot of historic results based on a given date for any course(s) previously and/or currently assigned to your Salt Site."     
          },      
        ]
      })

    const setGuideMe = (groupingOption: boolean, clearOldList: boolean = false) => {
                    let updateSteps = clearOldList ? [] : [...intro.steps]
                    if (clearOldList) {
                        updateSteps.push(       
                            {
                                element: ".adminReportType",
                                title: "Admin Report Type", intro: "Select 'Current Admin Report' to get a snapshot of a users current position, today, of their currently assigned course(s). Select 'Historic Admin Report' to get a snapshot of historic results based on a given date for any course(s) previously and/or currently assigned to your Salt Site."     
                            }
                        );  
                    }
                    updateSteps.push(       
                        {
                            element: ".unitFilterButtons",
                            title: "Units", intro: allResourcesToTranslations.emrepgmunitsm,     
                        }
                    );  
                    updateSteps.push(       
                        {
                            element: ".unitFilterSearch",
                            title: "Filter Unit", intro: allResourcesToTranslations.unitfiltersearch                 
                        }
                    );  
                    if (units.length <= 10) {
                      updateSteps.push(      
                        {
                          element: ".courseFilterButtons",                   
                          title: "Select Course", intro: allResourcesToTranslations.emrepgmcsm
                        },
                        {
                            element: ".courseFilterSearch",
                            title: "Filter Course", intro: allResourcesToTranslations.emrepgmfcsm
                        }
                      ); 
                    }
                    else {
                        updateSteps.push(      
                          {
                            element: ".courseFilterButtonsnew",                   
                            title: "Select Course", intro: allResourcesToTranslations.emrepgmcsm
                          },
                          {
                            element: ".courseFilterSearchnew",
                            title: "Filter Course", intro: allResourcesToTranslations.emrepgmfcsm
                        }
                        ); 
                    }                    
                    if (filterAdminReportType == 1)
                    {
                        updateSteps.push(    
                        {
                            element:  ".dateTo",
                            title: "Filter", intro: "Filter Results by Historic Date. Current day is considered if left unselected."
                        });
                    }
                    // if(isHistoryCourse && filterAdminReportType == 1)
                    // //if (filterAdminReportType == 1)
                    // {
                    //     updateSteps.push({
                    //         element:  ".includeHistoricdata",
                    //         title: "Filter", intro: "Select to include Historic data by selecting past course."
                    //      }  
                    //      );  
                    // }
                    //if (isHistoryChecked) 
                    if (filterAdminReportType == 1)
                    {
                        updateSteps.push(       
                            {
                                element: ".pastCoursesSectionForHAR",
                                title: "Past Courses for Historic Admin Report", 
                                intro: "Select course(s) from 'Past Courses' to obtain historic results for course(s) previously assigned to your Salt Site, courses must be selected to be considered in results provided. Select any course(s) from the 'Course' list, these are active courses on your Salt Site, by default all courses are selected if no selection is made."                  
                            }
                        );
                    }
                    updateSteps.push(    
                    {
                        element:  ".firstName",
                        title: "Filter", intro: allResourcesToTranslations.firstnamemsg
                    } ,
                    {
                        element:  ".lastName",
                        title: "Filter", intro: allResourcesToTranslations.lastnamemsg
                    } ,
                    {
                        element:  ".userName",
                        title: "Filter", intro: allResourcesToTranslations.usernamemsg
                    } ,
                    {
                        element:  ".email",
                        title: "Filter", intro: allResourcesToTranslations.emailmsg
                    } );
                    if (groupingOption)
                    {
                        updateSteps.push({
                            element: ".groupby",
                            title: "Filter", intro: allResourcesToTranslations.repgmgroupingoption
                        });
                    }
                    if (filterAdminReportType == 1)
                    {
                        updateSteps.push(
                            {
                                element:  ".includeInactive",
                                title: "Filter", intro: allResourcesToTranslations.prrepgminactiveaccount
                            });
                    }
                    updateSteps.push(
                    {
                        element:  ".runReport",
                        title: "Run Report", intro: allResourcesToTranslations.sntemrepgmmrunrep
                    }); 
                
                    if (IsAPILibraryEnabled)
                        {
                            updateSteps.push({
                                element:  ".apicall",
                                title: "API Call", intro: allResourcesToTranslations.guidemeapicall
                            });
                        }

                    updateSteps.push(
                    {
                        element:  ".scheduleReport",
                        title: "Schedule Report", intro: allResourcesToTranslations.repgmschedulereport
                    }, 
                    {                    
                        element:  ".resetAll",
                        title: "Reset Filters", intro: allResourcesToTranslations.emrepgmram
                    }

                )
                setIntro({...intro, steps: [...updateSteps]})              
     };
    
    const onExit = () => {
        setIntro({...intro, stepsEnabled: false });
    };      

    const formatDate = (givenDate: Date) => // To format as yyyy-MM-dd
    {
        if (givenDate === null || givenDate === undefined)
        {
            return "";
        }

        const year = new Intl.DateTimeFormat('en-AU', {year: 'numeric'}).format(givenDate);
        const month = new Intl.DateTimeFormat('en-AU', {month: 'numeric'}).format(givenDate);
        const day = new Intl.DateTimeFormat('en-AU', {day: 'numeric'}).format(givenDate);

        return (year.toString() + "-" + 
        (parseInt(month) < 10 ? "0" + month.toString() : month.toString()) + "-" + 
        (parseInt(day) < 10 ? "0" + day.toString() : day.toString()));
    }

    const updateSelectedUnit = (id: number) => {
        let updatedUnits = units
        const updateSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isSelected = !unit.isSelected
                } 
                updateSelected(unit.childUnitTreeItems)
            }
        }
        updateSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const updateExpandedUnit = (id: number) => {
        let updatedUnits = units
        const checkForExpanded = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.unitID === id) {
                    unit.isExpanded = !unit.isExpanded
                } 
                checkForExpanded(unit.childUnitTreeItems)
            }
        }
        checkForExpanded(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnits = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = true;
                }
                updateAllChildUnits(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnits(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllChildUnits = (id: number) => {
        let updatedUnits = units
        let parentList = [id]
        const updateAllChildUnitsToUnselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (parentList.includes(unit.parentUnitID) || unit.unitID === id) {
                    parentList.push(unit.unitID)
                    unit.isSelected = false;
                }
                updateAllChildUnitsToUnselected(unit.childUnitTreeItems)
            }
        }
        updateAllChildUnitsToUnselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const isAnyUnitSelected = (givenUnits: Array<UnitTreeItem> | null) => {
        let updatedUnits = givenUnits
        const checkIfAnyUnitSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return false //Break if no children
            if (unitArray.length === 0) return false;
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected) {
                    return true;
                }
                if (checkIfAnyUnitSelected(unit.childUnitTreeItems)) {
                    return true;
                }
            }
            return false;
        }
        return checkIfAnyUnitSelected(updatedUnits);
    }

    const expandAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = true;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const collapseAllUnits = () => {
        let updatedUnits = units
        const checkForSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    unit.isExpanded = false;
                    checkForSelected(unit.childUnitTreeItems);
                }
            }
        }
        checkForSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const selectAllUnits = () => {
        let updatedUnits = units
        const setAllSelected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = true;
                    }
                    setAllSelected(unit.childUnitTreeItems);
                }
            }
        }
        setAllSelected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const deselectAllUnits = () => {
        let updatedUnits = units
        const setAllDeselected = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                if (!unitArray[i].isHidden) {
                    const unit = unitArray[i];
                    if (!unit.isDisabled) {
                        unit.isSelected = false;
                    }
                    setAllDeselected(unit.childUnitTreeItems);
                }                
            }
        }
        setAllDeselected(updatedUnits)
        setInteractionCount(interactionCount + 1)
        setUnits(updatedUnits)
    }

    const updateSelectedCourse = (id: number) => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (updatedCourses[i].courseID === id) {
                updatedCourses[i].isSelected = !updatedCourses[i].isSelected;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const selectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = true;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const deselectAllCourses = () => {
        let updatedCourses = courseList 
        for (let i = 0; i < updatedCourses.length; i++) {
            if (!updatedCourses[i].isHidden) {
                updatedCourses[i].isSelected = false;
            }
        }
        setInteractionCount(interactionCount + 1)
        setCourseList(updatedCourses)
    }

    const resetAll = () => {
        deselectAllCourses();
        deselectAllUnits();
        setFilterFirstName('');
        setFilterLastName('');
        setFilterEmail('');
        setFilterDateFrom('');
        setFilterUserName('');
        setisResetClicked(!isResetClicked);   
        setSelectedClassification({});
    }
    
    const getValidClassificationID = (selectedClassification: any) => {
        var classID : number = 0;
        if ((!!selectedClassification) && (!!selectedClassification.classificationID)) {
            classID = ((typeof selectedClassification.classificationID) !== 'number') ? 
                parseInt(selectedClassification.classificationID.toString()) : 
                selectedClassification.classificationID;
        }
        return classID;
    }

    const checkAndWarnPendingStatusesThenRunReport = () => {
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let pastcourseIds: Array<number> = [];
        let allPastCourseIds: Array<number> = [];
        const firstName: string = oidcUser.profile.given_name ?? "";
        const lastName: string = oidcUser.profile.family_name ?? "";
        const email: string = oidcUser.profile.email ?? "";
        const userName: string = oidcUser.profile.user_name ?? "";
        const date = new Date();
        //const dateString = `${date.getUTCFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}` //TODO fix hard coded date
        const dateString = formatDate(date);     
        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate()+1);
        const nextDateString = formatDate(nextDate); //`${nextDate.getUTCFullYear()}/${("0" + (nextDate.getUTCMonth() + 1)).slice(-2)}/${('0' + (nextDate.getUTCDate())).slice(-2)}` 
    
        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                if (filterUnitName !== "") {
                    if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    unitIds.push(unit.unitID)
                    }
                } else {
                    unitIds.push(unit.unitID)
                }
                }
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {        
            for (let i = 0; i < courseList.length; i++) {                
                if (courseList[i].isSelected) {                  
                    courseIds.push(courseList[i].courseID);
                }    
                allCourseIds.push(courseList[i].courseID);           
            }           
        }
        getSelectedCourses()

        const getSelectedPastCourses = () => {           
            for (let i = 0; i < pastcourseList.length; i++) {
                if (pastcourseList[i].isSelected) {   
                    pastcourseIds.push(pastcourseList[i].courseID);  
                }   
                allPastCourseIds.push(pastcourseList[i].courseID);                     
            }     
        }       
        getSelectedPastCourses();

        //setMessage("Fetching your report");
        setMessage("Checking the pending quiz status updates");
        setLoading(true)
        
        // PENDING QUIZ STATUS CHECK
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started the check for the pending quiz status updates for the input in NEWSALT ClientApp", null, "CurrentAdminReport.tsx", 6, EnableActivityLogs);
        getUserModulesOfQuizStatusPending(
            oidcUser.access_token, userId, orgDomain, parseInt(orgId), 
            unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
            //(!!courseIds && courseIds.length > 0 && isHistoryChecked && !!pastcourseIds && pastcourseIds.length > 0) ?
            (!!courseIds && courseIds.length > 0 && filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.length > 0) ?
                (courseIds.toString() +","+ pastcourseIds.toString()) :
                //((isHistoryChecked && !!pastcourseIds && pastcourseIds.toString().length >=1) ? 
                ((filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.toString().length >=1) ? 
                    pastcourseIds.toString() : 
                    (!!courseIds && courseIds.length > 0 ? 
                        courseIds.toString() : 
                        allCourseIds.toString())),
            firstName, lastName, userName, email,
            // dateString,                
            ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? nextDateString : filterDateTo),
            "Unit_User", "LAST_NAME", filterIncludeInactive,
            getValidClassificationID(selectedClassification), 
            filterFirstName, filterLastName, filterUserName, filterEmail
            ).then((reportRes) => {
                if (!!reportRes) {
                    if (reportRes.isSuccess && !!reportRes.error == false) {
                        if (!!reportRes.pendingItems && reportRes.pendingItems.length > 0)
                        {
                            setLoading(false);
                            setStatusPendingItems(reportRes.pendingItems);
                            setIsQuizStatusPending(true);

                            setIsToContinueRunReport(true);

                            // // Force silent update
                            // TriggerStatusUpdateOrgWiseForToday(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((updateRes) => {
                            //     if (!!updateRes && updateRes.isSuccess) {            
                            //         setIsQuizStatusPending(false);
                            //         setStatusPendingItems([]);
                            //         setLoading(false);
                            //     }
                            //     else {
                            //         console.log("Silent forced update of pending statuses failed");
                            //         setLoading(false);
                            //     }
                            // })

                            // Show Warning Pop up about the missed/pending quiz status updates
                            setModalPopupMessage("As there are '" + reportRes.pendingItems.length.toString() + 
                                 "' pending quiz status updates, the results may not be complete until this processing has finished");
                            setHasErrored(false);
                            setShowWarningModal(true);

                            // setPopUpMessages(["As there are '" + reportRes.pendingItems.length.toString() + 
                            //                     "' pending quiz status updates, the results may not be complete until this processing has finished"]);
                            // setIsInputErrored(true);
                            // setShowPopUp(true);
                            // setPopUpHeading("Warning");
                        }
                        else {
                            if (!!reportRes === false || !!reportRes.pendingItems === false) {
                                console.log("Checking the pending status updates failed returning invalid");
                            }
                            setIsToContinueRunReport(false);
                            setIsQuizStatusPending(false);
                            doTheActualRunOfCAR();
                            //setLoading(false);
                        }
                    }
                    else {
                        console.log("Checking the pending status updates failed");
                        doTheActualRunOfCAR();
                        //setLoading(false);
                    }
                }
                else {
                    console.log("Checking the pending status updates failed");
                    doTheActualRunOfCAR();
                    //setLoading(false);
                }
            }).catch(() => {
                console.log("Checking the pending status updates or running the report failed");      
                setLoading(false);
            });        
    }

    const runReport = () => {
        checkAndWarnPendingStatusesThenRunReport();
    }
    
    const doTheActualRunOfCAR = () => {
        
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let pastcourseIds: Array<number> = [];
        let allPastCourseIds: Array<number> = [];
        const firstName: string = oidcUser.profile.given_name ?? "";
        const lastName: string = oidcUser.profile.family_name ?? "";
        const email: string = oidcUser.profile.email ?? "";
        const userName: string = oidcUser.profile.user_name ?? "";
        const date = new Date();
        //const dateString = `${date.getUTCFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)}` //TODO fix hard coded date
        const dateString = formatDate(date);     
        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate()+1);
        const nextDateString = formatDate(nextDate); //`${nextDate.getUTCFullYear()}/${("0" + (nextDate.getUTCMonth() + 1)).slice(-2)}/${('0' + (nextDate.getUTCDate())).slice(-2)}` 
    
        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                if (filterUnitName !== "") {
                    if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    unitIds.push(unit.unitID)
                    }
                } else {
                    unitIds.push(unit.unitID)
                }
                } 
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {        
            for (let i = 0; i < courseList.length; i++) {                
                if (courseList[i].isSelected) {                  
                    courseIds.push(courseList[i].courseID);
                }    
                allCourseIds.push(courseList[i].courseID);           
            }           
        }
        getSelectedCourses()

        const getSelectedPastCourses = () => {           
            for (let i = 0; i < pastcourseList.length; i++) {
                if (pastcourseList[i].isSelected) {   
                    pastcourseIds.push(pastcourseList[i].courseID);  
                }   
                allPastCourseIds.push(pastcourseList[i].courseID);                     
            }     
        }       
        getSelectedPastCourses();

        setMessage("Fetching your report");
        setLoading(true)
        // Activity log 
        addError(oidcUser.access_token, userId, orgDomain, "Started CurrentAdmin Report in NEWSALT ClientApp", null, "CurrentAdminReport.tsx", 6, EnableActivityLogs);

        getFullCurrentAdminReport(
            oidcUser.access_token, userId, orgDomain, parseInt(orgId), 
            unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString(), 
            //(!!courseIds && courseIds.length > 0 && isHistoryChecked && !!pastcourseIds && pastcourseIds.length > 0) ?
            (!!courseIds && courseIds.length > 0 && filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.length > 0) ?
                (courseIds.toString() +","+ pastcourseIds.toString()) :
                //((isHistoryChecked && !!pastcourseIds && pastcourseIds.toString().length >=1) ? 
                ((filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.toString().length >=1) ? 
                    pastcourseIds.toString() : 
                    (!!courseIds && courseIds.length > 0 ? 
                        courseIds.toString() : 
                        allCourseIds.toString())),
            firstName, lastName, userName, email,
            // dateString,                
            ((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? nextDateString : filterDateTo),
            "Unit_User", "LAST_NAME", filterIncludeInactive,
            getValidClassificationID(selectedClassification), 
            filterFirstName, filterLastName, filterUserName, filterEmail, 
            //(isHistoryChecked && !!pastcourseIds && pastcourseIds.length > 0)
            //(filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.length > 0)
            (filterAdminReportType == 1)
            ).then((reportRes) => {
                history.push({
                    pathname: `currentadminreport/result`,
                    state: {             
                        selectedUnits: units,
                        selectedCourses: courseList,
                        selectedPastCourses: updatedPastCourseList, 
                        filterCourseName: filterCourseName,
                        filterPastCourseName: filterPastCourseName,
                        filterUnitName: filterUnitName,
                        inputFilterFirstName: filterFirstName,
                        inputFilterLastName: filterLastName,
                        inputFilterUserName: filterUserName,
                        inputFilterEmail: filterEmail,
                        inputFilterIncludeInactive: filterIncludeInactive,
                        inputHistoricData: 
                            //(isHistoryChecked && !!pastcourseIds && pastcourseIds.length > 0)? true : false ,                           
                            //(filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.length > 0)? true : false ,                           
                            filterAdminReportType == 1 ? true : false ,                           
                        inputFilterDateTo: filterDateTo,
                        classificationList: classificationList,
                        groupingOption: groupingOption,
                        selectedClassification: selectedClassification,
                        groupBy: ((selectedClassification === null || selectedClassification === undefined || 
                            selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value),
                        selectedClassificationID: getValidClassificationID(selectedClassification.classificationID),
                        reportTitle:  
                            //(isHistoryChecked && courseIds.length > 0 && pastcourseIds.length > 0) 
                            //(filterAdminReportType == 1 && courseIds.length > 0 && pastcourseIds.length > 0) 
                            (filterAdminReportType == 1) 
                                        //? "Current - Historic Admin Report": (
                                        ? "Historic Admin Report": (
                                            //(isHistoryChecked && pastcourseIds.length > 0) 
                                            //(filterAdminReportType == 1 && pastcourseIds.length > 0) 
                                            (filterAdminReportType == 1) 
                                        ? "Historic Admin Report" : "Current Admin Report"),
                        exportreportTitle:  
                            //(isHistoryChecked && courseIds.length > 0 && pastcourseIds.length > 0)  ? 
                            (filterAdminReportType == 1 && courseIds.length > 0 && pastcourseIds.length > 0)  ? 
                                    //"Current_Historic_AdminReport": 
                                    "Historic_AdminReport": 
                                    //((isHistoryChecked && pastcourseIds.length > 0) ? "HistoricAdminReport" : "CurrentAdminReport"),
                                    ((filterAdminReportType == 1 && pastcourseIds.length > 0) ? "HistoricAdminReport" : "CurrentAdminReport"),
                        resultItems: (reportRes === null ? null : reportRes.currentAdminReportItems),
                        resultError: ((reportRes === null || reportRes === undefined || reportRes.isSuccess || reportRes.error === null || reportRes.error === undefined) ? "" : reportRes.error)
                    },
                });
                setLoading(false)
            })
    }

    // const handleShowPopUp = (value: boolean) => {
    //   setShowPopUp(value);
    // }
  
    // const handlePopUpClose = () => {
    //   setShowPopUp(false);
    //   doTheActualRunOfCAR();
    // }

    // const handlePopUpProceed = () => {
    //   setShowPopUp(false);
    //   doTheActualRunOfCAR();
    // }

    const parseAdminConsoleUrl = (url: string): string => {
        if (url === "https://" + oidcConfig.stagingdomain || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomain + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainSA || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainSA + ":444";
        } else if (url === "https://" + oidcConfig.stagingdomainForDeactTest || url.includes('localhost')) {
            return "https://" + oidcConfig.stagingdomainForDeactTest + ":444";
        } else {
            if (showSSOExitButton && orgDomainWithAltLogin.toLowerCase().indexOf("altlogin-") < 0) {
                if (url.toLowerCase().includes('https:')) {
                    return `${url}:444`;
                }
                else if  (url.toLowerCase().includes('http:')) {
                    var locUrl = url.toLowerCase().replace("http:", "https:")
                    return `${locUrl}:444`;
                }
                else {
                    return `https://${url}:444`;
                }
            }
            else {
                return `${url}:444/altlogin.aspx`;
            }
        }
    }

    const setModalOpen = (isOpen: boolean) => 
    {
        let cpdProfileId = -1
        cpdProfileId = (
            (cpdList !== null && 
            cpdList !== undefined && 
            cpdList.length > 0 && 
            currentCpd >= 0 && 
            cpdList.length > currentCpd) ? 
            cpdList[currentCpd].id : -1)
        history.push({
            pathname: `/CAR/reportscheduler/-1`,
            state: {                
                selectedUnits: units,
                selectedCourses: courseList,
                firstName: filterFirstName,
                lastName: filterLastName,
                userName: filterUserName,
                email: filterEmail,
                isIncludeInactive: filterIncludeInactive,
                classificationList: classificationList,
                groupingOption: groupingOption,
                selectedClassification: selectedClassification,
                groupBy: ((selectedClassification === null || selectedClassification === undefined || 
                    selectedClassification.value === null || selectedClassification.value === undefined) ? "" : selectedClassification.value),
                selectedClassificationID: getValidClassificationID(selectedClassification.classificationID),
                dateFrom: filterDateFrom,
                historicdate:filterDateTo,
                dateTo: filterDateTo,
                subject: filterSubject,
                body: filterBody,
                status: filterCourseStatus,
                quizStatus: filterQuizStatus,
                sortBy: filterSortBy,
                langCode: currentLanguage,
                expiryValue: filterExpiryValue,
                expiryUnit: filterExpiryUnit,
                profileID: cpdProfileId,
                acceptance: filterAcceptance,
                onlyUsersWithShortfall: filterOnlyUsersWithShortfall,
                licensingPeriod: filterLicensingPeriod,
                reportId: 3,  // 3 is for CAR                
                policyIds: filterPolicyIds,
                selectedPastCourses: pastcourseList, 
                //isHistoryChecked: isHistoryChecked
                isHistoryChecked: filterAdminReportType == 1
            },
        }); 
    }

    const isGivenItemNameMatched = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        if (item === null || item === undefined || item.name === null || item.name === undefined) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            if (item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase())) {
                return true;
            }
            return false;
        }
    }

    const isAnyChildItemNameMatched = (list: UnitTreeItem[] | null, givenFilterUnitName: string): boolean | undefined =>
    {
        if (list === null || list === undefined || list.length <= 0) {
            return false;
        }
        if (givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") {
            return true;
        }
        else
        {
            var newList = list.filter((e) => { return (isGivenItemNameMatched(e, givenFilterUnitName) === true || isAnyChildItemNameMatched(e.childUnitTreeItems, givenFilterUnitName) === true); });
            if (newList !== null && newList !== undefined && newList.length > 0) {
                return true;
            }
            return false;
        }
    }
  
    const updateUnitItemBasedOnNameMatch = (item: UnitTreeItem, givenFilterUnitName: string): boolean | undefined =>
    {
        let isMatchFound = false;
        if ((givenFilterUnitName === null || givenFilterUnitName === undefined || givenFilterUnitName.trimLeft().trimRight() === "") && 
            item !== null && item !== undefined) {
            isMatchFound = true;
            item.isDisabled = !isMatchFound;
            item.isHidden = !isMatchFound;
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        else if (givenFilterUnitName !== null && givenFilterUnitName !== undefined && givenFilterUnitName.trimLeft().trimRight() !== "" && 
            item !== null && item !== undefined) {
                
            isMatchFound = (item.name !== null && item.name !== undefined && item.name.toLowerCase().includes(givenFilterUnitName.toLowerCase()));
            if (isMatchFound) {
                item.isDisabled = false;
                item.isHidden = false;
            }
            else if (!isMatchFound && (item.childUnitTreeItems == null || item.childUnitTreeItems === undefined || item.childUnitTreeItems.length <= 0)){
                item.isDisabled = true;
                item.isHidden = true;
            }
            else if (!isMatchFound){
                let curAnyChildNameMatched = isAnyChildItemNameMatched(item.childUnitTreeItems, givenFilterUnitName);
                item.isDisabled = (curAnyChildNameMatched === true);
                item.isHidden = (curAnyChildNameMatched === false);
                isMatchFound = (curAnyChildNameMatched === true);
            }
            if (item.childUnitTreeItems !== null && item.childUnitTreeItems !== undefined && item.childUnitTreeItems.length > 0)
            {
                item.childUnitTreeItems.forEach(function (e) {            
                    let isMatchFoundSub : boolean | undefined = false;
                    isMatchFoundSub = updateUnitItemBasedOnNameMatch(e, givenFilterUnitName);
                });   
            }
        }
        return isMatchFound;
    }   
    useEffect(() => {
        setTitle(allResourcesToTranslations.currentadminreport)
        setTitleEnglish(pageTitles.currentadminreport)
        if (!!location && !!location.state) {  
            updateBackButtonRoute("");
            updateBackButtonRouteProps({});  
            setGuideMe(location?.state?.groupingOption);      
            setLoading(false);
        }
        else {
            updateBackButtonRoute("")
            getRelevantUnitTree(oidcUser.access_token, userId, orgDomain, true, parseInt(orgId)).then((unitTreeRes) => {
                if (unitTreeRes.isSuccess) {
                    setUnits(unitTreeRes.unitTreeItems)
                    setSmallUnitTree(unitTreeRes.unitTreeItems.length <= 10);               
                }
                getCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId), true).then((courseListRes) => {
                    if (courseListRes.isSuccess) {
                        setCourseList(courseListRes.courselistItem);
                    }
                    getPastCourseListByOrganisation(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((pastcourseListRes) => {
                        if (pastcourseListRes.isSuccess) {
                            if (pastcourseListRes !== null && pastcourseListRes.pastCourseListItems !== null && pastcourseListRes.pastCourseListItems.length > 0 )
                            {
                                setIsHistoryCourse(true)
                                setPastCourseList(pastcourseListRes.pastCourseListItems)
                            }
                        }
                        getClassificationType(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((classType) => {
                    
                            if(classType !== null && classType.classificationTypeIDs !== null && 
                                classType.classificationTypeIDs.length > 0 && classType.classificationTypeIDs[0] > 0) {
                                getClassificationListByType(oidcUser.access_token, userId, orgDomain, parseInt(orgId), classType.classificationTypeIDs[0]).then((data) => {
                                    
                                    if (data !== null && data.classificationlistItem !== null) {
                                        setClassificationList(data.classificationlistItem)
                                        setGroupingOption(true);
                                        units.length = unitTreeRes.unitTreeItems.length;
                                        setGuideMe(true);
                                    }
                                    else {
                                        setGroupingOption(false);
                                        setGuideMe(false);
                                    }
                                })
                            }
                            else {
                                setGroupingOption(false);
                            }

                            // To collect the pending statuses upfront upon loading this page so that the force update button can be displayed
                            //collectAndForceUpdateUsersWithPendingStatuses();
                            
                            setLoading(false);
                            units.length = unitTreeRes.unitTreeItems.length;
                            setGuideMe(groupingOption);    
                        })
                })              
                })          
            })
        }
    },[]) 

    useEffect(() => {
    },[filterDateFrom]) 

    const handleClosePopup = () => {
        setShowWarningModal(false);
        if (isToContinueRunReport) {
            doTheActualRunOfCAR();
            setIsToContinueRunReport(false);
        }
        else {
            setIsForceUpdateSuccess(false);
        }
    };

    const handleTriggerMissedUpdates = () => {
        if (!!statusPendingItems && statusPendingItems.length > 0) {
            let unitsUnique : Array<number> = [];
            let coursesUnique : Array<number> = [];
            let unitsUniqueCSV : string = "";
            let coursesUniqueCSV : string = "";
            for (let i=0; i<statusPendingItems.length; i++) {
                if (!!statusPendingItems[0]) {
                    let curObj = statusPendingItems[0];
                    if (!!curObj.unitID && !unitsUnique.includes(curObj.unitID)) {
                        unitsUnique.push(curObj.unitID);
                        unitsUniqueCSV = (unitsUniqueCSV == "" ) ? curObj.unitID.toString() : (unitsUniqueCSV + "," + curObj.unitID.toString());
                    }
                    if (!!curObj.courseID && !coursesUnique.includes(curObj.courseID)) {
                        coursesUnique.push(curObj.courseID);
                        coursesUniqueCSV = (coursesUniqueCSV == "" ) ? curObj.courseID.toString() : (coursesUniqueCSV + "," + curObj.courseID.toString());
                    }
                }
            }
            //setLoading(true);
            setIsStatusUpdatedForced(true);
            if (coursesUniqueCSV.trim() != "" || unitsUniqueCSV.trim() != "") {
                TriggerStatusUpdateOrgWiseForToday(oidcUser.access_token, userId, orgDomain, parseInt(orgId)).then((updateRes) => {
                //TriggerStatusUpdateUnitAndCourseBased(oidcUser.access_token, userId, orgDomain, parseInt(orgId), unitsUniqueCSV, coursesUniqueCSV).then((updateRes) => {
                    if (!!updateRes && updateRes.isSuccess) {
                        setModalPopupMessage("Missed statuses are updated now");
                        setHasErrored(false);
                        setShowWarningModal(true);

                        // setPopUpMessages(["Missing statuses are updated now"]);
                        // setIsInputErrored(true);
                        // setShowPopUp(true);
                        // setPopUpHeading("Success");

                        setIsQuizStatusPending(false);
                        setIsForceUpdateSuccess(true);
                        setIsStatusUpdatedForced(false);
                    }
                    else {
                        setModalPopupMessage("Failed to update the statuses due to error" + (!!updateRes.error ? " as '" + updateRes.error + "'" : "" ));
                        setHasErrored(true);
                        setShowWarningModal(true);

                        // setPopUpMessages(["Failed to update the statuses due to error" + (!!updateRes.error ? " as '" + updateRes.error + "'" : "" )]);
                        // setIsInputErrored(true);
                        // setShowPopUp(true);
                        // setPopUpHeading("Error");

                        setIsQuizStatusPending(true);
                        setIsForceUpdateSuccess(false);
                        setIsStatusUpdatedForced(false);
                    }
                })
            }
            else {
                setModalPopupMessage("There are no missing statuses to update!");
                setHasErrored(false);
                setShowWarningModal(true);

                setIsQuizStatusPending(false);

                // setPopUpMessages(["There are no missing statuses to update!"]);
                // setIsInputErrored(true);
                // setShowPopUp(true);
                // setPopUpHeading("Info");
            }
            //setLoading(false);
        }
    }
  
    // useEffect(() => {
    //     setGuideMe(groupingOption, true);
    // },[isHistoryChecked]) 
  
    useEffect(() => {
        setGuideMe(groupingOption, true);
    },[filterAdminReportType]) 
  
    useEffect(() => {
        setGuideMe(groupingOption, true);
    },[IsAPILibraryEnabled]) 
  
    useEffect(() => {
        if (units !== null && units !== undefined && units.length > 0) {
            var newUnits = units.map(function(e) {
                const retFlag = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && updateUnitItemBasedOnNameMatch(e, filterUnitName.toLowerCase())); 
                return e;
            });
            setUnits(newUnits);
        }
    },[filterUnitName]) 

    useEffect(() => {
        if (courseList !== null && courseList !== undefined && courseList.length > 0) {
            var newCourses = courseList.map(function(e) {
                if (filterCourseName === null || filterCourseName === undefined || filterCourseName.trimRight().trimLeft() === ""){
                    e.isHidden = false; 
                }
                else {
                    e.isHidden = !(e !== null && e !== undefined && e.name !== null && e.name !== undefined && e.name.toLowerCase().includes(filterCourseName.toLowerCase())); 
                }
                return e;
            });
            setCourseList(newCourses);            
        }
    },[filterCourseName]) 

     //#region API Library
    const GenerateAPIRequestBody = () => {       
        setAPIModalOpen(true);   
        setLoading(false)         
    } 
    const handleAPIModal = (value:boolean) => {
    setAPIModalOpen(value);
    }
    const runAPIQuery = () => {     
        let unitIds: Array<number> = [];
        let allUnitIds: Array<number> = [];
        let courseIds: Array<number> = [];
        let allCourseIds: Array<number> = [];
        let pastcourseIds: Array<number> = [];
        let allPastCourseIds: Array<number> = [];   
        
    
        const nextDate = new Date()
        nextDate.setDate(nextDate.getDate()+1);
        const nextDateString = formatDate(nextDate); 


        const getSelectedUnitIds = (unitArray: Array<UnitTreeItem> | null) => {
            if (unitArray === null) return //Break if no children
            if (unitArray.length === 0) return
            for (let i = 0; i < unitArray.length; i++) {
                const unit = unitArray[i];
                if (unit.isSelected && !unit.isDisabled && unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) { 
                if (filterUnitName !== "") {
                    if (unit.name.toLowerCase().includes(filterUnitName.toLowerCase())) {
                    unitIds.push(unit.unitID)
                    }
                } else {
                    unitIds.push(unit.unitID)
                }
                } 
                allUnitIds.push(unit.unitID);
                getSelectedUnitIds(unit.childUnitTreeItems);
            }
        }
        getSelectedUnitIds(units)

        const getSelectedCourses = () => {        
            for (let i = 0; i < courseList.length; i++) {                
                if (courseList[i].isSelected) {                  
                    courseIds.push(courseList[i].courseID);
                }    
                allCourseIds.push(courseList[i].courseID);           
            }           
        }
        getSelectedCourses()

        const getSelectedPastCourses = () => {           
            for (let i = 0; i < pastcourseList.length; i++) {
                if (pastcourseList[i].isSelected) {   
                    pastcourseIds.push(pastcourseList[i].courseID);  
                }   
                allPastCourseIds.push(pastcourseList[i].courseID);                     
            }     
        }       
        getSelectedPastCourses();    
      

let str=`{"ReportRequestingUserID":${userId},
"OrganisationID":${parseInt(orgId)},
"UnitIDs":"${unitIds.length > 0 ? unitIds.toString() : allUnitIds.toString()}",
"CourseIDs":"${((!!courseIds && courseIds.length > 0 && filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.length > 0) ?
(courseIds.toString() +","+ pastcourseIds.toString()) :
((filterAdminReportType == 1 && !!pastcourseIds && pastcourseIds.toString().length >=1) ? 
pastcourseIds.toString() : 
(!!courseIds && courseIds.length > 0 ? 
courseIds.toString() : 
allCourseIds.toString())))}",
"HistoricCourseIDs":"${(( filterAdminReportType == 1  && !!pastcourseIds && pastcourseIds.toString().length >=1) ? pastcourseIds.toString(): "")}",  
"userFirstName":"${filterFirstName}",
"userLastName":"${filterLastName}",
"userName":"${filterUserName}",
"userEmail":"${filterEmail}",
"effectiveDateOrg":"${((filterDateTo === null || filterDateTo === undefined || filterDateTo.trimLeft().trimRight() === "") ? nextDateString : filterDateTo)}",
"groupBy":"Unit_User",
"sortBy":"LAST_NAME",
"adminUserID":${userId},
"ClassificationID":${getValidClassificationID(selectedClassification)},
"inclInactive":${filterIncludeInactive},
"IsHistoricReport":${filterAdminReportType == 1 ? true : false}
}`;

        setMessage("Fetching your report");

        SaveAPILibraryLogs(oidcUser.access_token, userId, orgDomain, parseInt(orgId), filterAdminReportType == 1 ? "HistoricAdminReport" : "CurrentAdminReport", str).then((res) => {
            if (res.isSuccess) { 
                history.push({
                    pathname: `apilibrarydetail/CurrentAdminReport`,
                    state: {                                   
                        inputLogId: res.logsId ,                                                     
                    },
                });

            }
            else {              
                setModalPopupMessage("API Library Log Save Failed!");
                setHasErrored(false);
                setShowWarningModal(true);
            }
        });
        setLoading(false)         
    } 
    
    
    
        useEffect(() => {
            if (!!location && !!location.state) {           
            //  setFilterDateTo(location.state.inputFilterDateTo);
          //  setFilterDateFrom(location.state.inputFilterDateFrom);
    
          let formatDateTo = "";
          let tempDateTo = [""];
          if (location.state.inputFilterDateTo !== null && location.state.inputFilterDateTo !== undefined && location.state.inputFilterDateTo !== ""){
            formatDateTo = location.state.inputFilterDateTo.trimLeft().trimRight().split(" ")[0];
            if (formatDateTo.indexOf("/") > 0) {
              tempDateTo = formatDateTo.split("/");
              setFilterDateTo(formatDate(new Date(parseInt(tempDateTo[2]), parseInt(tempDateTo[1])-1, parseInt(tempDateTo[0]))));
            } else {
              setFilterDateTo(formatDateTo);
            }       
          }
    
    
            }     
        
        }, [location]);
        
//#endregion
    if (loading) return <LoaderContainer/>

    return (
        <>
        {/* <InfoPopUp key={"carPendingUpdatesWarningPopUp"}
            isError={isInputErrored}
            givenPopUpHeading={popUpHeading}
            givenShowPopUp={showPopUp}
            givenMessages={popUpMessages}
            givenSettingsList={[]}
            onHandleShowPopUp={handleShowPopUp}
            onPopUpClose={handlePopUpClose}
            onPopUpProceed={handlePopUpProceed}
            givenFlagToHideTopRightCloseBtn={true} 
            isInfoErrorHighlightRequired={false}
            givenSubBulletRequiredSettingTitles={[]} /> */}
        <Steps
          enabled={intro.stepsEnabled}
          steps={intro.steps}
          initialStep={intro.initialStep}
          onExit={onExit}
          options={{
            nextLabel: 'Next',
            prevLabel: 'Back',
            tooltipClass: 'sample-class',
            showBullets: true,
          }}
        />        

        <Modal type="warning" modalOpen={showAPIModal} setModalOpen={handleAPIModal} style={{ height: '232px', width: '381px' }}
        hideCloseBtn={true}>  
                <ReportsParametersPreview  
                units={units} 
                courseList={courseList}
                pastcourseList={pastcourseList}
                filterFirstName={filterFirstName}
                filterLastName={filterLastName}
                filterUserName={filterUserName}
                filterEmail={filterEmail}            
                filterIncludeInactive={filterIncludeInactive}         
                classificationList={classificationList}   
                filterClassificationID={getValidClassificationID(selectedClassification)}
                reportId={3}
                report={'car'}
                filterCourseStatus={''}
                ></ReportsParametersPreview>

        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '10px' }}>
          <CustomTooltip displayText={["Return"]}>
            <span className={styles.returnIcon} >
              <span
                onClick={() => {
                  setAPIModalOpen(false)               
                }}>
                <CloseIcon />
              </span>
            </span>
          </CustomTooltip>
          <CustomTooltip displayText={["Proceed"]}>
            <span className={styles.proceedIcon}>
              <span
                onClick={() => {
                    runAPIQuery();
                }}>
                <ProceedIcon />
              </span>
            </span>
          </CustomTooltip>
        </div>
        </Modal>

        <div>
            <Steps enabled={intro.stepsEnabled} steps={intro.steps} initialStep={intro.initialStep}
                onExit={onExit}
                options={{
                    nextLabel: 'Next',
                    prevLabel: 'Back',
                    tooltipClass: 'sample-class',
                    showBullets: true,
                }} />
            <CustomTooltip displayText={["Guide Me"]}>               
               <span onClick={() => setIntro({...intro, stepsEnabled: true})} className={styles.guideMeButtonSpecific}>
                      <GuideIcon />
                    </span>
            </CustomTooltip>
            </div>
            <BrandedCard>                 
                <div className={styles.reportWrraper}>
                   <div className={styles.unittreesection}>
                        <div style={{ paddingTop: '20px'}} className="adminReportType">
                             <FormControl variant="outlined" className={styles.formControl}>
                                <InputLabel id="selNewSaltARType" focused={isARTypeFocussed} style={{fontWeight: 'bolder', fontSize: '40', color: isARTypeFocussed ? '#63C2FF' : 'black'}}>I want to report on :</InputLabel>
                                <Select
                                    id="selNewSaltARType"
                                    native
                                    value={filterAdminReportType}
                                    label="I want to report on:"
                                    variant="outlined"
                                    onChange={(e) => {
                                            setFilterAdminReportType(e.target.value);
                                            if (!!e.target.value && (e.target.value === "0")) {
                                                setFilterIncludeInactive(false);
                                            }
                                            else if (!!e.target.value && (e.target.value === "1")) {
                                                setFilterIncludeInactive(true);
                                            }
                                        }
                                    }>  
                                    <optgroup label="I want to report on:">
                                        <option value={0}>Current Admin Report</option>
                                        <option value={1}>Historic Admin Report</option>
                                    </optgroup>
                                </Select>
                            </FormControl>
                        </div>
                        <br/>
                        <div>
                            <h4 className={styles.sectionTitle}>Unit</h4>
                            <p className={styles.blankContent}>Leave blank for all Units</p>
                            <div className="unitFilterButtons"><UnitFilterButtons expandAllUnits={expandAllUnits} colapseAllUnits={collapseAllUnits} selectAllUnits={selectAllUnits} deselectAllUnits={deselectAllUnits}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="unitFilterSearch"><TextField  className={styles.input}  label={"Unit Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterUnitName} onChange={(e) => setFilterUnitName(e.target.value)} 
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.treeContianer}>
                            <UnitTree units={units} updateSelectedUnit={updateSelectedUnit} updateExpandedUnit={updateExpandedUnit} selectAllChildUnits={selectAllChildUnits} deselectAllChildUnits={deselectAllChildUnits} isAnyUnitSelected={isAnyUnitSelected} />
                        </div>
                         <div hidden={isSmallUnitTree === false} style={{paddingTop: "10px"}}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div className="courseFilterButtons"><CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === false}>
                            <div className="courseFilterSearch"> <TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}           
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === false}>                        
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltCAR_" />
                        </div> 
                    </div>
                    <div className={styles.coursetreesection_shifteddown}>
                        {/* <div hidden={!isQuizStatusPending} style={{marginTop: "-90px", paddingBottom: "50px"}}>
                            <CustomTooltip displayText={["To force the missing updates to happen now synchronously"]} hidden={!isQuizStatusPending}>
                                <Button
                                    disabled={isStatusUpdatedForced}
                                    hidden={!isQuizStatusPending}
                                    onClick={handleTriggerMissedUpdates}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="small" 
                                    id="btnTriggerMissedUpdates"
                                    title="Force Update of Missing Statuses"
                                >
                                    <PlayArrowIcon />
                                </Button>
                            </CustomTooltip>
                            {"Force Update of Missing Statuses"}
                            <div className={styles.loaderContainer}>
                                <CircularProgress
                                style={{ width: isStatusUpdatedForced ? "20px" : "0px", paddingLeft: "20px", paddingRight: "20px" }}
                                color="primary"
                                size="10"
                                />
                            </div>
                        </div> */}
                        <div hidden={isSmallUnitTree === true}>
                            <h4 className={styles.sectionTitle}>Course</h4>
                            <p className={styles.blankContent}>Leave blank for all Courses</p>
                            <div className="courseFilterButtonsnew"> <CourseFilterButtons selectAllCourses={selectAllCourses} deselectAllCourses={deselectAllCourses}/></div>
                        </div>
                        <div className={styles.inputContainer} hidden={isSmallUnitTree === true}>
                            <div className="courseFilterSearchnew"><TextField  className={styles.input}  label={"Course Search"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                            value={filterCourseName} onChange={(e) => setFilterCourseName(e.target.value)}
                            InputProps={{endAdornment: ( <IconButton><SearchIcon /></IconButton> )}}/></div>
                        </div>
                        <div className={styles.courseTreeContianer} hidden={isSmallUnitTree === true}>                        
                            <CourseListTree courseList={courseList} updateSelectedCourse={updateSelectedCourse} uniquePrefix="chkNewSaltCAR_" />
                        </div>

                        {
                            filterAdminReportType == 1 ? (
                            <div className={styles.inputContainer}>
                                    <div className="dateTo"><TextField className={styles.input} label={"Historic Date"} variant="outlined" autoComplete="off" InputLabelProps={{ shrink: true, }}
                                        type="date" defaultValue={filterDateTo} onChange={(e) => setFilterDateTo(formatDate(new Date(e.target.value)))} /></div>
                            </div>
                            ) : ('')
                        }
                        {/* {isHistoryCourse ? (
                            <div>
                                <div>&nbsp;</div>  
                                <div className={`${styles.inactiveContainer} includeHistoricdata`} >
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        className={styles.checkBox}
                                        onClick={() => setIsHistoryChecked(!isHistoryChecked)}
                                    /> {"Include Historic Course"}
                                </div>
                            </div>
                        ) : ('')} */}

                        {/* {isHistoryChecked ? (
                            <div className="pastCoursesSectionForHAR">
                                <div>
                                    <Courses isResetClicked={isResetClicked} givenCourses={pastcourseList} hasSmallUnits={false} CourseTitle={"Past Course"} />
                                </div>                                
                            </div>
                        ) : ('')} */}

                        {filterAdminReportType == 1 ? (
                            <div className="pastCoursesSectionForHAR">
                                <div>
                                    <Courses isResetClicked={isResetClicked} givenCourses={pastcourseList} hasSmallUnits={false} CourseTitle={"Past Course"} setUpdatedPastCourses={setUpdatedPastCourseList} filterPastCourseName={filterPastCourseName} setFilterPastCourseName={setFilterPastCourseName} />
                                </div>                                
                            </div>
                        ) : ('')}

                        <div className={styles.inputContainer}>
                             <div className="firstName"><TextField  className={styles.input}  label={"First Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}}                      
                                value={filterFirstName} onChange={(e) => setFilterFirstName(e.target.value)}/></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="lastName"><TextField   className={styles.input}  label={"Last Name"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            value={filterLastName} onChange={(e) => setFilterLastName(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="userName"><TextField  className={styles.input}  label={"Username"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            value={filterUserName} onChange={(e) => setFilterUserName(e.target.value)} /></div>
                        </div>
                        <div className={styles.inputContainer}>
                            <div className="email"><TextField  className={styles.input}  label={"Email"} variant="outlined" autoComplete="off"  InputLabelProps={{shrink: true,}} 
                            value={filterEmail} onChange={(e) => setFilterEmail(e.target.value)} /></div>
                        </div>                    
                        {groupingOption && 
                          <div className="groupby">
                            <div className={styles.groupingOption} >
                                <FormControl variant="outlined" className={styles.formControl}>                      
                                    <InputLabel id="grouping">Grouping Option</InputLabel>
                                        <Select
                                        value={selectedClassification.value}
                                        native
                                        onChange={(e) => setSelectedClassification({value: e.target.value, classificationID: e.target.value})}
                                        className={styles.input}
                                        variant="outlined" 
                                        label="Grouping Option"
                                        id="grouping"
                                        >
                                        {classificationList.map((item:any) => {
                                            return <option key={item.value + "_" + item.text} value={item.value}>{item.text}</option>
                                        })}
                                    </Select>
                                </FormControl>
                                {/* <MUICustomSelect idName={"groupingOption"} labelName={"Grouping Option"} currentValue={selectedClassification.value}
                                                setCurrentValue={(e : any) => 
                                                {
                                                    if (!!e.target) {
                                                        setSelectedClassification({ value: e.target.value, classificationID: e.target.value, 
                                                            text: (e.target as HTMLSelectElement).options[(e.target as HTMLSelectElement).selectedIndex].text });
                                                    }
                                                    else {
                                                        let filtered = !!classificationList ? classificationList.filter((cur : any) => !!cur && 
                                                                        parseInt(cur.classificationID) === parseInt(e)) : null;
                                                        if (!!filtered && filtered.length > 0){                                                                
                                                            setSelectedClassification({ value: filtered[0].value, classificationID: filtered[0].value, 
                                                                text: filtered[0].text });
                                                        }
                                                    }
                                                } }
                                                isFocussed={isGroupingOptionFocussed} setIsFocussed={setIsGroupingOptionFocussed}
                                                inputLabelStyle={{}} listOfItems={classificationList}/> */}
                            </div>
                          </div>
                        }     
                        {filterAdminReportType == 1 ? (
                            <>
                                <div className={`includeInactive`}>
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={filterIncludeInactive}
                                        className={styles.checkBox}
                                        onClick={() => setFilterIncludeInactive(!filterIncludeInactive)}
                                    /> {" "}
                                    {"Include Inactive Accounts"}
                                </div>
                            </>
                        ) : ('')}                   
                        <div>
                        <ButtonPanel openReportSchedulerModal={setModalOpen} showScheduler runReport={runReport} resetAll={resetAll} showAPIbutton={IsAPILibraryEnabled ? true:false}  runAPIQuery={GenerateAPIRequestBody}/>
                        </div> 
                    </div>                    
                </div>
            </BrandedCard>
            {showWarningModal && <CommonPopupModalForResult saveSuccess={isForceUpdateSuccess} hasErrored={hasErrored} handleClose={handleClosePopup} titleText={hasErrored ? 'Error' : 'Warning'} bodyText={modalPopupMessage} />}
        </>
    )
}
export default CurrentAdminReport
