import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon'; 

export const ReuploadIcon = () => (    
<svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.25613 8.76918V11.2136H2.58946V22.2136H19.7006V11.2136H16.0339V8.76918H19.7006C20.3489 8.76918 20.9706 9.02672 21.4291 9.48515C21.8875 9.94357 22.145 10.5653 22.145 11.2136V22.2136C22.145 22.8619 21.8875 23.4837 21.4291 23.9421C20.9706 24.4005 20.3489 24.6581 19.7006 24.6581H2.58946C1.94116 24.6581 1.3194 24.4005 0.860981 23.9421C0.402558 23.4837 0.14502 22.8619 0.14502 22.2136V11.2136C0.14502 10.5653 0.402558 9.94357 0.860981 9.48515C1.3194 9.02672 1.94116 8.76918 2.58946 8.76918H6.25613ZM12.2255 0.786851L16.3309 4.89107C16.5602 5.12041 16.6891 5.43146 16.6891 5.7558C16.6891 6.08013 16.5602 6.39118 16.3309 6.62052C16.1016 6.84986 15.7905 6.9787 15.4662 6.9787C15.1419 6.9787 14.8308 6.84986 14.6015 6.62052L12.3672 4.38507V16.1025C12.3672 16.4267 12.2385 16.7375 12.0093 16.9668C11.78 17.196 11.4692 17.3247 11.145 17.3247C10.8209 17.3247 10.51 17.196 10.2808 16.9668C10.0516 16.7375 9.9228 16.4267 9.9228 16.1025V4.38507L7.68857 6.62052C7.57502 6.73407 7.44021 6.82415 7.29184 6.88561C7.14347 6.94707 6.98445 6.9787 6.82385 6.9787C6.66326 6.9787 6.50424 6.94707 6.35587 6.88561C6.2075 6.82415 6.07269 6.73407 5.95913 6.62052C5.84557 6.50696 5.7555 6.37215 5.69404 6.22378C5.63258 6.07541 5.60095 5.91639 5.60095 5.7558C5.60095 5.5952 5.63258 5.43618 5.69404 5.28781C5.7555 5.13944 5.84557 5.00463 5.95913 4.89107L10.0658 0.786851C10.3523 0.500624 10.7407 0.339844 11.1456 0.339844C11.5506 0.339844 11.939 0.500624 12.2255 0.786851Z" fill="white"/>
</svg>



);

export default ReuploadIcon